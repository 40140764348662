/**
 * NOTE:
 * 1) currently we aren't using ./ and / prefix for absolute paths, if so, it has to be added
 */
export const PATH_NODE_JOINER = '/';

/**
 * Checks if the given WT document is meant for use with given ui-path
 * - either the 2 paths have to equal
 * - or the ui-path has to end with '/' + document root (path)
 */
export function isWalkthrougDocumentForUiPath(
  uiPath: string,
  documentPath: string,
  partialMatchForReusables = true
) {
  return (
    uiPath.toUpperCase() === documentPath.toUpperCase() ||
    (partialMatchForReusables &&
      uiPath
        .toUpperCase()
        .endsWith(PATH_NODE_JOINER + documentPath.toUpperCase()))
  );
}

/**
 * @desc Returns all WT paths where a walkthrough document with a given path could be applied on...
 * uses: @ref isWalkthrougDocumentForUiPath(...)
 */
export function getUiPathsToDocumentPath(
  uiPaths: string[],
  documentPath: string,
  partialMatchForReusables = true
): string[] {
  const result = uiPaths
    .filter((uip: string) =>
      isWalkthrougDocumentForUiPath(uip, documentPath, partialMatchForReusables)
    )
    .sort(
      (a, b) =>
        b.length -
        a.length /* b-a = desc (most specific), a-b = asc (least specific) */
    );

  return result;
}

/** @desc generates a step number */
export function createStepNumber(level: number, index: number) {
  return level % 2 === 0 ? index + 1 : String.fromCharCode(65 + index);
}

/** @desc generates a step label */
export function createStepLabel(parentLabel: string, index: number) {
  const level = parentLabel ? parentLabel.split('/').length : 0;
  const number = createStepNumber(level, index);
  return (parentLabel ? parentLabel + '/' : '') + number;
}

/**
 * @desc
 * Function is used to get all appliable walkthroughs to a given element (walkthrough path).
 *
 * @example
 * The main-person.home-address.address describes is a walkthrough path to a selected address field-set.
 *
 * To the given field-set we can define walkthroughs with 3 different levels (level count depends on nesting)...
 * ...the 1st item is the most specific WT path, while the last one is the most generic:
 * [
 *  main-person.home-address.address
 *  home-address.address
 *  address
 * ]
 *
 * If we define a walkthreough for "address", the given WT will be used for every "address" instance (home address, mailing address) on the screen...
 * ...unless there's a more specific WT for the given address available.
 *
 * We can define a more specific WT by using more specific WT paths.
 */
export function getReusableRootUiPaths(uiWtPath: string): string[] {
  if (!uiWtPath || !uiWtPath.length) return [];

  const expandedPaths = [];

  let path = '';
  const nodes = uiWtPath.split(PATH_NODE_JOINER).reverse();
  nodes.forEach((n) => {
    path = (!path ? n : n + PATH_NODE_JOINER) + path;
    expandedPaths.push(path);
  });

  return expandedPaths.sort(
    (a, b) =>
      b.length -
      a.length /* b-a = desc (most specific), a-b = asc (least specific) */
  );
}

/**
 * Function appends path-parts to a full path "expression"...
 */
export function mergePaths(...paths: string[]): string {
  if (!paths || !paths.length) return '';

  const finalPathNodes = paths.reduce((resultPathNodes, path) => {
    const pathNodes = this.splitPathToNodes(path || '');

    pathNodes.forEach((n) => {
      if (n === '.')
        resultPathNodes = resultPathNodes.length ? resultPathNodes : ['.'];
      else if (n === '..') resultPathNodes.pop();
      else resultPathNodes.push(n);
    });

    return resultPathNodes;
  }, []);

  return this.mergePathNodes(finalPathNodes);
}

export function splitPathToNodes(path: string): string[] {
  if (!path) return [];
  return path.split(PATH_NODE_JOINER);
}

export function mergePathNodes(pathNodes: string[]): string {
  return pathNodes /*.filter(n => !!n)*/
    .join(PATH_NODE_JOINER);
}

/**
 * Converts the selected absolute path to a relative path afainst to the roothPath...
 * @param rootPath absolute root path
 * @param path selected absolute path
 */
export function getRelativePath(rootPath: string, path: string) {
  const rootPathNodes = this.splitPathToNodes(rootPath);
  const pathNodes = this.splitPathToNodes(path);

  const l = Math.min(rootPathNodes.length, pathNodes.length);

  let mismatchIndex = rootPathNodes.findIndex((el, i) => el !== pathNodes[i]);
  if (mismatchIndex === -1) mismatchIndex = l;

  const iterateBackwards =
    mismatchIndex === -1
      ? rootPathNodes.length
      : rootPathNodes.length - mismatchIndex;

  const finalPathNodes = [];

  // NOTE: we don't add the common, as we are creating a relative path
  // for (let i = 0; i < mismatchIndex; i++) finalPathNodes.push(rootPathNodes[i]);
  for (let i = 0; i < iterateBackwards; i++) finalPathNodes.push('..');
  for (let i = mismatchIndex; i < pathNodes.length; i++)
    finalPathNodes.push(pathNodes[i]);

  return this.mergePathNodes(finalPathNodes);
}

// export function pathToDescendentQuery(path: string) {
//   const parts = path.split('/');
//   return parts.map
// }
