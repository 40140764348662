import { Component, Input, ViewChild } from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { SubtaskEditorComponent } from '../subtask-editor/subtask-editor.component';
import {
  ManPowerRequirementDto,
  ManPowerSubRequirementDto,
} from '@ups/xplat/api/dto';
import { Guid } from '@ups/xplat/core';

@Component({
  selector: 'ups-subtask-list',
  templateUrl: './subtask-list.component.html',
})
export class SubtaskListComponent {
  @Input() requirement: ManPowerRequirementDto;
  @Input() isReadOnly = true;

  @ViewChild(SubtaskEditorComponent) editModal: SubtaskEditorComponent;

  constructor() {}

  onEditSubtask(subtask: ManPowerSubRequirementDto) {
    if (subtask.ManPowerSubRequirementID) {
      this.requirement.SubRequirements = this.requirement.SubRequirements.map(
        (s) => {
          if (s.ManPowerSubRequirementID === subtask.ManPowerSubRequirementID)
            return subtask;
          return s;
        }
      );
    } else {
      subtask.ManPowerSubRequirementID = Guid.newGuid();
      subtask.ManPowerRequirementID = this.requirement.ManPowerRequirementID;
      this.requirement.SubRequirements.unshift(subtask);
    }
  }

  public rowCallback(context: RowClassArgs) {
    return {
      warning: context.dataItem.bActive === false,
    };
  }
}
