/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import { BaseModel } from '../base.model';

export class DisaResultModel extends BaseModel {
  UPSUAStatus: any = '';
  DisaStatus: any = '';
  BackgroundStatus: any = '';
  DisaHairTestDate: any = '';
  DisaOralFluidTestDate: any = '';
  IsInRandomHairProgram: any = '';
  UPSUADate: any = '';
  LastUrineTest: any = '';
  DisaUADate: any = '';
  BackgroundDate: any = '';
  UseDisaBackground = false;

  constructor(o: any = null) {
    super(o);
    if (o) {
      Object.assign(this, o);
    }
  }
}
