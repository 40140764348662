import { BaseModel } from '../../base.model';

export class EquipmentJobHistoryDto extends BaseModel {
  public VPJobId: string;
  public StartDate: Date | null;
  public EndDate: Date | null;
  public Amount: number;
  public DispatchStatus: string;
  public Unit: string;
  public PlantName: string;
  public JobName: string;

  constructor(o: any = null) {
    super(o);
  }
}
