<div class="dynamic-field-container">
  <div class="dynamic-field">
    <ups-dynamic-helper-label
      [config]="config"
      [dynamicService]="dynamicService"
    ></ups-dynamic-helper-label>
    <ng-template [ngIf]="config?.disabled && config?.value && signatureImage">
      <div>
        <img [src]="signatureImage" style="max-width: 400px" />
      </div>
    </ng-template>
    <ng-template [ngIf]="!config?.disabled">
      <div #signaturePadContainer>
        <div style="padding-right: 15px">
          <span
            style="
              color: #65759f;
              display: inline-block;
              font-size: 20px;
              margin-right: 10px;
            "
          >
            X
          </span>
          <canvas
            #signaturePad
            style="display: inline-block; background-color: #fff"
          ></canvas>
        </div>
        <div
          style="
            margin-left: 22px;
            width: 500px;
            height: 1px;
            background-color: #65759f;
          "
        ></div>
        <div
          style="
            text-align: center;
            font-size: 15px;
            color: #65759f;
            margin-top: 10px;
            width: 500px;
          "
        >
          {{ config?.options?.signature?.hint }}
        </div>
      </div>
      <br />
      <section class="container-fluid">
        <div class="row">
          <div class="col-12">
            <button
              kendoButton
              class="me-7"
              [disabled]="config?.disabled"
              (click)="clear()"
            >
              Clear
            </button>
            <button
              kendoButton
              class="me-7"
              [disabled]="config?.disabled"
              (click)="undo()"
            >
              Undo
            </button>

            <!--<button *ngIf="config?.formControlName" kendoButton [primary]="true" class="me-7" (click)="savePNG()">Save</button>-->

            <button
              *ngIf="!config?.formControlName"
              kendoButton
              class="me-7"
              (click)="changeColor()"
            >
              Change Color
            </button>
          </div>
          <div *ngIf="!config?.formControlName" class="col-6">
            <button
              kendoButton
              [primary]="true"
              class="me-7"
              (click)="savePNG()"
            >
              PNG
            </button>
            <button kendoButton class="me-7" (click)="saveJPG()">JPG</button>
            <button kendoButton class="me-7" (click)="saveSVG()">SVG</button>
          </div>
        </div>
      </section>
      <div
        class="form-builder-mask"
        [class.hidden]="config?.options?.hidden"
        *ngIf="config?.isFormBuilder"
      ></div>
    </ng-template>
  </div>
  <div class="form-builder-options" *ngIf="config?.isFormBuilder">
    <i class="fas fa-times ms-3 cursor-pointer c-red" (click)="delete()"></i>
    <!-- <i class="fas fa-bars ms-3 cursor-grab "></i> -->
  </div>
</div>
