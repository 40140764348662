/* eslint-disable */
/**
 * Auth related Injection token handling
 *
 * CheckAndExecute (default):
 * - will do a auth.isAuthenticated$ subscription and thus alter basic loggedIn flags
 * - won't interfere with the HttpClient execution
 *
 * CheckAndThrowWhenNotauthenticated
 * - if the auth.isAuthenticated$ evaluates to false an exception will be thrown
 * - HttpClient endpoint won't be hit
 *
 * CheckAndSkipWhenNotAuthenticted
 * - if the auth.isAuthenticated$ evaluates to false HttpClient won't be executed and no error will be thrown
 *
 * NoCheck
 * - no check, thus no interfere with the HttpClient execution
 */
export enum HttpAuthenticationCheckMode {
  CheckAndExecute,
  CheckAndThrowWhenNotauthenticated,
  CheckAndSkipWhenNotAuthenticted,
  NoCheck,
}
