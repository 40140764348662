import { BaseModel } from '../base.model';

export class EquipmentInspectionHistoryDto extends BaseModel {
  public EquipmentInspectionHistoryID: string | null;
  public EMCo: number;
  public Equipment: string;
  public InspectionFrequency: string | null;
  public InspectedBy: string | null;
  public InspectionOutcome: string | null;
  public LastInspectionDate: Date | null;
  public NextInspectionDate: Date | null;
  public InspectionNotes: string | null;
  public CreatedUserID: string;
  public DateCreated: Date;
  public ModifiedUserID: string | null;
  public DateModified: Date | null;
  public ActiveYN: string;
  public ShouldUpdateEMEM: boolean;

  constructor(o: any = null) {
    super(o);
  }
}
