<kendo-dialog modal fade [hidden]="!opened" (close)="close()" [width]="850">
  <kendo-dialog-titlebar>
    <span *ngIf="model.bActive && canModify">
      {{ isBulkEdit ? 'Bulk ' : '' }}{{ isEdit ? 'Edit' : 'Add' }} Equipment
      Dispatch
    </span>
    <span *ngIf="!model.bActive || !canModify">Equipment Dispatch Detail</span>
  </kendo-dialog-titlebar>

  <div>
    <div class="form-group row" *ngIf="!isBulkEdit">
      <label class="col-form-label col-md-4">Equipment</label>
      <div class="col-md-8">
        <ups-equipment-resource-dropdown
          [equipment]="equipment"
          (equipmentChanged)="valueChangeEquipment($event)"
          [jobID]="jobID"
          [jobVPCompanyID]="jobVPCompanyID"
          [equipmentBillingTemplate]="billingTemplate"
          [disabled]="!model.bActive || !canModify || !isNew || isBulkEdit"
          [required]="isNew"
          help-anchor="dropdown__equipment"
        ></ups-equipment-resource-dropdown>
      </div>
    </div>

    <div *ngIf="showResourceConfirm" class="form-group row">
      <div class="col-form-label col-md-4"></div>
      <div class="col-md-8" style="max-height: 200px; overflow-y: auto">
        <ups-equipment-resource-confirm
          [equipmentResourceDto]="equipmentResourceDto"
          [overlappingRequests]="overlappingRequests"
          [showWarning]="showWarning"
          [showButtons]="showButtons"
          (confirmEquipment)="confirmEquipment()"
          (discardEquipment)="discardEquipment()"
        ></ups-equipment-resource-confirm>
      </div>
    </div>

    <div class="form-group row" *ngIf="!isBulkEdit">
      <label class="col-form-label col-md-4">Alt ID</label>
      <div class="col-md-8">
        <input
          type="text"
          class="k-textbox text-input"
          name="jobName"
          maxlength="200"
          [disabled]="!model.bActive || !canModify || isBulkEdit"
          [(ngModel)]="model.AltID"
          help-anchor="input__altId"
        />
      </div>
    </div>

    <div class="form-group row" *ngIf="!isBulkEdit">
      <label class="col-form-label col-md-4">Customer ID</label>
      <div class="col-md-8">
        <input
          type="text"
          class="k-textbox text-input"
          name="jobName"
          maxlength="40"
          [disabled]="!model.bActive || !canModify || isBulkEdit"
          [(ngModel)]="model.CustomerID"
          help-anchor="input__customerId"
        />
      </div>
    </div>

    <div class="form-group row" *ngIf="!isBulkEdit">
      <label class="col-form-label col-md-4">Start Date</label>
      <div class="col-md-8">
        <ups-kendo-datepicker-shared
          #startDateEl
          #startDate="ngModel"
          name="startDate"
          [disabled]="!model.bActive || !canModify || isBulkEdit"
          [required]="false"
          [(ngModel)]="model.StartDate"
          (valueChange)="reloadOverlappingEquipmentRequests()"
          [placeholder]="''"
          [isFullWidth]="true"
          (blur)="handleBlur(model.StartDate, startDateEl)"
          help-anchor="input__startDate"
        ></ups-kendo-datepicker-shared>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-md-4">End Date</label>
      <div class="col-md-8">
        <ups-kendo-datepicker-shared
          #endDateEl
          #endDate="ngModel"
          name="endDate"
          [disabled]="!model.bActive || !canModify"
          [required]="false"
          [(ngModel)]="model.EndDate"
          [min]="model.StartDate"
          [validWhen]="[
            {
              expression:
                !model.StartDate ||
                !model.EndDate ||
                model.StartDate <= model.EndDate,
              errorProperty: 'endDateIsBeforeStartDate',
              errorMessage: 'End Date must be equal or greater than Start Date!'
            }
          ]"
          (valueChange)="reloadOverlappingEquipmentRequests()"
          [placeholder]="''"
          (blur)="handleBlur(model.EndDate, endDateEl)"
          [isFullWidth]="true"
          help-anchor="input__endDate"
        ></ups-kendo-datepicker-shared>
        <span
          *ngIf="endDate.errors?.endDateIsBeforeStartDate"
          class="text-danger"
        >
          {{ endDate.errors?.endDateIsBeforeStartDate.message }}
        </span>
      </div>
    </div>

    <div class="form-group row" *ngIf="!isBulkEdit">
      <label class="col-form-label col-md-4">Note</label>
      <div class="col-md-8">
        <input
          type="text"
          class="k-textbox text-input"
          name="jobName"
          maxlength="8000"
          [disabled]="!model.bActive || !canModify || isBulkEdit"
          [(ngModel)]="model.Note"
          help-anchor="input__note"
        />
      </div>
    </div>
  </div>

  <kendo-dialog-actions [attr.disabled]="isSaving ? '' : null">
    <button class="k-button" (click)="close()">
      {{ model.bActive && canModify ? 'Cancel' : 'Close' }}
    </button>
    <button
      *ngIf="model.bActive && canModify"
      class="k-button k-primary"
      [disabled]="!isValid() || isSaving || showWarning"
      (click)="onSave($event)"
      help-anchor="button__save"
    >
      <span *ngIf="!isSaving">Save</span>
      <span *ngIf="isSaving">
        <i class="fa fa-spinner fa-spin"></i>
        Saving...
      </span>
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<ups-confirmation-dialog
  [titleText]="'Unsaved Changes'"
  [confirmText]="'Yes, Discard'"
  [cancelText]="'No, Return'"
></ups-confirmation-dialog>
