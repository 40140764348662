import {
  ApiResultCamelCase,
  ApiResultTransform,
  UpsApiHttpErrorResponse,
  UpsApiHttpErrorResponseCamelCase,
} from '@ups/common';
import { ErrorHandler, Injectable, Injector, inject } from '@angular/core';
import { LogService } from '@ups/xplat/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  injector = inject(Injector);
  log = inject(LogService);
  // Note: cannot inject toastr in error handler directly
  // https://github.com/scttcper/ngx-toastr/issues/179#issuecomment-325724269
  private counter = 0;

  private get toastr(): ToastrService {
    return this.injector.get(ToastrService);
  }

  /**
   * To avoid error handling at every http call to show message to the user - don't catch it in the component and it will be caught here.
   * If you need to catch it to update component state then rethrow the error so it will be caught here nad will show toast popup message with the error.
   */
  handleError(
    /* eslint-disable @typescript-eslint/naming-convention */
    response: (UpsApiHttpErrorResponse | UpsApiHttpErrorResponseCamelCase) & {
      rejection: (
        | UpsApiHttpErrorResponse
        | UpsApiHttpErrorResponseCamelCase
      ) & { StatusCode?: number | string; statusCode?: number | string };
    }
    /* eslint-enable @typescript-eslint/naming-convention */
  ) {
    const unwrappedResponse =
      response.rejection && response.rejection.error
        ? response.rejection
        : response; // Workaround to ZoneJs wrapping error to rejection property when throw from promise. Bug: https://github.com/angular/angular/issues/27840
    const counter = ++this.counter;

    // TODO: cretae a special sink for error that would log to the helpdesk...
    // const helpDesk: HelpDeskDTO = {
    //     FullName: null,
    //     Email: null,
    //     Phone: null,
    //     SmartlookURL: null,
    //     SupportInfo: error.stack,
    //     Body: error.message,
    //     RelativeURL: window.location.href,
    //     // ErrorHistory: this.errorHistory,
    //     ShortDescription: error.message
    // };

    // Error obj.:
    // - error.message
    // - error.stack()
    // We show only the toast popup message by default only if it is a and error from http call - from our API that returns extended http error - the UpsApiHttpErrorResponse
    // We don't show toast popup message for bugs or console errors - which end up here as well.
    // We show to user only first message from error.error.Messages as usually it contains most meaning full error message from the most inner exception sent by Api.
    const error: ApiResultCamelCase = {
      ...ApiResultTransform.toCamelCase(
        (unwrappedResponse as UpsApiHttpErrorResponse).error
      ),
      ...ApiResultTransform.toCamelCase(
        (unwrappedResponse as UpsApiHttpErrorResponseCamelCase).error
      ),
    };

    const errorMessage = `The error above was Caught in Global Error Handler`;

    if (error.message || error.messages?.length || unwrappedResponse?.error) {
      // Please see information on detail/Detail problem on :
      // https://docs.microsoft.com/en-us/dotnet/api/microsoft.aspnetcore.mvc.problemdetails?view=aspnetcore-6.0
      let messageForUser =
        unwrappedResponse?.error['detail'] ??
        unwrappedResponse?.error['Detail'] ??
        error.message ??
        unwrappedResponse?.statusText;

      if (error.messages?.length > 0) {
        messageForUser = error.messages[0];
        if (error.messages.length > 1) {
          messageForUser =
            messageForUser +
            '; ' +
            error.messages
              .slice(1)
              .filter((m) => m?.toLowerCase().indexOf('inner exception') === -1)
              .join('; '); // excluding messages where it just says look at the inner exception for more details.
        }
      }

      if (this.toastr) {
        try {
          this.toastr.error(messageForUser, 'Error occurred', {
            closeButton: true,
          });
          this.log.error(
            unwrappedResponse,
            `${errorMessage} and a toast message was shown to user as the error came from our UPS Api. Global Error counter #: ${counter} Message:${messageForUser}`
          );
        } catch (err) {
          this.log.debug(
            unwrappedResponse,
            `${errorMessage}. Global Error counter #: ${counter}`
          );
          this.log.debug(err);
          this.log.debug(
            `You can likely ignore this error. This just means this application has not added ngx-toastr with the proper CSS styling. If you want toast error messages to appear in the UI, you can add it.`
          );
        }
      } else {
        this.log.debug(
          unwrappedResponse,
          `${errorMessage}. Global Error counter #: ${counter}`
        );
      }
    } else {
      this.log.debug(
        unwrappedResponse,
        `${errorMessage}. Global Error counter #: ${counter}`
      );
    }

    // if (
    //   response.rejection &&
    //   (response.rejection.StatusCode?.toString() === '401' ||
    //     response.rejection.statusCode?.toString() === '401')
    // ) {
    //   console.log('response.rejection.StatusCode:', response.rejection.StatusCode)
    //   localStorage.removeItem('access_token');
    //   localStorage.removeItem('id_token');
    //   localStorage.removeItem('expires_at');
    //   localStorage.removeItem('profile');

    //   // Go back to the home route
    //   // this.router.navigate(['/']);

    //   window.location.reload();
    // }

    /*
         Rethrowing the error in the GlobalErrorHandler leads to an uncaught exception where the browser stops execution. That's why it works only once.
         The solution is simply to not rethrow in the ErrorHandler.

         More detail: In this case there is no onError-function provided in subscribe(). That's why rxjs/Subscriber.js runs into the catch-part of __tryOrUnsub. It tries to execute the onError-function but fails (because there is none) and therefore unsubscribes from the Observable and throws the error. The GlobalErrorHandler picks it up, does logging and notification and then rethrows it.
         At this point the error becomes an uncaught exception outside of Angular. (the default ErrorHandler does not rethrow an error)
         */

    if (
      (response && response.status?.toString() === '401') ||
      (response.rejection &&
        (response.rejection.StatusCode?.toString() === '401' ||
          response.rejection.statusCode?.toString() === '401' ||
          response.rejection.status?.toString() === '401'))
    ) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('id_token');
      localStorage.removeItem('expires_at');
      localStorage.removeItem('profile');
      // Go back to the home route
      // this.router.navigate(['/']);
      window.location.reload();
    }
    /*
     Rethrowing the error in the GlobalErrorHandler leads to an uncaught exception where the browser stops execution. That's why it works only once.
     The solution is simply to not rethrow in the ErrorHandler.

     More detail: In this case there is no onError-function provided in subscribe(). That's why rxjs/Subscriber.js runs into the catch-part of __tryOrUnsub. It tries to execute the onError-function but fails (because there is none) and therefore unsubscribes from the Observable and throws the error. The GlobalErrorHandler picks it up, does logging and notification and then rethrows it.
     At this point the error becomes an uncaught exception outside of Angular. (the default ErrorHandler does not rethrow an error)
     */
  }
}
