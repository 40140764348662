import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { forkJoin, of } from 'rxjs';
import {
  GridDataResult,
  DataStateChangeEvent,
} from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';

import {
  EquipmentInspectionHistoryDto,
  EquipmentResourceDto,
} from '@ups/xplat/api/dto';
import { EquipmentVpService } from '@ups/xplat/web/core';
import { nameOf } from '@ups/xplat/utils';
import { BaseComponent } from '@ups/xplat/core';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import { VpService } from '@ups/xplat/api/services';
import { EmployeeGuardService } from '@ups/xplat/base/employee-profile';

@Component({
  selector: 'ups-equipment-inspection-history',
  templateUrl: './equipment-inspection-history.component.html',
})
export class EquipmentInspectionHistoryComponent
  extends BaseComponent
  implements OnChanges
{
  @Input() vpCompanyID: number;
  @Input() equipmentName: string;
  @Input() keyId: number;

  inspectionHistory: EquipmentInspectionHistoryDto[] = [];
  equipmentResource: EquipmentResourceDto;
  inspectionOutcomeDropdownItems = [
    { text: 'Pass', value: 'P' },
    { text: 'Fail', value: 'F' },
  ];
  inspectionFrequencyDropdownItems = [];
  inspectedByDropdownItems = [];

  public gridData: GridDataResult;
  public state: State = {
    skip: 0,
    take: 10,
  };

  isEditMode = false;
  isEditInspectionMode = false;
  isLoading = false;
  hasReadPermissions = false;
  hasEditPermissions = false;
  selectedInspectionItem: EquipmentInspectionHistoryDto;
  wasNextInspectionAutoCalculated = false;

  constructor(
    private security: SecurityService,
    private vpService: VpService,
    private equipmentVpService: EquipmentVpService,
    private guardService: EmployeeGuardService
  ) {
    super();
    this.hasReadPermissions = this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_inspectiontab
    ).readAll;
    this.hasEditPermissions = this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_inspectiontab
    ).editAll;
  }

  ngOnChanges() {
    this.resetValues();
    this.gridData = process(this.inspectionHistory, this.state);
    this.loadData();
  }

  resetValues() {
    this.inspectionHistory = [];
    this.equipmentResource = null;
    this.selectedInspectionItem = null;
    this.isEditMode = false;
    this.isEditInspectionMode = false;
    this.guardService.isToUseGuard = false;
    this.wasNextInspectionAutoCalculated = false;
    this.isLoading = false;
  }

  loadData() {
    if (this.vpCompanyID && this.equipmentName && this.hasReadPermissions) {
      this.isLoading = true;
      forkJoin([
        this.equipmentVpService.getEquipmentInspectionHistory(
          this.vpCompanyID,
          this.equipmentName
        ),
        this.equipmentVpService.getEquipmentResource(
          this.vpCompanyID.toString(),
          this.equipmentName
        ),
        this.inspectionFrequencyDropdownItems.length
          ? of({ value: this.inspectionFrequencyDropdownItems })
          : this.vpService.getDropdownItems('udFrequency'),
        this.inspectedByDropdownItems.length
          ? of({ value: this.inspectedByDropdownItems })
          : this.vpService.getDropdownItems('Inspect'),
      ]).subscribe(
        (d) => {
          this.inspectionHistory = d[0].value;
          this.gridData = process(this.inspectionHistory, this.state);
          this.equipmentResource = d[1].value[0];
          this.inspectionFrequencyDropdownItems = d[2].value;
          this.inspectedByDropdownItems = d[3].value;
          this.isLoading = false;
        },
        (error) => {
          console.error(error);
          this.resetValues();
          throw error;
        }
      );
    }
  }

  editEquipment() {
    if (this.hasEditPermissions) {
      this.isEditMode = true;
      this.guardService.isToUseGuard = true;
    }
  }

  cancelEquipment(isFormDirty: boolean) {
    if (isFormDirty) {
      this.resetValues();
      this.loadData();
    } else {
      this.isEditMode = false;
      this.guardService.isToUseGuard = false;
    }
  }

  saveEquipment(isFormValid: boolean) {
    if (!isFormValid) {
      window.alert('Inspection Frequency has invalid value.');
      return;
    }

    if (this.equipmentResource) {
      this.isLoading = true;

      this.equipmentVpService
        .updateEquipmentResource(this.equipmentResource, [
          nameOf<EquipmentResourceDto>('InspectionFrequency'),
        ])
        .subscribe(
          () => {
            this.resetValues();
            this.loadData();
          },
          (error) => {
            console.error(error);
            this.isLoading = false;
            throw error;
          }
        );
    }
  }

  addInspection() {
    this.selectedInspectionItem = new EquipmentInspectionHistoryDto();
    this.selectedInspectionItem.EMCo = this.equipmentResource.EquipmentCompany;
    this.selectedInspectionItem.Equipment =
      this.equipmentResource.EquipmentName;
    this.isEditInspectionMode = true;
  }

  editInspection(inspectionItem: EquipmentInspectionHistoryDto) {
    this.selectedInspectionItem = inspectionItem;
    this.selectedInspectionItem.ShouldUpdateEMEM = false;
    this.isEditInspectionMode = true;
  }

  saveInspection(isFormValid: boolean) {
    if (!isFormValid) {
      window.alert('Some fields in the form are not filled properly.');
      return;
    }

    this.isLoading = true;
    this.isEditInspectionMode = false;
    this.equipmentVpService
      .saveEquipmentInspectionHistoryItem(this.selectedInspectionItem)
      .subscribe(
        () => {
          this.resetValues();
          this.loadData();
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
          this.isEditInspectionMode = true;
          throw error;
        }
      );
  }

  cancelInspection(isFormDirty: boolean) {
    if (!this.isLoading) {
      if (isFormDirty) {
        this.resetValues();
        this.loadData();
      } else {
        this.selectedInspectionItem = null;
        this.isEditInspectionMode = false;
      }
    }
  }

  getInspectionOutcomeText(value: string): string {
    const outcomeItem = this.inspectionOutcomeDropdownItems.find(
      (i) => i.value === value
    );
    if (!outcomeItem) {
      return value;
    }

    return outcomeItem.text;
  }

  getInspectedByText(value: string): string {
    const inspectedBy = this.inspectedByDropdownItems.find(
      (i) => i.id === value
    );
    if (!inspectedBy) {
      return value;
    }

    return inspectedBy.text;
  }

  lastInspectionDateChanged(
    lastInspectionDateDropdownControl: UntypedFormControl
  ) {
    if (
      lastInspectionDateDropdownControl.valid &&
      (!this.selectedInspectionItem.NextInspectionDate ||
        this.wasNextInspectionAutoCalculated)
    ) {
      this.wasNextInspectionAutoCalculated = true;
      const nextInspectionDate: Date = this.getNextInspectionDate(
        lastInspectionDateDropdownControl.value
      );
      this.selectedInspectionItem.NextInspectionDate =
        nextInspectionDate == null
          ? this.selectedInspectionItem.NextInspectionDate
          : nextInspectionDate;
    }
  }

  getNextInspectionDate(lastInspectionDate: Date): Date {
    if (this.equipmentResource.InspectionFrequency) {
      const newNextInspectionDate: Date = new Date(lastInspectionDate);
      switch (this.equipmentResource.InspectionFrequency) {
        case 'Annually': {
          newNextInspectionDate.setFullYear(
            lastInspectionDate.getFullYear() + 1
          );
          break;
        }
        case 'Montly': {
          newNextInspectionDate.setMonth(lastInspectionDate.getMonth() + 1);
          break;
        }
        case 'Quarterly': {
          newNextInspectionDate.setMonth(lastInspectionDate.getMonth() + 3);
          break;
        }
        case 'Quarterly / Per Use': {
          newNextInspectionDate.setMonth(lastInspectionDate.getMonth() + 3);
          break;
        }
        case 'Annually / Per Use': {
          newNextInspectionDate.setFullYear(
            lastInspectionDate.getFullYear() + 1
          );
          break;
        }
      }

      return newNextInspectionDate;
    }

    return null;
  }

  dataStateChange(state: DataStateChangeEvent) {
    this.state = state;
    this.gridData = process(this.inspectionHistory, this.state);
  }
}
