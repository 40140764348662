<div class="d-flex flex-wrap align-items-center w-100" kendoTooltip>
  <div class="d-flex me-3 mt-2">
    <button
      kendoButton
      id="reloadButton"
      [icon]="'reset'"
      title="Reload Grid"
      (click)="reloadClick.emit()"
      help-anchor="button__reload"
    ></button>
    @if (xlsxDownloadUri) {
    <button
      id="exportButton"
      title="Export to Excel"
      class="k-button-icontext k-button"
      [disabled]="
        !isExportEnabled || !isExportButtonEnabled || isFileDownloading
      "
      (click)="downloadXlsxFile()"
      help-anchor="button_export-to-excel"
    >
      <i class="fas fa-file-excel"></i>
    </button>
    } @if (xlsxSelectedDownloadUri) {
    <button
      id="exportSelectedButton"
      title="Export Selected Rows"
      class="k-button-icontext k-button"
      [disabled]="
        !isExportEnabled || !isExportSelectedButtonEnabled || isFileDownloading
      "
      (click)="downloadXlsxFile(true)"
      help-anchor="button_export-to-excel-selected"
    >
      <i class="fas fa-file-spreadsheet"></i>
    </button>
    }
    <span *ngIf="isFileDownloading" title="Downloading the Excel file ...">
      <i class="fa fa-spinner fa-spin"></i>
    </span>

    <button
      *ngIf="showPDF"
      class="k-button k-primary"
      [disabled]="isPDFDisabled"
      (click)="pdfClick.emit()"
    >
      PDF
    </button>
    <button
      *ngIf="showPDFImages"
      class="k-button k-primary"
      [disabled]="isPDFDisabled"
      (click)="pdfImageClick.emit()"
    >
      PDF w/Images
    </button>
  </div>

  <ups-tableview
    id="tableView"
    *ngIf="tableViewKey"
    class="me-3 mt-2"
    [key]="tableViewKey"
    [state]="gridState"
    (stateChanged)="gridStateChange.emit($event)"
    [excludeFromColConfig]="tableViewExcludedColumns"
    [customOptions]="tableViewCustomOptions"
    [securityFeature]="tableViewSecurity"
    [customSettings]="tableViewCustomSettings"
    (customSettingsChange)="customSettingsChange.emit($event)"
  ></ups-tableview>

  <div class="d-flex me-3 mt-2">
    <button
      id="addButton"
      *ngIf="showAdd"
      help-anchor="button__add"
      [disabled]="isAddDisabled"
      kendoButton
      [primary]="true"
      (click)="addClick.emit()"
    >
      {{ addText }}
    </button>
    <button
      id="copyButton"
      *ngIf="showCopy"
      help-anchor="button__copy"
      [disabled]="isCopyDisabled"
      kendoButton
      [primary]="true"
      (click)="copyClick.emit()"
    >
      {{ copyText }}
    </button>
    <span *ngIf="showEdit" [title]="editWarning">
      <button
        id="editButton"
        help-anchor="button__edit"
        [disabled]="isEditDisabled"
        kendoButton
        (click)="editClick.emit()"
      >
        {{ editText }}
      </button>
    </span>
    <button
      id="deleteButton"
      *ngIf="showDelete"
      help-anchor="button__delete"
      [disabled]="isDeleteDisabled"
      kendoButton
      (click)="handleDeleteClick()"
    >
      {{ deleteText }}
    </button>
  </div>
  <div class="me-3 mt-2">
    <ng-content></ng-content>
  </div>
  <div
    *ngIf="isCondensed && showWrapText"
    class="d-flex me-3 mt-2 align-items-center"
  >
    <span class="text-nowrap me-2">Wrap text</span>
    <kendo-switch
      [(ngModel)]="wrapWords"
      (valueChange)="wrapWordsChange.emit($event)"
    ></kendo-switch>
  </div>
  <div class="filter-search me-3 mt-2" *ngIf="multiSearchColumns.length !== 0">
    <input
      id="multiSearchBox"
      data-e2e="multiSearchBox"
      [(ngModel)]="multiSearchValue"
      multiColumnSearch
      [mcsDebounceFilterChange]="1000"
      [mcsSearchColumns]="multiSearchColumns"
      (mcsFilterChange)="extraFilterStateChange($event)"
      class="k-textbox w-400 ps-3"
      placeholder="{{ multiSearchPlaceholder }}"
      title="{{ multiSearchTitle }}"
      help-anchor="grid-toolbar__search"
    />
    <i class="far fa-search"></i>
  </div>
  <button
    class="me-3 mt-2"
    *ngIf="showClearFilters"
    kendoButton
    (click)="clearFilters()"
  >
    Clear Filters
  </button>
  <kendo-dropdownbutton
    class="me-3 mt-2"
    *ngIf="showActions"
    [data]="actions"
    icon="arrow-chevron-down"
  >
    Actions
    <ng-template kendoDropDownButtonItemTemplate let-dataItem>
      <span kendoTooltip [title]="dataItem.actionDesc ?? ''">
        {{ dataItem.actionName }}
      </span>
    </ng-template>
  </kendo-dropdownbutton>

  <div *ngIf="showHotkeys" class="me-3 mt-2">
    <ups-hotkeys [options]="hotkeyOptions"></ups-hotkeys>
  </div>

  <kendo-grid-column-chooser
    class="mt-2"
    *ngIf="(tableViewKey && showColumnChooser) || forceColumnChooser"
    help-anchor="column-chooser"
  ></kendo-grid-column-chooser>
</div>

<ups-confirmation-dialog
  [titleText]="deleteConfirmTitle"
  [confirmText]="deleteConfirmYesText"
  [cancelText]="deleteConfirmNoText"
></ups-confirmation-dialog>
