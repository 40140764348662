import { BaseModel } from '../../../base.model';

export class EquipmentSubRequirementDto extends BaseModel {
  EquipmentSubRequirementID: string;
  EquipmentTypeID: string;
  EquipmentRequirementID: string;
  JobID: string;
  PlantID: number;
  bActive: boolean;
  Name: string;
  RequirementName: string;
  bMandatory: boolean;
  bBillable: boolean;
  Notes: string;
  DateModified: any;
  ModifiedBy: string;

  constructor(o: any = null) {
    super(o);
  }
}
