import { BaseModel } from '../base.model';
import { TableviewShareModel } from './tableview-share-model';

export class TableviewModel extends BaseModel {
  TableViewID: string = null;
  Name: string = '';
  Key: string = '';
  Definition: string = '';
  bDefault: boolean = false;
  bPublic: boolean = false;
  bActive: boolean = false;
  bShared: boolean = false;
  Shares: TableviewShareModel[] = [];

  constructor(o: any = null) {
    super(o);
    if (o) {
      Object.assign(this, o);
    }
  }
}
