import { Injectable } from '@angular/core';
import { DockLog } from '@ups/tool-tracker';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReceiveService {
  allItems$ = new Observable<DockLog[]>();
  selectedItem$ = new BehaviorSubject<DockLog | null>(null);
}
