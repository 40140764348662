import { BaseModel } from '../../base.model';

export class DisaRosterAccountDto extends BaseModel {
  DisaRosterAccountID: number;
  ClientID: number | null;
  CompanyVpIDs: (number | null)[];
  UserName: string;

  constructor(o: any = null) {
    super(o);
  }
}
