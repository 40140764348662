export function downloadFileFromBase64(data) {
  // A byte array serialized by JSON is then returned as Base64 encoded string
  const base64String = data && data.Bytes;
  if (!base64String) {
    return;
  }
  // We need 2 base64 converters:
  // - one that converts plain "base64"
  // - and second that converts base64 encoded data URI's - (like the IMG src in HTML) - "data:image/png;base64,iVBORw0KGgo..."
  const byteArray1 = convertBase64ToByteArray(base64String);
  const byteArray2 = new Uint8Array(byteArray1);

  const a = window.document.createElement('a');
  a.href = window.URL.createObjectURL(
    new Blob([byteArray2], {
      type: 'application/octet-stream',
    })
  );
  a['download'] = data.Metadata.FileName;

  // Append anchor to body.
  document.body.appendChild(a);
  a.click();

  // Remove anchor from body
  document.body.removeChild(a);
}

export function convertBase64ToByteArray(base64: string): number[] {
  const byteCharacters = atob(base64);
  const byteNumbers: number[] = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  return byteNumbers;
}

/** Creates file dialog popup for user to choose where to save file. File "data" received from server as .NET Microsoft.AspNetCore.Mvc.FileContentResult with Header ContentType: application/octet-stream */
/** Alternatively you can use saveAs() method from (at)progress Kendo library */
export function downloadBlob(
  data: Blob | MediaSource | unknown,
  fileName: string
) {
  if (data) {
    let fileURL = null;
    try {
      fileURL = URL.createObjectURL(<Blob | MediaSource>data);
    } catch {
      fileURL = URL.createObjectURL((<{ body: Blob }>data).body);
    }

    const a = window.document.createElement('a');
    a.style.display = 'none';
    a.href = fileURL;
    a['download'] = fileName;

    // Append anchor to body.
    document.body.appendChild(a);
    a.click();
    // Remove anchor from body
    document.body.removeChild(a);
  }
}

export function generateCSV(downloadData, dataName, columnHeaders?) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const line = (row: Record<string, any>) =>
    Object.values(row)
      .map((val) => `"${val}"`)
      .join(',');

  const csv = downloadData.map((row) => line(row)).join('\n');
  let fullCsv = csv;

  if (columnHeaders) {
    fullCsv = `${columnHeaders.join(',')}\n${csv}`;
  }

  const blob = new Blob([fullCsv], { type: 'text/csv;charset=utf8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('visibility', 'hidden');
  link.download = `${dataName}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
