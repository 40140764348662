import { ControlContainer, FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { Directive } from '@angular/core';

/**
 * To support esier nesting with MODEL DRIVEN forms.
 *
 * ...it will pass the existing FormGroupDirective (and so the existing FormGroup) to the component
 * ...this allows us to attach new controls directly under the parent FormGroup, without a need to artifically add a new <ng-container [formGroup]='...'> element
 * ...thus allowing us to have same path to access FormControls as has the DTO to access the value...
 *
 * Benefits:
 * - child components no longer need to have a <ng-content [formGroup]='...'> wrapper element ***
 * - we don't need to pass in the formGroup from the parent to child via input
 * - parent can define the group's name via the formGroupName directive (<ng-content formGroupName ="homeAddress"><address-component /></ng-content>)
 * - the child itself will register it's control to the formGroup that is provided by the parent
 *
 * *** without that additional group forced on us, we can have same path to access controls and DTO property values as well:
 * - so data.mailAddress.street and. form.get('mailAddress.street').value
 * - or even data.street and for.get('street').value
 *
 * Usage:
 *
 *  The child control:
 *  - has to use viewProvider: [FORM_GROUP_DIRECTIVE_PROVIDER]
 *  - has to handle registering it's control to the provided (exiwsting) FormGroup
 *
 *    constructor(private formGroupDirective: FormGroupDirective) { ... }
 *    ngOnInit() {
 *      let parentFormGroup = this.formGroupDirective.control;
 *      let thisFormGroup = this.formBuilder.group({ ... });
 *
 *      // add new form group controls into the parent form group control collection...
 *      // extendFormGroupControls(parentFormGroup, thisFormGroup);
 *      //
 *      // ...or extend parent form group controls with the new form group (we probably won't use this option)
 *      // appendFormGroup(parentFormGroup, 'childGroupName', thisFormGroup);
 *    }
 *
 * In addition there are 2 functions exported:
 *
 *  appendFormGroupControls(destination: FormGroup, extension: FormGroup)
 *    registers the extension FormGroup controls into the destination FormGroups control collection
 *
 *  appendFormGroup(destination: FormGroup, extensionGroupName: string, extension: FormGroup)
 *    registers the extension FormGroup into the destination FormGroups control collection with the group/property name: extensionGroupName
 */
export const FORM_GROUP_DIRECTIVE_PROVIDER = {
  provide: ControlContainer,
  useExisting: FormGroupDirective,
};

export const appendFormGroupControls = (destination: UntypedFormGroup, extension: UntypedFormGroup): void => {
  // tslint:disable-next-line: forin
  for (const ctrlName in extension.controls) destination.registerControl(ctrlName, extension.controls[ctrlName]);
};

export const appendFormGroup = (destination: UntypedFormGroup, extensionGroupName: string, extension: UntypedFormGroup): void => {
  destination.registerControl(extensionGroupName, extension);
};

/**
 * To support esier nesting with MODEL DRIVEN forms.
 * Usage: add the [provide-form-group-directive] attribute to the top-most container item
 * See additional notes on: FORM_GROUP_DIRECTIVE_PROVIDER.
 */
@Directive({
  selector: '[provide-form-group-directive]',
  providers: [FORM_GROUP_DIRECTIVE_PROVIDER],
})
export class ProvideFormGroupDirectiveDirective {}
