import { BaseModel } from '../base.model';

export class MileageRateDto extends BaseModel {
  MileageRateID: number | null;
  JobID: string | null;
  Rate: number;
  StartDate: Date;
  EndDate: Date | null;
  VpJobID: string;
  JobName: string;
  bActive: boolean;
  VpCompanyID: number | null;
  /** GUID */
  CompanyID: string | null;
  CompanyName: string | null;
  LastModifiedAt: Date | null;
  LastModifiedAtDateOnly: Date | null;
  LastModifiedBy: string | null;

  constructor(o: any = null) {
    super(o);
  }
}

export class MileageRateDtoCamel extends BaseModel {
  mileageRateID: number | null;
  jobID: string | null;
  rate: number;
  startDate: Date;
  endDate: Date | null;
  vpJobID: string;
  jobName: string;
  bActive: boolean;
  vpCompanyID: number | null;
  /** GUID */
  companyID: string | null;
  companyName: string | null;
  lastModifiedAt: Date | null;
  lastModifiedAtDateOnly: Date | null;
  lastModifiedBy: string | null;

  constructor(o: any = null) {
    super(o);
  }
}
