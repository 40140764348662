import { DynamicTypeaheadAutocompleteComponent } from './dynamic-typeahead-autocomplete/dynamic-typeahead-autocomplete.component';
import { DynamicTypeaheadCategoryComponent } from './dynamic-typeahead-category/dynamic-typeahead-category.component';
import { DynamicTypeaheadCompanyComponent } from './dynamic-typeahead-company/dynamic-typeahead-company.component';
import { DynamicTypeaheadCustomersComponent } from './dynamic-typeahead-customers/dynamic-typeahead-customers.component';
import { DynamicTypeaheadEmployeeComponent } from './dynamic-typeahead-employee/dynamic-typeahead-employee.component';
import { DynamicTypeaheadFacilityComponent } from './dynamic-typeahead-facility/dynamic-typeahead-facility.component';
import { DynamicTypeaheadIconsComponent } from './dynamic-typeahead-icons/dynamic-typeahead-icons.component';
import { DynamicTypeaheadJobComponent } from './dynamic-typeahead-job/dynamic-typeahead-job.component';

export const DYNAMIC_TYPEAHEADS = [
  DynamicTypeaheadAutocompleteComponent,
  DynamicTypeaheadCategoryComponent,
  DynamicTypeaheadCompanyComponent,
  DynamicTypeaheadCustomersComponent,
  DynamicTypeaheadEmployeeComponent,
  DynamicTypeaheadFacilityComponent,
  DynamicTypeaheadIconsComponent,
  DynamicTypeaheadJobComponent,
];

export * from './dynamic-typeahead-autocomplete/dynamic-typeahead-autocomplete.component';
export * from './dynamic-typeahead-category/dynamic-typeahead-category.component';
export * from './dynamic-typeahead-company/dynamic-typeahead-company.component';
export * from './dynamic-typeahead-customers/dynamic-typeahead-customers.component';
export * from './dynamic-typeahead-employee/dynamic-typeahead-employee.component';
export * from './dynamic-typeahead-facility/dynamic-typeahead-facility.component';
export * from './dynamic-typeahead-icons/dynamic-typeahead-icons.component';
export * from './dynamic-typeahead-job/dynamic-typeahead-job.component';
