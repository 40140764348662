import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { LogService, ProgressService } from '@ups/xplat/core';
import {
  DynamicRenderService,
  StopWorkAuthorityBaseComponent,
} from '@ups/xplat/features';

@Component({
  selector: 'ups-stop-work-authority',
  templateUrl: 'stop-work-authority.component.html',
})
export class StopWorkAuthorityComponent extends StopWorkAuthorityBaseComponent {
  constructor(
    store: Store,
    log: LogService,
    progress: ProgressService,
    dynamicRender: DynamicRenderService
  ) {
    super(store, log, progress, dynamicRender);
  }
}
