import { Component, ViewContainerRef } from '@angular/core';

import { AuthService } from '@auth0/auth0-angular';

import { ReleaseNoteService } from '@ups/xplat/core';

@Component({
  selector: 'ups-release-checker',
  template: `<ups-announcements-popup></ups-announcements-popup><ups-release-note-popup></ups-release-note-popup>`,
})
export class ReleaseNoteComponent {
  constructor(auth: AuthService, release: ReleaseNoteService) {
    auth.isAuthenticated$.subscribe((x) => {
      if (x) {
        release.onLogin();
      }
    });
  }
}
