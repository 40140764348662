<section class="mt-7">
  <div class="container-fluid gutter-30">
    <div class="row" style="max-width: 700px; margin: 0 auto">
      <div class="col-12">
        <kendo-card style="width: unset !important; display: grid">
          <kendo-card-body
            style="display: block; width: 100%; height: 100%; overflow: auto"
          >
            <div class="dynamic-field form-input text-align">
              <h3>Hazard Card</h3>
            </div>
            <hr />
            <ups-dynamic-container
              [dynamicGroup]="dynamicGroup"
            ></ups-dynamic-container>
          </kendo-card-body>
        </kendo-card>
      </div>
    </div>
  </div>
</section>
<div class="form-submitted-overlay" *ngIf="showFormSuccess">
  <div>
    <span class="k-icon k-i-check-circle"></span>
    <br />
    <span class="success">Success</span>
    <br />
    <br />
    Thank you, your Hazard Card has
    <br />
    successfully been submitted.
  </div>
</div>
