import { Directive } from '@angular/core';
import { of } from 'rxjs';
import { DynamicTypeaheadBaseComponent } from '../dynamic-typeahead/dynamic-typeahead.base-component';

@Directive()
export abstract class DynamicTypeaheadCategoryBaseComponent extends DynamicTypeaheadBaseComponent {
  /* eslint-disable */
  categoryOptions = [
    {
      Id: 9,
      Name: 'Confined Space',
    },
    {
      Id: 12,
      Name: 'Cranes',
    },
    {
      Id: 17,
      Name: 'Dropped Object',
    },
    {
      Id: 8,
      Name: 'Electrical - GFCI, Tools',
    },
    {
      Id: 13,
      Name: 'Equipment',
    },
    {
      Id: 3,
      Name: 'Facility Hazards',
    },
    {
      Id: 16,
      Name: 'Fall Protection',
    },
    {
      Id: 11,
      Name: 'Hot Work',
    },
    {
      Id: 6,
      Name: 'Line of Fire',
    },
    {
      Id: 4,
      Name: 'Lock Out / Tag Out',
    },
    {
      Id: 1,
      Name: 'Permit - JSA',
    },
    {
      Id: 5,
      Name: 'Personal Protective Equipment',
    },
    {
      Id: 2,
      Name: 'PHA - Work Practices',
    },
    {
      Id: 14,
      Name: 'Supplied Air',
    },
    {
      Id: 7,
      Name: 'Worker Understanding and Quality',
    },
    {
      Id: 10,
      Name: 'Working at Heights',
    },
    {
      Id: 15,
      Name: 'Other',
    },
  ];

  constructor() {
    super();
    this.searchQuery = (query?: string) =>
      of(
        this.categoryOptions.filter((c) => {
          if (query) {
            if (isNaN(+query)) {
              // query is not a number, match against Name
              return (
                c.Name && c.Name.toLowerCase().indexOf(query.toLowerCase()) > -1
              );
            } else {
              // query is a number, match against Id
              return c.Id && c.Id.toString() === query;
            }
          }
          return true;
        })
      );
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.config) {
      // with hard coded categoryOptions above, we hard code the valueProperty as well
      this.config.valueProperty = 'Id';
    }
  }
  /* eslint-enable */
}
