import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MyHttpClientFactory, ResponseCasingEnum } from '@ups/xplat/core';
import { DynamicTypeaheadWebBaseComponent } from '../base/dynamic-typeahead.web-base-component';

const SEARCH_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DynamicTypeaheadComponent),
  multi: true,
};

@Component({
  selector: 'ups-dynamic-typeahead',
  templateUrl: 'dynamic-typeahead.component.html',
  providers: [SEARCH_CONTROL_VALUE_ACCESSOR],
})
export class DynamicTypeaheadComponent
  extends DynamicTypeaheadWebBaseComponent
  implements OnInit
{
  protected http: HttpClient;

  constructor(private clientFactory: MyHttpClientFactory) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    if (
      // this.config?.options?.api?.host &&
      this.config?.options?.api?.endpoint
    ) {
      this.http = this.clientFactory.createHttpClient(
        this.config.options.api.host,
        true,
        ResponseCasingEnum.CamelCase
      );
      this.searchQuery = this.search.bind(this);
    }
  }

  search(searchString: string, params: HttpParams = null) {
    params = params || new HttpParams();
    params = params.append('searchString', searchString);
    return this.http.get(this.config.options.api.endpoint, {
      params: params,
    });
  }
}
