<div>
  <span *ngIf="!hasReadPermissions">
    You don't have any permissions to see the Equipment Job History
  </span>
  <kendo-grid
    id="jobHistoryGrid"
    *ngIf="hasReadPermissions"
    [data]="gridData"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [filter]="state.filter"
    [sortable]="true"
    [pageable]="true"
    [filterable]="'menu'"
    [loading]="isLoading"
    (dataStateChange)="dataStateChange($event)"
    class="rate-sheet transparent-around"
  >
    <kendo-grid-column field="VPJobId" title="Job ID" width="60">
      <ng-template kendoGridCellTemplate let-dataItem kendoTooltip>
        <label title="{{ dataItem.JobName }}">{{ dataItem.VPJobId }}</label>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="PlantName"
      title="Plant"
      width="120"
    ></kendo-grid-column>
    <kendo-grid-column
      field="StartDate"
      title="Start Date"
      width="50"
      filter="date"
      format="{0:MM/dd/yyyy}"
    ></kendo-grid-column>
    <kendo-grid-column
      field="EndDate"
      title="End Date"
      width="50"
      filter="date"
      format="{0:MM/dd/yyyy}"
    ></kendo-grid-column>
    <kendo-grid-column
      field="Amount"
      title="Amount"
      width="40"
      filter="numeric"
    ></kendo-grid-column>
    <kendo-grid-column field="Unit" title="Unit" width="60"></kendo-grid-column>
    <kendo-grid-column
      field="DispatchStatus"
      title="Dispatch Status"
      width="80"
    ></kendo-grid-column>
  </kendo-grid>
</div>
