import { BaseModel } from '../base.model';

export class Job2Dto extends BaseModel {
  public id: string;
  public vpJobId: string;
  public name: string;
  public isActive: boolean;
  public companyId: string;
  public vpCompanyId: number;
  public plantId: number;

  constructor(o: any = null) {
    super(o);
  }
}
