/* eslint-disable @typescript-eslint/naming-convention */
export enum CountSettingsEnum {
  Employee = 1,
  Onsite = 2,
  OnsiteDispatched = 3,

  Equipment = 4,
  EquipmentOnsite = 5,
  EquipmentOnsiteDispatched = 6,
}
