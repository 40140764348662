import { IMenuItem } from '@ups/common/menu';
import { SecurityConstants } from '@ups/security-constants';

export const menuConfig = [
  {
    path: 'inventory',
    title: 'Inventory',
    icon: 'fa-barcode',
  },
  {
    path: 'vendors',
    title: 'Vendors',
    icon: 'fa-chevron-down',
  },
  {
    path: 'customers',
    title: 'Customers',
    icon: 'fa-user',
  },
  {
    path: 'orders',
    title: 'Orders',
    icon: 'fa-inbox',
  },
  {
    path: 'backorders',
    title: 'Backorders',
    icon: 'fa-inbox-out',
  },
  {
    path: 'diagrams',
    title: 'Diagrams',
    icon: 'fa-sitemap',
  },
  {
    path: 'dock-log',
    title: 'Dock Log',
    icon: 'fa-boxes',
    visible: [SecurityConstants.nifty_materials_management_docklogpage],
  },
] as IMenuItem[];
