import { InjectionToken, Type } from '@angular/core';
import { IXPlatWindow } from '../models';

/**
 * Various InjectionTokens shared across all platforms
 * Always suffix with 'Token' for clarity and consistency
 */

export interface IPlatformDeviceInfo {
  // literal version identifier: usually used for api http headers
  version: string;
  // version label display (usually includes build number along with version)
  versionDisplay: string;
  // os type
  os: 'ios' | 'android' | 'web';
  // various device details
  deviceDetails: string;
}
export const PlatformDeviceInfoToken = new InjectionToken<IPlatformDeviceInfo>('PlatformDeviceInfo');
export const PlatformLanguageToken = new InjectionToken<string>('PlatformLanguageToken');
export const PlatformWindowToken = new InjectionToken<IXPlatWindow>('PlatformWindowToken');
export const RouterToken = new InjectionToken<any>('RouterToken');
