import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone, inject } from '@angular/core';
import {} from '@angular/common';
import { WindowService } from '@ups/xplat/core';
import { MessageHelper } from './web-message-helper.service';

@Injectable({
  providedIn: 'root',
})
export class WebHelperService {
  private _win = inject(WindowService);
  private _document = inject(DOCUMENT);
  private _msgHelper = inject(MessageHelper);
  // lazily loaded js scripts
  private _lazyLoadedScripts: {
    [key: string]: boolean;
  } = {};

  constructor() {
    // show any toast message on web
    this._win.showToast$.subscribe((message) => {
      this._msgHelper.success(message);
    });
  }

  detectMobileOS(): string {
    if (/(android)/i.test(this._win.navigator.userAgent)) {
      return 'android';
    } else if (/iPad|iPhone|iPod/.test(this._win.navigator.userAgent)) {
      return 'ios';
    } else {
      return '';
    }
  }

  loadScriptAsync(
    scriptPath: string,
    options?: {
      id?: string;
      attributes?: any;
      onload?: () => void;
    }
  ) {
    if (
      this._win.isBrowser &&
      scriptPath &&
      !this._lazyLoadedScripts[scriptPath]
    ) {
      // prevent loading scripts repeatedly
      this._lazyLoadedScripts[scriptPath] = true;
      const lazyScript = this._document.createElement('script');
      lazyScript.type = 'text/javascript';
      lazyScript.defer = true;
      lazyScript.src = '//' + scriptPath;
      // example:
      // lazyScript.src =
      //   ('https:' == document.location.protocol ? 'https://' : 'http://') +
      //   'www.paypalobjects.com/api/checkout.min.js';
      if (options) {
        if (options.id) {
          lazyScript.id = options.id;
        }
        if (options.attributes) {
          for (const key in options.attributes) {
            lazyScript.setAttribute(key, options.attributes[key]);
            // example:
            // lazyScript.setAttribute('data-version-4', 'data-version-4');
          }
        }
        if (options.onload) {
          lazyScript.onload = options.onload;
        }
      }
      const s = this._document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(lazyScript, s);
    }
  }
}
