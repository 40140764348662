import { Component, OnInit } from '@angular/core';

import { EditorComponentBase } from '../editor-component-base';

@Component({
  selector: 'editor-safety-at-risk',
  templateUrl: 'editor-safety-at-risk.html',
})
export class EditorSafetyAtRiskComponent extends EditorComponentBase implements OnInit {
  public peLabel = '';

  public ngOnInit() {
    if (this.data) {
      this.peLabel = this.data['label'];
    }
  }

  public onApplyClick() {
    const changesObj = {
      label: this.peLabel,
    };

    this.applyChanges.emit(changesObj);
  }
}
