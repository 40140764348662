import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'requirementFilter',
})
export class RequirementFilterPipe implements PipeTransform {
  transform(value: any, args: string): any {
    if (!args) return value;
    let filter = args.toLocaleLowerCase();
    return filter ? value.filter((customer) => customer.RequirementName.toLocaleLowerCase().indexOf(filter) !== -1) : value;
  }
}
