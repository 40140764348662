export function timecodeDisplay(
  seconds: number,
  includeDays?: boolean
): string {
  let days: any;
  let hr: any = Math.floor(seconds / 3600);
  let min: any = Math.floor((seconds - hr * 3600) / 60);
  let sec: any = Math.floor(seconds - hr * 3600 - min * 60);
  if (includeDays) {
    hr = Math.floor((seconds % (3600 * 24)) / 3600);
    min = Math.floor((seconds % 3600) / 60);
    sec = Math.floor(seconds % 60);
  }
  if (hr < 10) {
    hr = '0' + hr;
  }
  if (min < 10) {
    min = '0' + min;
  }
  if (sec < 10) {
    sec = '0' + sec;
  }
  if (includeDays) {
    days = Math.floor(seconds / 86400);
    if (days < 10) {
      days = '0' + days;
    }
  }

  if (includeDays) {
    return days + ':' + hr + ':' + min + ':' + sec;
  } else {
    return (hr === '00' ? '' : hr + ':') + min + ':' + sec;
  }
}
