import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map, filter } from 'rxjs/operators';

class EventBusArgs {
  type: string;
  data: unknown;
}

@Injectable({
  providedIn: 'root',
})
export class EventBusService {
  types = {
    // define all cross platform types here
    // each platform and even feature can define their own types
    headerUpdateTitle: 'headerUpdateTitle',
    profileUpdated: 'profileUpdated',
    toggleFullScreen: 'toggleFullScreen',
    updateBadgeTotal: 'updateBadgeTotal',
    updateTypeaheadData: 'updateTypeaheadData',
    filterTypeahead: 'filterTypeahead',
    typeaheadMakeSelection: 'typeaheadMakeSelection',
    logout: 'logout',
    updateInfoOverlay: 'updateInfoOverlay',
    replyingToComment: 'replyingToComment',
    cmpReadyEvent: 'cmpReadyEvent',
    notificationShowMessage: 'notificationShowMessage',
    landingPageSettled: 'landingPageSettled',
    swipeActionPerformed: 'swipeActionPerformed',
  };
  private _messages$ = new Subject<EventBusArgs>();

  emit(eventType: string, data?: unknown) {
    this._messages$.next({ type: eventType, data });
  }

  observe<T>(eventType: string) {
    return this._messages$.pipe(
      filter((args) => args.type === eventType),
      map((args) => <T>args.data)
    );
  }
}
