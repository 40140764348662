/*eslint-disable */
import { BaseModel } from '../base.model';
import {
  AddressModel,
  AddressModelPascal,
  CertificationModel,
  SafetyModel,
} from '../sparta-api';
import { MPRModel } from './mpr-model';

export class ProfileModel extends BaseModel {
  IsDirty: boolean;
  FirstName: string = '';
  LastName: string = '';
  MiddleName: string = '';
  NickName: string = '';
  Suffix: string = '';
  SSN: string = '';
  HireDate: Date;
  SeniorityDate: Date;
  TermDate: Date;
  BeneEligDate: Date;
  LicExpDate: Date;
  LicNumber: string = '';
  LicCountry: string = '';
  LicClass: string = '';
  udPassportNum: string = '';
  udPassExDate: string = '';
  NonResAlienYN: string = '';
  StdClass: string = '';
  HRRef: string = '';
  PRCo: string = '';
  OriginalPRCo: string = '';
  PREmp: string = '';
  FullAddress: string = '';
  FullAddress2: string = '';
  City: string = '';
  State: string = '';
  Zip: string = '';
  Phone: string = '';
  CellPhone: string = '';
  AltContactPhone: string = '';
  Email: string = '';
  udUPSEmail: string = '';
  MailingAddress: AddressModelPascal = new AddressModelPascal();
  PhysicalAddress: AddressModelPascal = new AddressModelPascal();
  // PendingAddress: PendingAddressModel = new PendingAddressModel();
  positions: string[] = [];
  certifications: CertificationModel[] = [];
  safetyItems: SafetyModel[] = [];
  // fitItems: FitModel[] = [];
  safetyInstitutionNames: string[] = [];
  safetyClassNames: string[] = [];
  qcItems: BaseModel[] = [];
  operationalDocuments: any[] = [];
  documents: any = [];
  documentsComments: string;
  mpr: MPRModel[] = [];
  EmployeeId: string;
  StatusExp: any;
  FileCraft: string;
  FileClass: string;
  Auth0UserId: string;
  ProspectID: string;
  employeeId?: string;
  auth0UserId?: string;
  payCard: string;
  adpPayCard: string;

  /*
    driversLicenseFD: FileDataModel[];
    birthCertificateFD: FileDataModel[];
    ssnFD: FileDataModel[];
    idCardFD: FileDataModel[];
    passportFD: FileDataModel[];
    */

  idDocuments: any[] = [];

  PreferredSafetyCouncil: string = '';
  IsHRAdmin: boolean;
  spartaProfile: any;
  Position: string = '';
  I9ReviewDate: Date;
  BirthDate: Date;

  ActiveYN: string = '';

  TermReason: string = '';

  PrehTermReason: string = '';
  PrehTermDate: Date;

  NoRehireYN: string = '';
  Status: string = '';
  RehireStatusName: string = '';

  G40: string = ''; // YN
  G40Mgr: string = '';
  HomeLocal: string = '';
  Core: string = ''; // YN
  SponsoredSupv: string = '';
  ATF: string = ''; // YN
  BeneElig: string = ''; // YN

  EmployeeType: string = '';
  JobCategory: number;
  JobCategoryName: string = '';
  LTDClass: number;
  StartVacHrs: number;
  PhoneAllowance: number;
  AutoAllowance: number;
  CoMastercard: string = ''; // YN
  FuelCard: string = ''; // YN
  CompanyVehicle: string = ''; // YN

  LicType: any;
  LicState: any;
  I9Status: any;

  TWICID: string;
  TWICIssueDate: Date;
  TWICExpireDate: Date;
  EmergencyPrimaryName: string;
  EmergencyPrimaryPhone: string;
  EmergencySecName: string;
  EmergencySecPhone: string;

  constructor(o: any = null) {
    super(o);
    if (o) {
      Object.assign(this, o);

      this.MailingAddress = new AddressModelPascal(this.MailingAddress);
      this.PhysicalAddress = new AddressModelPascal(this.PhysicalAddress);
      // this.PendingAddress = new PendingAddressModel(this.PendingAddress);
    }
  }

  isDirty: boolean;
  firstName: string = '';
  lastName: string = '';
  middleName: string = '';
  nickName: string = '';
  suffix: string = '';
  ssn: string = '';
  hireDate: Date;
  seniorityDate: Date;
  termDate: Date;
  expiryDate: Date;
  beneEligDate: Date;
  licExpDate: Date;
  licNumber: string = '';
  licCountry: string = '';
  licClass: string = '';
  nonResAlienYN: string = '';
  stdClass: string = '';
  hrRef: string = '';
  prCo: string = '';
  originalPRCo: string = '';
  prEmp: string = '';
  fullAddress: string = '';
  fullAddress2: string = '';
  city: string = '';
  state: string = '';
  zip: string = '';
  phone: string = '';
  cellPhone: string = '';
  altContactPhone: string = '';
  email: string = '';

  // tmp: may want to handling this differently
  // mailingAddress: AddressModel = new AddressModel();
  // physicalAddress: AddressModel = new AddressModel();
  // // pendingAddress: PendingAddressModel = new PendingAddressModel();
  // certifications: CertificationModel[] = [];

  mailingAddress: AddressModel;
  physicalAddress: AddressModel;

  // safetyItems: SafetyModel[] = [];
  // fitItems: FitModel[] = [];
  statusExp: any;
  fileCraft: string;
  fileClass: string;
  prospectID: string;

  preferredSafetyCouncil: string = '';
  isHRAdmin: boolean;
  position: string = '';
  i9ReviewDate: Date;
  birthDate: Date;

  activeYN: string = '';

  termReason: string = '';

  prehTermReason: string = '';
  prehTermDate: Date;

  noRehireYN: string = '';
  status: string = '';
  rehireStatusName: string = '';

  g40: string = ''; // YN
  g40Mgr: string = '';
  homeLocal: string = '';
  core: string = ''; // YN
  sponsoredSupv: string = '';
  atf: string = ''; // YN
  beneElig: string = ''; // YN
  wap: string = ''; // YN

  employeeType: string = '';
  jobCategory: number;
  jobCategoryName: string = '';
  ltdClass: number;
  startVacHrs: number;
  phoneAllowance: number;
  autoAllowance: number;
  coMastercard: string = ''; // YN
  fuelCard: string = ''; // YN
  companyVehicle: string = ''; // YN

  licType: any;
  licState: any;
  i9Status: any;
  i9Type?: any;

  twicid: string;
  twicIssueDate: Date;
  twicExpireDate: Date;
  emergencyPrimaryName: string;
  emergencyPrimaryPhone: string;
  emergencySecName: string;
  emergencySecPhone: string;

  last4ssn: string = '';
  fullName() {
    const suffix = this.suffix == null ? '' : ` ${this.suffix}`;
    const middleName = this.middleName == null ? '' : ` ${this.middleName}`;
    return `${this.lastName}${suffix}, ${this.firstName}${middleName}`;
  }

  destroy(): void {
    this.certifications = [];
    this.safetyItems = [];
    this.safetyInstitutionNames = [];
    this.safetyClassNames = [];
    this.qcItems = [];
    this.operationalDocuments = [];
  }
}

/* eslint-enable */
