import { Component, OnInit } from '@angular/core';

import { EditorComponentBase } from '../editor-component-base';

@Component({
  selector: 'editor-form-button',
  templateUrl: 'editor-form-button.html',
})
export class EditorFormButtonComponent extends EditorComponentBase implements OnInit {
  public peButtonContent = '';
  public pePrimary = false;

  public ngOnInit() {
    if (this.data) {
      this.peButtonContent = this.data['content'];
      this.pePrimary = 'true' === this.data['primary'];
    }
  }

  public onApplyClick() {
    const changesObj = {
      content: this.peButtonContent,
      primary: String(this.pePrimary),
    };
    this.applyChanges.emit(changesObj);
  }
}
