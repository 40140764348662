import {
  Input,
  ViewContainerRef,
  ComponentRef,
  Directive,
  inject,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PlatformDynamicComponentsToken } from '../tokens';
import { DynComponentMapping } from '../mapping-types';
import { DynamicRenderService } from '../services/dynamic-render.service';
import { IDynamicModel } from '@ups/xplat/core';
import { Store } from '@ngrx/store';
import { UserState } from '@ups/user';
import { combineLatest, take } from 'rxjs';

/* eslint-disable */
@Directive({
  selector: '[dynamicItem]',
})
export class DynamicItemDirective {
  dynamicService = inject(DynamicRenderService);
  vcRef = inject(ViewContainerRef);
  // represented as Array because DI token has multi: true set with the provider
  dynComponentMappings = inject<Array<DynComponentMapping>>(
    PlatformDynamicComponentsToken
  );
  store = inject(Store);
  //@Input() config: DrDisplayControlDto;
  @Input() config: IDynamicModel;
  @Input() group: UntypedFormGroup;
  @Input() index: number;
  @Input() disableItem: boolean;

  component: ComponentRef<{
    config: IDynamicModel;
    group: UntypedFormGroup;
    dynamicService: DynamicRenderService;
  }>;

  ngOnInit() {
    // console.log('dynComponentMapping:', this.dynComponentMappings);
    let component;
    for (const dynComponentMapping of this.dynComponentMappings) {
      component = dynComponentMapping[this.config.type];
      if (component) {
        break;
      }
    }
    // console.log('control roles:', this.config);
    if (
      this.dynamicService.activeFormResponse &&
      this.config.accessRoleIds &&
      !this.config.isFormBuilder
    ) {
      combineLatest([
        this.store.select(UserState.selectSecurityRoles),
        this.store.select(UserState.selectCurrent),
      ])
        .pipe(take(1))
        .subscribe(([roles, info]) => {
          const userRoles = roles?.map((r) => r.roleId);
          const canAccess =
            this.config.accessRoleIds
              .split(',')
              .some((controlRoleId) =>
                userRoles?.includes(controlRoleId.toLowerCase())
              ) ||
            info.UserId === this.dynamicService.activeFormResponse.createdBy;
          if (!canAccess) {
            // console.log('RESTRICTED! ', this.config.formControlName)
            component = this.dynComponentMappings[0]['redacted'];
          }
        });
    }
    if (component) {
      this.component = this.vcRef.createComponent(component);

      this.component.instance.config = this.config;
      this.component.instance.group = this.group;
      this.component.instance.dynamicService = this.dynamicService;
    } else {
      console.error(
        `Component for ControlType '${this.config.type}' not found. Most likely a developer needs to implement it for this platform.`
      );
    }
  }

  ngOnChanges() {
    if (this.component) {
      this.component.instance.config = this.config;
      this.component.instance.group = this.group;
      this.component.instance.dynamicService = this.dynamicService;
    }
  }
}
