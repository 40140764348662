import { IMCSOptions } from '../mcs.settings.options';
import {
  IMCSSearchColumnConfig,
  MCSSearchColumnUtils,
} from '../mcs.settings.search-column';
import { IMCSOrderConfig, MCSOrderUtils } from '../mcs.settings.order';
import { SortDescriptor } from '@progress/kendo-data-query';

export class Process {
  /**
   * Function will use pure TS (and lodash) to do search over in-memory allData[].
   *
   *  Params:
   *      allData:    array of in-memory items
   *      search:     the search string (search phrases ~ words delimited by a delimiter) to search for
   *
   *  Returns:
   *      filtered items
   */
  public static filter(
    searchOptions: IMCSOptions,
    searchColumnConfigs: IMCSSearchColumnConfig[],
    orderConfigs: IMCSOrderConfig[],
    allData: any[],
    search: string
  ): any[] {
    // NOTE: we'd use the process from the kendo query as well, although how they handle case insensivity, we'd need to make a toLowerCase call to the filter itself...

    const self = this;

    if (!allData) throw Error('No local data provided to search in...');

    // get queqy phrases (words)
    const queryStringWords = (search || '')
      .split(searchOptions.wordSplitter)
      .filter((i) => ((i || '').trim() ? true : false));

    if (!queryStringWords.length) return allData;

    // the filtering syntax:
    // ---------------------
    // w1 in c1 *columnJoin* w1 in c2 *columnJoin* ... *columnJoin* w1 in cn
    // *expressionJoin*
    // w2 in c2 *columnJoin* ...
    // ..
    // wn in c1 *columnJoin* ... wn in cn

    //
    // algorithm:
    //
    // get an array based on a single word and all columns processing (filtering)
    // if we need to match ALL words, we continue to reduce down this result further
    // if we need to match ANY word, we do a union
    // NOTE: due to the union, we need to remove duplicates https://codehandbook.org/how-to-remove-duplicates-from-javascript-array/

    let previousResult = allData;

    const wordResultArray = queryStringWords.map((word) => {
      // skip empty words
      if ((word || '').trim() === '') return;

      // init array we will do search on
      const arrayToSearchIn = searchOptions.matchAllWords
        ? previousResult // need to use previous results because we use *expressionJoin* = AND and we do narrow down
        : allData; // need to use all items because we use *expressionJoin* = OR

      // search word in one/all columns
      const searchResult = arrayToSearchIn.filter((i) => {
        return searchOptions.matchInEveryColumn
          ? searchColumnConfigs.every((scc) => {
              return self.isMatch(
                self.getValue(i, scc.field),
                word,
                scc.matchStartsWith,
                scc.matchCaseInsensitive
              );
            }) // every
          : searchColumnConfigs.some((scc) => {
              return self.isMatch(
                self.getValue(i, scc.field),
                word,
                scc.matchStartsWith,
                scc.matchCaseInsensitive
              );
            }); // some
      });

      previousResult = searchResult;

      return searchResult;
    });

    // merge word expressions
    let filteredResult;

    if (searchOptions.matchAllWords) {
      filteredResult = previousResult; // which is also the last array in the wordResultArray
    } else {
      // wordResultArray contains result for each word, so it's an array of array
      // eslint-disable-next-line prefer-spread
      const mergedItemsArray = [].concat.apply([], wordResultArray);
      filteredResult = Array.from(new Set(mergedItemsArray));
    }

    // do sort
    const sortColumns = [];
    const sortOrders = [];

    orderConfigs.forEach((oc) => {
      sortColumns.push(oc.field);
      sortOrders.push(oc.dir);
    });

    const result = filteredResult.sort((a, b) => {
      for (let i = 0; i < sortColumns.length; i++) {
        const sortColumn = sortColumns[i];
        const sortOrder = sortOrders[i];
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];
        if (sortOrder === 'asc') {
          if (aValue > bValue) {
            return 1;
          }
          if (aValue < bValue) {
            return -1;
          }
        } else {
          if (aValue > bValue) {
            return -1;
          }
          if (aValue < bValue) {
            return 1;
          }
        }
      }
      return 0;
    });

    // return sorted result
    return result;
  }

  protected static getValue(obj: any, propertySelector: string): any {
    /*
        // NOTE: simple solution with prop1.prop2...propN - no support for arrays...
        var memberNames = propertySelector.split('.')
        var value = obj;
        for (let member of memberNames) {
            value = value[member]
        }
        */

    // NOTE: alternative way to eval("obj." + propertySelector);
    const value = new Function('obj', 'return obj.' + propertySelector)(obj);

    return value;
  }

  protected static isMatch(
    value,
    search,
    matchStartsWith,
    matchCaseInsensitive
  ): boolean {
    const index = matchCaseInsensitive
      ? (value || '')
          .toString()
          .toLowerCase()
          .indexOf((search || '').toString().toLowerCase())
      : (value || '').toString().indexOf(search || '');

    return matchStartsWith ? index === 0 : index >= 0;
  }
}
