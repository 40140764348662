import { BaseModel } from '../../base.model';
import { Address } from './Address';

export class Company extends BaseModel {
  CompanyNumber: number;
  CompanyName: string;
  CompanyAddress: Address;
  IsActive: boolean;

  constructor(o: any = null) {
    super(o);
  }
}
