/**
 * These values reflect values in RequestStatus SpartaDB table.
 * There is also a SERVER SIDE of this enumeration with the same name in Sparta solution.
 * When updating this update the server side version, as well as the InitializationValues_RequestStatus.sql script.
 */
export enum RequestStatusEnum {
  Requested = 1,
  Declined = 2,
  Approved = 3,
  Cancelled = 4,
}
