import { Component, Input, OnChanges } from '@angular/core';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import { EquipmentResourceDto, IODataResult } from '@ups/xplat/api/dto';
import { BaseComponent } from '@ups/xplat/core';
import { nameOf } from '@ups/xplat/utils';
import { EquipmentVpService } from '@ups/xplat/web/core';
import { EmployeeGuardService } from '@ups/xplat/base/employee-profile';

@Component({
  selector: 'ups-equipment-meters',
  templateUrl: './equipment-meters.component.html',
  styleUrls: ['./equipment-meters.component.scss'],
})
export class EquipmentMetersComponent
  extends BaseComponent
  implements OnChanges
{
  @Input() keyId: number;
  equipmentResource: EquipmentResourceDto;
  isEditMode = false;
  hasReadPermissions = false;
  hasEditPermissions = false;

  constructor(
    private security: SecurityService,
    private equipmentVpService: EquipmentVpService,
    private guardService: EmployeeGuardService
  ) {
    super();
    this.hasReadPermissions = this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_meterstab
    ).readAll;
    this.hasEditPermissions = this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_meterstab
    ).editAll;
  }

  ngOnChanges() {
    this.equipmentResource = null;
    this.loadData();
  }

  editEquipment() {
    if (this.hasEditPermissions) {
      this.isEditMode = true;
      this.guardService.isToUseGuard = true;
    }
  }

  cancelEquipment() {
    this.equipmentResource = null;
    this.isEditMode = false;
    this.guardService.isToUseGuard = false;
    this.loadData();
  }

  saveEquipment(isFormValid: boolean) {
    if (!isFormValid) {
      window.alert('Some fields in the form are not filled properly.');
      return;
    }

    if (this.equipmentResource) {
      // AutoCorrect items
      this.equipmentResource.HourReading =
        this.equipmentResource.HourReading === null ||
        this.equipmentResource.HourReading < 0
          ? 0
          : this.equipmentResource.HourReading; // In DB the column does not allow nulls
      this.equipmentResource.ReplacedHourReading =
        this.equipmentResource.ReplacedHourReading === null ||
        this.equipmentResource.ReplacedHourReading < 0
          ? 0
          : this.equipmentResource.ReplacedHourReading; // In DB the column does not allow nulls
      this.equipmentResource.OdometerReading =
        this.equipmentResource.OdometerReading === null ||
        this.equipmentResource.OdometerReading < 0
          ? 0
          : this.equipmentResource.OdometerReading; // In DB the column does not allow nulls
      this.equipmentResource.ReplacedOdometerReading =
        this.equipmentResource.ReplacedOdometerReading === null ||
        this.equipmentResource.ReplacedOdometerReading < 0
          ? 0
          : this.equipmentResource.ReplacedOdometerReading; // In DB the column does not allow nulls

      const equipmentResourceToSave = this.equipmentResource;

      this.equipmentResource = null; // Assign null so that Loading ... message appears.

      this.equipmentVpService
        .updateEquipmentResource(equipmentResourceToSave, [
          nameOf<EquipmentResourceDto>('HourReading'),
          nameOf<EquipmentResourceDto>('HourDate'),
          nameOf<EquipmentResourceDto>('ReplacedHourReading'),
          nameOf<EquipmentResourceDto>('ReplacedHourDate'),
          nameOf<EquipmentResourceDto>('OdometerReading'),
          nameOf<EquipmentResourceDto>('OdometerDate'),
          nameOf<EquipmentResourceDto>('ReplacedOdometerReading'),
          nameOf<EquipmentResourceDto>('ReplacedOdometerDate'),
        ])
        .subscribe(
          () => {
            this.isEditMode = false;
            this.guardService.isToUseGuard = false;
            this.loadData();
          },
          (error) => {
            console.error(error);
            this.equipmentResource = equipmentResourceToSave;
            throw error;
          }
        );
    }
  }

  loadData() {
    this.equipmentVpService
      .queryEquipmentResources(null, null, this.keyId)
      .subscribe(
        (result: IODataResult<EquipmentResourceDto>) => {
          if (result.value && result.value.length) {
            this.equipmentResource = result.value[0];
          } else {
            this.equipmentResource = null;
          }
        },
        (error) => {
          console.error(error);
          this.equipmentResource = null;
          throw error;
        }
      );
  }
}
