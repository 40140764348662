import { SecurityFeatureModel } from './security-feature-model';

/**
 * Previous name was SecurityModel.
 */
export class SecurityFeatureGroupModel {
  featureGroupName = '';
  features: SecurityFeatureModel[] = [];

  constructor(o: any = null) {
    if (o) Object.assign(this, o);

    // handle nested objects
    for (let i = 0; i < this.features.length; i++) {
      const thisFeature = this.features[i];

      // fix guid as string casing issues:
      thisFeature.featureId = thisFeature.featureId.toLowerCase();

      // add extra properties (that are not coming from API but somehow made it here from the old code and due the global featureById list we should have them filled)
      thisFeature.featureGroupName = this.featureGroupName;

      // convert to typed data
      const typedFeature = new SecurityFeatureModel(thisFeature);
      this.features[i] = typedFeature;
    }
  }

  public getFeatureById(id: string): SecurityFeatureModel {
    id = (id || '').toLowerCase();

    const featureById = this.features.find((feature) => feature.featureId === id);
    if (featureById) return featureById;

    return new SecurityFeatureModel();
  }
}
