import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Checks if the value contains only whitespace.
 * @param required - if true it will also validate against an empty value - so the value cannot be empty string as well as noWhitespace only- it is required
 */
export function NoWhitespaceValidator(required: boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let originalValue = (control.value || '').toString();

    // Empty value is ok if the field is not required
    if (!required && originalValue.length === 0) {
      return null;
    }

    let isWhitespace = originalValue.trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { whitespace: 'Value is required and cannot be empty' };
  };
}
