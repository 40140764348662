import { Component, Input, OnChanges } from '@angular/core';

import { EquipmentResourceDto, IODataResult } from '@ups/xplat/api/dto';
import { EquipmentVpService } from '@ups/xplat/web/core';
import { nameOf } from '@ups/xplat/utils';
import { BaseComponent } from '@ups/xplat/core';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import { EmployeeGuardService } from '@ups/xplat/base/employee-profile';

@Component({
  selector: 'ups-equipment-ownership-info',
  templateUrl: './equipment-ownership-info.component.html',
  styleUrls: ['./equipment-ownership-info.component.scss'],
})
export class EquipmentOwnershipInfoComponent
  extends BaseComponent
  implements OnChanges
{
  @Input() keyId: number;
  equipmentResource;
  isEditMode = false;
  hasReadPermissions = false;
  hasEditPermissions = false;

  constructor(
    private security: SecurityService,
    private equipmentVpService: EquipmentVpService,
    private guardService: EmployeeGuardService
  ) {
    super();
    this.hasReadPermissions = this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_ownershipinfotab
    ).readAll;
    this.hasEditPermissions = this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_ownershipinfotab
    ).editAll;
  }

  ngOnChanges() {
    this.equipmentResource = null;
    this.loadData();
  }

  editEquipment() {
    if (this.hasEditPermissions) {
      this.isEditMode = true;
      this.guardService.isToUseGuard = true;
    }
  }

  cancelEquipment() {
    this.equipmentResource = null;
    this.isEditMode = false;
    this.guardService.isToUseGuard = false;
    this.loadData();
  }

  saveEquipment(isFormValid: boolean) {
    if (this.equipmentResource) {
      if (!isFormValid) {
        window.alert('Some fields in the form are not filled properly.');
        return;
      }

      // AutoCorrect items
      this.equipmentResource.PurchasePrice =
        this.equipmentResource.PurchasePrice === null
          ? 0
          : this.equipmentResource.PurchasePrice; // In DB the column does not allow nulls

      const equipmentResourceToSave = this.equipmentResource;

      this.equipmentResource = null; // Assign null so that Loading ... message appears.

      this.equipmentVpService
        .updateEquipmentResource(equipmentResourceToSave, [
          nameOf<EquipmentResourceDto>('PurchaseDate'),
          nameOf<EquipmentResourceDto>('PurchasedFrom'),
          nameOf<EquipmentResourceDto>('PurchasePrice'),
        ])
        .subscribe(
          () => {
            this.isEditMode = false;
            this.guardService.isToUseGuard = false;
            this.loadData();
          },
          (error) => {
            console.error(error);
            this.equipmentResource = equipmentResourceToSave;
            throw error;
          }
        );
    }
  }

  loadData() {
    this.equipmentVpService
      .queryEquipmentResources(null, null, this.keyId)
      .subscribe(
        (result: IODataResult<EquipmentResourceDto>) => {
          if (result.value && result.value.length) {
            this.equipmentResource = result.value[0];
          } else {
            this.equipmentResource = null;
          }
        },
        (error) => {
          console.error(error);
          this.equipmentResource = null;
          throw error;
        }
      );
  }
}
