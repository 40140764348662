<section class="mt-7">
  <div class="container-fluid gutter-30">
    <div class="row" style="max-width: 700px; margin: 0 auto">
      <div class="col-12">
        <kendo-card style="width: unset !important; display: grid">
          <kendo-card-body
            style="display: block; width: 100%; height: 100%; overflow: auto"
          >
            <div class="dynamic-field form-input text-align">
              <h3 class="d-inline">
                {{
                  container
                    ? container.dynamicContainerName
                    : dynamicRender.metadata?.dynamicContainerName
                }}
              </h3>
              <small *ngIf="options?.shortName" class="px-5 opacity-50">
                ({{ options?.shortName }})
              </small>
            </div>
            <div
              *ngIf="dynamicRender.metadata?.dynamicContainerDescription"
              class="dynamic-field form-input text-align"
            >
              <h5>
                {{
                  container
                    ? container.dynamicContainerDescription
                    : dynamicRender.metadata?.dynamicContainerDescription
                }}
              </h5>
            </div>
            <div
              *ngIf="showWhatsNew"
              class="dynamic-field form-input text-align"
              style="margin: 5px 0 18px"
            >
              <h5 style="font-style: italic">What's New</h5>
              <div>
                {{
                  container
                    ? container.dynamicContainerWhatsNew
                    : dynamicRender.metadata?.dynamicContainerWhatsNew
                }}
              </div>
            </div>
            <hr />
            <ups-dynamic-container
              [dynamicGroup]="dynamicGroup"
            ></ups-dynamic-container>
          </kendo-card-body>
        </kendo-card>
      </div>
    </div>
  </div>
</section>
<div class="form-submitted-overlay" *ngIf="showFormSuccess">
  <div>
    <span class="k-icon k-i-check-circle"></span>
    <br />
    <span class="success">Success</span>
    <br />
    <br />
    Thank you, your {{ dynamicRender.metadata?.dynamicContainerName }}
    <br />
    {{ showFormSuccessMessage }}
  </div>
</div>
