import {
  AbstractControl,
  AsyncValidatorFn,
  Validator,
  Validators,
  ValidatorFn,
  ValidationErrors,
} from '@angular/forms';

import { Observable, of } from 'rxjs';

export type ValidationResult = { [validator: string]: string | boolean };

export type AsyncValidatorArray = Array<Function | Validator>;

export type ValidatorArray = Array<Function | Validator>;

const normalizeValidator = (
  validator: Validator | ValidatorFn
): ValidatorFn | AsyncValidatorFn => {
  const func = (validator as Validator).validate.bind(validator);
  if (typeof func === 'function') {
    return (c: AbstractControl) => func(c);
  } else {
    return validator as ValidatorFn | AsyncValidatorFn;
  }
};

export const composeValidators = (
  validators: ValidatorArray
): AsyncValidatorFn | ValidatorFn => {
  if (validators == null || validators.length === 0) {
    return null;
  }
  return Validators.compose(validators.map(normalizeValidator));
};

export const validate = (
  validators: ValidatorArray,
  asyncValidators: AsyncValidatorArray
) => {
  return (control: AbstractControl): Observable<ValidationErrors> => {
    const synchronousValid = () => composeValidators(validators)(control);

    if (asyncValidators) {
      const asyncValidator = composeValidators(asyncValidators);

      return of(asyncValidator(control));
      // .map((v) => {
      //   const secondary = synchronousValid();
      //   if (secondary || v) {
      //     // compose async and sync validator results
      //     return Object.assign({}, secondary, v);
      //   }
      // });
    }

    if (validators) {
      return of(synchronousValid());
    }

    return of(null);
  };
};

export const message = (validator: ValidationResult, key: string): string => {
  switch (key) {
    case 'required':
      return 'Please enter a value';
    case 'pattern':
      return 'Value does not match required pattern';
    case 'minlength':
      return 'Value must be N characters';
    case 'maxlength':
      return 'Value must be a maximum of N characters';
  }

  switch (typeof validator[key]) {
    case 'string':
      return validator[key] as string;
    default:
      return `Validation failed: ${key}`;
  }
};
