export enum UpsMessageTypeEnum {
  Info = 0x01,
  Success = 0x02,
  Warning = 0x04,
  Error = 0x08,

  Primary = 0x10,
  Secondary = 0x20,

  Light = 0x40,
  Dark = 0x80,
}

export class UpsMessageObject {
  id: number;
  type: UpsMessageTypeEnum;
  message: string;
  isHtml: boolean;
  expireDate: Date;

  constructor(id: number, type: UpsMessageTypeEnum, message: string, timeout: number, isHtml: boolean) {
    this.id = id;
    this.type = type;
    this.message = message;
    this.isHtml = isHtml;
    this.expireDate = new Date(Date.now() + timeout);
  }
}
