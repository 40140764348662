<kendo-card class="w-100">
  <kendo-card-header>
    <div class="row" *ngIf="title || menu || showCollapse || showClose">
      <div class="col-6">
        <h5><ng-container *ngTemplateOutlet="title"></ng-container></h5>
      </div>
      <div class="col-6">
        <i *ngIf="menu" (click)="toggleMenu($event)" class="fas fa-ellipsis-v">
          <span #anchor></span>
          <kendo-popup
            *ngIf="showMenu"
            [anchor]="anchor"
            [anchorAlign]="{ horizontal: 'right', vertical: 'bottom' }"
            [popupAlign]="{ horizontal: 'right', vertical: 'top' }"
            (anchorViewportLeave)="showMenu = false"
          >
            <ng-container *ngTemplateOutlet="menu"></ng-container>
          </kendo-popup>
        </i>
        <i
          *ngIf="showCollapse"
          (click)="collapseClick($event)"
          class="fas"
          [class.fa-angle-up]="!isCollapsed"
          [class.fa-angle-down]="isCollapsed"
        ></i>
        <i
          *ngIf="showClose"
          (click)="closeClick($event)"
          class="fas fa-times"
        ></i>
      </div>
    </div>
  </kendo-card-header>
  <kendo-card-body *ngIf="!isCollapsed">
    <ng-container *ngTemplateOutlet="body"></ng-container>
  </kendo-card-body>
</kendo-card>
