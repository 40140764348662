import { BaseModel } from '../../base.model';

export class EquipmentTypeToRequirementDto extends BaseModel {
  public EquipmentRequirementID: string;
  public RequirementName: string;
  public bActive: boolean;

  constructor(o: any = null) {
    super(o);
  }
}
