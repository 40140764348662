<kendo-dialog modal fade [hidden]="!opened" (close)="close()">
  <kendo-dialog-titlebar>
    <span>Equipment Dispatch History</span>
  </kendo-dialog-titlebar>

  <div>
    <kendo-grid [data]="historyData">
      <kendo-grid-column title="Equipment" field="EquipmentName" width="160">
        <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
          <span
            *ngIf="!dataItem.EquipmentKeyID || !canSeeEquipmentHyperlink"
            title="{{ dataItem.EquipmentName }}"
          >
            {{ dataItem.EquipmentName }}
          </span>
          <a
            *ngIf="dataItem.EquipmentKeyID && canSeeEquipmentHyperlink"
            target="_blank"
            [routerLink]="[
              '/resources/equipment-page',
              dataItem.EquipmentKeyID
            ]"
          >
            <span
              class="btn-link"
              title="Click to open the equipment in a new browser tab."
            >
              {{ dataItem.EquipmentName }}
            </span>
          </a>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">{{ column.title }}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Alt ID" field="AltID" width="100">
        <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
          <span title="{{ dataItem.AltID }}">{{ dataItem.AltID }}</span>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">{{ column.title }}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Customer ID" field="CustomerID" width="100">
        <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
          <span title="{{ dataItem.CustomerID }}">
            {{ dataItem.CustomerID }}
          </span>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">{{ column.title }}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        title="Start Date"
        field="StartDate"
        filter="date"
        type="date"
        format="{0:d}"
        width="95"
      >
        <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
          <span title="{{ dataItem.StartDate | date: 'MM/dd/yyyy' }}">
            {{ dataItem.StartDate | date: 'MM/dd/yyyy' }}
          </span>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">{{ column.title }}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        title="End Date"
        field="EndDate"
        filter="date"
        type="date"
        format="{0:d}"
        width="95"
      >
        <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
          <span title="{{ dataItem.EndDate | date: 'MM/dd/yyyy' }}">
            {{ dataItem.EndDate | date: 'MM/dd/yyyy' }}
          </span>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">{{ column.title }}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        title="Note"
        field="Note"
        width="120"
        [groupable]="false"
      >
        <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
          <span title="{{ dataItem.Note }}">{{ dataItem.Note }}</span>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">{{ column.title }}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        title="Modified"
        field="DateModified"
        filter="date"
        type="date"
        format="{0:d}"
        width="120"
      >
        <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
          <span
            title="{{ dataItem.DateModified | date: 'MM/dd/yyyy hh:mm aaa' }}"
          >
            {{ dataItem.DateModified | date: 'MM/dd/yyyy hh:mm aaa' }}
          </span>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">{{ column.title }}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Modified By" field="ModifiedBy" width="120">
        <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
          <span title="{{ dataItem.ModifiedBy }}">
            {{ dataItem.ModifiedBy }}
          </span>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">{{ column.title }}</span>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>

  <kendo-dialog-actions>
    <button class="k-button" (click)="close()">Close</button>
  </kendo-dialog-actions>
</kendo-dialog>
