import { DrAdminMapResolverService } from './dr-admin-map-resolver.service';
import { DrAdminService } from './dr-admin.service';
import { DrDtoConverterService } from './dr-dto-converter.service';

export const ADMIN_SERVICES = [
  DrAdminMapResolverService,
  DrAdminService,
  DrDtoConverterService,
];

export * from './dr-admin-map-resolver.service';
export * from './dr-admin.service';
export * from './dr-dto-converter.service';
