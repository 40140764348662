<div>
  <span *ngIf="!hasReadPermissions">
    You don't have any permissions to see the Equipment Location History
  </span>
  <kendo-grid
    id="equipmentLocationHistoryGridId"
    *ngIf="hasReadPermissions"
    [data]="gridData"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [filter]="state.filter"
    [sortable]="true"
    [pageable]="true"
    [filterable]="'menu'"
    [loading]="isLoading"
    (dataStateChange)="dataStateChange($event)"
    class="rate-sheet transparent-around high-header"
  >
    <kendo-grid-column
      field="LocationHistoryId"
      title="ID"
      width="60"
      filter="numeric"
    ></kendo-grid-column>
    <kendo-grid-column
      field="EMCo"
      title="EMCo"
      width="90"
      filter="numeric"
    ></kendo-grid-column>
    <kendo-grid-column
      field="Equipment"
      title="Equipment"
      width="120"
    ></kendo-grid-column>
    <kendo-grid-column field="DateIn" title="Date In" width="120" filter="date">
      <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
        <span>{{ dataItem.DateIn | date: 'MM/dd/yyyy' }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="TimeIn" title="Time In" width="120" filter="date">
      <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
        <span>{{ dataItem.TimeIn | date: 'h:mm:ss' }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="ToJCCo"
      title="To JCCo"
      width="100"
      filter="numeric"
    ></kendo-grid-column>
    <kendo-grid-column
      field="ToJob"
      title="To Job"
      width="120"
    ></kendo-grid-column>
    <kendo-grid-column
      field="ToLocation"
      title="To Location"
      width="150"
    ></kendo-grid-column>
    <kendo-grid-column
      field="Memo"
      title="Memo"
      width="120"
    ></kendo-grid-column>
    <kendo-grid-column
      field="EstDateOut"
      title="Est Date Out"
      width="150"
      filter="date"
    >
      <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
        <span>{{ dataItem.EstDateOut | date: 'MM/dd/yyyy' }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="DateTimeIn"
      title="Date Time In"
      width="150"
      filter="date"
    >
      <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
        <span>{{ dataItem.DateTimeIn | date: 'MM/dd/yyyy h:mm:ss' }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="CreatedBy"
      title="Created By"
      width="120"
    ></kendo-grid-column>
    <kendo-grid-column
      field="CreatedDate"
      title="Created Date"
      width="150"
      filter="date"
    >
      <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
        <span>{{ dataItem.CreatedDate | date: 'MM/dd/yyyy h:mm:ss' }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="ModifiedBy"
      title="Modified By"
      width="150"
    ></kendo-grid-column>
    <kendo-grid-column
      field="ModifiedDate"
      title="Modified Date"
      width="150"
      filter="date"
    >
      <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
        <span>{{ dataItem.ModifiedDate | date: 'MM/dd/yyyy h:mm:ss' }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="Notes"
      title="Notes"
      width="120"
    ></kendo-grid-column>
  </kendo-grid>
</div>
