import { NgModule } from '@angular/core';

import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';

import { InputsModule } from '@progress/kendo-angular-inputs';
import { ChartModule } from '@progress/kendo-angular-charts';
import {
  provideNgxMask,
  NgxMaskDirective,
  NgxMaskPipe,
} from 'ngx-mask';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import {
  DynamicRenderModule as SharedDynamicRenderModule,
  PlatformDynamicComponentsToken,
} from '@ups/xplat/features';
import { UIModule } from '../ui/ui.module';

import 'hammerjs';

import {
  DYNAMIC_ITEM_COMPONENTS,
  DYNAMIC_COMPONENT_MAPPING,
} from './components';
import { DYNAMIC_CONTAINER_COMPONENTS } from './containers';
import { DYNAMIC_MODALS } from './modals';
import { DYNAMIC_PAGE_COMPONENTS } from './pages';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';

const MODULES = [
  SharedDynamicRenderModule,
  UIModule,
  DashboardLayoutModule,
  DragDropModule,
  ChartModule,
  InputsModule,
  DateInputsModule,
  NgxMaskDirective,
  NgxMaskPipe,
  AccordionModule,
];

@NgModule({
  imports: [...MODULES],
  declarations: [
    ...DYNAMIC_ITEM_COMPONENTS,
    ...DYNAMIC_MODALS,
    ...DYNAMIC_CONTAINER_COMPONENTS,
    ...DYNAMIC_PAGE_COMPONENTS,
  ],
  exports: [
    ...MODULES,
    ...DYNAMIC_ITEM_COMPONENTS,
    ...DYNAMIC_MODALS,
    ...DYNAMIC_CONTAINER_COMPONENTS,
    ...DYNAMIC_PAGE_COMPONENTS,
    DragDropModule,
  ],
  providers: [
    provideNgxMask(),
    {
      provide: PlatformDynamicComponentsToken,
      useValue: DYNAMIC_COMPONENT_MAPPING,
      multi: true,
    },
  ],
})
export class DynamicRenderModule {}
