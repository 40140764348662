import { BaseModel } from '../../base.model';
import { PaySequenceRuleDto } from './PaySequenceRuleDto';

export class PaySequenceRuleGroupDto extends BaseModel {
  public PaySeq: number;
  public EarnCodeTypeGroup: string;
  public PaySeqEnd: number;
  public TimeSheetDesignationID: number;
  public TimeSheetDesignationName: string;
  public Companies: PaySequenceRuleDto[];

  constructor(o: any = null) {
    super(o);
  }
}
