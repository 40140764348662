import { Component } from '@angular/core';

import { EditorComponentBase } from '../editor-component-base';

@Component({
  selector: 'editor-drawing-circle',
  templateUrl: 'editor-drawing-circle.html',
})
export class EditorDrawingCircleComponent extends EditorComponentBase {
  public peSizeX = 2;
  public peSizeY = 2;
  public peContent = 'Hello';

  public onApplyClick() {}
}
