/* eslint-disable @typescript-eslint/naming-convention */
import { LodgeReservationDto } from '../LodgeReservationDto';
import { ManPowerReqDetailExpertiseDto } from './ManPowerReqDetailExpertiseDto';

export class SaveUpdateManPowerReqDetailDto {
  ManPowerReqDetailId: string | null;
  Supervisor: string;
  SupervisorId: string | null;
  JobId: string | null;
  JobClassId: number | null;
  RequestedTradeGroupId: string | null;
  RequestedTradeClassId: string | null;
  RequestedClassCodeId: string | null;
  RequestedLinkedClassId: string | null;
  Shift: number | null;
  FilledById: string | null;
  StartDate: Date | null;
  EndDate: Date | null;
  Foreman: string;
  EmployeeId: string | null;
  ProspectId: string | null;
  DispatchStatusId: number | null;
  DeclineReasonId: number | null;
  Notes: string;
  PreviousNotes: string;
  HasReplacement: boolean | null;
  LodgeReservation: LodgeReservationDto;
  ManPowerReqDetailExpertiseDtos: ManPowerReqDetailExpertiseDto[];
}
