import { BaseModel } from '../base.model';

export class ChangeDispatchStatusRequestDto extends BaseModel {
  /** GUID */
  public EmployeeJobID: string;
  public NewDispatchStatusID: number | null;
  public Date: Date | string | null;
  public ROFNote: string;

  constructor(o: any = null) {
    super(o);
  }
}
