<div>
  <div>
    <label>Size X</label>
    <input [(ngModel)]="peSizeX" />
  </div>
  <div>
    <label>Size Y</label>
    <input [(ngModel)]="peSizeY" />
  </div>
  <div>
    <label>Content</label>
    <input [(ngModel)]="peContent" />
  </div>
  <button (click)="onApplyClick()">Apply</button>
</div>
