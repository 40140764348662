import { Pipe, PipeTransform } from '@angular/core';
import { IEmployeeData } from '@ups/xplat/api/dto';

/* eslint-disable */
@Pipe({
  name: 'userCache',
})
export class UserCachePipe implements PipeTransform {
  transform(item: string, cache: IEmployeeData[] = []) {
    const mentionReg =
      /#[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/gi;
    const hasId = mentionReg.test(item);
    if (hasId) {
      let result = item;
      cache.forEach((user) => {
        result = result.replace(
          new RegExp(`#${user.Id}`, 'gi'),
          `@${user.FullName}`
        );
      });
      return result;
    }

    return cache.find((user) => user.Id === item)?.FullName || item;
  }
}
/* eslint-disable */
