import { Directive, ViewChild } from '@angular/core';
import { DynamicTypeaheadEmployeeBaseComponent } from '@ups/xplat/features';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';

@Directive()
export abstract class DynamicTypeaheadEmployeeWebBaseComponent extends DynamicTypeaheadEmployeeBaseComponent {
  @ViewChild('dropdown') dropdown: DropDownListComponent;

  setValue(value: unknown) {
    if (this.dropdown) {
      this.dropdown.value = value;
    }
  }
}
