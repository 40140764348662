/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { ToastUtility } from '@syncfusion/ej2-notifications';
import { EventBusService, NotificationsService } from '@ups/xplat/core';
import {
  NotificationService,
  NotificationSettings,
} from '@progress/kendo-angular-notification';
import { ToastComponent } from '@syncfusion/ej2-angular-notifications';

@Injectable({
  providedIn: 'root',
})
export class NotificationsViewService {
  toastObj?: ToastComponent;
  notificationDto: any;
  notificationToastInstances: {
    toast: ToastComponent;
    customProperties: any;
  }[] = [];

  constructor(
    private eventBus: EventBusService,
    private notificationsService: NotificationsService,
    private kendoNotificationService: NotificationService
  ) {
    notificationsService.notificationRecieved$.subscribe(
      this.showViewToast.bind(this)
    );
    notificationsService.notificationClosed$.subscribe(
      this.closeNotification.bind(this)
    );
    this.eventBus
      .observe(this.eventBus.types.notificationShowMessage)
      .subscribe((data) => {
        this.kendoNotificationService.show(<NotificationSettings>data);
      });
  }

  notificationToastDismissClick(notificationDto) {
    this.toastObj?.hide();
    this.closeNotification(notificationDto);
    this.notificationsService.dismissNotification(notificationDto.id);
  }

  notificationToastSnoozeClick(notificationDto) {
    this.toastObj?.hide();
    const selectedSnoozeTime = this.getSelectedSnoozeTime(notificationDto);
    this.closeNotification(notificationDto);
    this.notificationsService.snoozeNotification(
      notificationDto.id,
      selectedSnoozeTime ? selectedSnoozeTime : 300
    );
  }

  // ...
  closeNotification(notificationDto: any) {
    const toastInstance = this.notificationToastInstances.find(
      (i) => i.customProperties.id === notificationDto.id
    );

    if (toastInstance) {
      // remove from the list
      this.notificationToastInstances = this.notificationToastInstances.filter(
        (i) => i.customProperties.id !== notificationDto.id
      );
    }
  }

  showViewToast(notificationDto) {
    const existingToastMessage = this.notificationToastInstances.find(
      (i) => i.customProperties.id === notificationDto.id
    );

    if (existingToastMessage) return;

    // Create toast for new message
    const type = (notificationDto.type || '').toUpperCase();
    const cssClass =
      type === 'ERROR'
        ? 'e-btn e-control e-danger'
        : type === 'WARNING'
        ? 'e-btn e-control e-warning'
        : type === 'SUCCESS'
        ? 'e-btn e-control e-success'
        : type === 'INFO'
        ? 'e-btn e-control e-info'
        : '';

    // Create the toast using ToastUtility
    this.toastObj = ToastUtility.show({
      title: '',
      cssClass: cssClass,
      content: `
        <p class="mb-0">${notificationDto.message}<p>
        ${
          notificationDto.canSnooze
            ? `
            <div class="mb-5">
              <label>Snooze time:</label>
              <select id="snoozeSelect_${notificationDto.id}" name="snoozeSelect" class="form-control">
                <option>Select a snooze time</option>
                <option value="300">5 minutes</option>
                <option value="600">10 minutes</option>
                <option value="900">15 minutes</option>
              </select>
            </div>
          `
            : ''
        }
      `,
      timeOut: notificationDto.timeOut * 1000,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      position: { X: 'Right', Y: 'Bottom' },
      showCloseButton:
        !notificationDto.canDismiss && !notificationDto.canSnooze,
      buttons: [
        ...(notificationDto.canDismiss
          ? [
              {
                model: { content: 'Dismiss' },
                click: this.notificationToastDismissClick.bind(
                  this,
                  notificationDto
                ),
              },
            ]
          : []),
        ...(notificationDto.canSnooze
          ? [
              {
                model: { content: 'Snooze' },
                click: this.notificationToastSnoozeClick.bind(
                  this,
                  notificationDto
                ),
              },
            ]
          : []),
      ],
    }) as unknown as ToastComponent;

    this.notificationToastInstances.push({
      toast: this.toastObj,
      customProperties: notificationDto,
    });
  }

  getSelectedSnoozeTime(notificationDto): number | null {
    const toastInstance = this.notificationToastInstances.find(
      (i) => i.customProperties.id === notificationDto.id
    );

    if (toastInstance) {
      const snoozeSelect = document.getElementById(
        `snoozeSelect_${notificationDto.id}`
      ) as HTMLSelectElement;
      if (snoozeSelect) {
        const selectedValue = snoozeSelect.value;
        return parseInt(selectedValue, 10);
      }
    }

    return null;
  }
}
