/* eslint-disable @nx/enforce-module-boundaries */
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import { parseData, parseDataToLowerCamelCased } from '@ups/xplat/utils';
import { FileDto } from '@ups/xplat/api/dto';
import { firstValueFrom } from 'rxjs';

const DOWNLOAD_FROM_VIEWPOINT = '/api/e2e/pullviewpointfilethroughsparta/';
const DOWNLOAD_FROM_SPARTA = '/api/e2e/pullazurefile/';
const UPLOAD_FILE_THRU_SPARTA_TO_AZURE = '/api/file/uploadfile/';
const DELETE_FILE_THRU_SPARTA_IN_AZURE = '/api/file/{:fileId}/';
const DOWNLOAD_FILE_THRU_SPARTA_TO_AZURE = '/api/file/downloadfile/{:fileId}/';
const GET_FILE_THRU_SPARTA_TO_AZURE = '/api/file/{:fileId}/';
const LIST_FILES_IN_DIRECTORY = '/api/file/list/{:location}';

/* eslint-disable */
@Injectable({
  providedIn: 'root',
})
export class SpartaFileService {
  http: HttpClient;

  constructor(private httpFactory: MyHttpClientFactory) {
    this.http = this.httpFactory.createHttpClient(
      environment.urls.spartaAPI,
      true,
      ResponseCasingEnum.CamelCase
    );
  }

  /* eslint-disable */
  public downloadFileFromSparta(fileId: string): Promise<{ Data?: string }> {
    const url = DOWNLOAD_FROM_SPARTA + fileId;
    return this.http.get(url).toPromise().then(parseData);
  }
  /* eslint-enable */

  public downloadFileFromViewPoint(attachmentId: number): Promise<FileDto> {
    const url = DOWNLOAD_FROM_VIEWPOINT + attachmentId;
    return this.http.get(url).toPromise().then(parseData);
  }

  /**
   * Downloads file and opens file dialog popup for user to choose where to save file.
   * @fileName: fileName will be used in the file dialog as default name for the file
   */
  public downloadFile(fileId: string, fileName: string): Promise<unknown> {
    return this.getFileWithContent(fileId, { responseType: 'blob' }).then(
      (data) => {
        console.log('Dowload succeess. FileId:' + fileId);
        this.downloadBlob(data, fileName);
      },
      (err) => {
        console.log('Error while downloading file! ' + err.Messages);
        throw err;
      }
    );
  }

  public previewFile(fileId: string): Promise<string> {
    return this.getFileWithContent(fileId, { responseType: 'blob' }).then(
      (data) => {
        return this.previewBlob(data);
      },
      (err) => {
        console.log('Error while downloading file! ' + err.Messages);
        throw err;
      }
    );
  }

  public preloadFile(fileId: string): Promise<unknown> {
    return this.getFileWithContent(fileId, { responseType: 'blob' }).then(
      async (data) => {
        return await this.preloadBlob(data as Blob);
      },
      (err) => {
        console.log('Error while downloading file! ' + err.Messages);
        throw err;
      }
    );
  }

  /* Gets file info but without file content */
  public getFile(fileId: string): Promise<FileDto> {
    const url = GET_FILE_THRU_SPARTA_TO_AZURE.split('{:fileId}').join(fileId);
    return this.http.get(url).toPromise().then(parseDataToLowerCamelCased);
  }

  public getFileWithContent(fileId: string, options?: unknown) {
    const url =
      DOWNLOAD_FILE_THRU_SPARTA_TO_AZURE.split('{:fileId}').join(fileId);
    return this.http.get(url, options).toPromise().then();
  }

  public getFilesInDirectory(location: string) {
    const url = LIST_FILES_IN_DIRECTORY.split('{:location}').join(location);
    return this.http.get<FileDto[]>(url);
  }

  /** Uploads file. You can profile either f.Content or f.ContentBase64 to upload content.
   * If no content is specified the metadata of the file are updated only.
   */
  public uploadFile(fileDto: FileDto): Promise<FileDto> {
    const url = UPLOAD_FILE_THRU_SPARTA_TO_AZURE;
    // make sure no artifical id's when creating
    delete fileDto.fileID;

    return firstValueFrom(this.http.post<FileDto>(url, fileDto));
  }

  public deleteFile(fileId: string): Promise<boolean> {
    const url =
      DELETE_FILE_THRU_SPARTA_IN_AZURE.split('{:fileId}').join(fileId);
    /* eslint-disable */
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    /* eslint-enable */
    const options = { headers: headers };

    return this.http
      .delete(url, options)
      .pipe(map((res: HttpResponse<unknown>) => res.body as boolean))
      .toPromise();
  }

  /** Creates file dialog popup for user to choose where to save file.*/
  private downloadBlob(data: unknown, fileName: string) {
    if (data) {
      console.log('dowload blob:' + fileName);

      let fileURL = null;
      try {
        fileURL = URL.createObjectURL(<Blob>data);
      } catch {
        fileURL = URL.createObjectURL((<{ body: Blob }>data).body);
      }

      const a = window.document.createElement('a');
      a.style.display = 'none';
      a.href = fileURL;
      a['download'] = fileName;

      // Append anchor to body.
      document.body.appendChild(a);
      a.click();
      // Remove anchor from body
      document.body.removeChild(a);
    }
  }

  private previewBlob(data: unknown): string {
    if (data) {
      try {
        return URL.createObjectURL(<Blob>data);
      } catch {
        return URL.createObjectURL((<{ body: Blob }>data).body);
      }
    }
  }

  private preloadBlob(data: Blob) {
    const reader = new FileReader();
    reader.readAsDataURL(data as Blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        const base64data = reader.result as string;
        resolve(this.convertBase64DataUriToByteArray(base64data));
      };
    });
  }

  private convertBase64DataUriToByteArray(base64: string) {
    const explicit64 = base64.split(',')[1];
    const byteCharacters = atob(explicit64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    return byteNumbers;
  }
}
/* eslint-enable */
