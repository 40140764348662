import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService, environment, MyHttpClientFactory } from '@ups/xplat/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DockLog, Requisition, RequisitionItem } from '@ups/tool-tracker';

@Injectable({
  providedIn: 'root',
})
export class MaterialService extends DataService<unknown> {
  route: string;
  // cartItems$: BehaviorSubject<RequisitionItem[]> = new BehaviorSubject([]);
  selectedDockLog$: BehaviorSubject<DockLog> = new BehaviorSubject(null);
  selectedFacilityKeyId$: BehaviorSubject<number> = new BehaviorSubject(null);
  selectedRequisition$: BehaviorSubject<Requisition> = new BehaviorSubject(
    null
  );
  selectedRequisitionName$: BehaviorSubject<string> = new BehaviorSubject(null);

  cartItems: RequisitionItem[] = [];
  selectedCartItem: RequisitionItem;

  constructor(httpClient: HttpClient, httpFactory: MyHttpClientFactory) {
    super(
      environment.urls.toolTrackerAPI,
      '/api/dockLog',
      httpClient,
      httpFactory
    );
  }

  getDockLogs(facilityKeyId: number | null): Observable<DockLog[]> {
    if (facilityKeyId) {
      return this.httpClient.get<DockLog[]>(`${this.route}/${facilityKeyId}`);
    } else {
      return of([]);
    }
  }
}
