import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { GroupResult } from '@progress/kendo-data-query';
import { PagerModule } from '@progress/kendo-angular-pager';

export class PageChangeEventExtended<T> implements PageChangeEvent {
  totalCount: number;
  skip: number;
  take: number; // pageSize
  group: GroupResult;
  data: T[];
  checked: boolean;
}

@Component({
  selector: 'ups-grid-grouping-pager',
  standalone: true,
  imports: [CommonModule, PagerModule],
  templateUrl: './grid-grouping-pager.component.html',
})
export class GridGroupingPagerComponent {
  @Input() expanded: boolean;
  @Input() isLastGroup: boolean;
  @Input() isCountToShowPaging: boolean;

  @Input() pageSize: number;
  @Input() skip: number;
  @Input() total: number;

  @Input() addMargin = false;

  @Output() pageChange = new EventEmitter<PageChangeEvent>();
}
