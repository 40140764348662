import { Directive, HostListener } from '@angular/core';

@Directive({ selector: '[enableTextarea]' })
export class DynamicTextareaDirective {
  @HostListener('mouseenter', ['$event.target'])
  onMouseEnter(target: HTMLElement) {
    const textarea = target.getElementsByTagName('textarea')[0];

    if (textarea.disabled) {
      textarea.removeAttribute('disabled');
      textarea.setAttribute('readonly', 'true');
    }
  }

  @HostListener('mouseleave', ['$event.target'])
  onMouseLeave(target: HTMLElement) {
    const textarea = target.getElementsByTagName('textarea')[0];

    if (textarea.disabled) {
      textarea.removeAttribute('readonly');
      textarea.setAttribute('disabled', 'true');
    }
  }
}
