import { BaseModel } from '../base.model';

export class VendorName extends BaseModel {
  public vendor: number;
  public name: string;
  public vendorGroup: number;

  constructor(o = null) {
    super(o);
  }
}
