<div id="reqNotesTabContent">
  <label [hidden]="hidePreviousNotes">Previous Notes</label>
  <textarea
    id="old_notes"
    disabled="disabled"
    style="height: 10em"
    class="form-control w-100 mb-5 k-textbox"
    [(ngModel)]="previousNotes"
    [ngModelOptions]="{ standalone: true }"
    [hidden]="hidePreviousNotes"
  ></textarea>
  <label [hidden]="hideNewNote">Add Note</label>
  <textarea
    id="new_note"
    style="height: 6em"
    name="notes"
    class="form-control w-100 k-textbox"
    [(ngModel)]="newNote"
    [ngModelOptions]="{ standalone: true }"
    [disabled]="isDisabled"
    (change)="onNewNoteChange()"
    [hidden]="hideNewNote"
  ></textarea>
</div>
