import { Component } from '@angular/core';

import { AddressBaseComponent } from '@ups/xplat/features';

@Component({
  selector: 'ups-address',
  templateUrl: 'address.component.html',
})
export class AddressComponent extends AddressBaseComponent {
  constructor() {
    super();
  }
}
