import { PathSegment } from './path-segment';
import { Traverse } from './traverse';

export class GroupResultUtils {
  /**
   *  Check whether there are unloaded items inside sourceItems starting from path and below.
   *  NOTE: we do assume that every group has at least one item (otherwise there would be no group).
   */
  public static anyUnloadedItemsBelowPath(items: any[], path: PathSegment[]) {
    const anySubNodeWithoutItems = Traverse.traverseGridData(
      items,
      path,
      true,
      true,
      (callbackEv) => {
        const withoutData = callbackEv.isAggregate && (!callbackEv.dataItem.items || !callbackEv.dataItem.items.length);
        if (withoutData) {
          callbackEv.stopProcessing = true;
          return true;
        }
      },
      null
    );

    return anySubNodeWithoutItems;
  }
}
