// This is currently using localStorage, but the internal mechanism should be swapped out for whichever method is appropriate for the paltform

export const storagePrefix = `ups.`;

// all the keys ever persisted across web and mobile
export interface IStorageKeys {
  LOCALE: string;
  ACCESS_TOKEN: string;
  OFFLINE_DATA_DROPDOWNS: string;
  OFFLINE_DATA_DYNAMIC_CONTAINERS: string;
  OFFLINE_COMPLETE_ON_RECONNECT: string;
  USER_DATA: string;
}

export const StorageKeys: IStorageKeys = {
  LOCALE: `${storagePrefix}locale`,
  ACCESS_TOKEN: `${storagePrefix}access-token`,
  OFFLINE_DATA_DROPDOWNS: `${storagePrefix}offline-data-dropdowns`,
  OFFLINE_DATA_DYNAMIC_CONTAINERS: `${storagePrefix}offline-data-dynamic-containers`,
  OFFLINE_COMPLETE_ON_RECONNECT: `${storagePrefix}offline-complete-on-reconnect`,
  USER_DATA: `${storagePrefix}user-data`,
};
