import { BaseModel } from '../base.model';

export class DeclineReasonDto extends BaseModel {
  public DeclineReasonId: number;
  public Name: string;
  public IsDeleted: boolean;
  public Position: number;

  constructor(o: any = null) {
    super(o);
  }
}
