export class EmployeeManPowerOverlapping {
  JobName: string;
  StartDate: Date | null;
  EndDate: Date | null;
  DispatchStatusId: number | null;
  DispatchStatusName: string;
  ProjectManagerName: string;
  ProjectManagerEmail: string;
  ProjectManagerPhone: string;
}
