import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
  CheckListItem,
  SystemTask,
  SystemTaskRegistration,
  Task,
  SystemTaskCompletion,
} from '@ups/xplat/core';

const CREATE_OR_UPDATE_TASK = 'api/usertask/';
const DELETE_USER_TASK = 'api/usertask/';
const CONVERT_TO_TASK = 'api/checklistitem/convert-to-task';
const UPDATE_CHECKLIST_ITEM = 'api/checklistitem/';
const COMPLETE_SYSTEM_TASK = 'api/systemtask/complete';
const LIST_SYSTEM_TASK_TYPES = 'api/systemtask';
const ENROLL_IN_TASK = (taskId: string) => `api/systemtask/${taskId}/enroll`;

@Injectable({ providedIn: 'root' })
export class TasksService {
  private http: HttpClient;

  constructor(private httpClientFactory: MyHttpClientFactory) {
    this.http = httpClientFactory.createHttpClient(
      environment.urls.tasksAPI,
      true,
      ResponseCasingEnum.CamelCase
    );
  }

  public createOrUpdateTask(t: Task) {
    return this.http.put<Task>(CREATE_OR_UPDATE_TASK, t);
  }

  public convertChecklistItemToTask(checkListItem: CheckListItem) {
    return this.http.post<Task>(CONVERT_TO_TASK, checkListItem);
  }

  public deleteTask(editedRecord: Task) {
    return this.http.delete(DELETE_USER_TASK + editedRecord.taskId);
  }

  public updateChecklistItem(c: CheckListItem) {
    return this.http.put(UPDATE_CHECKLIST_ITEM, c);
  }

  public completeSystemTask(taskId: string, params: any[]) {
    return this.http.post(COMPLETE_SYSTEM_TASK, {
      SystemTaskId: taskId,
      Parameters: params,
    });
  }

  /** Returns number of completed tasks.*/
  public completeSystemTask2<int>(task: SystemTaskCompletion) {
    return this.http.post(COMPLETE_SYSTEM_TASK, task);
  }

  public enrollInSystemTask(registration: SystemTaskRegistration) {
    return this.http.post(
      ENROLL_IN_TASK(registration.systemTaskId),
      registration
    );
  }

  public listSystemTaskTypes() {
    return this.http.get<SystemTask[]>(LIST_SYSTEM_TASK_TYPES);
  }

  public getTasksForAssignmentGroup(assignmentGroupId: string) {
    return this.http.get<Task[]>(
      'api/usertask/assignmentgroup/' + assignmentGroupId
    );
  }

  public getTasksForRelatedUser(relatedUserId: string) {
    return this.http.get<Task[]>('api/usertask/relateduser/' + relatedUserId);
  }
}
