<div>
  <span *ngIf="!hasReadPermissions">You don't have any permissions to see the Equipment Fixed Asset History</span>
  <kendo-grid id="fixedAssetHistoryGridId"
    *ngIf="hasReadPermissions"
    [data]="gridData"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [filter]="state.filter"
    [sortable]="true"
    [pageable]="true"
    [filterable]="'menu'"
    [loading]="isLoading"
    (dataStateChange)="dataStateChange($event)"
    class="rate-sheet transparent-around"
  >
    <kendo-grid-column field="Company"
      title="Company"
      width="60"
      filter="numeric">
    </kendo-grid-column>
    <kendo-grid-column field="TransferCompany"
      title="Transfer Company"
      width="60"
      filter="numeric">
    </kendo-grid-column>
    <kendo-grid-column field="TransferStatusName"
      title="Transfer Status"
      width="120">
    </kendo-grid-column>
    <kendo-grid-column field="TransferDate"
      title="Transfer Date"
      width="120"
      filter="date"
      format="{0:MM/dd/yyyy}">
    </kendo-grid-column>
  </kendo-grid>
</div>
