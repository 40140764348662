import { BaseModel } from '../base.model';
import { FileDataModel } from './fileData-model';

export class ProspectResumeModel extends BaseModel {
  isNewItem: boolean;
  editable: boolean;
  isSelected: boolean;
  showFiles: boolean;

  ProspectResumeID: string;
  ProspectID: string;

  ResumeDate: Date;

  Files: FileDataModel[] = [];
  bActive: boolean = true;

  constructor(o: any = null) {
    super(o);
  }
}
