<kendo-window
  *ngIf="displayEditor"
  title="Help Editor"
  (close)="hideEditor()"
  [left]="250"
  [top]="150"
>
  <div class="d-flex flex-column w-100 h-100">
    <kendo-stepper
      #stepper="kendoStepper"
      [steps]="steps"
      [currentStep]="currentStep"
      (currentStepChange)="goToStep($event)"
      [stepType]="'full'"
    ></kendo-stepper>
    <ng-container [ngSwitch]="currentStep">
      <div *ngSwitchCase="0" class="pt-4">
        <div class="d-flex p-3 justify-content-center">
          <kendo-buttongroup [selection]="'single'">
            <button
              kendoButton
              [togglable]="true"
              [selected]="editedDoc.type === helpTypeEnum.Tooltip"
              (click)="editedDoc.type = helpTypeEnum.Tooltip; goToStep(1)"
            >
              Tooltip
            </button>
            <button
              kendoButton
              [togglable]="true"
              [selected]="editedDoc.type === helpTypeEnum.Walkthrough"
              (click)="editedDoc.type = helpTypeEnum.Walkthrough; goToStep(1)"
            >
              Walkthrough
            </button>
          </kendo-buttongroup>
        </div>
      </div>
      <div *ngSwitchCase="1" class="pt-4">
        <help-editor-walkthrough
          #walkEditor
          *ngIf="editedDoc.type === helpTypeEnum.Walkthrough"
          [document]="editedDoc"
          (complete)="goToStep(2)"
        ></help-editor-walkthrough>
        <help-editor-tooltip
          #tooltipEditor
          *ngIf="editedDoc.type === helpTypeEnum.Tooltip"
          [document]="editedDoc"
          (complete)="goToStep(2)"
        ></help-editor-tooltip>
      </div>
      <div
        *ngSwitchCase="2"
        class="p-4 m-4 d-flex align-items-center justify-content-center"
      >
        <i class="k-i-loading k-icon k-icon-xl" *ngIf="saveInProgress"></i>
        <div *ngIf="!saveInProgress">
          <label>
            <input
              type="checkbox"
              kendoCheckBox
              [(ngModel)]="editedDoc.isPublished"
            />
            <span class="ms-2">Published</span>
          </label>
          <div class="d-flex mt-4 mx-2">
            <button
              kendoButton
              [icon]="'delete'"
              *ngIf="canDeleteHelpDocs && this.editedDoc.id !== null"
              (click)="deleteHelpDoc()"
            >
              Delete
            </button>
            <button
              kendoButton
              [primary]="true"
              class="ms-2"
              [icon]="'save'"
              (click)="saveHelpDoc()"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
      <div *ngSwitchDefault>output2</div>
    </ng-container>
  </div>
  <!-- <div class="d-flex"><button class="k-button ms-auto">Previous</button><button class="mx-2 k-button" [disabled]="currentStepIsInvalid()">Next</button></div> -->
</kendo-window>

<ups-confirmation-dialog></ups-confirmation-dialog>
