import { Component, Injector, ViewChild, inject } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';

import { UserState } from '@ups/user';
import {
  HelpActions,
  HelpDeskSubmission,
  HelpState,
} from '@ups/xplat/base/in-app-help';
import { AppRouterState, BaseComponent, environment } from '@ups/xplat/core';
import { getAppVersionInfo } from '@ups/xplat/web/utils';
import { WebSmartlookService } from '@ups/xplat/web/core';

@Component({
  selector: 'ups-help-desk-modal',
  templateUrl: './help-desk-modal.component.html',
  styleUrls: [],
})
export class HelpDeskModalComponent extends BaseComponent {
  smartlookService = inject(WebSmartlookService);
  @ViewChild('supportForm') form: NgForm;
  version = getAppVersionInfo().versionName;

  showModal = false;

  isSaving: boolean = false;
  isValid: boolean = false;

  RelatedInfo = '';

  document: HelpDeskSubmission = {} as HelpDeskSubmission;

  constructor(private store: Store) {
    super();

    store
      .select(HelpState.selectHelpDesk)
      .pipe(takeUntil(this.destroy$))
      .subscribe((x) => {
        this.showModal = x.showModal;
        this.document = Object.assign({}, x.submission) as HelpDeskSubmission;
        if (x.submission) {
          this.document.SupportInfo = x.submission.SupportInfo || '';
          this.document.ShortDescription = x.submission.ShortDescription || '';
          this.document.Body = x.submission.Body || '';
          this.document.ErrorHistory = x.submission.ErrorHistory || [];
        }
        this.refreshValues();
      });
  }

  refreshValues() {
    this.store
      .select(AppRouterState.selectUrl)
      .pipe(take(1))
      .subscribe((x) => {
        this.document.RelativeURL = x;
      });

    this.store.pipe(UserState.selectCurrentTruthy(), take(1)).subscribe((x) => {
      this.document.FullName = `${x.Data.FirstName} ${
        x.Data.MiddleName != undefined && x.Data.MiddleName != null
          ? ' ' + x.Data.MiddleName
          : ''
      } ${x.Data.LastName}`;
      this.document.Email = `${x.Data.Email}${
        x.Data.udUPSEmail != undefined && x.Data.udUPSEmail != null
          ? ' | ' + x.Data.udUPSEmail
          : ''
      }`;
      this.document.Phone = x.Data.Phone;
      this.document.SmartlookURL = this.smartlookService.sessionUrl();
    });
  }

  cancel() {
    this.clearForm();
    this.showModal = false;
    this.store.dispatch(HelpActions.hideHelpDeskModal());
  }

  clearForm() {
    this.isSaving = false;
    this.isValid = false;
  }

  confirm() {
    const self = this;
    self.isSaving = true;
    if (!this.form.valid) return;

    let dto: HelpDeskSubmission = this.document;
    dto.ShortDescription = `UPS1 (${
      environment.name
    } v${this.version.toString()}) ${dto.ShortDescription}`;
    dto.Body = this.document.Body + '\r\n-----------\r\n' + this.RelatedInfo;
    this.store.dispatch(HelpActions.sendHelpDeskTicket(dto));
  }
}
