import { Component, Input, OnChanges } from '@angular/core';
import {
  GridDataResult,
  DataStateChangeEvent,
} from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { BaseComponent } from '@ups/xplat/core';
import { EquipmentVpService } from '@ups/xplat/web/core';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';

@Component({
  selector: 'ups-equipment-fixed-asset-history',
  templateUrl: './equipment-fixed-asset-history.component.html',
  styleUrls: ['./equipment-fixed-asset-history.component.scss'],
})
export class EquipmentFixedAssetHistoryComponent
  extends BaseComponent
  implements OnChanges
{
  @Input() vpCompanyID: number;
  @Input() equipmentName: string;

  fixedAssetHistory: any = [];
  public gridData: GridDataResult;
  public state: State = {
    skip: 0,
    take: 10,
  };
  isLoading: boolean = false;
  hasReadPermissions: boolean = false;

  constructor(
    private security: SecurityService,
    private equipmentVpService: EquipmentVpService
  ) {
    super();
    this.hasReadPermissions = this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_fixedassethistorytab
    ).readAll;
  }

  ngOnChanges() {
    this.fixedAssetHistory = [];
    this.gridData = process(this.fixedAssetHistory, this.state);
    this.loadFixedAssetHistory();
  }

  loadFixedAssetHistory() {
    const self = this;
    if (this.vpCompanyID && this.equipmentName && this.hasReadPermissions) {
      self.isLoading = true;
      this.equipmentVpService
        .getEquipmentFixedAssetHistory(this.vpCompanyID, this.equipmentName)
        .subscribe(
          (d) => {
            self.fixedAssetHistory = d.value;
            self.gridData = process(self.fixedAssetHistory, self.state);
            self.isLoading = false;
          },
          (error) => {
            console.error(error);
            self.isLoading = false;
            self.fixedAssetHistory = [];
            throw error;
          }
        );
    }
  }

  dataStateChange(state: DataStateChangeEvent): any {
    this.state = state;
    this.gridData = process(this.fixedAssetHistory, this.state);
  }
}
