<kendo-dialog *ngIf="show" [minWidth]="400" (close)="hide()">
  <kendo-dialog-titlebar>
    {{ title ? title : editing ? 'Update' : 'Add New' }}
  </kendo-dialog-titlebar>
  <ups-dynamic-container
    #dynamicContainerComponent
    [dynamicGroup]="dynamicGroup"
  ></ups-dynamic-container>
  <kendo-dialog-actions kendoTooltip [layout]="'normal'">
    <button kendoButton class="" (click)="hide()">
      {{ !!cancelButtonLabel ? cancelButtonLabel : 'Cancel' }}
    </button>
    <button
      kendoButton
      [primary]="'true'"
      (click)="save()"
      [title]="editing ? 'Update' : 'Save'"
      [disabled]="!dynamicContainerComponent.form.valid"
      *ngIf="!hideSaveButton"
    >
      {{ editing ? 'Update' : 'Save' }}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
