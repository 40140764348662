<kendo-dialog
  [height]="320"
  [hidden]="!opened"
  [width]="650"
  (close)="close()"
  fade
  modal
>
  <kendo-dialog-titlebar>
    <span>{{ isEdit ? 'Update' : 'Add' }} Subtask</span>
  </kendo-dialog-titlebar>

  <div class="form-group row">
    <label class="col-form-label col-md-3">Subtask Name</label>
    <div class="col-md-9">
      <input
        [disabled]="false"
        [required]="true"
        [(ngModel)]="model.Name"
        class="form-control k-input"
        kendoTextBox
        maxlength="100"
        name="name"
        type="text"
      />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-form-label col-md-3"></label>
    <div class="col-md-9">
      <label class="k-form-field right">
        <input
          [disabled]="false"
          [(ngModel)]="model.bMandatory"
          class="k-checkbox"
          id="mandatoryCheckbox"
          type="checkbox"
        />
        <label class="k-checkbox-label" for="mandatoryCheckbox">
          Mandatory
        </label>
      </label>
    </div>
  </div>

  <kendo-dialog-actions>
    <button class="k-button" (click)="close()">Cancel</button>
    <button
      [disabled]="!isValid()"
      (click)="onAdd()"
      class="k-button k-primary"
    >
      {{ isEdit ? 'Update' : 'Add' }}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
