import {
  Component,
  ChangeDetectorRef,
  Inject,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { IMenuService, MENU_SERVICE_TOKEN, IMenuItem } from '@ups/common';
import {
  PanelBarItemModel,
  PanelBarExpandMode,
} from '@progress/kendo-angular-layout';
import { LocalStorageService } from '@ups/xplat/core';

/**
 * Menu component
 * --------------
 *
 * MENU MODES:
 *
 *  @Input() expandMode = PanelBarExpandMode.Single | Multiple | Full (PanelBarExpandMode.Single will switch expandAll to false)
 *  @Input() expandAll = true | false (true will switch to PanelBarExpandMode.Multiple)
 *
 * STYLING:
 *
 *  If you wish to alter default style:
 *  - copy the local SCSS
 *  - extend/wrap main ups-kendo-panel-bar-menu (add an ID: ups-kendo-panel-bar-menu#menuId { ... }, or class .sidebar-content ups-kendo-panel-bar-menu { ... }) to ensure stronger CSS selector
 *  - reconfigure mixin's
 *
 * COMPONENT NOTES:
 *
 *  Because: https://github.com/angular/angular/issues/31930 - routerLinkActive broken in ng-template Angular 8.
 *  We introduced PatchContextRouterLinkActiveDirective, which made routerLinkActive details available directly on the item.
 *  Originally we used itemL1['_routerLinkActive']?.isActive for expanded/class.active...
 *  But we now use isExpanded(...) / isActive(...) function for more flexibility...
 *  ...as the plain routerLinkActive was not able to handle situations where there was no URL node for the L1 level menu (e.q. components in KS)
 *
 * STYLING NOTES:
 *
 *  Ideal solution:
 *  - possibility to use some initial SCSS config to set up items (idealy by using those mixins - for ease config)
 *  - let component work without extra SCSS to be pulled in (let it have some default styling)
 *
 *  Seems it's not possible to achieve this via @use, nor @extend will work (we can't globally define @mixins or styles that we then use in SCSS without importing it)...
 *  ...which means we'd need to know the file where we will define the new style...
 *  ...and this then is not satisfying our expectations of an ideal solution.
 *
 *  Notes related to SASS R&D:
 *  - It seems that angular-cli and the underlying sass version is tied together.
 *  - @use keyword is not avail. in sass 1.22.x, it's avail in the CLI from angular 9.
 *  - also older sass version does not supports mixin-exists check within a given module
 */
@Component({
  selector: 'ups-kendo-panel-bar-menu',
  templateUrl: 'kendo-panel-bar-menu.component.html',
})
export class KendoPanelBarMenuComponent {
  /*
   * Single requires expandAll = false;
   * expandAll = true requires Multiple;
   */

  c = console;

  _expandMode: PanelBarExpandMode = PanelBarExpandMode.Single;
  @Input() public get expandMode(): PanelBarExpandMode {
    return this._expandMode;
  }
  public set expandMode(value: PanelBarExpandMode) {
    if (value === PanelBarExpandMode.Single) this._expandAll = false;
    this._expandMode = value;
  }

  _expandAll = false;
  @Input() public get expandAll(): boolean {
    return this._expandAll;
  }
  public set expandAll(value: boolean) {
    if (value) this._expandMode = PanelBarExpandMode.Multiple;
    this._expandAll = value;
  }

  @Input() public useWebFrameworkDesign = true;
  @Input() public title: string;
  @Input() public displayName: string;

  @Output() sidebarToggled = new EventEmitter();

  constructor(
    private cd: ChangeDetectorRef,
    @Inject(MENU_SERVICE_TOKEN) public menuService: IMenuService,
    private storage: LocalStorageService
  ) {
    // NOTE: configure menu-service outside (from the application)
    // this.menuService.configureMenu(menuConfig, 2);
  }

  public isExpanded(item: IMenuItem): boolean {
    return (
      (this._expandAll && this._expandMode === PanelBarExpandMode.Multiple) ||
      this.isActive(item)
    );
  }

  // Added to collapse menu when it's clicked and expanded
  public toggleExpansion(item: IMenuItem) {
    if (this.isExpanded(item)) {
      this._expandMode = PanelBarExpandMode.Single;
    } else {
      this._expandMode = PanelBarExpandMode.Multiple;
    }
  }

  public isActive(item: IMenuItem): boolean {
    return (
      item && this.menuService && this.menuService.isMenuItemActive(item, true)
    );
  }

  public toggleSidebarMenu(): void {
    this.sidebarToggled.emit();
  }
}
