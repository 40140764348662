import { EmployeeAttitudeEnum } from './EmployeeAttitudeEnum';
import { EmployeeExpertiseDto } from './EmployeeExpertiseDto';
import { EquipmentOutfitReadinessRatingEnum } from './EquipmentOutfitReadinessRatingEnum';
import { PerformanceEvaluationExtendedDto } from './PerformanceEvaluationExtendedDto';

export class PerformanceEvaluationDto {
  PerformanceEvaluationId: string | null;
  EmployeeId: string | null;
  EmployeeJobId: string;
  EquipmentOutfitReadinessRating: number | null | EquipmentOutfitReadinessRatingEnum;
  EquipmentOutfitReadinessComments: string;
  AttitudeRating: number | null | EmployeeAttitudeEnum;
  AttitudeComments: string;
  IsActive: boolean | null;
  JobId: string | null;
  LastModifiedAt: Date;
  EmployeeExpertiseDtos: EmployeeExpertiseDto[];
  ExtendedInfo: PerformanceEvaluationExtendedDto;
}
