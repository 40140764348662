import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  templateUrl: './address.html',
  selector: 'ups-address',
  styleUrls: ['./address.scss'],
})
export class UPSAddress {
  @ViewChild('f') f: UntypedFormGroup;

  @Input() addressType = '';
  @Input() street1 = '';
  @Input() street2 = '';
  @Input() city = '';
  @Input() stateAbbrev = '';
  @Input() zip = '';
  @Input() isForm = false;

  @Input('group') addressForm: UntypedFormGroup;

  constructor(el: ElementRef) {}

  ngOnInit(): void {
    this.setAddressForm();
  }

  setAddressForm(): void {
    if (this.isForm) {
      this.addressForm.setValue({
        Street1: this.street1,
        Street2: this.street2,
        City: this.city,
        State: this.stateAbbrev,
        PostCode: this.zip,
      });
    }
  }

  render(): void {
    const self = this;
  }
}
