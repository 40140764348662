import { BaseModel } from '../base.model';

export class EquipmentCalibrationDto extends BaseModel {
  public EquipmentCalibrationID: string | null;
  public EMCo: number;
  public Equipment: string;
  public CalibrationFrequency: string | null;
  public CalibratedBy: string | null;
  public LastCalibrationDate: Date | null;
  public NextCalibrationDate: Date | null;
  public CalibrationNotes: string | null;
  public UniqueAttchID: string | null;
  public CreatedUserID: string;
  public CreatedUserHRRef: number | null;
  public DateCreated: Date;
  public ModifiedUserID: string | null;
  public ModifiedUserHRRef: number | null;
  public DateModified: Date | null;
  public ActiveYN: string;

  // Attachment details
  public AttachmentID: number | null;
  public FileName: string;
  public FileType: string;
  public FileBytes: number[] | ArrayBuffer;
}
