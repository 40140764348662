/* eslint-disable @typescript-eslint/naming-convention */
export class ManPowerSubRequirementLogDto {
  ManPowerRequirementId: string;
  DateModified: string;
  ModifiedUser: string;
  Name: string;
  Active: boolean;
  Mandatory: boolean;
  SafetyCouncilName: string;
  Billable: boolean;
  Notes: string;

  constructor() {
    this.DateModified = '';
    this.ModifiedUser = '';
    this.Name = '';
    this.Active = false;
    this.Mandatory = false;
    this.SafetyCouncilName = '';
    this.Billable = false;
    this.Notes = '';
  }
}
