import { Directive, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { DatePickerComponent } from '@progress/kendo-angular-dateinputs';

import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[datepickerClearButton]',
})
export class DatePickerClearButtonDirective
  implements AfterViewInit, OnDestroy
{
  public resetButton: HTMLElement;

  private changeSubscription: Subscription;

  constructor(public renderer: Renderer2, public dp: DatePickerComponent) {
    this.changeSubscription = this.dp.valueChange.subscribe(() => {
      this.toggle(!!this.dp.value);
    });
  }

  public ngAfterViewInit(): void {
    const openButton =
      this.dp.wrapper.nativeElement.querySelector('.k-input-button');
    this.resetButton = document.createElement('button');

    this.resetButton.setAttribute(
      'class',
      'k-input-button k-button k-icon-button k-button-md k-button-solid k-button-solid-base custom-clear-button'
    );
    this.resetButton.innerHTML =
      "<span id='datepicker-reset-icon' class='k-icon k-i-close'></span>";

    this.renderer.insertBefore(
      this.dp.wrapper.nativeElement,
      this.resetButton,
      openButton
    );
    this.renderer.listen(this.resetButton, 'click', this.reset.bind(this));
    this.toggle(true);
  }

  public ngOnDestroy(): void {
    this.changeSubscription.unsubscribe();
  }

  private toggle(visible: boolean): void {
    this.renderer.setStyle(
      this.resetButton,
      'visibility',
      visible ? 'visible' : 'hidden'
    );
  }

  private reset(): void {
    if (this.dp.readonly) return;

    this.dp.open.pipe(take(1)).subscribe((e) => {
      e.preventDefault();
    });

    this.dp.writeValue(null);

    this.dp.valueChange.emit(null);

    this.toggle(false);
  }
}
