<span *ngIf="hasReadPermissions && equipmentResource === null">
  Loading ...
</span>
<span *ngIf="!hasReadPermissions">
  You have no permissions to read the Equipment Resource Information.
</span>

<form
  id="equipmentResourceInfoFormId"
  #infoForm="ngForm"
  (ngSubmit)="saveEquipment(infoForm.valid)"
  *ngIf="hasReadPermissions && equipmentResource !== null"
  class="k-form k-form-vertical"
>
  <div
    *ngIf="hasReadPermissions && equipmentResource !== null"
    class="d-flex justify-content-end"
  >
    <button
      id="editEquipmentBtn"
      help-anchor="editInfoTabButton"
      *ngIf="!isEditMode && hasEditPermissions"
      type="button"
      class="k-button"
      (click)="editEquipment()"
    >
      Edit
    </button>
    <button
      id="editEquipmentSaveBtn"
      help-anchor="editEquipmentSaveBtn"
      *ngIf="isEditMode"
      type="button"
      class="k-button k-primary me-3"
      (click)="saveEquipment(infoForm.valid)"
    >
      Save
    </button>
    <button
      id="editEquipmentCancelBtn"
      *ngIf="isEditMode"
      type="button"
      class="k-button"
      (click)="cancelEquipment()"
    >
      Cancel
    </button>
  </div>

  <h4 class="mb-5">Ownership Status</h4>
  <div class="d-flex mb-7">
    <div class="me-7">
      <input
        type="radio"
        id="ownershipStatusIdOwned"
        help-anchor="ownershipStatusIdOwned"
        name="ownershipStatus"
        value="O"
        [(ngModel)]="equipmentResource.OwnershipStatus"
        [disabled]="!isEditMode"
        kendoRadioButton
        class="me-3"
      />
      <label *ngIf="isEditMode" for="ownershipStatusIdOwned">Owned</label>
      <span *ngIf="!isEditMode" for="ownershipStatusIdOwned">Owned</span>
    </div>
    <div class="me-7">
      <input
        type="radio"
        id="ownershipStatusIdLeased"
        name="ownershipStatus"
        value="L"
        [(ngModel)]="equipmentResource.OwnershipStatus"
        [disabled]="!isEditMode"
        kendoRadioButton
        class="me-3"
      />
      <label *ngIf="isEditMode" for="ownershipStatusIdLeased">Leased</label>
      <span *ngIf="!isEditMode" for="ownershipStatusIdLeased">Leased</span>
    </div>
    <div class="me-7">
      <input
        type="radio"
        id="ownershipStatusIdRented"
        name="ownershipStatus"
        value="R"
        [(ngModel)]="equipmentResource.OwnershipStatus"
        [disabled]="!isEditMode"
        kendoRadioButton
        class="me-3"
      />
      <label *ngIf="isEditMode" for="ownershipStatusIdRented">Rented</label>
      <span *ngIf="!isEditMode" for="ownershipStatusIdRented">Rented</span>
    </div>
    <div class="me-7">
      <input
        type="radio"
        id="ownershipStatusIdCustomer"
        name="ownershipStatus"
        value="C"
        [(ngModel)]="equipmentResource.OwnershipStatus"
        [disabled]="!isEditMode"
        kendoRadioButton
        class="me-3"
      />
      <label *ngIf="isEditMode" for="ownershipStatusIdCustomer">Customer</label>
      <span *ngIf="!isEditMode" for="ownershipStatusIdCustomer">Customer</span>
    </div>
  </div>
  <h4 class="mb-5">Ownership Information</h4>
  <div class="d-flex flex-wrap mb-7">
    <kendo-formfield class="me-11">
      <label>In Service Date</label>
      <ups-kendo-datepicker-shared
        id="inServiceDate"
        help-anchor="inServiceDate"
        name="inServiceDate"
        [(ngModel)]="equipmentResource.InServiceDate"
        [disabled]="!isEditMode"
        placeholder="mm/dd/yyyy"
      ></ups-kendo-datepicker-shared>
    </kendo-formfield>
    <div class="me-11">
      <label for="expectedLife">Expected Life:</label>
      <div class="d-flex">
        <kendo-numerictextbox
          id="expectedLife"
          help-anchor="expectedLife"
          name="expectedLife"
          [(ngModel)]="equipmentResource.ExpectedLife"
          [disabled]="!isEditMode"
          [decimals]="0"
          [format]="'n0'"
          [min]="0"
          [max]="99"
          class="me-7"
        ></kendo-numerictextbox>
        <kendo-dropdownlist
          id="expectedLifeTimeFrame"
          help-anchor="expectedLifeDD"
          name="expectedLifeTimeFrame"
          [(ngModel)]="equipmentResource.ExpectedLifeTimeFrame"
          [disabled]="!isEditMode"
          [data]="[
            { text: 'Y-Years', value: 'Y' },
            { text: 'M-Months', value: 'M' }
          ]"
          [textField]="'text'"
          [valueField]="'value'"
          [valuePrimitive]="true"
        ></kendo-dropdownlist>
      </div>
    </div>
    <div>
      <label for="salePrice">Sale Price:</label>
      <kendo-numerictextbox
        id="salePrice"
        help-anchor="salePrice"
        name="salePrice"
        [(ngModel)]="equipmentResource.SalePrice"
        [disabled]="!isEditMode"
        [decimals]="2"
        [format]="'n2'"
        [min]="-999999999.99"
        [max]="999999999.99"
      ></kendo-numerictextbox>
    </div>
  </div>
  <h4 class="mb-5">Information</h4>
  <div class="d-flex flex-wrap mb-7">
    <div class="me-11">
      <kendo-formfield>
        <label for="reportingType">Reporting Type</label>
        <input
          id="reportingType"
          help-anchor="reportingType"
          name="reportingType"
          [(ngModel)]="equipmentResource.ReportingType"
          [disabled]="!isEditMode"
          maxlength="15"
          noWhitespace
          kendoTextBox
        />
      </kendo-formfield>
    </div>
    <div class="me-11">
      <kendo-formfield>
        <label for="vehicleOperator">Vehicle Operator</label>
        <input
          id="vehicleOperator"
          help-anchor="vehicleOperator"
          name="vehicleOperator"
          [(ngModel)]="equipmentResource.VehicleOperator"
          [disabled]="!isEditMode"
          maxlength="60"
          noWhitespace
          kendoTextBox
        />
      </kendo-formfield>
    </div>
    <div class="me-11">
      <kendo-formfield>
        <label for="searchDescription">Search Description</label>
        <input
          id="searchDescription"
          help-anchor="searchDescription"
          name="searchDescription"
          [(ngModel)]="equipmentResource.SearchDescription"
          [disabled]="!isEditMode"
          maxlength="300"
          noWhitespace
          kendoTextBox
        />
      </kendo-formfield>
    </div>
    <div class="me-11">
      <kendo-formfield>
        <label for="selfConstructed">Self Constructed</label>
        <input
          id="selfConstructed"
          help-anchor="selfConstructed"
          name="selfConstructed"
          [(ngModel)]="equipmentResource.SelfConstructed"
          [disabled]="!isEditMode"
          maxlength="10"
          noWhitespace
          kendoTextBox
        />
      </kendo-formfield>
    </div>
    <div class="me-11">
      <kendo-formfield>
        <label for="trackLocation">Track Location</label>
        <kendo-dropdownlist
          id="trackLocation"
          help-anchor="trackLocation"
          name="trackLocation"
          [(ngModel)]="equipmentResource.TrackLocation"
          [disabled]="!isEditMode"
          [data]="[
            { text: 'Yes', value: 'Y' },
            { text: 'No', value: 'N' }
          ]"
          [textField]="'text'"
          [valueField]="'value'"
          [valuePrimitive]="true"
        ></kendo-dropdownlist>
      </kendo-formfield>
    </div>
    <div class="me-11">
      <kendo-formfield>
        <label for="currentLocation">Current Location</label>
        <input
          id="currentLocation"
          help-anchor="currentLocation"
          name="currentLocation"
          [(ngModel)]="equipmentResource.CurrentLocation"
          [disabled]="!isEditMode"
          maxlength="50"
          noWhitespace
          kendoTextBox
        />
      </kendo-formfield>
    </div>
    <div class="me-11">
      <kendo-formfield>
        <label for="loanToCompany">Loan to Company</label>
        <kendo-numerictextbox
          id="loanToCompany"
          help-anchor="loanToCompany"
          name="loanToCompany"
          [(ngModel)]="equipmentResource.LoanToCompany"
          [disabled]="!isEditMode"
          [decimals]="0"
          [format]="'n0'"
          [min]="0"
          [max]="255"
        ></kendo-numerictextbox>
      </kendo-formfield>
    </div>
    <div class="me-11">
      <kendo-formfield>
        <label for="equipmentType">Equipment Type</label>
        <kendo-combobox
          #equipmentTypeCombobox
          class="width-default"
          id="equipmentType"
          help-anchor="equipmentTypeWindow"
          name="equipmentType"
          [disabled]="!isEditMode"
          [placeholder]=""
          [valuePrimitive]="true"
          [textField]="'Name'"
          [valueField]="'ID'"
          [data]="equipmentTypesFiltered"
          [filterable]="true"
          (filterChange)="handleEquipmentTypeFilter($event)"
          [(ngModel)]="equipmentResource.EquipmentTypeID"
        ></kendo-combobox>
      </kendo-formfield>
    </div>
  </div>
  <h4 class="mb-5">Fixed Asset</h4>
  <div class="d-flex">
    <div class="me-11">
      <label for="fixedAssetId">Fixed Asset Id:</label>
      <input
        id="fixedAssetId"
        help-anchor="fixedAssetId"
        name="fixedAssetId"
        [(ngModel)]="equipmentResource.FixedAssetId"
        [disabled]="!isEditMode"
        maxlength="10"
        noWhitespace
        kendoTextBox
      />
    </div>
    <div class="me-11">
      <label for="fixedAssetStatus">Fixed Asset Status</label>
      <kendo-dropdownlist
        id="fixedAssetStatus"
        help-anchor="fixedAssetStatus"
        name="fixedAssetStatus"
        class="width-default"
        [(ngModel)]="equipmentResource.FixedAssetStatus"
        [disabled]="!isEditMode"
        [data]="fixedAssetStatuses"
        [textField]="'text'"
        [valueField]="'id'"
        [valuePrimitive]="true"
      ></kendo-dropdownlist>
    </div>
    <div>
      <label for="assetType">Asset Type</label>
      <kendo-dropdownlist
        id="assetType"
        help-anchor="assetTypeDD"
        name="assetType"
        class="width-default"
        [(ngModel)]="equipmentResource.AssetType"
        [disabled]="!isEditMode"
        [data]="fixedAssetTypes"
        [textField]="'text'"
        [valueField]="'id'"
        [valuePrimitive]="true"
        [ignoreCase]="true"
      ></kendo-dropdownlist>
    </div>
  </div>
</form>
