import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';

import { AnnouncementsPopupComponent } from './announcements-popup/announcements-popup.component';
import { PastReleasesTableComponent } from './past-releases-table/past-releases-table.component';
import { ReleaseNotePopupComponent } from './release-note-popup/release-note-popup.component';
import { ReleaseNoteComponent } from './release-note.component';
import { ShowReleaseButtonDirective } from './show-release-button.directive';

@NgModule({
  declarations: [
    ReleaseNotePopupComponent,
    ReleaseNoteComponent,
    ShowReleaseButtonDirective,
    PastReleasesTableComponent,
    AnnouncementsPopupComponent,
  ],
  imports: [
    CommonModule,
    DialogModule,
    GridModule,
    LayoutModule,
    ButtonsModule,
  ],
  exports: [
    ReleaseNotePopupComponent,
    ReleaseNoteComponent,
    ShowReleaseButtonDirective,
    PastReleasesTableComponent,
    AnnouncementsPopupComponent,
  ],
})
export class ReleaseNoteModule {}
