import {
  LogicalExpressionEvaluator,
  LogicalExpressionType,
  isUUID,
} from '@ups/xplat/utils';

import { SecurityService } from './security.service';

export type ViewFeatureExpressionType = LogicalExpressionType<boolean | string>;

/**
 * Security checks for viewFeature permissions.
 *
 * The expression allows boolean and string parameters.
 * The expression structure is based on the {@link LogicalExpressionType}, see also: {@link LogicalExpressionEvaluator}.
 *
 * Expression samples:
 *  true
 *  "security_id"
 *  [true]
 *  [false, "security_id"]
 *  ["security_id_one", "security_id_two"]
 *  { or: ["security_id_one", "security_id_two"]}
 *  { and: [ "sexurity_id_main", { or: ["security_id_one", "security_id_two"]} ]}
 */
export class ViewFeatureExpression {
  protected static viewFeatureSecurityEvaluatorFnc = (
    expr: ViewFeatureExpressionType,
    security: SecurityService
  ): boolean => {
    if (expr == null || expr === undefined) return true;

    if (typeof expr === 'boolean') {
      return expr;
    } else if (typeof expr === 'string') {
      if (isUUID(expr)) {
        return security.getFeatureById(expr).viewFeature;
      } else {
        return security.getFeatureByUniqName(expr).viewFeature;
      }
    } else {
      throw new Error(
        `Unsupported value '${expr}' passed to 'ViewFeatureExpression.viewFeatureSecurityEvaluatorFnc()'!`
      );
    }
  };

  public static evaluate(
    expr: ViewFeatureExpressionType,
    security: SecurityService
  ): boolean {
    return LogicalExpressionEvaluator.evaluate(
      expr,
      (value: ViewFeatureExpressionType) =>
        ViewFeatureExpression.viewFeatureSecurityEvaluatorFnc(value, security)
    );
  }
}
