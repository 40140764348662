import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpsMessageComponent } from './message-component';

@NgModule({
  imports: [CommonModule],
  declarations: [UpsMessageComponent],
  exports: [UpsMessageComponent],
})
export class UpsMessageModule {}
