export class CommonDateFunctions {
  /**
   * This method can be used e.g. to solve problem where Date gets translated to UTC during original JSON.stringify().
   * When overriding the toJson() method and sending as string we'll avoid the problem
   * Changes date to string: mm/dd/yyyy
   */
  public static ToDateString(date: Date): string {
    return date == null ? null : date == null ? null : date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
  }

  public static getNextSunday(): Date {
    const today = new Date();
    const nextSunday = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (7 - today.getDay()));
    return nextSunday;
  }

  public static getNearestSunday(): Date {
    const today = new Date();
    if (today.getDay() < 4) {
      return new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
    }

    return new Date(today.getFullYear(), today.getMonth(), today.getDate() + (7 - today.getDay()));
  }

  public static addHours(date: Date, hours: number): Date {
    const newDate: Date = new Date(date);
    newDate.setHours(date.getHours() + hours);
    return newDate;
  }

  public static addDays(date: Date, days: number): Date {
    let newDate = new Date(date);
    const originalHours = date.getHours();
    newDate = CommonDateFunctions.addHours(newDate, 1); // first we add 1 hours to deal with daylight saving time (DST) because when date is in DST and by adding/removing hours the result won't be in DST the hours will be e.g. not 1.1.2015 but 31.12.2014 23:00. So adding here hour and then removing one hour
    let val = newDate.valueOf();
    val += 86400000 * days;
    val = new Date(val).setHours(originalHours); // we put here the original hours. Otherwise if in DST the hours would be +1.
    return new Date(val);
  }
}
