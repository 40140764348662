import { BaseModel } from '../base.model';

export class EmergencyContactModel extends BaseModel {
  HRRef: number;
  KeyID: number;
  Contact: string;
  HomePhone: string;
  CellPhone: string;
  Relationship: string;
  IsToDelete: boolean;

  constructor(o: any = null) {
    super(o);
  }
}
