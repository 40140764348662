<input
    [(ngModel)]="model"
    multiColumnSearch
    [mcsDebounceFilterChange]="mcsDebounceFilterChange"
    [mcsSearchColumns]="mcsSearchColumns"
    (mcsFilterChange)="filterChange($event)"

    class="k-textbox multi-search"
    style="float:right;width:20em"
    [attr.placeholder]="placeholder"
    [attr.title]="title"
/>