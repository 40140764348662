import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorSuccessService {
  isErrorObject: EventEmitter<any> = new EventEmitter<any>();
  isSuccessObject: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  setError(data: any) {
    this.isErrorObject.emit(data);
  }

  setSuccess(data: any) {
    this.isSuccessObject.emit(data);
  }
}
