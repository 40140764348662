import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styles: [
    `
      .star {
        cursor: pointer;
        transition: color 0.2s ease-in-out;
        color: var(--color-primary);
      }

      .star:hover,
      .star.previous-hovered {
        color: gold;
      }
    `,
  ],
})
export class StarRatingComponent {
  @Input() set rating(_rating: number) {
    this.setRating(_rating, true);
    this.lastHoveredStar = null;
  }
  @Input() editable = false;
  @Output() ratingChange = new EventEmitter<number>();

  currentRating = 0;
  maxRating = 5;

  filledStars: number[] = [];
  emptyStars: number[] = [];
  hasHalfStar = false;

  allFilledStarsLength = 0;
  lastHoveredStar: number | null = null;

  setRating(rating: number, isInit = false) {
    if (this.editable || isInit) {
      this.currentRating = rating;

      this.hasHalfStar = this.currentRating % 1 !== 0;
      this.filledStars = Array(Math.floor(this.currentRating))
        .fill(0)
        .map((x, i) => i);
      this.emptyStars = Array(this.maxRating - Math.ceil(this.currentRating))
        .fill(0)
        .map((x, i) => i);

      this.allFilledStarsLength = this.filledStars.length;
      if (this.hasHalfStar) {
        this.allFilledStarsLength++;
      }
      this.ratingChange.emit(this.currentRating);
    }
  }

  hoverStar(index: number | null) {
    this.lastHoveredStar = index;
  }
}
