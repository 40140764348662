import {
  AfterViewInit,
  Component,
  HostListener,
  ViewChild,
} from '@angular/core';

import { GridDataResult } from '@progress/kendo-angular-grid';
import {
  GroupingExViewModel,
  GroupingExDirective,
  GroupStateUtils,
  GroupResultFlattener,
  GroupResultSelectionSupport,
} from '../../../kendo/Grid';
import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import { ODataReportingUtils, ODataResult } from '@ups/xplat/features';
import { EquipmentService } from '@ups/xplat/api/services';
import { of } from 'rxjs';
import { EquipmentDispatchEditComponent } from '../equipment-dispatch-edit/equipment-dispatch-edit.component';
import { EquipmentDispatchHistoryComponent } from '../equipment-dispatch-history/equipment-dispatch-history.component';
import { EquipmentBillingRatesComponent } from '../equipment-billing-rates/equipment-billing-rates.component';
import { JobDropdown, EquipmentDispatchDto } from '@ups/xplat/api/dto';
import * as moment from 'moment';
import { FilterUtils } from '../../..';
import { BaseComponent } from '@ups/xplat/core';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import { MessageHelper } from '@ups/xplat/web/core';

@Component({
  selector: 'ups-equipment-dispatch-page',
  templateUrl: 'equipment-dispatch-page.component.html',
})
export class EquipmentDispatchPageComponent
  extends BaseComponent
  implements AfterViewInit
{
  @ViewChild(GroupingExDirective) groupingEx: GroupingExDirective;
  @ViewChild('grid') public grid;

  @ViewChild(EquipmentDispatchEditComponent)
  editModal: EquipmentDispatchEditComponent;
  @ViewChild(EquipmentDispatchHistoryComponent)
  historyModal: EquipmentDispatchHistoryComponent;
  @ViewChild(EquipmentBillingRatesComponent)
  billingRatesModal: EquipmentBillingRatesComponent;

  canModify = false;
  canRead = false;
  canSeeEquipmentHyperlink = false;
  gridSearchValue: string;
  gridState: State = {
    take: 25,
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [{ field: 'EquipmentName', dir: 'asc' }],
  };
  hasSelection: boolean;
  isLoading = false;
  securityConstants = SecurityConstants;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedBillingTemplate: any;
  selectedJob: JobDropdown;
  selectedRecord: EquipmentDispatchDto[];
  templateHistory = [];
  view: GridDataResult;
  viewModel: GroupingExViewModel;

  height: number;
  pixelsToFitTable = 170;

  constructor(
    public security: SecurityService,
    private msgHelper: MessageHelper,
    private equipmentService: EquipmentService
  ) {
    super();

    this.canSeeEquipmentHyperlink = this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_equipmentresourcehyperlink
    ).readAll;
    const fSecurity = this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_equipmentdispatchgrid
    );
    this.canModify = fSecurity.editAll;
    this.canRead = fSecurity.readAll;

    this.viewModel = new GroupingExViewModel(
      (odataQuery) => {
        if (this.canRead && this.selectedJob && this.selectedBillingTemplate) {
          return this.equipmentService.queryEquipmentDispatchData(
            this.selectedJob.Id,
            this.selectedBillingTemplate.value,
            odataQuery
          );
        } else {
          return of(ODataResult.EmptyODataResult);
        }
      },
      () => false
    );
    this.viewModel.loadDataOnInit = true;

    const dataString = sessionStorage.getItem('EquipmentDispatchData');
    if (dataString) {
      const data = JSON.parse(dataString);
      if (data.selectedJob) {
        this.selectedJob = data.selectedJob;
        this.loadTemplateHistory();
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.height = event.target.innerHeight;
    if (this.height < 400) {
      this.height = 400;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.height = window.innerHeight;
    }, 1010);
  }

  gridDataStateChange(state: State): void {
    state = GroupStateUtils.consolidateSortOrder(this.gridState, state);

    for (const flt of state.filter.filters) {
      if (flt['filters']) {
        for (const f of flt['filters']) {
          if (f.operator === 'contains') {
            f.ignoreCase = true;
          }
        }
      }
    }

    this.gridState = state;
    this.loadData();
  }

  selectionChange() {
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const gridData = this.grid.data as any;
      const gridItems = gridData.data;

      this.selectedRecord = GroupResultFlattener.getFlattenedItemsWithDetails(
        gridItems,
        null
      )
        .map((i) => i.dataItem)
        .filter(
          (i) => i[GroupResultSelectionSupport.selectionStateProperty]?.checked
        );

      this.hasSelection = this.selectedRecord.length > 0;
    }, 100);
  }

  loadData() {
    const stateCopy = JSON.parse(JSON.stringify(this.gridState)) as State;

    // extra filters
    if (this.viewModel.extraFilter) {
      stateCopy.filter.filters.push(this.viewModel.extraFilter);
    }

    // load
    if (this.groupingEx) {
      this.selectedRecord = null;
      this.groupingEx.loadData(stateCopy);
    }
  }

  clearFilters() {
    this.gridState.filter = <CompositeFilterDescriptor>{
      logic: 'and',
      filters: [],
    };

    if (this.gridSearchValue === '') {
      this.gridDataStateChange(this.gridState);
    } else {
      this.gridSearchValue = '';
    }
  }

  extraFilterStateChange(filter: CompositeFilterDescriptor) {
    if (this.gridSearchValue !== undefined) {
      this.gridState.skip = 0;
      this.viewModel.extraFilter = filter;
      this.loadData();
    }
  }

  valueChangeJob(job) {
    this.selectedJob = job;
    this.updateSessionStorageData();
    this.loadTemplateHistory();
  }

  valueChangeTemplate() {
    this.updateSessionStorageData();
    this.loadData();
  }

  loadTemplateHistory() {
    if (this.selectedJob && this.selectedJob.Id) {
      this.equipmentService
        .getEquipmentTemplateHistory(this.selectedJob.Id)
        .toPromise()
        .then((d) => {
          this.templateHistory = d.map((t) => {
            return {
              text: `${
                t.EquipmentBillingTemplate
              } [Template Last Updated: ${moment(t.DateModified).format(
                'MM/DD/YYYY'
              )}, Effective Date: ${t.EffectiveDate}]`,
              value: t.EquipmentBillingTemplate,
            };
          });
          this.selectedBillingTemplate = this.templateHistory[0];
          this.loadData();
        });
    } else {
      this.templateHistory = [];
      this.selectedBillingTemplate = null;
      this.loadData();
    }
  }

  updateSessionStorageData() {
    const dataString = sessionStorage.getItem('EquipmentDispatchData');
    const data = dataString ? JSON.parse(dataString) : {};
    data.selectedJob = this.selectedJob;
    data.selectedBillingTemplate = this.selectedBillingTemplate;
    sessionStorage.setItem('EquipmentDispatchData', JSON.stringify(data));
  }

  delete(equipmentDispatches: EquipmentDispatchDto[]) {
    equipmentDispatches.forEach((ed) => (ed.bActive = false));

    this.equipmentService
      .bulkEditEquipmentDispatch(equipmentDispatches)
      .toPromise()
      .then(
        () => {
          this.loadData();
          this.msgHelper.success('Equipment Dispatch deleted successfully!');
        },
        (error) => {
          const e = error.error;
          this.msgHelper.error(
            (e.messages && e.messages.length && e.messages.join('|')) || error
          );
          equipmentDispatches.forEach((ed) => (ed.bActive = true));
        }
      );
  }

  get getXlsxDownloadUri(): string {
    if (!this.selectedJob) return '';

    if (this.selectedBillingTemplate) {
      const stateWithExtra = {
        filter: FilterUtils.concatFilters(
          this.gridState.filter,
          this.viewModel.extraFilter
        ),
      } as State;
      stateWithExtra.sort = stateWithExtra.sort || [];

      let uri = this.equipmentService.getEquipmentDispatchUriInclServer(
        this.selectedJob.Id,
        this.selectedBillingTemplate.value
      );
      uri +=
        '&' +
        ODataReportingUtils.getXlsxDowloadQueryString(
          this.grid,
          stateWithExtra,
          ['Log', 'Rates'],
          `equipment_dispatch_${this.selectedJob.JobID.replace('.', '_')}.xlsx`
        );

      return uri;
    } else {
      return '';
    }
  }

  openEditModal() {
    if (this.selectedRecord.length === 1) {
      this.editModal.open(this.selectedRecord[0]);
    } else {
      this.editModal.onBulkEdit(
        this.selectedJob?.CompanyID,
        this.selectedRecord
      );
    }
  }
}
