import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TableviewComponent } from './components/tableview/tableview.component';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { AllowShortYearFormatDirective } from './directives/allow-short-year-format.directive';
import { PortalPipesModule } from './pipes/_portal-pipes.module';
import { PipesModule } from './pipes/_pipes.module';
import { TextFilterModule } from './components/text-filter/text-filter.module';
import { CheckboxFilterModule } from './components/checkbox-filter/checkbox-filter.module';
import { NoWhitespaceDirective } from './directives/no-whitespace.directive';

@NgModule({
  declarations: [
    // Components / Directives/ Pipes
    TableviewComponent,
    AllowShortYearFormatDirective,
    NoWhitespaceDirective,
  ],
  exports: [
    TableviewComponent,
    AllowShortYearFormatDirective,
    NoWhitespaceDirective,
  ],
  providers: [],
  imports: [
    CommonModule,
    FormsModule,

    PipesModule,
    PortalPipesModule,
    TextFilterModule,
    CheckboxFilterModule,

    ButtonsModule,
    DialogModule,
    InputsModule,
    GridModule,
  ],
})
export class TablesModule {}
