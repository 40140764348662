export class FileDto {
  fileID: string;
  name: string;
  directory: string;
  contentType: string;
  description: string;
  bActive: boolean;
  size: number;
  userID: string;
  content: number[] | ArrayBuffer; // ?? Uint8Array, Int8Array, Uint8Array, Uint8ClampedArray, Int16Array, Uint16Array, Int32Array, Uint32Array, Float32Array, Float64Array, BigInt64Array, BigUint64Array - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Int8Array
  contentBase64: string; // If you set contentBase64 property instead of content property then the backend api will transform it to content instead of you
}

/* eslint-disable @typescript-eslint/naming-convention */
export class FileDtoPascalCase {
  FileID: string;
  Name: string;
  Directory: string;
  ContentType: string;
  Description: string;
  bActive: boolean;
  Size: number | null;
  UserID: string;
  Content: string;
  ContentBase64: string;
}
/* eslint-enable @typescript-eslint/naming-convention */
