import { BaseModel } from '../base.model';

export class AttachmentDto extends BaseModel {
  public attachmentID: number;
  public documentName: string;

  constructor(o: any = null) {
    super(o);
  }
}
