import { Injectable } from '@angular/core';

/**
 * xplat export service
 * Meant to provide consistent API for all cross platform UPS export needs
 * Each platform provides this with it's own implementation in the platform CoreModule
 */
export interface IExportComponent {
  fileName?: string;
  group?: unknown;
  data?: unknown;
  workbookOptions?: () => {
    sheets: Array<{
      rows?: Array<{
        type?: string;
        cells?: Array<{ value?: unknown; textAlign?: string }>;
      }>;
    }>;
  };
  save?: (data?: unknown) => void;
}

export interface IExportService {
  exportAndDownload?: (
    filename: string,
    type: 'excel',
    component?: IExportComponent
  ) => void;
}

@Injectable({
  providedIn: 'root',
})
export class ExportService implements IExportService {
  exportAndDownload(
    filename: string,
    type: 'excel',
    config?: { group?: unknown; data?: unknown },
    component?: IExportComponent
  ) {
    // each platform can provide it's own platform specific export handling
    console.log(
      'ExportService',
      'if you see this, platform implementation needed.'
    );
  }
}
