import { Component, OnInit } from '@angular/core';
import { DynamicItemBaseComponent } from '@ups/xplat/features';

@Component({
  selector: 'ups-dynamic-numerictextbox',
  template: `
    <div class="dynamic-field-container" [class.selected]="config?.selected">
      <div class="dynamic-field form-input text-align" [formGroup]="group">
        <ups-dynamic-helper-label
          [config]="config"
          [dynamicService]="dynamicService"
        ></ups-dynamic-helper-label>
        <div>
          <kendo-numerictextbox
            [value]="numValue()"
            [min]="0"
            format="#"
            [decimals]="config?.options?.decimals || 0"
            [autoCorrect]="autoCorrect"
            [formControlName]="config.formControlName"
          ></kendo-numerictextbox>
        </div>
        <div
          class="form-builder-mask"
          [class.hidden]="config?.options?.hidden"
          *ngIf="config?.isFormBuilder"
        ></div>
      </div>
      <div class="form-builder-options" *ngIf="config?.isFormBuilder">
        <i
          class="fas fa-times ml-3 cursor-pointer c-red"
          (click)="delete()"
        ></i>
        <!-- <i class="fas fa-bars ml-3 cursor-grab "></i> -->
      </div>
    </div>
  `,
})
export class DynamicNumericTextboxComponent
  extends DynamicItemBaseComponent
  implements OnInit
{
  public autoCorrect = false;
  // public value = 5;

  numValue() {
    return this.config?.value ? Number(this.config?.value) : null;
  }
}
