<div class="mb-5 mt-0" *ngIf="isLoadingFilterData; else doesDataExists">
  <span>
    <i class="fa fa-spinner fa-spin"></i>
    Loading filter data ...
  </span>
</div>
<ng-template #doesDataExists>
  <div *ngIf="data?.length === 0; else showOptions">
    <span class="ms-3 mb-5 mt-0">
      Only {{ customBlankOptionText ? customBlankOptionText : 'Blank' }} entries
      exist in this column.
    </span>
  </div>
</ng-template>
<ng-template #showOptions>
  <ul [ngStyle]="{ height: height + 'px' }">
    <li *ngIf="showFilter || showFilterOperators">
      <kendo-dropdownlist
        *ngIf="showFilterOperators"
        [(ngModel)]="dataItemSearchOperator"
        [data]="dataItemSearchOptions"
        [textField]="'text'"
        [valueField]="'value'"
        [valuePrimitive]="true"
        (selectionChange)="dataItemSearchFilterOperatorChange($event)"
      ></kendo-dropdownlist>
      <input
        *ngIf="showFilter"
        class="k-textbox"
        [(ngModel)]="dataItemFilterText"
        (ngModelChange)="dataItemFilterTextChange($event)"
      />
    </li>
    <li *ngIf="addSelectAllOption">
      <input
        type="checkbox"
        id="chk-select-all"
        class="k-checkbox"
        kendoCheckBox
        [ngModel]="areAllItemsSelected()"
        (ngModelChange)="onSelectAllChange($event)"
      />
      <label
        class="k-checkbox-label k-checkbox-label-patch"
        for="chk-select-all"
      >
        Select All
      </label>
    </li>
    <li *ngFor="let item of filteredDataItems">
      <input
        type="checkbox"
        id="chk-{{ valueAccessor(item) }}"
        class="k-checkbox"
        kendoCheckBox
        [ngModel]="isItemSelected(item)"
        (ngModelChange)="onSelectionChange($event, item)"
      />
      <label
        class="k-checkbox-label k-checkbox-label-patch"
        for="chk-{{ valueAccessor(item) }}"
      >
        {{ textAccessor(item) }}
      </label>
    </li>
  </ul>
</ng-template>
