import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { forkJoin, of } from 'rxjs';

import {
  GridDataResult,
  DataStateChangeEvent,
} from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { EquipmentCalibrationDto } from '@ups/xplat/api/dto';
import { EquipmentVpService } from '@ups/xplat/web/core';
import { BaseComponent } from '@ups/xplat/core';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import { HRRMService, VpService } from '@ups/xplat/api/services';
import { EmployeeGuardService } from '@ups/xplat/base/employee-profile';

@Component({
  selector: 'ups-equipment-calibrations',
  templateUrl: './equipment-calibrations.component.html',
  // styleUrls: ['./equipment-calibrations.component.scss'],
})
export class EquipmentCalibrationsComponent
  extends BaseComponent
  implements OnChanges
{
  @Input() vpCompanyID: number;
  @Input() equipmentName: string;
  @Input() keyId: number;

  calibrationHistory = [];
  latestEquipmentCalibration: EquipmentCalibrationDto;
  calibrationFrequencyDropdownItems = [];
  calibratedByDropdownItems = [];
  today: Date = new Date();

  public gridData: GridDataResult;
  public state: State = {
    skip: 0,
    take: 10,
    sort: [{ field: 'LastCalibrationDate', dir: 'desc' }],
  };

  isEditMode = false;
  isEditCalibrationMode = false;
  isLoading = false;
  hasReadPermissions = false;
  hasEditPermissions = false;
  selectedCalibrationItem: EquipmentCalibrationDto;
  wasNextCalibrationAutoCalculated = false;

  constructor(
    private security: SecurityService,
    private vpService: VpService,
    private hrrmService: HRRMService,
    private equipmentVpService: EquipmentVpService,
    private guardService: EmployeeGuardService
  ) {
    super();
    this.hasReadPermissions = this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_calibrationstab
    ).readAll;
    this.hasEditPermissions = this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_calibrationstab
    ).editAll;
    this.today.setHours(23, 59, 59, 999);
  }

  ngOnChanges() {
    this.resetValues();
    this.gridData = process(this.calibrationHistory, this.state);
    this.loadData();
  }

  resetValues() {
    this.calibrationHistory = [];
    this.latestEquipmentCalibration = null;
    this.selectedCalibrationItem = null;
    this.isEditMode = false;
    this.isEditCalibrationMode = false;
    this.guardService.isToUseGuard = false;
    this.wasNextCalibrationAutoCalculated = false;
    this.isLoading = false;
  }

  loadData() {
    if (this.vpCompanyID && this.equipmentName && this.hasReadPermissions) {
      this.isLoading = true;
      forkJoin([
        this.equipmentVpService.getEquipmentCalibrations(
          this.vpCompanyID,
          this.equipmentName
        ),
        this.equipmentVpService.getLatestEquipmentCalibration(
          this.vpCompanyID,
          this.equipmentName
        ),
        this.calibrationFrequencyDropdownItems.length
          ? of({ value: this.calibrationFrequencyDropdownItems })
          : this.vpService.getDropdownItems('udFrequency'),
        this.calibratedByDropdownItems.length
          ? of({ value: this.calibratedByDropdownItems })
          : this.vpService.getDropdownItems('Inspect'),
      ]).subscribe(
        (d) => {
          this.calibrationHistory = d[0].value;
          this.gridData = process(this.calibrationHistory, this.state);
          this.latestEquipmentCalibration = d[1];
          this.calibrationFrequencyDropdownItems = d[2].value;
          this.calibratedByDropdownItems = d[3].value;
          this.isLoading = false;
        },
        (error) => {
          console.error(error);
          this.resetValues();
          throw error;
        }
      );
    }
  }

  editEquipment() {
    if (this.hasEditPermissions) {
      this.isEditMode = true;
      this.guardService.isToUseGuard = true;
    }
  }

  cancelEquipment(isFormDirty: boolean) {
    if (isFormDirty) {
      this.resetValues();
      this.loadData();
    } else {
      this.isEditMode = false;
      this.guardService.isToUseGuard = false;
    }
  }

  saveEquipment(isFormValid: boolean) {
    if (!isFormValid) {
      window.alert('Calibration Frequency has invalid value.');
      return;
    }

    if (this.latestEquipmentCalibration) {
      this.isLoading = true;

      this.equipmentVpService
        .saveHiddenEquipmentCalibrationItem(this.latestEquipmentCalibration)
        .subscribe(
          () => {
            this.resetValues();
            this.loadData();
          },
          (error) => {
            console.error(error);
            this.isLoading = false;
            throw error;
          }
        );
    }
  }

  addCalibration() {
    this.selectedCalibrationItem = new EquipmentCalibrationDto();
    this.selectedCalibrationItem.EMCo = this.latestEquipmentCalibration.EMCo;
    this.selectedCalibrationItem.Equipment =
      this.latestEquipmentCalibration.Equipment;
    this.isEditCalibrationMode = true;
  }

  editCalibration(calibrationItem: EquipmentCalibrationDto) {
    this.selectedCalibrationItem = calibrationItem;
    this.isEditCalibrationMode = true;
  }

  removeCalibration(calibrationItem: EquipmentCalibrationDto) {
    if (confirm('Do you want to delete the calibration item?')) {
      calibrationItem.ActiveYN = 'N';
      this.equipmentVpService
        .saveEquipmentCalibrationItem(calibrationItem)
        .subscribe(
          () => {
            this.resetValues();
            this.loadData();
          },
          (error) => {
            console.error(error);
            calibrationItem.ActiveYN = 'Y';
            this.isLoading = false;
            throw error;
          }
        );
    }
  }

  saveCalibration(isFormValid: boolean) {
    if (!isFormValid) {
      window.alert('Some fields in the form are not filled properly.');
      return;
    }

    this.isLoading = true;
    this.isEditCalibrationMode = false;

    this.equipmentVpService
      .saveEquipmentCalibrationItem(this.selectedCalibrationItem)
      .subscribe(
        () => {
          this.resetValues();
          this.loadData();
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
          this.isEditCalibrationMode = true;
          throw error;
        }
      );
  }

  cancelCalibration(isFormDirty: boolean) {
    if (!this.isLoading) {
      if (isFormDirty) {
        this.resetValues();
        this.loadData();
      } else {
        this.selectedCalibrationItem = null;
        this.isEditCalibrationMode = false;
      }
    }
  }

  getCalibratedByText(value: string): string {
    const calibratedBy = this.calibratedByDropdownItems.find(
      (i) => i.id === value
    );
    if (!calibratedBy) {
      return value;
    }

    return calibratedBy.text;
  }

  lastCalibrationDateChanged(
    lastCalibrationDateDropdownControl: UntypedFormControl
  ) {
    if (
      lastCalibrationDateDropdownControl.valid &&
      (!this.selectedCalibrationItem.NextCalibrationDate ||
        this.wasNextCalibrationAutoCalculated)
    ) {
      this.wasNextCalibrationAutoCalculated = true;
      const nextCalibrationDate: Date = this.getNextCalibrationDate(
        lastCalibrationDateDropdownControl.value
      );
      this.selectedCalibrationItem.NextCalibrationDate =
        nextCalibrationDate == null
          ? this.selectedCalibrationItem.NextCalibrationDate
          : nextCalibrationDate;
    }
  }

  getNextCalibrationDate(lastCalibrationDate: Date): Date {
    if (this.latestEquipmentCalibration.CalibrationFrequency) {
      const newNextCalibrationDate: Date = new Date(lastCalibrationDate);
      switch (this.latestEquipmentCalibration.CalibrationFrequency) {
        case 'Annually': {
          newNextCalibrationDate.setFullYear(
            lastCalibrationDate.getFullYear() + 1
          );
          break;
        }
        case 'Montly': {
          newNextCalibrationDate.setMonth(lastCalibrationDate.getMonth() + 1);
          break;
        }
        case 'Quarterly': {
          newNextCalibrationDate.setMonth(lastCalibrationDate.getMonth() + 3);
          break;
        }
        case 'Quarterly / Per Use': {
          newNextCalibrationDate.setMonth(lastCalibrationDate.getMonth() + 3);
          break;
        }
        case 'Annually / Per Use': {
          newNextCalibrationDate.setFullYear(
            lastCalibrationDate.getFullYear() + 1
          );
          break;
        }
      }

      return newNextCalibrationDate;
    }

    return null;
  }

  dataStateChange(state: DataStateChangeEvent) {
    this.state = state;
    this.gridData = process(this.calibrationHistory, this.state);
  }

  async selectFile(evt) {
    const fileList = evt.target.files;

    if (fileList.length > 0) {
      const file: File = fileList[0];
      const myReader: FileReader = new FileReader();

      myReader.onload = () => {
        if (typeof myReader.result === 'string') {
          this.selectedCalibrationItem.FileBytes =
            this.convertBase64ToByteArray(myReader.result);
        } else {
          this.selectedCalibrationItem.FileBytes = myReader.result;
        }

        this.selectedCalibrationItem.FileName = file.name;
        this.selectedCalibrationItem.FileType = 'EMEMCalibration';
      };

      // this causes the myReader.onload() callback to be called defined above
      myReader.readAsDataURL(file);
    }
  }

  removeAttachment() {
    this.selectedCalibrationItem.FileName = null;
    this.selectedCalibrationItem.FileType = null;
    this.selectedCalibrationItem.FileBytes = null;
    this.selectedCalibrationItem.AttachmentID = null;
    this.selectedCalibrationItem.UniqueAttchID = null;
  }

  public convertBase64ToByteArray(base64: string) {
    const explicit64 = base64.split(',')[1];
    const byteCharacters = atob(explicit64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    return byteNumbers;
  }

  public downloadFile(dataItem: EquipmentCalibrationDto) {
    this.hrrmService.downloadAttachment({
      attachmentID: dataItem.AttachmentID,
      documentName: dataItem.FileName,
    });
  }
}
