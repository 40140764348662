import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import {
  BaseComponent,
  environment,
  LocalStorageService,
} from '@ups/xplat/core';
import { getAppVersionInfo } from '@ups/xplat/web/utils';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ups-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  // causes a lot of extra login screens, use an auth guard on layout route for same effect.
  autoLogin = false;
  applicationName = 'UPS1 App';
  displayName = '';
  version: string;
  buildNumber: string;
  environment = environment;

  isSidebarExpanded = false;
  isSidebarPinned = false;

  @ViewChild('sidebarLeft') sidebarLeft: ElementRef;
  @ViewChild('sidebarRight') sidebarRight: ElementRef;

  constructor(private auth: AuthService, private storage: LocalStorageService) {
    super();
    const info = getAppVersionInfo();
    if (info.versionName) {
      this.version = `v${info.versionName}`;
    } else {
      this.version = 'local';
    }
    if (info.buildNumber) {
      this.buildNumber = `(${info.buildNumber})`;
    } else {
      this.buildNumber = '';
    }
  }

  ngOnInit(): void {
    this.applicationName = environment.sidebarName ?? environment.name;
    this.displayName = environment.displayName || '';

    if (this.autoLogin) {
      // see notes on the app-routing.ts
      this.auth.isAuthenticated$
        .pipe(
          takeUntil(this.destroy$),
          filter((x) => !x)
        )
        .subscribe(() => this.auth.loginWithRedirect());
    }
  }

  ngAfterViewInit(): void {
    // Auto remove statically assigned open state if viewport is too small (e.g. when we start on a mobile)
    const vw = document.body.clientWidth;

    if (vw < 720) {
      this.sidebarLeft?.nativeElement?.classList.remove('open');
      this.sidebarRight?.nativeElement?.classList.remove('open');
    }

    this.detectSidebarStatus();
  }

  detectSidebarStatus() {
    this.isSidebarPinned = !!this.storage.getItem('sidebarPinned');
    if (this.isSidebarPinned) {
      this.isSidebarExpanded = true;
    }
  }

  toggleSidebarStatus() {
    this.isSidebarExpanded = !this.isSidebarExpanded;
  }

  togglePinSidebar() {
    this.isSidebarPinned = !this.isSidebarPinned;
    this.storage.setItem('sidebarPinned', this.isSidebarPinned);
  }
}
