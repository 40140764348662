/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/no-output-rename */
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as _ from 'lodash';

import { Directive, EventEmitter, Input, Output, OnInit } from '@angular/core';

import { ReplaySubject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { GroupResult, State } from '@progress/kendo-data-query';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';

import { StateUtils } from './../../Data-Query/state-utils';
import { FilterUtils } from './../../Data-Query/filter-utils';

import { PathSegmentDetached } from './../Group-Result/path-segment-detached';

import { GroupCollapseExpandUtils } from './Utils/group-collapse-expand-utils';

import { GroupingExViewModel } from './grouping-ex.view-model';
import {
  GroupingExDataStateSupport,
  IGroupingExDataState,
} from './grouping-ex.data-state.support';
import { GroupingExLoad, GroupingExLoadResult } from './grouping-ex.load';
import { GroupingExLoadSpecificGroups } from './grouping-ex.load-specific-groups';
import { BaseComponent, LogService } from '@ups/xplat/core';

@Directive({
  selector: '[grid-grouping-ex]',
})
export class GroupingExDirective extends BaseComponent implements OnInit {
  /** This event is needed for the selection.directive to do patch all processed data items... */
  @Output('ggx-dataLoadedEvent')
  public dataLoadedEvent: EventEmitter<GroupingExLoadResult> = new EventEmitter<GroupingExLoadResult>();
  @Input('ggx-viewModel') public viewModel: GroupingExViewModel;

  loadDataSpecialState: IGroupingExDataState;

  loadDataL1Subscription: Subscription;
  loadDataL2Subscription: Subscription;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(private log: LogService, public grid: GridComponent) {
    super();
    this.grid.groupExpand
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.internalGroupExpand.bind(this));
  }

  public ngOnInit(): void {
    // TODO: check whether load-data is still required, as the state-change might do the trick

    // load data
    if (this.viewModel && this.viewModel.loadDataOnInit) {
      const gridState = {
        skip: this.grid.skip,
        take: this.grid.pageSize,
        sort: this.grid.sort,
        filter: this.grid.filter,
        group: this.grid.group,
      } as State;

      const stateCopy = _.cloneDeep(gridState) as State;

      this.loadData(stateCopy);
    }
  }

  //
  //
  //

  public raiseDataLoadedEvent(d: GroupingExLoadResult) {
    this.dataLoadedEvent.emit(d);
  }

  public getModdedState(state: State): State {
    const stateCopy = _.cloneDeep(state) as State;
    FilterUtils.replaceFilter(
      stateCopy,
      FilterUtils.concatFilters(
        stateCopy.filter,
        this.viewModel.extraFilter,
        this.viewModel.extraFilterLogic
      )
    );
    return stateCopy;
  }

  cancel() {
    if (this.viewModel && this.viewModel.loadDataSubscription) {
      this.viewModel.loadDataSubscription.unsubscribe();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loadData(state: State, newItem: any = null): ReplaySubject<boolean> {
    // PORTAL-1336
    // - issues while we do start to remove (non last) groups from grouping (and having L1 or L2 load still in progress
    //      where subscription will be eventually evaluated after group change) and throwing an error:
    //      Oops! An error occured. (#1) Wrong data in destinationItems / sourceItems array - on the same level only one fieldName should exist and it should be equal in both arrays!
    // - fix for new issue: where a non completely loaded grid state overwrites a valid, stored state
    //
    // Www:
    // - https://www.youtube.com/watch?v=rUZ9CjcaCEw
    // - https://blog.angularindepth.com/the-best-way-to-unsubscribe-rxjs-observable-in-the-angular-applications-d8f9aa42f6a0
    let partiallyLoaded = false;
    this.refreshPageGroupingChanged(this.grid, state);

    // we can turn this on to cancel previous running calls globally - otherwise use cancel() method
    // if (this.viewModel.loadDataSubscription && !this.viewModel.loadDataSubscription.closed) {
    //   this.viewModel.loadDataSubscription.unsubscribe();
    // }

    if (this.loadDataL1Subscription && !this.loadDataL1Subscription.closed) {
      this.loadDataL1Subscription.unsubscribe();
      partiallyLoaded = true;
    }

    if (this.loadDataL2Subscription && !this.loadDataL2Subscription.closed) {
      this.loadDataL2Subscription.unsubscribe();
      partiallyLoaded = true;
    }

    const finishEventEmitter: ReplaySubject<boolean> =
      new ReplaySubject<boolean>();

    //
    // Special state - with additional filter...
    const mergedGridState = this.getModdedState(state);

    this.grid.loading = true;

    //
    // Get current state (if grid paging has not changed and full state is avail.)
    if (!partiallyLoaded) {
      this.loadDataSpecialState =
        (this.grid.skip || 0) === (state.skip || 0)
          ? GroupingExDataStateSupport.fetchCurrentState(this.grid) // we keep expansion / selection if no page switch!
          : null;
    }

    //
    // Load
    this.loadDataL1Subscription = GroupingExLoad.loadData(
      this.viewModel,
      this.grid,
      mergedGridState
    )
      .pipe(take(1))
      .subscribe((level1Data: GroupingExLoadResult) => {
        GroupingExLoad.mergeLoadDataResultToGrid(
          this.viewModel,
          this.grid,
          mergedGridState,
          level1Data,
          newItem
        );

        //
        // Extras
        //

        if (level1Data.loadedItemSets && level1Data.loadedItemSets.length) {
          const sumOfItems = level1Data.loadedItemSets.reduce(
            (lgsAcc, lgs) => lgsAcc + lgs.total,
            0
          );
          if (sumOfItems < mergedGridState.skip) {
            mergedGridState.skip = 0;
            this.loadData(mergedGridState);
            return;
          }
        }

        //
        // EPHF-445
        if (level1Data.loadedGroupSets && level1Data.loadedGroupSets.length) {
          const sumOfItems = level1Data.loadedGroupSets.reduce(
            (lgsAcc, lgs) =>
              lgsAcc +
              (lgs.data || []).reduce(
                (dataAcc, data) => dataAcc + data.count,
                0
              ),
            0
          );

          // FIX for https://universalplantservices.visualstudio.com/UPS1%20Resource%20Allocation/_workitems/edit/20721
          // if (sumOfItems < mergedGridState.skip) {
          //   mergedGridState.skip = 0;
          //   this.loadData(mergedGridState);
          //   return;
          // }

          if (sumOfItems < this.viewModel.loadAllItemsUntilCountOf) {
            // load all items, for all the 1st level groups...bul also each group (reason: aggregates)
            const itemState4Load = _.cloneDeep(mergedGridState);

            // remove paging as it's needed just for the 1st level (or all level group combination) - so for the part with paging
            delete itemState4Load['skip'];
            delete itemState4Load['take'];

            const groupsInGrid = level1Data.processedGroupSets[0].data.map(
              (i: GroupResult) => [
                { field: i.field, value: i.value } as PathSegmentDetached,
              ]
            );
            GroupingExLoadSpecificGroups.extendFilterFor(
              this.grid,
              itemState4Load,
              groupsInGrid,
              1
            );

            this.loadDataL2Subscription = GroupingExLoad.loadLevelsBelow(
              this.viewModel,
              this.grid,
              itemState4Load,
              null
            )
              .pipe(take(1))
              .subscribe((d: GroupingExLoadResult) => {
                const gridData = this.grid.data as GridDataResult;

                GroupingExLoad.mergeLoadLevelsBelowResultToGrid(
                  this.viewModel,
                  this.grid,
                  itemState4Load,
                  null,
                  d
                );

                GroupCollapseExpandUtils.expandAll(this.grid, gridData.data);

                if (this.loadDataSpecialState) {
                  // NOTE: we still need to handle item selection (but without load, as we have all data needed)
                  GroupingExDataStateSupport.refreshGridState(
                    this.grid,
                    this.loadDataSpecialState.selectedItemDetails,
                    this.viewModel.getEqualityComparer()
                  );
                }

                // quit...
                this.raiseDataLoadedEvent(d);
                this.grid.loading = false;
                finishEventEmitter.next(true);
              });

            // quit from executing further code from within the given subscribe execution path
            // ...as we will need to wait for the load-all-items to finish...
            return finishEventEmitter;
          }
        }

        //
        // data state
        if (this.loadDataSpecialState) {
          const nestedMergedGridState = _.cloneDeep(mergedGridState);

          // remove paging as it's needed just for the 1st level (or all level group combination)
          delete nestedMergedGridState['skip'];
          delete nestedMergedGridState['take'];

          this.loadDataL2Subscription =
            GroupingExDataStateSupport.loadStateData(
              this.viewModel,
              this.grid,
              nestedMergedGridState,
              this.loadDataSpecialState
            )
              .pipe(take(1))
              .subscribe((stateData: GroupingExLoadResult) => {
                GroupingExDataStateSupport.mergeStateDataResultToGrid(
                  this.viewModel,
                  this.grid,
                  nestedMergedGridState,
                  stateData,

                  this.loadDataSpecialState.selectedItemDetails,
                  this.viewModel.getEqualityComparer()
                );

                // quit...
                this.raiseDataLoadedEvent(stateData);
                this.grid.loading = false;
                finishEventEmitter.next(true);
              });
        } else {
          //
          // when no extra data to load
          this.raiseDataLoadedEvent(level1Data);
          this.grid.loading = false;
          finishEventEmitter.next(true);
        }
      });

    return finishEventEmitter;
  }

  internalGroupExpand(event: { group: GroupResult; groupIndex: string }): void {
    const groupDataItem = event.group;

    // if there are items, then we don't need to load
    const hasItemsBelow = groupDataItem.items && groupDataItem.items.length;
    if (hasItemsBelow) return;

    // Special state - with additional filter...
    const mergedGridState = this.getModdedState(
      StateUtils.getStateFromGrid(this.grid)
    );

    this.grid.loading = true;

    setTimeout(() => {
      GroupingExLoad.loadNextLevel(
        this.viewModel,
        this.grid,
        mergedGridState,
        groupDataItem
      ).subscribe((d) => {
        this.raiseDataLoadedEvent(d);

        if (
          groupDataItem &&
          groupDataItem.value &&
          this.viewModel.oDataOpenTypesFieldName &&
          d.processedItemSets &&
          d.processedItemSets.length
        ) {
          if (
            d.processedItemSets[0].data &&
            d.processedItemSets[0].data.length &&
            !d.processedItemSets[0].data[0].value
          ) {
            d.processedItemSets[0].data[0].value = groupDataItem.value;
          }
        }

        GroupingExLoad.mergeLoadNextLevelResultToGrid(
          this.viewModel,
          this.grid,
          mergedGridState,
          groupDataItem,
          d
        );

        // quit...
        this.grid.loading = false;
        return;
      });
    }, 10); // #8022 - to be able to execute groupExpand before internalGroupExpand
  }
  private refreshPageGroupingChanged(stateOld: State, stateNew: State) {
    // Comapare json to see if groups have changed before resetting skip
    if (JSON.stringify(stateOld.group) !== JSON.stringify(stateNew.group)) {
      stateNew.skip = 0;
      this.grid.skip = 0;
    }
  }
}
