import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { BaseComponent, IDynamicModel } from '@ups/xplat/core';
import { deepClone } from '@ups/xplat/utils';
import { Subject } from 'rxjs';

@Directive()
export abstract class EditModalBaseComponent extends BaseComponent {
  @Input() fields: IDynamicModel[];
  @Input() mutateOriginal = true;
  @Output() saveChanges = new EventEmitter<object>();

  closed$ = new Subject<boolean>();

  protected _current: object = {};
  @Input() set current(v: object) {
    this._current = v;
    this.changedModel = deepClone(v);
  }
  get current() {
    return this._current;
  }

  public changedModel: object = {};

  abstract open();
  close(saved?: boolean) {
    this.closed$.next(saved);
    this.hide();
  }
  abstract hide(saved?: boolean);

  constructor() {
    super();
  }

  protected cancel() {
    this.close();
  }

  protected save(mutateOriginal = this.mutateOriginal) {
    this.saveChanges.emit(this.changedModel);
    if (mutateOriginal) {
      Object.keys(this.changedModel).forEach((key) => {
        this.current[key] = this.changedModel[key];
      });
    }
    this.close(true);
  }
}
