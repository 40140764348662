import { Injectable } from '@angular/core';
import { ApplicationModel } from '@ups/xplat/api/dto';
import { ManPowerService } from '@ups/xplat/base/manpower';

@Injectable({ providedIn: 'root' })
export class RecruiterResolver {
  constructor(
    private manPowerService: ManPowerService,
    private applicationModel: ApplicationModel
  ) {}

  public load(): Promise<any> {
    const self = this;
    if (this.applicationModel.dispatchGroups.length === 0) {
      return this.manPowerService
        .fetchRecruiters()
        .toPromise()
        .then((data) => {
          self.applicationModel.recruiters = data;
        });
    } else {
      return Promise.resolve(this.applicationModel.recruiters);
    }
  }
}
