import { isObject } from './objects';

/**
 * @description Compares 2 objects and returns true if they are the same else returns false.
 * It also accepts properties to be ignoreed in the comparism
 */

export function deepEqual(
  object1: unknown,
  object2: unknown,
  ignoreProperties?: { [key: string]: boolean },
  nullCompare?: boolean
): boolean {
  if (!object1 || !object2) {
    return object1 === object2;
  }

  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!ignoreProperties?.[key]) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      const areDates = val1 instanceof Date && val2 instanceof Date;

      const areNullAndEmptyString = val1 === null && val2 === '';
      const areNullAndEmptyArray =
        val1 === null && (val2 as unknown[])?.length === 0;
      const areNullAndUndefined = val1 === null && val2 === undefined;

      if (
        nullCompare &&
        (areNullAndEmptyString || areNullAndEmptyArray || areNullAndUndefined)
      ) {
        continue;
      }

      if (
        (areObjects && !deepEqual(val1, val2, ignoreProperties, true)) ||
        (!areObjects && !areDates && val1 !== val2) ||
        (areDates && val1.getTime() !== val2.getTime())
      ) {
        return false;
      }
    }
  }

  return true;
}
