import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@ups/xplat/core';

@Directive()
export abstract class ProfileBasicBaseComponent extends BaseComponent {
  public text = 'ProfileBasic';
  @Input() profile: any;
  @Input() portalProfile: any;
  @Input() spartaProfile: any;
  @Input() editMode: any;
  @Input() editCellPhone: any;
  @Output() onSaveRequested: EventEmitter<any> = new EventEmitter();

  constructor() {
    super();
  }
}
