import { NiftySublocation } from './NiftySublocation';

export class SubLocationInventory {
  sublocationInvID: number;
  sublocationId: number;
  material: string;
  inco: number;
  onHand: number;
  inProcess: number;
  modifiedBy: string;
  modifiedDate: Date;
  nSubLocation: NiftySublocation;
}
