/**
 * Created by Tom.Jaeschke on 6/8/2017.
 */
import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import {
  PerDiemFile,
  PerDiemViewpointSideUpdate,
  PerDiemSpartaSideUpdate,
} from '@ups/xplat/api/dto';
import { HttpResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import { parseData } from '@ups/xplat/utils';
import { map } from 'rxjs/operators';

const GET_PER_DIEM_SUPPORTED_TYPES: string =
  '/api/sparta/perdiemdocs/supportedTypes';
const GET_EXPIRED_PER_DIEM: string = '/api/e2e/perdiemfiles/expired/';
const GET_UNEXPIRED_PER_DIEM: string = '/api/e2e/perdiemfiles/unexpired/'; //Guid? employeeJobID, Guid? manPowerReqDetailID
const GET_ALL_PER_DIEM: string = '/api/e2e/perdiemfiles/all/';
const SAVE_PER_DIEM_FILE: string = '/api/e2e/spartasideupload/'; //Guid? employeeJobID
const UPDATE_PER_DIEM_SPARTA: string = '/api/e2e/spartasideupdate/';
const UPDATE_PER_DIEM_VIEWPOINT: string =
  '/api/sparta/employeeportalperdiemdocs/';
const DOWNLOAD_FROM_VIEWPOINT = '/api/e2e/pullviewpointfilethroughsparta/';
const DOWNLOAD_FROM_SPARTA = '/api/e2e/pullazurefile/';
const ALTER_PER_DIEM_ASSOCIATION = '/api/e2e/alterperdiemassociation/';
const CREATE_SPARTA_SIDE_RECORD_WITH_PER_DIEM_FILE_ASSOCIATION =
  '/api/e2e/createspartasiderecordwithperdiemfileassociation/';
const CREATE_SPARTA_SIDE_RECORD_WITHOUT_PER_DIEM_FILE_ASSOCIATION =
  '/api/e2e/createspartasiderecordwithoutperdiemfileassociation/';
const GET_PERDIEM_DATA = '/api/perDiem/audit';
const GET_ALL_PERDIEM_DATA = '/api/perDiem/audit/job/all';
const GET_PERDIEM_ALL_JOBS_DATA = '/api/jobs/all';
const GET_PERDIEM_JOBS_DATA = '/api/jobs';
const GET_SELECTED_PERDIEM_JOBS_DATA = '/api/perDiem/audit/job/{jobId}';
const GET_SELECTED_PERDIEM_JOBS_ODATA = '/api/perDiem/audit-odata/job/{jobId}';

const GET_DATA_FOR_PERDIEM_POPUP =
  '/api/perDiem/filesForApproval/{employeeJobId}';
const GET_FILE_DATA_FOR_PERDIEM_POPUP = '/api/perDiem/downloadfile/{fileID}';
const ACCEPT_PERDIEM_FILE = '/api/perDiem/accept/file/{perDiemFileID}';
const REJECT_PERDIEM_FILE = '/api/perDiem/reject/file/{perDiemFileID}';

@Injectable({
  providedIn: 'root',
})
export class PerDiemService {
  showMessage$: Subject<{ type: string; message: string }> = new Subject();
  protected spartaClient: HttpClient;
  protected vpClient: HttpClient;

  constructor(private clientFactory: MyHttpClientFactory) {
    this.spartaClient = this.clientFactory.createHttpClient(
      environment.urls.spartaAPI,
      true,
      ResponseCasingEnum.PascalCase
    );
    this.vpClient = this.clientFactory.createHttpClient(
      environment.urls.viewpointAPI,
      true,
      ResponseCasingEnum.PascalCase
    );
  }

  public downloadFileFromSparta(fileId: string): any {
    var url = DOWNLOAD_FROM_SPARTA + fileId;
    return this.spartaClient
      .get(url)
      .toPromise()
      .then(parseData, (error) => {
        throw error;
      });
  }

  public downloadFileFromViewPoint(attachmentId: number): any {
    var url = DOWNLOAD_FROM_VIEWPOINT + attachmentId;
    return this.spartaClient
      .get(url)
      .toPromise()
      .then(parseData, (error) => {
        throw error;
      });
  }

  public fetch(
    manPowerReqDetailID: string,
    employeeId: string,
    jobId: string,
    isToGetExpiredData: boolean
  ): any {
    return this.fetchExtended(
      manPowerReqDetailID,
      null,
      employeeId,
      jobId,
      isToGetExpiredData
    );
  }

  public fetchExtended(
    manPowerReqDetailID: string,
    employeeJobID: string,
    employeeId: string,
    jobId: string,
    isToGetExpiredData: boolean
  ): any {
    let url: string;
    if (isToGetExpiredData === null) {
      url = GET_ALL_PER_DIEM + employeeId;
    } else if (isToGetExpiredData) {
      url = GET_EXPIRED_PER_DIEM + employeeId;
    } else {
      url = GET_UNEXPIRED_PER_DIEM + employeeId;
    }
    if (jobId)
      url =
        url + '/' + jobId + '?exceptManPowerReqDetailID=' + manPowerReqDetailID;
    if (jobId && employeeJobID)
      url = url + '&exceptEmployeeJobID=' + employeeJobID;
    return this.spartaClient.get(url).toPromise().then(parseData);
  }

  public fetchSupportedTypes(): any {
    var url = GET_PER_DIEM_SUPPORTED_TYPES;
    return this.vpClient.get(url).toPromise().then(parseData);
  }

  public uploadFile(
    file: any,
    perDiemFile: PerDiemFile,
    employeeId: string,
    jobId: string,
    manpowerReqDetailId: string
  ): any {
    var url =
      SAVE_PER_DIEM_FILE +
      employeeId +
      '/' +
      perDiemFile.DocType +
      this.dressDownDate(perDiemFile.SrvDate) +
      this.dressDownDate(perDiemFile.ExpDate) +
      '/';
    if (jobId && manpowerReqDetailId) {
      url = url + manpowerReqDetailId + '/' + jobId;
    } else {
      url = url + '00000000-0000-0000-0000-000000000000/0';
    }
    let formData: FormData = new FormData();
    formData.append('uploadFile', file, file.name);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'multipart/form-data');
    headers = headers.append('Accept', 'application/json');
    let options = { headers: headers };
    return this.spartaClient.post(url, formData).toPromise().then(parseData);
  }

  public updateFile(
    perDiemFile: PerDiemFile,
    employeeId: string,
    manpowerReqDetailId: string,
    jobId: string
  ) {
    var self = this;
    if (employeeId && employeeId != '00000000-0000-0000-0000-000000000000') {
      let promises = [];
      if (perDiemFile.VPKeyID) {
        var url = UPDATE_PER_DIEM_VIEWPOINT;
        let perDiemViewpointSideUpdate: PerDiemViewpointSideUpdate =
          new PerDiemViewpointSideUpdate();
        perDiemViewpointSideUpdate.KeyId = perDiemFile.VPKeyID;
        perDiemViewpointSideUpdate.DocType = perDiemFile.DocType;
        perDiemViewpointSideUpdate.Notes = perDiemFile.Notes;
        perDiemViewpointSideUpdate.ServiceDate = perDiemFile.SrvDate;
        perDiemViewpointSideUpdate.ExpirationDate = perDiemFile.ExpDate;
        let body = JSON.stringify(perDiemViewpointSideUpdate);
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let options = { headers: headers };
        promises.push(
          this.vpClient
            .put(url, body, options)
            .toPromise()
            .catch((e) => {
              this.showMessage$.next({ type: 'error', message: e.Messages });
              console.error(e);
            })
        );
      }
      if (
        perDiemFile.FileID &&
        perDiemFile.FileID != '00000000-0000-0000-0000-000000000000'
      ) {
        var url = UPDATE_PER_DIEM_SPARTA;
        let perDiemSpartaSideUpdate: PerDiemSpartaSideUpdate =
          new PerDiemSpartaSideUpdate();
        perDiemSpartaSideUpdate.FileID = perDiemFile.FileID;
        perDiemSpartaSideUpdate.Name = perDiemFile.FileName;
        perDiemSpartaSideUpdate.ServiceDate = perDiemFile.SrvDate;
        perDiemSpartaSideUpdate.ExpirationDate = perDiemFile.ExpDate;
        perDiemSpartaSideUpdate.AttachmentID = perDiemFile.VPAttachmentID;
        perDiemSpartaSideUpdate.Notes = perDiemFile.Notes;
        perDiemSpartaSideUpdate.DocType = perDiemFile.DocType;
        perDiemSpartaSideUpdate.KeyId = perDiemFile.VPKeyID;
        let body = JSON.stringify(perDiemSpartaSideUpdate);
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let options = { headers: headers };
        promises.push(
          this.spartaClient
            .put(url, body, options)
            .toPromise()
            .catch((e) => {
              this.showMessage$.next({ type: 'error', message: e.Messages });
              console.error(e);
            })
        );
      } else {
        promises.push(
          this.createSpartaSideRecordWithPerDiemFileAssociation(
            perDiemFile,
            employeeId,
            manpowerReqDetailId,
            jobId
          ).then(
            function (data) {
              perDiemFile.FileID = data.FileID;
              perDiemFile.PerDiemFileID = data.PerDiemFileID;
            }.bind(this),
            function (error) {
              console.error(error);
              this.showMessage$.next({ type: 'error', message: error });
            }.bind(this)
          )
        );
      }

      return forkJoin(promises).toPromise();
    }
  }

  public deleteFile(
    perDiemFile: PerDiemFile,
    employeeId: string,
    manpowerReqDetailId: string,
    jobId: string
  ): void {
    var url =
      ALTER_PER_DIEM_ASSOCIATION + perDiemFile.PerDiemFileID + '/' + employeeId;
    if (jobId && manpowerReqDetailId)
      url = url + '/' + jobId + '/' + manpowerReqDetailId;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    this.spartaClient.delete(url, options).toPromise();
  }

  public deleteFileWithReturnValue(
    perDiemFile: PerDiemFile,
    employeeId: string,
    manpowerReqDetailId: string,
    jobId: string
  ) {
    var url =
      ALTER_PER_DIEM_ASSOCIATION + perDiemFile.PerDiemFileID + '/' + employeeId;
    if (jobId && manpowerReqDetailId)
      url = url + '/' + jobId + '/' + manpowerReqDetailId;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.spartaClient.delete(url, options).toPromise();
  }

  public addFile(
    perDiemFile: PerDiemFile,
    employeeId: string,
    manpowerReqDetailId: string,
    jobId: string
  ): any {
    var url =
      ALTER_PER_DIEM_ASSOCIATION +
      perDiemFile.PerDiemFileID +
      '/' +
      employeeId +
      '/' +
      jobId +
      '/' +
      manpowerReqDetailId;
    let formData: FormData = new FormData();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'multipart/form-data');
    headers = headers.append('Accept', 'application/json');
    let options = { headers: headers };
    return this.spartaClient.post(url, formData).toPromise();
  }

  private dressDownDate(date: Date) {
    return (
      '/' +
      date.getFullYear() +
      '/' +
      (date.getMonth() + 1) +
      '/' +
      date.getDate()
    );
  }

  public createSpartaSideRecordWithPerDiemFileAssociation(
    perDiemFile: PerDiemFile,
    employeeId: string,
    manpowerReqDetailId: string,
    jobId: string
  ): any {
    var self = this;
    var url =
      CREATE_SPARTA_SIDE_RECORD_WITH_PER_DIEM_FILE_ASSOCIATION + employeeId;
    if (jobId && manpowerReqDetailId)
      url = url + '/' + jobId + '/' + manpowerReqDetailId;
    let perDiemSpartaSideUpdate: PerDiemSpartaSideUpdate =
      new PerDiemSpartaSideUpdate();
    perDiemSpartaSideUpdate.Name = perDiemFile.FileName;
    perDiemSpartaSideUpdate.ServiceDate = perDiemFile.SrvDate;
    perDiemSpartaSideUpdate.ExpirationDate = perDiemFile.ExpDate;
    perDiemSpartaSideUpdate.AttachmentID = perDiemFile.VPAttachmentID;
    perDiemSpartaSideUpdate.Notes = perDiemFile.Notes;
    perDiemSpartaSideUpdate.DocType = perDiemFile.DocType;
    perDiemSpartaSideUpdate.KeyId = perDiemFile.VPKeyID;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'multipart/form-data');
    headers = headers.append('Accept', 'application/json');
    let options = { headers: headers };
    return this.spartaClient
      .post(url, perDiemSpartaSideUpdate)
      .toPromise()
      .then(parseData)
      .catch((e) => {
        this.showMessage$.next({ type: 'error', message: e.Messages });
        console.error(e);
      });
  }

  public createSpartaSideRecordWithoutPerDiemFileAssociation(
    perDiemFile: PerDiemFile,
    employeeId: string,
    manpowerReqDetailId: string,
    jobId: string
  ): any {
    var url =
      CREATE_SPARTA_SIDE_RECORD_WITHOUT_PER_DIEM_FILE_ASSOCIATION + employeeId;
    if (jobId && manpowerReqDetailId)
      url = url + '/' + jobId + '/' + manpowerReqDetailId;
    let perDiemSpartaSideUpdate: PerDiemSpartaSideUpdate =
      new PerDiemSpartaSideUpdate();
    perDiemSpartaSideUpdate.Name = perDiemFile.FileName;
    perDiemSpartaSideUpdate.ServiceDate = perDiemFile.SrvDate;
    perDiemSpartaSideUpdate.ExpirationDate = perDiemFile.ExpDate;
    perDiemSpartaSideUpdate.AttachmentID = perDiemFile.VPAttachmentID;
    perDiemSpartaSideUpdate.Notes = perDiemFile.Notes;
    perDiemSpartaSideUpdate.DocType = perDiemFile.DocType;
    perDiemSpartaSideUpdate.KeyId = perDiemFile.VPKeyID;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'multipart/form-data');
    headers = headers.append('Accept', 'application/json');
    let options = { headers: headers };
    return this.spartaClient
      .post(url, perDiemSpartaSideUpdate)
      .toPromise()
      .then(parseData);
  }

  public fetchPerdiemData(): Observable<Response> {
    var url = GET_PERDIEM_DATA;
    return this.spartaClient
      .get(url)
      .pipe(map((response) => parseData(response)));
  }

  public fetchAllPerdiemData(): Observable<Response> {
    var url = GET_ALL_PERDIEM_DATA;
    return this.spartaClient
      .get(url)
      .pipe(map((response) => parseData(response)));
  }

  public fetchPerdiemAllJobsData(): Observable<Response> {
    var url = GET_PERDIEM_ALL_JOBS_DATA;
    return this.spartaClient
      .get(url)
      .pipe(map((response) => parseData(response)));
  }

  public fetchPerdiemJobsData(): Observable<Response> {
    var url = GET_PERDIEM_JOBS_DATA;
    return this.spartaClient
      .get(url)
      .pipe(map((response) => parseData(response)));
  }

  public fetchSelectedPerdiemJobsData(jobId): Observable<Response> {
    var url = GET_SELECTED_PERDIEM_JOBS_DATA.replace('{jobId}', jobId);
    return this.spartaClient
      .get(url)
      .pipe(map((response) => parseData(response)));
  }

  public fetchSelectedPerdiemJobsOData(
    jobId,
    odatastring: string
  ): Observable<Response> {
    var url = GET_SELECTED_PERDIEM_JOBS_ODATA.replace('{jobId}', jobId);

    url += '?$format=json&$count=true';
    url += odatastring ? '&' + odatastring : '';

    return this.spartaClient
      .get(url)
      .pipe(map((response) => parseData(response)));
  }

  public fetchPerdiemPopupData(employeeJobID): any {
    let url = GET_DATA_FOR_PERDIEM_POPUP.replace(
      '{employeeJobId}',
      employeeJobID
    );

    return this.spartaClient.get(url).toPromise().then(parseData);
  }

  public fetchFileDataForPerdiemPopup(fileId): any {
    let url = GET_FILE_DATA_FOR_PERDIEM_POPUP.replace('{fileID}', fileId);

    return this.spartaClient.get(url).toPromise();
    // .then(this.parseData);
  }

  public acceptPerdiemFile(perDiemFileID): any {
    let url = ACCEPT_PERDIEM_FILE.replace('{perDiemFileID}', perDiemFileID);

    return this.spartaClient.put(url, null).toPromise();
  }

  public rejectPerdiemFile(perDiemFileID): any {
    let url = REJECT_PERDIEM_FILE.replace('{perDiemFileID}', perDiemFileID);

    return this.spartaClient.put(url, null).toPromise();
  }
}
