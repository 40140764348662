import { Component, EventEmitter, Input, Output } from '@angular/core';

import { HelpDocumentDto } from '@ups/xplat/base/in-app-help';
import { BaseComponent } from '@ups/xplat/core';
import { Router } from '@angular/router';

@Component({
  selector: 'help-editor-walkthrough',
  templateUrl: './editor-walkthrough.component.html',
})
export class EditorWalkthroughComponent extends BaseComponent {
  @Input() document: HelpDocumentDto;

  @Output() complete = new EventEmitter<void>();

  stepIdx = -1;

  constructor(private route: Router) {
    super();
  }

  ngOnInit() {}

  nextStep() {
    this.document.steps = this.document.steps || [];
    this.stepIdx++;
    if (this.stepIdx === this.document.steps.length) {
      this.document.steps.push({
        index: this.stepIdx,
        path: null,
        title: '',
        html: '',
      });
    }
  }

  updatePathBasedOnCheckbox(state: boolean) {
    this.document.path = state ? this.route.routerState.snapshot.url : null;
    console.log('Set doc path to', this.document.path);
  }
}
