import { BaseModel } from '../base.model';

export class ManPowerRequisitionModel extends BaseModel {
  JobClassId: number = null;
  StartDate: Date = null;
  EndDate: Date = null;
  EmployeeId: string = null;
  ProspectId: string = null;
  CheckForOtherManPower: boolean = false;
  ManPowerReqDetailId: string = null;

  constructor(o: any = null) {
    super(o);
    if (o) {
      this.StartDate = o.StartDate ? new Date(o.StartDate) : null;
      this.EndDate = o.EndDate ? new Date(o.EndDate) : null;
    }
  }
}
