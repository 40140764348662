import { inject, Injectable } from '@angular/core';

import { AdminToolboxPanelItem, AdminPropertiesPanelItem } from '../models';

import { DrAdminMapResolverService } from './dr-admin-map-resolver.service';

import {
  dynamicAccordionControls,
  DynamicCustomValidations,
  DynamicModelType,
  IDynamicContainerMetadata,
  IDynamicModelProperties,
} from '@ups/xplat/core';
import { guid } from '@ups/xplat/utils';
import { DynamicRenderService } from '@ups/xplat/features';

@Injectable({ providedIn: 'root' })
export class DrAdminService {
  adminMapResolverService = inject(DrAdminMapResolverService);
  dynamicRender = inject(DynamicRenderService);
  toolboxItems: AdminToolboxPanelItem[] = [];
  designSurfaceItems: Array<unknown> = []; // Use Ngrx to store this state instead
  currentPropertiesPanelItem: AdminPropertiesPanelItem = undefined; // Use Ngrx to store this state
  currentContainerMetaData: IDynamicContainerMetadata = undefined; // Use Ngrx to store this state
  controlTypes: Array<DynamicModelType> = [
    'input',
    'text',
    'textarea',
    'date',
    'dataLabels',
    'file',
    'number',
    'phone',
    'checkbox',
    'email',
    'label',
    'numerictextbox',
    'addressautocomplete',
    'typeahead',
    'typeahead-autocomplete',
    'typeahead-category',
    'typeahead-company',
    'typeahead-customers',
    'typeahead-employee',
    'typeahead-job',
    'typeahead-facilities',
    'accordion',
    'target',
    'button',
    'buttongroup',
    'signature',
  ];
  // controls used for specific admin builder handling
  adminOnlyFormControlNames: Array<string> = [
    'adminAccordionAdd',
    'adminReorder',
  ];

  /* eslint-disable */
  dynamicModelOptions: Array<IDynamicModelProperties> = [
    {
      formControlName: 'label',
      label: 'Label',
      types: this.controlTypes.filter((item) => item !== 'button'),
      instruction: 'The label for the form control.',
      options: {
        maxlength: 250,
      },
      type: 'input',
    },
    {
      formControlName: 'instruction',
      label: 'Instruction',
      types: this.controlTypes.filter(
        (item) => !['button', 'accordion', 'label'].includes(item)
      ),
      instruction: 'The instruction for the form control (if needed).',
      options: {
        maxlength: 500,
      },
      type: 'textarea',
    },
    {
      formControlName: 'formControlName',
      label: 'Control Name',
      types: this.controlTypes.filter(
        (item) => !['button', 'accordion'].includes(item)
      ),
      instruction: 'The backend api stored property name for this control.',
      type: 'input',
    },
    {
      formControlName: 'formArrayName',
      label: 'Group Control Name',
      types: ['accordion'],
      instruction: 'The backend api stored property name for this control.',
      type: 'input',
    },
    {
      formControlName: 'placeholder',
      label: 'Placeholder',
      types: this.controlTypes.filter(
        (item) =>
          ![
            'button',
            'buttongroup',
            'accordion',
            'dataLabels',
            'label',
            'file',
            'checkbox',
            'signature',
          ].includes(item)
      ),
      instruction: 'The placeholder for the control.',
      type: 'input',
    },
    {
      formControlName: 'valueProperty',
      label: 'Value Property',
      types: ['typeahead-all'],
      // typeahead-category right now contains hard coded results, therefore don't allow user to customize the valueProperty
      typesExclusions: ['typeahead-category'],
      instruction:
        'Value property to use from the api response data. Used when user selects an item.',
      type: 'input',
    },
    {
      formControlName: 'adminAccordionAdd',
      types: ['accordion'],
      label: 'Add a control to the Accordion:',
      type: 'typeahead',
      options: {
        dropdownOptions: dynamicAccordionControls.sort().map((c) => {
          return {
            id: guid(),
            Name: c,
          };
        }),
      },
    },
    {
      formControlName: 'accordionRequiredValues',
      types: ['accordion'],
      label: 'Values required',
      type: 'textarea',
      instruction:
        'Provide a comma separated list of values required from this Accordion group. At least one of these values must be provided.',
      isAdvanced: true,
    },
    {
      formControlName: 'accordionRequiredValueRequiresAnother',
      types: ['accordion'],
      label: 'Value must require another',
      type: 'input',
      isAdvanced: true,
    },
    {
      formControlName: 'accordionRequiredValuesApplyToAll',
      types: ['accordion'],
      label: 'Values required satisfies all accordions',
      type: 'checkbox',
      isAdvanced: true,
    },
    {
      formControlName: 'minimumFieldsRequired',
      types: ['accordion'],
      label: 'Group Minimum Field(s) Required',
      type: 'number',
      instruction:
        'Allow this Accordion to be used with at least 1 required control to be selected or filled out.',
      isAdvanced: true,
      options: {
        decimals: 0,
      },
    },
    {
      formControlName: 'value',
      label: 'Title',
      types: ['button'],
      instruction: 'What should the button say?',
      type: 'input',
    },
    {
      formControlName: 'maxlength',
      label: 'Max Length',
      types: this.controlTypes.filter((item) =>
        ['input', 'text', 'textarea', 'phone', 'email'].includes(item)
      ),
      instruction: 'Limit the total character length.',
      options: {
        decimals: 0,
      },
      type: 'number',
    },
    {
      formControlName: 'required',
      label: 'Required',
      types: this.controlTypes.filter(
        (item) => !['label', 'dataLabels'].includes(item)
      ),
      instruction: 'Make this control required.',
      type: 'checkbox',
    },
    {
      formControlName: 'hidden',
      label: 'Hidden',
      types: this.controlTypes.filter(
        (item) => !['buttongroup', 'label', 'dataLabels'].includes(item)
      ),
      instruction: 'Hides the form field by default.',
      type: 'checkbox',
    },
    {
      formControlName: 'disabled',
      label: 'Disabled',
      types: this.controlTypes.filter(
        (item) =>
          !['accordion', 'buttongroup', 'label', 'dataLabels'].includes(item)
      ),
      instruction: 'Disable user interaction',
      type: 'checkbox',
    },
    {
      formControlName: 'primary',
      label: 'Primary',
      types: ['button'],
      instruction: 'Is the primary field.',
      type: 'checkbox',
    },
    {
      formControlName: 'submit',
      label: 'Submit',
      types: ['button'],
      instruction: 'Is the submit control.',
      type: 'checkbox',
    },
    {
      formControlName: 'labelIgnore',
      label: 'Ignore Label',
      types: this.controlTypes.filter(
        (item) =>
          !['checkbox', 'label', 'accordion', 'buttongroup'].includes(item)
      ),
      instruction: 'Hides the label.',
      type: 'checkbox',
    },
    {
      formControlName: 'customValidations',
      label: 'Date Validation',
      types: ['date'],
      type: 'typeahead',
      options: {
        isMultiSelect: true,
        dataResolver: () => {
          return Promise.resolve(
            DynamicCustomValidations.filter((v) => v.indexOf('date:') > -1).map(
              (v) => ({
                Id: v,
                Name: v,
              })
            )
          );
        },
      },
    },
    {
      formControlName: 'fileAllowMultiple',
      label: 'Allow Multiple File Attachments',
      types: ['file'],
      instruction: 'Will allow ability to add more than one file attachment.',
      type: 'checkbox',
    },
    {
      formControlName: 'disableFormControlName',
      label: 'Disable Form Control Name',
      types: ['checkbox'],
      instruction: 'Disable the named formControlName when checked.',
      type: 'input',
    },
    {
      formControlName: 'hiddenFormControlName',
      label: 'Hidden Form Control Name',
      types: ['checkbox'],
      instruction: 'Show the hidden formControlName when checked.',
      type: 'input',
    },
    {
      formControlName: 'uncheckedFormControlName',
      label: 'Unchecked Form Control Name',
      types: ['checkbox'],
      instruction:
        'Show the formControlName when unchecked - otherwise hide it.',
      type: 'input',
    },
    {
      formControlName: 'api-endpoint',
      label: 'API Endpoint',
      types: ['typeahead', 'dataLabels'],
      instruction: `The API endpoint which will return the data needed. You can use https://api.com/{controlName.variable} mapping to connect other control selections to variable substitution. Additionally, you can also use currently supported internal variables: Example: https://api.com/{HRRef}`,
      type: 'input',
    },
    {
      formControlName: 'api-data-properties',
      label: 'Data Properties',
      types: ['dataLabels'],
      instruction:
        'Comma separated properties. Example: VacationBalance, SickBalance',
      type: 'input',
    },
    {
      formControlName: 'api-model-Id',
      types: ['typeahead'],
      instruction: 'Value property name from api response.',
      type: 'input',
      isAdvanced: true,
      options: {
        labelIgnore: true,
      },
    },
    {
      formControlName: 'api-model-Name',
      types: ['typeahead'],
      instruction:
        'Display property name from api response to show in drop down.',
      type: 'input',
      isAdvanced: true,
      options: {
        labelIgnore: true,
      },
    },
    ...this.getUpdateOnValueChangeControls(),
    {
      formControlName: 'AddUpdateOnValueChange',
      label: 'Add another',
      types: ['typeahead-all', 'date'],
      instruction:
        'Indicate another control which should be updated on value change',
      value: '+ Add another control to update',
      type: 'button',
      isAdvanced: true,
    },
    {
      formControlName: 'scopeQueryWhenFormControlIsSet-formControlName',
      label: 'Scope Query by the value of Another Control',
      types: ['typeahead-all'],
      instruction: 'The Name of the Another Control',
      type: 'input',
      isAdvanced: true,
    },
    {
      formControlName: 'scopeQueryWhenFormControlIsSet-matchTargetProperty',
      types: ['typeahead-all'],
      instruction: 'Property name of the value',
      type: 'input',
      isAdvanced: true,
      options: {
        labelIgnore: true,
      },
    },
    {
      formControlName: 'scopeQueryWhenFormControlIsSet-queryParamName',
      types: ['typeahead-all'],
      instruction: '(Optional) Query Parameter Name',
      type: 'input',
      isAdvanced: true,
      options: {
        labelIgnore: true,
      },
    },
    {
      formControlName: 'checked',
      label: 'Checked',
      types: ['checkbox'],
      instruction: 'The checkbox is checked by default.',
      type: 'checkbox',
    },
    // {
    //   formControlName: 'minDate',
    //   label: 'Minimum Date',
    //   types: ['date'],
    //   instruction: 'The minimum date.',
    //   type: 'date',
    // },
    // {
    //   formControlName: 'maxDate',
    //   label: 'Maximum Date',
    //   types: ['date'],
    //   instruction: 'The maximum date.',
    //   type: 'date',
    // },
    {
      formControlName: 'showTimePicker',
      label: 'Show Time Picker',
      types: ['date'],
      instruction: 'In addition to date, also show a time picker.',
      type: 'checkbox',
    },
    {
      formControlName: 'isMultiSelect',
      label: 'Allow multiple selections',
      types: this.controlTypes.filter(
        (item) =>
          item.startsWith('typeahead') &&
          ![
            'typeahead-company',
            'typeahead-facilities',
            'typeahead-job',
            'typeahead-customers',
          ].includes(item)
      ),
      instruction: 'Multiselect',
      type: 'checkbox',
    },
    {
      formControlName: 'reportCategoryName',
      label: 'Report Category Name',
      types: this.controlTypes,
      valueProperty: 'Name',
      instruction:
        'Provide a custom report category name. By default the "Control Name" above will be used as the report category name.',
      isAdvanced: true,
      type: 'typeahead-autocomplete',
      options: {
        dataResolver: () => {
          return new Promise((resolve) => {
            this.dynamicRender
              .loadReportCategories(this.dynamicRender.selectedControl?.type)
              .then((categories) => {
                resolve(
                  (categories || []).map((c) => {
                    return {
                      Id: c,
                      Name: c,
                    };
                  })
                );
              });
          });
        },
      },
    },
    {
      formControlName: 'isReusable',
      label: 'Reusable',
      types: this.controlTypes,
      instruction: 'Allow to reuse this control',
      type: 'checkbox',
      isAdvanced: true,
    },
    {
      formControlName: 'accessRoleIds',
      label: 'Roles',
      types: this.controlTypes,
      instruction: 'Restrict access to this control via permission roles.',
      isAdvanced: true,
      type: 'typeahead',
      options: {
        isMultiSelect: true,
        dataResolver: () => {
          return new Promise((resolve) => {
            this.dynamicRender.loadFormRoles().then((roles) => {
              resolve(
                (roles || []).map((r) => {
                  return {
                    Id: r.RoleId,
                    Name: r.RoleName,
                  };
                })
              );
            });
          });
        },
      },
    },
    {
      formControlName: 'AddItemToButtonGroup',
      label: 'Add button',
      types: ['buttongroup'],
      instruction: 'Add button to group',
      value: '+ Add Button to Group',
      type: 'button',
    },
    {
      formControlName: 'distinctHRRef',
      label: 'Distinct HR Ref',
      types: ['typeahead-employee'],
      instruction: 'Use Distinct HR Ref Endpoint',
      type: 'checkbox',
      isAdvanced: true,
    },
    {
      formControlName: 'pointToViewpoint',
      label: 'Point to Viewpoint',
      types: ['typeahead-job'],
      instruction: 'Query Viewpoint DB instead of Sparta',
      type: 'checkbox',
      isAdvanced: true,
    },
  ];
  /* eslint-enable */

  constructor() {
    this.adminMapResolverService.availableAdminItems.map((i) => {
      // console.log('toolboxitem:', i);
      this.toolboxItems.push({
        controlName: i.controlName,
        displayName: i.displayName,
        icon: i.icon,
        iconSrc: i.iconSrc,
        toolboxGroup: i.toolboxGroup,
      });
    });
  }

  toolboxItemSelected(toolboxItem: AdminToolboxPanelItem) {
    const adminItem = this.adminMapResolverService.availableAdminItems.find(
      (i) => i.controlName === toolboxItem.controlName
    );
    const newDesignPanelItem = {
      clientId: `new-${guid()}`,
      ...adminItem,
    };
    // console.log('newDesignPanelItem', newDesignPanelItem);
    this.designSurfaceItems.push(newDesignPanelItem);
    return newDesignPanelItem;
  }

  public resetDesign() {
    this.designSurfaceItems = [];
    this.currentPropertiesPanelItem = undefined;
  }

  getUpdateOnValueChangeControls(index?: number) {
    index = typeof index === 'number' ? index : 0;
    const controls: Array<IDynamicModelProperties> = [
      {
        formControlName: `updateOnValueChange-formControlName${
          index > 0 ? '-' + index : ''
        }`,
        label: index
          ? `Control ${index + 1}`
          : `Update Other Controls based on Value Change`,
        types: ['typeahead-all', 'date'],
        instruction:
          (index ? `${index + 1}. ` : '') + `Control name to update.`,
        type: 'input',
        isAdvanced: true,
        options: {
          admin: {
            allowDelete: index > 0,
          },
        },
      },
      {
        formControlName: `updateOnValueChange-property${
          index > 0 ? '-' + index : ''
        }`,
        types: ['typeahead-all'],
        instruction: 'The backend api property to use',
        type: 'input',
        isAdvanced: true,
        options: {
          labelIgnore: true,
        },
      },
      {
        formControlName: `updateOnValueChange-matchTargetProperty${
          index > 0 ? '-' + index : ''
        }`,
        types: ['typeahead-all'],
        instruction:
          '(Optional) If a property mapping is needed, specify the target property here.',
        type: 'input',
        isAdvanced: true,
        options: {
          labelIgnore: true,
        },
      },
      {
        formControlName: `updateOnValueChange-validation${
          index > 0 ? '-' + index : ''
        }`,
        types: ['date'],
        instruction: 'Enter a validation: minDate, maxDate',
        type: 'input',
        isAdvanced: true,
        options: {
          labelIgnore: true,
        },
      },
    ];
    return controls;
  }
}
