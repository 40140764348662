import { Directive, Injector, Input } from '@angular/core';
import { BaseComponent } from '@ups/xplat/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as moment from 'moment/moment';
import { ISafetyItem } from '../models';
import { EmployeeSafetyService } from '../services/employee-safety.service';
import { AttachmentVpService } from '@ups/xplat/api/services';
import { EmployeeService } from '../services/employee.service';
import { IEmployeeInfo, ProfileModel } from '@ups/xplat/api/dto';
import { UserState } from '@ups/user';
import { Store } from '@ngrx/store';

@Directive()
export abstract class ProfileSafetyBaseComponent extends BaseComponent {
  isLoading = false;

  @Input() profile: ProfileModel;
  authProfile: IEmployeeInfo;
  protected attachmentService: AttachmentVpService;

  constructor(
    injector: Injector,
    protected employeeSafety: EmployeeSafetyService,
    protected employeeInfo: EmployeeService,
    protected store: Store<any>
  ) {
    super();
    this.attachmentService = injector.get(AttachmentVpService);
  }

  ngOnInit() {
    this.isLoading = true;
    this.store
      .pipe(UserState.selectCurrentTruthy(this.destroy$))
      .subscribe((x) => {
        this.authProfile = x;
        this.loadData();
      });
  }

  loadData() {
    this.employeeSafety
      .getTrainingById(this.profile?.hrRef)
      .pipe(
        catchError((err) => {
          this.isLoading = false;
          // console.log('error:', err);
          return of(null);
        })
      )
      .subscribe((results: Array<ISafetyItem>) => {
        // console.log('result:', results);
        const now = moment();
        this.data = results
          .map((r) => {
            return {
              ...r,
              isExpired: moment(r.udExpireDate).diff(now, 'days') < 0,
              dropdownExpired:
                moment(r.udExpireDate).diff(now, 'days') < 0
                  ? 'Inactive'
                  : 'Active',
            };
          })
          .sort((a, b) => {
            return a.isExpired ? 1 : -1;
          });
        this.isLoading = false;
      });
  }

  abstract set data(value: Array<ISafetyItem>);

  getFile(attachmentID: number): Promise<any> {
    return new Promise((resolve) => {
      if (attachmentID) {
        this.attachmentService.getFileById2(attachmentID).then(
          (data) => {
            // A byte array serialized by JSON is then returned as Base64 encoded string
            resolve(data);
          },
          (err) => {
            resolve(null);
          }
        );
      }
    });
  }
}
