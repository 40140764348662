<div class="properties-editor">
  <div class="pe-field">
    <label for="src">Src</label>
    <input kendoTextBox [(ngModel)]="peSrc" id="src" class="form-input" />
  </div>

  <div class="pe-field">
    <label for="height">Height</label>
    <input kendoTextBox [(ngModel)]="peHeight" id="height" class="form-input" />
  </div>

  <div class="pe-field">
    <label for="width">Width</label>
    <input kendoTextBox [(ngModel)]="peWidth" id="width" class="form-input" />
  </div>

  <div class="k-form-buttons dr-prop-actions">
    <button class="k-button k-primary" (click)="onApplyClick()">Apply</button>
    <i class="far fa-trash delete" (click)="onDeleteClick()"></i>
  </div>
</div>
