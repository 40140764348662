import { parseData } from '@ups/xplat/utils';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  TimeSheetSaveDto,
  TimeSheetDetailDto,
  IODataResult,
  TimeSheetRecordDto,
  TimeSheetDto,
} from '@ups/xplat/api/dto';
import {
  environment,
  MyHttpClientFactory,
  ResponseCasingEnum,
} from '@ups/xplat/core';
import { map } from 'rxjs/operators';

const GET_LABOR_TIME_SHEET_DATA = '/api/timeSheet/labor/{:jobID}';
const GET_EQUIPMENT_TIME_SHEET_DATA = '/api/timeSheet/equipment/{:jobID}';
const GET_LABOR_TIME_SHEETS = '/api/timesheets/labor/{:jobID}';
const VALIDATE_TIME_SHEET_DESIGNATION = '/api/timesheet/validate/designation';
const GET_EQUIPMENT_TIME_SHEETS = '/api/timesheets/equipment/{:jobID}';
const SAVE_LABOR_TIME_SHEET = '/api/timeSheet/labor';
const SAVE_EQUIPMENT_TIME_SHEET = '/api/timeSheet/equipment';
const GET_LABOR_TIME_SHEET_TOTALS =
  '/api/timeSheet/labor/totals/{:timeSheetID}';
const GET_EQUIPMENT_TIME_SHEET_TOTALS =
  '/api/timeSheet/equipment/totals/{:timeSheetID}';
const GET_TIME_SHEETS = '/api/timesheets/{:jobID}';
const SAVE_TIME_SHEET = '/api/timesheet';

@Injectable({
  providedIn: 'root',
})
export class TimeSheetService {
  spartaClient: HttpClient;
  http: HttpClient;

  constructor(private clientFactory: MyHttpClientFactory) {
    const urlBase = environment.urls.spartaAPI;
    this.http = this.clientFactory.createHttpClient(
      urlBase,
      true,
      ResponseCasingEnum.PascalCase
    );
    this.spartaClient = this.clientFactory.createHttpClient(
      urlBase,
      true,
      ResponseCasingEnum.CamelCase
    );
  }

  public queryTimeSheetData(
    jobID: string,
    odataString: string,
    timeSheetID: string = null,
    allRecords: boolean = false,
    weekEndDate: string = null,
    po: string = null,
    phase: string = null
  ): Observable<IODataResult<TimeSheetRecordDto>> {
    let url = GET_LABOR_TIME_SHEET_DATA.split('{:jobID}').join(jobID);

    url += '?$format=json&$count=true';

    if (timeSheetID) {
      url += `&timeSheetID=${timeSheetID}`;
    }

    if (allRecords === true) {
      url += `&allRecords=true`;
    }

    if (weekEndDate) {
      url += `&weekEndDate=${weekEndDate}`;
    }

    if (po) {
      url += `&po=${escape(po).replace('/', '%2F')}`;
    }

    if (phase) {
      url += `&phase=${escape(phase).replace('/', '%2F')}`;
    }

    url += odataString ? '&' + odataString : '';

    console.log('Calling service: ', url);

    return this.http.get(url).pipe(map(parseData));
  }

  public queryEquipmentTimeSheetData(
    jobID: string,
    odataString: string,
    timeSheetID: string = null,
    allRecords: boolean = false,
    weekEndDate: string = null,
    po: string = null,
    phase: string = null
  ): Observable<IODataResult> {
    let url = GET_EQUIPMENT_TIME_SHEET_DATA.split('{:jobID}').join(jobID);

    url += '?$format=json&$count=true';

    if (timeSheetID) {
      url += `&timeSheetID=${timeSheetID}`;
    }

    if (allRecords === true) {
      url += `&allRecords=true`;
    }

    if (weekEndDate) {
      url += `&weekEndDate=${weekEndDate}`;
    }

    if (po) {
      url += `&po=${escape(po).replace('/', '%2F')}`;
    }

    if (phase) {
      url += `&phase=${escape(phase).replace('/', '%2F')}`;
    }

    url += odataString ? '&' + odataString : '';

    console.log('Calling service: ', url);

    return this.http.get(url).pipe(map(parseData));
  }

  public getLaborTimeSheets(jobID: string) {
    const url = GET_LABOR_TIME_SHEETS.split('{:jobID}').join(jobID);

    return this.spartaClient.get(url).toPromise();
  }

  public getEquipmentTimeSheets(jobID: string) {
    const url = GET_EQUIPMENT_TIME_SHEETS.split('{:jobID}').join(jobID);

    return this.spartaClient.get(url).toPromise();
  }

  public validateTimeSheetDesignation(
    dto: TimeSheetSaveDto
  ): Promise<string[]> {
    return this.http
      .post(VALIDATE_TIME_SHEET_DESIGNATION, dto)
      .toPromise() as Promise<string[]>;
  }

  public saveLaborTimeSheet(dto: TimeSheetSaveDto) {
    return this.http.post(SAVE_LABOR_TIME_SHEET, dto).toPromise();
  }

  public saveEquipmentTimeSheet(dto: TimeSheetSaveDto) {
    return this.http.post(SAVE_EQUIPMENT_TIME_SHEET, dto).toPromise();
  }

  public getLaborTimeSheetTotals(timeSheetID: string) {
    const url =
      GET_LABOR_TIME_SHEET_TOTALS.split('{:timeSheetID}').join(timeSheetID);

    return this.spartaClient.get(url).toPromise();
  }

  public getEquipmentTimeSheetTotals(timeSheetID: string) {
    const url =
      GET_EQUIPMENT_TIME_SHEET_TOTALS.split('{:timeSheetID}').join(timeSheetID);

    return this.spartaClient.get(url).toPromise();
  }

  public getTimeSheets(jobID: string, odataString: string) {
    let url = GET_TIME_SHEETS.split('{:jobID}').join(jobID);
    url += '?$format=json&$count=true';
    url += odataString ? '&' + odataString : '';

    console.log('Calling service: ', url);

    return this.http.get(url).pipe(map(parseData));
  }

  public updateTimeSheet(dtos: TimeSheetDetailDto[]) {
    return this.http.post(SAVE_TIME_SHEET, dtos).toPromise();
  }

  editTimeSheet(dto: TimeSheetDto) {
    return this.http.put(SAVE_TIME_SHEET, dto).toPromise();
  }
}
