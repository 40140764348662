import { HttpClient } from '@angular/common/http';
import { Injectable, Signal, computed } from '@angular/core';

import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, Observable, map, distinctUntilChanged } from 'rxjs';

import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  handleReturnObject,
  LogService,
  environment,
} from '@ups/xplat/core';

import { SecurityFeatureGroupModel } from './security-feature-group-model';
import { SecurityFeatureModel } from './security-feature-model';
import { SecurityProfile } from './security-profile.model';
import { Store } from '@ngrx/store';
import { UserState } from '@ups/user';
import { PermissionType } from './security-constants';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';

export interface ISecurityFeatureGroupsIndexer {
  [index: string]: SecurityFeatureGroupModel;
}

export interface ISecurityFeaturesIndexer {
  [index: string]: SecurityFeatureModel;
}

export enum PermissionsChangedEnum {
  uninitialized,
  set,
  cleared,
}

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  public featureGroupsByName: ISecurityFeatureGroupsIndexer = {};
  public featuresById: ISecurityFeaturesIndexer = {};
  public featuresByUniqName: ISecurityFeaturesIndexer = {};
  public permissionsChanged$ = new BehaviorSubject<PermissionsChangedEnum>(
    PermissionsChangedEnum.uninitialized
  );
  protected httpClient: HttpClient;

  private permissionChanged = toSignal(
    this.permissionsChanged$.pipe(takeUntilDestroyed())
  );

  constructor(
    protected log: LogService,
    protected store: Store,
    protected auth: AuthService,
    protected clientFactory: MyHttpClientFactory
  ) {
    this.httpClient = clientFactory.createHttpClient(
      environment.security.apiUrl,
      true,
      ResponseCasingEnum.CamelCase
    );

    // NOTE: this used to use this.auth.user$?.subscribe...
    // that has proven to not be reliable and does not always fire leading to odd results at times
    this.store
      .select(UserState.selectCurrent)
      .pipe(distinctUntilChanged())
      .subscribe((user) => {
        // console.log('SecurityService user$ fired!')
        this.clearUserPermissions();

        if (user) {
          // console.log('user.Auth0UserId:', user.Auth0UserId)
          this.getSecurityForUser(user.Auth0UserId).subscribe(
            (data: Array<unknown>) => this.setUserPermissions(data)
          );
        }
      });
  }

  getPermissionStatus(
    securityConstant: string,
    permissionType: PermissionType
  ): Signal<boolean> {
    return computed(() => {
      const change = this.permissionChanged();

      if (change === PermissionsChangedEnum.set) {
        const permissions = { ...this.getFeatureById(securityConstant) };
        return permissions[permissionType];
      } else if (
        permissionType === PermissionType.approvalNeeded ||
        permissionType === PermissionType.approvalNeededSelf
      ) {
        return true;
      }
      return false;
    });
  }

  getPermissionStatusObject(
    securityConstant: string
  ): Signal<SecurityFeatureModel> {
    return computed(() => {
      const change = this.permissionChanged();

      if (change === PermissionsChangedEnum.set) {
        return this.getFeatureById(securityConstant);
      }

      return null;
    });
  }

  getRolesForApp(userId: string, appId: string) {
    const url = `api/roles/${encodeURIComponent(userId)}/${appId}`;

    return this.httpClient
      .get(url)
      .pipe(
        handleReturnObject(
          `Some error occurred while fetching roles for app: '${appId}' & user: '${userId}', original error message was: {{error}}`
        )
      );
  }

  /** Useful to find UserId for the currently logged in Auth0UserId or other additional data like HrRef */
  getSecurityProfileOfLoggedInUser(): Observable<SecurityProfile> {
    return this.httpClient
      .get('api/userProfile')
      .pipe(map((x: { data: SecurityProfile }) => x.data));
  }

  /** Case sensitive search by name */
  public getFeatureGroupByName(
    featureGroupName: string
  ): SecurityFeatureGroupModel {
    if (featureGroupName in this.featureGroupsByName)
      return this.featureGroupsByName[featureGroupName];

    return new SecurityFeatureGroupModel();
  }

  /** search by guid */
  public getFeatureById(uniqId: string): SecurityFeatureModel {
    uniqId = (uniqId || '').toLowerCase();

    if (uniqId in this.featuresById) return this.featuresById[uniqId];

    return new SecurityFeatureModel();
  }

  /** Case sensitive search by uniq. name */
  public getFeatureByUniqName(uniqFeatureName: string): SecurityFeatureModel {
    if (uniqFeatureName in this.featuresByUniqName)
      return this.featuresByUniqName[uniqFeatureName];

    return new SecurityFeatureModel();
  }

  protected getSecurityForUser(userAuth0Id: string): Observable<unknown> {
    if (environment.security.securityOverride) {
      if (environment.security.securityOverrideJson) {
        this.log.debug('Loading OVERRIDDEN user permissions (local)');

        // NOTE: we're just fetching a plain JSON file
        return this.httpClient.get(environment.security.securityOverrideJson);
      } else {
        this.log.debug(
          'No OVERRIDDEN user permissions set, please update environment.ts: security.securityOverrideJson'
        );
      }
    }

    const appGuid = environment.security.appId;
    const url = `api/userAppPermissions/${encodeURIComponent(
      userAuth0Id
    )}/${appGuid}`;

    return this.httpClient
      .get(url)
      .pipe(
        handleReturnObject(
          `Some error occurred while fetching permission for app: '${appGuid}' & user: '${userAuth0Id}', original error message was: {{error}}`
        )
      );
  }

  /**
   * @deprecated
   * Avoid creating and accessing FeatureGroup via this.securityService.security[featureGroupId].
   * If you still need feature group, use: this.securityService.getFeatureGroupByName(name)
   *
   * For querying a given feature (globally) use:
   * - this.securityService.getFeatureById(id)
   * - or this.securityService.getfeatureByUniqName(name)
   *
   * NOTE: the get* functions won't return undefined | null, but they will return an empty object (with no permissions) as default!
   */
  get security(): ISecurityFeatureGroupsIndexer {
    return this.featureGroupsByName;
  }

  protected clearUserPermissions() {
    // this.log.debug('Clearing user permissions');
    this.featureGroupsByName = {};
    this.featuresById = {};
    this.featuresByUniqName = {};
    this.permissionsChanged$.next(PermissionsChangedEnum.cleared);
  }

  protected setUserPermissions(data: Array<unknown>) {
    // this.log.debug('Setting user permissions');

    const typedData = data
      ? data.map((item) => new SecurityFeatureGroupModel(item))
      : null;

    // create:
    // groups by name
    // global features by id
    // global features by uniq. name
    this.featureGroupsByName = {};
    this.featuresById = {};
    this.featuresByUniqName = {};

    (typedData || []).forEach((featureGroup) => {
      this.featureGroupsByName[featureGroup.featureGroupName] = featureGroup;

      featureGroup.features.forEach((feature) => {
        this.featuresById[feature.featureId] = feature;
        this.featuresByUniqName[feature.uniqFeatureName] = feature;
      });
    });

    // event
    this.permissionsChanged$.next(PermissionsChangedEnum.set);
  }
}
