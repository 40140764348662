import { Component, ChangeDetectorRef, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { CheckboxFilterPipe } from '../../pipes/checkbox-filter.pipe';

declare var jQuery;

@Component({
  templateUrl: './checkbox-filter.component.html',
  selector: 'ups-checkbox-filter',
  styleUrls: ['./checkbox-filter.component.scss'],
})
export class UPSCheckboxFilter {
  $el: any;
  prevCheckboxes: any[] = [];

  checkBoxes: any[] = [];

  @Input('checkboxes')
  set checkboxes(value: any[]) {
    this.checkBoxes = value;
    if (value !== undefined) this.prevCheckboxes = JSON.parse(JSON.stringify(this.checkBoxes));
  }

  get checkboxes(): any[] {
    return this.checkBoxes;
  }

  @Input('field') field: string;
  @Input('blankText') blankText: string = '(Blank)';
  prevSelectAll: boolean = true;
  selectAll: boolean = true;
  search: string = null;
  @Output() filterChanged = new EventEmitter<any>();
  @Output() checkboxesChanged = new EventEmitter<any[]>();
  isFiltered: boolean = false;

  constructor(el: ElementRef, private cdr: ChangeDetectorRef, private checkboxFilter: CheckboxFilterPipe) {
    this.$el = jQuery(el.nativeElement);
  }

  checkAllCheckboxes() {
    if (!this.checkBoxes) return;
    for (let i = 0; i < this.checkBoxes.length; i++) {
      this.checkBoxes[i].value = this.selectAll;
    }
    // this.filterChanged.emit(this.checkBoxes);
  }

  forceUpdateCheckboxes(): void {
    if (this.checkBoxes) this.checkBoxes = this.checkBoxes.slice();

    this.selectAll = false;

    let isAllCheckboxesChecked: boolean = true;
    this.checkBoxes.forEach((checkbox) => {
      if (!checkbox.value) {
        isAllCheckboxesChecked = false;
      }
    });
    if (isAllCheckboxesChecked) this.selectAll = true;

    // this.filterChanged.emit(this.checkBoxes);
  }

  createFilterItems(data: any[], property?: string) {
    if (property === undefined) property = this.field;
    if (property !== undefined) this.checkBoxes = this.checkboxFilter.createFilterItems(data, property, true, this.blankText);
    if (this.checkBoxes !== undefined) this.prevCheckboxes = JSON.parse(JSON.stringify(this.checkBoxes));
  }

  createFilterItemsFromStringArray(array: string[], blankText: string = '(Blank)') {
    this.blankText = blankText;
    let jf = [];
    for (let i = 0; i < array.length; i++) {
      jf.push({
        id: i,
        value: array[i],
        label: array[i] == null || array[i] === 'null' || array[i].trim().length === 0 ? blankText : array[i],
      });
    }
    this.checkBoxes = jf;
    if (this.checkBoxes !== undefined) this.prevCheckboxes = JSON.parse(JSON.stringify(this.checkBoxes));
  }

  updateFilterItems(data: any[], property?: string) {
    if (property === undefined || property === null) property = this.field;
    if (property !== undefined || property !== null) this.checkBoxes = this.checkboxFilter.createFilterItems(data, property, !(this.prevCheckboxes !== undefined && this.prevSelectAll === false), this.blankText);

    if (this.prevCheckboxes !== undefined && this.prevSelectAll === false) {
      for (let prevCB of this.prevCheckboxes) {
        for (let cb of this.checkBoxes) {
          if (cb.label === prevCB.label) {
            cb.value = prevCB.value;
          }
        }
      }
    }

    if (this.checkBoxes !== undefined && this.checkBoxes.length) this.prevCheckboxes = JSON.parse(JSON.stringify(this.checkBoxes));
  }

  transform(data: any[], property?: string) {
    if (property === undefined) property = this.field;
    if (property === undefined) return data;
    return this.checkboxFilter.transform(data, property, this.checkBoxes, false, this.blankText);
  }

  clear() {
    this.search = null;
    this.selectAll = true;
    this.checkAllCheckboxes();
  }

  reset() {
    this.clear();
    this.isFiltered = false;
    this.prevSelectAll = true;
    this.prevCheckboxes = undefined;
  }

  cancel() {
    if (this.prevCheckboxes) this.checkBoxes = JSON.parse(JSON.stringify(this.prevCheckboxes));
    this.selectAll = this.prevSelectAll;
    this.checkboxesChanged.emit(this.checkBoxes);
  }

  confirm() {
    this.filterChanged.emit(this.checkBoxes);
    this.prevSelectAll = this.selectAll;
    this.prevCheckboxes = JSON.parse(JSON.stringify(this.checkBoxes));
    this.isFiltered = !this.selectAll;
  }

  odataFilterString() {
    let odataFilter = '';
    if (this.checkBoxes && this.field) {
      let checked = this.checkBoxes.filter((c) => c.value === true);
      if (checked.length !== 0 && checked.length < this.checkBoxes.length) {
        odataFilter = odataFilter + '(';
        for (let c of checked) {
          odataFilter = odataFilter + (c.label === this.blankText ? this.field + ' eq null' : 'contains(tolower(' + this.field + "),tolower('" + c.label + "'))");
          if (c !== checked[checked.length - 1]) odataFilter = odataFilter + ' or ';
        }
        odataFilter = odataFilter + ')';
      }
    }
    return odataFilter;
  }
}
