import { Injectable } from '@angular/core';
import { ManPowerService } from '@ups/xplat/base/manpower';
import { ApplicationModel } from '@ups/xplat/api/dto';

@Injectable({
  providedIn: 'root',
})
export class BackgroundStatusResolver {
  constructor(
    private manPowerService: ManPowerService,
    private applicationModel: ApplicationModel
  ) {}

  public load(): Promise<any> {
    var self = this;
    if (this.applicationModel.backgroundStatuses.length == 0)
      return this.manPowerService
        .fetchBackgroundStatuses()
        .then((data) => (self.applicationModel.backgroundStatuses = data));
    else return Promise.resolve(this.applicationModel.backgroundStatuses);
  }
}
