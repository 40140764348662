import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[upsAutoFocus]',
})
export class AutoFocusDirective implements OnInit {
  directFocusElements = 'input, button, select, textarea, a[href]';
  constructor(private el: ElementRef) {}

  ngOnInit() {
    if (
      this.directFocusElements.includes(
        this.el.nativeElement.tagName.toLowerCase()
      )
    ) {
      this.el.nativeElement.focus();
    } else if (this.el.nativeElement.querySelector) {
      // If the element itself doesn't have a focus method, try to find a focusable child
      const focusable = this.el.nativeElement.querySelector(
        'input, button, select, textarea, a[href]'
      );
      if (focusable) {
        focusable.focus();
      }
    }
  }
}
