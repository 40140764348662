import { assertType } from './typescript';

/**
 * @description Produces a deep clone (no references to original object or child objects) of the provided object or array.
 */
export function deepClone<T = any>(obj: Array<unknown> | Object): T {
  const ret = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    if (obj[key] === null) {
      ret[key] = null;
    } else if (obj[key] instanceof Date) {
      //typeof date = [object Object], results in empty object.
      const value = obj[key];
      assertType<Date>(value);
      ret[key] = new Date(value.valueOf());
    } else if (typeof obj[key] === 'object') {
      ret[key] = deepClone(obj[key]);
    } else {
      ret[key] = obj[key];
    }
  }
  assertType<T>(ret);
  return ret;
}

interface ICloneable {
  [key: string]: any;
  [key: number]: any;
}
