/**
 * Usage: nameOf‹Person›("LastName") will return string "LastName"
 * but will cause error at compile time if LastName is not part of Person class.
 * Useful to get compile error if "LastName" on Person gets renamed but we forgot to rename all its usages in the code.
 * Example: Instead of
 * let colName: string = "LastName";
 * use
 * let colName: string = nameOf‹Person›("LastName");
 *
 * This helps us with detecting breaking code changes during compile time rather than during runtime.
 * To even more simplify it - see nameOfFactory‹T›
 * @returns Name of the property on the type provided.
 */
export const nameOf = <T>(name: keyof T) => name as string;
