import { Type } from '@angular/core';

import { IDynamicControlLayout } from '@ups/xplat/core';

import { AdminControl } from './dr-admin-models';

export type ToolboxGroupType = 'form' | 'dashboard' | 'drawing' | 'safety';

export interface AdminItemMapping {
  // TODO: get rid of this and just use IDynamicModel directly
  controlName: string;
  displayName: string;
  valueProperty?: string;
  icon: string;
  iconSrc: string;
  toolboxGroup: ToolboxGroupType;
  renderedComponent: Type<any>;
  editorComponent: Type<any>;
  // defaultControlValues: Partial<AdminControl>;
  defaultLayout: IDynamicControlLayout;
  defaultData: { [key: string]: any };
  renderDelay?: number;
}

export interface AdminToolboxPanelItem {
  controlName: string;
  displayName: string;
  icon: string;
  iconSrc: string;
  toolboxGroup: ToolboxGroupType;
}

export interface AdminDesignPanelItem {
  clientId: string; //Id of the item that was created on client and not saved yet
  id: string;
  controlName: string;
  // controlValues: Partial<AdminControl>;
  data: { [key: string]: string };
  layout?: IDynamicControlLayout;
}

export interface AdminPropertiesPanelItem {
  id: string;
  controlName: string;
  // controlValues: Partial<AdminControl>;
  data: { [key: string]: string };
}

export interface AdminPreviewPanelItem {
  id: string;
  controlName: string;
  // controlValues: Partial<AdminControl>;
  data: { [key: string]: string };
  layout: IDynamicControlLayout;
}

/*
export interface AdminPreviewPanelItem extends Partial<AdminControl> {
  //id: string;
  //controlName: string;
  //controlValues: Partial<DrAdminControlDto>;
}
*/
