import { BaseModel } from '../base.model';

export class EmployeeMultiClassDto extends BaseModel {
  public hRRef: number;
  public pRCo: number;
  public companyId: string;
  public tradeClassId: string;
  public linkedClassId: string;

  constructor(o: any = null) {
    super(o);
  }
}
