import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { DialogComponent } from '@progress/kendo-angular-dialog';

import { Subscription } from 'rxjs';

import { Communication, FeatureNote } from '@ups/xplat/api/dto';
import { ReleaseNoteService } from '@ups/xplat/core';

@Component({
  selector: 'ups-release-note-popup',
  templateUrl: './release-note-popup.component.html',
  styleUrls: ['./release-note-popup.component.scss'],
})
export class ReleaseNotePopupComponent implements OnInit {
  usedFeatureGroups: { sectionHeader: string; featureNotes: any[] }[];

  releaseNote: Communication;
  showDialog = false;
  showDetails = false;
  doLogging = true;
  openedAt = new Date().getTime();
  expandedAt: number = null; // Date().getTime() value
  closeSubscription: Subscription = null;

  @ViewChild('dialog', { static: false }) dialog: DialogComponent;
  @ViewChild('details', { static: false, read: ElementRef })
  detailsContent: ElementRef;
  helpDownloadToken: any;
  storedDetailsHeight: number;
  detailsElement: HTMLElement;

  constructor(
    public releaseService: ReleaseNoteService,
    public domSanitizer: DomSanitizer
  ) {
    this.releaseService.showReleaseNotes$.subscribe((x) => {
      this.showReleasePopup(x, true);
    });
  }

  ngOnInit() {}

  onClose() {
    this.showDialog = false;
    if (this.doLogging) {
      const now = new Date().getTime();
      this.releaseService.uploadLogs({
        releaseNoteId: this.releaseNote.communicationId,
        minutesAfterExpansion:
          this.expandedAt == null ? 0 : (now - this.expandedAt) / 60000,
        minutesSpent: (now - this.openedAt) / 60000,
      });
    }
  }

  toggleDetails() {
    if (this.showDetails) {
      this.collapseDetails();
    } else {
      this.expandDetails();
    }
  }

  expandDetails() {
    this.showDetails = true;
    // this.expandedAt = this.expandedAt || new Date().getTime(); // if expandedAt not null, new date.
    // this.detailsElement.style.setProperty('max-height', this.storedDetailsHeight + 'px');
    // this.detailsElement.style.removeProperty('padding');
  }

  collapseDetails() {
    this.showDetails = false;
    // this.storedDetailsHeight = this.detailsElement.scrollHeight;
    // this.detailsElement.style.setProperty('max-height', this.storedDetailsHeight + 'px');
    // setTimeout(() => {
    //   this.detailsElement.style.setProperty('max-height', '0');
    //   this.detailsElement.style.setProperty('padding-top', '0');
    //   this.detailsElement.style.setProperty('padding-bottom', '0');
    // });
  }

  public showReleasePopup(rn: Communication, logging = true) {
    this.releaseNote = rn;
    this.usedFeatureGroups = [];
    this.showDetails = false;
    this.doLogging = logging;

    this.releaseService.getDownloadToken().then((x) => {
      this.helpDownloadToken = x;
    });

    const featureGroupDict: {
      [id: string]: { sectionHeader: string; featureNotes: FeatureNote[] };
    } = {}; // FeatureGroupId: name, id, notes

    if (this.releaseNote.featureNotes) {
      this.releaseNote.featureNotes.forEach((x) => {
        if (!featureGroupDict[x.sectionHeader]) {
          // ensure its featuregroup is marked as used
          featureGroupDict[x.sectionHeader] = {
            sectionHeader: x.sectionHeader,
            featureNotes: [],
          };
        }

        // Push x into category
        featureGroupDict[x.sectionHeader].featureNotes.push(x);
      });

      Object.entries(featureGroupDict).forEach((x) => {
        // Get the values from the dict.
        this.usedFeatureGroups.push(x[1]);
      });
    }

    this.showDialog = true;
    this.openedAt = new Date().getTime();
    this.expandedAt = null; // Prevents cumulating expansions by closing and reopening notes.
    // setTimeout(() => {
    //   console.dir(this.detailsContent);
    //   this.detailsElement = this.detailsContent.nativeElement as HTMLElement;
    //   this.storedDetailsHeight = this.detailsElement.scrollHeight;
    //   // this.collapseDetails()
    //   // this.detailsElement.style.setProperty('max-height', '0');
    //   console.dir(this.detailsElement);
    // });
  }
}
