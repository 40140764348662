import { Directive, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
  BaseComponent,
  IDynamicPageOptions,
  LogService,
} from '@ups/xplat/core';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DynamicRenderService } from '../../services/dynamic-render.service';
import { DynamicEventBusTypes } from '../../utils/dynamic-event-bus-types';

@Directive()
export abstract class DynamicPageRouteFormDetailComponent
  extends BaseComponent
  implements OnInit
{
  options: IDynamicPageOptions;

  activeRoute = inject(ActivatedRoute);
  log = inject(LogService);
  dynamicService = inject(DynamicRenderService);

  ngOnInit() {
    combineLatest([this.activeRoute.params, this.activeRoute.queryParams])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([params, queryParams]) => {
        // this.log.debug('form route data:', params, queryParams);
        // ensure the dynamic formgroup is wiped out and reset when route/page change occurs
        // the dynamic form is completely recreated/rerendered on each page navigation with new dynamic form data
        this.dynamicService.eventBus.emit(
          DynamicEventBusTypes.dynamicFormTotalReset,
          true
        );
        this.options = {
          name: params && params.id ? params.id : null,
          dynamicResponseId:
            queryParams && queryParams.dynamicResponseId
              ? queryParams.dynamicResponseId
              : null,
          shortName:
            queryParams && queryParams.shortName ? queryParams.shortName : null,
          isCoaching:
            queryParams && queryParams.isCoaching
              ? queryParams.isCoaching === '1'
              : null,
          isReview:
            queryParams && queryParams.isReview
              ? queryParams.isReview === '1'
              : null,
          isEditReview:
            queryParams && queryParams.isEditReview
              ? queryParams.isEditReview === '1'
              : null,
          reviewId:
            queryParams && queryParams.reviewId !== 'null'
              ? queryParams.reviewId
              : null,
          deleteComment:
            queryParams && queryParams.deleteComment !== 'null'
              ? queryParams.deleteComment
              : null,
          isDraft:
            queryParams && queryParams.isDraft
              ? queryParams.isDraft === '1'
              : null,
          showComments:
            queryParams && queryParams.showComments === 'true' ? true : false,
          showLastModified:
            queryParams && queryParams.showLastModified === 'true'
              ? true
              : false,
          submittedUserId:
            queryParams && queryParams.submittedUserId
              ? queryParams.submittedUserId
              : null,
        };

        // this.log.debug('Options', this.options);
      });
  }
}
