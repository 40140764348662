import {
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { isDate } from '@ups/xplat/utils';

export const patchDateTo4Digits = (
  filter: CompositeFilterDescriptor | FilterDescriptor | any
) => {
  if (filter.filters) {
    filter.filters.forEach((f) => patchDateTo4Digits(f));
  } else if (filter.filter) {
    patchDateTo4Digits(filter.filter);
  }

  if (
    filter.value &&
    isDate(filter.value) &&
    filter.value.getFullYear() < 100 /* is 2 digit */
  ) {
    filter.value = new Date(
      filter.value.getFullYear() + 2000,
      filter.value.getMonth(),
      filter.value.getDate()
    );
  }
};
