import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { CustomValidatorsService } from '@ups/xplat/core';

@Directive({
  selector: '[upsURLPattern]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: UrlPatternDirective, multi: true },
  ],
})
export class UrlPatternDirective implements Validator {
  constructor(private customValidator: CustomValidatorsService) {}

  validate(control: AbstractControl): { [key: string]: unknown } | null {
    return this.customValidator.urlValidator()(control);
  }
}
