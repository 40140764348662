import { HttpClient } from '@angular/common/http';
import {
  ComponentFactoryResolver,
  forwardRef,
  Inject,
  Injectable,
} from '@angular/core';

import { firstValueFrom, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../environments';

import { ResponseCasingEnum } from '../http/response-enum';
import { MyHttpClientFactory } from '../http/my-http-client-factory';
import {
  Communication,
  LoginResponse,
  OnCloseRelease,
  ReleaseLoginBody,
  ReleaseType,
} from '@ups/xplat/api/dto';

const GET_FULL_RELEASE_NOTE = 'api/releasenotes/view/:id';
const LOGIN_CALLBACK_URL = 'api/releasenotes/onlogin'; // Called on login for tracking and displaying release notes.
const LOGGING_CALLBACK_URL = 'api/releasenotes/closed'; // Called on login for tracking and displaying release notes.
const GET_RELEASE_NOTES = 'api/releasenotes';
const GET_RELEASE_TYPES = 'api/releasetypes';
const GET_MAC_TOKEN = '/api/documents/download-mac';
const DOWNLOAD_DOCUMENT_MAC = '/api/documents/download-file-mac';
const CREATE_RELEASE_NOTE = 'api/releasenotes';
const DELETE_RELEASE_NOTE = 'api/releasenotes/:id';

@Injectable({ providedIn: 'root' })
export class ReleaseNoteService {
  httpClient: HttpClient;
  azureClient: HttpClient;
  azureAPIURL: string;
  securityClient: HttpClient;
  showAnnouncementsSubject: Subject<Communication[]> = new Subject<
    Communication[]
  >();
  showAnnouncements$ = this.showAnnouncementsSubject.asObservable();
  showReleaseNotesSubject: Subject<Communication> =
    new Subject<Communication>();
  showReleaseNotes$ = this.showReleaseNotesSubject.asObservable();
  showTipsSubject: Subject<Communication> = new Subject<Communication>();
  showTips$ = this.showTipsSubject.asObservable();
  _token: string;
  _tokenFetched: Date;
  _inProgress: Observable<string>;
  private _releaseNote: Communication;

  constructor(
    @Inject(forwardRef(() => MyHttpClientFactory))
    private clientFactory: MyHttpClientFactory,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.httpClient = clientFactory.createHttpClient(
      environment.security.apiUrl,
      true,
      ResponseCasingEnum.CamelCase
    );
    this.azureAPIURL = environment.urls['azureAPI'];
    this.azureClient = clientFactory.createHttpClient(
      this.azureAPIURL,
      true,
      ResponseCasingEnum.CamelCase
    );
    this.securityClient = clientFactory.createHttpClient(
      environment.urls.securityAPI,
      true,
      ResponseCasingEnum.CamelCase
    );
  }

  onLogin() {
    const body: ReleaseLoginBody = {
      applicationId: environment.security.appId,
    };
    this.httpClient
      .post<LoginResponse>(LOGIN_CALLBACK_URL, body)
      .toPromise()
      .then((x) => {
        if (x) {
          if (x.announcements && x.announcements.length > 0)
            this.showAnnouncementsSubject.next(x.announcements);
          if (x.viewed === false)
            this.showReleaseNotesSubject.next(x.latestReleaseNote);
          if (x.tips && x.tips.length > 0)
            this.showAnnouncementsSubject.next(x.tips);
          this._releaseNote = x.latestReleaseNote;
        }
      });
  }

  getReleaseTypes(): Observable<ReleaseType[]> {
    // if (this._releaseTypes) return of(this._releaseTypes);
    // else {
    //   return this.http.get<ReleaseType[]>(GET_RELEASE_TYPES).pipe(
    //     map((x) => {
    //       this._releaseTypes = x;
    //       return x;
    //     })
    //   );
    // }

    return this.httpClient.get<ReleaseType[]>(GET_RELEASE_TYPES).pipe(
      map((type) => {
        return type;
      })
    );
  }

  public getReleaseNotes(appId?: string, odataQuery?: string) {
    let url = GET_RELEASE_NOTES;

    if (odataQuery || appId) url += '?';
    if (appId) {
      url += `applicationId=${appId}`;
      if (odataQuery) {
        url += '&$format=json&$count=true&' + odataQuery;
      }
    } else if (odataQuery) {
      url += '$format=json&$count=true&' + odataQuery;
    }

    return this.httpClient.get<{ value: Array<Communication> }>(url);
  }

  public getFullReleaseNote(releaseNoteId: string) {
    let url = GET_FULL_RELEASE_NOTE;
    url = url.replace(':id', releaseNoteId);
    return this.httpClient.get<Communication>(url);
  }

  public deleteReleaseNote(guid: string): Observable<boolean> {
    let url = DELETE_RELEASE_NOTE;
    url = url.replace(':id', guid);
    return this.httpClient.delete<boolean>(url);
  }

  async getLatestReleaseNote(): Promise<Communication> {
    if (this._releaseNote) {
      return this._releaseNote;
    } else {
      const body: ReleaseLoginBody = {
        applicationId: environment.security.appId,
      };
      return await this.httpClient
        .post<LoginResponse>(LOGIN_CALLBACK_URL, body)
        .toPromise()
        .then((x) => {
          this._releaseNote = x.latestReleaseNote;
          return this._releaseNote;
        });
    }
  }

  getDownloadToken() {
    if (
      !(
        this._token &&
        new Date().getTime() - this._tokenFetched.getTime() < 30 * 60 * 1000
      )
    ) {
      if (this._inProgress) {
        return firstValueFrom(this._inProgress);
      }
      const url = `${GET_MAC_TOKEN}`;
      this._inProgress = this.azureClient
        .get(url, { responseType: 'text' })
        .pipe(
          //Response has quotes wrapped around it
          map((x) => x.replace(/(^"|"$)/g, ''))
        );
      return firstValueFrom(this._inProgress).then((x) => {
        this._token = x;
        this._tokenFetched = new Date();
        return x;
      });
    }
    return firstValueFrom(of(this._token));
  }

  getDownloadUrl(
    location: string,
    fileName: string,
    macToken: string,
    inline = false
  ) {
    return `${this.azureAPIURL}${DOWNLOAD_DOCUMENT_MAC}/${location}/${fileName}/?mac=${macToken}&inline=${inline}`;
  }

  uploadLogs(loggingTemplate: OnCloseRelease) {
    this.httpClient
      .post(LOGGING_CALLBACK_URL, loggingTemplate)
      .subscribe(() => {});
  }

  public createReleaseNote(
    template: Partial<Communication>
  ): Observable<boolean> {
    return this.securityClient.post<boolean>(CREATE_RELEASE_NOTE, template);
  }
}
