import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
  Output,
  EventEmitter,
  Type,
  ComponentFactoryResolver,
} from '@angular/core';

import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import {
  DashboardLayoutComponent,
  PanelModel,
} from '@syncfusion/ej2-angular-layouts';

import {
  AdminDesignPanelItem,
  AdminItemDesignItemSelectRequest,
  AdminDesignSaveRequest,
  AdminItemPropsDeleteRequest,
} from '../../../models';
import { DrAdminMapResolverService } from '../../../services/dr-admin-map-resolver.service';

export type Content<T> = string | TemplateRef<T> | Type<T>;

@Component({
  selector: 'admin-design-web',
  templateUrl: 'admin-design-web.component.html',
  styleUrls: ['admin-design-web.component.scss'],
})
export class AdminDesignWebComponent implements OnInit {
  @Output()
  public designSave: EventEmitter<AdminDesignSaveRequest> = new EventEmitter();
  // @Output() public designReset: EventEmitter<never> = new EventEmitter();

  @Output()
  public itemSelected: EventEmitter<AdminItemDesignItemSelectRequest> = new EventEmitter();

  @Output()
  public deleteControl: EventEmitter<AdminItemPropsDeleteRequest> = new EventEmitter();

  @ViewChild('adminDesignLayout') dashboard: DashboardLayoutComponent;
  //@ViewChild('viewContainer', { read: ViewContainerRef, static: true })
  //viewContainer: ViewContainerRef;

  @ViewChild(TemplateRef) tpl: TemplateRef<any>;
  @ViewChild('viewContainerRef', { read: ViewContainerRef })
  viewContainerRef: ViewContainerRef;

  public drFormGroup = new UntypedFormGroup({});

  public allowFloating = false;
  public cellSpacing: number[] = [2, 2];
  public allowResizing = true;

  constructor(
    private adminMapResolverService: DrAdminMapResolverService, //private // @Inject(DOCUMENT) private document: Document
    private resolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {}

  addNewDesignPanelItem(newItem: AdminDesignPanelItem): void {
    // this.createComponent(newItem);

    this.getPanelContentHtmlElement(newItem).then(
      (htmlElement: HTMLElement) => {
        setTimeout(() => {
          const proxy = this;
          // TODO: Inject the document ?
          document
            .getElementById(newItem.clientId)
            .addEventListener('click', function () {
              proxy.onComponentSelect(newItem);
            });

          document
            .getElementById('dr-edit-del-' + newItem.clientId)
            .addEventListener('click', function (e) {
              e.stopPropagation();
              if (confirm('are you sure?')) {
                proxy.deleteControl.emit({
                  id: newItem.clientId,
                });
              }
            });
        }, 10);

        const newPanel: PanelModel = {
          id: newItem.clientId,
          sizeX: newItem.layout.sizeX,
          sizeY: newItem.layout.sizeY,
          row: newItem.layout.row,
          col: newItem.layout.col,
          content: htmlElement.outerHTML,
        };
        this.dashboard.addPanel(newPanel);
      }
    );
  }

  public updateDesignSurfaceItem(
    updatedDesignItem: AdminDesignPanelItem
  ): void {
    this.getPanelContentHtmlElement(updatedDesignItem).then(
      (htmlElement: HTMLElement) => {
        setTimeout(() => {
          const proxy = this;
          // TODO: Inject the document ?
          document
            .getElementById(updatedDesignItem.clientId)
            .addEventListener('click', function () {
              proxy.onComponentSelect(updatedDesignItem);
            });
        }, 10);

        const updatedPanel: PanelModel = {
          id: updatedDesignItem.clientId,
          sizeX: updatedDesignItem.layout.sizeX,
          sizeY: updatedDesignItem.layout.sizeY,
          row: updatedDesignItem.layout.row,
          col: updatedDesignItem.layout.col,
          content: htmlElement.outerHTML,
        };

        this.dashboard.updatePanel(updatedPanel);
      }
    );
  }

  public deleteDesignSurfaceItem(designItemId: string) {
    this.dashboard.removePanel(designItemId);
  }

  private getPanelContentHtmlElement(
    designItem: AdminDesignPanelItem
  ): Promise<HTMLElement> {
    return new Promise<HTMLElement>((resolve, reject) => {
      const wrapperComponentRef =
        this.adminMapResolverService.createDesignComponentWrapper();
      const renderCmpType =
        this.adminMapResolverService.getRenderComponentTypeForControlName(
          designItem.controlName
        );
      const renderDelay =
        this.adminMapResolverService.getRenderDelayForControlName(
          designItem.controlName
        );

      wrapperComponentRef.instance.childComponentType = renderCmpType;

      wrapperComponentRef.instance.childConfig = {
        formControlName: designItem.clientId,
        type: <any>designItem.controlName,
        validators: null,
        options: {
          ...(designItem.data || {}),
          layout: {
            ...(designItem.layout || {}),
          },
        },
      };
      wrapperComponentRef.instance.childFormGroup = this.drFormGroup;
      wrapperComponentRef.instance.renderDelay = renderDelay;
      wrapperComponentRef.instance.childLoaded.subscribe((ok) => {
        // wrapperComponentRef.instance.componentRef.hostView.detectChanges();
        this.drFormGroup.addControl(designItem.clientId, new UntypedFormControl(''));

        const htmlEl = wrapperComponentRef.location
          .nativeElement as HTMLElement;
        htmlEl.id = designItem.clientId;

        resolve(htmlEl);
      });
      wrapperComponentRef.hostView.detectChanges();

      /*
    const renderComponentRef = this.adminMapResolverService.createRenderComponentRef(
      designItem.controlName,
    );
    */

      /*
    renderComponentRef.instance.config = {
      // id: designItem.id ? designItem.id : designItem.clientId,
      id: designItem.clientId,
      controlType: designItem.controlName,
      validatorFn: null,
      // ...designItem.controlValues,
      data: designItem.data,
      layout: designItem.layout
    };
    */

      //renderComponentRef.instance.group = this.drFormGroup;
      //renderComponentRef.hostView.detectChanges();
      // wrapperComponentRef.instance.componentRef.hostView.detectChanges();

      // renderComponentRef.location.nativeElement;

      // const htmlEl = renderComponentRef.location.nativeElement as HTMLElement;
    });
    // return htmlEl;
  }

  private onComponentSelect(designItem: AdminDesignPanelItem) {
    const compClientId = designItem.clientId;
    const selectedPanel = this.dashboard.panels.find(
      (p) => p.id === compClientId
    );

    const req: AdminItemDesignItemSelectRequest = {
      clientId: compClientId,
      data: designItem.data,
      layout: {
        col: selectedPanel.col,
        row: selectedPanel.row,
        sizeX: selectedPanel.sizeX,
        sizeY: selectedPanel.sizeY,
      },
    };

    this.itemSelected.emit(req);
  }

  public getPanelLayout(): PanelModel[] {
    const panels = this.dashboard.serialize();
    console.log(panels);
    return panels;
  }

  public onSaveRequested(containerName: string) {
    const panels = this.getPanelLayout();
    const req: AdminDesignSaveRequest = {
      containerName: containerName,
      panels: panels,
    };
    this.designSave.emit(req);
  }

  public clearDesign(): void {
    this.dashboard.removeAll();
  }
}
