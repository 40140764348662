<div class="dr-admin-design-surface">
  <div class="dr-admin-panel panel-properties">
    <h5>
      <span>PROPERTIES</span>
      <span
        *ngIf="dynamicGroup"
        (click)="apply()"
        class="btn-apply"
        [class.has-changes]="dynamicRender.activeFormProperties?.dirty"
      >
        Apply
      </span>
    </h5>

    <!-- <ng-container dyn-item-editor-host></ng-container> -->
    <ng-container *ngIf="dynamicGroup">
      <ups-dynamic-container
        [isProperties]="true"
        [dynamicGroup]="dynamicGroup"
      ></ups-dynamic-container>
    </ng-container>

    <p *ngIf="!dynamicGroup">
      Select a control in the preview area to customize it's properties.
    </p>
  </div>
</div>
