import { BaseModel } from '../base.model';

export class DropDownItem extends BaseModel {
  public id: string;
  public text: string;

  constructor(o: any = null) {
    super(o);
  }
}
