import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'simpleUTCDate' })
export class SimpleUTCDatePipe implements PipeTransform {
  transform(value: Date): string {
    let datePipe = new DatePipe('en-US');
    if (value == null || value.toString().length === 0) return datePipe.transform(value, 'MM/dd/yyyy');

    let date = new Date(value.getUTCFullYear(), value.getUTCMonth(), value.getUTCDate(), value.getUTCHours(), value.getUTCMinutes(), value.getUTCSeconds());
    return datePipe.transform(date, 'MM/dd/yyyy');
    // return (value.getUTCMonth() + 1) + '/' + value.getUTCDate() + '/' + value.getUTCFullYear();
  }
}
