export interface IMCSSearchColumnConfig {
  field: string;
  matchStartsWith: boolean;
  matchCaseInsensitive: boolean;
  convertToString: boolean;
}

export class MCSSearchColumnUtils {
  /**
   * This function converts searchColumnExpression to a standardized & structured output.
   *
   *  Params:
   *
   *      searchColumnExpression:
   *          Either contains column (property) names we do search on - a single string or an array of strings:
   *              'FirstName'
   *              [ 'FirstName', 'Address.Street']
   *          Or it is an object, with column names as keys and with useStartsWith & useCaseInsensitive boolean values (for omitted properties, defaults will be used):
   *              { FName: { matchCaseInsensitive: true }, 'LName' :{ matchStartsWith: true}, HRRef: { convertToString: true } }
   *
   *      defaultMatchAlgorithmIsStartsWith:
   *          if TRUE and match alg. is not defined on column, it will use startsWith;
   *          otherwise (FALSE) search will use contains logic...
   *
   *      defaultMatchAlgorithmIsCaseInsensitive:
   *          if TRUE and match alg. is not defined on column, it will use case insensitive search;
   *          otherwise (FALSE) search will be case sensitive.
   *
   *  Returns:
   *      IMCSColumnSettings[]
   */
  public static getSearchColumnConfigArray(searchColumnExpression: string | string[] | Object, defaultMatchAlgorithmIsStartsWith: boolean, defaultMatchAlgorithmIsCaseInsensitive: boolean): IMCSSearchColumnConfig[] {
    if (typeof searchColumnExpression === 'string') {
      return [
        {
          field: searchColumnExpression,
          matchStartsWith: defaultMatchAlgorithmIsStartsWith,
          matchCaseInsensitive: defaultMatchAlgorithmIsCaseInsensitive,
          convertToString: false,
        } as IMCSSearchColumnConfig,
      ];
    }

    if (searchColumnExpression instanceof Array) {
      return searchColumnExpression.map(
        (columnName) =>
          ({
            field: columnName,
            matchStartsWith: defaultMatchAlgorithmIsStartsWith,
            matchCaseInsensitive: defaultMatchAlgorithmIsCaseInsensitive,
            convertToString: false,
          } as IMCSSearchColumnConfig)
      );
    }

    if (searchColumnExpression === Object(searchColumnExpression)) {
      // NOTE: ^ when input is an object
      const columnNames = Object.keys(searchColumnExpression);

      return columnNames.map(
        (columnName) =>
          ({
            field: columnName,
            matchStartsWith: searchColumnExpression[columnName].matchStartsWith === undefined || searchColumnExpression[columnName].matchStartsWith === null ? defaultMatchAlgorithmIsStartsWith : searchColumnExpression[columnName].matchStartsWith,
            matchCaseInsensitive: searchColumnExpression[columnName].matchCaseInsensitive === undefined || searchColumnExpression[columnName].matchCaseInsensitive === null ? defaultMatchAlgorithmIsCaseInsensitive : searchColumnExpression[columnName].matchCaseInsensitive,
            convertToString: searchColumnExpression[columnName].convertToString === undefined || searchColumnExpression[columnName].convertToString === null ? false : searchColumnExpression[columnName].convertToString,
          } as IMCSSearchColumnConfig)
      );
    }

    throw new Error('searchColumnSettings has to be a string (column name), array of strings (column names) or an object (based on the SearchColumnSettings.getSearchColumnSettingsObject(...) fnc. description.');
  }
}
