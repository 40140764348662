export class EmployeeToEvaluateDto {
  JobId: string | null;
  EmployeeId: string;
  EmployeeJobId: string;
  EmployeeVpId: string;
  EmployeeFirstName: string;
  EmployeeLastName: string;
  EmployeeHrRef: number | null;
  VpJobId: string;
  CompanyId: string | null;
  VpCompanyId: number | null;
  SupervisorFirstName: string;
  SupervisorLastName: string;
  ManPowerReqDetailId: string | null;
  JobStartDate: Date | null;
  JobEndDate: Date | null;
  ROFNote: string;
  PerformanceEvaluationId: string | null;
  PerformanceEvaluationDate: Date | null;
}
