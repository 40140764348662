import { Injectable } from '@angular/core';

import { ApplicationModel } from '@ups/xplat/api/dto';
import { TimekeepingService } from '@ups/xplat/api/services';

@Injectable({ providedIn: 'root' })
export class TimeSheetDesignationResolver {
  constructor(private timekeepingService: TimekeepingService, private applicationModel: ApplicationModel) {}

  public load(): Promise<any> {
    const self = this;
    if (this.applicationModel.timesheetDesignations.length === 0) {
      return this.timekeepingService.getTimeSheetDesignations().then((data) => (self.applicationModel.timesheetDesignations = data));
    } else {
      return Promise.resolve(self.applicationModel.timesheetDesignations);
    }
  }
}
