<div>
    <kendo-grid
      id="equipmentRequestGrid"
      [data]="gridData"
      [pageSize]="state.take"
      [skip]="state.skip"
      [sort]="state.sort"
      [filter]="state.filter"
      [sortable]="true"
      [pageable]="true"
      [filterable]="'menu'"
      [loading]="isLoading"
      (dataStateChange)="dataStateChange($event)"
      class="rate-sheet transparent-around"
    >
      <kendo-grid-column field="VPJobID" title="Job ID" width="60">
      </kendo-grid-column>
      <kendo-grid-column field="Status" title="Status" width="120">
      </kendo-grid-column>
      <kendo-grid-column field="StartDate" title="Start Date" width="60" filter="date" format = "{0:MM/dd/yyyy}">
      </kendo-grid-column>
      <kendo-grid-column field="EndDate" title="End Date" width="60" filter="date" format = "{0:MM/dd/yyyy}">
      </kendo-grid-column>
      <kendo-grid-column field="AssetControlManager" title="Asset Control Manager" width="150">
      </kendo-grid-column>
  
    </kendo-grid>
  </div>