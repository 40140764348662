<ng-container [formGroup]="group">
  <div class="hazard-row w-100">
    <label *ngIf="!config?.options?.ignoreLabel">{{ config?.label }}</label>
    <label class="ms-auto hazard-radio">
      <input
        type="radio"
        value="At Risk"
        [formControlName]="config.formControlName + '_radio'"
        [attr.name]="config.formControlName"
      />
      <div class="hazard-radio--btn">At Risk</div>
    </label>
    <label class="ms-4 hazard-radio">
      <input
        type="radio"
        value="Safe"
        [formControlName]="config.formControlName + '_radio'"
        [attr.name]="config.formControlName"
      />
      <div class="hazard-radio--btn">Safe</div>
    </label>
    <label class="ms-4 hazard-radio">
      <input
        type="radio"
        value="Not Observed"
        [formControlName]="config.formControlName + '_radio'"
        [attr.name]="config.formControlName"
      />
      <div class="hazard-radio--btn">Not Observed</div>
    </label>
  </div>
  <textarea
    rows="5"
    class="w-100"
    [formControlName]="config.formControlName + '_text'"
    *ngIf="group.value[config.formControlName + '_radio'] !== 'Not Observed'"
  ></textarea>
</ng-container>
