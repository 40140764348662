import { Component } from '@angular/core';
import { WebDynamicItemPreviewComponentBase } from '../web-dynamic-item-component-base';

@Component({
  selector: 'form-radio',
  styleUrls: ['form-radio.component.scss'],
  template: `
    <div class="dynamic-field form-input text-align">
      <label class="radio-heading">Radio Group</label>
      <label *ngFor="let option of options || []; let index = index">
        <input type="radio" [attr.name]="config.id" [attr.id]="config.id + index.toString()" />
        {{ option }}
      </label>
    </div>
  `,
})
export class FormRadioComponent extends WebDynamicItemPreviewComponentBase {
  options = ['Option 1', 'Option 2'];
}
