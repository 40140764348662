/* eslint-disable @typescript-eslint/naming-convention */
// Generated by https://quicktype.io

export interface RecruiterWorkload {
  Name: string;
  Id: string;
  DispatchStatuses: DispatchStatus[];
}

export interface DispatchStatus {
  RecruiterId: string;
  DispatchStatusName: string;
  DispatchStatusID: number;
  ManPowerReqCount: number;
}
