import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { LogService, ProgressService } from '@ups/xplat/core';
import {
  DynamicRenderService,
  HazardCardBaseComponent,
} from '@ups/xplat/features';

@Component({
  selector: 'ups-hazard-card',
  templateUrl: 'hazard-card.component.html',
})
export class HazardCardComponent extends HazardCardBaseComponent {
  constructor(
    store: Store,
    log: LogService,
    progress: ProgressService,
    dynamicRender: DynamicRenderService
  ) {
    super(store, log, progress, dynamicRender);
  }
}
