import { Injectable } from '@angular/core';
import { ApplicationModel } from '@ups/xplat/api/dto';
import { HRResourceService } from '@ups/xplat/api/services';

@Injectable({ providedIn: 'root' })
export class DispatchGroupResolver {
  constructor(
    private hrResourceService: HRResourceService,
    private applicationModel: ApplicationModel
  ) {}

  load(): Promise<any> {
    if (this.applicationModel.dispatchGroups.length === 0) {
      return this.hrResourceService.getDispatchGroups().then((data) => {
        this.applicationModel.dispatchGroups = data;
      });
    } else {
      return Promise.resolve(this.applicationModel.dispatchGroups);
    }
  }
}
