import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { JobDropdown, SpartaSyncStateDto } from '@ups/xplat/api/dto';
import { BaseComponent } from '@ups/xplat/core';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import { Subscription } from 'rxjs';
import { SyncSpartaPeriodicRefreshService } from '../../sync-sparta-periodic-refresh-service';

@Component({
  selector: 'ups-sync-feature',
  templateUrl: './sync-feature.component.html',
})
export class SyncFeatureComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  hasReadPermissions: boolean;
  hasEditPermissions: boolean;
  selectedJob: JobDropdown;

  @Input()
  get job(): JobDropdown {
    return this.selectedJob;
  }

  set job(job: JobDropdown) {
    this.selectedJob = job;
    this.syncRefreshService.jobId = job ? job.Id : null;
  }

  @Input() syncMethodsFilter: string[] = [];
  @Input() useSyncMethodsFilter = false;
  @Input() showInCompactMode = false;
  @Input() showAsJobSyncButton = false;
  @Input() disableButtonsAfterSyncInSeconds = 0;

  isLoading = true;
  syncStates: SpartaSyncStateDto[] = [];
  selectedSyncName: string;

  constructor(
    private security: SecurityService,
    private syncRefreshService: SyncSpartaPeriodicRefreshService
  ) {
    super();

    console.log('In sync component');

    this.hasReadPermissions = this.security.getFeatureById(
      SecurityConstants.employee_portal_sync_syncspartapage
    ).readAll;
    this.hasEditPermissions = this.security.getFeatureById(
      SecurityConstants.employee_portal_sync_syncspartapage
    ).editAll;
  }

  private syncStatesChangeEvent: Subscription;
  private isLoadingEvent: Subscription;

  ngOnInit() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    if (this.showAsJobSyncButton) {
      this.showInCompactMode = true;
      this.syncMethodsFilter = ['SyncDataForJob'];
      this.useSyncMethodsFilter = true;
    }

    this.syncStatesChangeEvent =
      this.syncRefreshService.syncStatesChanged.subscribe(
        (syncStates: SpartaSyncStateDto[]) => {
          self.isLoading = false;
          if (self.useSyncMethodsFilter) {
            if (!self.syncMethodsFilter || !self.syncMethodsFilter.length) {
              self.syncStates = [];
            } else {
              self.syncStates = syncStates.filter((s) =>
                self.syncMethodsFilter.some((f) => f === s.MethodName)
              );
            }
          } else {
            self.syncStates = syncStates;
          }
        }
      );

    this.isLoadingEvent = this.syncRefreshService.isLoading.subscribe(
      (isLoading: boolean) => (self.isLoading = isLoading)
    );
  }

  ngOnDestroy() {
    if (this.syncStatesChangeEvent) {
      this.syncStatesChangeEvent.unsubscribe();
      this.syncStatesChangeEvent = null;
    }

    if (this.isLoadingEvent) {
      this.isLoadingEvent.unsubscribe();
      this.isLoadingEvent = null;
    }
  }

  sync(syncState: SpartaSyncStateDto) {
    this.syncRefreshService.startSync(syncState);
  }
}
