/* eslint-disable @typescript-eslint/naming-convention */
export enum DispatchStatusEnum {
  // 03/20/2019 Values from sparta-ups-stg dbo.DispatchStatus where bActive = 1
  Hold = 1,
  Requested = 2,
  LeftMessage = 3,
  Declined = 5,
  HoldButProcess = 7,
  Dispatched = 8,
  OnSite = 10,
  ROF = 11,
  TermQuitNoShow = 12,
  WrongNumberListed = 32,
  PositionCancelled = 33,
  ReadyToBeDispatched = 49,
  Processing = 51,
  Backup = 52,
  FollowUp = 53,
  NotRehireable = 54,
  Confirmed = 55,
  SkillsVerification = 56,
  Caution = 57,
  MedicalReleaseRequired = 58,
  OnsiteProcessing = 60,
  DispatchedProcessing = 61,
  ComplianceHold = 62,
}
