import { Injectable } from '@angular/core';
import { parseData } from '@ups/xplat/utils';
import { HttpClient } from '@angular/common/http';
import { MyHttpClientFactory, environment } from '@ups/xplat/core';
import {
  ClassTypeDbDto,
  ClassTypeDto,
  RequirementTypeDto,
  ScoreMethodDto,
  TradeClassDto,
  TradeGroupDto,
  WorkLocationDto,
} from '@ups/xplat/api/dto';
import { lastValueFrom, Observable } from 'rxjs';

const GET_TRADE_GROUPS = '/api/classManagement/tradeGroups';
const GET_ALL_TRADE_GROUPS = '/api/classManagement/tradeGroups/all';
const GET_TRADE_CLASSES = '/api/classManagement/tradeClasses/{:tradeGroupID}';
const GET_CLASS_CODES =
  '/api/classManagement/classCodes/tradeClass/{:tradeClassID}';
const SAVE_CLASS_HIERARCHY = '/api/classManagement/save';
const GET_CLASS_TYPES = '/api/classManagement/classTypes';
const GET_ALL_CLASS_TYPES = '/api/classManagement/classTypes/all';
const GET_WORK_LOCATIONS = '/api/classManagement/workLocations';
const GET_ALL_WORK_LOCATIONS = '/api/classManagement/workLocations/all';
const GET_CLASS_TYPE_DBS = '/api/classManagement/classTypeDBs';
const GET_CLASS_REQUIREMENTS_BY_TRADE_CLASS =
  '/api/classManagement/classRequirements/tradeClass/{:tradeClassID}';
const GET_CLASS_REQUIREMENTS_BY_CLASS_CODE =
  '/api/classManagement/classRequirements/classCode/{:classCodeID}';
const GET_REQUIREMENT_TYPES = '/api/classManagement/requirementTypes';
const GET_ALL_REQUIREMENT_TYPES = '/api/classManagement/requirementTypes/all';
const GET_REQUIREMENTS = '/api/classManagement/requirements';
const GET_CLASS_CODES_BY_TRADE_CLASS =
  '/api/classManagement/classCodes/tradeClass/{:tradeClassID}';
const GET_REQUIREMENT_NAMES = '/api/distinctValues/{:table}/{:column}';
const GET_SCORE_METHODS = '/api/classManagement/scoreMethods';

const SAVE_TRADE_GROUP = '/api/classManagement/tradeGroup';
const SAVE_CLASS_TYPE = '/api/classManagement/classType';
const SAVE_WORK_LOCATION = '/api/classManagement/workLocation';
const SAVE_REQ_TYPE = '/api/classManagement/requirementType';
const GET_GROUP_OWNERS = '/api/classManagement/groupOwners/{:tradeGroupID}';
const SAVE_GROUP_OWNER = '/api/classManagement/groupOwner';

const ALL_CLASS_CODES = '/api/classManagement/classCodes';
const ALL_CLASSES = '/api/classManagement/classes';

@Injectable({
  providedIn: 'root',
})
export class ClassService {
  http: HttpClient;
  private defaultHttp: HttpClient;

  constructor(private httpFactory: MyHttpClientFactory) {
    this.http = httpFactory.createHttpClient(environment.urls.spartaAPI);
    this.defaultHttp = httpFactory.createHttpClient(null);
  }

  public getTradeGroups(): Observable<TradeGroupDto[]> {
    return this.http.get(GET_TRADE_GROUPS) as Observable<TradeGroupDto[]>;
  }

  public getAllTradeGroups(): Observable<TradeGroupDto[]> {
    return this.http.get(GET_ALL_TRADE_GROUPS) as Observable<TradeGroupDto[]>;
  }

  public getTradeClasses(tradeGroupID: string): Observable<TradeClassDto[]> {
    const url = GET_TRADE_CLASSES.split('{:tradeGroupID}').join(tradeGroupID);
    return this.http.get(url) as Observable<TradeClassDto[]>;
  }

  public getClassCodes(tradeClassID: string) {
    const url = GET_CLASS_CODES.split('{:tradeClassID}').join(tradeClassID);
    return this.http.get(url).toPromise().then(parseData);
  }

  public fetchClassCodes(vpCompanyId: number, search: string) {
    const url =
      ALL_CLASS_CODES + '?vpCompanyID=' + vpCompanyId + '&search=' + search;
    return this.http.get(url).toPromise().then(parseData);
  }

  public fetchClasses(vpCompanyID: number, search: string) {
    const url =
      ALL_CLASSES + '?vpCompanyID=' + vpCompanyID + '&search=' + search;
    return this.http.get(url).toPromise().then(parseData);
  }

  public saveClassHierarchy(items) {
    return lastValueFrom(this.http.post(SAVE_CLASS_HIERARCHY, items)).then(
      parseData
    );
  }

  public getClassTypes(): Observable<ClassTypeDto[]> {
    return this.http.get(GET_CLASS_TYPES) as Observable<ClassTypeDto[]>;
  }

  public getAllClassTypes(): Observable<ClassTypeDto[]> {
    return this.http.get(GET_ALL_CLASS_TYPES) as Observable<ClassTypeDto[]>;
  }

  public getWorkLocations(): Observable<WorkLocationDto[]> {
    return this.http.get(GET_WORK_LOCATIONS) as Observable<WorkLocationDto[]>;
  }

  public getAllWorkLocations(): Observable<WorkLocationDto[]> {
    return this.http.get(GET_ALL_WORK_LOCATIONS) as Observable<
      WorkLocationDto[]
    >;
  }

  public getClassTypeDBs(): Observable<ClassTypeDbDto[]> {
    return this.http.get(GET_CLASS_TYPE_DBS) as Observable<ClassTypeDbDto[]>;
  }

  public getClassRequirementsByTradeClassID(tradeClassID: string) {
    const url =
      GET_CLASS_REQUIREMENTS_BY_TRADE_CLASS.split('{:tradeClassID}').join(
        tradeClassID
      );
    return this.http.get(url).toPromise().then(parseData);
  }

  public getClassRequirementsByClassCodeID(classCodeID: string) {
    const url =
      GET_CLASS_REQUIREMENTS_BY_CLASS_CODE.split('{:classCodeID}').join(
        classCodeID
      );
    return this.http.get(url).toPromise().then(parseData);
  }

  public getRequirementTypes(): Observable<RequirementTypeDto[]> {
    return this.http.get(GET_REQUIREMENT_TYPES) as Observable<
      RequirementTypeDto[]
    >;
  }

  public getAllRequirementTypes(): Observable<RequirementTypeDto[]> {
    return this.http.get(GET_ALL_REQUIREMENT_TYPES) as Observable<
      RequirementTypeDto[]
    >;
  }

  public getRequirements() {
    return this.http.get(GET_REQUIREMENTS).toPromise().then(parseData);
  }

  public getClassCodesByTradeClassID(tradeClassID: string) {
    const url =
      GET_CLASS_CODES_BY_TRADE_CLASS.split('{:tradeClassID}').join(
        tradeClassID
      );
    return this.http.get(url).toPromise().then(parseData);
  }

  public createTradeGroup(item: TradeGroupDto): Observable<TradeGroupDto> {
    return this.http.put(SAVE_TRADE_GROUP, item) as Observable<TradeGroupDto>;
  }

  public updateTradeGroup(item: TradeGroupDto): Observable<TradeGroupDto> {
    return this.http.post(SAVE_TRADE_GROUP, item) as Observable<TradeGroupDto>;
  }

  public createClassType(item: ClassTypeDto): Observable<ClassTypeDto> {
    return this.http.put(SAVE_CLASS_TYPE, item) as Observable<ClassTypeDto>;
  }

  public updateClassType(item: ClassTypeDto): Observable<ClassTypeDto> {
    return this.http.post(SAVE_CLASS_TYPE, item) as Observable<ClassTypeDto>;
  }

  public createWorkLocation(
    item: WorkLocationDto
  ): Observable<WorkLocationDto> {
    return this.http.put(
      SAVE_WORK_LOCATION,
      item
    ) as Observable<WorkLocationDto>;
  }

  public updateWorkLocation(
    item: WorkLocationDto
  ): Observable<WorkLocationDto> {
    return this.http.post(
      SAVE_WORK_LOCATION,
      item
    ) as Observable<WorkLocationDto>;
  }

  public createReqType(
    item: RequirementTypeDto
  ): Observable<RequirementTypeDto> {
    return this.http.put(SAVE_REQ_TYPE, item) as Observable<RequirementTypeDto>;
  }

  public updateReqType(
    item: RequirementTypeDto
  ): Observable<RequirementTypeDto> {
    return this.http.post(
      SAVE_REQ_TYPE,
      item
    ) as Observable<RequirementTypeDto>;
  }

  public getDBTables(tableEndpointUrl: string): Observable<string[]> {
    return this.defaultHttp.get(tableEndpointUrl) as Observable<string[]>;
  }

  public getDBTableColumns(
    columnEndpointUrl: string,
    tableName: string
  ): Observable<string[]> {
    const url = columnEndpointUrl.split('{table}').join(tableName);
    return this.defaultHttp.get(url) as Observable<string[]>;
  }

  public getRequirementNames(
    endpointUrl: string,
    table: string,
    column: string
  ): Observable<string[]> {
    let url = (endpointUrl + GET_REQUIREMENT_NAMES)
      .split('{:column}')
      .join(column);
    url = url.split('{:table}').join(table);
    return this.defaultHttp.get(url) as Observable<string[]>;
  }

  public getScoreMethods(): Observable<ScoreMethodDto[]> {
    return this.http.get(GET_SCORE_METHODS) as Observable<ScoreMethodDto[]>;
  }

  public getGroupOwners(tradeGroupID: string) {
    const url = GET_GROUP_OWNERS.split('{:tradeGroupID}').join(tradeGroupID);
    return this.http.get(url).toPromise().then(parseData);
  }

  public createGroupOwner(item) {
    return this.http.put(SAVE_GROUP_OWNER, item).toPromise().then(parseData);
  }
}
