export class LaborDto {
  public BillRate: number;
  public CreatedBy: string;
  public DateCreated: Date;
  public DateExported: Date;
  public DateWorked: Date;
  public DispatchFactor: number;
  public EarnCode: string;
  public EarnCodeType: string;
  public EmployeeLaborID: string;
  public LineID: string;
  public PayCategoryID: number;
  public Factor: number;
  public Hours: number;
  public IsCorrected: boolean;
  public IsCorrection: boolean;
  public MileageType: number;
  public PayRate: number;
  public ShiftDiffAmount: number;
  public TimeSheetID: string;
  public TimeSheetName: string;
  public bActive: boolean;
  public Notes: string;

  // client side only
  public SavedHours: number;
  public SavedBillRate: number;
  public IsDirty: boolean;
}
