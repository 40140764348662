import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'salesOrderFilter',
})
export class SalesOrderFilterPipe implements PipeTransform {
  transform(value: any, args: string): any {
    if (!args) return value;
    let filter = args.toLocaleLowerCase();
    return filter ? value.filter((inventory) => inventory.Material.toLocaleLowerCase().indexOf(filter) !== -1 || inventory.Description.toLocaleLowerCase().indexOf(filter) !== -1) : value;
  }
}
