import { BaseModel } from '../base.model';

export class SalesOrder extends BaseModel {
  orderId: number;
  orderNumber: string;
  pickPackShipStatus: string;
  orderQty: number;
  picked: number;
  packed: number;
  shipped: number;
  unitsToPick: number;
  unitsToPack: number;
  unitsToShip: number;
}
