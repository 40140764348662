/**
 * Report IDs which are well know IDs.
 */
export class ReportConstants {
  /** Labor Cost Report ID */
  public static readonly employeeLaborReportID: string =
    '45872321-0C37-4214-B5AC-15037E7CE20B';

  /** Employee Labor Summary Report ID */
  public static readonly employeeLaborReportForEmployeeID: string =
    '823781EF-BC63-4EB4-8AAF-6A635FD8C564';

  /** Facility Manpower Report ID */
  public static readonly facilityManpowerReportID: string =
    '9E941900-DADD-4577-9D4C-485371D9DEF8';
}
