import {
  AfterViewInit,
  Directive,
  OnDestroy,
  OnInit,
  Optional,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { takeUntil } from 'rxjs';

import {
  TabStripComponent,
  TabStripTabComponent,
} from '@progress/kendo-angular-layout';
import { BaseComponent } from '@ups/xplat/core';

/*
USAGE:      Import LayoutModule & TabStripModuleEx - the module/directive and add *loadOnDemand structural directive to the kendo tab content <ng-template>.

IMPORTANT:  ...is to have keepTabContent]="true" otherwise the selected tab will be destroyed if new selection is made!
            ...currently this directive implementation sets this value for us!

HTML sample:
<kendo-tabstrip [keepTabContent]="true">
    <kendo-tabstrip-tab [title]="'Paris'" [selected]="true">
        <ng-template kendoTabContent *loadOnDemand>
            ...
        </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab [title]="'New York City'">
        <ng-template kendoTabContent *loadOnDemand>
            ...
        </ng-template>
    </kendo-tabstrip-tab>

    ...
 */
@Directive({
  selector: '[loadOnDemand]',
})
export class TabContentLoadOnDemandDirective
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  protected wasLoaded: boolean;

  constructor(
    @Optional() private tabStripComponent: TabStripComponent,
    @Optional() private tabStripTabComponent: TabStripTabComponent,
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef
  ) {
    super();
    // #1 Initialize only if it's inside a kendo tab-panel...
    if (!this.tabStripComponent || !tabStripTabComponent) return;

    // #2 Unload - is not necessary as a structural directive is responsible for creating and destroying content (so no "auto" create will occur)...

    // #3
    // We need to keep tab content (once loaded) to have benefits of using load-on-demand, so I do set it here!
    // Also setting this inside CTOR has the benefits of user to be able to change value via binding...even if it leads to weird effects (load on demand not working as expected)...
    this.tabStripComponent.keepTabContent = true;
  }

  ngOnInit() {
    // NOTE: we could do a check if this.tabStripComponent.keepTabContent is truthy, if not, we'd raise an error...or at least log a warning!
    /*
        if (!this.tabStripComponent.keepTabContent)
            console.warn(`The *loadOnDemand directive on tab panel: '${this.tabStripTabComponent.title}' is ineffective, cause the tab component uses {keepTabContent]="false" which destroys components!`)
        */

    this.tabStripComponent.tabSelect
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.tabSelectEx.bind(this));
  }

  ngAfterViewInit(): void {
    if (this.tabStripTabComponent.selected) this.loadMe();
  }

  protected loadMe() {
    if (!this.wasLoaded) {
      this.wasLoaded = true;
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  protected unloadMe() {
    if (this.wasLoaded) {
      this.wasLoaded = false;
      this.viewContainer.clear();
    }
  }

  protected tabSelectEx(e) {
    if (e.title === this.tabStripTabComponent.title) this.loadMe();
  }
}
