<div>
  <div class="d-flex justify-content-end">
    <!--
        The kendo-combobox name="add" code can be removed.
        As of #2057 we map Equipment Requirements to job or plant and not to equipment type.
        But we keep the code here in case we need to implement blacklisting of requirements
        for certain equipment type.
        -->
    <label class="col-form-label col-sm-3">Equipment Requirements</label>
    <kendo-combobox
      *ngIf="equipmentType && !isReadOnly && !showInactive"
      [(ngModel)]="selectedRequirement"
      [data]="inactiveRequirements()"
      [disabled]="false"
      [filterable]="false"
      [style.width]="'300px'"
      [textField]="'EquipmentRequirementName'"
      [valueField]="'EquipmentPlantJobRequirementID'"
      [valuePrimitive]="false"
      name="add"
      placeholder="Select and add a requirement for equipment ..."
    ></kendo-combobox>
    <kendo-dropdownlist
      *ngIf="!equipmentType && !isReadOnly && !showInactive"
      #allEquipmentRequirementsDropdown
      [(ngModel)]="selectedRequirement"
      [filterable]="true"
      [seDebounceFilterChange]="150"
      [seMinSearchLength]="0"
      [seSearchColumns]="['Name']"
      [style.width]="'300px'"
      [textField]="'Name'"
      [valueField]="'EquipmentRequirementID'"
      [valuePrimitive]="false"
      (seFilterChangeEx)="requirementsFilterChanged($event)"
      search-ex
      id="add"
      name="add"
      class="k-no-dropdown"
    ></kendo-dropdownlist>
    <button
      *ngIf="!isReadOnly && !showInactive"
      [disabled]="!selectedRequirement"
      (click)="addEquipmentRequirement()"
      class="k-button"
      id="addButton"
      title="Add"
    >
      <i class="fa fa-plus"></i>
    </button>
  </div>
</div>

<div class="req-header" *ngIf="showHeader">
  <label class="text-center col-20">Requirement</label>
  <label class="text-center col-15">Required</label>
  <label class="text-center col-15">
    Waiver
    <br />
    Applies
  </label>
  <label class="text-center col-15">
    Waiver
    <br />
    Days
  </label>
  <label class="text-center col-15">Billable</label>
</div>

<kendo-panelbar
  #panelbar
  [expandMode]="'single'"
  (stateChange)="onStateChange()"
>
  <kendo-panelbar-item
    *ngFor="let req of visibleRequirements(); let i = index"
    [title]="req.EquipmentRequirementName"
  >
    <ng-template kendoPanelBarItemTitle>
      <div class="row w-100 pt-2 position-absolute" (click)="onClick($event)">
        <label
          *ngIf="!showSubtasks"
          class="col-20"
          style="padding-left: 15px"
          title="{{ req.EquipmentRequirementName }}"
        ></label>
        <div
          *ngIf="showSubtasks"
          (click)="toggleExpand(req.EquipmentRequirementName)"
          class="col-20"
          style="cursor: pointer; pointer-events: auto"
          title="{{ req.EquipmentRequirementName }}"
        >
          <span
            [class.k-i-arrow-s]="expandedItem !== req.EquipmentRequirementName"
            [class.k-i-arrow-n]="expandedItem === req.EquipmentRequirementName"
            class="k-icon mt-2"
          ></span>
          <!-- <span>{{req.EquipmentRequirementName}}</span> -->
        </div>
        <div class="col-15 text-center">
          <label class="k-form-field right">
            <input
              [disabled]="isReadOnly"
              [(ngModel)]="req.bActive"
              class="k-checkbox"
              id="{{ id + '_checkRequired_' + i }}"
              type="checkbox"
            />
            <label
              (click)="req.bActive = !req.bActive"
              class="k-checkbox-label"
              for="{{ id + '_checkRequired_' + i }}"
            ></label>
          </label>
        </div>
        <div class="col-15 text-center">
          <label class="k-form-field right">
            <input
              [disabled]="isReadOnly"
              [(ngModel)]="req.bWaiver"
              class="k-checkbox"
              id="{{ id + '_checkWaiver_' + i }}"
              type="checkbox"
            />
            <label
              (click)="req.bWaiver = !req.bWaiver"
              class="k-checkbox-label"
              for="{{ id + '_checkWaiver_' + i }}"
            ></label>
          </label>
        </div>
        <div class="col-15 text-center">
          <span *ngIf="isReadOnly">{{ req.WaiverDays }}</span>
          <kendo-numerictextbox
            *ngIf="!isReadOnly"
            [(ngModel)]="req.WaiverDays"
            [disabled]="false"
            [format]="'n0'"
            [min]="0"
            [spinners]="true"
            [step]="1"
            [style.margin-top]="'-5px'"
            [style.width]="'80px'"
            [value]="1"
            class="w-75"
            name="{{ id + 'waiverDays' + i }}"
          ></kendo-numerictextbox>
        </div>
        <div class="col-15 text-center">
          <label class="k-form-field right">
            <input
              [disabled]="isReadOnly"
              [(ngModel)]="req.bBillable"
              class="k-checkbox"
              id="{{ id + '_checkBillable_' + i }}"
              type="checkbox"
            />
            <label
              (click)="req.bBillable = !req.bBillable"
              class="k-checkbox-label"
              for="{{ id + '_checkBillable_' + i }}"
            ></label>
          </label>
        </div>
        <div *ngIf="!isReadOnly" class="col-15 text-center">
          <label class="k-form-field right" (click)="req.bActive = false">
            Remove
          </label>
        </div>
      </div>
    </ng-template>
    <ng-template kendoPanelBarContent>
      <div>
        <ups-equipment-subtask-list
          [equipmentType]="equipmentType"
          [isReadOnly]="isReadOnly"
          [requirement]="req"
        ></ups-equipment-subtask-list>
      </div>
    </ng-template>
  </kendo-panelbar-item>
</kendo-panelbar>
