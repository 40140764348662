import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@ups/xplat/core';

@Directive()
export abstract class MultiColumnFilterBaseComponent extends BaseComponent {
  @Input() model: any;
  @Input() mcsSearchColumns: Array<string> = ['DisplayName']; // example
  @Input() mcsDebounceFilterChange = 1000; // default
  @Input() placeholder = 'Search Prospect Name or SSN';
  @Input() title = 'Search Prospect Name';
  @Output() mcsFilterChange: EventEmitter<any> = new EventEmitter();

  filterChange(args) {
    this.mcsFilterChange.emit(args);
  }
}
