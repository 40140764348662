<div class="dynamic-field-container" [class.selected]="config?.selected">
  <div
    class="dynamic-field form-input text-align"
    [formGroup]="group"
    [hidden]="!config?.isFormBuilder && config?.options?.hidden"
  >
    <ups-dynamic-helper-label
      [config]="config"
      [dynamicService]="dynamicService"
    ></ups-dynamic-helper-label>
    <div class="job-search-container" style="position: relative">
      <ng-template [ngIf]="!config?.options?.isMultiSelect">
        <kendo-dropdownlist
          #dropdown
          class="k-no-dropdown w-100"
          [attr.name]="config.formControlName"
          [formControlName]="config.formControlName"
          [valuePrimitive]="false"
          [textField]="'Name'"
          [valueField]="'Name'"
          [defaultItem]="{ Name: config?.placeholder }"
          [filterable]="true"
          (valueChange)="valueChangeItem($event)"
          search-ex
          [seSearchColumns]="['Name']"
          [itemDisabled]="itemDisabled"
          (seFilterChangeEx)="filterChangeItem($event)"
          [seDebounceFilterChange]="150"
          [seUseCustomLocalFilter]="true"
          [seMinSearchLength]="0"
          [loading]="isLoading"
        >
          <ng-template kendoDropDownListItemTemplate let-dataItem>
            <div
              style="display: inline-flex"
              [class.k-state-disabled]="dataItem?.Name === config?.placeholder"
            >
              <div>
                <span [hidden]="!displayIdPrefix">
                  {{
                    dataItem?.Description
                      ? dataItem?.Description
                      : dataItem?.Id
                  }}&nbsp;
                </span>
                <span
                  [class.link-style]="dataItem?.Name !== config?.placeholder"
                >
                  <span
                    *ngIf="
                      dataItem?.Active === 'false' &&
                      dataItem?.Name !== config.placeholder
                    "
                    style="color: red"
                  >
                    {{ dataItem?.Name }}
                  </span>
                  <span *ngIf="!dataItem?.Active || dataItem.Active === 'true'">
                    {{ dataItem?.Name }}
                  </span>
                </span>
              </div>
            </div>
          </ng-template>
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <div style="display: inline-flex">
              <div kendoTooltip [title]="dataItem?.Name">
                <span [hidden]="!displayIdPrefix">
                  <ng-container *ngIf="dataItem?.Description">
                    {{ dataItem?.Description + ' - ' }}
                  </ng-container>
                  <ng-container *ngIf="!dataItem?.Description && dataItem?.Id">
                    {{ dataItem?.Id ? dataItem?.Id + ' - ' : '' }}
                  </ng-container>
                </span>
                <span>{{ dataItem?.Name }}</span>
              </div>
            </div>
          </ng-template>
        </kendo-dropdownlist>
      </ng-template>

      <ng-template [ngIf]="config?.options?.isMultiSelect">
        <kendo-multiselect
          [attr.name]="config.formControlName"
          [formControlName]="config.formControlName"
          class="k-no-dropdown w-100"
          [defaultItem]="{ Name: config?.placeholder }"
          [valuePrimitive]="false"
          [textField]="'Name'"
          [valueField]="'Id'"
          [placeholder]="config?.placeholder"
          [filterable]="true"
          (valueChange)="valueChangeItem($event)"
          search-ex
          [seSearchColumns]="['Name']"
          (seFilterChangeEx)="filterChangeItem($event)"
          [seDebounceFilterChange]="150"
          [seUseCustomLocalFilter]="true"
          [seMinSearchLength]="0"
          [loading]="isLoading"
        >
          <ng-template kendoMultiSelectItemTemplate let-dataItem>
            <div
              style="display: inline-flex"
              [hidden]="dataItem?.Name === config?.placeholder"
            >
              <div>
                <span [hidden]="!displayIdPrefix">
                  {{
                    dataItem?.Description
                      ? dataItem?.Description
                      : dataItem?.Id
                  }}&nbsp;
                </span>
                <span class="job-name link-style">{{ dataItem?.Name }}</span>
              </div>
            </div>
          </ng-template>
          <ng-template kendoMultiSelectValueTemplate let-dataItem>
            <div style="display: inline-flex">
              <div kendoTooltip [title]="dataItem?.Name">
                <span [hidden]="!displayIdPrefix">
                  <ng-container *ngIf="dataItem?.Description">
                    {{ dataItem?.Description + ' - ' }}
                  </ng-container>
                  <ng-container *ngIf="!dataItem?.Description && dataItem?.Id">
                    {{ dataItem?.Id ? dataItem?.Id + ' - ' : '' }}
                  </ng-container>
                </span>
                <span>{{ dataItem?.Name }}</span>
              </div>
            </div>
          </ng-template>
        </kendo-multiselect>
      </ng-template>
    </div>
    <div
      class="form-builder-mask"
      [class.hidden]="config?.options?.hidden"
      *ngIf="config?.isFormBuilder"
    ></div>
  </div>
  <div class="form-builder-options" *ngIf="config?.isFormBuilder">
    <i class="fas fa-times ms-3 cursor-pointer c-red" (click)="delete()"></i>
    <!-- <i class="fas fa-bars ms-3 cursor-grab"></i> -->
  </div>
</div>
