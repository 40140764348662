import { DataResult } from '@progress/kendo-data-query';
import { IODataResult } from '@ups/xplat/api/dto';

export class ODataResult<T = any> implements IODataResult<T> {
  value: T[];
  '@odata.count': any;

  public static EmptyODataResult = {
    value: [],
    '@odata.count': 0,
  } as IODataResult;

  public static FromDataResult(
    dataResult: DataResult,
    totalCountOverride?: number
  ): IODataResult {
    return {
      value: dataResult.data,
      '@odata.count': totalCountOverride || dataResult.total,
    } as IODataResult;
  }
}
