import { Directive, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NgControl, ValidationErrors, Validator } from '@angular/forms';

/**
 * Generic, flexible conditional validator based on: https://medium.com/front-end-weekly/angular-how-to-implement-conditional-custom-validation-1ec14b0feb45
 *
 * Supports conditional expression with template references as with view-model values.
 *
 * Common usage:
 *  <input #ref1="ngModel" [(ngModel)]="value1" [validWhen]="value1 < value2" />
 *  <input #ref2="ngModel" [(ngModel)]="value2" [validWhen]="value1 < value2" />
 *
 * Validator on an input control, with multiple conditions depending on external model-view and/or template reference values:
 *  <input
 *      #inp="ngModel"
 *      ngModel
 *      [validWhen]="[
 *          { expression: ref1.value < ref2.value, errorProperty:'myErrorMustBeLt', errorMessage: 'myErrorMustBeLt: Validation failed as condition #ref1 < #ref2 evaluated to false!' },
 *          { expression: ref1.value == ref2.value, errorProperty:'myErrorMustEqual', errorMessage: 'myErrorMustEqual: Validation failed as condition #ref1 == #ref2 evaluated to false!' }
 *      ]"
 *      placeholder="Date validator display"
 *      />
 *  <span *ngIf="inp.errors?.myErrorMustBeLt">{{inp.errors?.myErrorMustBeLt.message}}</span>
 *  <span *ngIf="inp.errors?.myErrorMustEqual">{{inp.errors?.myErrorMustEqual.message}}</span>
 *
 */
@Directive({
  selector: '[validWhen][formControlName],[validWhen][formControl],[validWhen][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ValidWhenDirective, multi: true }],
})
export class ValidWhenDirective implements Validator, OnChanges, OnInit {
  public static defaultErrorProperty = 'validWhen';
  public static defaultErrorMessage = 'Valid-when condition is not met!';

  @Input('validWhen')
  public validWhen: [{ expression: any; errorProperty: string; errorMessage: string }] | any;

  private ngControl: NgControl;

  constructor(private injector: Injector) {}

  ngOnChanges(changes: SimpleChanges) {
    /**
     * Trigger validate after proper values are bound!
     * This does not solve to trigger validation (error messages) elsewhere, like this: https://scotch.io/tutorials/how-to-implement-a-custom-validator-directive-confirm-password-in-angular-2
     * But it solves a delay due the validator fn being executed before the new values are evaluated and bound (ngOnChanges) which leads that validation is always a step behind!
     * Also we are OK to put this validation just on the password input and do a comparison against the retype input, whereas the error will be shown beside the password input (as there's the validator defined) and error will shown also when we are changing the retype input value...
     */
    if (this.ngControl) this.ngControl.control.updateValueAndValidity();
  }

  ngOnInit() {
    this.ngControl = this.injector.get(NgControl);
  }

  validate(c: AbstractControl): ValidationErrors | null {
    // configured via array...
    if (Array.isArray(this.validWhen)) {
      const errors = {};

      // ...of special object
      this.validWhen.forEach((cfg: { expression: any; errorProperty: string; errorMessage: string }) => {
        const isValid = !!cfg.expression;

        if (!isValid) {
          const errorProperty = cfg.errorProperty || ValidWhenDirective.defaultErrorProperty;
          const errorMessage = cfg.errorMessage || ValidWhenDirective.defaultErrorMessage;

          errors[errorProperty] = { message: errorMessage };
        }
      });

      const anyError = Object.getOwnPropertyNames(errors).length !== 0;
      if (anyError) return errors;

      return null;
    }

    // configured via simple bool expression...
    const isValid = !!this.validWhen;

    if (isValid) return null;

    return {
      [ValidWhenDirective.defaultErrorProperty]: {
        message: ValidWhenDirective.defaultErrorMessage,
      },
    };
  }
}
