import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';

import {
  HelpDocumentDto,
  WalkthroughPathEntry,
  WalkthroughPathsDictionary,
  HelpTypeEnum,
} from '../models';
import { HelpDocument } from '@ups/xplat/api/dto';

@Injectable({
  providedIn: 'root',
})
export class HelpManagementService {
  public appGuid: string;

  protected http: HttpClient;

  private securityConstants = SecurityConstants;

  constructor(
    protected clientFactory: MyHttpClientFactory,
    private security: SecurityService
  ) {
    this.http = clientFactory.createHttpClient(
      environment.urls.securityAPI,
      true,
      ResponseCasingEnum.CamelCase
    );
    this.appGuid = environment.security.appId;
  }

  // public loadWalkthroughList(loadAppSpecificOnly = false) {
  //   const state = {
  //     filter: {
  //       logic: 'and',
  //       filters: [

  //       ]
  //     } as CompositeFilterDescriptor
  //   } as State;

  //   if (loadAppSpecificOnly && this.appGuid)
  //     state.filter.filters.push({
  //       field: 'applicationGuid',
  //       operator: 'eq',
  //       value: this.appGuid
  //     } as FilterDescriptor);

  //   return this.queryWalkthroughDocument(state);
  // }

  public fetchTooltipPaths(
    paths: string[]
  ): Observable<WalkthroughPathEntry[]> {
    const uri = 'api/walkthrough/fetchTooltipPaths';
    return this.http
      .post<WalkthroughPathsDictionary>(uri, {
        applicationGuid: environment.security.appId,
        paths: paths,
      })
      .pipe(
        map((r) =>
          Object.entries(r).map(
            ([key, value]) => ({ key, value } as WalkthroughPathEntry)
          )
        )
      );
  }

  public fetchWalkthroughPaths(
    paths: string[]
  ): Observable<WalkthroughPathEntry[]> {
    const uri = 'api/walkthrough/fetchWalkthroughPaths';
    return this.http
      .post<WalkthroughPathsDictionary>(uri, {
        applicationGuid: environment.security.appId,
        paths: paths,
      })
      .pipe(
        map((r) =>
          Object.entries(r).map(
            ([key, value]) => ({ key, value } as WalkthroughPathEntry)
          )
        )
      );
  }

  public fetchWalkthroughDocumentsByIds(
    ...ids: number[]
  ): Observable<HelpDocumentDto[]> {
    const uri = 'api/walkthrough/fetchWalkthroughDocumentsByIds';
    return this.http.post<HelpDocumentDto[]>(uri, ids);
  }

  public fetchWalkthroughDocumentsByPaths(
    type?: HelpTypeEnum,
    ...paths: string[]
  ): Observable<HelpDocumentDto[]> {
    const uri = 'api/walkthrough/fetchWalkthroughDocumentsByPaths';
    const editAll = this.security.getFeatureById(
      SecurityConstants.employee_portal_frameworkfeatures_walkthroughs
    ).editAll;
    let params = new HttpParams();
    if (type !== null && type !== undefined)
      params = params.append('type', `${type}`);
    if (editAll) {
      params = params.append('includeDrafts', 'true');
    }

    return this.http.post<HelpDocumentDto[]>(
      uri,
      { applicationGuid: environment.security.appId, paths: paths },
      { params }
    );
  }

  public createUpdateWalkthrough(wd: HelpDocumentDto): Observable<number> {
    const uri = 'api/walkthrough';
    return this.http.post<number>(uri, wd);
  }

  public deleteWalkthrough(wd: HelpDocumentDto): Observable<void> {
    const uri = `api/walkthrough/${wd.id}`;
    return this.http.delete<void>(uri);
  }

  // public queryWalkthroughDocument(state?: State): Observable<ODataResult<WalkthroughDocumentLookupResultDto>> {

  //   const odataQuery = state
  //     ? toODataStringEx(state)
  //     : '';

  //   const uri = 'api/walkthrough/queryWalkthroughDocument' + (odataQuery ? '?' + odataQuery : '');
  //   return this.http.get<any>(uri);
  // }

  getDocuments(location: string): Observable<HelpDocument[]> {
    const url = `/api/documents/list/${location}`;

    // NOTE: OData endpoint!
    return this.http.get<HelpDocument[]>(url).pipe(map((res) => res['value']));
  }
}
