import { Component, ViewChild } from '@angular/core';

import { StepperComponent, StepperStep } from '@progress/kendo-angular-layout';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, take, takeUntil, tap } from 'rxjs/operators';

import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import {
  HelpActions,
  HelpDocumentDto,
  HelpState,
  HelpTypeEnum,
} from '@ups/xplat/base/in-app-help';
import { BaseComponent, environment } from '@ups/xplat/core';
import { deepClone } from '@ups/xplat/utils';
import { ConfirmationDialogComponent } from '@ups/xplat/web/features';

import { EditorTooltipComponent } from './editor-tooltip/editor-tooltip.component';
import { EditorWalkthroughComponent } from './editor-walkthrough/editor-walkthrough.component';
import { WindowComponent } from '@progress/kendo-angular-dialog';
import { interval } from 'rxjs';

@Component({
  selector: 'ups-help-editor',
  templateUrl: './editor.component.html',
})
export class EditorComponent extends BaseComponent {
  editedDoc: HelpDocumentDto = this.getBlankHelpDocument();
  displayEditor = false;

  @ViewChild('stepper') stepper: StepperComponent;
  @ViewChild('tooltipEditor') tooltipEditor: EditorTooltipComponent;
  @ViewChild('walkEditor') walkEditor: EditorWalkthroughComponent;
  @ViewChild(ConfirmationDialogComponent) confirm: ConfirmationDialogComponent;
  @ViewChild('editor') editor: WindowComponent;

  currentStep = 0;

  helpTypeEnum = HelpTypeEnum;

  get canDeleteHelpDocs() {
    return this.security.getFeatureById(
      SecurityConstants.employee_portal_frameworkfeatures_walkthroughs
    ).editAll;
  }

  private securityConstants = SecurityConstants;

  steps: StepperStep[] = [
    {
      label: 'Type',
      icon: 'module-manager',
      isValid: () => this.editedDoc.type !== null,
    },
    {
      label: 'Build',
      icon: 'style-builder',
      isValid: () =>
        this.editedDoc.type === HelpTypeEnum.Tooltip
          ? this.tooltipEditor.f.valid
          : true,
    },
    {
      label: 'Settings',
      icon: 'settings',
    },
  ];

  saveInProgress = false;

  constructor(
    private store: Store,
    private actions$: Actions,
    private security: SecurityService
  ) {
    super();

    store.pipe(HelpState.selectEditorState(this.destroy$)).subscribe((x) => {
      this.displayEditor = x.show;
      this.editedDoc = x.document
        ? deepClone(x.document)
        : this.getBlankHelpDocument();
      if (this.editedDoc.type) {
        this.goToStep(1);
      }
    });

    // interval(500).pipe(
    //   filter(() => this.displayEditor),
    //   takeUntil(this.destroy$),
    // ).subscribe(x => {
    //   console.log(x, this.editor);
    //   this.editor?.bringToFront()
    // });
  }

  hideEditor() {
    this.store.dispatch(HelpActions.toggleEditor({ state: false }));
    this.goToStep(0);
  }

  goToStep(idx) {
    console.log(`Current: ${this.currentStep}, Target: ${idx}`);
    for (let index = 0; index < idx; index++) {
      const step = this.steps[index];
      if (typeof step.isValid === 'function') {
        if (!step.isValid(idx)) {
          idx = this.currentStep;
        }
      } else if (step.isValid !== undefined && !step.isValid) {
        idx = this.currentStep;
      }
    }
    this.currentStep = idx;
    if (this.stepper) this.stepper.currentStep = idx;
  }

  saveHelpDoc() {
    this.saveInProgress = true;
    this.store.dispatch(HelpActions.saveHelpDoc({ doc: this.editedDoc }));
    this.actions$
      .pipe(
        ofType(HelpActions.saveHelpDocSuccess, HelpActions.saveHelpDocError),
        take(1)
      )
      .subscribe((x) => {
        this.reset();
      });
  }

  getBlankHelpDocument(): HelpDocumentDto {
    return {
      path: '',
      reusable: false,
      title: null,
      type: null,
      applicationGuid: environment.security.appId,
      isPublished: false,
    };
  }

  deleteHelpDoc(): void {
    this.confirm.open(
      `Delete this ${
        this.editedDoc.type === HelpTypeEnum.Tooltip ? 'tooltip' : 'walkthrough'
      }?`,
      () => {
        this.saveInProgress = true;
        this.store.dispatch(
          HelpActions.deleteDocument({ document: this.editedDoc })
        );
        this.actions$
          .pipe(
            ofType(
              HelpActions.deleteDocumentSuccess,
              HelpActions.deleteDocumentError
            ),
            take(1)
          )
          .subscribe((x) => {
            this.reset();
          });
      }
    );
  }

  reset() {
    this.goToStep(0);
    this.editedDoc = this.getBlankHelpDocument();
    this.saveInProgress = false;
  }
}
