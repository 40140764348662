export * from './dynamic-data-labels/dynamic-data-labels.component';
import { DynamicDataLabelsComponent } from './dynamic-data-labels/dynamic-data-labels.component';
import { DynComponentMapping } from '@ups/xplat/features';

import { DynamicButtonComponent } from './dynamic-button/dynamic-button.component';
import { DynamicChartComponent } from './dynamic-chart/dynamic-chart.component';
import { DynamicCheckboxComponent } from './dynamic-checkbox/dynamic-checkbox.component';
import { DynamicCircleComponent } from './dynamic-circle/dynamic-circle.component';
import { DynamicDateComponent } from './dynamic-date/dynamic-date.component';
import { DynamicInputComponent } from './dynamic-input/dynamic-input.component';
import { DynamicImageComponent } from './dynamic-image/dynamic-image.component';
import { DynamicNumericTextboxComponent } from './dynamic-numerictextbox/dynamic-numerictextbox.component';
import { DynamicRadioComponent } from './dynamic-radio/dynamic-radio.component';
import { DynamicTextareaComponent } from './dynamic-textarea/dynamic-textarea.component';
import { DynamicSafetyAtRiskComponent } from './dynamic-safetyatrisk/dynamic-safetyatrisk.component';
import {
  DYNAMIC_TYPEAHEADS,
  DynamicTypeaheadAutocompleteComponent,
  DynamicTypeaheadEmployeeComponent,
  DynamicTypeaheadCustomersComponent,
  DynamicTypeaheadFacilityComponent,
  DynamicTypeaheadCompanyComponent,
  DynamicTypeaheadIconsComponent,
  DynamicTypeaheadCategoryComponent,
} from './dynamic-typeaheads';
import { DynamicTypeaheadComponent } from './dynamic-typeaheads/dynamic-typeahead/dynamic-typeahead.component';
import { DynamicLabelComponent } from './dynamic-label/dynamic-label.component';
import { DynamicTypeaheadJobComponent } from './dynamic-typeaheads/dynamic-typeahead-job/dynamic-typeahead-job.component';
import { DynamicHtmlTextComponent } from './dynamic-html-text/dynamic-html-text.component';
import { DynamicFileComponent } from './dynamic-file/dynamic-file.component';
import { DynamicAccordionComponent } from './dynamic-accordion/dynamic-accordion.component';
import { DynamicButtonGroupComponent } from './dynamic-buttongroup/dynamic-buttongroup.component';
import { DynamicSignatureComponent } from './dynamic-signature/dynamic-signature.component';
import { DYNAMIC_HELPER_COMPONENTS } from './helpers';
import { DynamicCoachingComponent } from './dynamic-coaching/dynamic-coaching.component';
import { DynamicReviewComponent } from './dynamic-review/dynamic-review.component';
import { DynamicCopyLinkButtonComponent } from './dynamic-copy-link-button/dynamic-copy-link-button.component';
import { DynamicCommentComponent } from './dynamic-comment/dynamic-comment.component';
import { DynamicLastModifiedComponent } from './dynamic-last-modified/dynamic-last-modified.component';
import { DynamicRedactedComponent } from './dynamic-redacted/dynamic-redacted.component';
import { DynamicTextareaDirective } from './dynamic-textarea/dynamic-textarea.directive';
export const DYNAMIC_ITEM_COMPONENTS = [
  DynamicButtonComponent,
  DynamicChartComponent,
  DynamicCheckboxComponent,
  DynamicCircleComponent,
  DynamicDateComponent,
  DynamicHtmlTextComponent,
  DynamicInputComponent,
  DynamicImageComponent,
  DynamicLabelComponent,
  DynamicNumericTextboxComponent,
  DynamicRadioComponent,
  DynamicSafetyAtRiskComponent,
  DynamicTextareaComponent,
  DynamicTextareaDirective,
  DynamicTypeaheadComponent,
  DynamicFileComponent,
  DynamicAccordionComponent,
  DynamicButtonGroupComponent,
  DynamicSignatureComponent,
  DynamicCoachingComponent,
  DynamicReviewComponent,
  DynamicCopyLinkButtonComponent,
  DynamicCommentComponent,
  DynamicLastModifiedComponent,
  ...DYNAMIC_TYPEAHEADS,
  ...DYNAMIC_HELPER_COMPONENTS,
  DynamicRedactedComponent,
  DynamicDataLabelsComponent,
];

/* eslint-disable @typescript-eslint/naming-convention */
export const DYNAMIC_COMPONENT_MAPPING: DynComponentMapping = {
  accordion: DynamicAccordionComponent,
  button: DynamicButtonComponent,
  buttongroup: DynamicButtonGroupComponent,
  circle: DynamicCircleComponent,
  coaching: DynamicCoachingComponent,
  comment: DynamicCommentComponent,
  chart: DynamicChartComponent,
  checkbox: DynamicCheckboxComponent,
  copyLinkButton: DynamicCopyLinkButtonComponent,
  dataLabels: DynamicDataLabelsComponent,
  date: DynamicDateComponent,
  signature: DynamicSignatureComponent,
  email: DynamicInputComponent,
  file: DynamicFileComponent,
  image: DynamicImageComponent,
  input: DynamicInputComponent,
  label: DynamicLabelComponent,
  'last-modified': DynamicLastModifiedComponent,
  'html-text': DynamicHtmlTextComponent,
  number: DynamicNumericTextboxComponent,
  numerictextbox: DynamicNumericTextboxComponent,
  phone: DynamicInputComponent,
  radio: DynamicRadioComponent,
  redacted: DynamicRedactedComponent,
  review: DynamicReviewComponent,
  safetyatrisk: DynamicSafetyAtRiskComponent,
  text: DynamicInputComponent,
  textarea: DynamicTextareaComponent,
  typeahead: DynamicTypeaheadComponent,
  'typeahead-autocomplete': DynamicTypeaheadAutocompleteComponent,
  'typeahead-category': DynamicTypeaheadCategoryComponent,
  'typeahead-company': DynamicTypeaheadCompanyComponent,
  'typeahead-customers': DynamicTypeaheadCustomersComponent,
  'typeahead-employee': DynamicTypeaheadEmployeeComponent,
  'typeahead-facilities': DynamicTypeaheadFacilityComponent,
  'typeahead-icons': DynamicTypeaheadIconsComponent,
  'typeahead-job': DynamicTypeaheadJobComponent,
};
/* eslint-enable @typescript-eslint/naming-convention */

export * from './dynamic-button/dynamic-button.component';
export * from './dynamic-chart/dynamic-chart.component';
export * from './dynamic-checkbox/dynamic-checkbox.component';
export * from './dynamic-circle/dynamic-circle.component';
export * from './dynamic-date/dynamic-date.component';
export * from './dynamic-html-text/dynamic-html-text.component';
export * from './dynamic-input/dynamic-input.component';
export * from './dynamic-image/dynamic-image.component';
export * from './dynamic-numerictextbox/dynamic-numerictextbox.component';
export * from './dynamic-radio/dynamic-radio.component';
export * from './dynamic-textarea/dynamic-textarea.component';
export * from './dynamic-safetyatrisk/dynamic-safetyatrisk.component';
export * from './dynamic-label/dynamic-label.component';
export * from './dynamic-file/dynamic-file.component';
export * from './dynamic-signature/dynamic-signature.component';
export * from './dynamic-coaching/dynamic-coaching.component';
export * from './dynamic-review/dynamic-review.component';
export * from './dynamic-comment/dynamic-comment.component';
export * from './dynamic-last-modified/dynamic-last-modified.component';
export * from './dynamic-typeaheads';
export * from './dynamic-redacted/dynamic-redacted.component';
