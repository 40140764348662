import { EarnCode } from '../../earn-code.model';
import { BillRatesDto } from './BillRatesDto';

export class DispatchDto {
  public bActive: boolean;
  public bActiveState: boolean;
  public bDispatchSigned: boolean;
  public bPerDiemDocs: boolean;
  public billMethod: string;
  public billPerDiem: number;
  public billRates: BillRatesDto;
  public class: string;
  public companyID: string;
  public craft: string;
  public dispatchStatusID: number;
  public employeeID: string;
  public employeeJobID: string;
  public firstName: string;
  public hasHiddenRates: boolean;
  public hasPerDiem: boolean;
  public jobID: string;
  public jobName: string;
  public last4SSN: string;
  public lastName: string;
  public manPowerReqDetailID: string;
  public mileage: number;
  public otFactor: number;
  public payPerDiem: number;
  public payRate: number;
  public perDiem: number;
  public ptFactor: number;
  public rofDate: string;
  public shift: number;
  public shiftDiffAmount: number;
  public shiftDiffPercentage: number;
  public shouldMoveOnsite: boolean;
  public startDate: Date;
  public template: string;
  public vpCompanyID: number;
  public vpJobID: string;
  public vpid: number;
  public prDept: string;
  public prGroup: string;
  public mileageDesignation: string;
  public contractRate: number;
  public fileOTFactor: number;
  public filePTFactor: number;
  public filePerDiem: number;
  public perDiemDocsStatusID: string;
  public userNotes: string;
  public workGroup: string;
  public defaultBillableEarnCodes: EarnCode[];
  public defaultNonBillableEarnCodes: EarnCode[];
  public defaultChildBillableEarnCodes: EarnCode[];
  public defaultChildNonBillableEarnCodes: EarnCode[];
  mileageRecords? = [];

  // client side only
  public employeeDisplayName: string;
}
