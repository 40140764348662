import { BaseModel } from '../base.model';

export class HRResourceDto extends BaseModel {
  public HRResourceID: string;
  public Name: string;
  public ResourceType: string;
  public bActive: boolean;
  public isSystemDependent: boolean;
  public Position: number;
  public URL: string;
  public VpCompanyIds: number[] | null;
  public SecurityUserId: string; // Guid

  constructor(o: any = null) {
    super(o);
  }
}
