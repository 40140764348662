import { Injectable, inject } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import {
  AppActions,
  RouterActions,
  LocalStorageService,
  WindowService,
} from '@ups/xplat/core';

import { IEmployeeInfo } from '@ups/xplat/api/dto';
import { of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { IAddressChangeRequest } from '../models';

import { UserService } from '../services';
import { AuthService } from '@auth0/auth0-angular';
import { UserActions } from './user.actions';
import { UserState } from './user.state';

const defaultPageKey = (userId) => `${userId}.defaultPage`;

/* eslint-disable */
@Injectable()
export class UserEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadCurrent),
      mergeMap(() =>
        this.userService.fetchMyInfo().pipe(
          mergeMap((x) => [
            UserActions.loadCurrentSuccess({ payload: x }),
            UserActions.loadDefaultPage(),
          ]),
          catchError((err) => of(UserActions.loadCurrentFailed(err)))
        )
      )
    )
  );

  loadRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadRoles),
      mergeMap(() =>
        this.userService.getRoles().pipe(
          mergeMap((x) => [UserActions.loadRolesSuccess({ payload: x })]),
          catchError((err) => of(UserActions.loadRolesFailed(err)))
        )
      )
    )
  );

  loadVacationAndSickBalance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadVacationAndSickBalance),
      switchMap((payload) =>
        this.userService.fetchVacationAndSickInfo(payload.hrRef).pipe(
          mergeMap((x) => [
            UserActions.loadVacationAndSickBalanceSuccess({
              vacationAndSickBalance: x,
            }),
          ]),
          catchError((err) =>
            of(UserActions.loadVacationAndSickBalanceFailed(err))
          )
        )
      )
    )
  );

  loadAttachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadAttachments),
      switchMap(() =>
        this.userService.fetchMyAttachments().pipe(
          mergeMap((x) => [UserActions.loadAttachmentsSuccess({ payload: x })]),
          catchError((err) => of(UserActions.loadAttachmentsFailed(err)))
        )
      )
    )
  );

  loadEContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadEContacts),
      switchMap((hrref) =>
        this.userService.fetchEmergencyContacts(hrref.hRRef).pipe(
          mergeMap((x: any[]) => [
            UserActions.loadEContactsSuccess({ payload: x }),
          ]),
          catchError((err) => of(UserActions.loadEContactsFailed(err)))
        )
      )
    )
  );

  updateEContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateEContact),
      mergeMap(({ contact }) =>
        this.userService.saveEmergencyContact(contact).pipe(
          mergeMap(() => {
            const actions: Array<Action> = [
              UserActions.updateEContactSuccess(),
            ];
            if (this.win.navigator.onLine) {
              // only refresh econtacts after updating if online
              actions.push(UserActions.loadEContacts({ hRRef: contact.HRRef }));
            }
            return actions;
          }),
          catchError((err) => of(UserActions.updateEContactFailed(err)))
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.update),
      switchMap(({ data }) =>
        this.userService.updateEmployeeProfileBasicDetails(data).pipe(
          mergeMap((updated) => [
            UserActions.updateSuccess({ data: updated }),
            UserActions.checkAddressChangeRequest({
              hRRef: updated.HRRef,
            }),
          ]),
          catchError((err) => of(UserActions.updateFailed(err)))
        )
      )
    )
  );

  checkAddressChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.checkAddressChangeRequest),
      switchMap((payload) =>
        this.userService.checkAddressChange(payload.hRRef).pipe(
          mergeMap((data) => [
            UserActions.checkAddressChangeRequestSuccess({ data }),
          ]),
          catchError((err) =>
            of(UserActions.checkAddressChangeRequestFailed(err))
          )
        )
      )
    )
  );

  loadMulitclass$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadMulticlass),
      switchMap(({ hRRef }) =>
        this.userService.fetchEmployeeMulitclass(hRRef).pipe(
          mergeMap((x: any[]) => [
            UserActions.loadMulticlassSuccess({ payload: x }),
          ]),
          catchError((err) => of(UserActions.loadMulticlassFailed(err)))
        )
      )
    )
  );

  submitAddressChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.submitAddressChangeRequest),
      switchMap((val) =>
        this.userService.submitAddressChangeRequest(val).pipe(
          mergeMap((res: IAddressChangeRequest) => [
            UserActions.checkAddressChangeRequestSuccess({ data: res }),
            UserActions.submitAddressChangeSuccess({ payload: res }),
          ]),
          catchError((err: any) =>
            of(UserActions.submitAddressChangeFailed(err))
          )
        )
      )
    )
  );

  navigateToDefaultPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadedDefaultPage),
      filter((x) => !!x.path),
      map((x) => {
        const basePaths = ['', '/', '/home'];
        if (
          this.win.isMobile ||
          !basePaths.includes(this.win.location.pathname)
        ) {
          // 1. mobile uses dynamic guard to control initial default page
          // 2. web should only set default page when loading domain, not when deep linking somewhere else
          return new AppActions.Noop();
        } else {
          return new RouterActions.Go({ path: [x.path] });
        }
      })
    )
  );

  loadDefaultPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadCurrentSuccess),
      distinctUntilChanged(),
      withLatestFrom(this.store.select(UserState.selectCurrent)),
      map(([action, user]) =>
        this.storageService.getItem<string>(
          defaultPageKey((<IEmployeeInfo>user)?.Auth0UserId)
        )
      ),
      mergeMap((path) => [
        UserActions.loadedDefaultPage({ path }),
        UserActions.setDefaultPage({ path }),
      ])
    )
  );

  persistDefaultPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.setDefaultPage),
        distinctUntilChanged(),
        filter((x) => !!x.path),
        withLatestFrom(this.store.select(UserState.selectCurrent)),
        tap(([action, user]) =>
          this.storageService.setItem(
            defaultPageKey((<IEmployeeInfo>user)?.Auth0UserId),
            action.path
          )
        )
      ),
    {
      dispatch: false,
    }
  );

  clearDefaultPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.clearDefaultPage),
        withLatestFrom(this.store.select(UserState.selectCurrent)),
        tap(([action, user]) =>
          this.storageService.removeItem(
            defaultPageKey((<IEmployeeInfo>user)?.Auth0UserId)
          )
        )
      ),
    {
      dispatch: false,
    }
  );

  getLoggedInSecurityUserId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getLoggedInSecurityUserId),
      map((x) => this.authService.idTokenClaims$),
      map((x) => (x ? x['http://security.universalplant.com/user-id'] : null)),
      map((x) => UserActions.setLoggedInSecurityUserId({ id: x }))
    )
  );

  loadOnLogin$ = createEffect(() =>
    this.authService.isAuthenticated$.pipe(
      filter((x) => !!x),
      mergeMap(() => [
        UserActions.loadCurrent(),
        UserActions.getLoggedInSecurityUserId(),
        UserActions.loadRoles(),
      ])
    )
  );

  private storageService = inject(LocalStorageService);
  private win = inject(WindowService);
  private userService = inject(UserService);

  constructor(
    private actions$: Actions,
    private store: Store,
    private authService: AuthService
  ) {}
}
/* eslint-enable */
