<div class="dr-admin-list-page">
  <div class="list-container">
    <kendo-grid [data]="summaries" [height]="1000">
      <kendo-grid-column
        field="name"
        title="NAME"
        width="200"
        [headerClass]="'grid-header'"
      >
        <ng-template kendoGridCellTemplate let-item="dataItem">
          <span class="grid-item-name">{{ item?.name }}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="id" title="ID"> </kendo-grid-column>
      <kendo-grid-column field="versionNumber" title="VERSION NO." width="120">
      </kendo-grid-column>
      <kendo-grid-column field="modified" title="DATE" width="130">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ dataItem.modified | date }}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="modifiedBy" title="AUTHOR" width="160">
      </kendo-grid-column>
      <kendo-grid-column field="isPublished" title="PUBLISHED" width="100">
        <ng-template kendoGridCellTemplate let-item="dataItem">
          <input type="checkbox" [checked]="item.isPublished" disabled />
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title=" " width="290">
        <ng-template kendoGridCellTemplate let-item="dataItem">
          <div>
            <a
              class="k-button k-primary grid-action"
              [routerLink]="['../design', item.id]"
              ><span><i class="far fa-edit"></i>Edit</span></a
            >
            <a class="k-button grid-action" [routerLink]="['../view', item.id]"
              ><span><i class="far fa-eye"></i>View</span></a
            >
            <a class=" grid-action" (click)="onDeleteClick(item.id)"
              ><span><i class="far fa-trash delete"></i></span
            ></a>
          </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
