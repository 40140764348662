import { Component } from '@angular/core';
import { WebDynamicItemPreviewComponentBase } from '../web-dynamic-item-component-base';

@Component({
  selector: 'form-numeric-textbox',
  styleUrls: [],
  template: `
    <div class="dynamic-field form-input text-align">
      <label></label>
      tetbox
    </div>
  `,
})
export class FormNumericTextboxComponent extends WebDynamicItemPreviewComponentBase {}
