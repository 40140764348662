import { BaseModel } from '../base.model';

export class TableviewShareModel extends BaseModel {
  TableViewShareID: string = null;
  TableViewID: string = null;
  EmployeeID: string = null;
  Employee: string = null;
  bActive: boolean;

  constructor(o: any = null) {
    super(o);
    if (o) {
      Object.assign(this, o);
    }
  }
}
