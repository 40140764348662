import { BaseModel } from '../base.model';
import { ManpowerAttachment } from './manpower/manpower-attachment';

export class Plant extends BaseModel {
  public PlantID: number;
  public CompanyID: string;
  public VPCompanyID: number;
  public ParentFacilityID: number;
  public Name: string;
  public Address: string;
  public City: string;
  public State: string;
  public ZIP: string;
  public Client: string;
  public bActive: boolean;
  public DisaOwnerIDForDrugTest: string;
  public DisaOwnerIDForBackground: string;
  public DisaOwnerForDrugTest: string;
  public DisaOwnerForBackground: string;
  public DaysUntilUrineRetestOnsite: number;
  public DaysUntilHairRetestOnsite: number;
  public DaysUntilOralRetestOnsite: number;
  public DaysUntilBackgroundRecheckOnsite: number;
  public DaysUntilUrineRetestPreOnsite: number;
  public DaysUntilHairRetestPreOnsite: number;
  public DaysUntilOralRetestPreOnsite: number;
  public DaysUntilBackgroundRecheckPreOnsite: number;
  public VPArcmKeyID: number;
  public AssetControlManagerID: string;
  public AssetControlManagerName: string;

  public Attachments: ManpowerAttachment[];
  public Requirements: any[];
  public EquipmentRequirements: any[] = []; // Need to be initialized as empty array as we need to instance that is accessed by subcomponents and adding items into it.

  constructor(o: any = null) {
    super(o);
  }
}
