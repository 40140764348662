import { Component, ElementRef, NgZone, OnInit, Renderer2 } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ProgressService, UIState } from '@ups/xplat/core';
//<div class="spinner"></div>
/**
 * Reactive DOM (page blocking) spinner
 * Style: _spinner.scss
 * NOTE: Style must not be refactored to component level.
 * It must remain global since it is used before Angular app loads
 * See index.html - The spinner is embedded inside root app component to start
 */
@Component({
  selector: 'ups-spinner',
  template: `
    <div class="activity-indicator loading-spinner">
      <i class="k-icon k-i-loading"></i>
      <div class="loading-msg"></div>
    </div>
  `,
})
export class SpinnerComponent implements OnInit {
  // internal state used to prevent multiple renderer touchpoints
  private _enabled = true;
  private _msg: string;
  private _textNode: any;

  constructor(private _store: Store<any>, private _ngZone: NgZone, private _el: ElementRef, private _renderer: Renderer2, private _progress: ProgressService) {}

  ngOnInit() {
    this._store.pipe(select(UIState.selectProgress)).subscribe((state) => {
      if (state.page.enabled !== this._enabled) {
        this._enabled = state.page.enabled;
        this.toggle(this._enabled);
      }
      if (state.page.message !== this._msg) {
        this._msg = state.page.message;
        this.setMsg(this._msg);
      }
    });
  }

  private toggle(enabled: boolean) {
    if (this._renderer) {
      this._renderer.setStyle(this._el.nativeElement, 'display', `${enabled ? 'block' : 'none'}`);
    }
  }

  private setMsg(msg?: string) {
    if (msg) {
      if (this._textNode) {
        this._renderer.setValue(this._textNode, msg);
      } else {
        this._textNode = this._renderer.createText(msg);
        this._renderer.appendChild(this._el.nativeElement.querySelector('.loading-msg'), this._textNode);
      }
    } else if (this._textNode) {
      this._renderer.removeChild(this._el.nativeElement.querySelector('.loading-msg'), this._textNode);
      this._textNode = null;
    }
  }
}
