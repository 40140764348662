import { Component, Output, EventEmitter } from '@angular/core';
import { ApplicationModel, DispatchStatusDto } from '@ups/xplat/api/dto';
import { BaseComponent } from '@ups/xplat/core';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import { CodeListResolver } from '@ups/xplat/web/services';
import { HRResourceService } from '@ups/xplat/api/services';
import { MessageHelper } from '@ups/xplat/web/core';

@Component({
  selector: 'ups-dispatch-status-edit',
  templateUrl: './dispatch-status-edit.component.html',
})
export class DispatchStatusEditComponent extends BaseComponent {
  canModify: boolean = false;

  @Output()
  saved: EventEmitter<DispatchStatusDto> = new EventEmitter<DispatchStatusDto>();

  opened: boolean = false;
  isEdit: boolean = false;
  isSaving: boolean = false;
  showConfirm: boolean = false;

  model: any = new DispatchStatusDto({ bActive: true });
  savedModel: any;

  constructor(
    private security: SecurityService,
    private msgHelper: MessageHelper,
    private codeListResolver: CodeListResolver,
    private hrResourceService: HRResourceService,
    public applicationModel: ApplicationModel
  ) {
    super();

    const fSecurity = this.security.getFeatureById(
      SecurityConstants.employee_portal_dispatch_status_adminpage
    );
    this.canModify = fSecurity.editAll;
  }

  public open(model: DispatchStatusDto) {
    const self = this;

    this.codeListResolver.loadDispatchGroups();

    if (model) {
      self.savedModel = JSON.stringify(model);
      self.model = JSON.parse(self.savedModel);
      self.isEdit = true;
      self.opened = true;
    } else {
      self.model = new DispatchStatusDto({ bActive: true });
      self.savedModel = JSON.stringify(self.model);
      self.isEdit = false;
      self.opened = true;
    }
  }

  public close() {
    if (this.savedModel !== JSON.stringify(this.model)) {
      this.showConfirm = true;
      return;
    }
    if (!this.isSaving) {
      this.opened = false;
    }
  }

  isValid() {
    return this.model && this.model.Name && this.model.Name.trim().length !== 0;
  }

  onSave() {
    const self = this;

    if (!this.isValid()) {
      return;
    }

    this.isSaving = true;

    self.hrResourceService.saveDispatchStatus(this.model).then(
      (httpOkData) => {
        self.isSaving = false;
        self.model = httpOkData;
        self.savedModel = JSON.stringify(self.model);

        self.close();
        self.saved.next(self.model);
        this.msgHelper.success(
          'Dispatch Status' +
            (self.isEdit ? ' updated ' : ' created ') +
            'successfully!'
        );
      },
      (error) => {
        self.isSaving = false;
        self.msgHelper.error(error.Messages);
      }
    );
  }
}
