<kendo-dialog modal fade [hidden]="!opened" (close)="close()" [width]="400">
  <kendo-dialog-titlebar>
    <span>Equipment Billing Rates ({{ billingTemplate }})</span>
  </kendo-dialog-titlebar>

  <div>
    <kendo-grid [data]="billingRates">
      <kendo-grid-column title="Unit" field="UnitName" width="100">
        <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
          <span
            title="{{ dataItem.UnitName }}"
            [ngClass]="{
              'text-success': isNewRate(dataItem),
              'text-danger': dataItem.BillingRate === 'N/A'
            }"
          >
            {{ dataItem.UnitName }}
          </span>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">{{ column.title }}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Rate" field="BillingRate" width="100">
        <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
          <span
            title="{{ dataItem.BillingRate }}"
            [ngClass]="{
              'text-success': isNewRate(dataItem),
              'text-danger': dataItem.BillingRate === 'N/A'
            }"
          >
            {{ dataItem.BillingRate }}
          </span>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">{{ column.title }}</span>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>

  <div *ngIf="newRatesAvailable()" class="w-100 text-center text-success mt-5">
    <span>New Revenue Code/Billing Rates available</span>
  </div>

  <kendo-dialog-actions>
    <button
      *ngIf="isUpdateAvailable()"
      class="k-button k-primary"
      [disabled]="isSaving"
      (click)="update()"
    >
      <span *ngIf="!isSaving">Update Dispatch</span>
      <span *ngIf="isSaving">
        <i class="fa fa-spinner fa-spin"></i>
        Updating...
      </span>
    </button>
    <button class="k-button" (click)="close()">Close</button>
  </kendo-dialog-actions>
</kendo-dialog>
