<ups-header
  help-anchor="header__title"
  title="Equipment Dashboard"
  class="ups-header"
></ups-header>
<section class="mt-3 condensed-view">
  <div class="container-fluid gutter-30">
    <div class="d-flex justify-content-end mb-3">
      <div
        *ngIf="selectedTabIx === 0 || selectedTabIx === 1"
        class="d-flex align-items-center"
      >
        <label class="me-3 whitespace-nowrap">Asset Control Manager</label>
        <kendo-combobox
          [data]="assetControlManagers"
          class="w-300"
          help-anchor="assetControlManagerDD"
          [textField]="'name'"
          [valueField]="'id'"
          [(ngModel)]="selectedAssetControlManager"
          [clearButton]="false"
        ></kendo-combobox>
      </div>

      <div *ngIf="selectedTabIx === 2" class="d-flex align-items-center">
        <kendo-dropdownlist
          #jobDropdownlist
          class="k-no-dropdown w-300 me-3"
          name="job"
          [(ngModel)]="selectedJob"
          [valuePrimitive]="false"
          [textField]="'Name'"
          [valueField]="'JobID'"
          [filterable]="true"
          [defaultItem]="{ Name: placeholder }"
          search-ex
          [seSearchColumns]="['VpJobId', 'Name']"
          (seFilterChangeEx)="filterChangeJob($event)"
          [seDebounceFilterChange]="150"
          [seUseCustomLocalFilter]="true"
          [seMinSearchLength]="3"
          [popupSettings]="{ width: 450 }"
        >
          <ng-template kendoDropDownListItemTemplate let-dataItem>
            <span *ngIf="dataItem.JobID" class="font-weight-bold">
              {{ dataItem.JobID }}
            </span>
            <span *ngIf="dataItem.JobID">{{ ' - ' }}</span>
            <span *ngIf="dataItem.Name" class="job-name">
              {{ dataItem.Name }}
            </span>
            <span
              *ngIf="!dataItem.IsActive && dataItem.JobID"
              class="text-danger"
            >
              (Closed)
            </span>
          </ng-template>
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <span
              [title]="
                dataItem.JobID +
                ' - ' +
                dataItem.Name +
                (!dataItem.IsActive && dataItem.JobID ? ' (Closed)' : '')
              "
            >
              <span *ngIf="dataItem.JobID" class="font-weight-bold">
                {{ dataItem.JobID }}
              </span>
              <span *ngIf="dataItem.JobID">{{ ' - ' }}</span>
              <span *ngIf="dataItem.Name" class="job-name">
                {{ dataItem.Name }}
              </span>
              <span
                *ngIf="!dataItem.IsActive && dataItem.JobID"
                class="text-danger"
              >
                (Closed)
              </span>
            </span>
          </ng-template>
        </kendo-dropdownlist>
        <div>
          <input
            id="showClosedJobsCheckbox"
            type="checkbox"
            kendoCheckBox
            [(ngModel)]="showAllJobs"
          />
          <label class="k-checkbox-label ms-0" for="showClosedJobsCheckbox">
            Include Closed Jobs
          </label>
        </div>
      </div>
    </div>

    <div *ngIf="selectedTabIx === 3">
      <label class="font-weight-bold">Count settings</label>

      <div class="wrap">
        <input
          id="equipment"
          name="countSettings"
          type="radio"
          [value]="countSettingsEnum.Equipment"
          [(ngModel)]="countSettings"
          kendoRadioButton
        />
        <label
          for="equipment"
          class="k-radio-label"
          title="Total records with an equipment assigned except status Cancelled"
        >
          Count by equipment
        </label>
      </div>

      <div class="wrap">
        <input
          id="onsiteDispatched"
          name="countSettings"
          type="radio"
          [value]="countSettingsEnum.EquipmentOnsiteDispatched"
          [(ngModel)]="countSettings"
          kendoRadioButton
        />
        <label
          for="onsiteDispatched"
          class="k-radio-label"
          title="All Dispatched and Onsite Equipment / All statuses except Cancelled"
        >
          Count by dispatched / onsite
        </label>
      </div>

      <div class="wrap">
        <input
          id="onsite"
          name="countSettings"
          help-anchor="countByOnsiteRadioButton"
          type="radio"
          [value]="countSettingsEnum.EquipmentOnsite"
          [(ngModel)]="countSettings"
          kendoRadioButton
        />
        <label
          for="onsite"
          class="k-radio-label"
          title="All Onsite Equipment / All statuses except Cancelled"
        >
          Count by onsite
        </label>
      </div>
      <div id="colorLegendId">
        <div>
          <div
            *ngIf="
              countSettings === countSettingsEnum.EquipmentOnsiteDispatched
            "
          >
            <span
              title="Number of equipment requests in OnSite or Dispatched status."
            >
              Dispatched/Onsite
            </span>
          </div>
          <div *ngIf="countSettings === countSettingsEnum.EquipmentOnsite">
            <span title="Number of equipment requests in OnSite status.">
              Onsite
            </span>
          </div>
        </div>
        <div>
          <div
            *ngIf="
              countSettings === countSettingsEnum.EquipmentOnsiteDispatched
            "
          >
            <span
              title="Number of equipment requests in status other than OnSite or Dispatched."
            >
              Not Dispatched/Not On-site
            </span>
          </div>
          <div *ngIf="countSettings === countSettingsEnum.EquipmentOnsite">
            <span
              title="Number of equipment requests in status other than OnSite."
            >
              Not On-site
            </span>
          </div>
        </div>
      </div>
    </div>

    <kendo-tabstrip
      id="equipmentDashboardTabs"
      (tabSelect)="handleTabSelect($event)"
      class="white-bg"
    >
      <kendo-tabstrip-tab [title]="'In Process'" [selected]="true">
        <ng-template kendoTabContent>
          <ups-equipment-dashboard-grid
            [tab]="equipmentDashboardTabEnum.InProcess"
            [assetControlManagerId]="selectedAssetControlManager?.id"
            [tableViewKey]="'EmployeePortal_EquipmentDashboard_InProcess'"
          ></ups-equipment-dashboard-grid>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Dispatched'">
        <ng-template kendoTabContent>
          <ups-equipment-dashboard-grid
            [tab]="equipmentDashboardTabEnum.Dispatched"
            [assetControlManagerId]="selectedAssetControlManager?.id"
            [tableViewKey]="'EmployeePortal_EquipmentDashboard_Dispatched'"
          ></ups-equipment-dashboard-grid>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'By Job'">
        <ng-template kendoTabContent>
          <ups-equipment-dashboard-grid
            [tab]="equipmentDashboardTabEnum.ByJob"
            [jobNumber]="selectedJob?.JobID"
            [tableViewKey]="'EmployeePortal_EquipmentDashboard_ByJob'"
          ></ups-equipment-dashboard-grid>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab
        id="scoreCardTabId"
        [title]="'Scorecard'"
        *ngIf="isScoreCardTabVisible()"
      >
        <ng-template kendoTabContent>
          <ups-equipment-score-card
            [countSettings]="countSettings"
          ></ups-equipment-score-card>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
</section>
