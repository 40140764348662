<div class="col-sm-9 alert-success me-0 mw-100">
  <fieldset>
    <b *ngIf="showWarning">
      Are you sure this is the correct resource:
      <br />
      <br />
    </b>
    <ups-equipment-basic-info
      [vpEquipment]="equipmentResourceDto"
      [showEquipmentEditButton]="false"
    ></ups-equipment-basic-info>
    <div *ngIf="!overlappingRequests">
      <span>Loading overlapping requests and dispatches...</span>
    </div>
    <div *ngIf="overlappingRequests && overlappingRequests.length">
      <br />
      <b>Overlapping Equipment Requests and Dispatches:</b>
      <table class="table table-striped table-bordered table-align-middle">
        <tr>
          <th>Job Number</th>
          <th>Start Date</th>
          <th>End Date</th>
        </tr>
        <tr *ngFor="let item of overlappingRequests">
          <td>{{ item.VPJobID }}</td>
          <td>{{ item.StartDate | date: 'shortDate' }}</td>
          <td>{{ item.EndDate | date: 'shortDate' }}</td>
        </tr>
      </table>
    </div>
    <button
      *ngIf="showButtons"
      class="btn btn-success pull-right"
      style="margin: 3px"
      [disabled]="!overlappingRequests || !equipmentResourceDto"
      (click)="confirmEquipment.emit()"
      help-anchor="button__yes"
    >
      Yes
    </button>
    <button
      *ngIf="showButtons"
      class="btn btn-danger pull-right"
      style="margin: 3px"
      [disabled]="!overlappingRequests || !equipmentResourceDto"
      (click)="discardEquipment.emit()"
    >
      No
    </button>
  </fieldset>
</div>
