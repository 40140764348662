import {
  Component,
  Output,
  EventEmitter,
  ViewEncapsulation,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { SecurityService } from '@ups/security';
import {
  BaseComponent,
  dynamicModelFormArrayTypes,
  IDynamicModel,
  IDynamicModelGroup,
  IDynamicModelProperties,
  IDynamicUpdateOnValueChange,
  WindowService,
  IApiModelType,
} from '@ups/xplat/core';
import {
  DynamicEventBusTypes,
  DynamicRenderService,
} from '@ups/xplat/features';
import { Camelize, deepClone } from '@ups/xplat/utils';
import { Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { AdminItemPropsDeleteRequest } from '../../../models';
import { DrAdminService } from '../../../services/dr-admin.service';
import { DrAdminMapResolverService } from '../../../services/dr-admin-map-resolver.service';
import { SecurityConstants } from '@ups/security-constants';

@Component({
  selector: 'admin-properties',
  templateUrl: 'admin-properties.component.html',
  styleUrls: ['admin-properties.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminPropertiesComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Output()
  deleteControl: EventEmitter<AdminItemPropsDeleteRequest> = new EventEmitter();
  @Output() updateControl: EventEmitter<{
    markingSelected?: boolean;
    clientId?: string;
    data?: { [key: string]: string | number };
  }> = new EventEmitter();
  // allows a control select to add selections to the selected control
  @Output() addControlToControl: EventEmitter<string> = new EventEmitter();
  @Output() reorderControl: EventEmitter<'up' | 'down'> = new EventEmitter();

  public currentPropertiesPanelItem: IDynamicModel = undefined;

  securityConstants = SecurityConstants;
  canReadAdvancedFields = false;
  canEditAdvancedFields = false;
  dynamicGroup: IDynamicModelGroup;
  private _propertyUpdateTimeout;
  private _valueWatcherSubs: Array<Subscription>;

  constructor(
    private win: WindowService,
    private adminMapResolverService: DrAdminMapResolverService,
    private drAdminService: DrAdminService,
    public dynamicRender: DynamicRenderService,
    private securityService: SecurityService
  ) {
    super();

    const fSecurity = this.securityService.getFeatureById(
      SecurityConstants.safety_2_0_form_builder_advancedfields
    );
    this.canEditAdvancedFields = fSecurity.editAll;
    this.canReadAdvancedFields = fSecurity.readAll || fSecurity.editAll;
  }

  ngOnInit() {
    this.dynamicRender.eventBus
      .observe(DynamicEventBusTypes.dynamicApplyProperties)
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.apply.bind(this));
  }

  cancelPropertyTimeout() {
    if (typeof this._propertyUpdateTimeout === 'number') {
      clearTimeout(this._propertyUpdateTimeout);
      this._propertyUpdateTimeout = null;
    }
  }

  markSelected(clientId: string) {
    this.updateControl.next({
      markingSelected: true,
      clientId: clientId || this.currentPropertiesPanelItem.clientId,
      data: {},
    });
  }

  setCurrentPropertiesPanelItem(selectedControl: IDynamicModel) {
    // console.log(selectedControl);
    this.dynamicRender.selectedControl = selectedControl;
    selectedControl.selected = true;
    this.dynamicGroup = undefined;
    this.cancelPropertyTimeout();
    // const scrollTopBody = this.win.platformWindow.document.body.scrollTop;
    const innerContainer =
      this.win.platformWindow.document.getElementsByClassName(
        'dr-admin-design-surface'
      )[0];
    const scrollTopContainer = innerContainer.scrollTop;
    this._propertyUpdateTimeout = setTimeout(() => {
      this.currentPropertiesPanelItem = selectedControl;
      const options: Array<IDynamicModelProperties> = deepClone(
        this.drAdminService.dynamicModelOptions
      );
      const models = options.filter((propertyModel) => {
        if (propertyModel.types.includes('all')) {
          return true;
        } else if (
          propertyModel.types.includes('typeahead-all') &&
          selectedControl.type.indexOf('typeahead') > -1 &&
          (!propertyModel.typesExclusions ||
            !propertyModel.typesExclusions.includes(selectedControl.type))
        ) {
          return true;
        } else {
          return propertyModel.types.includes(selectedControl.type);
        }
      });

      if (selectedControl?.options?.updateOnValueChange?.length > 1) {
        // add extra properties for additional updateOnValueChange options
        const index = models.findIndex(
          (m) => m.formControlName === 'updateOnValueChange-matchTargetProperty'
        );
        if (index > -1) {
          const updateOnValueChangeControls = [];
          for (
            let i = 1;
            i < selectedControl.options.updateOnValueChange.length;
            i++
          ) {
            updateOnValueChangeControls.push(
              ...this.drAdminService.getUpdateOnValueChangeControls(i)
            );
          }
          models.splice(index + 1, 0, ...updateOnValueChangeControls);
        }
      }

      // set default values and add extra properties as needed
      models.forEach((m) => {
        this._setDefaultValues(m, selectedControl);
      });

      // add reordering controls to top
      models.unshift(this._createReorderControl());

      for (const key in selectedControl) {
        if (key === 'options') {
          for (const optionKey in selectedControl.options) {
            // handle options that map to multiple embedded properties
            if (optionKey === 'buttongroup') {
              for (
                let i = 0;
                i < selectedControl.options.buttongroup.length;
                i++
              ) {
                const option = selectedControl.options.buttongroup[i];
                models.push({
                  formControlName: `buttongroupLabel${i + 1}`,
                  label: `Button Label ${i + 1}`,
                  types: ['buttongroup'],
                  type: 'input',
                  value: option.label || '',
                  options: {
                    tooltip: `The text entry made in the Button Label field will be the label displayed on the button within the form.`,
                    admin: {
                      allowDelete: true,
                    },
                    style: {
                      container: {
                        top: '-12px',
                      },
                      label: {
                        fontSize: '10px',
                      },
                    },
                  },
                });
                models.push({
                  formControlName: `buttongroupValue${i + 1}`,
                  label: 'Button Value',
                  types: ['buttongroup'],
                  type: 'input',
                  value: option.value || `buttonValue${i + 1}`,
                  options: {
                    tooltip: `The text entry made on the Button Value field will be the value written to the Dashboard pages and Database when the button is selected.`,
                    style: {
                      container: {
                        top: '-30px',
                      },
                      label: {
                        fontSize: '10px',
                      },
                    },
                  },
                });
              }
            } else if (
              [
                'api',
                'updateOnValueChange',
                'scopeQueryWhenFormControlIsSet',
              ].includes(optionKey)
            ) {
              for (const m of models) {
                if (m.formControlName.startsWith('api')) {
                  if (!selectedControl.options.api) {
                    selectedControl.options.api = {};
                  }
                  if (!selectedControl.options.api.model) {
                    selectedControl.options.api.model = {} as IApiModelType;
                  }
                  switch (m.formControlName) {
                    case 'api-endpoint':
                      m.value = selectedControl.options.api.endpoint;
                      break;
                    case 'api-model-Id':
                      m.value = selectedControl.options.api.model.id;
                      break;
                    case 'api-model-Name':
                      m.value = selectedControl.options.api.model.name;
                      break;
                    case 'api-data-properties':
                      m.value = selectedControl.options.api.properties;
                      break;
                  }
                } else if (
                  m.formControlName.startsWith('updateOnValueChange')
                ) {
                  const nameParts = m.formControlName.split('-');
                  let index = 0;
                  if (nameParts.length === 3) {
                    // get index from the formControlName
                    index = +nameParts.slice(-1)[0];
                    // allow additional options to be deleted
                    if (!m.options.admin) {
                      m.options.admin = {};
                    }
                    m.options.admin.allowDelete = true;
                  }
                  const updateOnValueChange: IDynamicUpdateOnValueChange =
                    selectedControl.options.updateOnValueChange &&
                    selectedControl.options.updateOnValueChange.length
                      ? selectedControl.options.updateOnValueChange[index]
                      : null;
                  if (updateOnValueChange) {
                    if (
                      m.formControlName.startsWith(
                        'updateOnValueChange-formControlName'
                      )
                    ) {
                      m.value = updateOnValueChange.formControlName;
                    } else if (
                      m.formControlName.startsWith(
                        'updateOnValueChange-property'
                      )
                    ) {
                      m.value = updateOnValueChange.property;
                    } else if (
                      m.formControlName.startsWith(
                        'updateOnValueChange-matchTargetProperty'
                      )
                    ) {
                      m.value = updateOnValueChange.matchTargetProperty;
                    } else if (
                      m.formControlName.startsWith(
                        'updateOnValueChange-validation'
                      )
                    ) {
                      m.value = updateOnValueChange.validation;
                    }
                  }
                } else if (
                  m.formControlName.startsWith('scopeQueryWhenFormControlIsSet')
                ) {
                  if (!selectedControl.options.scopeQueryWhenFormControlIsSet) {
                    selectedControl.options.scopeQueryWhenFormControlIsSet = {};
                  }
                  switch (m.formControlName) {
                    case 'scopeQueryWhenFormControlIsSet-formControlName':
                      m.value =
                        selectedControl.options.scopeQueryWhenFormControlIsSet.formControlName;
                      break;
                    case 'scopeQueryWhenFormControlIsSet-matchTargetProperty':
                      m.value =
                        selectedControl.options.scopeQueryWhenFormControlIsSet.matchTargetProperty;
                      break;
                    case 'scopeQueryWhenFormControlIsSet-queryParamName':
                      m.value =
                        selectedControl.options.scopeQueryWhenFormControlIsSet.queryParamName;
                      break;
                  }
                }
              }
            } else {
              const optionModelIndex = models.findIndex(
                (m) => m.formControlName === optionKey
              );
              if (optionModelIndex > -1 && models[optionModelIndex]) {
                // console.log(`updating property with:`, models[optionModelIndex]);
                // console.log(optionKey, selectedControl.options[optionKey]);
                models[optionModelIndex].value =
                  selectedControl.options[optionKey];
              }
            }
          }
        } else {
          const modelIndex = models.findIndex((m) => m.formControlName === key);
          if (modelIndex > -1) {
            if (
              !dynamicModelFormArrayTypes.includes(selectedControl.type) &&
              selectedControl.formControlName === selectedControl.placeholder
            ) {
              // ignore default values
            } else if (
              models[modelIndex] &&
              selectedControl[key] !== undefined
            ) {
              models[modelIndex].value = selectedControl[key];
            }

            // default limits:
            // https://universalplantservices.visualstudio.com/UPS1%20Phase%201%20Improvements/_workitems/edit/13029
            if (key === 'label' && selectedControl.type === 'label') {
              models[modelIndex].type = 'textarea';
            }
          } else {
          }
        }
      }
      // for (const m of models) {
      //   // ensure values reflect selected item values
      //   for (const p in selectedControl) {
      //     if (value[p]) {
      //       m[p] = value[p];
      //     }
      //   }
      // }

      const basicModels = models.filter((m) => !m.isAdvanced);
      const advancedModels = models.filter((m) => m.isAdvanced);
      if (!this.canEditAdvancedFields) {
        advancedModels.forEach((m) => (m.disabled = true));
      }

      const accordionModels: IDynamicModel[] = [
        {
          label: 'Basic Fields',
          formArrayName: 'basicFields',
          type: 'accordion',
          required: false,
          options: {
            accordionOpen: true,
            accordion: basicModels,
          },
        },
        {
          label: 'Advanced Fields',
          formArrayName: 'advancedFields',
          type: 'accordion',
          required: false,
          options: {
            hidden: !this.canReadAdvancedFields || advancedModels.length === 0,
            accordionOpen: true,
            accordion: advancedModels,
          },
        },
      ];

      this.dynamicGroup = this.dynamicRender.createConfigGroup({
        groupName: 'Properties',
        dynamicModels: accordionModels,
        ignoreDefaultButton: true,
        isFormBuilder: true,
      });
      this.markSelected(selectedControl.clientId);
      setTimeout(() => {
        this._setupValueWatchers();
        // maintain the same scroll position, otherwise will cause page to jump to the top
        // this.win.platformWindow.document.body.scrollTo(0, scrollTopBody);
        if (scrollTopContainer) {
          setTimeout(() => {
            innerContainer.scrollTo(0, scrollTopContainer);
          });
        }
      }, 100);
    });
  }

  clear() {
    this.dynamicGroup = null;
  }

  apply() {
    const formData: { [key: string]: string | number } = {};
    let formProperties = [];
    Object.values(this.dynamicRender.activeFormProperties.controls).forEach(
      (c: UntypedFormArray) => {
        formProperties = formProperties.concat(
          Object.keys((c.controls[0] as UntypedFormGroup).controls).filter(
            (k) => k !== 'submit'
          )
        );
      }
    );
    // const formProperties = Object.keys(this.dynamicRender.activeFormProperties.controls).filter((k) => k !== 'submit');
    let controls = [];
    this.dynamicGroup.controls.forEach((c) => {
      controls = controls.concat(c.options.accordion);
    });
    for (const prop of formProperties) {
      const fieldControl = controls.find((f) => f.formControlName === prop);
      if (fieldControl && !fieldControl.ignoreValue) {
        let formValue = this.getFormPropertiesValue(prop);
        if (
          formValue &&
          typeof formValue === 'object' &&
          fieldControl.valueProperty
        ) {
          formValue = formValue[fieldControl.valueProperty];
        }
        formData[prop] =
          fieldControl.inputType === 'number' ? +formValue : formValue;
      }
    }
    // console.log(formData);
    this.updateControl.next({
      clientId: this.currentPropertiesPanelItem.clientId,
      data: formData,
    });
    this.dynamicRender.activeFormProperties.markAsPristine();
  }

  getFormPropertiesValue(prop: string) {
    let value = null;
    Object.values(this.dynamicRender.activeFormProperties.controls).forEach(
      (c: UntypedFormArray) => {
        const controls = (c.controls[0] as UntypedFormGroup).controls;
        if (controls.hasOwnProperty(prop)) {
          // handle custom value parsing for some properties
          switch (prop) {
            case 'customValidations':
              // @ts-ignore
              value = controls[prop].value?.map((v) => v.Id);
              break;
            default:
              value = controls[prop].value;
              break;
          }
        }
      }
    );
    return value;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._resetValueWatchers();
  }

  private _setDefaultValues(
    adminPropertyControl: IDynamicModelProperties,
    selectedControl: IDynamicModel
  ) {
    // defaults defined in:
    // https://universalplantservices.visualstudio.com/UPS1%20Phase%201%20Improvements/_workitems/edit/13029
    if (selectedControl) {
      switch (adminPropertyControl.formControlName) {
        case 'formControlName':
          if (
            selectedControl.formControlName &&
            selectedControl.formControlName.indexOf(' ') > -1
          ) {
            // ensure no spaces in formControlName's ever
            selectedControl.formControlName = adminPropertyControl.value =
              Camelize.toCamelCase(
                selectedControl.formControlName.replace(/ /gi, '')
              );
          }
          break;
        case 'maxlength':
          if (
            !selectedControl.options ||
            (selectedControl.options && !selectedControl.options.maxlength)
          ) {
            switch (selectedControl.type) {
              case 'input':
              case 'text':
                adminPropertyControl.value = 250;
                break;
              case 'textarea':
                adminPropertyControl.value = 1000;
                break;
            }
          }
          break;
      }
    }
  }

  private _setupValueWatchers() {
    // various properties can emit custom behavior
    // ie, the 'adminAccordionAdd' property can emit an event to add controls inside a selected accordion
    this._resetValueWatchers();
    this._valueWatcherSubs = [];
    const basicFieldGroup = <UntypedFormGroup>(
      (<UntypedFormArray>(
        this.dynamicRender.activeFormProperties.controls.basicFields
      )).controls[0]
    );
    this._valueWatcherSubs.push(this._listenForValueChanges(basicFieldGroup));

    const advancedFieldGroup = <UntypedFormGroup>(
      (<UntypedFormArray>(
        this.dynamicRender.activeFormProperties.controls.advancedFields
      )).controls[0]
    );
    this._valueWatcherSubs.push(
      this._listenForValueChanges(advancedFieldGroup)
    );
  }

  private _listenForValueChanges(formGroup: UntypedFormGroup) {
    return formGroup.valueChanges
      .pipe(
        // emit specific outputs when certain control values change
        filter((g) => {
          let hasValue = false;
          this.drAdminService.adminOnlyFormControlNames.forEach((p) => {
            if (g[p]) {
              hasValue = true;
            }
          });
          return hasValue;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((g) => {
        let groupValueReset: {
          [key: string]: string;
        };

        this.drAdminService.adminOnlyFormControlNames.forEach(
          (formControlName) => {
            if (g[formControlName]) {
              if (!groupValueReset) {
                groupValueReset = {};
              }
              groupValueReset[formControlName] = null;

              switch (formControlName) {
                case 'adminAccordionAdd':
                  this.addControlToControl.emit(g.adminAccordionAdd.Name);
                  break;

                case 'adminReorder':
                  this.reorderControl.emit(g.adminReorder);
                  break;
              }
            }
          }
        );
        if (groupValueReset) {
          // blank out value right after setting to allow continual adding of new controls
          formGroup.patchValue(groupValueReset, {
            emitEvent: true,
          });
          // resets formgroup so it can be reused over/over without being seen as needing further validation fixes
          formGroup.markAsPristine();
        }
      });
  }

  private _resetValueWatchers() {
    if (this._valueWatcherSubs) {
      for (const s of this._valueWatcherSubs) {
        s.unsubscribe();
      }
    }
  }

  private _createReorderControl(): IDynamicModelProperties {
    return {
      formControlName: `adminReorder`,
      label: `Reorder`,
      types: this.drAdminService.controlTypes,
      type: 'buttongroup',
      options: {
        labelIgnore: true,
        style: {
          button: {
            fontSize: '12px',
            padding: '0 10px',
            width: '70px',
            height: '34px !important',
          },
        },
        buttonGroupIgnoreState: true,
        buttongroup: [
          {
            label: '⬆ Up',
            value: 'up',
          },
          {
            label: '⬇ Down',
            value: 'down',
          },
        ],
      },
    };
  }
}
