/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, inject, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';

import { MENU_SERVICE_TOKEN } from '@ups/common';

import '@ups/common/json/json-date-ex.js'; // needs also a ref. of JD in the code to be loaded
import { SecurityService } from '@ups/security';
import { AuthService } from '@auth0/auth0-angular';
import { BaseComponent } from '@ups/xplat/core';
import { HRRMService } from '@ups/xplat/api/services';
import { registerLicense } from '@syncfusion/ej2-base';
import { NotificationsViewService } from '../services';

@Directive()
export abstract class AppBaseComponent extends BaseComponent {
  /**
   * Define common root app component behavior for all web apps here
   */
  employeeVpService: HRRMService;
  injector = inject(Injector);
  menuService = inject(MENU_SERVICE_TOKEN);
  auth = inject(AuthService);
  security = inject(SecurityService);
  router = inject(Router);
  // NOTE: just to force DI to initialize instance
  notificationsService = inject(NotificationsViewService);

  constructor() {
    super();
    registerLicense(
      'ORg4AjUWIQA/Gnt2VlhhQlJCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn9Rdk1iXntfcXxVQGdV'
    );
    this.employeeVpService = this.injector.get(HRRMService);

    // this.router.events.subscribe((_) => console.log('router event', _));

    // NOTE: just a ref, so the module loads!
    // const loadJD = JD;
    (JSON as any).useDateStringifyMode = 2;

    this.auth.user$
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((x) => {
        if (x) {
          const auth0UserId = x.sub;
          // Todo: Do we need display name and email as local vars here?
          // eslint-disable-next-line no-unused-vars
          const displayName = x.nickname;
          // eslint-disable-next-line no-unused-vars
          const email = x.email;

          // We need to determine if we need to store displayname or email here, or just remove it
          this.employeeVpService
            .getEmpByToken()
            // eslint-disable-next-line @typescript-eslint/no-shadow
            .pipe(map((x: any) => x.data))
            .subscribe((profile: any) => {
              if (profile) {
                // NOTE: originally we got a LegacyReturnObject with Auth0UserId extended, so we used: let auth0UserId = data.Auth0UserId;
                profile.auth0UserId = auth0UserId;

                // NOTE: place to change HRRef in console: data.Data.HRRef = 213149
                localStorage.setItem('profile', JSON.stringify(profile));
                localStorage.setItem('WorkPhone', profile['WorkPhone']);

                /*
                  UniversalAuthBase.profile
                  self.profile = data.Data;
                  if (self.profile) self.profile.Auth0UserId = auth0UserId;
                  */
              }

              this.onEmployeeLoaded(profile);
            });
        } else {
        }
      });

    this.auth
      .getAccessTokenSilently()
      .pipe(
        takeUntil(this.destroy$),
        tap((x) => localStorage.setItem('id_token', x))
      )
      .subscribe();
  }

  /**
   * Override this method to extend behavior.
   */

  // eslint-disable-next-line no-unused-vars
  onEmployeeLoaded(profile: any) {}
}
