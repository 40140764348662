/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import { BaseModel } from '../base.model';
import { JobSubRequirementModel } from './jobsubrequirement-model';

export class JobRequirementModel extends BaseModel {
  Requirement = '';
  StatusName = '';
  JobId = '';
  SubRequirements: Array<JobSubRequirementModel>;

  constructor(o: any = null) {
    super(o);
    if (o) {
      Object.assign(this, o);
    }
  }
}
