import { Directive } from '@angular/core';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { FileDto } from '@ups/files';
import { IAddressChangeRequest, UserActions, UserState } from '@ups/user';
import {
  BaseComponent,
  ProgressService,
  RequestStatusEnum,
  IDynamicModel,
  WindowService,
} from '@ups/xplat/core';
import {
  AppConstants,
  deepMerge,
  getAddressCityStateZip,
} from '@ups/xplat/utils';
import { IEmployeeInfo, IAddress } from '@ups/xplat/api/dto';
import { EmployeeService } from '../services/employee.service';

/* eslint-disable */
@Directive()
export abstract class ProfilePersonalInfoBaseComponent extends BaseComponent {
  pendingAddress: IAddressChangeRequest;
  pendingAddressCityStateZip: string;
  showPendingAddress = false;
  attachments: UserState.Attachments;
  emergencyContacts: any[];
  selectedContacts: any[] = [];
  user: IEmployeeInfo;
  statusEnum = RequestStatusEnum;

  addressFields: IDynamicModel[] = [
    {
      label: 'Line 1',
      formControlName: 'Line1',
      required: true,
      type: 'text',
      options: {
        maxlength: 150,
      },
    },
    {
      label: 'Line 2',
      formControlName: 'Line2',
      required: false,
      type: 'text',
      options: {
        maxlength: 30,
      },
    },
    {
      formControlName: 'City',
      required: true,
      type: 'text',
    },
    {
      formControlName: 'State',
      required: true,
      type: 'text',
    },
    {
      formControlName: 'Zip',
      required: true,
      type: 'text',
    },
    {
      formControlName: 'Country',
      required: true,
      type: 'text',
    },
  ];

  mailingAddressFields: IDynamicModel[] = [
    {
      label:
        'Changes to your Mailing Address does not change your Per Diem eligibility requirements.',
      formControlName: 'verbiage-label',
      required: false,
      type: 'label',
    },
    ...this.addressFields,
  ];

  physicalAddressFields: IDynamicModel[] = [
    {
      label:
        'Changes to your Physical Address will affect your Per Diem eligibility requirements, and will necessitate prior approval.',
      formControlName: 'verbiage-label',
      required: false,
      type: 'label',
    },
    {
      label: 'Search for your address',
      formControlName: 'Address',
      required: false,
      type: 'addressautocomplete',
      ignoreValue: true,
    },
    ...this.addressFields,
    {
      label: 'Effective Date',
      formControlName: 'RequestDate',
      required: true,
      type: 'date',
      options: {
        minDate: new Date(),
      },
    },
    {
      label: 'Proof of Address',
      formControlName: 'Attachments',
      required: true,
      type: 'file',
      options: {
        directory: 'addressChangeRequest',
        fileAllowMultiple: true,
      },
    },
    {
      label:
        'I acknowledge this is just a request, and will not be permanent until approved.',
      formControlName: 'acknowledgement',
      required: true,
      type: 'checkbox',
    },
  ];

  eContactFields: IDynamicModel[] = [
    {
      label: 'Contact',
      formControlName: 'Contact',
      required: true,
      type: 'text',
    },
    {
      label: 'Relationship',
      formControlName: 'Relationship',
      required: false,
      type: 'text',
    },
    {
      label: 'Home Phone',
      formControlName: 'HomePhone',
      required: true,
      type: 'phone',
    },
    {
      label: 'Cell Phone',
      formControlName: 'CellPhone',
      required: false,
      type: 'phone',
    },
  ];

  contactFields: IDynamicModel[] = [
    {
      label: 'Nickname',
      formControlName: 'NickName',
      required: false,
      type: 'text',
    },
    {
      label: 'Primary Phone',
      formControlName: 'Phone',
      required: true,
      type: 'phone',
    },
    {
      label: 'Cell Phone',
      formControlName: 'CellPhone',
      required: false,
      type: 'phone',
    },
    {
      label: 'Alt Phone',
      formControlName: 'AltContactPhone',
      required: false,
      type: 'phone',
    },
    {
      label: 'Email',
      formControlName: 'Email',
      required: true,
      type: 'email',
      regex: new RegExp(AppConstants.regex.email),
    },
    {
      label: 'Work Email',
      formControlName: 'udUPSEmail',
      required: false,
      type: 'email',
      regex: new RegExp(AppConstants.regex.emailOrEmpty),
    },
  ];

  constructor(
    protected store: Store,
    protected actions$: Actions,
    protected progress: ProgressService,
    public employeeService: EmployeeService,
    protected win: WindowService
  ) {
    super();
  }

  ngOnInit() {
    this.store
      .pipe(UserState.selectPendingPhysicalAddressTruthy(this.destroy$))
      .subscribe((pending: IAddressChangeRequest) => {
        if (pending.RequestStatusId !== RequestStatusEnum.Cancelled) {
          this.pendingAddress = pending;
          this.pendingAddressCityStateZip = getAddressCityStateZip(pending);
        } else {
          this.pendingAddress = null;
          this.pendingAddressCityStateZip = null;
        }
      });
    this.actions$
      .pipe(ofType(UserActions.updateFailed), takeUntil(this.destroy$))
      .subscribe((err) => {
        // error handling here
        this.progress.toggleSpinner(false);
      });
    this.store
      .pipe(UserState.selectCurrentTruthy(this.destroy$))
      .subscribe((x) => {
        this.user = x;
        this.store.dispatch(
          UserActions.loadAttachments({ hRRef: x.Data.HRRef })
        );
        this.store.dispatch(UserActions.loadEContacts({ hRRef: x.Data.HRRef }));
        this.store.dispatch(
          UserActions.checkAddressChangeRequest({
            hRRef: x.Data.HRRef,
          })
        );
      });

    this.store
      .pipe(UserState.selectAttachmentsTruthy(this.destroy$))
      .subscribe((x) => (this.attachments = x));

    this.store
      .pipe(UserState.selectEContactsTruthy(this.destroy$))
      .subscribe((x) => {
        this.progress.toggleSpinner(false);
        if (x) {
          this.emergencyContacts = x.map((y) => ({
            ...y,
            selected: false,
          }));
        }
        this.selectedContacts = [];
      });
  }

  updateSelection(contact: any, selected: boolean) {
    if (selected) {
      this.selectedContacts.push(contact);
    } else {
      this.selectedContacts = this.selectedContacts.filter(
        (x) => x !== contact
      );
    }
  }

  submitPhysicalAddressChange(
    model: IAddress & { Attachments?: FileDto[]; RequestDate?: Date }
  ) {
    if (model.Attachments?.length <= 0) {
      this.win.reset();
      this.win
        .confirm({
          title: 'No Attachment',
          message: 'Proof of Attachment is required',
          okButtonText: `OK`,
        })
        .then(
          (ok) => {
            if (ok) {
            }
          },
          () => {}
        );

      return;
    }

    this.progress.toggleSpinner(true);
    const addressChangeRequest: IAddressChangeRequest = {
      hrRef: this.user.Data.HRRef,
      address: model.Line1,
      address2: model.Line2,
      addressChangeRequestId: this.pendingAddress?.addressChangeRequestId,
      city: model.City,
      country: model.Country,
      state: model.State,
      zip: model.Zip,
      attachments: model.Attachments,
      requestDate: model.RequestDate,
      requestStatusId: RequestStatusEnum.Requested,
    };

    this.store.dispatch(
      UserActions.submitAddressChangeRequest(addressChangeRequest)
    );
  }

  cancelAddressChangeRequest() {
    this.store.dispatch(
      UserActions.submitAddressChangeRequest({
        ...this.pendingAddress,
        requestStatusId: RequestStatusEnum.Cancelled,
      })
    );
  }

  saveMailingAddress(model) {
    console.log(this.user.Data, model);
    this.store.dispatch(
      UserActions.update({
        data: deepMerge(this.user.Data, { MailingAddress: model }),
      })
    );
  }

  saveContactInfo(model) {
    console.log(this.user.Data, model);
    this.store.dispatch(
      UserActions.update({ data: deepMerge(this.user.Data, model) })
    );
  }

  saveUpdatedContact(contact) {
    if (contact && !contact.HRRef) {
      contact.HRRef = this.employeeService.myInfo.Data.HRRef;
    }
    this.store.dispatch(UserActions.updateEContact({ contact }));
  }

  getCancelAddressMessage() {
    return `Do you really want to remove your address change request?`;
  }

  getConfirmDeleteSelectedMessage() {
    return `Do you want to delete ${this.selectedContacts.length} emergency contact(s)?`;
  }

  deleteSelectedContacts() {
    this.progress.toggleSpinner(true);
    this.selectedContacts.forEach((contact) => {
      contact.IsToDelete = true;
      this.store.dispatch(UserActions.updateEContact({ contact }));
    });
  }
}
/* eslint-enable */
