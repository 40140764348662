import { BaseModel } from '../base.model';
import { FileDto } from '../models-sparta';

// In case you need to extend the behavior (e.g. add custom properties) extend the class by creating a new class that derives from this class.
// REMARK: As the DTO properties in API Backend are Pascal Cased (Upper Camel Cases) but properties of DTO in TypeScript are lower camel cases use the parseDataToLowerCamelCased() to parse the incoming data on client side:
// Example: return Observable<AddressChangeRequestDto []>this.http.get(url).map(response => this.parseDataToLowerCamelCased(response));
export class AddressChangeRequestDto extends BaseModel {
  public addressChangeRequestId: string;
  public hrRef: number;
  public address: string;
  public address2: string;
  public city: string;
  public state: string;
  public zip: string;
  public requestDate: Date;
  public requestStatusId: number;
  public reviewedById: number;
  public reviewComment: string;
  /**
   * @deprecated Use attachments property instead as since US-6524 we use multiple attachments now for address change request
   */
  public fileId: string;
  /**
   * @deprecated Use attachments property instead as since US-6524 we use multiple attachments now for address change request
   */
  public fileName: string;
  public requestStatusName: string;
  public country: string;
  public attachments: FileDto[];

  constructor(o: any = null) {
    super(o);
  }
}
