import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Day } from '@progress/kendo-date-math';
import {
  DatePickerComponent,
  FormatSettings,
} from '@progress/kendo-angular-dateinputs';
import { IntlService } from '@progress/kendo-angular-intl';

@Component({
  selector: 'ups-kendo-datepicker-shared',
  templateUrl: './kendo-datepicker-shared.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => KendoDatepickerSharedComponent),
      multi: true,
    },
  ],
})
export class KendoDatepickerSharedComponent implements ControlValueAccessor {
  @Input() value: Date | string;
  @Input() disabled: boolean;
  @Input() disabledDates: ((date: Date) => boolean) | Date[] | Day[];
  @Input() format: string | FormatSettings;
  @Input() placeholder: string;
  @Input() min: Date;
  @Input() max: Date;
  @Input() readOnlyInput: boolean;
  @Input() displayFormat = 'MM/dd/yyyy';

  @Input() isFullWidth: boolean;
  @Input() dateToString: boolean;

  @Output() valueChange: EventEmitter<Date | string> = new EventEmitter();

  @ViewChild('kendoDatePicker') datePicker: DatePickerComponent;

  onTouched: () => void;
  private onChange: (value: Date | string) => void;

  constructor(private intl: IntlService) {}

  registerOnChange(fn: (value: Date) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: Date): void {
    this.value = value;
  }

  onDateChange(value: Date): void {
    let outputValue: string | Date = null;
    if (value) {
      if (value.getFullYear() < 100) {
        value = new Date(
          value.getFullYear() + 2000,
          value.getMonth(),
          value.getDate()
        );
      }

      this.value = value;
      outputValue = this.dateToString
        ? this.intl.formatDate(value, 'MM/dd/yyyy')
        : value;
    }

    this.valueChange.emit(outputValue);
    if (this.onChange) {
      this.onChange(outputValue);
    }
  }
}
