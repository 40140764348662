import { Input, Directive } from '@angular/core';
import {
  BaseComponent,
  dynamicTypesUnsupportedOffline,
  IDynamicModelProperties,
} from '@ups/xplat/core';
import { takeUntil } from 'rxjs';
import { DynamicRenderService } from '../../../services/dynamic-render.service';
import { DynamicEventBusTypes } from '../../../utils';

@Directive()
export abstract class DynamicHelperLabelBaseComponent extends BaseComponent {
  @Input()
  config: IDynamicModelProperties;
  @Input()
  dynamicService: DynamicRenderService;
  private _origInstruction = '0';
  private _wasOffline: boolean;
  private _listeningToNetwork: boolean;

  ngOnInit() {
    if (this.config) {
      if (
        !this.dynamicService.activeFormResponse &&
        !this.dynamicService.win.navigator.onLine
      ) {
        // already offline, include note
        this._addOfflineNote();
      }
      if (dynamicTypesUnsupportedOffline.includes(this.config.type)) {
        // listen for network changes to adjust note as state changes
        this._updateNoteWhenNetworkChanges();
      }
    }
  }

  removeItem() {
    let name: string;
    let index = 0;
    if (this.config.formControlName.startsWith('buttongroup')) {
      name = 'buttongroup';
      index =
        parseInt(
          this.config.formControlName.replace('buttongroupLabel', ''),
          10
        ) - 1;
    } else if (this.config.formControlName.startsWith('updateOnValueChange')) {
      name = 'updateOnValueChange';
      const nameParts = this.config.formControlName.split('-');
      if (nameParts.length === 3) {
        // get index off the end of the formControlName
        index = +nameParts.slice(-1)[0];
      }
    }
    this.dynamicService.eventBus.emit(
      DynamicEventBusTypes.dynamicRemoveItemFromOptions,
      {
        config: this.config,
        name,
        index,
      }
    );
  }

  private _addOfflineNote() {
    /**
     * Some types are not available for use while offline.
     * This will add a note in the instruction to make clear.
     */
    if (this._origInstruction === '0') {
      this._origInstruction = this.config.instruction;
    }
    switch (this.config.type) {
      case 'file':
        const offlineNote = `Note: You can only attach files while online.`;
        if (
          !this.config.instruction ||
          this.config.instruction?.indexOf(offlineNote) === -1
        ) {
          this.config.instruction =
            (this.config.instruction ? `${this.config.instruction} ` : '') +
            offlineNote;
        }
        this._updateNoteWhenNetworkChanges();
        break;
    }
  }

  private _updateNoteWhenNetworkChanges() {
    if (!this._listeningToNetwork) {
      this._listeningToNetwork = true;
      this.dynamicService.network.offline$
        .pipe(takeUntil(this.destroy$))
        .subscribe((offline) => {
          if (this.config) {
            if (offline) {
              this._addOfflineNote();
            } else if (
              this._wasOffline &&
              !offline &&
              this._origInstruction !== '0'
            ) {
              // update instruction back to original
              this.config.instruction = this._origInstruction;
            }
          }
          this._wasOffline = offline;
        });
    }
  }
}
