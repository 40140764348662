import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { MyHttpClientFactory, environment } from '@ups/xplat/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FitDataItem } from '../models/fit/fit-data-item';

const fitDataByHRRef = (hrRef) => `api/hrrs/fit/${hrRef}`;

@Injectable({
  providedIn: 'root',
})
export class EmployeeFitService {
  private http: HttpClient;

  constructor(httpClientFactory: MyHttpClientFactory) {
    this.http = httpClientFactory.createHttpClient(
      environment.urls.viewpointAPI,
      true
    );
  }

  getFitDataByHRRef(hrRef: number): Observable<any[]> {
    return this.http.get<FitDataItem[]>(fitDataByHRRef(hrRef)).pipe(
      map((ret) =>
        ret.map((x) => ({
          ...x,
          subgridData: [
            {
              TypeOfEquipment: 'Primary',
              full: {
                MFG: x.udFFMfg,
                Model: x.udFFModel,
                Size: x.udFFSize,
              },
              half: {
                MFG: x.udHMMfg,
                Model: x.udHMModel,
                Size: x.udHMSize,
              },
            },
            {
              TypeOfEquipment: 'Alternate',
              full: {
                MFG: x.udFFMfg2,
                Model: x.udFFModel2,
                Size: x.udFFSize2,
              },
              half: {
                MFG: x.udHMMfg2,
                Model: x.udHMModel2,
                Size: x.udHMSize2,
              },
            },
            {
              TypeOfEquipment: 'Alternate 2',
              full: {
                MFG: x.udFFMfg3,
                Model: x.udFFModel3,
                Size: x.udFFSize3,
              },
              half: {
                MFG: x.udHMMfg3,
                Model: x.udHMModel3,
                Size: x.udHMSize3,
              },
            },
          ],
        }))
      )
    );
  }
}
