import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { DrAdminService } from '../../../services/dr-admin.service';

@Component({
  selector: 'ups-dr-admin-list',
  templateUrl: 'dynamic-render-admin-list.component.html',
  styleUrls: ['dynamic-render-admin-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicRenderAdminListComponent implements OnInit {
  public summaries: Array<any> = [];

  constructor(private adminService: DrAdminService) {}

  public ngOnInit() {
    // this.adminService.getContainerSummaries().subscribe((summaries: IDynamicContainerSummary[]) => {
    //   this.summaries = summaries;
    // });
  }

  public onDeleteClick(id: string) {
    if (confirm('are you sure?')) {
      // this.adminService.deleteAdminDynamicContainer(id).subscribe((success: boolean) => {
      //   if (success) {
      //     this.summaries = this.summaries.filter((s) => {
      //       return s.id !== id;
      //     });
      //   } else {
      //     alert('the dr container could not be deleted.');
      //   }
      // });
    }
  }
}
