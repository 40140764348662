export function operation<T>(
  list1: T[],
  list2: T[],
  compareProp: string,
  isUnion = false
): T[] {
  return list1.filter(
    (a) => isUnion === list2.some((b) => a[compareProp] === b[compareProp])
  );
}

export function inBoth<T>(list1: T[], list2: T[], compareProp: string): T[] {
  return operation(list1, list2, compareProp, true);
}
export const inFirstOnly = operation;

export function inSecondOnly<T>(list1: T[], list2: T[], compareProp: string) {
  return inFirstOnly(list2, list1, compareProp);
}

export function swapArrayElements(
  arr: Array<any>,
  currentIndex: number,
  nextIndex: number
) {
  var temp = arr[currentIndex];
  arr[currentIndex] = arr[nextIndex];
  arr[nextIndex] = temp;
}

export function uniqueObjectsByFields(
  collection: Array<any>,
  fields?: Array<string>
) {
  const uniqueItems = [];
  const map = new Map();
  for (const item of collection) {
    if (item) {
      let key = '';
      fields.forEach((f) => {
        if (f.indexOf('.') > -1) {
          const nestedProps = f.split('.');
          let nestedValue: any;
          nestedProps.forEach((p) => (nestedValue = (nestedValue || item)[p]));
          key += `-${nestedValue || ''}`;
        } else {
          key += `-${item[f] || ''}`;
        }
      });
      // debug unique key:
      // console.log('uniqueObjectsByFields key:', key);
      if (!map.has(key)) {
        map.set(key, true);
        uniqueItems.push(item);
      }
    }
  }
  return uniqueItems;
}

export function uniqueObjectsBySimpleFields<T>(
  collection: Array<T>,
  fields?: Array<keyof T>
) {
  const uniqueItems = [];
  const set = new Set();
  for (const item of collection) {
    if (item) {
      let key = '';
      fields.forEach((f) => {
        key += `-${item[f] || ''}`;
      });
      // debug unique key:
      // console.log('uniqueObjectsByFields key:', key);
      if (!set.has(key)) {
        set.add(key);
        uniqueItems.push(item);
      }
    }
  }
  return uniqueItems;
}

/**
 * Gets all unique items from a collection using a projection function to get a key.
 * @param collection Array to be unique'd
 * @param project Function to project each item to a key. toString is used to map the return to a string.
 * @returns An Array‹T› containing unique items from collection
 */
export function uniqueObjectsByProjection<T>(
  collection: Array<T>,
  project: (item: T) => any
) {
  const uniqueItems = [];
  const set = new Set();
  for (const item of collection) {
    if (item) {
      const key = project(item).toString();
      if (!set.has(key)) {
        set.add(key);
        uniqueItems.push(item);
      }
    }
  }
  return uniqueItems;
}

export function uniqueArray(collection: Array<string | number>) {
  // only works for primitive values in collection (string, number, etc)
  // if using objects use above method
  return [...new Set(collection)];
}

/**
 * Sort collection by a date property in descending order
 * @param sortBy object property/field containing date string to sort by
 */
export function sortByDate(sortBy: string, ascending?: boolean) {
  return function (a: any, b: any) {
    if (!a[sortBy]) {
      // console.log(`sortByDate: '${sortBy}' is falsey.`);
      return -1;
    }
    // console.log(`sortByDate: '${sortBy}' is ${a[sortBy]}`);
    const dateA = new Date(a[sortBy]);
    const dateB = new Date(b[sortBy]);
    if (ascending) {
      return dateB < dateA ? 1 : -1;
    } else {
      return dateA > dateB ? -1 : 1;
    }
  };
}

/**
 * Example usage: array.sort(sortByProperty('Name'))
 * Sort collection by specified property in descending order
 * @param sortBy object property/field to sort by
 */
export function sortByProperty(sortBy: string, ascending?: boolean) {
  return function (a: any, b: any) {
    if (a[sortBy] === null || a[sortBy] === undefined) {
      console.log(`sortByProperty: '${sortBy}' is null or undefined.`);
      return -1;
    }
    // console.log(`sortByProperty: '${sortBy}' is ${a[sortBy]}`);
    if (ascending) {
      return b[sortBy] < a[sortBy] ? 1 : -1;
    } else {
      return a[sortBy] > b[sortBy] ? -1 : 1;
    }
  };
}

/**
 * Meant to be used with multi-column-search directive
 * @param query the query object
 * @param dataToFilter the collection to filter
 */
export function mscFilterResults(
  query: any,
  dataToFilter
): { filterValue?: string; matches?: Array<any> } {
  console.log('query:', query);
  const filterValue =
    query.value && typeof query.value === 'string'
      ? query.value.toLowerCase()
      : query.value;
  return {
    filterValue,
    // first filter always filters first the entire set
    // additional filters apply to remaining results
    matches: dataToFilter.filter((i) => {
      if (query.operator === 'contains' && filterValue) {
        let fieldValue = i[query.field];
        if (typeof fieldValue !== 'string') {
          fieldValue = `${fieldValue}`;
        }
        return fieldValue.toLowerCase().indexOf(filterValue) > -1;
      } else if (query.operator === 'equals') {
        if (typeof i[query.field] === 'string') {
          return i[query.field].toLowerCase() === filterValue;
        } else {
          return i[query.field] === filterValue;
        }
      } else if (query.operator === 'isNotNull') {
        return !!i[query.field];
      } else if (query.operator === 'isNull') {
        return !i[query.field];
      }
      return false;
    }),
  };
}

/**
 * Filter an array into two separate arrays
 * @param array Array to be filtered
 * @param callback Function to filter by
 * @returns [[], []] where the first [] is true
 */
export function partition<T>(
  array: T[],
  callback: (el: T, idx?: number, array?: T[]) => boolean
): [T[], T[]] {
  return array.reduce(
    function (result, element, i) {
      callback(element, i, array)
        ? result[0].push(element)
        : result[1].push(element);

      return result;
    },
    [[], []]
  );
}

/**
 * Pick N random items from an array
 * @param array Array to pick items from
 * @param n Number of items to pick
 * @returns New Array‹T›
 */
export function sample<T>(array: T[], n: number): T[] {
  const results: T[] = [];
  const copy = [...array];
  while (results.length < n) {
    let idx = Math.floor(Math.random() * copy.length);
    results.push(copy[idx]);
    copy.splice(idx, 1);
  }
  return results;
}
