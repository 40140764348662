import { BaseModel } from '../base.model';
import { EmployeeDesignationDto } from './EmployeeDesignationDto';
import { RequestedClassWithCountDto } from './RequestedClassWithCountDto';

export class EmployeeAroundPlantCriteriaDto extends BaseModel {
  public employeeDesignations: EmployeeDesignationDto[];
  public jobClassifications: RequestedClassWithCountDto[];
  public classNames: string[];
  public plantId: number;
  public jobId: string;
  public vpCompanyId: number;
  public plantName: string;
  public plantLng: number;
  public plantLat: number;

  constructor(o: any = null) {
    super(o);
  }
}
