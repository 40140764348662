import { HttpRequest } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { FeatureSwitch } from '@ups/xplat/utils';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  timer,
} from 'rxjs';
import { scan, takeWhile } from 'rxjs/operators';
import { LogService } from './log.service';

/**
 * This provides common network handling
 * Each platform web/mobile can inject this and use to help facilitate network handling
 */
@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  // enforce certain limitations on offline behavior
  // 20 post/put actions can be made offline only to avoid long offline usage that is wildly out of sync or not up to date
  offlineActionLimit = FeatureSwitch.offlineActionLimit;
  // offline activity can be limited to certain amount of time
  private _offlineTimeLimit = FeatureSwitch.offlineTimeLimit;
  // if time limit expires or offlineActionLimit post/put actions are taken the user will be in a more restrictive offline state
  private _offlineTimer$ = timer(0, 1000).pipe(
    scan((acc) => acc - 1000, this._offlineTimeLimit),
    takeWhile((x) => x >= 0)
  );
  private _offlineMessage = 'You are offline.';
  private _offlineHttpSub: {
    [url: string]: Subscription;
  } = {};
  private _offlineCountdownComplete$: Subject<boolean>;
  private _notifiedLimit: boolean;
  private _offline$: Subject<boolean> = new Subject();
  private _offlineTrackSaveOrUpdate$: Subject<HttpRequest<unknown>>;
  private _isOffline = false;
  private _showOfflinePage$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private _showOfflineNotice$: Subject<boolean> = new Subject();
  private _retryConnection$: Subject<boolean> = new Subject();
  // helper for http requests which need to help insternal browser http caching
  private _useHttpCacheControlHeaders = false;

  constructor(private log: LogService, private ngZone: NgZone) {}

  get offline$() {
    return this._offline$;
  }

  set offline(value: boolean) {
    this._isOffline = value;
    this.ngZone.run(() => {
      this._offline$.next(value);
    });
    if (!value) {
      // when coming back online, reset any offline limits for next time they go offline
      this.notifiedLimit = undefined;
    }
    console.log(`Client is ${value ? 'OFFLINE' : 'Online'}.`);
  }

  get offlineTimer$() {
    return this._offlineTimer$;
  }

  get offlineCountdownComplete$() {
    if (!this._offlineCountdownComplete$) {
      this._offlineCountdownComplete$ = new Subject();
    }
    return this._offlineCountdownComplete$;
  }

  get notifiedLimit() {
    return this._notifiedLimit;
  }

  set notifiedLimit(value: boolean) {
    this._notifiedLimit = value;
    this._resetOfflineLimits();
  }

  /**
   * For tracking PUT or POST requests made while offline
   */

  get offlineTrackSaveOrUpdate$() {
    if (this._offlineTrackSaveOrUpdate$) {
      this._offlineTrackSaveOrUpdate$ = new Subject();
    }
    return this._offlineTrackSaveOrUpdate$;
  }

  get isOffline() {
    return this._isOffline;
  }

  /**
   * Platforms can observe this to implement their own reaction flows for when
   * the error of an api call is related to user losing connection and going offline
   */
  get showOfflineNotice$() {
    return this._showOfflineNotice$;
  }

  get showOfflinePage$() {
    return this._showOfflinePage$;
  }

  /**
   * Platforms can observe this for when users attempt to retry the connection
   * Each platform should implement it's own specific connection retry logic
   */
  get retryConnection$() {
    return this._retryConnection$;
  }

  get useHttpCacheControlHeaders() {
    return this._useHttpCacheControlHeaders;
  }

  /**
   * http cache control headers
   */
  set useHttpCacheControlHeaders(value: boolean) {
    this._useHttpCacheControlHeaders = value;
  }

  set showOfflineNotice(value: boolean) {
    this.offline = true; // ensure flag is set here
    this._showOfflineNotice$.next(value);
  }

  set showOfflinePage(value: boolean) {
    this._showOfflinePage$.next(value);
  }

  /**
   * Components can use this to trigger platform specific network connections retries
   */
  retry() {
    this._retryConnection$.next(true);
  }

  /**
   * Http request
   * @param response the http response
   */
  offlineHttpCancel$(url: string): Observable<boolean> {
    return new Observable((observer) => {
      if (this._isOffline) {
        this.log.debug(`offlineHttpCancel$ this._isOffline:`, this._isOffline);
        // go ahead and cancel, client is already offline, not able to make request anyway
        observer.error(this._offlineMessage);
      } else {
        // this tracks individual offline handlers per request by it's url
        // prevent double request tracking if one had already been queued up
        this._clearSub(url);
        this._offlineHttpSub[url] = this.offline$.subscribe(
          (isOffline: boolean) => {
            // if a request is made and client goes offline during the request
            if (isOffline) {
              this.log.debug(
                `this.offline$.subscribe fired, manually tearing down http request, isOffline:`,
                isOffline
              );
              this._clearSub(url);
              // cancel in-flight request
              observer.error(this._offlineMessage);
            }
          }
        );
      }
    });
  }

  cleanup(url: string, resetAll?: boolean) {
    if (this._offlineHttpSub) {
      if (resetAll) {
        /* eslint-disable */
        for (const key in this._offlineHttpSub) {
          this._clearSub(key);
        }
        /* eslint-enable */
      } else {
        this._clearSub(url);
      }
    }
  }

  private _clearSub(url: string) {
    if (this._offlineHttpSub && this._offlineHttpSub[url]) {
      this._offlineHttpSub[url].unsubscribe();
      delete this._offlineHttpSub[url]; // remove entry
    }
  }

  private _resetOfflineLimits() {
    // reset limit notice when working on queue
    if (this._offlineCountdownComplete$) {
      this._offlineCountdownComplete$.next(true);
      this._offlineCountdownComplete$.complete();
      this._offlineCountdownComplete$ = null;
    }
  }
}
