import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { EquipmentSubRequirementDto } from '@ups/xplat/api/dto';

@Component({
  selector: 'ups-equipment-subtask-editor',
  templateUrl: './equipment-subtask-editor.component.html',
})
export class EquipmentSubtaskEditorComponent implements OnInit {
  @Output() editSubtask: EventEmitter<EquipmentSubRequirementDto> =
    new EventEmitter<EquipmentSubRequirementDto>();

  isEdit: boolean;
  model: EquipmentSubRequirementDto;
  opened: boolean;

  constructor() {}

  ngOnInit() {
    this.isEdit = false;
    this.model = new EquipmentSubRequirementDto();
    this.opened = false;
  }

  public open(model: EquipmentSubRequirementDto) {
    if (model) {
      this.model = JSON.parse(JSON.stringify(model));
      this.isEdit = true;
    } else {
      this.model = new EquipmentSubRequirementDto();
      this.model.bActive = true;
      this.isEdit = false;
    }
    this.opened = true;
  }

  public close() {
    this.opened = false;
  }

  isValid() {
    if (this.model.Name && this.model.Name.trim().length !== 0) return true;
    return false;
  }

  onAdd() {
    this.editSubtask.emit(this.model);
    this.opened = false;
  }
}
