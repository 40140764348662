import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { EquipmentTypeDto } from '@ups/xplat/api/dto';
import { EquipmentPlantJobRequirementDto } from '@ups/xplat/api/dto';
import { EquipmentRequirementDto } from '@ups/xplat/api/dto';
import { EquipmentService } from '@ups/xplat/api/services';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { Guid } from '@ups/xplat/core';

@Component({
  selector: 'ups-equipment-requirements-editor',
  templateUrl: './equipment-requirements-editor.component.html',
})
export class EquipmentRequirementsEditorComponent implements OnInit {
  @ViewChild('panelbar') panelbar;
  @ViewChild('allEquipmentRequirementsDropdown')
  public allEquipmentRequirementsDropdown: DropDownListComponent;

  /** Not used as of #2057 as we map Equipment Requirements to job or plant and not to equipment type
   * The equipmentType code can be removed.
   * But we keep the code here in case we need to implement blacklisting of requirements
   * for certain equipment type.
   */
  @Input() equipmentType: EquipmentTypeDto;
  @Input() id?: string = '';
  @Input() isReadOnly = true;
  @Input() showHeader = true;
  @Input() showInactive = true;
  @Input() showSubtask = true;
  @Input() title = 'Requirements';
  @Input() set requirements(value: EquipmentPlantJobRequirementDto[]) {
    setTimeout(() => {
      this.equipmentRequirements = value;

      if (this.equipmentType) {
        this.equipmentRequirements.forEach(
          (er) =>
            (er.EquipmentPlantJobRequirementID = er.EquipmentJobRequirementID
              ? er.EquipmentJobRequirementID
              : er.EquipmentPlantRequirementID)
        );
        this.equipmentType.Requirements.forEach((req) => {
          const foundRequirements = this.equipmentRequirements.find(
            (e) =>
              e.EquipmentRequirementID === req.EquipmentRequirementID &&
              e.EquipmentTypeID === this.equipmentType.EquipmentTypeID
          );
          if (!foundRequirements) {
            this.equipmentRequirements.unshift(
              new EquipmentPlantJobRequirementDto({
                bActive: false,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                EquipmentRequirementID: req.EquipmentRequirementID,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                EquipmentTypeID: this.equipmentType.EquipmentTypeID,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                EquipmentRequirementName: req.RequirementName,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                EquipmentPlantJobRequirementID: Guid.newGuid(),
                // eslint-disable-next-line @typescript-eslint/naming-convention
                SubRequirements: [],
              })
            );
          }
        }, 500);
      }
    });
  }
  get requirements() {
    return this.equipmentRequirements;
  }

  background: string;
  equipmentRequirements: EquipmentPlantJobRequirementDto[];
  expandedItem: string;
  selectedRequirement:
    | EquipmentPlantJobRequirementDto
    | EquipmentRequirementDto;

  constructor(private equipmentService: EquipmentService) {}

  ngOnInit(): void {
    this.background = '';
    this.equipmentRequirements = [];
    this.expandedItem = '';
  }

  addEquipmentRequirement() {
    if (this.equipmentType) {
      this.selectedRequirement.bActive = true;
      this.selectedRequirement = null;
    } else {
      const foundReq = this.equipmentRequirements.find(
        (r) =>
          r.EquipmentRequirementID ===
          this.selectedRequirement.EquipmentRequirementID
      );
      if (foundReq) {
        foundReq.bActive = true;
      } else {
        this.equipmentRequirements.push({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          EquipmentPlantJobRequirementID: Guid.newGuid(),
          // eslint-disable-next-line @typescript-eslint/naming-convention
          EquipmentRequirementID:
            this.selectedRequirement.EquipmentRequirementID,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          EquipmentPlantRequirementID: null,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          EquipmentJobRequirementID: null,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          EquipmentTypeID: null,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          SubRequirements: [],
          bActive: true,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          EquipmentRequirementName: (
            this.selectedRequirement as EquipmentRequirementDto
          ).Name,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          WaiverDays: 0,
          bBillable: false,
          bRequired: false,
          bWaiver: false,
        });
      }

      this.selectedRequirement = null;
      if (this.allEquipmentRequirementsDropdown) {
        this.allEquipmentRequirementsDropdown.data = [];
      }
    }
  }

  inactiveRequirements() {
    return this.showInactive
      ? this.equipmentRequirements
      : this.equipmentRequirements.filter(
          (r) =>
            r.bActive === false &&
            r.EquipmentTypeID ===
              (!this.equipmentType ? null : this.equipmentType.EquipmentTypeID)
        );
  }

  visibleRequirements(): Array<EquipmentPlantJobRequirementDto> {
    return this.showInactive
      ? this.equipmentRequirements
      : this.equipmentRequirements.filter(
          (r) =>
            r.bActive === true &&
            r.EquipmentTypeID ===
              (!this.equipmentType ? null : this.equipmentType.EquipmentTypeID)
        );
  }

  onBackgroundClick(background: EquipmentPlantJobRequirementDto) {
    this.equipmentRequirements.forEach((r) => {
      if (
        r.EquipmentRequirementID === background.EquipmentRequirementID &&
        r.EquipmentTypeID === background.EquipmentTypeID
      ) {
        r.bActive = true;
      }
    });
    this.background = background.EquipmentRequirementName;
  }

  onClick(event) {
    if (event.target && event.target.id.toString().indexOf('check') !== -1) {
      return true;
    }

    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  onStateChange() {
    this.panelbar.childrenItems.forEach((item) => {
      if (item.expanded && item.title !== this.expandedItem) {
        item.expanded = false;
        item.selected = false;
      }
      if (item.title === this.expandedItem) {
        item.expanded = true;
        item.selected = true;
      }
    });
  }

  rowCallback(context: RowClassArgs) {
    return {
      red: context.dataItem.bActive === false,
    };
  }

  requirementsFilterChanged(event) {
    const oDataQuery = event.extension.toODataQuery(event.query);
    this.equipmentService
      .queryEquipmentRequirementData(null, oDataQuery)
      .subscribe(
        (oData) => {
          const requirementsExceptAlreadyAssigned = oData.value.filter(
            (oDataItem) =>
              !this.visibleRequirements().some(
                (visibleReq) =>
                  visibleReq.EquipmentRequirementID ===
                  oDataItem['EquipmentRequirementID']
              )
          );
          event.extension.applyData(requirementsExceptAlreadyAssigned);
        },
        (error) => {
          event.extension.applyData([]);
          throw error;
        }
      );
  }

  toggleExpand(title: string) {
    this.expandedItem = this.expandedItem !== title ? title : '';
    this.panelbar.stateChange.next([
      { title: title, expanded: true, selected: true },
    ]);
  }
}
