<button
  kendoButton
  kendoTooltip
  title="Shortcut Keys Menu"
  (click)="openHotkeysMenu()"
  aria-label="Shortcut Keys Menu"
>
  <i class="fas fa-keyboard"></i>
</button>
<kendo-dialog
  *ngIf="isHotkeysMenuOpened"
  (close)="closeHotkeysMenu()"
  [minWidth]="250"
  [width]="600"
>
  <kendo-dialog-titlebar help-anchor="shortcutKeys__Titlebar">
    <span>Shortcut Keys Menu</span>
  </kendo-dialog-titlebar>

  <p>Ensure there is NO focus on an input when using shortcuts.</p>
  <table class="table">
    <thead>
      <tr>
        <th>Key</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let option of optionsShortcutsArr">
        <th scope="row">
          Press '{{ option.key }}' or '{{ option.key.toUpperCase() }}'
        </th>
        <td>{{ option.description }}</td>
      </tr>
      <tr>
        <th scope="row">Press 's' or 'S'</th>
        <td>Opens this "Shortcut Keys Menu"</td>
      </tr>
    </tbody>
  </table>
</kendo-dialog>
