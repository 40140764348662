import { BaseModel } from '../base.model';

export class DispatchGroupDto extends BaseModel {
  public DispatchGroupID: number;
  public Name: string;
  public IsFinalStatus: boolean;
  public Position: number;
  public bActive: boolean;
  public bShowInAttemptReport: boolean;

  constructor(o: any = null) {
    super(o);
  }
}
