import { Directive, inject, Input, OnInit } from '@angular/core';

import { GridDataResult } from '@progress/kendo-angular-grid';

import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { AttachmentVpService } from '@ups/xplat/api/services';
import { BaseComponent } from '@ups/xplat/core';
import { downloadFileFromBase64 } from '@ups/xplat/web/utils';
import { EmployeePayStubsService } from '../services/employee-pay-stubs.service';
import { EmployeeService } from '../services/employee.service';
import { IEmployeeInfo } from '@ups/xplat/api/dto';
import { IODataResult } from '@ups/xplat/api/dto';
import { PayStubDto, PayStubInfo } from '../models/pay-stubs';
import { UserState } from '@ups/user';

@Directive()
export abstract class ProfilePayDataBaseComponent
  extends BaseComponent
  implements OnInit
{
  @Input() hrRef: number | null;
  store = inject(Store);
  attachmentService = inject(AttachmentVpService);
  public text = 'ProfilePayData';
  isLoading = false;

  profile: IEmployeeInfo;

  public view: GridDataResult;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  viewModel: any; // TODO: refacor 'GroupingExViewModel' out of common into xplat/api/dto;

  constructor(
    protected payStubsService: EmployeePayStubsService,
    protected employeeService: EmployeeService
  ) {
    super();
    this.isLoading = true;

    // HrRef can be passed as input variable (in resource-allocation project) or thru store for currently logged in user - in my employee portal
    if (this.hrRef || this.hrRef?.toString() === '0') {
      this.loadData(this.hrRef);
    } else {
      this.store
        .pipe(UserState.selectCurrentTruthy(this.destroy$))
        .subscribe((x) => {
          this.profile = x;
          this.loadData(x.Data.HRRef);
        });
    }
  }

  ngOnInit() {
    this.isLoading = true;
    if (this.hrRef || this.hrRef?.toString() === '0') {
      this.loadData(this.hrRef);
    } else {
      this.store
        .pipe(UserState.selectCurrentTruthy(this.destroy$))
        .subscribe((x) => {
          this.profile = x;
          this.loadData(x.Data.HRRef);
        });
    }
  }

  loadData(hrRef: number) {
    this.payStubsService
      .fetchEmployeePayStubs(hrRef, '')
      .pipe(takeUntil(this.destroy$))
      .subscribe((r: IODataResult<PayStubDto>) => {
        this.data = {
          payStubs: r.value,
        } as PayStubInfo;
        this.isLoading = false;
      });
  }

  downloadFile(dataItem: PayStubDto) {
    dataItem.isDownloading = true;
    if (dataItem.AttachmentId) {
      this.attachmentService
        .getFileById2(dataItem.AttachmentId)
        .then((data) => {
          downloadFileFromBase64(data);
          dataItem.isDownloading = false;
        });
    }
  }

  getFileName(p: PayStubDto): string {
    return `PayStubWeek_${this.dateToString(p.PrStartDate)}_${this.dateToString(
      p.PrEndDate
    )}_Co${p.PrCo}_Seq_${p.PaySeq} `;
  }

  dateToString(d: Date): string {
    const dd = d.getDate();
    let ddd;
    const mm = d.getMonth() + 1; // January is 0!
    let mmm;
    const yyyy = d.getFullYear();

    if (dd < 10) {
      ddd = '0' + dd;
    } else {
      ddd = dd.toString();
    }

    if (mm < 10) {
      mmm = '0' + mm;
    } else {
      mmm = mm.toString();
    }

    const s = mmm + '' + ddd + '' + yyyy;

    return s;
  }

  abstract set data(value: PayStubInfo);
}
