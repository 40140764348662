<form>
  <fieldset *ngIf="stepIdx < 0">
    <legend class="d-flex">
      <div>Walkthrough Metadata</div>
      <div class="text-muted ms-auto" *ngIf="document.steps">
        {{ document.steps.length }} Steps
      </div>
    </legend>
    <label class="d-block k-label">
      <input
        type="checkbox"
        kendoCheckBox
        name="pageSpecificToggle"
        #checkbox
        (change)="updatePathBasedOnCheckbox(checkbox.checked)"
      />
      Is Page Specific?
    </label>
    <label class="d-block k-label" *ngIf="!checkbox.checked">
      Anchor Element (right click to select)
      <help-element-picker
        class="w-100"
        name="elementControl"
        [(ngModel)]="document.path"
      ></help-element-picker>
    </label>
    <label class="d-block k-label">
      Title
      <input
        class="w-100 k-textbox"
        name="titleControl"
        [(ngModel)]="document.title"
      />
    </label>
    <label class="d-block k-label">
      Description
      <kendo-editor
        name="bodyControl"
        [(ngModel)]="document.html"
      ></kendo-editor>
    </label>
  </fieldset>
  <fieldset *ngIf="stepIdx >= 0">
    <legend>Step {{ stepIdx + 1 }} / {{ document?.steps?.length || 0 }}</legend>
    <label class="d-block k-label">
      Element (right click to select)
      <help-element-picker
        class="w-100"
        name="elementControl"
        [(ngModel)]="document.steps[stepIdx].path"
      ></help-element-picker>
    </label>
    <label class="d-block k-label">
      Title
      <input
        class="w-100 k-textbox"
        name="titleControl"
        [(ngModel)]="document.steps[stepIdx].title"
      />
    </label>
    <label class="d-block k-label">
      Content
      <kendo-editor
        name="bodyControl"
        [(ngModel)]="document.steps[stepIdx].html"
      ></kendo-editor>
    </label>
  </fieldset>
  <div class="d-flex">
    <button
      class="k-button k-button-sm"
      *ngIf="stepIdx > -1"
      (click)="stepIdx = stepIdx - 1"
    >
      {{ stepIdx === 0 ? 'Edit Metadata' : 'Previous Step' }}
    </button>
    <button class="k-button k-button-sm" (click)="nextStep()">
      {{ stepIdx === (document.steps?.length || 0) - 1 ? 'Add' : 'Next' }} Step
    </button>
    <button
      class="ms-auto k-button k-button-sm k-primary"
      (click)="complete.emit()"
    >
      Complete
    </button>
  </div>
</form>
