export class TimekeepingCorrectionsDto {
  public Amount: number;
  public BillRate: number;
  public Class: string;
  public Craft: string;
  public DateWorked: Date;
  public WeekEndDate: Date;
  public EarnCode: string;
  public EarnCodeType: string;
  public Employee: string;
  public EmployeeVPID: string;
  public EmployeeJobID: string;
  public EmployeeLaborID: string;
  public FirstName: string;
  public Hours: number;
  public LaborBillingTemplate: string;
  public LastName: string;
  public Mileage: number;
  public OTEarnCode: string;
  public OTHours: number;
  public PDEarnCode: string;
  public PTEarnCode: string;
  public PTHours: number;
  public PayCategoryID: number;
  public PayRate: number;
  public PerDiem: number;
  public Phase: string;
  public STEarnCode: string;
  public STHours: number;
  public Shift: number;
  public EmployeeCraft: string;
  public EmployeeClass: string;
  public EmployeePayRate: number;
  public EmployeePerDiem: number;
  public EmployeeMileage: number;
  public MileageRate: number;
  public LineID: string;
  public ReasonFlag: number;
  public Reason: string;
  public TransactionType: string;
  public DateCreated: Date;
  public CreatedBy: string;
  public VPJobID: string;
  public JobName: string;
  public VPCompanyID: number;
  public SupervisorName: string;
  public PayrollCost: number;
  public ClientCost: number;
  public TimeSheetName: string;
  public TimeSheetNotes: string;
  public TimeSheetID: string;
}
