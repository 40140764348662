import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import { Observable } from 'rxjs';
import { HelpDeskSubmission } from '../models/help-desk-submission.interface';

const SEND_HELPDESK = '/api/sendHelpDeskEmail';

@Injectable({
  providedIn: 'root',
})
export class HelpDeskService {
  http: HttpClient;

  constructor(private httpClientFactory: MyHttpClientFactory) {
    this.http = httpClientFactory.createHttpClient(
      environment.urls.viewpointAPI,
      true,
      ResponseCasingEnum.PascalCase
    );
  }

  public submitHelpdeskTicket(helpdesk: HelpDeskSubmission): Observable<void> {
    return this.http.post<void>(SEND_HELPDESK, helpdesk);
  }
}
