import { Inject, Injectable } from '@angular/core';
import { parseData } from '@ups/xplat/utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  ManpowerReqLogDto,
  ParentCustomerDto,
  ParentFacilityDto,
  PlantBasicLogDto,
  PlantDto,
  ProfileModel,
} from '@ups/xplat/api/dto';
import {
  MyHttpClientFactory,
  LocalStorageService,
  environment,
} from '@ups/xplat/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

const GET_PLANTS = '/api/plants-odata';
const GET_PLANT = '/api/plant/{:plantID}';
const GET_PARENT_FACILITY_DROPDOWN = '/api/plants/parentFacilities';
const GET_DISA_OWNERS = '/api/plants/disaOwners';
const GET_DEFAULT_REQUIREMENTS = '/api/plant/defaultRequirements';
const SAVE_PLANT = '/api/plant';
const GET_PARENT_CUSTOMERS = '/api/parentCustomers-odata';
const SAVE_PARENT_CUSTOMER = '/api/parentCustomer';
const GET_PARENT_FACILITIES = '/api/parentFacilities-odata';
const SAVE_PARENT_FACILITY = '/api/parentFacility';
const GET_PARENT_CUSTOMER_DROPDOWN = '/api/parentCustomers';
const FAVORITE_FACILITY = '/api/parentFacilities/favorite';
const FAVORITE_CUSTOMER = '/api/parentCustomers/favorite';

@Injectable({ providedIn: 'root' })
export class PlantService {
  http: HttpClient;

  private profile: ProfileModel;

  constructor(
    private httpFactory: MyHttpClientFactory,
    private storage: LocalStorageService
  ) {
    this.http = httpFactory.createHttpClient(environment.urls.spartaAPI);
    this.profile = this.storage.getItem('profile') as ProfileModel;
  }

  public getPlants(odatastring: string, isActive: boolean = true) {
    let url = GET_PLANTS;

    url += '?$format=json&$count=true';

    if (isActive) {
      url += '&isActive=' + isActive;
    }

    url += odatastring ? '&' + odatastring : '';

    return this.http.get(url).pipe(map(parseData));
  }

  public getPlant(plantID: number, createPlantCopy = false) {
    let url = GET_PLANT.split('{:plantID}').join(plantID.toString());

    const queryParameters: string[] = [];

    if (createPlantCopy === true) {
      queryParameters.push('createNewCopy=' + createPlantCopy);
    }

    if (queryParameters.length) {
      url = url + '?' + queryParameters.join('&');
    }

    return this.http.get(url).toPromise().then(parseData);
  }
  public savePlant(model: PlantDto) {
    const url = SAVE_PLANT;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.http.post(url, model, options).toPromise().then(parseData);
  }

  public getParentFacilityDropdown(
    searchString: string,
    take: number = 0,
    parentCustomerID: number = null,
    parentFacilityID: number = null
  ) {
    const url = GET_PARENT_FACILITY_DROPDOWN;
    let queryParams = '?';

    if (searchString) queryParams += 'searchString=' + searchString;

    if (parentCustomerID && parentCustomerID !== 0) {
      queryParams +=
        (queryParams.length > 1 ? '&' : '') +
        'parentCustomerID=' +
        parentCustomerID;
    }

    if (parentFacilityID && parentFacilityID !== 0) {
      queryParams +=
        (queryParams.length > 1 ? '&' : '') +
        'parentFacilityID=' +
        parentFacilityID;
    }

    if (take) {
      queryParams += (queryParams.length > 1 ? '&' : '') + 'take=' + take;
    }

    return this.http
      .get(url + queryParams)
      .toPromise()
      .then(parseData);
  }

  public addOrRemoveFavoriteFacility(facilityID: string, auth0UserId?: string) {
    const url = FAVORITE_FACILITY;
    return this.http
      .post(url, {
        /* eslint-disable @typescript-eslint/naming-convention */
        UserID: auth0UserId ? auth0UserId : this.profile.auth0UserId,
        ParentFacilityID: facilityID,
        /* eslint-enable @typescript-eslint/naming-convention */
      })
      .toPromise();
  }

  public addOrRemoveFavoriteVPFacility(
    vpFacilityKeyID: string,
    auth0UserId?: string
  ) {
    const url = FAVORITE_FACILITY;
    return this.http
      .post(url, {
        /* eslint-disable @typescript-eslint/naming-convention */
        UserID: auth0UserId ? auth0UserId : this.profile.auth0UserId,
        VPParentFacilityKeyID: vpFacilityKeyID,
        /* eslint-enable @typescript-eslint/naming-convention */
      })
      .toPromise();
  }

  public getDisaOwners(searchString: string | null, take: number = 0) {
    const url = GET_DISA_OWNERS;
    let queryParams = '?';

    if (searchString) queryParams += 'searchString=' + searchString;

    if (take) {
      queryParams += (queryParams.length > 1 ? '&' : '') + 'take=' + take;
    }

    return this.http
      .get(url + queryParams)
      .toPromise()
      .then(parseData);
  }
  public getDefaultRequirements() {
    const url = GET_DEFAULT_REQUIREMENTS;
    return this.http.get(url).toPromise().then(parseData);
  }

  public getParentCustomers(odatastring: string) {
    let url = GET_PARENT_CUSTOMERS;
    url += '?$format=json&$count=true';

    url += odatastring ? '&' + odatastring : '';

    return this.http.get(url);
  }

  public saveParentCustomer(model: ParentCustomerDto) {
    const url = SAVE_PARENT_CUSTOMER;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers };
    return this.http.post(url, model, options).toPromise().then(parseData);
  }

  public getParentFacilities(odatastring: string) {
    let url = GET_PARENT_FACILITIES;
    url += '?$format=json&$count=true';

    url += odatastring ? '&' + odatastring : '';

    return this.http.get(url);
  }

  public saveParentFacility(model: ParentFacilityDto) {
    const url = SAVE_PARENT_FACILITY;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers };
    return this.http.post(url, model, options).toPromise().then(parseData);
  }

  public getParentCustomerDropdown(searchString: string, take: number = 0) {
    const url = GET_PARENT_CUSTOMER_DROPDOWN;
    let queryParams = '?';

    if (searchString) queryParams += 'searchString=' + searchString;

    if (take) {
      queryParams += (queryParams.length > 1 ? '&' : '') + 'take=' + take;
    }

    return this.http
      .get(url + queryParams)
      .toPromise()
      .then(parseData);
  }

  public addOrRemoveFavoriteCustomer(customerID: string) {
    const url = FAVORITE_CUSTOMER;
    return this.http
      .post(url, {
        /* eslint-disable @typescript-eslint/naming-convention */
        UserID: this.profile.auth0UserId,
        ParentCustomerID: customerID,
        /* eslint-enable @typescript-eslint/naming-convention */
      })
      .toPromise();
  }
  public addOrRemoveFavoriteVPCustomer(
    vpParentCustomerKeyID: string,
    auth0UserId?: string
  ) {
    const url = FAVORITE_CUSTOMER;
    return this.http
      .post(url, {
        /* eslint-disable @typescript-eslint/naming-convention */
        UserID: auth0UserId ? auth0UserId : this.profile.auth0UserId,
        VPParentCustomerKeyID: vpParentCustomerKeyID,
        /* eslint-enable @typescript-eslint/naming-convention */
      })
      .toPromise();
  }

  public getBasicLogHistory(plantId: number): Observable<PlantBasicLogDto[]> {
    return this.http.get<PlantBasicLogDto[]>(`api/plant/basicLog/${plantId}`);
  }

  public getManpowerReqLogHistory(
    plantId: number
  ): Observable<ManpowerReqLogDto[]> {
    return this.http.get<ManpowerReqLogDto[]>(
      `api/plant/manpowerReqLog/${plantId}`
    );
  }
}
