import { Component, OnInit } from '@angular/core';

import { EditorComponentBase } from '../editor-component-base';

@Component({
  selector: 'editor-dashboard-chart',
  templateUrl: 'editor-dashboard-chart.html',
})
export class EditorDashboardChartComponent extends EditorComponentBase implements OnInit {
  public ngOnInit() {
    if (this.data) {
      // set data
    }
  }

  public onApplyClick() {
    const changesObj = {
      // set local props
    };

    this.applyChanges.emit(changesObj);
  }
}
