import { Component, Input, OnChanges } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { EquipmentService, VpService } from '@ups/xplat/api/services';
import { DropDownItem, EquipmentResourceDto } from '@ups/xplat/api/dto';
import { EquipmentVpService } from '@ups/xplat/web/core';
import { nameOf } from '@ups/xplat/utils';
import { BaseComponent } from '@ups/xplat/core';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import { EmployeeGuardService } from '@ups/xplat/base/employee-profile';

@Component({
  selector: 'ups-equipment-info',
  templateUrl: './equipment-info.component.html',
})
export class EquipmentInfoComponent extends BaseComponent implements OnChanges {
  @Input() keyId: number;
  equipmentResource: EquipmentResourceDto;
  isEditMode = false;
  hasReadPermissions = false;
  hasEditPermissions = false;

  fixedAssetTypes = [];
  fixedAssetStatuses: DropDownItem[] = [];
  equipmentTypes = [];
  equipmentTypesFiltered = [];
  savedEquipmentTypeID;

  constructor(
    private security: SecurityService,
    private vpService: VpService,
    private equipmentVpService: EquipmentVpService,
    private equipmentService: EquipmentService,
    private guardService: EmployeeGuardService
  ) {
    super();
    this.hasReadPermissions = this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_infotab
    ).readAll;
    this.hasEditPermissions = this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_infotab
    ).editAll;
  }

  ngOnChanges() {
    this.equipmentResource = null;
    this.loadData();
  }

  editEquipment() {
    if (this.hasEditPermissions) {
      this.isEditMode = true;
      this.guardService.isToUseGuard = true;
    }
  }

  cancelEquipment() {
    this.equipmentResource = null;
    this.isEditMode = false;
    this.guardService.isToUseGuard = false;
    this.loadData();
  }

  saveEquipment(isFormValid: boolean) {
    if (this.equipmentResource) {
      if (!isFormValid) {
        window.alert('Some fields in the form are not filled properly.');
        return;
      }

      // AutoCorrect items
      this.equipmentResource.ExpectedLife =
        this.equipmentResource.ExpectedLife === null
          ? 0
          : this.equipmentResource.ExpectedLife; // In DB the column does not allow nulls
      this.equipmentResource.SalePrice =
        this.equipmentResource.SalePrice === null
          ? 0
          : this.equipmentResource.SalePrice; // In DB the column does not allow nulls

      const equipmentResourceToSave = this.equipmentResource;

      this.equipmentResource = null; // Assign null so that Loading ... message appears.

      this.equipmentVpService
        .updateEquipmentResource(equipmentResourceToSave, [
          nameOf<EquipmentResourceDto>('OwnershipStatus'),
          nameOf<EquipmentResourceDto>('InServiceDate'),
          nameOf<EquipmentResourceDto>('ExpectedLife'),
          nameOf<EquipmentResourceDto>('ExpectedLifeTimeFrame'),
          nameOf<EquipmentResourceDto>('SalePrice'),
          nameOf<EquipmentResourceDto>('ReportingType'),
          nameOf<EquipmentResourceDto>('VehicleOperator'),
          nameOf<EquipmentResourceDto>('SearchDescription'),
          nameOf<EquipmentResourceDto>('SelfConstructed'),
          nameOf<EquipmentResourceDto>('TrackLocation'),
          nameOf<EquipmentResourceDto>('CurrentLocation'),
          nameOf<EquipmentResourceDto>('LoanToCompany'),
          nameOf<EquipmentResourceDto>('FixedAssetId'),
          nameOf<EquipmentResourceDto>('FixedAssetStatus'),
          nameOf<EquipmentResourceDto>('AssetType'),
          nameOf<EquipmentResourceDto>('EquipmentTypeID'),
        ])
        .subscribe(
          () => {
            this.isEditMode = false;
            this.guardService.isToUseGuard = false;
            this.loadData();
          },
          (error) => {
            console.error(error);
            this.equipmentResource = equipmentResourceToSave;
            throw error;
          }
        );

      if (
        this.savedEquipmentTypeID !== equipmentResourceToSave.EquipmentTypeID
      ) {
        this.equipmentService.bulkEditEquipments([equipmentResourceToSave]);
      }
    }
  }

  loadData() {
    forkJoin([
      this.fixedAssetTypes.length
        ? of({ value: this.fixedAssetTypes })
        : this.vpService.getDropdownItems('udFixedAssetType'),
      this.fixedAssetStatuses.length
        ? of({ value: this.fixedAssetStatuses })
        : this.vpService.getDropdownItems('udFixedAssetStatus'),
      this.equipmentVpService.queryEquipmentResources(null, null, this.keyId),
      this.equipmentVpService.getEquipmentTypes(),
    ]).subscribe(
      (results) => {
        this.fixedAssetTypes = results[0].value;
        this.fixedAssetStatuses = results[1].value;
        const equipmentResources: EquipmentResourceDto[] = results[2].value;
        if (equipmentResources && equipmentResources.length) {
          this.equipmentResource = equipmentResources[0];
          this.savedEquipmentTypeID = this.equipmentResource.EquipmentTypeID;
        } else {
          this.equipmentResource = null;
        }
        this.equipmentTypes = results[3];
        this.equipmentTypesFiltered = this.equipmentTypes;
      },
      (error) => {
        console.error(error);
        this.equipmentResource = null;
        throw error;
      }
    );
  }

  handleEquipmentTypeFilter(value) {
    this.equipmentTypesFiltered = this.equipmentTypes.filter(
      (s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
}
