/* eslint-disable @typescript-eslint/naming-convention */
import { BaseModel } from '../../base.model';
import { EquipmentRateDto } from './EquipmentRateDto';

export class EquipmentDispatchDto extends BaseModel {
  public EquipmentDispatchIDs: string[];
  public EquipmentIDs: string[];
  public JobID: string;
  public EquipmentName: string;
  public Name: string;
  public StartDate: Date;
  public EndDate: Date;
  public bActive: boolean;
  public Note: string;
  public AltID: string;
  public CustomerID: string;
  public Rates: EquipmentRateDto[];

  constructor(o: unknown = null) {
    super(o);
  }
}
