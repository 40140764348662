import { Component, Input, OnInit, OnChanges } from '@angular/core';

import { EventStyleArgs } from '@progress/kendo-angular-scheduler';

import { EquipmentService } from '@ups/xplat/api/services';
import { BaseComponent } from '@ups/xplat/core';

@Component({
  selector: 'ups-equipment-schedule',
  templateUrl: './equipment-schedule.component.html',
  // styleUrls: ['./equipment-schedule.component.scss'],
})
export class EquipmentScheduleComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() vpCompanyID: string;
  @Input() equipmentName: string;

  scheduleData: any[] = [];
  isLoading: boolean = false;

  public selectedDate: Date;
  public baseData: any[] = [];

  constructor(private equipmentService: EquipmentService) {
    super();
  }

  ngOnInit() {}

  ngOnChanges() {
    this.scheduleData = [];
    this.baseData = [];
    this.loadScheduleData();
  }

  public getEventStyles = (args: EventStyleArgs) => {
    return { backgroundColor: args.event.dataItem.color };
  };

  loadScheduleData() {
    const self = this;
    if (this.vpCompanyID && this.equipmentName) {
      self.isLoading = true;
      this.equipmentService
        .getEquipmentScheduleData(this.vpCompanyID, this.equipmentName)
        .subscribe((d: any) => {
          self.scheduleData = d;
          // self.selectedDate = self.scheduleData[0] ? new Date(self.scheduleData[0].StartDate) : new Date();
          self.selectedDate = new Date();
          self.baseData = self.scheduleData.map((val: any, ix) => {
            return {
              id: ix + 1,
              start: new Date(val.StartDate),
              end: new Date(val.EndDate),
              title: `${val.VPJobId} - ${val.JobName}`,
              color: val.IsDispatched ? '#AA9569' : '#00275D',
            };
          });
          self.isLoading = false;
        });
    }
  }
}
