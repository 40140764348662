import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';

import { BaseComponent } from '@ups/xplat/core';

@Component({
  selector: 'ups-popover',
  templateUrl: 'popover.component.html',
})
export class PopoverComponent extends BaseComponent {
  private toggleText = 'Show';
  public show = false;

  @ViewChild('anchor') public anchor: ElementRef<HTMLElement>;
  @ViewChild('popup', { read: ElementRef }) public popup: ElementRef<HTMLElement>;

  @HostListener('keydown', ['$event'])
  public keydown(event: any): void {
    if (event.keyCode === 27) {
      this.toggle(false);
    }
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if (!this.contains(event.target)) {
      this.toggle(false);
    }
  }

  public toggle(show?: boolean): void {
    this.show = show !== undefined ? show : !this.show;
    this.toggleText = this.show ? 'Hide' : 'Show';
  }

  private contains(target: any): boolean {
    return this.anchor.nativeElement.contains(target) || (this.popup ? this.popup.nativeElement.contains(target) : false);
  }
}
