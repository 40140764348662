import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DynamicCustomValidationTypes } from '@ups/xplat/core';
import moment from 'moment';

/** Invalidates control if it is loading data
 * For example a dropdown loading data when initializing
 * It is set by isLoading property in dynamic-item.base-component.ts
 */
export function dynamicValidatorNotLoading(controlLabel: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isLoading = control && (control['isLoading_dynamic'] ?? false);
    return isLoading
      ? {
          isLoading: {
            value: `${
              controlLabel ?? 'A'
            } field on the form is still loading data ...`,
          },
        }
      : null;
  };
}

export function dynamicValidatorDate(
  controlLabel: string,
  type: DynamicCustomValidationTypes
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control && control.value;
    if (value) {
      // we always add one second to "today" time. This is because moment will only compare dates based on year/month/day/hours/minutes/seconds and not milliseconds!
      // therefore "today" time should always reflect 1 second ahead to ensure comparisons are "from a user experience" perspective make sense because the "today" time when the control is opened and set should always validate as behind the current "today" time always because time is always moving ahead and never standing still - let your head explode on that one.
      // Additionally the time picker only allows setting minutes and never seconds, thus the need for the handling consideration
      const today = moment().add(1, 'seconds');
      let valid = false;
      let customMessage: string;
      const controlDate = moment(value);
      switch (type) {
        case 'date: greater than current':
          if (controlDate.isAfter(today)) {
            valid = true;
          }
          break;
        case 'date: less than current':
          if (controlDate.isBefore(today)) {
            valid = true;
          }
          break;
        case 'date: greater than past 7 days':
          if (controlDate.isAfter(today.subtract(7, 'days'))) {
            valid = true;
          } else {
            customMessage = `'${controlLabel}' must be within the past 7 days in order to submit this form.`;
          }
          break;
      }
      if (!valid) {
        return {
          dateInvalid:
            customMessage ||
            `${
              controlLabel ? `'${controlLabel}'` : 'A'
            } field on the form must be ${type.replace('date: ', '')} date.`,
        };
      }
    }
    return null;
  };
}
