<div
  class="dynamic-field-container dynamic-icon-field"
  [class.selected]="config?.selected"
>
  <div
    class="dynamic-field form-input text-align"
    [formGroup]="group"
    [hidden]="!config?.isFormBuilder && config?.options?.hidden"
  >
    <ups-dynamic-helper-label
      [config]="config"
      [dynamicService]="dynamicService"
    ></ups-dynamic-helper-label>
    <div class="job-search-container" style="position: relative">
      <kendo-dropdownlist
        #dropdown
        *ngIf="!isMultiSelect"
        class="k-no-dropdown w-100"
        [attr.name]="config.formControlName"
        [formControlName]="config.formControlName"
        [valuePrimitive]="false"
        [textField]="'Name'"
        [valueField]="'Name'"
        [defaultItem]="{ Name: config?.placeholder }"
        [filterable]="true"
        (valueChange)="valueChangeItem($event)"
        search-ex
        [seSearchColumns]="['Name']"
        (seFilterChangeEx)="filterChangeItem($event)"
        [seDebounceFilterChange]="150"
        [seUseCustomLocalFilter]="true"
        [seMinSearchLength]="0"
      >
        <ng-template kendoDropDownListItemTemplate let-dataItem>
          <div
            style="display: inline-flex"
            [hidden]="dataItem?.Name === config?.placeholder"
          >
            <div>
              <i
                [ngClass]="dataItem?.Name ? 'me-3 fas ' + dataItem?.Name : null"
              ></i>
              <span class="job-name">{{ dataItem.Name }}</span>
            </div>
          </div>
        </ng-template>
        <ng-template kendoDropDownListValueTemplate let-dataItem>
          <div style="display: inline-flex">
            <div kendoTooltip [title]="dataItem?.Name">
              <span [hidden]="!displayIdPrefix">
                {{ dataItem.Id ? dataItem.Id + ' - ' : '' }}
              </span>
              <i
                [ngClass]="dataItem?.Name ? 'me-3 fas ' + dataItem?.Name : null"
              ></i>
              <span class="job-name">{{ dataItem.Name }}</span>
            </div>
          </div>
        </ng-template>
      </kendo-dropdownlist>
    </div>
    <div
      class="form-builder-mask"
      [class.hidden]="config?.options?.hidden"
      *ngIf="config?.isFormBuilder"
    ></div>
  </div>
  <div class="form-builder-options" *ngIf="config?.isFormBuilder">
    <i class="fas fa-times ms-3 cursor-pointer c-red" (click)="delete()"></i>
    <!-- <i class="fas fa-bars ms-3 cursor-grab"></i> -->
  </div>
</div>
