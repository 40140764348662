import { Directive, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { capitalize } from '@ups/xplat/utils';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import {
  dynamicCheckAccordionConditions,
  DynamicEventBusTypes,
  dynamicFindControlByCondition,
} from '../../utils';
import { DynamicItemBaseComponent } from '../dynamic-item/dynamic-item.base-component';

@Directive()
export abstract class DynamicAccordionBaseComponent
  extends DynamicItemBaseComponent
  implements OnInit
{
  open: { [key: string]: boolean } = {};
  statusCompletionTotal = ``;
  statusComplete = false;
  hasRequired = false;
  nestedFormGroup: UntypedFormGroup;

  get formArrayGroup() {
    if (
      this.group.controls[this.config.formArrayName] &&
      (<UntypedFormArray>this.group.controls[this.config.formArrayName]).controls
    ) {
      // console.log('get formArrayGroup!!', (<FormArray>this.group.controls[this.config.formArrayName]).controls[0]);
      return (<UntypedFormArray>this.group.controls[this.config.formArrayName])
        .controls[0];
    } else {
      return this.group;
    }
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.config?.isFormBuilder) {
      // retain opened state when regenerating builder controls
      if (this.dynamicService.formBuilderState?.accordion?.open) {
        this.open[this.config.formArrayName] =
          this.dynamicService.formBuilderState.accordion.open[
            this.config.formArrayName
          ];
      }
    } else {
      this.nestedFormGroup = <UntypedFormGroup>(
        (<UntypedFormArray>this.group.controls[this.config.formArrayName])
          .controls?.[0]
      );
      this.dynamicService.eventBus
        .observe(DynamicEventBusTypes.dynamicFormResetState)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (this.config) {
            this.open[this.config.formArrayName] = false;
          }
          if (this.nestedFormGroup) {
            this._updateStatus(this.nestedFormGroup.value);
          }
        });
      // this.dynamicService.eventBus
      //   .observe(DynamicEventBusTypes.dynamicAccordionApplyToAllStatusComplete)
      //   .pipe(takeUntil(this.destroy$))
      //   .subscribe((completeFormArrayName) => {
      //     if (this.config.formArrayName !== completeFormArrayName) {
      //       // adjust for all other accordions (the one emitting complete already has it's status updated)
      //       this.statusComplete = true;
      //       this.statusCompletionTotal = '';
      //     }
      //   });
      // this.dynamicService.eventBus
      //   .observe(DynamicEventBusTypes.dynamicAccordionApplyToAllStatusReset)
      //   .pipe(takeUntil(this.destroy$))
      //   .subscribe((completeFormArrayName) => {
      //     if (this.config.formArrayName !== completeFormArrayName) {
      //       this._updateStatus(this.nestedFormGroup.value);
      //     }
      //   });
      this.nestedFormGroup?.valueChanges
        .pipe(
          filter((v) => !!v && this._checkGroupSettings()),
          debounceTime(300),
          takeUntil(this.destroy$)
        )
        .subscribe((values) => {
          this._updateStatus(values, true);
        });

      if (this.config?.disabled || this.disableItem) {
        // often nested accordion controls are not even created component instances until opened (due to ngIf) thus not even populating the FormGroup parent they are part of
        // when a control is disabled we still want to show the completion status of any required validations of those nested controls
        // therefore when an accordion is initialized and it's disabled, we look at the nested control status up front to show the status right away (without having to open [creating nested instances])
        this._updateStatus(this.nestedFormGroup.value, true);
      }
    }
  }

  isOpenChange(opened: boolean) {
    if (typeof opened === 'boolean') {
      // console.log('isOpenChange:', args);
      this.open[this.config.formArrayName] = !!opened;
      if (this.config?.isFormBuilder) {
        const open = this.dynamicService.formBuilderState?.accordion?.open
          ? this.dynamicService.formBuilderState.accordion.open
          : {};
        open[this.config.formArrayName] = !!opened;
        this.dynamicService.updateFormBuilderState({
          accordion: {
            open,
          },
        });
      }
    }
  }

  private _updateStatus(values, considerGroupConditions?: boolean) {
    // check entire group conditions for consideration
    const groupConditions = considerGroupConditions
      ? dynamicCheckAccordionConditions(
          this.dynamicService.activeModelGroup.controls
        )
      : null;
    // console.log('groupConditions:', groupConditions)
    let groupConditionStatus = '';
    let groupConditionStatusComplete = false;
    let groupConditionControlValue: string;
    let groupConditionControlName = '';
    // update total counts
    // console.log('FORM ARRAY CHANGES:', values);
    let controlNames = Object.keys(values);
    if (
      groupConditions &&
      groupConditions.conditions[this.config?.formArrayName] &&
      controlNames.length === 0
    ) {
      // accordion is closed and the children may not be created, therefore fallback to using the activeModelGroup.controls
      controlNames =
        groupConditions.conditions[this.config?.formArrayName]
          .accordionControlNames;
    }
    // how many are required
    let requiredCnt = 0;
    // how many required fields have valid values
    let requiredValueCnt = 0;
    // how many button groups have been selected
    const buttonGroupCounts = {};
    for (const name of controlNames) {
      const control = <UntypedFormControl>this.nestedFormGroup.get(name);
      const dynamicConfig = dynamicFindControlByCondition(
        this.dynamicService.activeModelGroup.controls,
        (c) => c.formControlName === name
      );
      let controlValue: string;
      if (control) {
        controlValue = control.value;
      } else if (dynamicConfig.ctrl.value) {
        controlValue = <string>dynamicConfig.ctrl.value;
      }

      if (controlValue) {
        // find label for matching value
        const btnGroup = dynamicConfig.nestedArray
          .filter((n) => !!n.options.buttongroup)
          .map((n) => n.options.buttongroup)
          .flat()
          .find((g) => g.value === controlValue);
        if (btnGroup) {
          if (!buttonGroupCounts[btnGroup.label]) {
            buttonGroupCounts[btnGroup.label] = {
              count: 1,
              value: btnGroup.value,
            };
          } else {
            buttonGroupCounts[btnGroup.label].count++;
          }
        }
      }

      if (dynamicConfig.ctrl.required) {
        requiredCnt++;
        // attachment values are stored/tracked in the dynamic service (since they can contain multiples)
        if (
          dynamicConfig.ctrl.type === 'file' &&
          this.dynamicService.activeAttachments
        ) {
          if (
            this.dynamicService.activeAttachments[
              dynamicConfig.ctrl.formControlName
            ] &&
            this.dynamicService.activeAttachments[
              dynamicConfig.ctrl.formControlName
            ].length
          ) {
            requiredValueCnt++;
          }
        } else if (controlValue) {
          requiredValueCnt++;
        }
      }
      if (groupConditions?.applyToAllConditions?.accordionRequiredValues) {
        if (
          groupConditions.applyToAllConditions.accordionRequiredValues.includes(
            controlValue
          )
        ) {
          // at least one accordion control met the required criteria overriding all other requirements
          requiredCnt = 1;
          groupConditionStatus = '1/1';
          groupConditionStatusComplete = true;
          groupConditionControlValue = controlValue;
          groupConditionControlName = name;
        }
      }
      // console.log(name, ' required:', validator.required, ' value:', control.value);
    }
    if (
      groupConditionControlValue &&
      groupConditions.applyToAllConditions
        ?.accordionRequiredValueRequiresAnother
    ) {
      // also check if required value requires other values from the accordion group
      for (const requireAnother of groupConditions.applyToAllConditions
        .accordionRequiredValueRequiresAnother) {
        const parts = requireAnother
          .split(':')
          .filter((p) => !!p)
          .map((p) => p.trim());
        const requireMatchValue = parts[0];
        if (groupConditionControlValue === requireMatchValue) {
          // make sure the other field has a valid value to pass requirements
          if (parts.length > 1) {
            const controlNameSuffixCheck = parts[1];
            const matchingControlName = controlNames.find(
              (name) =>
                name ===
                `${groupConditionControlName}${capitalize(
                  controlNameSuffixCheck
                )}`
            );

            if (matchingControlName) {
              const matchingControl =
                this.nestedFormGroup.get(matchingControlName);
              if (matchingControl && !matchingControl.value) {
                groupConditionStatus = '0/1';
                groupConditionStatusComplete = false;
              }
            }
          }
        }
      }
    }
    // console.log('requiredCnt:', requiredCnt);
    // console.log('requiredValueCnt:', requiredValueCnt);
    this.hasRequired = requiredCnt > 0;
    this.statusComplete = requiredValueCnt >= requiredCnt;

    // console.log('groupConditionStatus:', this.config.formArrayName, ' groupConditionStatus:', groupConditionStatus)
    // console.log('this.hasRequired:', this.hasRequired);
    // console.log('this.statusComplete:', this.statusComplete);
    let btnGroupCountStatus = '';
    if (buttonGroupCounts) {
      const btnGroupTotals = [];
      for (const label in buttonGroupCounts) {
        btnGroupTotals.push(`${label}: ${buttonGroupCounts[label].count}`);
      }
      btnGroupCountStatus = btnGroupTotals.join(', ');
    }
    if (groupConditionStatus) {
      this.statusCompletionTotal = btnGroupCountStatus || groupConditionStatus;
      this.statusComplete = groupConditionStatusComplete;
      this.dynamicService.updateActiveGroupSettings({
        accordionApplyAll: this.config.formArrayName,
        accordionStatusComplete: this.statusComplete,
      });
      this.dynamicService.eventBus.emit(
        DynamicEventBusTypes.dynamicAccordionApplyToAllStatusComplete,
        this.config.formArrayName
      );
    } else if (requiredCnt) {
      if (
        this.dynamicService.activeGroupSettings?.accordionApplyAll ===
        this.config.formArrayName
      ) {
        // modified values in apply all accordion group, reset
        this._resetGroupSettings(true);
      }
      if (
        this.config?.options?.minimumFieldsRequired &&
        typeof this.config.options.minimumFieldsRequired === 'number'
      ) {
        this.statusComplete =
          requiredValueCnt >= this.config.options.minimumFieldsRequired;
        this.statusCompletionTotal =
          btnGroupCountStatus || this.statusComplete
            ? `${this.config.options.minimumFieldsRequired}/${this.config.options.minimumFieldsRequired}`
            : `${requiredValueCnt}/${this.config.options.minimumFieldsRequired}`;
      } else {
        this.statusCompletionTotal =
          btnGroupCountStatus || `${requiredValueCnt}/${requiredCnt}`;
      }
    } else {
      this.statusCompletionTotal = btnGroupCountStatus || '';
      this._resetGroupSettings();
    }
  }

  private _checkGroupSettings() {
    return true; // disabled specific behavior for now (can return later if needed)
    // 1. no active group settings
    // 2. has group settings but no apply all override is set
    // 3. has group settings applicable to accordion apply all overrides specifically for this accordion group
    return (
      !this.dynamicService.activeGroupSettings ||
      (this.dynamicService.activeGroupSettings &&
        (!this.dynamicService.activeGroupSettings.accordionApplyAll ||
          this.dynamicService.activeGroupSettings.accordionApplyAll ===
            this.config.formArrayName))
    );
  }

  private _resetGroupSettings(notifyOthers?: boolean) {
    this.dynamicService.updateActiveGroupSettings({
      accordionApplyAll: null,
      accordionStatusComplete: false,
    });
    if (notifyOthers) {
      this.dynamicService.eventBus.emit(
        DynamicEventBusTypes.dynamicAccordionApplyToAllStatusReset,
        this.config.formArrayName
      );
    }
  }
}
