/* eslint-disable */
declare const process: any;

export const AppConstants = {
  // in memory user token for system level interceptor usage
  userToken: null,
  // external keyboard connected
  // can be useful to know state of whether external bluetooth keyboard input is connected
  externalKeyboardConnected: false,
  // NOTE: double backslash!
  regex: {
    ssn: '^(?!(000|666|9))\\d{3}-(?!00)\\d{2}-(?!0000)\\d{4}$',
    onlyCharacters: '^[a-zA-Z]+$',
    onlyCharactersOrSpace: '^(?=.*[a-zA-Z])[a-zA-Z\\s]+$',
    onlyCharactersOrSpaceWithCommas: '^(?=.*[a-zA-Z])[a-zA-Z\\s\\,]+$',
    onlyAlphaNumeric: '^[a-zA-Z0-9]*$',
    genericIDNumber: '^[a-zA-Z0-9-/\\s*]+$',
    // NOTE: Employee portal has more stricter phone number data rules, but more broader phone number format! \D*([2-9]\d{2})(\D*)([2-9]\d{2})(\D*)(\d{4})\D*
    // phoneNumber: "^\\([0-9]{3}\\)[ ][0-9]{3}[-][0-9]{4}$"
    // Valid numbers are: 1234567890; (123)4567890; (123) 4567890; (123) 456-7890; 123456-7890; 123-456-7890 - as of Hotfix #20146 - we allow multiple formats as they are already stored in ViewpointDB so to not complain when we read them from Viewpoint
    phoneNumber: '^((\\(\\d{3}\\) ?)|(\\d{3}-?))?\\d{3}-?\\d{4}$',
    usZip54: '^\\d{5}([-]\\d{1,4})?$',
    datePatternDDMMYYYY:
      '^(0[1-9]|1[012])[\\/\\-](0[1-9]|[12][0-9]|3[01])[\\/\\-]\\d{4}$',
    // altered W3C email regex, to ensure it ends with .com or other "suffix"
    email:
      '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)+$',
    emailOrEmpty:
      '(^$|^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)+$)',
  },

  mask: {
    ssn: [
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      '-',
      /[0-9]/,
      /[0-9]/,
      '-',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
    ],
    phoneNumber: [
      '(',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      ')',
      ' ',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      '-',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
    ],
  },

  messages: {
    genericIDNumber:
      'Only alphanumeric values, spaces, underscore, hyphen, slash and asterisk characters are allowed.',
  },

  defaultRowsOnPage: 10,
  defaultRowsOnPageSet: [10, 25, 50, 100],
  ids: {
    mostUsedDispatchStatuses: [3, 32, 2, 7, 51, 53, 55, 49, 54, 57, 60, 61],
  },
  security: {
    /*
        nifty_helpdocuments_itsupportdocuments: 'CFE10F29-BE5A-4E6C-BC2E-74E00C164CEC',
        nifty_helpdocuments_usermanuals: 'B2319090-26BC-4082-A4E4-8EEDA36047F1',
        nifty_helpdocuments_trainingdocuments: '2DFDC876-D937-4D8C-A2E0-6B58E801EF99',
        */

    nifty_helpdocuments: 'C2665A55-7FD9-430E-AA23-87D0B0ED8F5C',
  },
  // determine if code is executed within testing environment
  isTest:
    typeof process === 'undefined'
      ? false
      : process?.env?.NODE_ENV == 'test' || !!process?.env?.JEST_WORKER_ID,
};
