import { Component, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { LogService, ProgressService } from '@ups/xplat/core';
import {
  DynamicRenderService,
  DynamicPageBaseComponent,
} from '@ups/xplat/features';
import { MessageHelper } from '@ups/xplat/web/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ups-dynamic-page',
  templateUrl: 'dynamic-page.component.html',
})
export class DynamicPageComponent extends DynamicPageBaseComponent {
  // Browser stopping code from executing
  @HostListener('window:beforeunload', ['$event']) handleUnload(e) {
    if (this.draftReady) {
      e.preventDefault();
      e.returnValue = `You have unsaved changes, leave anyway?`;
    }
  }

  constructor(
    store: Store,
    log: LogService,
    progress: ProgressService,
    dynamicRender: DynamicRenderService,
    private msg: MessageHelper
  ) {
    super(store, log, progress, dynamicRender);

    this.draftResponseAlert$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.msg.success(
        'Your Draft has been saved and can be found on your My Submissions page'
      );
    });
  }
}
