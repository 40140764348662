import { OnInit, Input, OnDestroy, Directive, ElementRef, Renderer2 } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { GridComponent } from '@progress/kendo-angular-grid';

@Directive({
  selector: '[upsGridArrowMove]',
})
export class GridArrowMoveDirective implements OnInit, OnDestroy {
  @Input() public inCellEdit: (args) => UntypedFormGroup;

  @Input() public wrap = true;

  private unsubKeydown: () => void;

  constructor(private grid: GridComponent, private el: ElementRef, private renderer: Renderer2) {}

  public ngOnInit(): void {
    this.unsubKeydown = this.renderer.listen(this.el.nativeElement, 'keydown', (e) => this.onKeydown(e));
  }

  public ngOnDestroy(): void {
    this.unsubKeydown();
  }

  public onKeydown(e: KeyboardEvent): void {
    if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp') {
      return;
    }

    let activeColumn = this.grid.activeCell.colIndex;
    let activeRow = this.grid.activeCell.rowIndex;

    if (this.grid.isEditingCell() && !this.grid.closeCell()) {
      // Content validation failed, keep focus in cell
      e.preventDefault();
      return;
    }

    if (e.key === 'ArrowUp') {
      this.grid.focusCell(activeRow - 1, activeColumn);
    } else {
      this.grid.focusCell(activeRow + 1, activeColumn);
    }

    const dataItem = this.grid.activeCell.dataItem;
    activeColumn = this.grid.activeCell.colIndex;
    activeRow = this.grid.activeCell.dataRowIndex;

    const formGroup = this.inCellEdit(dataItem);
    this.grid.editCell(activeRow, activeColumn, formGroup);
  }
}
