/**
 * Created by Tom.Jaeschke on 6/14/2017.
 */
export class PerDiemFile {
  FileName: string;
  Notes: string;
  NotesSafety: string;
  DocType: string;
  DocTypeSafety: string;
  ExpDate?: Date;
  ExpDateSafety?: Date;
  SrvDate?: Date;
  SrvDateSafety?: Date;
  IsDirty?: Boolean;

  //identity
  PerDiemFileID: string;
  FileID?: string;
  VPAttachmentID?: number;
  VPKeyID?: number;
  IsForbiddenFromDelete?: Boolean;
}
