/**
 * To understand original KENDO implementation, we need to understand:
 *
 * 1) how interpolation works (see last part) https://flaviocopes.com/javascript-template-literals/
 *    so the expression functionName`I paid ${10}€ then another ${10}€` will be interpreted as functionName(literals: string[], ...expressions)
 *
 * 2) currying https://basarat.gitbooks.io/typescript/docs/tips/currying.html
 *    functions are called from left to right. leftmost is evaluated with the 1st call parameter, the result is a function to which the next call parameters are supplied!
 *
 * Sample on INTERPOLATION:
 * ------------------------
 * const interpolated = interpolate`I paid ${10}€`
 *
 * and this is how interpolate works:
 *
 * function interpolate(literals, ...expressions) {
 *     let string = ``
 *     for (let i in expressions) {
 *         string += literals[i] + expressions[i]
 *     }
 *     string += literals.length > expressions.length ? literals[literals.length - 1] : ''; // NOTE: there must not be a literal aft. the last expression!
 *     return string
 * }
 *
 * So the main idea is that any function can be called with an interpolated string, where the 1st parameter is an array of strings and the rest are params to the given function, so common interfaces:
 * function interpolate(literals, ...expressions)
 * function interpolate(literals, expression)
 * function interpolate(literals, expression1, expression 2)
 *
 * there are 2 ways to execute this function:
 * 1) via interpolation: value = serializeKey`$skip=${state.skip}`
 * 2) classic: serializeKey(['$skip='], state.skip, ...)
 *
 * ---
 *
 * Sample on CURRYING:
 * -------------------
 * let curry = (a: number) => (b: number, c: number) => (d: number, e: number, f: number) => a + b + c + d + e + f;
 * let sum = curry(1)(2, 3)(4, 5, 6);
 *
 * functions are called from left to right. leftmost is evaluated with the 1st call parameter, the result is a function to which the next call parameters are supplied!
 * actually every call returns a new function...
 *
 * so the code above translates to:
 *
 * var curry = function (a) {
 *     return function (b, c) {
 *         return function (d, e, f) {
 *             return a + b + c + d + e + f;
 *         };
 *     };
 * };
 *
 * Some advanced implementations: https://medium.com/@hernanrajchert/creating-typings-for-curry-using-ts-3-x-956da2780bbf
 *
 * ---
 *
 * CURRYING IN KENDO toOdataString:
 * --------------------------------
 * export const either = (predicate, right, left) => value => predicate(value) ? right(value) : left(value);
 *
 * so actually it's eitherFn = (predicateFn, rightFn, leftFn) => value => predicateFn(value) ? rightFn(value) : leftFn(value);
 *
 * so it got translated to: eitherFn = function (predicate, right, left) {
 *     return function (value) {
 *         return predicate(value) ? right(value) : left(value);
 *     };
 * };
 *
 * so eitherFn(value) would result in: eitherFn(predicateFn(value), rightFn(value), leftFn(value))
 *
 */

import type { FilterDescriptor, State } from '@progress/kendo-data-query';

import { serializeFilter } from './odata-utils';
import { serializeSort } from './odata-sorting';
import { deepClone, isPresent } from '@ups/xplat/utils';

/** replaced the complex KENDO solution with a simple one */
const serializeKey = (keys, value) => (isPresent(value) ? keys[0] + value : '');

const rulesFn = (key, state, settings) =>
  ({
    filter: () => serializeFilter(state.filter || {}, settings),
    skip: () => serializeKey`$skip=${state.skip}`,
    sort: () => serializeSort(state.sort || [], settings),
    take: () => serializeKey`$top=${state.take}`,
  }[key]);

/**
 * Extended {@link toODataString} impl.
 * **IMPORTANT**: Just as the original {@link toODataString} the extended {@link toODataStringEx} requires also **typed values** inside {@link FilterDescriptor} to **work properly**!
 *
 * @example
 * some extras to support (in the filter)
 * - tolower on the DB level (due collation)
 * - convert to string so we can search in non string based values via startsWith, contains, endsWith...
 * - special handling of date values (focusing on .NET, OData and custom JSON stringify and parsing) & selecting a day (24h) period
 *
 * NOTES:
 * - by default sorting will be skipped for fields that do not have sort order defined (probably grid State implementation related decision of Telerik) - unless settings.sort.gridSort is set to FALSE
 */
export const toODataStringEx = (
  state: State,
  settings: ODataExSettings = new ODataExSettings(true)
): string => {
  // As we use object alteration instead of mutation, this extra step is necessary!
  const stateClone: State = deepClone(state);

  return Object.keys(stateClone)
    .map((x) => {
      const fn = rulesFn(x, stateClone, settings);
      if (fn) return fn();
      else return undefined;
    })
    .filter((x) => !!x)
    .join('&');
};

export class ODataExSettings {
  isMobile?: boolean;
  sort: {
    /** if true, sort by column will be ignored if dir (asc|desc) is not supplied within the SortDescriptor (default Kendo behavior) */
    gridSort: boolean;
  };

  filter: {
    /** if TRUE, will apply toLower always, regardless of the FilterDescriptor ignoreCase setting */
    globalIgnoreCase: boolean;
    /** if TRUE will apply toLower to value, if toLower is applied for the search */
    ignoreValueCase: boolean;
    /** if TRUE and date operator is eq/neq then the whole day will be eq/neq-ed so we will do a value >= day and value < day + 1 or value < day or value >= day + 1 */
    date24hMatch: boolean;

    /** it TRUE isnull and isblank will be translated to is null or isblank; while is not null and is not blank will become not null and not blank */
    useEmptyWithBlank: boolean;

    /** if TRUE, all 'in' operators will be translated to a composite operator with 'or' logic and inner filters using 'eq' operator */
    dontUseInOperator: boolean;

    //
    // Temporary patches

    // temporarily disabling IN for special cases: https://github.com/OData/WebApi/issues/2136
    dontUseInOperatorWithStringsContainingQuotes?: boolean;
  };

  constructor(
    caseInsensitive = true,
    dateEqNeqDayExtension = true,
    alwaysUseEmptyWithBlank = true,
    dontUseInOperator = false
  ) {
    this.sort = {
      gridSort: true,
    };

    this.filter = {
      globalIgnoreCase: caseInsensitive,
      ignoreValueCase: caseInsensitive,
      date24hMatch: dateEqNeqDayExtension,
      useEmptyWithBlank: alwaysUseEmptyWithBlank,
      dontUseInOperator: dontUseInOperator,

      //
      // Temporary patches
      dontUseInOperatorWithStringsContainingQuotes: true,
    };

    this.isMobile = false;
  }
}

export interface FilterDescriptorEx extends FilterDescriptor {
  /** if true, the search value will be wrapped with a tolower() function */
  ignoreValueCase?: boolean;

  /** if true, all eq/neq date searches will be considered as a search for dates within a day (so entered date + 1 day) or outside (in case of neq) */
  date24hMatch?: boolean;

  /** if true, the database value will be converted toString, this should allow us to use string-bases searches (like operator) over numbers, dates */
  convertToString?: boolean;

  /** it true isnull and isblank will be translated to is null or isblank; while is not null and is not blank will become not null and not blank */
  useEmptyWithBlank?: boolean;

  /** if true, all 'in' operators will be translated to a composite operator with 'or' logic and inner filters using 'eq' operator */
  dontUseInOperator?: boolean;

  /** if true, the search value will not be wrapped with quotes */
  skipQuotes?: boolean;
}
