/* eslint-disable @typescript-eslint/naming-convention */
import { BaseModel } from '../../base.model';

export class PhaseDto extends BaseModel {
  public PhaseID: string;
  public Area: string;
  public Description: string;
  public IsBillable: boolean;
  public OrderType: string;
  public PO: string;
  public PhaseName: string;
  public TrackLevel1: string;
  public TrackLevel2: string;
  public TrackLevel3: string;
  public TrackLevel4: string;
  public TrackLevel5: string;
  IsContractActive: boolean;
  bActive: boolean;
}
