<kendo-grid [data]="requirement.SubRequirements" [rowClass]="rowCallback">
  <kendo-grid-command-column
    *ngIf="!isReadOnly"
    field="Edit"
    title=""
    width="30"
  >
    <ng-template kendoGridHeaderTemplate>
      <button
        (click)="editModal.open()"
        class="btn btn-sm btn-info"
        title="Add Subtask"
      >
        <i class="fa fa-plus"></i>
        Add
      </button>
    </ng-template>
    <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
      <button
        *ngIf="dataItem.bActive"
        (click)="editModal.open(dataItem)"
        class="btn btn-sm btn-info"
        title="Update Subtask"
      >
        <i class="fa fa-edit"></i>
      </button>
      <button
        *ngIf="dataItem.bActive"
        (click)="dataItem.bActive = false"
        class="btn btn-sm btn-danger"
        title="Delete Subtask"
      >
        <i class="fa fa-remove"></i>
      </button>
      <button
        *ngIf="!dataItem.bActive"
        (click)="dataItem.bActive = true"
        class="btn btn-sm btn-warning"
        title="Undelete Subtask"
      >
        <i class="fa fa-refresh"></i>
      </button>
    </ng-template>
  </kendo-grid-command-column>

  <kendo-grid-column field="Name" title="Subtask" width="150">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ dataItem.Name }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="bMandatory" title="Mandatory" width="60">
    <ng-template kendoGridCellTemplate let-dataItem>
      <label class="k-form-field right">
        <input
          [disabled]="true"
          [(ngModel)]="dataItem.bMandatory"
          class="k-checkbox"
          id="checkMandatory"
          type="checkbox"
        />
        <label class="k-checkbox-label" for="checkMandatory"></label>
      </label>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

<ups-equipment-subtask-editor
  (editSubtask)="onEditSubtask($event)"
></ups-equipment-subtask-editor>
