import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageMap } from '@ngx-pwa/local-storage';
import type { IOfflineStorage } from '@ups/xplat/core';

// web specific offline data storage implementation (IndexedDB via @ngx-pwa/local-storage)

@Injectable()
export class WebOfflineStorageService implements IOfflineStorage {
  constructor(private storageMap: StorageMap) {}

  public setItem<T>(key: string, value: T): Observable<unknown> {
    return this.storageMap.set(key, value);
  }

  public getItem(key: string): Observable<unknown> {
    return this.storageMap.get(key);
  }

  public removeItem(key: string): Observable<unknown> {
    return this.storageMap.delete(key);
  }
}
