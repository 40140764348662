import { Pipe, PipeTransform } from '@angular/core';

declare let _: any;

@Pipe({
  name: 'DateFilter',
})
/**
 * Usage:
 * valid params lte, lt, eq, gt, gte
 *
 * DateFilter: 'field': type : value
 *
 * value = a date object.
 *
 * ts file:
 *
 * var searchDate = new Date();
 *
 * <tr *ngFor="let item of mf.data | DateFilter:'Until':'lte':searchDate:showNull">
 */
export class DateFilter implements PipeTransform {
  transform(values: any[], key: string, type: string, args: any, showNull: any): any {
    return values.filter((value) => {
      if (args == undefined) {
        return true;
      }

      if (args) {
        if (value.hasOwnProperty(key)) {
          //if there is a null or undefined value dont show it
          if (!value[key] && showNull == false) return false;
          else if (showNull == true && !value[key]) {
            return true;
          } else if (typeof value[key] == 'object' && typeof args == 'object') {
            const d = value[key];
            const tempDate = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0);

            if (type == 'lt') {
              if (tempDate < args) return true;
              else return false;
            } else if (type == 'lte') {
              if (tempDate <= args) return true;
              else return false;
            } else if (type == 'gt') {
              if (tempDate > args) return true;
              else return false;
            } else if (type == 'gte') {
              if (tempDate >= args) return true;
              else return false;
            } else if (type == 'eq') {
              if (tempDate >= args) return true;
              else return false;
            }
          }
        }
      }

      return true;
    });
  }
}
