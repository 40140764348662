/* eslint-disable @typescript-eslint/naming-convention */
import { BaseModel } from '../../base.model';

export class Address extends BaseModel {
  CustomerShippingRelationID: string | null;
  CustomerShippingDescription: string;
  Line1: string;
  Line2: string;
  City: string;
  State: string;
  Zip: string;
  Country: string;
  ShippingTo: string;
  Attention: string;
}

export interface AddressCamelCase {
  customerShippingRelationID: string | null;
  customerShippingDescription: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  shippingTo: string;
  attention: string;
}
