export const faCategories = {
  accessibility: {
    icons: [
      'accessible-icon',
      'american-sign-language-interpreting',
      'assistive-listening-systems',
      'audio-description',
      'blind',
      'braille',
      'closed-captioning',
      'deaf',
      'low-vision',
      'phone-volume',
      'question-circle',
      'sign-language',
      'tty',
      'universal-access',
      'wheelchair',
    ],
    label: 'Accessibility',
  },
  alert: {
    icons: [
      'bell',
      'bell-slash',
      'exclamation',
      'exclamation-circle',
      'exclamation-triangle',
      'radiation',
      'radiation-alt',
      'skull-crossbones',
    ],
    label: 'Alert',
  },
  animals: {
    icons: [
      'cat',
      'crow',
      'dog',
      'dove',
      'dragon',
      'feather',
      'feather-alt',
      'fish',
      'frog',
      'hippo',
      'horse',
      'horse-head',
      'kiwi-bird',
      'otter',
      'paw',
      'spider',
    ],
    label: 'Animals',
  },
  arrows: {
    icons: [
      'angle-double-down',
      'angle-double-left',
      'angle-double-right',
      'angle-double-up',
      'angle-down',
      'angle-left',
      'angle-right',
      'angle-up',
      'arrow-alt-circle-down',
      'arrow-alt-circle-left',
      'arrow-alt-circle-right',
      'arrow-alt-circle-up',
      'arrow-circle-down',
      'arrow-circle-left',
      'arrow-circle-right',
      'arrow-circle-up',
      'arrow-down',
      'arrow-left',
      'arrow-right',
      'arrow-up',
      'arrows-alt',
      'arrows-alt-h',
      'arrows-alt-v',
      'caret-down',
      'caret-left',
      'caret-right',
      'caret-square-down',
      'caret-square-left',
      'caret-square-right',
      'caret-square-up',
      'caret-up',
      'cart-arrow-down',
      'chart-line',
      'chevron-circle-down',
      'chevron-circle-left',
      'chevron-circle-right',
      'chevron-circle-up',
      'chevron-down',
      'chevron-left',
      'chevron-right',
      'chevron-up',
      'cloud-download-alt',
      'cloud-upload-alt',
      'compress-alt',
      'compress-arrows-alt',
      'download',
      'exchange-alt',
      'expand-alt',
      'expand-arrows-alt',
      'external-link-alt',
      'external-link-square-alt',
      'hand-point-down',
      'hand-point-left',
      'hand-point-right',
      'hand-point-up',
      'hand-pointer',
      'history',
      'level-down-alt',
      'level-up-alt',
      'location-arrow',
      'long-arrow-alt-down',
      'long-arrow-alt-left',
      'long-arrow-alt-right',
      'long-arrow-alt-up',
      'mouse-pointer',
      'play',
      'random',
      'recycle',
      'redo',
      'redo-alt',
      'reply',
      'reply-all',
      'retweet',
      'share',
      'share-square',
      'sign-in-alt',
      'sign-out-alt',
      'sort',
      'sort-alpha-down',
      'sort-alpha-down-alt',
      'sort-alpha-up',
      'sort-alpha-up-alt',
      'sort-amount-down',
      'sort-amount-down-alt',
      'sort-amount-up',
      'sort-amount-up-alt',
      'sort-down',
      'sort-numeric-down',
      'sort-numeric-down-alt',
      'sort-numeric-up',
      'sort-numeric-up-alt',
      'sort-up',
      'sync',
      'sync-alt',
      'text-height',
      'text-width',
      'undo',
      'undo-alt',
      'upload',
    ],
    label: 'Arrows',
  },
  'audio-video': {
    icons: [
      'audio-description',
      'backward',
      'broadcast-tower',
      'circle',
      'closed-captioning',
      'compress',
      'compress-alt',
      'compress-arrows-alt',
      'eject',
      'expand',
      'expand-alt',
      'expand-arrows-alt',
      'fast-backward',
      'fast-forward',
      'file-audio',
      'file-video',
      'film',
      'forward',
      'headphones',
      'microphone',
      'microphone-alt',
      'microphone-alt-slash',
      'microphone-slash',
      'music',
      'pause',
      'pause-circle',
      'phone-volume',
      'photo-video',
      'play',
      'play-circle',
      'podcast',
      'random',
      'redo',
      'redo-alt',
      'rss',
      'rss-square',
      'step-backward',
      'step-forward',
      'stop',
      'stop-circle',
      'sync',
      'sync-alt',
      'tv',
      'undo',
      'undo-alt',
      'video',
      'volume-down',
      'volume-mute',
      'volume-off',
      'volume-up',
      'youtube',
    ],
    label: 'Audio & Video',
  },
  automotive: {
    icons: [
      'air-freshener',
      'ambulance',
      'bus',
      'bus-alt',
      'car',
      'car-alt',
      'car-battery',
      'car-crash',
      'car-side',
      'caravan',
      'charging-station',
      'gas-pump',
      'motorcycle',
      'oil-can',
      'shuttle-van',
      'tachometer-alt',
      'taxi',
      'trailer',
      'truck',
      'truck-monster',
      'truck-pickup',
    ],
    label: 'Automotive',
  },
  autumn: {
    icons: [
      'apple-alt',
      'campground',
      'cloud-sun',
      'drumstick-bite',
      'football-ball',
      'hiking',
      'mountain',
      'tractor',
      'tree',
      'wind',
      'wine-bottle',
    ],
    label: 'Autumn',
  },
  beverage: {
    icons: [
      'beer',
      'blender',
      'cocktail',
      'coffee',
      'flask',
      'glass-cheers',
      'glass-martini',
      'glass-martini-alt',
      'glass-whiskey',
      'mug-hot',
      'wine-bottle',
      'wine-glass',
      'wine-glass-alt',
    ],
    label: 'Beverage',
  },
  buildings: {
    icons: [
      'archway',
      'building',
      'campground',
      'church',
      'city',
      'clinic-medical',
      'dungeon',
      'gopuram',
      'home',
      'hospital',
      'hospital-alt',
      'hospital-user',
      'hotel',
      'house-damage',
      'igloo',
      'industry',
      'kaaba',
      'landmark',
      'monument',
      'mosque',
      'place-of-worship',
      'school',
      'store',
      'store-alt',
      'synagogue',
      'torii-gate',
      'university',
      'vihara',
      'warehouse',
    ],
    label: 'Buildings',
  },
  business: {
    icons: [
      'address-book',
      'address-card',
      'archive',
      'balance-scale',
      'balance-scale-left',
      'balance-scale-right',
      'birthday-cake',
      'book',
      'briefcase',
      'building',
      'bullhorn',
      'bullseye',
      'business-time',
      'calculator',
      'calendar',
      'calendar-alt',
      'certificate',
      'chart-area',
      'chart-bar',
      'chart-line',
      'chart-pie',
      'city',
      'clipboard',
      'coffee',
      'columns',
      'compass',
      'copy',
      'copyright',
      'cut',
      'edit',
      'envelope',
      'envelope-open',
      'envelope-square',
      'eraser',
      'fax',
      'file',
      'file-alt',
      'folder',
      'folder-minus',
      'folder-open',
      'folder-plus',
      'glasses',
      'globe',
      'highlighter',
      'industry',
      'landmark',
      'laptop-house',
      'marker',
      'paperclip',
      'paste',
      'pen',
      'pen-alt',
      'pen-fancy',
      'pen-nib',
      'pen-square',
      'pencil-alt',
      'percent',
      'phone',
      'phone-alt',
      'phone-slash',
      'phone-square',
      'phone-square-alt',
      'phone-volume',
      'print',
      'project-diagram',
      'registered',
      'save',
      'sitemap',
      'socks',
      'sticky-note',
      'stream',
      'table',
      'tag',
      'tags',
      'tasks',
      'thumbtack',
      'trademark',
      'wallet',
    ],
    label: 'Business',
  },
  camping: {
    icons: [
      'binoculars',
      'campground',
      'caravan',
      'compass',
      'faucet',
      'fire',
      'fire-alt',
      'first-aid',
      'frog',
      'hiking',
      'map',
      'map-marked',
      'map-marked-alt',
      'map-signs',
      'mountain',
      'route',
      'toilet-paper',
      'trailer',
      'tree',
    ],
    label: 'Camping',
  },
  charity: {
    icons: [
      'dollar-sign',
      'donate',
      'dove',
      'gift',
      'globe',
      'hand-holding-heart',
      'hand-holding-usd',
      'hand-holding-water',
      'hands-helping',
      'handshake',
      'heart',
      'leaf',
      'parachute-box',
      'piggy-bank',
      'ribbon',
      'seedling',
    ],
    label: 'Charity',
  },
  chat: {
    icons: [
      'comment',
      'comment-alt',
      'comment-dots',
      'comment-medical',
      'comment-slash',
      'comments',
      'frown',
      'icons',
      'meh',
      'phone',
      'phone-alt',
      'phone-slash',
      'poo',
      'quote-left',
      'quote-right',
      'smile',
      'sms',
      'video',
      'video-slash',
    ],
    label: 'Chat',
  },
  chess: {
    icons: [
      'chess',
      'chess-bishop',
      'chess-board',
      'chess-king',
      'chess-knight',
      'chess-pawn',
      'chess-queen',
      'chess-rook',
      'square-full',
    ],
    label: 'Chess',
  },
  childhood: {
    icons: [
      'apple-alt',
      'baby',
      'baby-carriage',
      'bath',
      'biking',
      'birthday-cake',
      'cookie',
      'cookie-bite',
      'gamepad',
      'ice-cream',
      'mitten',
      'robot',
      'school',
      'shapes',
      'snowman',
    ],
    label: 'Childhood',
  },
  clothing: {
    icons: [
      'graduation-cap',
      'hat-cowboy',
      'hat-cowboy-side',
      'hat-wizard',
      'mitten',
      'shoe-prints',
      'socks',
      'tshirt',
      'user-tie',
    ],
    label: 'Clothing',
  },
  code: {
    icons: [
      'archive',
      'barcode',
      'bath',
      'bug',
      'code',
      'code-branch',
      'coffee',
      'file',
      'file-alt',
      'file-code',
      'filter',
      'fire-extinguisher',
      'folder',
      'folder-open',
      'keyboard',
      'laptop-code',
      'microchip',
      'project-diagram',
      'qrcode',
      'shield-alt',
      'sitemap',
      'stream',
      'terminal',
      'user-secret',
      'window-close',
      'window-maximize',
      'window-minimize',
      'window-restore',
    ],
    label: 'Code',
  },
  communication: {
    icons: [
      'address-book',
      'address-card',
      'american-sign-language-interpreting',
      'assistive-listening-systems',
      'at',
      'bell',
      'bell-slash',
      'bluetooth',
      'bluetooth-b',
      'broadcast-tower',
      'bullhorn',
      'chalkboard',
      'comment',
      'comment-alt',
      'comments',
      'envelope',
      'envelope-open',
      'envelope-square',
      'fax',
      'inbox',
      'language',
      'microphone',
      'microphone-alt',
      'microphone-alt-slash',
      'microphone-slash',
      'mobile',
      'mobile-alt',
      'paper-plane',
      'phone',
      'phone-alt',
      'phone-slash',
      'phone-square',
      'phone-square-alt',
      'phone-volume',
      'rss',
      'rss-square',
      'tty',
      'voicemail',
      'wifi',
    ],
    label: 'Communication',
  },
  computers: {
    icons: [
      'database',
      'desktop',
      'download',
      'ethernet',
      'hdd',
      'headphones',
      'keyboard',
      'laptop',
      'laptop-house',
      'memory',
      'microchip',
      'mobile',
      'mobile-alt',
      'mouse',
      'plug',
      'power-off',
      'print',
      'satellite',
      'satellite-dish',
      'save',
      'sd-card',
      'server',
      'sim-card',
      'stream',
      'tablet',
      'tablet-alt',
      'tv',
      'upload',
    ],
    label: 'Computers',
  },
  construction: {
    icons: [
      'brush',
      'drafting-compass',
      'dumpster',
      'hammer',
      'hard-hat',
      'paint-roller',
      'pencil-alt',
      'pencil-ruler',
      'ruler',
      'ruler-combined',
      'ruler-horizontal',
      'ruler-vertical',
      'screwdriver',
      'toolbox',
      'tools',
      'truck-pickup',
      'wrench',
    ],
    label: 'Construction',
  },
  currency: {
    icons: [
      'bitcoin',
      'btc',
      'dollar-sign',
      'ethereum',
      'euro-sign',
      'gg',
      'gg-circle',
      'hryvnia',
      'lira-sign',
      'money-bill',
      'money-bill-alt',
      'money-bill-wave',
      'money-bill-wave-alt',
      'money-check',
      'money-check-alt',
      'pound-sign',
      'ruble-sign',
      'rupee-sign',
      'shekel-sign',
      'tenge',
      'won-sign',
      'yen-sign',
    ],
    label: 'Currency',
  },
  'date-time': {
    icons: [
      'bell',
      'bell-slash',
      'calendar',
      'calendar-alt',
      'calendar-check',
      'calendar-minus',
      'calendar-plus',
      'calendar-times',
      'clock',
      'hourglass',
      'hourglass-end',
      'hourglass-half',
      'hourglass-start',
      'stopwatch',
    ],
    label: 'Date & Time',
  },
  design: {
    icons: [
      'adjust',
      'bezier-curve',
      'brush',
      'clone',
      'copy',
      'crop',
      'crop-alt',
      'crosshairs',
      'cut',
      'drafting-compass',
      'draw-polygon',
      'edit',
      'eraser',
      'eye',
      'eye-dropper',
      'eye-slash',
      'fill',
      'fill-drip',
      'highlighter',
      'icons',
      'layer-group',
      'magic',
      'marker',
      'object-group',
      'object-ungroup',
      'paint-brush',
      'paint-roller',
      'palette',
      'paste',
      'pen',
      'pen-alt',
      'pen-fancy',
      'pen-nib',
      'pencil-alt',
      'pencil-ruler',
      'ruler-combined',
      'ruler-horizontal',
      'ruler-vertical',
      'save',
      'splotch',
      'spray-can',
      'stamp',
      'swatchbook',
      'tint',
      'tint-slash',
      'vector-square',
    ],
    label: 'Design',
  },
  editors: {
    icons: [
      'align-center',
      'align-justify',
      'align-left',
      'align-right',
      'bold',
      'border-all',
      'border-none',
      'border-style',
      'clipboard',
      'clone',
      'columns',
      'copy',
      'cut',
      'edit',
      'eraser',
      'file',
      'file-alt',
      'font',
      'glasses',
      'heading',
      'highlighter',
      'i-cursor',
      'icons',
      'indent',
      'italic',
      'link',
      'list',
      'list-alt',
      'list-ol',
      'list-ul',
      'marker',
      'outdent',
      'paper-plane',
      'paperclip',
      'paragraph',
      'paste',
      'pen',
      'pen-alt',
      'pen-fancy',
      'pen-nib',
      'pencil-alt',
      'print',
      'quote-left',
      'quote-right',
      'redo',
      'redo-alt',
      'remove-format',
      'reply',
      'reply-all',
      'screwdriver',
      'share',
      'spell-check',
      'strikethrough',
      'subscript',
      'superscript',
      'sync',
      'sync-alt',
      'table',
      'tasks',
      'text-height',
      'text-width',
      'th',
      'th-large',
      'th-list',
      'tools',
      'trash',
      'trash-alt',
      'trash-restore',
      'trash-restore-alt',
      'underline',
      'undo',
      'undo-alt',
      'unlink',
      'wrench',
    ],
    label: 'Editors',
  },
  education: {
    icons: [
      'apple-alt',
      'atom',
      'award',
      'bell',
      'bell-slash',
      'book-open',
      'book-reader',
      'chalkboard',
      'chalkboard-teacher',
      'graduation-cap',
      'laptop-code',
      'microscope',
      'music',
      'school',
      'shapes',
      'theater-masks',
      'user-graduate',
    ],
    label: 'Education',
  },
  emoji: {
    icons: [
      'angry',
      'dizzy',
      'flushed',
      'frown',
      'frown-open',
      'grimace',
      'grin',
      'grin-alt',
      'grin-beam',
      'grin-beam-sweat',
      'grin-hearts',
      'grin-squint',
      'grin-squint-tears',
      'grin-stars',
      'grin-tears',
      'grin-tongue',
      'grin-tongue-squint',
      'grin-tongue-wink',
      'grin-wink',
      'kiss',
      'kiss-beam',
      'kiss-wink-heart',
      'laugh',
      'laugh-beam',
      'laugh-squint',
      'laugh-wink',
      'meh',
      'meh-blank',
      'meh-rolling-eyes',
      'sad-cry',
      'sad-tear',
      'smile',
      'smile-beam',
      'smile-wink',
      'surprise',
      'tired',
    ],
    label: 'Emoji',
  },
  energy: {
    icons: [
      'atom',
      'battery-empty',
      'battery-full',
      'battery-half',
      'battery-quarter',
      'battery-three-quarters',
      'broadcast-tower',
      'burn',
      'charging-station',
      'fan',
      'fire',
      'fire-alt',
      'gas-pump',
      'industry',
      'leaf',
      'lightbulb',
      'plug',
      'poop',
      'power-off',
      'radiation',
      'radiation-alt',
      'seedling',
      'solar-panel',
      'sun',
      'water',
      'wind',
    ],
    label: 'Energy',
  },
  files: {
    icons: [
      'archive',
      'clone',
      'copy',
      'cut',
      'file',
      'file-alt',
      'file-archive',
      'file-audio',
      'file-code',
      'file-excel',
      'file-image',
      'file-pdf',
      'file-powerpoint',
      'file-video',
      'file-word',
      'folder',
      'folder-open',
      'paste',
      'photo-video',
      'save',
      'sticky-note',
    ],
    label: 'Files',
  },
  finance: {
    icons: [
      'balance-scale',
      'balance-scale-left',
      'balance-scale-right',
      'book',
      'cash-register',
      'chart-line',
      'chart-pie',
      'coins',
      'comment-dollar',
      'comments-dollar',
      'credit-card',
      'donate',
      'file-invoice',
      'file-invoice-dollar',
      'hand-holding-usd',
      'landmark',
      'money-bill',
      'money-bill-alt',
      'money-bill-wave',
      'money-bill-wave-alt',
      'money-check',
      'money-check-alt',
      'percentage',
      'piggy-bank',
      'receipt',
      'stamp',
      'wallet',
    ],
    label: 'Finance',
  },
  fitness: {
    icons: [
      'bicycle',
      'biking',
      'burn',
      'fire-alt',
      'heart',
      'heartbeat',
      'hiking',
      'running',
      'shoe-prints',
      'skating',
      'skiing',
      'skiing-nordic',
      'snowboarding',
      'spa',
      'swimmer',
      'walking',
    ],
    label: 'Fitness',
  },
  food: {
    icons: [
      'apple-alt',
      'bacon',
      'bone',
      'bread-slice',
      'candy-cane',
      'carrot',
      'cheese',
      'cloud-meatball',
      'cookie',
      'drumstick-bite',
      'egg',
      'fish',
      'hamburger',
      'hotdog',
      'ice-cream',
      'lemon',
      'pepper-hot',
      'pizza-slice',
      'seedling',
      'stroopwafel',
    ],
    label: 'Food',
  },
  'fruit-vegetable': {
    icons: ['apple-alt', 'carrot', 'leaf', 'lemon', 'pepper-hot', 'seedling'],
    label: 'Fruits & Vegetables',
  },
  games: {
    icons: [
      'chess',
      'chess-bishop',
      'chess-board',
      'chess-king',
      'chess-knight',
      'chess-pawn',
      'chess-queen',
      'chess-rook',
      'dice',
      'dice-d20',
      'dice-d6',
      'dice-five',
      'dice-four',
      'dice-one',
      'dice-six',
      'dice-three',
      'dice-two',
      'gamepad',
      'ghost',
      'headset',
      'heart',
      'playstation',
      'puzzle-piece',
      'steam',
      'steam-square',
      'steam-symbol',
      'twitch',
      'xbox',
    ],
    label: 'Games',
  },
  'gaming-tabletop': {
    icons: [
      'acquisitions-incorporated',
      'book-dead',
      'critical-role',
      'd-and-d',
      'd-and-d-beyond',
      'dice-d20',
      'dice-d6',
      'dragon',
      'dungeon',
      'fantasy-flight-games',
      'fist-raised',
      'hat-wizard',
      'penny-arcade',
      'ring',
      'scroll',
      'skull-crossbones',
      'wizards-of-the-coast',
    ],
    label: 'Tabletop Gaming',
  },
  gender: {
    icons: [
      'genderless',
      'mars',
      'mars-double',
      'mars-stroke',
      'mars-stroke-h',
      'mars-stroke-v',
      'mercury',
      'neuter',
      'transgender',
      'transgender-alt',
      'venus',
      'venus-double',
      'venus-mars',
    ],
    label: 'Genders',
  },
  halloween: {
    icons: [
      'book-dead',
      'broom',
      'cat',
      'cloud-moon',
      'crow',
      'ghost',
      'hat-wizard',
      'mask',
      'skull-crossbones',
      'spider',
      'toilet-paper',
    ],
    label: 'Halloween',
  },
  hands: {
    icons: [
      'allergies',
      'fist-raised',
      'hand-holding',
      'hand-holding-heart',
      'hand-holding-medical',
      'hand-holding-usd',
      'hand-holding-water',
      'hand-lizard',
      'hand-middle-finger',
      'hand-paper',
      'hand-peace',
      'hand-point-down',
      'hand-point-left',
      'hand-point-right',
      'hand-point-up',
      'hand-pointer',
      'hand-rock',
      'hand-scissors',
      'hand-sparkles',
      'hand-spock',
      'hands',
      'hands-helping',
      'hands-wash',
      'handshake',
      'handshake-alt-slash',
      'handshake-slash',
      'praying-hands',
      'thumbs-down',
      'thumbs-up',
    ],
    label: 'Hands',
  },
  health: {
    icons: [
      'accessible-icon',
      'ambulance',
      'h-square',
      'heart',
      'heartbeat',
      'hospital',
      'medkit',
      'plus-square',
      'prescription',
      'stethoscope',
      'user-md',
      'wheelchair',
    ],
    label: 'Health',
  },
  holiday: {
    icons: [
      'candy-cane',
      'carrot',
      'cookie-bite',
      'gift',
      'gifts',
      'glass-cheers',
      'holly-berry',
      'mug-hot',
      'sleigh',
      'snowman',
    ],
    label: 'Holiday',
  },
  hotel: {
    icons: [
      'baby-carriage',
      'bath',
      'bed',
      'briefcase',
      'car',
      'cocktail',
      'coffee',
      'concierge-bell',
      'dice',
      'dice-five',
      'door-closed',
      'door-open',
      'dumbbell',
      'glass-martini',
      'glass-martini-alt',
      'hot-tub',
      'hotel',
      'infinity',
      'key',
      'luggage-cart',
      'shower',
      'shuttle-van',
      'smoking',
      'smoking-ban',
      'snowflake',
      'spa',
      'suitcase',
      'suitcase-rolling',
      'swimmer',
      'swimming-pool',
      'tv',
      'umbrella-beach',
      'utensils',
      'wheelchair',
      'wifi',
    ],
    label: 'Hotel',
  },
  household: {
    icons: [
      'bath',
      'bed',
      'bell',
      'blender',
      'box-tissue',
      'chair',
      'coffee',
      'couch',
      'door-closed',
      'door-open',
      'dungeon',
      'fan',
      'faucet',
      'house-user',
      'laptop-house',
      'lightbulb',
      'plug',
      'pump-soap',
      'shower',
      'sink',
      'snowflake',
      'soap',
      'toilet-paper',
      'toilet-paper-slash',
      'tv',
    ],
    label: 'Household',
  },
  images: {
    icons: [
      'adjust',
      'bolt',
      'camera',
      'camera-retro',
      'chalkboard',
      'clone',
      'compress',
      'compress-arrows-alt',
      'expand',
      'eye',
      'eye-dropper',
      'eye-slash',
      'file-image',
      'film',
      'id-badge',
      'id-card',
      'image',
      'images',
      'photo-video',
      'portrait',
      'sliders-h',
      'tint',
      'unsplash',
    ],
    label: 'Images',
  },
  interfaces: {
    icons: [
      'award',
      'ban',
      'barcode',
      'bars',
      'beer',
      'bell',
      'bell-slash',
      'blog',
      'bug',
      'bullhorn',
      'bullseye',
      'calculator',
      'calendar',
      'calendar-alt',
      'calendar-check',
      'calendar-minus',
      'calendar-plus',
      'calendar-times',
      'certificate',
      'check',
      'check-circle',
      'check-double',
      'check-square',
      'circle',
      'clipboard',
      'clone',
      'cloud',
      'cloud-download-alt',
      'cloud-upload-alt',
      'coffee',
      'cog',
      'cogs',
      'copy',
      'cut',
      'database',
      'dot-circle',
      'download',
      'edit',
      'ellipsis-h',
      'ellipsis-v',
      'envelope',
      'envelope-open',
      'eraser',
      'exclamation',
      'exclamation-circle',
      'exclamation-triangle',
      'external-link-alt',
      'external-link-square-alt',
      'eye',
      'eye-slash',
      'file',
      'file-alt',
      'file-download',
      'file-export',
      'file-import',
      'file-upload',
      'filter',
      'fingerprint',
      'flag',
      'flag-checkered',
      'folder',
      'folder-open',
      'frown',
      'glasses',
      'grip-horizontal',
      'grip-lines',
      'grip-lines-vertical',
      'grip-vertical',
      'hashtag',
      'heart',
      'history',
      'home',
      'i-cursor',
      'info',
      'info-circle',
      'language',
      'magic',
      'marker',
      'medal',
      'meh',
      'microphone',
      'microphone-alt',
      'microphone-slash',
      'minus',
      'minus-circle',
      'minus-square',
      'paste',
      'pen',
      'pen-alt',
      'pen-fancy',
      'pencil-alt',
      'plus',
      'plus-circle',
      'plus-square',
      'poo',
      'qrcode',
      'question',
      'question-circle',
      'quote-left',
      'quote-right',
      'redo',
      'redo-alt',
      'reply',
      'reply-all',
      'rss',
      'rss-square',
      'save',
      'screwdriver',
      'search',
      'search-minus',
      'search-plus',
      'share',
      'share-alt',
      'share-alt-square',
      'share-square',
      'shield-alt',
      'sign-in-alt',
      'sign-out-alt',
      'signal',
      'sitemap',
      'sliders-h',
      'smile',
      'sort',
      'sort-alpha-down',
      'sort-alpha-down-alt',
      'sort-alpha-up',
      'sort-alpha-up-alt',
      'sort-amount-down',
      'sort-amount-down-alt',
      'sort-amount-up',
      'sort-amount-up-alt',
      'sort-down',
      'sort-numeric-down',
      'sort-numeric-down-alt',
      'sort-numeric-up',
      'sort-numeric-up-alt',
      'sort-up',
      'star',
      'star-half',
      'sync',
      'sync-alt',
      'thumbs-down',
      'thumbs-up',
      'times',
      'times-circle',
      'toggle-off',
      'toggle-on',
      'tools',
      'trash',
      'trash-alt',
      'trash-restore',
      'trash-restore-alt',
      'trophy',
      'undo',
      'undo-alt',
      'upload',
      'user',
      'user-alt',
      'user-circle',
      'volume-down',
      'volume-mute',
      'volume-off',
      'volume-up',
      'wifi',
      'wrench',
    ],
    label: 'Interfaces',
  },
  logistics: {
    icons: [
      'box',
      'boxes',
      'clipboard-check',
      'clipboard-list',
      'dolly',
      'dolly-flatbed',
      'hard-hat',
      'pallet',
      'shipping-fast',
      'truck',
      'warehouse',
    ],
    label: 'Logistics',
  },
  maps: {
    icons: [
      'ambulance',
      'anchor',
      'balance-scale',
      'balance-scale-left',
      'balance-scale-right',
      'bath',
      'bed',
      'beer',
      'bell',
      'bell-slash',
      'bicycle',
      'binoculars',
      'birthday-cake',
      'blind',
      'bomb',
      'book',
      'bookmark',
      'briefcase',
      'building',
      'car',
      'coffee',
      'crosshairs',
      'directions',
      'dollar-sign',
      'draw-polygon',
      'eye',
      'eye-slash',
      'fighter-jet',
      'fire',
      'fire-alt',
      'fire-extinguisher',
      'flag',
      'flag-checkered',
      'flask',
      'gamepad',
      'gavel',
      'gift',
      'glass-martini',
      'globe',
      'graduation-cap',
      'h-square',
      'heart',
      'heartbeat',
      'helicopter',
      'home',
      'hospital',
      'image',
      'images',
      'industry',
      'info',
      'info-circle',
      'key',
      'landmark',
      'layer-group',
      'leaf',
      'lemon',
      'life-ring',
      'lightbulb',
      'location-arrow',
      'low-vision',
      'magnet',
      'male',
      'map',
      'map-marker',
      'map-marker-alt',
      'map-pin',
      'map-signs',
      'medkit',
      'money-bill',
      'money-bill-alt',
      'motorcycle',
      'music',
      'newspaper',
      'parking',
      'paw',
      'phone',
      'phone-alt',
      'phone-square',
      'phone-square-alt',
      'phone-volume',
      'plane',
      'plug',
      'plus',
      'plus-square',
      'print',
      'recycle',
      'restroom',
      'road',
      'rocket',
      'route',
      'search',
      'search-minus',
      'search-plus',
      'ship',
      'shoe-prints',
      'shopping-bag',
      'shopping-basket',
      'shopping-cart',
      'shower',
      'snowplow',
      'street-view',
      'subway',
      'suitcase',
      'tag',
      'tags',
      'taxi',
      'thumbtack',
      'ticket-alt',
      'tint',
      'traffic-light',
      'train',
      'tram',
      'tree',
      'trophy',
      'truck',
      'tty',
      'umbrella',
      'university',
      'utensil-spoon',
      'utensils',
      'wheelchair',
      'wifi',
      'wine-glass',
      'wrench',
    ],
    label: 'Maps',
  },
  maritime: {
    icons: [
      'anchor',
      'binoculars',
      'compass',
      'dharmachakra',
      'frog',
      'ship',
      'skull-crossbones',
      'swimmer',
      'water',
      'wind',
    ],
    label: 'Maritime',
  },
  marketing: {
    icons: [
      'ad',
      'bullhorn',
      'bullseye',
      'comment-dollar',
      'comments-dollar',
      'envelope-open-text',
      'funnel-dollar',
      'lightbulb',
      'mail-bulk',
      'poll',
      'poll-h',
      'search-dollar',
      'search-location',
    ],
    label: 'Marketing',
  },
  mathematics: {
    icons: [
      'calculator',
      'divide',
      'equals',
      'greater-than',
      'greater-than-equal',
      'infinity',
      'less-than',
      'less-than-equal',
      'minus',
      'not-equal',
      'percentage',
      'plus',
      'square-root-alt',
      'subscript',
      'superscript',
      'times',
      'wave-square',
    ],
    label: 'Mathematics',
  },
  medical: {
    icons: [
      'allergies',
      'ambulance',
      'bacteria',
      'bacterium',
      'band-aid',
      'biohazard',
      'bone',
      'bong',
      'book-medical',
      'brain',
      'briefcase-medical',
      'burn',
      'cannabis',
      'capsules',
      'clinic-medical',
      'comment-medical',
      'crutch',
      'diagnoses',
      'disease',
      'dna',
      'file-medical',
      'file-medical-alt',
      'file-prescription',
      'first-aid',
      'hand-holding-medical',
      'head-side-cough',
      'head-side-cough-slash',
      'head-side-mask',
      'head-side-virus',
      'heart',
      'heartbeat',
      'hospital',
      'hospital-alt',
      'hospital-symbol',
      'hospital-user',
      'id-card-alt',
      'joint',
      'laptop-medical',
      'lungs',
      'lungs-virus',
      'microscope',
      'mortar-pestle',
      'notes-medical',
      'pager',
      'pills',
      'plus',
      'poop',
      'prescription',
      'prescription-bottle',
      'prescription-bottle-alt',
      'procedures',
      'pump-medical',
      'radiation',
      'radiation-alt',
      'shield-virus',
      'smoking',
      'smoking-ban',
      'star-of-life',
      'stethoscope',
      'syringe',
      'tablets',
      'teeth',
      'teeth-open',
      'thermometer',
      'tooth',
      'user-md',
      'user-nurse',
      'vial',
      'vials',
      'virus',
      'virus-slash',
      'viruses',
      'weight',
      'x-ray',
    ],
    label: 'Medical',
  },
  moving: {
    icons: [
      'archive',
      'box-open',
      'caravan',
      'couch',
      'dolly',
      'people-carry',
      'route',
      'sign',
      'suitcase',
      'tape',
      'trailer',
      'truck-loading',
      'truck-moving',
      'wine-glass',
    ],
    label: 'Moving',
  },
  music: {
    icons: [
      'drum',
      'drum-steelpan',
      'file-audio',
      'guitar',
      'headphones',
      'headphones-alt',
      'microphone',
      'microphone-alt',
      'microphone-alt-slash',
      'microphone-slash',
      'music',
      'napster',
      'play',
      'record-vinyl',
      'sliders-h',
      'soundcloud',
      'spotify',
      'volume-down',
      'volume-mute',
      'volume-off',
      'volume-up',
    ],
    label: 'Music',
  },
  objects: {
    icons: [
      'ambulance',
      'anchor',
      'archive',
      'award',
      'baby-carriage',
      'balance-scale',
      'balance-scale-left',
      'balance-scale-right',
      'bath',
      'bed',
      'beer',
      'bell',
      'bicycle',
      'binoculars',
      'birthday-cake',
      'blender',
      'bomb',
      'book',
      'book-dead',
      'bookmark',
      'briefcase',
      'broadcast-tower',
      'bug',
      'building',
      'bullhorn',
      'bullseye',
      'bus',
      'calculator',
      'calendar',
      'calendar-alt',
      'camera',
      'camera-retro',
      'candy-cane',
      'car',
      'carrot',
      'church',
      'clipboard',
      'cloud',
      'coffee',
      'cog',
      'cogs',
      'compass',
      'cookie',
      'cookie-bite',
      'copy',
      'cube',
      'cubes',
      'cut',
      'dice',
      'dice-d20',
      'dice-d6',
      'dice-five',
      'dice-four',
      'dice-one',
      'dice-six',
      'dice-three',
      'dice-two',
      'digital-tachograph',
      'door-closed',
      'door-open',
      'drum',
      'drum-steelpan',
      'envelope',
      'envelope-open',
      'eraser',
      'eye',
      'eye-dropper',
      'fax',
      'feather',
      'feather-alt',
      'fighter-jet',
      'file',
      'file-alt',
      'file-prescription',
      'film',
      'fire',
      'fire-alt',
      'fire-extinguisher',
      'flag',
      'flag-checkered',
      'flask',
      'futbol',
      'gamepad',
      'gavel',
      'gem',
      'gift',
      'gifts',
      'glass-cheers',
      'glass-martini',
      'glass-whiskey',
      'glasses',
      'globe',
      'graduation-cap',
      'guitar',
      'hat-wizard',
      'hdd',
      'headphones',
      'headphones-alt',
      'headset',
      'heart',
      'heart-broken',
      'helicopter',
      'highlighter',
      'holly-berry',
      'home',
      'hospital',
      'hourglass',
      'igloo',
      'image',
      'images',
      'industry',
      'key',
      'keyboard',
      'laptop',
      'leaf',
      'lemon',
      'life-ring',
      'lightbulb',
      'lock',
      'lock-open',
      'magic',
      'magnet',
      'map',
      'map-marker',
      'map-marker-alt',
      'map-pin',
      'map-signs',
      'marker',
      'medal',
      'medkit',
      'memory',
      'microchip',
      'microphone',
      'microphone-alt',
      'mitten',
      'mobile',
      'mobile-alt',
      'money-bill',
      'money-bill-alt',
      'money-check',
      'money-check-alt',
      'moon',
      'motorcycle',
      'mug-hot',
      'newspaper',
      'paint-brush',
      'paper-plane',
      'paperclip',
      'paste',
      'paw',
      'pen',
      'pen-alt',
      'pen-fancy',
      'pen-nib',
      'pencil-alt',
      'phone',
      'phone-alt',
      'plane',
      'plug',
      'print',
      'puzzle-piece',
      'ring',
      'road',
      'rocket',
      'ruler-combined',
      'ruler-horizontal',
      'ruler-vertical',
      'satellite',
      'satellite-dish',
      'save',
      'school',
      'screwdriver',
      'scroll',
      'sd-card',
      'search',
      'shield-alt',
      'shopping-bag',
      'shopping-basket',
      'shopping-cart',
      'shower',
      'sim-card',
      'skull-crossbones',
      'sleigh',
      'snowflake',
      'snowplow',
      'space-shuttle',
      'star',
      'sticky-note',
      'stopwatch',
      'stroopwafel',
      'subway',
      'suitcase',
      'sun',
      'tablet',
      'tablet-alt',
      'tachometer-alt',
      'tag',
      'tags',
      'taxi',
      'thumbtack',
      'ticket-alt',
      'toilet',
      'toolbox',
      'tools',
      'train',
      'tram',
      'trash',
      'trash-alt',
      'tree',
      'trophy',
      'truck',
      'tv',
      'umbrella',
      'university',
      'unlock',
      'unlock-alt',
      'utensil-spoon',
      'utensils',
      'wallet',
      'weight',
      'wheelchair',
      'wine-glass',
      'wrench',
    ],
    label: 'Objects',
  },
  'payments-shopping': {
    icons: [
      'alipay',
      'amazon-pay',
      'apple-pay',
      'bell',
      'bitcoin',
      'bookmark',
      'btc',
      'bullhorn',
      'camera',
      'camera-retro',
      'cart-arrow-down',
      'cart-plus',
      'cc-amazon-pay',
      'cc-amex',
      'cc-apple-pay',
      'cc-diners-club',
      'cc-discover',
      'cc-jcb',
      'cc-mastercard',
      'cc-paypal',
      'cc-stripe',
      'cc-visa',
      'certificate',
      'credit-card',
      'ethereum',
      'gem',
      'gift',
      'google-pay',
      'google-wallet',
      'handshake',
      'heart',
      'key',
      'money-check',
      'money-check-alt',
      'paypal',
      'receipt',
      'shopping-bag',
      'shopping-basket',
      'shopping-cart',
      'star',
      'stripe',
      'stripe-s',
      'tag',
      'tags',
      'thumbs-down',
      'thumbs-up',
      'trophy',
    ],
    label: 'Payments & Shopping',
  },
  pharmacy: {
    icons: [
      'band-aid',
      'book-medical',
      'cannabis',
      'capsules',
      'clinic-medical',
      'disease',
      'eye-dropper',
      'file-medical',
      'file-prescription',
      'first-aid',
      'flask',
      'history',
      'joint',
      'laptop-medical',
      'mortar-pestle',
      'notes-medical',
      'pills',
      'prescription',
      'prescription-bottle',
      'prescription-bottle-alt',
      'receipt',
      'skull-crossbones',
      'syringe',
      'tablets',
      'thermometer',
      'vial',
      'vials',
    ],
    label: 'Pharmacy',
  },
  political: {
    icons: [
      'award',
      'balance-scale',
      'balance-scale-left',
      'balance-scale-right',
      'bullhorn',
      'check-double',
      'democrat',
      'donate',
      'dove',
      'fist-raised',
      'flag-usa',
      'handshake',
      'person-booth',
      'piggy-bank',
      'republican',
      'vote-yea',
    ],
    label: 'Political',
  },
  religion: {
    icons: [
      'ankh',
      'atom',
      'bahai',
      'bible',
      'church',
      'cross',
      'dharmachakra',
      'dove',
      'gopuram',
      'hamsa',
      'hanukiah',
      'jedi',
      'journal-whills',
      'kaaba',
      'khanda',
      'menorah',
      'mosque',
      'om',
      'pastafarianism',
      'peace',
      'place-of-worship',
      'pray',
      'praying-hands',
      'quran',
      'star-and-crescent',
      'star-of-david',
      'synagogue',
      'torah',
      'torii-gate',
      'vihara',
      'yin-yang',
    ],
    label: 'Religion',
  },
  science: {
    icons: [
      'atom',
      'biohazard',
      'brain',
      'burn',
      'capsules',
      'clipboard-check',
      'disease',
      'dna',
      'eye-dropper',
      'filter',
      'fire',
      'fire-alt',
      'flask',
      'frog',
      'magnet',
      'microscope',
      'mortar-pestle',
      'pills',
      'prescription-bottle',
      'radiation',
      'radiation-alt',
      'seedling',
      'skull-crossbones',
      'syringe',
      'tablets',
      'temperature-high',
      'temperature-low',
      'vial',
      'vials',
    ],
    label: 'Science',
  },
  'science-fiction': {
    icons: [
      'atom',
      'galactic-republic',
      'galactic-senate',
      'globe',
      'hand-spock',
      'jedi',
      'jedi-order',
      'journal-whills',
      'meteor',
      'moon',
      'old-republic',
      'robot',
      'rocket',
      'satellite',
      'satellite-dish',
      'space-shuttle',
      'user-astronaut',
    ],
    label: 'Science Fiction',
  },
  security: {
    icons: [
      'ban',
      'bug',
      'door-closed',
      'door-open',
      'dungeon',
      'eye',
      'eye-slash',
      'file-contract',
      'file-signature',
      'fingerprint',
      'id-badge',
      'id-card',
      'id-card-alt',
      'key',
      'lock',
      'lock-open',
      'mask',
      'passport',
      'shield-alt',
      'unlock',
      'unlock-alt',
      'user-lock',
      'user-secret',
      'user-shield',
    ],
    label: 'Security',
  },
  shapes: {
    icons: [
      'bookmark',
      'calendar',
      'certificate',
      'circle',
      'cloud',
      'comment',
      'file',
      'folder',
      'heart',
      'heart-broken',
      'map-marker',
      'play',
      'shapes',
      'square',
      'star',
    ],
    label: 'Shapes',
  },
  shopping: {
    icons: [
      'barcode',
      'cart-arrow-down',
      'cart-plus',
      'cash-register',
      'gift',
      'gifts',
      'person-booth',
      'receipt',
      'shipping-fast',
      'shopping-bag',
      'shopping-basket',
      'shopping-cart',
      'store',
      'store-alt',
      'store-alt-slash',
      'store-slash',
      'truck',
      'tshirt',
    ],
    label: 'Shopping',
  },
  social: {
    icons: [
      'bell',
      'birthday-cake',
      'camera',
      'comment',
      'comment-alt',
      'envelope',
      'hashtag',
      'heart',
      'icons',
      'image',
      'images',
      'map-marker',
      'map-marker-alt',
      'photo-video',
      'poll',
      'poll-h',
      'retweet',
      'share',
      'share-alt',
      'share-square',
      'star',
      'thumbs-down',
      'thumbs-up',
      'thumbtack',
      'user',
      'user-circle',
      'user-friends',
      'user-plus',
      'users',
      'video',
    ],
    label: 'Social',
  },
  spinners: {
    icons: [
      'asterisk',
      'atom',
      'bahai',
      'certificate',
      'circle-notch',
      'cog',
      'compact-disc',
      'compass',
      'crosshairs',
      'dharmachakra',
      'fan',
      'life-ring',
      'palette',
      'ring',
      'slash',
      'snowflake',
      'spinner',
      'stroopwafel',
      'sun',
      'sync',
      'sync-alt',
      'yin-yang',
    ],
    label: 'Spinners',
  },
  sports: {
    icons: [
      'baseball-ball',
      'basketball-ball',
      'biking',
      'bowling-ball',
      'dumbbell',
      'football-ball',
      'futbol',
      'golf-ball',
      'hockey-puck',
      'quidditch',
      'running',
      'skating',
      'skiing',
      'skiing-nordic',
      'snowboarding',
      'swimmer',
      'table-tennis',
      'volleyball-ball',
    ],
    label: 'Sports',
  },
  spring: {
    icons: [
      'allergies',
      'broom',
      'cloud-sun',
      'cloud-sun-rain',
      'frog',
      'rainbow',
      'seedling',
      'umbrella',
    ],
    label: 'Spring',
  },
  status: {
    icons: [
      'ban',
      'battery-empty',
      'battery-full',
      'battery-half',
      'battery-quarter',
      'battery-three-quarters',
      'bell',
      'bell-slash',
      'calendar',
      'calendar-alt',
      'calendar-check',
      'calendar-day',
      'calendar-minus',
      'calendar-plus',
      'calendar-times',
      'calendar-week',
      'cart-arrow-down',
      'cart-plus',
      'comment',
      'comment-alt',
      'comment-slash',
      'compass',
      'door-closed',
      'door-open',
      'exclamation',
      'exclamation-circle',
      'exclamation-triangle',
      'eye',
      'eye-slash',
      'file',
      'file-alt',
      'folder',
      'folder-open',
      'gas-pump',
      'info',
      'info-circle',
      'lightbulb',
      'lock',
      'lock-open',
      'map-marker',
      'map-marker-alt',
      'microphone',
      'microphone-alt',
      'microphone-alt-slash',
      'microphone-slash',
      'minus',
      'minus-circle',
      'minus-square',
      'parking',
      'phone',
      'phone-alt',
      'phone-slash',
      'plus',
      'plus-circle',
      'plus-square',
      'print',
      'question',
      'question-circle',
      'shield-alt',
      'shopping-cart',
      'sign-in-alt',
      'sign-out-alt',
      'signal',
      'smoking-ban',
      'star',
      'star-half',
      'star-half-alt',
      'stream',
      'thermometer-empty',
      'thermometer-full',
      'thermometer-half',
      'thermometer-quarter',
      'thermometer-three-quarters',
      'thumbs-down',
      'thumbs-up',
      'tint',
      'tint-slash',
      'toggle-off',
      'toggle-on',
      'unlock',
      'unlock-alt',
      'user',
      'user-alt',
      'user-alt-slash',
      'user-slash',
      'video',
      'video-slash',
      'volume-down',
      'volume-mute',
      'volume-off',
      'volume-up',
      'wifi',
    ],
    label: 'Status',
  },
  summer: {
    icons: [
      'anchor',
      'biking',
      'fish',
      'hotdog',
      'ice-cream',
      'lemon',
      'sun',
      'swimmer',
      'swimming-pool',
      'umbrella-beach',
      'volleyball-ball',
      'water',
    ],
    label: 'Summer',
  },
  toggle: {
    icons: [
      'bullseye',
      'check-circle',
      'circle',
      'dot-circle',
      'microphone',
      'microphone-slash',
      'star',
      'star-half',
      'star-half-alt',
      'toggle-off',
      'toggle-on',
      'wifi',
    ],
    label: 'Toggle',
  },
  travel: {
    icons: [
      'archway',
      'atlas',
      'bed',
      'bus',
      'bus-alt',
      'caravan',
      'cocktail',
      'concierge-bell',
      'dumbbell',
      'glass-martini',
      'glass-martini-alt',
      'globe-africa',
      'globe-americas',
      'globe-asia',
      'globe-europe',
      'hot-tub',
      'hotel',
      'luggage-cart',
      'map',
      'map-marked',
      'map-marked-alt',
      'monument',
      'passport',
      'plane',
      'plane-arrival',
      'plane-departure',
      'shuttle-van',
      'spa',
      'suitcase',
      'suitcase-rolling',
      'swimmer',
      'swimming-pool',
      'taxi',
      'tram',
      'tv',
      'umbrella-beach',
      'wine-glass',
      'wine-glass-alt',
    ],
    label: 'Travel',
  },
  'users-people': {
    icons: [
      'accessible-icon',
      'address-book',
      'address-card',
      'baby',
      'bed',
      'biking',
      'blind',
      'chalkboard-teacher',
      'child',
      'female',
      'frown',
      'hiking',
      'id-badge',
      'id-card',
      'id-card-alt',
      'male',
      'meh',
      'people-arrows',
      'people-carry',
      'person-booth',
      'poo',
      'portrait',
      'power-off',
      'pray',
      'restroom',
      'running',
      'skating',
      'skiing',
      'skiing-nordic',
      'smile',
      'snowboarding',
      'street-view',
      'swimmer',
      'user',
      'user-alt',
      'user-alt-slash',
      'user-astronaut',
      'user-check',
      'user-circle',
      'user-clock',
      'user-cog',
      'user-edit',
      'user-friends',
      'user-graduate',
      'user-injured',
      'user-lock',
      'user-md',
      'user-minus',
      'user-ninja',
      'user-nurse',
      'user-plus',
      'user-secret',
      'user-shield',
      'user-slash',
      'user-tag',
      'user-tie',
      'user-times',
      'users',
      'users-cog',
      'users-slash',
      'walking',
      'wheelchair',
    ],
    label: 'Users & People',
  },
  vehicles: {
    icons: [
      'accessible-icon',
      'ambulance',
      'baby-carriage',
      'bicycle',
      'bus',
      'bus-alt',
      'car',
      'car-alt',
      'car-crash',
      'car-side',
      'fighter-jet',
      'helicopter',
      'horse',
      'motorcycle',
      'paper-plane',
      'plane',
      'rocket',
      'ship',
      'shopping-cart',
      'shuttle-van',
      'sleigh',
      'snowplow',
      'space-shuttle',
      'subway',
      'taxi',
      'tractor',
      'train',
      'tram',
      'truck',
      'truck-monster',
      'truck-pickup',
      'wheelchair',
    ],
    label: 'Vehicles',
  },
  weather: {
    icons: [
      'bolt',
      'cloud',
      'cloud-meatball',
      'cloud-moon',
      'cloud-moon-rain',
      'cloud-rain',
      'cloud-showers-heavy',
      'cloud-sun',
      'cloud-sun-rain',
      'meteor',
      'moon',
      'poo-storm',
      'rainbow',
      'smog',
      'snowflake',
      'sun',
      'temperature-high',
      'temperature-low',
      'umbrella',
      'water',
      'wind',
    ],
    label: 'Weather',
  },
  winter: {
    icons: [
      'glass-whiskey',
      'icicles',
      'igloo',
      'mitten',
      'skating',
      'skiing',
      'skiing-nordic',
      'snowboarding',
      'snowplow',
      'tram',
    ],
    label: 'Winter',
  },
  writing: {
    icons: [
      'archive',
      'blog',
      'book',
      'bookmark',
      'edit',
      'envelope',
      'envelope-open',
      'eraser',
      'file',
      'file-alt',
      'folder',
      'folder-open',
      'keyboard',
      'newspaper',
      'paper-plane',
      'paperclip',
      'paragraph',
      'pen',
      'pen-alt',
      'pen-square',
      'pencil-alt',
      'quote-left',
      'quote-right',
      'sticky-note',
      'thumbtack',
    ],
    label: 'Writing',
  },
};
