import { BaseModel } from '../base.model';
import { JobRequestedClassDto } from './JobRequestedClassDto';

export class RequestedClassWithCountDto extends BaseModel {
  public count: number;
  public jobRequestedClass: JobRequestedClassDto;

  constructor(o: any = null) {
    super(o);
  }
}
