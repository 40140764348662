import { SecurityConstants } from '@ups/security-constants';
import { IDynamicModel } from '@ups/xplat/core';

export interface DrDisplayControlGroupDto {
  groupType: string;
  groupName: string;
  controls: IDynamicModel[];
}

export interface DrDisplayContainerDto {
  metadata; //DrContainerMetadataDto;
  controlGroups: DrDisplayControlGroupDto[];
}

/**
 * Which apps support the dynamic API
 */
export const dynamicSupportedAppTags: Array<{
  title: string;
  tag: string;
  default?: boolean;
}> = [
  {
    title: 'HSE Toolbox',
    tag: 'safety',
    default: true,
  },
  {
    title: 'My UPS',
    tag: 'app:my-ups',
  },
];

// used with dynamic nav menus
export const dynamicDefaultFormCategories: Array<{
  icon: string;
  title: string;
  visible: string[];
}> = [
  {
    icon: 'fa-comments',
    title: 'Focused Audits',
    visible: [SecurityConstants.safety_2_0_forms_focusedauditforms],
  },
  {
    icon: 'fa-eye',
    title: 'Observations',
    visible: [SecurityConstants.safety_2_0_forms_observationforms],
  },
  {
    icon: 'fa-bullhorn',
    title: 'Incidents',
    visible: [SecurityConstants.safety_2_0_forms_incidentforms],
  },
  {
    icon: 'fa-file-alt',
    title: 'Lessons Learned',
    visible: [SecurityConstants.safety_2_0_forms_reportingforms],
  },
];
