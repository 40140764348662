import { Inject, Injectable } from '@angular/core';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  LocalStorageService,
  environment,
} from '@ups/xplat/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ProfileModel } from '@ups/xplat/api/dto';

@Injectable({
  providedIn: 'root',
})
export class SearchFacilityService {
  private http: HttpClient;
  private vpHttp: HttpClient;
  private profile: ProfileModel;

  constructor(
    private clientFactory: MyHttpClientFactory,
    private storage: LocalStorageService
  ) {
    this.http = this.clientFactory.createHttpClient(
      environment.urls.spartaAPI,
      true,
      ResponseCasingEnum.PascalCase
    );
    this.vpHttp = this.clientFactory.createHttpClient(
      environment.urls.viewpointAPI,
      true,
      ResponseCasingEnum.PascalCase
    );
    this.profile = this.storage.getItem('profile') as ProfileModel;
  }

  search(searchString: string, params: HttpParams = null, limit: number = 50) {
    params = params || new HttpParams();
    params = params.append('searchString', searchString);
    params = params.append('take', limit);
    return this.vpHttp.get(`/api/parentFacilities`, {
      params,
    });
  }

  /* eslint-disable */
  public addOrRemoveFavoriteFacility(facilityID: string | number) {
    const url = `/api/parentFacilities/favorite`;
    this.http
      .post(url, {
        UserID: this.profile.auth0UserId,
        VPParentFacilityKeyID: facilityID,
      })
      .toPromise();
  }
  /* eslint-enable */
}
