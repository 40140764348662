import { OnInit, Injectable } from '@angular/core';
import { ApplicationModel } from '@ups/xplat/api/dto';
import { SecurityFeatureGroupModel, SecurityService } from '@ups/security';
import { DispatchStatusEnum } from '@ups/xplat/core';
import { DispatchStatusResolver } from './resolvers';
import { SecurityConstants } from '@ups/security-constants';

@Injectable()
export class DispatchStatusService implements OnInit {
  allDispatchStatuses = [];
  super: boolean;
  security: SecurityFeatureGroupModel;

  constructor(
    private dispatchStatusResolver: DispatchStatusResolver,
    private applicationModel: ApplicationModel,
    private securityService: SecurityService
  ) {
    this.dispatchStatusResolver
      .load()
      .then(
        () =>
          (this.allDispatchStatuses = this.applicationModel.dispatchStatuses)
      );
    this.security =
      this.securityService.getFeatureGroupByName('Dispatch Status');
    this.super = this.securityService.getFeatureById(
      SecurityConstants.employee_portal_supers_setdispatchstatus
    ).editAll;
  }

  public ngOnInit() {}

  public allEditable(dispatchStatusIDs: number[]) {
    for (const id of dispatchStatusIDs) {
      if (!this.isEditable(id)) return false;
    }
    return true;
  }

  public isEditable(dispatchStatusID: number) {
    // Onsite isn't editable
    if (dispatchStatusID === DispatchStatusEnum.OnSite) return false;

    // Super users can set dispatch status regardless.
    if (this.super) {
      return true;
    }

    // MedicalReleaseRequired isn't editable
    if (dispatchStatusID === DispatchStatusEnum.MedicalReleaseRequired)
      return false;

    // Checking permissions for individual statuses
    if (dispatchStatusID === DispatchStatusEnum.Dispatched)
      return this.security.getFeatureById(
        SecurityConstants.employeeportal_dispatchstatus_changedispatchedstatus
      ).editAll;

    if (dispatchStatusID === DispatchStatusEnum.Caution)
      return (
        this.security.getFeatureById(
          SecurityConstants.employeeportal_dispatchstatus_changecautionstatus
        ).editAll ||
        this.security.getFeatureById(
          SecurityConstants.employee_portal_dispatch_status_savedeclinedfromcaution_notrehirable_medicalreleaserequired
        ).editAll
      );

    if (dispatchStatusID === DispatchStatusEnum.NotRehireable)
      return this.security.getFeatureById(
        SecurityConstants.employee_portal_dispatch_status_savedeclinedfromcaution_notrehirable_medicalreleaserequired
      ).editAll;

    if (dispatchStatusID === DispatchStatusEnum.MedicalReleaseRequired)
      return this.security.getFeatureById(
        SecurityConstants.employee_portal_dispatch_status_savedeclinedfromcaution_notrehirable_medicalreleaserequired
      ).editAll;

    if (dispatchStatusID === DispatchStatusEnum.PositionCancelled)
      return this.security.getFeatureById(
        SecurityConstants.employeeportal_dispatchstatus_saverequestedfrompositioncanceled
      ).editAll;

    if (
      dispatchStatusID === DispatchStatusEnum.Hold ||
      dispatchStatusID === DispatchStatusEnum.HoldButProcess
    ) {
      if (
        this.security.getFeatureById(
          SecurityConstants.employeeportal_dispatchstatus_savedeclinedfromhold
        ).editAll ||
        this.security.getFeatureById(
          SecurityConstants.employeeportal_dispatchstatus_saverequestedfromhold
        ).editAll
      )
        return true;
    }

    if (dispatchStatusID === DispatchStatusEnum.Requested) {
      if (
        this.security.getFeatureById(
          SecurityConstants.employeeportal_dispatchstatus_savedeclinedfromrequested
        ).editAll ||
        this.security.getFeatureById(
          SecurityConstants.employeeportal_dispatchstatus_saveholdfromrequested
        ).editAll
      )
        return true;
    }

    if (dispatchStatusID === DispatchStatusEnum.WrongNumberListed) {
      if (
        this.security.getFeatureById(
          SecurityConstants.employeeportal_dispatchstatus_savedeclinedfromwrongnumberlisted
        ).editAll ||
        this.security.getFeatureById(
          SecurityConstants.employeeportal_dispatchstatus_saverequestedfromwrongnumberlisted
        ).editAll
      )
        return true;
    }

    // Final Statuses aren't editable
    const ds = this.allDispatchStatuses.find(
      (d) => d.DispatchStatusId === dispatchStatusID
    );
    if (ds != null && ds.isFinalStatus) return false;

    // Checking basic edit permission
    if (
      this.security.getFeatureById(
        SecurityConstants.employeeportal_dispatchstatus_basicedit
      ).editAll
    )
      return true;

    return false;
  }

  public nextStatuses(dispatchStatusID: number) {
    if (!dispatchStatusID) {
      return this.allDispatchStatuses.filter((item) => {
        return (
          item.DispatchStatusId === DispatchStatusEnum.Hold ||
          item.DispatchStatusId === DispatchStatusEnum.Requested ||
          item.DispatchStatusId === DispatchStatusEnum.HoldButProcess
        );
      });
    }

    const ds = this.allDispatchStatuses.find(
      (d) => d.DispatchStatusId === dispatchStatusID
    );

    if (!this.isEditable(dispatchStatusID)) return [ds];

    // possible statuses for dispatched status
    if (dispatchStatusID === DispatchStatusEnum.Dispatched) {
      return this.allDispatchStatuses.filter((item) => {
        return (
          item.DispatchStatusId === DispatchStatusEnum.Dispatched ||
          item.DispatchStatusId === DispatchStatusEnum.TermQuitNoShow ||
          item.DispatchStatusId === DispatchStatusEnum.PositionCancelled
        );
      });
    }

    // possible statuses for 'position cancelled'
    if (dispatchStatusID === DispatchStatusEnum.PositionCancelled) {
      return this.allDispatchStatuses.filter((item) => {
        return (
          item.DispatchStatusId === DispatchStatusEnum.PositionCancelled ||
          item.DispatchStatusId === DispatchStatusEnum.Declined ||
          (item.DispatchStatusId === DispatchStatusEnum.Requested &&
            this.security.getFeatureById(
              SecurityConstants.employeeportal_dispatchstatus_saverequestedfrompositioncanceled
            ).editAll) ||
          (item.DispatchStatusId === DispatchStatusEnum.Hold &&
            this.security.getFeatureById(
              SecurityConstants.employeeportal_dispatchstatus_saverequestedfrompositioncanceled
            ).editAll)
        );
      });
    }

    // possible statuses for caution status
    if (
      dispatchStatusID === DispatchStatusEnum.Caution &&
      !this.security.getFeatureById(
        SecurityConstants.employeeportal_dispatchstatus_changecautionstatus
      ).editAll
    ) {
      return this.allDispatchStatuses.filter((item) => {
        return (
          item.DispatchStatusId === DispatchStatusEnum.Caution ||
          item.DispatchStatusId === DispatchStatusEnum.Declined
        );
      });
    }

    // possible statuses for not rehireable status
    if (dispatchStatusID === DispatchStatusEnum.NotRehireable) {
      return this.allDispatchStatuses.filter((item) => {
        return (
          item.DispatchStatusId === DispatchStatusEnum.NotRehireable ||
          item.DispatchStatusId === DispatchStatusEnum.Declined
        );
      });
    }

    // possible statuses for medical release required status
    if (dispatchStatusID === DispatchStatusEnum.MedicalReleaseRequired) {
      return this.allDispatchStatuses.filter((item) => {
        return (
          item.DispatchStatusId === DispatchStatusEnum.MedicalReleaseRequired ||
          item.DispatchStatusId === DispatchStatusEnum.Declined
        );
      });
    }

    // When Dispatch Status is Dispatched/Processing
    if (dispatchStatusID === DispatchStatusEnum.DispatchedProcessing) {
      return this.allDispatchStatuses.filter((item) => {
        return (
          item.DispatchStatusId === DispatchStatusEnum.DispatchedProcessing ||
          item.DispatchStatusId === DispatchStatusEnum.Dispatched
        );
      });
    }

    // When Dispatch Status is Onsite/Processing
    if (dispatchStatusID === DispatchStatusEnum.OnsiteProcessing) {
      return this.allDispatchStatuses.filter((item) => {
        return (
          item.DispatchStatusId === DispatchStatusEnum.OnsiteProcessing ||
          item.DispatchStatusId === DispatchStatusEnum.OnSite
        );
      });
    }

    const possibleSelections = this.allDispatchStatuses.filter((item) => {
      if (
        item.DispatchStatusId === DispatchStatusEnum.OnSite ||
        item.DispatchStatusId === DispatchStatusEnum.OnsiteProcessing
      ) {
        return false;
      }

      // Super users can set dispatch status regardless except OnSite and Onsite/Processing
      if (this.super) {
        return true;
      }

      // current selection have to be always in the list
      if (item.DispatchStatusId === dispatchStatusID) return true;

      // not selectable
      if (
        item.DispatchStatusId === DispatchStatusEnum.Caution ||
        item.DispatchStatusId === DispatchStatusEnum.MedicalReleaseRequired ||
        item.DispatchStatusId === DispatchStatusEnum.NotRehireable ||
        item.DispatchStatusId === DispatchStatusEnum.OnSite ||
        item.DispatchStatusId === DispatchStatusEnum.OnsiteProcessing ||
        item.DispatchStatusId === DispatchStatusEnum.ROF ||
        item.DispatchStatusId === DispatchStatusEnum.TermQuitNoShow
      )
        return false;

      // set by permission
      if (
        dispatchStatusID === DispatchStatusEnum.Hold ||
        dispatchStatusID === DispatchStatusEnum.HoldButProcess
      ) {
        if (item.DispatchStatusId === DispatchStatusEnum.Requested)
          return this.security.getFeatureById(
            SecurityConstants.employeeportal_dispatchstatus_saverequestedfromhold
          ).editAll;
        if (
          item.DispatchStatusId === DispatchStatusEnum.Hold ||
          item.DispatchStatusId === DispatchStatusEnum.HoldButProcess
        )
          return true;
        if (item.DispatchStatusId === DispatchStatusEnum.PositionCancelled)
          return this.security.getFeatureById(
            SecurityConstants.employeeportal_dispatchstatus_savepositioncanceledfromhold
          ).editAll;
      }
      if (dispatchStatusID === DispatchStatusEnum.Requested) {
        if (
          item.DispatchStatusId === DispatchStatusEnum.Hold ||
          item.DispatchStatusId === DispatchStatusEnum.HoldButProcess
        )
          return this.security.getFeatureById(
            SecurityConstants.employeeportal_dispatchstatus_saveholdfromrequested
          ).editAll;
      }
      if (dispatchStatusID === DispatchStatusEnum.WrongNumberListed) {
        if (item.DispatchStatusId === DispatchStatusEnum.Requested)
          return this.security.getFeatureById(
            SecurityConstants.employeeportal_dispatchstatus_saverequestedfromwrongnumberlisted
          ).editAll;
      }

      // Default possible selections for Caution if basic edit permission is not allowed
      if (dispatchStatusID === DispatchStatusEnum.Caution) {
        if (
          item.DispatchStatusId === DispatchStatusEnum.Hold ||
          item.DispatchStatusId === DispatchStatusEnum.Requested
        )
          return true;
      }

      // other statuses are selectable if basic edit is allowed
      if (
        this.security.getFeatureById(
          SecurityConstants.employeeportal_dispatchstatus_basicedit
        ).editAll
      ) {
        return true;
      }

      return false;
    });

    return possibleSelections;
  }

  public availableStatuses(dispatchStatusIDs: number[]) {
    if (dispatchStatusIDs == null) return this.nextStatuses(null);

    const statuses = [];
    dispatchStatusIDs.forEach((id) =>
      statuses.push(this.nextStatuses(id).map((i) => i.DispatchStatusId))
    );
    const resultIds = statuses.shift().filter((v) => {
      return statuses.every(function (a) {
        return a.indexOf(v) !== -1;
      });
    });
    return this.allDispatchStatuses.filter(
      (ds) => resultIds.indexOf(ds.DispatchStatusId) !== -1
    );
  }
}
