import { Directive, OnInit } from '@angular/core';
import { BaseComponent, EventBusService, WindowService } from '@ups/xplat/core';
import { takeUntil } from 'rxjs/operators';

@Directive()
export abstract class InfoOverlayBaseComponent
  extends BaseComponent
  implements OnInit
{
  info: { message?: string; show?: boolean; alignRight?: boolean };

  constructor(
    protected win: WindowService,
    protected eventBus: EventBusService
  ) {
    super();
  }

  ngOnInit() {
    this.eventBus
      .observe(this.eventBus.types.updateInfoOverlay)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: { message: string; show: boolean; alignRight?: boolean }) => {
          this.info = {
            ...(this.info || {}),
            ...(data || {}),
          };
          if (this.info.show) {
            this.didShow();
          } else {
            this.didHide();
          }
        }
      );
  }

  didShow() {
    // platforms can implement additional custom behavior
  }

  didHide() {
    // platforms can implement additional custom behavior
  }
}
