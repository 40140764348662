import { AfterViewInit, ComponentFactory, ComponentFactoryResolver, Directive, ElementRef, Input, OnInit, Optional, Renderer2, ViewContainerRef } from '@angular/core';

import { Store } from '@ngrx/store';

import { HelpState } from '@ups/xplat/base/in-app-help';
import { BaseComponent } from '@ups/xplat/core';

import { HelpComponent } from '../components';

export const attributeIdName = 'help-id'; // attribute that is set with calculated id
export const attributePathName = 'help-path'; // attribute that is set with calculated path
export const idAttributes = ['help-id', 'help-anchor', 'id', 'name']; // attributes that can set help-id, sorted by priority.

@Directive({
  selector: '[help-anchor], [ngModel]',
})
export class HelpAnchorDirective extends BaseComponent implements AfterViewInit, OnInit {
  @Input() id?: string;
  @Input() name?: string;
  @Input('help-id') helpId?: string;
  @Input('help-anchor') helpAnchorId?: string;

  // protected canInject = false;
  protected componentFactory: ComponentFactory<HelpComponent>;

  constructor(componentFactoryResolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef, private elementRef: ElementRef<HTMLElement>, private renderer: Renderer2, private store: Store) {
    super();
    this.componentFactory = componentFactoryResolver.resolveComponentFactory(HelpComponent);
  }

  ngOnInit(): void {
    // get control id (from the attribute or input name)
    const el = this.elementRef.nativeElement;
    const walkthroughId = this.calculateId(el);

    // without an id we won't inject control
    if (!walkthroughId) return;

    // NOTE: we'd check if the help-id attribute exists, if not we'd set the calculated one to ensure attribute in al case (currently we don't need it)...

    // get full path
    const walkthroughPath = this.calculatePath(el, walkthroughId);

    // apply it to the control immediately
    this.renderer.setAttribute(el, attributePathName, walkthroughPath);
    this.renderer.setAttribute(el, attributeIdName, walkthroughId);
  }

  ngAfterViewInit(): void {
    // NOTE: if here, path is already calculated & set
    const el = this.elementRef.nativeElement;
    const walkthroughId = el.getAttribute(attributeIdName);
    const walkthroughPath = el.getAttribute(attributePathName);

    // without an path we won't inject control (id must not be provided, we can work without)
    if (!walkthroughPath) return;

    // inject control (ev. consider to add both original attribute value and calculated id)
    const componentRef = this.viewContainerRef.createComponent(this.componentFactory);

    // basic set up (switching server/client messages on/off is done by the doCheckManagedComponents() fnc.
    componentRef.instance.walkthroughIdValue = walkthroughId;
    componentRef.instance.walkthroughPathValue = walkthroughPath;
    componentRef.changeDetectorRef.detectChanges();

    const csBorder = getComputedStyle(el).border;
    this.store.pipe(HelpState.selectEditorState(this.destroy$)).subscribe((state) => {
      if (state.show) {
        el.style.border = 'dashed 4px #f5e05055';
      } else {
        el.style.border = csBorder;
      }
    });
  }

  calculateId(nativeElement: any): string {
    const el = this.elementRef.nativeElement;
    const id = idAttributes.reduce((prev, current) => prev || el.getAttribute(current), null);
    // console.log('CalculatedId: ', id, nativeElement);
    return id;
  }

  calculatePath(nativeElement: any, walkthroughId: string): string {
    // on empty id, quit, don't consider rest of the hierarchy
    if (!walkthroughId) return;

    // find first element with path attribute
    while (nativeElement.parentNode && nativeElement.parentNode != document && !nativeElement.hasAttribute(attributePathName)) nativeElement = nativeElement.parentNode;

    const parentPathElement = nativeElement;
    const parentPathAttribute = parentPathElement ? parentPathElement.getAttribute(attributePathName) : undefined;

    return parentPathAttribute ? parentPathAttribute + '/' + walkthroughId : '' + walkthroughId;
    // note: originally we tried to pre-pend the final WT path with a './' to signalize it's an absolute path - but we don't need to do as UI WT paths are always absolute
  }
}
