import { DynComponentMapping } from '@ups/xplat/features';

import { DashboardChartComponent } from './dashboard-chart/dashboard-chart.component';
import { DashboardImageComponent } from './dashboard-image/dashboard-image.component';
import { DrawingCircleComponent } from './drawing-circle/drawing-circle.component';
import { FormButtonComponent } from './form-button/form-button.component';
import { FormNumericTextboxComponent } from './form-numeric-textbox/form-numeric-textbox.component';
import { FormRadioComponent } from './form-radio/form-radio.component';
import { FormTextareaComponent } from './form-textarea/form-textarea.component';
import { FormCheckboxComponent } from './form-checkbox/form-checkbox.component';
import { FormTextInputComponent } from './form-textinput/form-textinput.component';

export const DYNAMIC_ITEM_PREVIEW_COMPONENTS = [FormNumericTextboxComponent, FormTextareaComponent, FormTextInputComponent, FormButtonComponent, FormRadioComponent, FormCheckboxComponent, DrawingCircleComponent, DashboardChartComponent, DashboardImageComponent];

export const DYNAMIC_COMPONENT_PREVIEW_MAPPING: DynComponentMapping = {
  textarea: FormTextareaComponent,
  numerictextbox: FormNumericTextboxComponent,
  button: FormButtonComponent,
  circle: DrawingCircleComponent,
  chart: DashboardChartComponent,
  image: DashboardImageComponent,
  radio: FormRadioComponent,
  input: FormTextInputComponent,
  checkbox: FormCheckboxComponent,
};

export * from './form-button/form-button.component';
export * from './form-numeric-textbox/form-numeric-textbox.component';
export * from './drawing-circle/drawing-circle.component';
export * from './form-textarea/form-textarea.component';
export * from './form-textinput/form-textinput.component';
export * from './dashboard-chart/dashboard-chart.component';
export * from './dashboard-image/dashboard-image.component';
export * from './form-radio/form-radio.component';
export * from './form-checkbox/form-checkbox.component';
