<div *ngIf="!canReadAll()">
  <span>
    You don't have permissions to read the Equipment Requests statistics.
  </span>
</div>
<div *ngIf="canReadAll()">
  <div class="row mb-11">
    <div class="col-lg-6">
      <div *ngIf="countSettings === countSettingsEnum.Equipment" class="label">
        <h2 title="Number of jobs with Active Requirement Requests">
          {{ activeJobsCount }} Active Jobs
        </h2>
        <h2
          title="Number of active equipment requests with no equipment assigned yet."
        >
          {{ openRequests }} Open Requests
        </h2>
        <h2
          title="Number of active equipment requests with an equipment assigned."
        >
          {{ filledRequests }} Filled Requests
        </h2>
      </div>
      <div
        *ngIf="countSettings === countSettingsEnum.EquipmentOnsiteDispatched"
        class="label"
      >
        <h2 title="Number of jobs with Active Requirement Requests">
          {{ activeJobsCount }} Active Jobs
        </h2>
        <h2
          title="Number of equipment requests in status other than OnSite or Dispatched."
        >
          {{ totalRequests - (onsiteRequests + dispatchedRequests) }} Not
          Dispatched / Not Onsite
        </h2>
        <h2
          title="Number of equipment requests in OnSite or Dispatched status."
        >
          {{ onsiteRequests + dispatchedRequests }} Dispatched / Onsite
        </h2>
      </div>

      <div
        *ngIf="countSettings === countSettingsEnum.EquipmentOnsite"
        class="label"
      >
        <h2 title="Number of jobs with Active Requirement Requests">
          {{ activeJobsCount }} Active Jobs
        </h2>
        <h2 title="Number of equipment requests in status other than OnSite.">
          {{ totalRequests - onsiteRequests }} Not Onsite
        </h2>
        <h2 title="Number of equipment requests in OnSite status.">
          {{ onsiteRequests }} Onsite
        </h2>
      </div>
    </div>
    <div class="col-lg-6">
      <div
        *ngIf="countSettings === countSettingsEnum.Equipment"
        class="arc-gauge"
      >
        <div class="arc-gauge-label">
          <div class="rectangle-legend khaki"></div>
          {{ filledRequests }} Filled Requests
        </div>
        <kendo-arcgauge
          id="filledRequestsGaugeId"
          [value]="filledRequests"
          [scale]="{ max: openRequests + filledRequests }"
          [colors]="color"
        >
          <kendo-arcgauge-area
            [width]="300"
            [height]="150"
          ></kendo-arcgauge-area>
          <kendo-arcgauge-scale
            [startAngle]="30"
            [endAngle]="150"
            [rangeSize]="20"
            [rangePlaceholderColor]="placeholderColor"
          ></kendo-arcgauge-scale>
        </kendo-arcgauge>
        <div class="arc-gauge-label">
          <div class="rectangle-legend red"></div>
          {{ openRequests }} Open Requests
        </div>
      </div>

      <div
        *ngIf="countSettings === countSettingsEnum.EquipmentOnsiteDispatched"
        class="arc-gauge"
      >
        <div class="arc-gauge-label">
          <div class="rectangle-legend khaki"></div>
          {{ onsiteRequests + dispatchedRequests }} Dispatched / Onsite
        </div>
        <kendo-arcgauge
          id="onsiteDispatchRequestsGaugeId"
          [value]="onsiteRequests + dispatchedRequests"
          [scale]="{ max: totalRequests }"
          [colors]="color"
        >
          <kendo-arcgauge-area
            [width]="300"
            [height]="150"
          ></kendo-arcgauge-area>
          <kendo-arcgauge-scale
            [startAngle]="30"
            [endAngle]="150"
            [rangeSize]="20"
            [rangePlaceholderColor]="placeholderColor"
          ></kendo-arcgauge-scale>
        </kendo-arcgauge>
        <div class="arc-gauge-label">
          <div class="rectangle-legend red"></div>
          {{ totalRequests - onsiteRequests - dispatchedRequests }} Not
          Dispatched / Not Onsite
        </div>
      </div>

      <div
        *ngIf="countSettings === countSettingsEnum.EquipmentOnsite"
        class="arc-gauge"
      >
        <div class="arc-gauge-label">
          <div class="rectangle-legend khaki"></div>
          {{ onsiteRequests }} Onsite
        </div>
        <kendo-arcgauge
          id="onsiteRequestsGaugeId"
          [value]="onsiteRequests"
          [scale]="{ max: totalRequests }"
          [colors]="color"
        >
          <kendo-arcgauge-area
            [width]="300"
            [height]="150"
          ></kendo-arcgauge-area>
          <kendo-arcgauge-scale
            [startAngle]="30"
            [endAngle]="150"
            [rangeSize]="20"
            [rangePlaceholderColor]="placeholderColor"
          ></kendo-arcgauge-scale>
        </kendo-arcgauge>
        <div class="arc-gauge-label">
          <div class="rectangle-legend red"></div>
          {{ totalRequests - onsiteRequests }} Not Onsite
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-5">
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-4">
          <label>Company</label>
        </div>
        <div class="col-md-8">
          <kendo-multiselect
            id="companyDropdownId"
            help-anchor="companyDD"
            [data]="companyDropdownDataFiltered"
            [textField]="'CompanyName'"
            [valueField]="'CompanyNumber'"
            placeholder="Select Company to filter Scorecard data"
            (valueChange)="onCompanyValueChange($event)"
            [popupSettings]="{ width: 'auto' }"
            [filterable]="true"
            (filterChange)="onCompanyFilterChange($event)"
          >
            <ng-template kendoMultiSelectTagTemplate let-dataItem>
              <span>
                {{ dataItem.CompanyNumber }} - {{ dataItem.CompanyName }}
              </span>
            </ng-template>
            <ng-template kendoMultiSelectItemTemplate let-dataItem>
              {{ dataItem.CompanyNumber }} - {{ dataItem.CompanyName }}
            </ng-template>
          </kendo-multiselect>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-4">
          <label>Asset Control Manager</label>
        </div>
        <div class="col-md-8">
          <kendo-multiselect
            id="assetControlManagerDropdownId"
            help-anchor="assetControlManagerDD"
            [data]="assetControlManagerNamesDropdownDataFiltered"
            [textField]="assetControlManagerNameFieldName"
            [valueField]="assetControlManagerNameFieldName"
            placeholder="Select Asset Control Manager to filter Scorecard data"
            (valueChange)="onAssetControlManagerNameValueChange($event)"
            [popupSettings]="{ width: 'auto' }"
            [filterable]="true"
            (filterChange)="onAssetControlManagerNameFilterChange($event)"
          >
            <ng-template kendoMultiSelectItemTemplate let-dataItem>
              {{ dataItem[assetControlManagerNameFieldName] }}
            </ng-template>
          </kendo-multiselect>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-5">
    <div class="col-md-6">
      <div class="row">
        <label class="col-md-4">Job</label>
        <div class="col-md-8">
          <kendo-multiselect
            id="jobsDropdownId"
            help-anchor="jobDD"
            [data]="jobsDropdownDataFiltered"
            [textField]="jobFieldName"
            [valueField]="jobFieldName"
            placeholder="Select Job to filter Scorecard data"
            (valueChange)="onJobValueChange($event)"
            [popupSettings]="{ width: 'auto' }"
            [filterable]="true"
            (filterChange)="onJobFilterChange($event)"
          >
            <ng-template kendoMultiSelectItemTemplate let-dataItem>
              {{ dataItem[jobFieldName] }} {{ dataItem['JobName'] }}
            </ng-template>
          </kendo-multiselect>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <label class="col-md-4">Requested Equipment</label>
        <div class="col-md-8">
          <kendo-multiselect
            id="requestedEquipmentId"
            help-anchor="requestedEquipmentDD"
            [data]="equipmentTypeNamesDropdownDataFiltered"
            [textField]="equipmentTypeNameFieldName"
            [valueField]="equipmentTypeNameFieldName"
            placeholder="Select Equipment Type to filter Scorecard data"
            (valueChange)="onEquipmentTypeNameValueChange($event)"
            [popupSettings]="{ width: 'auto' }"
            [filterable]="true"
            (filterChange)="onEquipmentTypeNameFilterChange($event)"
          >
            <ng-template kendoMultiSelectItemTemplate let-dataItem>
              {{ dataItem[equipmentTypeNameFieldName] }}
            </ng-template>
          </kendo-multiselect>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-11">
    <div class="col-md-6">
      <div class="row">
        <label class="col-md-4">Plant</label>
        <div class="col-md-8">
          <kendo-multiselect
            id="plantDropdownId"
            help-anchor="plantDD"
            [data]="plantNameDropdownDataFiltered"
            [textField]="plantNameFieldName"
            [valueField]="plantNameFieldName"
            placeholder="Select Plant to filter Scorecard data"
            (valueChange)="onPlantNameValueChange($event)"
            [popupSettings]="{ width: 'auto' }"
            [filterable]="true"
            (filterChange)="onPlantNameFilterChange($event)"
          >
            <ng-template kendoMultiSelectItemTemplate let-dataItem>
              {{ dataItem[plantNameFieldName] }}
            </ng-template>
          </kendo-multiselect>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <label class="col-md-4">Supervisor</label>
        <div class="col-md-8">
          <kendo-multiselect
            id="supervisorDropdownId"
            help-anchor="supervisorDD"
            [data]="supervisorDropdownDataFiltered"
            [textField]="supervisorFieldName"
            [valueField]="supervisorFieldName"
            placeholder="Select Supervisor to filter Scorecard data"
            (valueChange)="onSupervisorValueChange($event)"
            [popupSettings]="{ width: 'auto' }"
            [filterable]="true"
            (filterChange)="onSupervisorFilterChange($event)"
          >
            <ng-template kendoMultiSelectItemTemplate let-dataItem>
              {{ dataItem[supervisorFieldName] }}
            </ng-template>
          </kendo-multiselect>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <ups-equipment-bar-chart
        id="equipmentAllocationByMonthChartId"
        sectionTitle="Equipment Allocation by Month"
        [data]="monthsData"
        [field]="'MonthLabel'"
        [showDataByDates]="true"
        [useMonths]="true"
        [countSettings]="countSettings"
        [vertical]="true"
      ></ups-equipment-bar-chart>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <ups-equipment-bar-chart
        id="equipmentAllocationByWeekChartId"
        sectionTitle="Equipment Allocation by Week"
        [data]="weeksData"
        [field]="'WeekLabel'"
        [showDataByWeeks]="true"
        [countSettings]="countSettings"
        [vertical]="true"
      ></ups-equipment-bar-chart>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <ups-equipment-bar-chart
        id="equipmentAllocationByDayChartId"
        sectionTitle="Equipment Allocation by Day"
        [data]="dateData"
        [showDataByDates]="true"
        [countSettings]="countSettings"
        [vertical]="true"
      ></ups-equipment-bar-chart>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <ups-equipment-bar-chart
        id="jobsWithEquipmentRequestsChartId"
        sectionTitle="Jobs With Equipment Requests"
        [data]="jobsData"
        [field]="jobFieldName"
        [showDataByJobs]="false"
        [countSettings]="countSettings"
      ></ups-equipment-bar-chart>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <ups-equipment-bar-chart
        id="plantsWithEquipmentRequestsChartId"
        sectionTitle="Plants With Equipment Requests"
        [data]="plantNamesData"
        [field]="plantNameFieldName"
        [showDataByJobs]="false"
        [countSettings]="countSettings"
      ></ups-equipment-bar-chart>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <ups-equipment-bar-chart
        id="assetControlManagerChartId"
        sectionTitle="Asset Control Manager"
        [data]="assetControlManagerNamesData"
        [field]="assetControlManagerNameFieldName"
        [countSettings]="countSettings"
      ></ups-equipment-bar-chart>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <ups-equipment-bar-chart
        id="supervisorsChartId"
        sectionTitle="Supervisors"
        [data]="supervisorsData"
        [field]="supervisorFieldName"
        [countSettings]="countSettings"
      ></ups-equipment-bar-chart>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <ups-equipment-bar-chart
        id="requestedEquipmentChartId"
        sectionTitle="Requested Equipment"
        [data]="equipmentTypeNamesData"
        [field]="equipmentTypeNameFieldName"
        [countSettings]="countSettings"
      ></ups-equipment-bar-chart>
    </div>
  </div>
</div>
