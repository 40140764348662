import { CurrentEquipmentRequestComponent } from './current-equipment-request/current-equipment-request.component';
import { EquipmentBarChartComponent } from './equipment-bar-chart/equipment-bar-chart.component';
import { EquipmentCalibrationsComponent } from './equipment-calibrations/equipment-calibrations.component';
import { EquipmentDashboardBulkEditComponent } from './equipment-dashboard-bulk-edit/equipment-dashboard-bulk-edit.component';
import { EquipmentDashboardDetailComponent } from './equipment-dashboard-detail/equipment-dashboard-detail.component';
import { EquipmentDashboardGridComponent } from './equipment-dashboard-grid/equipment-dashboard-grid.component';
import { EquipmentDashboardPageComponent } from './equipment-dashboard-page/equipment-dashboard-page.component';
import { EquipmentFixedAssetHistoryComponent } from './equipment-fixed-asset-history/equipment-fixed-asset-history.component';
import { EquipmentInfoComponent } from './equipment-info/equipment-info.component';
import { EquipmentInspectionHistoryComponent } from './equipment-inspection-history/equipment-inspection-history.component';
import { EquipmentJobHistoryComponent } from './equipment-job-history/equipment-job-history.component';
import { EquipmentLeaseRentalInfoComponent } from './equipment-lease-rental-info/equipment-lease-rental-info.component';
import { EquipmentLocationHistoryComponent } from './equipment-location-history/equipment-location-history.component';
import { EquipmentMetersComponent } from './equipment-meters/equipment-meters.component';
import { EquipmentNotesComponent } from './equipment-notes/equipment-notes.component';
import { EquipmentOwnershipInfoComponent } from './equipment-ownership-info/equipment-ownership-info.component';
import { EquipmentScheduleComponent } from './equipment-schedule/equipment-schedule.component';
import { EquipmentScoreCardComponent } from './equipment-score-card/equipment-score-card.component';

export const EQUIPMENT_DASHBOARD_SHARED_COMPONENTS = [
  EquipmentInfoComponent,
  EquipmentMetersComponent,
  EquipmentInspectionHistoryComponent,
  EquipmentCalibrationsComponent,
  EquipmentLeaseRentalInfoComponent,
  EquipmentNotesComponent,
  EquipmentJobHistoryComponent,
  CurrentEquipmentRequestComponent,
  EquipmentFixedAssetHistoryComponent,
  EquipmentLocationHistoryComponent,
  EquipmentScheduleComponent,
  EquipmentOwnershipInfoComponent,
];
export const EQUIPMENT_DASHBOARD_COMPONENTS = [
  EquipmentDashboardPageComponent,
  EquipmentDashboardGridComponent,
  EquipmentDashboardBulkEditComponent,
  EquipmentDashboardDetailComponent,
  EquipmentScoreCardComponent,
  EquipmentBarChartComponent,
];

export * from './current-equipment-request/current-equipment-request.component';
export * from './equipment-calibrations/equipment-calibrations.component';
export * from './equipment-dashboard-bulk-edit/equipment-dashboard-bulk-edit.component';
export * from './equipment-dashboard-detail/equipment-dashboard-detail.component';
export * from './equipment-dashboard-grid/equipment-dashboard-grid.component';
export * from './equipment-dashboard-page/equipment-dashboard-page.component';
export * from './equipment-fixed-asset-history/equipment-fixed-asset-history.component';
export * from './equipment-info/equipment-info.component';
export * from './equipment-inspection-history/equipment-inspection-history.component';
export * from './equipment-job-history/equipment-job-history.component';
export * from './equipment-lease-rental-info/equipment-lease-rental-info.component';
export * from './equipment-location-history/equipment-location-history.component';
export * from './equipment-meters/equipment-meters.component';
export * from './equipment-notes/equipment-notes.component';
export * from './equipment-ownership-info/equipment-ownership-info.component';
export * from './equipment-schedule/equipment-schedule.component';
export * from './equipment-score-card/equipment-score-card.component';
export * from './equipment-bar-chart/equipment-bar-chart.component';
