<section class="p-7 border-all mb-7">
  <div class="d-flex w-100 justify-content-between align-items-center">
    <div>
      <span
        (click)="handleShowHide('showPanel')"
        [ngClass]="{ pointer: true, 'd-none': showPanel }"
      >
        <h4>{{ sectionTitle }}</h4>
      </span>
      <span
        (click)="handleShowHide('showPanel')"
        [ngClass]="{ pointer: true, 'd-none': !showPanel }"
      >
        <h4>{{ sectionTitle }}</h4>
      </span>
    </div>
    <div>
      <a
        class="link pull-right"
        (click)="handleShowHide('showPanel')"
        title="Expand"
        [ngClass]="{ 'd-none': showPanel }"
      >
        <i class="far fa-chevron-down"></i>
      </a>
      <a
        class="link pull-right"
        (click)="handleShowHide('showPanel')"
        title="Collapse"
        [ngClass]="{ 'd-none': !showPanel }"
      >
        <i class="far fa-chevron-up"></i>
      </a>
    </div>
  </div>
  <div [ngClass]="{ 'd-none': !showPanel }" class="mt-5">
    <div
      class="d-flex align-items-center"
      *ngIf="showDataByDates && !useMonths"
    >
      <label class="me-5 mb-0">Number of Days</label>
      <kendo-dropdownlist
        name="daysToProcess"
        [(ngModel)]="daysToProcess"
        (valueChange)="processDateData()"
        [data]="[30, 60, 90]"
        #daysDropdown
        class="w-300"
      ></kendo-dropdownlist>
    </div>

    <div class="d-flex align-items-center" *ngIf="showDataByDates && useMonths">
      <label class="me-5 mb-0">Number of Months</label>
      <kendo-dropdownlist
        name="monthsToProcess"
        [(ngModel)]="monthsToProcess"
        (valueChange)="processMonthData()"
        [data]="[6, 9, 12, 18]"
        #monthDropdown
        class="w-300"
      ></kendo-dropdownlist>
    </div>

    <div class="d-flex align-items-center" *ngIf="showDataByWeeks">
      <label class="me-5 mb-0">Number of Weeks</label>
      <kendo-dropdownlist
        name="weeksToProcess"
        [(ngModel)]="weeksToProcess"
        (valueChange)="processWeeksData()"
        [data]="[13, 26, 52]"
        #weeksDropdown
        class="w-300"
      ></kendo-dropdownlist>
    </div>
    <kendo-chart
      *ngIf="chartData && chartData.length"
      [seriesColors]="[color, placeholderColor]"
    >
      <kendo-chart-axis-defaults
        [minorGridLines]="{ visible: 'none' }"
      ></kendo-chart-axis-defaults>
      <kendo-chart-area [height]="chartHeight" [margin]="40"></kendo-chart-area>
      <kendo-chart-legend [visible]="false"></kendo-chart-legend>
      <kendo-chart-tooltip [shared]="true">
        <ng-template
          kendoChartSharedTooltipTemplate
          let-category="category"
          let-points="points"
        >
          <div *ngIf="!showDataByJobs">{{ category }}</div>
          <div *ngIf="showDataByJobs">
            {{
              category + ' ' + (chartData | jobName: field:'JobName':category)
            }}
          </div>
          <div *ngFor="let point of points; let i = index">
            <div
              [ngClass]="{ legend: true, khaki: i === 0, red: i !== 0 }"
            ></div>
            {{ point.series.name }}: {{ point.value }}
          </div>
          <div>Total Requests: {{ points | arraySum: 'value' }}</div>
        </ng-template>
      </kendo-chart-tooltip>
      <kendo-chart-category-axis>
        <kendo-chart-category-axis-item
          [categories]="categories"
          [maxDivisions]="45"
        >
          <kendo-chart-category-axis-item-labels
            [rotation]="vertical ? 90 : 0"
          ></kendo-chart-category-axis-item-labels>
        </kendo-chart-category-axis-item>
      </kendo-chart-category-axis>
      <kendo-chart-series *ngIf="countSettings === countSettingsEnum.Equipment">
        <kendo-chart-series-item
          [type]="vertical ? 'column' : 'bar'"
          name="Filled Requests"
          [gap]="1"
          [stack]="true"
          [data]="filledJobs"
        ></kendo-chart-series-item>
        <kendo-chart-series-item
          [type]="vertical ? 'column' : 'bar'"
          name="Open Requests"
          [data]="openJobs"
          [labels]="{ visible: true }"
        >
          <kendo-chart-series-item-labels
            [content]="labelContent"
            [rotation]="vertical ? 90 : 0"
            [padding]="0"
          ></kendo-chart-series-item-labels>
        </kendo-chart-series-item>
      </kendo-chart-series>

      <kendo-chart-series
        *ngIf="countSettings === countSettingsEnum.EquipmentOnsiteDispatched"
      >
        <kendo-chart-series-item
          [type]="vertical ? 'column' : 'bar'"
          name="Dispatched / Onsite"
          [gap]="1"
          [stack]="true"
          [data]="onsiteDispatchJobs"
        ></kendo-chart-series-item>
        <kendo-chart-series-item
          [type]="vertical ? 'column' : 'bar'"
          name="Not Dispatched / Not Onsite"
          [data]="notOnsiteDispatchJobs"
          [labels]="{ visible: true }"
        >
          <kendo-chart-series-item-labels
            [content]="labelContent"
            [rotation]="vertical ? 90 : 0"
          ></kendo-chart-series-item-labels>
        </kendo-chart-series-item>
      </kendo-chart-series>

      <kendo-chart-series
        *ngIf="countSettings === countSettingsEnum.EquipmentOnsite"
      >
        <kendo-chart-series-item
          [type]="vertical ? 'column' : 'bar'"
          name="Onsite"
          [data]="onsiteJobs"
          [gap]="1"
          [stack]="true"
        ></kendo-chart-series-item>
        <kendo-chart-series-item
          [type]="vertical ? 'column' : 'bar'"
          name="Not Onsite"
          [data]="notOnsiteJobs"
          [labels]="{ visible: true }"
        >
          <kendo-chart-series-item-labels
            [content]="labelContent"
            [rotation]="vertical ? 90 : 0"
          ></kendo-chart-series-item-labels>
        </kendo-chart-series-item>
      </kendo-chart-series>
    </kendo-chart>
    <div *ngIf="!chartData || !chartData.length">No data</div>
  </div>
</section>
