import { Directive, EventEmitter, OnInit, Output } from '@angular/core';
import { SpartaFileService } from '@ups/files';
import { FileDto } from '@ups/xplat/api/dto';
import { takeUntil } from 'rxjs/operators';
import { DynamicEventBusTypes } from '../../utils';
import { DynamicItemBaseComponent } from '../dynamic-item/dynamic-item.base-component';

@Directive()
export abstract class DynamicFileBaseComponent
  extends DynamicItemBaseComponent
  implements OnInit
{
  @Output() fileUploaded = new EventEmitter();

  attachedFileCount = 0;
  offlineNote = `Attaching files is only supported when you're online.`;
  constructor(protected spartaFileService: SpartaFileService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.dynamicService) {
      if (!this.dynamicService.activeAttachments) {
        this.dynamicService.activeAttachments = {};
      }
      if (
        !this.dynamicService.activeAttachments[this.config?.formControlName]
      ) {
        this.dynamicService.activeAttachments[this.config?.formControlName] =
          [];
      }
      if (this.isDisabled()) {
        // normalize the label when disabled as to not sound actionable
        this.config.label = (this.config.label || '').replace('Add', '').trim();
      }
      this.dynamicService.eventBus
        .observe(DynamicEventBusTypes.dynamicFormResetState)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.attachedFileCount = 0;
          this._resetRequired();
        });
    }
  }

  /* eslint-disable */
  open(file: FileDto) {
    // implemented in platform specific components
  }
  /* eslint-enable */

  removeFile(attachment: FileDto) {
    this.win
      .confirm({
        title: 'Are you sure?',
        message: 'Are you sure you want to remove this file?',
        okButtonText: 'Yes, remove',
        cancelButtonText: 'Cancel',
      })
      .then((ok) => {
        if (ok) {
          this.attachedFileCount--;
          this.dynamicService.removeAttachmentForField(
            this.config.formControlName,
            attachment
          );
          this._resetRequired();
        }
      });
  }

  createAndUploadFile(
    filename: string,
    content: string | ArrayBuffer,
    contentType: string,
    size: number = 0
  ): Promise<boolean> {
    return new Promise((resolve) => {
      const fileDto: FileDto = new FileDto();
      fileDto.name = filename;
      if (typeof content === 'string') {
        fileDto.contentBase64 = content;
      } else {
        fileDto.content = content;
      }
      fileDto.size = size;
      fileDto.contentType = contentType;
      fileDto.directory =
        this.config.options && this.config.options.directory
          ? this.config.options.directory
          : 'editModal';
      fileDto.bActive = true;
      // console.log('fileDto.directory:', fileDto.directory);

      this.spartaFileService
        .uploadFile(fileDto)
        .then((f) => {
          this.dynamicService.addAttachmentForField(
            this.config.formControlName,
            f
          );
          this.attachedFileCount++;
          // ensure to remove any required validation status
          this.setValidators(null);
          resolve(true);
        })
        .catch(() => {
          this.win.alert('An error has occurred.');
          resolve(false);
        });
    });
  }

  private _resetRequired() {
    if (!this.attachedFileCount && this.config?.required) {
      // make sure any required validation is added back
      this.setRequired();
    }
  }
}
