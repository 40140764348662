import { AvailableEmployeeResult, RoleDto } from '@ups/xplat/api/dto';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';

const GET_SPARTA_EMPLOYEE_DATA = '/api/users/{:hrref}';
const GET_ROLES = '/api/roles';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  http: HttpClient;

  constructor(private clientFactory: MyHttpClientFactory) {
    this.http = this.clientFactory.createHttpClient(
      environment.urls.spartaAPI,
      true,
      ResponseCasingEnum.PascalCase
    );
  }

  public fetchSpartaEmployeeByHRRef(
    hrRef: number | string
  ): Promise<AvailableEmployeeResult[]> {
    const url = GET_SPARTA_EMPLOYEE_DATA.split('{:hrref}').join(
      hrRef?.toString()
    );
    return this.http.get<AvailableEmployeeResult[]>(url).toPromise();
  }

  public fetchSpartaRoles(): Promise<RoleDto[]> {
    const url = GET_ROLES;
    return this.http.get<RoleDto[]>(url).toPromise();
  }
}
