import * as moment from 'moment';

import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import {
  SortDescriptor,
  State,
  toODataString,
} from '@progress/kendo-data-query';

import { environment } from '@ups/xplat/core';
import { FilterDescriptorEx } from '@ups/xplat/features';
import { ReleaseNotePopupComponent } from '../release-note-popup/release-note-popup.component';
import { ReleaseNoteService } from '@ups/xplat/core';
import { Communication, CommunicationTypeEnum } from '@ups/xplat/api/dto';

@Component({
  selector: 'ups-past-releases-table',
  templateUrl: './past-releases-table.component.html',
  styleUrls: ['./past-releases-table.component.scss'],
})
export class PastReleasesTableComponent implements OnInit {
  @ViewChild(GridComponent) grid: GridComponent;
  @ViewChild(ReleaseNotePopupComponent) popup: ReleaseNotePopupComponent;

  pageSizes = [5, 10, 15];
  initialPageSize = this.pageSizes[1];

  sortDescriptors: SortDescriptor[] = [
    {
      field: 'lastUpdated',
      dir: 'desc',
    },
  ];

  filters: FilterDescriptorEx[] = [
    {
      operator: 'eq',
      convertToString: false,
      value: true,
      field: 'bPublished',
    },
    {
      operator: 'eq',
      convertToString: false,
      value: CommunicationTypeEnum.ReleaseNote,
      field: 'communicationTypeId',
    },
  ];

  gridDataState: State = {
    filter: { logic: 'and', filters: this.filters },
    group: [],
    skip: 0,
    sort: this.sortDescriptors,
    take: this.initialPageSize,
  };

  gridSkip = 0;

  data: Array<Communication> = [];
  loading = false;

  constructor(private releaseNotesService: ReleaseNoteService) {}

  ngOnInit() {
    this.loadAppData();
  }

  public gridDataStateChanged(state: State): void {
    this.gridDataState = state;
    // console.dir(state);
    this.loadAppData();
  }

  loadAppData() {
    this.loading = true;
    this.releaseNotesService
      .getReleaseNotes(
        environment.security.appId,
        this.gridDataState ? toODataString(this.gridDataState) : null
      )
      .subscribe((x) => {
        this.loading = false;
        this.data = x.value;
        this.data.forEach((r) => {
          r.lastUpdated = moment.utc(r.lastUpdated).local().toDate();
          r.releaseDate = moment.utc(r.releaseDate).local().toDate();
        });
      });
  }

  showReleaseNote(rn: Communication) {
    this.releaseNotesService
      .getFullReleaseNote(rn.communicationId)
      .subscribe((x) => {
        this.popup.showReleasePopup(x, true);
      });
  }
}
