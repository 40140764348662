import { AfterViewInit, Directive, Input, OnDestroy } from '@angular/core';

import { GridComponent } from '@progress/kendo-angular-grid';

/**
 * Will handle if there's just one scrolling, but not double scrolling...
 * ...as position sticky works just if those items are immediately inside the scrolling container!
 *
 * NOTES:
 *
 * - only static sticky headers are considered when altering position of grid-headers
 * - also kendo grid's scrollable attribute must not be set (to 'none') - we got custom no-scroll attribute that we can apply on the grid
 * - NOTE: applying scrollable="none" will prevent the floating (sticky) effect on the header!
 *
 *
 * DEVELOPER NOTES:
 *
 * - The component relies on the layout we are using.
 * - Layout defines 3 scroll modes: global | main | article
 * - Those scroll modes are activated by setting the proper class to the the .layout-wrapper - see @UPS/common LayoutStyleModule
 *
 * https://nitayneeman.com/posts/listening-to-dom-changes-using-mutationobserver-in-angular/
 * https://docs.telerik.com/kendo-ui/knowledge-base/fixed-headers-grid
 *
 *
 *  Kendo grid HTML structure:
 *
 *    <kendo-grid class="k-widget k-grid">
 *      <kendo-grid-toolbar class="k-header k-grid-toolbar" />
 *      <kendo-grid-group-panel class="k-grouping-header k-grouping-header-flex">
 *        <div class="k-grid-aria-root" role="grid">
 *          <div class="k-grid-header" role="presentation">
 *            <div class="k-grid-header-wrap" />
 *          </div>
 *          <kendo-grid-list class="k-grid-container" role="presentation">
 *            <div class="k-grid-content k-virtual-content" role="presentation" tabindex="-1" ng-reflect-locked-width="31" ng-reflect-kendo-grid-resizable-container="0">
 *              <div class="k-grid-table-wrap" role="presentation">
 *                <table class="k-grid-table" role="presentation" />
 *              </div>
 *              <div class="k-height-container" role="presentation" />
 *            </div>
 *          </kendo-grid-list>
 *        </div>
 *     <kendo-pager class="k-pager-wrap k-grid-pager k-widget" />
 *   </kendo-grid>
 */
@Directive({
  selector: 'kendo-grid[sticky-header]',
})
export class GridStickyHeaderDirective implements AfterViewInit, OnDestroy {
  @Input('sticky-header') public selector: string;
  @Input('sticky-header-z-index') public zIndex = 1000;

  changes: MutationObserver;

  layoutWrapperElement;

  grid: any;
  gridRoot: any;
  gridHeader: any;
  currentScrollParent: any;

  constructor(private gridComponent: GridComponent) {
    const self = this;

    self.layoutWrapperElement = document.querySelector('.layout-wrapper');

    if (self.layoutWrapperElement) {
      // init change & event to it
      self.changes = new MutationObserver((mutations: MutationRecord[]) => {
        mutations.forEach((mutation: MutationRecord) => {
          // we can check:
          // mutation.type === 'attributes'
          // mutation.attributeName === 'class'
          self.setUpListeners();
        });
      });

      // observe the .layout-wrapper
      self.changes.observe(self.layoutWrapperElement, {
        attributes: true,
        attributeFilter: ['class'],
      });
    }
  }

  ngOnDestroy(): void {
    if (this.changes) this.changes.disconnect();
  }

  ngAfterViewInit() {
    this.grid = this.gridComponent.wrapper.nativeElement;
    this.gridRoot = this.grid.querySelector('[role=grid]');
    // NOTE: header is avail. just aft. view init
    this.gridHeader = this.grid.querySelector('.k-grid-header');
    // NOTE: ev. store: this.gridHeader.style.top|position|z-index values

    this.setUpListeners();
  }

  getScrollParentInsideLayout() {
    if (this.layoutWrapperElement) {
      if (this.layoutWrapperElement.classList.contains('no-scroll-fit'))
        return undefined;
      else if (this.layoutWrapperElement.classList.contains('scroll-content'))
        return document.querySelector(
          '.layout-wrapper .layout-content-wrapper main'
        );
      else if (
        this.layoutWrapperElement.classList.contains('scroll-article-only')
      )
        return document.querySelector(
          '.layout-wrapper .layout-content-wrapper main article'
        );

      // global scrolling
      return window;
    }

    return undefined;
  }

  getStickyHeader(scrollParent) {
    if (scrollParent === window) {
      // global-header
      return document.querySelector('.layout-wrapper > header');
    } else if (scrollParent) {
      // if we got scrollParent on the content level, we find the content-header via next selector
      // will return undefined in case the scrollParent is the article
      return scrollParent.querySelector(
        '.layout-wrapper .layout-content-wrapper > header'
      );
    }
    return undefined;
  }

  setUpListeners() {
    if (this.currentScrollParent) {
      this.currentScrollParent.removeEventListener('scroll', this.scroll);
      this.currentScrollParent.removeEventListener('resize', this.resize);
    }

    const scroll = this.getScrollParentInsideLayout();
    this.currentScrollParent = scroll;

    if (this.currentScrollParent) {
      this.currentScrollParent.addEventListener('scroll', this.scroll);
      this.currentScrollParent.addEventListener('resize', this.resize);
    }
  }

  scroll = () => {
    this.alterGridHeader();
  };

  resize = () => {
    this.alterGridHeader();
  };

  alterGridHeader() {
    if (!this.currentScrollParent) return;

    const headerElement = this.getStickyHeader(this.currentScrollParent);
    const isSticky =
      headerElement &&
      headerElement.classList.contains(
        'sticky'
      ); /* || headerElement.classList.contains('sticky-desktop-only') */
    const stickyHeights = isSticky ? headerElement.clientHeight : 0;

    const scrollContainerScrollTop =
      this.currentScrollParent.scrollTop ||
      this.currentScrollParent.pageYOffset ||
      0; /* how much we scrolled */
    const scrollContainerOffsetTop = this.currentScrollParent.offsetTop || 0;
    const gridDistanceFromScrollContainer =
      this.getCoords(this.grid).top - scrollContainerOffsetTop;
    const headerDistanceFromGrid =
      this.gridRoot.offsetTop; /* icludes the: menu, grouping, etc. heights */

    const shift =
      scrollContainerScrollTop -
      gridDistanceFromScrollContainer -
      headerDistanceFromGrid +
      stickyHeights;

    /*
    console.log('---');
    console.log('scrollContainerScrollTop', scrollContainerScrollTop);
    console.log('scrollContainerOffsetTop', scrollContainerOffsetTop);
    console.log('shift', shift);

    console.log('isSticky', isSticky);
    console.log('stickyHeights', stickyHeights);
    console.log('grid offset top', this.grid.offsetTop);
    console.log('gridRoot offset top', this.gridRoot.offsetTop);
    console.log('gridDistanceFromScrollContainer', gridDistanceFromScrollContainer);
    console.log('headerDistanceFromGrid', headerDistanceFromGrid);

    console.log('gridHeader', this.gridHeader);
    */

    if (shift > 0) {
      // sticky
      this.gridHeader.style.position = 'relative';
      this.gridHeader.style['z-index'] = this.zIndex;
      this.gridHeader.style.top = shift + 'px';
    } else {
      // regular
      this.gridHeader.style.position = 'static';
    }
  }

  getCoords(elem) {
    // crossbrowser version
    const box = elem.getBoundingClientRect();

    const body = document.body;
    const docEl = document.documentElement;

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const scrollLeft =
      window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    const clientTop = docEl.clientTop || body.clientTop || 0;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;

    const top = box.top + scrollTop - clientTop;
    const left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
  }
}
