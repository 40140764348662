import { Component, Input } from '@angular/core';
import { DialogRef, DialogContentBase, DialogResult, DialogAction } from '@progress/kendo-angular-dialog';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'user-info',
  template: `
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;"><span class="k-icon k-i-form-element"></span> Save Design</div>
    </kendo-dialog-titlebar>
    <div class="container">
      <form [formGroup]="formGroup">
        <div class="form-group">
          <label for="name">Name</label>
          <input type="text" class="form-control k-textbox" id="name" [formControl]="formGroup.get('name')" required />
        </div>
      </form>
    </div>
    <kendo-dialog-actions>
      <button kendoButton (click)="onCancelAction()">Cancel</button>
      <button kendoButton (click)="onConfirmAction()" [primary]="true" [disabled]="!formGroup.valid">Save</button>
    </kendo-dialog-actions>
  `,
})
export class SaveDialogComponent extends DialogContentBase {
  @Input() public set name(value: string) {
    this.formGroup.controls.name.setValue(value);
    this.containerName = value;
  }

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(this.name),
  });

  private containerName = '';

  constructor(public dialog: DialogRef) {
    super(dialog);
  }

  public onCancelAction(): void {
    this.dialog.close({ text: 'Cancel' });
  }

  public onConfirmAction(): void {
    this.dialog.close(new DrAdminSaveDialogResult(this.formGroup.get('name').value));
  }
}

export class DrAdminSaveDialogResult extends DialogAction {
  constructor(public containerName: string) {
    super();
  }
  public text = 'Save';
}
