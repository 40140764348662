import { Component, ElementRef, forwardRef, Input } from '@angular/core';
import { ColumnBase, ColumnComponent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'kendo-grid-column-ex',
  providers: [
    {
      provide: ColumnBase,
      useExisting: forwardRef(() => KendoGridColumnExComponent),
    },
  ],
  template: ``,
})
export class KendoGridColumnExComponent extends ColumnComponent {
  /*
  @Input('field') public field: string;
  */

  @Input('data-x') public dataX: any;

  constructor(private el: ElementRef) {
    // NOTE: this hack was necessary as the data-* attributes does not work same way as other non data-* attributes with - in their name!
    super();
    this.dataX = el.nativeElement.getAttribute('data-x');
  }
}
