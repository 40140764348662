import { EditableWrapperComponent } from './editable-wrapper.component';
import { EditorFormTextareaComponent } from './editor-form-textarea/editor-form-textarea';
import { EditorFormTextInputComponent } from './editor-form-textinput/editor-form-textinput';
import { EditorDashboardChartComponent } from './editor-dashboard-chart/editor-dashboard-chart';
import { EditorDashboardImageComponent } from './editor-dashboard-image/editor-dashboard-image';
import { EditorDrawingCircleComponent } from './editor-drawing-circle/editor-drawing-circle';
import { EditorFormButtonComponent } from './editor-form-button/editor-form-button';
import { EditorFormNumericTextboxComponent } from './editor-form-numeric-textbox/editor-form-numeric-textbox';
import { EditorFormRadioComponent } from './editor-form-radio/editor-form-radio.component';
import { EditorSafetyAtRiskComponent } from './editor-safety-at-risk/editor-safety-at-risk';
import { EditorFormCheckboxComponent } from './editor-form-checkbox/editor-form-checkbox';

export const EDITOR_COMPONENTS = [EditableWrapperComponent, EditorDashboardChartComponent, EditorDashboardImageComponent, EditorDrawingCircleComponent, EditorFormButtonComponent, EditorFormTextareaComponent, EditorFormNumericTextboxComponent, EditorFormRadioComponent, EditorFormTextInputComponent, EditorSafetyAtRiskComponent, EditorFormCheckboxComponent];

export * from './editable-wrapper.component';
export * from './editor-form-textarea/editor-form-textarea';
export * from './editor-dashboard-chart/editor-dashboard-chart';
export * from './editor-dashboard-image/editor-dashboard-image';
export * from './editor-drawing-circle/editor-drawing-circle';
export * from './editor-form-button/editor-form-button';
export * from './editor-component-base';
export * from './editor-form-numeric-textbox/editor-form-numeric-textbox';
export * from './editor-form-radio/editor-form-radio.component';
export * from './editor-form-textinput/editor-form-textinput';
export * from './editor-safety-at-risk/editor-safety-at-risk';
export * from './editor-form-checkbox/editor-form-checkbox';
