import { Pipe, PipeTransform } from '@angular/core';

declare let _: any;

@Pipe({
  name: 'CheckboxFilter',
})
/**
 * Arg should be an array of objects in this format
 *
 ts:
 checkboxes: any[] = [{
        id: 1,
        label: 'Filter 1',
        state: true
    }];

 forceUpdateCheckboxes(): void {
        if( this.checkboxes )
            this.checkboxes = this.checkboxes.slice();
    }

 html:

 <div class="btn-group">
 <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
 Filter
 </button>
 <div class="dropdown-menu">
 <span class="dropdown-item" *ngFor="let cb of checkboxes">
 <label>
 <input id="designationFilter{{cb.id}}" type="checkbox" [(ngModel)]="cb.value" (ngModelChange)="forceUpdateCheckboxes()"> {{cb.label}}
 </label>
 </span>
 </div>
 </div>

 */
export class CheckboxFilterPipe implements PipeTransform {
  transform(values: any[], filterKey: string, args: string[]): any {
    return values.filter((value) => {
      for (let i = 0; i < args.length; i++) {
        const arg = args[i];
        if (arg['value'] == true && value[filterKey] != undefined) {
          if (value[filterKey].indexOf(arg['label']) >= 0) {
            return true;
          }
        }
      }
      return false;
    });
  }
}
