import { Injectable } from '@angular/core';
import { ApplicationModel } from '@ups/xplat/api/dto';
import { TimekeepingService } from '@ups/xplat/api/services';

@Injectable({ providedIn: 'root' })
export class AbsentReasonResolver {
  constructor(private timekeepingService: TimekeepingService, private applicationModel: ApplicationModel) {}

  public load(): Promise<any> {
    const self = this;
    if (this.applicationModel.absentReasons.length === 0) {
      return this.timekeepingService.getAbsentReasons().then((data) => (self.applicationModel.absentReasons = data));
    } else {
      return Promise.resolve(self.applicationModel.absentReasons);
    }
  }
}
