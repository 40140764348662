<div class="dr-admin-design-surface">
  <div class="dr-admin-panel panel-toolbox">
    <h5>TOOLBOX</h5>

    <div class="dr-admin-tools">
      <input
        type="text"
        class="k-textbox"
        (input)="filterChange($event.target.value)"
        placeholder="Filter..."
        style="width: 100%; margin-bottom: 6px"
      />

      <accordion [isAnimated]="true">
        <accordion-group
          (isOpenChange)="isOpenChange('normal', $event)"
          [isOpen]="open['normal']"
        >
          <div accordion-heading>
            Standard Controls
            <i
              class="fa pull-xs-right"
              [class.fa-angle-down]="!open['normal']"
              [class.fa-angle-up]="open['normal']"
            ></i>
          </div>

          <div class="tool-category">
            <div class="items-container">
              <div
                *ngFor="let item of filteredToolboxItems"
                class="toolbox-item"
                (click)="onToolboxItemClick(item)"
              >
                <div class="icon" style="width: 30px">
                  <i [class]="'fas fa-' + item.icon" *ngIf="!item.iconSrc"></i>
                  <img
                    [src]="'assets/icons/' + item.iconSrc"
                    *ngIf="item.iconSrc"
                  />
                </div>
                <div class="name">
                  <span>{{ item.displayName }}</span>
                </div>
              </div>
            </div>
          </div>
        </accordion-group>

        <accordion-group
          (isOpenChange)="isOpenChange('existing', $event)"
          [isOpen]="open['existing']"
        >
          <div accordion-heading>
            Reusable Controls
            <i
              class="fa pull-xs-right"
              [class.fa-angle-down]="!open['existing']"
              [class.fa-angle-up]="open['existing']"
            ></i>
          </div>

          <div class="tool-category">
            <div class="items-container">
              <div
                *ngFor="let item of filteredReusableControls"
                class="toolbox-item"
                (click)="onReusableToolboxItemClick(item)"
              >
                <div class="icon" style="width: 30px">
                  <img [src]="'assets/icons/ico-textare.svg'" />
                </div>
                <div class="name">
                  <span>{{ item.dynamicControlName }}</span>
                </div>
              </div>
            </div>
          </div>
        </accordion-group>
      </accordion>

      <!-- <div class="tool-category">
      <h6>Dashboard</h6>

      <div class="items-container">
        <div
          *ngFor="let item of toolboxGroupDashboard"
          class="toolbox-item"
          (click)="onToolboxItemClick(item)"
        >
          <div class="icon">
            <i [class]="'fas fa-' + item.icon" *ngIf="!item.iconSrc"></i>
            <img [src]="'assets/icons/' + item.iconSrc" *ngIf="item.iconSrc" />
          </div>
          <div class="name">
            <span>{{ item.displayName }}</span>
          </div>
        </div>
      </div>
    </div> -->

      <!-- <div class="tool-category">
      <h6>Drawing</h6>

      <div class="items-container">
        <div
          *ngFor="let item of toolboxGroupDrawing"
          class="toolbox-item"
          (click)="onToolboxItemClick(item)"
        >
          <div class="icon">
            <i [class]="'fas fa-' + item.icon" *ngIf="!item.iconSrc"></i>
            <img [src]="'assets/icons/' + item.iconSrc" *ngIf="item.iconSrc" />
          </div>
          <div class="name">
            <span>{{ item.displayName }}</span>
          </div>
        </div>
      </div>
    </div> -->

      <!-- <div class="tool-category">
      <h6>Safety</h6>

      <div class="items-container">
        <div
          *ngFor="let item of toolboxGroupSafety"
          class="toolbox-item"
          (click)="onToolboxItemClick(item)"
        >
          <div class="icon">
            <i [class]="'fas fa-' + item.icon" *ngIf="!item.iconSrc"></i>
            <img [src]="'assets/icons/' + item.iconSrc" *ngIf="item.iconSrc" />
          </div>
          <div class="name">
            <span>{{ item.displayName }}</span>
          </div>
        </div>
      </div>
    </div> -->
    </div>
  </div>
</div>
