export class HttpClientError {
  public statusCode: number;
  public url: string;

  public errorObjectIsJson: boolean;
  public errorObject: any;

  public messages: string[];
  public messageCode: string;

  public exceptionType: string;
  public exceptionDetails: string;

  public modelValidationResult: any;

  public message(delimiter = '<br />'): string {
    return this.messages.join(delimiter);
  }
}
