import {
  Component,
  ChangeDetectorRef,
  ElementRef,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { FilterByPipe } from '@ups/common';

declare var jQuery;

@Component({
  templateUrl: './text-filter.component.html',
  selector: 'ups-text-filter',
  styleUrls: ['./text-filter.component.scss'],
})
export class UPSTextFilter {
  $el: any;
  prevSearch: string = '';
  search: string = '';
  @Input('field') field: string;
  @Output() filterChanged = new EventEmitter<any>();
  isFiltered: boolean = false;

  constructor(
    el: ElementRef,
    private cdr: ChangeDetectorRef,
    private filterBy: FilterByPipe
  ) {
    this.$el = jQuery(el.nativeElement);
  }

  onFilterChanged() {
    this.filterChanged.emit({ search: this.search });
  }

  transform(data: any[], property?: string) {
    if (property === undefined) property = this.field;
    if (property === undefined) return data;
    let searchObj = {};
    searchObj[property] = this.search;
    return this.filterBy.transform(data, searchObj);
  }

  clear() {
    this.search = null;
  }

  reset() {
    this.clear();
    this.isFiltered = false;
  }

  cancel() {
    this.search = this.prevSearch;
  }

  confirm() {
    this.onFilterChanged();
    this.prevSearch = this.search;
    this.isFiltered = this.search != null && this.search.length !== 0;
  }

  odataFilterString() {
    let odataFilter = '';
    if (this.search != null && this.search !== '') {
      odataFilter =
        odataFilter +
        'contains(tolower(' +
        this.field +
        "),tolower('" +
        this.search +
        "'))";
    }
    return odataFilter;
  }
}
