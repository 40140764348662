export function throwIfAlreadyLoaded(
  parentModule: unknown,
  moduleName: string
) {
  if (parentModule) {
    throw new Error(
      `${moduleName} has already been loaded. Import ${moduleName} in the AppModule only.`
    );
  }
}

/**
 * General view binding helper to aid in cases where you need a ghost click
 */
export function noop() {
  console.log('noop');
}
