import { Component, Input, OnInit, OnChanges } from '@angular/core';

import {
  GridDataResult,
  DataStateChangeEvent,
} from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';

import { EquipmentService } from '@ups/xplat/api/services';
import { BaseComponent } from '@ups/xplat/core';

@Component({
  selector: 'ups-current-equipment-request',
  templateUrl: './current-equipment-request.component.html',
  styleUrls: ['./current-equipment-request.component.scss'],
})
export class CurrentEquipmentRequestComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() vpCompanyID: string;
  @Input() equipmentName: string;

  equipmentRequests: any[] = [];
  public gridData: GridDataResult;
  public state: State = {
    skip: 0,
    take: 10,
  };
  isLoading: boolean = false;

  constructor(private equipmentService: EquipmentService) {
    super();
  }

  ngOnInit() {}

  ngOnChanges() {
    this.equipmentRequests = [];
    this.gridData = process(this.equipmentRequests, this.state);
    this.loadCurrentEquipmentRequests();
  }

  loadCurrentEquipmentRequests() {
    const self = this;
    if (this.vpCompanyID && this.equipmentName) {
      self.isLoading = true;
      this.equipmentService
        .getCurrentEquipmentRequests(this.vpCompanyID, this.equipmentName)
        .subscribe((d: any) => {
          self.equipmentRequests = d;
          self.gridData = process(self.equipmentRequests, self.state);
          self.isLoading = false;
        });
    }
  }

  dataStateChange(state: DataStateChangeEvent): any {
    this.state = state;
    this.gridData = process(this.equipmentRequests, this.state);
  }
}
