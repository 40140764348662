import * as _ from 'lodash';

import { ReplaySubject, forkJoin, of } from 'rxjs';

import { GridComponent } from '@progress/kendo-angular-grid';
import { DataResult, State } from '@progress/kendo-data-query';

import { PathSegment } from './../Group-Result/path-segment';
import { GroupResultMerger } from './../Group-Result/group-result-merger';

import { GroupCollapseExpandUtils } from './../Grouping/Utils/group-collapse-expand-utils';

import { GroupOData } from './OData/group-odata';
import { ODataResult } from '@ups/xplat/features';

import { GroupingExViewModel } from './grouping-ex.view-model';

export class GroupingExLoadResult {
  loadedGroupSets: DataResult[] = [];
  processedGroupSets: DataResult[] = [];

  loadedItemSets: DataResult[] = [];
  processedItemSets: DataResult[] = [];

  constructor() {
    // Ctor.
  }

  public addGroupDataSet(
    loadedGroupSet: DataResult,
    processedGroupSet: DataResult
  ): GroupingExLoadResult {
    this.loadedGroupSets.push(loadedGroupSet);
    this.processedGroupSets.push(processedGroupSet);
    return this;
  }

  public addItemDataSet(
    loadedItemSet: DataResult,
    processedItemSet: DataResult
  ): GroupingExLoadResult {
    this.loadedItemSets.push(loadedItemSet);
    this.processedItemSets.push(processedItemSet);
    return this;
  }
}

/**
 * Grid rules:
 * - group is only shown in grid only, if there's an item to it (sub-group and/or child items)
 * - if an aggregate data item contains (even a single) item (dataItem.items) in the items array, then those items are FULLY loaded
 * - since we enabled aggregates on all group levels, we need to load groups level by level
 *
 * For load the following rules apply:
 * - modifies existing grid-view data property
 * - does not alter other values
 */
export class GroupingExLoad {
  public static loadData(
    vm: GroupingExViewModel,
    grid: GridComponent,
    state: State
  ): ReplaySubject<GroupingExLoadResult> {
    // NOTE: grid still might have a diff. state set, the new state is in state parameter!

    const finishEventEmitter: ReplaySubject<GroupingExLoadResult> =
      new ReplaySubject<GroupingExLoadResult>();

    // copy to ensure non-stalled data over entire process
    const stateCopy = _.cloneDeep(state) as State;

    if (stateCopy.group && stateCopy.group.length) {
      //
      // load group(s)
      const odataQuery = GroupOData.getGroupQuery(
        stateCopy,
        null,
        vm.linkedAggregateFields,
        true,
        vm.loadGroupsByLevels ? 1 : 0,
        'count',
        vm.customODataAggregateExpression
      );

      vm.loadDataSubscription = vm.getDataLoadObservable(odataQuery).subscribe(
        (d) => {
          const serverData = d['value'];
          const serverTotalCount = parseInt(d['@odata.count'], 10);

          finishEventEmitter.next(
            new GroupingExLoadResult().addGroupDataSet(
              { data: serverData, total: serverTotalCount } as DataResult,
              GroupResultMerger.processGroups(
                null,
                serverData,
                state,
                vm.linkedAggregateFields,
                vm.loadGroupsByLevels ? 1 : 0
              )
            )
          );
        },
        (error) => {
          throw error;
        }
      );
    } else {
      //
      // load items - in case there's no grouping we need to do skip/take!
      const odataQuery = GroupOData.getItemQuery(
        stateCopy,
        null,
        vm.loadAllItemsToGroups,
        vm.oDataSettings
      );

      vm.loadDataSubscription = vm.getDataLoadObservable(odataQuery).subscribe(
        (d) => {
          const serverData = d['value'];
          const serverTotalCount = parseInt(d['@odata.count'], 10);

          finishEventEmitter.next(
            new GroupingExLoadResult().addItemDataSet(
              { data: serverData, total: serverTotalCount } as DataResult,
              GroupResultMerger.processItems(serverData, state)
            )
          );
        },
        (error) => {
          throw error;
        }
      );
    }

    return finishEventEmitter;
  }

  public static mergeLoadDataResultToGrid(
    vm: GroupingExViewModel,
    grid: GridComponent,
    state: State,
    result: GroupingExLoadResult,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    newItem: any = null
  ) {
    // NOTE: either one group result... or one item result...
    if (result.processedGroupSets && result.processedGroupSets.length) {
      // assign (alter existing grid.view object)
      grid.data = result.processedGroupSets[0];
      grid.data.total = result.loadedGroupSets[0].total;

      GroupingExLoad.addNewItem(newItem, grid.data.data);

      // expanding - if all groups were loaded, expand all (keep all expanded and collapse just items), otherwise collapse all beneath...
      if (vm.loadGroupsByLevels)
        GroupCollapseExpandUtils.collapseAll(grid, grid.data.data);
      else
        GroupCollapseExpandUtils.expandAllCollapseLast(
          grid,
          state,
          grid.data.data
        );
    } else if (result.processedItemSets && result.processedItemSets.length) {
      // assign
      grid.data = result.processedItemSets[0];
      grid.data.total = result.loadedItemSets[0].total;

      GroupingExLoad.addNewItem(newItem, grid.data.data);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static addNewItem(newItem: any, data: any[]) {
    if (newItem != null) {
      const newItemIndex = data.findIndex(
        (x) => JSON.stringify(x) === JSON.stringify(newItem)
      );
      if (newItemIndex === -1) {
        data.unshift(newItem);
      } else {
        const tmpData = data[newItemIndex];
        data[newItemIndex] = data[0];
        data[0] = tmpData;
      }
    }
  }

  public static loadNextLevel(
    vm: GroupingExViewModel,
    grid: GridComponent,
    state: State,
    groupDataItem: unknown
  ): ReplaySubject<GroupingExLoadResult> {
    const finishEventEmitter: ReplaySubject<GroupingExLoadResult> =
      new ReplaySubject<GroupingExLoadResult>();

    // copy to ensure non-stalled data over entire process
    const stateCopy = _.cloneDeep(state) as State;

    // i could use the index as the group (dataItem) to get path
    const gridData = grid.data;
    const groupDataItemPath =
      PathSegment.getPathToDataItem(gridData['data'], groupDataItem) || [];

    // need to check if items or groups has to be loaded
    const loadGroups =
      !stateCopy.group || groupDataItemPath.length < stateCopy.group.length;

    let odataQuery: string;

    // do the load
    if (loadGroups) {
      // load groups
      odataQuery = GroupOData.getGroupQuery(
        stateCopy,
        groupDataItemPath,
        vm.linkedAggregateFields,
        false,
        vm.loadGroupsByLevels ? 1 : 0,
        'count',
        vm.customODataAggregateExpression
      );
    } else {
      // load items
      odataQuery = GroupOData.getItemQuery(
        stateCopy,
        groupDataItemPath,
        vm.loadAllItemsToGroups,
        vm.oDataSettings
      );
    }

    vm.getDataLoadObservable(odataQuery).subscribe(
      (d) => {
        const serverData = d['value'];
        const serverTotalCount = parseInt(d['@odata.count'], 10);

        // finish event
        finishEventEmitter.next(
          loadGroups
            ? new GroupingExLoadResult().addGroupDataSet(
                { data: serverData, total: serverTotalCount } as DataResult,
                GroupResultMerger.processGroups(
                  groupDataItemPath,
                  serverData,
                  state,
                  vm.linkedAggregateFields,
                  vm.loadGroupsByLevels ? 1 : 0
                )
              )
            : new GroupingExLoadResult().addItemDataSet(
                { data: serverData, total: serverTotalCount } as DataResult,
                GroupResultMerger.processItems(serverData, state)
              )
        );
      },
      (error) => {
        throw error;
      }
    );

    return finishEventEmitter;
  }

  public static mergeLoadNextLevelResultToGrid(
    vm: GroupingExViewModel,
    grid: GridComponent,
    state: State,
    groupDataItem: unknown,
    result: GroupingExLoadResult
  ) {
    // NOTE: either one group result or one item result...
    const gridData = grid.data;

    if (result.processedGroupSets && result.processedGroupSets.length) {
      GroupResultMerger.mergeSubTreeOfProcessedNewItemsWithGrid(
        gridData['data'],
        result.processedGroupSets[0].data
      );
      GroupCollapseExpandUtils.collapseSubtree(grid, groupDataItem);
    } else if (result.processedItemSets && result.processedItemSets.length) {
      // need to clear sub-items before adding news (prereq. for the merge)...
      groupDataItem['items'] = [];
      GroupResultMerger.mergeSubTreeOfProcessedNewItemsWithGrid(
        gridData['data'],
        result.processedItemSets[0].data
      );
    }
  }

  public static loadLevelsBelow(
    vm: GroupingExViewModel,
    grid: GridComponent,
    state: State,
    groupDataItem: unknown
  ): ReplaySubject<GroupingExLoadResult> {
    const finishEventEmitter: ReplaySubject<GroupingExLoadResult> =
      new ReplaySubject<GroupingExLoadResult>();

    // copy to ensure non-stalled data over entire process
    const stateCopy = _.cloneDeep(state) as State;

    // get path (here we could use the groupIndex as well, if we supply that info to events as well)
    const gridData = grid.data;
    const groupDataItemPath =
      PathSegment.getPathToDataItem(gridData['data'], groupDataItem) || [];

    // load all items (in 1 call)
    const itemOdataQuery = GroupOData.getItemQuery(
      stateCopy,
      groupDataItemPath,
      vm.loadAllItemsToGroups,
      vm.oDataSettings
    );
    const itemPromise = vm.getDataLoadObservable(itemOdataQuery);

    // to support group aggregates, we need to load also groups (one call for each level to support that level aggregates)
    // ...and also to put them on the grid, we need to start
    const groupPromises = [];

    const levels = stateCopy.group.length - groupDataItemPath.length;

    for (let i = 0; i < levels; i++) {
      const groupOdataQuery = GroupOData.getGroupQuery(
        stateCopy,
        groupDataItemPath,
        vm.linkedAggregateFields,
        false,
        i + 1,
        'count',
        vm.customODataAggregateExpression
      );
      const groupPromise = vm.getDataLoadObservable(groupOdataQuery);
      groupPromises.push(groupPromise);
    }

    // NOTE: forkJoin does not likes empty arrays, nor Observable.empty() - it'll make other promises/observables to be cancelled!
    if (!groupPromises.length)
      groupPromises.push(of(ODataResult.EmptyODataResult));

    forkJoin([
      forkJoin(groupPromises), // d[0]
      itemPromise, // d[1]
    ]).subscribe(
      (d) => {
        const loadResult = new GroupingExLoadResult();

        // groups (all levels)
        if (d[0]) {
          const allGroupLevelsArray = d[0];

          allGroupLevelsArray.forEach((gdOdata, gdIndex) => {
            const groupData = gdOdata['value'];
            const groupTotalCount = parseInt(gdOdata['@odata.count'], 10); // originally d[0] instead fo gdOData

            loadResult.addGroupDataSet(
              { data: groupData, total: groupTotalCount } as DataResult,
              GroupResultMerger.processGroups(
                groupDataItemPath,
                groupData,
                state,
                vm.linkedAggregateFields,
                gdIndex + 1
              )
            );
          });
        }

        // items
        const itemData = d[1]['value'];
        const itemTotalCount = parseInt(d[1]['@odata.count'], 10);

        loadResult.addItemDataSet(
          { data: itemData, total: itemTotalCount } as DataResult,
          GroupResultMerger.processItems(itemData, state)
        );

        // finish event
        finishEventEmitter.next(loadResult);
      },
      (error) => {
        throw error;
      }
    );

    return finishEventEmitter;
  }

  public static mergeLoadLevelsBelowResultToGrid(
    vm: GroupingExViewModel,
    grid: GridComponent,
    state: State,
    groupDataItem: unknown,
    result: GroupingExLoadResult
  ) {
    // NOTE: 0..N group results and/or one item result...
    const gridData = grid.data;

    result.processedGroupSets.forEach((gds) => {
      GroupResultMerger.mergeSubTreeOfProcessedNewItemsWithGrid(
        gridData['data'],
        gds.data
      );
    });

    result.processedItemSets.forEach((ids) => {
      const mergeItemsToExistingAggregatesOnly = vm.loadAllItemsToGroups; // NOTE: if we always load and process groups first, so we can put TRUE here safely!
      GroupResultMerger.mergeSubTreeOfProcessedNewItemsWithGrid(
        gridData['data'],
        ids.data,
        mergeItemsToExistingAggregatesOnly
      );
    });

    if (vm.autoExpandGroupOnSelection) {
      GroupCollapseExpandUtils.expandSubtree(grid, groupDataItem, true);
    } else {
      // NOTE: change introduced by final refactor: originally we kept newly loaded tree fully expanded (except the main node)...so no ELSE branch was here present...
      GroupCollapseExpandUtils.collapseSubtree(grid, groupDataItem);
    }
  }
}
