export class PlantBasicLogDto {
  modifiedUser: string;
  dateModified: string;
  plantId: string;
  companyName: string;
  plantFacilityDisplayName: string;
  customerName: string;
  address: string;
  disaOwnerForDrugTest: string;
  disaOwnerIdForBackground: string;
  disaOwnerNameForBackground: string;
  daysUntilUrineRetestRequiredPreOnsite: number;
  daysUntilRetestRequired: number;
  daysUntilHairRetestPreOnsite: number;
  daysUntilHairRetestOnsite: number;
  daysUntilOralRetestPreOnsite: number;
  daysUntilOralRetestRequire: number;
  daysUntilBackgroundRecheckPreOnsite: number;
  daysUntilBackgroundRecheckOnsite: number;
}
