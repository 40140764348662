import { BaseModel } from '../base.model';

export class LodgeReservationDto extends BaseModel {
  public LodgeReservationID: string;
  public ManPowerReqDetailID: string;
  public EmployeeID: string;
  public ProspectID: string;
  public FullName: string;
  public JobID: string;
  public VPJobID: string;
  public JobName: string;
  public LodgeID: string;
  public LodgeRoomTypeID: string;
  public LodgeName: string;
  public ReservationNumber: string;
  public RoomType: string;
  public CheckInDate: Date;
  public CheckOutDate: Date;
  public CheckedIn?: boolean;
  public Notes: string;
  public ReservationRequestChangeText: string;
  public ReservationRequestChangeError: string;
  public SendMessage = false;
  public OverrideMessage: string;
  public bActive: boolean;
  public SupervisorName: string;
  public LastReservationChange: Date;
  public LastTextSent: Date;

  constructor(o: any = null) {
    super(o);
  }
}
