export const TaskConstants = {
  //Compliance Dashboard
  assignmentGroups: {
    fitTestAndCertApprovers: '96FE2125-83DE-4C08-A1C5-59301685EF14',
  },

  systemTasksTypes: {
    employee_fit_test_verification: 'F6097FD1-0BCC-4992-B470-5B9D15F86829',
    employee_certification_verification: '95D35927-013C-481E-8019-3163A4BA641A',
  },
};
