import * as _ from 'lodash';

import { SortDescriptor, State } from '@progress/kendo-data-query';

export class GroupStateUtils {
  /**
   * Creates a new State object to get the count of all items avail. by the supplied State object.
   * The new State object is optimized to avoid unnecessary data load (take 0) and db-operations (skip group, sort).
   */
  public static getCountQueryState(state: State): State {
    const countQueryState = _.cloneDeep(state) as State;

    countQueryState.take = 0;
    delete countQueryState.skip;

    delete countQueryState.group;
    delete countQueryState.sort;

    return countQueryState;
  }

  /**
   * This function:
   * 1/ compares whether a sort-order change on the group/column occured and applies sort & direction accordingly on Group or SortDecriptor!
   * 2/ ensures that grouping fields are added to the start of State's SortDescriptor array - as we do generate OData queries based on Sortdescriptors!
   * @param oldState
   * @param newState
   */
  public static consolidateSortOrder(oldState: State, newState: State): State {
    if (!newState.group || !newState.group.length) return newState;

    const localNewState = _.cloneDeep(newState) as State;

    //
    // create sort (from group sort)

    // move group sorts into 1st positions
    // if group sorts does not exists, add them to proper place
    const newSort: SortDescriptor[] = [];

    if (localNewState.group) {
      localNewState.group.forEach((g) => {
        const sortDescriptor = localNewState.sort ? localNewState.sort.find((s) => s.field === g.field) : undefined;

        if (sortDescriptor) newSort.push(sortDescriptor);
        else newSort.push({ field: g.field, dir: g.dir } as SortDescriptor);
      });
    }

    if (localNewState.sort)
      localNewState.sort.forEach((s) => {
        const alreadyExists = newSort.some((i) => i.field === s.field);
        if (!alreadyExists) newSort.push(s);
      });

    if (newSort && newSort.length) localNewState.sort = newSort;

    //
    // now patch changes
    const patchedSortFields: string[] = [];

    if (localNewState.group) {
      // patch SortDescriptor based on GroupDescriptor sort changes...
      localNewState.group.forEach((newGroup) => {
        const oldGroup = oldState.group ? oldState.group.find((og) => og.field === newGroup.field) : undefined;

        if (!oldGroup || oldGroup.dir != newGroup.dir) {
          // when sort changed on GroupDescriptor (or no group desc. previously)...
          // ...check corresponding SortDescriptor
          // ...patch sort order there!
          const sortField = localNewState.sort ? localNewState.sort.find((sf) => sf.field === newGroup.field) : undefined;

          if (sortField && sortField.dir != newGroup.dir) {
            sortField.dir = newGroup.dir;
            patchedSortFields.push(newGroup.field);
          }
        }
      });
    }

    if (localNewState.sort) {
      // patch SortDescriptor based on GroupDescriptor sort changes...
      localNewState.sort.forEach((newSort) => {
        const sortFieldAlreadyPatched = patchedSortFields.some((psf) => psf === newSort.field);
        if (sortFieldAlreadyPatched) return;

        const oldSort = oldState.sort ? oldState.sort.find((os) => os.field === newSort.field) : undefined;

        if (!oldSort || oldSort.dir != newSort.dir) {
          // when sort changed on SortDescriptor (but not due previous patch)...or no prev. sort (so change as well)...
          // ...check corresponding GroupDescriptor
          // ...patch sort order there!
          const groupField = localNewState.group ? localNewState.group.find((gf) => gf.field === newSort.field) : undefined;

          if (groupField && groupField.dir != newSort.dir) {
            groupField.dir = newSort.dir;
          }
        }
      });
    }

    return localNewState;
  }
}
