<ups-header help-anchor="header__title" title="Sync SPARTA"></ups-header>

<section class="pt-7">
  <div class="container-fluid gutter-30">
    <div class="row">
      <div class="col-md-12">
        <kendo-card class="custom-style">
          <kendo-card-body>
            <div *ngIf="hasReadPermissions">
              <div class="row">
                <div class="col-12">
                  <label>Job</label>
                  <ups-job-search
                    (valueChange)="jobFilterChanged($event)"
                  ></ups-job-search>
                </div>
              </div>
              <div class="mt-4">
                <!-- <ups-sync-sparta [job]="selectedJob" [showInCompactMode]="false">
                </ups-sync-sparta> -->
                <ups-sync-feature
                  [job]="selectedJob"
                  [showInCompactMode]="false"
                ></ups-sync-feature>
              </div>
            </div>

            <div *ngIf="!hasReadPermissions">
              <span>
                You currently don't have permissions to work with Sparta Sync.
              </span>
            </div>
          </kendo-card-body>
        </kendo-card>
      </div>
    </div>
  </div>
</section>
