import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GridModule } from '@progress/kendo-angular-grid';
import { UIModule } from '../ui';
import {
  GroupingGridExModule,
  GridModuleEx,
} from '../kendo';

import {
  DispatchStatusEditComponent,
  DispatchStatusesPageComponent,
} from './components';

const routes: Routes = [
  {
    path: '',
    component: DispatchStatusesPageComponent,
  },
];

@NgModule({
  imports: [
    UIModule,
    RouterModule.forChild(routes),
    GridModule,
    GridModuleEx,
    GroupingGridExModule,
  ],
  declarations: [
    DispatchStatusesPageComponent,
    DispatchStatusEditComponent,
  ],
  exports: [
    DispatchStatusesPageComponent,
    DispatchStatusEditComponent,
  ],
})
export class DispatchStatusesPageModule {}
