import { Directive, Input, inject } from '@angular/core';
import { BaseComponent, EventBusService, WindowService } from '@ups/xplat/core';
import { BehaviorSubject, filter, takeUntil } from 'rxjs';

@Directive()
export abstract class HeaderBaseComponent extends BaseComponent {
  win = inject(WindowService);
  @Input() title = '';
  @Input() showLogo = false;
  @Input() showSettings = false;
  @Input() logoSrc = '';
  @Input() logoAltText = '';
  @Input() breadcrumbs: Array<{
    name?: string;
    path?: string;
    disabled?: boolean;
  }> = [
    {
      name: 'Home',
      path: '/home',
    },
    {
      name: 'Section',
      path: '#',
    },
    {
      name: 'Subsection',
      path: '#',
    },
    {
      name: 'Page',
      disabled: true,
    },
  ];
  options$: BehaviorSubject<{ title: string; icon?: string }> =
    new BehaviorSubject(null);

  constructor(protected eventBus: EventBusService) {
    super();
    this.eventBus
      .observe(this.eventBus.types.headerUpdateTitle)
      .pipe(
        // update header title with exception cases
        filter((_) => !!this.win.headerUpdatesEnabled),
        takeUntil(this.destroy$)
      )
      .subscribe((options: { title: string; icon?: string }) => {
        this.options = options;
      });
  }

  @Input()
  set options(value: { title: string; icon?: string }) {
    this.options$.next(value);
  }

  get options() {
    return this.options$.getValue();
  }
}
