import {
  BaseRouterStoreState,
  getRouterSelectors,
  RouterReducerState,
} from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AppRouterState {
  export interface IState {
    default: RouterReducerState<BaseRouterStoreState>;
    custom: {};
  }

  export const initialState: IState = {
    default: {
      navigationId: null,
      state: null,
    },
    custom: {},
  };

  export const selectRouter = createFeatureSelector<IState>('router');

  export const selectDefaultRouter = createSelector(
    selectRouter,
    (x) => x.default
  );

  export const {
    selectCurrentRoute, // select the current route
    selectFragment, // select the current route fragment
    selectQueryParams, // select the current route query params
    selectQueryParam, // factory function to select a query param
    selectRouteParams, // select the current route params
    selectRouteParam, // factory function to select a route param
    selectRouteData, // select the current route data
    selectUrl, // select the current url
  } = getRouterSelectors(selectDefaultRouter);
}
