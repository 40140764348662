import { Component } from '@angular/core';
import { DynamicItemBaseComponent } from '@ups/xplat/features';

@Component({
  selector: 'ups-dynamic-label',
  template: `
    <div class="dynamic-field-container" [class.selected]="config?.selected">
      <div
        class="dynamic-field form-input text-align"
        [hidden]="!config?.isFormBuilder && config?.options?.hidden"
      >
        <div [innerHTML]="config?.label"></div>
        <div
          class="form-builder-mask"
          [class.hidden]="config?.options?.hidden"
          *ngIf="config?.isFormBuilder"
        ></div>
      </div>
      <div class="form-builder-options" *ngIf="config?.isFormBuilder">
        <i
          class="fas fa-times ml-3 cursor-pointer c-red"
          (click)="delete()"
        ></i>
        <!-- <i class="fas fa-bars ml-3 cursor-grab "></i> -->
      </div>
    </div>
  `,
})
export class DynamicLabelComponent extends DynamicItemBaseComponent {}
