import { Directive, Injector, Input, OnInit } from '@angular/core';
import { BaseComponent, EmployeeFitCertValidation } from '@ups/xplat/core';
import { EmployeeCertificationService, EmployeeService } from '../services';
import { AttachmentVpService } from '@ups/xplat/api/services';
import * as moment from 'moment/moment';
import { Store } from '@ngrx/store';
import { UserState } from '@ups/user';
import { IEmployeeInfo, ProfileModel } from '@ups/xplat/api/dto';
import { TasksService } from '@ups/xplat/features';
import { SecurityService } from '@ups/security';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FileDto } from '@ups/files';

@Directive()
export abstract class ProfileCertificationBaseComponent
  extends BaseComponent
  implements OnInit
{
  @Input() profile: ProfileModel;

  attachmentService: AttachmentVpService;
  authProfile: IEmployeeInfo;
  form: UntypedFormGroup;
  isLoading = false;
  text = 'ProfileCertification';
  uploadedFile: FileDto;

  constructor(
    protected injector: Injector,
    protected employeeCertification: EmployeeCertificationService,
    protected employeeService: EmployeeService,
    protected taskService: TasksService,
    protected securityService: SecurityService,
    protected store: Store<unknown>
  ) {
    super();
    this.attachmentService = this.injector.get(AttachmentVpService);
  }

  ngOnInit() {
    this.isLoading = true;

    this.form = new UntypedFormGroup({
      certificationDate: new UntypedFormControl(null, Validators.required),
      expirationDate: new UntypedFormControl(null, Validators.required),
    });

    this.store
      .pipe(UserState.selectCurrentTruthy(this.destroy$))
      .subscribe((x) => {
        this.authProfile = x;
        this.loadData();
      });
  }

  loadData() {
    this.employeeCertification
      .getCertificationById(this.profile?.hrRef)
      .subscribe((certificationData) => {
        const now = moment();
        const skills = certificationData.map((item) => ({
          ...item,
          isExpired: moment(item.ExpireDate).diff(now, 'days') < 0,
          dropdownExpired:
            moment(item.ExpireDate).diff(now, 'days') < 0
              ? 'Inactive'
              : 'Active',
        }));
        this.securityService
          .getSecurityProfileOfLoggedInUser()
          .subscribe((security) => {
            this.taskService
              .getTasksForRelatedUser(security.userId)
              .subscribe((requestData) => {
                const tasks = requestData.map(
                  (t) => new EmployeeFitCertValidation(t)
                );
                const approvalRequests = tasks.map((request) => ({
                  approvalRequest: true,
                  isCertificationValidation: request.isCertificationValidation,
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  CertDate: request.testDate,
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  ExpireDate: request.expirationDate,
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  Files: [
                    {
                      // eslint-disable-next-line @typescript-eslint/naming-convention
                      AttachmentID: request.fileId,
                      // eslint-disable-next-line @typescript-eslint/naming-convention
                      FileName: request.fileName,
                    },
                  ],
                }));
                this.data = approvalRequests
                  .filter((request) => request.isCertificationValidation)
                  .concat(...skills);
                this.isLoading = false;
              });
          });
      });
  }

  abstract set data(value: Array<unknown>);

  getFile(attachmentID: number): Promise<unknown> {
    return new Promise((resolve) => {
      if (attachmentID) {
        this.attachmentService.getFileById2(attachmentID).then(
          (data) => {
            // A byte array serialized by JSON is then returned as Base64 encoded string
            resolve(data);
          },
          () => {
            resolve(null);
          }
        );
      }
    });
  }

  public requestNewCertification(formData?) {
    this.securityService
      .getSecurityProfileOfLoggedInUser()
      .subscribe((data) => {
        const t = new EmployeeFitCertValidation(null);
        t.employeeFullName =
          this.authProfile.Data.LastName +
          ', ' +
          this.authProfile.Data.FirstName;
        t.hrRef = this.authProfile.Data.HRRef;
        t.relatedUserId = data.userId;
        t.testDate =
          formData?.certificationDate ?? this.form?.value?.certificationDate;
        t.expirationDate =
          formData?.expiration ?? this.form?.value?.expirationDate;
        t.fileId = formData?.attachment[0]?.fileID ?? this.uploadedFile?.fileID;
        t.fileName = formData?.attachment[0]?.name ?? this.uploadedFile?.name;
        t.isCertificationValidation = true;
        t.certificationCode =
          formData?.certificationCode ??
          this.form?.value?.certificationCode?.text;
        const x = t.createSystemTaskRegistration();
        this.taskService.enrollInSystemTask(x).subscribe(() => this.loadData());
      });
  }
}
