import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SearchCompanyService } from '@ups/xplat/api/services';
import { dynamicTypeaheadCompanyFilter } from '@ups/xplat/features';
import { DynamicTypeaheadWebBaseComponent } from '../base/dynamic-typeahead.web-base-component';

const SEARCH_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DynamicTypeaheadCompanyComponent),
  multi: true,
};

@Component({
  selector: 'ups-dynamic-typeahead-company',
  templateUrl: 'dynamic-typeahead-company.component.html',
  providers: [SEARCH_CONTROL_VALUE_ACCESSOR],
})
export class DynamicTypeaheadCompanyComponent extends DynamicTypeaheadWebBaseComponent {
  constructor(private searchService: SearchCompanyService) {
    super();
    this.queryFilter = dynamicTypeaheadCompanyFilter;
    this.searchQuery = this.searchService.search.bind(this.searchService);
  }
}
