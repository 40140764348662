import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { EquipmentRequirementsEditorComponent } from './equipment-requirements-editor.component';
import { EquipmentSubtaskListComponent } from './equipment-subtask-list/equipment-subtask-list.component';
import { EquipmentSubtaskEditorComponent } from './equipment-subtask-editor/equipment-subtask-editor.component';
import {
  DropDownsModuleEx,
  GridModuleEx,
  GroupingGridExModule,
} from '../kendo';
import { EditorModule } from '@progress/kendo-angular-editor';

const MODULES = [
  CommonModule,
  FormsModule,
  DropDownsModule,
  DropDownsModuleEx,
  GridModule,
  GridModuleEx,
  GroupingGridExModule,
  DialogModule,
  InputsModule,
  LayoutModule,
  EditorModule,
];

@NgModule({
  imports: [...MODULES],
  declarations: [
    EquipmentRequirementsEditorComponent,
    EquipmentSubtaskListComponent,
    EquipmentSubtaskEditorComponent,
  ],
  exports: [
    ...MODULES,
    EquipmentRequirementsEditorComponent,
    EquipmentSubtaskListComponent,
    EquipmentSubtaskEditorComponent,
  ],
})
export class EquipmentRequirementsEditorModule {}
