import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ProvideNgFormDirective } from './provideNgForm.directive.ts';
import { ProvideNgModelGroupDirective } from './provideNgModelGroup.directive';
import { ProvideControlContainerDirective } from './provideControlContainer.directive';

import { ProvideFormGroupDirectiveDirective } from './provideFormGroupDirective.directive';

/**
 * TEMPLATE DRIVEN forms
 * - use CONTROL_CONTAINER_PROVIDER on the component viewProviders for nesting
 *
 * MODEL DRIVEN forms
 * - use FORM_GROUP_DIRECTIVE_PROVIDER to get access to the form group of the parent (and add child FormControls / FormGroups)
 */
@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [
    ProvideNgFormDirective,
    ProvideNgModelGroupDirective,
    ProvideControlContainerDirective,
    ProvideFormGroupDirectiveDirective,
  ],
  exports: [
    ProvideNgFormDirective,
    ProvideNgModelGroupDirective,
    ProvideControlContainerDirective,
    ProvideFormGroupDirectiveDirective,
  ],
})
export class FormsExModule {}
