import { Component, Input } from '@angular/core';
import { EquipmentService } from '@ups/xplat/api/services';
import { BaseComponent } from '@ups/xplat/core';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';

@Component({
  selector: 'ups-equipment-dispatch-history',
  templateUrl: './equipment-dispatch-history.component.html',
})
export class EquipmentDispatchHistoryComponent extends BaseComponent {
  @Input() jobID: string;

  opened: boolean = false;
  historyData: any[];
  canSeeEquipmentHyperlink: boolean = false;

  constructor(
    private security: SecurityService,
    private equipmentService: EquipmentService
  ) {
    super();
    this.canSeeEquipmentHyperlink = this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_equipmentresourcehyperlink
    ).readAll;
  }

  public open(equipmentDispatchID: string) {
    const self = this;
    this.equipmentService
      .getEquipmentDispatchHistory(this.jobID, equipmentDispatchID)
      .toPromise()
      .then((d) => {
        self.historyData = d;
        self.opened = true;
      });
  }

  public close() {
    this.opened = false;
  }
}
