/* eslint-disable @typescript-eslint/naming-convention */
import { EquipmentUsageDetailDto } from './EquipmentUsageDetailDto';

export class EquipmentUsageDto {
  public AltID: string;
  public BillingRate: number;
  public BillingTemplate: string;
  public Details: EquipmentUsageDetailDto[];
  public Equipment: string;
  public EquipmentName: string;
  public EquipmentBillingID: string;
  public EquipmentDispatchID: string;
  public EquipmentID: string;
  public Shift: number;
  public UnitName: string;
  public WorkOrder: string;
  public CustomerPO: string;
  public bActive: boolean;
  JobID?: string;
  WorkOrderDescription?: string;
  IsContractActiveWorkOrder?: boolean;
  DispatchStartDate?: Date;
  DispatchEndDate?: Date;
  TotalAmount?: number;
}
