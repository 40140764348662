import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy',
})

/**
 * Usage:
 * <input type="text" [(ngModel)]="search">
 *
 * <tr *ngFor="let item of mf.data | filterBy: {fieldName: searchValue}>
 *
 * Example:
 * data = [{ Employee: "Axel", FirstName: "Axel", LastName: "Jensen" }];
 * <tr *ngFor="let item of mf.data | filterBy: {Employee: search}>
 */
export class FilterByPipe implements PipeTransform {
  private filterByString(filter) {
    filter = filter.toLowerCase();
    return (value) => {
      return !filter || value.toLowerCase().indexOf(filter) !== -1;
    };
  }

  private filterByObject(filter) {
    return (value) => {
      for (let key in filter) {
        if (!value || !value.hasOwnProperty(key)) {
          return false;
        }

        if (filter[key] == undefined || filter[key] == '') {
          continue;
        }

        const type = typeof value[key];
        let isMatching;

        if (type === 'string') {
          isMatching = this.filterByString(filter[key])(value[key]);
        } else if (type === 'object') {
          isMatching = this.filterByObject(filter[key])(value[key]);
        } else {
          isMatching = this.filterDefault(filter[key])(value[key]);
        }

        if (!isMatching) {
          return false;
        }
      }

      return true;
    };
  }

  /**
   * Defatul filterDefault function
   *
   * @param filter
   * @returns {(value:any)=>boolean}
   */
  private filterDefault(filter) {
    return (value) => {
      return !filter || filter == value;
    };
  }

  private isNumber(value) {
    return !isNaN(parseInt(value, 10)) && isFinite(value);
  }

  transform(array: any[], filter: any): any {
    const type = typeof filter;

    if (array == null || array.length === 0) return;

    if (type === 'string') {
      if (this.isNumber(filter)) {
        return array.filter(this.filterDefault(filter));
      }

      return array.filter(this.filterByString(filter));
    }

    if (type === 'object') {
      return array.filter(this.filterByObject(filter));
    }

    return array.filter(this.filterDefault(filter));
  }
}
