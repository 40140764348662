import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EquipmentResourceGuardInterface } from './equipment-resource-guard.interface';
import { EmployeeGuardService } from '@ups/xplat/base/employee-profile';

@Injectable()
export class EquipmentResourceGuard {
  constructor(private service: EmployeeGuardService) {}

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.service.isToUseGuard) {
      return confirm('Discard Changes?');
    } else {
      return true;
    }
  }
}
