import { Action } from '@ngrx/store';
import { DrawerState } from './drawer.state';

export namespace DrawerActions {
  export enum Types {
    OPEN = '[@ups/drawer] Open',
    CLOSE = '[@ups/drawer] Close',
    SET_SUCCESS = '[@ups/drawer] Set Success',
  }

  export class Open implements Action {
    type: string = Types.OPEN;
    payload: string = null;
  }

  export class Close implements Action {
    type: string = Types.CLOSE;
    payload: string = null;
  }

  export type Actions = Open | Close;
}
