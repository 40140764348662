import {
  APP_BASE_HREF,
  CommonModule,
} from '@angular/common';
import {
  ErrorHandler,
  Inject,
  ModuleWithProviders,
  NgModule,
  Optional,
  Provider,
  SkipSelf,
} from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  FeatureSwitch,
  throwIfAlreadyLoaded,
} from '@ups/xplat/utils';

// app
import { StaticService } from './services/static.service';
import { GlobalErrorHandler } from './services/error-handler.service';
import { LogService } from './services/log.service';
// import { OfflineHttpTrackingService } from './services/offline-http-tracking.service';
import { OfflineStorageService } from './services/offline-storage.service';
import { SignalRPlatformService } from './services/signalr.service';
import { LocalStorageService } from './services/storage.service';
import { PlatformLanguageToken } from './services/tokens';
import { WindowService } from './services/window.service';
import { uiReducer } from './state';
import { RouterEffects } from './state/router/router.effects';
import { appRouterReducer } from './state/router/router.reducer';
import { UIEffects } from './state/ui/ui.effect';
import { environment } from './environments';

/**
 * DEBUGGING
 */
LogService.DEBUG.LEVEL_4 = !environment.production;
/**
 * ISOLATE_LOG_TAGS
 * Specify logTag's you only want to see (isolate them)
 */
// LogService.DEBUG.ISOLATE_LOG_TAGS = ['DynamicRenderService'];
// LogService.DEBUG.ISOLATE_LOG_TAGS = ['Offline', 'Sqlite'];
/**
 * EXCLUDE_LOG_TAGS
 * Specify logTag's which should be excluded
 */
LogService.DEBUG.EXCLUDE_LOG_TAGS = [
  'DynamicRenderService',
  // 'http',
  'Offline',
  'Sqlite',
  'WageSheetDataGridComponent',
  'WageSheetCalculationsService',
  'WageSheetDataEntryPageComponent',
];

/**
 * Http request/responses
 */
LogService.DEBUG_HTTP.enable = !environment.production;
// LogService.DEBUG_HTTP.includeRequestBody = !environment.production;
// LogService.DEBUG_HTTP.includeRequestHeaders = !environment.production;
LogService.DEBUG_HTTP.includeResponse =
  !environment.production;

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot({
      ui: uiReducer,
      router: appRouterReducer,
    }),
    EffectsModule.forRoot([UIEffects, RouterEffects]),
    StoreRouterConnectingModule.forRoot(),
  ],
})
export class CoreModule {
  // configuredProviders: *required to configure WindowService and others per platform
  static forRoot(
    configuredProviders: Array<Provider>
  ): ModuleWithProviders<CoreModule> {
    if (FeatureSwitch.globalErrorHandlerEnabled) {
      const globalErrorProvider = {
        provide: ErrorHandler,
        useClass: GlobalErrorHandler,
      };
      configuredProviders = configuredProviders || [];
      configuredProviders.unshift(globalErrorProvider);
    }
    return {
      ngModule: CoreModule,
      providers: [
        LogService,
        WindowService,
        OfflineStorageService,
        LocalStorageService,
        SignalRPlatformService,
        {
          provide: APP_BASE_HREF,
          useValue: '/',
        },
        ...configuredProviders,
      ],
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
    @Inject(PlatformLanguageToken) lang: string,
    translate: TranslateService,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    staticService: StaticService
    // private offlineHttpTracking: OfflineHttpTrackingService
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');

    // ensure default platform language is set
    translate.use(lang);

    // setup offline http tracking queue for (PUT and POST requests made offline)
    // offlineHttpTracking.init();

    // ensure static services are ready
    // staticService.init();
  }
}
