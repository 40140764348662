import { Component, QueryList, AfterViewInit, ContentChildren, Input } from '@angular/core';
import { CustomCodeComponent } from './custom-code.component';

/**
 * Usage:
 *
 *  <custom-code-tabs>
 *
 *    <custom-code [title]="'HTML code'" [code]="'htmlRaw'" [region]="''" [language]="'html'" [lineNums]="true">
 *      Loading...
 *    </custom-code>
 *
 *    <custom-code [title]="'TypeScript code'"  [code]="'tsRaw'" [region]="''" [language]="'ts'" [lineNums]="true">
 *      Loading...
 *    </custom-code>
 *
 *    <custom-code [title]="'CSS sample'" [language]="'css'" [lineNums]="false">
 *      Static content...
 *    </custom-code>
 *
 *   </custom-code-tabs>
 *
 *
 * We extended the custom-code component with a hidden property, this enabled us to manage visibility in a simple way.
 * A nice tab-control solution https://juristr.com/blog/2016/02/learning-ng2-creating-tab-component/
 *
 */
@Component({
  selector: 'custom-code-tabs',
  template: `
    <!-- title -->
    <div class="custom-code__header" [class.open]="showCode">
      <div class="custom-code__header__left">
        <i class="fas fa-code mr-3"></i>
        <span *ngIf="title">{{ title }}</span>
        <span *ngIf="canCollapse">
          <a href="javascript:void(0)" (click)="showCode = !showCode">
            <ng-container *ngIf="!showCode">Show code</ng-container>
            <ng-container *ngIf="showCode">Hide code</ng-container>
          </a>
        </span>
      </div>
      <div class="custom-code__header__right">
        <!-- content selection -->
        <span *ngFor="let content of contents; let i = index" class="custom-code__header__right_types" [class.active]="showCode && i === showIndex">
          <a href="javascript:void(0)" (click)="refresh(i)">{{ content.title || content.language }}</a>
        </span>
        <span *ngIf="!hideCopy" class="custom-code__header__copy"
          ><a (click)="doCopy()"><i class="far fa-copy"></i></a
        ></span>
      </div>
    </div>
    <ng-content *ngIf="showCode"></ng-content>
  `,
})
export class CustomCodeTabsComponent implements AfterViewInit {
  @Input() title: string;
  @Input() canCollapse = true;
  @Input() hideCopy = false;

  @ContentChildren(CustomCodeComponent) contents: QueryList<CustomCodeComponent>;

  constructor() {}

  showCode = false;
  showIndex = 0;

  ngAfterViewInit(): void {
    if (this.canCollapse === false) this.showCode = true;
    this.refresh();
  }

  refresh(index?: number) {
    if (index !== undefined && index !== null) this.showIndex = index;

    this.contents.forEach((c, i) => {
      c.hidden = i != this.showIndex;
    });
  }

  doCopy() {
    const content = this.contents.find((c) => c.hidden === false) as CustomCodeComponent;
    content.doCopy();
  }

  getContent() {
    const content = this.contents.find((c) => c.hidden === false) as CustomCodeComponent;
    content.getContent();
  }
}
