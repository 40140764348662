/** Allows us to fine tune which field with what values to query (whether allow null/empty/whitespace in the result) */
export interface IFieldSettingObject {
  [key: string]: IFieldSettingObjectItem;
}

export interface IFieldSettingObjectItem {
  field: string;
  idField?: string;
  skipNull?: boolean;
  skipEmpty?: boolean;
  skipWhiteSpace?: boolean;
}

/** FilterData dictionary where keys are the field names used to generate result */
export class FilterData {
  [key: string]: FieldFilterData;
}

/** Filter data to a given field (all unique values for that field with the given/provided State object) */
export class FieldFilterData {
  field: string;
  values: unknown[];
}

export class FilterDataPascal {
  [key: string]: FieldFilterDataPascal;
}

/** Filter data to a given field (all unique values for that field with the given/provided State object) */
export class FieldFilterDataPascal {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Field: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Values: unknown[];
}
