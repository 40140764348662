<div *ngIf="expanded && isLastGroup && isCountToShowPaging">
  <kendo-datapager
    class="p-0 pe-3"
    [class.ms-3]="addMargin"
    [type]="'input'"
    [pageSize]="pageSize"
    [skip]="skip"
    [info]="true"
    [total]="total"
    [pageSizeValues]="[5, 10, 25, 50, 100]"
    (pageChange)="pageChange.emit($event)"
  ></kendo-datapager>
</div>
