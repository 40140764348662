import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ParentFacilityDto } from '@ups/xplat/api/dto';
import { SearchFacilityService } from '@ups/xplat/api/services';
import { dynamicTypeaheadFacilityFilter } from '@ups/xplat/features';
import { DynamicTypeaheadWebBaseComponent } from '../base/dynamic-typeahead.web-base-component';

const SEARCH_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DynamicTypeaheadFacilityComponent),
  multi: true,
};

@Component({
  selector: 'ups-dynamic-typeahead-facility',
  templateUrl: 'dynamic-typeahead-facility.component.html',
  providers: [SEARCH_CONTROL_VALUE_ACCESSOR],
})
export class DynamicTypeaheadFacilityComponent extends DynamicTypeaheadWebBaseComponent {
  constructor(private searchService: SearchFacilityService) {
    super();
    this.queryFilter = dynamicTypeaheadFacilityFilter;
    this.searchQuery = this.searchService.search.bind(this.searchService);
  }

  toggleFavoriteFacility(facility: ParentFacilityDto, e: PointerEvent) {
    facility.IsUserFavorite = !facility.IsUserFavorite;
    this.searchService.addOrRemoveFavoriteFacility(facility.ParentFacilityID);
    e.stopPropagation();
  }
}
