import { createSelector, createFeatureSelector } from '@ngrx/store';
import { DrawerState } from './drawer/drawer.state';
import { LocaleState } from './locale/locale.state';
import { ProgressIndicatorState } from './progress-indicator/progress-indicator.state';

export namespace UIState {
  export interface IState {
    locale?: LocaleState.Locale;
    progressIndicator?: ProgressIndicatorState.IState;
    drawer?: DrawerState.IState;
  }

  export const initialState: IState = {
    drawer: DrawerState.initialState,
    locale: null,
    progressIndicator: ProgressIndicatorState.initialState,
  };

  export const selectTopState = createFeatureSelector<IState>('ui');
  export const selectLocale = createSelector(selectTopState, (state: IState) => state.locale);
  export const selectProgress = createSelector(selectTopState, (state: IState) => state.progressIndicator);
  export const selectDrawer = createSelector(selectTopState, (state) => state.drawer);
}
