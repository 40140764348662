/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseModel } from '../../base.model';
import { EquipmentDashboardDetailJobInfo } from './EquipmentDashboardDetailJobInfo';

export class EquipmentDashboardDetailDto extends BaseModel {
  public equipmentRequestDetailID: string;
  public jobID: string;
  public equipmentID: string;
  public equipmentTypeID: string;
  public dispatchStatusID: number;
  public assetControlManagerID: string;
  public assetControlManagerName: string;
  public name: string;
  public description: string;
  public startDate: Date;
  public endDate: Date;
  public jobInfo: EquipmentDashboardDetailJobInfo;
  public notes: string;
  public newNotes: string;

  public isBillable: string;
  public phaseId: string;
  public phaseName: string;
  public poNumber: string;
  public timeNeeded: string | Date;

  public jobRequirements: any[];

  constructor(o: any = null) {
    super(o);
  }
}
