/* eslint-disable */
import { Inject, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { getQueryParametersString, parseData } from '@ups/xplat/utils';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
  CertificationModel,
  EmployeeAroundPlantCriteriaDto,
  EmployeeAroundPlantDto,
  JobDto,
  LodgeReservationDto,
  ManPowerRequisitionModel,
  PendingAddressModel,
  PlantDropdownDto,
  ProspectConversionDetailsModel,
  ProspectFitModel,
  ProspectResumeModel,
  SafetyModel,
  TransferComplianceManPowerDetails,
  TransferManPowerDetails,
  EmployeeJobsWorkedResult,
  IODataResult,
  ManPowerReqSupervisorDashboardDto,
  OverLappingJobAndEmployeeOrProspect,
  SaveUpdateManPowerReqDetailDto,
  VerificationOfExperienceRequestDto,
  VerificationOfExperienceResponseDto,
  JobClassDesignation,
  RequestedClassDesignation,
  DropdownEmployeeDetailDto,
  FilterData,
} from '@ups/xplat/api/dto';

import { CommonDateFunctions } from '@ups/xplat/utils';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import { RecruiterWorkload } from '../models/RecruiterWorkload';
import { appendStartDateAndEndDate } from '@ups/xplat/utils';

const GET_JOB_REQUIREMENTS_BY_EMPLOYEE =
  '/api/manpowers/jobrequirements/employee/{:EmployeeId}';
const GET_JOB_REQUIREMENTS_BY_JOB =
  '/api/manpowers/jobrequirements/job/{:JobID}/';
const GET_JOB_REQUIREMENT_DETAILS_BY_JOB =
  '/api/manpowers/jobrequirementdetails/job/{:JobID}/';
const GET_BY_LAST_WORKED = '/api/manpowers/lastworked';
const GET_EMPLOYEES_AND_PROSPECTS = '/api/manpowers/employeesAndProspects';
const GET_MPR_HISTORY = '/api/manpowers/manPowerReqHistory/{:employeeID}';
const GET_CURRENT_AND_UPCOMING_JOBS =
  '/api/manpowers/currentandupcomingjobs/{:VPCompany}/{:VPId}';
const GET_AVAILABLE_EMPLOYEES =
  '/api/manpowers/availableemployees/{:sJobClass}/{:startDate}/{:endDate}/{:jobNumber}/{:iCompanyID}';
const GET_EMPLOYEE_AROUND_PLANT_SEARCH_CRITERIA =
  '/api/manpowers/{:jobId}/employeesaroundplantcriteria';
const GET_EMPLOYEE_AROUND_PLANT_SEARCH_CRITERIA2 =
  '/api/manpowers/employeesaroundplantcriteria/{:plantId}';
const GET_EMPLOYEES_AROUND_PLANT =
  '/api/manpowers/plant/{:PlantID}/{:jobId}/employeesaroundplant?';
const GET_EMPLOYEES_AROUND_PLANT_ODATA =
  '/api/manpowers/employeesaroundplant-odata';
const GET_EMPLOYEES_AROUND_PLANT_PERF_ODATA =
  '/api/manpowers/employeesaroundplantperf-odata';
const GET_ONBOARDING_DATA = '/api/manpowers/onboarding/{:ID}';
const GET_ONBOARDING_DATA_ODATA = '/api/manpowers/onboarding-odata/{:ID}';
const GET_ONBOARDING_DATA_ODATA_ALL = '/api/manpowers/onboarding-odata';
const GET_ONBOARDING_DATA_FIRST_PAGE =
  '/api/manpowers/onboarding/{:ID}/{:pageSize}';
const GET_ONBOARDING_DISPATCH_DATA =
  '/api/manpowers/onboarding/dispatchandonsite/{:ID}';
const GET_ONBOARDING_DISPATCH_DATA_ODATA =
  '/api/manpowers/onboarding-odata/dispatchandonsite/{:ID}';
const GET_ONBOARDING_COUNTS = '/api/manpowers/onboarding/counts/{:ID}';
const GET_ONBOARDING_BY_JOB_DATA = '/api/manpowers/onboarding/byjob';
const GET_ONBOARDING_ODATA_BY_JOB_DATA = '/api/manpowers/onboardingOdata/byjob';
const GET_RECRUITERS = '/api/manpowers/recruiters';
const GET_RECRUITERS_WORKLOAD = '/api/manpowers/recruiters/workloadbydate?';
const GET_JOB_REQUIREMENT_DETAILS =
  '/api/manpowers/jobrequirementdetails/{:ID}';
const SYNC_DISA_STATUS = '/api/manpowers/syncdisadata/{:ID}';
const GET_JOB_REQUIREMENT_LOGS = '/api/manpowers/logs/{:ID}';
const GET_JOB_REQUIREMENT_STATUSES = '/api/manpowers/requirementstatuses';
const PUT_SPARTAEMPLOYEE_DATA = '/api/manpowers/availableemployees/{:hrref}';
const GET_BACKGROUNDSTATUSES = '/api/manpowers/backgroundstatuses';
const GET_COMPLIANCE = '/api/manpowers/compliance';
const GET_BADGECLERK = '/api/manpowers/badgeclerk';
const GET_BADGECLERK_ODATA = '/api/manpowers/badgeclerk-odata';
const GET_BADGECLERK_ODATA_BYJOB = '/api/manpowers/badgeclerk-odata-byjob';
const PUT_EMPLOYEE_ON_MANPOWER = '/api/manpowers/requisition';
const TRANSFER_MANPOWER_DETAIL_ = '/api/manpowers/transfer?';
const GET_CRAFTS_AND_AMOUNT_BY_DISPATCH_STATUS_AND_RECRUITER =
  '/api/manpowers/recruiters/craftsAndAmount?';
const GET_REQUESTED_CLASSES_AND_AMOUNT_BY_DISPATCH_STATUS_AND_RECRUITER =
  '/api/manpowers/recruiters/requestedClassAndAmount?';
const GET_ORDERED_DISPATCH_STATUSES =
  '/api/manpowers/recruiters/getOrderedDispatchStatuses';
const GET_PROSOECTLISTS = '/api/manpowers/prospects/getProspects';
const GET_PROSPECT_DATA = '/api/manpowers/prospects/getProspectData/{:ID}';
const GET_PROSPECT_CERT = '/api/manpowers/prospects/getProspectCert/{:ID}';
const PUT_PROSPECT_CERT = '/api/manpowers/prospects/cert';
const POST_PROSPECT_CERT = '/api/manpowers/prospects/cert';
const GET_PROSPECT_RESUME = '/api/manpowers/prospects/getProspectResume/{:ID}';
const PUT_PROSPECT_RESUME = '/api/manpowers/prospects/resume';
const POST_PROSPECT_RESUME = '/api/manpowers/prospects/resume';
const GET_PROSPECT_SAFETY = '/api/manpowers/prospects/getProspectSafety/{:ID}';
const PUT_PROSPECT_SAFETY = '/api/manpowers/prospects/safety';
const POST_PROSPECT_SAFETY = '/api/manpowers/prospects/safety';
const GET_PROSPECT_FIT = '/api/manpowers/prospects/getProspectFit/{:ID}';
const PUT_PROSPECT_FIT = '/api/manpowers/prospects/fit';
const POST_PROSPECT_FIT = '/api/manpowers/prospects/fit';
const ADD_OR_UPDATE_PROSPECT = '/api/manpowers/prospects/saveProspect';
const TRANSFER_MULTI_MANPOWER_DETAIL_ = '/api/manpowers/multitransfer';
const GET_JOB_REQUIREMENT_NOTES = '/api/manpowers/jobrequirementnotes/{:ID}';
const GET_ONSITE_DATA = '/api/manpowers/onsitecompliance/{:ID}';
const GET_PREONSITE_DATA = '/api/manpowers/preonsitecompliance/{:ID}';
const GET_COMPLAINCE_PREONSITE_WORKLOAD =
  '/api/manpowers/compliance/preonsite/workloadbydate?';
const GET_COMPLAINCE_ONSITE_WORKLOAD =
  '/api/manpowers/compliance/onsite/workloadbydate?';
const TRANSFER_COMPLAINCE_DETAIL = '/api/manpowers/compliance/transfer?';
const GET_COMPLAINCE_START_DATE_AND_AMOUNT =
  '/api/manpowers/compliance/preonsite/startdatesandamount?';
const GET_TAB_TOTAL_COUNT =
  '/api/manpowers/compliance/counts/{:backgroundMemberID}';
const GET_DISPATCH_STATUSES = '/api/manpowers/recruiters/getDispatchStatuses';
const GET_DECLINATION_REASONS =
  '/api/manpowers/recruiters/getDeclinationReasons';
const GET_WORKGROUPS = '/api/manpowers/workgroups';
const CONVERT_PROSPECT_TO_EMPLOYEE =
  '/api/manpowers/convertprospecttoemployee/';
const GET_EMPLOYEEPROSPECTDROPDOWN =
  '/api/spartaemployee/getemployeeandprospectdropdown?searchString={:term}';
const GET_EMPLOYEEDROPDOWN =
  '/api/spartaemployee/employee/dropdown?searchString={:term}';
const GET_LODGING_EMPLOYEEPROSPECTDROPDOWN =
  '/api/lodging/employeeAndProspectDropdown/{:jobID}/{:checkIn}/{:checkOut}';
const PUT_REQUESTION_BY_MANPOWERREQ_EMPID =
  '/api/manpowers/requisition/{:manPowerReqDetailID}/{:employeeOrProspectID}';
const GET_EMPLOYEEORPORSPECT_DETAILS =
  '/api/spartaemployee/employeeorprospectdetails/{:manPowerReqDetailID}/{:employeeOrProspectID}?';
const GET_MANPOWER_POSITIONS_DATA_ODATA =
  '/api/manpowers/supervisordashboard-odata/{:manPowerReqDetailID}';
const GET_PROSPECTS_DATA_ODATA =
  '/api/manpowers/{:showInactive}/prospects-odata';
const GET_PROSPECT_CLASSES = '/api/manpowers/{:showInactive}/prospectClasses';
const GET_MANPOWER_POSITIONS_DATA =
  '/api/manpowers/supervisordashboard-odata/{:manPowerReqDetailID}';
const DELETE_MANPOWER_POSITIONS = '/api/manpowers/delete';
const GET_JOBS = '/api/jobs/dropdown';
const BULK_UPDATE_MANPOWERS = '/api/manpowers/bulkUpdate';
const GET_CLASSESDROPDOWN =
  '/api/manpowers/getClassesForDropdown?jobid={:jobID}&searchString={:term}';
const GET_SIMILAR_PROSPECTS = '/api/manpowers/prospects/getSimilar/{:ID}';
const GET_MATCHING_EMPLOYEES =
  '/api/manpowers/prospects/getMatchingEmployees/{:ID}';
const MERGE_PROSPECT = '/api/manpowers/prospects/mergeProspect/{:ID}';
const GET_PLANTS = '/api/manpowers/dropdown/plants?';
const FETCH_WORKCLASSES = '/odata/workClasses';
const GET_EXPORT_ALL_SUPERVISOR_DATA =
  '/api/manpowers/supervisordashboardExportAll/{:manPowerReqDetailID}';
const GET_SCORE_CARD_DROPDOWNS_DATA =
  '/api/manpowers/scorecarddropdownsdata/{:manPowerReqDetailID}';
const GET_ALL_PLANTS_LATLONG = '/api/manpowers/fetchAllPlants';
const GET_SCORE_CARD_DATA_BY_WEEKS =
  '/api/manpowers/supervisordashboardchartdatabyweeks/{:weeksCount}';
const GET_SCORE_CARD_DATA_BY_DATES: string =
  '/api/manpowers/supervisordashboardchartdatabydates';
const GET_REQUESTED_CLASSES = '/api/manpowers/requestedClasses';
const GET_DISPATCH_CLASSES =
  '/api/manpowers/jobClasses/{:manpowerReqDetailId}/{:searchString}';
const GET_COMPLIANCE_ODATA = '/api/manpowers/compliance-odata';
const GET_COMPLIANCE_COUNTS = '/api/manpowers/compliance/counts/{:ID}';
const GET_RESOURCE_COUNTS = '/api/manpowers/resourceCounts';
const GET_RECRUITER_DISPATCH_STATUS_COUNTS =
  '/api/manpowers/recruiter/dispatchStatusCounts';
const GET_SUPERVISORS = '/api/manpowers/supervisors';
const GET_FILLEDBY = '/api/manpowers/filled-by';
const GET_FOREMANS = '/api/manpowers/foremans';
const GET_LODGING_DATA_ODATA = '/api/manpowers/lodgeReservation-odata';
const GET_LODGE_RESERVATION = '/api/lodgeReservation/{:lodgeReservationID}';
const GET_LODGE_RESERVATION_BY_MAN_POWER_ID =
  '/api/manpowers/lodgeReservation/{:manPowerReqDetailID}';
const SAVE_LODGE_RESERVATION = '/api/manpowers/lodgeReservation';
const GET_LODGES = '/api/manpowers/lodges';
const GET_EMPLOYEES_TRADECLASS_AND_LINKEDCLASSES =
  '/api/manpowers/getEmployeesTradeClassIDAndLinkedClassID/{:employeeId}';
const GET_EMPLOYEE_EMPLOYEEID = '/api/fetchEmployeeID/{:hrref}';
const GET_JOB_CLASS_ID = '/api/manpowers/getJobClassId/{:jobId}';
const GET_CURRENT_JOBS_OF_EMPLOYEE =
  '/api/manpowers/currentandupcomingjobsByHrref/{:hrref}';
const SEARCH_EMPLOYEES =
  '/api/manpowers/search-candidates-for-manpowerreqdetail-odata?';
const GET_REGIONS = '/api/region';

@Injectable({
  providedIn: 'root',
})
export class ManPowerService {
  http: HttpClient;
  spartaApiHttp: HttpClient;
  spartaApiHttpCamel: HttpClient;
  datePipe: DatePipe = new DatePipe('en-US');

  constructor(httpFactory: MyHttpClientFactory) {
    this.http = httpFactory.createHttpClient(environment.urls.spartaAPI);
    this.spartaApiHttp = httpFactory.createHttpClient(
      environment.urls.spartaAPI,
      true,
      ResponseCasingEnum.PascalCase
    );
    this.spartaApiHttpCamel = httpFactory.createHttpClient(
      environment.urls.spartaAPI,
      true,
      ResponseCasingEnum.CamelCase
    );
  }

  public fetchJobRequirementStatuses() {
    var url = GET_JOB_REQUIREMENT_STATUSES;
    return this.http.get(url).toPromise();
  }

  public fetchEmployeeID(hrref: any) {
    var url = GET_EMPLOYEE_EMPLOYEEID.split('{:hrref}').join(hrref);
    return this.http
      .get(url)
      .pipe(map((res) => JSON.parse((res as HttpResponse<any>).body)))
      .toPromise();
  }

  public fetchJobClassID(jobId: any) {
    var url = GET_JOB_CLASS_ID.split('{:jobId}').join(jobId);
    return this.http
      .get(url)
      .pipe(map((res) => JSON.parse((res as HttpResponse<any>).body)));
  }

  public syncDisaStatus(manpowerReqDetailId: string): Observable<any> {
    var url = SYNC_DISA_STATUS.split('{:ID}').join(manpowerReqDetailId);
    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public fetchAllPlantsLatLong(): Observable<any> {
    var url = GET_ALL_PLANTS_LATLONG;
    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public fetchJobRequirementDetails(
    manpowerReqDetailId: string
  ): Observable<any> {
    var url =
      GET_JOB_REQUIREMENT_DETAILS.split('{:ID}').join(manpowerReqDetailId);
    // var url = 'config/jobReqDetails.json';
    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public fetchJobRequirementLogs(manpowerReqDetailId: string): Observable<any> {
    var url = GET_JOB_REQUIREMENT_LOGS.split('{:ID}').join(manpowerReqDetailId);
    //var url = 'config/jobReqDetails.json';
    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public fetchBackgroundStatuses() {
    var url = GET_BACKGROUNDSTATUSES;
    return this.http
      .get(url)
      .pipe(map((res) => JSON.parse((res as HttpResponse<any>).body)))
      .toPromise();
  }

  public fetchCompliance(): Observable<Response> {
    var url = GET_COMPLIANCE;
    return this.http.get(url).pipe(map((response) => parseData(response)));
  }

  public fetchBadgeClerk(): Observable<Response> {
    var url = GET_BADGECLERK;
    return this.http.get(url).pipe(map((response) => parseData(response)));
  }

  public fetchBadgeClerkOdata(odataString: string): Observable<any> {
    var url = GET_BADGECLERK_ODATA;

    // solution to defect #19916 in Badge Clerk Dashboard
    odataString = odataString.replace("tolower('Blank')", 'null');

    url += '?$format=json&$count=true';
    url += odataString ? '&' + odataString : '';

    return this.http.get(url).pipe(map((response) => parseData(response)));
  }

  public fetchBadgeClerkOdataByJob(
    jobs: string[],
    odataString: string
  ): Observable<any> {
    var url = GET_BADGECLERK_ODATA_BYJOB + '?jobNumbers=' + jobs;

    url += '&$format=json&$count=true';
    url += odataString ? '&' + odataString : '';

    return this.http.get(url).pipe(map((response) => parseData(response)));
  }

  public fetchRecruiters(): Observable<any> {
    var url = GET_RECRUITERS;
    return this.http.get(url);
  }

  public fetchRecruitersWorkloadByDate(
    startDate: string,
    endDate: string,
    regionId?: string
  ) {
    let url = appendStartDateAndEndDate(
      GET_RECRUITERS_WORKLOAD,
      startDate,
      endDate
    );

    if (regionId) {
      if (startDate || endDate) {
        url += '&';
      }
      url += `regionId=${regionId}`;
    }

    return this.http.get<RecruiterWorkload[]>(url);
  }

  public fetchOnboardingData(recruiterId: string): Observable<any> {
    var url = GET_ONBOARDING_DATA.split('{:ID}').join(recruiterId);

    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public fetchOnboardingDataOdata(
    recruiterId: string,
    odatastring: string
  ): Observable<any> {
    var url = GET_ONBOARDING_DATA_ODATA.split('{:ID}').join(recruiterId);

    url += '?$format=json&$count=true';
    url += odatastring ? '&' + odatastring : '';

    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public fetchOnboardingDataOdataAll(
    recruiterId: string,
    inprocessEnum: any,
    odatastring: string,
    includeEmployeesWithNoMissingRequirements = false,
    includeEmployeesFromProcessOrDispatchTab = false
  ): Observable<any> {
    let url =
      GET_ONBOARDING_DATA_ODATA_ALL +
      '?recruiterID=' +
      recruiterId +
      '&showAllDispatched=' +
      includeEmployeesWithNoMissingRequirements +
      '&selectedQuery=' +
      inprocessEnum +
      '&includeEmployeesFromProcessOrDispatchTab=' +
      includeEmployeesFromProcessOrDispatchTab +
      '&';

    url += '$format=json&$count=true';
    url += odatastring ? '&' + odatastring : '';

    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public fetchOnboardingDataFirstPage(
    recruiterId: string,
    pageSize: number
  ): Observable<any> {
    var url = GET_ONBOARDING_DATA_FIRST_PAGE.split('{:ID}')
      .join(recruiterId)
      .split('{:pageSize}')
      .join(pageSize.toString());

    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public fetchOnboardingDispatchData(recruiterId: string): Observable<any> {
    var url = GET_ONBOARDING_DISPATCH_DATA.split('{:ID}').join(recruiterId);

    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public fetchOnboardingDispatchDataOdata(
    recruiterId: string,
    odatastring: string
  ): Observable<any> {
    var url =
      GET_ONBOARDING_DISPATCH_DATA_ODATA.split('{:ID}').join(recruiterId);
    url = url + odatastring;
    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public fetchOnboardingCounts(recruiterId: string): Observable<any> {
    var url = GET_ONBOARDING_COUNTS.split('{:ID}').join(recruiterId);

    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public fetchOnboardingByJobData(jobs: string[]): Observable<any> {
    let url = GET_ONBOARDING_BY_JOB_DATA;
    return this.http.post(url, jobs).pipe(map((data) => parseData(data)));
  }

  public fetchOnboardingOdataByJobData(
    jobs: string[],
    odatastring: string
  ): Observable<any> {
    let url = GET_ONBOARDING_ODATA_BY_JOB_DATA + '?jobNumbers=' + jobs;

    url += '&$format=json&$count=true';
    url += odatastring ? '&' + odatastring : '';

    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public fetchEmployeeProspectDropdown(
    term: string,
    take: number = 0,
    allPRGroups: boolean = false,
    requestedClassName: string = null
  ): Observable<any> {
    if (term && term.length > 2) {
      var url = GET_EMPLOYEEPROSPECTDROPDOWN.split('{:term}').join(term);

      if (allPRGroups === true) url += '&allPRGroups=true';

      if (requestedClassName)
        url += '&requestedClassName=' + requestedClassName;

      if (take) url += '&take=' + take;

      return this.http.get(url).pipe(map((data) => parseData(data)));
    } else {
      return of([]);
    }
  }

  public fetchEmployeeDropdown(
    term: string,
    take: number = 0,
    vpCompanyID: number = null
  ): Observable<DropdownEmployeeDetailDto[]> {
    if (term && term.length > 2) {
      let url = GET_EMPLOYEEDROPDOWN.split('{:term}').join(term);

      if (vpCompanyID) {
        url += `&vpCompanyID=${vpCompanyID}`;
      }

      if (take) {
        url += '&take=' + take;
      }

      return this.http
        .get<DropdownEmployeeDetailDto>(url)
        .pipe(map((data) => parseData(data)));
    } else {
      return of([]);
    }
  }

  public fetchLodgingEmployeeProspectDropdown(
    jobID: string,
    checkIn: string,
    checkOut: string,
    search: string,
    take: number = 0
  ): Observable<any> {
    let url = GET_LODGING_EMPLOYEEPROSPECTDROPDOWN.split('{:jobID}')
      .join(jobID)
      .split('{:checkIn}')
      .join(checkIn)
      .split('{:checkOut}')
      .join(checkOut);

    url += '?take=' + take;

    if (search) url += '&searchString=' + search;

    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public fetchClassesDropdown(
    jobid: any,
    term: string,
    minLength: number = 3
  ): Observable<any> {
    if (term && term.length >= minLength) {
      var url = GET_CLASSESDROPDOWN.split('{:jobID}')
        .join(jobid)
        .split('{:term}')
        .join(term);

      return this.http.get(url).pipe(map((data) => parseData(data)));
    } else {
      return of([]);
    }
  }

  public getPersonByReqEmpId(
    manPowerReqDetailID: string,
    employeeOrProspectID: string,
    jobStart: Date | null,
    jobEnd: Date | null
  ): Observable<OverLappingJobAndEmployeeOrProspect> {
    let url = GET_EMPLOYEEORPORSPECT_DETAILS.split(
      '{:manPowerReqDetailID}'
    ).join(manPowerReqDetailID);
    url = url.split('{:employeeOrProspectID}').join(employeeOrProspectID);

    const queryParameters: string[] = [];
    if (jobStart) {
      queryParameters.push(
        `startDate=${this.datePipe.transform(jobStart, 'MM/dd/yyyy')}`
      );
    }

    if (jobEnd) {
      queryParameters.push(
        `endDate=${this.datePipe.transform(jobEnd, 'MM/dd/yyyy')}`
      );
    }

    url = url + queryParameters.join('&');

    return this.http.get(
      url
    ) as Observable<OverLappingJobAndEmployeeOrProspect>;
  }

  public putRequestionByManpowerReqEmpId(
    manPowerReqDetailID: string,
    employeeOrProspectID: string,
    rehireStatus: string = null
  ) {
    var url = PUT_REQUESTION_BY_MANPOWERREQ_EMPID.split(
      '{:manPowerReqDetailID}'
    ).join(manPowerReqDetailID);
    url = url.split('{:employeeOrProspectID}').join(employeeOrProspectID);
    if (rehireStatus) url = url + '/' + rehireStatus;

    return this.http.put(url, {}).toPromise();
  }

  public putSpartaEmployeeAddressInPending(
    employeeAddressPending: PendingAddressModel,
    hrref: string
  ) {
    var url = PUT_SPARTAEMPLOYEE_DATA.split('{:hrref}').join(hrref);

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    return this.http
      .put(url, employeeAddressPending, options)
      .toPromise()
      .then(parseData);
  }

  public getJobRequirementsByEmployee(employeeId: string) {
    var url =
      GET_JOB_REQUIREMENTS_BY_EMPLOYEE.split('{:EmployeeId}').join(employeeId);
    return this.http.get(url).toPromise().then(parseData);
  }

  public getJobRequirementsByJob(jobid: string) {
    var url = GET_JOB_REQUIREMENTS_BY_JOB.split('{:JobID}').join(jobid);
    return this.http.get(url).toPromise().then(parseData);
  }

  public getJobRequirementDetailsByJob(jobid: string) {
    var url = GET_JOB_REQUIREMENT_DETAILS_BY_JOB.split('{:JobID}').join(jobid);
    return this.http.get(url).toPromise().then(parseData);
  }

  public getByLastWorked() {
    return this.http.get(GET_BY_LAST_WORKED).toPromise().then(parseData);
  }

  public getEmployeesAndProspects() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.http
      .get(GET_EMPLOYEES_AND_PROSPECTS)
      .toPromise()
      .then(parseData);
  }

  public getWorkedByEmp(
    HRRef: string | number
  ): Observable<EmployeeJobsWorkedResult[]> {
    const url = `/api/manpowers/jobsworked/${HRRef}`;
    return this.spartaApiHttp.get(url) as Observable<
      EmployeeJobsWorkedResult[]
    >;
  }

  public sendEmailForVerificationOfExperience(
    hrRef: number,
    reason: string,
    responseDueDate: Date
  ): Observable<VerificationOfExperienceResponseDto[]> {
    const url = `/api/manpowers/verificationofexperience`;
    return this.spartaApiHttpCamel.post(url, {
      responseDueDate: responseDueDate,
      reason: reason,
      hrRef: hrRef,
    } as VerificationOfExperienceRequestDto) as Observable<
      VerificationOfExperienceResponseDto[]
    >;
  }

  public GetManPowerReqHistory(employeeID: string) {
    let url = GET_MPR_HISTORY.split('{:employeeID}').join(employeeID);
    return this.http.get(url).toPromise().then(parseData);
  }

  public getCurrentAndUpcomingJobs(vpid: string, vpCompany: string) {
    var url = GET_CURRENT_AND_UPCOMING_JOBS.split('{:VPId}').join(vpid);
    url = url.split('{:VPCompany}').join(vpCompany);

    return this.http.get(url).toPromise().then(parseData);
  }

  /**
   * @deprecated This method has been moved to @ups/xplat/features/employee-profile/services/employee-jobs-service
   */
  public getCurrentAndUpcomingJobsByHRREf(hrref: string) {
    var url = GET_CURRENT_JOBS_OF_EMPLOYEE.split('{:hrref}').join(hrref);
    return this.http.get(url).toPromise().then(parseData);
  }

  public saveJobRequirementDetails(manpowerReqDetailId: string, o: any) {
    var url =
      GET_JOB_REQUIREMENT_DETAILS.split('{:ID}').join(manpowerReqDetailId);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    return this.http.put(url, o, options).toPromise().then(parseData);
  }

  public getAvailableEmployees(
    jobClass: string,
    startDate: string,
    endDate: string,
    jobNumber: string,
    iCompanyID: string = '0'
  ) {
    var url = GET_AVAILABLE_EMPLOYEES.split('{:sJobClass}').join(jobClass);
    url = url.split('{:startDate}').join(startDate);
    url = url.split('{:endDate}').join(endDate);
    url = url.split('{:jobNumber}').join(jobNumber);
    url = url.split('{:iCompanyID}').join(iCompanyID);
    return this.http.get(url).toPromise().then(parseData);
  }

  public getEmployeeAroundPlantSearchCriteria(
    jobId: string,
    plantId: string
  ): Observable<EmployeeAroundPlantCriteriaDto[]> {
    var url = jobId
      ? GET_EMPLOYEE_AROUND_PLANT_SEARCH_CRITERIA.split('{:jobId}').join(jobId)
      : GET_EMPLOYEE_AROUND_PLANT_SEARCH_CRITERIA2.split('{:plantId}').join(
          plantId
        );
    return this.spartaApiHttpCamel
      .get(url)
      .pipe(map((response) => parseData(response)));
  }

  public getEmployeesAroundPlant(
    plantID: number,
    jobId: string,
    milesRange: number,
    vpCompanyIDs: number[],
    employeeDesignationIDs: number[],
    jobClassIDs: string[],
    classNames: string[],
    startDate: Date,
    endDate: Date,
    workedSinceDate: Date
  ): Observable<EmployeeAroundPlantDto[]> {
    var url = GET_EMPLOYEES_AROUND_PLANT.split('{:PlantID}')
      .join(plantID.toString())
      .split('{:jobId}')
      .join(jobId ? jobId.toString() : '');
    url += getQueryParametersString(
      {
        milesRange: milesRange,
        vpCompanyIDsCommaDelimited:
          vpCompanyIDs == null ? null : vpCompanyIDs.join(','),
        employeeDesignationIDsCommaDelimited:
          employeeDesignationIDs == null
            ? null
            : employeeDesignationIDs.join(','),
        jobClassIDsCommaDelimited:
          jobClassIDs == null ? null : jobClassIDs.join(','),
        classNamesCommaDelimited:
          classNames == null ? null : encodeURIComponent(classNames.join(',')),
        startDate: CommonDateFunctions.ToDateString(startDate),
        endDate: CommonDateFunctions.ToDateString(endDate),
        workedSinceDate: CommonDateFunctions.ToDateString(workedSinceDate),
      },
      true
    );

    return this.spartaApiHttpCamel
      .get(url)
      .pipe(map((response) => parseData(response)));
  }

  public transferManPowerDetail(
    transferManPowerDetails: TransferManPowerDetails,
    startDate: string,
    endDate: string
  ) {
    let url = appendStartDateAndEndDate(
      TRANSFER_MANPOWER_DETAIL_,
      startDate,
      endDate
    );
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(transferManPowerDetails);
    return this.http.put(url, body, options).toPromise();
  }

  public getEmployeesTradeClassAndLinkedClasses(employeeId: any) {
    let url =
      GET_EMPLOYEES_TRADECLASS_AND_LINKEDCLASSES.split('{:employeeId}').join(
        employeeId
      );
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.http.get(url, options).toPromise().then(parseData);
  }

  public getOrderedDispatchStatuses() {
    let url = GET_ORDERED_DISPATCH_STATUSES;
    return this.http.get(url).toPromise();
  }

  public getCraftsAndAmountByDispatchStatusAndRecruiter(
    transferManPowerDetails: TransferManPowerDetails,
    startDate: string,
    endDate: string,
    regionId?: string
  ): Promise<JobClassDesignation[]> {
    let url = appendStartDateAndEndDate(
      GET_CRAFTS_AND_AMOUNT_BY_DISPATCH_STATUS_AND_RECRUITER,
      startDate,
      endDate
    );

    if (regionId) {
      if (startDate || endDate) {
        url += '&';
      }
      url += `regionId=${regionId}`;
    }

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(transferManPowerDetails);
    return this.http.put<JobClassDesignation[]>(url, body, options).toPromise();
  }

  public getRequestedClassesAndAmountByDispatchStatusAndRecruiter(
    transferManPowerDetails: TransferManPowerDetails,
    startDate: string,
    endDate: string,
    regionId?: string
  ): Promise<RequestedClassDesignation[]> {
    let url = appendStartDateAndEndDate(
      GET_REQUESTED_CLASSES_AND_AMOUNT_BY_DISPATCH_STATUS_AND_RECRUITER,
      startDate,
      endDate
    );

    if (regionId) {
      if (startDate || endDate) {
        url += '&';
      }
      url += `regionId=${regionId}`;
    }

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(transferManPowerDetails);
    return this.http
      .put<RequestedClassDesignation[]>(url, body, options)
      .toPromise();
  }

  public requisitionEmployeeOnManPower(
    o: ManPowerRequisitionModel = null
  ): Observable<any> {
    var url = PUT_EMPLOYEE_ON_MANPOWER;

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.http
      .put(url, o, options)
      .pipe(map((response) => parseData(response)));
  }

  public fetchProspectLists() {
    var url = GET_PROSOECTLISTS;
    return this.http.get(url).toPromise().then(parseData);
  }

  public fetchProspectData(id: string) {
    var url = GET_PROSPECT_DATA.split('{:ID}').join(id);
    return this.http.get(url).toPromise().then(parseData);
  }

  public getProspectCert(id: string) {
    const url = GET_PROSPECT_CERT.split('{:ID}').join(id);

    return this.http.get(url).toPromise().then(parseData);
  }

  public createProspectCert(item: CertificationModel) {
    return this.http.put(PUT_PROSPECT_CERT, item).toPromise().then(parseData);
  }

  public updateProspectCert(item: CertificationModel) {
    return this.http.post(POST_PROSPECT_CERT, item).toPromise().then(parseData);
  }

  public getProspectResumes(id: string) {
    const url = GET_PROSPECT_RESUME.split('{:ID}').join(id);

    return this.http.get(url).toPromise().then(parseData);
  }

  public createProspectResume(item: ProspectResumeModel) {
    return this.http
      .post(POST_PROSPECT_RESUME, item)
      .toPromise()
      .then(parseData);
  }

  public updateProspectResume(item: ProspectResumeModel) {
    return this.http.put(PUT_PROSPECT_RESUME, item).toPromise().then(parseData);
  }

  public getProspectSafety(id: string) {
    const url = GET_PROSPECT_SAFETY.split('{:ID}').join(id);

    return this.http.get(url).toPromise().then(parseData);
  }

  public createProspectSafety(item: SafetyModel) {
    return this.http.put(PUT_PROSPECT_SAFETY, item).toPromise().then(parseData);
  }

  public updateProspectSafety(item: SafetyModel) {
    return this.http
      .post(POST_PROSPECT_SAFETY, item)
      .toPromise()
      .then(parseData);
  }

  public getProspectFit(id: string) {
    const url = GET_PROSPECT_FIT.split('{:ID}').join(id);

    return this.http.get(url).toPromise().then(parseData);
  }

  public createProspectFit(item: ProspectFitModel) {
    return this.http.put(PUT_PROSPECT_FIT, item).toPromise().then(parseData);
  }

  public updateProspectFit(item: ProspectFitModel) {
    return this.http.post(POST_PROSPECT_FIT, item).toPromise().then(parseData);
  }

  public saveProspect(prospectModel: any) {
    let url = ADD_OR_UPDATE_PROSPECT;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(prospectModel);
    return this.http.put(url, body, options).toPromise().then(parseData);
  }

  public fetchDispatchStatuses() {
    var url = GET_DISPATCH_STATUSES;
    return this.http.get(url).pipe(map(parseData)).toPromise();
  }

  public fetchDeclinationReasons() {
    var url = GET_DECLINATION_REASONS;
    return this.http.get(url).pipe(map(parseData)).toPromise();
  }

  public fetchWorkgroups() {
    var url = GET_WORKGROUPS;
    return this.http.get(url).pipe(map(parseData)).toPromise();
  }

  public transferMultiManPowerDetail(
    transferManPowerDetails: TransferManPowerDetails[]
  ) {
    let url = TRANSFER_MULTI_MANPOWER_DETAIL_;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(transferManPowerDetails);
    return this.http.put(url, body, options).toPromise();
  }

  public fetchJobRequirementNotes(
    manpowerReqDetailId: string
  ): Observable<any> {
    var url =
      GET_JOB_REQUIREMENT_NOTES.split('{:ID}').join(manpowerReqDetailId);
    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public fetchOnSiteData(memberId: string): Observable<any> {
    var url = GET_ONSITE_DATA.split('{:ID}').join(memberId);
    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public fetchPreOnSiteData(memberId: string): Observable<any> {
    var url = GET_PREONSITE_DATA.split('{:ID}').join(memberId);
    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public fetchComplaincePreonsiteWorkloadByDate(
    startDate: string,
    endDate: string
  ) {
    console.debug('fetchComplaincePreonsiteWorkloadByDate', startDate, endDate);
    let url = appendStartDateAndEndDate(
      GET_COMPLAINCE_PREONSITE_WORKLOAD,
      startDate,
      endDate
    );
    // return this.http.get(url).pipe(map((res) => JSON.parse((res as HttpResponse<any>).body)));
    return this.http.get(url);
  }

  public fetchComplainceOnsiteWorkloadByDate(
    startDate: string,
    endDate: string
  ) {
    let url = appendStartDateAndEndDate(
      GET_COMPLAINCE_ONSITE_WORKLOAD,
      startDate,
      endDate
    );
    // return this.http.get(url).pipe(map((res) => JSON.parse((res as HttpResponse<any>).body)));
    return this.http.get(url);
  }

  public transferComplianceManPowerDetails(
    transferComplianceManPowerDetails: TransferComplianceManPowerDetails,
    startDate: string,
    endDate: string
  ) {
    let url = appendStartDateAndEndDate(
      TRANSFER_COMPLAINCE_DETAIL,
      startDate,
      endDate
    );
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(transferComplianceManPowerDetails);
    return this.http.put(url, body, options).toPromise();
  }

  public bulkUpdateManPowerDetails(dto: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(dto);
    return this.http
      .post(BULK_UPDATE_MANPOWERS, body, options)
      .toPromise()
      .then(parseData);
  }

  public getComplainceStartDatesAndAmount(
    transferComplianceManPowerDetails: TransferComplianceManPowerDetails,
    startDate: string,
    endDate: string
  ) {
    var url = appendStartDateAndEndDate(
      GET_COMPLAINCE_START_DATE_AND_AMOUNT,
      startDate,
      endDate
    );
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(transferComplianceManPowerDetails);
    return this.http.put(url, body, options).toPromise().then(parseData);
  }

  public fetchTabTotalCount(memberId) {
    var url = GET_TAB_TOTAL_COUNT.split('{:backgroundMemberID}').join(memberId);
    return this.http.get(url).toPromise().then(parseData);
  }

  public handleSuccess(data: any) {
    // console.log(data);
  }

  public convertProspectToEmployee(
    prospectConversionDetails: ProspectConversionDetailsModel
  ) {
    var url = CONVERT_PROSPECT_TO_EMPLOYEE;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.http
      .put(url, prospectConversionDetails, options)
      .toPromise()
      .then(parseData);
  }

  public handleError(error: any) {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  public fetchManpowerPositionsData(
    manpowerReqDetailId: string
  ): Observable<IODataResult<ManPowerReqSupervisorDashboardDto[]>> {
    var url = GET_MANPOWER_POSITIONS_DATA.split('{:manPowerReqDetailID}').join(
      manpowerReqDetailId
    );
    return this.http.get(url) as Observable<
      IODataResult<ManPowerReqSupervisorDashboardDto[]>
    >;
  }

  public deleteManpowerPositions(manpowers: any) {
    let url = DELETE_MANPOWER_POSITIONS;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(manpowers);
    return this.http.put(url, body, options).toPromise().then(parseData);
  }

  public queryProspectsData(
    activeOnly: boolean,
    odataString: string
  ): Observable<any> {
    var url = GET_PROSPECTS_DATA_ODATA.split('{:showInactive}').join(
      activeOnly.toString()
    );

    url += '?$format=json&$count=true';
    url += odataString ? '&' + odataString : '';

    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public queryProspectClasses(activeOnly: boolean): Observable<any> {
    let url = GET_PROSPECT_CLASSES.split('{:showInactive}').join(
      activeOnly.toString()
    );

    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public queryManpowerPositionsData(
    manpowerReqDetailId: string,
    odataString: string
  ): Observable<IODataResult<ManPowerReqSupervisorDashboardDto>> {
    var url = GET_MANPOWER_POSITIONS_DATA_ODATA.split(
      '{:manPowerReqDetailID}'
    ).join(manpowerReqDetailId);

    url += '?$format=json&$count=true';
    url += odataString ? '&' + odataString : '';

    return this.http.get(url) as Observable<
      IODataResult<ManPowerReqSupervisorDashboardDto>
    >;
  }

  public exportAllData(manpowerReqDetailId: string): Observable<any> {
    var url = GET_EXPORT_ALL_SUPERVISOR_DATA.split(
      '{:manPowerReqDetailID}'
    ).join(manpowerReqDetailId);

    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public queryManpowerPositionsStringFilterData(
    manpowerReqDetailId: string,
    fieldName: string,
    fieldQuery: string,
    top: number = 20,
    mustStartWith: boolean = false
  ): Observable<IODataResult<ManPowerReqSupervisorDashboardDto[]>> {
    // sample: https://qa-sparta-api.azurewebsites.net/api/manpowers/supervisordashboard-odata/76be5ccc-4511-4f88-9fbf-b87ae5f32281?$count=true&$top=100&$apply=groupby((Supervisor),aggregate(ManPowerReqDetailID with countdistinct as groupTotal))&$filter=startswith(Supervisor, 'a')
    let url = GET_MANPOWER_POSITIONS_DATA_ODATA.split(
      '{:manPowerReqDetailID}'
    ).join(manpowerReqDetailId);

    url += `?$count=true&$apply=groupby((${fieldName}),aggregate(ManPowerReqDetailID with countdistinct as total))`;
    if (fieldQuery) {
      if (mustStartWith)
        url += `&$filter=startswith(tolower(${fieldName}), tolower('${fieldQuery}'))`;
      else
        url += `&$filter=contains(tolower(${fieldName}), tolower('${fieldQuery}'))`;
    }

    return this.http.get(url) as Observable<
      IODataResult<ManPowerReqSupervisorDashboardDto[]>
    >;
  }

  public searchJobs(search: string, active: boolean = true): Promise<JobDto[]> {
    let url =
      GET_JOBS + '/?searchString=' + encodeURI(search) + '&status=' + active;
    return this.http.get(url).toPromise().then(parseData);
  }

  public searchRequestedClasses(
    vpCompanyID: number,
    search: string
  ): Promise<any[]> {
    let url =
      GET_REQUESTED_CLASSES +
      '/?searchString=' +
      encodeURI(search) +
      '&vpCompanyID=' +
      vpCompanyID;
    return this.http.get(url).toPromise().then(parseData);
  }

  public searchDispatchClasses(
    manPowerReqDetailID: string,
    search: string
  ): Promise<any[]> {
    let url = GET_DISPATCH_CLASSES.split('{:manpowerReqDetailId}').join(
      manPowerReqDetailID
    );
    url = url.split('{:searchString}').join(search);
    return this.http.get(url).toPromise().then(parseData);
  }

  public saveManpowerReqDetails(
    items: SaveUpdateManPowerReqDetailDto[]
  ): Promise<any> {
    const url = '/api/manpowers/pm-dashboard';
    return this.http.post(url, items).toPromise();
  }

  public deleteManpowerReqDetails(itemGuids: any[]) {
    let url = '/api/manpowers/pm-dashboard';
    let options = { body: itemGuids };
    return this.http.request('delete', url, options).toPromise();
  }

  public fetchWorkClasses(odataString: string) {
    let url = FETCH_WORKCLASSES;

    url += '?$format=json&$count=true';
    url += odataString ? '&' + odataString : '';

    return this.http.get(url).toPromise().then(parseData);
  }

  public fetchSimilarProspects(id: string) {
    var url = GET_SIMILAR_PROSPECTS.split('{:ID}').join(id);
    return this.http.get(url).toPromise().then(parseData);
  }

  public fetchMatchingEmployees(id: string) {
    var url = GET_MATCHING_EMPLOYEES.split('{:ID}').join(id);
    return this.http.get(url).toPromise().then(parseData);
  }

  public mergeProspect(prospectModel: any, prospectIdToMerge: string) {
    let url = MERGE_PROSPECT.split('{:ID}').join(prospectIdToMerge);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(prospectModel);
    return this.http.put(url, body, options).toPromise().then(parseData);
  }

  public fetchPlants(
    searchString: string,
    take: number = 0,
    vpCompanyID: number = null,
    minLength: number = 2
  ): Promise<PlantDropdownDto[]> {
    if (vpCompanyID || (searchString && searchString.length >= minLength)) {
      let url = GET_PLANTS;
      let queryParams = '';

      if (searchString) queryParams += 'searchString=' + searchString;

      if (vpCompanyID)
        queryParams +=
          (queryParams.length > 0 ? '&' : '') + 'vpCompanyID=' + vpCompanyID;

      if (take) queryParams += '&take=' + take;

      return this.spartaApiHttpCamel
        .get(url + queryParams)
        .toPromise()
        .then(parseData);
    } else {
      return of([]).toPromise();
    }
  }

  // TODO: eventually get rid of this completely if possible
  public fetchPlantsPascalCase(
    searchString: string,
    take: number = 0,
    vpCompanyID: number = null,
    minLength: number = 2
  ): Promise<PlantDropdownDto[]> {
    if (vpCompanyID || (searchString && searchString.length >= minLength)) {
      let url = GET_PLANTS;
      let queryParams = '';

      if (searchString) queryParams += 'searchString=' + searchString;

      if (vpCompanyID)
        queryParams +=
          (queryParams.length > 0 ? '&' : '') + 'vpCompanyID=' + vpCompanyID;

      if (take) queryParams += '&take=' + take;

      return this.http
        .get(url + queryParams)
        .toPromise()
        .then(parseData);
    } else {
      return of([]).toPromise();
    }
  }

  public fetchScoreCardDropdownsData(
    manpowerReqDetailId: string
  ): Observable<any> {
    var url = GET_SCORE_CARD_DROPDOWNS_DATA.split(
      '{:manPowerReqDetailID}'
    ).join(manpowerReqDetailId);

    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public queryScoreCardChartDataByWeeks(
    weeksCount: number,
    queryString: string
  ): Observable<any> {
    var url = GET_SCORE_CARD_DATA_BY_WEEKS.split('{:weeksCount}').join(
      weeksCount.toString()
    );

    url += queryString;

    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public queryScoreCardChartDataByDates(
    startDate: Date,
    endDate: Date,
    queryString: string
  ): Observable<any> {
    var url = GET_SCORE_CARD_DATA_BY_DATES;

    if (queryString) {
      url += queryString + '&';
    } else {
      url += '?';
    }

    if (startDate) {
      url += 'startDate=' + startDate.toISOString();
    }
    if (endDate) {
      url += '&endDate=' + endDate.toISOString();
    }

    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public getEmployeesAroundPlantOdata(odataQuery: string): Observable<any[]> {
    var url = GET_EMPLOYEES_AROUND_PLANT_ODATA;
    url += '?$format=json&$count=true&';
    url += odataQuery;

    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public getEmployeesAroundPlantPerfOdata(
    queryString: string,
    odataString: string
  ): Observable<any> {
    var url = GET_EMPLOYEES_AROUND_PLANT_PERF_ODATA;
    url += queryString;
    url += '$format=json&$count=true&';
    url += odataString;

    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public fetchComplianceOdata(
    backgroundMemberID: string,
    odatastring: string,
    isPreOnSite: boolean = false
  ): Observable<any> {
    var url =
      GET_COMPLIANCE_ODATA +
      '?backgroundMemberID=' +
      backgroundMemberID +
      '&isPreOnSite=' +
      isPreOnSite +
      '&';

    url += '$format=json&$count=true';
    url += odatastring ? '&' + odatastring : '';

    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public fetchComplianceCounts(backgroundMemberID: string): Observable<any> {
    var url = GET_COMPLIANCE_COUNTS.split('{:ID}').join(backgroundMemberID);

    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public getSupervisors(jobIds: string[]) {
    return this.http
      .post(GET_SUPERVISORS, jobIds ?? [])
      .toPromise()
      .then(parseData);
  }

  public getFilledBy() {
    return this.http.get(GET_FILLEDBY).toPromise().then(parseData);
  }

  public getForemans(jobIds: string[]) {
    return this.http
      .post(GET_FOREMANS, jobIds ?? [])
      .toPromise()
      .then(parseData);
  }

  public getResourceCounts(
    dispatchStatusIDs: number[],
    dateFrom: Date,
    dateTo: Date
  ) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let dto = {
      DispatchStatusIDs: dispatchStatusIDs,
      ResourceType: 'recruiter',
      DateFrom: dateFrom,
      DateTo: dateTo,
    };
    let body = JSON.stringify(dto);
    return this.http
      .put(GET_RESOURCE_COUNTS, body, options)
      .toPromise()
      .then(parseData);
  }

  public getRecruiterDispatchStatusCounts(dateFrom: Date, dateTo: Date) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let dto = {
      DateFrom: dateFrom,
      DateTo: dateTo,
    };
    let body = JSON.stringify(dto);
    return this.http
      .put(GET_RECRUITER_DISPATCH_STATUS_COUNTS, body, options)
      .toPromise()
      .then(parseData);
  }

  public queryLodgingData(odataString: string): Observable<FilterData> {
    var url = GET_LODGING_DATA_ODATA;

    url += '?$format=json&$count=true';
    url += odataString ? '&' + odataString : '';

    return this.http.get(url).pipe(map((data) => parseData<FilterData>(data)));
  }

  public getLodgeReservation(
    lodgeReservationID: string
  ): Observable<LodgeReservationDto> {
    const url = GET_LODGE_RESERVATION.split('{:lodgeReservationID}').join(
      lodgeReservationID
    );
    return this.http
      .get(url)
      .pipe(map((data) => parseData(data) as LodgeReservationDto));
  }

  public getLodgeReservationByManPowerID(manPowerReqDetailID: string) {
    const url = GET_LODGE_RESERVATION_BY_MAN_POWER_ID.split(
      '{:manPowerReqDetailID}'
    ).join(manPowerReqDetailID);
    return this.http.get(url).toPromise().then(parseData);
  }

  public saveLodgeReservation(
    model: LodgeReservationDto
  ): Promise<LodgeReservationDto> {
    const url = SAVE_LODGE_RESERVATION;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.http.post(url, model, options).toPromise().then(parseData);
  }

  public saveLodgeReservations(model: LodgeReservationDto[]) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return this.http.post('/api/manpowers/multiple/lodgeReservation', model, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  public getLodges() {
    return this.http.get(GET_LODGES).toPromise().then(parseData);
  }

  public getRegions() {
    return this.http.get(GET_REGIONS).toPromise().then(parseData);
  }

  public searchEmployees(searchFields) {
    let url = SEARCH_EMPLOYEES + '$format=json&$top=20&$count=true';

    if (searchFields.requestedClass) {
      url += '&requestedClassName=' + searchFields.requestedClass;
    }

    if (searchFields.requestedExpertises.length) {
      for (const expertiseId of searchFields.requestedExpertises) {
        url += '&expertiseId=' + expertiseId;
      }
    }

    if (searchFields.jobStartDate) {
      url +=
        '&jobStartDate=' +
        searchFields.jobStartDate.toLocaleDateString('en-US');
    }

    if (searchFields.jobEndDate) {
      url +=
        '&jobEndDate=' + searchFields.jobEndDate.toLocaleDateString('en-US');
    }

    if (searchFields.companies.length) {
      for (const company of searchFields.companies) {
        url += '&employeeVpCompanyId=' + company;
      }
    }

    return this.http.get(url).toPromise().then(parseData);
  }

  public bulkEditBadgeRequest(manpowerReqDetailIds: string[]) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    return this.http.put(
      '/api/manpowers/bulkEditBadgeRequest',
      manpowerReqDetailIds,
      options
    );
  }
}
