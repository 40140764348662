import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '@auth0/auth0-angular';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';

//
// NOTE:
// having verb in URI is not desired, this is an old style api, based on URI standards we should have:
//
// GET    /api/documents                list documents
// GET    /api/documents/{id}           get document bytes
// GET    /api/documents/{id}           for geting metadata I'd possibly use a HTTP HEADER
// DELETE /api/documents/{id}           delete given document
// POST   /api/documents/{id}           create document - note ID refers to a file location (path) while bytes[] bust be supplied as well
//                                      for posting we use FORM-DATA with meta and file key's
//                                      on the server side we got: [Route("/api/documents/{*location}")] public DocumentDetailDto UploadCreate(string location, [FromForm(Name = "meta")] string metadataJson, [FromForm(Name = "file")] IFormFile file)
// PUT    /api/documents/{id}           update document - similar approach as fot the create, with similar method interface on the back end
//
// NOTE:
// we can omit the POST in case the file name is known in advance and then PUT endpoint is enough.
//

const GET_MAC_TOKEN = () => `api/documents/download-mac`;
const GET_DOCUMENTS = (location: string) => `api/documents/list/${location}`;
const DELETE_DOCUMENT = (location: string, filename: string) =>
  `api/documents/delete/${location}/${filename}`;
const POST_DOCUMENT_CREATE = (location: string) =>
  `api/documents/upload/${location}`;
const PUT_DOCUMENT_UPDATE = (location: string, filename: string) =>
  `api/documents/upload/${location}/${filename}`;
const GET_DOCUMENT_DOWNLOAD = (
  urlBase: string,
  location: string,
  fileName: string,
  macToken: string,
  inline: boolean = false
) =>
  `${urlBase}/api/documents/download-file-mac/${location}/${fileName}/?mac=${macToken}&inline=${inline}`;

@Injectable({ providedIn: 'root' })
export class AzureApiDocumentService {
  protected location = 'kitchen-sink';

  protected http: HttpClient;
  protected urlBase: string;

  constructor(
    protected auth: AuthService,
    protected clientFactory: MyHttpClientFactory
  ) {
    this.urlBase = environment.urls.azureAPI;
    this.http = clientFactory.createHttpClient(
      this.urlBase,
      true,
      ResponseCasingEnum.CamelCase
    );
  }

  public getDownloadToken(): Observable<string> {
    const url = GET_MAC_TOKEN();
    return this.http.get(url, { responseType: 'text' });
  }

  public createDownloadUrl(
    location: string,
    fileName: string,
    macToken: string,
    inline: boolean = false
  ): string {
    return GET_DOCUMENT_DOWNLOAD(
      this.urlBase,
      location,
      fileName,
      macToken,
      inline
    );
  }

  public getDocuments(location: string) {
    const url = GET_DOCUMENTS(location);
    return this.http.get(url).pipe(map((data) => data));
  }

  public deleteDocument(location: string, filename: string) {
    const url = DELETE_DOCUMENT(location, filename);
    return this.http.delete(url);
  }

  public createDocument(
    location: string,
    meta: DocumentMetadataDto,
    file: File
  ): Observable<DocumentDetailDto> {
    // NOTE:
    // To supply both metadata and file, we need to use form-data;
    // We could also use a dto with byte[] and read the file content via: this.readFile(file)
    const formData: FormData = new FormData();

    formData.append('meta', JSON.stringify(meta));
    formData.append('file', file, file.name);

    const url = POST_DOCUMENT_CREATE(location);

    return this.http
      .post(url, formData)
      .pipe(map((data) => data as DocumentDetailDto));
  }

  public updateDocument(
    location: string,
    filename: string,
    meta: DocumentMetadataDto,
    file: File
  ): Observable<DocumentDetailDto> {
    // See notes from createDocument(...)
    const formData: FormData = new FormData();

    // NOTE: we append file only if content changes, otherwise we update just META
    formData.append('meta', JSON.stringify(meta));
    if (file) formData.append('file', file, file.name);

    const url = PUT_DOCUMENT_UPDATE(location, filename);

    return this.http
      .put(url, formData)
      .pipe(map((data) => data as DocumentDetailDto));
  }

  protected readFile(file) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = () => {
        const arrayBuffer = reader.result;
        const uint8Array = new Uint8Array(arrayBuffer as ArrayBuffer);
        const bytes = Array.from(uint8Array); // or alt.: let bytes = [].slice.call(uint8Array)
        resolve(bytes);
      };
      reader.onerror = () => reject(this);
      reader.readAsArrayBuffer(file);
    });
  }
}

export interface DocumentDetailDto {
  documentStorageMetadataId: number;
  location: string;
  fileName: string;
  fileSize: number;
  uploadedFileName: string;
  contentType: string;
  description: string;
  version: string;
  createdOn: Date;
  createdBy: string;
  updatedOn: Date;
  updatedBy: string;
}

export interface DocumentMetadataDto {
  description: string;
  version: string;
}
