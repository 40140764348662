import { Injectable } from '@angular/core';
import { ApplicationModel, HRResourceDto } from '@ups/xplat/api/dto';
import { HRResourceService } from '@ups/xplat/api/services';

@Injectable({ providedIn: 'root' })
export class EmploymentVerificationTypeResolver {
  isLoading = false;

  constructor(
    private hrResourceService: HRResourceService,
    private applicationModel: ApplicationModel
  ) {}

  public load(): Promise<HRResourceDto[]> {
    if (
      this.applicationModel.employmentVerificationTypes.length === 0 &&
      !this.isLoading
    ) {
      this.isLoading = true;
      return this.hrResourceService
        .fetchEmploymentVerificationTypes()
        .then((data: HRResourceDto[]) => {
          this.applicationModel.employmentVerificationTypes = data;
          this.isLoading = false;
        });
    } else {
      return Promise.resolve(this.applicationModel.employmentVerificationTypes);
    }
  }
}
