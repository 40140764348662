export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return [s4(), s4(), '-', s4(), '-', s4(), '-', s4(), s4(), s4()].join('');
}

export function newGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    /* eslint-disable no-bitwise */
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function generateRandomKey() {
  const r = Math.random();
  return r.toString();
}

export function decodeJwt(
  token: string,
  platformAtob?: (input: string) => string /* platform specific atob impl */
) {
  if (token) {
    const tokenParts = token.split('.');
    const base64Url = tokenParts.length > 1 ? tokenParts[1] : null;
    if (base64Url) {
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        (platformAtob || atob)(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      return JSON.parse(jsonPayload);
    } else {
      return {};
    }
  }
  return {};
}

export function capitalize(str: string): string {
  // being defensize here since some edge cases have arised around it's usage
  if (str) {
    const firstChar = str.charAt(0);
    if (firstChar) {
      return firstChar.toUpperCase() + str.slice(1);
    } else {
      return str;
    }
  }

  return '';
}

/**
 * Converts a data value with underscores to a Labelized human readable title
 * @param value data value like 'at_risk'
 * @returns labelized form of the value like 'At Risk'
 */
export function labelizeDataValue(value: string) {
  return value
    ?.split('_')
    .map((i) => capitalize(i))
    .join(' ');
}

export function tagCleanser(tag: string) {
  return tag && tag.replace(/\s+/g, '-').toLowerCase();
}

export function convertTagToName(tag: string) {
  if (tag) {
    const words = tag.split('-');

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(' ');
  }
  return '';
}

/**
 * Fix for dictionary key values if they contain nested property expressions (we need to camelize on client side)
 * see: https://github.com/JamesNK/Newtonsoft.Json/issues/2187
 */
export class Camelize {
  static toCamelCase(value: string): string {
    // nullish or empty or starting camel...
    if (
      value === null ||
      value === undefined ||
      value.length === 0 ||
      !Camelize.isUpperCase(value[0])
    ) {
      return value;
    }

    // convert
    const chars = Object.assign([], value);
    for (let i = 0; i < chars.length; i++) {
      // if the 2nd char is not upper, we finished conversion...
      if (i === 1 && !Camelize.isUpperCase(chars[i])) {
        break;
      }

      const hasNext = i + 1 < chars.length;

      if (i > 0 && hasNext && !Camelize.isUpperCase(chars[i + 1])) {
        // if the next character is a space, which is not considered uppercase
        // (otherwise we wouldn't be here...)
        // we want to ensure that the following:
        // 'FOO bar' is rewritten as 'foo bar', and not as 'foO bar'
        // The code was written in such a way that the first word in uppercase ends when if finds an uppercase letter followed by a lowercase letter.
        // Now a ' ' (space, (char)32) is considered not upper but in that case we still want our current character to become lowercase.
        if (Camelize.isSeparator(chars[i + 1])) {
          chars[i] = chars[i].toLowerCase();
        }

        break;
      }

      chars[i] = chars[i].toLowerCase();
    }

    return chars.join('');
  }

  static camelizeDictionaryKeys(dictionary: object): object {
    const result = {};

    for (const key of Object.keys(dictionary)) {
      const camelKey = key
        .split('.')
        .map((part) => Camelize.toCamelCase(part))
        .join('.');

      result[camelKey] = dictionary[key];
    }

    return result;
  }

  protected static isUpperCase(value: string): boolean {
    return value === value.toUpperCase();
  }

  protected static isSeparator(value: string): boolean {
    // line separator/paragraph separator/space separators - https://www.compart.com/en/unicode/category
    const separator =
      '\u2028\u2029\u0020\u00a0\u1680\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u202f\u205f\u3000';

    return separator.indexOf(value) >= 0;
  }
}

/* Trims specific character from the beginning of the string */
export function trimStart(str: string, trimChar: string) {
  return str?.replace(`/^${trimChar}+/g`, '');
}

/* Trims specific character from the end of the string */
export function trimEnd(str: string, trimChar: string) {
  return str?.replace(`/${trimChar}+$/g`, '');
}

/**
 * Create a unique filename based off it's file extension
 * @param ext file extension
 * @param prefix preferred filename prefix for the name to help identify it
 * @returns filename formatted
 */
export function createUniqueFilenameFromExt(ext: string, prefix?: string) {
  ext = ext.replace(/\./gi, '').replace(/ /gi, '');
  return `${prefix || ext}-${Math.floor(Date.now() + Math.random())}.${ext}`;
}

export function getUrlExtension(url: string) {
  return url.split(/[#?]/)[0].split('.').pop().trim().toLowerCase();
}
