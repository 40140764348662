import { Injectable } from '@angular/core';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import { JobDropdown, ProfileModel } from '@ups/xplat/api/dto';

const GET_JOBS = '/api/jobs/dropdown';
const FAVORITE_JOB = '/api/jobs/favorite';

@Injectable({ providedIn: 'root' })
export class JobSearchVPService {
  private vpClient;
  private spartaClient;
  private profile: ProfileModel;

  constructor(private clientFactory: MyHttpClientFactory) {
    this.vpClient = this.clientFactory.createHttpClient(
      environment.urls.viewpointAPI,
      true,
      ResponseCasingEnum.PascalCase
    );
    this.spartaClient = this.clientFactory.createHttpClient(
      environment.urls.spartaAPI,
      true,
      ResponseCasingEnum.PascalCase
    );
    this.profile = JSON.parse(localStorage.getItem('profile')) as ProfileModel;
  }

  public addOrRemoveFavoriteJob(vpJobID: string) {
    const url = FAVORITE_JOB;
    this.spartaClient
      // eslint-disable-next-line @typescript-eslint/naming-convention
      .post(url, { UserID: this.profile.auth0UserId, VPJobID: vpJobID })
      .toPromise();
  }

  public searchJobs(
    search: string,
    companyIDs: number[],
    hrRef: string = null,
    active: boolean = true
  ): Promise<JobDropdown[]> {
    let url =
      GET_JOBS + '/?searchString=' + encodeURI(search) + '&status=' + active;

    if (companyIDs && companyIDs.length) {
      companyIDs.forEach((c) => {
        url = url + `&vpCompanyIDs=${c}`;
      });
    }

    url = url + `&hrRef=${hrRef || this.profile.hrRef}`;

    return this.vpClient.get(url).toPromise();
  }
}
