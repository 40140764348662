/**
 * Collection of utilities useful for broad offline usage depending on workspace or per app level needs
 */
/* eslint-disable */
import { HttpRequest } from '@angular/common/http';
import { StorageKeys } from './storage';

/**
 * Generate a unique key based on the http request method + urlWithParams
 * @param req HttpRequest
 * @returns string formatted for uniqueness
 */
export function httpCacheRequestKey(req: HttpRequest<any>) {
  return `${req.method}-${req.urlWithParams}`;
}

/**
 * In-Memory cache of http cacheKey's with their timestemps on when they were last stored.
 * These are integrated into the http-ups.interceptor to handle automatically.
 * The uilities here can be used to look at timestamps against any api cacheKey you'd like anywhere for various purposes.
 */
const httpCacheResponseTimestamps: { [key: string]: number } = {};
export function getHttpCacheResponseTimestamps(cacheKey: string): number {
  return httpCacheResponseTimestamps[cacheKey];
}
export function setHttpCacheResponseTimestamps(
  cacheKey: string,
  timestamp: number
) {
  httpCacheResponseTimestamps[cacheKey] = timestamp;
}

/**
 * Strips the domain/protocol from url to provide just the endpoint
 * @param url of request
 * @returns string of the endpoint
 */
export function httpEndpointFromUrl(url: string) {
  return url ? `/${url.split('/').splice(3).join('/')}` : '';
}

export type offlineStorageColumnType = 'dynamicDropdowns' | 'dynamicContainers';

/**
 * Cached offline Dynamic typeahead/dropdown api requests
 * Keys match those of the response to the consolidated prefetch call
 * https://dynamic-content-api.azurewebsites.net/api/Form/dropdowns
 */
export const offlineRequestDropdowns = {
  Companies: '/api/hqco/company',
  Clients: '/api/parentCustomers',
  Employees: '/api/spartaemployee/employee/dropdown',
  Facilities: '/api/parentFacilities',
  Jobs: '/api/jobs/dropdown',
  LessonLearnedCategories: '/api/Form/lessonLearnedCategories',
  NearMissCategories: '/api/Form/nearMissCategories',
};

/**
 * Cached offline Dynamic containers
 * Key is what the data is stored with in any platform storage database
 */
export const offlineRequestDynamicContainers = {
  data: '/api/DynamicContainer/all',
};

/**
 * Offline response handling can by derived from other stored requests (for example, /api/DynamicContainer/all)
 * This defines all potential api request formats which can be handled while offline when data is derived from a different stored request
 * @param cacheKey the offline cacheKey determined in the http-ups.interceptor
 * @returns the offlineStorageColumnType of where to get the data and the processing function to handle the result
 */
export function getOfflineRequestHandling(cacheKey: string): {
  cacheKey: string;
  prefetchCacheKey: offlineStorageColumnType;
  handleResponseBody: (res: { data: unknown }) => unknown;
} {
  if (offlineRequestChecks.isDynamicContainerAll(cacheKey)) {
    return {
      cacheKey: StorageKeys.OFFLINE_DATA_DYNAMIC_CONTAINERS,
      prefetchCacheKey: 'dynamicContainers',
      handleResponseBody: (res) => {
        if (res?.data) {
          return res.data;
        }
        return [];
      },
    };
  } else if (offlineRequestChecks.isDynamicContainer(cacheKey)) {
    // Opening a dynamic container will fetch this endpoint with this cacheKey:
    // * GET-https://qa-dynamic-content-api.azurewebsites.net/api/DynamicContainer/1b46244e-67f6-4cbb-9871-fa5f15349bb1/container/15
    // while offline, we can get it's data from the stored data off the /api/DynamicContainer/all endpoint
    return {
      cacheKey: StorageKeys.OFFLINE_DATA_DYNAMIC_CONTAINERS,
      prefetchCacheKey: 'dynamicContainers',
      handleResponseBody: (res) => {
        const parts = cacheKey
          .split('/api/DynamicContainer/')[1]
          .split('/container/');
        const containerId = parts[0];
        const version = +parts[1];
        if (res?.data) {
          return (<Array<unknown>>res.data).find(
            (c: {
              originalVersionContainerId: string;
              versionNumber: number;
            }) => {
              return (
                c.originalVersionContainerId === containerId &&
                c.versionNumber === version
              );
            }
          );
        }
        return null;
      },
    };
  }
  return null;
}

/**
 * Various api requests which are custom handled offline
 */
export const offlineRequestChecks = {
  isDynamicContainerAll(url: string) {
    return url.includes(offlineRequestDynamicContainers.data);
  },
  isDynamicContainer(url: string) {
    // TODO: use RegEx here instead when time permits
    return (
      url.indexOf('dynamic-content-api') > -1 &&
      url.indexOf('/api/DynamicContainer/') > -1 &&
      url.indexOf('/container/') > -1
    );
  },
};

/**
 * All prefetched http requests considered for offline usage
 * These can be direct prefetched requests or part of consolidated prefetch calls
 * Stores an array of api endpoints, e.g. ['/api/hqco/company', '/api/parentCustomers']
 */
export const httpPrefetchedRequestsForOffline: Array<string> = [
  '/api/Form/dropdowns',
  ...Object.keys(offlineRequestDropdowns).map(
    (r) => offlineRequestDropdowns[r]
  ),
  ...Object.keys(offlineRequestDynamicContainers).map(
    (r) => offlineRequestDynamicContainers[r]
  ),
];
