import { Directive, HostListener, Optional, Self } from '@angular/core';
import { AutoCompleteComponent, ComboBoxComponent, DropDownListComponent } from '@progress/kendo-angular-dropdowns';

enum KEY_CODE {
  ENTER = 13,
}

/*
USAGE:      Import DropDownsModule & DropDownsModuleEx - the module/directive and add add open-on-enter attribute to <kendo-autocomplete>, <kendo-combobox> or <kendo-dropdownlist>.

HTML sample:
<kendo-autocomplete open-on-enter />
<kendo-combobox open-on-enter />
<kendo-dropdownlist open-on-enter />

 */
@Directive({
  selector: '[open-on-enter]',
})
export class DropDownsOpenOnEnterDirective {
  constructor(@Optional() @Self() private ac: AutoCompleteComponent, @Optional() @Self() private cb: ComboBoxComponent, @Optional() @Self() private ddl: DropDownListComponent) {
    console.warn('[open-on-enter] is bypassed until kendo ticket is reslved and a solution os found for the strange re-opening behaviour!');
  }

  @HostListener('keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    /*
        if (event.keyCode === KEY_CODE.ENTER) {

            if (this.ac && !this.ac.isOpen)
                this.ac.togglePopup(true);

            if (this.cb && !this.cb.isOpen)
                this.cb.toggle(true);

            if (this.ddl && !this.ddl.isOpen)
                this.ddl.togglePopup(true);

        }

        */
  }
}
