import { Injectable } from '@angular/core';
import { PerDiemService } from '@ups/xplat/api/services';
import { ApplicationModel } from '@ups/xplat/api/dto';

@Injectable({ providedIn: 'root' })
export class PerDiemSupportedTypesResolver {
  constructor(private perDiemService: PerDiemService, private applicationModel: ApplicationModel) {}

  public load(): Promise<any> {
    var self = this;
    if (this.applicationModel.perDiemSupportedTypes.length == 0) return this.perDiemService.fetchSupportedTypes().then((data) => (self.applicationModel.perDiemSupportedTypes = data));
    else return Promise.resolve(this.applicationModel.perDiemSupportedTypes);
  }
}
