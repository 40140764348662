function getStartOfWeek(date = new Date()) {
  const startDate = new Date();

  startDate.setDate(date.getDate() - (date.getDay() || 7) + 1);

  return startDate;
}

function getEndOfWeek(date = new Date()) {
  const endDate = new Date();

  endDate.setDate(date.getDate() - (date.getDay() || 7) + 7);

  return endDate;
}

export function getWeekRange(date = new Date()) {
  return [getStartOfWeek(date), getEndOfWeek(date)];
}

export function getDateOffsetFromCurrent(dateTime: Date): number {
  if (dateTime) {
    const msInDay = 24 * 60 * 60 * 1000;
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const date = new Date(
      dateTime.getFullYear(),
      dateTime.getMonth(),
      dateTime.getDate()
    );
    return Math.round((Number(date) - Number(today)) / msInDay);
  }
  return null;
}
