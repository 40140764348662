import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { FileDto } from '@ups/xplat/api/dto';
import {
  IDynamicModel,
  dynamicModelFormArrayTypes,
  IDynamicControlResponse,
  dynamicModelNonNullableKeys,
  IDynamicContainerMetadata,
  IDynamicTag,
  IDynamicControl,
  IDynamicUpdateOnValueChange,
} from '@ups/xplat/core';
import {
  convertTagToName,
  deepClone,
  isObject,
  swapArrayElements,
  tagCleanser,
} from '@ups/xplat/utils';

/**
 * Find a dynamic control by a specific condition
 * @param items dynamic controls
 * @param condition any condition which returns true
 * @returns index, ctrl, nestedArray (if found within nested controls)
 */
export function dynamicFindControlByCondition(
  items: Array<IDynamicModel>,
  condition: (item: IDynamicModel) => boolean
): { index: number; ctrl: IDynamicModel; nestedArray?: Array<IDynamicModel> } {
  let index = items.findIndex(condition);
  if (index > -1) {
    return {
      index,
      ctrl: items[index],
    };
  } else {
    // look in nested controls
    for (const i of items) {
      switch (i.type) {
        case 'accordion':
          if (i.options && i.options.accordion) {
            index = i.options.accordion.findIndex(condition);
            if (index > -1) {
              return {
                index,
                ctrl: i.options.accordion[index],
                nestedArray: i.options.accordion,
              };
            }
          }
          break;
      }
    }
  }
  // not found
  return {
    index: -1,
    ctrl: null,
  };
}

export function dynamicFindControlByClientId(
  items: Array<IDynamicModel>,
  id: string
) {
  return dynamicFindControlByCondition(items, (i) => i.clientId === id).ctrl;
}

export function dynamicFindSelectedControl(items: Array<IDynamicModel>) {
  return dynamicFindControlByCondition(items, (i) => !!i.selected).ctrl;
}

export function dynamicRemoveControl(items: Array<IDynamicModel>, id: string) {
  const findOptions = dynamicFindControlByCondition(
    items,
    (i) => i.clientId === id
  );
  if (findOptions.index > -1) {
    if (findOptions.nestedArray) {
      findOptions.nestedArray.splice(findOptions.index, 1);
    } else {
      items.splice(findOptions.index, 1);
    }
  }
  return findOptions.index;
}

export function dynamicReorderControl(
  items: Array<IDynamicModel>,
  id: string,
  direction: 'up' | 'down'
) {
  const findOptions = dynamicFindControlByCondition(
    items,
    (i) => i.clientId === id
  );

  const setReorder = (theItems: Array<IDynamicModel>) => {
    for (let i = 0; i < theItems.length; i++) {
      theItems[i].order = i + 1;
    }
  };
  if (findOptions.index > -1) {
    if (direction === 'up') {
      if (findOptions.index > 0) {
        swapArrayElements(
          findOptions.nestedArray ? findOptions.nestedArray : items,
          findOptions.index,
          findOptions.index - 1
        );
      }
    } else if (direction === 'down') {
      const targetItems = findOptions.nestedArray
        ? findOptions.nestedArray
        : items;
      if (findOptions.index < targetItems.length - 1) {
        swapArrayElements(
          targetItems,
          findOptions.index,
          findOptions.index + 1
        );
      }
    }
    setReorder(findOptions.nestedArray ? findOptions.nestedArray : items);
  }
}

/**
 * Checks whether a dynamic control is already saved to database
 * Determined by whether it's clientId is prefixed with 'new' or not
 * @param clientId control guid
 * @returns clientId if it's a existing/saved to backend dynamic control
 */
export function dynamicIsExistingControl(clientId: string) {
  return clientId && clientId.indexOf('new') === -1 ? clientId : null;
}

/**
 * Add item to a button group
 * @param selectedControl the control to add buttongroup items to
 */
export function dynamicAddItemToButtonGroupForControl(
  selectedControl: IDynamicModel
) {
  if (!selectedControl.options.buttongroup) {
    selectedControl.options.buttongroup = [];
  }
  selectedControl.options.buttongroup.push({
    label: 'Name',
    value: '',
  });
}

export function dynamicAddUpdateOnValueChangeForControl(
  selectedControl: IDynamicModel,
  updateOnValueChange?: IDynamicUpdateOnValueChange
) {
  if (!selectedControl.options.updateOnValueChange) {
    selectedControl.options.updateOnValueChange = [];
  }
  selectedControl.options.updateOnValueChange.push(updateOnValueChange);
}

export function dynamicPrepareControlForBackend(
  model: IDynamicModel,
  order?: number,
  isExistingContainer?: boolean
) {
  const transformPropsForBackend = (
    item: IDynamicModel,
    displayOrder?: number
  ) => {
    const displayCtrl = deepClone(item);
    let backendCtrl: IDynamicControl = {
      dynamicControlId: null,
      options: {},
      order: displayOrder || 0,
      dynamicControlName: (displayCtrl.formControlName || '').trim(),
      dynamicControlType: displayCtrl.type,
      dynamicControlLabel: displayCtrl.label,
      dynamicControlInstruction: displayCtrl.instruction,
      reportCategoryName: (
        displayCtrl.reportCategoryName ||
        displayCtrl.formControlName ||
        ''
      ).trim(),
    };
    if (isObject(backendCtrl.reportCategoryName)) {
      /* eslint-disable */
      // ensure all reportCategoryNames are reduced to strings only
      backendCtrl.reportCategoryName = (<{ Name: string }>(
        (<unknown>backendCtrl.reportCategoryName)
      )).Name;
      /* eslint-enable */
    }
    for (const prop of dynamicModelNonNullableKeys) {
      if (displayCtrl[prop]) {
        // only set if non nullable property
        backendCtrl[prop] = displayCtrl[prop];
      } else {
        // otherwise completely remove
        delete displayCtrl[prop];
      }
    }
    // clean various properties before merging for backend updates
    delete displayCtrl.formControlName;
    delete displayCtrl.type;
    delete displayCtrl.label;
    delete displayCtrl.instruction;
    delete displayCtrl.dynamicControlInstruction;
    delete displayCtrl.dynamicControlId;
    delete displayCtrl.dynamicControlLabel;
    delete displayCtrl.dynamicControlName;
    delete displayCtrl.dynamicControlType;
    delete displayCtrl.reportCategoryName;
    // create final updated control for backend
    backendCtrl = {
      ...backendCtrl,
      ...displayCtrl,
    };

    // 1. ensure updating controls maintain their backend saved id
    // 2. ensure newly added controls (which contain new-{guid})
    const existingControlId = dynamicIsExistingControl(displayCtrl.clientId);
    if (isExistingContainer && existingControlId) {
      backendCtrl.dynamicControlId = existingControlId;
    } else {
      // all controls should be new
      delete backendCtrl.dynamicControlId;
    }
    if (!backendCtrl.options) {
      backendCtrl.options = {};
    }
    if (backendCtrl.dynamicControlType === 'date') {
      // always set minDate to today
      backendCtrl.options.minDate = new Date();
    }
    // clear any admin options used while building the form
    // only applicable to builder UI and should never be persisted
    delete backendCtrl.options.admin;
    if (
      backendCtrl.dynamicControlType === 'buttongroup' &&
      backendCtrl.options &&
      backendCtrl.options.buttongroup
    ) {
      for (const g of backendCtrl.options.buttongroup) {
        g.dynamicControlLabel = g.label;
      }
    }
    // ensure empty accessRoleIds are not saved as empty Arrays - backend only accepts string | null
    if (Array.isArray(backendCtrl.accessRoleIds)) {
      backendCtrl.accessRoleIds =
        backendCtrl.accessRoleIds.length === 0
          ? null
          : backendCtrl.accessRoleIds.map((role) => role.Id).join(',');
    }
    return backendCtrl;
  };

  const newDynamicControl = transformPropsForBackend(model, order);

  if (
    dynamicModelFormArrayTypes.includes(newDynamicControl.dynamicControlType)
  ) {
    newDynamicControl.dynamicControlName = newDynamicControl.formArrayName;
    newDynamicControl.dynamicControls = <Array<IDynamicControl>>(
      newDynamicControl.options.accordion
    );
    if (
      newDynamicControl.dynamicControls &&
      newDynamicControl.dynamicControls.length
    ) {
      for (let i = 0; i < newDynamicControl.dynamicControls.length; i++) {
        newDynamicControl.dynamicControls[i] = transformPropsForBackend(
          <IDynamicModel>newDynamicControl.dynamicControls[i],
          i
        );
      }
    }
    delete newDynamicControl.options.accordion;
  }
  return newDynamicControl;
}

export function dynamicPrepareResponse(
  displayControls: Array<IDynamicModel>,
  formData: unknown,
  dynamicControlResponses: Array<IDynamicControlResponse>,
  formGroup: UntypedFormGroup,
  properties: Array<string>,
  activeAttachments: { [key: string]: Array<FileDto> }
) {
  if (!formGroup) {
    return;
  }
  const formDataJson: { [key: string]: Array<unknown> } = {};
  for (const prop of properties) {
    // console.log('prop:', prop);
    const fieldControl = dynamicFindControlByCondition(
      displayControls,
      (control) => {
        const propName = dynamicGetUniquePropertyName(control);
        return control[propName] === prop;
      }
    ).ctrl;
    if (fieldControl && !fieldControl.ignoreValue) {
      const nestedFormGroup = findNestedFormGroupsWith(formGroup, prop)[0];
      if (
        activeAttachments &&
        activeAttachments[prop] &&
        Array.isArray(activeAttachments[prop])
      ) {
        if (!formDataJson[prop]) {
          formDataJson[prop] = [];
        }
        for (const a of activeAttachments[prop]) {
          formDataJson[prop].push(a);
        }
        const fileIDs = activeAttachments[prop].map((f) => f.fileID);
        formData[prop] = fileIDs.join();
      } else if (nestedFormGroup) {
        const nestedFormProperties = Object.keys(
          nestedFormGroup.controls
        ).filter((k) => k !== 'submit');
        dynamicPrepareResponse(
          displayControls,
          formData,
          dynamicControlResponses,
          nestedFormGroup,
          nestedFormProperties,
          activeAttachments
        );
      } else {
        let formValue = formGroup.controls[prop].value;

        // console.log(prop, 'fieldControl:', fieldControl)

        if (formValue && fieldControl.valueProperty) {
          if (Array.isArray(formValue)) {
            const multiValues = [];
            for (const v of formValue) {
              multiValues.push(v[fieldControl.valueProperty]);
            }
            // TODO: discuss how multiple values are best represented in backend data
            formValue = multiValues.map((v) => `${v}`).join(',');
          } else {
            formValue = formValue[fieldControl.valueProperty];
          }
        }
        formData[prop] =
          fieldControl.inputType === 'number' && !isNaN(+formValue)
            ? +formValue
            : formValue;

        if (fieldControl.type === 'date' && formValue && formValue.formatted) {
          formData[prop] = formValue.formatted;
        }
        // console.log(prop, formValue);
      }
      // const responseContentJson: any = {};
      // responseContentJson[prop] = formData[prop];
      const response = dynamicFindControlResponseByCondition(
        dynamicControlResponses,
        (cr) => cr.dynamicControlId === fieldControl.clientId
      );
      const responseContent = formData[prop];
      const responseContentJson = formDataJson[prop];
      if (response) {
        response.responseContent = responseContent;
        response.responseContentJson = responseContentJson
          ? JSON.stringify(responseContentJson)
          : null;
      } else {
        dynamicControlResponses.push({
          dynamicControlId: fieldControl.clientId,
          isActive: true,
          isFinal: true,
          responseContent,
          responseContentJson: responseContentJson
            ? JSON.stringify(responseContentJson)
            : null,
        });
      }
    }
  }
}

/**
 * Finds nested FormGroups within the provided FormGroup controls
 * @param formGroup The FormGroup to find nested groups in
 * @param formControlName (optional) The formControlName
 * @param nestedFormGroupCollection (optional) Useful when combining with other logic
 * @returns collection of nested FormGroups if found, otherwise empty array
 */
export function findNestedFormGroupsWith(
  formGroup: UntypedFormGroup,
  formControlName?: string,
  nestedFormGroupCollection?: Array<UntypedFormGroup>
): Array<UntypedFormGroup> {
  const nestedFormGroups = nestedFormGroupCollection || [];
  if (formControlName) {
    if (formGroup?.controls[formControlName] instanceof UntypedFormArray) {
      // only one FormGroup can be nested in a FormArray control
      if ((<UntypedFormArray>formGroup.controls[formControlName]).controls) {
        nestedFormGroups.push(
          <UntypedFormGroup>(
            (<UntypedFormArray>formGroup.controls[formControlName]).controls[0]
          )
        );
      }
    }
  } else {
    // look through all controls
    for (const key of Object.keys(formGroup.controls)) {
      findNestedFormGroupsWith(formGroup, key, nestedFormGroups);
    }
  }
  return nestedFormGroups;
}

export function dynamicGetUniquePropertyName(selectedControl: IDynamicModel) {
  return dynamicModelFormArrayTypes.includes(selectedControl.type)
    ? 'formArrayName'
    : 'formControlName';
}

/**
 * Find a dynamic control response by a specific condition
 * @param items dynamic controls
 * @param condition any condition which returns true
 * @returns index, ctrl, nestedArray (if found within nested controls)
 */
export function dynamicFindControlResponseByCondition(
  items: Array<IDynamicControlResponse>,
  condition: (item: IDynamicControlResponse) => boolean
): IDynamicControlResponse {
  const index = items.findIndex(condition);
  if (index > -1) {
    return items[index];
  }
  return null;
}

export function validateNoDupeFormControlNames(items: Array<IDynamicModel>) {
  const errors = [];
  const dupeNames = {};
  const addError = (c: IDynamicModel, name: string) => {
    errors.push(`Your form contains controls with duplicate "Control Names".`);
    errors.push(
      `Label: ${c.label || c.formControlName}, Control Name: ${name}`
    );
  };
  for (const control of items) {
    const controlName = control.formControlName || control.formArrayName;
    if (dupeNames[controlName]) {
      addError(control, controlName);
      return errors.join(' ');
    } else {
      dupeNames[controlName] = control.label || control.formControlName;
    }
  }
  // look in nested controls
  for (const i of items) {
    switch (i.type) {
      case 'accordion':
        if (i.options && i.options.accordion) {
          for (const subControl of i.options.accordion) {
            const controlName =
              subControl.formControlName || subControl.formArrayName;
            if (dupeNames[subControl.formControlName]) {
              addError(subControl, controlName);
              return errors.join(' ');
            } else {
              dupeNames[subControl.formControlName] =
                subControl.label || subControl.formControlName;
            }
          }
        }
        break;
    }
  }
  return errors.join(' ');
}

export function dynamicGetCategoryTags(tags: Array<IDynamicTag>) {
  return tags ? tags.filter((t) => t.tagName.indexOf('category:') > -1) : [];
}

export function dynamicGetAppTags(tags: Array<IDynamicTag>) {
  // App tags can be prepended with 'app:{name}' or just the '{name}' alone.
  // UPS began adding 'app:{name}' prefix to app tags in October 2023
  // Note: A migration could be added to ensure all non-category tags are prefixed with 'app:{name}' to ensure consistency across the board
  // Keeping tags prefixed helps utilize tagging in versatile ways
  return tags
    ? tags.filter(
        (t) =>
          t.tagName.indexOf('app:') > -1 ||
          t.tagName.indexOf('category:') === -1
      )
    : [];
}

export function dynamicGetCategoryTagName(tag: IDynamicTag) {
  return tag && tag.tagName ? tag.tagName.replace('category:', '') : '';
}

export function dynamicGetAppTagName(tag: IDynamicTag) {
  return tag && tag.tagName ? tag.tagName.replace('app:', '') : '';
}

export function dynamicConvertTagToName(tag: IDynamicTag) {
  return tag ? convertTagToName(dynamicGetCategoryTagName(tag)) : '';
}

export function dynamicGetFormPathFromRouteUrl(
  url: string,
  dynamicContainerList: Array<IDynamicContainerMetadata>,
  fallbackUrl?: string
) {
  // dynamically considers form categories from the dynamic container list
  let firstForm: IDynamicContainerMetadata;
  let firstFormCategory = '';
  const useFirstForm = () => {
    firstForm = dynamicContainerList[0];
    const categoryTags = dynamicGetCategoryTags(firstForm.tags);
    if (categoryTags && categoryTags.length) {
      firstFormCategory = dynamicGetCategoryTagName(categoryTags[0]);
    }
  };
  if (dynamicContainerList && dynamicContainerList.length) {
    if (url === '/form') {
      // if exact match on base form route, get the first form that is uncategorized (has no category tags)
      // categorized routes start with /forms1, /forms2, etc.
      firstForm = dynamicContainerList.find(
        (c) => dynamicGetCategoryTags(c.tags).length === 0
      );
      if (firstForm) {
        firstFormCategory = 'forms';
      } else {
        // fallback to first one in the list
        useFirstForm();
      }
    } else {
      useFirstForm();
    }
  }
  // the fallback here is a just in case generic fallback (can be anything relevent for app or passed in via arg in future - should not happen if data loads properly as it should)
  return firstForm
    ? `/form/${firstFormCategory ? firstFormCategory + '/' : ''}${tagCleanser(
        firstForm.dynamicContainerName
      )}`
    : fallbackUrl || '/page-auth-landing';
}

export function dynamicGetReportCategoryNames(
  controls: Array<IDynamicControl>
) {
  const reportNames = [];
  if (controls) {
    reportNames.push(
      ...controls.map((c) => c.reportCategoryName).filter((n) => !!n)
    );
  }
  return reportNames;
}

export interface IDynamicAccordionConditions {
  accordionRequiredValues: Array<string>;
  accordionRequiredValueRequiresAnother: Array<string>;
  accordionControlNames: Array<string>;
}

export function dynamicCheckAccordionConditions(
  controls: Array<IDynamicModel>
) {
  const allAccordions = controls.filter((c) => c.type === 'accordion');
  let applyToAllConditions: IDynamicAccordionConditions = null;
  const conditions: { [key: string]: IDynamicAccordionConditions } = {};
  for (const accordion of allAccordions) {
    const accordionCondition: IDynamicAccordionConditions = {
      accordionControlNames: accordion.options.accordion.map(
        (c) => c.formControlName
      ),
      accordionRequiredValues: accordion.options?.accordionRequiredValues
        ? accordion.options?.accordionRequiredValues
            .split(',')
            .filter((v) => !!v)
            .map((v) => v.trim())
        : [],
      accordionRequiredValueRequiresAnother: accordion.options
        ?.accordionRequiredValueRequiresAnother
        ? accordion.options?.accordionRequiredValueRequiresAnother
            .split(',')
            .filter((v) => !!v)
            .map((v) => v.trim())
        : [],
    };
    if (
      accordion.options?.accordionRequiredValuesApplyToAll &&
      !applyToAllConditions
    ) {
      // use the first apply to all condition found
      applyToAllConditions = accordionCondition;
    }
    conditions[accordion.formArrayName] = accordionCondition;
  }

  return {
    applyToAllConditions,
    conditions,
    allAccordions,
  };
}

/* eslint-disable */
/**
 * Dynamic Typeahead Company filter
 * @param query search string
 * @param data data to filter
 * @returns filtered data
 */
export function dynamicTypeaheadCompanyFilter(
  query: string,
  data: { CompanyName?: string; CompanyNumber?: number }
): boolean {
  if (query && data) {
    return (
      data.CompanyName?.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
      data.CompanyNumber?.toString() === query
    );
  } else {
    return true;
  }
}

/**
 * Dynamic Typeahead Employees filter
 * @param query search string
 * @param data data to filter
 * @returns filtered data
 */
export function dynamicTypeaheadEmployeeFilter(
  query: string,
  data: {
    Name?: string;
    FirstName?: string;
    LastName?: string;
    FullName?: string;
    IsActive?: boolean;
  },
  extraFilters?:
    | {
        IsActive?: boolean;
      }
    | undefined
): boolean {
  if (query && data) {
    // allow extra custom filters, often via custom component usages
    if (typeof extraFilters?.IsActive === 'boolean') {
      if (data.IsActive !== extraFilters.IsActive) {
        return false;
      }
    }
    query = query.toLowerCase();
    let results = false;
    let tokenized = [query];
    if (query.indexOf(' ') > -1) {
      tokenized = query
        .split(' ')
        .filter((q) => !!q)
        .map((q) => q.trim());
    }
    if (tokenized.length) {
      if (tokenized.length === 1) {
        // full, first or last name contains
        if (
          data.FullName?.toLowerCase().trim().indexOf(tokenized[0]) > -1 ||
          data.FirstName?.toLowerCase().trim().indexOf(tokenized[0]) > -1 ||
          data.LastName?.toLowerCase().trim().indexOf(tokenized[0]) > -1
        ) {
          results = true;
        }
      } else {
        // ignore ',' and ' ' in full names when querying
        const fullName = data.FullName?.toLowerCase()
          .split(/,| /gi)
          .filter((q) => !!q)
          .map((q) => q.trim());
        if (fullName.length >= 2 && tokenized.length === 2) {
          // compare query string to: lastname, firstname (from fullname string) or vice versa
          if (
            !results &&
            ((fullName[0] === tokenized[0] &&
              fullName[1].indexOf(tokenized[1]) > -1) ||
              (fullName[1].indexOf(tokenized[0]) > -1 &&
                fullName[0].indexOf(tokenized[1]) > -1))
          ) {
            results = true;
          }
        } else {
          const fullNameString = fullName.join(' ');

          if (fullNameString?.indexOf(tokenized.join(' ')) > -1) {
            results = true;
          }
          if (!results) {
            for (const token of tokenized) {
              if (!results && fullNameString?.indexOf(token) > -1) {
                results = true;
                break;
              }
            }
          }
        }
      }
    }
    return results;
  } else {
    return true;
  }
}

/**
 * Dynamic Typeahead Facility filter
 * @param query search string
 * @param data data to filter
 * @returns filtered data
 */
export function dynamicTypeaheadFacilityFilter(
  query: string,
  data: { DisplayName?: string }
): boolean {
  if (query && data) {
    return data.DisplayName
      ? data.DisplayName.toLowerCase().indexOf(query.toLowerCase()) > -1
      : false;
  } else {
    return true;
  }
}

/**
 * Dynamic Typeahead Job filter
 * @param query search string
 * @param data data to filter
 * @returns filtered data
 */
export function dynamicTypeaheadJobFilter(
  query: string,
  data: { Name?: string; JobID?: string }
): boolean {
  if (query && data) {
    return data.Name
      ? data.Name.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          (data.JobID &&
            data.JobID.toLowerCase().indexOf(query.toLowerCase()) > -1)
      : false;
  } else {
    return true;
  }
}

/**
 * Dynamic Typeahead Customers filter
 * @param query search string
 * @param data data to filter
 * @returns filtered data
 */
export function dynamicTypeaheadCustomersFilter(
  query: string,
  data: { CustomerName?: string }
): boolean {
  if (query && data) {
    return data.CustomerName
      ? data.CustomerName.toLowerCase().indexOf(query.toLowerCase()) > -1
      : false;
  } else {
    return true;
  }
}

/**
 * Dynamic Typeahead Generic filter
 * @param query search string
 * @param data data to filter
 * @returns filtered data
 */
export function dynamicTypeaheadGenericFilter(
  query: string,
  data: { Name?: string }
): boolean {
  if (query && data) {
    return data.Name
      ? data.Name.toLowerCase().indexOf(query.toLowerCase()) > -1
      : false;
  } else {
    return true;
  }
}
