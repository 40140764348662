import * as _ from 'lodash';

import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  GroupDescriptor,
  State,
} from '@progress/kendo-data-query';

import { PathSegment } from './../../Group-Result/path-segment';

/**
 * KENDO: import{ toODataString } from "@progress/kendo-data-query";
 * CUSTOM: import {toODataStringEx as toODataString} from "@ups/angular-shared-libs/kendo/OData/odata";
 */
import {
  toODataStringEx as toODataString,
  ODataSortUtils,
  ODataExSettings,
} from '@ups/xplat/features';

/** GroupOData calls for an alternative grouping grid. */
export class GroupOData {
  /*
  Creates an ODataQuery string to load next sub-level (or all sub-level) groups to a given group.
  Params:
      state (State = required):
          kendo grid state object
      parentGroupPath (PathSegment[] = null)
          locates the given group to which we load data
      linkedAggregateFields (Array<Array<fieldName: string>> = null)
          array of field names we want to load as a group - if inside a group any of the field is used in the aggregation, others will be added as well
      usePaging (boolean = true):
          in cases we want to load all sub-groups we set it to false
      levelsToLoad (number = 0)
          NOTE: this param. replaces the previous loadImmediateSubGroupsOnly so levelsToLoad: 0 eq. loadImmediateSubGroupsOnly: false
          when expanding groups we load next level (so levelsToLoad = 1) and it also creates aggregates to that level
          we can use levelsToLoad = 0 to fetch all groups beneath a node, but the aggregates are always calculated to the last level only
          this means if we wan't to have aggregates on all levels, we need to one call per level, with values 1, 2, ... N
      countFieldName (string = "count"):
          a default name to get the total number of items found
      customAggregates:
          odata expression to add other custom aggregates to the call
  Returns:
      ODataQuery (string)
  */
  public static getGroupQuery(
    state: State,
    parentGroupPath: PathSegment[] | null = null,
    linkedAggregateFields: string[][] | null = null,
    usePaging = true,
    levelToLoad = 0,
    countFieldName = 'count',
    customAggregates: string = null
  ): string {
    const stateCopy = _.cloneDeep(state) as State;

    if (!stateCopy.group && !stateCopy.group.length)
      throw Error('No grouping is set, cannot create group query!');

    if (!usePaging) {
      delete stateCopy.skip;
      delete stateCopy.take;
    }

    if (levelToLoad) {
      // 0 = all levels in 1 call, aggregates will be avail. just to the last level
      // 1 = load next level only
      // 2 = load 2nd level groups
      // ...
      const gl = (parentGroupPath ? parentGroupPath.length : 0) + levelToLoad;
      stateCopy.group.length = Math.min(stateCopy.group.length, gl);
    }

    GroupOData.extendStateGroupArrayWithLinkedFieldNames(
      stateCopy,
      linkedAggregateFields
    );

    const groupByExpression = countFieldName
      ? stateCopy.group.length > 0
        ? `groupby((${stateCopy.group
            .map((i) => i.field)
            .join(', ')}), aggregate($count as ${countFieldName}${
            customAggregates ? ',' + customAggregates : ''
          }))`
        : ''
      : stateCopy.group.length > 0
      ? `groupby((${stateCopy.group.map((i) => i.field).join(', ')}))`
      : '';
    // filter must include also parent item filtering as well
    GroupOData.extendStateFilterWithParentGroupFiltering(
      stateCopy,
      parentGroupPath
    );

    // now need to handle filter differently
    const groupFilterExpression =
      stateCopy.filter &&
      stateCopy.filter.filters &&
      stateCopy.filter.filters.length
        ? `filter(${toODataString({ filter: stateCopy.filter }).substring(8)})`
        : '';

    // the apply (group) expression
    const applyExpression =
      `$apply=` +
      [groupFilterExpression, groupByExpression].filter((i) => i).join('/');

    // now ensure proper sort (a must for grouping)
    stateCopy.sort = ODataSortUtils.getPatchedSortForGroupQuery(
      stateCopy,
      false
    );
    const sortExpression =
      stateCopy.sort && stateCopy.sort.length
        ? toODataString({
            skip: stateCopy.skip,
            take: stateCopy.take,
            sort: stateCopy.sort,
          })
        : '';

    // final odata query
    const odataQuery = sortExpression
      ? applyExpression + '&' + sortExpression
      : applyExpression;

    return odataQuery;
  }

  /*
  With groups (aggregates) we might want to load similar fields (same aggregation group on the view).
  If for a given group aggregate there are some sum/min/max/avg columns on the view, here we can force them to load as well.
   */
  private static extendStateGroupArrayWithLinkedFieldNames(
    state: State,
    linkedFields: string[][]
  ) {
    // NOTE: based on NIFTY - BackOrders - extendGroupQueryWithField
    if (linkedFields) {
      linkedFields.forEach((fieldNames) => {
        // if any of the fieldNames is inside the group...
        const hasOneCommonItem = fieldNames.some((fieldName) =>
          state.group.some((group) => group.field === fieldName)
        );
        if (hasOneCommonItem) {
          // ...we need to add all other field names (if not already there)
          fieldNames.forEach((fieldName) => {
            if (state.group.every((group) => group.field !== fieldName))
              state.group.push({
                field: fieldName,
                dir: 'asc',
              } as GroupDescriptor);
          });
        }
      });
    }
  }

  /*
  When loading a sub-group to a selected group, we need to apply a where clause, so just the items to the selected groups are used in our aggregate call.
  */
  private static extendStateFilterWithParentGroupFiltering(
    state: State,
    parentGroupPath: PathSegment[] | null
  ) {
    // NOTE: this fnc. ensures that when we do a search, we search only for group/items below the selected node (group)
    if (parentGroupPath && parentGroupPath.length) {
      const filterDescriptors = parentGroupPath.map(
        (item) =>
          <FilterDescriptor>{
            field: item.field,
            value: item.value,
            operator: 'eq',
          }
      );
      const appendFilter = <CompositeFilterDescriptor>{
        logic: 'and',
        filters: filterDescriptors,
      };
      if (!state.filter) {
        state.filter = appendFilter;
      } else if (state.filter.logic && state.filter.logic === 'and') {
        state.filter.filters.push(...appendFilter.filters);
      } else {
        const mainCompositeFilter = state.filter;
        state.filter = <CompositeFilterDescriptor>{
          logic: 'and',
          filters: [mainCompositeFilter, appendFilter],
        };
      }
    }
  }

  /*
  Creates an ODataQuery string to the URI from we do get all the items below a given group.
  Params:
      state (State = required):
          kendo grid state object
      parentGroupPath (PathSegment[] = null)
          locates the given group to which we load data
      loadAllItemsToGroups (boolean = false)
          filter by entries   => loadAllItemsToGroups = false
          filter by groups    => loadAllItemsToGroups = true
          most grids by default do have a filter, do search data based on that filter and then do grouping those items that matched filter.
          with group and item loading separated we are able to create a special way to load groups and items.
          if we apply the filter for groups, we will get all groups in which there's at least one item fulfilling filter condition.
          if we omit the filter when loading items (loadAllItemsToGroups = true) then we actually show all the items to that given group.
          for the above to work, we also have to support merging kendo data-result in a special way, where:
          - we do merge new groups to the data structure
          - but after then we do merge new items to existing groups only (and skip items in belonging to a group that's not already part of the data structure)
          this trick is needed because the loadAllItemsToGroups will load extra items as well, where the at least 1 item must fulfill filter would not be satisfied)
  Returns:
      ODataQuery (string)
  */
  public static getItemQuery(
    state: State,
    parentGroupPath: PathSegment[] | null = null,
    loadAllItemsToGroups = false,
    oDataSettings: ODataExSettings = new ODataExSettings(true)
  ): string {
    // keep just sort and filter (if not all -so unfiltered- items are req.)
    // we do remove skip/take as we load all items or all items complying filter setting
    const hasGrouping =
      parentGroupPath &&
      parentGroupPath.length &&
      state.group &&
      state.group.length;
    const stateCopy = hasGrouping
      ? ({
          sort: state.sort,
          filter: loadAllItemsToGroups ? null : state.filter,
        } as State)
      : ({
          skip: state.skip,
          take: state.take,
          sort: state.sort,
          filter: state.filter,
        } as State);

    // apply parent's as a where filter clausule
    GroupOData.extendStateFilterWithParentGroupFiltering(
      stateCopy,
      parentGroupPath
    );

    // get & return odata query
    const odataQuery = toODataString(stateCopy, oDataSettings);
    return odataQuery;
  }

  /*
  Converts a regular $filter=... odata expression to get unique values.
  Final query should move the filtering inside the $apply expression - so $apply=filter(...)/groupby((...), aggregate).
   */
  public static extendODataFilterQueryToGetUniqueFieldValues(
    odataFilterQuery: string,
    fieldNames: string[]
  ): string {
    if (!fieldNames || !fieldNames.length)
      throw new Error(
        'The fieldNames[] property must contain at least one valid field name.'
      );

    // we need the value of the $filter from the original query
    const filterValue = odataFilterQuery
      .split('&')
      .find((i) => i.startsWith('$filter'))
      .substring(8);

    // the group by expression consists of our fields
    const groupByExpression = `groupby(${fieldNames.join(',')})`;
    const applyExpression =
      `$apply=` +
      [filterValue ? `filter(${filterValue})` : '', groupByExpression]
        .filter((i) => i)
        .join('/');

    // trim filterBy from original query
    let modifiedOdataFilterQuery = odataFilterQuery;
    if (filterValue) {
      const filterExpression = '$filter=' + filterValue;
      modifiedOdataFilterQuery = odataFilterQuery.startsWith(filterExpression)
        ? odataFilterQuery.replace(filterExpression, '')
        : odataFilterQuery.replace('&' + filterExpression, '');
    }

    // final unique values query
    const uniqueValuesQuery = [modifiedOdataFilterQuery, applyExpression]
      .filter((i) => i)
      .join('&');
    return uniqueValuesQuery;
  }
}
