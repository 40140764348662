import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ParentFacilityDropdownDto,
  ParentFacilityDto,
} from '@ups/xplat/api/dto';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ViewPointFacilityService {
  protected viewpointApiClient: HttpClient;
  constructor(private clientFactory: MyHttpClientFactory) {
    const urlBase = environment.urls.viewpointAPI;
    this.viewpointApiClient = this.clientFactory.createHttpClient(
      urlBase,
      true,
      ResponseCasingEnum.PascalCase
    );
  }

  public loadFacilities(): Promise<ParentFacilityDropdownDto[]> {
    console.log('loading facilities?');

    const url = '/api/quotelog/facilities';

    return this.viewpointApiClient
      .get<ParentFacilityDropdownDto[]>(url)
      .toPromise()
      .then((data) => data);
  }

  public loadVpFacilities(): Observable<ParentFacilityDropdownDto[]> {
    console.log('loading facilities?');

    const url = '/api/quotelog/facilities';

    return this.viewpointApiClient.get<ParentFacilityDropdownDto[]>(url);
  }

  public getParentFacilityDropdown(
    searchString: string,
    take: number = 0,
    parentCustomerIDs: number[] = [],
    parentFacilityID: number = null
  ): Promise<ParentFacilityDto[]> {
    const url = '/api/parentFacilities';
    let queryParams = '?';

    if (searchString) queryParams += 'searchString=' + searchString;

    if (parentCustomerIDs && parentCustomerIDs.length !== 0) {
      parentCustomerIDs.forEach((i) => {
        queryParams +=
          (queryParams.length > 1 ? '&' : '') + 'parentCustomerIDs=' + i;
      });
    }

    if (parentFacilityID && parentFacilityID !== 0) {
      queryParams +=
        (queryParams.length > 1 ? '&' : '') +
        'parentFacilityID=' +
        parentFacilityID;
    }

    if (take) {
      queryParams += (queryParams.length > 1 ? '&' : '') + 'take=' + take;
    }

    return this.viewpointApiClient
      .get<ParentFacilityDto[]>(url + queryParams)
      .toPromise()
      .then((data) => data);
  }
}
