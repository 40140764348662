import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UPSCheckboxFilter } from './checkbox-filter.component';
import { FormsModule } from '@angular/forms';
import { PopupModule } from '@progress/kendo-angular-popup';
import { UIModule } from '../../../ui/ui.module';

const components = [UPSCheckboxFilter];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule,
    UIModule,
    FormsModule,
    PopupModule,
  ],
})
export class CheckboxFilterModule {}
