import { BaseModel } from '../base.model';

export class JobContactDto extends BaseModel {
  public ManPowerReqContactID: string;
  public EmployeeID: string | null;
  public ManPowerReqID: string;
  public SecurityUserId: number | null;
  public SecurityRoleId: number | null;
  public RoleName: string | null;
  public Name: string;
  public Email: string;
  public PhoneNumber: string;
  public Shift: number | null;
  public Description: string;
  public bNotify: boolean;
  public bPOC: boolean;
  public bActive: boolean;

  constructor(o: any = null) {
    super(o);
  }
}
