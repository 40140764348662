import { Injectable } from '@angular/core';

import { Actions } from '@ngrx/effects';

import { LocalStorageService } from '../../services';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const defaultPageKey = 'default-route';

@Injectable()
export class RouterEffects {
  constructor(
    private actions: Actions,
    private storageService: LocalStorageService
  ) {}
}
