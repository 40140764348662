<div
  *ngIf="showBanner"
  class="offline-banner"
  [class.hide-now]="hideNow"
  [class.online]="!offline"
>
  <i
    class="far me-3"
    [class.fa-wifi-slash]="offline"
    [class.fa-wifi]="!offline"
  ></i>
  {{ offlineMessage }}
  <span class="pending-message" [hidden]="!statusUpdates">
    {{ statusUpdates }}
  </span>
</div>
