/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import { BaseModel } from '../base.model';
import { PlantModel } from './plant-model';

export class JobModel extends BaseModel {
  Active = 0;
  ClassName = '';
  EndDate: Date = null;
  StartDate: Date = null;
  JobID = '';
  JobName = '';
  OpeningsCount = 0;
  Plant: PlantModel = new PlantModel();
  selected = false;
  TotalCount = 0;

  constructor(o: any = null) {
    super(o);
    if (o) {
      Object.assign(this, o);
      this.StartDate = o.StartDate ? new Date(o.StartDate) : null;
      this.EndDate = o.EndDate ? new Date(o.EndDate) : null;
    }
  }
}
