<ups-header title="Dispatch Statuses"></ups-header>
<section class="pt-7 condensed-view">
  <div class="container-fluid gutter-30">
    <div class="row">
      <div class="col-md-12">
        <kendo-card class="custom-style">
          <kendo-card-body>
            <div id="dispatchStatusesGrid">
              <kendo-grid
                [data]="gridData"
                [pageable]="false"
                [rowClass]="rowCallback"
                (dataStateChange)="dataStateChange($event)"
                [loading]="isLoading"
                [selectable]="{ mode: 'single', checkboxOnly: true }"
                (selectionChange)="selectionChange($event)"
                class="white-bg toolbar-no-border rate-sheet high-header fit-to-screen-xl"
                #grid
              >
                <ng-template kendoGridToolbarTemplate>
                  <ups-grid-toolbar
                    [xlsxDownloadUri]="getXlsxDownloadUri"
                    [isExportEnabled]="grid"
                    [isExportButtonEnabled]="grid"
                    (reloadClick)="loadData()"
                    [showClearFilters]="false"
                    [showAdd]="canModify"
                    (addClick)="editModal.open(null)"
                    [showEdit]="true"
                    [isEditDisabled]="!selectedRecord"
                    [editText]="canModify ? 'Edit' : 'Detail'"
                    (editClick)="editModal.open(selectedRecord)"
                    [showDelete]="canModify"
                    [isDeleteDisabled]="
                      !selectedRecord || selectedRecord.isSystemDependent
                    "
                    [deleteConfirmMessage]="
                      'Do you really want to Delete the selected Dispatch Status?'
                    "
                    (deleteConfirmClick)="delete(selectedRecord)"
                  >
                    <input
                      type="button"
                      class="k-button-icontext k-button"
                      value="Save Order"
                      help-anchor="button__save-order"
                      (click)="saveOrder()"
                    />
                  </ups-grid-toolbar>
                </ng-template>

                <kendo-grid-checkbox-column
                  [width]="25"
                  [resizable]="false"
                  [includeInChooser]="false"
                ></kendo-grid-checkbox-column>

                <kendo-grid-column
                  field="Position"
                  title="Position"
                  width="40"
                ></kendo-grid-column>
                <kendo-grid-column
                  field="Name"
                  title="Name"
                  width="200"
                ></kendo-grid-column>
                <kendo-grid-column
                  field="DispatchGroupName"
                  title="Dispatch Group"
                  width="200"
                ></kendo-grid-column>
                <kendo-grid-column
                  title="Is Final"
                  field="isFinalStatus"
                  width="40"
                >
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div
                      *ngIf="dataItem.isFinalStatus"
                      style="text-align: center"
                    >
                      <i class="fa fa-check"></i>
                    </div>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column
                  title="Is System"
                  field="isSystemDependent"
                  width="40"
                >
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div
                      *ngIf="dataItem.isSystemDependent"
                      style="text-align: center"
                    >
                      <i class="fa fa-check"></i>
                    </div>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>
            </div>
          </kendo-card-body>
        </kendo-card>
      </div>
    </div>
  </div>
</section>

<ups-dispatch-status-edit (saved)="loadData()"></ups-dispatch-status-edit>
