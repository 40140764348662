import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import {
  CustomExportDataRequestDto,
  CustomExportDto,
} from '@ups/xplat/api/dto';

const GET_FIELD_GROUPS: string = '/api/customExport/fieldGroups';
const GET_FIELDS: string = '/api/customExport/fields/{:viewName}';
const GET_FIELD_SECTIONS: string =
  '/api/customExport/fieldSections/{:fieldGroupID}';
const CUSTOM_EXPORT: string = '/api/customExport';
const GET_DATA: string = '/api/customExport/data';

@Injectable({
  providedIn: 'root',
})
export class CustomExportService {
  protected spartaClient: HttpClient;
  protected spartaApiClient: HttpClient;

  constructor(private clientFactory: MyHttpClientFactory) {
    const urlBase = environment.urls.spartaAPI;
    this.spartaClient = this.clientFactory.createHttpClient(
      urlBase,
      true,
      ResponseCasingEnum.CamelCase
    );
    this.spartaApiClient = this.clientFactory.createHttpClient(
      urlBase,
      true,
      ResponseCasingEnum.PascalCase
    );
  }

  public getFieldGroups() {
    const url = GET_FIELD_GROUPS;

    return this.spartaClient.get(url).toPromise();
  }

  public getFields(viewName: string) {
    const url = GET_FIELDS.split('{:viewName}').join(viewName);

    return this.spartaClient.get(url).toPromise();
  }

  public getFieldSections(fieldGroupID: string) {
    const url = GET_FIELD_SECTIONS.split('{:fieldGroupID}').join(fieldGroupID);

    return this.spartaClient.get(url).toPromise();
  }

  public getTemplates(userID: string = null) {
    const url = `${CUSTOM_EXPORT}/${userID}`;

    return this.spartaClient.get(url).toPromise();
  }

  public saveTemplate(dto: CustomExportDto) {
    const url = CUSTOM_EXPORT;

    return this.spartaClient.post(url, dto).toPromise();
  }

  public getData(dto: CustomExportDataRequestDto) {
    const url = GET_DATA;

    return this.spartaClient.post(url, dto).toPromise();
  }
}
