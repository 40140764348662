import { Injectable } from '@angular/core';
import { GroupRowArgs, PageChangeEvent } from '@progress/kendo-angular-grid';
import {
  GroupDescriptor,
  GroupResult,
  State,
} from '@progress/kendo-data-query';
import { PageChangeEventExtended } from './grid-grouping-pager.component';
import { Observable, of } from 'rxjs';
import { ODataResult } from '@ups/xplat/features';

@Injectable({
  providedIn: 'root',
})
export class GridGroupingPagerService<T> {
  lastGroupExpandIndex: string = null;
  groupIndexAndLastPageUsed: Record<string, PageChangeEventExtended<T>> = {};
  initialGroupPageSize = 25;
  startPagingFromItemsNumber: number = this.initialGroupPageSize; // groups that have more than this number of items will get the paging activated

  getGroupPage(groupIndex: string): PageChangeEventExtended<T> {
    if (!this.groupIndexAndLastPageUsed[groupIndex]) {
      this.groupIndexAndLastPageUsed[groupIndex] = {
        skip: 0,
        take: this.initialGroupPageSize,
        totalCount: 0,
      } as PageChangeEventExtended<T>;
    }

    return this.groupIndexAndLastPageUsed[groupIndex];
  }

  pageSize(groupIndex: string): number {
    return this.getGroupPage(groupIndex).take;
  }

  skip(groupIndex: string): number {
    return this.getGroupPage(groupIndex).skip;
  }

  totalCount(groupIndex: string, totalCount: number): number {
    return totalCount || this.getGroupPage(groupIndex).totalCount;
  }

  onPageChange(
    event: PageChangeEvent,
    group: GroupResult,
    groupIndex: string,
    totalCount?: number
  ) {
    const page: PageChangeEventExtended<T> = this.getGroupPage(groupIndex);
    const lastKnownTotalCount = totalCount || page.totalCount;
    page.skip = event.skip;
    page.take = event.take;
    page.totalCount = lastKnownTotalCount;
    this.groupIndexAndLastPageUsed[groupIndex] = page;
    this.lastGroupExpandIndex = groupIndex;
    group.items = []; // to force refresh of the already loaded rows inside a group as the items will change - otherwise it would be cashed and no rows would be loaded.
  }

  /**
   * Is last group expanded (in case data in the grid are grouped by multiple columns the method returns true if groupIndex is the last group - so the one which shows actual results)
   */
  isLastGroup(groups: GroupDescriptor[], groupIndex: string): boolean {
    return !!groups?.length && groups.length === groupIndex.split('_').length;
  }

  consolidateGroupPaging() {
    // During filter change the kendo generates odata query for all opened groups in the grid by condition where column in (...,..,..)
    // and it re-expands the already expanded groups so we need to consolidate the pagesize as now we had only 1 query for all groups
    for (const key of Object.keys(this.groupIndexAndLastPageUsed)) {
      const pageData: PageChangeEventExtended<T> =
        this.groupIndexAndLastPageUsed[key];
      pageData.skip = 0;
      pageData.take = this.initialGroupPageSize;
    }
  }

  resetGroupPaging() {
    this.lastGroupExpandIndex = null;
    this.groupIndexAndLastPageUsed = {};
  }

  onGroupExpand(groups: GroupDescriptor[], groupRowArgs: GroupRowArgs) {
    this.lastGroupExpandIndex = null;
    if (
      this.isLastGroup(groups, groupRowArgs.groupIndex) &&
      this.isCountToShowPaging(groupRowArgs.group)
    ) {
      this.lastGroupExpandIndex = groupRowArgs.groupIndex;
    }
  }

  isCountToShowPaging(group: GroupResult): boolean {
    const itemsCount = this.getGroupResultItemsCount(group);
    return itemsCount > this.startPagingFromItemsNumber;
  }

  onGroupChange() {
    this.resetGroupPaging();
  }

  getGroupResultItemsCount(group: GroupResult): number {
    if (
      !group ||
      !group.aggregates ||
      !group.aggregates.default ||
      !group.aggregates.default.count
    ) {
      return 0;
    }

    return group.aggregates.default.count;
  }

  getGroupData(): Observable<ODataResult> | undefined {
    const page = this.getGroupPage(this.lastGroupExpandIndex);

    if (page?.data) {
      return of(
        ODataResult.FromDataResult({
          data: page.data.slice(page.skip, page.skip + page.take),
          total: page.data.length,
        })
      );
    }
  }

  isGridGroupPagination(gridState: State, odataQuery: string): boolean {
    return (
      gridState.group?.length &&
      this.lastGroupExpandIndex &&
      !odataQuery.includes('$top') &&
      !odataQuery.includes('groupby((')
    );
  }
}
