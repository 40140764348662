/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line @nx/enforce-module-boundaries
import { isNullOrEmpty } from '@ups/xplat/utils';
import { BaseModel } from '../base.model';

export class AddressModelPascal extends BaseModel {
  Line1 = '';
  Line2 = '';
  City = '';
  State = '';
  Zip = '';
  Country = '';
  LastChangedDate: Date = null;

  constructor(o: unknown = null) {
    super(o);
    if (o) {
      // Due to some bugs camel case objects are sometimes passed to create pascal cased object. Here we deal with it.
      const camelAddress = <AddressModel>o;
      this.Line1 = isNullOrEmpty(camelAddress.line1)
        ? this.Line1
        : camelAddress.line1;
      this.Line2 = isNullOrEmpty(camelAddress.line2)
        ? this.Line2
        : camelAddress.line2;
      this.City = isNullOrEmpty(camelAddress.city)
        ? this.City
        : camelAddress.city;
      this.State = isNullOrEmpty(camelAddress.state)
        ? this.State
        : camelAddress.state;
      this.Zip = isNullOrEmpty(camelAddress.zip) ? this.Zip : camelAddress.zip;
      this.Country = isNullOrEmpty(camelAddress.country)
        ? this.Country
        : camelAddress.country;
      this.LastChangedDate = isNullOrEmpty(camelAddress.lastChangedDate)
        ? this.LastChangedDate
        : camelAddress.lastChangedDate;
    }
  }
}

export class AddressModel extends BaseModel {
  attention?: string;
  line1 = '';
  line2 = '';
  city = '';
  state = '';
  zip = '';
  country = '';
  lastChangedDate: Date = null;
  shippingTo?: string;

  constructor(o: unknown = null) {
    super(o);
    // Due to some bugs pascal case objects sometimes passed to create camel cased object. Here we deal with it.
    if (o) {
      const pascalAddress = <AddressModelPascal>o;
      this.line1 = isNullOrEmpty(pascalAddress.Line1)
        ? this.line1
        : pascalAddress.Line1;
      this.line2 = isNullOrEmpty(pascalAddress.Line2)
        ? this.line2
        : pascalAddress.Line2;
      this.city = isNullOrEmpty(pascalAddress.City)
        ? this.city
        : pascalAddress.City;
      this.state = isNullOrEmpty(pascalAddress.State)
        ? this.state
        : pascalAddress.State;
      this.zip = isNullOrEmpty(pascalAddress.Zip)
        ? this.zip
        : pascalAddress.Zip;
      this.country = isNullOrEmpty(pascalAddress.Country)
        ? this.country
        : pascalAddress.Country;
      this.lastChangedDate = isNullOrEmpty(pascalAddress.LastChangedDate)
        ? this.lastChangedDate
        : pascalAddress.LastChangedDate;
    }
  }
}
