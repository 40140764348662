import { IMCSOptions } from '../mcs.settings.options';
import { IMCSSearchColumnConfig } from '../mcs.settings.search-column';
import { IMCSOrderConfig } from '../mcs.settings.order';

export class ToODataQuery {
  /**
   * Creates an odata-query-string to get desired (filtered) items from the server via HTTP.
   *
   *  Params:
   *      search:                             the search phrase entered into the dropdown
   *      take:                               the number of items to limit result (falsy = take all results)
   *      additionalODataFilterExpression:    additional odata expression (including or/and operator) that will be appended to the filter part of the generated odata query
   */
  public static convert(searchOptions: IMCSOptions, searchColumnConfigs: IMCSSearchColumnConfig[], orderConfigs: IMCSOrderConfig[], search: string, take: number = undefined, additionalODataFilterExpression: string = undefined): string {
    const self = this;

    // get queqy phrases (words) -  NOTE: originally for some reason we also excluded spaces...
    const queryStringWords = (search || '')
      .split(searchOptions.wordSplitter)
      .filter((i) => ((i || '').trim() ? true : false))
      // escaping single quotes
      .map((i) => i.replace(/'/g, "''"))
      // encoding
      .map((i) => encodeURIComponent(i));

    if (!take) take = searchOptions.maximizeResultTo;

    // the odata query generation syntax:
    // ----------------------------------
    // w1 in c1 *columnJoin* w1 in c2 *columnJoin* ... *columnJoin* w1 in cn
    // *expressionJoin*
    // w2 in c1 *columnJoin* ...
    // ..
    // wn in c1 *columnJoin* ... wn in cn
    const columnJoin = searchOptions.matchInEveryColumn ? 'and' : 'or';
    const wordJoin = searchOptions.matchAllWords ? 'and' : 'or';

    //
    // generate odata query

    // get expression for each word
    const wordExpressionArray = queryStringWords.map((word) => {
      // skip empty words
      if ((word || '').trim() === '') return;

      // get expression for each column
      const columnExpressionArray = searchColumnConfigs.map((scc) => {
        const matchType = scc.matchStartsWith ? 'startsWith' : 'contains';
        const caseInsensitiveMatch = scc.matchCaseInsensitive;
        const convertToString = scc.convertToString;

        const columnExpression = convertToString ? `cast(${scc.field}, 'Edm.String')` : scc.field;

        return caseInsensitiveMatch ? `${matchType}(tolower(${columnExpression}), tolower('${word}'))` : `${matchType}(${columnExpression}, '${word}')`;
      });

      // merge column expressions
      let wordExpression = columnExpressionArray.reduce((prevValue, currValue, currIndex, array) => {
        return prevValue ? prevValue + ' ' + columnJoin + ' ' + currValue : currValue;
      }, '');

      // encapsulate multi column expressions into parenthesis
      if (columnExpressionArray.length > 1) wordExpression = '(' + wordExpression + ')';

      return wordExpression;
    });

    // merge word expressions
    let filter = wordExpressionArray.reduce((prevValue, currValue, currIndex, array) => {
      return prevValue ? prevValue + ' ' + wordJoin + ' ' + currValue : currValue;
    }, '');

    // extending filter with additional expressions
    let isMultiPartFilterExpression = wordExpressionArray.length > 1;

    if (additionalODataFilterExpression) {
      filter = isMultiPartFilterExpression ? '(' + filter + ')' + additionalODataFilterExpression : filter + additionalODataFilterExpression;

      // NOTE: now we got a multi-part expression (we need to set that so that other (upcoming) extension filters are properly added)
      isMultiPartFilterExpression = true;
    }

    // finalize filter expression
    if (filter !== '') filter = '$filter=' + filter;

    // order by
    const orderExpressions = orderConfigs.map((oc) => {
      return oc.dir.toLowerCase() === 'desc' ? oc.field + ' desc' : oc.field;
    });

    let order = orderExpressions.filter((i) => i).join(',');
    if (order != '') order = '$orderBy=' + order;

    const takeExpression = take ? '$top=' + take : '';

    // return query (join, but skip empty)
    const query = [filter, order, takeExpression].filter((i) => i).join('&');
    return query;
  }
}
