import { Injectable, NgZone, inject } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';

import { BehaviorSubject, of } from 'rxjs';
import { OfflineStorageService } from './offline-storage.service';
import { NetworkService } from './network.service';
import { LocalStorageService } from './storage.service';
import { catchError, delay, filter, take } from 'rxjs/operators';
import { LogService } from './log.service';
import { environment } from '../environments';
import { WindowService } from './window.service';
import { AuthService } from '@auth0/auth0-angular';
import {
  AppConstants,
  FeatureSwitch,
  StorageKeys,
  isPresent,
  storagePrefix,
} from '@ups/xplat/utils';
import { OfflineAPICallService } from './offline-apicall.service';
import { UserService } from '@ups/user';

/* eslint-disable*/
export interface OfflineTrackedRequestItem {
  date: number;
  url: string;
  body: any;
  method: string;
  params: any;
  headers: any;
  errorCount?: number;
}
export type OfflineTrackedRequests = Array<OfflineTrackedRequestItem>;

interface IStatusUpdates {
  pending: number;
  complete: number;
  errored: number;
}

// various endpoints which should never be tracked offline because they don't matter
const exclusionEndpoints = [`api/walkthrough/fetchWalkthroughDocumentsByPaths`];

/**
 * Offline data storage caching
 * Separate than standard StorageService since this has platform specific providers for larger data storage needs
 * Tracks PUT or POST requests made while user is offline
 * Auto retries them in order
 */
@Injectable({
  providedIn: 'root',
})
export class OfflineHttpTrackingService {
  key = `${storagePrefix}offlinePutOrPostQueue`;
  trackedRequests: OfflineTrackedRequests;
  private _wasOffline = false;
  private _retryQueueDelay = 1;
  private _retryQueueCount = 0;
  private _retryQueueLimit = 1;
  private _processingQueue = false;
  private _processingQueuePosition = 0;
  private _currentCompleteTotal = 0;
  private _currentErrorTotal = 0;
  statusUpdates$: BehaviorSubject<IStatusUpdates> = new BehaviorSubject({
    pending: 0,
    complete: 0,
    errored: 0,
  });

  offlineAPIService = inject(OfflineAPICallService);
  userService = inject(UserService);

  constructor(
    private auth: AuthService,
    private log: LogService,
    private ngZone: NgZone,
    private network: NetworkService,
    private offlineStorage: OfflineStorageService,
    private win: WindowService,
    private storage: LocalStorageService,
    private http: HttpClient
  ) {
    this.init();
  }

  getPendingOfflineRequests(fetchDelay = 1): Promise<OfflineTrackedRequests> {
    return new Promise((resolve) => {
      this.offlineStorage
        .getItem(this.key)
        .pipe(delay(fetchDelay), take(1))
        .subscribe((trackedRequests: OfflineTrackedRequests) => {
          resolve(trackedRequests || []);
        });
    });
  }

  trackRequest(req: HttpRequest<unknown>): void {
    if (this.network.isOffline && environment.offline?.enabled) {
      for (const endpoint of exclusionEndpoints) {
        if (req.url && req.url.indexOf(endpoint) > -1) {
          // ignore
          return;
        }
      }
      if (!this.trackedRequests) {
        this.trackedRequests = [];
      }
      if (
        (this.win.isBrowser || FeatureSwitch.enableMobileOfflineLimits) &&
        this.trackedRequests.length === this.network.offlineActionLimit
      ) {
        // user is limited to number of actions they can take offline
        if (!this.network.notifiedLimit) {
          this.network.notifiedLimit = true;
          this.win.alert(
            `You have reached the maximum number of actions you can take offline: ${this.network.offlineActionLimit}. Restore your network connection to make further changes.`
          );
        }
      } else {
        const headers = {};
        for (const key of req.headers.keys()) {
          if (key !== 'Authorization') {
            // never store auth key since it should always use latest auth bearer token when user is back online
            headers[key] = req.headers.get(key);
          }
        }
        const params = {};
        for (const key of req.params.keys()) {
          params[key] = req.params.get(key);
        }
        this.trackedRequests.push({
          date: Date.now(),
          url: req.urlWithParams,
          body: req.body,
          method: req.method,
          params,
          headers,
          errorCount: 0,
        });
        this.log.debug(
          'OfflineHttpTrackingService',
          `queued ${req.method} request with url:`,
          req.urlWithParams
        );
        this.offlineStorage
          .setItem(this.key, this.trackedRequests)
          .pipe(take(1))
          .subscribe();
      }
    }
  }

  removeRequest(item: OfflineTrackedRequestItem) {
    return new Promise<void>((resolve) => {
      const index = this.trackedRequests.findIndex((r) => {
        return (
          r.date === item.date && r.url === item.url && r.method === item.method
        );
      });
      if (index > -1) {
        this.trackedRequests.splice(index, 1);
        this.offlineStorage
          .setItem(this.key, this.trackedRequests)
          .pipe(take(1))
          .subscribe(() => {
            resolve();
          });
      }
    });
  }

  storeRequestOnAPI(trackedRequest: OfflineTrackedRequestItem, reason: string) {
    const { url, body, method, headers } = trackedRequest;
    return this.offlineAPIService.createOfflineAPICall({
      applicationId: environment.security.appId,
      userId: this.userService.myInfo.UserId,
      url,
      body: JSON.stringify(body),
      httpType: method,
      // params,
      headers: JSON.stringify(headers),
      application: environment.displayName,
      user: this.userService.myInfo.Data.FullName,
      reason,
      // errorCount: 0,
    });
  }

  completeRequestQueue() {
    if (!this.network.isOffline && environment.offline?.enabled) {
      this.getPendingOfflineRequests(this._retryQueueDelay).then(
        (trackedRequests: OfflineTrackedRequests) => {
          this.trackedRequests = trackedRequests.sort((a, b) =>
            a.date > b.date ? 1 : -1
          );

          const userSettingReconnectCompletePendingOffline =
            this.storage.getItem(StorageKeys.OFFLINE_COMPLETE_ON_RECONNECT);
          if (
            !isPresent(userSettingReconnectCompletePendingOffline) ||
            userSettingReconnectCompletePendingOffline
          ) {
            // increase the delay after first check
            this._retryQueueDelay = 2500;

            this.auth.isAuthenticated$
              .pipe(
                filter((isAuth) => !!isAuth),
                take(1)
              )
              .subscribe(() => {
                if (this.trackedRequests.length) {
                  this._processingQueue = true;
                  this._reportStatus();
                  const req =
                    this.trackedRequests[this._processingQueuePosition];
                  this.log.debug(
                    'OfflineHttpTrackingService',
                    'retrying request:',
                    req.url
                  );
                  if (this.win.isMobile) {
                    // ensure auth header is attached when attempting to finish api calls
                    // the mobile-auth0.interceptor does not engage with this http.request so handle auth token manually
                    if (AppConstants.userToken) {
                      req.headers = {
                        ...(req.headers || {}),
                        Authorization: `Bearer ${AppConstants.userToken}`,
                      };
                    }
                  }
                  this.http
                    .request(
                      new HttpRequest(req.method, req.url, req.body, {
                        headers: new HttpHeaders(req.headers),
                        params: new HttpParams({
                          fromObject: req.params,
                        }),
                      })
                    )
                    .pipe(
                      catchError((err) => {
                        this.log.debug(
                          'OfflineHttpTrackingService',
                          'retry request failed:',
                          err
                        );
                        return of(null);
                      })
                    )
                    .subscribe((res) => {
                      const handleError = () => {
                        this._currentErrorTotal++;
                        this._reportStatus();
                        req.errorCount++;
                        this.trackedRequests[this._processingQueuePosition] =
                          req;
                        this.offlineStorage
                          .setItem(this.key, this.trackedRequests)
                          .pipe(take(1))
                          .subscribe(() => {
                            // failed retry, bump queue position
                            this._processingQueuePosition++;
                            this._checkQueue();
                          });
                      };

                      if (res) {
                        if (res.type !== 0) {
                          this._currentCompleteTotal++;
                          // 0 type is OPTIONS call and should be ignored
                          // completed, remove from queue and continue trying until queue is empty
                          this.trackedRequests.splice(
                            this._processingQueuePosition,
                            1
                          );
                          // when a request had errored previously, deduct from error count since it now succeeded
                          if (req.errorCount) {
                            this._currentErrorTotal--;
                          }
                          this._reportStatus();
                          this.offlineStorage
                            .setItem(this.key, this.trackedRequests)
                            .pipe(take(1))
                            .subscribe(() => {
                              this._checkQueue();
                            });
                        } else {
                          if (this.network.isOffline) {
                            // user still offline
                            handleError();
                          }
                        }
                      } else {
                        handleError();
                      }
                    });
                } else {
                  // reset
                  this._queueCompleted();
                }
              });
          }
        }
      );
    }
  }

  private _reportStatus() {
    this.ngZone.run(() => {
      this.statusUpdates$.next({
        pending: this.trackedRequests.length,
        complete: this._currentCompleteTotal ? this._currentCompleteTotal : 0,
        errored: this._currentErrorTotal ? this._currentErrorTotal : 0,
      });
    });
  }

  private _checkQueue() {
    if (this.trackedRequests.length) {
      if (this.trackedRequests.length === this._processingQueuePosition) {
        // reached end of queue but requests remain due to failed requests in the queue, start over from beginning

        this.trackedRequests.forEach((item) => {
          this.storeRequestOnAPI(item, 'Request failed to complete').subscribe(
            () => console.log('Done!')
          );
        });

        this._processingQueuePosition = 0;
        this._retryQueueCount++;
      }
      if (this._retryQueueCount < this._retryQueueLimit) {
        // haven't reached queue retry limit yet, retry again
        this.completeRequestQueue();
      } else {
        // reset count for next time it triggers
        this._retryQueueCount = 0;
      }
    } else {
      this._queueCompleted();
    }
  }

  private _queueCompleted() {
    this._processingQueuePosition = 0;
    this._retryQueueCount = 0;
    this._currentCompleteTotal = 0;
    this._currentErrorTotal = 0;
    this._reportStatus();
    if (this._processingQueue) {
      this._processingQueue = false;
      // only log output if a queue had actually been processed to avoid any confusion
      this.log.debug('OfflineHttpTrackingService', 'Request queue complete.');
    }
  }

  init() {
    if (environment.offline?.enabled) {
      this.completeRequestQueue();
      this.network.offline$.subscribe((offline) => {
        if (this._wasOffline && !offline) {
          // only trigger completing request queue when coming back online from previously being offline
          this._currentCompleteTotal = 0;
          this._currentErrorTotal = 0;
          this.completeRequestQueue();
        }
        this._wasOffline = offline;
      });
    }
  }
}
