import { Component } from '@angular/core';
import { EventBusService, WindowService } from '@ups/xplat/core';

import { InfoOverlayBaseComponent } from '@ups/xplat/features';

@Component({
  selector: 'ups-info-overlay',
  templateUrl: 'info-overlay.component.html',
  styleUrls: ['info-overlay.component.scss'],
})
export class InfoOverlayComponent extends InfoOverlayBaseComponent {
  constructor(win: WindowService, eventBus: EventBusService) {
    super(win, eventBus);
  }

  didShow() {
    if (this.win.isBrowser) {
      (<HTMLDivElement>(
        document.getElementsByClassName('content')[0]
      )).style.paddingBottom = '60px';
    }
  }

  didHide() {
    if (this.win.isBrowser) {
      (<HTMLDivElement>(
        document.getElementsByClassName('content')[0]
      )).style.paddingBottom = '0px';
    }
  }
}
