/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Optional,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { UntypedFormControl } from '@angular/forms';

import { ReplaySubject, of } from 'rxjs';

import { UUID } from 'angular2-uuid';

import { GridComponent } from '@progress/kendo-angular-grid';

import { StateUtils } from './../../Data-Query/state-utils';

import { PathSegment } from './../Group-Result/path-segment';
import { GroupResultUtils } from './../Group-Result/group-result-utils';
import { GroupResultSelectionSupport } from '../Group-Result/group-result-selection-support';

import { GroupCollapseExpandUtils } from './Utils/group-collapse-expand-utils';

import { GroupingExDirective } from './grouping-ex.directive';
import {
  IGroupingExSelectionChangeArgs,
  GroupingExSelectionDirective,
} from './grouping-ex.selection.directive';
import { GroupingExLoad, GroupingExLoadResult } from './grouping-ex.load';

/*
A header component to allow kendo looking checkbox selection on groups + also allow setting indeterminate state and getting proper value back.

Indeterminate state is not necessary for items, just for groups.
The component is responsible for: triggering load of the entire sub-tree (if group checked and some data missing) & altering group check state of the hierarchical grid-data.
The extension (to have the selection state appended to items) is still done via the GroupingExSelectionDirective.

    Params:

        dataItem                - the group header dataItem associated with the control
        showLabel               - boolean = true - show stylized field / value label
        showGroupSelect         - boolean = true - show group select checkbox
        (groupSelectChecked)    - when checkbox is checked, it emits a { checkboxValue: boolean | null, dataItem: any }

 */
@Component({
  selector: 'ggx-group-selection-header',
  template: `
    <span *ngIf="showGroupSelect">
      <input
        #checkbox
        id="{{ uuid }}"
        class="k-checkbox"
        attr.name="{{ uuid }}"
        type="checkbox"
        [formControl]="checkboxForm"
        [ngModel]="dataItem ? dataItem[selectionStateProperty]?.checked : false"
        (ngModelChange)="internalGroupCheckedValueChange($event)"
        (click)="
          internalGroupSelectionClick({
            checked: $event.target.checked,
            dataItem: dataItem
          })
        "
      />
      <label for="{{ uuid }}" class="k-checkbox-label">&nbsp;</label>
    </span>
    <span *ngIf="showLabel" style="font-weight: initial">
      {{ fieldName ? fieldName : dataItem.field }}: @if(isDate) {
      <strong>{{ dataItem.value | date: 'MM/dd/yyyy' }}</strong>
      } @else {
      <strong>{{ dataItem.value }}</strong>
      }

      <span *ngIf="showCount">{{ ' (' + dataItem.count }})</span>
    </span>
  `,
})
export class GroupingExSelectionGroupHeaderComponent
  implements AfterViewInit, OnChanges
{
  selectionStateProperty: string =
    GroupResultSelectionSupport.selectionStateProperty;

  @Input() public showLabel = true;
  @Input() public showCount = false;
  @Input() public fieldName: string = undefined;
  @Input() public showGroupSelect = true;
  @Input() public expand = true;

  @Input() public dataItem: any;

  @Output() groupSelectChecked: EventEmitter<IGroupingExSelectionChangeArgs> =
    new EventEmitter<IGroupingExSelectionChangeArgs>();

  uuid: UUID = UUID.UUID();

  isDate = false;
  // NOTE: for some reason, the ngModelChange) event is not raised if we don't have a FormControl declaration for it (weird)
  checkboxForm: UntypedFormControl = new UntypedFormControl();

  // NOTE: as checkboxRef (which is needed to set state) will be avail just after init, we need to postpone initial setting to that point and for that we use ReplaySubject
  @ViewChild('checkbox', { static: false }) checkboxRef: ElementRef;
  internalCheckboxValueChanged$: ReplaySubject<boolean | null> =
    new ReplaySubject<boolean | null>();

  // NOTE: probably redundant...
  private groupCheckedValue: boolean | null;

  constructor(
    private renderer2: Renderer2,
    @Optional() private gridComponent: GridComponent,
    @Optional() private groupingExDirective: GroupingExDirective,
    @Optional()
    private groupingExSelectionSupportDirective: GroupingExSelectionDirective
  ) {
    if (!gridComponent)
      throw new Error(
        'The GroupingExSelectionGroupHeaderComponent has to be used inside a GridComponent!'
      );

    if (!groupingExDirective)
      throw new Error(
        'The GroupingExSelectionGroupHeaderComponent needs the GroupingExDirective extension on the GridComponent!'
      );

    if (!groupingExSelectionSupportDirective)
      throw new Error(
        'The GroupingExSelectionDirective needs the GroupingExSelectionDirective extension on the GridComponent!'
      );
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataItem'] && changes['dataItem'].currentValue) {
      if (changes['dataItem'].currentValue.value instanceof Date) {
        this.isDate = true;
      } else {
        this.isDate = false;
      }
    }
  }

  internalGroupCheckedValueChange(value: boolean | null) {
    // refresh checkbox UI state - need to do via ReplaySubject 'cause checkboxRef is set just aft. onAfterInit, while event is raised before...
    this.internalCheckboxValueChanged$.next(value);
  }

  internalGroupSelectionClick(args: IGroupingExSelectionChangeArgs) {
    const groupDataItem = args.dataItem;
    const groupDataItemChecked = args.checked;

    const mergedGridState = this.groupingExDirective.getModdedState(
      StateUtils.getStateFromGrid(this.gridComponent)
    );

    // get path (here we could use the groupIndex as well, if we supply that info to events as well)
    const gridData = <any>this.gridComponent.data;
    const groupDataItemPath =
      PathSegment.getPathToDataItem(gridData.data, groupDataItem) || [];

    // check if we need to load...and once data is present, alter the checked-states
    const anySubNodeWithoutItems = GroupResultUtils.anyUnloadedItemsBelowPath(
      gridData.data,
      groupDataItemPath
    );

    // raise our event
    this.groupSelectChecked.emit(args);

    const localObservable =
      args.checked && anySubNodeWithoutItems
        ? GroupingExLoad.loadLevelsBelow(
            this.groupingExDirective.viewModel,
            this.gridComponent,
            mergedGridState,
            groupDataItem
          )
        : of(new GroupingExLoadResult());

    // load
    this.gridComponent.loading = true;

    localObservable.subscribe((data: GroupingExLoadResult) => {
      // when new data was loaded
      if (data) {
        // NOTE:
        // data item extension (with selection object)) was done via load and the data loaded event emitter handler via the selection directive!
        this.groupingExDirective.raiseDataLoadedEvent(data);

        GroupingExLoad.mergeLoadLevelsBelowResultToGrid(
          this.groupingExDirective.viewModel,
          this.gridComponent,
          mergedGridState,
          groupDataItem,
          data
        );
      }

      // selection change...
      GroupResultSelectionSupport.handleItemChecked(
        gridData.data,
        groupDataItemPath,
        groupDataItemChecked
      );

      // expansion (only when checked)
      if (groupDataItemChecked)
        GroupCollapseExpandUtils.expandSubtree(
          this.gridComponent,
          groupDataItem,
          this.expand
        );

      // NOTE:
      // There's actually no item selection changed via an UI event, we have just altered selection state properties!
      // So it's questionable if we are allowed to call/raise a selection changed event via self.groupingExSelectionSupportDirective.raiseSelectionChangedEvent.
      //
      // Let's use this approach: as there was no UI change, lets call the given event, but pass in no dataItems!
      const specArgs = {
        checked: args.checked,
        dataItem: undefined,
      } as IGroupingExSelectionChangeArgs;

      this.groupingExSelectionSupportDirective.raiseSelectionChangedEvent(
        specArgs
      );

      // finish loading prgress
      this.gridComponent.loading = false;
    });
  }

  ngAfterViewInit(): void {
    this.internalCheckboxValueChanged$.subscribe((value) => {
      if (this.showGroupSelect) {
        if (value == null) {
          this.renderer2.setProperty(
            this.checkboxRef.nativeElement,
            'indeterminate',
            true
          );
        } else if (value) {
          this.renderer2.setProperty(
            this.checkboxRef.nativeElement,
            'indeterminate',
            false
          );
          this.renderer2.setProperty(
            this.checkboxRef.nativeElement,
            'checked',
            true
          );
        } else {
          this.renderer2.setProperty(
            this.checkboxRef.nativeElement,
            'indeterminate',
            false
          );
          this.renderer2.setProperty(
            this.checkboxRef.nativeElement,
            'checked',
            false
          );
        }
      }
    });
  }
}
