import { Injectable } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { SecurityService, ViewFeatureExpression } from '@ups/security';
import { IMenuService, IMenuItem } from './menu';

@Injectable({
  providedIn: 'root',
})
export class MenuService implements IMenuService {
  // manually configured menu
  staticMenuConfig?: Array<IMenuItem>;

  /** menu items resolved for UI */
  public menuItems: IMenuItem[];

  /** menu item configuration with expressions for visible/enabled */
  protected configItems: Array<IMenuItem>;

  constructor(private router: Router, public security: SecurityService) {
    this.security.permissionsChanged$.subscribe(() => {
      this.configureMenu();
    });
  }

  public configureMenu(
    configItems?: Array<IMenuItem>,
    maxDepth?: number,
    skipParseInternal?: boolean
  ) {
    if (configItems) this.configItems = configItems;

    this.menuItems = this.parse(this.configItems, maxDepth, skipParseInternal);
    // console.log('this.menuItems:', this.menuItems)
  }

  //
  // Functions to set up internal (UX) menu data-structure
  //

  //
  // Routing related parsing
  //
  public fetchFromRoutes(): Array<IMenuItem> {
    const menuConfig = this.fetchFromRoutesInternal(this.router.config);
    return menuConfig;
  }

  fetchFromRoutesInternal(routes: Routes): Array<IMenuItem> {
    const result = [] as Array<IMenuItem>;

    for (const route of routes) {
      // skip fallback route
      if (route.path === '**') continue;

      // prepare current item
      const hasStaticRoutes = route.children && route.children.length;
      const hasDynamicRoutes =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (route as any)._loadedConfig &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (route as any)._loadedConfig.routes.length;

      const thisMenuItem = {
        path: route.path,
        exact: route.pathMatch === 'full',

        index: (route.data || {}).index || -1,

        title: (route.data || {}).title,

        icon: (route.data || {}).icon,

        disableRouting: (route.data || {}).disableRouting,

        visible: (route.data || {}).visible,
        enabled: (route.data || {}).enabled,

        children:
          (hasStaticRoutes || hasDynamicRoutes) && route.path !== ''
            ? ([] as Array<IMenuItem>)
            : undefined,
      } as IMenuItem;

      // PARSE STATIC ROUTES
      if (hasStaticRoutes) {
        const childMenuItems = this.fetchFromRoutesInternal(route.children);

        // note: don't create nesting for empty containers
        if (route.path === '') result.push(...childMenuItems);
        else thisMenuItem.children.push(...childMenuItems);
      }

      // PARSE DYNAMICALLY LOADED MODULE ROUTES
      if (hasDynamicRoutes) {
        const childMenuItems = this.fetchFromRoutesInternal(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (route as any)._loadedConfig.routes
        );

        // note: don't create nesting for empty containers
        if (route.path === '') result.push(...childMenuItems);
        else thisMenuItem.children.push(...childMenuItems);
      }

      // skip empty route
      if (route.path !== '') result.push(thisMenuItem);
    }

    // sort routes by index
    const sortedResult = result.sort((l, r) => {
      if (l.index === -1 && r.index === -1) return 0;
      // note: for asc. order (-1 treated as Infinity)
      if (l.index === -1) return 1;
      if (r.index === -1) return -1;
      return l.index - r.index;
    });

    return sortedResult;
  }

  //
  // UI related functions and properties
  //

  public getMenuForNode(path: string[], maxDepth?: number): IMenuItem[] {
    let menuItems = JSON.parse(JSON.stringify(this.menuItems));

    path.forEach((element) => {
      const node = menuItems.find((n) => n.path === element);
      if (!node)
        throw new Error(`Path '${path.join(', ')}' was not fond on menu!`);
      if (!node.children && !node.children.length)
        throw new Error(`Path '${path.join(', ')}' has no sub-nodes!`);

      menuItems = node.children;
    });

    if (maxDepth) this.limitDepth(menuItems, maxDepth);

    return menuItems;
  }

  /** @deprecated */
  public hasActiveUrl(menuItem: IMenuItem, checkChildren = false): boolean {
    return this.isMenuItemActive(menuItem, checkChildren);
    /*
    if (this.router.isActive(menuItem.url, menuItem.exact))
      return true;

    if (checkChildren && menuItem.children) {
      for (const subMenuItem of menuItem.children) {
        const result = this.hasActiveUrl(subMenuItem);
        if (result) return true;
      }
    }

    return false;
    */
  }

  public isMenuItemActive(menuItem: IMenuItem, checkChildren = false): boolean {
    // todo: consider way to properly encode (encodeURIComponent will not work right here) - considering routes with parentheses in various params
    if (
      this.router.isActive(
        menuItem.url
          ? menuItem.url.replace('(', '%28').replace(')', '%29')
          : '',
        true
      )
    )
      return true;

    if (checkChildren && menuItem.children) {
      for (const subMenuItem of menuItem.children) {
        const result = this.isMenuItemActive(subMenuItem);
        if (result) return true;
      }
    }

    return false;
  }

  public getMenuItemToActiveUrl(
    menuItems?: IMenuItem[],
    checkChildren = true
  ): IMenuItem {
    if (!menuItems) menuItems = this.menuItems;

    for (const menuItem of menuItems) {
      const urlMatches = this.router.isActive(menuItem.url, menuItem.exact);
      if (urlMatches) return menuItem;

      if (checkChildren && menuItem.children) {
        const result = this.getMenuItemToActiveUrl(menuItem.children);
        if (result) return result;
      }
    }

    return undefined;
  }

  protected parse(
    configItems: Array<IMenuItem>,
    maxDepth?: number,
    skipParseInternal?: boolean
  ): IMenuItem[] {
    const menuItems = skipParseInternal
      ? <Array<IMenuItem>>configItems
      : this.parseInternal(configItems).filter(
          (x) => !(x.disableRouting && x.children.length === 0)
        );
    if (maxDepth) this.limitDepth(menuItems, maxDepth);
    return menuItems;
  }

  protected parseInternal(
    configItems: Array<IMenuItem>,
    parentUrl?: string
  ): IMenuItem[] {
    if (configItems == null || configItems === undefined)
      return [] as IMenuItem[];

    const thisNodeMenuItems: IMenuItem[] = [];

    for (const configItem of configItems) {
      const menuItem = JSON.parse(JSON.stringify(configItem)) as IMenuItem;
      menuItem.url =
        (parentUrl || '/') +
        (parentUrl && configItem.path ? '/' : '') +
        (configItem.path || '');

      menuItem.title = configItem.title || configItem.path;

      menuItem.visible = ViewFeatureExpression.evaluate(
        configItem.visible,
        this.security
      );
      menuItem.enabled = ViewFeatureExpression.evaluate(
        configItem.enabled,
        this.security
      );

      menuItem.children = [] as IMenuItem[];

      if (configItem.children && configItem.children.length) {
        menuItem.children = this.parseInternal(
          configItem.children,
          menuItem.url
        );
      }

      if (menuItem.visible) {
        thisNodeMenuItems.push(menuItem);
      }
    }

    return thisNodeMenuItems;
  }

  protected limitDepth(menuItems: IMenuItem[], maxDepth: number): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    menuItems.forEach((item) => {
      if (maxDepth === 1) item.children = [];
      else if (item.children && item.children.length)
        self.limitDepth(item.children, maxDepth - 1);
    });
  }
}
