import { NgModule } from '@angular/core';
import { FormsModule as SharedFormsModule } from '@ups/xplat/features';
import { UIModule } from '../ui/ui.module';
import { FORMS_COMPONENTS } from './components';

@NgModule({
  imports: [SharedFormsModule, UIModule],
  declarations: [...FORMS_COMPONENTS],
  exports: [...FORMS_COMPONENTS],
})
export class FormsModule {}
