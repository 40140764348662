import { Component } from '@angular/core';
import { DynamicItemBaseComponent } from '@ups/xplat/features';
import { MessageHelper } from '@ups/xplat/web/core';

@Component({
  selector: 'ups-dynamic-copy-link-button',
  styleUrls: ['dynamic-copy-link-button.component.scss'],
  templateUrl: 'dynamic-copy-link-button.component.html',
})
export class DynamicCopyLinkButtonComponent extends DynamicItemBaseComponent {
  constructor(private msgHelper: MessageHelper) {
    super();
  }

  copyLink() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? ':' + window.location.port : ''
    }/${this.config.options.formPath}`;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.msgHelper.success('Form Link has been copied to the Clipboard.');
  }
}
