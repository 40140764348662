<div
  #dynamicComponent
  class="dynamic-field-container"
  [class.selected]="config?.selected"
>
  <div class="dynamic-field form-input text-align">
    <div>
      <label>Comments</label>
      <p>
        Comments serve the purpose of allowing HSE Personnel and Field employees
        the ability to collaborate on form submissions
      </p>
      <div *ngFor="let item of comments; trackBy: trackByFn">
        <div class="dynamic-field form-input comment-reply-item">
          <div class="reply-by" [innerHTML]="item?.createdBy"></div>
          <div class="reply-text" [innerHTML]="item.text"></div>
          <div
            class="reply-timestamp"
            innerHTML="At {{ item.createdAt | date: 'medium' }}"
          ></div>
          <button
            class="k-button reply-btn"
            [hidden]="isReplyingTo"
            (click)="replyToComment(item?.id)"
          >
            Reply
          </button>
        </div>

        <div *ngFor="let child of item?.comments || []; trackBy: trackByFn">
          <div class="dynamic-field form-input comment-reply-inner-item">
            <div class="reply-by" [innerHTML]="child?.createdBy"></div>
            <div class="reply-text" [innerHTML]="child.text"></div>
            <div
              class="reply-timestamp"
              innerHTML="At {{ child.createdAt | date: 'medium' }}"
            ></div>
            <button
              class="k-button reply-btn"
              [hidden]="isReplyingTo"
              (click)="replyToComment(child?.id)"
            >
              Reply
            </button>
          </div>

          <div
            *ngFor="let grand of child?.comments || []; trackBy: trackByFn"
            class="dynamic-field form-input comment-reply-inner-item inner"
          >
            <div class="reply-by" [innerHTML]="grand?.createdBy"></div>
            <div class="reply-text" [innerHTML]="grand.text"></div>
            <div
              class="reply-timestamp"
              innerHTML="At {{ grand.createdAt | date: 'medium' }}"
            ></div>
            <!-- <button class="k-button reply-btn" [hidden]="isReplyingTo" (click)="replyToComment(grand?.id)">Reply</button> -->
          </div>
        </div>
      </div>
    </div>

    <ng-template [ngIf]="isCommenting">
      <div
        class="replying-to-container"
        *ngIf="isReplyingTo && isReplyingTo.text"
      >
        <div
          class="replying-to-title"
          innerHTML="Replying to  {{ isReplyingTo.createdBy }}"
        ></div>
        <div class="replying-to-text" [innerHTML]="isReplyingTo.text"></div>
      </div>
      <hr />

      <textarea
        #commentInput
        (itemSelected)="itemSelected($event)"
        (searchTerm)="searchChange($event)"
        [mentionListTemplate]="mentionListTemplate"
        [mentionConfig]="mentionConfig"
        class="k-textbox"
        [attr.minLength]="3"
        [attr.required]="true"
        [attr.name]="comment"
        [(ngModel)]="comment"
      ></textarea>
      <div class="dynamic-field form-button">
        <button
          class="k-button"
          style="width: 50%"
          (click)="showCancelConfirm = true"
        >
          Cancel
        </button>
        <button
          class="k-button k-primary"
          style="width: 50%"
          [disabled]="!isValid()"
          (click)="saveComment()"
        >
          Save
        </button>
      </div>
    </ng-template>

    <ng-template [ngIf]="!isCommenting">
      <button class="k-button k-primary mt-5" (click)="enableCommenting()">
        Add Comment
      </button>
    </ng-template>

    <div
      class="form-builder-mask"
      [class.hidden]="config?.options?.hidden"
      *ngIf="config?.isFormBuilder"
    ></div>
  </div>
  <div class="form-builder-options" *ngIf="config?.isFormBuilder">
    <i class="fas fa-times ms-3 cursor-pointer c-red" (click)="delete()"></i>
    <!-- <i class="fas fa-bars ms-3 cursor-grab"></i> -->
  </div>
</div>

<kendo-dialog modal fade [hidden]="!showCancelConfirm" [width]="550">
  <kendo-dialog-titlebar>
    <span>Cancel Comment</span>
  </kendo-dialog-titlebar>

  <p>Do you really want to Cancel Commenting?</p>

  <kendo-dialog-actions>
    <button class="k-button k-primary" (click)="cancelComment()">
      Yes, Cancel Commenting
    </button>
    <button class="k-button" (click)="showCancelConfirm = false">
      No, Continue Commenting
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<ng-template #mentionListTemplate let-item="item">
  <div style="display: inline-flex">
    <div>
      <span *ngIf="item.Name" class="job-name link-style">
        <label>{{ item?.Name }}</label>
      </span>
      <span *ngIf="item?.RoleName" class="job-name link-style">
        <label>{{ item?.RoleName }}</label>
      </span>
      <div *ngIf="item?.CellPhone" class="clearBoth">
        <div style="display: inline-block; width: 120px">
          <label>Cell Phone:</label>
        </div>
        <div style="display: inline-block">
          <label>{{ item?.CellPhone }}</label>
        </div>
      </div>
      <div *ngIf="item?.EMDesignationName" class="clearBoth">
        <div style="display: inline-block; width: 120px">
          <label>EM Designation:</label>
        </div>
        <div style="display: inline-block">
          <label>{{ item?.EMDesignationName || 'n/a' }}</label>
        </div>
      </div>
      <div *ngIf="item?.FileClassName" class="clearBoth">
        <div style="display: inline-block; width: 120px">
          <label>File Class:</label>
        </div>
        <div style="display: inline-block">
          <label>{{ item?.FileClassName || 'n/a' }}</label>
        </div>
      </div>
      <div *ngIf="item?.Company" class="clearBoth">
        <div style="display: inline-block; width: 120px">
          <label>Company:</label>
        </div>
        <div style="display: inline-block">
          <label>{{ item?.Company || 'n/a' }}</label>
        </div>
      </div>
    </div>
  </div>
</ng-template>
