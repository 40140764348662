import { Injectable } from '@angular/core';
import { ApplicationModel } from '@ups/xplat/api/dto';
import { lastValueFrom } from 'rxjs';
import { EquipmentService } from './equipment-service';

@Injectable({
  providedIn: 'root',
})
export class EquipmentDispatchStatusResolver {
  constructor(
    private equipmentService: EquipmentService,
    private applicationModel: ApplicationModel
  ) {}

  load(): Promise<any> {
    if (this.applicationModel.equipmentDispatchStatuses.length === 0) {
      return lastValueFrom(
        this.equipmentService.getEquipmentDispatchStatuses()
      ).then((data) => {
        this.applicationModel.equipmentDispatchStatuses = data;
      });
    } else {
      return Promise.resolve(this.applicationModel.equipmentDispatchStatuses);
    }
  }
}
