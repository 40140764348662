import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NgMutationObserver {
  domNodeRemoved$: Observable<Node>;
  private domNodeRemovedSubject = new Subject<Node>();
  private mutationObserver = new MutationObserver((ev) => {
    ev.forEach((record) => {
      if (record.type === 'childList') {
        record.removedNodes.forEach((n) => {
          this.domNodeRemovedSubject.next(n);
        });
      }
    });
  });

  constructor() {
    this.domNodeRemoved$ = this.domNodeRemovedSubject.asObservable();
    this.mutationObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });
  }
}
