<kendo-dialog modal fade [hidden]="!opened" (close)="close()" [width]="550">
  <kendo-dialog-titlebar>
    <span *ngIf="model.bActive && canModify">
      {{ (isEdit ? 'Edit ' : 'Add ') + 'Dispatch Status' }}
    </span>
    <span *ngIf="!model.bActive || !canModify">Dispatch Status Detail</span>
  </kendo-dialog-titlebar>

  <div>
    <div class="form-group row">
      <label class="col-form-label col-md-4">Name</label>
      <div class="col-md-8">
        <input
          type="text"
          class="k-textbox text-input form-control"
          name="name"
          required
          maxlength="40"
          [disabled]="!model.bActive || !canModify || model.isSystemDependent"
          [(ngModel)]="model.Name"
        />
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-md-4">Dispatch Group</label>
      <div class="col-md-8">
        <kendo-combobox
          class="w-300"
          name="dispatchGroup"
          [(ngModel)]="model.DispatchGroupID"
          [valuePrimitive]="true"
          [textField]="'Name'"
          [valueField]="'DispatchGroupID'"
          [filterable]="false"
          [placeholder]="'Select Dispatch Group'"
          [data]="applicationModel.dispatchGroups"
        ></kendo-combobox>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-md-4">Is Final</label>
      <div class="col-md-3" style="padding-top: 7px">
        <label class="k-form-field">
          <input
            type="checkbox"
            id="isFinalStatus"
            class="k-checkbox"
            [(ngModel)]="model.isFinalStatus"
            [disabled]="!canModify"
          />
          <label class="k-checkbox-label" for="isFinalStatus"></label>
        </label>
      </div>
    </div>
  </div>

  <kendo-dialog-actions [attr.disabled]="isSaving ? '' : null">
    <button class="k-button" (click)="close()">
      {{ model.bActive && canModify ? 'Cancel' : 'Close' }}
    </button>
    <button
      *ngIf="canModify"
      class="k-button k-primary"
      [disabled]="!isValid()"
      (click)="onSave($event)"
    >
      <span *ngIf="!isSaving">Save</span>
      <span *ngIf="isSaving">
        <i class="fa fa-spinner fa-spin"></i>
        Saving...
      </span>
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog modal fade [hidden]="!showConfirm" [width]="550">
  <kendo-dialog-titlebar>
    <span>Unsaved Changes</span>
  </kendo-dialog-titlebar>

  <p>Do you really want to Cancel Unsaved Changes?</p>

  <kendo-dialog-actions>
    <button class="k-button" (click)="showConfirm = false; opened = false">
      Yes, Cancel Changes
    </button>
    <button class="k-button" (click)="showConfirm = false">
      No, Return to Edit
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
