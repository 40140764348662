import * as _ from 'lodash';

import { GridComponent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';

export class StateUtils {
  public static getStateFromGrid(grid: GridComponent): State {
    const gridState = {
      skip: grid.skip,
      take: grid.pageSize,
      sort: grid.sort,
      filter: grid.filter,
      group: grid.group,
    } as State;

    return gridState;
  }

  public static copyStateFromGrid(grid: GridComponent): State {
    const gridState = StateUtils.getStateFromGrid(grid);
    const stateCopy = _.cloneDeep(gridState) as State;
    return stateCopy;
  }
}
