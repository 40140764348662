<div class="d-flex justify-content-end mb-7 w-100">
  <button
    *ngIf="isEditable && !isEditMode"
    id="editNotesBtn"
    help-anchor="editEquipmentNotesTabButton"
    type="button"
    class="k-button"
    (click)="editNotes()"
  >
    Edit
  </button>
  <button
    *ngIf="isEditMode"
    id="editNotesSaveBtn"
    help-anchor="saveButton"
    type="button"
    class="k-button k-primary me-3"
    (click)="saveNotes()"
  >
    Save
  </button>
  <button
    *ngIf="isEditMode"
    id="editNotesCancelBtn"
    type="button"
    class="k-button"
    (click)="cancelNotes()"
  >
    Cancel
  </button>
</div>
<div *ngIf="isEditMode" class="form-group">
  <label for="addNotesTextarea">Add Notes:</label>
  <textarea
    class="form-control rounded-0"
    id="addNotesTextarea"
    help-anchor="addNotesField"
    name="newNotes"
    [(ngModel)]="newNotes"
    rows="2"
  ></textarea>
</div>
<div class="form-group">
  <label *ngIf="isEditMode" for="exampleFormControlTextarea1">
    Previous Notes:
  </label>
  <textarea
    class="form-control rounded-0"
    id="exampleFormControlTextarea1"
    rows="10"
    readonly
    >{{ notes }}</textarea
  >
</div>
