import { NearmissComponent } from './nearmiss/nearmiss.component';

import { StopWorkAuthorityComponent } from './stop-work-authority/stop-work-authority.component';

import { HazardCardComponent } from './hazard-card/hazard-card.component';

export const SAFETY_COMPONENTS = [
  NearmissComponent,
  StopWorkAuthorityComponent,
  HazardCardComponent,
];

export * from './nearmiss/nearmiss.component';
export * from './stop-work-authority/stop-work-authority.component';
export * from './hazard-card/hazard-card.component';
