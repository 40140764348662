import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import {
  CompositeFilterDescriptor,
  filterBy,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { FilterService } from '@progress/kendo-angular-grid';
import { SortFilterItemsService } from '../../sort-filter-items.service';

import {
  patchToUiCollectionFilter,
  fetchSimpleCompositeFilterFromUiCollectionFilter,
} from '../../collection-filter-utils';
import { GridCheckboxFilterMenuUIService } from './grid-checkbox-filter-menu.ui-service';

interface SearchOption {
  text: string;
  value: string;
}

/**
 * https://www.telerik.com/kendo-angular-ui/components/grid/filtering/reusable-filter/ + refactor and renaming was done
 */
@Component({
  selector: 'grid-checkbox-filter-menu',
  templateUrl: 'grid-checkbox-filter-menu.component.html',
  styleUrls: ['./grid-checkbox-filter-menu.component.scss'],
})
export class CheckBoxFilterMenuComponent implements AfterViewInit {
  public static blankFilterText = 'Blank';
  public static blankFilterValue = 'BlankValue'; // TODO: prefix with something $$
  public static notBlankFilterText = 'Not Blank';
  public static notBlankFilterValue = 'NotBlankValue'; // TODO: prefix with something $$

  public filteredDataItems: unknown[] = [];
  public dataItemFilterText = '';

  public dataItemSearchOptions: SearchOption[] = [
    { text: 'Contains', value: 'contains' },
    { text: 'Does not contain', value: 'doesnotcontain' },
    { text: 'Starts with', value: 'startswith' },
    { text: 'Ends wit', value: 'endswith' },
    { text: 'Is equal to', value: 'isequalto' },
    { text: 'Is not equal to', value: 'isnotequalto' },
  ];

  @Input() public height = 200;
  @Input() public isLoadingFilterData = false;

  @Input() public isPrimitive: boolean;
  @Input() public textField: string;
  @Input() public valueField: string;

  @Input() public currentFilter: CompositeFilterDescriptor;
  @Input() public filterService: FilterService;

  @Input() public field: string;
  @Input() public operator = 'eq'; // NOTE: originally it was contains, but it has to be eq for checkbox selection!

  @Input() public compositeLogic: 'or' | 'and' = 'or';

  @Input() public collectionField: string;
  @Input() public collectionRef: string;
  @Input() public collectionItemField: string;
  @Input() public collectionOperator: 'any' | 'all' = 'any';

  @Input() public isSingleSelection = false;
  @Input() public alwaysShowSelectedItems = true; // NOTE: should be true by def.
  @Input() public keepExtraFilterValues = false;

  @Input() public showFilter = true;
  @Input() public showFilterOperators = false;

  @Input() public addSelectAllOption = false;

  @Input() public addBlankOption = false;
  @Input() public customBlankOptionText: string;

  @Input() public dataItemSearchOperator = 'contains';

  @Input() public isGuidValue = false;

  /** selected item's value fields */
  value: unknown[] = [];

  /** NOT USED ANYWHERE */
  @Output() public valueChange = new EventEmitter<number[]>();

  public _data: unknown[];

  /** Items that are shown in the checkbox filter */
  @Input() public set data(items: unknown[]) {
    if (!this.isLoadingFilterData && !this.isSingleSelection) {
      this._data = this.sortData(items);
    } else {
      this._data = items;
    }
    this.filteredDataItems = this.data || [];
    this.dataItemFilterTextChange(this.dataItemFilterText);
  }

  // the template gets the list of checkboxes from here
  public get data() {
    let data = this._data ? [...this._data] : undefined;
    if (this.addBlankOption && data) {
      data = this.insertBlankOption(data);
    }
    return data;
  }

  constructor(
    private gridCheckboxFilterMenuUIService: GridCheckboxFilterMenuUIService,
    private sortService: SortFilterItemsService
  ) {}

  public ngAfterViewInit() {
    // at this point the currentFilter (related to the field to which UI is applied) gets set by the grid, so here we can parse checked values
    const valuesFromAssignedFilter = this.parseValueFromFilter(
      this.currentFilter
    );
    this.value = (this.value || []).concat(...valuesFromAssignedFilter);
  }

  public insertBlankOption(data: unknown[]): unknown[] {
    // the list of options should only include a 'Blank" option when the column
    // contains blank rows
    if (this.containsBlankRows(data)) {
      // we found a blank row, but we won't need it in the checkbox list
      // because we are replacing it with a 'Blank' option, so the next line
      // removes the blank row
      data = this.removeBlankRow(data);
      // now insert the 'Blank' option to the list
      let blankItem: unknown;
      if (this.isPrimitive) {
        blankItem =
          this.customBlankOptionText ||
          CheckBoxFilterMenuComponent.blankFilterText;
      } else {
        blankItem = {};
        blankItem[this.valueField] =
          CheckBoxFilterMenuComponent.blankFilterValue;
        blankItem[this.textField] =
          this.customBlankOptionText ||
          CheckBoxFilterMenuComponent.blankFilterText;
      }
      data.unshift(blankItem);
    }
    return data;
  }

  public processFiltering() {
    const selectedValues = this.keepExtraFilterValues
      ? this.value
      : this.filterValidSelections(this.value);

    if (!selectedValues || !selectedValues.length) {
      const emptyFilter = {
        logic: 'or',
        filters: [],
      } as CompositeFilterDescriptor;
      this.filterService.filter(emptyFilter);
      return;
    }

    const compositeFilter = {
      logic: this.compositeLogic,
      filters: selectedValues?.map((v) => {
        // regular filter with select blank or not blank or values
        switch (v) {
          case CheckBoxFilterMenuComponent.blankFilterValue:
            return { operator: 'isnull', field: this.field };
          case CheckBoxFilterMenuComponent.notBlankFilterValue:
            return { operator: 'isnotnull', field: this.field };
          default:
            return {
              operator: this.operator,
              field: this.field,
              value: v,
              ignoreCase: !this.isGuidValue,
              ignoreValueCase: !this.isGuidValue,
              skipQuotes: this.isGuidValue,
            };
        }
      }),
    } as CompositeFilterDescriptor;

    if (this.collectionField) {
      const collectionBlankNotBlankOverride = true;
      const odataListFilter = patchToUiCollectionFilter(
        compositeFilter,
        this.field,
        this.collectionField,
        this.collectionItemField,
        this.collectionOperator,
        this.collectionRef,
        collectionBlankNotBlankOverride
      );

      this.filterService.filter(odataListFilter);
      return;
    }

    this.filterService.filter(compositeFilter);
  }

  public containsBlankRows(data: unknown[]): boolean {
    let blankRowFound = false;

    blankRowFound = this.gridCheckboxFilterMenuUIService.containsBlankRows(
      data,
      this.isPrimitive,
      this.valueField
    );

    return blankRowFound;
  }

  public removeBlankRow(data: unknown[]): unknown[] {
    data = data.filter((item) => {
      return !this.gridCheckboxFilterMenuUIService.isItemBlank(
        item,
        this.isPrimitive,
        this.valueField
      );
    });

    return data;
  }

  public isBlank(item: unknown) {
    return this.isPrimitive
      ? item === CheckBoxFilterMenuComponent.blankFilterText
      : item[this.valueField] === CheckBoxFilterMenuComponent.blankFilterValue;
  }

  public isNotBlank(item: unknown) {
    return this.isPrimitive
      ? item === CheckBoxFilterMenuComponent.notBlankFilterText
      : item[this.valueField] ===
          CheckBoxFilterMenuComponent.notBlankFilterValue;
  }

  public textAccessor = (dataItem: unknown) =>
    this.isPrimitive ? dataItem : dataItem[this.textField];

  public valueAccessor = (dataItem: unknown) =>
    this.isPrimitive ? dataItem : dataItem[this.valueField];

  public isItemSelected(item): boolean {
    return this.value.some(
      (x) => x === this.valueAccessor(item) || (x === null && item === 'Blank')
    );
  }

  public areAllItemsSelected(): boolean {
    if (this.data)
      return this.data.every((dataItem) =>
        this.value.includes(this.valueAccessor(dataItem))
      );
    return true;
  }

  /** when single item was checked */
  public onSelectionChange(checked: boolean, dataItem: unknown) {
    // NOTE: we try to avoid to clear extra filter values (set from outside)
    const value = this.valueAccessor(dataItem);

    if (checked) {
      // clear other if in 'single' mode
      if (this.isSingleSelection) this.unselectAll();
      // select item
      this.value.push(value);
    } else {
      // get rid of the selection
      this.value = this.value.filter((x) => x !== value);
    }

    this.processFiltering();
  }

  /** when select-all changed */
  public onSelectAllChange(checked: boolean) {
    // NOTE: this.data uses all values, while if filtered, i'd probably rather select-unselect the visible items
    // NOTE: i'd probably also remove (or have a switch) on whether selected items have to be always displayed in the filter
    const dataItemsToConsider = this.filteredDataItems;

    // NOTE: we try to avoid to clear extra filter values (set from outside)
    if (checked) {
      // add all items (that are not in the value[])
      dataItemsToConsider.forEach((dataItem) => {
        const dataItemValue = this.valueAccessor(dataItem);
        if (!this.value.includes(dataItemValue)) this.value.push(dataItemValue);
      });
    } else {
      // remove all items (that are in the data[])
      this.unselectAll(dataItemsToConsider);
    }

    this.processFiltering();
  }

  public dataItemSearchFilterOperatorChange(searchOption: SearchOption): void {
    // NOTE: the event fires before [(ngModel)] binds, so:
    this.dataItemSearchOperator = searchOption.value;

    // Refresh filtered items
    this.dataItemFilterTextChange(this.dataItemFilterText);
  }

  public dataItemFilterTextChange(searchText: unknown) {
    const searchFilter = {
      logic: 'or',
      filters: [
        {
          operator: this.dataItemSearchOperator, // operator for search
          field: this.textField,
          value: searchText,
        },
      ],
    } as CompositeFilterDescriptor;

    searchFilter.filters.push(
      ...(this.alwaysShowSelectedItems
        ? (this.value || []).map(
            (v) =>
              ({
                operator: 'eq',
                field: this.valueField,
                value: v,
              } as FilterDescriptor)
          )
        : [])
    );

    this.filteredDataItems = filterBy(this.data || [], searchFilter);
    return;
  }

  public parseValueFromFilter(filter: CompositeFilterDescriptor): unknown[] {
    // NOTE: originally we used an extra property, now as we have a parser for our complex filter, we use that (it's more general)
    const valuesCompositeFilter =
      fetchSimpleCompositeFilterFromUiCollectionFilter(filter, this.field);

    const values = valuesCompositeFilter.filters?.map((f: FilterDescriptor) => {
      if (f.operator === 'isnull')
        return CheckBoxFilterMenuComponent.blankFilterValue;
      else if (f.operator === 'isnotnull')
        return CheckBoxFilterMenuComponent.notBlankFilterValue;
      else return f.value;
    });

    return values;
  }

  public sortData(dataToBeSorted: unknown[]): unknown[] {
    const returnValue = [...dataToBeSorted];

    if (this.isPrimitive) {
      this.sortService.sortArray(returnValue);
    } else {
      this.sortService.sortFilteredItems(returnValue);
    }

    return returnValue;
  }

  protected filterValidSelections(values: unknown[]): unknown[] {
    return (values || []).filter((v) =>
      this.data.some((dataItem) => this.valueAccessor(dataItem) === v)
    );
  }

  protected unselectAll(dataItemsToUnselect?: unknown[]) {
    // remove all items (that are in the data[])
    const dataItems = dataItemsToUnselect || this.data;
    dataItems.forEach((dataItem) => {
      const dataItemValue = this.valueAccessor(dataItem);
      const index = this.value.indexOf(dataItemValue);
      if (index > -1) this.value.splice(index, 1);
    });
  }
}
