import { Directive, OnInit } from '@angular/core';
import { take, map, takeUntil, debounceTime, filter } from 'rxjs';
import { DynamicEventBusTypes } from '../../utils';
import { DynamicItemBaseComponent } from '../dynamic-item/dynamic-item.base-component';

@Directive()
export abstract class DynamicCheckboxBaseComponent
  extends DynamicItemBaseComponent
  implements OnInit
{
  checkboxValue = false;

  ngOnInit() {
    super.ngOnInit();
    if (this.win.isMobile || this.dynamicService.activeFormResponse) {
      // web and mobile checkbox controls behave differently
      // this accounts for the difference
      // listen for value changes to ensure UI is updated respectively per platform
      this.group.valueChanges
        .pipe(
          filter((formValue) => {
            return formValue.hasOwnProperty(this.config.formControlName);
          }),
          map((formValue) => formValue[this.config.formControlName]),
          // helps prevent too early initialization
          debounceTime(300),
          take(1)
        )
        .subscribe((value: boolean) => {
          this.checkboxValue = value;

          this.updateConfigTargets({
            target: {
              checked: this.checkboxValue,
            },
          });
        });
    }

    this.dynamicService.eventBus
      .observe(DynamicEventBusTypes.dynamicFormResetState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.checkboxChange(false);
        this._resetRequired();
      });
  }

  updateConfigTargets(args: { target: { checked?: boolean } }) {
    if (this.config.options) {
      if (this.config.options.hiddenFormControlName) {
        const hiddenFormControlNames =
          this.config.options.hiddenFormControlName.split(',');

        hiddenFormControlNames.forEach((formControlName) => {
          this.dynamicService.itemTargetUpdate$.next({
            formControlName: formControlName.trim(),
            type: 'target',
            disabled: !!this.dynamicService.activeFormResponse,
            options: {
              hidden: !args.target.checked,
            },
          });
        });
      }
      if (this.config.options.disableFormControlName) {
        this.dynamicService.itemTargetUpdate$.next({
          formControlName: this.config.options.disableFormControlName,
          disabled: args.target.checked,
          type: 'target',
        });
      }
      if (this.config.options.uncheckedFormControlName) {
        this.dynamicService.itemTargetUpdate$.next({
          formControlName: this.config.options.uncheckedFormControlName,
          type: 'target',
          disabled: !!this.dynamicService.activeFormResponse,
          options: {
            hidden: args.target.checked,
          },
        });
      }
    }
  }

  checkboxChange(checked: boolean): void {
    if (this.config?.disabled || this.disableItem) {
      return;
    }
    this.checkboxValue = checked;
    this.updateValue(checked);
    this.updateConfigTargets({
      target: {
        checked: this.checkboxValue,
      },
    });
  }

  private _resetRequired() {
    if (this.config?.required) {
      // make sure any required validation is added back
      this.setRequired();
    }
  }
}
