import { NgModule } from '@angular/core';
import { SafetyModule as SharedSafetyModule } from '@ups/xplat/features';
import { DynamicRenderModule } from '../dynamic-render';
import { UIModule } from '../ui/ui.module';
import { SAFETY_COMPONENTS } from './components';

@NgModule({
  imports: [
    SharedSafetyModule,
    DynamicRenderModule,
    UIModule,
  ],
  declarations: [...SAFETY_COMPONENTS],
  exports: [...SAFETY_COMPONENTS],
})
export class SafetyModule {}
