/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import { BaseModel } from '../base.model';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ManpowerAttachmentModel } from '@ups/xplat/api/dto';
import { EquipmentRequestDto } from './equipment/EquipmentRequestDto';

export class PlantDto extends BaseModel {
  public PlantID: number;
  public CompanyID: string;
  public VPCompanyID: number | null;
  public ParentFacilityID: number | null;
  public Name: string | null;
  public Address: string | null;
  public City: string | null;
  public State: string | null;
  public ZIP: string | null;
  public Client: string | null;
  public bActive: boolean;
  public DisaOwnerIDForDrugTest: string;
  public DisaOwnerIDForBackground: string;
  public DisaOwnerForDrugTest: string;
  public DisaOwnerForBackground: string;
  public DaysUntilUrineRetestOnsite: number;
  public DaysUntilHairRetestOnsite: number;
  public DaysUntilOralRetestOnsite: number;
  public DaysUntilBackgroundRecheckOnsite: number;
  public DaysBeforeTrainingRecheckOnsite: number;
  public DaysUntilUrineRetestPreOnsite: number;
  public DaysUntilHairRetestPreOnsite: number;
  public DaysUntilOralRetestPreOnsite: number;
  public DaysUntilBackgroundRecheckPreOnsite: number;
  public DaysBeforeTrainingRecheckPreOnsite: number;
  public VPArcmKeyID: number | null;
  public AssetControlManagerID: string;
  public AssetControlManagerName: string;
  public PlantEquipmentRequest: EquipmentRequestDto = new EquipmentRequestDto();

  public Attachments: ManpowerAttachmentModel[];
  public Requirements: any[];
  public EquipmentRequirements: any[] = []; // Need to be initialized as empty array as we need to instance that is accessed by subcomponents and adding items into it.

  constructor(o: any = null) {
    super(o);
  }
}
