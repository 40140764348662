export class EquipmentRequestDto {
  public EquipmentRequestID: string;
  public JobID: string;
  public PlantID: number;
  public bActive: boolean;
  public Description: string;
  public bUseJobRequirements: string;
  public AssetControlManagerID: string;
  public AssetControlManagerName: string;
}
