import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import {
  AssignmentGroupsService,
  TasksService,
  WorkflowsService,
} from './services';

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    TasksService,
    WorkflowsService,
    AssignmentGroupsService,
  ],
})
export class TasksModule {}
