import { Directive, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseComponent } from '@ups/xplat/core';
import { catchError, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';
import { EmployeeService } from '../services/employee.service';
import { EmployeeJobsWorkedResult } from '@ups/xplat/api/dto';
import { ManPowerService } from '@ups/xplat/base/manpower';
import { select, Store } from '@ngrx/store';
import { UserState } from '@ups/user';

@Directive()
export abstract class ProfileJobHistoryBaseComponent extends BaseComponent {
  protected httpClient: HttpClient;

  protected isLoading = false;
  public manPowerService: ManPowerService;

  constructor(
    protected injector: Injector,
    protected employeeService: EmployeeService,
    protected store: Store<any>
  ) {
    super();
    this.manPowerService = this.injector.get(ManPowerService);
  }

  ngOnInit() {
    this.store
      .pipe(UserState.selectCurrentTruthy(this.destroy$))
      .subscribe((x) => {
        this.isLoading = true;
        this.manPowerService
          .getWorkedByEmp(x.Data.HRRef)
          .pipe(
            catchError((err) => {
              this.isLoading = false;
              // console.log('error:', err);
              return of(null);
            })
          )
          .subscribe((results) => {
            // console.log('result:', results);
            this.data = results;
            this.isLoading = false;
          });
      });
  }

  abstract set data(value: Array<EmployeeJobsWorkedResult>);
}
