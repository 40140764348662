import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EQUIPMENT_DASHBOARD_COMPONENTS } from './components';

import { LabelModule } from '@progress/kendo-angular-label';
import {
  FormFieldModule,
  TextBoxModule,
} from '@progress/kendo-angular-inputs';
import {
  GridModuleEx,
  GroupingGridExModule,
} from '../kendo/Grid';
import { ValidatorsModuleEx } from '@ups/xplat/features';
import { EquipmentBasicInfoModule } from '../equipment-dispatch/components/equipment-basic-info/equipment-basic-info.module';
import { EquipmentResourceDropdownModule } from '../equipment-dispatch/components/equipment-resource-dropdown/equipment-resource-dropdown.module';
import { EquipmentResourceModule } from '../equipment-resource/equipment-resource.module';
import { TablesModule } from '../resources/tables.module';
import { TabStripModule } from '@progress/kendo-angular-layout';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { UIModule } from '../ui';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { PipesModule } from '../resources';
import {
  TimePickerModule,
  DateInputsModule,
} from '@progress/kendo-angular-dateinputs';
import { KendoDatepickerSharedModule } from '../kendo-datepicker/kendo-datepicker-shared.module';

export const EQUIPMENT_DASHBOARD_MODULES = [
  TablesModule,
  ValidatorsModuleEx,
  GroupingGridExModule,
  GridModuleEx,
  EquipmentResourceDropdownModule,
  EquipmentBasicInfoModule,
  EquipmentResourceModule,
  LabelModule,
  FormFieldModule,
  TextBoxModule,
  TabStripModule,
  DialogModule,
  UIModule,
  EquipmentResourceModule,
  ChartsModule,
  GaugesModule,
  PipesModule,
  DateInputsModule,
];

@NgModule({
  declarations: [...EQUIPMENT_DASHBOARD_COMPONENTS],
  imports: [
    CommonModule,
    ...EQUIPMENT_DASHBOARD_MODULES,
    TimePickerModule,
    KendoDatepickerSharedModule,
  ],
  exports: [...EQUIPMENT_DASHBOARD_MODULES],
  providers: [],
})
export class EquipmentDashboardModule {}
