import { Inject, Injectable, NgZone } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { of } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { filter, take } from 'rxjs/operators';
import {
  LogService,
  ProgressService,
  RouterActions,
  WindowService,
  environment,
} from '@ups/xplat/core';
import { IMenuService, MENU_SERVICE_TOKEN } from '@ups/common';
import { DynamicRenderService } from './dynamic-render.service';
import { Store } from '@ngrx/store';
import {
  PermissionsChangedEnum,
  SecurityGuard,
  SecurityService,
  ViewFeatureExpression,
  ViewFeatureExpressionType,
} from '@ups/security';
import { dynamicGetFormPathFromRouteUrl } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class AppDynamicGuard {
  constructor(
    @Inject(MENU_SERVICE_TOKEN) private menuService: IMenuService,
    private ngZone: NgZone,
    private store: Store,
    private progress: ProgressService,
    private log: LogService,
    private win: WindowService,
    private auth: AuthService,
    private dynamicRender: DynamicRenderService,
    private router: Router,
    private securityService: SecurityService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot
  ): Promise<boolean> {
    const data = route.data || {};
    const roles = data.roles as ViewFeatureExpressionType;
    const fallbackUrl = (data.fallbackUrl ||
      SecurityGuard.defaultFallbackUrl) as string;

    return new Promise((resolve) => {
      this.securityService.permissionsChanged$
        .pipe(
          filter((e) => {
            // wait for security details being loaded on web
            // console.log(
            //   'AppDynamicGuard this.securityService.permissionsChanged$:',
            //   e
            // );
            // console.log(
            //   'AppDynamicGuard PermissionsChangedEnum.set:',
            //   PermissionsChangedEnum.set
            // );
            return this.win.isMobile ? true : e === PermissionsChangedEnum.set;
          }),
          take(1)
        )
        .subscribe(() => {
          if (roles && !this.win.isMobile) {
            const hasAccess = ViewFeatureExpression.evaluate(
              roles,
              this.securityService
            );
            if (!hasAccess) return of(this.router.parseUrl(fallbackUrl));
          }

          // console.log(
          //   'AppDynamicGuard isAuthenticated$ about to be called from guard!'
          // );
          this.auth.isAuthenticated$
            .pipe(
              filter((x) => !!x),
              take(1)
            )
            .subscribe(() => {
              if (this.win.isMobile) {
                resolve(true);
              } else {
                this.progress.infinitePreventionOn = false;
                this.progress.toggleSpinner(true);
                // always load container list on app load
                this.dynamicRender.loadContainerList().then(() => {
                  this.progress.infinitePreventionOn = true;
                  this.progress.toggleSpinner(false);
                  // this.log.debug(
                  //   'list:',
                  //   this.dynamicRender.dynamicContainerList
                  // );

                  this.ngZone.run(() => {
                    const formPath = dynamicGetFormPathFromRouteUrl(
                      routerState.url,
                      this.dynamicRender.dynamicContainerList,
                      '/page-auth-landing'
                    );

                    if (['', '/'].includes(routerState.url)) {
                      this.store.dispatch(
                        new RouterActions.Go({
                          path: [environment.homeRoute],
                          extras: {
                            clearHistory: true,
                          },
                        })
                      );
                      resolve(false);
                    } else if (['/form'].includes(routerState.url)) {
                      this.store.dispatch(
                        new RouterActions.Go({
                          path: [formPath],
                          extras: {
                            clearHistory: true,
                          },
                        })
                      );
                      resolve(false);
                    } else {
                      resolve(true);
                    }
                  });
                });
              }
            });
        });
    });
  }
}
