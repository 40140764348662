import { Injectable, inject } from '@angular/core';
import { EventBusService, LogService, environment } from '@ups/xplat/core';
import { WebHelperService } from './web-helper.service';
import { IEmployeeInfo, userFullName } from '@ups/xplat/api/dto';
import { debounceTime } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type SMARTLOOKOPTIONS = {
  region?: string;
  forms?: boolean;
  ips?: boolean;
  emails?: boolean;
};
declare global {
  interface Window {
    smartlook: { api?: Array<unknown>; playUrl?: string } & ((
      action: string,
      value?: string | SMARTLOOKOPTIONS,
      options?: {
        region?: string;
        [key: string]: string | number;
      }
    ) => void);
  }
}

@Injectable({
  providedIn: 'root',
})
export class WebSmartlookService {
  eventBus = inject(EventBusService);
  log = inject(LogService);
  webHelper = inject(WebHelperService);
  init = false;

  constructor() {
    if (environment.smartlook?.webApiKey) {
      // smartlook user detail reporting
      this.eventBus
        .observe(this.eventBus.types.profileUpdated)
        .pipe(debounceTime(300))
        .subscribe((info: IEmployeeInfo) => {
          if (info.Auth0UserId) {
            this.setUser(info);
            this.log.debug('Smartlook session:', this.sessionUrl());
          } else {
            window.smartlook('anonymize');
          }
        });
    }
    /* eslint-disable @typescript-eslint/no-unused-vars */
    window.smartlook = function (
      // eslint-disable-next-line no-unused-vars
      action: string,
      // eslint-disable-next-line no-unused-vars
      apiKey: string | SMARTLOOKOPTIONS,
      // eslint-disable-next-line no-unused-vars
      options?: SMARTLOOKOPTIONS
    ) {
      window.smartlook.api = window.smartlook.api || [];
      // eslint-disable-next-line prefer-rest-params
      window.smartlook.api.push(arguments);
    };
    window.smartlook('init', environment.smartlook.webApiKey, {
      region: 'eu',
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window.smartlook('record', { forms: true, ips: true, emails: true }),
      this.webHelper.loadScriptAsync(`web-sdk.smartlook.com/recorder.js`, {
        id: 'smartlook-jssdk',
        onload: () => {
          this.init = true;
        },
      });
  }

  setUser(info: IEmployeeInfo) {
    window.smartlook('identify', info.Auth0UserId, {
      name: userFullName(info),
      email: info.Data?.udUPSEmail ?? info.Data?.Email,
    });
  }

  sessionUrl() {
    return window.smartlook.playUrl;
  }

  pause() {
    window.smartlook('pause');
  }

  resume() {
    window.smartlook('resume');
  }

  trackEvent(name: string, properties?: { [key: string]: string | number }) {
    window.smartlook('track', name, properties);
  }
}
