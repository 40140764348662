import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  EventBusService,
  LogService,
  environment,
} from '@ups/xplat/core';
import { getFullNameFromInfo, deepMerge } from '@ups/xplat/utils';
import { IAddressChangeRequest, IVacationAndSickBalance } from '../models';
import {
  BaseResponse,
  EmergencyContactModel,
  IEmployeeData,
  IEmployeeInfo,
  SecurityUser,
} from '@ups/xplat/api/dto';
import { Role } from '../models/role.interface';

const VP_GET_LOGGED_IN_USER = 'api/hrrm/employee';
const VP_GET_EMPLOYMENT_INFO = (hrref) =>
  `api/hrrm/employment-information/${hrref}`;
const VP_GET_HRRM_INFO = (hrref) => `api/hrrm/employee/${hrref}`; // TEMP, TODO: Replace with new api endpoint containing only personal info fields
const SPARTA_GET_PROFILE = (hrref) => `api/users/${hrref}`;
const SPARTA_POST_ADDRESS_CHANGE = `/api/spartaemployee/addresschangerequest/`;
const SECURITY_GET_USER_ROLES = `/api/user/roles`;

const PUT_EMPLOYEE_PROFILE_BASIC = '/api/hrrm/employee/{:id}';
const POST_EMPLOYEE_PROFILE_BASICDETAILS = '/api/hrrm/employee/basic';
const SPARTA_UPDATE_EMPLOYEE = 'api/spartaemployee/update';
const POST_EMPLOYEE_PROFILE_EMPLOYMENTDETAILS = '/api/hrrm/employee/employment';
const POST_EMPLOYEE_PROFILE_POSITIONDETAILS = '/api/hrrm/employee/position';
const POST_EMPLOYEE_PROFILE_BENEFITDETAILS = '/api/hrrm/employee/benefits';
const POST_EMPLOYEE_PROFILE_IDENTIFICATIONDETAILS =
  '/api/hrrm/employee/identification';

const GET_COMPANIES = '/api/hqco/company';
const GET_LICENSE_TYPES = '/api/manpowers/licensetypes';
const GET_IDCARD_TYPES = '/api/IdCard/HrAppIdCardTypes';
// const GET_EMPLOYEE_POSITION_NAMES = '/api/data/employeepositions/names';
const CHECK_ADDRESS_CHANGE_REQUEST = (hrref: number) =>
  `api/spartaemployee/addresschangerequest/${hrref}`;

const SEND_RESET_EMAIL = '/api/resetPassword';
const GET_USER = (userId) => `/api/user/${userId}`;
const UPDATE_EMPLOYEE_FOR_SPARTA_ONLY = '/api/spartaemployee/update';
const GET_EMPLOYEE_INFO_BY_SSN_COMPANYID =
  '/api/hrrm/employeeInfo/{:SSN}/{:compID}';
const GET_EMERGENCY_CONTACTS = '/api/hrrm/employee/emergencyContacts/{:hrRef}';
const SAVE_EMERGENCY_CONTACT_INFORMATION =
  '/api/hrrm/employee/emergencyContact';
const VP_GET_I9_ATTCH = (hrref) =>
  `/api/employeeportal/attachment/hrref/${hrref}/i9`;
const VP_GET_TWIC_ATTCH = (hrref) =>
  `/api/employeeportal/attachment/hrref/${hrref}/twic`;
const VP_GET_IDENTITY_ATTCH = (hrref) =>
  `/api/employeeportal/attachment/hrref/${hrref}/identity`;
const VP_GET_EMERGENCY_CONTACTS = (hrref) =>
  `/api/hrrm/employee/emergencyContacts/${hrref}`;
const VP_SAVE_EMERGENCY_CONTACT = `/api/hrrm/employee/emergencyContact`;
const SPARTA_GET_MULTICLASS = (hrref, prCos) =>
  `/api/spartaemployee/employeemulticlass/${hrref}?prCos=${prCos}`;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  myInfo: IEmployeeInfo;
  http: HttpClient;
  vpClient: HttpClient;
  spartaClient: HttpClient;
  securityClient: HttpClient;
  softwareSecurityClient: HttpClient;
  usersCache = [];
  private logService: LogService = inject(LogService);
  private eventBus: EventBusService = inject(EventBusService);
  private httpClientFactory: MyHttpClientFactory = inject(MyHttpClientFactory);
  constructor() {
    this.vpClient = this.httpClientFactory.createHttpClient(
      environment.urls.viewpointAPI,
      true,
      ResponseCasingEnum.PascalCase
    );
    this.spartaClient = this.httpClientFactory.createHttpClient(
      environment.urls.spartaAPI,
      true,
      ResponseCasingEnum.PascalCase
    );
    this.securityClient = this.httpClientFactory.createHttpClient(
      environment.urls.securityAPI,
      true,
      ResponseCasingEnum.PascalCase
    );
    this.softwareSecurityClient = this.httpClientFactory.createHttpClient(
      environment.urls.softwareSecurityAPI,
      true,
      ResponseCasingEnum.PascalCase
    );
  }

  get fullName() {
    return getFullNameFromInfo(this.myInfo);
  }

  fetchMyInfo(): Observable<IEmployeeInfo> {
    // console.log('fetchMyInfo!');
    return this.vpClient.get(VP_GET_LOGGED_IN_USER).pipe(
      catchError((err) => {
        console.log('error:', err);
        return of(null);
      }),
      mergeMap((vp: IEmployeeInfo) =>
        forkJoin({
          employment: this.vpClient.get(VP_GET_EMPLOYMENT_INFO(vp.Data.HRRef)),
          sparta: this.spartaClient.get(SPARTA_GET_PROFILE(vp.Data.HRRef)),
          hrrm: this.vpClient.get(VP_GET_HRRM_INFO(vp.Data.HRRef)),
        }).pipe(
          catchError((err) => {
            console.log('error:', err);
            return of(null);
          }),
          map(
            (x: {
              employment: IEmployeeInfo;
              sparta: Array<IEmployeeData>;
              hrrm: IEmployeeInfo;
            }) => {
              if (x) {
                const myInfo = vp;
                myInfo.Data = {
                  ...vp.Data,
                  ...x.employment.Data,
                  ...x.sparta[0],
                  ...x.hrrm.Data,
                };
                myInfo.HasError = vp.HasError || x.employment.HasError;
                this.myInfo = myInfo;
                // console.log('UserService fetchMyInfo this.myInfo.Auth0UserId:', this.myInfo.Auth0UserId);
                this.eventBus.emit(this.eventBus.types.profileUpdated, myInfo);
                return this.myInfo;
              } else {
                return null;
              }
            }
          )
        )
      )
    );
  }

  getRoles() {
    return this.softwareSecurityClient
      .get<Array<Role>>(SECURITY_GET_USER_ROLES)
      .pipe(
        catchError((err) => {
          this.logService.debug('error:', err);
          return of(null);
        })
      );
  }

  getCompanies(userCompanies = false) {
    let url = GET_COMPANIES;
    if (userCompanies === true) {
      url += '?userCompanies=true';
    } else {
      url += '?userCompanies=false';
    }

    return this.vpClient.get(url);
  }

  updateEmployeeProfileBasic(employeeProfileBasic: unknown, id: string) {
    return this.vpClient.put(
      PUT_EMPLOYEE_PROFILE_BASIC.split('{:id}').join(id),
      employeeProfileBasic
    );
  }

  /* eslint-disable */
  updateEmployeeProfileBasicDetails(
    data: IEmployeeData
  ): Observable<IEmployeeData> {
    const body = deepMerge(data, {
      UPSEmail: data.udUPSEmail,
      MailingAddress: {
        Street1: data.MailingAddress?.Line1,
        Street2: data.MailingAddress?.Line2,
        PostCode: data.MailingAddress?.Zip,
      },
    });
    return forkJoin({
      vp: this.vpClient.post<IEmployeeData>(
        POST_EMPLOYEE_PROFILE_BASICDETAILS,
        body
      ),
      sparta: this.spartaClient.put(SPARTA_UPDATE_EMPLOYEE, data),
    }).pipe(map(() => body));
  }
  /* eslint-enable */

  getEmployeeEmergencyContacts(hrRef: string) {
    return this.vpClient.get(
      GET_EMERGENCY_CONTACTS.split('{:hrRef}').join(hrRef)
    );
  }

  updateEmployeeEmergencyContact(emergencyContact: EmergencyContactModel) {
    return this.vpClient.post(
      SAVE_EMERGENCY_CONTACT_INFORMATION,
      emergencyContact
    );
  }

  /* eslint-disable */
  getEmployeeInfoUsingSSNAndCompanyID(SSN: string, compID: string) {
    const url = GET_EMPLOYEE_INFO_BY_SSN_COMPANYID.split('{:SSN}')
      .join(SSN)
      .split('{:compID}')
      .join(compID);

    return this.vpClient.get(url);
  }
  /* eslint-enable */

  updateEmployeeProfileForSpartaOnly(data: unknown) {
    return this.spartaClient.put(UPDATE_EMPLOYEE_FOR_SPARTA_ONLY, data);
  }

  updateEmployeeProfileEmploymentDetails(data: unknown) {
    return this.vpClient.post(POST_EMPLOYEE_PROFILE_EMPLOYMENTDETAILS, data);
  }

  updateEmployeeProfilePositionDetails(data: unknown) {
    return this.vpClient.post(POST_EMPLOYEE_PROFILE_POSITIONDETAILS, data);
  }

  updateEmployeeProfileBenefitDetails(data: unknown) {
    return this.vpClient.post(POST_EMPLOYEE_PROFILE_BENEFITDETAILS, data);
  }

  updateEmployeeProfileIdentificationDetails(data: unknown) {
    return this.vpClient.post(
      POST_EMPLOYEE_PROFILE_IDENTIFICATIONDETAILS,
      data
    );
  }

  // getEmployeePositionNames() {
  //   return this.packetClient
  //     .get(GET_EMPLOYEE_POSITION_NAMES);
  // }

  fetchLicenseTypes(): Observable<unknown> {
    return this.spartaClient.get(GET_LICENSE_TYPES);
  }

  fetchIdCardTypes(): Observable<unknown> {
    return this.vpClient.get(GET_IDCARD_TYPES);
  }

  sendPasswordResetEmail(email: string) {
    return this.securityClient.put(SEND_RESET_EMAIL, { email });
  }

  getUserFromSecurity(userID: string): Observable<SecurityUser> {
    return this.securityClient
      .get<BaseResponse<SecurityUser>>(GET_USER(userID))
      .pipe(map((res) => res.Data));
  }

  /* eslint-disable */
  checkAddressChange(HRRef: number) {
    return this.spartaClient.get<IAddressChangeRequest>(
      CHECK_ADDRESS_CHANGE_REQUEST(HRRef)
    );
  }
  /* eslint-enable */

  fetchMyAttachments() {
    return this.fetchAttachments(this.myInfo.Data.HRRef, false);
  }

  /* eslint-disable */
  fetchAttachments(HRRef: number, includeI9: boolean = true) {
    if (includeI9) {
      return forkJoin({
        i9: this.vpClient.get<any[]>(VP_GET_I9_ATTCH(HRRef)),
        twic: this.vpClient.get<any[]>(VP_GET_TWIC_ATTCH(HRRef)),
        identity: this.vpClient.get<any[]>(VP_GET_IDENTITY_ATTCH(HRRef)),
      });
    }
    return forkJoin({
      twic: this.vpClient.get<any[]>(VP_GET_TWIC_ATTCH(HRRef)),
      identity: this.vpClient.get<any[]>(VP_GET_IDENTITY_ATTCH(HRRef)),
    });
  }

  fetchEmergencyContacts(HRRef: number) {
    return this.vpClient.get(VP_GET_EMERGENCY_CONTACTS(HRRef));
  }

  saveEmergencyContact(contact: unknown) {
    return this.vpClient.post(VP_SAVE_EMERGENCY_CONTACT, contact);
  }

  fetchEmployeeMulitclass(HRRef: number) {
    return this.spartaClient.get<Array<unknown>>(
      SPARTA_GET_MULTICLASS(HRRef, 'all')
    );
  }
  /* eslint-enable */

  submitAddressChangeRequest(val: IAddressChangeRequest) {
    return this.spartaClient.post<IAddressChangeRequest>(
      SPARTA_POST_ADDRESS_CHANGE,
      val
    );
  }

  fetchVacationAndSickInfo(hrRef: number): Observable<IVacationAndSickBalance> {
    const url = '/api/hrrm/employee/vacationandsick/hrref/{:hrRef}'
      .split('{:hrRef}')
      .join(hrRef.toString());
    return this.vpClient.get(url) as Observable<IVacationAndSickBalance>;
  }
}
