import { Injectable, EventEmitter, Injector, Inject } from '@angular/core';
import { parseData } from '@ups/xplat/utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MyHttpClientFactory, environment } from '@ups/xplat/core';

declare let _: any;

const GET_ALLEMPLOYEECHANGES: string = '/api/employeechanges/';
const GET_EMPLOYEECHANGES_BY_HRREF: string = '/api/employeechanges/{:hrref}';
const PUT_DECIDEEMPLOYEECHANGES_BY_HRREF: string =
  '/api/employeechanges/authorize/{:hrref}';
const PUT_EMPLOYEECHANGES_DATA_BY_HRREF: string =
  '/api/employeechanges/employeeaddress/{:hrref}';

// SPARTA
const GET_PREFERRED_SAFETY_COUNCILS: string = '/api/preferredSafetyCouncils/';

// PACKET
const GET_EMPLOYEE_PACKET_REGISTERED: string =
  '/api/employee/register/{:ssn}/check';
const PUT_EMPLOYEE_REGISTER: string = '/api/employee/register/';

@Injectable({
  providedIn: 'root',
})
export class EmployeePortalService {
  employeesChanged: EventEmitter<any> = new EventEmitter<any>();

  private http: HttpClient;

  constructor(httpFactory: MyHttpClientFactory) {
    this.http = httpFactory.createHttpClient('', true);
  }

  public getAllEmployeeChanges(): any {
    var url = GET_ALLEMPLOYEECHANGES;
    return this.http.get(url).toPromise().then(parseData);
  }

  public getEmployeeChangesByHrref(hrref: string): any {
    var url = GET_EMPLOYEECHANGES_BY_HRREF.split('{:hrref}').join(hrref);
    return this.http.get(url).toPromise().then(parseData);
  }

  public getPreferredSafetyCouncils(): any {
    var url = GET_PREFERRED_SAFETY_COUNCILS;
    return this.http.get(url).toPromise().then(parseData);
  }

  public putDecideEmployeeChangesByHrref(
    pendingAddress: any,
    hrref: string
  ): any {
    var url = PUT_DECIDEEMPLOYEECHANGES_BY_HRREF.split('{:hrref}').join(hrref);

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    return this.http
      .put(url, pendingAddress, options)
      .toPromise()
      .then(parseData);
  }

  public putEmployeeChangesDataByHrref(changes: any, hrref: string): any {
    var url = PUT_EMPLOYEECHANGES_DATA_BY_HRREF.split('{:hrref}').join(hrref);

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    return this.http.put(url, changes, options).toPromise().then(parseData);
  }

  public getEmployeePacketRegistered(ssn: string): any {
    var url = GET_EMPLOYEE_PACKET_REGISTERED.split('{:ssn}').join(ssn);
    return this.http
      .get(environment.urls.packetAPI + url)
      .toPromise()
      .then(parseData);
  }

  public putRegisterEmployeeInPacket(
    prospectData: any,
    allowReRegistration: boolean
  ): any {
    const url = PUT_EMPLOYEE_REGISTER;

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    if (allowReRegistration) {
      headers = headers.append('Data-AllowReRegistration', 'true');
    }

    let options = { headers: headers };
    return this.http
      .put(environment.urls.packetAPI + url, prospectData, options)
      .toPromise()
      .then(parseData);
  }

  setEmployeesChanged() {
    this.employeesChanged.emit(true);
  }

  protected parseData(res: any) {
    let body = JSON.parse(res.body, function (key, value) {
      var a;
      if (typeof value === 'string') {
        a = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)$/.exec(
          value
        );
        if (a) {
          return new Date(
            Date.UTC(+a[1], +a[2] - 1, +a[3], +a[4], +a[5], +a[6])
          );
        }
      }
      return value;
    });
    return body;
  }

  public handleError(error: any) {
    //console.error('An error occurred', error.message || error);
    return Promise.reject(error.message || error);
  }
}
