/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import { BaseModel } from '../base.model';

export class LocationModel extends BaseModel {
  Latitude = 0;
  Longitude = 0;

  constructor(o: any = null) {
    super(o);
    if (o) {
      Object.assign(this, o);
    }
  }
}
