import { Component, OnInit } from '@angular/core';
import { DrAdminService } from '../../../services';

import { EditorComponentBase } from '../editor-component-base';

@Component({
  selector: 'editor-form-textarea',
  templateUrl: 'editor-form-textarea.html',
})
export class EditorFormTextareaComponent
  extends EditorComponentBase
  implements OnInit
{
  public pePlaceholderValue = '';
  public peLabel = '';
  public options: Array<any>;

  // constructor(private _drAdminService: DrAdminService) {
  //   super()
  // }

  public ngOnInit() {
    if (this.data) {
      this.pePlaceholderValue = this.data['placeholder'];
      this.peLabel = this.data['label'];
      // this.options = this._drAdminService.dynamicModelOptions.filter(item => {
      //   return item.types.includes('all') || item.types.includes('textarea');
      // })
      // console.log(this.options);
    }
  }

  public onApplyClick() {
    const changesObj = {
      placeholder: this.pePlaceholderValue,
      label: this.peLabel,
    };

    this.applyChanges.emit(changesObj);
  }
}
