import { Directive, Optional, SkipSelf } from '@angular/core';
import { ControlContainer } from '@angular/forms';

export const containerFactory = (container: ControlContainer) => container;

/**
 * Use with nested TEMPLATE DRIVEN forms.
 *
 * Usage:
 *  Inside the component annotation add viewProviders: [CONTROL_CONTAINER_PROVIDER]
 *
 * What it does:
 *  Ensures that the parent control container (form or form group) is accessible for the child...
 *  ...so child is able to add it's own controls to the parent group.
 *
 * Notes:
 *  This is the universal way to provide the form (group) from the parent form...
 *  ...which can be used in every situation, regardless of whether parent component is a ngForm or agModelGroup.
 *
 *  This is why it should be prefered over NG_FORM_PROVIDER and NG_MODEL_GROUP_PROVIDER.
 */
export const CONTROL_CONTAINER_PROVIDER = {
  provide: ControlContainer,
  deps: [[new Optional(), new SkipSelf(), ControlContainer]],
  useFactory: containerFactory,
};

/**
 * Please use the CONTROL_CONTAINER_PROVIDER route if possible.
 * Usage: Inside a nested control, add the [provide-control-container] to the outermost element.
 */
@Directive({
  selector: '[provide-control-container]',
  providers: [
    {
      provide: ControlContainer,
      deps: [[new Optional(), new SkipSelf(), ControlContainer]],
      useFactory: containerFactory,
    },
  ],
})
export class ProvideControlContainerDirective {}
