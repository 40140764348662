import { BaseModel } from '../../base.model';
import { EquipmentRequirementStatusDto } from './EquipmentRequirementStatusDto';

export class EquipmentRequirementDto extends BaseModel {
  public EquipmentRequirementID: string;
  public Name: string;
  public RequirementName: string;
  public bActive: boolean;
  public isFinalStatus: boolean;

  Statuses: EquipmentRequirementStatusDto[];

  constructor(o: any = null) {
    super(o);
  }
}
