import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { MyHttpClientFactory, environment } from '@ups/xplat/core';
import { parseData } from '@ups/xplat/utils';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISafetyItem } from '../models';
import { EmployeeService } from './employee.service';

const GET_TRAINING_BY_HRREF = '/api/hret/training/';

@Injectable({
  providedIn: 'root',
})
export class EmployeeSafetyService {
  private http: HttpClient;

  constructor(
    httpClientFactory: MyHttpClientFactory,
    private employeeService: EmployeeService
  ) {
    this.http = httpClientFactory.createHttpClient(
      environment.urls.viewpointAPI,
      true
    );
  }

  getTrainingById(id?: string) {
    if (this.employeeService.myInfo && this.employeeService.myInfo.Data) {
      return this.http
        .get<Array<ISafetyItem>>(
          `${GET_TRAINING_BY_HRREF}${
            id || this.employeeService.myInfo.Data.HRRef
          }`
        )
        .pipe(map(parseData));
    } else {
      return of(null);
    }
  }
}
