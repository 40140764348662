import { Component, ViewEncapsulation, ViewChild, Input, Output, EventEmitter, ContentChild } from '@angular/core';

@Component({
  selector: 'ups-panel',
  templateUrl: './panel.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class PanelComponent {
  @Input() public showClose = false;
  @Output() public onClose: EventEmitter<PanelComponent> = new EventEmitter<PanelComponent>();

  @Input() public showCollapse = true;
  @Input() public isCollapsed = false;

  @ContentChild('title') title;
  @ContentChild('menu') menu;
  @ContentChild('body') body;

  public showMenu = false;

  closeClick(ev) {
    this.onClose.emit(this);
  }

  collapseClick(ev) {
    this.isCollapsed = !this.isCollapsed;
  }

  toggleMenu(ev) {
    this.showMenu = !this.showMenu;
  }
}
