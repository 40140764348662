import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: 'input[type="checkbox"][readonly], input[kendoCheckBox][readonly]',
})
export class ReadonlyCheckboxDirective {
  @HostListener('click', ['$event']) onClick(ev: MouseEvent): void {
    ev.preventDefault();
  }

  @HostListener('keyup', ['$event']) onKeyup(ev: KeyboardEvent): void {
    ev.preventDefault();
  }

  @HostListener('keydown', ['$event']) onKeydown(ev: KeyboardEvent): void {
    ev.preventDefault();
  }

  @HostListener('keypress', ['$event']) onKeypress(ev: KeyboardEvent): void {
    ev.preventDefault();
  }
}
