import { BaseModel } from '../base.model';
import { FileDataModel } from './fileData-model';

export class ProspectFitModel extends BaseModel {
  isNewItem: boolean;
  editable: boolean;
  isSelected: boolean;
  showFiles: boolean;

  ProspectFitID: string;
  ProspectID: string;

  CertCode: string;
  SkillTester: string;
  CertDate: Date;
  ExpireDate: Date;

  FFModel: string;
  FFMfg: string;
  FFSize: string;
  FFModel2: string;
  FFMfg2: string;
  FFSize2: string;
  HMModel: string;
  HMMfg: string;
  HMSize: string;
  HMModel2: string;
  HMMfg2: string;
  HMSize2: string;

  Files: FileDataModel[] = [];
  bActive: boolean = true;

  constructor(o: any = null) {
    super(o);
  }
}
