import { Inject, Injectable } from '@angular/core';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SearchEmployeeHRRefService {
  private http: HttpClient;

  constructor(private clientFactory: MyHttpClientFactory) {
    this.http = this.clientFactory.createHttpClient(
      environment.urls.spartaAPI,
      true,
      ResponseCasingEnum.PascalCase
    );
  }

  search(searchString: string, params: HttpParams = null, limit: number = 20) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    params = params || new HttpParams();
    params = params.append('searchString', searchString);
    params = params.append('take', limit);
    return this.http
      .get(`/api/spartaemployee/hrref/employee/dropdown`, {
        params,
      })
      .pipe(
        map((data) => {
          if (data && Array.isArray(data)) {
            // only include valid data
            return data.filter((d) => !!d.FirstName || !!d.LastName);
          }
          return data;
        })
      );
  }
}
