import { BaseModel } from '../../base.model';

export class PrGroupDto extends BaseModel {
  PRGroup: number | null;
  PRCo: number;
  Status: number;

  constructor(o: any = null) {
    super(o);
  }
}
