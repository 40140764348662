import { State, SortDescriptor } from '@progress/kendo-data-query';

export class ODataSortUtils {
  /**
   * When fetching grouped data, we need to prioritize sort by the group fields.
   *
   * When fetching just groups, we need to make sure fields we don't query are not inside the sort expression.
   *  - This usually excludes all non group fields (so all original sort fields)
   *  - Also requires that state contains only those groups, that we're interested in
   *
   * When doing reporting (excel) - having the original sort columns from the state is desired as we want to keep same order.
   *
   * @param state original grid state
   * @param keepOriginalSort false when doing just group fetching
   * @returns newly created, patched SortDescriptor[]
   */
  public static getPatchedSortForGroupQuery(state: State, keepOriginalSort = true): SortDescriptor[] {
    // ensure there's a sort & group array (even if empty)
    state.sort = state.sort || [];
    state.group = state.group || [];

    // NOTE:
    // we don't get directly grouped data via GroupOData, we get a flat structure
    // a grouping is nothing more than having items with same groups together
    // so it's a prioritized sort by grouping field names (so we need to patch the state.sort property for that)
    // the conversion to a special data structure is done then by a kendo process() function call
    if (!state.group || !state.group.length) {
      return state.sort.map((i) => ({ field: i.field, dir: i.dir } as SortDescriptor));
    }

    // get group names and extra sort names (and use the groupSort.dir value, not the sort.dir for group values)
    const patchedSortDescriptor = state.group.map((i) => ({ field: i.field, dir: i.dir || 'asc' } as SortDescriptor));

    // now append other sort (if avail) to get items/groups in the proper order
    if (keepOriginalSort && state.sort) {
      state.sort.forEach((sd) => {
        if (patchedSortDescriptor.every((gsd) => gsd.field !== sd.field)) {
          patchedSortDescriptor.push(sd);
        }
      });
    }

    // return final sort order
    return patchedSortDescriptor;
  }
}
