<div class="dynamic-field-container" [class.selected]="config?.selected">
  <div
    class="dynamic-field form-input text-align"
    [formGroup]="group"
    [hidden]="!config?.isFormBuilder && config?.options?.hidden"
  >
    <!-- <ups-job-search></ups-job-search> -->
    <ups-dynamic-helper-label
      [config]="config"
      [dynamicService]="dynamicService"
    ></ups-dynamic-helper-label>
    <div class="job-search-container" style="position: relative">
      <ng-template [ngIf]="!config?.options?.isMultiSelect">
        <kendo-dropdownlist
          #dropdown
          class="k-no-dropdown w-100"
          [attr.name]="config.formControlName"
          [formControlName]="config.formControlName"
          [valuePrimitive]="false"
          [textField]="'Name'"
          [valueField]="'Name'"
          [defaultItem]="{ Name: config?.placeholder }"
          [filterable]="true"
          (valueChange)="valueChangeItem($event)"
          search-ex
          [seSearchColumns]="['FullName', 'Email']"
          (seFilterChangeEx)="filterChangeItem($event)"
          [seDebounceFilterChange]="150"
          [seUseCustomLocalFilter]="true"
          [seMinSearchLength]="0"
          [loading]="isLoading"
        >
          <ng-template kendoDropDownListItemTemplate let-dataItem>
            <div
              style="display: inline-flex"
              [hidden]="dataItem?.Name === config?.placeholder"
            >
              <span
                *ngIf="dataItem.JobID"
                (click)="toggleFavorite(dataItem, $event)"
              >
                <i
                  class="fas fa-star"
                  title="Add/Remove Favorite Job"
                  [ngStyle]="{
                    color: dataItem.IsUserFavorite ? 'orange' : 'lightgrey'
                  }"
                ></i>
                &nbsp;
              </span>
              <span
                *ngIf="dataItem.JobID && showFacilityTooltip"
                kendoTooltip
                [tooltipTemplate]="facilityTooltip"
              >
                <i class="fa fa-info-circle" title></i>
                &nbsp;
              </span>
              <ng-template #facilityTooltip>
                <div>Active Jobs:</div>
                <div *ngIf="dataItem.FacilityActiveJobs.length === 0">N/A</div>
                <div *ngFor="let job of dataItem.FacilityActiveJobs">
                  {{ job }}
                </div>
              </ng-template>
              <div>
                <span *ngIf="dataItem.JobID" class="font-weight-bold">
                  {{ dataItem.JobID }}
                </span>
                <span *ngIf="dataItem.JobID">{{ ' - ' }}</span>
                <span *ngIf="dataItem.Name" class="job-name">
                  {{ dataItem.Name }}
                </span>
                <span
                  *ngIf="!dataItem.IsActive && dataItem.JobID"
                  class="text-danger"
                >
                  (Closed)
                </span>
              </div>
            </div>
          </ng-template>
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <div style="display: inline-flex">
              <div kendoTooltip [title]="dataItem?.Name">
                <span *ngIf="dataItem.JobID" class="font-weight-bold">
                  {{ dataItem.JobID }} -
                </span>
                <span class="job-name">{{ dataItem.Name || '' }}</span>
              </div>
            </div>
          </ng-template>
        </kendo-dropdownlist>
      </ng-template>

      <ng-template [ngIf]="config?.options?.isMultiSelect">
        <kendo-multiselect
          [attr.name]="config.formControlName"
          [formControlName]="config.formControlName"
          class="k-no-dropdown w-100"
          [defaultItem]="{ Name: config?.placeholder }"
          [valuePrimitive]="false"
          [textField]="'JobID'"
          [valueField]="'JobID'"
          [placeholder]="config?.placeholder"
          [filterable]="true"
          (valueChange)="valueChangeItem($event)"
          search-ex
          [seSearchColumns]="['FullName', 'Email']"
          (seFilterChangeEx)="filterChangeItem($event)"
          [seDebounceFilterChange]="150"
          [seUseCustomLocalFilter]="true"
          [seMinSearchLength]="0"
          [loading]="isLoading"
        >
          <ng-template kendoMultiSelectItemTemplate let-dataItem>
            <div style="display: inline-flex">
              <span
                *ngIf="dataItem.JobID"
                (click)="toggleFavorite(dataItem, $event)"
              >
                <i
                  class="fas fa-star"
                  title="Add/Remove Favorite Job"
                  [ngStyle]="{
                    color: dataItem.IsUserFavorite ? 'orange' : 'lightgrey'
                  }"
                ></i>
                &nbsp;
              </span>
              <span
                *ngIf="dataItem.JobID && showFacilityTooltip"
                kendoTooltip
                [tooltipTemplate]="facilityTooltip"
              >
                <i class="fa fa-info-circle" title></i>
                &nbsp;
              </span>
              <ng-template #facilityTooltip>
                <div>Active Jobs:</div>
                <div *ngIf="dataItem.FacilityActiveJobs.length === 0">N/A</div>
                <div *ngFor="let job of dataItem.FacilityActiveJobs">
                  {{ job }}
                </div>
              </ng-template>
              <div>
                <span *ngIf="dataItem.JobID" class="font-weight-bold">
                  {{ dataItem.JobID }}
                </span>
                <span *ngIf="dataItem.JobID">{{ ' - ' }}</span>
                <span *ngIf="dataItem.Name" class="job-name">
                  {{ dataItem.Name }}
                </span>
                <span
                  *ngIf="!dataItem.IsActive && dataItem.JobID"
                  class="text-danger"
                >
                  (Closed)
                </span>
              </div>
            </div>
          </ng-template>
          <ng-template kendoMultiSelectValueTemplate let-dataItem>
            <div style="display: inline-flex">
              <div kendoTooltip [title]="dataItem?.JobID">
                <span class="job-name">
                  {{ dataItem.JobID ? dataItem.JobID + ' - ' : '' }}
                </span>
              </div>
            </div>
          </ng-template>
        </kendo-multiselect>
      </ng-template>
    </div>
    <div
      [class.hidden]="config?.options?.hidden"
      *ngIf="config?.isFormBuilder"
    ></div>
  </div>
  <div class="form-builder-options" *ngIf="config?.isFormBuilder">
    <i class="fas fa-times ms-3 cursor-pointer c-red" (click)="delete()"></i>
    <!-- <i class="fas fa-bars ms-3 cursor-grab"></i> -->
  </div>
</div>
