import { Component } from '@angular/core';
import { WebDynamicItemPreviewComponentBase } from '../web-dynamic-item-component-base';

@Component({
  selector: 'form-textinput',
  styleUrls: ['form-textinput.component.scss'],
  template: `
    <div class="dynamic-field form-input text-align">
      <label>Ex. Label</label>
      <input class="k-textbox" [attr.name]="config.id" [attr.placeholder]="config.data.placeholder" />
    </div>
  `,
})
export class FormTextInputComponent extends WebDynamicItemPreviewComponentBase {}
