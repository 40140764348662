import { Injectable } from '@angular/core';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';

@Injectable({
  providedIn: 'root',
})
export class BlankFilterService {
  defaultBlankFilterText = 'Blank'; // this should not be a "magic" string

  setIsBlankFilters(
    filter: CompositeFilterDescriptor,
    customBlankFilterText?: string
  ): void {
    const blankFilterText = customBlankFilterText
      ? customBlankFilterText
      : this.defaultBlankFilterText;

    if (filter && filter.filters) {
      filter.filters.forEach((element) => {
        const filterDescriptor = element as FilterDescriptor;

        if (
          filterDescriptor.operator &&
          filterDescriptor.operator === 'isempty'
        ) {
          filter.filters.push({
            field: filterDescriptor.field,
            operator: 'isnull',
            custom: true,
          } as FilterDescriptor);
          filter.logic = 'or';
        } else if (
          filterDescriptor.operator &&
          filterDescriptor.operator === 'eq' &&
          filterDescriptor.value === blankFilterText
        ) {
          // The back end can't retrieve data from the database using the blankFilterText
          // because that is not what is in the database, so we change it here to filters
          // that the back end can find in the database, namely an empty string, or null
          filter.filters.push({
            field: filterDescriptor.field,
            operator: 'eq',
            value: null,
          } as FilterDescriptor);

          filter.filters.push({
            field: filterDescriptor.field,
            operator: 'eq',
            value: '',
          } as FilterDescriptor);

          filter.logic = 'or';
        } else if (!filterDescriptor.operator) {
          this.setIsBlankFilters(
            element as CompositeFilterDescriptor,
            blankFilterText
          );
        }
      });
    }
  }
}
