import { Directive, Input } from '@angular/core';
import { BaseComponent } from '@ups/xplat/core';
import { IAddress } from '@ups/xplat/api/dto';

@Directive()
export abstract class AddressBaseComponent extends BaseComponent {
  @Input() addressModel: IAddress & { Address: string; Address2: string }; // address change requests use this.

  constructor() {
    super();
  }
}
