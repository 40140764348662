import { BaseModel } from '../base.model';

/* eslint-disable */
export class CompanyDto extends BaseModel {
  CompanyID: string;
  VpCompanyID: number | string | null;
  CompanyName: string;
  CompanyNumber: number;
  /** If true then currently logged in user has granted access/permissions for the company in our security portal */
  IsVisibleForCurrentUser: boolean | null;

  constructor(o: any = null) {
    super(o);
  }
}
/* eslint-enable */
