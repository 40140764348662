import { BaseModel } from '../../../base.model';

export class ForemanCrewMemberDto extends BaseModel {
  public bActive: boolean | null;
  public bActiveState: boolean | null;
  public EmployeeFirstName: string;
  public EmployeeLastName: string;
  public EmployeeVpKeyId: string;
  public EmployeeVpCompanyId: number | null;
  public EmployeeJobId: string;
  public ForemanCrewMemberId: string;
  public ForemanCrewId: string;
  public ForemanCrewName: string;
  public ClientId: string;
  public bLoadComdataCard: boolean | null;
  public bLoadComdataCardVerified: boolean | null;
  public bLoadComdataCardVerifiedBy: string;
  public bLoadComdataCardVerifiedAt: Date | null;
  public ComDataRefNum: string;
  public Shift: number | null;
  public DispatchClass: string;
  public LaborType: string;
  public LaborTypeId: string;
  public LaborTypeIdOverride: string;
  public LaborTypeModifiedBy: string;
  public LaborTypeModifiedAt: Date | null;
  public DirectDeposit: boolean;
  public ROFDate: Date | null;
  public Craft: string;
  public OnSiteDate: Date | null;
  public ROFReason: string;
  public ShouldMoveOnsite: boolean;
  public WorkLocation: string;
  public EquipmentName: string;
  public HasPerDiem: boolean;

  // ClientSide only
  public IsSaving: boolean;
  public IsDifferentDispatchShiftAndCrewShift: boolean;

  constructor(o: any = null) {
    super(o);
  }
}
