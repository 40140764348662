<div>
  <span *ngIf="!hasReadPermissions">
    You don't have permissions to see the Equipment Inspection History
  </span>
  <span *ngIf="hasReadPermissions && isLoading">Loading ...</span>

  <form
    id="equipmentResourceFormId"
    #equipmentForm="ngForm"
    (ngSubmit)="saveEquipment(equipmentForm.valid)"
    *ngIf="hasReadPermissions && equipmentResource !== null"
  >
    <div *ngIf="hasEditPermissions && equipmentResource !== null">
      <button
        id="editEquipmentBtn"
        help-anchor="editInspectionTabButton"
        *ngIf="!isEditMode"
        type="button"
        [disabled]="isLoading"
        class="k-button"
        (click)="editEquipment()"
      >
        Edit
      </button>
      <button
        id="editEquipmentSaveBtn"
        help-anchor="saveButton"
        *ngIf="isEditMode"
        type="button"
        [disabled]="isLoading || !equipmentForm.dirty"
        class="k-button"
        (click)="saveEquipment(equipmentForm.valid)"
      >
        Save
      </button>
      <button
        id="editEquipmentCancelBtn"
        *ngIf="isEditMode"
        type="button"
        [disabled]="isLoading"
        class="k-button"
        (click)="cancelEquipment(equipmentForm.dirty)"
      >
        Cancel
      </button>
    </div>

    <fieldset>
      <legend>Current Equipment</legend>
      <div>
        <div class="d-flex align-items-center">
          <label for="inspectionFrequency" class="text-nowrap">
            Inspection Frequency:
          </label>
          <kendo-dropdownlist
            id="inspectionFrequency"
            class="ms-3 w-300"
            help-anchor="inspectionFrequencyDD"
            name="inspectionFrequency"
            [(ngModel)]="equipmentResource.InspectionFrequency"
            [disabled]="!isEditMode"
            [data]="inspectionFrequencyDropdownItems"
            [textField]="'text'"
            [valueField]="'id'"
            [valuePrimitive]="true"
          ></kendo-dropdownlist>
        </div>
        <div>
          <label>Inspected By:</label>
          <label>{{ getInspectedByText(equipmentResource.InspectedBy) }}</label>
        </div>
        <div>
          <label>Last Inspection Date:</label>
          <label>
            {{ equipmentResource.LastInspectionDate | date: 'MM/dd/yyyy' }}
          </label>
        </div>
        <div>
          <label>Inspection Due Date:</label>
          <label>
            {{ equipmentResource.NextInspectionDate | date: 'MM/dd/yyyy' }}
          </label>
        </div>
        <div>
          <label>Inspection Outcome:</label>
          <label>
            {{ getInspectionOutcomeText(equipmentResource.InspectionOutcome) }}
          </label>
        </div>
        <div>
          <label>Notes:</label>
          <label>{{ equipmentResource.InspectionNotes }}</label>
        </div>
      </div>
    </fieldset>
  </form>
  <kendo-grid
    id="equipmentInspectionHistoryGridId"
    *ngIf="hasReadPermissions"
    [data]="gridData"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [filter]="state.filter"
    [sortable]="true"
    [pageable]="true"
    [filterable]="'menu'"
    [loading]="isLoading"
    (dataStateChange)="dataStateChange($event)"
    class="rate-sheet transparent-around"
  >
    <ng-template kendoGridToolbarTemplate>
      <div class="d-flex align-items-center justify-content-between w-100">
        <h4 class="mb-0">Inspection History</h4>
        <button
          id="addInspectionButtonId"
          help-anchor="addButton"
          *ngIf="hasEditPermissions"
          [disabled]="isLoading"
          (click)="addInspection()"
          class="k-button k-primary"
        >
          Add
        </button>
      </div>
    </ng-template>
    <kendo-grid-command-column
      field="Edit"
      *ngIf="hasEditPermissions"
      title=""
      width="40"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          kendoButton
          id="editInspectionButtonId"
          title="Edit"
          [disabled]="isLoading"
          (click)="editInspection(dataItem)"
        >
          <i class="fa fa-edit"></i>
          Edit
        </button>
      </ng-template>
    </kendo-grid-command-column>
    <kendo-grid-column field="InspectedBy" title="Inspected By" width="120">
      <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
        <span>{{ getInspectedByText(dataItem.InspectedBy) }}</span>
      </ng-template>
      <ng-template
        kendoGridFilterMenuTemplate
        let-column="column"
        let-filter="filter"
        let-filterService="filterService"
      >
        <grid-checkbox-filter-menu
          [isPrimitive]="false"
          [textField]="'text'"
          [valueField]="'id'"
          [field]="column.field"
          [filterService]="filterService"
          [currentFilter]="filter"
          [data]="inspectedByDropdownItems"
        ></grid-checkbox-filter-menu>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="LastInspectionDate"
      title="Last Inspection Date"
      width="120"
      filter="date"
    >
      <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
        <span>{{ dataItem.LastInspectionDate | date: 'MM/dd/yyyy' }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="NextInspectionDate"
      title="Inspection Due Date"
      width="120"
      filter="date"
    >
      <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
        <span>{{ dataItem.NextInspectionDate | date: 'MM/dd/yyyy' }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="InspectionOutcome"
      title="Inspection Outcome"
      width="120"
    >
      <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
        <span>{{ getInspectionOutcomeText(dataItem.InspectionOutcome) }}</span>
      </ng-template>
      <ng-template
        kendoGridFilterMenuTemplate
        let-column="column"
        let-filter="filter"
        let-filterService="filterService"
      >
        <grid-checkbox-filter-menu
          [isPrimitive]="false"
          [textField]="'text'"
          [valueField]="'value'"
          [field]="column.field"
          [filterService]="filterService"
          [currentFilter]="filter"
          [data]="inspectionOutcomeDropdownItems"
        ></grid-checkbox-filter-menu>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="InspectionNotes"
      title="Notes"
      width="120"
    ></kendo-grid-column>
    <kendo-grid-column
      field="DateModified"
      title="Date Modified"
      width="60"
      filter="date"
    >
      <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
        <span>{{ dataItem.DateModified | date: 'MM/dd/yyyy h:mm:ss' }}</span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
  <form #inspectionForm="ngForm">
    <kendo-dialog
      id="inspectionEditDialogId"
      *ngIf="isEditInspectionMode"
      [hidden]="!isEditInspectionMode"
      modal
      fade
      (close)="cancelInspection(inspectionForm.dirty)"
      [width]="550"
      [height]="650"
    >
      <kendo-dialog-titlebar style="padding: 5px 1em !important">
        <span>
          {{
            selectedInspectionItem.EquipmentInspectionHistoryID
              ? 'Edit'
              : 'Create'
          }}
          inspection history item
        </span>
      </kendo-dialog-titlebar>
      <fieldset>
        <legend>Equipment Inspection History Item</legend>
        <div>
          <div>
            <label for="inspectionItemInspectedBy">Inspected By:</label>
            <kendo-dropdownlist
              id="inspectionItemInspectedBy"
              help-anchor="inspectedByDD"
              name="inspectionItemInspectedBy"
              [(ngModel)]="selectedInspectionItem.InspectedBy"
              [disabled]="!isEditInspectionMode"
              [data]="inspectedByDropdownItems"
              [textField]="'text'"
              [valueField]="'id'"
              [valuePrimitive]="true"
            ></kendo-dropdownlist>
          </div>
          <div>
            <label for="inspectionItemLastInspectionDate">
              Last Inspection Date:
            </label>
            <ups-kendo-datepicker-shared
              id="inspectionItemLastInspectionDate"
              help-anchor="lastInspectionDate"
              name="inspectionItemLastInspectionDate"
              #lastInspectionDateControl="ngModel"
              [(ngModel)]="selectedInspectionItem.LastInspectionDate"
              [disabled]="!isEditInspectionMode"
              (valueChange)="
                lastInspectionDateChanged(lastInspectionDateControl)
              "
              placeholder="mm/dd/yyyy"
              required
            ></ups-kendo-datepicker-shared>
          </div>
          <div>
            <label for="inspectionItemInspectionOutcome">
              Inspection Outcome:
            </label>
            <kendo-dropdownlist
              id="inspectionItemInspectionOutcome"
              help-anchor="inspectionOutcomeDD"
              name="inspectionItemInspectionOutcome"
              [(ngModel)]="selectedInspectionItem.InspectionOutcome"
              [disabled]="!isEditInspectionMode"
              [data]="inspectionOutcomeDropdownItems"
              [textField]="'text'"
              [valueField]="'value'"
              [valuePrimitive]="true"
              required
            ></kendo-dropdownlist>
          </div>
          <div>
            <label for="inspectionItemNextInspectionDate">
              Inspection Due Date:
            </label>
            <ups-kendo-datepicker-shared
              id="inspectionItemNextInspectionDate"
              help-anchor="inspectionDueDate"
              name="inspectionItemNextInspectionDate"
              [(ngModel)]="selectedInspectionItem.NextInspectionDate"
              [disabled]="!isEditInspectionMode"
              placeholder="mm/dd/yyyy"
            ></ups-kendo-datepicker-shared>
          </div>
          <div>
            <label for="inspectionItemNotes">Notes:</label>
            <textarea
              id="inspectionItemNotes"
              help-anchor="notesField"
              name="inspectionItemNotes"
              type="text"
              [disabled]="!isEditInspectionMode"
              rows="3"
              [(ngModel)]="selectedInspectionItem.InspectionNotes"
              kendoTextArea
            ></textarea>
          </div>
        </div>
      </fieldset>
      <fieldset>
        <legend>Update current equipment record</legend>
        <small>
          When checked the current equipment record will be updated with data
          from this inspection history record.
        </small>
        <div>
          <div class="nowrap">
            <input
              id="editCurrentEquipmentCheckboxId"
              help-anchor="alsoUpdateCurrentEquipmentCheckbox"
              name="editCurrentEquipmentCheckboxId"
              type="checkbox"
              kendoCheckBox
              [(ngModel)]="selectedInspectionItem.ShouldUpdateEMEM"
              [disabled]="!isEditInspectionMode"
            />
            <label
              id="editCurrentEquipmentCheckboxLabelId"
              for="editCurrentEquipmentCheckboxId"
              title="When checked the current equipment information will be updated with data from this inspection history record."
            >
              Also update current equipment
            </label>
          </div>
        </div>
      </fieldset>
      <kendo-dialog-actions>
        <button
          id="inspectionItemCancelButtonId"
          (click)="cancelInspection(inspectionForm.dirty)"
          [disabled]="isLoading"
          class="k-button"
        >
          Cancel
        </button>
        <button
          id="inspectionItemSaveButtonId"
          help-anchor="saveInspectionHistoryItemButton"
          (click)="saveInspection(inspectionForm.valid)"
          [disabled]="isLoading || !inspectionForm.dirty"
          class="k-button k-primary"
        >
          Save
        </button>
      </kendo-dialog-actions>
    </kendo-dialog>
  </form>
</div>
