/* eslint-disable @typescript-eslint/naming-convention */
export class TimekeepingSearchDto {
  public Amount: number;
  public BillRate: number;
  public Class: string;
  public Craft: string;
  public CustomerPO: string;
  public DateWorked: Date;
  public EarnCode: string;
  public EarnCodeType: string;
  public Employee: string;
  public EmployeeJobID: string;
  public EmployeeLaborID: string;
  public FirstName: string;
  public Hours: number;
  public LaborBillingTemplate: string;
  public LastName: string;
  public Mileage: number;
  public OTEarnCode: string;
  public OTHours: number;
  public PDEarnCode: string;
  public PTEarnCode: string;
  public PTHours: number;
  public PayCategoryID: number;
  public PayRate: number;
  public PerDiem: number;
  public Phase: string;
  public IsBillablePhase: boolean;
  public STEarnCode: string;
  public STHours: number;
  public Shift: number;
  public EmployeeCraft: string;
  public EmployeeClass: string;
  public EmployeePayRate: number;
  public EmployeePerDiem: number;
  public EmployeeMileage: number;
  public MileageRate: number;
  public LineID: string;
}
