import { DynamicAdminContainerComponent } from './dynamic-admin-container/dynamic-admin-container.component';
import { DynamicContainerComponent } from './dynamic-container/dynamic-container.component';
import { DynamicSfContainerComponent } from './dynamic-container/dynamic-sf-container.component';

export const DYNAMIC_CONTAINER_COMPONENTS = [
  DynamicContainerComponent,
  DynamicAdminContainerComponent,
  DynamicSfContainerComponent,
];

export * from './dynamic-admin-container/dynamic-admin-container.component';
export * from './dynamic-container/dynamic-container.component';
export * from './dynamic-container/dynamic-sf-container.component';
