import { Injectable } from '@angular/core';
import { ApplicationModel, Company } from '@ups/xplat/api/dto';
import { JobsService } from './jobs-service';

@Injectable({ providedIn: 'root' })
export class CompanyResolver {
  constructor(
    private jobsService: JobsService,
    private applicationModel: ApplicationModel
  ) {}

  resolve() {
    return this.load();
  }

  load(searchString?: string): Promise<unknown> {
    if (this.applicationModel.companies.length === 0) {
      return new Promise((resolve) => {
        this.jobsService.getCompanies().then((data) => {
          this.applicationModel.companies = data;
          resolve(this._filterByName(searchString));
        });
      });
    } else {
      return Promise.resolve(this._filterByName(searchString));
    }
  }

  private _filterByName(searchString: string): Company[] {
    const names = searchString ? searchString.toLowerCase().split(',') : [];
    return this.applicationModel.companies.filter((c) => {
      if (names.length && c) {
        return c.CompanyName
          ? names.includes((<string>c.CompanyName).toLowerCase()) ||
              names.includes(c.CompanyNumber.toString())
          : false;
      } else {
        return true;
      }
    });
  }
}
