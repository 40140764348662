import { BaseModel } from '../base.model';

export class NameAndLongDto extends BaseModel {
  public ID: number;
  public Name: string;

  constructor(o: any = null) {
    super(o);
  }
}
