import {
  Component,
  ViewChild,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  Inject,
} from '@angular/core';
import { State, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { of, tap } from 'rxjs';
import {
  GroupingExViewModel,
  GroupingExDirective,
  GroupStateUtils,
  GroupResultFlattener,
  GroupResultSelectionSupport,
  CheckBoxFilterMenuComponent,
  FilterUtils,
} from '../../../kendo';
import {
  ODataResult,
  ODataReportingUtils,
  ODataFilterDataUtils,
} from '@ups/xplat/features';
import { EquipmentService } from '@ups/xplat/api/services';
import { EquipmentDashboardDetailComponent } from '../equipment-dashboard-detail/equipment-dashboard-detail.component';
import { EquipmentDashboardBulkEditComponent } from '../equipment-dashboard-bulk-edit/equipment-dashboard-bulk-edit.component';
import { BaseComponent, environment } from '@ups/xplat/core';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import { EquipmentDashboardTabEnum, FilterData } from '@ups/xplat/api/dto';

@Component({
  selector: 'ups-equipment-dashboard-grid',
  templateUrl: './equipment-dashboard-grid.component.html',
})
export class EquipmentDashboardGridComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() tab: EquipmentDashboardTabEnum = EquipmentDashboardTabEnum.InProcess;

  @Input() assetControlManagerId = 'All';

  @Input() jobNumber;

  @Input() tableViewKey = 'EmployeePortal_EquipmentDashboard';

  @ViewChild(EquipmentDashboardBulkEditComponent)
  bulkEditDialog: EquipmentDashboardBulkEditComponent;

  @ViewChild(EquipmentDashboardDetailComponent)
  detailDialog: EquipmentDashboardDetailComponent;

  @ViewChild('grid') public grid;

  @ViewChild(GroupingExDirective) groupingEx: GroupingExDirective;

  public canRead = false;

  public canSeeEquipmentHyperlink = false;

  public gridFilterData = {};

  public gridSearchValue: string;

  public gridState: State = {
    take: 25,
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [{ field: 'StartDate', dir: 'desc' }],
  };

  public isLoading = false;

  public selectedItems = [];

  public showCheckboxColumn = true;

  public view: GridDataResult;

  public viewModel: GroupingExViewModel;

  public blankFilterText = CheckBoxFilterMenuComponent.blankFilterText;

  public blankFilterValue = CheckBoxFilterMenuComponent.blankFilterValue;

  public notBlankFilterText = CheckBoxFilterMenuComponent.notBlankFilterText;

  public notBlankFilterValue = CheckBoxFilterMenuComponent.notBlankFilterValue;

  public shouldWrapWords = false;

  securityConstants = SecurityConstants;
  isLoadingFilterData = false;

  constructor(
    private equipmentService: EquipmentService,
    public security: SecurityService
  ) {
    super();

    this.canSeeEquipmentHyperlink = this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_equipmentresourcehyperlink
    ).readAll;
    const fSecurity = this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_equipmentdashboard
    );
    this.canRead = fSecurity.readAll;

    this.viewModel = new GroupingExViewModel(
      (odataQuery) => {
        return this.loadEquipmentDashboardData(odataQuery);
      },
      () => false
    );
    this.viewModel.loadDataOnInit = true;
  }

  loadEquipmentDashboardData(odataQuery: string) {
    if (this.canRead && this.tab === EquipmentDashboardTabEnum.InProcess) {
      return this.equipmentService.queryEquipmentDashboardInProcessData(
        this.assetControlManagerId,
        odataQuery
      );
    } else if (
      this.canRead &&
      this.tab === EquipmentDashboardTabEnum.Dispatched
    ) {
      return this.equipmentService.queryEquipmentDashboardDispatchedData(
        this.assetControlManagerId,
        odataQuery
      );
    } else if (
      this.canRead &&
      this.tab === EquipmentDashboardTabEnum.ByJob &&
      this.jobNumber
    ) {
      return this.equipmentService.queryEquipmentDashboardByJobData(
        this.jobNumber,
        odataQuery
      );
    } else {
      return of(ODataResult.EmptyODataResult);
    }
  }

  ngOnInit() {
    this.loadFilterData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.assetControlManagerId &&
        changes.assetControlManagerId.currentValue !==
          changes.assetControlManagerId.previousValue) ||
      (changes.jobNumber &&
        changes.jobNumber.currentValue !== changes.jobNumber.previousValue)
    ) {
      this.loadFilterData();
      this.loadData();
    }
  }

  gridDataStateChange(state: State): void {
    state = GroupStateUtils.consolidateSortOrder(this.gridState, state);

    for (const flt of state.filter.filters) {
      if (flt['filters']) {
        for (const f of flt['filters']) {
          if (f.operator === 'contains') {
            f.ignoreCase = true;
          }
        }
      }
    }

    this.gridState = state;
    this.loadData();
  }

  loadFilterData() {
    this.isLoadingFilterData = true;
    const filterDataState = {} as State;
    const filterFields = [
      'VPJobNumber',
      'RequestedEquipment',
      'DispatchStatus',
      'AssetControlManager',
      'MissingRequirements.Name',
      'OtherJobs.VPJobId',
      'OtherJobs.DispatchStatus',
    ];
    const filterDataQuery = ODataFilterDataUtils.getFilterDataQueryString(
      filterDataState,
      filterFields
    );
    this.viewModel
      .getDataLoadObservable(filterDataQuery)
      .pipe(tap(() => (this.isLoadingFilterData = false)))
      .subscribe((fd: FilterData) => {
        this.gridFilterData = fd;
      });
  }

  loadData() {
    const stateCopy = JSON.parse(JSON.stringify(this.gridState)) as State;

    // extra filters
    if (
      this.viewModel.extraFilter &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.viewModel.extraFilter as any).filters.length
    ) {
      stateCopy.filter.filters.push(this.viewModel.extraFilter);
    }

    // load
    if (this.groupingEx) {
      this.groupingEx.loadData(stateCopy);
    }
  }

  open(record) {
    this.detailDialog.open(record);
  }

  clearFilters() {
    this.gridState.filter = <CompositeFilterDescriptor>{
      logic: 'and',
      filters: [],
    };

    if (this.gridSearchValue === '') {
      this.gridDataStateChange(this.gridState);
    } else {
      this.gridSearchValue = '';
    }
  }

  mcsFilterChange(e) {
    if (e !== undefined) {
      this.viewModel.extraFilter = e;
      this.gridState.skip = 0;
      this.loadData();
    }
  }

  selectionChange() {
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const gridData = this.grid.data as any;
      const gridItems = gridData.data;

      this.selectedItems = GroupResultFlattener.getFlattenedItemsWithDetails(
        gridItems,
        null
      )
        .map((i) => i.dataItem)
        .filter(
          (i) => i[GroupResultSelectionSupport.selectionStateProperty]?.checked
        );
    }, 100);
  }

  updateXlsxHref(e) {
    const uri = this.getXlsxDownloadUri;
    e.target.setAttribute('href', uri);
  }

  get getXlsxDownloadUri(): string {
    let uri = `${environment.urls.spartaAPI}`;

    const stateWithExtra = {
      filter: FilterUtils.concatFilters(
        this.gridState.filter,
        this.viewModel.extraFilter
      ),
    } as State;
    stateWithExtra.sort = stateWithExtra.sort || [];

    uri += `/api/equipment/dashboard-odata/inprocess/${this.assetControlManagerId}`;
    uri +=
      '?' +
      ODataReportingUtils.getXlsxDowloadQueryString(
        this.grid,
        stateWithExtra,
        []
      );
    return uri;
  }
}
