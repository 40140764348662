import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {
  DynamicContainerBaseComponent,
  DynamicRenderService,
} from '@ups/xplat/features';

@Component({
  selector: 'ups-dynamic-admin-container',
  styleUrls: ['dynamic-admin-container.component.scss'],
  templateUrl: 'dynamic-admin-container.component.html',
})
export class DynamicAdminContainerComponent extends DynamicContainerBaseComponent {
  @Output()
  formBuilderItemSelected: EventEmitter<any> = new EventEmitter();
  @Output()
  formBuilderItemReordered: EventEmitter<any> = new EventEmitter();
  constructor(fb: UntypedFormBuilder, dynamicService: DynamicRenderService) {
    super(fb, dynamicService);
  }

  drop(args) {
    this.formBuilderItemReordered.emit(args);
  }
}
