import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { GridModule } from '@progress/kendo-angular-grid';
import { RequirementsEditorComponent } from './requirements-editor.component';
import { SubtaskListComponent } from './subtask-list/subtask-list.component';
import { SubtaskEditorComponent } from './subtask-editor/subtask-editor.component';
import { TrainingSubtaskListComponent } from './training-subtask-list/training-subtask-list.component';
import { TrainingSubtaskEditorComponent } from './training-subtask-editor/training-subtask-editor.component';
import {
  DropDownsModuleEx,
  GridModuleEx,
  GroupingGridExModule,
} from '../kendo';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { LabelModule } from '@progress/kendo-angular-label';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DropDownsModule,
    DropDownsModuleEx,
    GridModule,
    GridModuleEx,
    GroupingGridExModule,
    DialogModule,
    InputsModule,
    LayoutModule,
    ButtonModule,
    LabelModule,
  ],
  declarations: [
    RequirementsEditorComponent,
    SubtaskListComponent,
    SubtaskEditorComponent,
    TrainingSubtaskListComponent,
    TrainingSubtaskEditorComponent,
  ],
  exports: [
    RequirementsEditorComponent,
    SubtaskListComponent,
    SubtaskEditorComponent,
    TrainingSubtaskListComponent,
    TrainingSubtaskEditorComponent,
  ],
})
export class RequirementsEditorModule {}
