import { Injectable } from '@angular/core';
import { ManPowerService } from '@ups/xplat/base/manpower';
import { ApplicationModel } from '@ups/xplat/api/dto';

@Injectable({
  providedIn: 'root',
})
export class DeclineReasonResolver {
  constructor(
    private manPowerService: ManPowerService,
    private applicationModel: ApplicationModel
  ) {}

  public load(): Promise<any> {
    const self = this;
    if (this.applicationModel.declinationReasons.length === 0) {
      return this.manPowerService
        .fetchDeclinationReasons()
        .then((data) => (self.applicationModel.declinationReasons = data));
    } else {
      return Promise.resolve(self.applicationModel.declinationReasons);
    }
  }
}
