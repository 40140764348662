import { Directive, Injector, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@ups/xplat/core';
import { UserState } from '@ups/user';
import { IEmployeeInfo } from '@ups/xplat/api/dto';
import { EmployeeExpertiseService } from '@ups/xplat/api/services';
import { Store } from '@ngrx/store';
import {
  EmployeeExpertiseDto,
  ExpertiseDto,
  ExpertiseTypeEnum,
} from '@ups/xplat/api/dto';

@Directive()
export abstract class ProfileEmployeeExpertiseBaseComponent
  extends BaseComponent
  implements OnInit
{
  @Input() readonly = false;
  @Input() employeeProfile: any;

  employeeExpertises: EmployeeExpertiseDto[];
  employeeExpertiseHistory: EmployeeExpertiseDto[];

  expertises: ExpertiseDto[];

  profile: IEmployeeInfo;
  public employeeExpertiseService: EmployeeExpertiseService;

  constructor(protected injector: Injector, protected store: Store<any>) {
    super();
    this.employeeExpertiseService = this.injector.get(EmployeeExpertiseService);
  }

  ngOnInit() {
    this.store
      .pipe(UserState.selectCurrentTruthy(this.destroy$))
      .subscribe((x) => {
        this.profile = x;
        this.loadData();
      });
  }

  public loadData() {
    this.loadExpertises();
    this.loadEmployeeExpertises();
  }

  public loadExpertises() {
    this.employeeExpertiseService
      .getExpertises(ExpertiseTypeEnum.All)
      .subscribe((ret) => {
        this.expertises = ret.value;
      });
  }

  public loadEmployeeExpertises() {
    const employeeId = this.employeeProfile?.employeeId
      ? this.employeeProfile?.employeeId
      : this.profile.Data.EmployeeID;
    this.employeeExpertiseService
      .getEmployeeExpertises(employeeId, null)
      .subscribe((ret) => {
        this.data = ret;
        this.employeeExpertises = ret;
      });
  }

  abstract set data(value: Array<any>);
}
