import { BaseModel } from '../../base.model';

export class DisaPolicyDto extends BaseModel {
  DisaPolicyID: string;
  PolicyID: number | string;
  PolicyName: string;
  IsActive: boolean;
  DisaRosterAccountID: number | null;

  constructor(o: any = null) {
    super(o);
  }
}
