import { IEnvironmentConfiguration } from '../environment.interface';

/**
 * Reduce the most commonly used environment values here
 */
export const environmentBase: IEnvironmentConfiguration = {
  production: false,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  environment_name: 'stg',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  api_url: 'http://127.0.0.1:4000',
  baseRoutePath: '',
  logging: {
    defaultLogLevel: 0x0010,
  },
  appsync: {
    production: true,
    android: {
      productionKey: process.env['AppSync:AndroidKey@prod'],
      stagingKey: process.env['AppSync:AndroidKey@stg'],
    },
    ios: {
      productionKey: process.env['AppSync:IosKey@prod'],
      stagingKey: process.env['AppSync:IosKey@stg'],
    },
    version: '0.0.1',
    serverUrl: process.env['Urls:AppSyncServerUrl'],
  },
  smartlook: {
    webApiKey: '',
    mobileApiKey: process.env['Smartlook:MobileApiKey'],
  },
  uxcam: {
    apiKey: process.env['UxCam:ApiKey'],
  },
  microsoftClarity: {
    // HSE Toolbox
    projectId: process.env['MicrosoftClarity:ProjectId@prod'],
  },
  apiHeader: 'production',
  sockets: {
    signalR: {
      url: process.env['Urls:NotificationHub@stg'],
    },
  },
  liveUpdate: {
    url: '',
  },
  featuresSwitch: {
    enableSignalR: false,
  },
  offline: {
    enabled: true,
  },
  name: '',
  displayName: '',
  version: '',
  auth0: {
    domain: process.env['Auth0:Domain'],
    audience: process.env['Auth0:Audience'],
    callbackPath: 'callback',
    clientId: null,
  },
  syncfusionLicenseKey: process.env['SyncFusion:LicenseKey@prod'],
  security: {
    allowEverything: false,
    securityOverride: false,
    securityOverrideJson: 'environments/security.json',
    apiUrl: process.env['Urls:SecurityApiAzure@stg'],
    appId: process.env['Security:AppId@web-safety'],
  },
  urls: {
    azureAPI: process.env['Urls:AzureApi@stg'],
    sparta: process.env['Urls:Sparta@stg'],
    spartaAPI: process.env['Urls:SpartaApi@stg'],
    viewpointAPI: process.env['Urls:ViewpointApi@stg'],
    packetAPI: process.env['Urls:PacketApi@stg'],
    securityAPI: process.env['Urls:SecurityApi@stg'],
    softwareSecurityAPI: process.env['Urls:SoftwareSecurityApi@stg'],
    fileManagementAPI: process.env['Urls:FileManagementApi@stg'],
    tasksAPI: process.env['Urls:TasksApi@stg'],
    safetyAPI: process.env['Urls:SafetyApi@stg'],
    ratesAPI: process.env['Urls:RatesApi@stg'],
    toolTrackerAPI: process.env['Urls:ToolTrackerApi@stg'],
    reportsServer: process.env['Urls:ReportsServer@stg'],
    dynamicAPI: process.env['Urls:DynamicApi@stg'],
  },
  mobile: {
    auth401Redirect: '/page-personal-info',
    appStoreLink: {
      ios: process.env['Mobile:IosAppStoreLink'],
      android: '',
    },
  },
  host: process.env['Urls:Safety2HostUrl@stg'],

  blobStorage: {
    url: process.env['Urls:AzureBlobStorageUrl'],
    container: process.env['AzureBlobStorage:ContainerName@stg'],
  },
};
