export class AdminInventoryEntry {
  location: string;
  material: string;
  finalCount: number;
  finalCountBroken: number;
  niftyOnHand: number;
  adminCount: number;
  adminCountBroken: number;
  inventoryNote: string;
  adminCountNote: string;
  niftySubLocInProcess: number;
}
