import { Component, Input } from '@angular/core';

import { BaseComponent } from '@ups/xplat/core';

@Component({
  selector: 'ups-labeled-field',
  templateUrl: 'labeled-field.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class LabeledFieldComponent extends BaseComponent {
  @Input() label: string = 'SampleLabel';
  @Input() value: string;
  @Input() alignment: FieldAlignment = 'justify';

  constructor() {
    super();
  }
}

export type FieldAlignment = 'left' | 'center' | 'justify' | 'right';
