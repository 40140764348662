<div class="d-flex">
  <b
    class="field-label text--primary"
    [ngClass]="{ 'ms-auto': alignment === 'right' || alignment === 'center' }"
  >
    {{ label }}:
  </b>
  <div
    class="field-value"
    [ngClass]="{
      'ms-auto': alignment === 'justify',
      'me-auto': alignment === 'right' || alignment === 'center',
      'ms-2': alignment === 'left'
    }"
  >
    {{ value }}
    <ng-content></ng-content>
  </div>
</div>
