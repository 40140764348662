import { Component, OnInit } from '@angular/core';
import {
  CountSettingsEnum,
  EquipmentDashboardTabEnum,
} from '@ups/xplat/api/dto';
import { ManPowerService } from '@ups/xplat/base/manpower';
import { EquipmentService } from '@ups/xplat/api/services';
import { BaseComponent } from '@ups/xplat/core';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';

@Component({
  selector: 'ups-equipment-dashboard-page',
  templateUrl: './equipment-dashboard-page.component.html',
})
export class EquipmentDashboardPageComponent
  extends BaseComponent
  implements OnInit
{
  equipmentDashboardTabEnum = EquipmentDashboardTabEnum;
  selectedTabIx = 0;
  countSettingsEnum = CountSettingsEnum;
  countSettings: CountSettingsEnum = CountSettingsEnum.Equipment;

  defaultAssetControlManagerItem = { id: 'All', name: 'All' };
  assetControlManagers = [this.defaultAssetControlManagerItem];
  selectedAssetControlManager = this.defaultAssetControlManagerItem;

  showAllJobs = false;
  placeholder = 'Search for Jobs...';
  selectedJob;

  constructor(
    private security: SecurityService,
    private equipmentService: EquipmentService,
    private manPowerService: ManPowerService
  ) {
    super();
  }

  ngOnInit() {
    this.loadAssetControlManagers();
  }

  handleTabSelect(tab) {
    this.selectedTabIx = tab.index;
  }

  loadAssetControlManagers() {
    this.equipmentService.getAssetControlManagers().subscribe((d) => {
      d.unshift(this.defaultAssetControlManagerItem);
      this.assetControlManagers = d;
    });
  }

  filterChangeJob($event) {
    this.manPowerService
      .searchJobs($event.query, this.showAllJobs ? null : true)
      .then((data) => {
        $event.extension.applyData(data);
      })
      .catch((error) => {
        $event.extension.applyData([]);
        throw error;
      });
  }

  isScoreCardTabVisible(): boolean {
    return this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_equipmentdashboardscorecardtab
    ).viewFeature;
  }
}
