import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';

import {
  AppError,
  BadInput,
  environment,
  MyHttpClientFactory,
  NotFoundError,
  ResponseCasingEnum,
} from '@ups/xplat/core';
import {
  getFullNameFromInfo,
  getPhysicalCityStateZip,
  getMailingCityStateZip,
  hasEmptyAddress,
} from '@ups/xplat/utils';
import { UserState } from '@ups/user';
import { IEmployeeInfo } from '@ups/xplat/api/dto';
import { EmployeeTeam } from '../models/employee-team';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  myInfo: IEmployeeInfo;
  fullName: string;
  hasEmptyPhysicalAddress: boolean;
  physicalCityStateZip: string;
  hasEmptyMailingAddress: boolean;
  mailingCityStateZip: string;
  private _myInfo$: BehaviorSubject<IEmployeeInfo>;

  constructor(
    httpFactory: MyHttpClientFactory,
    private httpClient: HttpClient,
    private ngZone: NgZone,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private store: Store<any>
  ) {
    this.httpClient = httpFactory.createHttpClient(
      environment.urls.viewpointAPI,
      true,
      ResponseCasingEnum.CamelCase
    );

    this.store.pipe(UserState.selectCurrentTruthy()).subscribe((x) => {
      this.ngZone.run(() => {
        this.myInfo = x;
        this.fullName = getFullNameFromInfo(this.myInfo);
        console.log('this.fullName:', this.fullName);
        this.physicalCityStateZip = getPhysicalCityStateZip(this.myInfo);
        this.hasEmptyPhysicalAddress = hasEmptyAddress(
          this.myInfo,
          'PhysicalAddress'
        );
        this.mailingCityStateZip = getMailingCityStateZip(this.myInfo);
        this.hasEmptyMailingAddress = hasEmptyAddress(
          this.myInfo,
          'MailingAddress'
        );
        if (this._myInfo$) {
          this._myInfo$.next(this.myInfo);
        }
      });
    });
  }

  get myInfo$() {
    if (!this._myInfo$) {
      this._myInfo$ = new BehaviorSubject(undefined);
    }
    return this._myInfo$;
  }

  getEmployeeTeamByHRRef(hrRef: number): Observable<EmployeeTeam[]> {
    return this.httpClient
      .get<EmployeeTeam[]>(`/api/hrrm/employee/org/${hrRef}`)
      .pipe(catchError(this.errorHandler));
  }

  // Error types could be anything
  // https://angular.io/api/core/ErrorHandler
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected errorHandler(error: any) {
    if (error.status === 404) return throwError(new NotFoundError());
    else if (error.status === 400) return throwError(new BadInput(error));
    else return throwError(new AppError(error));
  }
}
