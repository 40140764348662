import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tradeGroupName',
})
export class ScoreCardTradeGroupNamePipe implements PipeTransform {
  transform(
    items: any[],
    fieldToCompare: string,
    fieldToReturn: string,
    value: string
  ): any {
    let item = items.find((x) => x[fieldToCompare] === value);
    return item && item[fieldToReturn]
      ? 'Trade Group: ' + item[fieldToReturn]
      : '';
  }
}
