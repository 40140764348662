<div class="dr-admin-design-surface dr-admin-panel">
  <div class="dynamic-container" [formGroup]="drFormGroup">
    <ejs-dashboardlayout
      id="adminDesignLayout"
      [columns]="48"
      #adminDesignLayout
      [allowFloating]="allowFloating"
      [cellSpacing]="cellSpacing"
      [allowResizing]="allowResizing"
    >
    </ejs-dashboardlayout>
  </div>
</div>

<ng-template #viewContainer> </ng-template>

<div id="removeme">
  <ng-template #viewContainerRef></ng-template>
</div>
