<kendo-window
  [hidden]="!opened"
  (close)="close()"
  state="maximized"
  [resizable]="false"
  class="w-100 h-100"
  style="padding-bottom: 100px"
>
  <kendo-window-titlebar class="d-flex justify-content-between invert">
    <span>{{ title }}</span>
    <button kendoWindowCloseAction></button>
  </kendo-window-titlebar>

  <div class="d-flex flex-row">
    <ng-container *ngIf="isCollapsed; else expandedJobInfo">
      <section class="col-1">
        <a (click)="handleCollapseClick()">
          <i class="far fa-angle-right fa-lg float-right"></i>
        </a>

        <h4 class="mb-0 mh-100 p-5 rotated">Job Info</h4>
      </section>
    </ng-container>

    <ng-template #expandedJobInfo>
      <section class="col-4 ps-6 pe-10">
        <div id="jobInfoPanel" class="info-panel">
          <a (click)="handleCollapseClick()">
            <i class="far fa-angle-left fa-lg float-right"></i>
          </a>

          <div class="d-flex justify-content-between">
            <h4 class="mb-5">Job Info</h4>
          </div>

          <div class="d-flex align-items-center">
            <label class="w-25 me-3">Plant:</label>

            <p class="mb-0">
              {{ model.jobInfo?.plantName }}
              <br />
              {{ model.jobInfo?.plantAddress }}
            </p>
          </div>

          <div class="d-flex align-items-center">
            <label class="w-25 me-3">Description:</label>

            <div id="description-container">
              <div id="jobDescription"></div>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <label class="w-50 me-3">Equipment Billing Template:</label>

            <div>
              <p class="mb-0 ms-1">
                {{ model.jobInfo?.equipmentBillingTemplate }}
              </p>
            </div>
          </div>

          <label class="w-25 me-3">Contact:</label>

          <kendo-grid [data]="jobContact">
            <kendo-grid-column
              [title]="'Name'"
              [field]="'name'"
              [width]="120"
            ></kendo-grid-column>

            <kendo-grid-column
              [title]="'Phone'"
              [field]="'phoneNumber'"
              [width]="80"
            ></kendo-grid-column>

            <kendo-grid-column
              [title]="'Email'"
              [field]="'email'"
              [width]="200"
            ></kendo-grid-column>
          </kendo-grid>
        </div>
      </section>
    </ng-template>

    <section
      [ngClass]="
        isCollapsed ? 'border-left col-11 ps-5' : 'border-left col-8 ps-5'
      "
    >
      <div class="border-bottom mb-5 ms-0 p-0">
        <div id="equipmentInfoPanel" class="info-panel">
          <div class="d-flex justify-content-between">
            <h4 class="mb-5">Equipment Info</h4>
            <button
              *ngIf="switchEquipment"
              kendoButton
              (click)="cancelSwitchEquipment()"
              icon="close"
            ></button>
          </div>
          <div
            *ngIf="!model.equipmentID || switchEquipment"
            class="form-group row"
          >
            <div class="col-md-8">
              <ups-equipment-resource-dropdown
                [equipment]="equipment"
                (equipmentChanged)="valueChangeEquipment($event)"
                [jobID]="model.jobID"
                [jobVPCompanyID]="
                  model.jobInfo ? model.jobInfo.vpCompanyId : null
                "
                [equipmentTypeID]="model.equipmentTypeID"
                [disabled]="!canModify || !isEquipmentEditable()"
                [required]="isResourceRequired()"
              ></ups-equipment-resource-dropdown>
              <span class="p.small">
                You can choose equipment from the following companies that have
                the same equipment billing template that current job:{{
                  model?.jobInfo?.companiesWithSameEquipmentBillingTemplate?.join(
                    ','
                  )
                }}
              </span>
            </div>
          </div>
          <div *ngIf="showResourceConfirm" class="col-md-8">
            <ups-equipment-resource-confirm
              [equipmentResourceDto]="vpEquipment"
              [overlappingRequests]="overlappingRequests"
              (discardEquipment)="discardEquipment()"
              (confirmEquipment)="confirmEquipment()"
            ></ups-equipment-resource-confirm>
          </div>
          <div
            *ngIf="model.equipmentID && !switchEquipment"
            class="d-flex mb-9"
          >
            <div class="w-75">
              <h4 class="mb-3 subheading">Basic Info</h4>
              <ups-equipment-basic-info
                [vpEquipment]="vpEquipment"
                (switchEquipment)="switchEquipment = true"
              ></ups-equipment-basic-info>
            </div>
            <div class="w-25">
              <h4 class="mb-3 subheading">Rates</h4>
              <div class="d-flex" *ngFor="let rate of billingRates">
                <div class="w-50">
                  <label>{{ rate.UnitName }}:</label>
                </div>
                <div class="w-50">
                  <p class="mb-0">{{ rate.BillingRate }}</p>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="model.equipmentID">
            <h4 class="mb-5">Dispatch History</h4>
            <kendo-grid
              id="dispatchHistoryGrid"
              [data]="gridData"
              [pageSize]="state.take"
              [skip]="state.skip"
              [sort]="state.sort"
              [filter]="state.filter"
              [sortable]="true"
              [pageable]="true"
              [filterable]="'menu'"
              [loading]="isLoading"
              (dataStateChange)="dataStateChange($event)"
              class="white-bg toolbar-no-border rate-sheet pb-7"
            >
              <kendo-grid-column
                field="vpJobID"
                title="Job ID"
                [width]="100"
              ></kendo-grid-column>
              <kendo-grid-column
                field="startDate"
                title="Start Date"
                [width]="150"
                filter="date"
                format="{0:MM/dd/yyyy}"
              ></kendo-grid-column>
              <kendo-grid-column
                field="endDate"
                title="End Date"
                [width]="150"
                filter="date"
                format="{0:MM/dd/yyyy}"
              ></kendo-grid-column>
              <kendo-grid-column
                field="altID"
                title="Alt ID"
                [width]="150"
                filter="numeric"
              ></kendo-grid-column>
              <kendo-grid-column
                field="customerID"
                title="Customer ID"
              ></kendo-grid-column>
              <kendo-grid-column field="note" title="Note"></kendo-grid-column>
            </kendo-grid>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between align-items-center">
        <h4 class="mb-5">Processing and Job Requirements Info</h4>
        <span class="mb-5">
          <label class="me-3">Dispatch Status</label>
          <kendo-dropdownlist
            #dispatchStatus="ngModel"
            class="k-no-dropdown"
            name="dispatchStatus"
            [disabled]="false"
            [(ngModel)]="model.dispatchStatusID"
            [valuePrimitive]="true"
            [textField]="'text'"
            [valueField]="'value'"
            [data]="dispatchItems"
            style="width: 25rem"
            required
          ></kendo-dropdownlist>
        </span>
      </div>

      <kendo-tabstrip class="default-bg">
        <kendo-tabstrip-tab [title]="'Processing'" [selected]="true">
          <ng-template kendoTabContent>
            <div class="row">
              <div class="col-3 mb-5">
                <kendo-formfield [orientation]="'horizontal'">
                  <kendo-label
                    [for]="transportDate"
                    text="Transport Date"
                    class="mb-3"
                  ></kendo-label>
                  <ups-kendo-datepicker-shared
                    #transportDate="ngModel"
                    name="transportDate"
                    [disabled]="false"
                    [required]="false"
                    [(ngModel)]="model.transportDate"
                    [placeholder]="''"
                    [isFullWidth]="true"
                  ></ups-kendo-datepicker-shared>
                </kendo-formfield>
              </div>
              <div class="col-3 mb-5">
                <kendo-formfield [orientation]="'horizontal'">
                  <kendo-label
                    [for]="startDate"
                    text="Start Date"
                    class="mb-3"
                  ></kendo-label>
                  <ups-kendo-datepicker-shared
                    #startDate="ngModel"
                    name="startDate"
                    [disabled]="false"
                    [required]="true"
                    [(ngModel)]="model.startDate"
                    [placeholder]="''"
                    [isFullWidth]="true"
                  ></ups-kendo-datepicker-shared>
                </kendo-formfield>
              </div>
              <div class="col-3 mb-5">
                <kendo-formfield [orientation]="'horizontal'">
                  <kendo-label
                    [for]="endDate"
                    text="End Date"
                    class="mb-3"
                  ></kendo-label>
                  <ups-kendo-datepicker-shared
                    #endDate="ngModel"
                    name="endDate"
                    [disabled]="false"
                    [required]="true"
                    [(ngModel)]="model.endDate"
                    [placeholder]="''"
                    [min]="model.startDate"
                    [isFullWidth]="true"
                    [validWhen]="[
                      {
                        expression:
                          !model.startDate ||
                          !model.endDate ||
                          model.startDate <= model.endDate,
                        errorProperty: 'endDateIsBeforeStartDate',
                        errorMessage:
                          'End Date must be equal or greater than Start Date!'
                      }
                    ]"
                  ></ups-kendo-datepicker-shared>
                </kendo-formfield>
                <span
                  *ngIf="endDate.errors?.endDateIsBeforeStartDate"
                  class="text-danger"
                >
                  {{ endDate.errors?.endDateIsBeforeStartDate.message }}
                </span>
              </div>
              <div class="col-3 mb-5">
                <kendo-formfield>
                  <kendo-label
                    [for]="employee"
                    text="Asset Control Manager"
                    class="mb-3"
                  ></kendo-label>
                  <kendo-combobox
                    [name]="'employee'"
                    [(ngModel)]="model.assetControlManagerID"
                    [valuePrimitive]="true"
                    [textField]="'name'"
                    [valueField]="'id'"
                    [filterable]="true"
                    search-ex
                    [seInitialData]="
                      assetControlManager && [assetControlManager]
                    "
                    [seSearchColumns]="['Name']"
                    (seFilterChangeEx)="filterChangeEmployee($event)"
                    [seDebounceFilterChange]="1000"
                    [seMinSearchLength]="3"
                    class="w-100"
                  ></kendo-combobox>
                </kendo-formfield>
              </div>
              <div class="col-3 mb-5">
                <kendo-formfield>
                  <kendo-label text="Needed By Time"></kendo-label>
                  <kendo-timepicker
                    #timeNeeded="ngModel"
                    name="timeNeeded"
                    [(ngModel)]="model.timeNeeded"
                    class="w-100"
                    help-anchor="neededByTime"
                  ></kendo-timepicker>
                </kendo-formfield>
              </div>
              <div class="col-3 mb-5">
                <kendo-formfield>
                  <kendo-label text="Billable"></kendo-label>
                  <kendo-dropdownlist
                    #isBillable="ngModel"
                    name="isBillable"
                    [(ngModel)]="model.isBillable"
                    class="w-100"
                    help-anchor="isBillable"
                    required
                    [textField]="'text'"
                    [valueField]="'value'"
                    [valuePrimitive]="true"
                    [data]="billableItems"
                    (valueChange)="valueChangeBillable($event)"
                  ></kendo-dropdownlist>
                </kendo-formfield>
              </div>
              <div class="col-3 mb-5">
                <kendo-formfield>
                  <kendo-label text="Purchase Order Number"></kendo-label>
                  <kendo-combobox
                    #poNumber="ngModel"
                    name="poNumber"
                    [(ngModel)]="model.poNumber"
                    class="w-100"
                    help-anchor="purchaseOrderNumber"
                    [allowCustom]="true"
                    [data]="filteredPos"
                    (valueChange)="valueChangePO($event)"
                  ></kendo-combobox>
                </kendo-formfield>
              </div>
              <div class="col-3 mb-5">
                <kendo-formfield>
                  <kendo-label text="Phase/Work Order"></kendo-label>
                  <kendo-combobox
                    #phase="ngModel"
                    name="phase"
                    [(ngModel)]="model.phaseId"
                    class="w-100"
                    help-anchor="phaseWorkOrder"
                    [textField]="'phaseName'"
                    [valueField]="'phaseID'"
                    [valuePrimitive]="true"
                    [allowCustom]="true"
                    [data]="filteredWorkOrders"
                  >
                    <ng-template kendoComboBoxItemTemplate let-dataItem>
                      {{ dataItem.phaseName }} ({{ dataItem.description }}) -
                      {{ dataItem.po }}
                    </ng-template>
                  </kendo-combobox>
                </kendo-formfield>
              </div>
              <div class="col-3 mb-5">
                <kendo-label text="Requested By"></kendo-label>
                <kendo-textbox
                  name="submittedUserName"
                  [disabled]="true"
                  [(ngModel)]="model.submittedUserName"
                  class="w-100"
                ></kendo-textbox>
              </div>

              <div class="col-3 mb-5">
                <kendo-label
                  [for]="submittedDate"
                  text="Requested Date"
                ></kendo-label>
                <ups-kendo-datepicker-shared
                  #submittedDate
                  name="submittedDate"
                  [(ngModel)]="model.dateSubmitted"
                  [isFullWidth]="true"
                  [disabled]="true"
                ></ups-kendo-datepicker-shared>
              </div>
              <div class="col-3 mb-5">
                <kendo-label text="Modified By"></kendo-label>
                <kendo-textbox
                  name="modifiedUserName"
                  [disabled]="true"
                  [(ngModel)]="model.modifiedUserName"
                  class="w-100"
                ></kendo-textbox>
              </div>
              <div class="col-3 mb-5">
                <kendo-label
                  [for]="modifiedDate"
                  text="Modified Date"
                ></kendo-label>
                <ups-kendo-datepicker-shared
                  #submittedDate
                  name="modifiedDate"
                  [(ngModel)]="model.dateModified"
                  [isFullWidth]="true"
                  [disabled]="true"
                ></ups-kendo-datepicker-shared>
              </div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab
          *ngFor="let jr of model.jobRequirements; let ix = index"
        >
          <ng-template kendoTabTitle *ngIf="isValidJobRequirement(jr)">
            <span>{{ jr.requirement }}</span>
          </ng-template>
          <ng-template kendoTabTitle *ngIf="!isValidJobRequirement(jr)">
            <div kendoTooltip>
              <span
                class="attention"
                title="All Requirements and Sub Requirements must be in final state before setting request to Dispatched."
              >
                {{ jr.requirement + ' !' }}
              </span>
            </div>
          </ng-template>
          <ng-template kendoTabContent>
            <div class="info-panel">
              <label class="col-sm-1">Status</label>
              <kendo-dropdownlist
                [data]="jr.statuses"
                [textField]="'name'"
                [valueField]="'equipmentRequirementStatusID'"
                [valuePrimitive]="true"
                [(ngModel)]="jr.equipmentRequirementStatusID"
                (valueChange)="valueChangeReqStatus($event, jr)"
              ></kendo-dropdownlist>
              <div id="subtask-container">
                <div class="row">
                  <div *ngFor="let sub of jr.subRequirements" class="col-sm-2">
                    <input
                      type="checkbox"
                      kendoCheckBox
                      [(ngModel)]="sub.bChecked"
                    />
                    <label>{{ sub.name }}</label>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Notes'">
          <ng-template kendoTabContent>
            <div class="row">
              <div class="col-md-6">
                <textarea
                  kendoTextArea
                  name="notes"
                  [disabled]="true"
                  [rows]="8"
                  [(ngModel)]="model.notes"
                ></textarea>
              </div>
              <div class="col-md-6">
                <label>Add Notes</label>
                <textarea
                  kendoTextArea
                  name="newnotes"
                  [disabled]="false"
                  [(ngModel)]="model.newNotes"
                ></textarea>
              </div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>
    </section>
  </div>

  <div class="d-flex flex-row border-top pt-5">
    <ups-equipment-resource
      *ngIf="model.equipmentID"
      [equipmentResource]="vpEquipment"
    ></ups-equipment-resource>
  </div>

  <kendo-card-footer
    class="text-end footer fixed-bottom"
    [attr.disabled]="blockUi.isActive || isSaving ? '' : null"
  >
    <button kendoButton class="me-3" (click)="close()">
      {{ canModify ? 'Cancel' : 'Close' }}
    </button>
    <button
      *ngIf="canModify"
      kendoButton
      [primary]="'true'"
      [disabled]="!isValid()"
      (click)="onSave()"
    >
      <span *ngIf="!blockUi.isActive && !isSaving">Save</span>
      <span *ngIf="blockUi.isActive || isSaving">
        <i class="fa fa-spinner fa-spin"></i>
        Saving...
      </span>
    </button>
  </kendo-card-footer>
</kendo-window>

<kendo-dialog
  modal
  fade
  title="Unsaved Changes"
  [hidden]="!showConfirm"
  (close)="showConfirm = false"
  [width]="550"
  class="modal-above"
>
  <p class="mb-0">Do you really want to Cancel Unsaved Changes?</p>

  <kendo-dialog-actions [layout]="'normal'">
    <button class="k-button" (click)="showConfirm = false">
      No, Return to Edit
    </button>
    <button
      class="k-button k-primary"
      (click)="showConfirm = false; opened = false"
    >
      Yes, Cancel Changes
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
