import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
  SimpleChange,
} from '@angular/core';
import { EquipmentDto } from '@ups/xplat/api/dto';
import { lastValueFrom, of, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { EquipmentService } from '@ups/xplat/api/services';
import { nameOf } from '@ups/xplat/utils';
import { BaseComponent } from '@ups/xplat/core';

@Component({
  selector: 'ups-equipment-resource-dropdown',
  templateUrl: './equipment-resource-dropdown.component.html',
})
export class EquipmentResourceDropdownComponent
  extends BaseComponent
  implements OnChanges
{
  @Input() disabled: boolean;
  @Input() jobID: string;
  @Input() jobVPCompanyID: number;
  @Input() equipmentBillingTemplate: string;
  @Input() equipmentTypeID: string;
  @Input() required = false;

  @Input() equipment: EquipmentDto;
  @Output() equipmentChanged = new EventEmitter<EquipmentDto>();

  companyEquipment: EquipmentDto;
  filteredEquipments: EquipmentDto[] = [];
  selectedEquipmentOtherCompanies: EquipmentDto[] = [];
  isEquipmentLoading = false;
  isEquipmentFilterLoading = false;
  equipmentFilterDebounce: Subject<{
    searchString: string;
    searchNameOnly: boolean;
    forceLoad: boolean;
  }> = new Subject<{
    searchString: string;
    searchNameOnly: boolean;
    forceLoad: boolean;
  }>();

  previousEquipmentValue: EquipmentDto = null;

  constructor(private equipmentService: EquipmentService) {
    super();
    this.equipmentFilterDebounce
      .pipe(debounceTime(500), takeUntil(this.destroy$))
      .subscribe((f) =>
        this.fetchFilteredEquipment(
          f.searchString,
          f.searchNameOnly,
          f.forceLoad
        )
      );
  }

  ngOnChanges(valuesChanged: SimpleChanges) {
    const equipmentChange: SimpleChange =
      valuesChanged[nameOf<EquipmentResourceDropdownComponent>('equipment')];
    const equipment: EquipmentDto = equipmentChange
      ? equipmentChange.currentValue
      : null;
    const equipmentPrevious: EquipmentDto = equipmentChange
      ? equipmentChange.previousValue
      : null;

    const isSelectedNewEquipment: boolean = this.isDifferentEquipment(
      equipment,
      equipmentPrevious
    );

    if (isSelectedNewEquipment) {
      this.isEquipmentLoading = true;
      const promise = this.fetchFilteredEquipment(
        equipment ? equipment.Name : null,
        true,
        true
      );
      promise.then(() => {
        this.valueChangeEquipment(equipment);
        this.isEquipmentLoading = false;
      });
    } else {
      this.fetchFilteredEquipment(null, false, true);
    }
  }

  valueChangeCompany(selectedEquipment: EquipmentDto): void {
    this.equipment = selectedEquipment;
    this.equipmentChanged.emit(selectedEquipment);
  }

  valueChangeEquipment(selectedEquipment: EquipmentDto): void {
    const isDifferent: boolean = this.isDifferentEquipment(
      selectedEquipment,
      undefined
    );
    if (selectedEquipment) {
      this.equipment = selectedEquipment;
      this.selectedEquipmentOtherCompanies = this.filteredEquipments.filter(
        (e) => e.Name === selectedEquipment.Name
      );
    } else {
      this.equipment = null;
      this.selectedEquipmentOtherCompanies = [];
    }

    this.companyEquipment = selectedEquipment;
    if (!isDifferent) {
      return;
    }

    this.equipmentChanged.emit(selectedEquipment);
  }

  fetchFilteredEquipmentDebounced(
    searchString: string,
    searchNameOnly: boolean,
    forceLoad: boolean = false
  ) {
    this.equipmentFilterDebounce.next({
      searchString,
      searchNameOnly,
      forceLoad,
    });
  }

  fetchFilteredEquipment(
    searchString: string,
    searchNameOnly: boolean,
    forceLoad: boolean = false
  ): Promise<void> {
    if (
      this.jobID &&
      (this.equipmentBillingTemplate || this.equipmentTypeID) &&
      (searchString || forceLoad)
    ) {
      this.isEquipmentFilterLoading = true;
      const callSubscription = this.equipmentService.getEquipments(
        this.jobID,
        this.equipmentBillingTemplate,
        this.equipmentTypeID,
        searchString,
        0 /* Hotfix #14476 */,
        searchNameOnly,
        true // We search equipment across all companies: Hotfix  #18518 previously partially fixed in #14476 and US-2049
      );

      return lastValueFrom(callSubscription)
        .then((d) => {
          this.filteredEquipments = d;
        })
        .finally(() => (this.isEquipmentFilterLoading = false));
    } else {
      return of<void>().toPromise();
    }
  }

  isDifferentEquipment(
    equipment: EquipmentDto,
    previousEquipment: EquipmentDto
  ): boolean {
    previousEquipment =
      previousEquipment !== undefined
        ? previousEquipment
        : this.previousEquipmentValue;
    const isDifferent: boolean =
      (equipment == null && previousEquipment != null) ||
      (equipment != null && previousEquipment == null) ||
      (equipment != null &&
        previousEquipment != null &&
        (equipment.DisplayName !== previousEquipment.DisplayName ||
          (equipment.EquipmentIDs ? equipment.EquipmentIDs : []).filter(
            (id) =>
              (previousEquipment.EquipmentIDs
                ? previousEquipment.EquipmentIDs
                : []
              ).indexOf(id) === -1
          ).length > 1));
    this.previousEquipmentValue = equipment;
    return isDifferent;
  }
}
