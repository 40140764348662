import { createReducer, on, Action } from '@ngrx/store';
import { IVacationAndSickBalance } from '../models';
import { UserActions } from './user.actions';
import { UserState } from './user.state';

const reducer = createReducer(
  UserState.initialState,
  on(UserActions.resetState, (state) => ({})),
  on(UserActions.loadCurrentSuccess, (state, { payload }) => ({
    ...state,
    current: payload || null,
  })),
  on(UserActions.loadRolesSuccess, (state, { payload }) => ({
    ...state,
    roles: payload || null,
  })),
  on(UserActions.checkAddressChangeRequestSuccess, (state, { data }) => {
    return {
      ...state,
      pendingPhysicalAddress: data || null,
    };
  }),
  on(UserActions.updateSuccess, (state: UserState.IState, { data }) => {
    const updatedState = {
      ...state,
    };
    if (
      data &&
      data.HRRef &&
      state.current &&
      state.current.Data &&
      state.current.Data.HRRef === data.HRRef
    ) {
      // updating current user
      updatedState.current = {
        ...state.current,
        Data: {
          ...state.current.Data,
          ...data,
        },
      };
    } else {
      // updating another user
      updatedState.selected = {
        ...state.selected,
        Data: {
          ...state.selected.Data,
          ...data,
        },
      };
    }
    return updatedState;
  }),
  on(UserActions.loadAttachmentsSuccess, (state, { payload }) => ({
    ...state,
    attachments: payload || null,
  })),
  on(UserActions.loadEContactsSuccess, (state, { payload }) => ({
    ...state,
    emergencyContacts: payload,
  })),
  on(UserActions.loadMulticlassSuccess, (state, { payload }) => ({
    ...state,
    multiclass: payload,
  })),
  on(UserActions.setDefaultPage, (state: UserState.IState, { path }) => ({
    ...state,
    preferences: {
      ...(state.preferences || {}),
      defaultPage: path,
    },
  })),
  on(UserActions.clearDefaultPage, (state: UserState.IState) => ({
    ...state,
    preferences: {
      ...(state.preferences || {}),
      defaultPage: null,
    },
  })),
  on(
    UserActions.loadVacationAndSickBalanceSuccess,
    (state, payload: { vacationAndSickBalance: IVacationAndSickBalance }) => {
      return {
        ...state,
        vacationAndSickBalance: payload.vacationAndSickBalance,
      };
    }
  ),
  on(UserActions.loadVacationAndSickBalanceFailed, (state) => {
    return {
      ...state,
      vacationAndSickBalance: null,
    };
  }),
  on(UserActions.setLoggedInSecurityUserId, (state, { id }) => ({
    ...state,
    securityUserId: id,
  }))
);

export function userReducer(
  state: UserState.IState | undefined,
  action: Action
) {
  return reducer(state, action);
}
