import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { IDynamicControl } from '@ups/xplat/core';
import { DynamicRenderService } from '@ups/xplat/features';

import { AdminToolboxPanelItem } from '../../../models/dr-admin-panel-item-models';

@Component({
  selector: 'admin-toolbox',
  templateUrl: 'admin-toolbox.component.html',
  styleUrls: ['admin-toolbox.component.scss'],
  styles: [],
})
export class AdminToolboxComponent implements OnChanges {
  @Input() toolboxItems: AdminToolboxPanelItem[] = [];
  @Input() reusableControls: IDynamicControl[] = [];

  filteredToolboxItems: AdminToolboxPanelItem[] = [];
  filteredReusableControls: IDynamicControl[] = [];

  open = { normal: true };

  public get toolboxGroupForm(): AdminToolboxPanelItem[] {
    return this.toolboxItems.filter((i) => i.toolboxGroup === 'form');
  }

  public get toolboxGroupDashboard(): AdminToolboxPanelItem[] {
    return this.toolboxItems.filter((i) => i.toolboxGroup === 'dashboard');
  }

  public get toolboxGroupDrawing(): AdminToolboxPanelItem[] {
    return this.toolboxItems.filter((i) => i.toolboxGroup === 'drawing');
  }

  public get toolboxGroupSafety(): AdminToolboxPanelItem[] {
    return this.toolboxItems.filter((i) => i.toolboxGroup === 'safety');
  }

  @Output()
  public toolboxItemSelected: EventEmitter<AdminToolboxPanelItem> = new EventEmitter();
  @Output()
  public toolboxReusableItemSelected: EventEmitter<IDynamicControl> = new EventEmitter();

  constructor(public dynamicRender: DynamicRenderService) {}

  ngOnChanges() {
    this.filterChange('');
  }

  public onToolboxItemClick(item: AdminToolboxPanelItem) {
    this.toolboxItemSelected.emit(item);
  }

  public onReusableToolboxItemClick(item: IDynamicControl) {
    this.toolboxReusableItemSelected.emit(item);
  }

  isOpenChange(name, args) {
    this.open[name] = args;
  }

  filterChange(text) {
    if (text) {
      this.filteredToolboxItems = this.toolboxItems.filter(
        (i) => i.displayName.toLowerCase().indexOf(text.toLowerCase()) !== -1
      );
      this.filteredReusableControls = this.reusableControls.filter(
        (i) =>
          i.dynamicControlName.toLowerCase().indexOf(text.toLowerCase()) !== -1
      );
    } else {
      this.filteredToolboxItems = this.toolboxItems;
      this.filteredReusableControls = this.reusableControls;
    }
  }
}
