import { BaseModel } from '../../base.model';
import { ExpertiseTypeEnum } from './ExpertiseTypeEnum';

export class ExpertiseDto extends BaseModel {
  ExpertiseId: string | null;
  ExpertiseName: string;
  ExpertiseTypeId: number | ExpertiseTypeEnum;
  IsActive: boolean | null;
  VpCompanyIds: (number | string)[]; // Are only numbers, but have also string type possible to be compatible with CompanyDto that can have vpcompanyid as string or number

  constructor(o: any = null) {
    super(o);

    if (!this.VpCompanyIds) {
      this.VpCompanyIds = [];
    }
  }
}
