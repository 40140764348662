import { InjectionToken, signal, WritableSignal } from '@angular/core';

export interface ProfileData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  profile: any;
}

export const PROFILE_INFO_PROVIDER_TOKEN = new InjectionToken<
  WritableSignal<ProfileData>
>('PROFILE_INFO_PROVIDER_TOKEN', {
  providedIn: 'root',
  factory: () => signal({ profile: undefined }),
});
