import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  DataStateChangeEvent,
  GridDataResult,
  RowClassArgs,
} from '@progress/kendo-angular-grid';
import { TrainingSubtaskEditorComponent } from '../training-subtask-editor/training-subtask-editor.component';
import {
  ManPowerRequirementDto,
  ManPowerSubRequirementDto,
} from '@ups/xplat/api/dto';
import { Guid } from '@ups/xplat/core';
import { GroupDescriptor, process, State } from '@progress/kendo-data-query';

@Component({
  selector: 'ups-training-subtask-list',
  templateUrl: './training-subtask-list.component.html',
})
export class TrainingSubtaskListComponent implements OnInit {
  @Input() companyId: string;
  @Input() isReadOnly = true;
  @Input() requirement: ManPowerRequirementDto;

  @ViewChild(TrainingSubtaskEditorComponent)
  editModal: TrainingSubtaskEditorComponent;

  gridData: GridDataResult;

  state: State = {
    group: <GroupDescriptor[]>[{ field: 'GroupName' }],
  };

  ngOnInit() {
    this.gridData = process(this.requirement.SubRequirements, this.state);
  }

  onEditSubtask(manPowerSubRequirement: ManPowerSubRequirementDto) {
    if (manPowerSubRequirement.ManPowerSubRequirementID) {
      this.requirement.SubRequirements = this.requirement.SubRequirements.map(
        (subRequirement) => {
          if (
            subRequirement.ManPowerSubRequirementID ===
            manPowerSubRequirement.ManPowerSubRequirementID
          ) {
            return manPowerSubRequirement;
          }

          return subRequirement;
        }
      );
    } else {
      manPowerSubRequirement.ManPowerSubRequirementID = Guid.newGuid();
      manPowerSubRequirement.ManPowerRequirementID =
        this.requirement.ManPowerRequirementID;
      this.requirement.SubRequirements.unshift(manPowerSubRequirement);
    }

    this.gridData = process(this.requirement.SubRequirements, this.state);
  }

  public dataStateChange(state: DataStateChangeEvent | State): void {
    this.state = state;
    this.gridData = process(this.requirement.SubRequirements, this.state);
  }

  rowCallback(context: RowClassArgs) {
    return {
      warning: context.dataItem.bActive === false,
    };
  }
}
