import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import { DashboardLayoutComponent } from '@syncfusion/ej2-angular-layouts';
import {
  IDynamicContainer,
  IDynamicModel,
  IDynamicModelGroup,
} from '@ups/xplat/core';

import { DynamicRenderService } from '@ups/xplat/features';

@Component({
  selector: 'ups-dynamic-sf-container',
  template: `
    <div class="dynamic-container">
      <ejs-dashboardlayout
        id="defaultLayout"
        [columns]="48"
        #defaultLayout
        [cellSpacing]="cellSpacing"
      >
        <div
          class="e-panel"
          *ngFor="let item of dynamicItems"
          [attr.data-row]="item.options?.layout?.row"
          [attr.data-col]="item.options?.layout?.col"
          [attr.data-sizeX]="item.options?.layout?.sizeX"
          [attr.data-sizeY]="item.options?.layout?.sizeY"
        >
          <ng-container
            dynamicItem
            [config]="item"
            [group]="form"
          ></ng-container>
        </div>
      </ejs-dashboardlayout>
    </div>
  `,
})
export class DynamicSfContainerComponent implements OnInit {
  @Input() public dynamicGroup: IDynamicModelGroup;

  @ViewChild('defaultLayout') public dashboard: DashboardLayoutComponent;

  public cellSpacing = [4, 4];
  public cellAspectRatio = 100 / 50;

  public get dynamicItems(): IDynamicModel[] {
    // only using first control group. need to handle multiple
    if (this.dynamicGroup && this.dynamicGroup.controls) {
      return this.dynamicGroup.controls;
    } else {
      return [];
    }
  }

  public form: UntypedFormGroup; // single form group, need to expand to handle multiple

  constructor(
    private fb: UntypedFormBuilder,
    private dynamicService: DynamicRenderService
  ) {}

  public ngOnInit() {
    this.dynamicService.activeForm = this.form = this.fb.group({});
  }
}
