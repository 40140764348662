import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {
  DynamicContainerBaseComponent,
  DynamicRenderService,
} from '@ups/xplat/features';

@Component({
  selector: 'ups-dynamic-container',
  styleUrls: ['dynamic-container.component.scss'],
  template: `
    <div class="dynamic-container">
      <div>
        <ng-container
          *ngFor="let item of dynamicItems"
          dynamicItem
          [config]="item"
          [group]="form"
        ></ng-container>
      </div>
    </div>
  `,
})
export class DynamicContainerComponent extends DynamicContainerBaseComponent {
  constructor(fb: UntypedFormBuilder, dynamicService: DynamicRenderService) {
    super(fb, dynamicService);
  }
}
