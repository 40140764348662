import { IInventoryCounter } from '@ups/tool-tracker';
import { InventoryEntry } from './InventoryEntry';

export class WarehouseInventory {
  inventoryId: number;
  inventoryCo: number;
  inventoryName: string;
  inventoryStatusName: string;
  inventoryLocation: number;
  inventoryStatusId: number;
  nextInventoryStatusId: number;
  previousInventoryStatusId: number;
  countStartDate: Date;
  lastUpdated: Date;
  performedBy: IInventoryCounter[];
  percentComplete: number;
  itemsLeft: number;
  verificationPercent: number;
  inventoryEntries: InventoryEntry[];
}
