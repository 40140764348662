import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NotesComponent } from './notes.component';

@NgModule({
  declarations: [NotesComponent],
  imports: [FormsModule],
  exports: [NotesComponent],
})
export class NotesModule {}
