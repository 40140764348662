import {
  AndExpression,
  ExpressionValueEvaluatorFnc,
  LogicalExpressionType,
  OrExpression,
} from './typescript';

export function timeDiff(newTime: number, oldTime: number) {
  const timeFactor = 1000;
  // TODO: add minutes/hours support
  return Math.abs((newTime - oldTime) / timeFactor);
}

/*
  Logical expression evaluator expects a specific structure of values <T>.

    Simple expressions:
      true
      "security_id"

    Array of simple expressions (evaluated separately then the results are OR-ed):
      [true]
      [false, "security_id"]
      ["security_id_one", "security_id_two"]

    Or a complex expression object of type:
      OrExpression<T> or AndExpression<T>,
      Where the expression expects an array of (simple expressions | OrExpression<T> | AndExpression<T>)

      { or: ["security_id_one", "security_id_two"]}
      { and: [ "sexurity_id_main", { or: ["security_id_one", "security_id_two"]} ]}

    Every value <T> is then evaluated as truthy or falsy - ot it can be evaluated via an (expression: T, ...params: any[]) => boolean; function:
      return LogicalExpressionEvaluator.evaluateLogicalExpession(expr, (value, securityService) => securityService.hasAccess(value) );
*/
export class LogicalExpressionEvaluator {
  public static evaluate<T>(
    expr: LogicalExpressionType<T>,
    valueEvaluatorFnc: ExpressionValueEvaluatorFnc<T>
  ): boolean {
    return LogicalExpressionEvaluator.evaluateLogicalExpession(
      expr,
      valueEvaluatorFnc
    );
  }

  protected static evaluateLogicalExpession<T>(
    expr: LogicalExpressionType<T>,
    evaluatorFnc: (args: unknown) => boolean
  ): boolean {
    if (expr == null || expr === undefined)
      return this.evaluateSimpleLogicalExpression(expr, evaluatorFnc);

    let result: boolean;

    if ((expr as OrExpression<T>).or) {
      result = false;
      for (const item of (expr as OrExpression<T>).or)
        result = result || this.evaluateLogicalExpession(item, evaluatorFnc);
    } else if ((expr as AndExpression<T>).and) {
      result = true;
      for (const item of (expr as AndExpression<T>).and)
        result = result && this.evaluateLogicalExpession(item, evaluatorFnc);
    } else if (Array.isArray(expr)) {
      result = false;
      for (const item of expr)
        result = result || this.evaluateLogicalExpession(item, evaluatorFnc);
    } else {
      result = this.evaluateSimpleLogicalExpression(expr, evaluatorFnc);
    }

    return result;
  }

  protected static evaluateSimpleLogicalExpression<T>(
    expr: LogicalExpressionType<T>,
    evaluatorFnc: (args: unknown) => boolean
  ): boolean {
    return evaluatorFnc ? evaluatorFnc(expr) : !!expr;
  }
}
