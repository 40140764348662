import { Injectable } from '@angular/core';
import { DropPosition, RowReorderEvent } from '@progress/kendo-angular-grid';

@Injectable({
  providedIn: 'root',
})
export class GridReorderService {
  getNewSortOrder(
    dragIndex: number,
    dropIndex: number,
    dropPosition: DropPosition
  ): number {
    if (dropPosition === 'before') {
      if (dragIndex < dropIndex) {
        return dropIndex - 1;
      } else {
        return dropIndex;
      }
    } else if (dropPosition === 'after') {
      if (dragIndex < dropIndex) {
        return dropIndex;
      } else {
        return dropIndex + 1;
      }
    }
  }

  swapItemsInArray<T>(rowReorderEvent: RowReorderEvent, gridData): T[] {
    const draggedItem = rowReorderEvent.draggedRows[0].dataItem;
    const dragIndex = rowReorderEvent.draggedRows[0].rowIndex;
    const dropIndex = rowReorderEvent.dropTargetRow.rowIndex;

    const sortOrder = this.getNewSortOrder(
      dragIndex,
      dropIndex,
      rowReorderEvent.dropPosition
    );

    const items = gridData;
    items.splice(dragIndex, 1);
    items.splice(sortOrder, 0, draggedItem);

    return items;
  }
}
