import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { TextBoxComponent } from '@progress/kendo-angular-inputs';

import { fromEvent } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Key } from 'ts-key-enum';

import { BaseComponent } from '@ups/xplat/core';

export interface IPickerConfig {
  pickerElement?: any;
  rootUiPath?: string;
  allowSelectionOutsideRoot?: boolean;
  allowSelectRoot?: boolean;
}

export interface IPickerCallbackArgs {
  walkthroughPath: string;
}

export const ELEMENT_PICKER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ElementPickerComponent),
  multi: true,
};

/** Component works with absolute (fully qualified) help-paths! */
@Component({
  selector: 'help-element-picker',
  template: `
    <kendo-textbox
      [readonly]="true"
      [disabled]="disabled"
      [value]="value"
      (click)="pick()"
      placeholder="Pick an Element"
      class="w-100 cursor-pointer"
    >
      <ng-template kendoTextBoxPrefixTemplate>
        <i class="k-icon k-i-menu"></i>
        <kendo-textbox-separator></kendo-textbox-separator>
      </ng-template>
    </kendo-textbox>
  `,
  styles: [':host { display: block; }'],
  encapsulation: ViewEncapsulation.None,
  providers: [ELEMENT_PICKER_VALUE_ACCESSOR],
})
export class ElementPickerComponent extends BaseComponent {
  config: IPickerConfig = {};
  inSelectionMode = false;

  @ViewChild(TextBoxComponent) textbox: TextBoxComponent;

  private _value: string;
  public disabled: boolean;

  set value(value: string) {
    this._value = value;
    this.notifyValueChange();
  }

  get value(): string {
    return this._value || null;
  }

  onChange: (value) => {};
  onTouched: () => {};

  constructor(public renderer: Renderer2) {
    super();

    fromEvent(document.body, 'mouseover')
      .pipe(
        takeUntil(this.destroy$),
        filter((x) => this.inSelectionMode)
      )
      .subscribe((event: MouseEvent) => {
        this.wtHighlighterHandler(event);
      });

    fromEvent(document.body, 'keydown')
      .pipe(
        takeUntil(this.destroy$),
        filter((x) => this.inSelectionMode)
      )
      .subscribe((event: KeyboardEvent) => {
        if (event.key === Key.Escape) {
          this.stopSelectionMode();
        }
      });

    fromEvent(document.body, 'contextmenu')
      .pipe(
        takeUntil(this.destroy$),
        filter((x) => this.inSelectionMode)
      )
      .subscribe((event: MouseEvent) => {
        this.onClickListener();
        event.preventDefault();
        event.stopImmediatePropagation();
      });
  }

  onClickListener = () => {
    if (this.inSelectionMode /* && this.highlightedWtElement */) {
      const walkthroughPath = this.highlightedWtElement
        ? this.highlightedWtElement.getAttribute('help-path')
        : undefined;
      this.value = walkthroughPath;
      this.stopSelectionMode();
    }
  };

  ee = new EventEmitter<any>();

  selectionSubscription;

  public pick() {
    // stop existing selection

    console.log('Start Picking');
    this.stopSelectionMode();

    setTimeout(
      () => {
        // start new selection
        this.startSelectionMode();
        if (this.onTouched) {
          this.onTouched();
        }
      },
      // NOTE: must avoid that the global on-click event propagation kills the newly added subscription
      100
    );
  }

  startSelectionMode() {
    this.inSelectionMode = true;
  }

  stopSelectionMode() {
    if (this.selectionSubscription) this.selectionSubscription.unsubscribe();

    if (this.config.pickerElement)
      this.renderer.removeClass(
        this.config.pickerElement,
        'walkthrough-selection-highlight'
      );

    if (this.highlightedWtElement) {
      this.renderer.removeClass(
        this.highlightedWtElement,
        'walkthrough-selection-highlight'
      );
      this.highlightedWtElement = undefined;
    }

    this.inSelectionMode = false;
    this.config = {};
  }

  highlightedWtElement;

  wtHighlighterHandler(ev: any): any {
    let el = ev.target;

    if (
      ev.target === document.body ||
      (this.highlightedWtElement && this.highlightedWtElement === el)
    )
      return;

    if (this.highlightedWtElement) {
      this.renderer.removeClass(
        this.highlightedWtElement,
        'walkthrough-selection-highlight'
      );
      this.highlightedWtElement = undefined;
    }

    // element must be a WT item - or some of it's parent has to be a WT item
    const searchParents = true;

    do {
      if (!el) break;
      const elWalkthroughPath = el.getAttribute('help-path');
      const isEditor = el.classList.contains('walkthrough-editor');
      const isMatch = elWalkthroughPath && !isEditor;
      if (isMatch || !searchParents) break;
      el = el.parentElement;
    } while (true);

    const walkthroughPath = el ? el.getAttribute('help-path') : undefined;

    if (el && walkthroughPath) {
      // select & highlight
      this.highlightedWtElement = el;
      this.renderer.addClass(el, 'walkthrough-selection-highlight');
    }
  }

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(obj: string): void {
    this._value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
