import { Component } from '@angular/core';
import { AppBaseComponent } from '@ups/xplat/web/core';
import { menuConfig } from './_layout/menu/config';

@Component({
  selector: 'ups-root',
  templateUrl: './app.component.html',
})
export class AppComponent extends AppBaseComponent {
  constructor() {
    super();
    this.menuService.configureMenu(menuConfig);
  }
}
