/* eslint-disable @typescript-eslint/naming-convention */
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UserService } from '@ups/user';
import { UpsApiHttpErrorResponseCamelCase } from '@ups/common';
import { EventBusService, IFormReview } from '@ups/xplat/core';
import { DynamicItemBaseComponent } from '@ups/xplat/features';
import { MessageHelper } from '@ups/xplat/web/core';
import { filter, takeUntil } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';

@Component({
  selector: 'ups-dynamic-review',
  styleUrls: ['dynamic-review.component.scss'],
  templateUrl: 'dynamic-review.component.html',
})
export class DynamicReviewComponent
  extends DynamicItemBaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('approvalResult') approvalResult: ElementRef;
  @ViewChild('reviewNotes') reviewNotes: ElementRef;

  showCancelConfirm = false;
  showDeleteConfirm = false;
  selectedButton = '';
  formReview: IFormReview;
  comment = '';
  approvalComments = '';
  reviewedBy = '';
  dateReviewed = '';
  isSaving = false;

  initialState = {
    selectedButton: null,
    comments: null,
  };

  dynamicComment = {
    isCommenting: false,
    commentText: '',
  };

  isReadOnly = false;
  isSubmittedByLoggedInUser = false;

  canReviewForms = false;
  canSelfReviewForms = false;
  canEditForms = false;
  canEditApprovedForms = false;
  canEditRejectedForms = false;

  canViewApprovalComments = false;
  canReadAllApprovalComments = false;
  canReadSelfApprovalComments = false;
  canEditAllApprovalComments = false;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(
    private msg: MessageHelper,
    private _eventBus: EventBusService,
    public security: SecurityService,
    public userService: UserService
  ) {
    super();

    this.canReviewForms = this.security.getFeatureById(
      SecurityConstants.safety_2_0_forms_canreviewforms
    ).editAll;
    this.canSelfReviewForms = this.security.getFeatureById(
      SecurityConstants.safety_2_0_forms_canreviewforms
    ).editSelf;
    this.canEditForms = this.security.getFeatureById(
      SecurityConstants.safety_2_0_forms_caneditforms
    ).editAll;
    this.canEditApprovedForms = this.security.getFeatureById(
      SecurityConstants.safety_2_0_forms_caneditacceptedforms
    ).editAll;
    this.canEditRejectedForms = this.security.getFeatureById(
      SecurityConstants.safety_2_0_forms_caneditrejectedforms
    ).editAll;

    const fSecurity = this.security.getFeatureById(
      SecurityConstants.safety_2_0_forms_approvalcomments
    );
    this.canViewApprovalComments = fSecurity.viewFeature;
    this.canReadAllApprovalComments = fSecurity.readAll;
    this.canReadSelfApprovalComments = fSecurity.readSelf;
    this.canEditAllApprovalComments = fSecurity.editAll;
  }

  ngOnInit() {
    this.isReadOnly = this.config?.options?.isReadOnly;
    this.isSubmittedByLoggedInUser =
      this.userService.myInfo?.UserId === this.config.options.submittedUserId;

    if (this.config.options.dynamicContainerResponseId) {
      this.dynamicService
        .getFormReview(this.config.options.dynamicContainerResponseId, 'Review')
        .toPromise()
        .then((d) => {
          this.formReview = d;
          this.comment = d.Response;
          this.approvalComments = d.ApprovalComments;
          this.reviewedBy = d.ReviewedBy;
          this.dateReviewed = d.ReviewDate;
          this.selectedButton = d.FormReviewStatusName.toLowerCase();

          this.initialState.comments = d.Response;
          this.initialState.selectedButton =
            d.FormReviewStatusName.toLowerCase();
          this.selectReviewStatus(d.FormReviewStatusName.toLowerCase());
        });
    }
  }

  ngAfterViewInit(): void {
    this._eventBus
      .observe(this._eventBus.types.cmpReadyEvent)
      .pipe(
        filter((data) => data['type'] === 'dynamic-comments-component'),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        this.dynamicComment.isCommenting = data['instance'].isCommenting;
        this.dynamicComment.commentText = data['instance'].comment;
      });

    merge(
      fromEvent(this.approvalResult.nativeElement, 'click'),
      fromEvent(this.reviewNotes.nativeElement, 'input')
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        console.log('changes detected');

        this._eventBus.emit(this._eventBus.types.cmpReadyEvent, {
          type: 'dynamic-review-component',
          instance: this.checkForFormChanges.bind(this),
        });
      });
  }

  isApproved() {
    return (
      this.formReview && this.formReview.FormReviewStatusName === 'Approved'
    );
  }

  isRejected() {
    return (
      this.formReview && this.formReview.FormReviewStatusName === 'Rejected'
    );
  }

  isSubmitted() {
    return this.isApproved() || this.isRejected();
  }

  isEditingRejected() {
    return !this.isReadOnly && this.canEditRejectedForms && this.isRejected();
  }

  isEditable() {
    return (
      this.canReviewForms &&
      !this.isReadOnly &&
      (!this.isSubmittedByLoggedInUser || this.canSelfReviewForms) &&
      (!this.isSubmitted() || (this.canEditApprovedForms && this.isApproved()))
    );
  }

  isSubmitReview() {
    return (
      !this.isReadOnly &&
      !this.isSubmitted() &&
      (this.selectedButton === 'approved' || this.selectedButton === 'rejected')
    );
  }

  canAddApprovalComments() {
    return (
      !this.formReview && this.canEditAllApprovalComments && this.isEditable()
    );
  }

  canReadApprovalComments() {
    const isReviewedByLoggedInUser =
      this.formReview?.ReviewedUserId === this.userService.myInfo.UserId;
    return (
      this.formReview &&
      (this.canReadAllApprovalComments ||
        (isReviewedByLoggedInUser &&
          (this.canReadSelfApprovalComments ||
            this.canEditAllApprovalComments)))
    );
  }

  canEditApprovalComments() {
    return (
      this.canReadApprovalComments() &&
      this.canEditAllApprovalComments &&
      this.isEditable()
    );
  }

  selectReviewStatus(status: string) {
    this.selectedButton = status;
  }

  showApprovalComments() {
    return (
      this.selectedButton === 'approved' &&
      (this.canAddApprovalComments() ||
        this.canReadApprovalComments() ||
        this.canEditApprovalComments())
    );
  }

  isValid() {
    return (
      this.config.options.dynamicContainerId &&
      this.config.options.dynamicContainerResponseId &&
      this.selectedButton &&
      (this.selectedButton === 'approved' ||
        this.selectedButton === 'inreview' ||
        (this.selectedButton === 'rejected' &&
          this.comment &&
          this.comment.length))
    );
  }

  closeTab() {
    this.showCancelConfirm = false;
    this.dynamicService.formReviewDone$.next();
  }

  checkForFormChanges() {
    return (
      this.initialState.selectedButton !== this.selectedButton ||
      this.initialState.comments !== this.comment ||
      !!this.comment.length
    );
  }

  resetFormChanges() {
    this.initialState.selectedButton = this.selectedButton;
    this.initialState.comments = this.comment;

    this._eventBus.emit(this._eventBus.types.cmpReadyEvent, {
      type: 'dynamic-review-component',
      instance: this.checkForFormChanges.bind(this),
    });
  }

  submitReview() {
    if (this.dynamicComment.isCommenting && this.dynamicComment.commentText) {
      const msg =
        'There is an unsaved comment. Are you sure you want to continue?';

      this.win.confirm(msg).then((ok) => {
        if (ok) {
          this.submit();
        }
      });
    } else {
      this.submit();
    }
  }

  submit() {
    const submit = () => {
      const dto = {
        DynamicContainerId: this.config.options.dynamicContainerId,
        DynamicContainerResponseId:
          this.config.options.dynamicContainerResponseId,
        FormReviewStatusName: this.selectedButton,
        Response: this.comment,
        ApprovalComments: this.approvalComments,
        ReviewType: 'Review',
      } as IFormReview;

      if (this.config.options.reviewId)
        dto.FormReviewId = this.config.options.reviewId;

      this.isSaving = true;
      this.dynamicService
        .saveFormReview(dto)
        .toPromise()
        .then(
          (d: IFormReview) => {
            if (d.FormReviewStatusName === 'inreview') {
              this.msg.success('Saved Successfully');
            }
            this.isSaving = false;
            this.resetFormChanges();
            this.closeTab();
          },
          (e: UpsApiHttpErrorResponseCamelCase) => {
            this.isSaving = false;
            this.msg.error(e?.error.message);
          }
        );
    };

    if (this.config.options.isEditReview) {
      this.dynamicService.activeAction().then(() => {
        submit();
      });
    } else {
      submit();
    }
  }
}
