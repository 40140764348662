import { createAction, props } from '@ngrx/store';
import { HelpDeskSubmission, HelpDocumentDto, HelpTypeEnum } from '../models';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace HelpActions {
  export const toggleEditor = createAction('[@ups/help] toggle editor', (payload: { state: boolean; document?: HelpDocumentDto }) => payload);

  export const toggleTooltips = createAction('[@ups/help] toggle tooltips', (payload: { state: boolean }) => payload);

  export const saveHelpDoc = createAction('[@ups/help] save document', (payload: { doc: HelpDocumentDto }) => payload);

  export const saveHelpDocSuccess = createAction('[@ups/help] save document success', (payload: { doc: HelpDocumentDto }) => payload);

  export const saveHelpDocError = createAction('[@ups/help] save document error', (payload: { error?: unknown } = {}) => payload);

  export const deleteDocument = createAction('[@ups/help] delete document', (payload: { document: HelpDocumentDto }) => payload);

  export const deleteDocumentSuccess = createAction('[@ups/help] delete document success', (payload: { error?: unknown } = {}) => payload);

  export const deleteDocumentError = createAction('[@ups/help] delete document error', (payload: { error?: unknown } = {}) => payload);

  export const loadDocumentsForPaths = createAction('[@ups/help] load documents for paths', (payload: { documentType?: HelpTypeEnum; paths: string[] }) => payload);

  export const loadDocumentsForDisplay = createAction('[@ups/help load documents for currently displayed components');

  export const loadedDocuments = createAction('[@ups/help] loaded documents', (payload: { docs: HelpDocumentDto[] }) => payload);

  export const registerUIPath = createAction('[@ups/help] register path', (payload: { path: string }) => payload);

  export const deregisterUIPath = createAction('[@ups/help] deregister path', (payload: { path: string }) => payload);
  export const bulkDeregisterUIPaths = createAction('[@ups/help] bulk deregister paths', (payload: { paths: string[] }) => payload);

  export const loadWalkthrough = createAction('[@ups/help] fetching walkthrough details', (payload: { documentId: number }) => payload);

  export const loadWalkthroughSuccess = createAction('[@ups/help] fetched walkthrough details', (payload: { document: HelpDocumentDto }) => payload);

  export const loadWalkthroughError = createAction('[@ups/help] fetching walkthrough details failed', (payload: { error?: unknown } = {}) => payload);

  export const goToWalkthroughStep = createAction('[@ups/help] go to step', (payload: { step: number }) => payload);

  export const closeWalkthrough = createAction('[@ups/help] close walkthrough');

  export const resumeWalkthrough = createAction('[@ups/help] resume walkthrough');

  export const showHelpDeskModal = createAction('[@ups/help] show help desk modal', (payload: Partial<HelpDeskSubmission> = {}) => payload);

  export const hideHelpDeskModal = createAction('[@ups/help] hide help desk modal');

  export const sendHelpDeskTicket = createAction('[@ups/help] send help desk ticket', (payload: HelpDeskSubmission) => payload);

  export const sendHelpDeskTicketSuccess = createAction('[@ups/help] send help desk ticket success');

  export const sendHelpDeskTicketError = createAction('[@ups/help] send help desk ticket error', (payload: { error?: unknown }) => payload);

  export const setUrlPath = createAction('[@ups/help] set url path', props<{ path: string }>());
}
