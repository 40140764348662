import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import { GroupingExDirective } from './grouping-ex.directive';
import { GroupingExSelectionDirective } from './grouping-ex.selection.directive';
import { GroupingExSelectionGroupHeaderComponent } from './grouping-ex.selection.group-header.component';

const exportedModules = [
  GroupingExDirective,
  GroupingExSelectionDirective,
  GroupingExSelectionGroupHeaderComponent,
];

const declarations = [...exportedModules];

@NgModule({
  declarations: [declarations],
  exports: [exportedModules],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
})
export class GroupingGridExModule {}
