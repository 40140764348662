import { BaseModel } from '../../base.model';

export class EquipmentRequestGridDto extends BaseModel {
  public EquipmentRequestDetailID: string;
  public CompanyID: string;
  public VPCompanyID: number | null;
  public PlantID: number | null;
  public PlantName: string | null;
  public JobID: string;
  public VPJobID: string;
  public JobName: string;
  public SupervisorName: string | null;
  public SupervisorID: string | null;
  public SupervisorPhoneNumber: string | null;
  public SupervisorSSN: string | null;
  public StartDate: Date | null;
  public EndDate: Date | null;
  public EquipmentDispatchStatusID: number;
  public EquipmentDispatchStatusName: string;
  public EquipmentDispatchStatusIsFinal: boolean;
  public Notes: string;
  public HasEquipment: number;
  public UseInTotal: number;
  public IsOnsite: number;
  public IsDispatched: number;
  public UseInTotalOnsite: number;
  public EquipmentTypeID: string | null;
  public EquipmentTypeName: string | null;
  public EquipmentKeyID: number | null;
  public AssetControlManagerName: string | null;
  public AssetControlManagerID: string | null;

  constructor(o: any = null) {
    super(o);
  }
}
