import { HttpClient } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { parseData } from '@ups/xplat/utils';
import { TableviewModel, TableviewShareModel } from '@ups/xplat/api/dto';
import { MyHttpClientFactory } from '../http/my-http-client-factory';
import { environment } from '../environments';
import { map } from 'rxjs';

const GET_TABLEVIEWS = '/api/tableviews/{:key}';
const SAVE_TABLEVIEW = '/api/tableview';
const SAVE_TABLEVIEW_SHARE = '/api/tableview/share';

@Injectable({ providedIn: 'root' })
export class TableviewService {
  http: HttpClient;

  constructor(
    @Inject(forwardRef(() => MyHttpClientFactory))
    private httpFactory: MyHttpClientFactory
  ) {
    this.http = httpFactory.createHttpClient(environment.urls.spartaAPI);
  }

  public getTableviews(key: string) {
    const url = GET_TABLEVIEWS.split('{:key}').join(key);
    return this.http.get(url).pipe(map(parseData));
  }

  public createTableView(view: TableviewModel) {
    return this.http.post(SAVE_TABLEVIEW, view).pipe(map(parseData));
  }

  public updateTableView(view: TableviewModel) {
    return this.http.post(SAVE_TABLEVIEW, view).pipe(map(parseData));
  }

  public saveTableViewShare(view: TableviewShareModel) {
    return this.http.post(SAVE_TABLEVIEW_SHARE, view).pipe(map(parseData));
  }
}
