import { Component } from '@angular/core';
import { DynamicItemBaseComponent } from '@ups/xplat/features';

@Component({
  selector: 'ups-dynamic-textarea',
  styleUrls: ['dynamic-textarea.component.scss'],
  template: `
    <div class="dynamic-field-container" [class.selected]="config?.selected">
      <div
        class="dynamic-field form-input text-align"
        [formGroup]="group"
        [hidden]="!config?.isFormBuilder && config?.options?.hidden"
      >
        <ups-dynamic-helper-label
          [config]="config"
          [dynamicService]="dynamicService"
        ></ups-dynamic-helper-label>

        <p
          style="border: 1px solid #d6d8df; border-radius: 4px; padding: 10px; min-height: 50px;"
          *ngIf="disableItem"
        >
          {{ config.value }}
        </p>

        <textarea
          *ngIf="!disableItem"
          class="k-textarea"
          [attr.name]="config.formControlName"
          [attr.placeholder]="config?.placeholder"
          [formControlName]="config.formControlName"
          [attr.maxLength]="
            config?.options?.maxlength ? config?.options?.maxlength : null
          "
          rows="4"
        ></textarea>
        <div
          class="form-builder-mask"
          [class.hidden]="config?.options?.hidden"
          *ngIf="config?.isFormBuilder"
        ></div>
      </div>
      <div class="form-builder-options" *ngIf="config?.isFormBuilder">
        <i
          class="fas fa-times ml-3 cursor-pointer c-red"
          (click)="delete()"
        ></i>
        <!-- <i class="fas fa-bars ml-3 cursor-grab"></i> -->
      </div>
    </div>
  `,
})
export class DynamicTextareaComponent extends DynamicItemBaseComponent {}
