export class TransferEmployeeCompany {
  employeeJobID: string;
  manPowerReqDetailID: string;
  employeeID: string;
  hrRef: number;
  pREmp: number;
  pRCo: number;
  newPREmp: number;
  transferPRCo: number;
  employeeAlreadyTransferred: boolean;
  classNotExist: boolean;
  craftNotExist: boolean;
  deductionNotExist: boolean;
  transferCompleted: boolean;
  errorMessages: string[];
}
