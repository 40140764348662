import { Injectable, Inject } from '@angular/core';
import { throwError, catchError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ResponseCasingEnum } from '../http/response-enum';
import { AppError, BadInput, NotFoundError } from '../models/common';
import { IMyHttpClientFactory } from '../http/my-http-client-factory.interface';

@Injectable({
  providedIn: 'root',
})
export class DataService<T> {
  constructor(
    @Inject(String) private url: string,
    @Inject(String) public route: string,
    protected httpClient: HttpClient,
    private httpFactory: IMyHttpClientFactory,
    httpClientCasing = ResponseCasingEnum.CamelCase
  ) {
    this.httpClient = httpFactory.createHttpClient(url, true, httpClientCasing);
  }

  get() {
    return this.httpClient.get<T[]>(this.route);
  }

  getById(id: string) {
    return this.httpClient
      .get<T>(`${this.route}/${id}`)
      .pipe(catchError(this.errorHandler));
  }

  post(resource: T) {
    return this.httpClient
      .post<T>(this.route, resource)
      .pipe(catchError(this.errorHandler));
  }

  put(resource: T) {
    return this.httpClient
      .put<T>(this.route, resource)
      .pipe(catchError(this.errorHandler));
  }

  delete(id: string) {
    return this.httpClient
      .delete<T>(`${this.route}/${id}`)
      .pipe(catchError(this.errorHandler));
  }

  // TODO: Move these to a separate service
  retire(id: string) {
    return this.httpClient
      .put<T>(`${this.route}/${id}/retire`, {})
      .pipe(catchError(this.errorHandler));
  }

  unretire(id: string) {
    return this.httpClient
      .put<T>(`${this.route}/${id}/unretire`, {})
      .pipe(catchError(this.errorHandler));
  }

  // Error types could be anything
  // https://angular.io/api/core/ErrorHandler
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected errorHandler(error: any) {
    if (error.status === 404) return throwError(new NotFoundError());
    else if (error.status === 400) return throwError(new BadInput(error));
    else return throwError(new AppError(error));
  }
}
