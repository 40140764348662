/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import { BaseModel } from '../base.model';

export class JobSubRequirementModel extends BaseModel {
  SubRequirement = '';
  Mandatory: boolean;
  SubtaskDone: boolean;

  constructor(o: any = null) {
    super(o);
    if (o) {
      Object.assign(this, o);
    }
  }
}
