import { Injectable } from '@angular/core';
import { TypeOfProcessingResolver } from './type-of-processing-resolver';
import { WorkgroupResolver } from './workgroup-resolver';
import { PerDiemSupportedTypesResolver } from './per-diem-supported-types-resolver';
import { CertificationSkillTestersResolver } from './certification-skill-testers-resolver';
import { CertificationCodeResolver } from './certification-code-resolver';
import { JobRequirementStatusResolver } from './job-requirement-statuses-resolver';
import {
  CompanyResolver,
  CustomerIndustriesResolver,
  SafteyCouncilResolver,
} from '@ups/xplat/api/services';
import { JobBillMethodResolver } from './job-bill-method.resolver';
import { PerDiemResolver } from './perdiem-resolver';
import { MileageResolver } from './mileage-resolver';
import { RoleResolver } from './role-resolver';
import { DrugTestTypeResolver } from './drug-test-type-resolver';
import { BackgroundTypeResolver } from './background-type.resolver';
import { LodgeRoomTypeResolver } from './lodge-room-type.resolver';
import { AbsentReasonResolver } from './absent-reason-resolver';
import { LaborTypeResolver } from './labor-type.resolver';
import { DispatchGroupResolver } from './dispatch-group-resolver';
import { RecruiterResolver } from './recruiter-resolver';
import { TimeSheetDesignationResolver } from './timesheet-designation-resolver';
import { RegionResolver } from './region-resolver';
import { EmploymentVerificationTypeResolver } from './employment-verification-type-resolver';

@Injectable({
  providedIn: 'root',
})
export class CodeListResolver {
  isLoading = false;

  constructor(
    private absentReasonResolver: AbsentReasonResolver,
    private backgroundTypeResolver: BackgroundTypeResolver,
    private certificationCodeResolver: CertificationCodeResolver,
    private certificationSkillTestersResolver: CertificationSkillTestersResolver,
    private companyResolver: CompanyResolver,
    private customerIndustriesResolver: CustomerIndustriesResolver,
    private dispatchGroupResolver: DispatchGroupResolver,
    private drugTestTypeResolver: DrugTestTypeResolver,
    private employmentVerificationTypeResolver: EmploymentVerificationTypeResolver,
    private jobBillMethodResolver: JobBillMethodResolver,
    private jobRequirementStatusResolver: JobRequirementStatusResolver,
    private laborTypeResolver: LaborTypeResolver,
    private lodgeRoomTypeResolver: LodgeRoomTypeResolver,
    private mileageResolver: MileageResolver,
    private perDiemResolver: PerDiemResolver,
    private perDiemSupportedTypesResolver: PerDiemSupportedTypesResolver,
    private recruiterResolver: RecruiterResolver,
    private regionResolver: RegionResolver,
    private roleResolver: RoleResolver,
    private safetyCouncilResolver: SafteyCouncilResolver,
    private timeSheetDesignationResolver: TimeSheetDesignationResolver,
    private typeOfProcessingResolver: TypeOfProcessingResolver,
    private workgroupResolver: WorkgroupResolver
  ) {}

  loadTypesOfProcessing() {
    return this.typeOfProcessingResolver.load();
  }

  loadPerDiems() {
    return this.perDiemResolver.load();
  }

  loadMileages() {
    return this.mileageResolver.load();
  }

  loadWorkgroups() {
    return this.workgroupResolver.load();
  }

  loadPerDiemSupportedTypes() {
    return this.perDiemSupportedTypesResolver.load();
  }

  loadCertificationSkillTesters() {
    return this.certificationSkillTestersResolver.load();
  }

  loadCertSkillCodes() {
    return this.certificationCodeResolver.loadCertSkillCodes();
  }

  loadPhysicalSkillCodes() {
    return this.certificationCodeResolver.loadPhysicalSkillCodes();
  }

  loadBackgroundSkillCodes() {
    return this.certificationCodeResolver.loadBackgroundSkillCodes();
  }

  loadJobRequirementStatuses() {
    return this.jobRequirementStatusResolver.load();
  }

  loadSafetyCouncils() {
    return this.safetyCouncilResolver.load();
  }

  loadRoles() {
    return this.roleResolver.load();
  }

  loadCompanies() {
    return this.companyResolver.load();
  }

  loadJobBillMethods() {
    return this.jobBillMethodResolver.load();
  }

  loadBackgroundTypes() {
    return this.backgroundTypeResolver.load();
  }

  loadDrugTestTypes() {
    return this.drugTestTypeResolver.load();
  }

  loadLodgeRoomTypes() {
    return this.lodgeRoomTypeResolver.load();
  }

  loadCustomerIndustries() {
    return this.customerIndustriesResolver.load();
  }

  loadAbsentReasons() {
    return this.absentReasonResolver.load();
  }

  loadTimeSheetDesignations() {
    return this.timeSheetDesignationResolver.load();
  }

  loadLaborTypes() {
    return this.laborTypeResolver.load();
  }

  loadDispatchGroups() {
    return this.dispatchGroupResolver.load();
  }

  loadRecruiters() {
    return this.recruiterResolver.load();
  }

  loadRegions() {
    return this.regionResolver.load();
  }

  loadEmploymentVerificationTypeResolver() {
    return this.employmentVerificationTypeResolver.load();
  }
}
