export class ProspectEdit {
  cellPhone: string;
  companyName: string;
  dob: string;
  email: string;
  firstName: string;
  lastName: string;
  position: string;
  ssn: string;

  constructor() {
    this.cellPhone = '';
    this.companyName = '';
    this.dob = '';
    this.email = '';
    this.firstName = '';
    this.lastName = '';
    this.position = '';
    this.ssn = '';
  }
}
