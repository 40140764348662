/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import { AddressModel } from '../sparta-api/address-model';
import { BaseModel } from '../base.model';
import { LocationModel } from './location-model';

export class PlantModel extends BaseModel {
  Id = '';
  Name = '';
  Address: AddressModel = new AddressModel();
  Location: LocationModel = new LocationModel();

  constructor(o: any = null) {
    super(o);
    if (o) {
      Object.assign(this, o);
    }
  }
}
