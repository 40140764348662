import { Component } from '@angular/core';
import { WebDynamicItemPreviewComponentBase } from '../web-dynamic-item-component-base';

@Component({
  selector: 'form-checkbox',
  styleUrls: ['form-checkbox.component.scss'],
  template: `
    <div class="dynamic-field form-input text-align">
      <label>
        <input class="k-checkbox" [attr.name]="config.id" [attr.checked]="config.data.checked" type="checkbox" />
        Ex. Label
      </label>
    </div>
  `,
})
export class FormCheckboxComponent extends WebDynamicItemPreviewComponentBase {}
