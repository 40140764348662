import { BaseModel } from '../base.model';

export class DispatchStatusDto extends BaseModel {
  public DispatchStatusID: number;
  public Name: string;
  public isFinalStatus: boolean;
  public Position: number;
  public bActive: boolean;
  public DispatchGroupID: number;
  public FieldDispatchGroupID: number;
  public bHideHRView: boolean;
  public bHideFieldView: boolean;
  public bHideJobsRecruiter: boolean;
  public bHideJobsMgr: boolean;

  constructor(o: any = null) {
    super(o);
  }
}
