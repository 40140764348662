import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  environment,
  MyHttpClientFactory,
  ResponseCasingEnum,
} from '@ups/xplat/core';
import {
  InventoryEntry,
  Material,
  PackDto,
  PickMaterial,
  PickPackShip,
  SalesOrder,
  SubLocation,
  Warehouse,
  WarehouseInventory,
  WarehouseInventoryAdmin,
  SubLocationEntry,
  SublocationMaterial,
  AdminInventoryEntry,
} from '@ups/xplat/api/dto';
import { BehaviorSubject, catchError, throwError } from 'rxjs';

const URL = '/api/warehouseInventory';

@Injectable({
  providedIn: 'root',
})
export class WarehouseInventoryService {
  selectedWarehouse: Warehouse;
  selectedSublocation$: BehaviorSubject<string> = new BehaviorSubject('');
  selectedMaterial: InventoryEntry;
  selectedInventoryId: number;
  selectedInventory$: BehaviorSubject<WarehouseInventory> = new BehaviorSubject(
    null
  );
  selectedAdminInventory$: BehaviorSubject<WarehouseInventoryAdmin> =
    new BehaviorSubject(null);
  selectedPickPackShip$: BehaviorSubject<PickPackShip> = new BehaviorSubject(
    null
  );
  selectedAdminSublocation$: BehaviorSubject<SubLocationEntry> =
    new BehaviorSubject(null);
  selectedInventoryEntry: InventoryEntry;
  selectedSubLocationEntry$: BehaviorSubject<SubLocationEntry> =
    new BehaviorSubject(null);
  selectedMaterialEntry$: BehaviorSubject<SublocationMaterial> =
    new BehaviorSubject(null);
  selectedInventoryEntry$: BehaviorSubject<InventoryEntry> =
    new BehaviorSubject(null);

  sublocations$: BehaviorSubject<SubLocation[]> = new BehaviorSubject([]);
  materials$: BehaviorSubject<Material[]> = new BehaviorSubject([]);
  salesOrders$: BehaviorSubject<SalesOrder[]> = new BehaviorSubject([]);
  selectedSalesOrder$: BehaviorSubject<SalesOrder> = new BehaviorSubject(null);

  showNotesOnly$ = new BehaviorSubject<boolean>(false);

  constructor(
    private httpClient: HttpClient,
    httpFactory: MyHttpClientFactory
  ) {
    this.httpClient = httpFactory.createHttpClient(
      environment.urls.toolTrackerAPI,
      true,
      ResponseCasingEnum.CamelCase
    );
  }

  get(companyId: number, locationId: number) {
    return this.httpClient
      .get<WarehouseInventory>(`${URL}/count/${companyId}/${locationId}`)
      .pipe(catchError((error) => throwError(() => error)));
  }

  getAdminCount(companyId: number, locationId: number) {
    return this.httpClient
      .get<WarehouseInventoryAdmin>(
        `${URL}/count/${companyId}/${locationId}/admin`
      )
      .pipe(catchError((error) => throwError(() => error)));
  }

  getSublocations(companyId: number, locationId: number) {
    return this.httpClient
      .get<SubLocation[]>(`${URL}/sublocation/${companyId}/${locationId}`)
      .pipe(catchError((error) => throwError(() => error)))
      .subscribe((sublocations) => {
        this.sublocations$.next(sublocations);
      });
  }

  getMaterials(companyId: number) {
    return this.httpClient
      .get<Material[]>(`${URL}/materials/${companyId}`)
      .pipe(catchError((error) => throwError(() => error)))
      .subscribe((materials) => {
        this.materials$.next(materials);
      });
  }

  getSalesOrders(
    companyId: number,
    locationId: number,
    refresh: boolean = false
  ) {
    return this.httpClient
      .get<SalesOrder[]>(
        `/api/WarehousePickPackShip/salesorders/${companyId}/${locationId}`
      )
      .pipe(catchError((error) => throwError(() => error)))
      .subscribe((salesOrders) => {
        if (refresh && this.selectedSalesOrder$.getValue()) {
          const updated = salesOrders.find(
            (order) =>
              order.orderId === this.selectedSalesOrder$.getValue().orderId
          );
          this.selectedSalesOrder$.next(updated);
        }
        this.salesOrders$.next(salesOrders);
      });
  }

  getSalesOrderMaterials(orderId: number, company: number) {
    return this.httpClient
      .get<PickPackShip>(
        `/api/WarehousePickPackShip/orderInfo/${company}/${orderId}`
      )
      .pipe(catchError((error) => throwError(() => error)))
      .subscribe((pickPackShip: PickPackShip) => {
        this.selectedPickPackShip$.next(pickPackShip);
      });
  }

  previousStatus(companyId: number, locationId: number) {
    return this.httpClient
      .get<WarehouseInventory>(`${URL}/count/${companyId}/${locationId}/prev`)
      .pipe(catchError((error) => throwError(() => error)));
  }

  nextStatus(companyId: number, locationId: number) {
    return this.httpClient
      .get<WarehouseInventory>(`${URL}/count/${companyId}/${locationId}/next`)
      .pipe(catchError((error) => throwError(() => error)));
  }

  updateVerificationPercentage(percent) {
    return this.httpClient
      .put<WarehouseInventory>(
        `${URL}/updateVerificationPercentage/${
          this.selectedInventory$.getValue().inventoryId
        }/${percent}`,
        {}
      )
      .pipe(catchError((error) => throwError(() => error)));
  }

  put(
    inventoryEntry: InventoryEntry,
    count: number,
    broken: number,
    adminCount: boolean = false
  ) {
    const entry = {
      inventoryEntryId: 0,
      inventoryId: this.selectedInventory$.getValue().inventoryId,
      location: inventoryEntry.location,
      productBarcode: inventoryEntry.productBarcode,
      amount: count,
      nInventoryStatusId: adminCount
        ? 5
        : this.selectedInventory$.getValue().inventoryStatusId,
      inventoryNote: inventoryEntry.inventoryNote,
      broken: broken,
    };

    return this.httpClient
      .put<InventoryEntry>(`${URL}/inventoryEntry`, entry)
      .pipe(catchError((error) => throwError(() => error)));
  }

  putStock(
    companyId: number,
    material: string,
    locationId: string,
    subLocation: string,
    quantity: number
  ) {
    const stock = {
      companyId: companyId,
      locationId: locationId,
      materialName: material,
      subLocationTo: subLocation,
      amount: quantity,
    };

    return this.httpClient
      .put<boolean>(`${URL}/stock`, stock)
      .pipe(catchError((error) => throwError(() => error)));
  }

  getCountField(): string {
    const statusName = this.selectedInventory$.getValue().inventoryStatusName;

    if (statusName === 'Initial Count') return 'initialCountAmount';
    if (statusName === 'Missed Count') return 'missedCountAmount';
    if (statusName === 'Variance Count') return 'varianceCountAmount';
    if (statusName === 'Count Verification') return 'countVerification';
    if (statusName === 'Final Count') return 'finalCount';
  }

  getBrokenField(): string {
    const statusName = this.selectedInventory$.getValue().inventoryStatusName;

    if (statusName === 'Initial Count') return 'initialCountBroken';
    if (statusName === 'Missed Count') return 'missedCountBroken';
    if (statusName === 'Variance Count') return 'varianceCountBroken';
    if (statusName === 'Count Verification') return 'countVerificationBroken';
    if (statusName === 'Final Count') return 'finalCountBroken';
  }

  updateInventoryEntry(
    inventoryEntry: InventoryEntry,
    count: number,
    broken: number = undefined
  ) {
    const updatedInventory = this.selectedInventory$.getValue();

    let index = updatedInventory.inventoryEntries.findIndex(
      (entry) =>
        entry.location === inventoryEntry.location &&
        entry.productBarcode === inventoryEntry.productBarcode
    );

    if (index === -1) {
      index = updatedInventory.inventoryEntries.length - 1;
      updatedInventory.inventoryEntries[index] = inventoryEntry;
    }

    // Count
    const countField = this.getCountField();

    if (countField in updatedInventory.inventoryEntries[index]) {
      updatedInventory.inventoryEntries[index][countField] = count;
    } else {
      return;
    }

    // Broken
    const brokenField = this.getBrokenField();

    if (broken !== undefined) {
      if (brokenField in updatedInventory.inventoryEntries[index]) {
        updatedInventory.inventoryEntries[index][brokenField] = broken;
      } else {
        updatedInventory.inventoryEntries[index][brokenField] = null;
      }
    } else {
      broken = updatedInventory.inventoryEntries[index][brokenField];
    }

    // Note
    updatedInventory.inventoryEntries[index].inventoryNote =
      inventoryEntry.inventoryNote;

    this.selectedInventory$.next(updatedInventory);

    return this.put(inventoryEntry, count, broken);
  }

  updateAdminInventoryEntry(
    adminInventoryEntry: AdminInventoryEntry,
    productBarcode: number
  ) {
    const entry = {
      inventoryEntryId: 0,
      inventoryId: this.selectedInventory$.getValue().inventoryId,
      location: adminInventoryEntry.location,
      productBarcode: productBarcode,
      amount: adminInventoryEntry.adminCount,
      nInventoryStatusId: 5,
      inventoryNote: adminInventoryEntry.adminCountNote,
      broken: adminInventoryEntry.adminCountBroken,
    };

    return this.httpClient
      .put<InventoryEntry>(`${URL}/inventoryEntry`, entry)
      .pipe(catchError((error) => throwError(() => error)));
  }

  getMaterialQuantity(
    companyId: number,
    locationId: string,
    materialName: string,
    subLocationFrom: string
  ) {
    const payLoad = {
      companyId,
      locationId,
      materialName,
      subLocationFrom,
      subLocationTo: '',
      amount: 0,
    };

    return this.httpClient.post<number>(`${URL}/materialQty`, payLoad);
  }

  transferMaterial(
    companyId: number,
    locationId: string,
    materialName: string,
    subLocationFrom: string,
    subLocationTo: string,
    amount: number
  ) {
    const payLoad = {
      companyId,
      locationId,
      materialName,
      subLocationFrom,
      subLocationTo,
      amount,
    };

    return this.httpClient.put<boolean>(`${URL}/transfer`, payLoad);
  }

  pickMaterial(material: PickMaterial, quantity: number) {
    return this.httpClient
      .post<PickPackShip>(
        `/api/WarehousePickPackShip/picklist/${material.pickListID}/${quantity}`,
        {}
      )
      .pipe(catchError((error) => throwError(() => error)));
  }

  unpickMaterial(orderId: number, material: PickMaterial, quantity: number) {
    return this.httpClient
      .post<PickPackShip>(
        `/api/WarehousePickPackShip/unPickMaterial/${orderId}/${material.pickListID}/${quantity}`,
        {}
      )
      .pipe(catchError((error) => throwError(() => error)));
  }

  packMaterial(packDto: PackDto) {
    return this.httpClient
      .post<PackDto>(
        `/api/WarehousePickPackShip/packlist/${packDto.packId}/0`,
        packDto
      )
      .pipe(catchError((error) => throwError(() => error)));
  }

  shipMaterial(packDto: PackDto) {
    return this.httpClient
      .post<PackDto>(`/api/WarehousePickPackShip/ship/0/0`, packDto)
      .pipe(catchError((error) => throwError(() => error)));
  }
}
