import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { environment, MyHttpClientFactory } from '@ups/xplat/core';
import { parseData } from '@ups/xplat/utils';

const GET_DOCUMENTS: string = '/api/documents';
const SAVE_DOCUMENT: string = '/api/document';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  http: HttpClient;

  constructor(private httpFactory: MyHttpClientFactory) {
    this.http = httpFactory.createHttpClient(environment.urls.spartaAPI);
  }

  public getDocuments(): any {
    return this.http.get(GET_DOCUMENTS).toPromise().then(parseData);
  }

  public createDocument(item: any): any {
    return this.http.put(SAVE_DOCUMENT, item).toPromise().then(parseData);
  }

  public updateDocument(item: any): any {
    return this.http.post(SAVE_DOCUMENT, item).toPromise().then(parseData);
  }
}
