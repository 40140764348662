import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ComponentFactoryResolver,
  Inject,
  AfterViewInit,
} from '@angular/core';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';

import {
  BaseComponent,
  IDynamicModel,
  dynamicModelFormArrayTypes,
  IDynamicModelGroup,
  dynamicModelBaseLevelKeys,
  LogService,
  EventBusService,
  ProgressService,
  WindowService,
  dynamicModelNonNullableKeys,
  IDynamicActiveActionOptions,
  IDynamicContainerMetadata,
  IDynamicControl,
  IDynamicButtonGroup,
  DynamicModelType,
  IApiModelType,
  IDynamicUpdateOnValueChangeValidationTypes,
} from '@ups/xplat/core';
import {
  DynamicEventBusTypes,
  DynamicRenderService,
  dynamicFindControlByClientId,
  dynamicFindSelectedControl,
  dynamicAddItemToButtonGroupForControl,
  dynamicRemoveControl,
  dynamicPrepareControlForBackend,
  dynamicReorderControl,
  validateNoDupeFormControlNames,
  dynamicGetCategoryTags,
  dynamicAddUpdateOnValueChangeForControl,
} from '@ups/xplat/features';
import {
  guid,
  isObject,
  swapArrayElements,
  tagCleanser,
} from '@ups/xplat/utils';
import { fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import {
  AdminItemDesignItemSelectRequest,
  AdminDesignSaveRequest,
  AdminItemPropsDeleteRequest,
} from '../../../models';
import { DrAdminMapResolverService } from '../../../services/dr-admin-map-resolver.service';
import { DrAdminService } from '../../../services/dr-admin.service';

@Component({
  selector: 'admin-form-builder-web',
  templateUrl: 'admin-form-builder-web.component.html',
  styleUrls: ['admin-form-builder-web.component.scss'],
})
export class AdminFormBuilderWebComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @Output()
  designSave: EventEmitter<AdminDesignSaveRequest> = new EventEmitter();
  @Output()
  itemSelected: EventEmitter<AdminItemDesignItemSelectRequest> = new EventEmitter();
  @Output()
  nestedItemSelected: EventEmitter<AdminItemDesignItemSelectRequest> = new EventEmitter();
  @Output()
  deleteControl: EventEmitter<AdminItemPropsDeleteRequest> = new EventEmitter();
  @Output()
  selectSavedForm: EventEmitter<IDynamicContainerMetadata> = new EventEmitter();

  dynamicGroup: IDynamicModelGroup;
  groupItems: Array<IDynamicModel> = [];

  canModify = false;

  constructor(
    private adminMapResolverService: DrAdminMapResolverService, //private // @Inject(DOCUMENT) private document: Document
    private resolver: ComponentFactoryResolver,
    private log: LogService,
    private win: WindowService,
    private eventBus: EventBusService,
    private progress: ProgressService,
    private security: SecurityService,
    private dynamicAdminService: DrAdminService,
    public dynamicRender: DynamicRenderService
  ) {
    super();

    const fSecurity = this.security.getFeatureById(
      SecurityConstants.safety_2_0_form_builder_formbuilderpage
    );
    this.canModify = fSecurity.editAll;

    // testing
    for (const c of this.groupItems) {
      c.clientId = `new-${guid()}`;
    }
  }

  ngOnInit() {
    this.dynamicGroup = this.dynamicRender.createConfigGroup({
      // mobile uses top actionbar to title them
      groupName: 'Form Builder',
      dynamicModels: this.groupItems,
      ignoreDefaultButton: true,
    });
    this.dynamicRender.eventBus
      .observe(DynamicEventBusTypes.dynamicRemoveControl)
      .pipe(takeUntil(this.destroy$))
      .subscribe((config: IDynamicModel) => {
        const index = dynamicRemoveControl(this.groupItems, config.clientId);
        if (index > -1) {
          this.refreshForm();
        }
      });
    this.dynamicRender.selectNestedControl$
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.selectNested.bind(this));
    this.dynamicRender.eventBus
      .observe(DynamicEventBusTypes.dynamicAddItemToButtonGroup)
      .pipe(takeUntil(this.destroy$))
      .subscribe(this._addToButtonGroup.bind(this));
    this.dynamicRender.eventBus
      .observe(DynamicEventBusTypes.dynamicAddUpdateOnValueChange)
      .pipe(takeUntil(this.destroy$))
      .subscribe(this._addUpdateOnValueChange.bind(this));

    this.dynamicRender.eventBus
      .observe(DynamicEventBusTypes.dynamicRemoveItemFromOptions)
      .pipe(takeUntil(this.destroy$))
      .subscribe(this._removeFromOptions.bind(this));
    this.dynamicRender.activeAction = (
      options?: IDynamicActiveActionOptions
    ) => {
      return new Promise((resolve, reject) => {
        if (!this.dynamicRender.metadata.dynamicContainerName) {
          reject(`You must provide a name for this form.`);
          return;
        }
        // check for duplicate formControlNames, enforce that all should be unique
        // important for the form to work properly
        const dupeFormControlNameError = validateNoDupeFormControlNames(
          this.groupItems
        );
        if (dupeFormControlNameError) {
          reject(dupeFormControlNameError);
          return;
        }
        this.log.debug('this.groupItems:', this.groupItems);

        const newDynamicControls = [];
        for (let i = 0; i < this.groupItems.length; i++) {
          const newDynamicControl = dynamicPrepareControlForBackend(
            this.groupItems[i],
            i,
            this.dynamicRender.isExistingContainer()
          );
          if (
            dynamicModelFormArrayTypes.includes(
              newDynamicControl.dynamicControlType
            ) &&
            (!newDynamicControl.dynamicControls ||
              (newDynamicControl.dynamicControls &&
                newDynamicControl.dynamicControls.length === 0))
          ) {
            // do not allow empty formarray type controls (ie, empty accordions)
            reject(
              `You must provide controls inside the '${newDynamicControl.dynamicControlType}' control.`
            );
            return;
          }
          newDynamicControls.push(newDynamicControl);
        }

        // check if a default displayOrder needs to be set
        // let displayOrder = this.dynamicRender.metadata.displayOrder;

        // if (!displayOrder || (displayOrder && !displayOrder[appTag])) {
        //   displayOrder = {};
        //   // default to order 1 if nothing is set explicitly
        //   displayOrder[appTag] = 1;
        // }

        const newDynamicContainer: IDynamicContainerMetadata = {
          dynamicContainerName:
            this.dynamicRender.metadata.dynamicContainerName.trim(),
          dynamicContainerDescription: this.dynamicRender.metadata
            .dynamicContainerDescription
            ? this.dynamicRender.metadata.dynamicContainerDescription.trim()
            : '',
          dynamicContainerWhatsNew:
            this.dynamicRender.metadata.dynamicContainerWhatsNew,
          displayIcon: this.dynamicRender.metadata.displayIcon,
          // displayOrder,
          dynamicControls: newDynamicControls,
          tags: this.dynamicRender.metadata.tags || [],
        };
        this.dynamicRender
          .saveDynamicContainer(newDynamicContainer)
          .subscribe((res) => {
            if (isObject(res) && !(res instanceof HttpErrorResponse)) {
              this.dynamicRender.updateMetaData({
                ...res,
                isDirty: false,
              });
              const reloadAndEmitSaved = () => {
                this._reloadListAndSelectContainer(res).then(() => {
                  this.eventBus.emit(
                    DynamicEventBusTypes.dynamicContainerSaved,
                    res
                  );
                });
              };
              if (options && options.publish) {
                // there's a sqlserver error that can occur intermittently when attempting to publish soon after save/update
                // need to discuss with Tim/Peter if the call to save/update/publish can be all combined to avoid likely
                /**
                 * Microsoft.Data.SqlClient.SqlException (0x80131904): New transaction is not allowed because there are other threads running in the session.
   at Microsoft.Data.SqlClient.SqlConnection.OnError(SqlException exception, Boolean breakConnection, Action`1 wrapCloseInAction)
                 */
                // also publish the container
                const id =
                  res.versionNumber > 1 && res.originalVersionContainerId
                    ? res.originalVersionContainerId
                    : res.dynamicContainerId;
                this.dynamicRender
                  .publishDynamicContainer(id, res.versionNumber)
                  .subscribe(() => {
                    reloadAndEmitSaved();
                  });
              } else {
                reloadAndEmitSaved();
              }
            } else {
              this.progress.toggleSpinner(false);
              if (res.error) {
                const err = res.error;
                if (
                  err.message &&
                  err.message.indexOf('Name and Tag exists') !== -1
                ) {
                  this.win.alert(
                    `You cannot save a form with the name "${this.dynamicRender.metadata.dynamicContainerName}" due to another form with this same name existing already. Please use a unique form name.`
                  );
                } else {
                  this.win.alert(
                    (err.messages &&
                      err.messages.length &&
                      err.messages.join('|')) ||
                      err
                  );
                }
              } else {
                this.win.alert(`An error occurred trying to save this form.`);
              }
            }
          });
      });
    };
  }

  ngAfterViewInit() {
    fromEvent(window, 'scroll')
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe(this.scrollFunc.bind(this));
  }

  scrollToTop(e) {
    e.preventDefault();
    window.scrollTo(0, 0);

    // also apply any current changes as a convenience
    this.applyPropertyChanges();

    // The following is a nice option if desired: animates scroll
    // opted not to with desire to be fast snap to top
    // Let's set a variable for the number of pixels we are from the top of the document.
    // const c = document.documentElement.scrollTop || document.body.scrollTop;

    // // If that number is greater than 0, we'll scroll back to 0, or the top of the document.
    // // We'll also animate that scroll with requestAnimationFrame:
    // // https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
    // if (c > 0) {
    //   window.requestAnimationFrame(this.scrollToTop.bind(this));
    //   // ScrollTo takes an x and a y coordinate.
    //   // Increase the '10' value to get a smoother/slower scroll!
    //   window.scrollTo(0, c - c / 10);
    // }
  }

  scrollFunc() {
    const scrollToTopButton = document.getElementById('js-top');
    // Get the current scroll value
    const y = window.scrollY;

    // If the scroll value is greater than the window height, let's add a class to the scroll-to-top button to show it!
    // console.log('scroll top:', y);
    if (y > 5) {
      scrollToTopButton.className = 'top-link show';
    } else {
      scrollToTopButton.className = 'top-link hide';
    }
  }

  async showContainer(container: Partial<IDynamicContainerMetadata>) {
    this.progress.toggleSpinner(false);
    // this.log.debug('showcontainer in form builder:', container);
    this.groupItems = await this.dynamicRender.prepareContainerForDisplay(
      container,
      true
    );
    this.refreshForm(false);
  }

  updateControl(
    props: {
      markingSelected?: boolean;
      clientId?: string;
      data?: { [key: string]: string };
    },
    markingSelected = false
  ) {
    // deselect all to reset
    this.groupItems.forEach((item) => {
      // console.log('Item: ', item);
      if (markingSelected) {
        item.selected = false;
        // deselect nested controls as well
        switch (item.type) {
          case 'accordion':
            if (item.options && item.options.accordion) {
              item.options.accordion.forEach((a) => (a.selected = false));
            }
            break;
          case 'typeahead-employee':
            item.valueProperty = item.options?.distinctHRRef ? 'HRRef' : 'Id';
        }
      }
    });

    const ctrl = dynamicFindControlByClientId(this.groupItems, props.clientId);

    if (ctrl) {
      this._applyPropertiesToControl(props, ctrl, markingSelected);
    }
    this.refreshForm();
  }

  addNewDesignPanelItem(newItem, addToSelected?: boolean) {
    const ctrl: IDynamicModel = {
      clientId: newItem.clientId,
      label: newItem.displayName,
      type: newItem.controlName,
      valueProperty: newItem.valueProperty,
      isReusable: false,
    };
    if (dynamicModelFormArrayTypes.includes(newItem.controlName)) {
      // ie, 'accordion' groups other controls together
      ctrl.formArrayName = tagCleanser(`${newItem.displayName}`);
    } else {
      ctrl.formControlName = newItem.displayName;
    }
    if (addToSelected) {
      const selectedCtrl = dynamicFindSelectedControl(this.groupItems);
      switch (selectedCtrl.type) {
        case 'accordion':
          if (!selectedCtrl.options) {
            selectedCtrl.options = {};
          }
          if (!selectedCtrl.options.accordion) {
            selectedCtrl.options.accordion = [];
          }
          ctrl.isFormBuilder = true;
          selectedCtrl.options.accordion.push(ctrl);
          this.refreshForm();
          break;
      }
    } else {
      this.groupItems.push(ctrl);
      this.refreshForm();
    }
  }

  addNewDesignPanelReusableControl(ctrl: IDynamicControl) {
    this.dynamicRender
      .prepareContainerForDisplay({ dynamicControls: [ctrl] }, true)
      .then((d) => {
        const item = d[0];
        item.clientId = `new-${guid()}`;
        item.isReusable = false;
        this.groupItems.push(item);
        this.refreshForm();
      });
  }

  reordered(args) {
    // console.log('drag/drop reorder:', args)
    swapArrayElements(this.groupItems, args.previousIndex, args.currentIndex);
    for (let i = 0; i < this.groupItems.length; i++) {
      this.groupItems[i].order = i;
    }
    this.refreshForm();
  }

  reorderControl(direction: 'up' | 'down') {
    const selectedControl = dynamicFindSelectedControl(this.groupItems);
    dynamicReorderControl(this.groupItems, selectedControl.clientId, direction);
    this.refreshForm();
  }

  refreshForm(isDirty: boolean = true) {
    this.dynamicGroup = this.dynamicRender.createConfigGroup({
      // mobile uses top actionbar to title them
      groupName: 'Form Builder',
      dynamicModels: this.groupItems,
      ignoreDefaultButton: true,
      isFormBuilder: true,
    });
    this.dynamicRender.updateMetaData({ isDirty: isDirty });
  }

  logStructure() {
    console.log(this.groupItems);
  }

  submit(publish?: boolean) {
    this.dynamicRender.submitForm(this.dynamicRender.activeForm, publish);
  }

  transformToolboxControl(item: {
    clientId?: string;
    displayName?: string;
    controlName?: string;
  }): IDynamicModel {
    return {
      clientId: item.clientId,
      formControlName: item.displayName,
      label: item.displayName,
      type: <DynamicModelType>item.controlName,
    };
  }

  applyPropertyChanges() {
    if (this.dynamicRender.activeFormProperties?.dirty) {
      this.dynamicRender.eventBus.emit(
        DynamicEventBusTypes.dynamicApplyProperties,
        true
      );
    }
  }

  select(item, skipDirtyCheck?: boolean, isNested?: boolean) {
    const notifySelection = () => {
      if (isNested) {
        this.nestedItemSelected.emit(item);
      } else {
        this.itemSelected.emit(item);
      }
    };
    if (item) {
      this.log.debug('select:', item);
      if (
        !skipDirtyCheck &&
        this.dynamicRender.activeFormProperties &&
        this.dynamicRender.activeFormProperties.dirty
      ) {
        this.win
          .confirm(
            `You have unsaved property changes, are you sure you want to change without saving?`
          )
          .then((ok) => {
            if (ok) {
              notifySelection();
            }
          });
      } else {
        notifySelection();
      }
    }
  }

  selectNested(item) {
    // used when selecting nested controls in groupings (ie, accordion)
    // this.log.debug('selectNested:', item);
    this.select(item, false, true);
  }

  private _applyPropertiesToControl(
    props: { data?: { [key: string]: string } },
    ctrl: IDynamicModel,
    markingSelected?: boolean
  ) {
    if (markingSelected) {
      ctrl.selected = true;
    }
    if (!ctrl.options) {
      ctrl.options = {};
    }
    if (!ctrl.isReusable) {
      // must be set, is non-nullable
      ctrl.isReusable = false;
    }
    for (const key in props.data) {
      if (dynamicModelBaseLevelKeys.includes(<keyof IDynamicModel>key)) {
        if (
          ['formControlName', 'formArrayName', 'reportCategoryName'].includes(
            key
          )
        ) {
          if (props.data[key]) {
            // only set these properties if it's an actual value, otherwise form would break since these properties can never be null
            ctrl[key] = props.data[key];

            if (['formControlName', 'reportCategoryName'].includes(key)) {
              // always match reportCategoryName with formControlName
              if (!ctrl['reportCategoryName']) {
                ctrl['reportCategoryName'] = props.data['formControlName'];
              }
              // always match formControlName and dynamicControlName
              ctrl['dynamicControlName'] = props.data['formControlName'];
            }
          }
        } else {
          if (
            dynamicModelNonNullableKeys.includes(<keyof IDynamicModel>key) &&
            (props.data[key] === null || props.data[key] === undefined)
          ) {
            // skip
          } else {
            ctrl[key] = props.data[key];
          }
        }
      } else {
        // handle options that map to multiple embedded properties
        if (key.startsWith('buttongroup')) {
          if (!ctrl.options.buttongroup) {
            ctrl.options.buttongroup = [];
          }
          // get exact index of updating property
          const index =
            parseInt(
              key
                .replace('buttongroupLabel', '')
                .replace('buttongroupValue', ''),
              10
            ) - 1;
          const property = key.indexOf('Label') > -1 ? 'label' : 'value';
          if (index < ctrl.options.buttongroup.length) {
            // update existing
            ctrl.options.buttongroup[index][property] = props.data[key];
          } else {
            const buttongroupItem: IDynamicButtonGroup = {};
            buttongroupItem[property] = props.data[key];
            ctrl.options.buttongroup.push(buttongroupItem);
          }
        } else if (key.startsWith('api')) {
          if (!ctrl.options.api) {
            ctrl.options.api = {};
          }
          if (!ctrl.options.api.model) {
            ctrl.options.api.model = {} as IApiModelType;
          }
          switch (key) {
            case 'api-endpoint':
              ctrl.options.api.endpoint = props.data[key];
              break;
            case 'api-model-Id':
              ctrl.options.api.model.id = props.data[key];
              break;
            case 'api-model-Name':
              ctrl.options.api.model.name = props.data[key];
              break;
            case 'api-data-properties':
              ctrl.options.api.properties = props.data[key];
              break;
          }
        } else if (key.startsWith('updateOnValueChange')) {
          if (!ctrl.options.updateOnValueChange) {
            ctrl.options.updateOnValueChange = [{}];
          }
          const nameParts = key.split('-');
          let index = 0;
          if (nameParts.length === 3) {
            // get index from the formControlName
            index = +nameParts.slice(-1)[0];
          }
          if (ctrl.options.updateOnValueChange[index]) {
            if (key.startsWith('updateOnValueChange-formControlName')) {
              ctrl.options.updateOnValueChange[index].formControlName =
                props.data[key];
            } else if (key.startsWith('updateOnValueChange-property')) {
              ctrl.options.updateOnValueChange[index].property =
                props.data[key];
            } else if (
              key.startsWith('updateOnValueChange-matchTargetProperty')
            ) {
              ctrl.options.updateOnValueChange[index].matchTargetProperty =
                props.data[key];
            } else if (key.startsWith('updateOnValueChange-validation')) {
              ctrl.options.updateOnValueChange[index].validation = <
                IDynamicUpdateOnValueChangeValidationTypes
              >props.data[key];
            }
          }
        } else if (key.startsWith('scopeQueryWhenFormControlIsSet')) {
          if (!ctrl.options.scopeQueryWhenFormControlIsSet) {
            ctrl.options.scopeQueryWhenFormControlIsSet = {};
          }
          switch (key) {
            case 'scopeQueryWhenFormControlIsSet-formControlName':
              ctrl.options.scopeQueryWhenFormControlIsSet.formControlName =
                props.data[key];
              break;
            case 'scopeQueryWhenFormControlIsSet-matchTargetProperty':
              ctrl.options.scopeQueryWhenFormControlIsSet.matchTargetProperty =
                props.data[key];
              break;
            case 'scopeQueryWhenFormControlIsSet-queryParamName':
              ctrl.options.scopeQueryWhenFormControlIsSet.queryParamName =
                props.data[key];
              break;
          }
        } else {
          ctrl.options[key] = props.data[key];
        }
      }
    }
  }

  private _reloadListAndSelectContainer(metadata?: IDynamicContainerMetadata) {
    return new Promise<void>((resolve) => {
      this.dynamicRender.loadContainerList(true, false, true).then(() => {
        if (metadata) {
          // ensure the newly created or updated form is selected in the list and loaded fully in builder
          this.selectSavedForm.emit(metadata);
        } else {
          this.progress.toggleSpinner(false);
        }
        resolve();
      });
    });
  }

  private _addToButtonGroup() {
    const selectedControl = dynamicFindSelectedControl(this.groupItems);
    if (selectedControl) {
      dynamicAddItemToButtonGroupForControl(selectedControl);
      this.select(selectedControl, true);
    }
  }

  private _removeFromOptions(options: {
    config: IDynamicModel;
    name: string;
    index: number;
  }) {
    const selectedControl = dynamicFindSelectedControl(this.groupItems);
    if (selectedControl) {
      if (selectedControl.options[options.name]) {
        // remove by indexed properties
        if (options.index < selectedControl.options[options.name].length) {
          selectedControl.options[options.name].splice(options.index, 1);
        }
      }
      this.select(selectedControl, true);
    }
  }

  private _addUpdateOnValueChange() {
    const selectedControl = dynamicFindSelectedControl(this.groupItems);
    if (selectedControl) {
      dynamicAddUpdateOnValueChangeForControl(selectedControl, {
        formControlName: '',
        property: '',
      });
      this.select(selectedControl, true);
    }
  }
}
