import { AdminInventoryEntry } from './admin-inventory-entry';
import { NiftySubLocationInventory } from './nifty-sublocation-inventory';

export class SublocationMaterial {
  productBarcode: number;
  material: string;
  description: string;
  uom: string;
  viewpointOnHand: number;
  finalCount: number;
  avgCost: number;
  dollarOffset: number;
  inventoryEntries: AdminInventoryEntry[];
  niftySubLocationInvs: NiftySubLocationInventory[];
}
