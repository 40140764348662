import { Injectable } from '@angular/core';
import { parseData } from '@ups/xplat/utils';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment, MyHttpClientFactory } from '@ups/xplat/core';
import { map } from 'rxjs/operators';

const GET_PLACE_MAP = '/api/mapquest/placeMap';
const GET_ROUTE = '/api/mapquest/route';

@Injectable({
  providedIn: 'root',
})
export class MapQuestService {
  http: HttpClient;

  constructor(private httpFactory: MyHttpClientFactory) {
    this.http = httpFactory.createHttpClient(environment.urls.spartaAPI);
  }

  getRoute(
    addressFrom: string,
    addressTo: string,
    forceReload: boolean = false
  ) {
    let url =
      GET_ROUTE + '?addressFrom=' + addressFrom + '&addressTo=' + addressTo;
    if (forceReload) url = url + '&forceReload=true';
    return this.http.get(url).toPromise().then(parseData);
  }

  getPlaceMap(address: string): Observable<Blob> {
    const url = GET_PLACE_MAP + '?address=' + address;
    return this.http
      .get(url, { responseType: 'blob' })
      .pipe(map((i) => i as Blob));
  }
}
