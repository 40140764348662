import { Component, EventEmitter, Output } from '@angular/core';
import { BaseComponent } from '@ups/xplat/core';
import { DynamicRenderService } from '@ups/xplat/features';

@Component({
  selector: 'ups-dynamic-delete-form-modal',
  template: `
    <kendo-dialog modal fade [width]="550" (close)="closeModal.emit(null)">
      <kendo-dialog-titlebar>
        <span>Delete Form</span>
      </kendo-dialog-titlebar>

      <p>Please enter the reason for deleting this form below.</p>

      <textarea
        class="k-textbox w-100"
        [attr.name]="deleteComment"
        kendoTextArea
        style="height: 135px"
        [(ngModel)]="deleteComment"
        [required]="true"
        placeholder="Enter deletion reason here..."
      ></textarea>

      <kendo-dialog-actions>
        <button class="k-button k-primary" (click)="closeModal.emit(null)">
          Cancel
        </button>
        <button class="k-button" (click)="deleteForm()">
          Delete this Form!
        </button>
      </kendo-dialog-actions>
    </kendo-dialog>
  `,
})
export class DynamicDeleteFormModalComponent extends BaseComponent {
  @Output() closeModal = new EventEmitter();
  @Output() deleteDone = new EventEmitter();
  deleteComment = '';

  constructor(private dynamicService: DynamicRenderService) {
    super();
  }

  deleteForm() {
    if (!this.deleteComment) return;

    this.dynamicService
      .updateDynamicResponse(this.deleteComment)
      .toPromise()
      .then(() => {
        this.closeModal.emit(null);
        this.deleteDone.emit(null);
      });
  }
}
