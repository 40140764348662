import { GridComponent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';

import { ReplaySubject } from 'rxjs';

import { PathSegmentDetached } from './../Group-Result/path-segment-detached';
import { FlattenedItemDetails } from './../Group-Result/group-result-flattener';
import { GroupResultSelectionSupport } from './../Group-Result/group-result-selection-support';

import { GroupCollapseExpandUtils } from './Utils/group-collapse-expand-utils';

import { GroupingExLoadSpecificGroups } from './grouping-ex.load-specific-groups';
import { GroupingExViewModel } from './grouping-ex.view-model';
import { GroupingExLoadResult } from './grouping-ex.load';

// NOTE:
// exports.isDate = function (value) { return value && value.getTime; };

/** state class we store between dataStateChange */
export interface IGroupingExDataState {
  expandedGroupPaths: PathSegmentDetached[][];
  selectedItemDetails: FlattenedItemDetails[];
}

export class GroupingExDataStateSupport {
  public static fetchCurrentState(grid: GridComponent): IGroupingExDataState {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const gridData = <any>grid.data;
    const gridItems = gridData ? gridData.data : null;

    const expandedGroupPaths = gridItems
      ? GroupCollapseExpandUtils.getExpansionDetails(
          grid,
          gridItems,
          null,
          grid.skip
        ).expanded
      : // no need to map, the PathSegment implements PathSegmentDetached interface
        // .map(psa => psa.map(i => ({field: i.field, value: i.value} as PathSegmentDetached)))
        [];

    const selectedItemDetails = gridItems
      ? GroupResultSelectionSupport.getItemSelectionDetails(gridItems).selected
      : [];

    if (!expandedGroupPaths.length && !selectedItemDetails.length) return null;

    return {
      expandedGroupPaths: expandedGroupPaths,
      selectedItemDetails: selectedItemDetails,
    } as IGroupingExDataState;
  }

  public static loadStateData(
    vm: GroupingExViewModel,
    grid: GridComponent,
    state: State,
    dataState: IGroupingExDataState
  ): ReplaySubject<GroupingExLoadResult> {
    // convert custom data to be used in the generic specific group loading function
    const groups = [].concat(
      dataState.expandedGroupPaths,
      dataState.selectedItemDetails.map((i) => i.parentPath)
    );

    // generic specific group load
    return GroupingExLoadSpecificGroups.loadSpecificGroups(
      vm,
      grid,
      groups,
      state
    );
  }

  public static mergeStateDataResultToGrid(
    // merge related
    vm: GroupingExViewModel,
    grid: GridComponent,
    state: State,
    result: GroupingExLoadResult,

    // grid refresh related
    selectedItemDetails: FlattenedItemDetails[] = null,
    equalityComparerCallback: (lef, right) => boolean = null
  ) {
    // merging generic specific groups (must collapse, as def. is uncollapsed)
    GroupingExLoadSpecificGroups.mergeLoadSpecificGroupsResultToGrid(
      vm,
      grid,
      state,
      result
    );
    GroupCollapseExpandUtils.collapseSubtree(grid, null);

    // handling state related grid refresh
    GroupingExDataStateSupport.refreshGridState(
      grid,
      selectedItemDetails,
      equalityComparerCallback
    );
  }

  public static refreshGridState(
    grid: GridComponent,
    selectedItemDetails: FlattenedItemDetails[] = null,
    equalityComparerCallback: (lef, right) => boolean = null
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const gridData = <any>grid.data;
    const gridItems = gridData ? gridData.data : null;

    if (gridItems) {
      if (selectedItemDetails && selectedItemDetails.length) {
        // refresh selection (equalityComparerCallback is mandatory in this case)
        GroupResultSelectionSupport.setSelectionFromItemDetails(
          gridItems,
          null,
          true,
          selectedItemDetails,
          equalityComparerCallback
        );
        GroupResultSelectionSupport.refreshGroupSelectionStateForGrid(
          gridItems
        );
      }

      // refresh expansion
      // NOTE:
      // if we load just the desired groups (no overlap), then the basic expandAll with expandJustWhenAggregateItemsPresent: true will do the trick (expand all groups with items loaded)...
      // if our WHERE expression is not specific enough, we might end up with extra data merged and auto-expanded...
      GroupCollapseExpandUtils.expandAll(grid, gridItems, true);
    }
  }
}
