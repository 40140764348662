import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BulkMessageModalComponent } from './bulk-message-modal.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { UIModule } from '../ui';

@NgModule({
  imports: [
    CommonModule,
    DialogsModule,
    SortableModule,
    ButtonsModule,
    UIModule,
  ],
  declarations: [BulkMessageModalComponent],
  exports: [BulkMessageModalComponent],
})
export class BulkMessageModalModule {}
