import { Address } from '../../models-sparta';

export class EmployeeOrProspect {
  EmployeeId: string | null;
  ProspectId: string | null;
  HRRef: number | null;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  MailingAddress: Address;
  PhysicalAddress: Address;
  Class: string;
  Designation: string;
  SSN: string;
  Phone: string;
  CellPhone: string;
  Email: string;
  Company: number | null;
  BirthDate: Date | null;
  AlternatePhone: string;
  IsProspect: boolean | null;
  TWICCardExpirationDate: Date | null;
  PreferredSafetyCouncil: string;
  PossibleCompanies: (number | null)[];
  PREmp: string;
  TermDate: Date | null;
  MostRecentTermDate: Date | null;
  IsActiveInAnyCompany: boolean;
  IsNoRehire: boolean;
  RehireStatus: string;
}
