import { Input, ComponentFactoryResolver, ViewContainerRef, ComponentRef, Inject, forwardRef, Directive } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PlatformDynamicComponentsToken, PlatformDynamicComponentsPreviewToken, DynComponentMapping } from '@ups/xplat/features';
import { AdminPreviewPanelItem } from '../models';

export interface IDynamicPreviewItem {
  config: AdminPreviewPanelItem;
  //group: FormGroup;
}

@Directive({
  selector: '[dynamicItemPreview]',
})
export class DynamicItemPreviewDirective {
  @Input() config: AdminPreviewPanelItem;
  //@Input() group: FormGroup;

  public component: ComponentRef<IDynamicPreviewItem>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef,
    @Inject(forwardRef(() => PlatformDynamicComponentsPreviewToken))
    private dynComponentMapping: DynComponentMapping
  ) {}

  public ngOnInit() {
    const component = this.dynComponentMapping[this.config.controlName];

    if (!component) {
      throw new Error(`Component for controlName '${this.config.controlName}' not found.`);
    }
    const factory = this.resolver.resolveComponentFactory<IDynamicPreviewItem>(component);
    this.component = this.container.createComponent(factory);
    this.component.instance.config = this.config;
    // this.component.instance.group = this.group;
  }
}
