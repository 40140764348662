// import { deepClone } from '@ups/xplat/utils';

export class BaseModel {
  constructor(o: unknown = null) {
    if (o) {
      Object.assign(this, o);
      // Object.assign(this, deepClone(o));
    }
  }
}
