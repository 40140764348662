import { EditorTooltipComponent } from './editor-component/editor-tooltip/editor-tooltip.component';
import { EditorWalkthroughComponent } from './editor-component/editor-walkthrough/editor-walkthrough.component';
import { EditorComponent } from './editor-component/editor.component';
import { ElementPickerComponent } from './element-picker/element-picker.component';
import { HelpComponent } from './help-component/help.component';
import { HelpDeskModalComponent } from './help-desk-modal/help-desk-modal.component';
import { HelpDocumentsComponent } from './help-documents/help-documents.component';
import { WalkthroughPlayerComponent } from './walkthrough-component/walkthrough.component';

export * from './editor-component/editor-walkthrough/editor-walkthrough.component';
export * from './editor-component/editor-tooltip/editor-tooltip.component';
export * from './help-component/help.component';
export * from './editor-component/editor.component';
export * from './walkthrough-component/walkthrough.component';
export * from './help-desk-modal/help-desk-modal.component';
export * from './help-documents/help-documents.component';

export const COMPONENTS = [HelpComponent, EditorComponent, EditorTooltipComponent, EditorWalkthroughComponent, ElementPickerComponent, WalkthroughPlayerComponent, HelpDeskModalComponent, HelpDocumentsComponent];
