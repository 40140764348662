import { Injectable } from '@angular/core';
import { ApplicationModel } from '@ups/xplat/api/dto';
import { ProfileService } from '@ups/xplat/api/services';

@Injectable({ providedIn: 'root' })
export class RoleResolver {
  isLoading: boolean = false;

  constructor(private profileService: ProfileService, private applicationModel: ApplicationModel) {}

  public load(): Promise<any> {
    const self = this;
    if (this.applicationModel.roles.length === 0 && !this.isLoading) {
      self.isLoading = true;
      return this.profileService.fetchSpartaRoles().then(function (data) {
        self.applicationModel.roles = data;
        self.isLoading = false;
      });
    } else {
      return Promise.resolve(self.applicationModel.roles);
    }
  }
}
