import { Component } from '@angular/core';
import { DynamicFileBaseComponent } from '@ups/xplat/features';
import { SpartaFileService } from '@ups/files';
import { FileDto } from '@ups/xplat/api/dto';

@Component({
  selector: 'ups-dynamic-file',
  template: `
    <div class="dynamic-field-container" [class.selected]="config?.selected">
      <div
        class="dynamic-field form-input text-align"
        [hidden]="!config?.isFormBuilder && config?.options?.hidden"
        kendoTooltip
      >
        <ups-dynamic-helper-label
          [config]="config"
          [dynamicService]="dynamicService"
        ></ups-dynamic-helper-label>
        <div
          style="margin: 10px 0px 22px;"
          [hidden]="
            (!!dynamicService.activeFormResponse &&
              dynamicService.activeFormResponse?.isDraft === false) ||
            (!config?.options?.fileAllowMultiple && attachedFileCount >= 1)
          "
        >
          <input
            type="file"
            (change)="fileChange($event)"
            [disabled]="config?.disabled"
          />
        </div>
        <div
          *ngFor="
            let attachment of dynamicService.activeAttachments
              ? dynamicService.activeAttachments[config?.formControlName]
              : []
          "
          style="margin: 12px 0px 20px;"
        >
          <button
            class="btn btn-sm btn-danger"
            style="margin-right: 20px"
            (click)="removeFile(attachment)"
            [hidden]="
              !!dynamicService.activeFormResponse &&
              dynamicService.activeFormResponse?.isDraft === false
            "
            title="Delete"
          >
            <i class="fa fa-trash"></i>
          </button>
          <button
            class="btn btn-sm btn-primary"
            style="margin-right: 20px"
            (click)="open(attachment)"
            [hidden]="!dynamicService.activeFormResponse"
            title="Download"
          >
            <i class="fa fa-download"></i>
          </button>
          <span>{{ attachment?.name }}</span>
        </div>

        <div
          style="font-style: italic; font-size: 13px"
          [hidden]="
            !dynamicService.activeFormResponse ||
            !dynamicService.activeFormResponse?.isDraft ||
            dynamicService.activeAttachments[config?.formControlName]?.length
          "
        >
          No attachments
        </div>

        <div
          class="form-builder-mask"
          [class.hidden]="config?.options?.hidden"
          *ngIf="config?.isFormBuilder"
        ></div>
      </div>
      <div class="form-builder-options" *ngIf="config?.isFormBuilder">
        <i
          class="fas fa-times ml-3 cursor-pointer c-red"
          (click)="delete()"
        ></i>
        <!-- <i class="fas fa-bars ml-3 cursor-grab "></i> -->
      </div>
    </div>
  `,
})
export class DynamicFileComponent extends DynamicFileBaseComponent {
  constructor(spartaFileService: SpartaFileService) {
    super(spartaFileService);
  }

  fileChange(args) {
    if (!this.config.options.fileAllowMultiple && this.attachedFileCount) {
      return;
    }
    if (!this.win.navigator.onLine) {
      this.win.alert(this.offlineNote);
      return;
    }

    const fileList = args.target.files;

    if (fileList.length > 0) {
      const file: File = fileList[0];
      const myReader: FileReader = new FileReader();

      myReader.onload = () => {
        const fileDto: FileDto = new FileDto();
        if (typeof myReader.result === 'string') {
          fileDto.contentBase64 = myReader.result;
        } else {
          fileDto.content = myReader.result;
        }

        fileDto.name = file.name;
        fileDto.size = file.size;
        fileDto.contentType = file.type;
        fileDto.directory = 'addressChangeRequest';
        fileDto.bActive = true;

        this.createAndUploadFile(
          file.name,
          myReader.result,
          file.type,
          file.size
        ).then((result) => {
          if (result) {
            this.fileUploaded.emit();
          } else {
            // failure
          }
          args.target.value = '';

          if (!/safari/i.test(this.win.platformWindow.navigator.userAgent)) {
            args.target.type = '';
            args.target.type = 'file';
          }
        });
      };

      // this causes the myReader.onload() callback to be called defined above
      myReader.readAsDataURL(file);
    }
  }

  open(file: FileDto) {
    this.spartaFileService.downloadFileFromSparta(file.fileID).then(
      function (data) {
        window.open(data.Data, '_blank');
      },
      function () {}
    );
  }
}
