import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'NonNullPipe',
})
/**
 * Usage:
 *
 * <tr *ngFor="let item of mf.data | NonNullPipe:'fieldName':trueOrFalseValue">
 *
 * ts file:
 * showNull : boolean = true;
 <label>
 <input type="checkbox" [(ngModel)]="showNull"> Show empty JobNumbers
 </label>
 <tr *ngFor="let item of mf.data | NonNullPipe:'JobClass':showNull">
 */
export class NonNullPipe implements PipeTransform {
  transform(values: any[], key: string, args: any): any {
    return values.filter((value) => {
      if (args == undefined) {
        return true;
      }

      if (args == true) {
        if (!value.hasOwnProperty(key) || !value[key]) return true;
      } else {
        if (value[key]) return true;
        else return false;
      }

      return true;
    });
  }
}
