/* eslint-disable @typescript-eslint/naming-convention */
export class EquipmentUsageCorrectionDto {
  public Amount: number;
  public CreatedBy: string;
  public DateCreated: string;
  public DateExported: string;
  public TimeSheetID: string;
  public TimeSheetName: string;
  public TransactionType: string;
}
