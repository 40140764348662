export class PickMaterial {
  material: string;
  location: string;
  locationDesc: string;
  subLocationId: number;
  subLocationName: string;
  qtyOnOrder: number;
  qtyReadyToPick: number;
  qtyPicked: number;
  qtyUnableToPick: number;
  amtAvailableAtLocation: number;
  pickListID: number;
  keyID: number;
  lineItemId: number;
}
