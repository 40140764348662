import { Directive, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';

import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

/*
Adds a clear button to all drop-down lists!

    Original implementation used an Output property (event) so for the clearing a view-method was responsible.
    See: https://stackblitz.com/edit/angular-uv553z-objyev?file=app/app.component.ts

    This version behaves same as the combo-box or autocomplete (so sets a undefined value).
 */
@Directive({
  selector: 'kendo-dropdownlist',
})
export class DropDownListClearButtonDirective
  implements AfterViewInit, OnDestroy
{
  public resetButton: HTMLElement;

  private changeSubscription: Subscription;

  constructor(public renderer: Renderer2, public ddl: DropDownListComponent) {
    this.changeSubscription = this.ddl.valueChange.subscribe(() => {
      this.toggle(!!this.ddl.value);
    });
  }

  public ngAfterViewInit(): void {
    const openButton =
      this.ddl.wrapper.nativeElement.querySelector('.k-input-button');
    this.resetButton = document.createElement('button');

    this.resetButton.setAttribute(
      'class',
      'k-input-button k-button k-icon-button k-button-md k-button-solid k-button-solid-base custom-clear-button'
    );
    this.resetButton.innerHTML = "<span class='k-icon k-i-close'></span>";

    this.renderer.insertBefore(
      this.ddl.wrapper.nativeElement,
      this.resetButton,
      openButton
    );
    this.renderer.listen(this.resetButton, 'click', this.reset.bind(this));
    this.toggle(true);
  }

  public ngOnDestroy(): void {
    this.changeSubscription.unsubscribe();
  }

  private toggle(visible: boolean): void {
    this.renderer.setStyle(
      this.resetButton,
      'visibility',
      visible ? 'visible' : 'hidden'
    );
  }

  private reset(): void {
    // AVOID THE ACTION IF READONLY (DISABLED IS ALREADY HANDLED BY THE CONTROL)
    if (this.ddl.readonly) return;

    // ?
    this.ddl.open.pipe(take(1)).subscribe((e) => {
      e.preventDefault();
    });

    // does the desired clean, but without UI update and events...
    this.ddl.reset();

    // update UI
    this.ddl.writeValue(this.ddl.value);

    // NOTE: emit change does the same as the 2 lines below - (<any>this.ddl).emitChange(undefined);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (<any>this.ddl).onChangeCallback(this.ddl.value);
    this.ddl.valueChange.emit(this.ddl.value);

    this.toggle(false);
  }
}

/*
import { Component, Directive, Renderer2, EventEmitter, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';

import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[kendoClearableDropDown]'
})
export class ClearableDirective implements AfterViewInit, OnDestroy {

  @Output() public resetValue: EventEmitter<any> = new EventEmitter();

  public resetButton: HTMLElement;

  private changeSubscription: Subscription;

  constructor(
    public renderer: Renderer2,
    public ddl: DropDownListComponent) {
    this.changeSubscription = this.ddl.valueChange.subscribe(() => { this.toggle(!!this.ddl.value) });
  }

  private toggle(visible: boolean): void {
    this.renderer.setStyle(this.resetButton, "visibility", visible ? "visible" : "hidden");
  }

  private reset(): void {
    this.ddl.open.pipe(take(1)).subscribe((e: any) => { e.preventDefault(); });
    this.resetValue.emit();
    this.toggle(false);

  }

  public ngAfterViewInit(): void {
    const openButton = this.ddl.wrapper.nativeElement.querySelector(".k-select");
    this.resetButton = document.createElement('span');

    this.resetButton.setAttribute('class', 'k-select');
    this.resetButton.innerHTML = "<span class='k-icon k-i-close'></span>";

    this.renderer.insertBefore(this.ddl.wrapper.nativeElement, this.resetButton, openButton);
    this.renderer.listen(this.resetButton, "click", this.reset.bind(this));
    this.toggle(false);
  }

  public ngOnDestroy(): void {
    this.changeSubscription.unsubscribe();
  }

}

@Component({
  selector: 'my-app',
  template: `
      <kendo-textbox-container floatingLabel="Select size...">
        <kendo-dropdownlist
          kendoClearableDropDown
          (resetValue)="onReset()"
          [data]="data"
          [textField]="'text'"
          [valueField]="'value'"
          [(ngModel)]="model"
        >
        </kendo-dropdownlist>
      </kendo-textbox-container>

  `
})
export class AppComponent {

  public onReset(): void {
    this.model = undefined;
  }

  public model = undefined;

  public data: Array<{ text: string, value: number }> = [
    { text: "Small", value: 1 },
    { text: "Medium", value: 2 },
    { text: "Large", value: 3 }
  ];

}

 */
