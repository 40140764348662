import { BaseModel } from '../base.model';

export class EmployeeRatesDto extends BaseModel {
  public filePayRate: number;
  public contractRate: number;
  public fieldPayRate: number;
  public shopPayRate: number;
  public nonBillablePayRate: number;
  public contractOTFactor: number;
  public contractPTFactor: number;
  public contractPerDiem: number;

  constructor(o: any = null) {
    super(o);
  }
}
