import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

import { ValidatorsModuleEx } from '@ups/xplat/features';

import {
  GridModuleEx,
  GroupingGridExModule,
} from '../kendo/Grid';
import { UIModule } from '../ui';
import {
  EquipmentBasicInfoModule,
  EquipmentResourceDropdownModule,
  EQUIPMENT_DISPATCH_COMPONENTS,
} from './components';
import { KendoDatepickerSharedModule } from '../kendo-datepicker/kendo-datepicker-shared.module';

export const EQUIPMENT_DISPATCH_MODULES = [
  CommonModule,
  FormsModule,
  DropDownsModule,
  GridModule,
  GridModuleEx,
  GroupingGridExModule,
  TooltipModule,
  DatePickerModule,
  ValidatorsModuleEx,
  EquipmentResourceDropdownModule,
  EquipmentBasicInfoModule,
  UIModule,
];

@NgModule({
  declarations: [...EQUIPMENT_DISPATCH_COMPONENTS],
  imports: [
    CommonModule,
    ...EQUIPMENT_DISPATCH_MODULES,
    KendoDatepickerSharedModule,
  ],
  exports: [...EQUIPMENT_DISPATCH_MODULES],
  providers: [],
})
export class EquipmentDispatchModule {}
