export const isString = function (arg: unknown) {
  return typeof arg === 'string';
};
export const isDate = function (arg: unknown) {
  return arg && (arg as Date).getTime;
};

export const isObject = function (arg: unknown) {
  return arg && typeof arg === 'object';
};

export const isFunction = (value) =>
  value &&
  (Object.prototype.toString.call(value) === '[object Function]' ||
    'function' === typeof value ||
    value instanceof Function);

export const isUUID = (s: string) =>
  !!s.match(
    '^([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[0-9a-dA-D][0-9a-fA-F]{3}-[0-9a-fA-F]{12}|00000000-0000-0000-0000-000000000000)$'
  );

export function isNullOrEmpty(value: unknown): boolean {
  if (value == null || value === '') return true;
  return false;
}

export function isPresent(value: unknown) {
  return value !== null && value !== undefined;
}
