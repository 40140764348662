import { Component } from '@angular/core';
import { DynamicItemBaseComponent } from '@ups/xplat/features';

@Component({
  selector: 'ups-dynamic-input',
  styleUrls: ['dynamic-input.component.scss'],
  template: `
    <div class="dynamic-field-container" [class.selected]="config?.selected">
      <div
        class="dynamic-field form-input text-align"
        [formGroup]="group"
        [hidden]="!config?.isFormBuilder && config?.options?.hidden"
        [class.standalone]="config?.options?.standalone"
        [ngStyle]="config?.options?.style?.container"
      >
        <ups-dynamic-helper-label
          [config]="config"
          [dynamicService]="dynamicService"
        ></ups-dynamic-helper-label>
        <input
          class="k-textbox"
          [attr.name]="config.formControlName"
          [attr.placeholder]="config?.placeholder"
          [formControlName]="config.formControlName"
          [attr.type]="config?.inputType || 'text'"
          [attr.maxLength]="
            config?.options?.maxlength ? config?.options?.maxlength : null
          "
        />
        <div
          class="form-builder-mask"
          [class.hidden]="config?.options?.hidden"
          *ngIf="config?.isFormBuilder"
        ></div>
      </div>
      <div class="form-builder-options" *ngIf="config?.isFormBuilder">
        <i
          class="fas fa-times ml-3 cursor-pointer c-red"
          (click)="delete()"
        ></i>
        <!-- <i class="fas fa-bars ml-3 cursor-grab "></i> -->
      </div>
    </div>
  `,
})
export class DynamicInputComponent extends DynamicItemBaseComponent {}
