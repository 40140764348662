export class DispatchedEquipmentDto {
  public altID: string;
  public bActive: boolean;
  public billingRate: number;
  public customerID: string;
  public endDate: string;
  public equipmentBillingID: string;
  public equipmentDispatchID: string;
  public equipmentID: string;
  public equipmentKeyID: number;
  public equipmentName: string;
  public jobID: string;
  public name: string;
  public note: string;
  public startDate: string;
  public unitName: string;
}
