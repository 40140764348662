import { Directive } from '@angular/core';
import { BaseComponent } from '@ups/xplat/core';

@Directive()
export abstract class ProfileBenefitsBaseComponent extends BaseComponent {
  public text = 'ProfileBenefits';

  constructor() {
    super();
  }
}
