import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  SearchEmployeeHRRefService,
  SearchEmployeeService,
} from '@ups/xplat/api/services';
import { IDynamicModel } from '@ups/xplat/core';

import { DynamicTypeaheadEmployeeWebBaseComponent } from '../base/dynamic-typeahead-employee.web-base-component';

const SEARCH_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DynamicTypeaheadEmployeeComponent),
  multi: true,
};

@Component({
  selector: 'ups-dynamic-typeahead-employee',
  templateUrl: 'dynamic-typeahead-employee.component.html',
  providers: [SEARCH_CONTROL_VALUE_ACCESSOR],
})
export class DynamicTypeaheadEmployeeComponent
  extends DynamicTypeaheadEmployeeWebBaseComponent
  implements OnInit
{
  constructor(
    private searchService: SearchEmployeeService,
    private hrRefSearchService: SearchEmployeeHRRefService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.searchQuery = this.config?.options?.distinctHRRef
      ? this.hrRefSearchService.search.bind(this.hrRefSearchService)
      : this.searchService.search.bind(this.searchService);
  }

  public showCellMask(config: IDynamicModel): boolean {
    let showMask = false;
    if (config) {
      if (config?.formControlName) {
        if (
          config?.formControlName === 'businessDevelopmentId' ||
          config?.formControlName === 'estimatorId'
        ) {
          showMask = true;
        }
      }
    }

    return showMask;
  }
}
