import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ups-notes',
  templateUrl: 'notes.component.html',
})
export class NotesComponent {
  @Input()
  previousNotes: string;

  @Input()
  isDisabled = false;

  @Input()
  hidePreviousNotes = false;

  @Input()
  hidePreviousNotesLabel = true;

  @Input()
  hideNewNote = false;

  @Input() newNote: string;

  @Output()
  newNoteChanged: EventEmitter<string> = new EventEmitter();

  constructor() {}

  onNewNoteChange() {
    this.newNoteChanged.emit(this.newNote);
  }
}
