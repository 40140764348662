<div class="d-flex">
  <kendo-dropdownlist
    id="equipmentCombobox"
    name="equipmentCombobox"
    [data]="filteredEquipments"
    [value]="equipment"
    (valueChange)="valueChangeEquipment($event)"
    [(ngModel)]="equipment"
    [required]="required"
    textField="DisplayName"
    valueField="DisplayName"
    [disabled]="disabled || isEquipmentLoading"
    [ngClass]="{ 'ng-invalid': required && !equipment }"
    [loading]="isEquipmentLoading"
    class="me-7"
    [filterable]="true"
    (filterChange)="fetchFilteredEquipmentDebounced($event, false, true)"
    help-anchor="dropdown__equipmentList"
  ></kendo-dropdownlist>
  <div class="d-flex align-items-center">
    <label class="me-5" for="equipmentCompanyCombobox">Co:</label>
    <kendo-dropdownlist
      id="equipmentCompanyCombobox"
      style="width: 7em"
      name="equipmentCompanyCombobox"
      [data]="selectedEquipmentOtherCompanies"
      [value]="companyEquipment"
      (valueChange)="valueChangeCompany($event)"
      textField="VPCompanyID"
      valueField="DisplayName"
      [disabled]="disabled || isEquipmentLoading || isEquipmentFilterLoading"
      [loading]="isEquipmentLoading || isEquipmentFilterLoading"
      [popupSettings]="{ width: 'auto' }"
    ></kendo-dropdownlist>
  </div>
</div>
