import { BaseModel } from '../base.model';
import { AddressModel } from './address-model';

export class PendingAddressModel extends BaseModel {
  FirstName: string;
  LastName: string;
  MailingAddress: AddressModel = new AddressModel();
  PhysicalAddress: AddressModel = new AddressModel();
  HRRef: string;
  PreferredSafetyCouncil: string;
  IsUpdatingPendingAddress: boolean;

  constructor(o: any = null) {
    super(o);
    if (o) {
      this.MailingAddress = new AddressModel(this.MailingAddress);
      this.PhysicalAddress = new AddressModel(this.PhysicalAddress);
    }
  }
}
