/* eslint-disable @typescript-eslint/no-explicit-any */

import { Injectable } from '@angular/core';
import { CompanyDto } from '../sparta-api';
import { NameIdDescModel } from './name-id-desc.model';

@Injectable({
  providedIn: 'root',
})
export class ApplicationModel {
  // Drop Down Properties
  companies: any = [];
  /** All companies regardless currently logged in user has permissions to see them or not*/
  companiesFromSparta: CompanyDto[] = [];
  /** Companies that the currently logged in user has access to / has granted permissions to see them in our security portal. */
  absentReasons: any = [];
  backgroundStatuses: any = [];
  backgroundTeam: any = [];
  backgroundTypes: any[] = [];
  certSkillCodes: any = [];
  physicalSkillCodes: any = [];
  backgroundSkillCodes: any = [];
  certificationSkillTesters: any[] = [];
  companiesFromSpartaForUser: CompanyDto[] = [];
  countries: any = [];
  customerIndustries: any[] = [];
  declinationReasons: any = [];
  dispatchGroups: any = [];
  dispatchStatuses: any = [];
  drugTestTypes: any[] = [];
  employeePositionNames: any[] = [];
  employmentVerificationTypes: any = [];
  equipmentDispatchStatuses: any = [];
  hrAppIdCardTypes: any[] = [];
  jobBillMethods: any = [];
  laborTypes: any[] = [];
  licenseTypes: any[] = [];
  lodgeRoomTypes: any[] = [];
  mileages: any = [];
  perDiems: any = [];
  perDiemSupportedTypes: any = [];
  recruiters: any = [];
  regions = [];
  roles: any[] = [];
  saftyCouncils: any[] = [];
  states: any = [];
  timesheetDesignations: NameIdDescModel[] = [];
  tradeClasses: any[] = [];
  tradeGroups: any[] = [];
  typesOfProcessing: any = [];
  workgroups: any = [];

  jobRequirementStatusesById: any = {};

  _jobRequirementStatuses: any = [];
  set jobRequirementStatuses(value: any[]) {
    this._jobRequirementStatuses = value;
    for (let i = 0; i < value.length; i++) {
      const manpowerReq = value[i];

      for (let j = 0; j < manpowerReq.RequirementStatuses.length; j++) {
        const rs = manpowerReq.RequirementStatuses[j];

        this.jobRequirementStatusesById[rs.ManPowerRequirementStatusID] = rs;
      }
    }
  }

  get jobRequirementStatuses(): any[] {
    return this._jobRequirementStatuses;
  }

  _selectedCompanyId: any = 0;
  set selectedCompanyId(value: any) {
    if (value !== this._selectedCompanyId) {
      this._selectedCompanyId = value;
    }
  }

  get selectedCompanyId(): any {
    return this._selectedCompanyId;
  }
}
