import { Component, Injector, Input, OnInit } from '@angular/core';

import { switchMap, catchError, debounceTime } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import {
  CompositeFilterDescriptor,
  State,
  filterBy,
  FilterDescriptor,
  orderBy,
  SortDescriptor,
  distinct,
  GroupDescriptor,
} from '@progress/kendo-data-query';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import {
  ApplicationModel,
  CountSettingsEnum,
  EquipmentRequestGridDto,
  IODataResult,
} from '@ups/xplat/api/dto';
import { CodeListResolver } from '@ups/xplat/web/services';
import { EquipmentService } from '@ups/xplat/api/services';
import { nameOf } from '@ups/xplat/utils';
import { GroupOData } from '../../../kendo/Grid/Grouping/OData';
import { of } from 'rxjs';

@Component({
  selector: 'ups-equipment-score-card',
  templateUrl: './equipment-score-card.component.html',
  styleUrls: ['./equipment-score-card.component.scss'],
})
export class EquipmentScoreCardComponent implements OnInit {
  countSettingsEnum = CountSettingsEnum;
  @Input() countSettings: CountSettingsEnum = CountSettingsEnum.Equipment;
  activeJobsCount = 0;
  openRequests = 0;
  filledRequests = 0;
  onsiteRequests = 0;
  dispatchedRequests = 0;
  totalRequests = 0;
  supervisorDataAll: string[] = [];
  plantNameDataAll: string[] = [];
  jobDataAll: string[] = [];
  equipmentTypeNameDataAll: string[] = [];
  assetControlManagerNameDataAll: string[] = [];
  companyDropdownDataFiltered: string[] = [];
  supervisorDropdownDataFiltered: string[] = [];
  plantNameDropdownDataFiltered: string[] = [];
  jobsDropdownDataFiltered: string[] = [];
  equipmentTypeNamesDropdownDataFiltered: string[] = [];
  equipmentTypeNamesData: EquipmentRequestGridDto[] = [];
  assetControlManagerNamesDropdownDataFiltered: string[] = [];
  assetControlManagerNamesData: EquipmentRequestGridDto[] = [];
  jobsData: EquipmentRequestGridDto[] = [];
  supervisorsData: EquipmentRequestGridDto[] = [];
  plantNamesData: EquipmentRequestGridDto[] = [];
  dateData: EquipmentRequestGridDto[] = [];
  weeksData: EquipmentRequestGridDto[] = [];
  monthsData: EquipmentRequestGridDto[] = [];
  // Remark: the field names must match the property names (UpperCase - but on server it is LowerCased) of the api/equipment/equipment-request-chart-data-by-weeks-equipment
  companyFieldName = nameOf<EquipmentRequestGridDto>('VPCompanyID');
  supervisorFieldName = nameOf<EquipmentRequestGridDto>('SupervisorName');
  plantNameFieldName = nameOf<EquipmentRequestGridDto>('PlantName');
  jobFieldName = nameOf<EquipmentRequestGridDto>('VPJobID');
  jobNameFieldName = nameOf<EquipmentRequestGridDto>('JobName');
  equipmentTypeNameFieldName =
    nameOf<EquipmentRequestGridDto>('EquipmentTypeName');
  assetControlManagerNameFieldName = nameOf<EquipmentRequestGridDto>(
    'AssetControlManagerName'
  );
  applicationModel: ApplicationModel;
  companyFilter: CompositeFilterDescriptor = {
    logic: 'or',
    filters: [],
  };
  supervisorFilter: CompositeFilterDescriptor = {
    logic: 'or',
    filters: [],
  };
  plantNameFilter: CompositeFilterDescriptor = {
    logic: 'or',
    filters: [],
  };
  jobFilter: CompositeFilterDescriptor = {
    logic: 'or',
    filters: [],
  };
  equipmentTypeNameFilter: CompositeFilterDescriptor = {
    logic: 'or',
    filters: [],
  };
  assetControlManagerNameFilter: CompositeFilterDescriptor = {
    logic: 'or',
    filters: [],
  };
  state: State = {
    group: [],
  };
  height: number;

  public color: unknown[] = [
    {
      color: '#AA9569',
    },
  ];

  public placeholderColor = '#EF3B24';

  loadChartData$: Subject<void> = new Subject();
  private securityConstants = SecurityConstants;

  constructor(
    private equipmentService: EquipmentService,
    public injector: Injector,
    private codeListResolver: CodeListResolver,
    private securityService: SecurityService
  ) {
    this.applicationModel = injector.get(ApplicationModel);
  }
  ngOnInit() {
    this.getScoreCardDropdownsData();
    this.setLoadChartDataObservable();
    this.loadChartData$.next();
  }

  canReadAll(): boolean {
    return this.securityService.getFeatureById(
      SecurityConstants.employee_portal_equipment_equipmentdashboardscorecardtab
    ).readAll;
  }

  getScoreCardDropdownsData() {
    this.codeListResolver
      .loadCompanies()
      .then(
        () =>
          (this.companyDropdownDataFiltered = this.applicationModel.companies)
      );
    this.equipmentService.fetchScoreCardDropdownsData().subscribe(
      (returnData) => {
        this.getFilteredDataByField(returnData);
        this.supervisorDropdownDataFiltered = filterBy(
          this.supervisorDataAll,
          this.companyFilter
        );
        this.plantNameDropdownDataFiltered = filterBy(
          this.plantNameDataAll,
          this.companyFilter
        );
        this.jobsDropdownDataFiltered = filterBy(
          this.jobDataAll,
          this.companyFilter
        );
        this.equipmentTypeNamesDropdownDataFiltered = filterBy(
          this.equipmentTypeNameDataAll,
          this.companyFilter
        );
        this.assetControlManagerNamesDropdownDataFiltered = filterBy(
          this.assetControlManagerNameDataAll,
          this.companyFilter
        );
      },
      (error) => {
        throw error;
      }
    );
  }

  private loadChartDataWithGroup(
    group: string | GroupDescriptor[]
  ): Observable<IODataResult<EquipmentRequestGridDto>> {
    return this.loadChartData$.pipe(
      debounceTime(500),
      switchMap(() => {
        return this.getDataByGroupFieldEquipment(group);
      }),
      catchError((err) => {
        console.log('Issue with getDataByGroupFieldEquipment', err);
        return of(null);
      })
    );
  }

  setLoadChartDataObservable() {
    // Equipment Allocation By Month
    // Equipment Allocation By Day
    this.loadChartDataWithGroup([
      { field: nameOf<EquipmentRequestGridDto>('StartDate') },
      { field: nameOf<EquipmentRequestGridDto>('EndDate') },
    ]).subscribe((response) => {
      this.dateData = response.value;
      this.monthsData = response.value;
    });

    // Equipment Allocation By Week
    this.loadChartData$
      .pipe(
        debounceTime(500),
        switchMap(() => {
          return this.getDataGroupedByWeek();
        }),
        catchError(() => of(null))
      )
      .subscribe((response) => {
        this.weeksData = response;
      });

    // Jobs With Equipment Requests
    this.loadChartDataWithGroup([
      { field: this.jobFieldName },
      { field: this.jobNameFieldName },
    ]).subscribe((response) => {
      this.jobsData = response.value;
      this.activeJobsCount = parseInt(response['@odata.count'], 10);
      this.filledRequests = 0;
      this.openRequests = 0;
      this.onsiteRequests = 0;
      this.dispatchedRequests = 0;
      this.totalRequests = 0;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.jobsData.forEach((element: any) => {
        this.filledRequests += element.nonEmptyCount;
        this.openRequests += element.totalCount - element.nonEmptyCount;
        this.onsiteRequests += element.onsiteCount;
        this.dispatchedRequests += element.dispatchedCount;
        this.totalRequests += element.totalCountOnsite;
      });
    });

    // Plants with Equipment Requests
    this.loadChartDataWithGroup(this.plantNameFieldName).subscribe(
      (response) => {
        this.plantNamesData = response.value;
      }
    );

    // Asset Control Manager
    this.loadChartDataWithGroup(
      this.assetControlManagerNameFieldName
    ).subscribe((response) => {
      this.assetControlManagerNamesData = response.value;
    });

    // Supervisors
    this.loadChartDataWithGroup(this.supervisorFieldName).subscribe(
      (response) => {
        this.supervisorsData = response.value;
      }
    );

    // Requested Equipment
    this.loadChartDataWithGroup(this.equipmentTypeNameFieldName).subscribe(
      (response) => {
        this.equipmentTypeNamesData = response.value;
      }
    );
  }

  getDataByGroupFieldEquipment(group: string | GroupDescriptor[]) {
    const customAggregate = `${nameOf<EquipmentRequestGridDto>(
      'HasEquipment'
    )} with sum as nonEmptyCount,${nameOf<EquipmentRequestGridDto>(
      'UseInTotal'
    )} with sum as totalCount,${nameOf<EquipmentRequestGridDto>(
      'IsOnsite'
    )} with sum as onsiteCount,${nameOf<EquipmentRequestGridDto>(
      'IsDispatched'
    )} with sum as dispatchedCount,${nameOf<EquipmentRequestGridDto>(
      'UseInTotalOnsite'
    )} with sum as totalCountOnsite`;
    if (typeof group === 'string') {
      this.state.group = [{ field: group }];
    } else if (Array.isArray(group)) {
      this.state.group = group;
    }
    const groupDataQuery = GroupOData.getGroupQuery(
      this.state,
      null,
      null,
      false,
      0,
      'count',
      customAggregate
    );
    return this.equipmentService.queryEquipmentRequestData(groupDataQuery);
  }

  getDataGroupedByWeek() {
    const queryString = this.getFilterQueryString();
    return this.equipmentService.queryScoreCardChartDataByWeeks(
      52,
      queryString
    );
  }

  // We use here API feature where ?foo=1&foo=2 is called on API backend as FooApiEndpoint(int[] foo) - where foo builds up an array.
  getFilterQueryString() {
    let queryString = '?';
    if (this.companyFilter.filters.length) {
      this.companyFilter.filters.forEach((element: FilterDescriptor) => {
        queryString += `${element.field}=${element.value}&`;
      });
    }
    if (this.supervisorFilter.filters.length) {
      this.supervisorFilter.filters.forEach((element: FilterDescriptor) => {
        queryString += `${element.field}=${element.value}&`;
      });
    }
    if (this.plantNameFilter.filters.length) {
      this.plantNameFilter.filters.forEach((element: FilterDescriptor) => {
        queryString += `${element.field}=${element.value}&`;
      });
    }
    if (this.equipmentTypeNameFilter.filters.length) {
      this.equipmentTypeNameFilter.filters.forEach(
        (element: FilterDescriptor) => {
          queryString += `${element.field}=${element.value}&`;
        }
      );
    }
    if (this.assetControlManagerNameFilter.filters.length) {
      this.assetControlManagerNameFilter.filters.forEach(
        (element: FilterDescriptor) => {
          queryString += `${element.field}=${element.value}&`;
        }
      );
    }
    if (this.jobFilter.filters.length) {
      this.jobFilter.filters.forEach((element: FilterDescriptor) => {
        queryString += `${element.field}=${element.value}&`;
      });
    }
    return queryString.substring(0, queryString.length - 1);
  }

  onCompanyValueChange(selectedCompanies) {
    this.companyFilter = this.getEmptyFilterDescriptor();
    if (selectedCompanies && selectedCompanies.length) {
      selectedCompanies.forEach((element) => {
        this.companyFilter.filters.push({
          field: this.companyFieldName,
          operator: 'eq',
          value: element.CompanyNumber,
        });
      });
    }
    this.supervisorDropdownDataFiltered = filterBy(
      this.supervisorDataAll,
      this.companyFilter
    );
    this.plantNameDropdownDataFiltered = filterBy(
      this.plantNameDataAll,
      this.companyFilter
    );
    this.jobsDropdownDataFiltered = filterBy(
      this.jobDataAll,
      this.companyFilter
    );
    this.equipmentTypeNamesDropdownDataFiltered = filterBy(
      this.equipmentTypeNameDataAll,
      this.companyFilter
    );
    this.assetControlManagerNamesDropdownDataFiltered = filterBy(
      this.assetControlManagerNameDataAll,
      this.companyFilter
    );
    this.updateStateFilter();
    this.loadChartData$.next();
  }

  onSupervisorValueChange(selectedSupervisors) {
    this.supervisorFilter = this.getEmptyFilterDescriptor();
    if (selectedSupervisors && selectedSupervisors.length) {
      selectedSupervisors.forEach((element) => {
        this.supervisorFilter.filters.push({
          field: this.supervisorFieldName,
          operator: 'eq',
          value: element[this.supervisorFieldName],
        });
      });
    }
    this.updateStateFilter();
    this.loadChartData$.next();
  }

  onPlantNameValueChange(selectedPlantNames) {
    this.plantNameFilter = this.getEmptyFilterDescriptor();
    if (selectedPlantNames && selectedPlantNames.length) {
      selectedPlantNames.forEach((element) => {
        this.plantNameFilter.filters.push({
          field: this.plantNameFieldName,
          operator: 'eq',
          value: element[this.plantNameFieldName],
        });
      });
    }
    this.updateStateFilter();
    this.loadChartData$.next();
  }

  onJobValueChange(selectedJobs) {
    this.jobFilter = this.getEmptyFilterDescriptor();
    if (selectedJobs && selectedJobs.length) {
      selectedJobs.forEach((element) => {
        this.jobFilter.filters.push({
          field: this.jobFieldName,
          operator: 'eq',
          value: element[this.jobFieldName],
        });
      });
    }
    this.updateStateFilter();
    this.loadChartData$.next();
  }

  onEquipmentTypeNameValueChange(selectedEquipmentTypeNames) {
    this.equipmentTypeNameFilter = this.getEmptyFilterDescriptor();
    if (selectedEquipmentTypeNames && selectedEquipmentTypeNames.length) {
      selectedEquipmentTypeNames.forEach((element) => {
        this.equipmentTypeNameFilter.filters.push({
          field: this.equipmentTypeNameFieldName,
          operator: 'eq',
          value: element[this.equipmentTypeNameFieldName],
        });
      });
    }
    this.updateStateFilter();
    this.loadChartData$.next();
  }

  onAssetControlManagerNameValueChange(selectedAssetControlManagerNames) {
    this.assetControlManagerNameFilter = this.getEmptyFilterDescriptor();
    if (
      selectedAssetControlManagerNames &&
      selectedAssetControlManagerNames.length
    ) {
      selectedAssetControlManagerNames.forEach((element) => {
        this.assetControlManagerNameFilter.filters.push({
          field: this.assetControlManagerNameFieldName,
          operator: 'eq',
          value: element[this.assetControlManagerNameFieldName],
        });
      });
    }
    this.updateStateFilter();
    this.loadChartData$.next();
  }

  updateStateFilter() {
    this.state.filter = {
      logic: 'and',
      filters: [],
    } as CompositeFilterDescriptor;
    if (this.companyFilter.filters.length) {
      this.state.filter.filters.push(this.companyFilter);
    }
    if (this.supervisorFilter.filters.length) {
      this.state.filter.filters.push(this.supervisorFilter);
    }
    if (this.plantNameFilter.filters.length) {
      this.state.filter.filters.push(this.plantNameFilter);
    }
    if (this.equipmentTypeNameFilter.filters.length) {
      this.state.filter.filters.push(this.equipmentTypeNameFilter);
    }
    if (this.assetControlManagerNameFilter.filters.length) {
      this.state.filter.filters.push(this.assetControlManagerNameFilter);
    }
    if (this.jobFilter.filters.length) {
      this.state.filter.filters.push(this.jobFilter);
    }
  }

  getFilteredDataByField(data) {
    data.forEach((element) => {
      if (
        !this.equipmentTypeNameDataAll.some(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (x: any) =>
            x[this.equipmentTypeNameFieldName] ===
              element[this.equipmentTypeNameFieldName] &&
            x.VPCompanyID === element.VPCompanyID
        )
      ) {
        this.equipmentTypeNameDataAll.push(element);
      }
      if (
        !this.assetControlManagerNameDataAll.some(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (x: any) =>
            x[this.assetControlManagerNameFieldName] ===
              element[this.assetControlManagerNameFieldName] &&
            x.VPCompanyID === element.VPCompanyID
        )
      ) {
        this.assetControlManagerNameDataAll.push(element);
      }
      if (
        !this.jobDataAll.some(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (x: any) =>
            x[this.jobFieldName] === element[this.jobFieldName] &&
            x.VPCompanyID === element.VPCompanyID
        )
      ) {
        this.jobDataAll.push(element);
      }
      if (
        !this.supervisorDataAll.some(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (x: any) =>
            x[this.supervisorFieldName] === element[this.supervisorFieldName] &&
            x.VPCompanyID === element.VPCompanyID
        )
      ) {
        element[this.supervisorFieldName] =
          element[this.supervisorFieldName] &&
          element[this.supervisorFieldName].trim();
        this.supervisorDataAll.push(element);
      }
      if (
        !this.plantNameDataAll.some(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (x: any) =>
            x[this.plantNameFieldName] === element[this.plantNameFieldName] &&
            x.VPCompanyID === element.VPCompanyID
        )
      ) {
        element[this.plantNameFieldName] =
          element[this.plantNameFieldName] &&
          element[this.plantNameFieldName].trim();
        this.plantNameDataAll.push(element);
      }
    });
    this.supervisorDataAll = orderBy(
      distinct(this.supervisorDataAll, this.supervisorFieldName),
      [{ field: this.supervisorFieldName, dir: 'asc' } as SortDescriptor]
    );
    this.plantNameDataAll = orderBy(
      distinct(this.plantNameDataAll, this.plantNameFieldName),
      [{ field: this.plantNameFieldName, dir: 'asc' } as SortDescriptor]
    );
    this.assetControlManagerNameDataAll = orderBy(
      distinct(
        this.assetControlManagerNameDataAll,
        this.assetControlManagerNameFieldName
      ),
      [
        {
          field: this.assetControlManagerNameFieldName,
          dir: 'asc',
        } as SortDescriptor,
      ]
    );
    this.equipmentTypeNameDataAll = orderBy(
      distinct(this.equipmentTypeNameDataAll, this.equipmentTypeNameFieldName),
      [{ field: this.equipmentTypeNameFieldName, dir: 'asc' } as SortDescriptor]
    );
  }

  getEmptyFilterDescriptor(): CompositeFilterDescriptor {
    return {
      logic: 'or',
      filters: [],
    } as CompositeFilterDescriptor;
  }

  onCompanyFilterChange(filterEvent) {
    this.companyDropdownDataFiltered = this.applicationModel.companies.filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (s: any) =>
        s.CompanyName.toLowerCase().indexOf(filterEvent.toLowerCase()) !== -1 ||
        s.CompanyNumber === filterEvent
    );
  }

  onSupervisorFilterChange(filterEvent) {
    this.supervisorDropdownDataFiltered = filterBy(
      this.supervisorDataAll,
      this.companyFilter
    ).filter(
      (s) =>
        (s[this.supervisorFieldName] || '')
          .toLowerCase()
          .indexOf(filterEvent.toLowerCase()) !== -1
    );
  }

  onPlantNameFilterChange(filterEvent) {
    this.plantNameDropdownDataFiltered = filterBy(
      this.plantNameDataAll,
      this.companyFilter
    ).filter(
      (s) =>
        (s[this.plantNameFieldName] || '')
          .toLowerCase()
          .indexOf(filterEvent.toLowerCase()) !== -1
    );
  }

  onJobFilterChange(filterEvent) {
    this.jobsDropdownDataFiltered = filterBy(
      this.jobDataAll,
      this.companyFilter
    ).filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (s: any) =>
        (s[this.jobFieldName] || '')
          .toLowerCase()
          .indexOf(filterEvent.toLowerCase()) !== -1 ||
        s.JobName.toLowerCase().indexOf(filterEvent.toLowerCase()) !== -1
    );
  }

  onEquipmentTypeNameFilterChange(filterEvent) {
    this.equipmentTypeNamesDropdownDataFiltered = filterBy(
      this.equipmentTypeNameDataAll,
      this.companyFilter
    ).filter(
      (s) =>
        (s[this.equipmentTypeNameFieldName] || '')
          .toLowerCase()
          .indexOf(filterEvent.toLowerCase()) !== -1
    );
  }

  onAssetControlManagerNameFilterChange(filterEvent) {
    this.assetControlManagerNamesDropdownDataFiltered = filterBy(
      this.assetControlManagerNameDataAll,
      this.companyFilter
    ).filter(
      (s) =>
        (s[this.assetControlManagerNameFieldName] || '')
          .toLowerCase()
          .indexOf(filterEvent.toLowerCase()) !== -1
    );
  }
}
