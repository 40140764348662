import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent, Guid } from '@ups/xplat/core';
import { EmployeeUpcomingJobsService } from '../services/employee-upcoming-jobs.service';

@Directive()
export abstract class ProfileJobScheduleBaseComponent extends BaseComponent {
  public text = 'JobSchedule';

  today = new Date();

  public selectedDate: Date = new Date();
  public events: any[];

  private manuallyAddedJobs: any[] = [];
  private fetchedJobs: any[] = [];

  _hrRef: number;
  get hrRef() {
    return this._hrRef;
  }
  @Input() set hrRef(v: number) {
    this.loadData(v);
    this._hrRef = v;
  }

  @Output() eventsUpdated = new EventEmitter<any[]>();
  @Output() currentAndUpcomingJobs = new EventEmitter<any[]>();

  constructor(private mprService: EmployeeUpcomingJobsService) {
    super();
    const currentDay = this.today.getDay();
    const distance = 0 - currentDay;
    this.selectedDate.setDate(this.today.getDate() + distance);
  }

  loadData(v) {
    this.mprService
      .getCurrentAndUpcomingJobsByHRREf(v.toString())
      .then((allEmployeeJobs) => {
        if (allEmployeeJobs != null) {
          console.log(allEmployeeJobs);
          this.selectedDate = allEmployeeJobs[0]
            ? new Date(allEmployeeJobs[0].StartDate)
            : new Date();
          this.fetchedJobs = allEmployeeJobs.map((val) => {
            return {
              ...val,
              id: Guid.newGuid(),
              start: new Date(val.StartDate),
              end: new Date(val.EndDate),
              title: `${val.VPJobId || ''}, ${val.JobName || ''}, ${
                val.JobClass || ''
              }, ${val.Shift === 1 ? 'Day' : 'Night'}`,
              color: new Date() < val.StartDate ? '#AA9569' : '#3584B2',
              acceptedJob: true,
              address: val?.Plant?.Address
                ? `${val.Plant.Address.Line1} ${
                    val.Plant.Address.Line2 || ''
                  }, ${val.Plant.Address.City} ${val.Plant.Address.State}, ${
                    val.Plant.Address.Zip
                  }`
                : '',
            };
          });

          this.refreshEventsList();
          this.currentAndUpcomingJobs.emit(this.fetchedJobs);
        }
      });
  }

  public addJob(job: any, color: string) {
    this.manuallyAddedJobs.push({
      ...job,
      id: Guid.newGuid(),
      start: new Date(job.StartDate),
      end: new Date(job.EndDate),
      title: `${job.VPJobId}, ${job.JobName}, ${job.TradeClassName}, ${
        job.Shift === 1 ? 'Day' : 'Night'
      }`,
      color: color,
    });
    this.refreshEventsList();
  }

  public removeJob(removeJob: any) {
    const fetchedJobsIndex = this.fetchedJobs.findIndex(
      (job) => job.OpeningId === removeJob.OpeningId
    );
    if (fetchedJobsIndex >= 0) {
      this.fetchedJobs.splice(fetchedJobsIndex, 1);
    }

    const manuallyAddedJobIndex = this.manuallyAddedJobs.findIndex(
      (job) => job.OpeningId === removeJob.OpeningId
    );
    if (manuallyAddedJobIndex >= 0) {
      this.manuallyAddedJobs.splice(manuallyAddedJobIndex, 1);
    }

    this.refreshEventsList();
  }

  public resetAddedJobs() {
    this.manuallyAddedJobs = [];
    this.refreshEventsList();
  }

  protected refreshEventsList() {
    this.events = [...this.fetchedJobs, ...this.manuallyAddedJobs];
    this.events.sort((x, y) => x.start.getTime() - y.start.getTime());
    this.eventsUpdated.emit(this.events);
  }
}
