import {
  Component,
  ViewChild,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from '@angular/core';

import {
  BaseComponent,
  IDynamicModel,
  IDynamicModelGroup,
  MyHttpClientFactory,
  ResponseCasingEnum,
  LogService,
  EventBusService,
  ProgressService,
  WindowService,
  IDynamicContainerMetadata,
  DynamicModelType,
  IDynamicControl,
  environment,
} from '@ups/xplat/core';
import {
  AdminFormBuilderWebComponent,
  AdminPropertiesComponent,
} from '../../admin-panels';
import { ActivatedRoute, Params } from '@angular/router';
import { AdminToolboxPanelItem } from '../../../models';
// import { DrAdminDesignCreateResult } from '../../../models/dtos/dr-admin-api-result-models';
import { DrAdminService } from '../../../services/dr-admin.service';

import { DialogService } from '@progress/kendo-angular-dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  dynamicConvertTagToName,
  dynamicDefaultFormCategories,
  DynamicEventBusTypes,
  dynamicGetAppTags,
  dynamicGetCategoryTags,
  DynamicRenderService,
  dynamicSupportedAppTags,
} from '@ups/xplat/features';
import { HttpClient } from '@angular/common/http';
import { catchError, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';
import { IconSearchService } from '../../../../icon-search/icon-search.service';
import { tagCleanser } from '@ups/xplat/utils';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';

interface IWebTypeaheadComponentApi {
  setValue: (v: unknown) => void;
  clear: () => void;
}

@Component({
  selector: 'ups-dynamic-render-admin',
  templateUrl: 'dynamic-render-admin.component.html',
  styleUrls: ['dynamic-render-admin.component.scss'],
})
export class DynamicRenderAdminComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  faIcons = [];
  formGroup: UntypedFormGroup;
  configName: IDynamicModel = {
    type: 'text',
    placeholder: 'Type...(limit 25 AN chars)',
    formControlName: 'containerName',
    options: {
      labelIgnore: true,
      standalone: true,
      maxlength: 25,
    },
  };
  configDescription: IDynamicModel = {
    type: 'textarea',
    label: 'Description',
    placeholder: 'Description...',
    formControlName: 'containerDescription',
    options: {
      standalone: true,
      maxlength: 500,
    },
  };
  configWhatsNew: IDynamicModel = {
    type: 'textarea',
    label: "What's New",
    placeholder: "What's New",
    formControlName: 'containerWhatsNew',
    options: {
      standalone: true,
      maxlength: 250,
    },
  };
  configDisplayIcon: IDynamicModel = {
    type: 'typeahead-icons',
    label: 'Icon',
    placeholder: 'Icon...',
    formControlName: 'displayIcon',
    options: {
      standalone: true,
      dropdownOptions: [],
    },
  };
  // configDisplayOrder: IDynamicModel = {
  //   type: 'text',
  //   placeholder: 'Order',
  //   formControlName: 'displayOrder',
  //   inputType: 'number',
  //   options: {
  //     labelIgnore: true,
  //     standalone: true,
  //   },
  // };
  configAppTag: IDynamicModel = {
    type: 'typeahead',
    label: 'Apps',
    placeholder: 'Select an app...',
    formControlName: 'appTag',
    value: dynamicSupportedAppTags.find((a) => !!a.default).title,
    valueProperty: 'Name',
    required: true,
    options: {
      initWithQuery: dynamicSupportedAppTags.find((a) => !!a.default).title,
      standalone: true,
      isMultiSelect: true,
    },
  };
  configCategory: IDynamicModel = {
    type: 'typeahead',
    label: 'Navigation Menu Section',
    placeholder: 'Select a section...',
    formControlName: 'category',
    value: dynamicDefaultFormCategories[0].title,
    valueProperty: 'Name',
    required: true,
    options: {
      initWithQuery: dynamicDefaultFormCategories[0].title,
      standalone: true,
      isMultiSelect: true,
    },
  };
  configMetadataValidations: Array<IDynamicModel>;
  configMetadataValidation: IDynamicModel = {
    type: 'typeahead',
    label: 'Control Name',
    placeholder: 'Choose a control...',
    formControlName: 'control',
    valueProperty: 'Name',
    options: {
      standalone: true,
    },
  };
  /* eslint-disable */
  defaultFormAppTags: Array<{ Id: string; Name: string; icon?: string }>;
  defaultFormCategories: Array<{ Id: string; Name: string; icon?: string }>;
  /* eslint-enable */
  selectedContainer: IDynamicContainerMetadata = {
    dynamicContainerName: 'Select existing form to edit...',
    dynamicContainerId: null,
  };
  showDetailsBar = true;
  previewOpen = false;
  previewWindowWidth = 1400;
  previewWindowHeight = 800;

  dynamicGroup: IDynamicModelGroup;
  reusableControls: IDynamicControl[];
  minimizeCustomRules = false;

  canModify = false;
  canView = false;
  canRead = false;

  @ViewChild('adminDesign') adminDesign: AdminFormBuilderWebComponent;
  // @ViewChild('adminFormBuilder') adminFormBuilder: AdminFormBuilderWebComponent;
  @ViewChild('adminProperties') adminProperties: AdminPropertiesComponent;
  @Output() containerSelected: EventEmitter<string> = new EventEmitter();
  private http: HttpClient;
  private _picker: IWebTypeaheadComponentApi;
  private _typeaheadIconsPicker: IWebTypeaheadComponentApi;

  private _editingContainerId: string;
  get editingContainerId() {
    return this._editingContainerId;
  }
  set editingContainerId(val: string) {
    this._editingContainerId = val;
  }

  get toolboxItems(): AdminToolboxPanelItem[] {
    return this.adminService.toolboxItems;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private log: LogService,
    private win: WindowService,
    private adminService: DrAdminService,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private security: SecurityService,
    private progress: ProgressService,
    private clientFactory: MyHttpClientFactory,
    private eventBus: EventBusService,
    public dynamicService: DynamicRenderService,
    public iconSearchService: IconSearchService
  ) {
    super();

    const fSecurity = this.security.getFeatureById(
      SecurityConstants.safety_2_0_form_builder_formbuilderpage
    );
    this.canModify = fSecurity.editAll;
    this.canRead = fSecurity.readAll;
    this.canView = this.canRead || fSecurity.viewFeature;

    this.http = this.clientFactory.createHttpClient(
      environment.urls.dynamicAPI,
      true,
      ResponseCasingEnum.PascalCase
    );
    activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe((val: Params) => {
        if (val.id) {
        }
      });
    this.formGroup = this.fb.group({});
    this.formGroup.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((formData) => {
        // this.log.debug('changes:', group);
        const metadata: IDynamicContainerMetadata = {
          dynamicContainerName: formData.containerName,
        };
        if (formData.hasOwnProperty('containerDescription')) {
          metadata.dynamicContainerDescription = formData.containerDescription;
        }

        if (formData.hasOwnProperty('containerWhatsNew')) {
          metadata.dynamicContainerWhatsNew = formData.containerWhatsNew;
        }

        if (formData.hasOwnProperty('displayIcon')) {
          /* eslint-disable */
          metadata.displayIcon = formData.displayIcon
            ? formData.displayIcon.Name
            : null;
          /* eslint-enable */
        }
        // if (formData.hasOwnProperty('displayOrder')) {
        //   const appName = this.dynamicService.getAppTagName();
        //   const displayOrder = {};
        //   // default to order 0 if nothing is set explicitly
        //   const orderNum = parseInt(formData.displayOrder);
        //   displayOrder[appName] = orderNum && !isNaN(orderNum) ? orderNum : 0;
        //   metadata.displayOrder = displayOrder;
        // }

        // reconsider tags
        metadata.tags = [];

        console.log('formData.appTag:', formData.appTag);
        console.log('formData.category:', formData.category);

        const appTags = formData.hasOwnProperty('appTag')
          ? formData.appTag
          : null;
        if (appTags?.length) {
          this.log.debug('appTags total:', appTags.length);
          for (const appTag of appTags) {
            if (appTag.Name && appTag.Id) {
              const supportedAppTag = dynamicSupportedAppTags.find(
                (t) => t.title === appTag.Name
              );
              if (supportedAppTag) {
                const appTagName = supportedAppTag.tag;
                const existingAppTag = metadata.tags.find(
                  (t) => t.tagName === appTagName
                );
                if (!existingAppTag) {
                  // if app tag has not been created yet, ensure it's added
                  // first grab the app tag from remotely loaded tags to see if there's a match with an id already
                  const dynamicAppTag = this.dynamicService.dynamicAppTags.find(
                    (c) => c.tagName === appTagName
                  );
                  if (dynamicAppTag && dynamicAppTag.Id) {
                    metadata.tags.push({
                      tagId: dynamicAppTag.Id,
                      tagName: dynamicAppTag.tagName,
                    });
                  } else {
                    metadata.tags.push({
                      tagName: appTagName,
                    });
                  }
                }
              }
            }
          }
        }
        let categories = formData.hasOwnProperty('category')
          ? formData.category
          : null;
        if (categories?.length) {
          this.log.debug('categories total:', categories.length);
          for (const category of categories) {
            if (category.Name && category.Id) {
              // always replace existing category: tags with the one selected
              const categoryTagName = `category:${tagCleanser(category.Name)}`;
              const categoryTag = metadata.tags.find(
                (t) => t.tagName === categoryTagName
              );
              if (!categoryTag) {
                // if category tag has not been created yet, ensure it's added
                // first grab the category from remotely loaded categories to see if there's a match with an id already
                const dynamicCategoryTag =
                  this.dynamicService.dynamicCategories.find(
                    (c) => c.tagName === categoryTagName
                  );
                if (dynamicCategoryTag && dynamicCategoryTag.Id) {
                  metadata.tags.push({
                    tagId: dynamicCategoryTag.Id,
                    tagName: dynamicCategoryTag.tagName,
                  });
                } else {
                  metadata.tags.push({
                    tagName: categoryTagName,
                  });
                }
              }
            }
          }
        }

        // this.log.debug('tags:', metadata.tags);
        this.dynamicService.updateMetaData(metadata);
      });

    this.faIcons = this.iconSearchService.getFaIcons();
    this.configDisplayIcon.options.dropdownOptions = this.faIcons;

    // Handle tagging for apps/categories/tags
    this.defaultFormAppTags = dynamicSupportedAppTags.map((c) => {
      return {
        Id: c.title,
        Name: c.title,
      };
    });
    this.defaultFormCategories = dynamicDefaultFormCategories.map((c) => {
      return {
        Id: c.title,
        Name: c.title,
        icon: c.icon,
      };
    });

    this.configAppTag.options.dataResolver = () => {
      return new Promise((resolve) => {
        resolve(this.defaultFormAppTags);
      });
    };
    this.configCategory.options.dataResolver = () => {
      return new Promise((resolve) => {
        resolve(
          this.dynamicService.dynamicCategories || this.defaultFormCategories
        );
      });
    };
  }

  ngOnInit() {
    this.dynamicService.loadContainerList().then(() => {
      this.activatedRoute.queryParams
        .pipe(takeUntil(this.destroy$))
        .subscribe((params) => {
          if (
            this.dynamicService.dynamicContainerListAll &&
            params &&
            params.formId
          ) {
            const form = this.dynamicService.dynamicContainerListAll.find(
              (f) => f.formId === params.formId
            );
            this.selectSavedForm(form);
          }
        });
    });
    this.loadReusableControls();
    this.eventBus
      .observe(DynamicEventBusTypes.dynamicContainerSaved)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.loadReusableControls();
      });
  }

  addNewMetadataValidation() {
    if (this.adminDesign.groupItems?.length) {
      if (!this.configMetadataValidations) {
        this.configMetadataValidations = [];
      }
      const configMetadata: IDynamicModel = {
        ...this.configMetadataValidation,
        formControlName: `control${this.configMetadataValidations.length + 1}`,
      };
      configMetadata.options.dropdownOptions = this.adminDesign.groupItems.map(
        (model) => {
          /* eslint-disable */
          return {
            Name: model.formArrayName || model.formControlName,
            Id: model.formArrayName || model.formControlName,
          };
          /* eslint-enable */
        }
      );
      this.configMetadataValidations.push(configMetadata);
    } else {
      this.win.alert(
        `You need at least one control in your form to add custom validation rules.`
      );
    }
  }

  loadReusableControls() {
    this.dynamicService.loadReusableControls().then((d: IDynamicControl[]) => {
      this.reusableControls = d;
    });
  }

  showDetails() {
    this.showDetailsBar = !this.showDetailsBar;
  }

  onContainerSelected(value: IDynamicContainerMetadata) {
    if (value && value.dynamicContainerId) {
      const updateAndChange = () => {
        this.progress.toggleSpinner(true);
        // this.containerSelected.emit(id);
        this.log.debug('onContainerSelected:', value);
        // let displayOrder = value.displayOrder;
        // const appName = this.dynamicService.getAppTagName();
        // if (displayOrder && typeof displayOrder[appName] === 'number') {
        //   displayOrder = displayOrder[appName];
        // } else {
        //   // default order to 1
        //   displayOrder = 1;
        // }
        this.dynamicService.updateMetaData({
          dynamicContainerName: value.dynamicContainerName,
          dynamicContainerDescription: value.dynamicContainerDescription,
          dynamicContainerId: value.dynamicContainerId,
          displayIcon: value.displayIcon,
          dynamicContainerWhatsNew: value.dynamicContainerWhatsNew,
          // displayOrder: value.displayOrder,
          tags: value.tags,
          isDirty: false,
          dynamicControls: [],
        });
        let appTag = this.defaultFormAppTags[0];
        const appTags = dynamicGetAppTags(value.tags);
        if (appTags && appTags.length) {
          const supportedTag = dynamicSupportedAppTags.find(
            (a) => a.tag === appTags[0].tagName
          );
          appTag = {
            Id: appTags[0].tagId,
            Name: supportedTag
              ? supportedTag.title
              : dynamicSupportedAppTags[0].title,
          };
        }
        let category = this.defaultFormCategories[0];
        const categoryTags = dynamicGetCategoryTags(value.tags);
        if (categoryTags && categoryTags.length) {
          category = {
            Id: categoryTags[0].tagId,
            Name: dynamicConvertTagToName(categoryTags[0]),
          };
        }
        let displayIcon = null;
        if (value.displayIcon) {
          displayIcon = {
            Id: value.displayIcon,
            Name: value.displayIcon,
          };
        }

        this.formGroup.patchValue(
          {
            containerName: value.dynamicContainerName,
            containerDescription: value.dynamicContainerDescription,
            displayIcon,
            dynamicContainerWhatsNew: value.dynamicContainerWhatsNew,
            // displayOrder,
            appTag: [appTag],
            category: [category],
          },
          {
            emitEvent: true,
          }
        );
        this.dynamicService.loadContainerDetails(value).then((container) => {
          this.configDescription.value = container.dynamicContainerDescription;
          this.configDisplayIcon.value = container.displayIcon;
          this.configWhatsNew.value = container.dynamicContainerWhatsNew;
          // this.configDisplayOrder.value = displayOrder;
          this.configAppTag.value = dynamicSupportedAppTags.find(
            (a) => !!a.default
          ).title;
          this.configCategory.value = dynamicDefaultFormCategories[0].title;
          this.adminDesign.showContainer(container);
          this.adminProperties.clear();
        });
      };
      if (this.dynamicService.isDirtyContainer()) {
        this.win
          .confirm(
            `Are you sure you want to clear and reset? You will lose any unsaved changes.`
          )
          .then((ok) => {
            if (ok) {
              updateAndChange();
            }
          });
      } else {
        updateAndChange();
      }
    }
  }

  addNewContainer() {
    let message: string;
    if (this.dynamicService.isDirtyContainer()) {
      message = `Are you sure you want to create a new form? This will discard any changes on the current form.`;
    }
    if (message) {
      this.win.confirm(message).then((ok) => {
        if (ok) {
          this._resetNewContainer();
        }
      });
    } else {
      this._resetNewContainer();
    }
  }

  deleteContainer() {
    let message = `Are you sure you want to delete this form`;
    if (
      this.dynamicService.isExistingContainer() &&
      this.dynamicService.metadata.dynamicContainerName
    ) {
      message = `${message} named "${this.dynamicService.metadata.dynamicContainerName}"?`;
    } else {
      message = `${message}?`;
    }
    this.win.confirm(message).then((ok) => {
      if (ok) {
        if (this.dynamicService.metadata?.dynamicContainerId) {
          this.progress.toggleSpinner(true);
          this.http
            .delete(
              `/api/DynamicContainer/remove/${this.dynamicService.metadata?.dynamicContainerId}`
            )
            .pipe(
              catchError(() => {
                return of(null);
              })
            )
            .subscribe(() => {
              this._resetNewContainer();
              this.dynamicService.loadContainerList(true, false, true);
            });
        } else {
          this._resetNewContainer();
        }
      }
    });
  }

  loadedPicker(picker: IWebTypeaheadComponentApi) {
    this._picker = picker;
  }

  loadedTypeaheadIcons(picker: IWebTypeaheadComponentApi) {
    this._typeaheadIconsPicker = picker;
  }

  selectSavedForm(container: IDynamicContainerMetadata) {
    this.selectedContainer = container;
    if (this._picker) {
      this._picker.setValue(container);
    }
    this.onContainerSelected(container);
  }

  onToolboxItemSelected(toolboxItem: AdminToolboxPanelItem): void {
    // console.log(toolboxItem);
    const newDesignPanelItem =
      this.adminService.toolboxItemSelected(toolboxItem);
    // console.log(newDesignPanelItem);
    this.adminDesign.addNewDesignPanelItem(newDesignPanelItem);
  }

  onToolboxReusableItemSelected(reusableControl: IDynamicControl) {
    this.log.debug(reusableControl);
    this.adminDesign.addNewDesignPanelReusableControl(reusableControl);
  }

  onDesignerItemSelected(item: IDynamicModel): void {
    this.adminProperties.setCurrentPropertiesPanelItem(item);
  }

  showAllVersions() {
    this.progress.toggleSpinner(true);
    this.dynamicService
      .loadContainerList(false, !this.dynamicService.showingAllVersions, true)
      .then(() => {
        this.progress.toggleSpinner(false);
      });
  }

  duplicateForm() {
    if (this.dynamicService.isExistingContainer()) {
      this.win
        .confirm(`Are you sure you want to create a copy of this form?`)
        .then((ok) => {
          if (ok) {
            this.dynamicService.metadata.dynamicContainerId = null;
            let firstNamePart =
              this.dynamicService.metadata.dynamicContainerName;
            const copyNameStartConvention = '(Copy';
            let copySuffix = `${copyNameStartConvention})`;
            let existingCopyCount = 0;

            if (firstNamePart.indexOf(copyNameStartConvention) > -1) {
              // start incrementing since a copy already exists
              existingCopyCount++;
              // also get the stripped down (non copy suffix) name here
              firstNamePart = firstNamePart
                .split(copyNameStartConvention)[0]
                .trim();
            }

            // now gather a list of existing copies from the list to get current copy total
            const existingCopies =
              this.dynamicService.dynamicContainerListAll.filter((c) => {
                return (
                  c.dynamicContainerName &&
                  c.dynamicContainerName.indexOf(
                    `${firstNamePart} ${copyNameStartConvention}`
                  ) === 0
                );
              });
            existingCopyCount = existingCopyCount + existingCopies.length;
            if (existingCopyCount) {
              existingCopyCount++;
              copySuffix = `${copyNameStartConvention} ${existingCopyCount})`;
            }

            let copyName = `${firstNamePart} ${copySuffix}`;
            // make sure there's no existing match of this name in case the copy convention counter skipped one (can happen with non-standard incrementing and manual edits)
            const hasExactMatch =
              this.dynamicService.dynamicContainerListAll.find(
                (c) => c.dynamicContainerName === copyName
              );
            if (hasExactMatch) {
              // increment again
              existingCopyCount++;
              copySuffix = `${copyNameStartConvention} ${existingCopyCount})`;
              copyName = `${firstNamePart} ${copySuffix}`;
            }

            this.dynamicService.metadata.dynamicContainerName = copyName;
            this.adminDesign.submit();
          }
        });
    } else {
      this.win.alert(`Please load a saved form into the builder to copy.`);
    }
  }

  updateControl(args) {
    // console.log('updateControl', args);
    this.adminDesign.updateControl(args, args.markingSelected);
  }

  addControlToControl(type: DynamicModelType) {
    const toolboxItem = this.toolboxItems.find((c) => c.controlName === type);
    if (toolboxItem) {
      const newDesignPanelItem =
        this.adminService.toolboxItemSelected(toolboxItem);
      this.adminDesign.addNewDesignPanelItem(newDesignPanelItem, true);
    }
  }

  reorderControl(direction: 'up' | 'down') {
    this.adminDesign.reorderControl(direction);
  }

  public onResetClick(): void {
    this.clearDesign();
  }

  public closePreview() {
    this.previewOpen = false;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._resetNewContainer();
  }

  private _resetSelectedContainer() {
    this.selectedContainer = {
      dynamicContainerName: 'Select existing form to edit...',
      dynamicContainerId: null,
    };
    if (this._picker) {
      this._picker.clear();
    }
    if (this._typeaheadIconsPicker) {
      this._typeaheadIconsPicker.clear();
    }
  }

  private _resetNewContainer() {
    this.dynamicService.resetMetaData();
    this.formGroup.patchValue(
      {
        containerName: '',
        containerDescription: '',
        whatsNew: '',
        displayIcon: '',
        appTag: [this.defaultFormAppTags[0]],
        category: [this.defaultFormCategories[0]],
      },
      {
        emitEvent: true,
      }
    );

    this.adminDesign.showContainer({ dynamicControls: [] });
    this.adminProperties.dynamicGroup = null;
    this._resetSelectedContainer();
  }

  private clearDesign(): void {
    this.adminService.resetDesign();
    // this.adminDesign.clearDesign();
  }
}
