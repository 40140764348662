import { Component, Input, OnChanges, ViewChild } from '@angular/core';

import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';

import { CompanyResolver, VpService } from '@ups/xplat/api/services';
import {
  ApplicationModel,
  CustomerNameDto,
  EquipmentResourceDto,
  IODataResult,
  VendorName,
} from '@ups/xplat/api/dto';
import { nameOf } from '@ups/xplat/utils';
import { EquipmentVpService, MessageHelper } from '@ups/xplat/web/core';
import { SeFilterChangeExArgs } from '../../../kendo/DropDowns';
import { BaseComponent } from '@ups/xplat/core';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import { EmployeeGuardService } from '@ups/xplat/base/employee-profile';

@Component({
  selector: 'ups-equipment-lease-rental-info',
  templateUrl: './equipment-lease-rental-info.component.html',
  styleUrls: ['./equipment-lease-rental-info.component.scss'],
})
export class EquipmentLeaseRentalInfoComponent
  extends BaseComponent
  implements OnChanges
{
  @Input() keyId: number;

  @ViewChild('customerDropdown')
  public customerDropdown: DropDownListComponent;

  @ViewChild('leasedFromDropdown')
  public leasedFromDropdown: DropDownListComponent;

  public equipmentResource;

  public hasEditPermissions = false;

  public hasReadPermissions = false;

  public isEditMode = false;

  public selectedCustomer: CustomerNameDto;

  public selectedVendor: VendorName;

  get capitalizedCheckboxValue(): boolean {
    if (!this.equipmentResource || this.equipmentResource.Capitalized === 'N') {
      return false;
    }

    return true;
  }

  set capitalizedCheckboxValue(value: boolean) {
    if (!this.equipmentResource) {
      return;
    }

    this.equipmentResource.Capitalized = value ? 'Y' : 'N';
  }

  constructor(
    public applicationModel: ApplicationModel,
    private security: SecurityService,
    private msgHelper: MessageHelper,
    private vpService: VpService,
    private equipmentVpService: EquipmentVpService,
    private guardService: EmployeeGuardService,
    private companyResolver: CompanyResolver
  ) {
    super();
    this.hasReadPermissions = this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_rentalreleaseinfotab
    ).readAll;
    this.hasEditPermissions = this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_rentalreleaseinfotab
    ).editAll;
  }

  ngOnChanges() {
    this.equipmentResource = null;
    this.loadData();
  }

  editEquipment() {
    if (this.hasEditPermissions) {
      this.isEditMode = true;
      this.guardService.isToUseGuard = true;
    }
  }

  cancelEquipment() {
    this.equipmentResource = null;
    this.isEditMode = false;
    this.guardService.isToUseGuard = false;
    this.loadData();
  }

  saveEquipment(isFormValid: boolean) {
    if (this.equipmentResource) {
      if (!isFormValid) {
        window.alert('Some fields in the form are not filled properly.');
        return;
      }

      // AutoCorrect items
      this.equipmentResource.ExpectedLife =
        this.equipmentResource.ExpectedLife === null
          ? 0
          : this.equipmentResource.ExpectedLife; // In DB the column does not allow nulls
      this.equipmentResource.SalePrice =
        this.equipmentResource.SalePrice === null
          ? 0
          : this.equipmentResource.SalePrice; // In DB the column does not allow nulls
      this.equipmentResource.LeasePayment =
        this.equipmentResource.LeasePayment === null
          ? 0
          : this.equipmentResource.LeasePayment; // In DB the column does not allow nulls
      this.equipmentResource.LeaseResidualValue =
        this.equipmentResource.LeaseResidualValue === null
          ? 0
          : this.equipmentResource.LeaseResidualValue; // In DB the column does not allow nulls
      this.equipmentResource.ReplacementCost =
        this.equipmentResource.ReplacementCost === null
          ? 0
          : this.equipmentResource.ReplacementCost; // In DB the column does not allow nulls
      this.equipmentResource.CurrentAppraisal =
        this.equipmentResource.CurrentAppraisal === null
          ? 0
          : this.equipmentResource.CurrentAppraisal; // In DB the column does not allow nulls

      const equipmentResourceToSave = this.equipmentResource;

      this.equipmentResource = null; // Assign null so that Loading ... message appears.

      this.equipmentVpService
        .updateEquipmentResource(equipmentResourceToSave, [
          nameOf<EquipmentResourceDto>('ApCompany'),
          nameOf<EquipmentResourceDto>('LeasedFrom'),
          nameOf<EquipmentResourceDto>('LeaseStartDate'),
          nameOf<EquipmentResourceDto>('LeaseEndDate'),
          nameOf<EquipmentResourceDto>('LeasePayment'),
          nameOf<EquipmentResourceDto>('LeaseResidualValue'),
          nameOf<EquipmentResourceDto>('LeaseNotes'),
          nameOf<EquipmentResourceDto>('ArCoCompany'),
          nameOf<EquipmentResourceDto>('VendorGroup'),
          nameOf<EquipmentResourceDto>('Customer'),
          nameOf<EquipmentResourceDto>('CustomerGroup'),
          nameOf<EquipmentResourceDto>('CustomerEquipmentNumber'),
          nameOf<EquipmentResourceDto>('InServiceDate'),
          nameOf<EquipmentResourceDto>('ReplacementCost'),
          nameOf<EquipmentResourceDto>('ExpectedLife'),
          nameOf<EquipmentResourceDto>('ExpectedLifeTimeFrame'),
          nameOf<EquipmentResourceDto>('CurrentAppraisal'),
          nameOf<EquipmentResourceDto>('SoldDate'),
          nameOf<EquipmentResourceDto>('SalePrice'),
          nameOf<EquipmentResourceDto>('Capitalized'),
          nameOf<EquipmentResourceDto>('BalanceAtTheTimeOfSale'),
        ])
        .subscribe(
          () => {
            this.isEditMode = false;
            this.guardService.isToUseGuard = false;
            this.loadData();
          },
          (error) => {
            console.error(error);
            this.equipmentResource = equipmentResourceToSave;
            throw error;
          }
        );
    }
  }

  loadData() {
    this.companyResolver.load().then(() => {
      this.equipmentVpService
        .queryEquipmentResources(null, null, this.keyId)
        .subscribe(
          (result: IODataResult<EquipmentResourceDto>) => {
            if (this.customerDropdown) {
              this.customerDropdown.data = [];
            }

            if (this.leasedFromDropdown) {
              this.leasedFromDropdown.data = [];
            }

            if (result.value && result.value.length) {
              this.equipmentResource = result.value[0];
              this.selectedCustomer = new CustomerNameDto();
              this.selectedCustomer.name = this.equipmentResource.CustomerName;
              this.selectedCustomer.custGroup =
                this.equipmentResource.CustomerGroup;
              this.selectedCustomer.customer = this.equipmentResource.Customer;
              this.selectedVendor = new VendorName();
              this.selectedVendor.name = this.equipmentResource.VendorName;
              this.selectedVendor.vendorGroup =
                this.equipmentResource.VendorGroup;
              this.selectedVendor.vendor = this.equipmentResource.LeasedFrom;
            } else {
              this.equipmentResource = null;
              this.selectedCustomer = null;
              this.selectedVendor = null;
            }
          },
          (error) => {
            console.error(error);
            this.equipmentResource = null;
            this.selectedCustomer = null;
            this.selectedVendor = null;
            throw error;
          }
        );
    });
  }

  onArCompanyChange() {
    this.customerDropdown.data = [];
    this.selectedCustomer = null;
    this.equipmentResource.Customer = null;
    this.equipmentResource.CustomerGroup = null;
  }

  onApCompanyChange() {
    this.leasedFromDropdown.data = [];
    this.selectedVendor = null;
    this.equipmentResource.LeasedFrom = null;
    this.equipmentResource.VendorGroup = null;
  }

  onCustomerChange(customer: CustomerNameDto) {
    if (!customer) {
      this.equipmentResource.Customer = null;
      this.equipmentResource.CustomerGroup = null;
    } else {
      this.equipmentResource.Customer = customer.customer;
      this.equipmentResource.CustomerGroup = customer.custGroup;
    }
  }

  onVendorChange(vendor: VendorName) {
    if (!vendor) {
      this.equipmentResource.LeasedFrom = null;
      this.equipmentResource.VendorGroup = null;
    } else {
      this.equipmentResource.LeasedFrom = vendor.vendor;
      this.equipmentResource.VendorGroup = vendor.vendorGroup;
    }
  }

  customerFilterChanged(event: SeFilterChangeExArgs) {
    const oDataQuery = event.extension.toODataQuery(event.query);

    if (this.equipmentResource.ArCoCompany) {
      this.vpService
        .queryCustomerNamesByGroupId(
          this.equipmentResource.ArCoCompany,
          oDataQuery
        )
        .then((oData) => {
          event.extension.applyData(oData.value);
        })
        .catch((error) => {
          event.extension.applyData([]);
          throw error;
        });
    } else {
      this.msgHelper.error('AR Company needs to be selected');
    }
  }

  vendorFilterChanged(event: SeFilterChangeExArgs) {
    const oDataQuery = event.extension.toODataQuery(event.query);

    if (this.equipmentResource.ApCompany) {
      this.vpService
        .queryVendorNamesByGroupId(this.equipmentResource.ApCompany, oDataQuery)
        .then((oData) => {
          event.extension.applyData(oData.value);
        })
        .catch((error) => {
          event.extension.applyData([]);
          throw error;
        });
    } else {
      this.msgHelper.error('AP Company needs to be selected');
    }
  }
}
