import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { EMPTY, pipe } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { RequestStatusEnum } from '@ups/xplat/core';

import { IEmployeeInfo } from '@ups/xplat/api/dto';

import { IAddressChangeRequest, IVacationAndSickBalance } from '../models';
import { Role } from '../models/role.interface';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace UserState {
  /**
   * Interface for 'user' state
   */
  export interface IState {
    /** Vacation and Sick Available Balance */
    vacationAndSickBalance?: IVacationAndSickBalance;
    // authenticated user
    current?: IEmployeeInfo;
    // selected user
    selected?: IEmployeeInfo;
    // pending address change
    pendingPhysicalAddress?: IAddressChangeRequest;
    // attachments loaded for emp.
    attachments?: Attachments;
    // emergency contacts loaded for emp
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    emergencyContacts?: any[];
    // multiclass data for selected employee
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    multiclass?: any[];
    preferences?: {
      defaultPage: string;
    };
    securityUserId?: string;
    roles?: Array<Role>;
  }

  export interface Attachment {
    attachmentID: string;
    documentName: string;
  }

  export interface Attachments {
    identity: Attachment[];
    i9?: Attachment[];
    twic: Attachment[];
  }

  /**
   * You can define other interfaces as needed here
   */

  export const initialState: IState = {};

  /**
   * Selectors
   */
  export const selectState = createFeatureSelector<IState>('user');
  export const selectCurrent = createSelector(
    selectState,
    (state: IState) => state.current
  );
  export const selectCurrentTruthy = (takeUntil$ = null) =>
    pipe(
      select(selectCurrent),
      filter((val: IEmployeeInfo) => !!val),
      takeUntil(takeUntil$ || EMPTY)
    );

  export const selectSelected = createSelector(
    selectState,
    (state: IState) => state.selected
  );

  export const selectSelectedTruthy = (takeUntil$ = null) =>
    pipe(
      select(selectSelected),
      filter((val) => !!val),
      takeUntil(takeUntil$ || EMPTY)
    );

  export const selectVacationAndSickBalance = createSelector(
    selectState,
    (state: IState) => state.vacationAndSickBalance
  );

  export const selectVacationAndSickBalanceTruthy = (takeUntil$ = null) =>
    pipe(
      select(selectVacationAndSickBalance),
      // filter((val) => !!val), // We allow to assign null in case of an error - so user will get spinner on vacation and sick fields
      takeUntil(takeUntil$ || EMPTY)
    );

  export const selectPendingPhysicalAddress = createSelector(
    selectState,
    (state: IState) => state.pendingPhysicalAddress
  );

  export const selectPendingPhysicalAddressTruthy = (takeUntil$ = null) =>
    pipe(
      select(selectPendingPhysicalAddress),
      filter(
        (val) =>
          !!val &&
          val.RequestStatusId !== RequestStatusEnum.Cancelled &&
          val.RequestStatusId !== RequestStatusEnum.Approved
      ),
      takeUntil(takeUntil$ || EMPTY)
    );

  export const selectAttachments = createSelector(
    selectState,
    (s) => s.attachments
  );
  export const selectAttachmentsTruthy = (takeUntil$ = null) =>
    pipe(
      select(selectAttachments),
      filter((val) => !!val),
      takeUntil(takeUntil$ || EMPTY)
    );

  export const selectEContacts = createSelector(
    selectState,
    (s) => s.emergencyContacts
  );
  export const selectEContactsTruthy = (takeUntil$ = null) =>
    pipe(
      select(selectEContacts),
      filter((val) => !!val),
      takeUntil(takeUntil$ || EMPTY)
    );

  export const selectMulticlass = createSelector(
    selectState,
    (s) => s.multiclass
  );
  export const selectMulticlassTruthy = (takeUntil$ = null) =>
    pipe(
      select(selectMulticlass),
      filter((val) => !!val),
      takeUntil(takeUntil$ || EMPTY)
    );

  export const selectDefaultPage = createSelector(
    selectState,
    (s) => s.preferences?.defaultPage
  );

  export const selectSecurityUserId = createSelector(
    selectState,
    (s) => s.securityUserId
  );
  export const selectSecurityRoles = createSelector(
    selectState,
    (s) => s.roles
  );
}
