import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IOfflineStorage } from '../models';

/* eslint-disable */
// baseline api for offline data storage caching
// intended to be separate than standard StorageService since this has platform specific providers for larger data storage needs

/**
 * Implemented and provided for per platform
 * see: web-offline and mobile-offline
 */
@Injectable({
  providedIn: 'root',
})
export class OfflineStorageService implements IOfflineStorage {
  public setItem<T>(
    key: string,
    value: T,
    largeStorageProperty?: string
  ): Observable<any> {
    return new Observable();
  }

  public getItem<T>(
    key: string,
    defaultValue?: any,
    largeStorageProperty?: string
  ): Observable<any> {
    return new Observable();
  }

  public removeItem(
    key: string,
    largeStorageProperty?: string
  ): Observable<any> {
    return new Observable();
  }
}
