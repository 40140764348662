import { Component, OnInit } from '@angular/core';

import { EditorComponentBase } from '../editor-component-base';

@Component({
  selector: 'editor-form-checkbox',
  templateUrl: 'editor-form-checkbox.html',
})
export class EditorFormCheckboxComponent extends EditorComponentBase implements OnInit {
  public peChecked = false;
  public peLabel = '';

  public ngOnInit() {
    if (this.data) {
      this.peChecked = this.data['checked'] === 'true';
      this.peLabel = this.data['label'];
    }
  }

  public onApplyClick() {
    const changesObj = {
      checked: this.peChecked,
      label: this.peLabel,
    };

    this.applyChanges.emit(changesObj);
  }
}
