import {
  Component,
  ContentChild,
  Inject,
  Input,
  Optional,
  TemplateRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import {
  defaultIfEmpty,
  distinctUntilChanged,
  map,
  takeUntil,
} from 'rxjs/operators';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import { UserActions, UserState } from '@ups/user';
import {
  HelpActions,
  HelpDocumentDto,
  HelpState,
  HelpTypeEnum,
  HELP_DOCUMENT_ROUTE_TOKEN,
} from '@ups/xplat/base/in-app-help';
import {
  AppRouterState,
  BaseComponent,
  LocalStorageService,
  RouterActions,
  environment,
} from '@ups/xplat/core';
import { IEmployeeInfo } from '@ups/xplat/api/dto';
import { AuthService } from '@auth0/auth0-angular';

import { Align } from '@progress/kendo-angular-popup';
import { NavbarContentDirective } from './navbar-content.directive';

interface NavbarMenuTypes {
  name?: string;
  path?: string;
  icon?: string;
  url?: string;
  disabled?: boolean;
  text?: string;
  items?: { text: string }[];
  active?: boolean;
}

@Component({
  selector: 'ups-navbar',
  templateUrl: 'navbar.component.html',
})
export class NavbarComponent extends BaseComponent {
  @Input() title: string;
  @Input() logoSrc = 'assets/kitchen-sink.svg';
  @Input() logoAltText = 'Kitchen Sink';
  @Input() breadcrumbs: NavbarMenuTypes[] = [
    {
      name: 'Home',
      path: '/home',
    },
    {
      name: 'Section',
      path: '#',
    },
    {
      name: 'Subsection',
      path: '#',
    },
    {
      name: 'Page',
      disabled: true,
    },
  ];

  @Input() isNiftyApp = false;

  menuItems: NavbarMenuTypes[] = [
    {
      text: 'Home',
      path: '/home',
    },
    {
      text: 'Link',
      path: '/buttons',
    },
    {
      text: 'Dropdown',
      items: [{ text: 'Item1' }, { text: 'Item2' }, { text: 'Item3' }],
    },
  ];

  appSwitcherItems: NavbarMenuTypes[] = [
    {
      name: 'Employee Portal',
      icon: 'assets/employee-portal.svg',
      active: false,
      url: 'https://portal.universalplant.com',
    },
    {
      name: 'Kitchen Sink',
      icon: 'assets/kitchen-sink.svg',
      active: true,
      url: '#',
    },
    {
      name: 'NIFTY',
      icon: 'assets/nifty.svg',
      active: false,
      url: 'https://nifty.universalplant.com',
    },
    {
      name: 'SPARTA',
      icon: 'assets/sparta.svg',
      active: false,
      url: 'https://sparta.universalplant.com',
    },
    {
      name: 'Safety Hazard Card',
      icon: 'assets/safety-hazard-card.svg',
      active: false,
      url: 'https://safety.universalplant.com',
    },
  ];

  appSwitcherItemsAlt: NavbarMenuTypes[] = [
    {
      name: 'My UPS',
      icon: 'assets/my-ups.svg',
      active: false,
      url: 'https://my-ups.azurewebsites.net/',
    },
    {
      name: 'Resource Allocation',
      icon: 'assets/employee-portal.svg',
      active: false,
      url: 'https://resource-allocation.azurewebsites.net/home',
    },
    {
      name: 'NIFTY',
      icon: 'assets/nifty.svg',
      active: false,
      url: 'https://nifty.universalplant.com',
    },
    {
      name: 'SPARTA',
      icon: 'assets/sparta.svg',
      active: false,
      url: 'https://sparta.universalplant.com',
    },
    {
      name: 'HSE Toolbox',
      icon: 'assets/safety.svg',
      active: false,
      url: 'https://hsetoolbox.universalplant.com',
    },
    {
      name: 'UPS Appstore',
      icon: 'assets/sign-up-form-img.svg',
      active: false,
      url: 'https://appstore.universalplant.com',
    },
    {
      name: 'CAM',
      icon: 'assets/c-logo.svg',
      active: false,
      url: 'https://cam.universalplant.com/',
    },
  ];

  user: IEmployeeInfo;
  matchingDrafts: HelpDocumentDto[] = [];
  matchingWalkthroughs: HelpDocumentDto[] = [];

  popupAlign: Align = { horizontal: 'right', vertical: 'top' };

  @ContentChild(NavbarContentDirective, { read: TemplateRef })
  content: TemplateRef<NavbarContentDirective>;

  showAppSwitcher = false;
  allowOfflineManager = environment.offline?.showManager;

  get viewTooltips() {
    return this.security.getFeatureById(
      SecurityConstants.employee_portal_frameworkfeatures_tooltips
    ).viewFeature;
  }

  get viewWalkthroughs() {
    return this.security.getFeatureById(
      SecurityConstants.employee_portal_frameworkfeatures_walkthroughs
    ).viewFeature;
  }

  get viewAppSwitcher() {
    return this.security.getFeatureById(
      SecurityConstants.employee_portal_app_switcher_viewappswitcher
    ).viewFeature;
  }

  get manageWalkthroughs() {
    return this.security.getFeatureById(
      SecurityConstants.employee_portal_frameworkfeatures_walkthroughs
    ).editAll;
  }

  defaultPageLoaded$ = combineLatest([
    this.store.select(AppRouterState.selectUrl),
    this.store
      .select(UserState.selectDefaultPage)
      .pipe(defaultIfEmpty('no-page-set-as-default')),
  ]).pipe(
    map((value) => value[0] === value[1]),
    distinctUntilChanged()
  );

  public securityConstants = SecurityConstants;

  constructor(
    public auth: AuthService,
    public security: SecurityService,
    public router: Router,
    private store: Store,
    private storage: LocalStorageService,
    @Optional()
    @Inject(HELP_DOCUMENT_ROUTE_TOKEN)
    public helpDocumentsRoute?: string
  ) {
    super();

    this.preloadColorMode();

    this.store
      .select(UserState.selectCurrent)
      .pipe(takeUntil(this.destroy$))
      .subscribe((x) => (this.user = x));

    this.store
      .pipe(
        HelpState.selectDocumentList(this.destroy$, HelpTypeEnum.Walkthrough)
      )
      .subscribe((x) => {
        this.matchingWalkthroughs = x.filter((y) => y.isPublished);
        this.matchingDrafts = x.filter((y) => !y.isPublished);
      });
  }

  login = () => this.auth.loginWithRedirect();

  logout = () => this.auth.logout({ returnTo: window.location.origin });

  showWalkthrough(wt: HelpDocumentDto) {
    this.store.dispatch(HelpActions.loadWalkthrough({ documentId: wt.id }));
  }

  viewOfflineManager() {
    this.store.dispatch(
      new RouterActions.Go({
        path: ['/page-offline-manager'],
        extras: {
          clearHistory: true,
        },
      })
    );
  }

  public onShowTooltipsClicked() {
    this.store.dispatch(HelpActions.toggleTooltips({ state: true }));
  }

  public onEditWalkthroughsClicked() {
    this.store.dispatch(HelpActions.toggleEditor({ state: true }));
  }

  public onHelpdeskClicked() {
    this.store.dispatch(HelpActions.showHelpDeskModal());
  }

  public setDefaultPage() {
    this.store.dispatch(UserActions.setDefaultPage({ path: this.router.url }));
  }

  public clearDefaultPage() {
    this.store.dispatch(UserActions.clearDefaultPage());
  }

  toggleAppSwitcher(): void {
    this.showAppSwitcher = !this.showAppSwitcher;
  }

  toggleLeftSidebarMenu(): void {
    const element = document.querySelector('.sidebar-left');
    if (element.classList.contains('open')) {
      element.classList.remove('open');
    } else {
      element.classList.add('open');
    }
  }

  toggleRightSidebarMenu(): void {
    const element = document.querySelector('.sidebar-right');
    if (element.classList.contains('open')) {
      element.classList.remove('open');
    } else {
      element.classList.add('open');
    }
  }

  preloadColorMode(): void {
    const colorTheme = this.storage.getItem('ups-color-theme');
    if (colorTheme === 'dark') {
      const htmlElement = document.querySelector('html');
      htmlElement.setAttribute('data-theme', 'dark');
    }
  }

  toggleColorMode(): void {
    const htmlElement = document.querySelector('html');
    if (htmlElement.hasAttribute('data-theme')) {
      htmlElement.removeAttribute('data-theme');
      this.storage.setItem('ups-color-theme', 'light');
    } else {
      htmlElement.setAttribute('data-theme', 'dark');
      this.storage.setItem('ups-color-theme', 'dark');
    }
  }

  goToNiftySettings() {
    this.router.navigate(['/settings']);
  }

  executeClickListener(item: { onClick: () => void }) {
    if (item.onClick) item.onClick();
  }
}
