import { BaseModel } from '../../base.model';

export class EquipmentResourceDto extends BaseModel {
  public UnitNameRevCode: string;
  public KeyId: number;
  public EquipmentName: string;
  public EquipmentTypeName: string | null;
  public EquipmentTypeID: string | null;
  public EquipmentCompany: number;
  public EquipmentCategory: string;
  public EquipmentModel: string;
  public EquipmentManufacturer: string;
  public EquipmentVinNumber: string;
  public EquipmentDescription: string;
  public EquipmentModelYear: string;
  public EquipmentStatus: string;

  // Equipment InfoTab
  public InServiceDate: Date;
  public ExpectedLife: number;
  public ExpectedLifeTimeFrame: string;
  public SalePrice: number;
  public TrackLocation: string;
  public SearchDescription: string;
  public CurrentLocation: string;
  public LoanToCompany: number;
  public FixedAssetId: string;
  public FixedAssetStatus: string;
  public AssetType: string;
  public SelfConstructed: string;
  public ReportingType: string;
  public VehicleOperator: string;
  public OwnershipStatus: string;

  // Equipment Meters Tab
  public HourReading: number;
  public HourDate: Date;
  public ReplacedHourReading: number;
  public ReplacedHourDate: Date;
  public OdometerReading: number;
  public OdometerDate: Date;
  public ReplacedOdometerReading: number;
  public ReplacedOdometerDate: Date;

  // Equipment Ownership Info Tab
  public PurchasedFrom: string | null;
  public PurchaseDate: Date | null;
  public PurchasePrice: number;

  // Equipment Lease/Rental Info Tab
  public ApCompany: number | null;
  public LeasedFrom: number | null;
  public VendorGroup: number | null;
  public VendorName: string | null;
  public LeaseStartDate: Date | null;
  public LeaseEndDate: Date | null;
  public LeasePayment: number;
  public LeaseResidualValue: number;
  public LeaseNotes: string | null;
  public ArCoCompany: number | null;
  public Customer: number | null;
  public CustomerGroup: number | null;
  public CustomerName: string | null;
  public CustomerEquipmentNumber: string | null;
  public ReplacementCost: number;
  public CurrentAppraisal: number;
  public SoldDate: Date | null;
  public Capitalized: string;
  public BalanceAtTheTimeOfSale: number | null;

  // Fields Coming from Sparta API:
  public SpartaJobs: string;
  public SpartaJobsStartDates;
  public SpartaJobsEndDates;
  public SpartaJobsDispatchStatuses;

  // Last Equipment Inspection
  public InspectionFrequency: string | null;
  public InspectedBy: string | null;
  public InspectionOutcome: string | null;
  public LastInspectionDate: Date | null;
  public NextInspectionDate: Date | null;
  public InspectionNotes: string | null;

  constructor(o: any = null) {
    super(o);
  }
}
