import { BaseModel } from '@ups/xplat/api/dto';

// caught in lint hell and these changes just need to go through
export { FileDto } from '@ups/xplat/api/dto';

/* eslint-disable */
export class FileDtoPascal extends BaseModel {
  public FileID: string;
  public Name: string;
  public Directory: string;
  public ContentType: string;
  public Description: string;
  public bActive: boolean;
  public Size: number;
  public UserID: string;
  public Content: number[] | ArrayBuffer; // ?? Uint8Array, Int8Array, Uint8Array, Uint8ClampedArray, Int16Array, Uint16Array, Int32Array, Uint32Array, Float32Array, Float64Array, BigInt64Array, BigUint64Array - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Int8Array
  public ContentBase64: string; // If you set contentBase64 property instead of content property then the backend api will transform it to content instead of you

  constructor(o: unknown = null) {
    super(o);
  }
}
/* eslint-enable */
