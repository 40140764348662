export class EmployeeDropdownDto {
  public BirthDate: Date;
  public CellPhone: string;
  public Company: number;
  public EMDesignationName: string;
  public Email: string;
  public FileClassName: string;
  public FilePayRate: number;
  public FirstName: string;
  public FullName: string;
  public HRRef: number;
  public Id: string;
  public LastName: string;
  public Name: string;
  public PRDept: string;
  public PRGroup: number;
  public SSN: string;
  public SSNSecured: string;
  public VPPRId: string;
}
