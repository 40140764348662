import { Directive, inject, Input, NgZone } from '@angular/core';
import { BaseComponent } from '@ups/xplat/core';

@Directive()
export abstract class AppsyncStatusBaseComponent extends BaseComponent {
  @Input() color = '#00275d';
  @Input() row = 0;
  ngZone = inject(NgZone);
  status = '';
  hasUpdate = false;
}
