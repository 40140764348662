<div class="dynamic-field-container">
  <div class="dynamic-field form-input text-align dynamic-button-group">
    <hr />
    <label>Coaching Result</label>
    <div
      #coachingResult
      class="button-group-container"
      *ngIf="
        userService.myInfo?.UserId !== config.options.submittedUserId;
        else noSelfCoaching
      "
    >
      <button
        class="k-button"
        [class.k-primary]="selectedButton === 'approved'"
        (click)="selectedButton = 'approved'"
        [disabled]="formReview !== null"
      >
        Approve
      </button>
      <button
        class="k-button"
        [class.k-primary]="selectedButton === 'coached'"
        (click)="selectedButton = 'coached'"
        [disabled]="formReview !== null"
      >
        Coach
      </button>
      <button
        class="k-button k-danger"
        [disabled]="formReview !== null"
        (click)="showDeleteConfirm = true"
      >
        Delete
      </button>
    </div>

    <ng-template #noSelfCoaching>
      <p>Self coaching is currently disabled</p>
    </ng-template>

    <label>
      Coaching Comments
      <span *ngIf="selectedButton === 'coached'" class="c-red">*</span>
    </label>
    <p>
      The Coaching Comments field allows HSE Employees to leave feedback for the
      employee who submitted the form.
    </p>
    <textarea
      #coachingComment
      class="k-textbox"
      [attr.name]="comments"
      [attr.maxLength]="500"
      [(ngModel)]="comments"
      [required]="selectedButton === 'coached'"
      [disabled]="
        formReview !== null ||
        userService.fullName === config.options.submittedBy
      "
    ></textarea>

    <div *ngIf="formReview === null && selectedButton">
      <div>Canned Responses:</div>
      <button
        *ngFor="let cr of getCannedResponses()"
        class="k-button me-2 mb-1"
        (click)="addCannedResponse(cr)"
        [class.k-primary]="cr?.clicked"
      >
        {{ cr.Response }}
      </button>
    </div>

    <ng-container *ngIf="showApprovalComments()">
      <label class="mt-4">Approval Comments</label>
      <p>Comments added here are only visible to HSE Management.</p>
      <textarea
        class="k-textbox"
        [attr.name]="approvalComments"
        [attr.maxLength]="500"
        [(ngModel)]="approvalComments"
        [disabled]="!canAddApprovalComments() && !canEditApprovalComments()"
      ></textarea>
    </ng-container>

    <h5 style="margin-top: 20px" *ngIf="coachedBy">
      Coached By: {{ coachedBy }}
    </h5>
    <h5 *ngIf="dateCoached">Coached At: {{ dateCoached | date: 'short' }}</h5>
  </div>
</div>
<div
  *ngIf="
    formReview === null &&
    userService.myInfo?.UserId !== config.options.submittedUserId
  "
  class="dynamic-field-container"
>
  <div class="dynamic-field form-button">
    <button
      class="k-button"
      style="width: 50%"
      (click)="showCancelConfirm = true"
    >
      Cancel
    </button>
    <button
      class="k-button k-primary"
      style="width: 50%"
      [disabled]="!isValid() || isSaving"
      (click)="submitCoaching()"
    >
      Submit Coaching
    </button>
  </div>
</div>

<kendo-dialog modal fade [hidden]="!showCancelConfirm" [width]="550">
  <kendo-dialog-titlebar>
    <span>Cancel Coaching</span>
  </kendo-dialog-titlebar>

  <p>Do you really want to Cancel Coaching and Close this Window?</p>

  <kendo-dialog-actions>
    <button class="k-button k-primary" (click)="closeTab()">
      Yes, Cancel Coaching
    </button>
    <button class="k-button" (click)="showCancelConfirm = false">
      No, Continue Coaching
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<ups-dynamic-delete-form-modal
  *ngIf="showDeleteConfirm"
  (closeModal)="showDeleteConfirm = false"
  (deleteDone)="closeTab()"
></ups-dynamic-delete-form-modal>
