import { CompositeFilterDescriptor, FilterDescriptor, GroupDescriptor, SortDescriptor, State } from '@progress/kendo-data-query';

import { IMCSOptions } from '../mcs.settings.options';
import { IMCSSearchColumnConfig, MCSSearchColumnUtils } from '../mcs.settings.search-column';
import { IMCSOrderConfig, MCSOrderUtils } from '../mcs.settings.order';

import { FilterDescriptorEx } from '../OData/odata';

export class ToStateEx {
  /**
   * Creates a kendo State object (with skip/take/filter/sort expressions) based on the search criteria nad settings.
   *  - As it's a structured object, we can easily manipulate filter-expression safely.
   *  - This State object can then be used when calling toODataString or toOdataStringEx.
   *
   *  Params:
   *      search:                             the search phrase entered into the dropdown
   *      take:                               the number of items to limit result (falsy = take all results)
   *
   *      createForKendoToOdataString:        use TRUE if Kendos original toOdataString will be used for odata-query serialization...
   *                                          ...as the original algorithm does not recognizes the new FilterDescriptorEx interface
   *                                          ...so convertToString and matchCaseInsensitive has to be appended by this function.
   *
   *                                          if FALSE is set, we assume the new (custom) toOdataStringEx is used, which uses FilterDescriptorEx at conversion to odata query...
   *                                          ...and has support for special (recurring) things as conversion to string, match case insensitive and even for special date searches...
   */
  public static convert(searchOptions: IMCSOptions, searchColumnConfigs: IMCSSearchColumnConfig[], orderConfigs: IMCSOrderConfig[], search: string, take: number = undefined, createForKendoToOdataString = true): State {
    const self = this;

    //
    // Init missing pieces
    if (!take) take = searchOptions.maximizeResultTo;

    //
    // partial result with: skip, take
    const result = {
      filter: undefined as CompositeFilterDescriptor,
      sort: undefined as SortDescriptor[],
      group: undefined as GroupDescriptor[],
      skip: 0,
      take: take,
    } as State;

    //
    // filter by query phrases (words) - NOTE: originally for some reason we also excluded spaces...
    const queryStringWords = (search || '').split(searchOptions.wordSplitter).filter((i) => ((i || '').trim() ? true : false));
    // NOTE: removed escaping single quotes and encoding, as the toODataString function is responsibel to handle that...
    // the odata query generation syntax:
    // w1 in c1 *columnJoin* w1 in c2 *columnJoin* ... *columnJoin* w1 in cn
    // *expressionJoin*
    // w2 in c1 *columnJoin* ...
    // ..
    // wn in c1 *columnJoin* ... wn in cn
    const columnJoin = searchOptions.matchInEveryColumn ? 'and' : 'or';
    const wordJoin = searchOptions.matchAllWords ? 'and' : 'or';

    // generates w1 ... wN expressions (where w1..wN is an expressions for the given word 'used' with each column)
    const wordFilterArray = queryStringWords.map((word) => {
      // skip empty words
      if ((word || '').trim() === '') return;

      // generates filter expr.: word in c1 *wolumnJoin* ... word in cN
      const columnFilterArray = searchColumnConfigs.map((scc) => {
        // NOTE: we have to use startswith (and not startsWith), as it's a key in the kendo odata-filtering.ts filterOperators const
        const matchType = scc.matchStartsWith ? 'startswith' : 'contains';
        const caseInsensitiveMatch = scc.matchCaseInsensitive;
        const convertToString = scc.convertToString;

        if (createForKendoToOdataString) {
          // return value for the old  (Kendo) toOdataString implementation, where we have to:
          // - convert search value to lower case
          // - ensure casting column value to string
          const columnExpression = convertToString ? `cast(${scc.field}, 'Edm.String')` : scc.field;

          // NOTE:
          // we can't use `tolower(${word})`
          // ...as the whole value would be interpreted as string resulting in similar expr. as: contains(tolower(LastName), 'tolower(tra)')
          // ...instead of expr: contains(tolower(LastName), tolower('tra'))
          //
          // THE FIX BELOW is not 100% correct, as it uses local function to convert to lower case and bypasses so the effects of possible collations on the DB side
          const valueExpression = caseInsensitiveMatch ? word.toLowerCase() : word;

          return {
            field: columnExpression,
            operator: matchType,
            value: valueExpression,
            ignoreCase: caseInsensitiveMatch,
          } as FilterDescriptor;
        } else {
          // new, extended FilterDescriptor that works well with our custom toOdataStringEx implementation
          return {
            field: scc.field,
            operator: matchType,
            value: word,
            ignoreCase: caseInsensitiveMatch,

            ignoreValueCase: caseInsensitiveMatch,
            date24hMatch: true,
            convertToString: convertToString,
          } as FilterDescriptorEx;
        }
      });

      if (columnFilterArray.length > 1) {
        // composite expr. (multi column)
        return {
          filters: columnFilterArray,
          logic: columnJoin,
        } as CompositeFilterDescriptor;
      } else {
        // single expression (single column)
        return columnFilterArray[0];
      }
    });

    result.filter = {
      filters: wordFilterArray,
      logic: wordJoin,
    } as CompositeFilterDescriptor;

    //
    // order (sort) by
    result.sort = orderConfigs as SortDescriptor[];

    // retun the fully filled (and extended) State object
    return result;
  }
}
