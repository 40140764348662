import { Directive, NgZone, inject } from '@angular/core';

import {
  environment,
  BaseComponent,
  LocalStorageService,
  LogService,
  NetworkService,
  OfflineHttpTrackingService,
  OfflineTrackedRequestItem,
  OfflineTrackedRequests,
  WindowService,
} from '@ups/xplat/core';
import { StorageKeys, isPresent } from '@ups/xplat/utils';
import { filter } from 'rxjs/operators';

@Directive()
export abstract class OfflineManagerBaseComponent extends BaseComponent {
  network = inject(NetworkService);
  win = inject(WindowService);
  ngZone = inject(NgZone);
  log = inject(LogService);
  storage = inject(LocalStorageService);
  offlineHttpTracking = inject(OfflineHttpTrackingService);
  showBanner = false;
  hideNow = false;
  offline = false;
  offlineMessage;
  statusUpdates: string;
  allPendingRequests: OfflineTrackedRequests = [];
  completePendingOfflineOnReconnect: boolean;

  constructor() {
    super();
    if (environment.offline?.enabled) {
      this.completePendingOfflineOnReconnect = this.storage.getItem(
        StorageKeys.OFFLINE_COMPLETE_ON_RECONNECT
      );
      if (
        !isPresent(this.completePendingOfflineOnReconnect) ||
        this.completePendingOfflineOnReconnect
      ) {
        this.completePendingOfflineOnReconnect = true;
      }
      // if (this.network.isOffline) {
      //   this._offlineStateHandler(true);
      // }
      this.offlineHttpTracking.statusUpdates$
        .pipe(filter((status) => !!status))
        .subscribe((updates) => {
          const statusMessages = [];
          if (updates.pending) {
            statusMessages.push(`Requests processing: ${updates.pending}`);
            if (updates.complete) {
              statusMessages.push(`Completed: ${updates.complete}`);
            }
          }
          if (updates.errored) {
            statusMessages.push(`Errors: ${updates.errored}`);
          }
          this.statusUpdates = statusMessages.join(', ');
          if (!this.statusUpdates && !this.network.isOffline) {
            // no more status updates, hide banner
          }
        });

      this.loadData();
    }
  }

  loadData() {
    return new Promise<void>((resolve) => {
      this.offlineHttpTracking
        .getPendingOfflineRequests()
        .then((pendingRequests) => {
          this.allPendingRequests = pendingRequests;
          this.pendingRequests = pendingRequests;
          resolve();
        });
    });
  }

  set pendingRequests(value: OfflineTrackedRequests) {
    // overriden by platform implementations as needed
    this.log.debug('pending request:', value);
  }

  updateUserSetting(options: { completePendingOfflineOnReconnect: boolean }) {
    if (Object.hasOwn(options, 'completePendingOfflineOnReconnect')) {
      this.completePendingOfflineOnReconnect =
        options.completePendingOfflineOnReconnect;
      this.storage.setItem(
        StorageKeys.OFFLINE_COMPLETE_ON_RECONNECT,
        this.completePendingOfflineOnReconnect
      );
    }
  }

  deleteItem(item: OfflineTrackedRequestItem) {
    this.win
      .confirm({
        title: 'Are you sure?',
        message: `Are you sure you want to delete this offline pending request?`,
        okButtonText: 'Yes, Delete',
        cancelButtonText: 'Cancel',
      })
      .then((ok) => {
        if (ok) {
          this.offlineHttpTracking
            .storeRequestOnAPI(item, 'Request was Deleted')
            .subscribe({
              next: () => {
                this.offlineHttpTracking.removeRequest(item).then(() => {
                  this.loadData();
                });
              },
              error: () => {
                this.win.alert({
                  title: 'Uh oh',
                  message: 'There was an error deleting the request.',
                });
              },
            });
        }
      });
  }
}
