import { Component } from '@angular/core';
import { DynamicButtonGroupBaseComponent } from '@ups/xplat/features';

@Component({
  selector: 'ups-dynamic-buttongroup',
  styleUrls: ['./dynamic-buttongroup.component.scss'],
  template: `
    <div class="dynamic-field-container" [class.selected]="config?.selected">
      <div
        class="dynamic-field form-button dynamic-button-group"
        [class.inline]="config?.options?.labelInline"
        [formGroup]="group"
        [hidden]="!config?.isFormBuilder && config?.options?.hidden"
      >
        <ups-dynamic-helper-label
          [config]="config"
          [dynamicService]="dynamicService"
        ></ups-dynamic-helper-label>
        <input
          type="text"
          [attr.name]="config.formControlName"
          [formControlName]="config.formControlName"
          hidden
        />
        <div class="button-group-container">
          <button
            *ngFor="let button of config?.options?.buttongroup"
            class="k-button"
            [class.k-primary]="button.selected"
            (click)="selectButton(button)"
            [ngStyle]="config?.options?.style?.button"
            [disabled]="config?.disabled"
          >
            {{ button.label }}
          </button>
        </div>
        <div
          class="form-builder-mask"
          [class.hidden]="config?.options?.hidden"
          *ngIf="config?.isFormBuilder"
        ></div>
      </div>
      <div class="form-builder-options" *ngIf="config?.isFormBuilder">
        <i
          class="fas fa-times ml-3 cursor-pointer c-red"
          (click)="delete()"
        ></i>
        <!-- <i class="fas fa-bars ml-3 cursor-grab "></i> -->
      </div>
    </div>
  `,
})
export class DynamicButtonGroupComponent extends DynamicButtonGroupBaseComponent {
  // get formControl() {
  //   return this.group.controls[this.config.formControlName];
  // }
}
