import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EmailQueueDto } from '@ups/xplat/api/dto';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  protected http: HttpClient;

  constructor(private clientFactory: MyHttpClientFactory) {
    const urlBase = environment.urls.spartaAPI;
    this.http = this.clientFactory.createHttpClient(
      urlBase,
      true,
      ResponseCasingEnum.Default
    );
  }

  public enqueEmail(emailDto: EmailQueueDto): Observable<EmailQueueDto> {
    const url = '/api/email/enqueue';

    return this.http.post(url, emailDto) as Observable<EmailQueueDto>;
  }

  public sendEmail(emailDto: EmailQueueDto) {
    const url = '/api/email/send';

    return this.http.post(url, emailDto);
  }

  public sendHSEToolboxEmail(emailDto: EmailQueueDto) {
    const url = '/api/email/hsetoolbox/send';

    return this.http.post(url, emailDto);
  }
}
