import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Typeahead } from './ng2-typeahead';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [Typeahead],
  exports: [Typeahead],
})
export class TypeaheadModule {}
