import { Subscription } from 'rxjs';

import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogComponent } from '@progress/kendo-angular-dialog';

import { Communication } from '@ups/xplat/api/dto';
import { ReleaseNoteService } from '@ups/xplat/core';

@Component({
  selector: 'ups-announcements-popup',
  templateUrl: './announcements-popup.component.html',
  styleUrls: ['./announcements-popup.component.scss'],
})
export class AnnouncementsPopupComponent implements OnInit {
  usedFeatureGroups: { sectionHeader: string; featureNotes: any[] }[];

  announcements: Communication[];
  showDialog = false;
  showDetails = false;
  doLogging = true;
  openedAt = new Date().getTime();
  expandedAt: number = null; // Date().getTime() value
  closeSubscription: Subscription = null;
  unseenAnnouncement = false;
  visibleIdx = 0;

  @ViewChild('dialog') dialog: DialogComponent;
  helpDownloadToken: any;

  constructor(public releaseService: ReleaseNoteService, public domSanitizer: DomSanitizer) {
    this.releaseService.showAnnouncements$.subscribe((x) => {
      this.showAnnouncementPopup(x, true);
    });
  }

  ngOnInit() {}

  onCloseAnnouncement() {
    if (this.doLogging) {
      const now = new Date().getTime();
      if (this.announcements) {
        this.releaseService.uploadLogs({
          releaseNoteId: this.announcements[this.visibleIdx].communicationId,
          minutesAfterExpansion: this.expandedAt == null ? 0 : (now - this.expandedAt) / 60000,
          minutesSpent: (now - this.openedAt) / 60000,
        });
      }
    }
  }

  onClose() {
    this.onCloseAnnouncement();
    this.showDialog = false;
  }

  toggleDetails() {
    this.showDetails = !this.showDetails;
    this.expandedAt = this.expandedAt || new Date().getTime(); // if expandedAt not null, new date.
  }

  public showAnnouncementPopup(comms: Communication[], logging = true) {
    this.announcements = comms;
    this.usedFeatureGroups = [];
    this.showDetails = false;
    this.doLogging = logging;

    this.releaseService.getDownloadToken().then((x) => {
      this.helpDownloadToken = x;
    });

    this.showDialog = true;
    this.announcements[0].viewed = true;
    this.initializeData();
  }

  initializeData() {
    this.expandedAt = null;
    this.openedAt = new Date().getTime();
    this.announcements[this.visibleIdx].viewed = true;
    this.unseenAnnouncement = this.announcements.some((x) => x.viewed == false);
  }

  previousAnnouncement() {
    this.onCloseAnnouncement();
    this.initializeData();
    this.visibleIdx -= 1;
  }

  nextAnnouncement() {
    this.onCloseAnnouncement();
    this.initializeData();
    this.visibleIdx += 1;
  }
}
