<div class="properties-editor">
  <div class="pe-field">
    <label for="label">Label</label>
    <input kendoTextBox [(ngModel)]="peLabel" id="label" class="form-input" />
  </div>

  <div class="pe-field">
    <label for="placeholder">
      Default:
      <input
        kendoCheckBox
        [(ngModel)]="peChecked"
        id="placeholder"
        class="ms-2"
        type="checkbox"
      />
    </label>
  </div>

  <div class="k-form-buttons dr-prop-actions">
    <button class="k-button k-primary" (click)="onApplyClick()">Apply</button>

    <i class="far fa-trash delete" (click)="onDeleteClick()"></i>
  </div>
</div>
