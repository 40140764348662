/* eslint-disable @typescript-eslint/naming-convention */
import { BaseModel } from '../base.model';

/* eslint-disable */
export class ParentCustomerDto extends BaseModel {
  /** To avoid confusion:
   *  When working with vp-api the ParentCustomerID is the budParentCustomer table pkKey column
   *  When working with sparta-api the ParentCustomerID is the foreign key to ParentCustomer sparta db table - currently not used as we read directly from Viewpoint DB
   *  When working with sparta-api the VPParentCustomerKeyID contains the pkKey column
   *  When working with vp-api the the VPParentCustomerKeyID contains the budParentCustomer table KeyID column. */
  public ParentCustomerID: number;
  public CustomerName: string;
  public ShortCustomerName: string;
  /** To avoid confusion:
   *  When working with vp-api the ParentCustomerID is the budParentCustomer table pkKey column
   *  When working with sparta-api the ParentCustomerID is the foreign key to ParentCustomer sparta db table - currently not used as we read directly from Viewpoint DB
   *  When working with sparta-api the VPParentCustomerKeyID contains the pkKey column
   *  When working with vp-api the the VPParentCustomerKeyID contains the budParentCustomer table KeyID column. */
  public VPParentCustomerKeyID: number;
  public BActive: boolean;
  public IsUserFavorite: boolean;
}
/* eslint-enable */
