import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  isCompositeFilterDescriptor,
} from '@progress/kendo-data-query';

import { wrapIf } from '@progress/kendo-data-query/dist/npm/filter-serialization.common';

import { ODataExSettings } from './odata';

import {
  encodeValue,
  formatDateEx,
  normalizeField,
  quote,
  toLowerField,
  toLowerValue,
  castFieldToDateTimeOffset,
  castFieldToString,
} from './odata-filtering-transformations';
import { isDate, isString } from '@ups/xplat/utils';

/* eslint-disable */
/**
 * Currently supported ops:
 *  not   - negation operator - odata sample:  $filter=not(Items/any(d:d/Quantity gt 100)); $filter=not endswith(Name, 'ilk')
 *
 * URL:
 *  https://www.odata.org/documentation/odata-version-3-0/url-conventions/
 *
 * NOTE:
 *  At this moment support for arithmetic ops is not considered: $filter=( 4 add 5 ) mod ( 4 sub 1 ) eq 0
 */
export interface UnaryFilterDescriptorEx {
  operator: '$noop' | 'not';
  filter: any /** any due to future extensions, otherwise: FilterDescriptor | CompositeFilterDescriptor | CollectionFilterDescriptorEx; */;
}

export const isUnaryFilterDescriptorEx = (
  source: any
): source is UnaryFilterDescriptorEx => {
  const unaryFilter = source as UnaryFilterDescriptorEx;
  return (
    unaryFilter.filter &&
    ['$noop', 'not'].some((op) => op === unaryFilter.operator)
  );
};

export const unaryFilterExSerializationFn = (
  unaryFilter: UnaryFilterDescriptorEx,
  settings: ODataExSettings
): string => {
  switch (unaryFilter.operator) {
    case '$noop':
      // simply just serialize the inside
      return serializeFilterFn(unaryFilter.filter, settings);
    case 'not':
      // TODO: check if the serializeFilterFn wraps complex expression inside brackets
      const notFilterExpression = serializeFilterFn(
        unaryFilter.filter,
        settings
      );
      return `not ${notFilterExpression}`;
    default:
      throw new Error(
        `Unsupported operator: '${
          unaryFilter.operator
        }' in the UnaryFilterDescriptorEx: '${JSON.stringify(unaryFilter)}'`
      );
  }
};

export const serializeFilterFn = (
  filter: any,
  settings: ODataExSettings
): string => {
  //
  // handle regular composite filter
  if (isCompositeFilterDescriptor(filter))
    return compositeFilterSerializationFn(filter, settings);

  //
  // handle special collection filter
  if (isCollectionFilterDescriptorEx(filter))
    return collectionFilterExSerializationFn(filter, settings);

  //
  // handle special unary operators
  if (isUnaryFilterDescriptorEx(filter))
    return unaryFilterExSerializationFn(filter, settings);

  //
  // handle standard FilterDescriptors

  // handle IN operator overrides with strings that contains " - see: https://github.com/OData/WebApi/issues/2136
  if (filter.operator === 'in') {
    const values = Array.isArray(filter.value) ? filter.value : [filter.value];

    const dontUseInOperator =
      settings.filter.dontUseInOperator || (filter as any).dontUseInOperator;
    const dontUseInOperatorWithStringsContainingQuotes =
      settings.filter.dontUseInOperatorWithStringsContainingQuotes &&
      values.some((v) => isString(v) && v.indexOf('"') > -1);

    if (dontUseInOperator || dontUseInOperatorWithStringsContainingQuotes) {
      const anyValueFilter = {
        logic: 'or',
        filters: values.map((v) => ({
          field: filter.field,
          operator: 'eq',
          value: v,
        })) as FilterDescriptor[],
      } as CompositeFilterDescriptor;

      return compositeFilterSerializationFn(anyValueFilter, settings);
    }
  }

  // handle date from-to to support 1 day selection (replace simple filter with a composite) with eq/neq
  if (
    isDate(filter.value) &&
    (settings.filter.date24hMatch || (filter as any).date24hMatch) &&
    ((filter.operator as any).toLowerCase() === 'eq' ||
      (filter.operator as any).toLowerCase() === 'neq')
  ) {
    // replace single date-filter expression with a composite one that will consider a whole day (from-to) or outside it...
    const typedDate = filter.value as Date;
    const fromDate = new Date(
      typedDate.getFullYear(),
      typedDate.getMonth(),
      typedDate.getDate()
    );
    const toDate = new Date(typedDate.getTime() + 86400000); // get next day: add 86400000ms = 1 day

    // if date operator is 'eq' we use: date >= from && < to
    // for the 'ne' we use: date < from && date >= to

    const operatorLeft =
      (filter.operator as any).toLowerCase() === 'eq' ? 'gte' : 'lt';
    const operatorRight =
      (filter.operator as any).toLowerCase() === 'eq' ? 'lt' : 'gte';

    const dayDateCompositeFilter = {
      logic: 'and',
      filters: [
        {
          field: filter.field,
          operator: operatorLeft,
          value: fromDate,
        } as FilterDescriptor,
        {
          field: filter.field,
          operator: operatorRight,
          value: toDate,
        } as FilterDescriptor,
      ] as FilterDescriptor[],
    } as CompositeFilterDescriptor;

    return compositeFilterSerializationFn(dayDateCompositeFilter, settings);
  }

  //
  // simple filter patches

  // isblank/isnotblank (empty/not empty is just for strings, so we don't need to check for field/search string type)
  const useEmptyWithBlank = filter.hasOwnProperty('useEmptyWithBlank')
    ? filter.useEmptyWithBlank
    : settings.filter.useEmptyWithBlank;

  if (useEmptyWithBlank) {
    if (filter.operator.toLowerCase() === 'isempty')
      filter.operator = 'isblank';

    if (filter.operator.toLowerCase() === 'isnotempty')
      filter.operator = 'isnotblank';
  }

  //
  // handle regular simple filter
  return singleFilterSerializationFn(filter, settings);
};

/**
 * TODO: support search in date/number as string!
 */

/**
 * IMPORTANT:
 *
 * The original solution had the either (ifElse) function defined on 2 places, the one from func.ts was the one we use as a base.
 *
 * As we need to pass over new values, we had 2 ways to alter the original functions:
 * 1) avoid use of immutables inside the Transfer functions + extend the initial (starting) data with values we need
 * 2) make sure aggregate function pass over an extra parameter to the inener functions
 *
 * We choose option 2, so we had to:
 * - create a new compose function
 * - and to have similar notation, skip using fnciton either (as its just a conditional ternary operator)
 */

const composeEx =
  (...args) =>
  (initialData, settings) =>
    args.reduceRight((acc, curr) => {
      return curr(acc, settings);
    }, initialData);

const eitherEx = (predicate, right, left) => (value, settings) =>
  predicate(value, settings) ? right(value, settings) : left(value, settings);

const fnFormatter =
  (operator) =>
  ({ field, value }: FilterDescriptor): string =>
    `${operator}(${field},${value})`;
const singleOperatorFormatter =
  (operator) =>
  ({ field, value }: FilterDescriptor): string => {
    return Array.isArray(value)
      ? `${field} ${operator} (${value.join(', ')})`
      : `${field} ${operator} ${value}`;
  };

const stringFormat = (formatter) =>
  composeEx(
    formatter,
    toLowerValue,
    encodeValue,
    quote,
    toLowerField,
    castFieldToString,
    normalizeField
  );

const stringFnOperator = (operator) => stringFormat(fnFormatter(operator));
const stringOperator = (operator) =>
  stringFormat(singleOperatorFormatter(operator));
const numericOperator = (operator) =>
  composeEx(singleOperatorFormatter(operator), normalizeField);
const dateOperator = (operator) =>
  composeEx(
    singleOperatorFormatter(operator),
    castFieldToDateTimeOffset,
    normalizeField,
    formatDateEx
  );

const isDateValueEx = (x) =>
  Array.isArray(x.value) ? isDate(x.value[0]) : isDate(x.value);
const ifDate = (operator) =>
  eitherEx(isDateValueEx, dateOperator(operator), numericOperator(operator));

const isStringValueEx = (x) =>
  Array.isArray(x.value) ? isString(x.value[0]) : isString(x.value);
const typedOperator = (operator) =>
  eitherEx(isStringValueEx, stringOperator(operator), ifDate(operator));

const fieldFnExpression = (expression) => (field) => {
  if (field?.field) {
    return `${field.field} ${expression}`;
  } else {
    return `${field} ${expression}`;
  }
};

//
// Switch-table for supported filter operator
// ------------------------------------------
// Will convert a FilterDescriptor (so single filter expression) to OData string!
//
// The whole "table" is based on a single concept:
// - Use a FilterDescriptor as a parameter (mapping it's main properties to simple parameters)
// - process it through a chain of transformation functions
// - and convert it to OData string on the last step
//
// The main functions used:
// ------------------------
// either(predicateFn, leftFn, rightFn)(param):
//  if predicate is true returns 1st part otherwise it returns the 2nd part - so those 2 inner functions are the ones that must return OData string
//
// compose(convertODataFn, alterParamFnN, ... alterParamFn1)(param)
//  will process the rightmost parameter as first, continuing to the next one on the left
//  the last (1st) parameter (curry function) is the one responsible to the OData conversion
//  the other parameters just do alter/transform the call parameter
type Operator = (filter: FilterDescriptor, setting: ODataExSettings) => string;

const filterOperators: { [operator: string]: Operator } = {
  contains: stringFnOperator('contains'),
  doesnotcontain: composeEx(
    fieldFnExpression('eq -1'),
    stringFnOperator('indexof')
  ),
  endswith: stringFnOperator('endswith'),
  eq: typedOperator('eq'),
  gt: typedOperator('gt'),
  gte: typedOperator('ge'),
  isempty: composeEx(fieldFnExpression("eq ''"), normalizeField),
  isnotempty: composeEx(fieldFnExpression("ne ''"), normalizeField),
  isnotnull: composeEx(fieldFnExpression('ne null'), normalizeField),
  isnull: composeEx(fieldFnExpression('eq null'), normalizeField),
  lt: typedOperator('lt'),
  lte: typedOperator('le'),
  neq: typedOperator('ne'),
  startswith: stringFnOperator('startswith'),

  /**
   * Limits for the "in" operator:
   * - For strings the toupper can't be applied to the value!
   * - Can't use it for dates.
   */
  in: typedOperator('in'),

  /*
   * isblank isnotblank (for strings with null/empty checks)
   *
   * could be also written as:
   *   compositeFilterSerializationFn({
   *     logic: "and",
   *     filters: [
   *       { operator: "isnotempty", field: filter.field } as FilterDescriptor,
   *       { operator: "isnotnull", field: filter.field } as FilterDescriptor
   *     ]
   *   } as CompositeFilterDescriptor, setting)
   */

  isblank: (filter: FilterDescriptor, setting: ODataExSettings) =>
    `(${composeEx(fieldFnExpression("eq ''"), normalizeField)(
      filter,
      setting
    )} or ${composeEx(fieldFnExpression('eq null'), normalizeField)(
      filter,
      setting
    )})`,
  isnotblank: (filter: FilterDescriptor, setting: ODataExSettings) =>
    `(${composeEx(fieldFnExpression("ne ''"), normalizeField)(
      filter,
      setting
    )} and ${composeEx(fieldFnExpression('ne null'), normalizeField)(
      filter,
      setting
    )})`,
};

//
// Handling filter composition (simplified structure, with a settings parameter extended)

export const singleFilterSerializationFn = (
  filter: FilterDescriptor,
  settings: ODataExSettings
) => {
  return filterOperators[<string>filter.operator](filter, settings);
};

export const compositeFilterSerializationFn = (
  filter: CompositeFilterDescriptor,
  settings: ODataExSettings
): string => {
  const filterExpression = filter.filters
    .map((f) => serializeFilterFn(f, settings))
    .filter((q) => !!q) // filter out accidental empty filters (and thus empty queries) that then do produce invalid odata URI
    .join(` ${filter.logic} `);

  const brackets = wrapIf(() => filter.filters.length > 1);
  return brackets`(${filterExpression})`;
};

//
// Main entry function

/**
 * MAIN ENTRY FUNCTION for toOdatastringEx
 *
 * TODO: some extras to support:
 * - tolower on the DB level (due collation)
 * - convert to string so we can search in non string based values via startsWith, contains, endsWith...
 * - special handling of date values (focusing on .NET, OData and custom JSON stringify and parsing)
 */
export const serializeFilter = (
  filter: CompositeFilterDescriptor,
  settings: ODataExSettings = new ODataExSettings(true)
): string => {
  if (filter.filters && filter.filters.length) {
    // NOTE: now we can support even single filters via serializeFilterFn(...)
    // NOTE: we added a check if there's a filter expression, thus we do avoid invalid (empty) filter query this way...
    const filterExpression = compositeFilterSerializationFn(filter, settings);
    return filterExpression ? '$filter=' + filterExpression : '';
  }

  return '';
};

/**
 * samples to set-up:
 *
 * in case of primitive collections, like: obj.idList
 *  collectionField = 'idList'
 *  collectionRef = 'p0'
 *  filter = { field: 'p0', operator: 'eq', value: 12 }
 *  resulting in odata: $filter=idList/any(p0: p0 eq '10001')
 *
 * in case when collection contains complex objects, like: obj.persons[].mailAddress.zip:
 *  collectionField = 'persons'
 *  collectionRef = 'p0'
 *  filter = { field: 'p0/mailAddress/zip', operator: 'eq', value: '10001' }
 *  resulting in odata: $filter=persons/any(p0: p0/mailAddress.zip eq '10001')
 *
 * note: the filter property can hold a composite filter as well
 */
export interface CollectionFilterDescriptorEx {
  collectionField: string;
  collectionRef: string;
  operator: 'any' | 'all';
  filter: FilterDescriptor | CompositeFilterDescriptor | any;
}

export const isCollectionFilterDescriptorEx = (
  source: any /** any due to future extensions, otherwise: CollectionFilterDescriptorEx | CompositeFilterDescriptor | FilterDescriptor */
): source is CollectionFilterDescriptorEx => {
  return (
    source.collectionField &&
    source.collectionRef &&
    source.operator &&
    source.filter
  );
};

export const collectionFilterExSerializationFn = (
  filter: CollectionFilterDescriptorEx,
  settings: ODataExSettings
): string => {
  const normalizedCollectionFieldName = filter.collectionField.replace(
    /\./g,
    '/'
  );
  const innerFilterExpression = serializeFilterFn(filter.filter, settings);
  return innerFilterExpression
    ? `${normalizedCollectionFieldName}/${filter.operator}(${filter.collectionRef} : ${innerFilterExpression})`
    : ``;
};
