import { Injectable } from '@angular/core';
import { ManPowerService } from '@ups/xplat/base/manpower';
import { ApplicationModel } from '@ups/xplat/api/dto';
import { DispatchStatusEnum } from '@ups/xplat/core';

@Injectable({
  providedIn: 'root',
})
export class DispatchStatusResolver {
  constructor(
    private manPowerService: ManPowerService,
    private applicationModel: ApplicationModel
  ) {}

  load(): Promise<any> {
    if (this.applicationModel.dispatchStatuses.length === 0) {
      return this.manPowerService.fetchDispatchStatuses().then((data) => {
        this.applicationModel.dispatchStatuses = data.filter((item) => {
          return item.DispatchStatusId !== DispatchStatusEnum.Backup;
        });
      });
    } else {
      return Promise.resolve(this.applicationModel.dispatchStatuses);
    }
  }
}
