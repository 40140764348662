import {
  Directive,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';

import { Observable, of, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  switchMap,
  distinctUntilChanged,
  takeUntil,
} from 'rxjs/operators';

import {
  AutoCompleteComponent,
  ComboBoxComponent,
  DropDownListComponent,
  MultiSelectComponent,
} from '@progress/kendo-angular-dropdowns';

import { State } from '@progress/kendo-data-query';

import { DropDownListRememberFilterDirective } from './dropdownlist-remember.filter-directive';
import {
  IMCSOptions,
  IMCSOrderConfig,
  IMCSSearchColumnConfig,
  MCSOrderUtils,
  MCSSearchColumnUtils,
  Process,
  ToODataQuery,
  ToStateEx,
} from '@ups/xplat/features';
import { BaseComponent } from '@ups/xplat/core';

/* eslint-disable */
/*
Usage:
    Import DropDownsModule & DropDownsModuleEx

    Use attribute inside HTML (see samples below) - depending if you need search over local data or server (odata) data
    Set seSearchColumns (mandatory)
    Alter other settings by your need.

HTML sample:
<kendo-autocomplete search-ex [seLocalData]="items" seSearchColumns="['FirstName', 'LastName']" />
<kendo-combobox search-ex (seFilterChangeEx)="fetchItems($event)" seSearchColumns="{ FirstName: { useStartsWith: true, useCaseInsensitive: true }, HRRef: { convertToString: true } }" />
<kendo-dropdownlist ... />
<kendo-multiselect ... />

search-ex
    attribute - will append extended searchfunctionality to the given Kendo drop-down control.


--- main props.

seInitialData: any[]
    contains an array of items on which will be set initially.
    once filtering occurs, this data set won't be available anymore.
    if the assigned object's value changes, the new set will be immediately applied.

seLocalData: any[]
    contains an array of items on which we will do searching (local-data search).
    if seUseCustomLocalFilter is set to false, all the logic will be handled via this directive.
    if seUseCustomLocalFilter is true, we can use custom logic - for more see: seFilterChangeEx event.

seUseCustomLocalFilter: boolean
    default: FALSE = but...
    ...custom local filter will be used when this property is TRUE
    ...also custom local filter will be used if there's no [seLocalData] assigned (null or undefined) - thus assuming OData queries
    see: useCustomFilterChange getter

seFilterChangeExFn: (ev: SeFilterChangeExArgs) => Observable<any>
    an alternative to (seFilterChangeEx)
    has same logic, but must return an observable
    as it's using observables, it is now cancellable

    IMPORTANT: the assigned function must be defined via FAT-ARROW otherwise the THIS keyword will point to the DropDownSearchExtensionDirective instead of the class:

    export class MyComponent {

      filterChangeFn = (ev: SeFilterChangeExArgs): Observable<any> => {
        const state = ev.extension.toStateEx(ev.query);
        const odataQuery = toODataStringEx(state);
        const vendorGroup = 26;
        const uri = `api/inmt/materialddopt-odata/${vendorGroup}` + (odataQuery ? '?' + odataQuery : '');
        const observable = this.httpVp.get(uri);
        observable.subscribe((odata: any) => { ev.extension.applyData(odata.value); console.log(odata.value); } );
        return observable;
      }

    }

(seFilterChangeEx) with event-arg: <SeFilterChangeExArgs>{ query: search, extension: self }
    this event is raised if we do have [seUseCustomLocalFilter] set to true or if there's no [seLocalData] set.
    in this event we can handle local data filtering as odata query creation.

    local data filter: {
        let columnFilterSettings = e.extension.getColumnFilterSettings(); // note: no need to call, enough to pass in undefined / null
        let filteredData = e.extension.process(allData, e.query, columnFilterSettings - this can be undefined, then it'l be filled based on settings);
        e.extension.applyData(filteredData);
    }

    odata filter: {
        let odataQuery = e.extension.toODataQuery(e.query)

        myService.queryData(odataQuery)
            .then(odata => {
                e.extension.applyData(odata.value, parseInt(odata["@odata.count"], 10));
            })
    }

    NOTES to:

        e.extension.process(allData: any[], search: string, columnFilterSettings: any = undefined): any[]

            Filters out items from the allData[].

            Params:
                allData:                array of in-memory items
                search:                 the search phrase entered into the dropdown
                columnFilterSettings:   can be undefined (in that case values set on the component will be sued)
            Returns:
                filtered items

        e.extension.toStateEx(search: string, take: number = undefined, createForKendoToOdataString: boolean = true, columnFilterSettings: any = undefined)

            Creates a kendo State object (with skip/take/filter/sort expressions).
            As it's a structured object, we can easily manipulate filter-expression safely.
            This State object can then be used when calling toODataString or toOdataStringEx.

            Params:
                search:                             the search phrase entered into the dropdown
                take:                               the number of items to limit result (falsy = take all results)
                createForKendoToOdataString:        if true, the conversion (in case of convertToString = true) and/or the tolower(value) (in case of matchCaseInsensitive = true)...
                                                    ...is appended by this directive (as it's not supported by Kendos toOdataString implementation)
                columnFilterSettings:               can be undefined (in that case values set on the component will be used)

        e.extension.toODataQuery(search: string, take: number = undefined, additionalODataFilterExpression: string = undefined, columnFilterSettings: any = undefined):

            Creates an odata-query-string to get desired (filtered) items from the server.

            Params:
                search:                             the search phrase entered into the dropdown
                take:                               the number of items to limit result (falsy = take all results)
                additionalODataFilterExpression:    additional odata expression (including or/and) that will be appended to the one generated base on columnFilterSettings and search query
                columnFilterSettings:               can be undefined (in that case values set on the component will be used)

        e.extension.applyData(data: [], dataCountTotal: number = undefined, take: number = undefined, searchIndex: number = undefined):

            Applies filtered items to the dwop-down.

            Params:
                data:           which is an array of filtered values (or objects) to display
                dataCountTotal: is the total items found based on the search, if not provided, the original data arrays length will be set as the total count.
                take:           if provided, will take just first N items from the data array.
                searchIndex:    each search gets a searchIndex (incremental value), once data with a given searchIndex is applied, any other data with lesser indexes won't be applied

            Apply data will set 2 extra parameters on the original kendo component:
            __dataCount - containing the current item count assigned to the dropdown
            __dataCountTotal - containing the total avail. item count satisfying our filter expression (or containing same value as __dataCount if dataCountTotal is not provided)

            With an OData result we use: e.extension.applyData(odata.value, parseInt(odata["@odata.count"], 10));
                - with odata we always should set the __dataCountTotal
                - with odata take is rarely used, but if then same applies as for in-memory data (see below)

            With in-memory date, we commonly use:  e.extension.applyData(filteredData);
                - the __dataCountTotal will be computed from the array's length
                - the __dataCount will be the items we display in the drop-down (either the data array's original length or the size limited via parameter take

            SAMPLE:
            <kendo-dropdownlist #ddl search-ex
                ...
                >
                <ng-template kendoDropDownListFooterTemplate>
                    {{ddl['__dataCount']}}/{{ddl['__dataCountTotal']}}
                </ng-template>
            </kendo-dropdownlist>

        e.extension.getColumnFilterSettings()
            see: seSearchColumns [object] setting
            this actually converts the search settings to a standardized object that our algorithms use, which is the same as the seSearchColumns [object] setting.
            we might override computed (converted) values based on our needs.

--- additional props.

seSearchColumns: special - MANDATORY property
    Either contains column (property) names we do search on - a single string or an array of strings:
        [ 'FirstName', 'Address.Street']
    Or it is an object, with column names as keys and with useStartsWith & useCaseInsensitive boolean values (for omitted properties, defaults will be used):
        { FName: { matchCaseInsensitive: true }, 'LName' :{ matchStartsWith: true}, HRRef: { convertToString: true } }

seOrderByColumns: string[] = column names to do final sort, use + or - prefix for ascending/descending order.
    Client side order by uses lodash and so nesting like Address.City is allowed
    With OData for nesting use / so: Address/City

seOrderByFirstSearchColumnWhenNoOrderByColumns: boolean = true (default)
    Uses first search column to order data in case no column in seOrderByColumns is defined.

seMinSearchLength: number = undefined (default: undefined)
    If set to truthy (> 0) it will require you to enter a search string with minimum of the given length to perform search and/or to trigger (seFilterChangeEx).
    NOTE: setting this property will also make [dropdownLoadAllAfterSelection]="true" to be ignored

seEmptyIfMinSearchLengthNotMet: boolean = true (default)
    if minimum search length criteria is not met and this property is FALSE, we will display all (or paged) amount of items.
    if minimum search length criteria is not met and this property is TRUE, nothing will be displayed in the dropdown.

seTake: number = undefined (default: undefined)
    Will limit (trim) data displayed in drop-down to a given number of items (if value is truthy and a number greater than 0).

seDebounceFilterChange: number = 250
    Debounce seFilterChangeEx events...

seRaiseLoadAllFilterChangeExOnOpen: boolean = TRUE by default
    If TRUE a load all (with no filter) will be done when pop-up is opened
    NOTE:
        MUST BE SET TO FALSE with [remember-filter] - cause remember-filter will trigger it's own load.
        Currently there's a check in the ctor, so if [remember-filter] with auto-trigger (rfRaiseFilterChangeOnOpen) is used, then this default is turned off, unless it's overridden via input property binding or code.

seAvoidLoadAllFilterChangeExAfterSelection: boolean = TRUE by default
    Kendo dropdown controls do trigger a (filterChange) event with no value after selection (component.isOpen === false, so aft. closing pop-up) - resulting in additional trip to the server if server-side data is used.
    This property should avoid to force a filterChange with empty value when pop-up is closed.

seMatchUseStartsWithDefault: boolean = Value is used as a default if no concrete value is defined for the column. FALSE by default, so a CONTAINS operator will be used; if  true STARTSWITH is used.
seMatchCaseInsensitiveDefault: boolean = Value is used as a default if no concrete value is defined for the column. TRUE by default; if False uppercase/lowercase letters will be distinguished.

seWordSplitter: string - SPACE by default; use empty string to do no-split.
seMatchAllWords: boolean = TRUE by default - all words must be present in the result; FALSE is presence of any word is enough.
seMatchInEveryColumn: boolean = FALSE by default - the searched word must be present in a column; TRUE - if the word has to be present in all columns;

seHandleLoadingState: boolean = TRUE by default - the loading flag will be managed by the search extension (turn on before emitting change event and turned off with applyData).
*/
@Directive({
  selector: '[search-ex]',
})
export class DropDownSearchExtensionDirective extends BaseComponent {
  protected _seInitialData: any[];

  @Input() public get seInitialData(): any[] {
    return this._seInitialData;
  }

  public set seInitialData(value: any[]) {
    //
    // NOTE: worth to consider if this should be a one time assignment or later changes in the bindings should be also reflected...
    this._seInitialData = value;
    this.applyData(this.seInitialData);
  }

  /** basic set-up, for cancellable observables please use the seFilterChangeObservableFn input */
  @Output() public seFilterChangeEx: EventEmitter<SeFilterChangeExArgs> =
    new EventEmitter<SeFilterChangeExArgs>();

  /** replacement for seFilterChangeEx, as it's an observable, it will be cancellable */
  @Input() public seFilterChangeExFn: (
    ev: SeFilterChangeExArgs
  ) => Observable<any>;

  protected _seLocalData: any[];

  @Input() public get seLocalData(): any[] {
    return this._seLocalData;
  }

  public set seLocalData(value: any[]) {
    this._seLocalData = value;

    // NOTE:
    // seLocalData - it might contain a big amount of items we intend to filter from.
    // applying it might make UI stall when opening pop-up (above 1000 items it is noticeable, around 10.000 it's annoying)

    // if dropdown opened, execute filter-change code...
    if (this._component.isOpen) this.internalFilterChange(this.lastFilterValue);
  }

  @Input() public seUseCustomLocalFilter = false;

  @Input() public seSearchColumns: string | string[] | any = [];
  @Input() public seOrderByColumns: string[] = [];
  @Input() public seOrderByFirstSearchColumnWhenNoOrderByColumns = true;

  @Input() public seMinSearchLength: number = undefined;
  @Input() public seEmptyIfMinSearchLengthNotMet = true;

  @Input() public seTake: number = undefined;

  protected _seDebounceFilterChange = 250;

  @Input() /* must be placed on getter */
  public get seDebounceFilterChange(): number {
    return this._seDebounceFilterChange;
  }

  public set seDebounceFilterChange(value: number) {
    this._seDebounceFilterChange = value;

    // set up subscription
    if (this.filterChangeSubscription)
      this.filterChangeSubscription.unsubscribe();

    this.filterChangeSubscription = this.filterChangeSubject$
      .pipe(debounceTime(this.seDebounceFilterChange), distinctUntilChanged())
      .subscribe((value: any) => this.internalFilterChange(value));
  }

  @Input() public seMatchUseStartsWithDefault = false;
  @Input() public seMatchCaseInsensitiveDefault = true;

  @Input() public seWordSplitter = ' ';
  @Input() public seMatchAllWords = true;
  @Input() public seMatchInEveryColumn = false;

  @Input() public seRaiseLoadAllFilterChangeExOnOpen = true;
  @Input() public seAvoidLoadAllFilterChangeExAfterSelection = true;

  @Input() public seHandleLoadingState = true;

  protected _component: any;

  public get component(): any {
    return this._component;
  }

  protected get useCustomFilterChange(): boolean {
    return !this.seLocalData || this.seUseCustomLocalFilter;
  }

  protected lastFilterValue = '';
  protected filterChangeSubject$: Subject<any> = new Subject<any>();
  protected filterChangeSubscription: Subscription;

  protected internalCustomFilterChangeEvent: EventEmitter<SeFilterChangeExArgs> =
    new EventEmitter<SeFilterChangeExArgs>();

  constructor(
    @Optional() @Self() private ac: AutoCompleteComponent,
    @Optional() @Self() private cb: ComboBoxComponent,
    @Optional() @Self() private ddl: DropDownListComponent,
    @Optional() @Self() private ms: MultiSelectComponent,
    @Optional() @Self() private remember: DropDownListRememberFilterDirective
  ) {
    super();
    this._component = this.ac || this.cb || this.ddl || this.ms;

    if (!this.component)
      throw Error(
        'Search-extension has to be defined on a kendo search like component!'
      );

    // NOTE: to bypass async double load where mostly the 1st load values are bound at last (thus overriding results from the remembered value search)...
    if (remember && remember.rfRaiseFilterChangeOnOpen)
      this.seRaiseLoadAllFilterChangeExOnOpen = false;

    this.component.filterChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.internalFilterChangeEx.bind(this));
    this.component.open
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.internalOpenEx.bind(this));

    this.internalCustomFilterChangeEvent
      .pipe(
        switchMap((ev) => {
          if (
            this.seFilterChangeExFn &&
            this.seFilterChangeEx.observers.length > 0
          )
            throw new Error(
              `Can't have both 'seFilterChangeEx' and 'seFilterChangeExFn' set!`
            );

          if (this.seFilterChangeExFn) {
            return this.seFilterChangeExFn(ev);
          } else {
            this.seFilterChangeEx.emit(ev);
            return of(null);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    // NOTE: triggering setter to set up subscription...
    this.seDebounceFilterChange = this.seDebounceFilterChange;
  }

  protected internalOpenEx(e) {
    // force load all event on open if set so...
    if (this.seRaiseLoadAllFilterChangeExOnOpen)
      this.internalFilterChange('', true);
  }

  protected internalFilterChangeEx(e) {
    this.filterChangeSubject$.next(e);
  }

  public clearDropdownItems() {
    this.applyData([], undefined, this.seTake);
  }

  protected searchIndex = 0;
  protected appliedSearchIndex = 0;

  /** Executed with filter changed with debouncing/distincUntil pipe */
  protected internalFilterChange(e, isOpening = false) {
    // get safe search string
    let search = e || '';

    // remember last entered search text for internal use
    this.lastFilterValue = search;

    // remember filter might raise a load-all when pop-up is opened
    // and the regular control does a load-all when closed (which with odata must be avoided and with local data again not necessary)
    const isRememberFilterLoadAllEvent =
      this.remember &&
      this.remember.rfRaiseFilterChangeOnOpen &&
      !this.remember.filterText;
    const isLoadAllAfterSelectionEvent =
      !search &&
      !this.component.isOpen &&
      !isOpening &&
      !isRememberFilterLoadAllEvent;

    if (
      isLoadAllAfterSelectionEvent &&
      this.seAvoidLoadAllFilterChangeExAfterSelection
    )
      return;

    // if we have to perform a search, but search length is not met...
    if (this.seMinSearchLength > 0 && search.length < this.seMinSearchLength) {
      // ...we wither do show nothing
      if (this.seEmptyIfMinSearchLengthNotMet) {
        this.clearDropdownItems();
        return;
      }

      // ...or we do a call to get all data (no search crit)
      search = '';
    }

    // do filtering...
    if (this.seHandleLoadingState) this.component.loading = true;

    if (this.useCustomFilterChange) {
      // use custom event or observable fn...
      const ev = new SeFilterChangeExArgs(this, search, ++this.searchIndex);
      this.internalCustomFilterChangeEvent.emit(ev);
    } else {
      // if local data and use "default" handle with internal logic
      const filteredData = this.process(this.seLocalData, search);
      this.applyData(filteredData, undefined, this.seTake);
    }
  }

  public applyData(
    data: any[],
    dataCountTotal: number = undefined,
    take: number = undefined,
    searchIndex: number = undefined
  ): void {
    // NOTE:
    // Based on lates impl. all the supported components does have a data @Input and does implement OnChanges.
    // If not, we need an exception!

    // check if we need to apply data
    if (searchIndex) {
      // skip if newer data avail.
      if (this.appliedSearchIndex > searchIndex) return;

      // set new applied index if we got newer data to apply
      this.appliedSearchIndex = Math.max(this.appliedSearchIndex, searchIndex);
    }

    // originally assigned data-set
    const originalData = this.component.data;

    // trim data (based on provided take value)
    const trimmedData = take ? data.slice(0, take) : data;

    // assign new data
    this.component.data = trimmedData;
    this.component.__dataCount = trimmedData ? trimmedData.length : 0;
    this.component.__dataCountTotal =
      dataCountTotal || (data ? data.length : 0);

    // Fix KENDO UI issue when data is changed...
    // NOTE: this feature will be supported later: https://github.com/telerik/kendo-angular/issues/1645 - from v3.4.0
    this.component.ngOnChanges({
      data: {
        previousValue: originalData,
        currentValue: trimmedData,
        firstChange: false,
        isFirstChange: () => false,
      },
    });

    // switch loading off...
    if (this.seHandleLoadingState) this.component.loading = false;
  }

  //
  // The multi-column-search integration...

  protected getSearchOptions(): IMCSOptions {
    const self = this;
    return {
      matchWithStartsWithByDefault: self.seMatchUseStartsWithDefault,
      matchCaseInsensitiveByDefault: self.seMatchCaseInsensitiveDefault,

      wordSplitter: self.seWordSplitter,
      matchAllWords: self.seMatchAllWords,
      matchInEveryColumn: self.seMatchInEveryColumn,

      orderByFirstSearchColumnWhenNoOrderByColumns:
        self.seOrderByFirstSearchColumnWhenNoOrderByColumns,

      maximizeResultTo: self.seTake,
    } as IMCSOptions;
  }

  getColumnFilterSettings(): IMCSSearchColumnConfig[] {
    return MCSSearchColumnUtils.getSearchColumnConfigArray(
      this.seSearchColumns,
      this.seMatchUseStartsWithDefault,
      this.seMatchCaseInsensitiveDefault
    );
  }

  getOrderSettings(firstColumnName: string): IMCSOrderConfig[] {
    return MCSOrderUtils.getSortOrderConfigArray(
      this.seOrderByColumns,
      this.seOrderByFirstSearchColumnWhenNoOrderByColumns,
      firstColumnName
    );
  }

  public toStateEx(
    search: string,
    take: number = undefined,
    createForKendoToOdataString = true,
    columnFilterSettings: IMCSSearchColumnConfig[] = undefined
  ): State {
    columnFilterSettings =
      columnFilterSettings || this.getColumnFilterSettings();
    return ToStateEx.convert(
      this.getSearchOptions(),
      columnFilterSettings,
      this.getOrderSettings(columnFilterSettings[0].field),
      search,
      take,
      createForKendoToOdataString
    );
  }

  public toODataQuery(
    search: string,
    take: number = undefined,
    additionalODataFilterExpression: string = undefined,
    columnFilterSettings: IMCSSearchColumnConfig[] = undefined
  ): string {
    columnFilterSettings =
      columnFilterSettings || this.getColumnFilterSettings();
    return ToODataQuery.convert(
      this.getSearchOptions(),
      columnFilterSettings,
      this.getOrderSettings(columnFilterSettings[0].field),
      search,
      take,
      additionalODataFilterExpression
    );
  }

  public process(
    allData: any[],
    search: string,
    columnFilterSettings: IMCSSearchColumnConfig[] = undefined
  ): any[] {
    columnFilterSettings =
      columnFilterSettings || this.getColumnFilterSettings();
    return Process.filter(
      this.getSearchOptions(),
      columnFilterSettings,
      this.getOrderSettings(columnFilterSettings[0].field),
      allData,
      search
    );
  }
}

export class SeFilterChangeExArgs {
  public target: DropDownSearchExtensionDirective;
  public query: string;
  extension: any;

  constructor(
    searchExDirective: DropDownSearchExtensionDirective,
    query: string,
    searchIndex: number = undefined
  ) {
    this.target = searchExDirective;
    this.query = query;
    this.extension = {
      searchIndex: searchIndex,

      target: this.target,
      query: this.query,

      getColumnFilterSettings: () => this.target.getColumnFilterSettings(),
      toStateEx: (
        search: string,
        take: number = undefined,
        createForKendoToOdataString = true,
        columnFilterSettings: IMCSSearchColumnConfig[] = undefined
      ) =>
        this.target.toStateEx(
          search,
          take,
          createForKendoToOdataString,
          columnFilterSettings
        ),
      toODataQuery: (
        search: string,
        take: number = undefined,
        additionalODataFilterExpression: string = undefined,
        columnFilterSettings: IMCSSearchColumnConfig[] = undefined
      ): string =>
        this.target.toODataQuery(
          search,
          take,
          additionalODataFilterExpression,
          columnFilterSettings
        ),
      process: (
        allData: any[],
        search: string,
        columnFilterSettings: IMCSSearchColumnConfig[] = undefined
      ) => this.target.process(allData, search, columnFilterSettings),
      applyData: (
        data: any[],
        dataCountTotal: number = undefined,
        take: number = undefined
      ) =>
        this.target.applyData(
          data,
          dataCountTotal,
          take,
          this.extension.searchIndex
        ),
    };
  }
}
