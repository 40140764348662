/* eslint-disable @typescript-eslint/naming-convention */
export class LaborTypeSetupDto {
  public Class: string;
  public Craft: string;
  public CraftClass: string;
  public DirectCraftClassID: string;
  public JobID: string;
  public LaborTypeID: string;
  public LaborTypeName: string;
  public MileageCode: string;
  public OTCode: string;
  public PDCode: string;
  public PTCode: string;
  public STCode: string;
  public ShiftDiffAmount: number;
  public bShiftDifferential: boolean;
  public ShiftDiffPercentage: number;
}
