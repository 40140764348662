import { deepClone } from './deep-clone';

/**
 * @description Merge object 2 into object 1 recursively.
 * This differs from Object.assign() in that child objects are not references to the original.
 * Child objects / arrays that are present in each object are merged.
 * Returns the result, treats object 1 / 2 as immutable since there is not guarantee that they are not.
 *
 * @link [Codepen Playground](https://codepen.io/agentender/pen/abNVxvV)
 * @example
 * ```
 *      Object A ({
 *          "auth0": {
 *          "clientId": "XXX-YYY-ZZZ",
 *              "audience": "COMMON AUD"
 *          },
 *          "urls": {
 *              "API1": "ENV_API1.AZUREWEBSITES.NET"
 *          }
 *      })
 *      Object B ({
 *          "auth0": {
 *              "clientId": "SPECIFIC ID"
 *          },
 *          "urls": {
 *              "PROJECT_SPECIFIC_URL": "TEST"
 *          }
 *      })
 *      deepMerge(A, B) ({
 *          "auth0": {
 *              "clientId": "SPECIFIC ID",
 *              "audience": "COMMON AUD"
 *          },
 *          "urls": {
 *              "API1": "ENV_API1.AZUREWEBSITES.NET",
 *              "PROJECT_SPECIFIC_URL": "TEST"
 *          }
 *      })
 * ```
 */
export function deepMerge<T1 extends object, T2 extends object>(
  obj1: T1,
  obj2: T2
): T1 & T2 {
  const result: any = {};
  if (obj1 === null) {
    return obj2 === null ? null : deepClone(obj2);
  }
  Object.entries(obj1).forEach(([key, value]) => {
    if (key in obj2) {
      // potential overwrite
      if (typeof value !== typeof obj2[key]) {
        // value type mismatch, always take obj2's values.
        result[key] = obj2[key];
      } else if (typeof value == 'object') {
        result[key] = deepMerge(value, obj2[key]);
      } else {
        result[key] = obj2[key];
      }
    } else {
      result[key] = value;
    }
  });
  Object.entries(obj2)
    .filter(([key]) => !(key in obj1))
    .forEach(([key, value]) => {
      result[key] = value;
    });
  return result;
}
