import { Directive, forwardRef } from '@angular/core';
import { ControlContainer, NgModelGroup } from '@angular/forms';

/*
 * Please use CONTROL_CONTAINER_PROVIDER or [provide-control-container] directive instead for more flexibility...
 * ...handling both ngForm as ngModelGroup universally.
 *
 * To be used with nested TEMPLATE DRIVEN forms.
 *
 * What it does:
 *  When the parent control uses ngModelGroup and inside that group we do add out custom control...
 *  ...and we want our custom control inputs to be registered in that group
 *  ...then we need to provide the parent ngModelGroup to our control
 *
 * Usage:
 *  The child component (we add to HTML)
 *  - must have viewProvider: [NG_MODEL_GROUP_PROVIDER] defined
 *  - or one of it's top level node must have the [provide-ng-model-group] attribute
 */
export const NG_MODEL_GROUP_PROVIDER: any = {
  provide: ControlContainer,
  useExisting: forwardRef(() => NgModelGroup),
};

/**
 * Please use CONTROL_CONTAINER_PROVIDER or [provide-control-container] directive instead for more flexibility
 * See additional notes on: NG_MODEL_GROUP_PROVIDER
 */
@Directive({
  selector: '[provide-ng-model-group]',
  providers: [NG_MODEL_GROUP_PROVIDER],
})
export class ProvideNgModelGroupDirective {}
