import { BaseModel } from '../../base.model';

export class ManPowerSubRequirementDto extends BaseModel {
  ManPowerSubRequirementID: string;
  ManPowerRequirementID: string;
  JobID: string | null;
  PlantID: number | null;
  bActive: boolean;
  Name: string;
  RequirementName: string;
  bMandatory: boolean;
  SafetyCouncilID: string | null;
  SafetyCouncilName: string;
  bBillable: boolean;
  VPTrainingClassKeyID: number;
  ManPowerSubRequirementGroupID: string;
  GroupName: string;
  Notes: string;
  DateModified: string;
  ModifiedBy: string;

  RequestedClasses: ManPowerSubRequirementRequestedClass[];

  constructor(o: any = null) {
    super(o);
  }
}

export interface ManPowerSubRequirementRequestedClass {
  ManPowerSubRequirementRequestedClassID?: string;
  ManPowerSubRequirementID?: string;
  RequestedTradeClassID?: string;
  RequestedLinkedClassID?: string;
  RequestedClassName?: string;
  bActive: boolean;
}
