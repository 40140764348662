import { EquipmentBillingRatesComponent } from './equipment-billing-rates/equipment-billing-rates.component';
import { EquipmentDispatchEditComponent } from './equipment-dispatch-edit/equipment-dispatch-edit.component';
import { EquipmentDispatchHistoryComponent } from './equipment-dispatch-history/equipment-dispatch-history.component';
import { EquipmentDispatchPageComponent } from './equipment-dispatch-page/equipment-dispatch-page.component';

export * from './equipment-dispatch-page/equipment-dispatch-page.component';
export * from './equipment-dispatch-history/equipment-dispatch-history.component';
export * from './equipment-dispatch-edit/equipment-dispatch-edit.component';
export * from './equipment-resource-dropdown/equipment-resource-dropdown.module';
export * from './equipment-basic-info/equipment-basic-info.module';
export * from './equipment-billing-rates/equipment-billing-rates.component';

export const EQUIPMENT_DISPATCH_COMPONENTS = [EquipmentDispatchPageComponent, EquipmentDispatchHistoryComponent, EquipmentDispatchEditComponent, EquipmentBillingRatesComponent];
