import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';

const UPDATE_EMPLOYEE_TEAM = '/api/hrrm/employee/org/{:hrRef}/{:reportsTo}';
const REMOVE_EMPLOYEE_FROM_TEAM = '/api/hrrm/employee/org/{:hrRef}';

@Injectable({ providedIn: 'root' })
export class MyTeamService {
  http: HttpClient;

  constructor(private clientFactory: MyHttpClientFactory) {
    this.http = this.clientFactory.createHttpClient(
      environment.urls.viewpointAPI,
      true,
      ResponseCasingEnum.CamelCase
    );
  }

  public updateEmployeeTeam(hrRef: string, reportsTo: string) {
    const url = UPDATE_EMPLOYEE_TEAM.split('{:hrRef}')
      .join(hrRef)
      .split('{:reportsTo}')
      .join(reportsTo);
    return this.http.put(url, {});
  }

  public removeEmployeeFromTeam(hrRef: string) {
    const url = REMOVE_EMPLOYEE_FROM_TEAM.split('{:hrRef}').join(hrRef);
    return this.http.delete(url);
  }
}
