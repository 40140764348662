<div
  id="tableview-container"
  *ngIf="
    securityFeature == null ||
    securityFeature.ViewFeature ||
    securityFeature.editAll ||
    securityFeature.EditSelf ||
    securityFeature.viewFeature ||
    securityFeature.editAll ||
    securityFeature.editSelf
  "
>
  <label class="me-5">View:</label>
  <button class="k-button view-btn me-3" (click)="open()">
    {{ selectedTableView ? selectedTableView.Name : 'Default' }}
  </button>
  <button
    class="k-button view-btn me-3"
    *ngIf="
      securityFeature && (securityFeature.editAll || securityFeature.EditSelf)
    "
    (click)="openSave()"
    title="Save View"
    [disabled]="!isSaveEnabled()"
  >
    Save
  </button>
  <button
    class="k-button view-btn"
    *ngIf="
      securityFeature && (securityFeature.editAll || securityFeature.EditSelf)
    "
    (click)="openCreate()"
    title="Create View"
  >
    Create
  </button>
</div>

<kendo-dialog
  modal
  fade
  [hidden]="!showSaveTableViewDialog"
  (close)="showSaveTableViewDialog = false"
>
  <kendo-dialog-titlebar>
    <span>Save Current Table View</span>
  </kendo-dialog-titlebar>

  <div *ngIf="tableViewToEdit != null" style="width: 550px; padding-left: 10px">
    <div class="row form-group">
      <div class="col-sm-12">
        <div class="d-flex align-items-center">
          <label class="me-5 mb-0">View Name</label>
          <input
            type="text"
            maxlength="50"
            class="k-textbox flex-fill"
            name="newTableViewName"
            required
            [(ngModel)]="tableViewToEdit.Name"
            [disabled]="
              tableViewToEdit.TableViewID != null ||
              (tableViewToEdit.bDefault && tableViewToEdit.bPublic)
            "
          />
        </div>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-sm-4">
        <label class="d-block">Public</label>
        <kendo-switch
          [(ngModel)]="tableViewToEdit.bPublic"
          [onLabel]="'Yes'"
          [offLabel]="'No'"
          [disabled]="
            !securityFeature ||
            !securityFeature.editAll ||
            tableViewToEdit.bDefault
          "
        ></kendo-switch>
      </div>
      <div class="col-sm-4">
        <label class="d-block">Pre-selected</label>
        <kendo-switch
          [(ngModel)]="bPreSelected"
          [onLabel]="'Yes'"
          [offLabel]="'No'"
          [disabled]="tableViewToEdit.bDefault && tableViewToEdit.bPublic"
        ></kendo-switch>
      </div>
      <div class="col-sm-4">
        <label class="d-block">Save Grouping</label>
        <kendo-switch
          [(ngModel)]="bSaveGrouping"
          [onLabel]="'Yes'"
          [offLabel]="'No'"
          [disabled]="tableViewToEdit.bDefault && tableViewToEdit.bPublic"
        ></kendo-switch>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-sm-4">
        <label class="d-block">Save Filtering</label>
        <kendo-switch
          [(ngModel)]="bSaveFiltering"
          [onLabel]="'Yes'"
          [offLabel]="'No'"
          [disabled]="tableViewToEdit.bDefault && tableViewToEdit.bPublic"
        ></kendo-switch>
      </div>
      <div class="col-sm-4">
        <label class="d-block">Save Sorting</label>
        <kendo-switch
          [(ngModel)]="bSaveSorting"
          [onLabel]="'Yes'"
          [offLabel]="'No'"
          [disabled]="tableViewToEdit.bDefault && tableViewToEdit.bPublic"
        ></kendo-switch>
      </div>
      <div class="col-sm-4">
        <label class="d-block" title="Save Column Order and Visibility">
          Save Column Settings
        </label>
        <br />
        <kendo-switch
          [(ngModel)]="bSaveColConfig"
          [onLabel]="'Yes'"
          [offLabel]="'No'"
          [disabled]="tableViewToEdit.bDefault && tableViewToEdit.bPublic"
        ></kendo-switch>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-sm-12">
        <label
          class="d-block"
          title="Save Items per Page (Fewer items per page may allow data to load faster)"
        >
          Save Items Per Page
        </label>
        <kendo-switch
          [(ngModel)]="bSaveItemsPerPage"
          [onLabel]="'Yes'"
          [offLabel]="'No'"
          [disabled]="tableViewToEdit.bDefault && tableViewToEdit.bPublic"
        ></kendo-switch>
        <span class="text-primary d-inline-block ms-3">
          Fewer items per page may allow your dashboard to load faster.
        </span>
      </div>
    </div>

    <div
      class="row form-group"
      *ngIf="tableViewToEdit.bDefault && tableViewToEdit.bPublic"
    >
      <div class="col">
        <label class="font-weight-bold">
          I understand that saving this will affect every user's default grid
          view.
        </label>
        <br />
        <kendo-switch
          [(ngModel)]="dangerAcknowledged"
          [onLabel]="'Yes'"
          [offLabel]="'No'"
        ></kendo-switch>
      </div>
    </div>
  </div>

  <kendo-dialog-actions *ngIf="tableViewToEdit != null" [layout]="'normal'">
    <button
      kendoButton
      [primary]="'true'"
      (click)="saveGridSettings()"
      [disabled]="
        tableViewToEdit.Name.length == 0 ||
        (tableViewToEdit.bDefault &&
          tableViewToEdit.bPublic &&
          !dangerAcknowledged)
      "
    >
      Save
    </button>
    <button kendoButton (click)="showSaveTableViewDialog = false">
      Cancel
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog
  modal
  fade
  title="Table Views"
  [hidden]="!showTableViewDialog"
  (close)="showTableViewDialog = false"
  [width]="900"
>
  <kendo-dialog-titlebar>
    <span>Table Views</span>
  </kendo-dialog-titlebar>

  <kendo-grid [data]="tableViews" class="no-pagination">
    <kendo-grid-column field="Name" title="Name" width="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [ngClass]="{ 'font-weight-bold': dataItem.bDefault }">
          {{ dataItem.Name }}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="bPublic" title="Public" width="70">
      <ng-template kendoGridCellTemplate let-dataItem>
        <i *ngIf="dataItem.bPublic" class="far fa-check text-success"></i>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="Name" title="Pre-selected" width="100">
      <ng-template kendoGridCellTemplate let-dataItem>
        <i
          *ngIf="dataItem.Name == preSelectedTableViewName"
          class="far fa-check text-success"
        ></i>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Actions" width="300">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          kendoButton
          [primary]="'true'"
          (click)="loadState(dataItem)"
          class="me-3"
        >
          Load
        </button>
        <button kendoButton (click)="preSelect(dataItem)" class="me-3">
          Pre-select
        </button>
        <button
          *ngIf="
            dataItem.bActive &&
            !dataItem.bDefault &&
            securityFeature &&
            (securityFeature.editAll ||
              (securityFeature.EditSelf && !dataItem.bPublic))
          "
          kendoButton
          (click)="delete(dataItem)"
        >
          Delete
        </button>
        <button
          *ngIf="!dataItem.bActive"
          kendoButton
          (click)="undelete(dataItem)"
        >
          UnDelete
        </button>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>

  <kendo-dialog-actions [layout]="'normal'">
    <button kendoButton (click)="close()">Close</button>
  </kendo-dialog-actions>
</kendo-dialog>
