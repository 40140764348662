import { InjectionToken } from '@angular/core';
import { ViewFeatureExpressionType } from '@ups/security';

/**
 * This is the basic structure to configure menu.
 * The UI will use an internal one, that will be re-calculated whenever permissions change.
 *
 * The visible and enabled properties:
 *
 *  It's an expression of type: ViewFeatureExpressionType
 *
 *  Expression samples:
 *    true
 *    "security_id"
 *    [true]
 *    [false, "security_id"]
 *    ["security_id_one", "security_id_two"]
 *    { or: ["security_id_one", "security_id_two"]}
 *    { and: [ "sexurity_id_main", { or: ["security_id_one", "security_id_two"]} ]}
 */

/** Used by UI components, contains extended IMenuConfig data (full URL, evaluated visible/enabled properties, etc.) */
export interface IMenuItem {
  url?: string;

  path?: string;
  exact?: boolean;

  index?: number;

  title: string;
  tooltip?: string;
  tagName?: string; // used alongside tagging/categories
  isCategory?: boolean;

  icon?: string;
  iconClass?: string;
  disableRouting?: boolean;

  // visible?: boolean;
  // enabled?: boolean;
  visible?: ViewFeatureExpressionType;
  enabled?: ViewFeatureExpressionType;

  children?: IMenuItem[];

  action?: () => void;
}

export const MENU_SERVICE_TOKEN = new InjectionToken<IMenuService>(
  'MENU_SERVICE_TOKEN'
);

export interface IMenuService {
  staticMenuConfig?: Array<IMenuItem>;
  menuItems: Array<IMenuItem>;
  configureMenu(
    configItems?: Array<IMenuItem>,
    maxDepth?: number,
    skipParseInternal?: boolean
  );

  /** @deprecated use: isMenuItemActive */
  hasActiveUrl(menuItem: IMenuItem, checkChildren?: boolean): boolean;

  isMenuItemActive(menuItem: IMenuItem, checkChildren?: boolean): boolean;
  getMenuItemToActiveUrl(
    menuItems?: IMenuItem[],
    checkChildren?: boolean
  ): IMenuItem;
  fetchFromRoutes(): IMenuItem[];
}
