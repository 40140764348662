import { Injectable } from '@angular/core';

type PrettyPrintOne = (code: string, language?: string, linenums?: number | boolean) => string;

/*
import * as PP from 'node_modules/code-prettify/src/prettify.js';

  // private getPrettyPrintOne(): Promise<PrettyPrintOne> {
  //   const ppo = (window as any)['PR'].prettyPrintOne;
  //   return Promise.resolve(ppo);
  // }

*/

/*
import * as PP from './assets/prettify.js';

  // private getPrettyPrintOne(): Promise<PrettyPrintOne> {
  //   const ppo = (window as any)['prettyPrintOne'];
  //   return Promise.resolve(ppo);
  // }

*/

import * as PP from '../../../../../../../node_modules/code-prettify/src/prettify.js';

import { from, Observable } from 'rxjs';
import { share, map, first } from 'rxjs/operators';

/**
 * Wrapper around the prettify.js library
 */
@Injectable({ providedIn: 'root' })
export class PrettyPrinterService {
  private prettyPrintOne: Observable<PrettyPrintOne>;

  constructor() {
    const initializeScript = PP;
    this.prettyPrintOne = from(this.getPrettyPrintOne()).pipe(share());
  }

  private getPrettyPrintOne(): Promise<PrettyPrintOne> {
    const ppo = (window as any)['PR'].prettyPrintOne;
    return Promise.resolve(ppo);
  }

  /**
   * Format code snippet as HTML
   * @param {string} code - the code snippet to format; should already be HTML encoded
   * @param {string} [language] - The language of the code to render (could be javascript, html, typescript, etc)
   * @param {string|number} [linenums] - Whether to display line numbers:
   *  - false: don't display
   *  - true: do display
   *  - number: do display but start at the given number
   * @returns Observable<string> - Observable of formatted code
   */
  formatCode(code: string, language?: string, linenums?: number | boolean) {
    return this.prettyPrintOne.pipe(
      map((ppo) => {
        try {
          const result = ppo(code, language, linenums);
          return result;
        } catch (err) {
          const msg = `Could not format code that begins '${code.substr(0, 50)}...'.`;
          console.error(msg, err);
          throw new Error(msg);
        }
      }),
      first() // complete immediately
    );
  }
}
