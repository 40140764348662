import { BaseModel } from '../base.model';

/* eslint-disable */
export class RegionDto extends BaseModel {
  RegionID: string;
  RegionName: string;
  IsVisibleForCurrentUser: boolean | null;

  constructor(o: any = null) {
    super(o);
  }
}
/* eslint-enable */
