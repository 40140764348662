/**
 * Auth0
 */
import { HttpRequest } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import type { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js';
export type PlatformAuth0ClientType = (
  options: Auth0ClientOptions
) => Promise<Auth0Client>;
export const PlatformAuth0ClientToken =
  new InjectionToken<PlatformAuth0ClientType>('PlatformAuth0ClientToken');

// JWT
export interface IPlatformJWTHelper {
  tokenGetter: () => string;
  urlBase64Decode(str: string): string;
  decodeToken(token?: string): any;
  getTokenExpirationDate(token?: string): Date | null;
  isTokenExpired(token?: string, offsetSeconds?: number): boolean;
  getAuthScheme(
    authScheme: Function | string | undefined,
    request: HttpRequest<any>
  ): string;
}
export const PlatformJWTHelperToken = new InjectionToken<IPlatformJWTHelper>(
  'PlatformJWTHelperToken'
);
