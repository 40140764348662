<div class="dynamic-field-container" [class.selected]="config?.selected">
  <div
    class="dynamic-field form-input text-align"
    [formGroup]="group"
    [hidden]="!config?.isFormBuilder && config?.options?.hidden"
  >
    <ups-dynamic-helper-label
      [config]="config"
      [dynamicService]="dynamicService"
    ></ups-dynamic-helper-label>
    <div class="job-search-container" style="position: relative">
      <ng-template [ngIf]="!config?.options?.isMultiSelect">
        <kendo-dropdownlist
          #dropdown
          class="k-no-dropdown w-100"
          [attr.name]="config.formControlName"
          [formControlName]="config.formControlName"
          [valuePrimitive]="false"
          [textField]="'DisplayName'"
          [valueField]="'DisplayName'"
          [defaultItem]="{ DisplayName: config?.placeholder }"
          [filterable]="true"
          (valueChange)="valueChangeItem($event)"
          search-ex
          [seSearchColumns]="['DisplayName', 'FacilityName', 'Address']"
          (seFilterChangeEx)="filterChangeItem($event)"
          [seDebounceFilterChange]="150"
          [seUseCustomLocalFilter]="true"
          [seMinSearchLength]="0"
          [loading]="isLoading"
        >
          <ng-template kendoDropDownListItemTemplate let-dataItem>
            <div
              style="display: inline-flex"
              [hidden]="dataItem?.DisplayName === config?.placeholder"
            >
              <span (click)="toggleFavoriteFacility(dataItem, $event)">
                <i
                  class="fas fa-star"
                  title="Add/Remove Favorite Facility"
                  [ngStyle]="{
                    color: dataItem.IsUserFavorite ? 'orange' : 'lightgrey'
                  }"
                ></i>
                &nbsp;
              </span>
              <div>
                <span *ngIf="dataItem.DisplayName" class="job-name link-style">
                  {{ dataItem.DisplayName }}
                </span>
                <div *ngIf="dataItem.Address" class="clearBoth">
                  <div style="display: inline-block">
                    {{ dataItem.Address }}
                  </div>
                </div>
                <div class="clearBoth">
                  <div style="display: inline-block">
                    {{ dataItem.City || 'n/a' }}
                  </div>
                  <div style="display: inline-block">
                    {{ dataItem.State ? ', ' + dataItem.State : '' }}
                  </div>
                  <div style="display: inline-block">
                    {{ dataItem.Zip ? ' ' + dataItem.Zip : '' }}
                  </div>
                </div>
                <!-- <div class="clearBoth">
                <div style="display:inline-block;">{{dataItem.DisplayName || 'n/a'}}</div>
              </div>
              <div class="clearBoth">
                <div style="display:inline-block;width:120px;">Parent:</div>
                <div style="display:inline-block;">{{dataItem.ParentCustomerName || 'n/a'}}</div>
              </div> -->
              </div>
            </div>
          </ng-template>
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <div style="display: inline-flex">
              <div kendoTooltip [title]="dataItem?.DisplayName">
                <span class="job-name">{{ dataItem.DisplayName }}</span>
              </div>
            </div>
          </ng-template>
        </kendo-dropdownlist>
      </ng-template>
      <ng-template [ngIf]="config?.options?.isMultiSelect">
        <kendo-multiselect
          [attr.name]="config.formControlName"
          [formControlName]="config.formControlName"
          class="k-no-dropdown w-100"
          [defaultItem]="{ DisplayName: config?.placeholder }"
          [valuePrimitive]="false"
          [textField]="'DisplayName'"
          [valueField]="'DisplayName'"
          [placeholder]="config?.placeholder"
          [filterable]="true"
          (valueChange)="valueChangeItem($event)"
          search-ex
          [seSearchColumns]="['DisplayName', 'FacilityName', 'Address']"
          (seFilterChangeEx)="filterChangeItem($event)"
          [seDebounceFilterChange]="150"
          [seUseCustomLocalFilter]="true"
          [seMinSearchLength]="0"
          [loading]="isLoading"
        >
          <ng-template kendoMultiSelectItemTemplate let-dataItem>
            <div
              style="display: inline-flex"
              [hidden]="dataItem?.DisplayName === config?.placeholder"
            >
              <span (click)="toggleFavoriteFacility(dataItem, $event)">
                <i
                  class="fas fa-star"
                  title="Add/Remove Favorite Facility"
                  [ngStyle]="{
                    color: dataItem.IsUserFavorite ? 'orange' : 'lightgrey'
                  }"
                ></i>
                &nbsp;
              </span>
              <div>
                <span *ngIf="dataItem.DisplayName" class="job-name link-style">
                  {{ dataItem.DisplayName }}
                </span>
                <div *ngIf="dataItem.Address" class="clearBoth">
                  <div style="display: inline-block">
                    {{ dataItem.Address }}
                  </div>
                </div>
                <div class="clearBoth">
                  <div style="display: inline-block">
                    {{ dataItem.City || 'n/a' }}
                  </div>
                  <div style="display: inline-block">
                    {{ dataItem.State ? ', ' + dataItem.State : '' }}
                  </div>
                  <div style="display: inline-block">
                    {{ dataItem.Zip ? ' ' + dataItem.Zip : '' }}
                  </div>
                </div>
                <!-- <div class="clearBoth">
                  <div style="display:inline-block;">{{dataItem.DisplayName || 'n/a'}}</div>
                </div>
                <div class="clearBoth">
                  <div style="display:inline-block;width:120px;">Parent:</div>
                  <div style="display:inline-block;">{{dataItem.ParentCustomerName || 'n/a'}}</div>
                </div> -->
              </div>
            </div>
          </ng-template>
          <ng-template kendoMultiSelectValueTemplate let-dataItem>
            <div style="display: inline-flex">
              <div kendoTooltip [title]="dataItem?.DisplayName">
                <span class="job-name">{{ dataItem.DisplayName }}</span>
              </div>
            </div>
          </ng-template>
        </kendo-multiselect>
      </ng-template>
    </div>
    <div
      class="form-builder-mask"
      [class.hidden]="config?.options?.hidden"
      *ngIf="config?.isFormBuilder"
    ></div>
  </div>
  <div class="form-builder-options" *ngIf="config?.isFormBuilder">
    <i class="fas fa-times ms-3 cursor-pointer c-red" (click)="delete()"></i>
    <!-- <i class="fas fa-bars ms-3 cursor-grab"></i> -->
  </div>
</div>
