/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import { BaseModel } from '../base.model';
import { AddressModel } from '../sparta-api/address-model';

export class PersonModel extends BaseModel {
  public static get DATE_REGEX(): string {
    return '[0-9]{2}[/][0-9]{2}[/][0-9]{4}$';
  }

  IsProspect = false;
  AlternatePhone: any = '';
  BirthDate: any = '';
  TermDate: Date = null;
  Phone: any = '';
  CellPhone: any = '';
  Class: any = '';
  Company: any = '';
  Designation: any = '';
  Email: any = '';
  EmployeeId: any = '';
  FirstName: any = '';
  Suffix: any = '';
  HRRef: any = '';
  LastName: any = '';
  MiddleName: any = '';
  ProspectId: any = '';
  SSN: any = '';
  PhysicalAddress: AddressModel = new AddressModel();
  MailingAddress: AddressModel = new AddressModel();
  PossibleCompanies: any[] = [];
  IsNoRehire = false;
  RehireStatus: any = '';

  Person: any;

  private _BirthDateString = '';
  set BirthDateString(value: any) {
    this._BirthDateString = value;
    this.setDateFromString(this.BirthDate, value);
  }

  get BirthDateString(): any {
    if (
      this.BirthDate !== undefined &&
      this.BirthDate != null &&
      typeof this.BirthDate === 'object'
    ) {
      const s = this.dateToString(this.BirthDate);
      return s;
    }
    return '';
  }

  constructor(o: any = null) {
    super(o);
    if (o) {
      Object.assign(this, o);

      this.MailingAddress = new AddressModel(this.MailingAddress);
      this.PhysicalAddress = new AddressModel(this.PhysicalAddress);
      if (this.Email) this.Email = this.Email.trim();
      this.CellPhone = this.formatPhoneNumber(this.CellPhone);
      this.Phone = this.formatPhoneNumber(this.Phone);
    }
  }

  setDateFromString(property: any, value: any): void {
    if (value !== undefined) {
      if (typeof value === 'object') {
        property = value;
      } else if (
        typeof value === 'string' &&
        value.search(new RegExp(PersonModel.DATE_REGEX)) > -1
      ) {
        try {
          const d = new Date(value);
          property = d;
        } catch (error) {
          console.log(error);
        }
      }
    }
  }

  dateToString(d: Date): string {
    const dd = d.getUTCDate();
    let ddd;
    const mm = d.getUTCMonth() + 1; // January is 0!
    let mmm;
    const yyyy = d.getUTCFullYear();
    if (dd < 10) {
      ddd = '0' + dd;
    } else {
      ddd = dd.toString();
    }
    if (mm < 10) {
      mmm = '0' + mm;
    } else {
      mmm = mm.toString();
    }
    const s = mmm + '/' + ddd + '/' + yyyy;

    return s;
  }

  formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }
}
