import { Directive, NgZone } from '@angular/core';

import {
  BaseComponent,
  environment,
  LogService,
  NetworkService,
  OfflineHttpTrackingService,
  WindowService,
} from '@ups/xplat/core';
import { FeatureSwitch, timecodeDisplay } from '@ups/xplat/utils';
import { filter, takeUntil } from 'rxjs/operators';

@Directive()
export abstract class OfflineBannerBaseComponent extends BaseComponent {
  showBanner = false;
  hideNow = false;
  offline = false;
  offlineMessage;
  statusUpdates: string;
  minimized = false;
  private _offlineMessage = `You are not currently connected to the internet.`;
  private _offlineSomeFeatures = `Some features may be temporarily unavailable.`;
  private _reconnectedMessage = `You are back online.`;
  private _hideBannerTimeout;
  private _currentCountdown: number;

  constructor(
    public network: NetworkService,
    protected win: WindowService,
    protected ngZone: NgZone,
    protected log: LogService,
    protected offlineHttpTracking: OfflineHttpTrackingService
  ) {
    super();
    if (environment.offline?.enabled) {
      if (this.network.isOffline) {
        this._offlineStateHandler(true);
      }
      this.network.offline$.subscribe(this._offlineStateHandler.bind(this));
      this.offlineHttpTracking.statusUpdates$
        .pipe(filter((status) => !!status))
        .subscribe((updates) => {
          const statusMessages = [];
          if (updates.pending) {
            statusMessages.push(`Requests processing: ${updates.pending}`);
            if (updates.complete) {
              statusMessages.push(`Completed: ${updates.complete}`);
            }
          }
          if (updates.errored) {
            statusMessages.push(`Errors: ${updates.errored}`);
          }
          this.statusUpdates = statusMessages.join(', ');
          if (!this.statusUpdates && !this.network.isOffline) {
            // no more status updates, hide banner
            this._hideBanner();
          }
        });
    }
  }

  private _offlineStateHandler(offline: boolean) {
    if (this.offline && !offline) {
      // when coming back online from offline, show success and then hide after a moment
      this.offlineMessage = this._reconnectedMessage;
      this._resetHideBannerTimeout();

      // check for queued requests to fulfill and add to message so user knows
      this.offlineHttpTracking
        .getPendingOfflineRequests()
        .then((pendingRequests) => {
          if (pendingRequests && pendingRequests.length === 0) {
            // no pending requests to show status on, just hide banner
            this._hideBanner(1000);
          }
        });
    }
    this.offline = offline;
    if (offline) {
      this._resetHideBannerTimeout();
      if (!this.showBanner) {
        this.offlineMessage = this._offlineMessage;
        this.showBanner = true;
        if (this.win.isBrowser || FeatureSwitch.enableMobileOfflineLimits) {
          this.network.offlineTimer$
            .pipe(takeUntil(this.network.offlineCountdownComplete$))
            .subscribe({
              next: (countdown) => {
                this._currentCountdown = countdown;
                if (this.offline) {
                  // console.log(timecodeDisplay(this._currentCountdown/1000))
                  this.offlineMessage = `${
                    this._offlineMessage
                  } You have ${timecodeDisplay(
                    this._currentCountdown / 1000
                  )} to finish your work while offline.`;
                }
              },
              error: (err) => {
                this.log.debug('offlineTimer ERROR sub:', err);
              },
              complete: () => {
                this.log.debug(
                  'offlineTimer COMPLETE with currentCountdown (ms):',
                  this._currentCountdown
                );
                if (this.offline) {
                  // user is still offline but they can no longer use offline features as time limit expired
                  this.ngZone.run(() => {
                    this.offlineMessage = `${this._offlineMessage} ${this._offlineSomeFeatures}`;
                  });
                }
              },
            });
        }
      }
    }
  }

  private _hideBanner(timeout = 3500) {
    this._resetHideBannerTimeout();
    this._hideBannerTimeout = setTimeout(() => {
      this.hideNow = true;
      this._hideBannerTimeout = setTimeout(() => {
        this.showBanner = false;
        this.hideNow = false;
      }, 500);
    }, timeout);
  }

  private _resetHideBannerTimeout() {
    if (typeof this._hideBannerTimeout === 'number') {
      clearTimeout(this._hideBannerTimeout);
      this._hideBannerTimeout = null;
    }
  }
}
