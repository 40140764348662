import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { DynamicRenderService } from '@ups/xplat/features';

@Component({
  selector: 'ups-dr-container-picker',
  template: `
    <kendo-dropdownlist
      #dropdown
      [defaultItem]="defaultItem"
      [data]="list"
      [textField]="'dynamicContainerName'"
      [filterable]="true"
      [valueField]="'dynamicContainerIdAndVersion'"
      (valueChange)="valueChange($event)"
      search-ex
      [seSearchColumns]="['dynamicContainerName']"
      (seFilterChangeEx)="filterChangeItem($event)"
      [seDebounceFilterChange]="150"
      [seUseCustomLocalFilter]="true"
      [seMinSearchLength]="0"
      class="w-350"
    >
      <ng-template kendoDropDownListItemTemplate let-dataItem>
        <div
          style="display: inline-flex;"
          [hidden]="dataItem?.dynamicContainerName === config?.placeholder"
        >
          <div>
            <span>{{ dataItem.dynamicContainerName }}</span>
            <span style="margin-left:1px;color:#cacaca;">
              v{{ dataItem.versionNumber }}
            </span>
            <span
              style="font-weight:bold;color:green;margin-left:4px;"
              [hidden]="!dataItem.isPublished || !dataItem.isActive"
            >
              Published
            </span>
          </div>
        </div>
      </ng-template>
      <ng-template kendoDropDownListValueTemplate let-dataItem>
        <div style="display: inline-flex;">
          <div kendoTooltip [title]="dataItem?.dynamicContainerName">
            <span>{{ dataItem.dynamicContainerName }}</span>
            <span
              style="margin-left:5px;color:#cacaca;"
              [hidden]="dataItem?.dynamicContainerName === config?.placeholder"
            >
              {{ dataItem.versionNumber ? 'v' + dataItem.versionNumber : '' }}
            </span>
            <span
              style="font-weight:bold;color:green;margin-left:6px;"
              [hidden]="!dataItem.isPublished || !dataItem.isActive"
            >
              Published
            </span>
          </div>
        </div>
      </ng-template>
    </kendo-dropdownlist>
  `,
})
export class DrContainerPickerComponent implements AfterViewInit {
  @Input() list: Array<unknown>;
  @Output() containerSelected: EventEmitter<unknown> = new EventEmitter();

  @Input() defaultItem: unknown;
  @ViewChild('dropdown') dropdown: DropDownListComponent;
  @Output()
  loaded: EventEmitter<DrContainerPickerComponent> = new EventEmitter();

  constructor(private dynamicRender: DynamicRenderService) {}

  ngAfterViewInit() {
    this.loaded.emit(this);
  }

  clear() {
    // this helps ensure the dropdown resets back to placeholder
    this.setValue(null);
  }

  setValue(value: unknown) {
    if (this.dropdown) {
      this.dropdown.value = value;
    }
  }

  valueChange(value: unknown): void {
    this.containerSelected.emit(value);
  }

  filterChangeItem($event: {
    query?: string;
    extension?: { applyData?: (res: unknown) => void };
  }) {
    return new Promise((resolve) => {
      const results = this.dynamicRender.dynamicContainerListAll.filter(
        (item) => {
          if ($event.query) {
            if (item.dynamicContainerName) {
              return (
                item.dynamicContainerName
                  .toLowerCase()
                  .indexOf($event.query.toLowerCase()) > -1
              );
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
      );
      if ($event.extension) {
        $event.extension.applyData(results);
      }

      resolve(results);
    });
  }
}
