import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import {
  FitSkillEmployeeExpirationDto,
  IODataResult,
} from '@ups/xplat/api/dto';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class FitTestCertificationService {
  protected http: HttpClient;

  constructor(
    private clientFactory: MyHttpClientFactory,
    private dateFormatPipe: DatePipe
  ) {
    this.http = this.clientFactory.createHttpClient(
      environment.urls.spartaAPI,
      true,
      ResponseCasingEnum.PascalCase
    );
  }

  public getEmployeeFitCertUpcomingExpirations(
    fromExpireDate?: Date,
    toExpireDate?: Date,
    vpCompanyIdsFilter?: number[],
    vpJobIdsFilter?: string[],
    daysToNotifyBeforeExpire: number = 31,
    oDataString?: string
  ): Observable<IODataResult<FitSkillEmployeeExpirationDto>> {
    let url = 'api/fitskill/expirations-odata?';

    const queryParameters: string[] = [];

    if (fromExpireDate) {
      queryParameters.push(
        'fromExpireDate=' +
          this.dateFormatPipe.transform(fromExpireDate, 'MM/dd/yyyy')
      );
    }

    if (toExpireDate) {
      queryParameters.push(
        'toExpireDate=' +
          this.dateFormatPipe.transform(fromExpireDate, 'MM/dd/yyyy')
      );
    }

    if (vpCompanyIdsFilter && vpCompanyIdsFilter.length > 0) {
      vpCompanyIdsFilter.forEach((vpCompanyId) => {
        queryParameters.push('vpCompanyIdsFilter=' + vpCompanyId);
      });
    }

    if (vpJobIdsFilter && vpJobIdsFilter.length > 0) {
      vpJobIdsFilter.forEach((vpJobId) => {
        queryParameters.push('vpJobIdsFilter=' + vpJobId);
      });
    }

    queryParameters.push(
      'daysToNotifyBeforeExpire=' + daysToNotifyBeforeExpire
    );

    queryParameters.push('$count=true');
    queryParameters.push('$format=json');

    if (oDataString) {
      oDataString = oDataString
        ?.replace('filter()/', '')
        .replace('filter()', ''); // workaround as for some reason it generates wong oDataQuery if the searchFilter is cleared.
      queryParameters.push(oDataString);
    }

    url = url + queryParameters.join('&');

    return this.http.get(url) as Observable<
      IODataResult<FitSkillEmployeeExpirationDto>
    >;
  }
}
