<div id="horizontalForm">
    <div *ngIf="isForm == true">
        <fieldset [formGroup]="addressForm">
            <legend>
                {{addressType}}
            </legend>
            <label class="first" for="street1">
                Street Line 1
                <input id="street1" type="text" class="form-control" formControlName="Street1"/>
            </label>
            <label class="first" for="street2">
                Street Line 2
                <input id="street2" type="text" class="form-control" formControlName="Street2"/>
            </label>
            <label for="city1">
                City
                <input id="city1" type="text" class="form-control" formControlName="City"/>
            </label>
            <label for="state1">
                State
                <input id="state1" type="text" class="form-control" formControlName="State"/>
            </label>
            <label for="postcode1">
                Zip Code
                <input id="postcode1" type="text" class="form-control" formControlName="PostCode"/>
            </label>
        </fieldset>
    </div>
    <fieldset id="horizontalForm" *ngIf="isForm == false">
        <legend>
            {{addressType}}
        </legend>
        <div>{{street1}}</div>
        <div *ngIf="street2">{{street2}}</div>
        <div>{{city}}, {{stateAbbrev}}, {{zip}}</div>
    </fieldset>
</div>