import { BaseModel } from '../base.model';

export class UpdateEmployeeROFStateDto extends BaseModel {
  PRCo: number;
  EmployeeVPID: number;
  ROFDate: Date;
  ROFReason: string;

  constructor(o: any = null) {
    super(o);
  }
}
