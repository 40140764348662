import { IEmployeeInfo } from '../models/users/employee-info.interface';

/**
 * Get user full name based on available data
 * @param info IEmployeeInfo
 * @returns full name
 */
export function userFullName(info: IEmployeeInfo) {
  const lastName = info.Data.LastName;
  return (
    info.Data.FullName ||
    (info.Data.FirstName
      ? `${info.Data.FirstName}${lastName ? ' ' + lastName : ''}`
      : lastName || 'n/a')
  );
}
