export class EquipmentCorrectionsDto {
  public Amount: number;
  public BillRate: number;
  public DateUSed: Date;
  public WeekEndDate: Date;
  public Equipment: string;
  public AltID: string;
  public EquipmentUsageID: string;
  public EquipmentDispatchID: string;
  public Phase: string;
  public Shift: number;
  public UnitName: string;
  public ReasonFlag: number;
  public Reason: string;
  public TransactionType: string;
  public DateCreated: Date;
  public CreatedBy: string;
  public VPJobID: string;
  public JobName: string;
  public VPCompanyID: number;
  public SupervisorName: string;
  public ClientCost: number;
  public TimeSheetName: string;
  public TimeSheetNotes: string;
  public TimeSheetID: string;
}
