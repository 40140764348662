<div class="dynamic-field-container" [class.selected]="config?.selected">
  <div
    class="dynamic-field form-input text-align"
    [formGroup]="group"
    [hidden]="!config?.isFormBuilder && config?.options?.hidden"
  >
    <ups-dynamic-helper-label
      [config]="config"
      [dynamicService]="dynamicService"
    ></ups-dynamic-helper-label>
    <div class="job-search-container" style="position: relative">
      <ng-template [ngIf]="!config?.options?.isMultiSelect">
        <kendo-dropdownlist
          #dropdown
          class="k-no-dropdown w-100"
          [attr.name]="config.formControlName"
          [formControlName]="config.formControlName"
          [valuePrimitive]="false"
          [textField]="'FullName'"
          [valueField]="'FullName'"
          [defaultItem]="{ FullName: config?.placeholder }"
          [filterable]="true"
          (valueChange)="valueChangeItem($event)"
          search-ex
          [seSearchColumns]="['FullName', 'Email']"
          (seFilterChangeEx)="filterChangeItem($event)"
          [seDebounceFilterChange]="150"
          [seUseCustomLocalFilter]="true"
          [seMinSearchLength]="0"
          [loading]="isLoading"
        >
          <ng-template kendoDropDownListItemTemplate let-dataItem>
            <div
              style="display: inline-flex"
              [hidden]="dataItem?.FullName === config?.placeholder"
            >
              <div>
                <span *ngIf="dataItem.Name" class="job-name link-style">
                  {{ dataItem.FullName }} : XXX-XX-{{ dataItem.Last4SSN }}
                </span>
                <div class="clearBoth">
                  <div style="display: inline-block; width: 120px">HR Ref:</div>
                  <div style="display: inline-block">
                    {{ dataItem.HRRef }}
                  </div>
                </div>
                <div *ngIf="dataItem.CellPhone" class="clearBoth">
                  <div style="display: inline-block; width: 120px">
                    Cell Phone:
                  </div>
                  <div style="display: inline-block">
                    {{
                      showCellMask(config)
                        ? '(XXX) XXX - XXXX'
                        : dataItem.CellPhone
                    }}
                  </div>
                </div>
                <div class="clearBoth">
                  <div style="display: inline-block; width: 120px">
                    EM Designation:
                  </div>
                  <div style="display: inline-block">
                    <span
                      *ngFor="
                        let i of getEMDesignationList(dataItem);
                        last as isLast;
                        count as c
                      "
                      [ngClass]="{ 'font-weight-bold': c > 1 && i.IsActive }"
                    >
                      {{ i.EMDesignationName }}{{ isLast ? '' : ',' }}
                    </span>
                  </div>
                </div>
                <div class="clearBoth">
                  <div style="display: inline-block; width: 120px">
                    File Class:
                  </div>
                  <div style="display: inline-block">
                    <span
                      *ngFor="
                        let i of getFileClassList(dataItem);
                        last as isLast;
                        count as c
                      "
                      [ngClass]="{ 'font-weight-bold': c > 1 && i.IsActive }"
                    >
                      {{ i.FileClassName }}{{ isLast ? '' : ',' }}
                    </span>
                  </div>
                </div>
                <div class="clearBoth">
                  <div style="display: inline-block; width: 120px">
                    Company:
                  </div>
                  <div style="display: inline-block">
                    <span
                      *ngFor="
                        let i of getCompaniesList(dataItem);
                        last as isLast;
                        count as c
                      "
                      [ngClass]="{ 'font-weight-bold': c > 1 && i.IsActive }"
                    >
                      {{ i.Company }}{{ isLast ? '' : ',' }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <div style="display: inline-flex">
              <div kendoTooltip [title]="dataItem?.FullName">
                <span class="job-name">{{ dataItem.FullName }}</span>
              </div>
            </div>
          </ng-template>
        </kendo-dropdownlist>
      </ng-template>
      <ng-template [ngIf]="config?.options?.isMultiSelect">
        <kendo-multiselect
          [attr.name]="config.formControlName"
          [formControlName]="config.formControlName"
          class="k-no-dropdown w-100"
          [defaultItem]="{ FullName: config?.placeholder }"
          [valuePrimitive]="false"
          [textField]="'FullName'"
          [valueField]="'FullName'"
          [placeholder]="config?.placeholder"
          [filterable]="true"
          (valueChange)="valueChangeItem($event)"
          search-ex
          [seSearchColumns]="['FullName', 'Email']"
          (seFilterChangeEx)="filterChangeItem($event)"
          [seDebounceFilterChange]="150"
          [seUseCustomLocalFilter]="true"
          [seMinSearchLength]="0"
          [loading]="isLoading"
        >
          <ng-template kendoMultiSelectItemTemplate let-dataItem>
            <div
              style="display: inline-flex"
              [hidden]="dataItem?.FullName === config?.placeholder"
            >
              <div>
                <span *ngIf="dataItem.Name" class="job-name link-style">
                  {{ dataItem.FullName }} : XXX-XX-{{ dataItem.Last4SSN }}
                </span>
                <div class="clearBoth">
                  <div style="display: inline-block; width: 120px">HR Ref:</div>
                  <div style="display: inline-block">
                    {{ dataItem.HRRef }}
                  </div>
                </div>
                <div *ngIf="dataItem.CellPhone" class="clearBoth">
                  <div style="display: inline-block; width: 120px">
                    Cell Phone:
                  </div>
                  <div style="display: inline-block">
                    {{ dataItem.CellPhone }}
                  </div>
                </div>
                <div class="clearBoth">
                  <div style="display: inline-block; width: 120px">
                    EM Designation:
                  </div>
                  <div style="display: inline-block">
                    <span
                      *ngFor="
                        let i of getEMDesignationList(dataItem);
                        last as isLast;
                        count as c
                      "
                      [ngClass]="{ 'font-weight-bold': c > 1 && i.IsActive }"
                    >
                      {{ i.EMDesignationName }}{{ isLast ? '' : ',' }}
                    </span>
                  </div>
                </div>
                <div class="clearBoth">
                  <div style="display: inline-block; width: 120px">
                    File Class:
                  </div>
                  <div style="display: inline-block">
                    <span
                      *ngFor="
                        let i of getFileClassList(dataItem);
                        last as isLast;
                        count as c
                      "
                      [ngClass]="{ 'font-weight-bold': c > 1 && i.IsActive }"
                    >
                      {{ i.FileClassName }}{{ isLast ? '' : ',' }}
                    </span>
                  </div>
                </div>
                <div class="clearBoth">
                  <div style="display: inline-block; width: 120px">
                    Company:
                  </div>
                  <div style="display: inline-block">
                    <span
                      *ngFor="
                        let i of getCompaniesList(dataItem);
                        last as isLast;
                        count as c
                      "
                      [ngClass]="{ 'font-weight-bold': c > 1 && i.IsActive }"
                    >
                      {{ i.Company }}{{ isLast ? '' : ',' }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template kendoMultiSelectValueTemplate let-dataItem>
            <div style="display: inline-flex">
              <div kendoTooltip [title]="dataItem?.FullName">
                <span class="job-name">{{ dataItem.FullName }}</span>
              </div>
            </div>
          </ng-template>
        </kendo-multiselect>
      </ng-template>
    </div>
    <div
      class="form-builder-mask"
      [class.hidden]="config?.options?.hidden"
      *ngIf="config?.isFormBuilder"
    ></div>
  </div>
  <div class="form-builder-options" *ngIf="config?.isFormBuilder">
    <i class="fas fa-times ms-3 cursor-pointer c-red" (click)="delete()"></i>
    <!-- <i class="fas fa-bars ms-3 cursor-grab"></i> -->
  </div>
</div>
