import {
  Component,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropDownSearchExtensionDirective } from '../../../kendo/DropDowns/dropdowns-search.ex-directive';
import { JobDropdown, ProfileModel } from '@ups/xplat/api/dto';
import { JobSearchService } from './job-search.service';
import { noop } from '@ups/xplat/utils';
import { JobSearchVPService } from './job-search-vp.service';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { BaseComponent } from '@ups/xplat/core';

export const JOB_SEARCH_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => JobSearchComponent),
  multi: true,
};

@Component({
  selector: 'ups-job-search',
  templateUrl: './job-search.component.html',
  styleUrls: ['./job-search.component.scss'],
  providers: [JobSearchVPService, JOB_SEARCH_CONTROL_VALUE_ACCESSOR],
})
export class JobSearchComponent
  extends BaseComponent
  implements ControlValueAccessor, OnInit, OnChanges
{
  @Output() valueChange: EventEmitter<JobDropdown> =
    new EventEmitter<JobDropdown>();
  @Output() valuesChange: EventEmitter<JobDropdown[]> = new EventEmitter<
    JobDropdown[]
  >();

  selectedJob: JobDropdown;
  selectedJobs: JobDropdown[];

  @Input() disabled = false;

  @Input() includeClosedJobs = false;
  @Input() showClosedJobsCheckbox = true;

  @Input() isMultiSelect = false;
  @Input() showMultiSelectCheckbox = false;

  @Input() showFavoritesButton = true;
  @Input() showFacilityTooltip = true;
  @Input() useDefaultJobFeature = true;

  @Input() placeholder = 'Search for Jobs...';

  @Input() companyIDs: number[] = [];
  @Input() hrRef: string;

  @Input() pointToViewpoint = false;

  @ViewChild(DropDownSearchExtensionDirective)
  searchEx: DropDownSearchExtensionDirective;

  isJobActive = false;
  profile: ProfileModel;

  filterDebounce: Subject<unknown> = new Subject<unknown>();

  touchedCallback: () => void = noop;
  changeCallback: (_: unknown) => void = noop;

  get value() {
    return this.selectedJob;
  }

  set value(v: JobDropdown) {
    if (v !== this.selectedJob) {
      this.selectedJob = v;
      this.changeCallback(v);
    }
  }

  constructor(
    private jobSearchService: JobSearchService,
    private jobSearchVPService: JobSearchVPService
  ) {
    super();
    this.profile = JSON.parse(localStorage.getItem('profile')) as ProfileModel;

    this.filterDebounce
      .pipe(debounceTime(500), takeUntil(this.destroy$))
      .subscribe((f) => this.filterChangeJob(f));
  }

  async ngOnInit() {
    if (this.useDefaultJobFeature) {
      await this.jobSearchService.getDefaultJob(
        this.profile.auth0UserId,
        this.hrRef || this.profile.hrRef
      );

      this.jobSearchService.defaultJob
        .pipe(takeUntil(this.destroy$))
        .subscribe((job) => {
          this.valueChangeJob(job, false);
        });
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.hrRef && !changes.hrRef.firstChange) {
      await this.jobSearchService.getDefaultJob(
        this.profile.auth0UserId,
        this.hrRef
      );
    }
  }

  onBlur() {
    this.touchedCallback();
  }

  writeValue(value: JobDropdown) {
    if (value !== this.selectedJob) {
      this.selectedJob = value;
      this.selectedJobs = [value];
    }
  }

  registerOnChange(fn: (_: unknown) => void) {
    this.changeCallback = fn;
  }

  registerOnTouched(fn: () => void) {
    this.touchedCallback = fn;
  }

  clear() {
    this.selectedJob = null;
    this.selectedJobs = [];
    this.searchEx.clearDropdownItems();
  }

  filterChangeJob($event) {
    const service = this.pointToViewpoint
      ? this.jobSearchVPService
      : this.jobSearchService;
    service
      .searchJobs(
        $event.query,
        this.companyIDs,
        this.hrRef,
        this.includeClosedJobs ? null : true
      )
      .then((data) => {
        $event.extension.applyData(data);
      })
      .catch((error) => {
        $event.extension.applyData([]);
        throw error;
      });
  }

  valueChangeJobs(jobs: JobDropdown[]) {
    this.selectedJobs = jobs;
    if (jobs && jobs.length) {
      this.valueChangeJob(jobs[0]);
    } else {
      this.valueChangeJob(null);
    }
  }

  async valueChangeJob(job: JobDropdown, saveDefault: boolean = true) {
    this.selectedJob = job;

    if (!this.isMultiSelect) {
      if (job) {
        this.selectedJobs = [job];
      } else {
        this.selectedJobs = [];
      }
    }

    if (this.useDefaultJobFeature && saveDefault && job) {
      await this.jobSearchService.saveDefaultJob(job.JobID);
    }

    this.valueChange.emit(job);
    this.valuesChange.emit(this.selectedJobs);
    this.isJobActive = job && job.IsActive ? true : false;
  }

  onMultiSelectCheckboxChange() {
    if (!this.isMultiSelect) {
      this.valueChangeJob(this.selectedJob);
    }
  }

  toggleFavorite(job: JobDropdown, e: PointerEvent) {
    job.IsUserFavorite = !job.IsUserFavorite;
    if (this.pointToViewpoint) {
      this.jobSearchVPService.addOrRemoveFavoriteJob(job.JobID);
    } else {
      this.jobSearchService.addOrRemoveFavoriteJob(job.Id);
    }
    e.stopPropagation();
  }
}
