/* eslint-disable */
import {
  Component,
  EventEmitter,
  Input,
  Output,
  Inject,
  OnInit,
} from '@angular/core';
import { ColumnBase, GridComponent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import { BaseComponent, TableviewService } from '@ups/xplat/core';
import {
  SecurityConstants,
  SecurityFeatureModel,
  SecurityService,
} from '@ups/security';
import {
  TableviewCustomOption,
  TableviewModel,
  TableviewShareModel,
} from '@ups/xplat/api/dto';
import { ManPowerService } from '@ups/xplat/base/manpower';
import { MessageHelper } from '@ups/xplat/web/core';
import * as _ from 'lodash';
import { catchError, filter, of } from 'rxjs';

@Component({
  selector: 'ups-tableview',
  templateUrl: 'tableview.component.html',
})
export class TableviewComponent extends BaseComponent implements OnInit {
  @Input() key: string;
  @Input() state: State;
  @Input() securityFeature: SecurityFeatureModel;
  @Input() excludeFromColConfig: string[] = [];
  @Input() showAsDropdown = true;
  @Input() customOptions: TableviewCustomOption[] = [];
  @Input() customSettings: unknown;
  @Output() stateChanged = new EventEmitter<State>();
  @Output() customSettingsChange = new EventEmitter<unknown>();

  tableViewToEdit: TableviewModel;
  tableViewToShare: TableviewModel;
  isSaving = false;
  tableViews: TableviewModel[] = [];
  bSaveGrouping = true;
  bSaveFiltering = true;
  bSaveSorting = true;
  bSaveColConfig = true;
  bPreSelected = true;
  bSaveItemsPerPage = true;
  preSelectedTableViewName = 'Default';
  selectedTableView: TableviewModel;
  defaultTableView: TableviewModel;
  sharesLoading = false;

  showTableViewDialog = false;
  showSaveTableViewDialog = false;
  showShareDialog = false;

  defaultGroup = [];
  defaultFilter = <CompositeFilterDescriptor>{ logic: 'and', filters: [] };
  defaultSort = [];
  defaultTake = 10;

  canSetDefaults = false;
  canEditAll = false;
  canEditSelf = false;
  canShare = true;

  dangerAcknowledged = false;

  private securityConstants = SecurityConstants;
  menuData = [];

  selectedEmployee;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    @Inject(GridComponent) private grid: GridComponent = null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private securityService: SecurityService,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private tableViewService: TableviewService,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private manPowerService: ManPowerService,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private msgHelper: MessageHelper
  ) {
    super();
  }

  ngOnInit() {
    this.loadTableViews();
    const ls = localStorage.getItem(this.key);
    if (ls) {
      this.preSelectedTableViewName = ls;
    } else {
      localStorage.setItem(this.key, 'Default');
    }
    this.canSetDefaults = this.securityService.getFeatureById(
      SecurityConstants.employee_portal_supers_savedefaulttableviews
    ).editAll;
    this.canEditAll =
      this.securityFeature &&
      (this.securityFeature.editAll || this.securityFeature.editSelf);

    this.createMenuButtons();
  }

  createMenuButtons() {
    this.menuData = [
      {
        text: 'Show Views',
        icon: 'eye',
        disabled: false,
        click: () => {
          this.open();
        },
      },
      {
        text: 'Save View',
        icon: 'save',
        disabled: !this.isSaveEnabled(),
        click: () => {
          this.openSave();
        },
      },
      {
        text: 'Create View',
        icon: 'plus',
        disabled: !this.canEditAll && !this.canEditSelf,
        click: () => {
          this.openCreate();
        },
      },
    ];
  }

  loadTableViews() {
    if (this.securityFeature) {
      this.securityFeature.editAll =
        this.securityFeature.editAll || this.securityFeature.editAll;
      this.securityFeature.editSelf =
        this.securityFeature.editSelf || this.securityFeature.editSelf;
      this.securityFeature.viewFeature =
        this.securityFeature.viewFeature || this.securityFeature.viewFeature;
    }

    this.tableViewService
      .getTableviews(this.key)
      .pipe(
        catchError(() => {
          return of(null);
        }),
        filter((r) => !!r)
      )
      .subscribe((data) => {
        this.tableViews = data
          ? data.sort((a, b) =>
              a.Name === 'Default' || a.Name < b.Name ? -1 : 1
            )
          : [];
        this.selectedTableView = _.find(this.tableViews, {
          Name: this.preSelectedTableViewName,
        });
        this.defaultTableView = _.find(this.tableViews, { Name: 'Default' });
        if (!this.selectedTableView)
          this.selectedTableView = this.defaultTableView;
        if (this.selectedTableView) {
          this.loadState(this.selectedTableView);
        } else {
          this.createDefaultView();
          this.stateChanged.emit(this.state);
          this.customSettingsChange.emit(this.customSettings);
        }
      });
  }

  open() {
    this.showTableViewDialog = true;
  }

  openCreate() {
    this.createNewTableView();
    this.showSaveTableViewDialog = true;
  }

  isSaveEnabled() {
    if (!this.selectedTableView) return false;
    if (this.selectedTableView.bShared) return false;
    if (this.selectedTableView.bDefault) return this.canSetDefaults;
    if (
      !this.securityFeature ||
      (!this.securityFeature.editAll && !this.securityFeature.editSelf)
    )
      return false;
    return (
      this.securityFeature.editAll ||
      (this.securityFeature.editSelf && !this.selectedTableView.bPublic)
    );
  }

  openSave() {
    this.tableViewToEdit = this.selectedTableView;
    this.showSaveTableViewDialog = true;
  }

  createDefaultView() {
    this.createNewTableView();
    this.tableViewToEdit.Name = 'Default';
    this.tableViewToEdit.bDefault = true;
    this.tableViewToEdit.bPublic = true;
    this.bSaveColConfig = true;
    this.saveGridSettings();
  }

  createNewTableView() {
    this.tableViewToEdit = new TableviewModel({
      Name: '',
      Key: this.key,
      bActive: true,
      bPublic: false,
    });
  }

  public saveGridSettings(): void {
    if (!this.tableViewToEdit.TableViewID) {
      const existing = _.find(this.tableViews, {
        Name: this.tableViewToEdit.Name,
        bActive: true,
      });
      if (existing) {
        this.msgHelper.error('Duplicate Table View Name!');
        return;
      }
    }

    this.tableViewToEdit.Definition = JSON.stringify(this.getGridConfig());

    this.isSaving = true;
    this.tableViewService
      .createTableView(this.tableViewToEdit)
      .pipe(
        catchError(() => {
          this.isSaving = false;
          return of(null);
        }),
        filter((r) => !!r)
      )
      .subscribe((data) => {
        this.isSaving = false;
        if (this.tableViewToEdit.TableViewID == null) {
          const temp = this.tableViews;
          temp.push(data);
          this.tableViews = null;
          setTimeout(() => {
            this.tableViews = temp;
            this.selectedTableView = data;
            this.createMenuButtons();
          }, 100);
        }
        this.tableViewToEdit = new TableviewModel();

        if (this.bPreSelected) {
          this.preSelect(data);
        }

        this.bPreSelected = true;
        this.bSaveGrouping = true;
        this.bSaveFiltering = true;
        this.bSaveSorting = true;
        this.bSaveColConfig = true;
        this.bSaveItemsPerPage = true;

        if (data.Name === 'Default') {
          this.defaultTableView = data;
          this.defaultTableView.Definition = JSON.stringify(
            this.getGridConfig()
          );
        }
        this.stateChanged.emit(this.state);
        this.showSaveTableViewDialog = false;
      });
  }

  getGridConfig() {
    let colConfig = [];
    if (this.grid && this.bSaveColConfig) {
      const columns = this.grid.columns;
      let colConfigColumns = columns
        .toArray()
        .filter(
          (col) =>
            col['field'] &&
            (col.width || col.orderIndex !== 0) &&
            !this.isExcludedCol(col['field'])
        );
      let groupOrder = colConfigColumns.length;
      columns
        .filter((c) => c.isColumnGroup === true)
        .forEach((cg) => {
          const groupColConfigColumns = cg['childrenArray'].filter(
            (col) =>
              col['field'] &&
              (col.width || col.orderIndex !== 0) &&
              !this.isExcludedCol(col['field'])
          );
          for (const gcc of groupColConfigColumns) {
            gcc.orderIndex = groupOrder++;
          }
          colConfigColumns = colConfigColumns.concat(groupColConfigColumns);
        });
      colConfig = colConfigColumns.map((col) => ({
        field: col['field'],
        width: col.width,
        orderIndex: col.orderIndex,
        isVisible: col.isVisible,
      }));
      colConfig.sort((a, b) =>
        a.orderIndex < b.orderIndex ? -1 : a.orderIndex > b.orderIndex ? 1 : 0
      );

      // to set correct order when columns are hidden
      let order = 0;
      for (const c of colConfig) {
        if (c.isVisible) c.orderIndex = ++order;
      }
      for (const c of colConfig) {
        if (!c.isVisible) c.orderIndex = ++order;
      }
      colConfig.sort((a, b) =>
        a.orderIndex < b.orderIndex ? -1 : a.orderIndex > b.orderIndex ? 1 : 0
      );
    }

    return {
      group: this.bSaveGrouping
        ? this.state.group
          ? this.state.group
          : this.defaultGroup
        : null,
      filter: this.bSaveFiltering
        ? this.state.filter
          ? this.state.filter
          : this.defaultFilter
        : null,
      sort: this.bSaveSorting
        ? this.state.sort
          ? this.state.sort
          : this.defaultSort
        : null,
      columnsConfig: this.bSaveColConfig ? colConfig : null,
      take: this.bSaveItemsPerPage
        ? this.state.take
          ? this.state.take
          : this.defaultTake
        : null,
      customSettings: this.customSettings ? this.customSettings : null,
    };
  }

  isExcludedCol(field: string) {
    if (this.excludeFromColConfig.includes(field)) return true;
    for (const ec of this.excludeFromColConfig) {
      if (ec.endsWith('*')) {
        const prefix = ec.replace('*', '');
        return field.startsWith(prefix);
      }
    }
    return false;
  }

  /**
   * Reloads grid with the last selected view
   * This is useful if a column is hidden or shown so it appears at the correct position.
   * Also use setTimeout(() => tableView.reloadView(), 0) to take affect if simple reloadView() call doesn't work.
   */
  public reloadView(emitCustomSettings: boolean = true) {
    if (this.selectedTableView) {
      this.loadState(this.selectedTableView, emitCustomSettings);
    }
  }

  loadState(tableView: TableviewModel, emitCustomSettings: boolean = true) {
    const gridConfig = JSON.parse(tableView.Definition);

    const state = this.state;

    if (gridConfig.group) state.group = gridConfig.group;
    if (gridConfig.filter) state.filter = gridConfig.filter;
    if (gridConfig.sort) state.sort = gridConfig.sort;
    state.take = gridConfig.take
      ? gridConfig.take
      : !state.take
      ? this.defaultTake
      : state.take;
    this.customSettings = gridConfig.customSettings;

    this.applyColumnSettings(tableView, gridConfig);

    this.stateChanged.emit(state);
    if (emitCustomSettings) {
      this.customSettingsChange.emit(this.customSettings);
    }
    this.selectedTableView = tableView;
    this.createMenuButtons();
    this.showTableViewDialog = false;
  }

  public refreshColumns() {
    if (this.selectedTableView) {
      const gridConfig = JSON.parse(this.selectedTableView.Definition);
      this.applyColumnSettings(this.selectedTableView, gridConfig);
    }
  }

  applyColumnSettings(tableView: TableviewModel, gridConfig) {
    let bLockedColumnExist = false;
    if (this.grid) {
      const columns = this.grid.columns.toArray();
      let order = 0;
      for (const c of columns) {
        if (c['field'] || c.isColumnGroup) c.orderIndex = ++order;
        if (c['locked']) {
          bLockedColumnExist = true;
        }
        if (c.isColumnGroup) {
          let chOrder = 0;
          for (const cc of c['childrenArray']) {
            if (cc['field'] || cc.isColumnGroup) cc.orderIndex = ++chOrder;
          }
        }
      }

      let isHiddenColumnReorder = false;

      if (gridConfig.columnsConfig) {
        for (const c of gridConfig.columnsConfig) {
          const col = this.getColumnByField(columns, c.field);
          if (col) {
            if (c.isVisible) {
              if (
                !tableView.bDefault &&
                !this.isExcludedCol(c.field) &&
                (col['hidden'] === undefined || col['hidden'] === false)
              ) {
                this.grid.reorderColumn(col, c.orderIndex);
              }
              if (col['hidden'] === true) {
                col['hidden'] = false;
                isHiddenColumnReorder = true;
              }
            } else {
              col['hidden'] = true;
            }
            col.width = c.width;
          }
        }

        if (isHiddenColumnReorder) {
          setTimeout(() => {
            for (const c of gridConfig.columnsConfig) {
              const col = this.getColumnByField(columns, c.field);
              if (col) {
                if (c.isVisible) {
                  if (
                    !tableView.bDefault &&
                    !this.isExcludedCol(c.field) &&
                    (col['hidden'] === undefined || col['hidden'] === false)
                  )
                    this.grid.reorderColumn(col, c.orderIndex - 1);
                }
              }
            }
          }, 500);
        }
      } else {
        const colsReversed = columns.reverse();
        for (const col of colsReversed) {
          if (!this.isExcludedCol(col['field'])) {
            if (col.isVisible) {
              if (!bLockedColumnExist) {
                this.grid.reorderColumn(col, 0);
                col.width = null;
              }
            } else {
              col['hidden'] = true;
            }
          }
        }
      }
    }
  }

  getColumnByField(columns: ColumnBase[], field: string) {
    if (this.grid) {
      const col = _.find(columns, { field: field });
      if (col) {
        return col;
      }
      const groups = columns.filter((c) => c.isColumnGroup);
      for (const cg of groups) {
        const cgCol = this.getColumnByField(cg['childrenArray'], field);
        if (cgCol) {
          return cgCol;
        }
      }
    }
    return null;
  }

  preSelect(tableView: TableviewModel) {
    this.preSelectedTableViewName = tableView.Name;
    localStorage.setItem(this.key, tableView.Name);
  }

  delete(tableView: TableviewModel) {
    tableView.bActive = false;
    this.saveTableView(tableView);
  }

  undelete(tableView: TableviewModel) {
    tableView.bActive = true;
    this.saveTableView(tableView);
  }

  saveTableView(tableView: TableviewModel) {
    this.isSaving = true;
    this.tableViewService
      .updateTableView(tableView)
      .pipe(
        catchError(() => {
          this.isSaving = false;
          return of(null);
        }),
        filter((r) => !!r)
      )
      .subscribe(() => {
        this.isSaving = false;
      });
  }

  close() {
    if (this.tableViews.some((t) => t.bActive === false)) {
      this.loadTableViews();
    }
    this.showTableViewDialog = false;
  }

  public mapGridSettings() {
    const settings = localStorage.getItem('pmGridSettings');
    if (settings) {
      const gridConfig = JSON.parse(settings);
      this.stateChanged.emit(gridConfig.state);
    }
  }

  filterChangeEmployee(ev) {
    this.manPowerService
      .fetchEmployeeDropdown(ev.query, 20)
      .toPromise()
      .then((d) => {
        const m = d.map((i) => ({
          id: i.Id,
          name: `${i.FullName} - ${i.FileClassName}, ${i.Company}, ${i.VPPRId}`,
        }));
        ev.extension.applyData(m);
      });
  }

  valueChangeEmployee() {}

  shareView() {
    if (this.tableViewToShare && this.selectedEmployee) {
      this.isSaving = true;
      this.tableViewService
        .saveTableViewShare({
          TableViewID: this.tableViewToShare.TableViewID,
          EmployeeID: this.selectedEmployee.id,
          bActive: true,
        } as TableviewShareModel)
        .pipe(
          catchError(() => {
            this.isSaving = false;
            return of(null);
          }),
          filter((r) => !!r)
        )
        .subscribe(() => {
          this.isSaving = false;
          this.selectedEmployee = null;
          this.refreshShares();
        });
    }
  }

  unShareView(tableViewShare: TableviewShareModel) {
    if (tableViewShare) {
      tableViewShare.bActive = false;
      this.tableViewService
        .saveTableViewShare(tableViewShare)
        .pipe(
          catchError(() => {
            this.isSaving = false;
            return of(null);
          }),
          filter((r) => !!r)
        )
        .subscribe(() => {
          this.refreshShares();
        });
    }
  }

  refreshShares() {
    if (this.tableViewToShare) {
      this.sharesLoading = true;
      this.tableViewService
        .getTableviews(this.key)
        .pipe(
          catchError(() => {
            this.isSaving = false;
            return of(null);
          }),
          filter((r) => !!r)
        )
        .subscribe((d: TableviewModel[]) => {
          const savedView = d.find(
            (x) => x.TableViewID === this.tableViewToShare.TableViewID
          );
          if (savedView) {
            this.tableViewToShare.Shares = savedView.Shares;
          }
          this.sharesLoading = false;
        });
    }
  }

  customOptionChange(option: TableviewCustomOption) {
    if (
      option.value === true &&
      option.switchOffNames &&
      option.switchOffNames.length
    ) {
      this.customOptions.forEach((o) => {
        if (option.switchOffNames.includes(o.name)) {
          o.value = false;
        }
      });
    }
  }
}
