import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  environment,
  MyHttpClientFactory,
  ResponseCasingEnum,
} from '@ups/xplat/core';
import { SubLocation, Warehouse } from '@ups/xplat/api/dto';

@Injectable({
  providedIn: 'root',
})
export class WarehouseService {
  constructor(
    private httpClient: HttpClient,
    httpFactory: MyHttpClientFactory
  ) {
    this.httpClient = httpFactory.createHttpClient(
      environment.urls.toolTrackerAPI,
      true,
      ResponseCasingEnum.CamelCase
    );
  }

  get() {
    return this.httpClient.get<Warehouse[]>('/api/warehouse');
  }

  getByCompany(companyId: number) {
    return this.httpClient.get<Warehouse[]>(`/api/warehouse/${companyId}`);
  }

  getSubLocation(companyId: number, locationId: number) {
    return this.httpClient.get<SubLocation[]>(
      `/api/warehouse/sublocation/${companyId}/${locationId}`
    );
  }

  updateSublocation(
    companyId: number,
    locationId: number,
    sublocationName: string
  ) {
    return this.httpClient.post<SubLocation>(
      `/api/warehouse/sublocation/${companyId}/${locationId}/${sublocationName}`,
      {}
    );
  }
}
