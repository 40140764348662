import { Component, ElementRef, forwardRef, Input, ViewChild, TemplateRef } from '@angular/core';
import { ColumnBase, ColumnComponent, CellTemplateDirective } from '@progress/kendo-angular-grid';

/**
 * https://www.telerik.com/forums/making-a-reusable-customised-grid-column
 */
@Component({
  selector: 'kendo-grid-column-ex-boolean',
  providers: [
    {
      provide: ColumnBase,
      useExisting: forwardRef(() => KendoGridColumnExBooleanComponent),
    },
  ],
  template: `
    <ng-template #myCellTemplate let-dataItem>
      <input type="checkbox" [checked]="dataItem[field]" class="k-checkbox" disabled />
      <label class="k-checkbox-label">{{ label }}</label>
    </ng-template>
  `,
})
export class KendoGridColumnExBooleanComponent extends ColumnComponent {
  @Input() label: string;

  constructor(private el: ElementRef) {
    super();
  }

  /* NOTE:
   *
   * For some reason that what worked directly in an A8 app (where KendoGridColumnExBooleanComponent wasn't imported by a module)...
   * ...somehow stopped working here.
   *
   * As a fallback we use TemplateRef<any> with a template reference to address it.
   * Also the final public get templateRef(): TemplateRef<any> code changed a bit, as we now have a TemplateRef<any> and not a CellTemplateDirective child component (notice change from viewTemplate to viewTemplateRef).
   */

  @ViewChild('myCellTemplate') viewTemplateRef: TemplateRef<any>;

  //@ts-ignore
  public get templateRef(): TemplateRef<any> {
    const hasTemplate = this.template && this.template.templateRef;
    const hasViewTemplate = this.viewTemplateRef;
    return hasTemplate ? this.template.templateRef : hasViewTemplate ? this.viewTemplateRef : undefined;
  }
}
