import { FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { UnaryFilterDescriptorEx } from './odata-utils';

/**
 * The $noop filter is actually an unary filter.
 * It's just a container around a real filter.
 *
 * It is needed on the UI, to create custom reusable FilterComponents for the grid.
 * Especially needed with collection filtering (all/any) over complex data.
 *
 * Rules for the filter component:
 * - The grid uses the field parameter to profide the filter component with the proper filter expression (related to the given column).
 * - The UI needs a value to be set, otherwise the filter button won't be active (so we need a value and we need to set also a 'fake' value).
 * - The grid will keep also any extra parameters set on the filter expression.
 */
export class NoopFilterDescriptorEx implements UnaryFilterDescriptorEx {
  // UnaryFilterDescriptorEx with defaulting to $noop
  operator: any = '$noop';
  filter: FilterDescriptor | CompositeFilterDescriptor | any;

  // container extension - so the expression works well with the filter
  field: string;
  value: any = 'fake';

  public constructor(field: string, filter: any, extensionObject?: object) {
    if (extensionObject) Object.assign(this, extensionObject);

    this.field = field;
    this.filter = filter;
  }
}
