/* eslint-disable @typescript-eslint/naming-convention */
import { BaseModel } from '../base.model';

export class ParentFacilityDto extends BaseModel {
  public ParentFacilityID: number;
  public ParentCustomerID: number;
  public FacilityName: string;
  public DisplayName: string;
  public Address: string;
  public City: string;
  public State: string;
  public ZIP: string;
  public ParentCustomerName: string;
  public ParentCustomerShortName: string;
  public Subsidiary: string;
  public IndustryID: string;
  public IndustryName: string;
  public VPParentFacilityKeyID: number;
  public bActive: boolean;
  public IsUserFavorite: boolean;
}
