import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ParentCustomerDto } from '@ups/xplat/api/dto';
import { Observable } from 'rxjs';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';

@Injectable({
  providedIn: 'root',
})
export class ViewPointCustomerService {
  protected viewpointApiClient: HttpClient;

  constructor(private clientFactory: MyHttpClientFactory) {
    const urlBase = environment.urls.viewpointAPI;
    this.viewpointApiClient = this.clientFactory.createHttpClient(
      urlBase,
      true,
      ResponseCasingEnum.PascalCase
    );
  }

  public loadCustomers(): Promise<ParentCustomerDto[]> {
    //console.log('loading customers?');

    const url = '/api/quotelog/customers';

    return this.viewpointApiClient
      .get<ParentCustomerDto[]>(url)
      .toPromise()
      .then((data) => {
        return data;
      });
  }

  public loadVpCustomers(): Observable<ParentCustomerDto[]> {
    console.log('loading vp customers?');

    const url = '/api/quotelog/customers';

    return this.viewpointApiClient.get<ParentCustomerDto[]>(url);
  }

  public getParentCustomerDropdown(
    searchString: string,
    take: number = 0
  ): Promise<ParentCustomerDto[]> {
    const url = '/api/parentCustomers';
    let queryParams = '?';

    if (searchString) queryParams += 'searchString=' + searchString;

    if (take) {
      queryParams += (queryParams.length > 1 ? '&' : '') + 'take=' + take;
    }

    return this.viewpointApiClient
      .get<ParentCustomerDto[]>(url + queryParams)
      .toPromise()
      .then((data) => data);
  }
}
