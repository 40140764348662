import { Directive, Inject, NgZone, inject } from '@angular/core';

import {
  BaseComponent,
  LogService,
  NetworkService,
  OfflineHttpTrackingService,
  OfflineTrackedRequestItem,
  WindowService,
} from '@ups/xplat/core';

@Directive()
export abstract class OfflineItemDetailBaseComponent extends BaseComponent {
  network = inject(NetworkService);
  win = inject(WindowService);
  ngZone = inject(NgZone);
  log = inject(LogService);
  offlineHttpTracking = inject(OfflineHttpTrackingService);

  detail: OfflineTrackedRequestItem;
}
