export class SecurityFeatureModel {
  // NOT-RETURNED-BY-API: featureGroupId: string = "";
  featureGroupName = '';

  featureId = '';
  featureName = '';
  uniqFeatureName = '';

  // NOT-RETURNED-BY-API: isActive: boolean = false;

  approvalNeeded = false; // TODO: IMO default should be true!
  approvalNeededSelf = true;

  containsSelf = false;

  editAll = false;
  editSelf = false;

  // NOT-RETURNED-BY-API: modifiedBy: string = "";
  // NOT-RETURNED-BY-API: modifiedDate: string = "";

  // NOT-RETURNED-BY-API: permissionId: string = "";

  readAll = false;
  readSelf = false;

  // NOT-RETURNED-BY-API: userId: string = "";

  viewFeature = false;

  constructor(o: any = null) {
    if (o) {
      Object.assign(this, o);
    }
  }
}
