import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  NotFoundError,
  BadInput,
  AppError,
  environment,
  MyHttpClientFactory,
  ResponseCasingEnum,
} from '@ups/xplat/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AzureApiDocumentService {
  route = `/api/documents`;

  constructor(
    private httpClient: HttpClient,
    private httpFactory: MyHttpClientFactory
  ) {
    this.httpClient = this.httpFactory.createHttpClient(
      environment.urls.azureAPI,
      true,
      ResponseCasingEnum.CamelCase
    );
  }

  getDocumentCount(path: string, locations: string[]) {
    return this.httpClient
      .post(`${this.route}/countList/${path}`, locations)
      .pipe(catchError(this.errorHandler));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private errorHandler(error: any) {
    if (error.status === 404) return throwError(new NotFoundError());
    else if (error.status === 400) return throwError(new BadInput(error));
    else return throwError(new AppError(error));
  }
}
