<div class="dynamic-field-container">
  <div class="dynamic-field form-input text-align dynamic-button-group">
    <hr />
    <label>Approval Result</label>
    <!-- <div>{{ formReview.FormReviewStatusName }}</div> -->

    <div
      #approvalResult
      class="button-group-container"
      *ngIf="
        !isSubmittedByLoggedInUser || canSelfReviewForms || isEditingRejected();
        else noSelfReview
      "
    >
      <button
        class="k-button"
        [class.k-primary]="selectedButton === 'approved'"
        (click)="selectReviewStatus('approved')"
        [disabled]="isReadOnly || isSubmitted()"
      >
        Approve
      </button>
      <button
        class="k-button"
        [class.k-primary]="selectedButton === 'rejected'"
        (click)="selectReviewStatus('rejected')"
        [disabled]="isReadOnly || isSubmitted()"
      >
        Reject
      </button>
      <button
        *ngIf="!isEditingRejected()"
        class="k-button"
        [class.k-primary]="selectedButton === 'inreview'"
        (click)="selectReviewStatus('inreview'); submitReview()"
        [disabled]="isReadOnly || isSubmitted()"
      >
        Save Progress
      </button>
      <button
        *ngIf="isEditingRejected()"
        class="k-button"
        [class.k-primary]="selectedButton === 're-submitted'"
        (click)="selectReviewStatus('re-submitted'); submitReview()"
        [disabled]="isReadOnly"
      >
        Re-Submit
      </button>
      <button
        class="k-button"
        [disabled]="isReadOnly || isSubmitted()"
        (click)="showDeleteConfirm = true"
      >
        Delete
      </button>
    </div>

    <ng-template #noSelfReview>
      <p>Self review is currently disabled</p>
    </ng-template>

    <label>
      Review Notes
      <span *ngIf="selectedButton === 'rejected'" class="c-red">*</span>
    </label>
    <p>
      Review Notes allow HSE Employees to add notes while reviewing a Safety
      form submission. These notes will be displayed to the employee who
      submitted the form.
    </p>
    <textarea
      #reviewNotes
      class="k-textbox"
      [attr.name]="comment"
      [attr.maxLength]="500"
      [(ngModel)]="comment"
      [required]="selectedButton === 'rejected'"
      [disabled]="!isEditable()"
    ></textarea>

    <ng-container *ngIf="showApprovalComments()">
      <label class="mt-4">Approval Comments</label>
      <p>Comments added here are only visible to HSE Management.</p>
      <textarea
        class="k-textbox"
        [attr.name]="approvalComments"
        [attr.maxLength]="500"
        [(ngModel)]="approvalComments"
        [disabled]="!canAddApprovalComments() && !canEditApprovalComments()"
      ></textarea>
    </ng-container>

    <h5 *ngIf="reviewedBy" style="margin-top: 20px">
      Reviewed By: {{ reviewedBy }}
    </h5>
    <h5 *ngIf="dateReviewed">
      Reviewed At: {{ dateReviewed | date: 'short' }}
    </h5>
  </div>
</div>

<div *ngIf="isEditable()" class="dynamic-field-container">
  <div class="dynamic-field form-button">
    <button
      class="k-button"
      style="width: 50%"
      (click)="showCancelConfirm = true"
    >
      {{ selectedButton === 'inreview' ? 'Disregard My Changes' : 'Cancel' }}
    </button>
    <button
      class="k-button k-primary"
      style="width: 50%"
      [disabled]="!isValid() || isSaving"
      (click)="submitReview()"
    >
      {{ isSubmitReview() ? 'Submit Review' : 'Save My Changes' }}
    </button>
  </div>
</div>

<kendo-dialog modal fade [hidden]="!showCancelConfirm" [width]="550">
  <kendo-dialog-titlebar>
    <span>Cancel Review</span>
  </kendo-dialog-titlebar>

  <p>Do you really want to Cancel Review and Close this Window?</p>

  <kendo-dialog-actions>
    <button class="k-button k-primary" (click)="closeTab()">
      Yes, Cancel Review
    </button>
    <button class="k-button" (click)="showCancelConfirm = false">
      No, Continue Review
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<ups-dynamic-delete-form-modal
  *ngIf="showDeleteConfirm"
  (closeModal)="showDeleteConfirm = false"
  (deleteDone)="closeTab()"
></ups-dynamic-delete-form-modal>
