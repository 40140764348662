import {
  Component,
  Injector,
  ElementRef,
  OnInit,
  AfterViewInit,
  OnDestroy,
  HostListener,
  ViewChild,
  Renderer2,
  NgZone,
} from '@angular/core';

import { State } from '@progress/kendo-data-query';
import { process } from '@progress/kendo-data-query';
import { take, tap } from 'rxjs/operators';
import { Subscription, of, fromEvent } from 'rxjs';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { DispatchStatusEditComponent } from '../dispatch-status-edit/dispatch-status-edit.component';

import * as _ from 'lodash';
import { ODataReportingUtils, ODataResult } from '@ups/xplat/features';
import { BaseComponent, environment } from '@ups/xplat/core';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import { HRResourceService } from '@ups/xplat/api/services';
import { MessageHelper } from '@ups/xplat/web/core';

const tableRow = (node) => node.tagName.toLowerCase() === 'tr';

const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }

  return node;
};

@Component({
  selector: 'ups-dispatch-statuses-page',
  templateUrl: 'dispatch-statuses-page.component.html',
})
export class DispatchStatusesPageComponent
  extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  state: State = {
    skip: 0,
    take: 1000,
  };
  isLoading = false;
  @ViewChild('grid') public grid;

  gridData = process([], this.state);
  selectedRecord: unknown;
  showDeleteConfirm = false;

  currentSubscription: Subscription;

  canModify = false;
  canRead = false;

  @ViewChild(DispatchStatusEditComponent)
  editModal: DispatchStatusEditComponent;

  width: number;
  height: number;
  pixelsToFitTable = 100;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth;
    this.height = event.target.innerHeight;
    if (this.height < 400) {
      this.height = 400;
    }
  }

  constructor(
    private zone: NgZone,
    private security: SecurityService,
    private renderer: Renderer2,
    private hrResourceService: HRResourceService,
    private msgHelper: MessageHelper
  ) {
    super();

    const fSecurity = this.security.getFeatureById(
      SecurityConstants.employee_portal_dispatch_status_adminpage
    );
    this.canModify = fSecurity.editAll;
    this.canRead = fSecurity.readAll;
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit(): void {
    _.delay(() => {
      this.height = window.innerHeight;
      this.width = window.innerWidth;
    }, 1010);
  }

  public rowCallback(context: RowClassArgs) {
    return {
      dragging: context.dataItem.dragging,
    };
  }

  public dataStateChange(state: State): void {
    this.state = state;
    this.currentSubscription.unsubscribe();
    this.zone.onStable
      .pipe(take(1))
      .subscribe(() => (this.currentSubscription = this.handleDragAndDrop()));
  }

  loadData() {
    if (!this.canRead) {
      return of(ODataResult.EmptyODataResult);
    }

    this.isLoading = true;
    return this.hrResourceService.getDispatchStatuses().then((data) => {
      this.gridData = process(data, this.state);
      this.selectionChange(null);
      if (this.currentSubscription) {
        this.currentSubscription.unsubscribe();
      }
      this.zone.onStable
        .pipe(take(1))
        .subscribe(() => (this.currentSubscription = this.handleDragAndDrop()));
      this.isLoading = false;
      return data;
    });
  }

  selectionChange(event) {
    if (event && event.selectedRows.length) {
      this.selectedRecord = event.selectedRows[0].dataItem;
    } else {
      this.selectedRecord = null;
    }
  }

  protected handleDragAndDrop(): Subscription {
    const sub = new Subscription(() => {});
    let draggedItemIndex;

    const tableRows = Array.from(document.querySelectorAll('.k-grid tr'));
    tableRows.forEach((item) => {
      this.renderer.setAttribute(item, 'draggable', 'true');
      const dragStart = fromEvent<DragEvent>(item, 'dragstart');
      const dragOver = fromEvent(item, 'dragover');
      const dragEnd = fromEvent(item, 'dragend');

      sub.add(
        dragStart
          .pipe(
            tap(({ dataTransfer }) => {
              try {
                const dragImgEl = document.createElement('span');
                dragImgEl.setAttribute(
                  'style',
                  'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;'
                );
                document.body.appendChild(dragImgEl);
                dataTransfer.setDragImage(dragImgEl, 0, 0);
              } catch (err) {
                // IE doesn't support setDragImage
              }
              try {
                // Firefox won't drag without setting data
                dataTransfer.setData('application/json', '');
              } catch (err) {
                // IE doesn't support MIME types in setData
              }
            })
          )
          .subscribe(({ target }) => {
            const row: HTMLTableRowElement = <HTMLTableRowElement>target;
            draggedItemIndex = row.rowIndex;
            const dataItem = this.gridData.data[draggedItemIndex];
            dataItem.dragging = true;
          })
      );

      sub.add(
        dragOver.subscribe((e: Event) => {
          e.preventDefault();
          const dataItem = this.gridData.data.splice(draggedItemIndex, 1)[0];
          const dropIndex = closest(e.target, tableRow).rowIndex;
          // const dropItem = this.gridData.data[dropIndex];

          draggedItemIndex = dropIndex;
          this.zone.run(() =>
            this.gridData.data.splice(dropIndex, 0, dataItem)
          );
        })
      );

      sub.add(
        dragEnd.subscribe((e: Event) => {
          e.preventDefault();
          const dataItem = this.gridData.data[draggedItemIndex];
          dataItem.dragging = false;
        })
      );
    });

    return sub;
  }

  saveOrder() {
    this.gridData.data.forEach((d, ix) => {
      d.Position = ix + 1;
    });
    this.hrResourceService
      .updateDispatchStatusPositions(this.gridData.data)
      .then((d) => {
        this.gridData = process(d, this.state);
      });
  }

  delete(status) {
    status.bActive = false;

    this.hrResourceService.saveDispatchStatus(status).then(
      () => {
        this.loadData();
        this.showDeleteConfirm = false;
        this.msgHelper.success('Dispatch Status deleted successfully!');
      },
      (error) => {
        status.bActive = true;
        this.msgHelper.error(error.Messages);
      }
    );
  }

  get getXlsxDownloadUri(): string {
    if (!this.grid) return '';

    let uri = environment.urls.spartaAPI + '/api/hrresource/dispatchStatuses';
    uri +=
      '?' +
      ODataReportingUtils.getXlsxDowloadQueryString(
        this.grid,
        this.state,
        [],
        `dispatch_statuses.xlsx`
      );

    return uri;
  }
}
