/* eslint-disable @typescript-eslint/naming-convention */

// 💀⚡💀 DO NOT EDIT THIS FILE 💀⚡💀 DO NOT EDIT THIS FILE 💀⚡💀 DO NOT EDIT THIS FILE 💀⚡💀

/*////////////////////////////////////////////////////////////////////////////////////////////////

  ##### ##### ##### ##### |   This file is autogenerated using an Nx workspace schematic.
  #       #   #   # #   # |   Manual changes should not be needed. After adding your feature
  #####   #   #   # ##### |   to software security, simply regenerate this file by running
      #   #   #   # #     |   `nx workspace-schematic security-constants`. If there is an issue
  #####   #   ##### #     |   with the generated file, check tools/generators/security-constants

////////////////////////////////////////////////////////////////////////////////////////////////*/

export const SecurityConstants = {
  employee_portal_equipment_equipmentdispatchcustomview:
    '94e2b367-1a5e-4d83-9b8b-dfd6038bb571',
  employee_portal_equipment_equipmentdispatchgrid:
    '806b79a4-5b71-4784-a9e9-8aad0e209c42',
  employee_portal_app_switcher_viewappswitcher:
    'f29dd3c3-700b-4968-9958-7b4434ba2113',
  employee_portal_custom_export_customexportpage:
    '393dc619-4444-4e48-bde9-02eaac5c63b6',
  employee_portal_dispatch_dashboard_allowdispatchof2monthormoreofinactivity:
    'b315f122-a44e-43f6-a500-5e6329a0aa2a',
  employee_portal_dispatch_dashboard_allowdispatchof6monthormoreofinactivity:
    'b943412d-3ecc-47cd-8759-46cdcf06f22d',
  employee_portal_dispatch_dashboard_allowdispatchofinactiveemployees:
    '9f84a0aa-01d6-4928-b64d-d7311338ceb8',
  employee_portal_dispatch_dashboard_allowdispatchofinactiveemployees0_6months:
    '4e19d31d-7144-4adc-bc10-347d9da7df7b',
  employee_portal_dispatch_dashboard_allowperdiemabove500:
    'af2014dd-74d0-457d-afb5-cabd09482669',
  employee_portal_dispatch_dashboard_copyjobcrew:
    '0cedbffa-742b-4ffc-89be-74fa7d037098',
  employee_portal_dispatch_dashboard_dispatchhistory:
    'f5506b34-9e73-4188-853c-c79dd618905c',
  employee_portal_dispatch_dashboard_transferemployeecompany:
    'f34fd454-ced9-4f31-b343-50a145df71dc',
  employee_portal_dispatch_dashboard_allowdispatchofinactiveemployeeswithcautionstatus:
    'ed6a80e5-cdd0-4dc7-9593-17bcfd2fc8f5',
  employee_portal_dispatch_rates__autopopulatewage:
    '1b1d5ef6-45b8-468f-a943-419b2cbc3cdd',
  employee_portal_dispatch_rates__contactperdiem:
    'b9a3cffa-b739-4f95-9dde-1b6c52e3bb02',
  employee_portal_dispatch_rates__contractptfactor:
    '2694c08e-535a-4fcc-ba6a-10abdb9c2577',
  employee_portal_dispatch_rates__dispatchcontractrate:
    '0340e3b0-24da-42e8-8388-6d050fd15629',
  employee_portal_dispatch_rates__fieldpayrate:
    '878173bc-24a4-41c5-8eff-bf3470162583',
  employee_portal_dispatch_rates__filepayrate:
    '399a8a51-39c9-4d99-893a-73cf85938e83',
  employee_portal_dispatch_rates__nonbillablepayrate:
    '76d59e9e-1aeb-43ce-be11-2cce0699a850',
  employee_portal_dispatch_rates__shoppayrate:
    'a9f8bff2-12e7-4c62-82fc-958a343faa43',
  employee_portal_dispatch_rates__wageover99:
    'e29b9f6b-3b28-4950-b00d-6b5ac0a0cc10',
  employee_portal_dispatch_status_adminpage:
    'fc54bfd4-1432-4f5b-83b0-9455d5818cb6',
  employee_portal_dispatch_status_adminpagecustomview:
    '89986eee-72b0-426e-aec5-a36a9d9a7ad3',
  employee_portal_dispatch_status_savedeclinedfromcaution_notrehirable_medicalreleaserequired:
    '17e3b55f-5194-4e30-ad60-008458263790',
  employee_portal_employee_jobs_list_customview:
    '5be435c6-69d3-413a-95d5-690effd0c3c6',
  employee_portal_employee_profile_backgroundtabmvr:
    'b3211494-0541-457b-b650-e9ff65a8cc61',
  employee_portal_employee_profile_jobhistorypayrate:
    '89a01c1f-2719-41be-b1cb-5f3e4cd2a1fb',
  employee_portal_equipment_administration_equipmentadministrationdashboard:
    '5f9d0a96-fb3d-4010-990a-4640f6aa2a0d',
  employee_portal_equipment_correctionsauditcustomview:
    '5bb29077-c256-4d25-84e5-5b32d9026e02',
  employee_portal_equipment_correctionsauditpage:
    '8b09e20c-f33b-4b2e-8bda-cec3df4e8217',
  employee_portal_equipment_equipmentdashboard:
    'b6c10796-3ccd-43e7-b98d-f900871fefb0',
  employee_portal_equipment_equipmentdashboardbulkedit:
    '21596cce-e7d3-4f85-8620-eb92fd6fa0b6',
  employee_portal_equipment_equipmentdashboardcustomview:
    '28714d10-3583-4d7c-9a1a-643e6db030dd',
  employee_portal_equipment_equipmentdashboardscorecardtab:
    '9f9ce6c9-8930-4332-b537-dd290ec8abd4',
  employee_portal_equipment_equipmentreallocation:
    '1e867bda-a9fa-4bd1-87bf-b8b1b6b1aea0',
  employee_portal_equipment_equipmentresourcehyperlink:
    '1b28aa6e-b161-4141-8f44-cb39b0f82197',
  employee_portal_equipment_equipmentsearch:
    'f64cce1f-da87-4e8a-8309-5decb2728081',
  employee_portal_equipment_equipmentsearchcustomview:
    'eb53e617-3599-486b-9531-cf979c12e4dd',
  employee_portal_equipment_equipmentusage:
    'e949dc36-d5c4-4403-b673-b305b4c575d3',
  employee_portal_equipment_equipmentusagecustomview:
    'a9b45b69-7dbb-4c9d-8a7b-fcfb91f28564',
  employee_portal_equipment_resources_bulkedit:
    '2292ee39-4a43-45b4-8621-dca516d502a7',
  employee_portal_equipment_resources_calibrationstab:
    '5544b91b-72aa-43af-aa1c-2005b752680b',
  employee_portal_equipment_resources_equipmentlocationhistorytab:
    '3c44e3ed-6828-4c30-8a4a-e749c5303bae',
  employee_portal_equipment_resources_equipmentnotestab:
    'ca683804-eb0b-4731-9b82-c9f190e7f299',
  employee_portal_equipment_resources_equipmentrequesthistorytab:
    '30da4fb4-0ab5-4e8d-beb0-2242ce7ebcd3',
  employee_portal_equipment_resources_equipmentresourcescustomview:
    'a00b523d-1adc-4316-a031-2b15c146ac44',
  employee_portal_equipment_resources_fixedassethistorytab:
    'dff7a955-7b85-4a9b-aa41-8f69069c1aeb',
  employee_portal_equipment_resources_infotab:
    '50b3e17a-9218-444d-8db3-3091793bf004',
  employee_portal_equipment_resources_inspectiontab:
    'a8a649c2-e07d-4196-8ea0-d21c74663027',
  employee_portal_equipment_resources_jobhistorytab:
    'b1286b03-138d-4e09-a866-a37cc1615998',
  employee_portal_equipment_resources_mechanicnotestab:
    'edff4f4d-5535-4140-bb59-1b63d9340b7e',
  employee_portal_equipment_resources_meterstab:
    'c0013e04-5e46-4e2d-ac41-fbe472d1c900',
  employee_portal_equipment_resources_ownershipinfotab:
    'b5f59e15-b7dc-4918-8626-5ddcc1f48935',
  employee_portal_equipment_resources_rentalreleaseinfotab:
    '42c7fa02-772a-450a-bfd0-4311ab99e817',
  employee_portal_equipment_resources_scheduletab:
    '75e0ae00-b232-49ea-8cda-0ae64e3df7c8',
  employee_portal_foreman_crews_canmovetoonsite:
    '5690c19e-3305-4982-bc2c-22e98a5bfea1',
  employee_portal_foreman_crews_canoverridelabortype:
    '19827c02-a033-4bd0-97e2-66f7da0b8351',
  employee_portal_foreman_crews_foremancrewscustomview:
    '49bd7f11-9d95-4c28-b912-4c620bdb796f',
  employee_portal_foreman_crews_foremancrewspage:
    '03d24ea5-da82-4ab6-b9db-24842baf4ddd',
  employee_portal_foreman_crews_foremanmassrelease:
    '5ddcf063-f447-40c3-9ea7-3f753e54b50d',
  employee_portal_foreman_crews_labortypesetup:
    'a08100dc-cb89-42e3-acd8-e391ae27408a',
  employee_portal_foreman_crews_labortypesetupcustomview:
    'aacaa3e4-215d-4f70-9c9c-55a80ad8e01a',
  employee_portal_frameworkfeatures_helpdocuments:
    '18981789-9d10-4b02-92e3-4b2bffbbca92',
  employee_portal_frameworkfeatures_tooltips:
    'b62974b9-2108-4b89-8b38-b9e0128f6c9d',
  employee_portal_frameworkfeatures_walkthroughs:
    'bea227a0-a7bb-4474-b60d-20a8bdfd9bc0',
  employee_portal_interestsubmittedtab_assigntootherjobs:
    'f499dcfb-577b-4f41-b4bd-89101a80f570',
  employee_portal_job_requirements_caneditoverflow:
    'e3524248-0c57-4a76-8a90-45f8524ff43e',
  employee_portal_mileage_rates_mileageratescustomview:
    '3ec8cae6-b4b4-48a8-a6b2-cd65ff018257',
  employee_portal_mileage_rates_mileageratesdashboard:
    '763819c3-36bb-44d6-9fb0-7b5c05e0d011',
  employee_portal_navigation_canseeadminexpertiseareas:
    '4ea405e7-6d30-4d21-af18-030a662cec3b',
  employee_portal_navigation_canseeadminplants:
    '2b827952-b67c-4f89-997f-1a7a4bd39610',
  employee_portal_navigation_canseeadminprospectivejobs:
    '32d5e933-6255-42e5-8208-d130bee5cee2',
  employee_portal_navigation_canseeemployeesaroundplant:
    '043a189b-623b-4208-995b-46850c937ef0',
  employee_portal_navigation_canseeequipmentdashboard:
    '93e8f547-bad0-48e5-96d4-f4ef7ae8db5a',
  employee_portal_navigation_canseeequipmentresources:
    '988957dc-87d4-4620-b441-586630e95a10',
  employee_portal_navigation_canseeforemancrews:
    'aac24c73-ba34-42f4-b880-4a74a8fbad45',
  employee_portal_navigation_canseereports:
    '00aceadf-e05b-4efa-b79e-f10c4a47b093',
  employee_portal_navigation_canseesyncpage:
    '958e410f-4e8c-4a5e-ab95-03063fdb0fa4',
  employee_portal_parent_customers_parentcustomerscustomview:
    'e3ce1dfa-52a6-407e-b9fd-f350ff3a69cb',
  employee_portal_pay_sequence_rules_paysequencerulescustomview:
    'a000e0b7-b7b6-4ffe-80e4-666a4fdf5266',
  employee_portal_pay_sequence_rules_paysequencerulesdashboard:
    '482d0f5b-d0fd-4368-9098-7b406211ee54',
  employee_portal_pay_sequences_paysequencescustomview:
    'e506272f-7388-47d3-b45d-01d28b7c6f54',
  employee_portal_pay_sequences_paysequencesdashboard:
    '6bdad9fd-3081-444a-aa70-61d8cddcafff',
  employee_portal_reports_reportcategoriesassignment:
    '18d0b730-5a85-44b1-919a-2122186b3dac',
  employee_portal_reports_reportcategoriescontrol:
    '08c3545e-ca1d-4933-8a2b-3016f37fc9e5',
  employee_portal_reports_reportscustomview:
    '4c1cd485-e7c4-415d-aa55-9d14d5c0ad40',
  employee_portal_reports_reportspage: '8d7381d1-b847-410b-b2e0-e2d6218d4df1',
  employee_portal_reports_reportspartarolesassignment:
    'e23b59fa-6685-439a-9180-995a9c5c8bf1',
  employee_portal_reports_reportsync: '02b9cf66-0179-4d62-a660-5590114dda6d',
  employee_portal_sparta_navigation_customexport:
    '857ef57a-8d72-4916-9d57-93b099c5fbe1',
  employee_portal_sparta_navigation_dispatchstatuses:
    '8609e631-e61c-400b-ae62-faf03da79ebb',
  employee_portal_sparta_navigation_employeedispatch:
    '8198303d-ad0a-42ca-9619-6aa63c8f53f6',
  employee_portal_sparta_navigation_equipmentdispatch:
    '1984c542-e1eb-4dde-af2d-454f85e7215a',
  employee_portal_sparta_navigation_equipmentsearch:
    '1026b11c-d973-4bb6-bdcd-cac1b747e60b',
  employee_portal_sparta_navigation_equipmentusage:
    '68ee32b8-56eb-4caf-b8ad-2a7975ef2488',
  employee_portal_sparta_navigation_exports:
    '7c72d395-7c15-4fc0-aaff-910e0c405ce2',
  employee_portal_sparta_navigation_foremancrews:
    '2016b5f9-3cac-40f7-bcba-e798f243599d',
  employee_portal_sparta_navigation_jobrequirements:
    'afab2877-85f6-40cb-b2eb-514507b900de',
  employee_portal_sparta_navigation_labortypesetup:
    '81c64e9f-88e4-45f5-85a1-1cfbfccba54c',
  employee_portal_sparta_navigation_mileagerates:
    '903206f4-188b-414f-a907-96b19b951a67',
  employee_portal_sparta_navigation_parentcustomer:
    'fa502b3b-bcf9-499d-b15d-ad74bcaf1f7b',
  employee_portal_sparta_navigation_parentfacility:
    '088e05ed-4a96-4a05-adfc-f50442f6237f',
  employee_portal_sparta_navigation_paysequences:
    'c368bc5b-2599-47c5-bd83-40ee0ba9914a',
  employee_portal_sparta_navigation_plants:
    '96b2ebdc-2239-4d7a-8118-c52faffac407',
  employee_portal_sparta_navigation_reports:
    '391a1fbd-233d-4704-b316-82bda764ead6',
  employee_portal_sparta_navigation_sync:
    'aac74d15-2900-41ac-8a33-ec7474976f89',
  employee_portal_sparta_navigation_timein_timeout:
    'a24efa72-252a-4164-bc5f-03de27f64b47',
  employee_portal_sparta_navigation_timekeeping:
    '9d135474-fc73-4741-8f3d-db0fc707c530',
  employee_portal_sparta_navigation_timekeepingchecklist:
    '9ddcddf2-d84b-4691-8d62-9c3c0c81bfa9',
  employee_portal_sparta_navigation_timekeepingsearch:
    '9ce2c871-45c5-4145-8828-6e87b62ae3f3',
  employee_portal_sparta_navigation_timesheet:
    'fab0a72a-63b1-49bb-88b2-4db299e19d39',
  employee_portal_sparta_navigation_workorders:
    '214ab865-e685-4265-8a04-cd9131946653',
  employee_portal_sparta_navigation_equipmentcorrectionsaudit:
    '0b64de56-39cc-4b43-bfbf-fbd1ee918a9a',
  employee_portal_sparta_navigation_timekeepingcorrectionsaudit:
    '9e4380ed-ded1-4dac-a564-70d6836c8073',
  employee_portal_supers_savedefaulttableviews:
    '5c7d1b81-7204-4342-89af-dbae3b658609',
  employee_portal_supers_setdispatchstatus:
    'c35f48e9-b6be-4b28-a071-9f0509af92f9',
  employee_portal_sync_syncspartapage: '642ee98f-83dc-49a5-9e4b-7059bdb33bdf',
  employee_portal_timekeeping_canmovetoonsite:
    'ba2a8c92-afd2-4acf-9f36-3cf60d851b15',
  employee_portal_timekeeping_canrof: 'cc7e0097-da85-4a4f-9984-ab4fb80c053d',
  employee_portal_timekeeping_canseebillrates:
    '44eaf8a5-3f66-4111-8db1-8a965481c49a',
  employee_portal_timekeeping_canseepayrates:
    '1cc6d5c4-931e-4fa0-892a-a63ae42ea558',
  employee_portal_timekeeping_correctionsauditpage:
    'b6eff7d3-73c1-4c5e-84aa-39c93b8a1427',
  employee_portal_timekeeping_correctionsauditpagecustomview:
    '247eae97-5267-489c-81db-58a886e5ae19',
  employee_portal_timekeeping_exporttimesheetcustomview:
    'e245bb10-26b5-48ac-b5ef-393d56c8c8e7',
  employee_portal_timekeeping_exporttimesheetpage:
    '45408308-b505-4aa7-82a8-14943f0439e5',
  employee_portal_timekeeping_splittingperdiem:
    '8bcfc091-0f14-41f3-a117-633bba98df6e',
  employee_portal_timekeeping_timein_outbyemployeecustomview:
    'fda18420-68cf-4016-883a-0ae8e11c18aa',
  employee_portal_timekeeping_timein_outbyjobcustomview:
    'c077056c-8e7c-4c6f-be0b-97eb459c1317',
  employee_portal_timekeeping_timein_outpage:
    'a121ad86-6428-4ef7-bcc1-239c9d584b4f',
  employee_portal_timekeeping_timekeepingchecklist:
    'aaf7733d-fec6-444a-9ce8-282edd090025',
  employee_portal_timekeeping_timekeepingchecklistcustomview:
    '3aee7db5-6aac-4d79-b010-0ffdbe280e9c',
  employee_portal_timekeeping_timekeepingcustomview:
    '5d4debbc-06a8-4c38-b2f9-e9374e0605f9',
  employee_portal_timekeeping_timekeepingpage:
    'a3fef5bb-50d9-4360-9cf4-64078c514c58',
  employee_portal_timekeeping_timekeepingreallocation:
    '800d4cf4-8670-4b11-98dd-18bb7615c03a',
  employee_portal_timekeeping_timekeepingsearch:
    'f1e837f5-0f30-444b-8661-cc39ba418f6e',
  employee_portal_timekeeping_timekeepingsearchcustomview:
    '111111dc-3847-4439-af6c-4d4850209364',
  employee_portal_timekeeping_timesheetcustomview:
    'df439b3b-5bb3-4794-9c71-066a539afbb2',
  employee_portal_timekeeping_timesheetpage:
    '412c5605-37a4-4df1-8019-fa4e30489c77',
  employee_portal_ups_employee_my_jobs_nativechataccess:
    'c4b388e2-0dcb-4c25-8c20-aa2cd143d91c',
  employee_portal_work_orders_workorderscustomview:
    '9282ffee-c7ee-4959-a311-fde3fed4dfa7',
  employee_portal_work_orders_workorderspage:
    '7c4c62e7-15f3-4b36-ac32-5b9b4c551d32',
  employeeportal_analytics_map_analytics:
    '47a7a5c7-41f8-4725-97cc-e7a16e6d63f8',
  employeeportal_availablejobsrecruiter_requisition:
    '0fa70f6b-68e6-4445-bc03-636838d53acc',
  employeeportal_badgeclerkdashboard_badgeclerkcustomview:
    'f8dade08-a531-4a7d-bc5b-5dac831b4106',
  employeeportal_classadministration_classadministrationdashboard:
    'e3094b26-f70e-4e46-b42c-04602ff11a88',
  employeeportal_classmanagement_classmanagementdashboard:
    'ea1e4372-454c-4d10-89ac-2daeba8f7aee',
  employeeportal_compliancedashboard_addresschangerequesttab:
    '7c8545c0-930e-4124-8b57-38c69d666770',
  employeeportal_compliancedashboard_compliancecustomview:
    'a7d3910f-dfc1-4ca6-af5e-3718f3b3f294',
  employeeportal_compliancedashboard_complianceworkload:
    '60f4300a-80d6-40e8-bc01-98337cfcf07e',
  employeeportal_compliancedashboard_transferworkload:
    '615d4560-2e04-4bd6-9108-df75e202ebfc',
  employeeportal_disaroster_disarostercustomview:
    '7495e483-6394-4249-8b36-0031258a4657',
  employeeportal_disaroster_disarosterdashboard:
    '84837958-7f5c-4300-90ff-e855a4e83c37',
  employeeportal_dispatchdashboard_activestatus:
    '43766c56-a93c-43af-9e52-d890d40b06cf',
  employeeportal_dispatchdashboard_billrates:
    '11eb2310-3b72-4150-a33b-3415eb331011',
  employeeportal_dispatchdashboard_dispatchcustomview:
    'd3c1ef36-337c-4d50-bbed-6f5e47171c37',
  employeeportal_dispatchdashboard_dispatchdocs:
    'df436867-34f0-4fb8-baa6-927a2fd9ddce',
  employeeportal_dispatchdashboard_editclass_craft_prdetails:
    'a9c259a1-84c6-4e21-b712-504144f707f6',
  employeeportal_dispatchdashboard_editstartdate:
    '3ae60853-5175-418b-8197-679f87758589',
  employeeportal_dispatchdashboard_editwage:
    '8831222c-3ffa-4da1-93b6-517fde706ccd',
  employeeportal_dispatchdashboard_harddispatch:
    'be3ba390-8864-4556-9567-4a1f8842813e',
  employeeportal_dispatchdashboard_mileage:
    '3edb8b88-e6ba-4abe-811c-171e8fac6e88',
  employeeportal_dispatchdashboard_multiedit:
    '40119dca-3eb1-495e-a41e-c1be6130da65',
  employeeportal_dispatchdashboard_paycolumns_non_wage:
    'd332180f-12ca-4a97-ac94-06590db376cc',
  employeeportal_dispatchdashboard_perdiemdocs:
    '0da79dc0-07e7-4c88-bc09-70be2dc8c403',
  employeeportal_dispatchdashboard_rofdate:
    '4e695061-c50a-48e7-b90c-8e83a67300eb',
  employeeportal_dispatchstatus_basicedit:
    '3ce951e7-31d2-4f0f-8560-62ae4f31f674',
  employeeportal_dispatchstatus_changecautionstatus:
    'e04d2119-be90-4261-9ead-dab82b73c02f',
  employeeportal_dispatchstatus_changedispatchedstatus:
    'c9e8cbe5-4f0d-48ec-8843-c403ef75b549',
  employeeportal_dispatchstatus_savedeclinedfromhold:
    '1f8f75c2-c1a3-4d24-a297-6eaab2415ddf',
  employeeportal_dispatchstatus_savedeclinedfromrequested:
    '0618c062-a9ac-424f-b3ce-3df5946c11e7',
  employeeportal_dispatchstatus_savedeclinedfromwrongnumberlisted:
    '1db8db5b-0a56-4936-bb9a-8a83bb96a102',
  employeeportal_dispatchstatus_saveholdfromrequested:
    'c52df092-c9e9-4020-ba90-322bb19b1e31',
  employeeportal_dispatchstatus_savepositioncanceledfromhold:
    'd40ade1b-1a2f-4a19-971a-3e6ad47a773b',
  employeeportal_dispatchstatus_saverequestedfromhold:
    '963b699d-7b0b-4a0c-9322-f0fbc5c7e20e',
  employeeportal_dispatchstatus_saverequestedfrompositioncanceled:
    'a9e36455-4e10-4dd1-98f7-0f2eeff4a7ee',
  employeeportal_dispatchstatus_saverequestedfromwrongnumberlisted:
    'c91b6a1a-aab6-4f9e-b6c2-002a283d7a15',
  employeeportal_documentmanagement_documentinfo:
    '3b104190-23ab-41ce-a4ab-bdf1502dda6b',
  employeeportal_employeejobslist_requisition:
    '84136cfb-00a1-48f9-997e-6a4bde418da9',
  employeeportal_employeeprofile_absorbtab:
    '5db877f8-6134-4842-95b0-fdf78541586f',
  employeeportal_employeeprofile_attachmentstab:
    '3cc0fdb7-1a49-4a1a-8e71-79c467b753d2',
  employeeportal_employeeprofile_authenticationdata:
    'b988b702-12bb-4fb0-b35d-1afa6d4af546',
  employeeportal_employeeprofile_backgroundtab:
    '3d7405f6-6050-4ff2-8b0f-ee9c489fa952',
  employeeportal_employeeprofile_backgroundtabattachments_addresstrace:
    '36776953-4d84-489e-a0fb-2166696bd13d',
  employeeportal_employeeprofile_backgroundtabattachments_canview:
    'a07aefe6-553f-45c3-a496-2af29533b65f',
  employeeportal_employeeprofile_benefitstab:
    'bb13a993-78ea-4564-8715-03358f53e7f7',
  employeeportal_employeeprofile_benefitstab_employeetype_jobcategory:
    'e8495c54-1a55-4e33-9577-018b5dd4f743',
  employeeportal_employeeprofile_certtab:
    '3d4729e7-4d64-47c8-9344-6c022441a575',
  employeeportal_employeeprofile_chatlogstab:
    '5fb85dca-f07a-4ab0-b6f4-ea8ed48671a1',
  employeeportal_employeeprofile_editemail:
    '0c28c43b-be9b-4a55-8800-4258b422a1af',
  employeeportal_employeeprofile_emergencycontactstab:
    '2a53d1ea-27f4-4163-84e0-a7b2ed3e880f',
  employeeportal_employeeprofile_employeecustomview:
    '4aa607b7-2024-4b44-ae01-40015b5ab8c5',
  employeeportal_employeeprofile_employeeinfo:
    'd7434746-d639-4235-a0f0-773f9fb0e32c',
  employeeportal_employeeprofile_employeepacketdocumentstab:
    '978c132c-846f-4178-aea4-a2bfacb1af27',
  employeeportal_employeeprofile_employeeprofilehrref:
    'ac38d465-90c2-495c-9fa6-478b005a3330',
  employeeportal_employeeprofile_employeestab_ssncolumn:
    '26173290-d364-4ba6-8252-3a8d465715e0',
  employeeportal_employeeprofile_employmenttab:
    'ce114872-e24e-4c61-be07-41ba2e94194b',
  employeeportal_employeeprofile_employmenttab_donotrehire:
    '1957c780-ccb2-4a92-b401-43f56209cba4',
  employeeportal_employeeprofile_evaluationstab:
    '0ae3e232-48ad-429c-879b-a29996265248',
  employeeportal_employeeprofile_fittab: 'a231b66b-80be-419a-b487-168dccc92100',
  employeeportal_employeeprofile_i9status:
    'ef4ad7fd-d2a5-43ee-9f4c-a46708731d61',
  employeeportal_employeeprofile_identificationdocumentstab:
    '3d5cfc0b-6cb3-4e59-a534-5e636e276a4a',
  employeeportal_employeeprofile_identificationdocumentstab_attachments:
    '7250027c-01d1-42bf-9ec8-72b11a926992',
  employeeportal_employeeprofile_infotab:
    '1315a0ab-0bc5-46cf-a8a2-d763fb6f45c1',
  employeeportal_employeeprofile_infotab_editcellphoneonly:
    '6bce8c23-8d0b-417f-8a33-88c845544a9c',
  employeeportal_employeeprofile_jobhistorytab:
    '9dc6a615-a9fe-43ad-810b-893064477af9',
  employeeportal_employeeprofile_manpowerreqhistorytab:
    '353ba3f0-085f-43db-8b90-f2248a87db27',
  employeeportal_employeeprofile_paystubstab:
    '29bfdc65-886b-4658-8658-a2ec6649d866',
  employeeportal_employeeprofile_perdiemdocumentstab:
    '112b8c67-35d1-4a00-b0c6-49ec65454b70',
  employeeportal_employeeprofile_positiontab:
    'aa382429-7363-416e-9de8-b91de66aaf42',
  employeeportal_employeeprofile_rehirestatus:
    '110c5847-7432-4cbf-91f0-7cee0a54feaa',
  employeeportal_employeeprofile_rehirestatus_medicalreleaserequired:
    '99fb5a4c-ed87-49ed-b83e-88c27678811d',
  employeeportal_employeeprofile_roftab: 'b599f3c6-7567-49ad-904c-5b41e730aea9',
  employeeportal_employeeprofile_safetytab:
    'b697bec1-9df6-44fc-9aa2-53d9c452d61c',
  employeeportal_hrresources_hrresourcesdashboard:
    'f74db5e1-b1fc-41af-9dec-0919ffe05b24',
  employeeportal_jobrequirements_adminapprove:
    'd46ed4f3-898b-4ef5-a42c-72d9f48a0975',
  employeeportal_jobrequirements_jobrequirementscustomview:
    '8c3662fc-50d4-49f6-b46c-289382ada8fc',
  employeeportal_jobrequirements_jobrequirementsdashboard:
    '2bd2b315-30b7-4dde-8885-ba12d0bbedb3',
  employee_portal_job_requirements_trainingsubtasksgroups:
    'f8f2b13c-dfb5-499d-b83c-b485888bb965',
  employeeportal_lodging_lodgingadministration:
    '7c081d5e-82a4-4f47-b9b4-39184fb7525a',
  employeeportal_lodging_lodgingcustomview:
    'b6f4a803-6e29-4f40-824f-4922c28507ec',
  employeeportal_lodging_lodginggrid: 'f27f439d-96e8-4373-845e-98cbfb809c46',
  employeeportal_lodging_lodingandeventsapi:
    '4faa0a29-8d56-4935-90fb-df4401c2d579',
  employeeportal_manpowerpopup_allowdispatchof6monthormoreofinactivity:
    '17a5d498-75f6-40e9-bf8e-8d5dcb6f96d9',
  employeeportal_manpowerpopup_assignnonrehirable:
    '7c0e1e41-2188-4e4c-b25a-0aaea2ecfb43',
  employeeportal_manpowerpopup_employeenotes:
    '34c1be7a-6dc9-41ed-a5e0-9428f1952eaa',
  employeeportal_manpowerpopup_forcedispatch:
    'c5957c33-c59f-40bd-9454-1ebc675e7c9c',
  employeeportal_manpowerpopup_jobrequirements:
    '8dabca72-751d-46fe-996b-878260424e97',
  employee_portal_manpower_pop_up_ocrtrainings:
    '8b25b944-18fa-4b46-89d7-62f818054f3b',
  employee_portal_manpower_pop_up_trainingexpirations:
    'b09abd77-68af-4c0c-9771-016e83f62553',
  employeeportal_my_ups_hrfiles: '33e5096d-eca8-4218-9f3b-d0cd9abd627a',
  Employee_Portal_My_UPS_myteam: 'd09b89ee-28e1-4201-b174-7c640a42e998',
  employeeportal_navigation_canseeavailablejobsrecruiter:
    '245cbf8b-4cab-40ac-a5d8-4ec8d118cbf9',
  employeeportal_navigation_canseebadgeclerkdashboard:
    '196a28ce-0a0f-44ce-9935-1e062d0b33d7',
  employeeportal_navigation_canseeclassadministration:
    '79d411c0-1e7f-44aa-a61a-0ec90416931c',
  employeeportal_navigation_canseeclassmanagement:
    '2e735857-0820-4363-a8f0-aff1155ad08c',
  employeeportal_navigation_canseecompliancedashboard:
    '72e6c6c2-c9d1-4da2-bb1f-36cfd9c862ae',
  employeeportal_navigation_canseedisaroster:
    '128d5b6b-5011-4da7-8d3c-8a26c8a284b7',
  employeeportal_navigation_canseedisptachdashboard:
    '5c29a753-ac43-41b9-9f4e-f8d5481b2fb1',
  employeeportal_navigation_canseeemployeejobslist:
    '177828aa-07d1-47aa-b05f-421dbf35f02d',
  employeeportal_navigation_canseeemployees:
    'f930dbd4-b202-4b8d-9647-9f9215688b69',
  employeeportal_navigation_canseemyprofileinfotab:
    '4eb7e2db-016d-4761-8a73-7d16f75a335c',
  employeeportal_navigation_canseeperdiemauditdashboard:
    '88f80148-68eb-446c-a6d5-81d584e5ab75',
  employeeportal_navigation_canseeprojectmanagerdashboard:
    'aec5595d-8ff8-4809-b9b4-1853b0333a98',
  employeeportal_navigation_canseeprospects:
    '26f8d0f0-d382-415a-8b21-4f74c3ac4ba1',
  employeeportal_navigation_canseerecruiterdashboard:
    'e0bc7dd2-f3f2-4d58-a1ba-a60cf26c05cc',
  employeeportal_parentcustomers_parentcustomersdashboard:
    'da426305-9aca-42df-ba69-97645c6f6afa',
  employeeportal_parentfacilities_parentfacilitiescustomview:
    'b60150fe-588a-4e8c-a994-26ec6c881597',
  employeeportal_parentfacilities_parentfacilitiesdashboard:
    '88902048-fde9-4d4b-a336-341f9e8d3237',
  employeeportal_perdiemaudit_jobpopup: '2ea08966-55b8-48dc-891b-b19efa89437c',
  employeeportal_perdiemaudit_perdiemapproval:
    '802fc251-18c0-4431-9d39-ccf342b6f5a1',
  employeeportal_perdiemaudit_perdiemcustomview:
    '398f5208-8c2f-43a1-a100-d97adb7a3ffe',
  employeeportal_plants_plantscustomview:
    '961fe424-c7e7-4c82-8f61-e24d67a6f2dc',
  employeeportal_plants_plantsdashboard: '053c20c5-477d-421d-96cd-01b4f0a445a2',
  employeeportal_projectmanagerdashboard_projectmanageradvance:
    '9a496878-416b-4863-9006-24a41f8cb82b',
  employeeportal_projectmanagerdashboard_projectmanagercustomview:
    'a0780247-d98f-4ed8-b624-dc1c973d79de',
  employeeportal_projectmanagerdashboard_projectmanagerdashboardinfo:
    'f9e09c14-0c0b-4467-a29a-8796de54c944',
  employeeportal_projectmanagerdashboard_projectmanagerequipmentcustomview:
    'eaee380e-ec5c-4fa8-802d-c2c2f485408a',
  employeeportal_prospectivejobs_prospectivejobscustomview:
    'a532c364-e88e-4b19-8332-8862cad2c174',
  employeeportal_prospectivejobs_prospectivejobsdashboard:
    '093fc505-97f1-470b-b4c1-357c41f51651',
  employeeportal_prospects_packetregistration:
    '32b8caf8-6d3c-4953-8433-741971eed6f6',
  employeeportal_prospects_prospectinfo: '92bf9b3d-94f1-404f-a550-fff3c489f132',
  employeeportal_prospects_prospectscustomview:
    'c55acbb5-f867-4721-8beb-214b50f7b12b',
  employeeportal_recruiterdashboard_bulkeditrecruiter:
    '3e8aa3ff-09fb-4cf3-bfca-7026dda6c6fb',
  employeeportal_recruiterdashboard_recruitercustomview:
    'df3e5b0d-2e97-4d03-95c8-25e373f4d168',
  employeeportal_recruiterdashboard_recruiterscorecard:
    'cf69d556-fdb6-49ae-bb6f-3c072301c054',
  employeeportal_recruiterdashboard_recruiterworkload:
    '623e1ccc-d1ae-4708-8fbf-a60b412f5859',
  employeeportal_recruiterdashboard_transferworkload:
    '64874bc8-4acd-4532-9939-894f22307bdf',
  employeeportal_upsemployeemyjobs_employeejobs:
    'ef15fdfd-3222-4c44-a9c7-f21f31e93ff3',
  employeeportal_zipwhip_chatlogs: 'c4e3e684-0c69-4000-b6a9-67a2bfda4e09',
  nifty_dock_log_docklogcustomviews: 'ee464831-427f-4371-a43d-cbfef9fff64b',
  nifty_helpdocuments_documentmanagement:
    'c2665a55-7fd9-430e-aa23-87d0b0ed8f5c',
  nifty_inventory_add_editsublocation: '00e23279-ab4f-45f2-8ac4-0511955760b9',
  nifty_inventory_exporttoexcel: 'fba9a33c-2d17-434c-b0eb-15ab0ac60f1f',
  nifty_materials_management_docklogpage:
    '15160e1a-865f-4425-b3c4-12ccad4e27be',
  nifty_orders_createquote: '07e48d03-10bc-4027-8ae9-8f5de7f8f480',
  nifty_orders_editorder: 'ef504702-c2f3-4819-b847-a24c99ba69c3',
  nifty_orders_fulfill: 'c2a984c4-b174-4814-bb29-123407ea1980',
  nifty_orders_quotetosalesorder: '4959c913-fd5e-41c2-9325-fa8e9b4597b3',
  nifty_mobile_inventory_inventorymanagement:
    '85de2587-d410-4545-a3b1-724f92cd8425',
  nifty_mobile_inventory_transferstockandprint:
    '98a575d8-68fb-4a99-8e1c-6bc5f693036d',
  operations_management_navigation_quotelogpage:
    'f995ad13-010c-443a-8ecb-4cefe824d8eb',
  operations_management_quote_log_page_bidreviewdate:
    'de929645-0322-4f18-acf1-134c30ff4909',
  operations_management_quote_log_page_createquote:
    'a3f1e9a8-ab09-4b05-87b9-d2040c593b72',
  operations_management_quote_log_page_editquote:
    '4c4a6112-797e-4c70-8fc1-a600b51ab022',
  operations_management_quote_log_page_exporttoexcel:
    '784e7ec7-e3cb-4271-808a-f591f33bbe42',
  operations_management_quote_log_page_gridcustomview:
    '219dcf76-5510-41d0-a192-8982531d5ac3',
  operations_management_quote_log_page_quotelogattachments:
    '9a87e7d9-38c4-4714-8b46-f3fa58c40509',
  operations_management_quote_log_page_quotelogattachmentsdelete:
    'fe4191f1-ea1b-4231-aa54-dfb87d444f19',
  operations_management_quote_log_page_quotelogrevisions:
    '7118595e-8507-4fa7-9235-5093bebddff8',
  rates_management_admin_contractsections:
    'f49f0565-f6f6-4e47-b00b-02863ad010aa',
  rates_management_admin_divisions: 'cea6eb95-bb41-4564-9700-60bc316d25b6',
  rates_management_admin_equipment: '875f807f-8233-4686-95fc-9b458ee5302c',
  rates_management_admin_equipmentnotes: 'f07287c0-f442-4035-bac1-c0d2ab267f73',
  rates_management_admin_unitsofmeasure: '9339db30-bafa-4077-b6a5-cd7cf2a0f022',
  rates_management_admin_revenuecodes: '1755df0c-1331-4117-84ed-7dda007f24fd',
  rates_management_contracts_contractattachments:
    '23bfbc5a-68ec-4154-a60f-92228021cc2b',
  rates_management_contracts_contractscreen:
    'df8e33da-7bb8-427f-911f-0c5a6442c127',
  rates_management_contracts_contractsummary:
    '569b578f-c65c-4a7f-8385-a348ab25f7ec',
  rates_management_contracts_copycontracts:
    '54cf86a2-8b5a-4edd-9867-5cdf5ccf06db',
  rates_management_contracts_createcontractscustomviews:
    '05c08b0b-bee4-44b9-bfe5-32af74c3afdb',
  rates_management_rate_sheet_ratesheetattachments:
    'b1191b25-4af8-42d4-928b-69b83811806d',
  rates_management_rate_sheet_editequipmentbaserevcodes:
    '898b7f8b-5030-4774-90e6-d768f3e0ce11',
  rates_management_rate_sheet_ratesheets:
    '8d0bbd20-9b80-4b3a-87c8-35ce95a072ea',
  rates_management_rate_sheet_versionratesheets:
    'beb9fd7f-f77f-4a67-a34e-2626b40af9bd',
  rates_management_rates_app_navigation_contractselectionscreen:
    'dc04bd41-b3a1-492c-ba87-b6995126b10e',
  rates_management_rates_app_navigation_ratesheetclassification:
    'bcfe8cbb-85ce-4d5c-80b5-330b3cb42c0f',
  rates_management_rates_app_navigation_ratesheetcustomcolumns:
    '9961f0c2-2476-4839-8a3d-3adebd7f62ae',
  rates_management_rates_app_navigation_ratesheetequipmentrates:
    '7dace562-e055-4fe0-949a-0a048297b9c1',
  rates_management_rates_app_navigation_ratesheetselection:
    '624531a9-0284-41fe-8c5b-5cf9cc01d8e3',
  rates_management_rates_app_navigation_ratesheetterms:
    'b6358fb1-8454-48c9-bc22-24da3b5e38d7',
  rates_management_rates_app_navigation_wagesheetcustomcolumns:
    '7236a360-bad6-4aa4-8109-e5c31c43ce92',
  rates_management_rates_app_navigation_wagesheetdataentrypage:
    'f723ccb6-4b26-454e-b79c-65534dfdb113',
  rates_management_rates_app_navigation_wagesheetselection:
    '3cee13aa-9905-42ce-a1ff-ae7b414da383',
  rates_management_rates_app_navigation_wagesheetterms:
    '7e01331d-ad78-4fe3-b0a2-7060c7148e61',
  rates_management_rates_management_reports_lrcsreport:
    'ed87133e-805f-417c-a27a-ad7a5eeaefc7',
  rates_management_wage_sheet_copywagesheets:
    '3a94b81f-ce8c-4e46-806a-404e2db86bb2',
  rates_management_wage_sheet_versionwagesheets:
    'adbbf3ab-40a1-47b5-8aed-05db88aa48ca',
  rates_management_wage_sheet_wagesheets:
    '38bbdbde-3d72-4ac2-8e4d-57c57b673788',
  resource_allocation_class_administration_ra_classadministrationdashboard:
    'be184318-759b-42be-a74f-72808b19a362',
  resource_allocation_dispatch_dashboard_ra_mileagemultiedit:
    '0bbde1df-108d-4679-a6ab-2be690f1940f',
  resource_allocation_employee_absorb_tab_prefilterskillandonboardingcourses:
    'c411345f-479f-4316-b296-e245bcd8463d',
  resource_allocation_employee_expertise_employeeexpertise:
    '67b47c42-3c55-47e5-8a56-dfd99c81daba',
  resource_allocation_employee_expertise_equipmentexpertise:
    'b25c71af-7ff5-4aa2-9db1-2927139b43f7',
  resource_allocation_employee_expertise_manpowerreqdetailexpertise:
    '3f56f47f-7b01-4e77-83e6-31532391fea2',
  resource_allocation_employee_expertise_performanceevaluation:
    '90b97697-c40e-42d2-aa34-4cfbb08a3d55',
  resource_allocation_employee_expertise_tradeexpertise:
    'ae3d1507-cecd-4110-88a5-8efdaf93dd09',
  resource_allocation_employee_profile_ra_allowuseofalive5forchatlogs:
    '14a37b70-2863-4ba8-bb50-2ae020543615',
  resource_allocation_inventory_count_additemstocount:
    'e8e5fd64-16c9-4cc2-888d-46d946e5b85b',
  resource_allocation_inventory_count_adjustbillable:
    'ed40f93a-2447-4839-85a4-b0971b436aa9',
  resource_allocation_inventory_count_adjustcountadjusted:
    '563f4264-ca28-484b-aa63-c0d2b9c971ac',
  resource_allocation_inventory_count_adjustcountrecommendedinventorylevel:
    'fcddb565-e240-47f2-9e16-b974facc761e',
  resource_allocation_inventory_count_adjustinitialcount:
    '6846e36a-aa60-4680-b421-d18e017b08cf',
  resource_allocation_inventory_count_adjustorderedandreceived:
    'c2d60338-cd0b-433f-b236-dbb880726a84',
  resource_allocation_inventory_count_adjustpulled:
    '7777f3c6-80c1-40c3-9038-8984ac2e2765',
  resource_allocation_inventory_count_createnewcount:
    '0c61d785-0995-4068-b7d6-88f54c9b5cda',
  resource_allocation_inventory_count_equipmentselection:
    '8564f18d-43c5-4d0c-b218-8bc5fe38dd59',
  resource_allocation_inventory_count_inventorycountcustomview:
    'b0cc3236-1140-44ce-ae53-713877af10e3',
  resource_allocation_inventory_count_removeitemsfromcount:
    '01f7679e-6351-447e-a394-85be4b488283',
  resource_allocation_inventory_count_serialnumberandexpirationdate:
    '3e9a2ca8-6efd-45e9-8245-86c87622cbe0',
  resource_allocation_inventory_count_status_change_mobileopentomobilepullorreturn:
    'df633c68-bdb5-4b2d-9c51-8ffc5385f7fe',
  resource_allocation_inventory_count_status_change_mobilizepullorreturntomobilizepending:
    '81c2d775-a094-409a-a234-ba39b14e5aea',
  resource_allocation_inventory_count_status_change_mobilizependingtomobilizeapproved:
    'c1fc982b-9523-4d13-a608-299196d5331d',
  resource_allocation_inventory_count_status_change_mobilizeapprovedtofieldcountinitialopen:
    '5a82131f-89aa-4bd1-9617-10b37204610d',
  resource_allocation_inventory_count_status_change_fieldcountinitialopentofieldcountinitialclose:
    '005e1f4b-7e2a-48d4-a90a-f827a410ae22',
  resource_allocation_inventory_count_status_change_fieldcountinitialclosetofieldcountfinalopen:
    '8e43a0e8-d28a-48fe-8359-0feace0b97eb',
  resource_allocation_inventory_count_status_change_fieldcountfinalopentofieldcountfinalclose:
    'fc7baa18-368b-4b13-a2a3-c20731e4d4f3',
  resource_allocation_inventory_count_status_change_demobilizeopentodemobilizepullorreturn:
    '37724b7b-5965-436e-bad2-957143a9f4ae',
  resource_allocation_inventory_count_status_change_demobilizepullorreturntodemobilizepending:
    '000a4530-79b9-42de-906a-619f2eb15d61',
  resource_allocation_inventory_count_status_change_demobilizependingtodemobilizeapproved:
    'f4374f87-fbea-4fe0-919d-5b519844836b',
  resource_allocation_inventory_count_status_change_demobilizeapprovedtoclosed:
    '13cf81e2-679d-44d2-ba66-a0e667b3853a',
  resource_allocation_inventory_count_status_change_mobileopenfrommobilepullorreturn:
    '3da6b694-ada8-4673-928e-9624be656560',
  resource_allocation_inventory_count_status_change_mobilizepullorreturnfrommobilizepending:
    '0fdb0f69-5fca-4e44-bef8-cdc0d33b6250',
  resource_allocation_inventory_count_status_change_mobilizependingfrommobilizeapproved:
    'f7fb6b43-deea-429a-b0ad-afc4cd234f6f',
  resource_allocation_inventory_count_status_change_mobilizeapprovedfromfieldcountinitialopen:
    'd762c67b-231e-4737-aa15-04c2ffc24a7c',
  resource_allocation_inventory_count_status_change_fieldcountinitialopenfromfieldcountinitialclose:
    '37f27d21-f70d-4914-b46c-1cfd4b2307b6',
  resource_allocation_inventory_count_status_change_fieldcountinitialclosefromfieldcountfinalopen:
    'ba13f939-aab5-4336-b16b-b6e05688bf9a',
  resource_allocation_inventory_count_status_change_fieldcountfinalopenfromfieldcountfinalclose:
    '12453f72-1e11-49d9-ae16-8458db7a07b9',
  resource_allocation_inventory_count_status_change_fieldcountfinalclosefromdemobilizeopen:
    'f5d3e200-94c6-454e-9dc1-b3f4b21b4f39',
  resource_allocation_inventory_count_status_change_demobilizeopenfromdemobilizepullorreturn:
    'cb240d0d-62bc-4c2b-8162-122c41959f8e',
  resource_allocation_inventory_count_status_change_demobilizepullorreturnfromdemobilizepending:
    'eb61398d-0619-46a3-8afe-762b959c1b24',
  resource_allocation_inventory_count_status_change_demobilizependingfromdemobilizeapproved:
    'fce95c64-619f-44e0-89f0-665163a46e0f',
  resource_allocation_inventory_count_status_change_fieldcountfinalclosetodemobilizeopen:
    '5754d38a-0e85-4dea-bc9a-359732a489a2',
  resource_allocation_inventory_properties_addtethering:
    'ecfa9d3f-ec3e-4a6e-8d6e-11f17f90b289',
  resource_allocation_inventory_properties_itemcustomview:
    '8a0e0228-2c2e-47fc-b42d-92f7a932515f',
  resource_allocation_inventory_properties_itemname:
    '1e450f4a-52e7-4996-b268-f5fe4fcac9e7',
  resource_allocation_inventory_properties_partnumber:
    '2a1e7d1b-338d-4387-9800-754857bbbf37',
  resource_allocation_inventory_properties_reminder:
    '187ef446-f8e7-4239-b1e5-2c8a3202e7b9',
  resource_allocation_inventory_properties_retire:
    '0ae7def0-1b51-4a9f-a0f9-5ba21f339347',
  resource_allocation_inventory_properties_tethering:
    '691576d7-4bd7-4ff0-a3d4-7f643e60909d',
  resource_allocation_inventory_properties_toolgroup:
    '50a55f25-b66b-4057-9277-1a79d7806594',
  resource_allocation_inventory_properties_vendormanufacturertable:
    '699576de-f4d3-44ce-8477-1635fa2ef42e',
  resource_allocation_inventory_template_addrelatedequipment:
    'e36c0916-0269-41a4-9a18-b67fcdea3a96',
  resource_allocation_inventory_template_adjustrecommendedinventorylevel:
    'be284f18-8362-41ce-8590-a7ca8bf284ff',
  resource_allocation_inventory_template_deleterelatedequipment:
    '57f0984d-a7b8-43c3-885f-50aab1efb391',
  resource_allocation_inventory_template_inventorytemplatedetails_additems:
    '6b76a9b5-f009-4262-9084-1f3c1e2139b3',
  resource_allocation_inventory_template_inventorytemplatedetails_removeitems:
    '3ecf1123-931b-4a68-9624-76a28fb32518',
  resource_allocation_inventory_template_inventorytemplateheader:
    '23e83c3e-c665-42da-96c2-fa93e3868077',
  resource_allocation_job_requirements_ra_cancopyjobrequirements:
    '3ab729ea-0459-4f61-a0c6-8fcfeaee5f25',
  resource_allocation_plants_on_resource_allocation_cancopyplants:
    'ff93cb57-d70c-4dc8-839e-6d5f8096033e',
  resource_allocation_pm_dashboard_searchcandidatesformanpowerposition:
    'd01acdcd-b8e7-4a27-97e0-129bd1699258',
  resource_allocation_resource_allocation_navigation_containerpage:
    'de82f656-6716-4d36-916d-32fec04e1f41',
  resource_allocation_resource_allocation_navigation_inventorycountpage:
    'a4259d88-a89b-4110-8fcc-f10b9ee0a469',
  resource_allocation_resource_allocation_navigation_inventorytemplatepage:
    '62583997-4595-4603-8c0c-906f7b0e0cc6',
  resource_allocation_resource_allocation_navigation_itemlisting:
    '47f4ac9e-4d72-4aff-9f51-59bfd80d2d7d',
  resource_allocation_resource_allocation_navigation_moveitemspage:
    'c694a074-ecda-4365-95d4-65874882f951',
  safety_2_0_coach_forms_coachformscustomview:
    'dfa8a7bd-d4f4-409d-baa0-d911d07992fc',
  safety_2_0_coach_forms_coachformspage: 'b2ce0bc0-02cb-448e-beb8-441044d54652',
  safety_2_0_form_builder_advancedfields:
    'f8d60a48-0ee7-4ae4-8b5d-fdb4dd79c9c6',
  safety_2_0_form_builder_formbuilderpage:
    '3142ee11-52bc-40b3-8790-19c066401895',
  safety_2_0_form_controls_page_formcontrolspage:
    'fe41744b-8772-40d3-b156-c7025575376f',
  safety_2_0_form_controls_page_formcontrolspagecustomview:
    '346813e2-23af-499a-9eed-88343de4953f',
  safety_2_0_form_properties_formpropertiescustomview:
    '0b651d09-ac9a-4fbf-82bf-d1cb9d9f95d1',
  safety_2_0_form_properties_formpropertiespage:
    '7dcec9d4-2a98-461b-ab4a-13af2c963bf7',
  safety_2_0_forms_approvalcomments: '924040ca-a6f3-4ee4-8996-4e950e7cb97b',
  safety_2_0_forms_canaddlessonslearned: '9c5654f6-c17a-4bd4-9421-a4bfca343887',
  safety_2_0_forms_cancoachforms: '9d19a2c5-8cca-4f27-af13-d7d91297a36c',
  safety_2_0_forms_cancommentforms: '0519f2d8-d921-4781-aff2-0f3a7993d613',
  safety_2_0_forms_caneditacceptedforms: 'e236c13e-1a3a-42e9-8907-cdbac3c5acb1',
  safety_2_0_forms_caneditforms: '1b6292d2-0713-402c-9b85-91315870005f',
  safety_2_0_forms_canreclassifyforms: '927e3ffb-bac7-4968-aaa4-1d1bfdce9c17',
  safety_2_0_forms_canreviewforms: '599cbd44-19d1-4c48-b513-630b44c53164',
  safety_2_0_forms_focusedauditforms: 'c0f447c6-80fe-4221-afa2-380e855805ef',
  safety_2_0_forms_incidentforms: '3b35b3db-9039-43f1-bc4e-edb60b04289f',
  safety_2_0_forms_observationforms: 'eff18972-0622-4485-b292-05bfe7e0933b',
  safety_2_0_forms_reportingforms: '69f9390f-e468-4bb3-838f-d8746a8a4f55',
  safety_2_0_lessons_learned_lessonslearnedcustomview:
    '13403f64-25e0-4880-9918-fcd715bc5551',
  safety_2_0_lessons_learned_lessonslearnedpage:
    '82c888da-88a0-4327-9fbf-1166f5be4a93',
  safety_2_0_my_submissions_mysubmissionscustomview:
    'f050a6d9-dae5-4a86-b5c0-24566a322b5c',
  safety_2_0_my_submissions_mysubmissionspage:
    'b84227e1-7407-4e35-b978-cc4cdb9e7701',
  safety_2_0_participation_participationcustomview:
    '18e6e753-9d0b-4afc-b425-7cdee9e48ffb',
  safety_2_0_participation_participationgrid:
    'b347a0c6-ac8b-49de-b8cb-59b8925cb383',
  safety_2_0_review_forms_reviewformscustomview:
    '4472fb37-a2f9-4f14-a8f4-07620e43e901',
  safety_2_0_review_forms_reviewformspage:
    'd9804356-4f69-4793-963f-51868ea57c8d',
  safety_2_0_safety_forms_custom_dashboard_customdashboardcustomview:
    '16edd1fd-6836-47f9-acaf-112d4793ed84',
  safety_2_0_safety_forms_custom_dashboard_safetyformscustomdashboardpage:
    '88a657a5-5341-4562-a204-ec96ca003d01',
  safety_2_0_submission_requirements_submissionrequirementscustomview:
    '59405ceb-ec12-4fe8-ad05-ca9f33370182',
  safety_2_0_submission_requirements_submissionrequirementspage:
    '0fb974a5-3daa-4272-9956-f63e1c5c5983',
  safetyhazardcard_assignmentspage_assignmentspage:
    '57dd6829-e787-4a4f-9251-4597957ea864',
  safetyhazardcard_assignmentspage_classificationfrequencies:
    '01a52acb-bd72-4eb1-9a22-e7a435defce8',
  safetyhazardcard_assignmentspage_individualassignments:
    'f412fa53-e28d-427a-ada3-a6fb07446956',
  safetyhazardcard_coaching_coachtier5ops:
    'da2489a0-92d4-4094-b6d0-1e3460f88635',
  safetyhazardcard_detailscreens_viewdetailscreens:
    '9cdda093-f325-4b88-a5ef-646931ff2beb',
  safetyhazardcard_detailscreens_viewextendeddetailtabs:
    '5e1d19cc-349c-4f5b-8b7e-e225f74289a7',
  safetyhazardcard_eformspage_eformpage: '066c002a-bc11-4d94-8901-923d1b0ecc33',
  safetyhazardcard_hazardcardspage_createhazardcard:
    'd3837282-c750-44ed-b713-d4177827360c',
  safetyhazardcard_needsreviewpage_mitigationstrategy:
    '799ff1bb-457a-4a9b-a980-8b045eb067bf',
  safetyhazardcard_participationpage_participationpage:
    'd511fcc7-6af4-454c-bc54-852ee7e87b0d',
  safetyhazardcard_submissionspage_viewsubmissionspage:
    'a1625cfe-a88b-463a-952d-362880f92785',
  safetyhazardcard_summarypage_mystatistics:
    'de3f1c4f-9a0e-4aa8-ab13-7ff1c1f14d9f',
  safetyhazardcard_summarypage_viewsummarypage:
    '4efe5925-047e-4279-9c83-4e276743748c',
  safetyhazardcard_weeklysnapshotspage_hse_opsmanager:
    '9b7fcceb-b6fb-477e-a212-fd38e129912f',
  safetyhazardcard_weeklysnapshotspage_reviewhazardcardfromsnapshot:
    '2464475c-64d0-4302-9fe8-1197ae1a282b',
  safetyhazardcard_weeklysnapshotspage_viewweeklysnapshotspage:
    'a4510751-0963-43b0-b388-b26ec531363d',
  softwaresecurity_administration_administrationtab:
    'c96ed056-9a9e-4c2f-9501-41ec8c77d139',
  softwaresecurity_releasenotes_managereleasenotes:
    '437d4612-a6d9-4068-9e41-1c204e4b3217',
  softwaresecurity_users_usercompanies: 'e07ec7bf-f800-4328-9f34-6edbc655292d',
  softwaresecurity_users_userinfo: '005a181c-5fba-45cd-b1fb-4ce1812aad41',
  softwaresecurity_users_userjobs: '4579db13-a6b6-4180-a52e-b5097969c5e4',
  softwaresecurity_users_userprgroups: '01efeef3-dcde-4a4f-b049-1a57eafd0f4f',
  softwaresecurity_users_userroles: '186ea356-8da6-40a2-a2e2-6c968514fe7f',
  sparta_testing_test2: 'e8d1a4a5-3651-4e67-aafc-35f22e0dcff7',
  sparta_testing_testfeature: '1e197dd0-3c60-46f0-ae95-6622969a572f',
  resource_allocation_inventory_count_canaccessmobileapp:
    'cae48cd3-6e25-4010-b7cd-d5b9445f0f4b',
  Resource_Allocation_Inventory_Count_admincount:
    '6bdb6845-b9b5-4178-b789-469993ddabc1',
};
export * from '@ups/security-constants';
