import { Inject, Injectable } from '@angular/core';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  IDynamicContainerMetadata,
  LogService,
  environment,
} from '@ups/xplat/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {
  Company,
  EmployeeDesignationDto,
  EmployeeDropdownDto,
  IODataResult,
  JobDropdown,
  ParentCustomerDto,
  ParentFacilityDto,
  TradeClassDto,
  TradeGroupDto,
} from '@ups/xplat/api/dto';
import { Observable } from 'rxjs';
import { parseData } from '@ups/xplat/utils';

// TODO: refactor to be formservice from web-safety
/* eslint-disable */
import { BaseModel } from '@ups/xplat/api/dto';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import { UserService } from '@ups/user';

export class FormDto extends BaseModel {
  public FormID: string;
  public FormId: string;
  public DynamicContainerId: string;
  public Name: string;
  public Description: string;
  public UrlPath: string;
  public bActive: boolean;
  public bCanBeReclassified: boolean;
  public bSubmissionRequirements: boolean;
  public bUsedForParticipation: boolean;
  public bCoachable: boolean;
  public bReviewable: boolean;
  public bReviewableEditable: boolean;
  public bSummaryEmail: boolean;
  public EmailTemplate: string;
  public Fields: unknown[];
  public Version: number;
  public EmployeeEmails: unknown[];
  public Roles: unknown[];
}
// export interface FormStatus extends BaseModel {
//   name: string;
// }

@Injectable({
  providedIn: 'root',
})
export class FormDataService {
  private http: HttpClient;
  private dynamicHttp: HttpClient;

  canReview = false;
  canEditForms = false;
  canEditApprovedForms = false;
  canEditRejectedForms = false;

  constructor(
    private security: SecurityService,
    private clientFactory: MyHttpClientFactory,
    private userService: UserService,
    private log: LogService
  ) {
    this.http = this.clientFactory.createHttpClient(
      environment.urls.spartaAPI,
      true,
      ResponseCasingEnum.PascalCase
    );
    this.dynamicHttp = this.clientFactory.createHttpClient(
      environment.urls.dynamicAPI
    );

    this.canReview = this.security.getFeatureById(
      SecurityConstants.safety_2_0_forms_canreviewforms
    ).editAll;
    this.canEditForms = this.security.getFeatureById(
      SecurityConstants.safety_2_0_forms_caneditforms
    ).editAll;
    this.canEditApprovedForms = this.security.getFeatureById(
      SecurityConstants.safety_2_0_forms_caneditacceptedforms
    ).editAll;
    this.canEditRejectedForms = this.security.getFeatureById(
      SecurityConstants.safety_2_0_forms_caneditrejectedforms
    ).editAll;
  }

  isReviewableForm(dataItem) {
    return (
      this.canReview &&
      dataItem &&
      (dataItem.bReviewable || dataItem.bReviewableEditable) &&
      ((!this.isSubmittedByLoggedInUser(dataItem) &&
        dataItem.ReviewStatus !== 'Approved' &&
        dataItem.ReviewStatus !== 'Rejected') ||
        (!this.isSubmittedByLoggedInUser(dataItem) &&
          this.canEditApprovedForms &&
          dataItem.ReviewStatus === 'Approved') ||
        (this.isSubmittedByLoggedInUser(dataItem) &&
          this.canEditRejectedForms &&
          dataItem.ReviewStatus === 'Rejected'))
    );
  }

  isEditableForm(dataItem) {
    return (
      this.isEdit(dataItem) ||
      this.isEditApproved(dataItem) ||
      this.isEditRejected(dataItem)
    );
  }

  isSubmittedByLoggedInUser(dataItem) {
    return (
      dataItem && dataItem.SubmittedUserId === this.userService.myInfo?.UserId
    );
  }

  isEdit(dataItem) {
    return (
      dataItem &&
      !this.isSubmittedByLoggedInUser(dataItem) &&
      dataItem.bReviewableEditable &&
      this.canEditForms &&
      dataItem.ReviewStatus !== 'Approved' &&
      dataItem.ReviewStatus !== 'Rejected'
    );
  }

  isEditApproved(dataItem) {
    return (
      dataItem &&
      !this.isSubmittedByLoggedInUser(dataItem) &&
      dataItem.bReviewableEditable &&
      this.canEditApprovedForms &&
      dataItem.ReviewStatus === 'Approved'
    );
  }

  isEditRejected(dataItem) {
    return (
      dataItem &&
      this.canEditRejectedForms &&
      dataItem.ReviewStatus === 'Rejected'
    );
  }

  /* eslint-disable */
  public queryFormData(
    odataString: string,
    companies: Company[],
    jobs: JobDropdown[],
    client: ParentCustomerDto,
    facility: ParentFacilityDto,
    employees: EmployeeDropdownDto[],
    forms: Array<{ FormID: string }> /*FormDto[]*/,
    emDesignations: EmployeeDesignationDto[],
    tradeGroup: TradeGroupDto,
    tradeClass: TradeClassDto,
    dateFrom: string,
    dateTo: string,
    safetyContact: { Id: number },
    bCoachable?: boolean,
    bReviewable?: boolean,
    coachingStatus?: string,
    reviewStatus?: string,
    reviewByIds?: Array<{ id: string; name?: string }>,
    reviewDateFrom?: string,
    reviewDateTo?: string,
    pageRange?: {
      skip?: number;
      limit?: number;
    }
  ): Observable<IODataResult<unknown /*ParticipationDto*/>> {
    let url = '/api/participation?';

    const queryParameters: string[] = [];
    queryParameters.push('$format=json');
    queryParameters.push('$count=true');

    if (companies) {
      companies.forEach((i) => {
        queryParameters.push(`vpCompanyIDs=${i.CompanyNumber}`);
      });
    }
    if (jobs) {
      jobs.forEach((i) => {
        queryParameters.push(`jobNumbers=${encodeURIComponent(i.JobID)}`);
      });
    }
    if (client) {
      queryParameters.push(`clientID=${client.ParentCustomerID}`);
    }
    if (facility) {
      queryParameters.push(`facilityIDs=${facility.ParentFacilityID}`);
    }
    if (employees) {
      employees.forEach((i) => {
        if (i.Id) {
          queryParameters.push(`employeeIDs=${i.Id}`);
        }
      });
      employees.forEach((i) => {
        queryParameters.push(`employeeHRRefs=${i.HRRef}`);
      });
    }
    if (forms) {
      forms.forEach((i) => {
        queryParameters.push(`formIDs=${i.FormID}`);
      });
    }
    if (emDesignations) {
      emDesignations.forEach((i) => {
        queryParameters.push(`emDesignationIDs=${i.employeeDesignationId}`);
      });
    }
    if (dateFrom) {
      queryParameters.push(`dateFrom=${dateFrom}`);
    }
    if (dateTo) {
      queryParameters.push(`dateTo=${dateTo}`);
    }
    if (safetyContact) {
      queryParameters.push(`safetyContactIDs=${safetyContact.Id}`);
    }
    if (tradeGroup) {
      queryParameters.push(`tradeGroupID=${tradeGroup.TradeGroupID}`);
    }
    if (tradeClass) {
      queryParameters.push(`tradeClassID=${tradeClass.TradeClassID}`);
    }
    if (bCoachable != null) {
      queryParameters.push(`bCoachable=${bCoachable}`);
    }
    if (bReviewable != null) {
      queryParameters.push(`bReviewable=${bReviewable}`);
    }
    if (coachingStatus) {
      queryParameters.push(
        `coachingStatus=${encodeURIComponent(coachingStatus)}`
      );
    }
    if (reviewStatus) {
      queryParameters.push(`reviewStatus=${encodeURIComponent(reviewStatus)}`);
    }
    if (reviewByIds) {
      reviewByIds.forEach((i) => {
        queryParameters.push(`reviewByIds=${i.id}`);
      });
    }
    if (reviewDateFrom) {
      queryParameters.push(`reviewDateFrom=${reviewDateFrom}`);
    }
    if (reviewDateTo) {
      queryParameters.push(`reviewDateTo=${reviewDateTo}`);
    }

    if (pageRange) {
      if (pageRange.skip) {
        queryParameters.push(`$skip=${pageRange.skip}`);
      }
      if (pageRange.limit) {
        //queryParameters.push(`$top=${pageRange.limit}`);
      }
    }

    queryParameters.push(odataString || '');

    url = url + queryParameters.join('&');

    // TODO: condense all string queryParameters above and use as 'params' in options of http .get call here (it will auto encode values properly without having to worry about it above):
    return this.dynamicHttp.get(url).pipe(
      map((data) => {
        return parseData(data);
      })
    );
  }

  querySubmissionFormData(
    odataString: string,
    jobs: JobDropdown[],
    facilities: ParentFacilityDto[],
    dateFrom: string,
    dateTo: string,
    forms: FormDto[],
    formStatuses?: string,
    pageRange?: {
      skip?: number;
      limit?: number;
    }
  ) {
    let url = '/api/participation/mysubmissions?';

    let params = new HttpParams();

    const queryParameters: string[] = [];
    queryParameters.push('$format=json');
    queryParameters.push('$count=true');

    if (jobs) {
      jobs.forEach((i) => {
        queryParameters.push(`jobNumbers=${encodeURIComponent(i.JobID)}`);
      });
    }

    if (facilities) {
      facilities.forEach((i) => {
        queryParameters.push(`facilityIDs=${i.ParentFacilityID}`);
      });
    }

    if (forms) {
      forms.forEach((i) => {
        queryParameters.push(`formIDs=${i.FormID}`);
      });
    }

    if (formStatuses) {
      // queryParameters.push(`formStatuses=${formStatuses}`);
      params = params.append('formStatuses', formStatuses.toString());
    }

    if (dateFrom) queryParameters.push(`dateFrom=${dateFrom}`);

    if (dateTo) queryParameters.push(`dateTo=${dateTo}`);

    if (pageRange) {
      if (pageRange.skip) {
        queryParameters.push(`$skip=${pageRange.skip}`);
      }
      if (pageRange.limit) {
        //queryParameters.push(`$top=${pageRange.limit}`);
      }
    }

    queryParameters.push(odataString || '');

    url = url + queryParameters.join('&');

    return this.dynamicHttp.get(url, { params }).pipe(map(parseData));
  }

  getDynamicFormsOData(
    odatastring: string
  ): Observable<IODataResult<IDynamicContainerMetadata>> {
    let url = '/api/DynamicContainer/odata';
    url += '?$format=json&$count=true';

    url += odatastring ? '&' + odatastring : '';
    console.log('url', url);
    return this.dynamicHttp.get(url) as Observable<
      IODataResult<IDynamicContainerMetadata>
    >;
  }

  queryCoachByUsers(
    odata?: string
  ): Observable<Array<{ Name?: string; UserID?: string }>> {
    return this.queryUsers('coachByUsers', odata);
  }

  queryReviewByUsers(
    odata?: string
  ): Observable<Array<{ Name?: string; UserID?: string }>> {
    return this.queryUsers('reviewByUsers', odata);
  }

  queryUsers(
    api: string,
    odata?: string
  ): Observable<Array<{ Name?: string; UserID?: string }>> {
    let url = `/api/participation/${api}`;

    const params: {
      [param: string]:
        | string
        | number
        | boolean
        | readonly (string | number | boolean)[];
    } = {
      $format: 'json',
      $count: true,
    };
    url += odata ? '?' + odata : '';
    return this.dynamicHttp
      .get(url, {
        params,
      })
      .pipe(map(parseData));
  }

  getParticipationSummary(dateFrom, dateTo, employeeId) {
    let url =
      '/api/participation/total/employee/weekly/{dateFrom}/{dateTo}/{employeeId}';
    url = url.replace('{dateFrom}', dateFrom);
    url = url.replace('{dateTo}', dateTo);
    url = url.replace('{employeeId}', employeeId);

    return this.dynamicHttp.get<
      [
        {
          RequiredCount: number;
          SubmittedCount: number;
        }
      ]
    >(url);
  }

  /* eslint-enable */
}
