import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import { parseData } from '@ups/xplat/utils';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

const GET_PLACE_MAP: string = '/api/googlemap/placeMap';
const GET_ROUTE: string = '/api/googlemap/route';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapService {
  protected http: HttpClient;

  constructor(private clientFactory: MyHttpClientFactory) {
    const urlBase = environment.urls.spartaAPI;
    this.http = this.clientFactory.createHttpClient(
      urlBase,
      true,
      ResponseCasingEnum.PascalCase
    );
  }

  getRoute(
    addressFrom: string,
    addressTo: string,
    forceReload: boolean = false
  ): any {
    let url =
      GET_ROUTE + '?addressFrom=' + addressFrom + '&addressTo=' + addressTo;
    if (forceReload) url = url + '&forceReload=true';
    return this.http.get(url).toPromise().then(parseData);
  }

  getPlaceMap(address: string): Observable<Blob> {
    const url = GET_PLACE_MAP + '?address=' + address;
    return this.http
      .get(url, { responseType: 'blob' })
      .pipe(map((i) => (i as any).body as Blob));
  }
}
