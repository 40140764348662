/* eslint-disable @typescript-eslint/naming-convention */
import { environmentBase } from './environment.base';
import { IEnvironmentConfiguration } from '../environment.interface';
import { deepMerge } from '@ups/xplat/utils';

export const environmentStg = deepMerge(environmentBase, <
  IEnvironmentConfiguration
>{
  production: false,
  environment_name: 'stg',
  appsync: {
    production: true,
    android: {
      productionKey: process.env['AppSync:AndroidKey@prod'],
      stagingKey: process.env['AppSync:AndroidKey@stg'],
    },
    ios: {
      productionKey: process.env['AppSync:IosKey@prod'],
      stagingKey: process.env['AppSync:IosKey@stg'],
    },
    version: '0.0.1',
    serverUrl: process.env['Urls:AppSyncServerUrl'],
  },
  uxcam: {
    apiKey: process.env['UxCam:ApiKey'],
  },
  sockets: {
    signalR: {
      url: process.env['Urls:NotificationHub@stg'],
    },
  },
  name: 'safety',
  security: {
    allowEverything: false,
    securityOverride: false,
    securityOverrideJson: 'environments/security.json',
    apiUrl: process.env['Urls:SecurityApiAzure@stg'],
    appId: null,
  },
  urls: {
    azureAPI: process.env['Urls:AzureApi@stg'],
    sparta: process.env['Urls:Sparta@stg'],
    spartaAPI: process.env['Urls:SpartaApi@stg'],
    viewpointAPI: process.env['Urls:ViewpointApi@stg'],
    packetAPI: process.env['Urls:PacketApi@stg'],
    securityAPI: process.env['Urls:SecurityApi@stg'],
    softwareSecurityAPI:
      process.env['Urls:SoftwareSecurityApi@stg:Ups.SoftwareSecurity.Api'],
    fileManagementAPI: process.env['Urls:FileManagementApi@stg'],
    tasksAPI: process.env['Urls:TasksApi@stg:Ups.Tasks.Api'],
    safetyAPI: process.env['Urls:SafetyApi@stg'],
    ratesAPI: process.env['Urls:RatesApi@stg'],
    toolTrackerAPI: process.env['Urls:ToolTrackerApi@stg'],
    reportsServer: process.env['Urls:ReportsServer@stg'],
    dynamicAPI: process.env['Urls:DynamicApi@stg'],
  },
  host: process.env['Urls:HseToolBoxHostUrl@stg'],
  blobStorage: {
    url: process.env['Urls:AzureBlobStorageUrl'],
    container: process.env['AzureBlobStorage:ContainerName@stg'],
  },
});
