import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { JobDropdown } from '@ups/xplat/api/dto';
import { SearchJobService, SearchJobVPService } from '@ups/xplat/api/services';
import { dynamicTypeaheadJobFilter } from '@ups/xplat/features';
import { DynamicTypeaheadWebBaseComponent } from '../base/dynamic-typeahead.web-base-component';

const SEARCH_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DynamicTypeaheadJobComponent),
  multi: true,
};

@Component({
  selector: 'ups-dynamic-typeahead-job',
  templateUrl: 'dynamic-typeahead-job.component.html',
  providers: [SEARCH_CONTROL_VALUE_ACCESSOR],
})
export class DynamicTypeaheadJobComponent
  extends DynamicTypeaheadWebBaseComponent
  implements OnInit
{
  constructor(
    private jobsService: SearchJobService,
    private jobsVPService: SearchJobVPService
  ) {
    super();
    this.queryFilter = dynamicTypeaheadJobFilter;
  }

  ngOnInit() {
    super.ngOnInit();
    this.searchQuery = this.config?.options?.pointToViewpoint
      ? this.jobsVPService.search.bind(this.jobsVPService)
      : this.jobsService.search.bind(this.jobsService);
  }

  toggleFavorite(job: JobDropdown, e: PointerEvent) {
    job.IsUserFavorite = !job.IsUserFavorite;
    if (this.config?.options?.pointToViewpoint) {
      this.jobsVPService.addOrRemoveFavoriteJob(job.JobID).then(() => {});
    } else {
      this.jobsService.addOrRemoveFavoriteJob(job.Id).then(() => {});
    }
    e.stopPropagation();
  }
}
