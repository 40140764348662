/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Output, EventEmitter } from '@angular/core';
import {
  GridDataResult,
  DataStateChangeEvent,
} from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import * as moment from 'moment';
import {
  EquipmentDispatchStatusService,
  EquipmentService,
  TimekeepingService,
} from '@ups/xplat/api/services';
import {
  EquipmentDashboardDetailDto,
  EquipmentDashboardGridDto,
  EquipmentDispatchStatusEnum,
  EquipmentDto,
  EquipmentRequestBillableEnum,
  EquipmentRequestOverlapDto,
} from '@ups/xplat/api/dto';
import { ManPowerService } from '@ups/xplat/base/manpower';
import { ProfileModel } from '@ups/xplat/api/dto';
import { EquipmentVpService, MessageHelper } from '@ups/xplat/web/core';
import { BaseComponent } from '@ups/xplat/core';
import { IntlService } from '@progress/kendo-angular-intl';

@Component({
  selector: 'ups-equipment-dashboard-detail',
  templateUrl: './equipment-dashboard-detail.component.html',
  styleUrls: ['./equipment-dashboard-detail.component.scss'],
})
export class EquipmentDashboardDetailComponent extends BaseComponent {
  @BlockUI('blockUI')
  blockUi: NgBlockUI;

  @Output()
  dataChanged: EventEmitter<any> = new EventEmitter<any>();

  assetControlManager: any;
  billableItems: Array<{ text: string; value: string }> = [];
  billingRates: any[] = [];
  canModify = true;
  dispatches: any[] = [];
  dispatchItems: Array<{ text: string; value: number }> = [];
  equipment: EquipmentDto;
  filteredEquipments: EquipmentDto[] = [];
  filteredPos: string[] = [];
  filteredWorkOrders = [];
  gridData: GridDataResult;
  gridDto: EquipmentDashboardGridDto;
  isCollapsed = false;
  isEquipmentLoading = false;
  isLoading = false;
  isSaving = false;
  isUpdated = false;
  jobContact: any[] = [];
  model: EquipmentDashboardDetailDto = new EquipmentDashboardDetailDto();
  opened = false;
  overlappingRequests: EquipmentRequestOverlapDto[] = null;
  pos: string[] = [];
  profile: ProfileModel;
  savedDispatchStatusID: number = null;
  savedModel: any;
  selectedEquipmentOtherCompanies: EquipmentDto[] = [];
  showConfirm = false;
  showResourceConfirm = false;
  state: State = {
    skip: 0,
    take: 3,
  };
  switchEquipment = false;
  title = '';
  vpEquipment: any = null;
  originalVpEquipment: any = null;
  workOrders = [];

  constructor(
    private msgHelper: MessageHelper,
    private equipmentService: EquipmentService,
    private equipmentVpService: EquipmentVpService,
    private dispatchStatusService: EquipmentDispatchStatusService,
    private manPowerService: ManPowerService,
    private timekeepingService: TimekeepingService,
    private intl: IntlService
  ) {
    super();
    this.profile = JSON.parse(localStorage.getItem('profile')) as ProfileModel;
  }

  close() {
    if (this.savedModel !== JSON.stringify(this.model)) {
      this.showConfirm = true;
      return;
    }
    if (!this.isSaving) {
      this.opened = false;
      if (this.isUpdated) {
        this.dataChanged.emit();
      }
    }
  }

  confirmEquipment() {
    this.model.equipmentID = this.equipment.EquipmentIDs[0];
    this.equipmentService
      .getEquipmentBillingRates(
        this.model.jobID,
        null,
        this.equipment.DisplayName
      )
      .subscribe((r) => (this.billingRates = r));
    this.isLoading = true;
    this.equipmentService
      .getEquipmentDispatches(this.model.equipmentID)
      .subscribe((e) => {
        this.dispatches = e;
        this.gridData = process(this.dispatches, this.state);
        this.isLoading = false;
      });
    this.showResourceConfirm = false;
    this.switchEquipment = false;
    this.equipment = null;
    this.originalVpEquipment = JSON.parse(JSON.stringify(this.vpEquipment));
  }

  dataStateChange(state: DataStateChangeEvent): any {
    this.state = state;
    this.gridData = process(this.dispatches, this.state);
  }

  discardEquipment() {
    this.equipment = null;
    this.valueChangeEquipment(null);
  }

  filterChangeEmployee(event) {
    this.manPowerService
      .fetchEmployeeDropdown(event.query, 20)
      .toPromise()
      .then((d) => {
        const m = d.map((i) => ({
          id: i.Id,
          name: `${i.FullName} - HRRef: ${i.HRRef}`,
        }));
        event.extension.applyData(m);
      });
  }

  handleCollapseClick() {
    this.isCollapsed = !this.isCollapsed;
  }

  initDispatchItems() {
    this.dispatchItems = this.dispatchStatusService
      .nextStatuses(this.model.dispatchStatusID)
      .map((data) => ({
        text: data.DispatchStatusName,
        value: data.DispatchStatusId,
      }));
    this.dispatchItems.sort((a, b) =>
      a.text > b.text ? 1 : b.text > a.text ? -1 : 0
    );
  }

  isEquipmentEditable(): boolean {
    return (
      this.savedDispatchStatusID === EquipmentDispatchStatusEnum.Hold ||
      this.savedDispatchStatusID === EquipmentDispatchStatusEnum.Requested ||
      this.savedDispatchStatusID === EquipmentDispatchStatusEnum.Assigned
    );
  }

  isResourceRequired() {
    return (
      this.model.dispatchStatusID === EquipmentDispatchStatusEnum.Dispatched ||
      this.model.dispatchStatusID === EquipmentDispatchStatusEnum.Assigned
    );
  }

  isValid() {
    if (
      !this.model.dispatchStatusID ||
      !this.model.startDate ||
      !this.model.endDate
    )
      return false;
    if (this.model.endDate < this.model.startDate) return false;
    if (this.model.dispatchStatusID === EquipmentDispatchStatusEnum.Assigned) {
      if (!this.model.equipmentID) return false;
    }
    if (
      this.model.dispatchStatusID === EquipmentDispatchStatusEnum.Dispatched
    ) {
      if (!this.model.equipmentID) return false;
      if (this.model.jobRequirements.some((jr) => jr.isFinalStatus === false))
        return false;
      for (const jobRequirement of this.model.jobRequirements) {
        if (
          jobRequirement.subRequirements.some(
            (sr) => sr.mandatory && !sr.bChecked
          )
        )
          return false;
      }
    }
    return true;
  }

  isValidJobRequirement(jobRequirement) {
    if (
      this.model.dispatchStatusID === EquipmentDispatchStatusEnum.Dispatched
    ) {
      if (!jobRequirement.isFinalStatus) return false;
      if (
        jobRequirement.subRequirements.some(
          (sr) => sr.mandatory && !sr.bChecked
        )
      )
        return false;
    }
    return true;
  }

  loadBillableTypes() {
    this.billableItems = [
      { text: 'Yes', value: EquipmentRequestBillableEnum.yes },
      { text: 'No', value: EquipmentRequestBillableEnum.no },
      { text: 'COE', value: EquipmentRequestBillableEnum.billAsCOE },
    ];
  }

  loadWorkOrders(jobId) {
    if (jobId) {
      this.timekeepingService
        .getPhases(jobId, null, false)
        .then((workOrders: any[]) => {
          this.workOrders = workOrders;
          this.filteredWorkOrders = [...this.workOrders];
          this.pos = [...new Set(workOrders.map((p) => p.po))].sort();
          this.filteredPos = [...this.pos];
        });
    } else {
      this.workOrders = [];
      this.filteredWorkOrders = [];
      this.pos = [];
      this.filteredPos = [];
    }
  }

  open(gridDto: EquipmentDashboardGridDto) {
    this.title = `${gridDto.VPJobNumber} ${gridDto.JobName} - ${gridDto.RequestedEquipment}`;
    this.model = new EquipmentDashboardDetailDto();
    this.gridDto = gridDto;
    this.billingRates = [];
    this.dispatches = [];
    this.gridData = process(this.dispatches, this.state);
    this.vpEquipment = null;
    this.originalVpEquipment = null;
    this.equipment = null;
    this.overlappingRequests = null;
    this.showResourceConfirm = false;
    this.savedDispatchStatusID = null;
    this.isUpdated = false;
    this.switchEquipment = false;

    this.equipmentService
      .getEquipmentDashboardDetail(gridDto.EquipmentRequestDetailID)
      .subscribe((d) => {
        this.model = d;
        this.savedModel = JSON.stringify(this.model);
        this.savedDispatchStatusID = this.model.dispatchStatusID;

        this.initDispatchItems();
        this.loadBillableTypes();
        this.loadWorkOrders(this.model.jobID);

        if (this.model.assetControlManagerID) {
          this.assetControlManager = {
            id: this.model.assetControlManagerID,
            name: this.model.assetControlManagerName,
          };
        }

        if (this.model.jobInfo.assetControlManager) {
          this.jobContact = [
            {
              name: this.model.jobInfo.assetControlManager,
              phoneNumber: this.model.jobInfo.phoneNumber,
              email: this.model.jobInfo.email,
            },
          ];
        }

        if (this.model.name) {
          this.equipmentService
            .getEquipmentBillingRates(
              this.model.jobID,
              null,
              `${gridDto.Name} - Co.${gridDto.VPCompanyID}`
            )
            .subscribe((r) => (this.billingRates = r));
          this.equipmentVpService
            .getEquipmentResource(gridDto.VPCompanyID, this.model.name)
            .toPromise()
            .then((v) => {
              this.vpEquipment = v.value[0];
              this.originalVpEquipment = v.value[0];
            });
          this.isLoading = true;
          this.equipmentService
            .getEquipmentDispatches(this.model.equipmentID)
            .subscribe((e) => {
              this.dispatches = e;
              this.gridData = process(this.dispatches, this.state);
              this.isLoading = false;
            });
        }

        if (this.model.timeNeeded) {
          const time = (this.model.timeNeeded as string)?.split(':') ?? [];
          if (time.length) {
            const date = new Date();
            date.setHours(
              parseInt(time[0], 10),
              parseInt(time[1], 10),
              parseInt(time[2], 10)
            );
            this.model.timeNeeded = date;
          }
        }

        this.opened = true;
      });
  }

  onSave() {
    if (!this.isValid()) {
      return;
    }

    if (
      this.model.startDate &&
      this.model.endDate &&
      this.model.startDate > this.model.endDate
    ) {
      this.msgHelper.error('End Date cannot be before Start Date!');
      return;
    }

    this.blockUi.start('Saving...');
    this.isSaving = true;

    if (this.model.newNotes) {
      const now = moment().format('MMM DD, YYYY h:mm A');
      this.model.newNotes = `${now} - ${this.profile.firstName} ${this.profile.lastName} \r\n${this.model.newNotes}`;
      this.model.notes = this.model.notes
        ? this.model.newNotes
        : `${this.model.newNotes}\r\n${this.model.notes}`;
    }

    const workOrder = this.workOrders.find(
      (phase) => phase.phaseID === this.model.phaseId
    );
    if (workOrder) {
      this.model.phaseName = workOrder?.phaseName ?? null;
    } else {
      this.model.phaseName = this.model.phaseId;
      this.model.phaseId = null;
    }

    const savedModel: EquipmentDashboardDetailDto = {
      ...this.model,
      timeNeeded: this.model.timeNeeded
        ? this.intl.formatDate(this.model.timeNeeded as Date, 'HH:mm:ss')
        : null,
    };

    this.equipmentService.saveEquipmentRequestDetail(savedModel).subscribe(
      (httpOkData) => {
        this.blockUi.stop();
        this.isSaving = false;
        this.model = httpOkData;
        this.savedModel = JSON.stringify(this.model);

        if (
          this.savedDispatchStatusID !==
            EquipmentDispatchStatusEnum.Dispatched &&
          this.model.dispatchStatusID === EquipmentDispatchStatusEnum.Dispatched
        ) {
          this.updateEquipmentDispatchSessionStorageData();
          window.open('/dashboards/equipment-dashboard-page', '_blank');
        }
        this.savedDispatchStatusID = this.model.dispatchStatusID;

        this.isUpdated = true;
        this.msgHelper.success('Saved successfully!');
      },
      (error) => {
        this.blockUi.stop();
        this.isSaving = false;
        if (error.ErrorObject) {
          const errorObj = JSON.parse(error.ErrorObject);
          this.msgHelper.error(errorObj.Messages);
        } else if (error.error) {
          this.msgHelper.error(error.error.messages);
        }
      }
    );
  }

  updateEquipmentDispatchSessionStorageData() {
    let data: any = sessionStorage.getItem('EquipmentDispatchData');
    if (data) {
      data = JSON.parse(data);
    } else {
      data = {};
    }
    data.selectedJob = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Id: this.model.jobID,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      JobID: this.model.jobInfo.vpJobId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Name: this.model.jobInfo.jobName,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      CompanyID: null,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      IsActive: true,
    };
    sessionStorage.setItem('EquipmentDispatchData', JSON.stringify(data));
  }

  valueChangeBillable(value) {
    const filteredWorkOrdersByBillable = this.workOrders.filter((workOrder) => {
      switch (value) {
        case 'Billable':
          return workOrder.isBillable;
        case 'Non Billable':
          return !workOrder.isBillable;
        default:
          return true;
      }
    });
    this.filteredPos = [
      ...new Set(filteredWorkOrdersByBillable.map((p) => p.po)),
    ].sort();
  }

  valueChangeEquipment(selectedEquipment: EquipmentDto): void {
    if (selectedEquipment) {
      this.equipment = selectedEquipment;
      this.vpEquipment = null;
      this.overlappingRequests = null;

      this.equipmentVpService
        .getEquipmentResource(
          selectedEquipment.VPCompanyID.toString(),
          selectedEquipment.Name
        )
        .toPromise()
        .then((v) => {
          this.vpEquipment = v.value[0];
          this.showResourceConfirm = true;
        });
      this.equipmentService
        .getOverlappingEquipmentRequests(
          this.model.equipmentRequestDetailID,
          selectedEquipment.EquipmentIDs[0]
        )
        .subscribe((o) => {
          this.overlappingRequests = o;
        });
    } else {
      this.equipment = null;
      this.vpEquipment = this.originalVpEquipment || null;
      this.overlappingRequests = null;
      this.selectedEquipmentOtherCompanies = [];
      this.showResourceConfirm = false;
    }
  }

  valueChangePO(po) {
    if (po) {
      this.filteredWorkOrders = this.workOrders.filter(
        (workOrder) => workOrder.po === po
      );
    }
  }

  valueChangeReqStatus(statusID, requirement) {
    if (statusID) {
      const status = requirement.statuses.find(
        (s) => s.equipmentRequirementStatusID === statusID
      );
      requirement.isFinalStatus = status.isFinalStatus;
    } else {
      requirement.isFinalStatus = false;
    }
  }

  cancelSwitchEquipment() {
    this.switchEquipment = false;
    this.discardEquipment();
  }
}
