import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GridCheckboxFilterMenuUIService {
  public containsBlankRows(
    data: unknown[],
    isPrimitive: boolean,
    valueField: string = 'value'
  ): boolean {
    let blankRowFound = false;

    if (!data) {
      blankRowFound = true;
    } else {
      data.forEach((item) => {
        if (this.isItemBlank(item, isPrimitive, valueField)) {
          blankRowFound = true;
        }
      });
    }

    return blankRowFound;
  }

  public isItemBlank(
    item: unknown,
    isPrimitive: boolean,
    valueField: string
  ): boolean {
    let returnValue = false;

    if (item === null) {
      returnValue = true;
    } else if (isPrimitive) {
      if (typeof item === 'string' && item.trim() === '') {
        returnValue = true;
      }
    } else {
      const fieldContents = item[valueField];
      if (
        fieldContents === null ||
        (typeof fieldContents === 'string' && fieldContents.trim() === '')
      ) {
        returnValue = true;
      }
    }

    return returnValue;
  }
}
