import { Directive, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { ColumnComponent, FilterService, StringFilterMenuComponent, BooleanFilterMenuComponent, NumericFilterMenuComponent, DateFilterMenuComponent, StringFilterCellComponent, BooleanFilterCellComponent, NumericFilterCellComponent, DateFilterCellComponent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { skipEmptyCompositeFilters, patchToUiCollectionFilter, fetchSimpleCompositeFilterFromUiCollectionFilter, filterByField, removeFilter } from '../collection-filter-utils';

import * as _ from 'lodash';

/**
 * Apply this directive inside a kendo grid filter template...
 * ...on a: string|numeric|date|boolean filtter cell|menu component.
 *
 * Set at least the [collectionField] to allow filtering inside an 1:N collection!
 */
@Directive({
  selector: '[collection-filter]',
})
export class CollectionFilterDirective implements OnInit, OnDestroy {
  @Input() collectionField: string;
  @Input() collectionFieldRef: string;
  @Input() collectionItemField: string;
  @Input() collectionOperator: 'any' | 'all' = 'any';

  origFilterFnc;
  origFilterFncBound;

  filterComponent: {
    column: ColumnComponent; // FilterComponent interface
    filter: CompositeFilterDescriptor; // FilterComponent interface
  };

  constructor(
    // NOTE:
    // We need to distinguish between the FilterService provided via dependency injection,
    // and between the one that's passed to the filter-component via @Input().
    //
    // The one vid DI operates on the grid level, so it's affecting setting/fetching the state.filter property directly,
    // while the one that is component-related is holding just the filter-condition that's designed in the UI.
    //
    // We need to patch:
    // - the filter coming from grid (possibly contaning odata-list related extended queries) to a valid UI CompositeFilterDescriptor
    // - and when closing the component, we need to patch the final grid filter, where the UI expression has to be converted to the odata-list enabled query
    //
    // Sample:
    // To hook to the UI related filterService: (this.filterComponent as any).filterService.filter = filterNew;
    // To hook to the global filterService: this.filterService.filter = filterNew;
    private filterService: FilterService,
    @Optional() private stringFmc?: StringFilterMenuComponent,
    @Optional() private booleanFmc?: BooleanFilterMenuComponent,
    @Optional() private numericFmc?: NumericFilterMenuComponent,
    @Optional() private dateFmc?: DateFilterMenuComponent,
    @Optional() private stringFcc?: StringFilterCellComponent,
    @Optional() private booleanFcc?: BooleanFilterCellComponent,
    @Optional() private numericFcc?: NumericFilterCellComponent,
    @Optional() private dateFcc?: DateFilterCellComponent
  ) {
    // check if inside a component
    this.filterComponent = this.stringFmc || this.booleanFmc || this.numericFmc || this.dateFmc || this.stringFcc || this.booleanFcc || this.numericFcc || this.dateFcc;

    if (!this.filterComponent) {
      throw new Error(`'ListFilterDirective' must be defined on a FilterMenu or FilterCell Component!`);
    }
  }

  ngOnInit() {
    // VALIDATE INPUTS
    if (!this.collectionField) {
      throw new Error(`'ListFilterDirective' requires a valid 'collectionName' attribute!`);
    }

    if (!this.collectionItemField) {
      throw new Error(`'ListFilterDirective' requires a valid 'collectionItemProperty' attribute!`);
    }

    // store original filter function of filterService
    this.origFilterFnc = this.filterService.filter;
    this.origFilterFncBound = this.origFilterFnc.bind(this.filterService);

    // redirect to the new filter function
    this.filterService.filter = this.filterNew;

    /*
    // NOTE:
    // this below would work with DateFilterMenuComponent, but it's fragile and error prone on changes
    // we will use a less fragile way, by overriding the filterService.filter method and doing our patches there
    (this.dfmc as any).__proto__.__proto__.__proto__.updateFilter = this.updateFilterNew;
    (this.dfmc as any).__proto__.__proto__.__proto__.applyFilter = this.applyFilterNew;
    */

    // NOTE:
    // need to have valid CompositeFilterDescriptor for regular kendo filters to work...
    // it's a LUCK that the filter components do validate just after this event and not on data-bound (ngOnChanges)
    const uiFilter = this.patchToFilterThatDefaultKendoComponentsUnderstand(this.filterComponent.filter);
    this.filterComponent.filter = uiFilter;
  }

  ngOnDestroy() {
    // reset back to original filter function
    if (this.origFilterFnc) this.filterService.filter = this.origFilterFnc;
  }

  // NOTE:
  // must be fat arrow, for this.origFilterBound to work
  // also see note on ctor. reg. filterService on the grid (that gets injected into this directive by def.) vs. inside filter component
  // also note that if clearing filter, the gridCompositeFilter will already be updated, but the this.filterComponent.filter will still hold prev. values
  filterNew = (gridCompositeFilter: CompositeFilterDescriptor) => {
    // check if the grid's filter contains component related field, if not, then celar button was pressed. no patching needed
    // NOTE: by default the grid's composite filter is simple, so the original kendo's functions are ok to do a check
    const isClearButtonPressed = !filterByField(gridCompositeFilter, this.filterComponent.column.field);
    if (isClearButtonPressed) {
      this.origFilterFncBound(gridCompositeFilter);
      return;
    }

    // filter changed
    const collectionBlankNotBlankOverride = true;

    const uiCompositeFilter = this.filterComponent.filter;
    const patchedValue = patchToUiCollectionFilter(uiCompositeFilter, this.filterComponent.column.field, this.collectionField, this.collectionItemField, this.collectionOperator, this.collectionFieldRef, collectionBlankNotBlankOverride);

    // remove the invalid ui filter from grid
    removeFilter(gridCompositeFilter, this.filterComponent.column.field);

    // push this new filter to grid
    gridCompositeFilter.filters.push(patchedValue);

    // call original function...
    this.origFilterFncBound(gridCompositeFilter);
  };

  patchToFilterThatDefaultKendoComponentsUnderstand(compositeFilter: CompositeFilterDescriptor): CompositeFilterDescriptor {
    const innerCompositeFilter = skipEmptyCompositeFilters(compositeFilter, true);

    if (innerCompositeFilter.filters && innerCompositeFilter.filters.length === 1 && innerCompositeFilter.filters[0].operator === '$noop') {
      // we got an extended filter, which we need to parse to one kendo can understand...
      const uiCompositeFilter = fetchSimpleCompositeFilterFromUiCollectionFilter(compositeFilter, this.filterComponent.column.field);
      return uiCompositeFilter;
    } else {
      // the filter is a regular one, keep it...
      return compositeFilter;
    }
  }
}
