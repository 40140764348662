import { Injectable } from '@angular/core';
import {
  AdminDesignPanelItem,
  AdminItemMapping,
  AdminToolboxPanelItem,
  AdminPropertiesPanelItem,
  AdminPreviewPanelItem,
} from '../models/dr-admin-panel-item-models';
import { uuidv4 } from '@ups/xplat/utils';
import { DrDisplayContainerDto } from '@ups/xplat/features';
import { AdminControl } from '../models/dr-admin-models';
import { IDynamicContainer, IDynamicModel } from '@ups/xplat/core';

@Injectable({ providedIn: 'root' })
export class DrDtoConverterService {
  public toPreviewPanelItems(
    designPanelItems: AdminDesignPanelItem[]
  ): AdminPreviewPanelItem[] {
    return designPanelItems.map((i) => this.toPreviewPanelItem(i));
  }

  /*
  public toPreviewPanelItem(
    designPanelItem: AdminDesignPanelItem
  ): AdminPreviewPanelItem {
    return {
      id: designPanelItem.id ? designPanelItem.id : designPanelItem.clientId,
      controlName: designPanelItem.controlName,
      controlValues: designPanelItem.controlValues
    };
  }
  */

  public toPreviewPanelItem(
    designPanelItem: AdminDesignPanelItem
  ): AdminPreviewPanelItem {
    // return designPanelItem.controlValues;
    return {
      id: designPanelItem.id,
      controlName: designPanelItem.controlName,
      layout: designPanelItem.layout,
      data: designPanelItem.data,
    };
  }
}
