export class InventoryEntry {
  name: string;
  location: string;
  productBarcode: number;
  material: string;
  description: string;
  stdUM: string;
  inventoryNote: string;
  modifiedBy: string;
  modifiedDate: Date;
  initialCountAmount: number;
  missedCountAmount: number;
  varianceCountAmount: number;
  countVerification: number;
  finalCount: number;
  initialCountBroken: number;
  missedCountBroken: number;
  varianceCountBroken: number;
  countVerificationBroken: number;
  finalCountBroken: number;
  adminCountNote: string;
  adminCount: number;
  adminCountBroken: number;
  niftyOnHand: number;
  niftyInProcess: number;
}
