import { BaseModel } from '../base.model';

export class HRResourceTypeDto extends BaseModel {
  public HRResourceTypeID: number;
  public Name: string;
  public DisplayName: string;
  public Entity: string;
  public ManPowerRequirementID: string;

  constructor(o: any = null) {
    super(o);
  }
}
