import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Alive5Conversation, IODataResult } from '@ups/xplat/api/dto';
import {
  environment,
  MyHttpClientFactory,
  ResponseCasingEnum,
} from '@ups/xplat/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class Alive5Service {
  http: HttpClient;

  constructor(httpFactory: MyHttpClientFactory) {
    this.http = httpFactory.createHttpClient(
      environment.urls.spartaAPI,
      true,
      ResponseCasingEnum.CamelCase
    );
  }

  getMessages(
    phoneNumber: string,
    startFrom: number = 0,
    pageSize: number = 10
  ): Observable<IODataResult<Alive5Conversation>> {
    return this.http.get<IODataResult<Alive5Conversation>>(
      `/api/alive5/messages/${phoneNumber}?format=json&$count=true&$skip=${startFrom}&$top=${pageSize}&$orderby=CreatedAt%20desc`
    );
  }

  sendMessage(sender: string, receiver: string, message: string) {
    const newMessage = {
      sender: sender,
      receiver: receiver,
      message: message,
    };

    return this.http.post(`/api/alive5/messages`, newMessage);
  }
}
