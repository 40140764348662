import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import {
  BaseComponent,
  IDynamicActiveActionOptions,
  IDynamicContainerMetadata,
  IDynamicModel,
  IDynamicModelGroup,
  IDynamicPageOptions,
  IDynamicResponseBody,
  LogService,
  ProgressService,
  RouterActions,
} from '@ups/xplat/core';
import { DynamicRenderService } from '../../services/dynamic-render.service';
import {
  capitalize,
  deepClone,
  deepEqual,
  getFullNameFromInfo,
  tagCleanser,
  labelizeDataValue,
} from '@ups/xplat/utils';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import {
  dynamicCheckAccordionConditions,
  DynamicEventBusTypes,
  dynamicPrepareResponse,
  findNestedFormGroupsWith,
} from '../../utils';
import { Store } from '@ngrx/store';
import { UserState } from '@ups/user';
import { merge, Subject } from 'rxjs';

@Directive()
export abstract class DynamicPageBaseComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  editDataItem: unknown;
  isNew = true;
  isDuplicating = false;
  _ignoreAutoSuccessMessage = false;
  _showFormSuccess = false;
  get showFormSuccess() {
    return this._showFormSuccess;
  }
  set showFormSuccess(value) {
    this._showFormSuccess = value;
  }
  get showFormSuccessMessage() {
    const action = this.dynamicRender.activeFormResponse
      ? 'processed'
      : 'submitted';
    return this.dynamicRender.win.navigator.onLine
      ? `has been successfully ${action}.`
      : `will be ${action} when you're back online.`;
  }

  dynamicGroup: IDynamicModelGroup;

  controls: IDynamicModel[] = [];
  showWhatsNew = false;

  draftResponseAlert$: Subject<void> = new Subject();
  formInitState: unknown;
  formInitId: string;
  draftReady = false;
  activeFormDirtyStatus = false;

  @Input() container: IDynamicContainerMetadata;

  private _options: IDynamicPageOptions;
  private _ready = false;
  private nestedFormGroupControlNames: Array<string>;

  constructor(
    protected store: Store,
    protected log: LogService,
    protected progress: ProgressService,
    public dynamicRender: DynamicRenderService
  ) {
    super();
  }

  @Input()
  set options(value: IDynamicPageOptions) {
    this._options = value;
    if (this._options) {
      this._setupPage();
    }
  }

  get options() {
    return this._options;
  }

  ngOnInit() {
    this.progress.toggleSpinner(true);
    this.store
      .pipe(UserState.selectCurrentTruthy(this.destroy$), take(1))
      .subscribe(() => {
        this._ready = true;
        if (this._options?.name) {
          this._setupPage();
        }
      });

    /* 
      Functionality to check if the form has changed from the initial state
    */
    this.dynamicRender.eventBus
      .observe(DynamicEventBusTypes.dynamicFormReady)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        // this.dynamicRender.activeForm.valueChanges.subscribe((value) =>
        //   console.log('valueChanges', value)
        // );

        merge(
          this.dynamicRender.eventBus.observe(
            this.dynamicRender.eventBus.types.cmpReadyEvent
          ),
          this.dynamicRender.activeForm.valueChanges.pipe(debounceTime(1500))
        )
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            // console.log('form value changed', data);

            let coachingCompDirty = false;
            let reviewCompDirty = false;
            let commentCompDirty = false;

            const ignoredFields: { [key: string]: boolean } = {};

            if (data['type'] && data['instance']) {
              if (data['type'] === 'dynamic-coaching-component') {
                coachingCompDirty = data['instance']();
              }
              if (data['type'] === 'dynamic-review-component') {
                reviewCompDirty = data['instance']();
              }
              if (data['type'] === 'dynamic-comments-component') {
                commentCompDirty = data['instance']();
              }
            } else {
              // ignoredFields['Employee'] = true;
              ignoredFields['submit'] = true;
              if (!this.nestedFormGroupControlNames) {
                // we ignore grouped/nested controls since they aren't even created until opened
                this.nestedFormGroupControlNames = this.dynamicGroup.controls
                  .filter((c) => !!c.formArrayName)
                  .map((c) => c.formArrayName);
              }
              for (const name of this.nestedFormGroupControlNames) {
                ignoredFields[name] = true;
              }

              if (!this.formInitState) this.formInitState = data;

              if (!this.formInitId) {
                this.formInitId =
                  this.dynamicRender.metadata.dynamicContainerId;
              } else if (
                this.formInitId !==
                this.dynamicRender.metadata.dynamicContainerId
              ) {
                this.formInitState = data;
                this.formInitId =
                  this.dynamicRender.metadata.dynamicContainerId;
              }

              if (deepEqual(this.formInitState, data, ignoredFields, true)) {
                this.draftReady = false;
                this.activeFormDirtyStatus = false;
              } else {
                this.draftReady = true;
                this.activeFormDirtyStatus = true;
                // this.formInitState = data;
              }
            }

            if (
              !this.activeFormDirtyStatus &&
              !coachingCompDirty &&
              !reviewCompDirty &&
              !commentCompDirty
            ) {
              this.draftReady = false;
            } else if (
              !this.draftReady &&
              ((this._options.isCoaching && coachingCompDirty) ||
                ((this._options.isReview || this._options.isEditReview) &&
                  reviewCompDirty) ||
                (this._options.showComments && commentCompDirty))
            ) {
              this.draftReady = true;
            }

            // console.log('draftReady:', this.draftReady);
            // console.log('coachingCompDirty:', coachingCompDirty);
            // console.log('reviewCompDirty:', reviewCompDirty);
            // console.log('commentCompDirty:', commentCompDirty);
          });
      });

    this.dynamicRender.activeAction = (
      options?: IDynamicActiveActionOptions
    ) => {
      return new Promise((resolve) => {
        this._ignoreAutoSuccessMessage = options?.ignoreAutoSuccessMessage;
        // If it is a draft skip all validation
        if (options && options.draft) {
          this._saveFormData(resolve, options.draft);
        } else {
          // check for all validation overrides first
          // TODO: could avoid this by iterating through formgroup inside dynamic-accordion.base when complete status occurs and changing required status for all fields nested in accordions (larger task to do later as it could have performance and ux side effects)
          const groupConditions = dynamicCheckAccordionConditions(
            this.dynamicRender.activeModelGroup.controls
          );
          if (groupConditions?.applyToAllConditions) {
            if (
              groupConditions?.applyToAllConditions?.accordionRequiredValues
            ) {
              const accordionControlNames = groupConditions.allAccordions.map(
                (a) => a.formArrayName
              );
              let isAccordionStatusComplete = false;
              // total number of controls found which require another
              let requiredValueRequiresAnotherCnt = 0;
              // total number of controls found which require another which actually pass validation
              let requiredValueRequiresAnotherCompleteCnt = 0;
              for (const accordionControlName of accordionControlNames) {
                const nestedFormGroup = findNestedFormGroupsWith(
                  this.dynamicRender.activeForm,
                  accordionControlName
                )[0];
                if (nestedFormGroup) {
                  const controlNames = Object.keys(nestedFormGroup.controls);
                  // determine any specific validation requirements with accordion setings

                  for (const formControlName of controlNames) {
                    const controlValue =
                      nestedFormGroup.controls[formControlName].value;
                    if (
                      groupConditions.applyToAllConditions.accordionRequiredValues.includes(
                        controlValue
                      )
                    ) {
                      for (const requireAnother of groupConditions
                        .applyToAllConditions
                        .accordionRequiredValueRequiresAnother) {
                        const parts = requireAnother
                          .split(':')
                          .filter((p) => !!p)
                          .map((p) => p.trim());
                        const requireMatchValue = parts[0];
                        if (controlValue === requireMatchValue) {
                          requiredValueRequiresAnotherCnt++;
                          // make sure the other field has a valid value to pass requirements
                          if (parts.length > 1) {
                            const controlNameSuffixCheck = parts[1];
                            const matchingControlName = controlNames.find(
                              (name) =>
                                name ===
                                `${formControlName}${capitalize(
                                  controlNameSuffixCheck
                                )}`
                            );

                            if (matchingControlName) {
                              const matchingControl =
                                nestedFormGroup.get(matchingControlName);
                              if (matchingControl && matchingControl.value) {
                                requiredValueRequiresAnotherCompleteCnt++;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              if (
                requiredValueRequiresAnotherCnt > 0 &&
                requiredValueRequiresAnotherCnt ===
                  requiredValueRequiresAnotherCompleteCnt
              ) {
                isAccordionStatusComplete = true;
              }

              if (isAccordionStatusComplete) {
                // when reviewing forms we don't need to validate accordion groups
                // check for other field requirements outside of accordions (standard controls to ensure it's valid)
                const nonAccordionControls =
                  this.dynamicRender.activeModelGroup.controls.filter(
                    (c) => c.type !== 'accordion' && c.required
                  );
                const requiredControlLabels = [];
                for (const control of nonAccordionControls) {
                  const formControl = this.dynamicRender.activeForm.get(
                    control.formControlName
                  );
                  if (formControl && !formControl.value) {
                    // required field must contain a value
                    requiredControlLabels.push(control.label);
                  }
                }
                if (requiredControlLabels.length) {
                  this.dynamicRender.failValidation(
                    `To submit the form, please fill out these remaining fields: ${requiredControlLabels
                      .map((l) => `'${l}'`)
                      .join(', ')}.`
                  );
                  return;
                } else {
                  // VALIDATION SUCCESS: satisified all overrides and other control requirements
                  const errorMessage = this.dynamicRender.isActiveFormValid(
                    null,
                    isAccordionStatusComplete
                  );
                  if (errorMessage) {
                    this.dynamicRender.failValidation(errorMessage);
                  } else {
                    this._saveFormData(resolve);
                  }
                  // this._saveFormData(resolve);
                  return;
                }
              } else {
                let message = `You must select at least one ${groupConditions?.applyToAllConditions?.accordionRequiredValues
                  .map((v) => `${labelizeDataValue(v)}`)
                  .join(' or ')} response.`;
                const matchValues = [];
                const othersRequired = [];
                for (const requireAnother of groupConditions
                  .applyToAllConditions.accordionRequiredValueRequiresAnother) {
                  const parts = requireAnother
                    .split(':')
                    .filter((p) => !!p)
                    .map((p) => p.trim());
                  matchValues.push(parts[0]);
                  if (parts.length > 1) {
                    othersRequired.push(parts[1]);
                  }
                }
                if (matchValues.length) {
                  const additional = `For every ${matchValues
                    .map((v) => `${labelizeDataValue(v)}`)
                    .join(
                      ' or '
                    )} response, you must enter a corresponding ${othersRequired
                    .map((v) => `${labelizeDataValue(v)}`)
                    .join(' or ')}.`;
                  message += ` ${additional}`;
                }
                this.dynamicRender.failValidation(message);
                return;
              }
            }
          }

          const validationErrorMessage = this.dynamicRender.isActiveFormValid();
          if (validationErrorMessage) {
            this.dynamicRender.failValidation(validationErrorMessage);
          } else {
            this._saveFormData(resolve);
          }
        }
      });
    };
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private _saveFormData(
    resolve: (v: IDynamicResponseBody) => void,
    draft?: boolean
  ) {
    const formData: unknown = {};
    const formProperties = Object.keys(
      this.dynamicRender.activeForm.controls
    ).filter(
      (k) =>
        k !== 'submit' &&
        k !== 'coaching' &&
        k !== 'review' &&
        k !== 'copyLinkButton' &&
        k !== 'last-modified'
    );
    this.log.debug('---- submitting form data ----');

    this.dynamicRender.prepareToSubmitResponse(this.controls).then(() => {
      const dynamicControlResponses =
        this.dynamicRender.activeFormResponse?.dynamicControlResponses || [];
      dynamicPrepareResponse(
        this.controls,
        formData,
        dynamicControlResponses,
        this.dynamicRender.activeForm,
        formProperties,
        this.dynamicRender.activeAttachments
      );
      this.log.debug('formData:', formData);
      this.log.debug('dynamicControlResponses:', dynamicControlResponses);
      this.progress.toggleSpinner(true);

      this.dynamicRender
        .saveDynamicResponse(dynamicControlResponses, draft)
        .subscribe((res) => {
          this.progress.toggleSpinner(false);
          this.draftReady = false;
          this.formInitState = null;
          if (res && !res.isDraft) {
            // some implementations may want to handle their own success (review/coach)
            if (!this._ignoreAutoSuccessMessage) {
              this.showFormSuccess = true;
            }
          } else {
            this.draftResponseAlert$.next();
          }

          resolve(res);

          setTimeout(() => {
            this.showFormSuccess = false;
            if (!this.dynamicRender.activeFormResponse) {
              // reset any control instance level state in group
              this.dynamicRender.resetControlState(this.dynamicGroup.controls);
              // clear/reset form
              this.dynamicRender.clearActiveForm();
            }
          }, 3000);

          this.dynamicRender.formReviewDone$.next();
        });
    });
  }

  private _resetComponentState() {
    this.nestedFormGroupControlNames = null;
  }

  private _setupPage() {
    if (this.dynamicRender.dynamicContainerList) {
      // reset any component state on each display
      this._resetComponentState();

      let container = this.dynamicRender.dynamicContainerList.find(
        (c) => tagCleanser(c.dynamicContainerName) === this._options.name
      );

      if (!container && this._options.dynamicResponseId) {
        // unpublished form but viewing a response
        container = this.dynamicRender.dynamicContainerListAll.find(
          (c) => tagCleanser(c.dynamicContainerName) === this._options.name
        );
      }

      if (!container && this._options.dynamicContainerId) {
        this.dynamicRender.loadAllVersionContainerList().then((list) => {
          container = list.find(
            (c) => c.dynamicContainerId === this._options.dynamicContainerId
          );
          this._setupContainer(container);
        });
      } else {
        this._setupContainer(container);
      }
    }
  }

  private _setupContainer(container: IDynamicContainerMetadata) {
    if (container) {
      this.dynamicRender
        .loadContainerDetails({
          dynamicContainerId:
            container.originalVersionContainerId ||
            container.dynamicContainerId,
          versionNumber: container.versionNumber,
        })
        .then((c) => {
          this.progress.toggleSpinner(false);
          if (c) {
            // this.log.debug('loaded dynamic container for form usage:', c);
            // reset each time new container loads
            this.showWhatsNew = false;
            // now consider if data should show what's new
            const maxDate = 1000 * 60 * 60 * 24 * 14;
            if (c.dynamicContainerWhatsNew && c.modifiedDate) {
              const modifiedDate = new Date(c.modifiedDate).getTime();
              const timeSince = Date.now() - modifiedDate;
              if (timeSince <= maxDate) {
                this.showWhatsNew = true;
              }
            }

            this.dynamicRender
              .prepareContainerForDisplay(c, false, this._options)
              .then((controls) => {
                this.controls = controls;
                const ignoreDefaultButton =
                  this._options.ignoreDefaultButton ||
                  this._options.isCoaching != null ||
                  this._options.dynamicResponseId != null;
                this._configureAndDisplayForm(
                  this._options.editDataItem,
                  ignoreDefaultButton
                );
              });
          } else {
            this._alertNotFound();
          }
        });
    } else {
      this._alertNotFound();
    }
  }

  private _alertNotFound() {
    this.progress.toggleSpinner(false);
    this.dynamicRender.win
      .alert('An error occurred. We did not find this form.')
      .then(() => {
        if (this.dynamicRender.win.isMobile) {
          // if a form is not found, always redirect back to my submissions
          this.dynamicRender.ngZone.run(() => {
            this.store.dispatch(
              new RouterActions.Go({
                path: ['/page-my-submissions'],
                extras: {
                  clearHistory: true,
                },
              })
            );
          });
        }
      });
  }

  private _configureAndDisplayForm(
    dataItem?: unknown,
    ignoreDefaultButton: boolean = false
  ) {
    const isNew = this.isDuplicating || dataItem === undefined;
    this.editDataItem = dataItem || {};
    const editingControls = this.editDataItem ? [] : deepClone(this.controls);
    if (this.editDataItem) {
      // this.log.debug('this.editDataItem:', this.editDataItem);
      for (const control of this.controls) {
        const editControl: IDynamicModel = deepClone(control);
        if (
          control.formControlName === 'Employee' &&
          control.value === undefined
        ) {
          this.store
            .pipe(UserState.selectCurrentTruthy(), take(1))
            .subscribe((myInfo) => {
              editControl.value = myInfo.Data.HRRef;
              editControl.options.initWithQuery = getFullNameFromInfo(myInfo);
            });
        } else if (
          this._options.isReclassification &&
          this._options.name === 'hazard-card' &&
          control.type === 'date'
        ) {
          editControl.options.customValidations = (
            control.options.customValidations || []
          ).filter((v) => v !== 'date: greater than past 7 days');
        } else if (this.editDataItem[control.formControlName]) {
          editControl.value = this.editDataItem[control.formControlName];
          editControl.options.initWithQuery =
            this.editDataItem[control.formControlName];
        }
        editingControls.push(editControl);
      }
    }
    this.dynamicGroup = this.dynamicRender.createConfigGroup({
      // mobile uses top actionbar to title them
      groupName: this.dynamicRender.win.isMobile
        ? null
        : `${isNew ? '' : 'Edit '}${
            this.dynamicRender.metadata.dynamicContainerName
          }`,
      groupDescription:
        this.dynamicRender.metadata.dynamicContainerDescription || '',
      dynamicModels: editingControls,
      editing: !isNew,
      addCancelButton: true,
      submitButtonText: 'Submit',
      ignoreDefaultButton: ignoreDefaultButton,
      activateModelGroup: true,
      addDraftButton: true,
    });
    if (this.dynamicRender.metadata) {
      this.dynamicRender.eventBus.emit(
        this.dynamicRender.eventBus.types.headerUpdateTitle,
        {
          title: this.dynamicRender.metadata.dynamicContainerName,
          icon: this.dynamicRender.metadata.displayIcon
            ? this.dynamicRender.metadata.displayIcon
            : null,
        }
      );
    }
    this.dynamicRender.formReady$.next();
  }
}
