import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { GridModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { EmployeeJobSchedulerComponent } from './kendo-scheduler-shared.component';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';

export const routes: Routes = [
  {
    path: '',
    component: EmployeeJobSchedulerComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  declarations: [EmployeeJobSchedulerComponent],
  imports: [
    CommonModule,
    GridModule,
    ButtonsModule,
    DialogsModule,
    SchedulerModule,
    // RouterModule.forChild(routes),
  ],
  exports: [EmployeeJobSchedulerComponent],
})
export class KendoSchedulerSharedModule {
  static routes = routes;
}
