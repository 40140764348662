<kendo-grid [data]="data" 
            [sortable]="true"
            [pageable]="{
                pageSizes: pageSizes
            }"
            [filterable]="'menu'"
            [pageSize]="gridDataState.take"
            [skip]="gridDataState.skip"
            [sort]="gridDataState.sort"
            [filter]="gridDataState.filter"
            [loading]="loading"
            (dataStateChange)="gridDataStateChanged($event)">
    <kendo-grid-column field="releaseVersionNumber" title="Application Version">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <a (click)="showReleaseNote(dataItem)">{{dataItem.releaseVersionNumber}}</a>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="releaseDate" title="Release Date">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.displayDate | date:'L/d/yyyy h:mm a' }}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="lastUpdated" title="Last Updated">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.lastUpdated | date:'L/d/yyyy h:mm a' }}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Actions">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="btn-group" kendoTooltip>
                <button kendoBtn class="btn btn-success" (click)="showReleaseNote(dataItem)" title="Preview Release Note"><span class="k-icon k-i-hyperlink-open"></span></button>
            </div>
        </ng-template>
    </kendo-grid-column>
</kendo-grid>

<ups-release-note-popup></ups-release-note-popup>