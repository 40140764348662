/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import { JobDropdown, ProfileModel } from '@ups/xplat/api/dto';

const USER_DEFAULTS = '/api/userDefaults/{:auth0UserId}';
const GET_JOBS = '/api/jobs/dropdown';
const GET_DEFAULT_JOB = '/api/jobs/default';
const FAVORITE_JOB = '/api/jobs/favorite';

@Injectable({ providedIn: 'root' })
export class JobSearchService {
  private defaultJobSubject = new BehaviorSubject(null);
  public defaultJob = this.defaultJobSubject.asObservable();

  private securityClient;
  private spartaClient;
  private profile: ProfileModel;

  constructor(private clientFactory: MyHttpClientFactory) {
    this.securityClient = this.clientFactory.createHttpClient(
      environment.urls.securityAPI,
      true,
      ResponseCasingEnum.CamelCase
    );
    const urlBase = environment.urls.spartaAPI;
    this.spartaClient = this.clientFactory.createHttpClient(
      urlBase,
      true,
      ResponseCasingEnum.PascalCase
    );
    this.profile = JSON.parse(localStorage.getItem('profile')) as ProfileModel;
    // this.getDefaultJob(this.profile.auth0UserId, this.profile.hrRef);
  }

  public getUserDefaults(auth0UserId: string): any {
    const url = USER_DEFAULTS.split('{:auth0UserId}').join(auth0UserId);
    return this.securityClient.get(url).toPromise();
  }

  public async saveDefaultJob(jobID: string) {
    if (jobID) {
      this.getUserDefaults(this.profile.auth0UserId).then(async (def) => {
        def.defaultJob = jobID;
        const url = USER_DEFAULTS.split('{:auth0UserId}').join(
          this.profile.auth0UserId
        );
        this.securityClient
          .put(url, def)
          .toPromise()
          .then(async () => {
            const defaultJob = await this._getDefaultJobCall(
              this.profile.auth0UserId,
              null
            );
            this.defaultJobSubject.next(defaultJob);
          });
      });
    }
  }

  public addOrRemoveFavoriteJob(jobID: string) {
    const url = FAVORITE_JOB;
    this.spartaClient
      // eslint-disable-next-line @typescript-eslint/naming-convention
      .post(url, { UserID: this.profile.auth0UserId, JobID: jobID })
      .toPromise();
  }

  public async getDefaultJob(
    auth0UserId: string,
    hrRef: string = null
  ): Promise<any> {
    const defaultValue = await firstValueFrom(this.defaultJobSubject);
    let response;

    if (defaultValue === null) {
      response = await this._getDefaultJobCall(auth0UserId, hrRef);
      this.defaultJobSubject.next(response);
    }

    return defaultValue === null ? defaultValue : response;
  }

  public searchJobs(
    search: string,
    companyIDs: number[],
    hrRef: string = null,
    active: boolean = true
  ): Promise<JobDropdown[]> {
    let url =
      GET_JOBS + '/?searchString=' + encodeURI(search) + '&status=' + active;

    if (companyIDs && companyIDs.length) {
      companyIDs.forEach((c) => {
        url = url + `&vpCompanyIDs=${c}`;
      });
    }

    url = url + `&hrRef=${hrRef || this.profile.hrRef}`;

    return this.spartaClient.get(url).toPromise();
  }

  private async _getDefaultJobCall(auth0UserId: string, hrRef: string = null) {
    let url = GET_DEFAULT_JOB;

    if (auth0UserId) {
      url += '/' + auth0UserId;
    }

    url = url + `?hrRef=${hrRef || this.profile.hrRef}`;

    return await firstValueFrom(this.spartaClient.get(url));
  }
}
