/** Returned by UPSs backend APIs such as Sparta Api, Viewpoint Api, ...
 *  As part of the UpsApiHttpErrorResponse.
 *  If the http client is constructed as Pascal Cased.
 */
export interface ApiResult {
  /* eslint-disable @typescript-eslint/naming-convention */
  StatusCode: number;
  Message: string;
  Messages: string[];
  MessageCode: string;
  ExceptionType: string;
  ExceptionDetails: string;
  ModelValidationResult: { [key: string]: string[] };
  /* eslint-enable @typescript-eslint/naming-convention */
}

/** Returned by UPSs backend APIs such as Sparta Api, Viewpoint Api, ...
 *  As part of the UpsApiHttpErrorResponse.
 *  If the http client is constructed as Camel Cased.
 */
export interface ApiResultCamelCase {
  statusCode: number;
  message: string;
  messages: string[];
  messageCode: string;
  exceptionType: string;
  exceptionDetails: string;
  modelValidationResult: { [key: string]: string[] };
}

/**
 * Based on how the http client was constructed it can return pascal cased or camel cased results.
 * This is helper class to transform between each other.
 */
export class ApiResultTransform {
  static toPascalCase(error: ApiResultCamelCase | ApiResult): ApiResult {
    const errorCamel = error as ApiResultCamelCase;
    const errorPascal = error as ApiResult;
    const result: ApiResult = {
      /* eslint-disable @typescript-eslint/naming-convention */
      StatusCode: errorCamel?.statusCode || errorPascal?.StatusCode,
      Message: errorCamel?.message || errorPascal?.Message,
      Messages: errorCamel?.messages || errorPascal?.Messages,
      MessageCode: errorCamel?.messageCode || errorPascal?.MessageCode,
      ExceptionType: errorCamel?.exceptionType || errorPascal?.ExceptionType,
      ExceptionDetails:
        errorCamel?.exceptionDetails || errorPascal?.ExceptionDetails,
      ModelValidationResult:
        errorCamel?.modelValidationResult || errorPascal?.ModelValidationResult,
      /* eslint-enable @typescript-eslint/naming-convention */
    };

    return result;
  }

  static toCamelCase(
    error: ApiResultCamelCase | ApiResult
  ): ApiResultCamelCase {
    const errorCamel = error as ApiResultCamelCase;
    const errorPascal = error as ApiResult;
    const result: ApiResultCamelCase = {
      /* eslint-disable @typescript-eslint/naming-convention */
      statusCode: errorCamel?.statusCode || errorPascal?.StatusCode,
      message: errorCamel?.message || errorPascal?.Message,
      messages: errorCamel?.messages || errorPascal?.Messages,
      messageCode: errorCamel?.messageCode || errorPascal?.MessageCode,
      exceptionType: errorCamel?.exceptionType || errorPascal?.ExceptionType,
      exceptionDetails:
        errorCamel?.exceptionDetails || errorPascal?.ExceptionDetails,
      modelValidationResult:
        errorCamel?.modelValidationResult || errorPascal?.ModelValidationResult,
      /* eslint-enable @typescript-eslint/naming-convention */
    };

    return result;
  }
}
