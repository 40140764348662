/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { parseData } from '@ups/xplat/utils';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {
  Job2DtoExtended,
  ProspectiveJobDto,
  EmergencyContactModel,
  IODataResult,
  DashboardTradeClassDto,
} from '@ups/xplat/api/dto';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import { map } from 'rxjs/operators';

const PUT_EMPLOYEE_PROFILE_BASIC = '/api/hrrm/employee/{:id}';
const PATCH_EMPLOYEE_PROFILE_BASICDETAILS = '/api/hrrm/employee/{:hrRef}/basic';
const POST_EMPLOYEE_PROFILE_EMPLOYMENTDETAILS = '/api/hrrm/employee/employment';
const POST_EMPLOYEE_PROFILE_POSITIONDETAILS = '/api/hrrm/employee/position';
const POST_EMPLOYEE_PROFILE_BENEFITDETAILS = '/api/hrrm/employee/benefits';
const POST_EMPLOYEE_PROFILE_IDENTIFICATIONDETAILS =
  '/api/hrrm/employee/identification';

const GET_COMPANIES = '/api/hqco/company';
const GET_LICENSE_TYPES = '/api/manpowers/licensetypes';
const GET_IDCARD_TYPES = '/api/IdCard/HrAppIdCardTypes';
const GET_EMPLOYEE_POSITION_NAMES = '/api/data/employeepositions/names';

const GET_FULL_COUNTRIES = '/api/norder/full/country';
const GET_STATES = '/api/norder/states';

const ADD_OR_UPDATE_PROSPECT = '/api/manpowers/prospects/saveProspect';
const UPDATE_EMPLOYEE_FOR_SPARTA_ONLY = '/api/spartaemployee/update';
const PATCH_EMPLOYEE_FOR_SPARTA_ONLY =
  '/api/spartaemployee/patchContactAndMailing/{:hrRef}';
const GET_EMPLOYEE_INFO_BY_SSN_COMPANYID =
  '/api/hrrm/employeeInfo/{:SSN}/{:compID}';

const FETCH_JOBS = '/api/jobs-odata';
const FETCH_SPECIFICCLASSES_FOR_COMPANY =
  '/odata/companies/{:companyId}/specificClasses';
const FETCH_TRADECLASSES_FOR_COMPANY =
  '/api/manpowers/tradeclasses/company/{:companyId}';
const FETCH_ALL_TRADECLASSES = '/api/manpowers/tradeclasses/all';

const GET_HIRE_DATE =
  '/api/manpowers/hire-date/?employeeId={:employeeId}&profileId={:profileId}&PRCo={:prco}';

const GET_PROSPECTIVE_JOBS = '/api/prospective-jobs-odata';
const GET_NEXT_PROSPECTIVE_JOB_ID = '/api/prospective-job/nextid?vpCompanyID=';
const SAVE_PROSPECTIVE_JOB = '/api/prospective-job';
const CONVERT_PROSPECTIVE_JOB = '/api/job/convertProspectiveJob';
const PROSPECTIVE_JOB_CONVERSION_REPORT =
  '/api/prospective-job/conversion-report/{:prospectiveJobID}';
const GET_JOB_CUSTOMER_NAMES = '/api/job/customerNames';
const GET_JOB_LABOR_BILLING_TEMPLATES = '/api/job/laborBillingTemplates';
const GET_JOB_EQUIPMENT_BILLING_TEMPLATES =
  '/api/job/equipmentBillingTemplates';
const GET_JOB_TIMEKEEPERS = '/api/job/timeKeepers';
const GET_JOB_BILL_METHODS = '/api/job/billMethods';
const GET_EMERGENCY_CONTACTS = '/api/hrrm/employee/emergencyContacts/{:hrRef}';
const SAVE_EMERGENCY_CONTACT_INFORMATION =
  '/api/hrrm/employee/emergencyContact';
const GET_JOB_DETAILS_FROM_VPJOBID = '/api/job/jobDetails/{:jobId}';
const SEND_BULK_MESSAGE = '/api/job/bulkmessage';

@Injectable({ providedIn: 'root' })
export class JobsService {
  http: HttpClient;
  spartaPascalClient: HttpClient;
  spartaCamelClient: HttpClient;

  constructor(private httpFactory: MyHttpClientFactory) {
    this.spartaPascalClient = this.httpFactory.createHttpClient(
      environment.urls.spartaAPI,
      true,
      ResponseCasingEnum.PascalCase
    );
    this.spartaCamelClient = this.httpFactory.createHttpClient(
      environment.urls.spartaAPI,
      true,
      ResponseCasingEnum.CamelCase
    );
    this.http = httpFactory.createHttpClient('', true);
  }

  public getCompanies(userCompanies: boolean = false) {
    let url = environment.urls.viewpointAPI + GET_COMPANIES;
    if (userCompanies === true) {
      url += '?userCompanies=true';
    } else {
      url += '?userCompanies=false';
    }

    return this.http.get(url).toPromise().then(parseData);
  }

  public updateEmployeeProfileBasic(employeeProfileBasic, id: string) {
    const url =
      environment.urls.viewpointAPI +
      PUT_EMPLOYEE_PROFILE_BASIC.split('{:id}').join(id);

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    return this.http
      .put(url, employeeProfileBasic, options)
      .toPromise()
      .then(parseData);
  }

  public patchEmployeeProfileBasicDetails(hrRef, data) {
    const url =
      environment.urls.viewpointAPI +
      PATCH_EMPLOYEE_PROFILE_BASICDETAILS.split('{:hrRef}').join(hrRef);

    const patchData = [];

    if (data?.MailingAddress?.Street1) {
      patchData.push({
        op: 'replace',
        path: `/Address`,
        value: data.MailingAddress.Street1,
      });
    }

    if (data?.MailingAddress?.Street2) {
      patchData.push({
        op: 'replace',
        path: `/Address2`,
        value: data.MailingAddress.Street2,
      });
    }

    if (data?.MailingAddress?.City) {
      patchData.push({
        op: 'replace',
        path: `/City`,
        value: data.MailingAddress.City,
      });
    }

    if (data?.MailingAddress?.State) {
      patchData.push({
        op: 'replace',
        path: `/State`,
        value: data.MailingAddress.State,
      });
    }

    if (data?.MailingAddress?.PostCode) {
      patchData.push({
        op: 'replace',
        path: `/Zip`,
        value: data.MailingAddress.PostCode,
      });
    }

    if (data?.MailingAddress?.Country) {
      patchData.push({
        op: 'replace',
        path: `/Country`,
        value: data.MailingAddress.Country,
      });
    }

    if (data?.AltContactPhone) {
      patchData.push({
        op: 'replace',
        path: `/udAlternatePhone`,
        value: data.AltContactPhone,
      });
    }

    if (data?.CellPhone) {
      patchData.push({
        op: 'replace',
        path: `/CellPhone`,
        value: data.CellPhone,
      });
    }

    if (data?.Phone) {
      patchData.push({ op: 'replace', path: `/Phone`, value: data.Phone });
    }

    if (data?.Email) {
      patchData.push({ op: 'replace', path: `/Email`, value: data.Email });
    }

    if (data?.UPSEmail) {
      patchData.push({
        op: 'replace',
        path: `/udUPSEmail`,
        value: data.UPSEmail,
      });
    }

    if (data?.NickName) {
      patchData.push({
        op: 'replace',
        path: `/udNickName`,
        value: data.NickName,
      });
    }

    return this.http.patch(url, patchData).toPromise();
  }

  public getEmployeeEmergencyContacts(hrRef: string) {
    const url =
      environment.urls.viewpointAPI +
      GET_EMERGENCY_CONTACTS.split('{:hrRef}').join(hrRef);
    return this.http
      .get(url)
      .pipe(map((response) => parseData(response)))
      .toPromise();
  }

  public updateEmployeeEmergencyContact(
    emergencyContact: EmergencyContactModel
  ) {
    const url =
      environment.urls.viewpointAPI + SAVE_EMERGENCY_CONTACT_INFORMATION;

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.http.post(url, emergencyContact, options).toPromise();
  }

  public getEmployeeInfoUsingSSNAndCompanyID(SSN, compID) {
    const url =
      environment.urls.viewpointAPI +
      GET_EMPLOYEE_INFO_BY_SSN_COMPANYID.split('{:SSN}')
        .join(SSN)
        .split('{:compID}')
        .join(compID);

    return this.http.get(url).toPromise().then(parseData);
  }

  public updateEmployeeProfileForSpartaOnly(data) {
    const url = environment.urls.spartaAPI + UPDATE_EMPLOYEE_FOR_SPARTA_ONLY;

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    return this.http.put(url, data, options).toPromise();
  }

  public patchEmployeeProfileForSpartaOnly(hrRef, data) {
    const url =
      environment.urls.spartaAPI +
      PATCH_EMPLOYEE_FOR_SPARTA_ONLY.split('{:hrRef}').join(hrRef);

    const patchData = [];

    if (data?.MailingAddress?.Street1) {
      patchData.push({
        op: 'replace',
        path: `/Address`,
        value: data.MailingAddress.Street1,
      });
    }

    if (data?.MailingAddress?.Street2) {
      patchData.push({
        op: 'replace',
        path: `/Address2`,
        value: data.MailingAddress.Street2,
      });
    }

    if (data?.MailingAddress?.City) {
      patchData.push({
        op: 'replace',
        path: `/City`,
        value: data.MailingAddress.City,
      });
    }

    if (data?.MailingAddress?.State) {
      patchData.push({
        op: 'replace',
        path: `/State`,
        value: data.MailingAddress.State,
      });
    }

    if (data?.MailingAddress?.Zip) {
      patchData.push({
        op: 'replace',
        path: `/Zip`,
        value: data.MailingAddress.Zip,
      });
    }

    if (data?.AltContactPhone) {
      patchData.push({
        op: 'replace',
        path: `/AlternatePhone`,
        value: data.AltContactPhone,
      });
    }

    if (data?.CellPhone) {
      patchData.push({
        op: 'replace',
        path: `/CellPhone`,
        value: data.CellPhone,
      });
    }

    if (data?.Phone) {
      patchData.push({ op: 'replace', path: `/Phone`, value: data.Phone });
    }

    if (data?.Email) {
      patchData.push({ op: 'replace', path: `/Email`, value: data.Email });
    }

    if (data?.UPSEmail) {
      patchData.push({
        op: 'replace',
        path: `/UPSEmail`,
        value: data.UPSEmail,
      });
    }

    if (data?.NickName) {
      patchData.push({
        op: 'replace',
        path: `/Nickname`,
        value: data.NickName,
      });
    }

    return this.http.patch(url, patchData).toPromise();
  }

  public updateEmployeeProfileEmploymentDetails(data) {
    const url =
      environment.urls.viewpointAPI + POST_EMPLOYEE_PROFILE_EMPLOYMENTDETAILS;

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    return this.http.post(url, data, options).toPromise();
  }

  public updateEmployeeProfilePositionDetails(data) {
    const url =
      environment.urls.viewpointAPI + POST_EMPLOYEE_PROFILE_POSITIONDETAILS;

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    return this.http.post(url, data, options).toPromise();
  }

  public updateEmployeeProfileBenefitDetails(data) {
    const url =
      environment.urls.viewpointAPI + POST_EMPLOYEE_PROFILE_BENEFITDETAILS;

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.http.post(url, data, options).toPromise();
  }

  public updateEmployeeProfileIdentificationDetails(data) {
    const url =
      environment.urls.viewpointAPI +
      POST_EMPLOYEE_PROFILE_IDENTIFICATIONDETAILS;

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    return this.http.post(url, data, options).toPromise();
  }

  public getEmployeePositionNames() {
    const url = environment.urls.packetAPI + GET_EMPLOYEE_POSITION_NAMES;
    return this.http
      .get(url)
      .pipe(map((response) => parseData(response)))
      .toPromise();
  }

  public fetchLicenseTypes(): Observable<any> {
    const url = environment.urls.spartaAPI + GET_LICENSE_TYPES;
    return this.http.get(url);
  }

  public fetchIdCardTypes(): Observable<any> {
    const url = environment.urls.viewpointAPI + GET_IDCARD_TYPES;
    return this.http.get(url);
  }

  public handleError(error) {
    return Promise.reject(error.message || error);
  }

  public getFullCountries() {
    const url = environment.urls.viewpointAPI + GET_FULL_COUNTRIES;
    return this.http
      .get(url)
      .pipe(map((response) => parseData(response)))
      .toPromise();
  }

  public getStates() {
    const url = environment.urls.viewpointAPI + GET_STATES;
    return this.http
      .get(url)
      .pipe(map((response) => parseData(response)))
      .toPromise();
  }

  public getActiveJobsWithPlantAndPositionsOpened(): Observable<
    Job2DtoExtended[]
  > {
    const url =
      /*environment.urls.spartaAPI +*/ '/api/jobs/withManPowerPlantAssignedAndPositionsOpened';
    return this.spartaCamelClient
      .get(url)
      .pipe(map((response) => parseData(response)));
  }

  public saveProspect(prospectModel) {
    const url = environment.urls.spartaAPI + ADD_OR_UPDATE_PROSPECT;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.http
      .put(url, prospectModel, options)
      .toPromise()
      .then(parseData);
  }

  public fetchJobs(
    odataString: string,
    includeProspective: boolean = true,
    hasRequirements: boolean = true,
    requiresLodging: boolean = null
  ): Promise<any> {
    let url = environment.urls.spartaAPI + FETCH_JOBS;

    url += '?$format=json&$count=true';

    if (!includeProspective) {
      url += '&includeProspective=false';
    }

    if (!hasRequirements) {
      url += '&hasRequirements=false';
    }

    if (requiresLodging) {
      url += '&requiresLodging=' + requiresLodging;
    }

    url += odataString ? '&' + odataString : '';

    return this.http.get(url).pipe(map(parseData)).toPromise();
  }

  public fetchSpecificClassesForCompany(
    companyId: string,
    odataString: string
  ): Promise<any> {
    let url =
      environment.urls.spartaAPI +
      FETCH_SPECIFICCLASSES_FOR_COMPANY.split('{:companyId}').join(companyId);

    url += '?$format=json&$count=true';
    url += odataString ? '&' + odataString : '';

    return this.http.get(url).pipe(map(parseData)).toPromise();
  }

  public fetchTradeClassesForCompany(
    companyId: string,
    odataString: string,
    skipCompany: boolean
  ): Observable<IODataResult<DashboardTradeClassDto>> {
    let url = skipCompany
      ? FETCH_ALL_TRADECLASSES
      : FETCH_TRADECLASSES_FOR_COMPANY.split('{:companyId}').join(companyId);

    url += '?$format=json&$count=true';
    url += odataString ? '&' + odataString : '';

    return this.spartaCamelClient.get<IODataResult<DashboardTradeClassDto>>(
      url
    );
  }

  public getHireDate(employeeId, profileId, prco): Promise<any> {
    const url =
      environment.urls.spartaAPI +
      GET_HIRE_DATE.split('{:employeeId}')
        .join(employeeId)
        .split('{:profileId}')
        .join(profileId)
        .split('{:prco}')
        .join(prco);

    return this.http.get(url).pipe(map(parseData)).toPromise();
  }

  public getProspectiveJobs(odatastring: string, activeOnly: boolean = true) {
    let url = environment.urls.spartaAPI + GET_PROSPECTIVE_JOBS;

    url += '?$format=json&$count=true';

    if (activeOnly) {
      url += '&isActive=true';
    }

    url += odatastring ? '&' + odatastring : '';

    return this.http.get(url).pipe(map((response) => parseData(response)));
  }

  public getNextProspectiveJobID(vpCompanyID: string) {
    const url =
      environment.urls.spartaAPI + GET_NEXT_PROSPECTIVE_JOB_ID + vpCompanyID;
    return this.http
      .get(url, { responseType: 'text' })
      .toPromise()
      .then(parseData);
  }

  public saveProspectiveJob(model: ProspectiveJobDto) {
    const url = environment.urls.spartaAPI + SAVE_PROSPECTIVE_JOB;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.http.post(url, model, options).toPromise().then(parseData);
  }

  public convertProspectiveJob(prospectiveJobID: string, jobID: string) {
    const url = environment.urls.spartaAPI + CONVERT_PROSPECTIVE_JOB;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .put(
        url,
        { ProspectiveJobID: prospectiveJobID, JobID: jobID },
        { headers: headers, responseType: 'blob' }
      )
      .toPromise();
  }

  public getProspectiveJobConversionReport(prospectiveJobID: string) {
    const url =
      environment.urls.spartaAPI +
      PROSPECTIVE_JOB_CONVERSION_REPORT.split('{:prospectiveJobID}').join(
        prospectiveJobID
      );
    return this.http.get(url, { responseType: 'blob' }).toPromise();
  }

  public getJobCustomerNames(vpCompanyID: number = null) {
    let url = environment.urls.spartaAPI + GET_JOB_CUSTOMER_NAMES;

    if (vpCompanyID) {
      url = url + '?vpCompanyID=' + vpCompanyID;
    }

    return this.http.get(url).toPromise().then(parseData);
  }

  public getJobLaborBillingTemplates(vpCompanyID: number = null) {
    let url = environment.urls.spartaAPI + GET_JOB_LABOR_BILLING_TEMPLATES;

    if (vpCompanyID) {
      url = url + '?vpCompanyID=' + vpCompanyID;
    }

    return this.http.get(url).toPromise().then(parseData);
  }

  public getJobEquipmentBillingTemplates(vpCompanyID: number = null) {
    let url = environment.urls.spartaAPI + GET_JOB_EQUIPMENT_BILLING_TEMPLATES;

    if (vpCompanyID) {
      url = url + '?vpCompanyID=' + vpCompanyID;
    }

    return this.http.get(url).toPromise().then(parseData);
  }

  public getJobTimekeepers(vpCompanyID: number = null) {
    let url = environment.urls.spartaAPI + GET_JOB_TIMEKEEPERS;

    if (vpCompanyID) {
      url = url + '?vpCompanyID=' + vpCompanyID;
    }

    return this.http.get(url).toPromise().then(parseData);
  }

  public getJobBillMethods() {
    const url = environment.urls.spartaAPI + GET_JOB_BILL_METHODS;

    return this.http.get(url).toPromise().then(parseData);
  }

  public getJobDetails(jobId: string) {
    const url =
      environment.urls.spartaAPI +
      GET_JOB_DETAILS_FROM_VPJOBID.split('{:jobId}').join(jobId);

    return this.http.get(url).pipe(map((data) => parseData(data)));
  }

  public sendBulkMessage(
    hrRefs: number[],
    message: string,
    excludeCautionEmployees: boolean
  ) {
    const url = environment.urls.spartaAPI + SEND_BULK_MESSAGE;
    hrRefs = hrRefs.filter((hrRef) => hrRef !== 0);

    if (message?.length) {
      // eslint-disable-next-line no-undef
      const profile = JSON.parse(localStorage.getItem('profile'));

      return this.http.post(`${url}/${excludeCautionEmployees}`, {
        HRRefs: hrRefs,
        SenderPhone: profile.workPhone
          ? profile.workPhone.replace(/\D/g, '')
          : '',
        Message: message,
      });
    }
  }

  search(searchString: string, limit: number = 20) {
    return this.spartaPascalClient
      .get(`/api/jobs/dropdown`, {
        params: {
          searchString,
          take: `${limit}`,
        },
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
