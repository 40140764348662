import { BaseModel } from '../base.model';

export class ManpowerAttachmentModel extends BaseModel {
  DateModified: any = '';
  Description: any = '';
  FileID: any = '';
  FileName: any = '';
  ManPowerAttachmentID: any = '';
  ManPowerReqID: any = '';
  ModifiedUserID: any = '';
  ModifiedUserName: any = '';
  PlantID: any = '';
  Type: any = '';
  TypeName: any = '';
  bActive: any = '';
  File: any = null;

  constructor(o: any = null) {
    super(o);
    if (o) {
      Object.assign(this, o);
    }
  }
}
