/* eslint-disable @typescript-eslint/naming-convention */
import { ManPowerSubRequirementLogDto } from './ManPowerSubRequirementLogDto';

export class ManpowerReqLogDto {
  ManPowerRequirementId: string | null;
  DateModified: string;
  ModifiedUser: string;
  Name: string;
  Waiver: boolean;
  WaiverDays: number | null;
  Billable: boolean | null;
  NonDisa: boolean | null;
  DisaPolicy: string;
  Active: boolean;
  ManPowerSubRequirements: ManPowerSubRequirementLogDto[];

  constructor() {
    this.DateModified = '';
    this.ModifiedUser = '';
    this.Name = '';
    this.Waiver = false;
    this.WaiverDays = null;
    this.Billable = null;
    this.NonDisa = null;
    this.DisaPolicy = '';
    this.Active = false;
  }
}
