<kendo-dialog *ngIf="show" [minWidth]="400">
  <form
    name="editForm"
    #f="ngForm"
    #formElement
    class="d-flex flex-column"
    style="gap: 1.25rem"
  >
    <div *ngFor="let field of fields; let i = index">
      <label
        class="d-block k-label"
        for="editField{{ i }}"
        *ngIf="field.type !== 'checkbox'"
      >
        {{ field.label || field.formControlName }}
        <span *ngIf="field.required" class="c-red">*</span>
      </label>
      <ng-container [ngSwitch]="field.type" *ngIf="field.type !== 'label'">
        <div *ngSwitchCase="'file'">
          <kendo-fileselect
            (select)="fileChange($event, field)"
            [required]="field.required"
            ngModel
            class="w-100"
            name="editField{{ i }}"
          ></kendo-fileselect>
        </div>
        <div *ngSwitchCase="'date'">
          <ups-kendo-datepicker-shared
            [(ngModel)]="changedModel[field.formControlName]"
            [required]="field.required"
            [readOnlyInput]="true"
            [min]="field.options?.minDate"
            [isFullWidth]="true"
            name="editField{{ i }}"
          ></ups-kendo-datepicker-shared>
        </div>
        <div *ngSwitchCase="'phone'">
          <kendo-maskedtextbox
            [mask]="'(000) 000-0000'"
            [(ngModel)]="changedModel[field.formControlName]"
            [required]="field.required"
            class="w-100"
            name="editField{{ i }}"
          ></kendo-maskedtextbox>
        </div>
        <div *ngSwitchCase="'checkbox'" class="d-flex align-items-center">
          <label class="ps-2 k-label" for="editField{{ i }}">
            <input
              [(ngModel)]="changedModel[field.formControlName]"
              [required]="field.required"
              [id]="'editField' + i"
              [name]="'editField' + i"
              type="checkbox"
              kendoCheckBox
            />
            {{ field.label || field.formControlName }}
          </label>
        </div>
        <div
          *ngSwitchCase="'addressautocomplete'"
          class="d-flex align-items-center"
        >
          <input
            type="text"
            class="form-control"
            placeholder="Type your address"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="off"
            type="text"
            #search
          />
        </div>
        <div *ngSwitchDefault>
          <input
            class="k-textbox w-100"
            name="editField{{ i }}"
            [type]="field.type"
            [(ngModel)]="changedModel[field.formControlName]"
            [pattern]="field.regex"
            [required]="field.required === true"
            [maxlength]="
              field.options?.maxlength ? field.options?.maxlength : null
            "
          />
        </div>
      </ng-container>
    </div>
  </form>
  <kendo-dialog-actions kendoTooltip>
    <button kendoButton class="" (click)="cancel()">Cancel</button>
    <button
      kendoButton
      class="k-primary"
      [disabled]="!f.valid || filesInProgress > 0"
      (click)="save()"
      [title]="filesInProgress > 0 ? 'Please wait for files to upload' : 'save'"
    >
      Save
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
