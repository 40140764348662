import {
  Input,
  Output,
  EventEmitter,
  Directive,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import { DynamicItemBaseComponent } from '../dynamic-item/dynamic-item.base-component';
import { catchError, filter, map, takeUntil } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { IDynamicModel } from '@ups/xplat/core';
import { isObject, noop } from '@ups/xplat/utils';
import { DynamicEventBusTypes } from '../../utils';
import { HttpParams } from '@angular/common/http';

/* eslint-disable */
type SelectedItemType = {
  CompanyName?: string;
  CustomerName?: string;
  FullName?: string;
  DisplayName?: string;
  Id?: string;
  Name?: string;
} & string;
/* eslint-enable */

@Directive()
export abstract class DynamicTypeaheadBaseComponent
  extends DynamicItemBaseComponent
  implements OnInit, AfterViewInit
{
  // bind this to the api service call in the component implementation
  searchQuery: (...args: Array<unknown>) => Observable<unknown>;

  // allows an id to be shown in the dropdown options
  displayIdPrefix: boolean;

  /* eslint-disable */
  selectedItem: SelectedItemType;
  /* eslint-enable */
  selectedItems: Array<SelectedItemType>;
  multiSelectValues: Array<SelectedItemType>;
  // each implementation can define a custom filter function to query it's data against
  queryFilter: (query: string, data: unknown) => boolean;

  @Input() isMultiSelect = false;
  @Input() showMultiSelectCheckbox = false;
  // used when a use case desires to explicitly hide a typeahead search input
  @Input() hideSearch: boolean;
  // any typeahead components can provide their own additional custom query params
  @Input() customQueryParams: unknown;

  @Output() loaded: EventEmitter<unknown> = new EventEmitter();
  @Output() valueChange: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() valuesChange: EventEmitter<Array<unknown>> = new EventEmitter<
    Array<unknown>
  >();
  @Output() remove: EventEmitter<unknown> = new EventEmitter();

  private touchedCallback: () => void = noop;
  private changeCallback: (_: unknown) => void = noop;

  private _matchingTargetProperty: string;

  get value(): SelectedItemType {
    return this.selectedItem;
  }

  set value(v: SelectedItemType) {
    if (v !== this.selectedItem) {
      this.selectedItem = v;
      if (v) {
        this.selectedItems = [v];
      } else {
        this.selectedItems = [];
      }
      this.changeCallback(v);
    }
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.config.options) {
      this.displayIdPrefix = this.config.options.displayIdPrefix;
    }

    this.dynamicService.itemTargetUpdate$
      .pipe(
        filter(
          (updates) =>
            !!(
              updates.formControlName === this.config.formControlName &&
              updates.options
            )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((updates: IDynamicModel) => {
        if (
          !this.config.readOnly &&
          updates.options.typeahead &&
          updates.options.typeahead.matchTargetProperty
        ) {
          this.isLoading = true;
          this._matchingTargetProperty =
            updates.options.typeahead.matchTargetProperty;
          this.filterChangeItem({ query: updates.value || '' }).then(
            (result) => {
              if (result && Array.isArray(result)) {
                const valueLowerCase = updates.value?.toString()?.toLowerCase();
                const valueMatch = result.find(
                  (item) =>
                    item[this._matchingTargetProperty]
                      ?.toString()
                      ?.toLowerCase() === valueLowerCase
                );
                if (
                  valueMatch ||
                  updates.options.typeahead.setNullValueIfNotFoundInSearch
                ) {
                  this.dynamicService.ngZone.run(() => {
                    this.updateValue(valueMatch);
                    if (valueMatch) {
                      if (this.config?.options?.isMultiSelect) {
                        const currentLength = this.selectedItems.length;
                        if (
                          this.selectedItems.filter(
                            (i) =>
                              i[this._matchingTargetProperty] ===
                              valueMatch[this._matchingTargetProperty]
                          ).length === 0
                        ) {
                          // didn't exist in multi-select, add it
                          this.selectedItems.push(valueMatch);
                        }

                        if (this.selectedItems.length < currentLength) {
                          this.valuesChange.emit(this.selectedItems);
                        }
                      } else {
                        this.valueChangeItem(valueMatch);
                      }
                    }
                  });
                } else {
                  this._matchingTargetProperty = null;
                }
              }

              this.isLoading = false;
            }
          );
        }
      });

    this.dynamicService.eventBus
      .observe(this.dynamicService.eventBus.types.filterTypeahead)
      .pipe(
        filter(
          (result: { id: string }) =>
            !!result && result.id === this.config?.formControlName
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (result: { id: string; query: string; initWithQuery?: string }) => {
          if (result.initWithQuery) {
            // used to reinit selected value(s) in the control (when already visible on screen)
            // first reset any currently selected items to completely reinit them
            this.resetSelectedItems();
            if (!this.config.options) {
              this.config.options = {};
            }
            this.valueObjectId = this.config.options.initWithQuery =
              result.initWithQuery;
            this.initCheckValue();
          } else {
            this.isLoading = true;
            this.filterChangeItem({ query: result.query }).then(() => {
              this.isLoading = false;
            });
          }
        }
      );

    this.dynamicService.eventBus
      .observe(DynamicEventBusTypes.dynamicFormResetState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.resetSelectedItems();
      });
  }

  ngAfterViewInit() {
    this.initCheckValue();

    this.loaded.emit(this);
  }

  initCheckValue() {
    if (this.valueObjectId) {
      if (
        this.config?.options?.isMultiSelect &&
        this.config?.options?.initWithQuery
      ) {
        // with multiselect, ensure all results return and the full collection is then filtered
        // ensure initWithQuery is a string - can be a number when singular value
        this.multiSelectValues = `${this.config.options.initWithQuery}`.split(
          ','
        );
        if (this.multiSelectValues.length) {
          // enumerate values, querying api for each (to ensure deep search matches found)
          // start with first value
          this.config.options.initWithQuery = this.multiSelectValues[0];
        } else {
          this.config.options.initWithQuery = '';
        }
      }
      // find a match to prefill
      this.findMatchToPrefill(
        this.config?.options?.initWithQuery,
        this.multiSelectValues ? this.multiSelectValues.length === 1 : true
      );
    }
  }

  findMatchToPrefill(query: string, complete = true) {
    this.isLoading = true;
    this.filterChangeItem(
      {
        query: query || '',
      },
      this.config.options.valueQueryParamName
    ).then((result) => {
      if (result && Array.isArray(result)) {
        if (this.config?.options?.isMultiSelect) {
          const ids =
            this.valueObjectId?.toString()?.toLowerCase()?.split(',') ?? [];
          const valueProperty = this.config?.valueProperty || 'Id';
          const valuesMatch = result.filter((item) =>
            ids.includes(item[valueProperty]?.toString()?.toLowerCase())
          );
          if (valuesMatch && valuesMatch.length) {
            if (this.multiSelectValues) {
              // accumulate results together until complete
              this.selectedItems = this.selectedItems || [];
              for (const value of valuesMatch) {
                // prevent dupes
                const compareValue = value[valueProperty]?.toString();
                if (
                  !this.selectedItems.find(
                    (selectedItem) =>
                      selectedItem[valueProperty]?.toString() === compareValue
                  )
                ) {
                  this.selectedItems.push(value);
                }
              }
            } else {
              this.selectedItems = valuesMatch;
            }
            if (complete) {
              this.multiSelectValues = null;
              this.updateValue(this.selectedItems);
            } else if (this.multiSelectValues) {
              this.multiSelectValues.splice(0, 1);
              this.findMatchToPrefill(
                this.multiSelectValues[0],
                this.multiSelectValues.length === 1
              );
            }
          }
        } else {
          const valueMatch = result.find((item) => {
            const valuePropertyFallback = item?.hasOwnProperty('Id')
              ? 'Id'
              : 'id';

            if (this.valueObjectId) {
              return (
                item[this.config?.valueProperty || valuePropertyFallback]
                  ?.toString()
                  ?.toLowerCase() ===
                this.valueObjectId?.toString()?.toLowerCase()
              );
            } else {
              return (
                item[
                  this.config?.valueProperty || valuePropertyFallback
                ]?.toString() === this.config?.options?.initWithQuery
              );
            }
          });

          if (valueMatch) {
            this.selectedItem = valueMatch;
            this.updateValue(valueMatch);
          }
        }
      }
      if (complete) {
        this.isLoading = false;
      }
    });
  }

  resetSelectedItems() {
    this.selectedItem = null;
    this.selectedItems = null;
  }

  clear() {
    // this helps ensure the dropdown resets back to placeholder
    this.setValue(null);
  }

  setValue(value: unknown) {
    console.log(
      'component implementations can set component values by overriding setValue:',
      value
    );
  }

  /* eslint-disable */
  itemDisabled(itemArgs: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    dataItem: { Name?: string; Id?: string };
    index: number;
  }) {
    return !itemArgs || itemArgs?.dataItem?.Name === this.config?.placeholder;
  }
  /* eslint-enable */

  onBlur() {
    this.touchedCallback();
  }

  writeValue(value: SelectedItemType) {
    if (value !== this.selectedItem) {
      this.selectedItem = value;
      if (value) {
        this.selectedItems = [value];
      } else {
        this.selectedItems = [];
      }
    }
  }

  registerOnChange(fn: (_: unknown) => void) {
    this.changeCallback = fn;
  }

  registerOnTouched(fn: () => void) {
    this.touchedCallback = fn;
  }

  filterChangeItem($event, valueQueryParamName: string = null) {
    // filterChangeItem can by called by dynamic-.. component where it controls isLoading explicitly,
    // but also can be called as callback of seFilterChangeEx where we control the isLoading here;
    let isLoadingWasSetHere = false;

    if (!this.isLoading) {
      this.isLoading = true;
      isLoadingWasSetHere = true;
    }

    const setNotLoading = () =>
      (this.isLoading = isLoadingWasSetHere ? false : this.isLoading);

    return new Promise((resolve) => {
      this.dynamicService.ngZone.run(() => {
        let scopeDefaultQuery = '';
        let scopeDefaultQueryParamName = '';
        if (this.config.options?.scopeQueryWhenFormControlIsSet) {
          // check for the value from the form group and scope the query to it when no explicit query is searched for (ie, opening dropdown for first time)
          const scopeControl =
            this.group.controls[
              this.config.options?.scopeQueryWhenFormControlIsSet
                .formControlName
            ];
          if (scopeControl && !scopeControl['isLoading_dynamic']) {
            scopeDefaultQuery = scopeControl.value;
            if (
              isObject(scopeDefaultQuery) &&
              this.config.options?.scopeQueryWhenFormControlIsSet
                .matchTargetProperty
            ) {
              scopeDefaultQuery =
                scopeDefaultQuery[
                  this.config.options?.scopeQueryWhenFormControlIsSet
                    .matchTargetProperty
                ];
            }
            scopeDefaultQueryParamName =
              this.config.options.scopeQueryWhenFormControlIsSet.queryParamName;
            // console.log('scopeDefaultQuery:', scopeDefaultQuery)
          }
        }

        if (this.config?.options?.dataResolver) {
          this.config?.options?.dataResolver().then((data: Array<unknown>) => {
            const mappedData = this.filterData(
              data,
              scopeDefaultQuery || $event.query
            );
            if ($event.extension) {
              $event.extension.applyData(mappedData);
            }
            this.dynamicService.eventBus.emit(
              this.dynamicService.eventBus.types.updateTypeaheadData,
              mappedData
            );
            setNotLoading();
            resolve(mappedData);
          });
        } else if (this.config?.options?.dropdownOptions) {
          const mappedData = this.filterData(
            this.config?.options?.dropdownOptions,
            scopeDefaultQuery || $event.query
          );
          if ($event.extension) {
            $event.extension.applyData(mappedData);
          }
          this.dynamicService.eventBus.emit(
            this.dynamicService.eventBus.types.updateTypeaheadData,
            mappedData
          );
          setNotLoading();
          resolve(mappedData);
        } else {
          let params = new HttpParams();
          if (scopeDefaultQuery && scopeDefaultQueryParamName) {
            params = params.append(
              scopeDefaultQueryParamName,
              scopeDefaultQuery
            );
            scopeDefaultQuery = '';
          }
          if (this.customQueryParams) {
            for (const key in <object>this.customQueryParams) {
              params = params.append(key, this.customQueryParams[key]);
            }
          }
          if (valueQueryParamName && $event.query) {
            params = params.append(valueQueryParamName, $event.query);
          }
          this.searchQuery?.(scopeDefaultQuery || $event.query, params)
            .pipe(
              map(this.apiModelMapper.bind(this)),
              catchError(() => {
                if ($event.extension) {
                  $event.extension.applyData([]);
                }
                return of([]);
              })
            )
            .subscribe((data: Array<unknown>) => {
              if (
                !this.dynamicService.win.navigator.onLine &&
                $event.query &&
                this.queryFilter
              ) {
                // when clients are offline the query won't filter remotely so filter on the full cached offline result
                data = data.filter((d) => {
                  return this.queryFilter($event.query, d);
                });
              }
              // console.log('offline:', data.filter((d: any) => d.LastName === 'leal').map((d: any) => {
              //   return {
              //     first: d.FirstName,
              //     last: d.LastName
              //   }
              // }));
              if ($event.extension) {
                $event.extension.applyData(data);
              }
              this.dynamicService.eventBus.emit(
                this.dynamicService.eventBus.types.updateTypeaheadData,
                data
              );

              setNotLoading();

              resolve(data);
            });
        }
      });
    });
  }

  valueChangeItems(items: Array<SelectedItemType>) {
    this.selectedItems = items;
    if (items && items.length) {
      this.valueChangeItem(items[0]);
    } else {
      this.valueChangeItem(null);
    }
  }

  valueChangeItem(item: SelectedItemType) {
    this.selectedItem = item;
    if (this.config?.options?.updateOnValueChange) {
      this.config?.options?.updateOnValueChange.forEach((selectionConfig) => {
        if (
          selectionConfig.formControlName &&
          this.selectedItem &&
          !this.selectedItem[this._matchingTargetProperty]
        ) {
          let propertyValuePromise: Promise<string | number>;
          // set the loading indicator on the target control as we are already calculating data for it here
          const targetLoadingModel: IDynamicModel = {
            formControlName: selectionConfig.formControlName,
            type: 'target-loading',
            value: 1,
          };

          this.dynamicService.itemTargetUpdate$.next(targetLoadingModel);

          if (selectionConfig.calculatedPropertyValue != null) {
            propertyValuePromise = selectionConfig
              .calculatedPropertyValue(
                this.selectedItem,
                this.config,
                this.dynamicService.activeForm
              )
              .toPromise();
          } else {
            propertyValuePromise = Promise.resolve(
              this.selectedItem[selectionConfig.property]
            );
          }

          propertyValuePromise.then((propertyValue) => {
            // only trigger 2 way field updates when not already matching from another
            const targetModel: IDynamicModel = {
              formControlName: selectionConfig.formControlName,
              type: 'target',
              value: propertyValue,
            };

            if (selectionConfig.matchTargetProperty) {
              targetModel.options = {
                typeahead: {
                  matchTargetProperty: selectionConfig.matchTargetProperty,
                  setNullValueIfNotFoundInSearch:
                    selectionConfig.calculatedPropertyValue != null, // force to set the target value to null if no result was found when using calculatedPropertyValue
                },
              };
            }

            this.dynamicService.itemTargetUpdate$.next(targetModel);
          });
        }
      });

      this._matchingTargetProperty = null;
    }
    if (item) {
      if (this.config.options?.isMultiSelect) {
        if (!this.selectedItems) {
          this.selectedItems = [];
        }
        const valueProperty = this.config?.valueProperty || 'Id';
        if (
          !this.selectedItems.some(
            (selectedItem) =>
              selectedItem[valueProperty] === item[valueProperty]
          )
        ) {
          this.selectedItems.push(item);
        } else {
          return;
        }
      } else {
        this.selectedItems = [item];
      }
    } else {
      this.selectedItems = [];
    }

    this.valueChange.emit(item);
    this.valuesChange.emit(this.selectedItems);
  }

  onMultiSelectCheckboxChange() {
    if (!this.isMultiSelect) {
      if (this.selectedItems && this.selectedItems.length) {
        this.valueChangeItem(this.selectedItems[0]);
      } else {
        this.valueChangeItem(null);
      }
    }
  }

  removeItem(item: unknown, property: string) {
    if (this.isDisabled()) {
      return;
    }
    if (this.config?.options?.isMultiSelect) {
      const currentLength = this.selectedItems.length;
      this.selectedItems = this.selectedItems.filter(
        (val) => val[property] !== item[property]
      );
      this.remove.emit(item);
      if (this.selectedItems.length < currentLength) {
        this.valuesChange.emit(this.selectedItems);
      }
    } else {
      this.valueChangeItem(null);
    }
    if (!this.selectedItems || this.selectedItems?.length === 0) {
      this.updateValue(null);
      if (this.config?.required) {
        // when clearing out, always reset validity if needed
        this.setRequired();
      }
    }
  }
}
