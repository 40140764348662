<!-- <kendo-window title="Design preview" *ngIf="previewOpen" (close)="closePreview()" [(width)]="previewWindowWidth"
  [(height)]="previewWindowHeight">
  <admin-preview-web [dynamicGroup]="dynamicGroup"></admin-preview-web>
</kendo-window> -->

<div *ngIf="!canView" class="p-5">
  <span class="text-danger">
    Insufficient Permissions to access the Form Builder!
  </span>
</div>

<div *ngIf="canView" class="dr-admin">
  <div class="dr-admin-header">
    <div class="dr-admin-header-name">
      <label
        style="
          float: left;
          margin-top: 10px;
          margin-right: 10px;
          color: var(--color-primary);
          font-size: 1rem;
          font-weight: 600;
        "
      >
        Form Name:
      </label>
      <div style="float: left; min-width: 250px">
        <ups-dynamic-input
          [group]="formGroup"
          [config]="configName"
          [dynamicService]="dynamicService"
        ></ups-dynamic-input>
        <!-- <span class="dr-container-meta">Last change: 09/03/2020, 02:35 AM</span> -->
      </div>
      <button
        *ngIf="canModify"
        class="ms-5 me-3"
        kendoButton
        [iconClass]="'far fa-plus'"
        (click)="addNewContainer()"
        title="Reset Builder and Create a New Form"
      ></button>
      <button
        kendoButton
        class="me-3 ms-3"
        [iconClass]="
          showDetailsBar ? 'far fa-chevron-up' : 'far fa-chevron-down'
        "
        (click)="showDetails()"
      ></button>
    </div>

    <div class="ms-auto dr-admin-actions">
      <div class="picker-wrapper">
        <span class="label">Saved Forms:</span>
        <ups-dr-container-picker
          [defaultItem]="selectedContainer"
          [list]="dynamicService.dynamicContainerListAll"
          (containerSelected)="onContainerSelected($event)"
          (loaded)="loadedPicker($event)"
        ></ups-dr-container-picker>
      </div>

      <button
        *ngIf="canModify"
        kendoButton
        [iconClass]="'far fa-copy'"
        (click)="duplicateForm()"
        style="transform: scaleX(-1)"
        title="Copy Form"
      ></button>

      <button
        kendoButton
        [iconClass]="'far fa-history'"
        [class.k-primary]="dynamicService.showingAllVersions"
        (click)="showAllVersions()"
        style="transform: scaleX(-1)"
        title="Show All Versions"
      ></button>

      <button
        kendoButton
        [iconClass]="'far fa-redo-alt'"
        (click)="dynamicService.loadContainerList(true, false, true)"
        style="transform: scaleX(-1)"
        title="Refresh Saved Forms"
      ></button>

      <button
        *ngIf="canModify"
        kendoButton
        [iconClass]="'far fa-trash-alt'"
        (click)="deleteContainer()"
        title="Delete Form"
      ></button>
    </div>
  </div>

  <ng-template [ngIf]="showDetailsBar">
    <div
      class="dr-admin-sub-header"
      [ngStyle]="
        configMetadataValidations?.length && !minimizeCustomRules
          ? { height: 'auto' }
          : null
      "
    >
      <div style="clear: both">
        <div style="float: left; width: 500px; margin-right: 10px">
          <ups-dynamic-input
            [group]="formGroup"
            [config]="configDescription"
            [dynamicService]="dynamicService"
          ></ups-dynamic-input>
        </div>

        <div style="float: left; width: 250px; margin-right: 10px">
          <ups-dynamic-input
            [group]="formGroup"
            [config]="configWhatsNew"
            [dynamicService]="dynamicService"
          ></ups-dynamic-input>
        </div>

        <div style="float: left; width: 300px; margin-right: 10px">
          <ups-dynamic-typeahead-icons
            [group]="formGroup"
            [config]="configDisplayIcon"
            [dynamicService]="dynamicService"
            (loaded)="loadedTypeaheadIcons($event)"
          ></ups-dynamic-typeahead-icons>
        </div>

        <!-- <div style="float:left; width: 80px;">
          <ups-dynamic-input [group]="formGroup" [config]="configDisplayOrder" [dynamicService]="dynamicService">
          </ups-dynamic-input>
        </div> -->
      </div>
      <div style="clear: both">
        <div style="float: left; width: 500px; margin-right: 10px">
          <ups-dynamic-typeahead
            [group]="formGroup"
            [config]="configAppTag"
            [dynamicService]="dynamicService"
          ></ups-dynamic-typeahead>
        </div>
        <div style="float: left; width: 550px; margin-right: 10px">
          <ups-dynamic-typeahead
            [group]="formGroup"
            [config]="configCategory"
            [dynamicService]="dynamicService"
          ></ups-dynamic-typeahead>
        </div>
      </div>
      <!-- <div style="clear: both">
        <div style="margin-top:10px;">
          <button
            kendoButton
            [iconClass]="'far fa-plus'"
            (click)="addNewMetadataValidation()"
            title="Add a Custom Validation Rule"
          ></button>
          &nbsp;Add <span>{{configMetadataValidations?.length ? 'another' : 'a'}}</span> Custom Validation Rule
          <button
            kendoButton
            [iconClass]="minimizeCustomRules ? 'far fa-chevron-up' : 'far fa-chevron-down'"
            (click)="minimizeCustomRules=!minimizeCustomRules"
            title="show/hide custom rules"
          ></button>
        </div>
        <div [hidden]="minimizeCustomRules">
          <div *ngFor="let configMetadataValidation of configMetadataValidations" style="width: 500px; margin-right: 10px">
            <ups-dynamic-typeahead
              [group]="formGroup"
              [config]="configMetadataValidation"
              [dynamicService]="dynamicService"
            ></ups-dynamic-typeahead>
          </div>
        </div>
      </div> -->
    </div>
  </ng-template>

  <div class="dr-admin-body" cdkDropListGroup>
    <section class="dr-admin-content">
      <admin-form-builder-web
        #adminDesign
        (itemSelected)="onDesignerItemSelected($event)"
        (nestedItemSelected)="onDesignerItemSelected($event)"
        (selectSavedForm)="selectSavedForm($event)"
      ></admin-form-builder-web>
      <!-- <admin-design-web
        #adminDesign
        (designSave)="onDesignSave($event)"
        (itemSelected)="onDesignerItemSelected($event)"
        (deleteControl)="onDeleteControl($event)"
      ></admin-design-web> -->
    </section>

    <div *ngIf="canModify" class="dr-admin-sidebar-1 dr-sidebar">
      <admin-toolbox
        [toolboxItems]="toolboxItems"
        [reusableControls]="reusableControls"
        (toolboxItemSelected)="onToolboxItemSelected($event)"
        (toolboxReusableItemSelected)="onToolboxReusableItemSelected($event)"
      ></admin-toolbox>
    </div>

    <div class="dr-admin-sidebar-2 dr-sidebar">
      <admin-properties
        #adminProperties
        (updateControl)="updateControl($event)"
        (addControlToControl)="addControlToControl($event)"
        (reorderControl)="reorderControl($event)"
      ></admin-properties>
    </div>
  </div>
</div>

<div kendoDialogContainer></div>
