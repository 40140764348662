import { BaseModel } from '../../base.model';
import { TimeSheetExportHistoryDto } from './TimeSheetExportHistoryDto';

export class ExportTimesheetDto extends BaseModel {
  TimeSheetID: string;
  TimeSheetName: string;
  CreatedByUserID: string;
  DateCreated: Date | null;
  JobID: string | null;
  DateExported: Date | null;
  CorrectedTimeSheetID: string | null;
  bCorrecton: boolean | null;
  bLocked: boolean;
  Notes: string;
  ExportType: string;
  ExportMessage: string;
  ExportFlag: number | null;
  DateSentToClient: Date | null;
  DateSignedTimesheetUploaded: Date | null;
  WeekEndingDate: Date | null;
  DesignationID: number | null;
  DesignationName: string;
  CustomerPO: string;
  TotalCost: number | null;
  SignedNotes: string;
  HasEquipment: boolean | null;
  LaborExportedDate: Date | null;
  PerDiemExportedDate: Date | null;
  MileageExportedDate: Date | null;
  EquipmentExportedDate: Date | null;
  ExistNonExportedLaborItem: boolean | null;
  ExistNonExportedPerDiemItem: boolean | null;
  ExistNonExportedMileageItem: boolean | null;
  SignedTimesheetUploadedBy: string;
  BatchIDs: string;
  BatchMonths: string;
  OriginalBatchIDs: string;
  OriginalBatchMonths: string;
  BatchCompanies: string;
  AreLaborsPartiallyExported: number;
  ArePerDiemsPartiallyExported: number;
  AreMileagesPartiallyExported: number;
  PartiallyExported: number;
  ExportMessageCalculated: string;
  DateCreatedDateOnly: Date | null;
  DateExportedDateOnly: Date | null;
  LaborExportedDateOnly: Date | null;
  PerDiemExportedDateOnly: Date | null;
  MileageExportedDateOnly: Date | null;
  EquipmentExportedDateOnly: Date | null;
  PRCos: string;
  PRGroups: string;

  // client side
  isLoadingExportHistory: boolean = false;
  exportHistory: TimeSheetExportHistoryDto[] = [];

  constructor(o: any = null) {
    super(o);
  }
}
