import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CustomCodeComponent } from './components/custom-code.component';
import { CustomCodeTabsComponent } from './components/custom-code-tabs.component';

const components = [
  CustomCodeComponent,
  CustomCodeTabsComponent,
];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [CommonModule, FormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CodeHelperModule {
  constructor() {}
}
