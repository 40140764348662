import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AbsorbCourseDto,
  AbsorbDepartmentDto,
  AbsorbEnrollmentDto,
  NameAndGuid,
  AbsorbUserDto,
  ProspectData,
  AbsorbDateUpdateDto,
} from '@ups/xplat/api/dto';
import {
  MyHttpClientFactory,
  handleReturnObject,
  environment,
} from '@ups/xplat/core';

import { Observable } from 'rxjs';

const GET_USER = '/api/absorb/user/{:email}';
const GET_ABSORB_DATE = 'api/absorb/getAbsorbDate';
const GET_DEPARTMENT = '/api/absorb/department/{:id}';
const GET_USER_DEPARTMENT = '/api/absorb/user/{:email}/department';
const GET_CRAFT_TESTING_DEPARTMENTS = '/api/absorb/departments/craft-testing';
const CREATE_ACCOUNT = '/api/absorb/account/create';
const GET_USER_ENROLLMENTS = '/api/absorb/user/{:id}/enrollments';
const UPDATE_USER = '/api/absorb/user';
const GET_COURSES = '/api/absorb/courses';
const CREATE_ENROLLMENT = '/api/absorb/user/{:userId}/enrollments/{:courseId}';
const UPDATE_ABSORB_DATE_UPDATED = '/api/absorb/updateAbsorbDate';

@Injectable({ providedIn: 'root' })
export class AbsorbService {
  private http: HttpClient;

  constructor(httpFactory: MyHttpClientFactory) {
    this.http = httpFactory.createHttpClient(environment.urls.spartaAPI, true);
  }

  public getUser(email: string): Promise<AbsorbUserDto> {
    const url = GET_USER.split('{:email}').join(email);
    return this.http.get<AbsorbUserDto>(url).toPromise();
  }

  public getDepartment(id: string): Promise<AbsorbDepartmentDto> {
    const url = GET_DEPARTMENT.split('{:id}').join(id);
    return this.http.get<AbsorbDepartmentDto>(url).toPromise();
  }

  public getUserDepartment(email: string): Promise<AbsorbDepartmentDto> {
    const url = GET_USER_DEPARTMENT.split('{:email}').join(email);
    return this.http.get<AbsorbDepartmentDto>(url).toPromise();
  }

  public getCraftTestingDepartments(): Promise<NameAndGuid[]> {
    const url = GET_CRAFT_TESTING_DEPARTMENTS;
    return this.http.get<NameAndGuid[]>(url).toPromise();
  }

  public getCourses(): Promise<AbsorbCourseDto[]> {
    const url = GET_COURSES;
    return this.http.get<AbsorbCourseDto[]>(url).toPromise();
  }

  public getAbsorbDateForProspect(
    prospectId: string
  ): Observable<AbsorbDateUpdateDto> {
    const url = GET_ABSORB_DATE;
    const queryParams = new HttpParams({
      fromObject: { prospectId: prospectId },
    });
    return this.http.get<AbsorbDateUpdateDto>(url, { params: queryParams });
  }

  public createAccount(prospectData: ProspectData): Promise<AbsorbUserDto> {
    const url = CREATE_ACCOUNT;

    return this.http
      .put<AbsorbUserDto>(url, prospectData)
      .pipe(handleReturnObject())
      .toPromise();
  }

  public createEnrollment(userId: string, courseId: string): Promise<boolean> {
    let url = CREATE_ENROLLMENT;
    url = url.split('{:userId}').join(userId);
    url = url.split('{:courseId}').join(courseId);

    return this.http
      .post<boolean>(url, '')
      .pipe(handleReturnObject())
      .toPromise();
  }

  public getUserEnrollments(id: string): Observable<AbsorbEnrollmentDto[]> {
    const url = GET_USER_ENROLLMENTS.split('{:id}').join(id);
    return this.http.get(url) as Observable<AbsorbEnrollmentDto[]>;
  }

  public updateUser(absorbUser: AbsorbUserDto): Promise<AbsorbUserDto> {
    const url = UPDATE_USER;

    return this.http
      .put<AbsorbUserDto>(url, absorbUser)
      .pipe(handleReturnObject())
      .toPromise();
  }

  public updateAbsorbDate(
    absorbDateObj: AbsorbDateUpdateDto
  ): Promise<AbsorbDateUpdateDto> {
    const url = UPDATE_ABSORB_DATE_UPDATED;

    return this.http
      .put<AbsorbDateUpdateDto>(url, absorbDateObj)
      .pipe(handleReturnObject())
      .toPromise();
  }
}
