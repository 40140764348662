export function showPreloader() {
  // Target the bottom of head tag and dynamically add a style tag
  const style = document.createElement('style');
  style.appendChild(
    document.createTextNode(`
      @keyframes spinner {
        to {
          transform: rotate(360deg);
        }
      }

      .spinner {
        position: absolute;
        margin-top: 20%;
        left: 45%;
        display: block;
        width: 10rem;
        height: 10rem;
        vertical-align: text-bottom;
        border: 0.25em solid currentColor;
        border-right-color: transparent;
        border-radius: 50%;
        animation: spinner 0.75s linear infinite;
        z-index:-1;      
      }`)
  );
  document.head.appendChild(style);

  function fadeOut(element, duration) {
    let opacity = 1;
    const interval = duration / 100;
    const timer = setInterval(() => {
      if (opacity <= 0) {
        clearInterval(timer);
        element.style.display = 'none';
      }
      element.style.opacity = opacity;
      opacity -= 0.01;
    }, interval);
  }

  window.addEventListener('load', function () {
    // Get the preloader element
    const preloader = document.getElementById('spinner');
    // Fade out the preloader
    fadeOut(preloader, 1000); // fade out the preloader over 1 second
  });
}
