export enum QueryStringFragmentHandling {
  DropFragments,
  KeepBaseFragmentOnly,
  KeepAppendedFragmentOnly,
  MergeUseBaseFirst,
  MergeUseAppendedFirst,
}

/**
 * @description
 * Utility to manage query string parsing and concatenation.
 * Alternative: http://medialize.github.io/URI.js/
 */
export class QueryString {
  constructor(public baseUrl: string, public queryObject: object, public decodedValues: boolean) {
    baseUrl = baseUrl || '';
    queryObject = queryObject || {};
  }

  /**
   * @description creates an object key/value representation from the query
   * @param query the URI or QueryString to parse
   */
  public static parse(query: string, decodeQueryValues = true): QueryString {
    const [uriWithoutQs, qsWithFragment] = (query || '').split('?', 2);
    const [queryValues, queryFragment] = (qsWithFragment || '').split('#', 2);

    const queryObject = queryValues.split('&').reduce((params, param) => {
      const [key, value] = param.split('=');
      params[key] = value
        ? decodeQueryValues
          ? decodeURIComponent(value)
          : value // NOTE: originally it replaced all + to single space
        : '';
      return params;
    }, {});

    if (queryFragment) queryObject['#'] = queryFragment;

    return new QueryString(uriWithoutQs, queryObject, decodeQueryValues);
  }

  /**
   * @description converts an object into a query string appending fragment at the end (if # key on the object exists)
   * @param queryObject object (key/value pairs)
   */
  public static toQuery(queryObject: object, encodeValues = true): string {
    let queryString = Object.keys(queryObject)
      .filter((key) => key !== '#')
      .map((key) => {
        const value = queryObject[key];
        return key + '=' + (value ? (encodeValues ? encodeURIComponent(value) : value) : '');
      })
      .join('&');

    if ('#' in queryObject) queryString = queryString + '#' + queryObject['#'];

    return queryString;
  }

  public toQuery(): string {
    return QueryString.toQuery(this, this.decodedValues);
  }

  /**
   * @description
   * This function does a string concatenation  of @param queryToAppend to @param baseQuery, ensuring proper concatenating symbol (? vs &), thus valid query.
   * @param baseQuery the basic query
   * @param queryToAppend the query to append
   * @param fragmentHandling we can choose how fragments should be handled (keep original from baseQuery, the one from the appended or merge them)
   */
  public static append(baseQuery: string, queryToAppend: string, fragmentHandling: QueryStringFragmentHandling = QueryStringFragmentHandling.KeepBaseFragmentOnly) {
    const [baseUriWithoutQs, baseQsWithFragment] = baseQuery.split('?');
    const [baseQs, baseFragment] = baseQsWithFragment.split('#', 1);

    // remove ? or & from the start of the appendable query
    queryToAppend = /^[?&]/.test(queryToAppend) ? queryToAppend.slice(1) : queryToAppend;

    const [appendBaseQs, appendFragment] = baseQsWithFragment.split('#', 1);

    // merge uri + query strings
    const finalQueryWithoutFragment =
      baseUriWithoutQs + (baseQs || appendBaseQs)
        ? '?'
        : '' + // append ? if there's a query string to add
          baseQs +
          (baseQs && appendBaseQs)
        ? '&'
        : '' + // append & between the 2 query-strings (both values must be non-empty)
          appendBaseQs;
    // handle fragments
    if (baseFragment || appendFragment) {
      if (fragmentHandling === QueryStringFragmentHandling.DropFragments) return finalQueryWithoutFragment;

      if (baseFragment && fragmentHandling === QueryStringFragmentHandling.KeepBaseFragmentOnly) return finalQueryWithoutFragment + '#' + baseFragment;
      if (appendFragment && fragmentHandling === QueryStringFragmentHandling.KeepAppendedFragmentOnly) return finalQueryWithoutFragment + '#' + appendFragment;

      if (baseFragment && appendFragment) {
        if (fragmentHandling === QueryStringFragmentHandling.MergeUseBaseFirst) return finalQueryWithoutFragment + '#' + baseFragment + '+' + appendFragment;
        if (fragmentHandling === QueryStringFragmentHandling.MergeUseAppendedFirst) return finalQueryWithoutFragment + '#' + appendFragment + '+' + baseFragment;
      }
    }

    return finalQueryWithoutFragment;
  }

  /**
   * @description merges multiple query strings (https://tools.ietf.org/html/rfc3986#page-16)
   * @param queries the query strings (must not contain fragments)
   */
  public static merge(...queries: string[]) {
    return queries
      .map((q) => {
        if (/[#]/.test(q)) throw new Error(`Query '${q}' contains # - fragments are not allowed!`);
        return q;
      })
      .map((q) => (/^[?&]/.test(q) ? q.slice(1) : q)) // trim initial & or ?
      .filter((q) => !q) // skip empty
      .join('&');
  }
}
