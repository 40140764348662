import { AfterViewInit, Injector, Type, Directive } from '@angular/core';

import { NgControl } from '@angular/forms';
import { Validator, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ValueAccessorBase } from './value-accessor-base';

import { ValidationResult, message, validate } from './validate';

@Directive()
export abstract class ElementBase<T>
  extends ValueAccessorBase<T>
  implements AfterViewInit
{
  protected validators;
  protected asyncValidators;

  protected ngControl: NgControl;

  constructor(protected injector: Injector) {
    super(injector);
    this.validators = injector.get(NG_VALIDATORS);
    this.asyncValidators = injector.get(NG_ASYNC_VALIDATORS);
  }

  protected validate(): Observable<ValidationResult> {
    if (this.ngControl) {
      return validate(
        this.validators,
        this.asyncValidators
      )(this.ngControl.control);
    } else {
      return of(undefined);
    }
  }

  protected get invalid(): Observable<boolean> {
    return this.validate().pipe(map((v) => Object.keys(v || {}).length > 0));
  }

  protected get failures(): Observable<Array<string>> {
    return this.validate().pipe(
      map((v) => Object.keys(v).map((k) => message(v, k)))
    );
  }

  public ngAfterViewInit() {
    // TODO: wired here and not inside ngOnInit to support structural directives with the component
    this.ngControl =
      this.ngControl || this.injector.get<NgControl>(NgControl as any, null);
  }
}
