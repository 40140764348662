import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReorderRowsModalComponent } from './reorder-rows-modal.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { ButtonsModule } from '@progress/kendo-angular-buttons';

@NgModule({
  imports: [
    CommonModule,
    DialogsModule,
    SortableModule,
    ButtonsModule,
  ],
  declarations: [ReorderRowsModalComponent],
  exports: [ReorderRowsModalComponent],
})
export class ReorderRowsModalModule {}
