import { Directive, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent } from '@ups/xplat/core';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import { IEmployeeData, ProfileModel } from '@ups/xplat/api/dto';
import { UserState } from '@ups/user';

@Directive()
export abstract class ProfileEmployeeBaseComponent
  extends BaseComponent
  implements OnInit
{
  public text = 'ProfileEmployee';

  public securityConstants = SecurityConstants;

  authProfile: IEmployeeData;
  employeeNotFound = false;
  isSelfDataView = false;
  @Input() profile: ProfileModel;
  @Input() spartaProfile: any;
  @Input() portalProfile: any;
  @Input() showName = true;

  constructor(public security: SecurityService, protected store: Store<any>) {
    super();
  }

  ngOnInit() {
    this.store
      .pipe(UserState.selectCurrentTruthy(this.destroy$))
      .subscribe((x) => {
        this.authProfile = x.Data;
        this.isSelfDataView = this.isSelfData();
      });
  }

  /**
   * Returns true if the data being edited/shown are data of the currently logged in user.
   * This is to find out the user logged in sees only his/her data and not data of some other employee/user.
   */
  isSelfData(): boolean {
    if (!this.authProfile || !this.authProfile.HRRef) {
      return false;
    }

    if (
      this.profile &&
      this.profile.hrRef &&
      parseInt(this.profile.hrRef) !== this.authProfile.HRRef
    ) {
      return false; // Return false, the profile being edited is different from profile of the currently logged in user.
    }

    if (
      this.spartaProfile &&
      this.spartaProfile.hrRef &&
      this.spartaProfile.hrRef !== this.authProfile.HRRef
    ) {
      return false; // Return false, the profile being edited is different from profile of the currently logged in user.
    }

    return true;
  }
}
