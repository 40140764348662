/*
http://blog.rangle.io/angular-2-ngmodel-and-custom-form-components/
 */
import { ControlValueAccessor } from '@angular/forms';
import { Injector, Renderer2, ElementRef, Optional, Type } from '@angular/core';

export class ValueAccessorBase<T> implements ControlValueAccessor {
  protected innerValue: T;

  protected changed = new Array<(value: T) => void>();
  protected touched = new Array<() => void>();

  protected renderer: Renderer2;
  protected elementRef: ElementRef;

  public get value(): T {
    return this.innerValue;
  }

  public set value(value: T) {
    if (this.innerValue !== value) {
      this.innerValue = value;
      this.changed.forEach((f) => f(value));
    }
  }

  protected touch() {
    this.touched.forEach((f) => f());
  }

  /** NOTES: Please use ElementBase<T> for new components & provide injector when you need to support default setDisabledState(...); */
  constructor(@Optional() protected injector?: Injector) {
    if (this.injector) {
      this.renderer = injector.get<Renderer2>(Renderer2 as any);
      this.elementRef = injector.get<ElementRef>(ElementRef as any);
    }
  }

  public writeValue(value: T) {
    this.innerValue = value;
  }

  public registerOnChange(fn: (value: T) => void) {
    this.changed.push(fn);
  }

  public registerOnTouched(fn: () => void) {
    this.touched.push(fn);
  }

  public setDisabledState(isDisabled: boolean): void {
    if (this.injector) {
      this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
    }
  }
}
