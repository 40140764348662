import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ResponseCasingEnum,
  MyHttpClientFactory,
  environment,
} from '@ups/xplat/core';
import { PayPeriodDto, PayrollNoteDto } from '@ups/xplat/api/dto';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ExportTimesheetsVpService {
  protected urlBase: string;
  protected http: HttpClient;

  constructor(private clientFactory: MyHttpClientFactory) {
    this.urlBase = environment.urls.viewpointAPI;
    this.http = this.clientFactory.createHttpClient(
      this.urlBase,
      true,
      ResponseCasingEnum.PascalCase
    );
  }

  public fetchPayPeriods(
    prCos: number[],
    prEndDates: Date[],
    prGroups: number[],
    isEquipmentExport: boolean
  ): Observable<PayPeriodDto[]> {
    let url = '/api/exporttimesheet/getpayperiods?';

    const queryParameters: string[] = [];
    if (prCos) {
      prCos.forEach((c) => queryParameters.push(`prco=${c}`));
    }

    if (prEndDates) {
      const datePipe = new DatePipe('en-US');
      prEndDates.forEach((c) =>
        queryParameters.push(`prEndDate=${datePipe.transform(c, 'MM/dd/yyyy')}`)
      );
    }

    if (prGroups) {
      prGroups.forEach((c) => queryParameters.push(`prGroup=${c}`));
    }

    queryParameters.push(`isEquipmentExport=${isEquipmentExport}`);

    url = url + queryParameters.join('&');

    return this.http.get(url) as Observable<PayPeriodDto[]>;
  }

  public updatePayrollNotes(dtos: PayrollNoteDto[]): any {
    const url = '/api/prtb/memo';

    return this.http.put(url, dtos).toPromise();
  }
}
