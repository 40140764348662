import { BaseModel } from '../../base.model';

export class EquipmentRequestOverlapDto extends BaseModel {
  public VPJobID: string;
  public StartDate: Date | null;
  public EndDate: Date | null;

  constructor(o: any = null) {
    super(o);
  }
}
