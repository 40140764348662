import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { ManPowerSubRequirementDto } from '@ups/xplat/api/dto';

@Component({
  selector: 'ups-subtask-editor',
  templateUrl: './subtask-editor.component.html',
})
export class SubtaskEditorComponent implements OnInit {
  @Output() editSubtask: EventEmitter<ManPowerSubRequirementDto> =
    new EventEmitter<ManPowerSubRequirementDto>();

  isEdit: boolean;
  model: ManPowerSubRequirementDto;
  opened: boolean;

  constructor() {}

  ngOnInit() {
    this.isEdit = false;
    this.opened = false;
    this.model = new ManPowerSubRequirementDto();
  }

  close() {
    this.opened = false;
  }

  isValid() {
    if (this.model.Name && this.model.Name.trim().length !== 0) return true;
    return false;
  }

  onAdd() {
    this.editSubtask.emit(this.model);
    this.opened = false;
  }

  open(model: ManPowerSubRequirementDto) {
    if (model) {
      this.model = JSON.parse(JSON.stringify(model));
      this.isEdit = true;
    } else {
      this.model = new ManPowerSubRequirementDto();
      this.model.bActive = true;
      this.isEdit = false;
    }
    this.opened = true;
  }
}
