/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */

/**
 * @example
 * Multi edit support
 * ==================
 *
 * This class should serve as a helper (property to which we bind) for multi-select edit. It:
 * - parses initial data, sets the hasMultipleValues, hasEmptyValue, hasAllEmpty properties
 * - then it creates the default value object we bind to
 * - stores the initial value so we're able to revert to it
 *
 * All these logic is around 3 values:
 * - InitialData
 * - SelectionData
 * - ValueData
 * ...where those values might be comples or primitive
 * ...so CCC means complex initial data, complex selection data (key/text) and complex value data
 * ...while CCP means means complex initial data, complex selection data (key/text) and primitive value data
 *
 *
 * Public PROPs & FNCs related to CURRENT STATE:
 * ---------------------------------------------
 *
 *      value
 *          Currently selected value (ngModel).
 *
 *      valueHasChanged()
 *          Flag is set when the value setter is executed (value is changed)
 *
 *      void resetChanges()
 *          Function restores initial value and clears the valueHasChanged flag.
 *
 *      get valueIsMulti(): boolean
 *          Returns TRUE if currently selected value represents multiple selections.
 *          If we select a new value, a value change occurs and from that point we have a non-multi selection
 *
 *      get valueIsEmpty(): boolean
 *
 *          NOTE: For complex object it requires a valueEmptyComparerCallback: (value: any) => boolean to be passed to the creator functions!
 *
 *          When initial selection was not changed, we return:
 *          - TRUE if all items were empty
 *          - NULL if a multi selection has some (but not all) empty values
 *          - FALSE in all other cases
 *
 *          Once change in value occurs, we return:
 *          - if valueEmptyComparerCallback is defined, we return the result of valueEmptyComparerCallback(value)
 *          - if valueEmptyComparerCallback is not defined but the value is not an object (is simple), we return truthy/falsy values
 *          - otherwise we throw an error
 *
 *
 * Public PROPs & FNCs related to INITIAL STATE.
 * ---------------------------------------------
 *
 *      get initialSelectionItem(): any
 *          Get's the initial object which is passed as a list into the selection input.
 *          This selection might be a special one, signalling that the initial array had multiple different values.
 *
 *      get initialValue(): any
 *          This is the initial value, corresponding (referring) to the initial selection item.
 *
 *     get dataCount(): number
 *          The number of items in the initial array.
 *
 *     get hasMultipleValues(): boolean
 *          TRUE if the initial array had multiple different values.
 *
 *     get hasEmptyValue(): boolean
 *          TRUE if the initial array had AT LEAST ONE empty value.
 *
 *      get hasAllEmpty(): boolean
 *          TRUE if the initial array had ALL values empty.
 *
 *
 * Multi edit support (type details)
 * =================================
 * Has static create "overrides" for 3+1 use cases:
 * - empty object
 * - complex data, complex value
 * - complex data, primitive value
 * - primitive data, primitive value
 *
 * It uses 3 types of data:
 *
 * IT - Initial type
 *         initialArray: IT[] - contains our selected values from which we calculate additional information (hasMultipleValues, hasEmptyValue, ...)
 *         if there's just a single value (all items are considered as equal), we will create the initialSelection and initialValue from that value
 *         when array contains multiple values, the initialSelection and initialValue will be a special one, signalizing the multiple selection.
 *
 * ST - Selection type
 *         these items are displayed in the pop-up, when filtering/searching for new values
 *         also in case of combo-box / drop-down we need such item to display initial value (initial selection)
 *         in most cases ST is a part of IT, containing at least a key and a text field (property) - additional properties might be used for more complex scenarios (extra conditions, etc.)
 *
 * VT - ValueType
 *         this is the type we bind to (so it's the value for the current selection)
 *         if the model binds to a COMPLEX value, then the value if from the selection itself (so type ST, or a part of ST in case of initial selection - where some data might be missing)
 *         if the model binds to a PRIMITIVE value, then the value is a field/property from the ST type
 *
 * NOTES:
 *         We advise to use "empty" (invalid) key value for the "Multiple items selected..." variations as the required(...) function is intended to work with such values
 *
 *
 * Possible situations:
 * - no items (set that item)
 * - we have same item everywhere (set that item)
 * - we have multiple values, but no empty value (requirement fulfilled)
 * - we have multiple values, and also an empty value (field is required and we need to select new value or change selection)
 *
 *
 * Some hacks over REQUIRED attribute (then andRequired property):
 *     if hasMultipleValues && hasEmptyValue === false, then all items have a valid selection, requirement is fulfilled, so the "required" attr. can be dropped
 *     the reason for dropping the required attribute is, that USUALLY AND EMPTY KEY VALUE IS USED TO DISPLAY THE "Multiple values..." item.
 *     NO OTHER SCENARION needs dropping of the required flag.
 */
export class MultiEditProperty {
  public static MultipleValuesSelectedText = 'Multiple values...';
  public static MultipleValuesWithInvalidSelectionText =
    'Multiple with invalid selection...';

  /*
    When binding to a getter, we need to avoid new object creation as it would trigger a new ngOnChanges event!
    https://codeburst.io/angular-bad-practices-eab0e594ce92#6bcc
    https://stackblitz.com/edit/angulardevg-getter-input-arrays?file=src/app/app.component.ts
     */
  _value: any;

  /** Get's the currently selected value */
  public get value(): any {
    return this._value;
  }

  public set value(value: any) {
    if (value !== this._value) {
      this._value = value;
      this._valueHasChanged = true;
    }
  }

  _valueHasChanged = false;

  /** True if the initial selection (value) was changed */
  public get valueHasChanged(): boolean {
    return this._valueHasChanged;
  }

  /** Function to restore initial value and clears {@link valueHasChanged}. */
  public resetChanges() {
    this._value = this._initialValue;
    this._valueHasChanged = false;
  }

  /**
   * @example
   * Returns TRUE if currently selected value represents multiple selections.
   *
   * NOTE:
   * We assume that the initial value we have set up (when we calculated the multiple selection from the initial array) also corresponds to a multiple selection.
   * Once we do select a value, it's a single pick, so function should return FALSE then.
   */
  public get valueIsMulti(): boolean {
    return this._valueHasChanged === false && this._hasMultipleValues;
  }

  /**
   * Returns TRUE if the current selection evaluates as empty.
   *
   * @returns
   *
   * When initial selection was not changed, we return:
   * - TRUE if all items were empty
   * - NULL if a multi selection has some (but not all) empty values
   * - FALSE in all other cases
   *
   * Once change in value occurs, we return:
   * - if valueEmptyComparerCallback is defined, we return the result of valueEmptyComparerCallback(value)
   * - if valueEmptyComparerCallback is not defined but the value is not an object (is simple), we return truthy/falsy values
   * - otherwise we throw an error
   */
  public get valueIsEmpty(): boolean | null {
    if (!this._valueHasChanged) {
      if (this._hasAllEmpty) return true;
      if (this._hasMultipleValues && this._hasEmptyValue && !this._hasAllEmpty)
        return null;
      return false;
    } else {
      if (this._valueEmptyComparerCallback) {
        return this._valueEmptyComparerCallback(this._value);
      } else {
        const isObject = MultiEditProperty.isObject(this._value);

        // TODO: consider to return null instead!?
        if (isObject)
          throw new Error(
            'Please supply a valueEmptyComparerCallback for the MultiEditProperty.'
          );

        // return truthy/falsy
        return !!this._value;
      }
    }
  }

  /**
   * Ctor
   *
   * @param {numeric} dataCount (relates to IT)
   *      number of items in the initial data array
   * @param hasMultipleValues (relates to IT)
   *      true if the initial data array contained multiple selections
   * @param hasEmptyValue (relates to IT)
   *      true if the initial data array contained at least one empty selection
   * @param hasAllEmpty (relates to IT)
   *      true if all items inside the initial data array were "empty"
   * @param initialValue => VT
   *      the value to which we bind via ngModel (might be complex or simple type)
   *      if complex, then initialValue should equal to initialSelectionItem
   *      if simple, then initalValue is one of the properties of the initialSelectionItem object (or calculated from its properties)
   * @param initialSelectionItem => ST
   *      the initial selection item we do supply for the select control (combobox/dropdown)
   * @param valueEmptyComparerCallback (value: VT) => boolean
   *      a callback which determines whether the selected value is empty
   */
  constructor(
    dataCount,
    hasMultipleValues,
    hasEmptyValue,
    hasAllEmpty,
    initialValue,
    initialSelectionItem,
    valueEmptyComparerCallback
  ) {
    // details on the original input array
    this._dataCount = dataCount;
    this._hasMultipleValues = hasMultipleValues;
    this._hasEmptyValue = hasEmptyValue;
    this._hasAllEmpty = hasAllEmpty;

    // calculated initial settings
    this._initialValue = initialValue;
    this._initialSelectionItem = initialSelectionItem;

    // note: value - due bindings to value & initialData has to be set as last
    this._value = initialValue;

    // for special checks:
    this._valueEmptyComparerCallback = valueEmptyComparerCallback;
  }

  /**
   * The Object constructor creates an object wrapper for the given value.
   * - If the value is null or undefined, it will create and return an empty object, otherwise, it will return an object of a type that corresponds to the given value.
   * - If the value is an object already, it will return the value.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object
   *
   * @returns TRUE if the value is an object
   */
  public static isObject(obj): boolean {
    return obj === Object(obj);
  }

  _initialSelectionItem: any;

  /**
   *  Get's the initial object which is passed as a list into the selection input.
   *  This selection might be a special one, signalling that the initial array had multiple different values.
   */
  public get initialSelectionItem(): any {
    return this._initialSelectionItem;
  }

  _initialValue: any;

  /** This is the initial value, corresponding (referring) to the initial selection item. */
  public get initialValue(): any {
    return this._initialValue;
  }

  _dataCount: number;

  /** The number of items in the initial array. */
  public get dataCount(): number {
    return this._dataCount;
  }

  _hasMultipleValues: boolean;

  /** TRUE if the initial array had multiple different values. */
  public get hasMultipleValues(): boolean {
    return this._hasMultipleValues;
  }

  _hasEmptyValue: boolean;

  /** TRUE if the initial array had AT LEAST ONE empty value. */
  public get hasEmptyValue(): boolean {
    return this._hasEmptyValue;
  }

  _hasAllEmpty: boolean;

  /** TRUE if the initial array had ALL values empty. */
  public get hasAllEmpty(): boolean {
    return this._hasAllEmpty;
  }

  /** Internal parameter which is used to determine if the selected value (value type - VT) is empty! */
  _valueEmptyComparerCallback: (value: any) => boolean;

  /**
   * This function covers the extra use-cases, where the basic required expression has to be extended.
   * \- it returns the baseRequiredValue (expression) in all cases...
   * \- EXCEPT
   *      when multiple selection
   *      and when some (but not all) values are EMPTY
   *      and noEmptyValuesEnabled is FALSE
   *      ...cause in that case it returns FALSE
   *      ...so disabling required flag and allowing us to have some values EMPTY
   */
  public required(
    baseRequiredValue: boolean,
    noEmptyValuesEnabled: boolean
  ): boolean {
    //
    // if not multiple...
    if (!this.hasMultipleValues) return baseRequiredValue;

    //
    // if multiple...

    if (this.valueHasChanged) {
      // if value changes, then we switch away from multi-value and we must apply the base required to the new value...
      return baseRequiredValue;
    } else {
      if (!this.hasEmptyValue) return false; // all values are valid, so we must turn off REQUIRED so the NULL value for the 'Multiple values selected...' does not renders control as invalid

      if (!this.hasAllEmpty && noEmptyValuesEnabled === false) return false; // we got at least one (but not all) invalid/empty value, if we don't require all values to be valid (if we allow empty values) we must return false (reason same as above ^)

      return baseRequiredValue;
    }
  }

  /** Creates an empty MultiEditProperty instance. */
  public static Empty(
    initialValue: any = undefined,
    initialSelectionItem: any = {},
    valueEmptyComparerCallback: (value: any) => boolean = undefined
  ): MultiEditProperty {
    return new MultiEditProperty(
      0,
      false,
      false,
      false,
      initialValue,
      initialSelectionItem,
      valueEmptyComparerCallback
    );
  }

  /**
   * MAIN / Basic function to create the multiple-edit object.
   * Check @params section for short desc.
   * --
   * @example
   * Detailed description of used properties:
   *
   *      initialData: IT[]
   *          input array
   *
   *      selectionItemCreationCallback(e: MultiEditSelectionItemCreationEventArgs<IT>) => ST | any
   *          will create a selection type (ST)
   *          - from the 1st item of the input array
   *          - or it will create a "Multiple values..." like selection item
   *
   *          the selection type (ST)
   *          - contains all the necessary field/properties our logic needs (key/text field at least)
   *          - it uses same fields (key/text/...) as the data we get when filtering for new matches
   *
   *          the e property will contain all the necessary data to construct the final value.
   *          - if the e.createFromData is TRUE - we use e.firstData to create ST
   *          - otherwise (when e.createFromData is FALSE) we do have multiple selection se the e.defaultText will contain a verbiage accordingly
   *
   *          we create final values from properties of e.
   *          in some cases (where IT and ST are the same, we might return e.firstData directly).
   *
   *      equalityComparerCallback (over IT types)
   *          function which compares data items for equality (TRUE if equals) - when initialData && initialData.length > 1
   *
   *      emptyComparerCallback (over IT types)
   *          function which check if data item is empty (TRUE if empty) - when initialData && initialData.length > 1
   *
   *      valueCreationCallback(selectionITem: ST) => VT
   *          MANDATORY WHEN we have complex data (complex ST) and simple value (simple VT).
   *          Can be OMITTED WHEN both selection and value is complex or both are primitive values (and of same type).
   *          if not provided, it will return the initial selectionItem (so ST).
   *
   * @param initialData - this array holds all the initial values
   * @param emptyComparerCallback - function which compares data items for equality (TRUE if equals)
   * @param equalityComparerCallback - function which check if data item is empty (TRUE if empty)
   * @param selectionItemCreationCallback - function which creates the initial selection item from a single initial value or creates a special item to signalize multiple selection
   * @param valueCreationCallback
   * \- function which converts selection item to a value (to which we bind)
   * \- if omitted, the selection item is used also as the value (this is the case when we bind to complex value object)
   * @param valueEmptyComparerCallback - a callback which determines whether the selected value is empty
   */
  public static CreateMain<IT, ST, VT>(
    initialData: IT[],
    emptyComparerCallback: (data: IT) => boolean,
    equalityComparerCallback: (left: IT, right: IT) => boolean,
    selectionItemCreationCallback: (
      e: MultiEditSelectionItemCreationEventArgs<IT>
    ) => any | ST,
    valueCreationCallback: (selectionItem: any | ST) => any | VT = undefined,
    valueEmptyComparerCallback: (value: VT) => boolean = undefined
  ): MultiEditProperty {
    const dataCount =
      initialData && initialData.length > 0 ? initialData.length : 0;

    const firstData =
      initialData && initialData.length > 0 ? initialData[0] : undefined;

    const hasMultipleValues =
      initialData && initialData.length > 1
        ? initialData.some(
            (i) => equalityComparerCallback(initialData[0], i) !== true
          )
        : false;

    const hasEmptyValue =
      initialData && initialData.length > 0
        ? initialData.some((i) => emptyComparerCallback(i) === true)
        : false;

    const hasAllEmpty =
      hasEmptyValue &&
      initialData.every((i) => emptyComparerCallback(i) === true);

    //
    // value creation
    let defaultText = '';

    if (hasMultipleValues) {
      if (hasEmptyValue === false)
        defaultText = MultiEditProperty.MultipleValuesSelectedText;
      // note: consider null / undefined and other "invalid" as separate values!
      else
        defaultText = MultiEditProperty.MultipleValuesWithInvalidSelectionText;
    }

    const e = new MultiEditSelectionItemCreationEventArgs<IT>(
      dataCount,
      firstData,
      hasMultipleValues,
      hasEmptyValue,
      hasAllEmpty,
      defaultText
    );

    const initialSelectionItem = selectionItemCreationCallback(e);

    const initialValue = hasAllEmpty
      ? null // NOTE: the value has to be NULL, otherwise required would not work! (PORTAL-1328)
      : valueCreationCallback
      ? valueCreationCallback(initialSelectionItem)
      : initialSelectionItem;

    return new MultiEditProperty(
      dataCount,
      hasMultipleValues,
      hasEmptyValue,
      hasAllEmpty,
      initialValue,
      initialSelectionItem,
      valueEmptyComparerCallback
    );
  }

  /**
   * **MultiEditProperty for the classic CCC scenario.**
   * For detailed @params description check out the **CreateMain** fnc.
   *
   * CCC stands for: COMPLEX initial type (IT), COMPLEX selection type (ST) and COMPLEX value type (VT)
   *
   * @see CreateMain
   */
  public static ForCCC<IT, ST, VT>(
    initialData: IT[],
    emptyComparerCallback: (data: IT) => boolean,
    equalityComparerCallback: (left: IT, right: IT) => boolean,
    selectionItemCreationCallback: (
      e: MultiEditSelectionItemCreationEventArgs<IT>
    ) => ST | any,
    valueEmptyComparerCallback: (value: VT) => boolean = undefined
  ): MultiEditProperty {
    return MultiEditProperty.CreateMain(
      initialData,
      emptyComparerCallback,
      equalityComparerCallback,
      selectionItemCreationCallback,
      valueEmptyComparerCallback
    );
  }

  /**
   * **A special ForCCC implementation, which uses a value/text (key/text) pairs as selection type (ST).**
   *
   * CCC stands for: COMPLEX initial type (IT), COMPLEX selection type (ST) and COMPLEX value type (VT)
   * ***
   *
   * As this interface is implemented to work with a key/text pairs, **key is considered to be of a simple type**.
   * \- **Falsy** key value is considered as an **empty** selection.
   * \- Also **if two keys equal**, then initial data is considered as equal too.
   * ***
   *
   * @param initialData - this array holds all the initial values
   * @param initialValueField - an initial data property/field name for the KEY (VALUE)
   * @param initialTextField - an initial data property/field name for the TEXT
   * @param selectionValueField - the KEY field name on the selection object
   * @param selectionTextField - the TEXT field name on the selection object
   * @param valueEmptyComparerCallback - a callback which determines whether the selected value is empty; NOTE: **there's no default for CCC, so calls to {@link valueIsEmpty} might throw error!**
   * @param valueForMultipleSelection - the KEY value on the selection object which signalizes a multiple selection
   *
   * @see ForCCC
   * @see CreateMain
   */
  public static ForCCCx<IT, ST, VT>(
    initialData: IT[],
    initialValueField: string,
    initialTextField: string,
    selectionValueField: string,
    selectionTextField: string,
    valueEmptyComparerCallback: (value: VT) => boolean = undefined,
    valueForMultipleSelection: any = null
  ): MultiEditProperty {
    return MultiEditProperty.ForCCC(
      initialData,
      (data) => !data[initialValueField],
      (left, right) => left[initialValueField] == right[initialValueField],
      (e) => {
        const result = {};

        if (e.createFromData) {
          result[selectionValueField] = e.firstData[initialValueField];
          result[selectionTextField] =
            e.firstData[initialTextField] || undefined;
        } else {
          result[selectionValueField] = valueForMultipleSelection;
          result[selectionTextField] = e.defaultText;
        }

        return result;
      },
      valueEmptyComparerCallback
    );
  }

  /**
   * **MultiEditProperty for the classic CCP scenario.**
   * For detailed @params description check out the {@link CreateMain} fnc.
   *
   * CCP stands for: COMPLEX initial type (IT), COMPLEX selection type (ST) and PRIMITIVE value type (VT)
   *
   * @param valueEmptyComparerCallback can be omitted, in that case the we check for falsy values, so: valueEmptyComparerCallback = (value) => !value is used as default.
   *
   * @see CreateMain
   */
  public static ForCCP<IT, ST, VT>(
    initialData: IT[],
    emptyComparerCallback: (data: IT) => boolean,
    equalityComparerCallback: (left: IT, right: IT) => boolean,
    selectionItemCreationCallback: (
      e: MultiEditSelectionItemCreationEventArgs<IT>
    ) => ST | any,
    valueCreationCallback: (selectionItem: any | ST) => any | VT,
    valueEmptyComparerCallback: (value: VT) => boolean = undefined
  ): MultiEditProperty {
    if (!valueEmptyComparerCallback)
      valueEmptyComparerCallback = (value) => !value;

    return MultiEditProperty.CreateMain(
      initialData,
      emptyComparerCallback,
      equalityComparerCallback,
      selectionItemCreationCallback,
      valueCreationCallback,
      valueEmptyComparerCallback
    );
  }

  /**
   * **A special ForCCP implementation, which uses a value/text (key/text) pairs as selection type (ST).**
   * For detailed @params description check out the {@link ForCCCx} fnc.
   *
   * CCP stands for: COMPLEX initial type (IT), COMPLEX selection type (ST) and PRIMITIVE value type (VT)
   *
   * @param valueEmptyComparerCallback can be omitted, see {@link ForCCP} implementation  - in that case the we check for falsy values, so: valueEmptyComparerCallback = (value) => !value is used as default.
   *
   * @see ForCCCx
   * @see ForCCP
   */
  public static ForCCPx<IT, ST, VT>(
    initialData: IT[],
    initialValueField: string,
    initialTextField: string,
    selectionValueField: string,
    selectionTextField: string,
    valueEmptyComparerCallback: (value: VT) => boolean = undefined,
    valueForMultipleSelection: any = null
  ): MultiEditProperty {
    if (!valueEmptyComparerCallback)
      valueEmptyComparerCallback = (value) => !value;

    return MultiEditProperty.ForCCP(
      initialData,
      (data) => !data[initialValueField],
      (left, right) => left[initialValueField] == right[initialValueField],
      (e) => {
        const result = {};

        if (e.createFromData) {
          result[selectionValueField] = e.firstData[initialValueField];
          result[selectionTextField] =
            e.firstData[initialTextField] || undefined;
        } else {
          result[selectionValueField] = valueForMultipleSelection;
          result[selectionTextField] = e.defaultText;
        }

        return result;
      },
      (selectionItem) => selectionItem[selectionValueField]
    );
  }

  /**
   * **MultiEditProperty for the classic CPP scenario.**
   * Some of the @params (callbacks) below do have a default value - for detailed @params description check out the {@link CreateMain} fnc.
   *
   * CPP stands for: COMPLEX initial type (IT), PRIMITIVE selection type (ST) and PRIMITIVE value type (VT)
   *
   * @param valueEmptyComparerCallback can be omitted, in that case the we check for falsy values, so: valueEmptyComparerCallback = (value) => !value is used as default.
   *
   * @see CreateMain
   */
  public static ForCPP<IT, ST, VT>(
    initialData: IT[],
    emptyComparerCallback: (data: IT) => boolean,
    equalityComparerCallback: (left: IT, right: IT) => boolean,
    selectionItemCreationCallback: (
      e: MultiEditSelectionItemCreationEventArgs<IT>
    ) => ST | any = undefined,
    valueCreationCallback: (selectionItem: any | ST) => any | VT = undefined,
    valueEmptyComparerCallback: (value: VT) => boolean = undefined
  ): MultiEditProperty {
    if (!selectionItemCreationCallback)
      selectionItemCreationCallback = (e) =>
        e.createFromData ? e.firstData : undefined; // NOTE: originally it was: ''

    if (!valueCreationCallback)
      valueCreationCallback = (selectionItem) => selectionItem;

    if (!valueEmptyComparerCallback)
      valueEmptyComparerCallback = (value) => !value;

    return MultiEditProperty.CreateMain(
      initialData,
      emptyComparerCallback,
      equalityComparerCallback,
      selectionItemCreationCallback,
      valueCreationCallback,
      valueEmptyComparerCallback
    );
  }

  /**
   * **A special ForCPP implementation, which uses a single, primitive value for the selection type (ST) and for the value type (VT).**
   *
   * CPP stands for: COMPLEX initial type (IT), PRIMITIVE selection type (ST) and PRIMITIVE value type (VT)
   * ***
   *
   * As this interface is implemented to work with a **simple type** (key).
   * \- **Falsy** key is considered as an **empty** selection.
   * \- **if two keys equal**, then initial data is considered as equal too.
   * ***
   *
   * @param initialData - this array holds all the initial values
   * @param initialValueField - an initial data property/field name for the KEY (VALUE)
   * @param valueForMultipleSelection - the KEY value on the selection object which signalizes a multiple selection
   * @param valueEmptyComparerCallback can be omitted, see {@link ForCPP} implementation  - in that case the we check for falsy values, so: valueEmptyComparerCallback = (value) => !value is used as default.
   *
   * @see ForCPP
   * @see CreateMain
   */
  public static ForCPPx<IT, ST, VT>(
    initialData: IT[],
    initialValueField: string,
    valueEmptyComparerCallback: (value: VT) => boolean = undefined,
    valueForMultipleSelection: any = null
  ): MultiEditProperty {
    return MultiEditProperty.ForCPP(
      initialData,
      (data) => !data[initialValueField],
      (left, right) => left[initialValueField] == right[initialValueField],
      (e) =>
        e.createFromData
          ? e.firstData[initialValueField]
          : valueForMultipleSelection,
      (selectionItem) => selectionItem,
      valueEmptyComparerCallback
    );
  }

  /**
   * PPP stands for: PRIMITIVE initial type (IT), PRIMITIVE selection type (ST) and PRIMITIVE value type (VT)
   *
   * @param initialData - this array holds all the initial values
   * @param emptyComparerCallback - a function that evaluates when an item from initialData should be considered as empty (if omitted, the falsy values will be considered s empty)
   *
   * @see CreateMain
   */
  public static ForPPP<IT, ST, VT>(
    initialData: IT[],
    valueForMultipleSelection: any = null,
    emptyComparerCallback: (data: IT) => boolean = undefined
  ): MultiEditProperty {
    if (!emptyComparerCallback) emptyComparerCallback = (data) => !data;

    return MultiEditProperty.CreateMain(
      initialData,
      emptyComparerCallback,
      (left, right) => left == right,
      (e) => (e.createFromData ? e.firstData : valueForMultipleSelection),
      (data) => data,
      emptyComparerCallback
    );
  }

  /**
   * @example
   * UPDATE helper
   *
   *  When there's no change, it will return the originalItemFieldValue.
   *  When the selected value has changed, it will return the new selected value (or a value created from it)
   *
   *  Params:
   *
   *      originalValue
   *          when there's NO CHANGE on the multi edit property, this value will be returned
   *
   *      selectedValueTransformationCallback (not mandatory)
   *          when there's a CHANGE DETECTED on the multi edit property, we will return the new newly selected value (see: value getter)
   *          the selected value can be altered via this callback
   *          if callback is not provided the value object this multi edit class will be returned
   *
   * @param originalValue - the original selection
   * @param selectedValueTransformationCallback
   * \- if value is PRIMITIVE, this CAN BE OMITTED and the selected value will be used if change occurs
   * \- if value is complex, this callback transforms the selected value to new one, which will replace original value with the new selection
   */
  public fetchFinalValue(
    originalValue,
    selectedValueTransformationCallback: (
      multiEditValue: any
    ) => any = undefined
  ): any {
    if (this.valueHasChanged) {
      return selectedValueTransformationCallback
        ? selectedValueTransformationCallback(this.value)
        : this.value;
    } else {
      return originalValue;
    }
  }
}

/** This event argument is used to create initial selection value! */
export class MultiEditSelectionItemCreationEventArgs<IT> {
  _dataCount: number;
  _firstData: IT;

  _hasMultipleValues: boolean;
  _hasEmptyValue: boolean;
  _hasAllEmpty: boolean;

  _defaultText: string;

  /**
   * If there's only a single selection in the entire initial array (so there's no multiple selection), then this function returns TRUE.
   * In this case the initial selection as the initial value is constructed from the first item within from the initial data array.
   */
  public get createFromData(): boolean {
    return this.dataCount && !this.hasMultipleValues;
  }

  //
  //
  //

  public get dataCount(): number {
    return this._dataCount;
  }

  /** @returns The 1st item from the initial data array - or undefined if no such exists! */
  public get firstData(): any {
    return this._firstData;
  }

  public get hasMultipleValues(): boolean {
    return this._hasMultipleValues;
  }

  public get hasEmptyValue(): boolean {
    return this._hasEmptyValue;
  }

  public get hasAllEmpty(): boolean {
    return this._hasAllEmpty;
  }

  /**
   * This value is used in case of multiple selections, along with a special key value signalizing that multi-selection.
   * @returns Returns the default text for multiple selection (or for multiple selection with some empty values).
   */
  public get defaultText(): string {
    return this._defaultText;
  }

  constructor(
    dataCount: number,
    firstData: any,
    hasMultipleValues: boolean,
    hasEmptyValue: boolean,
    hasAllEmpty: boolean,
    defaultText: string
  ) {
    this._dataCount = dataCount;
    this._firstData = firstData;
    this._hasMultipleValues = hasMultipleValues;
    this._hasEmptyValue = hasEmptyValue;
    this._hasAllEmpty = hasAllEmpty;
    this._defaultText = defaultText;
  }
}
