import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';
import { mergeMap, skipWhile, take } from 'rxjs/operators';

import { PermissionsChangedEnum, SecurityService } from './security.service';
import {
  ViewFeatureExpression,
  ViewFeatureExpressionType,
} from './view-feature-expression';

/**
 * @description
 * Will allow just authenticathed users with loaded security details to enter.
 * It's actually a wait for security details to be loaded.
 *
 * @param data: { roles?: ViewFeatureExpressionType, fallbackUrl?: string = '/' }
 *
 * NOTES:
 * https://seegatesite.com/how-to-passing-parameter-to-canactivete-guard-angular-4-for-beginner/
 */
@Injectable({
  providedIn: 'root',
})
export class SecurityGuard {
  public static defaultFallbackUrl = '/';

  constructor(
    private router: Router,
    private securityService: SecurityService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | boolean
    | UrlTree
    | Promise<boolean | UrlTree>
    | Observable<boolean | UrlTree> {
    const data = route.data || {};
    const roles = data.roles as ViewFeatureExpressionType;
    const fallbackUrl = (data.fallbackUrl ||
      SecurityGuard.defaultFallbackUrl) as string;

    return this.securityService.permissionsChanged$.pipe(
      skipWhile((e) => e !== PermissionsChangedEnum.set), // skip of values other than 'set' - wait for security details being loaded
      take(1), // take just 1st 'set'
      mergeMap(() => {
        if (roles) {
          const hasAccess = ViewFeatureExpression.evaluate(
            roles,
            this.securityService
          );
          if (!hasAccess) return of(this.router.parseUrl(fallbackUrl));
        }

        return of(true);
      })
    );
  }
}
