import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DropDownsModule } from '@progress/kendo-angular-dropdowns';

import { GridStickyHeaderDirective } from './grid-sticky.grid.header-directive';

import { KendoGridColumnExComponent } from './Column-Extension/column-component-ex.component';
import { KendoGridColumnExBooleanComponent } from './Column-Extension/kendo-grid-column-ex-boolean';

// import { IsBlankFilterOperatorComponent } from './Filtering/Operators/is-blank-operator.component';
// import { IsNotBlankFilterOperatorComponent } from './Filtering/Operators/is-not-blank-operator.component';

import { GridFilter2DigitYearSupportDirective } from './Filtering/grid-filter-2digit-year-support.directive';

import { CollectionFilterDirective } from './Filtering/UX/collection-filter.directive';

import { CheckBoxFilterMenuComponent } from './Filtering/UX/menu/grid-checkbox-filter-menu.component';

const _exports = [
  //
  // Grid related
  GridStickyHeaderDirective,

  KendoGridColumnExComponent,
  KendoGridColumnExBooleanComponent,

  //
  // Filtering related

  // operators
  // IsBlankFilterOperatorComponent,
  // IsNotBlankFilterOperatorComponent,

  // general
  GridFilter2DigitYearSupportDirective,

  // UX (menu / cell)
  CollectionFilterDirective,

  CheckBoxFilterMenuComponent,
];

const declarations = [..._exports];

@NgModule({
  declarations: [declarations],
  exports: [_exports],
  imports: [CommonModule, FormsModule, DropDownsModule],
})
export class GridModuleEx {}
