import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  EquipmentUsageDto,
  EquipmentUsageOverview,
  IODataResult,
} from '@ups/xplat/api/dto';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import { parseData } from '@ups/xplat/utils';
import { Observable, map } from 'rxjs';

const GET_EQUIPMENT_USAGE =
  '/api/equipmentUsage/{:jobID}/{:dateFrom}/{:dateTo}';
const SAVE_EQUIPMENT_USAGE = '/api/equipmentUsage';
const GET_WEEKLY_AND_MONTHLY_DATA =
  '/api/equipmentUsage/weeklyAndMonthly/{:jobID}/{:anyDateInTheWeek}';

@Injectable({ providedIn: 'root' })
export class EquipmentUsageService {
  protected httpCamelCase: HttpClient;
  protected httpPascalCase: HttpClient;

  constructor(private clientFactory: MyHttpClientFactory) {
    const urlBase = environment.urls.spartaAPI;
    this.httpCamelCase = this.clientFactory.createHttpClient(
      urlBase,
      true,
      ResponseCasingEnum.CamelCase
    );
    this.httpPascalCase = this.clientFactory.createHttpClient(
      urlBase,
      true,
      ResponseCasingEnum.PascalCase
    ); // Using pascal case as odata has bug where GroupBy statement with orderBy is case sensitive and the backend is PascalCased
  }

  queryEquipmentUsageData(
    jobID: string,
    dateFrom: string,
    dateTo: string,
    odataString: string,
    equipmentID: string = null,
    workOrder: string = null,
    shift: number = null,
    includeDispatched: boolean = null
  ): Observable<IODataResult<EquipmentUsageDto>> {
    let url = GET_EQUIPMENT_USAGE.split('{:jobID}')
      .join(jobID)
      .split('{:dateFrom}')
      .join(dateFrom)
      .split('{:dateTo}')
      .join(dateTo);

    url += '?$format=json&$count=true';

    if (shift) {
      url += `&shift=${shift}`;
    }

    if (equipmentID) {
      url += `&equipmentID=${equipmentID}`;
    }

    if (workOrder) {
      url += `&workOrder=${escape(workOrder).replace('/', '%2F')}`;
    }

    if (includeDispatched === true) {
      url += `&includeDispatched=true`;
    }

    url += odataString ? '&' + odataString : '';

    return this.httpPascalCase.get(url).pipe(map(parseData));
  }

  getEquipmentUsageOverview(
    jobID: string,
    equipmentName: string
  ): Observable<EquipmentUsageOverview[]> {
    const url = `/api/equipmentUsage/${jobID}/${equipmentName}`;
    return this.httpCamelCase.get<EquipmentUsageOverview[]>(url);
  }

  saveEquipmentUsage(dtos: EquipmentUsageDto[]) {
    return this.httpCamelCase.post(SAVE_EQUIPMENT_USAGE, dtos);
  }

  getWeeklyAndMonthlyData(jobID: string, anyDateInTheWeek: string) {
    const url = GET_WEEKLY_AND_MONTHLY_DATA.split('{:jobID}')
      .join(jobID)
      .split('{:anyDateInTheWeek}')
      .join(anyDateInTheWeek);

    return this.httpPascalCase.get(url).pipe(map(parseData));
  }
}
