import * as _ from 'lodash';

import { CompositeFilterDescriptor, FilterDescriptor, isCompositeFilterDescriptor, State } from '@progress/kendo-data-query';

export class FilterUtils {
  public static concatFilters(originalFilter: FilterDescriptor | CompositeFilterDescriptor, mergeFilter: FilterDescriptor | CompositeFilterDescriptor, mergeLogic: 'or' | 'and' = 'and'): FilterDescriptor | CompositeFilterDescriptor | null {
    const isOriginalEmpty = FilterUtils.isEmptyFilter(originalFilter);
    const isMergeEmpty = FilterUtils.isEmptyFilter(mergeFilter);

    if (isOriginalEmpty && isMergeEmpty) return null;

    if (isOriginalEmpty) return _.cloneDeep(mergeFilter);

    if (isMergeEmpty) return _.cloneDeep(originalFilter);

    const originalClone = _.cloneDeep(originalFilter);
    const mergeClone = _.cloneDeep(mergeFilter);

    const newFilter = {
      logic: mergeLogic,
      filters: [originalClone, mergeClone],
    } as CompositeFilterDescriptor;

    return newFilter;
  }

  public static isEmptyFilter(filter: FilterDescriptor | CompositeFilterDescriptor): boolean {
    const isEmpty = !filter || (isCompositeFilterDescriptor(filter) && !(filter as CompositeFilterDescriptor).filters.length);
    return isEmpty;
  }

  public static replaceFilter(state: State, filter: FilterDescriptor | CompositeFilterDescriptor): State {
    if (!filter) {
      state.filter = {
        logic: 'and',
        filters: [],
      } as CompositeFilterDescriptor;
    } else if (isCompositeFilterDescriptor(filter)) {
      state.filter = filter as CompositeFilterDescriptor;
    } else {
      state.filter = {
        logic: 'and',
        filters: [filter],
      } as CompositeFilterDescriptor;
    }

    return state;
  }

  public static findFilters(compositeFilter: CompositeFilterDescriptor, matchCallback: (filter: FilterDescriptor) => boolean, remove = false, filters: FilterDescriptor[] = []): FilterDescriptor[] {
    if (!compositeFilter || !compositeFilter.filters || !compositeFilter.filters.length) return filters;

    let i = compositeFilter.filters.length - 1;

    while (i >= 0) {
      const item = compositeFilter.filters[i];

      if (isCompositeFilterDescriptor(item)) {
        // composite filter
        FilterUtils.findFilters(item, matchCallback, remove, filters);

        if (item.filters.length === 0) compositeFilter.filters.splice(i, 1);
      } else {
        // simple filter
        if (matchCallback(item)) {
          filters.push(item);

          if (remove) compositeFilter.filters.splice(i, 1);
        }
      }

      // as we remove items from the end of the array, we can safely check previous item (anything above that index is already processed)
      i--;
    }

    return filters;
  }
}
