import { Directive, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, Validators } from '@angular/forms';
import { NoWhitespaceValidator } from '../validators/no-whitespace.validator';

/**
 * The validator complains when the control's value contains only whitespace characters.
 * To check both for empty and no whitespace values use both directive validators together: required and noWhitespace
 * Example: <input noWhitespace>          // OK values: "", "fooo", " foo ", "    foo", "foo  " WRONG values: "   "
 * Example: <input required noWhitespace> // OK values:     "fooo", " foo ", "    foo", "foo  " WRONG values: "   ", ""    //  Combination of both required and if not empty the it cannot be whitespace only
 * Example: <input [noWhitespace]="myFooShouldBeNoWhitespace">  // the directive is only applied if the myFooShouldBeNoWhitespace variable is true
 * Example: <input required [noWhitespace]="myFooShouldBeNoWhitespace">  // the directive is only applied if the myFooShouldBeNoWhitespace variable is true
   Example: <input [required]="myOtherShouldBeFilled" [noWhitespace]="myFooShouldBeNoWhitespace">  // the directive is only applied if the myFooShouldBeNoWhitespace variable is true
 * @export
 * @class NoWhitespaceDirective
 * @implements {Validator}
 */
@Directive({
  selector: '[noWhitespace]',
  providers: [{ provide: NG_VALIDATORS, useExisting: NoWhitespaceDirective, multi: true }],
})
export class NoWhitespaceDirective implements Validator {
  private _isValidationActive: boolean = true;
  private _onChange: () => void;

  @Input('noWhitespace')
  get isValidationActive(): boolean | string {
    return this._isValidationActive;
  }

  set isValidationActive(value: boolean | string) {
    this._isValidationActive = value != null && value !== false && `${value}` !== 'false';
    if (this._onChange) this._onChange();
  }

  private valFn = NoWhitespaceValidator(false);

  validate(control: AbstractControl): { [key: string]: any } {
    if (!this.isValidationActive) {
      return null;
    }

    return this.valFn(control);
  }

  registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }
}
