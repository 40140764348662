import { AppRouterState } from './router.state';
import { RouterActions } from './router.action';
import { ActionReducer, combineReducers, createReducer, on } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';

const customRouterReducer = createReducer(
  AppRouterState.initialState.custom,
  on(RouterActions.Initialize, (state, { local }) => ({
    ...state,
    ...local,
  }))
);

export const appRouterReducer = combineReducers({
  default: routerReducer,
  custom: customRouterReducer,
});
