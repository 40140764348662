import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import {
  BaseComponent,
  environment,
  LocalStorageService,
} from '@ups/xplat/core';
import { getAppVersionInfo } from '@ups/xplat/web/utils';

import { ReleaseNoteModule, UIModule } from '@ups/xplat/web/features';
import { InAppHelpModule } from '@ups/xplat/web/in-app-help';
import { KendoPanelBarMenuModule } from '../kendo-panel-bar-menu/kendo-panel-bar-menu.module';

@Component({
  selector: 'ups-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    InAppHelpModule,
    ReleaseNoteModule,
    KendoPanelBarMenuModule,
    UIModule,
  ],
  templateUrl: './lazy-loaded-layout.component.html',
  styleUrl: './lazy-loaded-layout.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class LazyLoadedLayoutComponent
  extends BaseComponent
  implements AfterViewInit
{
  applicationName = environment.sidebarName ?? environment.name;
  displayName = environment.displayName || '';
  version: string;
  buildNumber: string;
  environment = environment;

  isSidebarExpanded = false;
  isSidebarPinned = false;

  @ViewChild('sidebarLeft') sidebarLeft: ElementRef;
  @ViewChild('sidebarRight') sidebarRight: ElementRef;

  constructor(private auth: AuthService, private storage: LocalStorageService) {
    super();
    const info = getAppVersionInfo();
    if (info.versionName) {
      this.version = `v${info.versionName}`;
    } else {
      this.version = 'local';
    }
    if (info.buildNumber) {
      this.buildNumber = `(${info.buildNumber})`;
    } else {
      this.buildNumber = '';
    }
  }

  ngAfterViewInit(): void {
    // Auto remove statically assigned open state if viewport is too small (e.g. when we start on a mobile)
    const vw = document.body.clientWidth;

    if (vw < 720) {
      this.sidebarLeft?.nativeElement?.classList.remove('open');
      this.sidebarRight?.nativeElement?.classList.remove('open');
    }

    this.detectSidebarStatus();
  }

  detectSidebarStatus() {
    this.isSidebarPinned = !!this.storage.getItem('sidebarPinned');
    if (this.isSidebarPinned) {
      this.isSidebarExpanded = true;
    }
  }

  toggleSidebarStatus() {
    this.isSidebarExpanded = !this.isSidebarExpanded;
  }

  togglePinSidebar() {
    this.isSidebarPinned = !this.isSidebarPinned;
    this.storage.setItem('sidebarPinned', this.isSidebarPinned);
  }
}
