import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { State, CompositeFilterDescriptor, isCompositeFilterDescriptor } from '@progress/kendo-data-query';
import { isDate } from 'util';

/**
 * Obsolete - outdated implementation that is not working with the new OData extensions.
 * Use [grid-filter-2digit-year-support] on kendo grid (import GridModuleEx from @ups/common) to patch 2 digit years to full 4 digit!
 */
@Pipe({ name: 'fixKendoStateDatesPipe' })
export class FixKendoStateDatesPipe implements PipeTransform {
  transform(state: State): State {
    this.fixFilters(state.filter);

    return state;
  }

  fixFilters(filter: CompositeFilterDescriptor) {
    let self = this;
    filter.filters.forEach((element) => {
      if (isCompositeFilterDescriptor(element)) {
        self.fixFilters(element);
      } else {
        if (isDate(element.value) && element.value.getFullYear() < 100) {
          element.value = new Date(element.value.getFullYear() + 2000, element.value.getMonth(), element.value.getDate());
        }
      }
    });
  }
}
