import { PathSegment } from './path-segment';

/** Class with static functions to recursively traverse the kendo grid GroupResult tree-structure. */
export class Traverse {
  /**
     * Function to traverse the hierarchical grid structure...
     * ---
     *
     * Callbacks:
     *
     *      THE onExitCallback IS IDEAL FOR GETTING AN ORDER WHERE WE NEED TO PROCESS INNERMOST ITEMS FIRST
     *      ...while te onEnterCallback is for creating an order where we do process uppermost items first

     *      With onEnterCallback we deep into the tree
     *      ...with onExitCallback we crawl out

     *      The onEnterCallback is executed when item is found...
     *      ...after it will try to iterate to a deeper level (if exists) and continue processing item there
     *      ...then it returns and executes onExitCallback
     *
     * Other parameters:
     *
     *      items: any[]                            - a list of items (usually top level items)
     *      rootPath: PathSegment[]                 - an array that describes the path to the root inside the item[]
     *      includeRootNode: boolean = false        - if TRUE the 1st onEnterCallback will contain details for the supplied root item
     *      traverseAggregatesOnly: boolean = false - if FALSE onEnterCallback will be executed for each item; if TRUE then only for aggregates
     *
     *  Result:
     *
     *      mostly nothing,
     *      the only meaningful return value is returned when onEnterCallback decides to stop processing further
     */
  public static traverseGridData(
    items: any[],
    rootPath: PathSegment[] | null,
    includeRootNode = false,
    traverseAggregatesOnly = false,
    onEnterCallback: (event: TraverseCallbackDelegate) => any,
    onExitCallback: (event: TraverseCallbackDelegate) => any,

    // properties for recursive calls:
    stackData: any = null
  ): any {
    items = items || [];
    rootPath = rootPath || [];

    if (rootPath.length) {
      // get root from within items[] hierarchy
      const rootItem = PathSegment.getItem(items, rootPath);

      // get children of root
      items = (rootItem as any).items;

      // if root is set and we have to check it...
      if (includeRootNode) {
        const rootItemsParentPath = rootPath.length > 1 ? rootPath.slice(0, rootPath.length - 1) : [];
        const rootItemsIndex = rootPath[rootPath.length - 1].index;
        const rootItemIsAggregate = PathSegment.isAggregate(rootItem);

        // resEnter
        if (onEnterCallback) {
          const evEnter = new TraverseCallbackDelegate(rootItemsParentPath, rootItemsIndex, rootItem, rootItemIsAggregate, true);
          const resEnter = onEnterCallback(evEnter);

          if (evEnter.stopProcessing || evEnter.stopProcessingNodesBelow) {
            return resEnter;
          }
        }

        // iterate
        const result = Traverse.traverseGridDataInternal(items, rootPath, traverseAggregatesOnly, onEnterCallback, onExitCallback, stackData);
        if (result) return result;

        // exit
        if (onExitCallback) {
          const evExit = new TraverseCallbackDelegate(rootItemsParentPath, rootItemsIndex, rootItem, rootItemIsAggregate, true);
          const resExit = onExitCallback(evExit);

          if (evExit.stopProcessing || evExit.stopProcessingNodesBelow) {
            return resExit;
          }
        }

        // default (no) return value
        return;
      }
    }

    // if no root path or not includeRootNode
    return Traverse.traverseGridDataInternal(items, rootPath, traverseAggregatesOnly, onEnterCallback, onExitCallback, stackData);
  }

  public static traverseGridDataInternal(
    items: any[],
    parentPath: PathSegment[] | null,
    traverseAggregatesOnly = false,
    onEnterCallback: (event: TraverseCallbackDelegate) => any,
    onExitCallback: (event: TraverseCallbackDelegate) => any,

    // properties for recursive calls:
    stackData: any = null,
    state: TraverseGlobalState | null = null
  ): any {
    items = items || [];
    state = state || new TraverseGlobalState();

    for (let index = 0; index < items.length; index++) {
      const item = items[index];
      const isAggregate = PathSegment.isAggregate(item);

      if (!isAggregate && traverseAggregatesOnly) {
        // if this node is not an aggregate and we have to process aggregates (groups) only...
        return;
      }

      const itemPathArray = [].concat(parentPath, [new PathSegment(item, index)]);

      // enter
      let evEnter;

      if (onEnterCallback) {
        evEnter = new TraverseCallbackDelegate(parentPath, index, item, isAggregate, false, stackData);
        const resEnter = onEnterCallback(evEnter);

        if (evEnter.stopProcessing) {
          state.stopProcessing = true;
          return resEnter;
        }
      }

      // iterate (if possible)
      if (isAggregate && (!onEnterCallback || !evEnter.stopProcessingNodesBelow)) {
        const childItems = item['items'];

        // note: itemPath array is current level, chuld items is sub-level so the parent/child relationship is OK
        const result = Traverse.traverseGridDataInternal(childItems, itemPathArray, traverseAggregatesOnly, onEnterCallback, onExitCallback, evEnter ? evEnter.stackData : null, state);

        // NOTE: state.stopProcessing might be set also inside nested call
        if (state.stopProcessing) return result;
      }

      // exit
      if (onExitCallback) {
        const evExit = new TraverseCallbackDelegate(parentPath, index, item, isAggregate, false, stackData);
        const resExit = onExitCallback(evExit);

        if (evExit.stopProcessing) {
          state.stopProcessing = true;
          return resExit;
        }
      }
    }

    // no return value...
    return;
  }
}

class TraverseGlobalState {
  stopProcessing = false;
}

export class TraverseCallbackDelegate {
  isRoot: boolean;

  parentPath: PathSegment[];
  dataIndex: number;
  dataItem: any;

  isAggregate: boolean;

  stackData: any;

  stopProcessingNodesBelow = false;
  stopProcessing = false;

  constructor(parentPath: PathSegment[], dataIndex: number, dataItem: any, isAggregate: boolean, isRoot = false, stackData: any = null) {
    this.isRoot = isRoot;

    this.parentPath = parentPath;
    this.dataIndex = dataIndex;
    this.dataItem = dataItem;

    this.isAggregate = isAggregate;

    this.stackData = stackData;
  }
}
