import { MonoTypeOperatorFunction } from 'rxjs';
import { tap, map } from 'rxjs/operators';

/**
 * Return object should not be used as it masks real errors: it returns a HTTP 200 even on errors and requires additional error handling from our side!
 *
 * If you still use it, please wrap observable with a pipe and use custom: handleReturnObject("Some error occurred, error message was: {{error}}")
 */
class ReturnObject<T> {
  public hasError: boolean;
  public message: string;
  public data: T;
}

/**
 * Return object should not be used as it masks real errors: it returns a HTTP 200 even on errors and requires additional error handling from our side!
 *
 * If you still use it, please wrap observable with a pipe and use custom: handleReturnObject("Some error occured, error message was: {{error}}")
 */
class ReturnObjectPascalCase<T> {
  /* eslint-disable @typescript-eslint/naming-convention */
  public HasError: boolean;
  public Message: string;
  public Data: T;
  /* eslint-enable @typescript-eslint/naming-convention */
}

/**
 * @description
 * Default error handler and data fetcher for ReturnObject types.
 * Based on: https://medium.com/javascript-everyday/rxjs-custom-operators-f8b9aeab9631
 *
 * @param customErrorMessage
 * Customized error message with a {{error}} placeholder, e.g. "Some error occurred, error message was: {{error}}"
 */
export function handleReturnObject<T>(
  customErrorMessage = 'Some error occurred, error message was: {{error}}'
): MonoTypeOperatorFunction<T> {
  return (input$) =>
    input$.pipe(
      tap((result: ReturnObject<T> | ReturnObjectPascalCase<T> | T) => {
        // A wrongly designed way to handle errors, so I need to do additional check even on HTTP OK:
        if (
          result &&
          ((result as ReturnObject<T>).hasError ||
            (result as ReturnObjectPascalCase<T>).HasError)
        ) {
          if (customErrorMessage) {
            customErrorMessage = customErrorMessage.replace(
              '{{error}}',
              (result as ReturnObject<T>).message ??
                (result as ReturnObjectPascalCase<T>).Message
            );
            throw new Error(customErrorMessage);
          } else {
            throw new Error(
              (result as ReturnObject<T>).message ??
                (result as ReturnObjectPascalCase<T>).Message
            );
          }
        }
      }),
      map((result: ReturnObject<T> | ReturnObjectPascalCase<T> | T) => {
        // If we had no error previously, we can simply return the data
        return result &&
          ((result as ReturnObject<T>).data ||
            (result as ReturnObjectPascalCase<T>).Data)
          ? (result as ReturnObject<T>).data ??
              (result as ReturnObjectPascalCase<T>).Data
          : (result as T);
      })
    );
}
