import { Injectable } from '@angular/core';
import { ApplicationModel } from '@ups/xplat/api/dto';
import { SpartaCompanyService } from '@ups/xplat/api/services';
import { CompanyDto } from '@ups/xplat/api/dto';

@Injectable({
  providedIn: 'root',
})
export class CompanySpartaResolver {
  constructor(private spartaCompanyService: SpartaCompanyService, private applicationModel: ApplicationModel) {}

  public load(): Promise<CompanyDto[]> {
    const self = this;
    if (this.applicationModel.companiesFromSparta.length === 0) {
      return this.spartaCompanyService
        .fetchCompanies()
        .toPromise()
        .then((data: CompanyDto[]) => {
          self.applicationModel.companiesFromSparta = data;
          self.applicationModel.companiesFromSpartaForUser = data.filter((c) => c.IsVisibleForCurrentUser === true);
          return self.applicationModel.companiesFromSparta;
        });
    } else {
      return Promise.resolve(self.applicationModel.companiesFromSparta);
    }
  }
}
