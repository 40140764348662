<kendo-dialog
  title="Help Desk"
  id="help-desk-modal"
  #helpDeskModal
  (close)="cancel()"
  [width]="450"
  *ngIf="showModal"
>
  <form #supportForm="ngForm">
    <ng-container *ngIf="!isSaving">
        <div class="form-group">
        <label>Name:</label>
        <input
            id="ticketName"
            name="name"
            class="form-control autogrow transition-height"
            [(ngModel)]="document.FullName"
            disabled
            required
        />
        <label>Email:</label>
        <input
            id="ticketEmail"
            name="email"
            class="form-control autogrow transition-height"
            [(ngModel)]="document.Email"
            disabled
            required
        />
        </div>
        <div class="form-group">
        <label>Title:</label>
        <input
            id="ticketTitle"
            name="title"
            class="form-control autogrow transition-height"
            [(ngModel)]="document.ShortDescription"
            required
        />
        </div>
        <div class="form-group">
        <label>Brief Description:</label>
        <textarea
            id="ticketBody"
            rows="3"
            name="body"
            class="form-control autogrow transition-height"
            [(ngModel)]="document.Body"
            required
            minlength="3"
        ></textarea>
        </div>
        <div class="form-group">
        <label>Related Info:</label>
        <input
            id="ticketInfo"
            type="text"
            name="relatedInfo"
            class="form-control autogrow transition-height"
            [(ngModel)]="RelatedInfo"
            placeholder="Job #, Employee Name, etc"
        />
        </div>
    </ng-container>
    <ng-container *ngIf="isSaving">
        <div class="d-flex align-items-center justify-content-center">
            <i class="k-icon k-i-loading k-icon-xl"></i>
        </div>
    </ng-container>
  </form>
  <kendo-dialog-actions>
    <button
      type="button"
      class="k-button k-primary"
      (click)="confirm()"
      [disabled]="!supportForm.valid || isSaving"
    >
      Confirm
    </button>
    <button type="button" class="k-button" (click)="cancel()">Cancel</button>
  </kendo-dialog-actions>
</kendo-dialog>
