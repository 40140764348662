import { Injectable } from '@angular/core';
import { ApplicationModel } from '@ups/xplat/api/dto';
import { JobsService } from './jobs-service';

@Injectable({
  providedIn: 'root',
})
export class StatesResolver {
  constructor(private jobsService: JobsService, private applicationModel: ApplicationModel) {}

  load(): Promise<any> {
    if (this.applicationModel.states.length === 0) {
      return this.jobsService.getStates().then((data) => (this.applicationModel.states = data.Data));
    } else {
      return Promise.resolve(this.applicationModel.states);
    }
  }
}
