import {
  DynamicTextareaComponent,
  DynamicNumericTextboxComponent,
  DynamicInputComponent,
  DynamicCheckboxComponent,
  DynamicSignatureComponent,
  DynamicLabelComponent,
  DynamicDataLabelsComponent,
} from '../../dynamic-render/components';
import { DynamicAccordionComponent } from '../../dynamic-render/components/dynamic-accordion/dynamic-accordion.component';
import { DynamicButtonGroupComponent } from '../../dynamic-render/components/dynamic-buttongroup/dynamic-buttongroup.component';
import {
  DynamicTypeaheadAutocompleteComponent,
  DynamicTypeaheadCategoryComponent,
  DynamicTypeaheadCompanyComponent,
  DynamicTypeaheadEmployeeComponent,
  DynamicTypeaheadFacilityComponent,
  DynamicTypeaheadJobComponent,
} from '../../dynamic-render/components/dynamic-typeaheads';
import {
  EditorFormCheckboxComponent,
  EditorFormNumericTextboxComponent,
  EditorFormTextareaComponent,
  EditorFormTextInputComponent,
} from '../components/editors';
import { DynComponentEditorMapping } from './dr-admin-map-resolver.service';

export const EDITOR_COMPONENT_MAPPING: DynComponentEditorMapping = {
  // todo:
  // addressautocomplete: {
  //   controlName: 'textarea',
  //   displayName: 'Address Autocomplete',
  //   icon: 'file-alt',
  //   iconSrc: 'ico-textare.svg',
  //   toolboxGroup: 'form',
  //   renderedComponent: DynamicTextareaComponent,
  //   editorComponent: EditorFormTextareaComponent,
  //   defaultData: {
  //     placeholder: 'Placeholder',
  //   },
  //   defaultLayout: {
  //     row: 0,
  //     col: 0,
  //     sizeX: 7,
  //     sizeY: 2,
  //   },
  // },
  label: {
    controlName: 'label',
    displayName: 'Label',
    icon: 'text',
    iconSrc: null,
    toolboxGroup: 'form',
    renderedComponent: DynamicLabelComponent,
    editorComponent: EditorFormTextInputComponent,
    defaultData: {
      placeholder: 'Placeholder',
      label: 'Label',
    },
    defaultLayout: {
      row: 0,
      col: 0,
      sizeX: 7,
      sizeY: 2,
    },
  },
  dataLabels: {
    controlName: 'dataLabels',
    displayName: 'Data Labels',
    icon: 'text',
    iconSrc: null,
    toolboxGroup: 'form',
    renderedComponent: DynamicDataLabelsComponent,
    editorComponent: EditorFormTextInputComponent,
    defaultData: {
      placeholder: 'Placeholder',
      label: 'Data Labels',
    },
    defaultLayout: {
      row: 0,
      col: 0,
      sizeX: 7,
      sizeY: 2,
    },
  },
  input: {
    controlName: 'input',
    displayName: 'Text Input',
    icon: 'file-alt',
    iconSrc: 'ico-textare.svg',
    toolboxGroup: 'form',
    renderedComponent: DynamicInputComponent,
    editorComponent: EditorFormTextInputComponent,
    defaultData: {
      placeholder: 'Placeholder',
      label: 'Label',
    },
    defaultLayout: {
      row: 0,
      col: 0,
      sizeX: 7,
      sizeY: 2,
    },
  },
  textarea: {
    controlName: 'textarea',
    displayName: 'TextArea',
    icon: 'file-alt',
    iconSrc: 'ico-textare.svg',
    toolboxGroup: 'form',
    renderedComponent: DynamicTextareaComponent,
    editorComponent: EditorFormTextareaComponent,
    defaultData: {
      placeholder: 'Placeholder',
    },
    defaultLayout: {
      row: 0,
      col: 0,
      sizeX: 7,
      sizeY: 2,
    },
  },
  checkbox: {
    controlName: 'checkbox',
    displayName: 'Checkbox',
    icon: 'file-alt',
    iconSrc: 'ico-check.svg',
    toolboxGroup: 'form',
    renderedComponent: DynamicCheckboxComponent,
    editorComponent: EditorFormCheckboxComponent,
    defaultData: {
      checked: 'false',
      label: 'Label',
    },
    defaultLayout: {
      row: 0,
      col: 0,
      sizeX: 7,
      sizeY: 2,
    },
  },
  // radio: {
  //   controlName: 'radio',
  //   displayName: 'Radio Group',
  //   icon: 'asterisk',
  //   iconSrc: 'ico-radio-alt.svg',
  //   toolboxGroup: 'form',
  //   renderedComponent: DynamicRadioComponent,
  //   editorComponent: EditorFormRadioComponent,
  //   /*
  //     defaultControlValues: {
  //       data: JSON.stringify({options: ['Placeholder Option']})
  //     },
  //     */
  //   defaultData: {
  //     options: ['Placeholder Option'],
  //     label: 'Radio Group',
  //   },
  //   defaultLayout: {
  //     row: 0,
  //     col: 0,
  //     sizeX: 7,
  //     sizeY: 2,
  //   },
  // },
  numerictextbox: {
    controlName: 'numerictextbox',
    displayName: 'Numeric Textbox',
    icon: 'sort-numeric-up-alt',
    iconSrc: 'ico-numeric.svg',
    toolboxGroup: 'form',
    renderedComponent: DynamicNumericTextboxComponent,
    editorComponent: EditorFormNumericTextboxComponent,
    defaultData: {
      placeholder: 'Placeholder',
    },
    defaultLayout: {
      row: 0,
      col: 0,
      sizeX: 8,
      sizeY: 2,
    },
  },
  // button: {
  //   controlName: 'button',
  //   displayName: 'Button',
  //   icon: 'ad',
  //   iconSrc: 'ico-button.svg',
  //   toolboxGroup: 'form',
  //   renderedComponent: DynamicButtonComponent,
  //   editorComponent: EditorFormButtonComponent,
  //   defaultData: {
  //     content: 'Button',
  //   },
  //   defaultLayout: {
  //     row: 0,
  //     col: 0,
  //     sizeX: 3,
  //     sizeY: 1,
  //   },
  // },
  // circle: {
  //   controlName: 'circle',
  //   displayName: 'Circle',
  //   icon: 'circle',
  //   iconSrc: 'ico-radio.svg',
  //   toolboxGroup: 'drawing',
  //   renderedComponent: DynamicCircleComponent,
  //   editorComponent: EditorDrawingCircleComponent,
  //   defaultData: {},
  //   defaultLayout: {
  //     row: 0,
  //     col: 0,
  //     sizeX: 4,
  //     sizeY: 4,
  //   },
  // },
  // chart: {
  //   controlName: 'chart',
  //   displayName: 'Chart',
  //   icon: 'chart-area',
  //   iconSrc: 'ico-chart-bars.svg',
  //   toolboxGroup: 'dashboard',
  //   renderedComponent: DynamicChartComponent,
  //   editorComponent: EditorDashboardChartComponent,

  //   defaultData: {},
  //   defaultLayout: {
  //     row: 0,
  //     col: 0,
  //     sizeX: 24,
  //     sizeY: 10,
  //   },
  //   renderDelay: 1000,
  // },
  // image: {
  //   controlName: 'image',
  //   displayName: 'Image',
  //   icon: 'image',
  //   iconSrc: 'ico-image.svg',
  //   toolboxGroup: 'dashboard',
  //   renderedComponent: DynamicImageComponent,
  //   editorComponent: EditorDashboardImageComponent,
  //   defaultData: {},
  //   defaultLayout: {
  //     row: 0,
  //     col: 0,
  //     sizeX: 12,
  //     sizeY: 10,
  //   },
  // },
  // safetyatrisk: {
  //   controlName: 'safetyatrisk',
  //   displayName: 'Hazard Row',
  //   icon: 'image',
  //   iconSrc: 'ico-image.svg',
  //   toolboxGroup: 'safety',
  //   renderedComponent: DynamicSafetyAtRiskComponent,
  //   editorComponent: EditorSafetyAtRiskComponent,
  //   defaultData: {
  //     label: 'L1 Hazard',
  //   },
  //   defaultLayout: {
  //     row: 0,
  //     col: 0,
  //     sizeX: 10,
  //     sizeY: 10,
  //   },
  // },
  typeahead: {
    controlName: 'typeahead',
    displayName: 'Typeahead Search',
    icon: 'file-alt',
    iconSrc: 'ico-textare.svg',
    toolboxGroup: 'form',
    renderedComponent: DynamicTypeaheadEmployeeComponent,
    editorComponent: EditorFormTextareaComponent,
    defaultData: {
      placeholder: 'Placeholder',
    },
    defaultLayout: {
      row: 0,
      col: 0,
      sizeX: 7,
      sizeY: 2,
    },
  },
  'typeahead-autocomplete': {
    controlName: 'typeahead-autocomplete',
    displayName: 'Typeahead Autocomplete',
    icon: 'file-alt',
    iconSrc: 'ico-textare.svg',
    toolboxGroup: 'form',
    renderedComponent: DynamicTypeaheadAutocompleteComponent,
    editorComponent: EditorFormTextareaComponent,
    defaultData: {
      placeholder: 'Placeholder',
    },
    defaultLayout: {
      row: 0,
      col: 0,
      sizeX: 7,
      sizeY: 2,
    },
  },
  'typeahead-category': {
    controlName: 'typeahead-category',
    displayName: 'Typeahead Category',
    icon: 'file-alt',
    iconSrc: 'ico-textare.svg',
    toolboxGroup: 'form',
    renderedComponent: DynamicTypeaheadCategoryComponent,
    editorComponent: EditorFormTextareaComponent,
    defaultData: {
      placeholder: 'Placeholder',
    },
    defaultLayout: {
      row: 0,
      col: 0,
      sizeX: 7,
      sizeY: 2,
    },
  },
  'typeahead-company': {
    controlName: 'typeahead-company',
    displayName: 'Company Search',
    valueProperty: 'CompanyNumber',
    icon: 'file-alt',
    iconSrc: 'ico-textare.svg',
    toolboxGroup: 'form',
    renderedComponent: DynamicTypeaheadCompanyComponent,
    editorComponent: EditorFormTextareaComponent,
    defaultData: {
      placeholder: 'Placeholder',
    },
    defaultLayout: {
      row: 0,
      col: 0,
      sizeX: 7,
      sizeY: 2,
    },
  },
  'typeahead-customers': {
    controlName: 'typeahead-customers',
    displayName: 'Customer Search',
    valueProperty: 'ParentCustomerID',
    icon: 'file-alt',
    iconSrc: 'ico-textare.svg',
    toolboxGroup: 'form',
    renderedComponent: DynamicTypeaheadEmployeeComponent,
    editorComponent: EditorFormTextareaComponent,
    defaultData: {
      placeholder: 'Placeholder',
    },
    defaultLayout: {
      row: 0,
      col: 0,
      sizeX: 7,
      sizeY: 2,
    },
  },
  'typeahead-employee': {
    controlName: 'typeahead-employee',
    displayName: 'Employee Search',
    valueProperty: 'Id',
    icon: 'file-alt',
    iconSrc: 'ico-textare.svg',
    toolboxGroup: 'form',
    renderedComponent: DynamicTypeaheadEmployeeComponent,
    editorComponent: EditorFormTextareaComponent,
    defaultData: {
      placeholder: 'Placeholder',
    },
    defaultLayout: {
      row: 0,
      col: 0,
      sizeX: 7,
      sizeY: 2,
    },
  },
  'typeahead-facilities': {
    controlName: 'typeahead-facilities',
    displayName: 'Facility Search',
    valueProperty: 'ParentFacilityID',
    icon: 'file-alt',
    iconSrc: 'ico-textare.svg',
    toolboxGroup: 'form',
    renderedComponent: DynamicTypeaheadFacilityComponent,
    editorComponent: EditorFormTextareaComponent,
    defaultData: {
      placeholder: 'Placeholder',
    },
    defaultLayout: {
      row: 0,
      col: 0,
      sizeX: 7,
      sizeY: 2,
    },
  },
  'typeahead-job': {
    controlName: 'typeahead-job',
    displayName: 'Job Search',
    valueProperty: 'JobID',
    icon: 'file-alt',
    iconSrc: 'ico-textare.svg',
    toolboxGroup: 'form',
    renderedComponent: DynamicTypeaheadJobComponent,
    editorComponent: EditorFormTextareaComponent,
    defaultData: {
      placeholder: 'Placeholder',
    },
    defaultLayout: {
      row: 0,
      col: 0,
      sizeX: 7,
      sizeY: 2,
    },
  },
  date: {
    // TEMP, these controls are not implemented on web in dynamic content yet.
    controlName: 'date',
    displayName: 'Date',
    icon: 'calendar',
    iconSrc: null,
    toolboxGroup: 'form',
    renderedComponent: DynamicTextareaComponent,
    editorComponent: EditorFormTextareaComponent,
    defaultData: {},
    defaultLayout: {
      row: 0,
      col: 0,
      sizeX: 10,
      sizeY: 10,
    },
    renderDelay: 0,
  },
  file: {
    // TEMP, these controls are not implemented on web in dynamic content yet.
    controlName: 'file',
    displayName: 'Attachments',
    icon: 'file',
    iconSrc: null,
    toolboxGroup: 'form',
    renderedComponent: DynamicTextareaComponent,
    editorComponent: EditorFormTextareaComponent,
    defaultData: {},
    defaultLayout: {
      row: 0,
      col: 0,
      sizeX: 10,
      sizeY: 10,
    },
    renderDelay: 0,
  },
  accordion: {
    controlName: 'accordion',
    displayName: 'Accordion',
    icon: 'file-alt',
    iconSrc: 'ico-textare.svg',
    toolboxGroup: 'form',
    renderedComponent: DynamicAccordionComponent,
    editorComponent: EditorFormTextInputComponent,
    defaultData: {
      placeholder: 'Placeholder',
      label: 'Label',
    },
    defaultLayout: {
      row: 0,
      col: 0,
      sizeX: 7,
      sizeY: 2,
    },
  },
  buttongroup: {
    controlName: 'buttongroup',
    displayName: 'Button Group',
    icon: 'file-alt',
    iconSrc: 'ico-textare.svg',
    toolboxGroup: 'form',
    renderedComponent: DynamicButtonGroupComponent,
    editorComponent: EditorFormTextInputComponent,
    defaultData: {
      placeholder: 'Placeholder',
      label: 'Label',
    },
    defaultLayout: {
      row: 0,
      col: 0,
      sizeX: 7,
      sizeY: 2,
    },
  },
  signature: {
    controlName: 'signature',
    displayName: 'Signature',
    icon: 'pen',
    iconSrc: null,
    toolboxGroup: 'form',
    renderedComponent: DynamicSignatureComponent,
    editorComponent: EditorFormTextInputComponent,
    defaultData: {},
    defaultLayout: {
      row: 0,
      col: 0,
      sizeX: 10,
      sizeY: 10,
    },
  },
};
