import { BaseModel } from '../base.model';

export class MPRModel extends BaseModel {
  VPJobID: string = '';
  StartDate: Date;
  EndDate: Date;
  HoursWorked: string = '';
  VPJobId?: string; // some use camel case?

  Recruiter: any;
  Status: any;

  editable: any;

  // constructor(o: any = null) {
  //   super(o);
  //   if (o) {
  //     extend(this, o);
  //   }
  // }
}
