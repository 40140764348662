/**
 * Created by Tom.Jaeschke on 5/23/2017.
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SimpleDatePipe } from './simple-date.pipe';
import { SimpleUTCDatePipe } from './simple-utc-date.pipe';

@NgModule({
  declarations: [SimpleDatePipe, SimpleUTCDatePipe],
  exports: [SimpleDatePipe, SimpleUTCDatePipe],
  imports: [CommonModule],
})
export class SimpleDateModule {}
