/* eslint-disable */
import { Directive, OnDestroy, OnInit } from '@angular/core';
import {
  BaseComponent,
  IDynamicModel,
  IDynamicModelGroup,
  LogService,
  ProgressService,
} from '@ups/xplat/core';
import { DynamicRenderService } from '../../dynamic-render/services/dynamic-render.service';
import { deepClone, getFullNameFromInfo } from '@ups/xplat/utils';
import { take, takeUntil } from 'rxjs/operators';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { DynamicEventBusTypes } from '../../dynamic-render/utils';
import { Store } from '@ngrx/store';
import { UserState } from '@ups/user';

@Directive()
export abstract class HazardCardBaseComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  editDataItem: unknown;
  isNew = true;
  isDuplicating = false;
  showFormSuccess = false;

  dynamicGroup: IDynamicModelGroup;
  private _explanationLabel = 'Explanation And Mitigation Strategy';
  private _explanationStyle: { label: Partial<CSSStyleDeclaration> } = {
    label: {
      color: '#adafb9',
      fontWeight: 'normal',
    },
  };
  private infoOverlayMessages = {
    warnings: 0,
    safe: 0,
    atRisk: 0,
  };

  hazardControls: IDynamicModel[] = [
    {
      label: 'Observer Name',
      formControlName: 'observerNum',
      placeholder: 'Search for Employee...',
      required: true,
      valueProperty: 'Id',
      type: 'typeahead-employee',
    },
    {
      label: 'Date',
      formControlName: 'date',
      placeholder: 'Choose a date...',
      valueProperty: 'jsdate',
      required: true,
      type: 'date',
      options: {
        minDate: new Date(
          new Date(Date.now() - 1000 * 60 * 60 * 24 * 6).setHours(0, 0, 0, 0)
        ),
        showTimePicker: true,
      },
    },
    {
      label: 'Facility',
      formControlName: 'facilityId',
      valueProperty: 'ParentFacilityID',
      placeholder: 'Choose a Facility...',
      required: false,
      type: 'typeahead-facilities',
      options: {
        updateOnValueChange: [
          // an array of other fields to populate based on the selected item.
          {
            formControlName: 'clientContactName',
            property: 'ParentCustomerName',
            matchTargetProperty: 'CustomerName',
          },
          {
            formControlName: 'clientContactCity',
            property: 'City',
          },
          {
            formControlName: 'clientContactState',
            property: 'State',
          },
        ],
      },
    },
    {
      label: 'Client',
      formControlName: 'clientContactName',
      placeholder: 'Search for Clients...',
      valueProperty: 'ParentCustomerID',
      required: true,
      type: 'typeahead-customers',
      options: {
        updateOnValueChange: [
          // an array of other fields to populate based on the selected item.
          {
            formControlName: 'facilityId',
            property: 'CustomerName',
            matchTargetProperty: 'ParentCustomerName',
          },
        ],
        scopeQueryWhenFormControlIsSet: {
          formControlName: 'facilityId',
          matchTargetProperty: 'ParentCustomerName',
        },
      },
    },

    {
      // this field is meant to only show jobs associated with the selected facility,
      // but i did not find an API endpoint for that in the job service.
      label: 'Job',
      formControlName: 'Id',
      required: false,
      valueProperty: 'Name',
      type: 'typeahead-job',
      placeholder: 'Search for job...',
    },
    {
      label: 'Task Performed',
      formControlName: 'taskPerformed',
      required: true,
      type: 'textarea',
      options: {
        maxlength: 50,
      },
    },
    {
      label: 'Personal Injury Risk',
      formArrayName: 'personalInjuryRisk',
      type: 'accordion',
      required: false,
      options: {
        accordionCloseOthers: true,
        accordion: [
          this._createButtonGroup(
            'ppeBasic',
            'I1: PPE basic (wrong or not using)'
          ),
          this._createExplanationControl('ppeBasic'),
          this._createButtonGroup(
            'ppeSpecial',
            'I2: PPE special (wrong or not using)'
          ),
          this._createExplanationControl('ppeSpecial'),

          this._createButtonGroup(
            'muscle',
            'I3: Muscle instead of machine (over 50 lbs)'
          ),
          this._createExplanationControl('muscle'),
          this._createButtonGroup('barricade', 'I4: Crossing barricade'),
          this._createExplanationControl('barricade'),

          this._createButtonGroup(
            'withoutPpe',
            `I5: Within 10' of a job without PPE required for that job`
          ),
          this._createExplanationControl('withoutPpe'),
        ],
      },
    },
    {
      label: 'Line of Fire',
      formArrayName: 'lineOfFire',
      type: 'accordion',
      required: false,
      options: {
        accordion: [
          this._createButtonGroup('lof1', 'L1: Unsafe body position'),
          this._createExplanationControl('lof1'),
          this._createButtonGroup('lof2', 'L2: Unsafe hand position'),
          this._createExplanationControl('lof2'),
          this._createButtonGroup('lof3', 'L3: Walking/working under a load'),
          this._createExplanationControl('lof3'),
        ],
      },
    },
    {
      label: 'Work Practices',
      formArrayName: 'workPractices',
      type: 'accordion',
      required: false,
      options: {
        accordion: [
          this._createButtonGroup(
            'wp1',
            'W1: Not using 3 points of contact climbing/not using handrails'
          ),
          this._createExplanationControl('wp1'),
          this._createButtonGroup(
            'wp2',
            'W2: Climbing/standing on equip. (need scaffold, ladder or stool)'
          ),
          this._createExplanationControl('wp2'),
          this._createButtonGroup('wp3', 'W3: Improper ladder use'),
          this._createExplanationControl('wp3'),
          this._createButtonGroup(
            'wp4',
            'W4: Worker not qualified on tool or equipment'
          ),
          this._createExplanationControl('wp4'),
          this._createButtonGroup(
            'wp5',
            'W5: Improper use of tool or equipment'
          ),
          this._createExplanationControl('wp5'),
          this._createButtonGroup('wp6', 'W6: Improper spark containment'),
          this._createExplanationControl('wp6'),
          this._createButtonGroup('wp7', 'W7: Improper use of fall protection'),
          this._createExplanationControl('wp7'),
          this._createButtonGroup(
            'wp8',
            'W8: Improper operation of mobile equipment'
          ),
          this._createExplanationControl('wp8'),
          this._createButtonGroup(
            'wp9',
            'W9: Not using a spotter for moving equipment'
          ),
          this._createExplanationControl('wp9'),
          this._createButtonGroup(
            'wp10',
            'W10: Poor or improper rigging practices'
          ),
          this._createExplanationControl('wp10'),
          this._createButtonGroup(
            'wp11',
            'W11: Swing path not cleared for crane load'
          ),
          this._createExplanationControl('wp11'),
          this._createButtonGroup(
            'wp12',
            'W12: JSA/addendum/PHA issues (wrong/incomplete)'
          ),
          this._createExplanationControl('wp12'),
        ],
      },
    },
    {
      label: 'Procedures',
      formArrayName: 'procedures',
      type: 'accordion',
      required: false,
      options: {
        accordion: [
          this._createButtonGroup(
            'pro1',
            'P1: Permit completed properly & all signed'
          ),
          this._createExplanationControl('pro1'),
          this._createButtonGroup(
            'pro2',
            'P2: Not following procedure or no procedure for task'
          ),
          this._createExplanationControl('pro2'),
          this._createButtonGroup('pro3', 'P3: LOTO/energy isolation'),
          this._createExplanationControl('pro3'),
          this._createButtonGroup(
            'pro4',
            'P4: Confined space issues (ventilation, no hole watch, gas test, etc.)'
          ),
          this._createExplanationControl('pro3'),
          this._createButtonGroup('pro5', 'P5: Hot work issues'),
          this._createExplanationControl('pro5'),
        ],
      },
    },
    {
      label: 'Housekeeping / Travel Path Hazards',
      formArrayName: 'housekeeping',
      type: 'accordion',
      required: false,
      options: {
        accordion: [
          this._createButtonGroup(
            'house1',
            'H1: Uneven ground conditions (holes, uneven concrete or grating, etc.)'
          ),
          this._createExplanationControl('house1'),
          this._createButtonGroup(
            'house2',
            'H2: Slippery surfaces (algae, ice, water, pellets, etc.)'
          ),
          this._createExplanationControl('house2'),
          this._createButtonGroup(
            'house3',
            'H3: Tripping hazards (rigging, equipment, hoses, piping, etc.)'
          ),
          this._createExplanationControl('house3'),
          this._createButtonGroup(
            'house4',
            'H4: Clutter in work area (trash, materials, tools, etc.)'
          ),
          this._createExplanationControl('house4'),
          this._createButtonGroup('house5', 'H5: Potential falling objects'),
          this._createExplanationControl('house5'),
          this._createButtonGroup(
            'house6',
            'H6: Barricades (tags, setup, etc.)'
          ),
          this._createExplanationControl('house6'),
        ],
      },
    },
    {
      label: 'Facility Hazards',
      formArrayName: 'facility',
      type: 'accordion',
      required: false,
      options: {
        accordion: [
          this._createButtonGroup('fac1', 'F1: Hot surfaces'),
          this._createExplanationControl('fac1'),
          this._createButtonGroup(
            'fac2',
            'F2: Head knockers/body knockers (pipe, valve stems, etc)'
          ),
          this._createExplanationControl('fac2'),
          this._createButtonGroup(
            'fac3',
            'F3: Open ended flanges, bleed points, double block and bleeds'
          ),
          this._createExplanationControl('fac3'),
          this._createButtonGroup('fac4', 'F4: Lighting inadequate'),
          this._createExplanationControl('fac4'),
          this._createButtonGroup(
            'fac5',
            'F5: Sensitive equipment (tubing, flow meters, probes, etc.'
          ),
          this._createExplanationControl('fac5'),
          this._createButtonGroup(
            'fac6',
            'F6: Cuts/punctures/sharp edges (metal insulation, wire, banding, etc.)'
          ),
          this._createExplanationControl('fac6'),
        ],
      },
    },
    {
      label: 'Job Planning / Set-up',
      formArrayName: 'jobPlanning',
      type: 'accordion',
      required: false,
      options: {
        accordion: [
          this._createButtonGroup(
            'jobplan1',
            'J1: Platform or scaffold not inspected/adequate for job'
          ),
          this._createExplanationControl('jobplan1'),
          this._createButtonGroup(
            'jobplan2',
            'J2: Adjacent work crews (hazards introduced)'
          ),
          this._createExplanationControl('jobplan2'),
          this._createButtonGroup(
            'jobplan3',
            'J3: Overhead loads/work crews (material/tools/debris falling)'
          ),
          this._createExplanationControl('jobplan3'),
        ],
      },
    },
    {
      label: 'Environment',
      formArrayName: 'environment',
      type: 'accordion',
      required: false,
      options: {
        accordion: [
          this._createButtonGroup(
            'env1',
            'E1: Weather (windy, lightning, rain, fog, etc.)'
          ),
          this._createExplanationControl('env1'),
          this._createButtonGroup('env2', 'E2: Heat stress or cold stress'),
          this._createExplanationControl('env2'),
          this._createButtonGroup('env3', 'E3: Insects or animals'),
          this._createExplanationControl('env3'),
        ],
      },
    },
    {
      label: 'Tools / Equipment',
      formArrayName: 'toolsEquipment',
      type: 'accordion',
      required: false,
      options: {
        accordion: [
          this._createButtonGroup('tools1', 'T1: Defective tool/not inspected'),
          this._createExplanationControl('tools1'),
          this._createButtonGroup('tools2', 'T2: Safety guard/device missing'),
          this._createExplanationControl('tools2'),
          this._createButtonGroup(
            'tools3',
            'T3: Electrical hazards (extension cords frayed, loose wiring, etc.)'
          ),
          this._createExplanationControl('tools3'),
          this._createButtonGroup(
            'tools4',
            'T4: Compressed gas cylinder storage and use'
          ),
          this._createExplanationControl('tools4'),
          this._createButtonGroup(
            'tools5',
            'T5: Equipment defective/Inspection not current'
          ),
          this._createExplanationControl('tools5'),
        ],
      },
    },
  ];

  constructor(
    protected store: Store,
    protected log: LogService,
    protected progress: ProgressService,
    public dynamicRender: DynamicRenderService
  ) {
    super();
  }

  ngOnInit() {
    // this.progress.toggleSpinner(true);
    // this.employeeService.myInfo$
    //   .pipe(
    //     filter((info) => !!info),
    //     take(1),
    //     takeUntil(this.destroy$)
    //   )
    //   .subscribe(() => {
    //     this.progress.toggleSpinner(false);
    this.addNew();
    // });

    this.dynamicRender.activeAction = () => {
      return new Promise(() => {
        const formData: { [key: string]: string | number } = {};
        const formProperties = Object.keys(
          this.dynamicRender.activeForm.controls
        ).filter((k) => k !== 'submit');
        this.log.debug('---- submitting form data ----');
        const failedValidateProps = {};

        for (const prop of formProperties) {
          this.log.debug('prop:', prop);
          const fieldControlIndex = this.hazardControls.findIndex(
            (f) => f.formControlName === prop || f.formArrayName === prop
          );
          const fieldControl = this.hazardControls[fieldControlIndex];
          if (fieldControl && !fieldControl.ignoreValue) {
            const handleValue = (
              control: IDynamicModel,
              value: string | number
            ) => {
              let formValue = value;
              // console.log(control.formControlName, 'control:', control)

              if (formValue && control.valueProperty) {
                formValue = formValue[control.valueProperty];
              }
              formData[control.formControlName] =
                control.inputType === 'number' ? +formValue : formValue;
              this.log.debug(control.formControlName, formValue);
            };

            if (fieldControl.formArrayName) {
              // flatten category/groups onto submitted form
              const formArray = <UntypedFormArray>(
                this.dynamicRender.activeForm.controls[prop]
              );
              const formArrayGroup = <UntypedFormGroup>formArray.controls[0];
              const controlInGroup =
                this.dynamicGroup.controls[fieldControlIndex];
              // const groupProperties = Object.keys(formArrayGroup.controls).filter((k) => k !== 'submit');
              for (const groupControl of fieldControl.options[
                fieldControl.type
              ]) {
                if (formArrayGroup.controls[groupControl.formControlName]) {
                  const value =
                    formArrayGroup.controls[groupControl.formControlName].value;
                  const buttonGroupFormControlName =
                    groupControl.formControlName.replace('Explanation', '');
                  const buttonGroupValue =
                    formArrayGroup.controls[buttonGroupFormControlName].value;
                  if (
                    groupControl.formControlName.indexOf('Explanation') > -1 &&
                    ['safe', 'at_risk'].includes(buttonGroupValue) &&
                    !value
                  ) {
                    // explanation must be filled out if the button group is 'safe' or 'at_risk'
                    failedValidateProps[fieldControl.formArrayName] = true;
                  }
                  controlInGroup.options.accordionHasError =
                    !!failedValidateProps[fieldControl.formArrayName];
                  handleValue(
                    groupControl,
                    formArrayGroup.controls[groupControl.formControlName].value
                  );
                }
              }
            } else {
              handleValue(
                fieldControl,
                this.dynamicRender.activeForm.controls[prop].value
              );
            }
          }
        }
        const failedValidations = Object.keys(failedValidateProps);
        if (failedValidations.length === 0) {
          this.infoOverlayMessages.warnings = 0;
          this.dynamicRender.eventBus.emit(
            this.dynamicRender.eventBus.types.updateInfoOverlay,
            {
              show: true,
              alignRight: true,
              message: this._updateInfoOverlayMessage(),
            }
          );
          this.log.debug('formData:', formData);
          this.progress.toggleSpinner(true);
          // Fake this here for now:
          setTimeout(() => {
            this.progress.toggleSpinner(false);
            this.showFormSuccess = true;
            setTimeout(() => {
              this.showFormSuccess = false;
              this.dynamicRender.clearActiveForm();
              this.infoOverlayMessages = {
                warnings: 0,
                atRisk: 0,
                safe: 0,
              };
              this.dynamicRender.eventBus.emit(
                this.dynamicRender.eventBus.types.updateInfoOverlay,
                {
                  show: false,
                }
              );
            }, 3000);
          }, 2000);
        } else {
          this.progress.toggleSpinner(false);
          this.infoOverlayMessages.warnings = failedValidations.length;
          this.dynamicRender.eventBus.emit(
            this.dynamicRender.eventBus.types.updateInfoOverlay,
            {
              show: true,
              alignRight: true,
              message: this._updateInfoOverlayMessage(),
            }
          );
          this.dynamicRender.win.alert(
            'An explanation must be provided for Safe and At Risk reports.'
          );
        }
      });
    };

    // allow for dynamic updates to the info overlay
    this.dynamicRender.eventBus
      .observe(DynamicEventBusTypes.dynamicDataUpdate)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.dynamicRender.activeForm) {
          // this.log.debug('this.dynamicRender.activeForm.value:', this.dynamicRender.activeForm.value);
          this.infoOverlayMessages.safe = 0;
          this.infoOverlayMessages.atRisk = 0;

          for (const key of Object.keys(this.dynamicRender.activeForm.value)) {
            if (this.dynamicRender.activeForm.value[key]) {
              if (Array.isArray(this.dynamicRender.activeForm.value[key])) {
                // FormArray (injury reports)
                const injuryGroup = this.dynamicRender.activeForm.value[key][0];
                for (const injuryKey of Object.keys(injuryGroup)) {
                  if (injuryGroup[injuryKey] === 'at_risk') {
                    this.infoOverlayMessages.atRisk++;
                  }
                  if (injuryGroup[injuryKey] === 'safe') {
                    this.infoOverlayMessages.safe++;
                  }
                }
              }
            }
          }
          console.log(
            'this.infoOverlayMessages.atRisk:',
            this.infoOverlayMessages.atRisk
          );
          if (
            this.infoOverlayMessages.atRisk ||
            this.infoOverlayMessages.safe
          ) {
            this.dynamicRender.eventBus.emit(
              this.dynamicRender.eventBus.types.updateInfoOverlay,
              {
                show: true,
                alignRight: true,
                message: this._updateInfoOverlayMessage(),
              }
            );
          } else {
            this.dynamicRender.eventBus.emit(
              this.dynamicRender.eventBus.types.updateInfoOverlay,
              {
                show: false,
              }
            );
          }
        }
      });
  }

  private _updateInfoOverlayMessage() {
    if (this.dynamicRender.win.isMobile) {
      const messages = [];
      if (this.infoOverlayMessages.warnings) {
        messages.push(this.infoOverlayMessages.warnings + ' Errors');
      }
      if (this.infoOverlayMessages.safe) {
        messages.push(this.infoOverlayMessages.safe + ' Safe');
      }
      if (this.infoOverlayMessages.atRisk) {
        messages.push(this.infoOverlayMessages.atRisk + ' At Risk');
      }
      return messages.join(', ');
    } else {
      return `<div style="width: 192px; color: #fff; float: right; margin-right: 20px">
      <div style="float:left; color: #ff1800; margin-right: 28px; opacity: ${
        this.infoOverlayMessages.warnings ? '1' : '0'
      } ">
       <div style="font-size:30px"><i class="fa fa-exclamation-triangle"></i></div>
       <div style="font-size:18px">${
         this.infoOverlayMessages.warnings
       } Errors</div>
      </div>
      <div style="float:left">
       <div style="font-size:30px">${this.infoOverlayMessages.safe}</div>
       <div style="font-size:18px">Safe</div>
      </div>
      <div>
       <div style="font-size:30px">${this.infoOverlayMessages.atRisk}</div>
       <div style="font-size:18px">At Risk</div>
      </div>
    </div>`;
    }
  }

  private _createButtonGroup(
    formControlName: string,
    label: string
  ): IDynamicModel {
    return {
      formControlName,
      label,
      type: 'buttongroup',
      required: true,
      options: {
        style: this.dynamicRender.win.isMobile
          ? {
              label: {
                fontSize: '14',
                fontWeight: 'normal',
              },
            }
          : null,
        emitEventOnChange: true,
        // labelInline: true,
        showOnValues: {
          formControlName: `${formControlName}Explanation`,
          values: ['safe', 'at_risk'],
        },
        buttongroup: [
          {
            label: 'Safe',
            value: 'safe',
          },
          {
            label: 'At Risk',
            value: 'at_risk',
          },
          {
            label: 'Not Observed',
            value: 'not_observed',
            selected: true,
          },
        ],
      },
    };
  }

  private _createExplanationControl(formControlName: string): IDynamicModel {
    return {
      formControlName: `${formControlName}Explanation`,
      type: 'textarea',
      label: this._explanationLabel,
      placeholder: 'Start typing...',
      options: {
        hidden: true,
        labelSoft: true,
        style: this._explanationStyle,
      },
    };
  }

  addNew() {
    this._configureAndOpenForm();
  }

  // to do: wire this up.
  private _configureAndOpenForm(dataItem?: unknown) {
    this.store
      .pipe(UserState.selectCurrentTruthy(), take(1), takeUntil(this.destroy$))
      .subscribe((myInfo) => {
        this.log.debug(myInfo);
        const isNew = this.isDuplicating || dataItem === undefined;
        this.editDataItem = dataItem || {};
        const editingFields = this.editDataItem
          ? []
          : deepClone(this.hazardControls);
        if (this.editDataItem) {
          // this.log.debug('this.editDataItem:', this.editDataItem);
          for (const field of this.hazardControls) {
            const editField: IDynamicModel = deepClone(field);
            if (field.formControlName === 'observerNum' && myInfo) {
              editField.value = myInfo.Data.EmployeeID;
              editField.options = {
                initWithQuery: getFullNameFromInfo(myInfo),
              };
            } else {
              editField.value = this.editDataItem[field.formControlName];
            }
            editingFields.push(editField);
          }
        }
        this.dynamicGroup = this.dynamicRender.createConfigGroup({
          groupName: `${isNew ? '' : 'Edit '}Hazard Card`,
          dynamicModels: editingFields,
          editing: !isNew,
          addCancelButton: true,
          submitButtonText: 'Submit',
        });
        // this.dynamicEditModal.open();
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.dynamicRender.eventBus.emit(
      this.dynamicRender.eventBus.types.updateInfoOverlay,
      {
        show: false,
      }
    );
  }
}
