import {
  Component,
  Input,
  AfterViewInit,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '@ups/xplat/core';
import { FileManagerComponent as SFFileMgr } from '@syncfusion/ej2-angular-filemanager';

@Component({
  selector: 'ups-file-manager',
  templateUrl: './file-manager.component.html',
})
export class FileManagerComponent implements AfterViewInit {
  @Input() filePath: string;
  @Input() readonly = true;
  @Input() allowEditing = true;
  @Input() title: string;

  @ViewChild(SFFileMgr) fileManager: SFFileMgr;

  token: string;
  public view = 'Details';
  public toolbarSettings;

  public contextMenuSettings: unknown = { visible: true };
  public showThumbnail: boolean;
  public ajaxSettings: {
    url: string;
    downloadUrl: string;
    uploadUrl: string;
    getImageUrl: string;
  };

  constructor(authService: AuthService, private cdr: ChangeDetectorRef) {
    authService.getAccessTokenSilently().subscribe((x) => {
      this.token = x;
    });
  }

  ngAfterViewInit(): void {
    this.ajaxSettings = {
      url:
        environment.urls.fileManagementAPI +
        `/api/AzureProvider/${this.filePath}/AzureFileOperations`,
      downloadUrl:
        environment.urls.fileManagementAPI +
        `/api/AzureProvider/${this.filePath}/AzureDownload`,
      uploadUrl:
        environment.urls.fileManagementAPI +
        `/api/AzureProvider/${this.filePath}/AzureUpload`,
      getImageUrl:
        environment.urls.fileManagementAPI +
        `/api/AzureProvider/${this.filePath}/AzureGetImage`,
    };
    this.showThumbnail = false;

    if (!this.allowEditing) {
      this.toolbarSettings = { visible: false };
      this.contextMenuSettings = {
        file: ['Download'],
        folder: ['Open', '|', 'Download'],
        layout: ['SortBy', 'View', 'Refresh', '|', 'SelectAll'],
        visible: true,
      };
    } else {
      this.contextMenuSettings = {
        file: ['Open', '|', 'Cut', 'Copy', '|', 'Delete', 'Download', 'Rename'],
        folder: [
          'Open',
          '|',
          'Cut',
          'Copy',
          'Paste',
          '|',
          'Delete',
          'Download',
          'Rename',
        ],
        layout: [
          'SortBy',
          'View',
          'Refresh',
          '|',
          'Paste',
          '|',
          'NewFolder',
          'Upload',
          '|',
          'SelectAll',
        ],
        visible: true,
      };
      this.toolbarSettings = {
        items: [
          'NewFolder',
          'Upload',
          'Cut',
          'Copy',
          'Paste',
          'Delete',
          'Download',
          'Rename',
          'SortBy',
          'Refresh',
          'Selection',
          'View',
        ],
        visible: true,
      };
    }
    this.cdr.detectChanges();
  }

  attachAuthToken(args) {
    //Ajax beforeSend event
    args.ajaxSettings.beforeSend = (args) => {
      //Setting authorization header
      args.httpRequest.setRequestHeader(
        'Authorization',
        `Bearer ${this.token}`
      );
      args.httpRequest.setRequestHeader('ReadOnly', this.readonly);
    };
  }
}
