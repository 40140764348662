import { Injectable } from '@angular/core';

import { LogService, IDynamicPageOptions } from '@ups/xplat/core';
import { DynamicRenderService } from '../services/dynamic-render.service';

interface PageFormDetailComponent {
  dynamicPage: {
    draftReady: boolean;
  };
  options: IDynamicPageOptions;
}

@Injectable({
  providedIn: 'root',
})
export class DynamicPageGuard {
  constructor(
    private dynamicService: DynamicRenderService,
    private log: LogService
  ) {}

  canDeactivate(component: PageFormDetailComponent): Promise<boolean> {
    return new Promise((resolve) => {
      if (
        component.dynamicPage?.draftReady &&
        !component.options?.dynamicResponseId
      ) {
        try {
          this.dynamicService
            .confirmSaveActiveFormAsDraft()
            .then(
              () => {
                resolve(true);
              },
              () => {
                resolve(true);
              }
            )
            .catch(() => {
              resolve(true);
            });
        } catch (err) {
          resolve(true);
        }
      } else {
        resolve(true);
      }
    });
  }
}
