export const faIcons = {
  '500px': {
    changes: ['4.4', '5.0.0'],
    label: '500px',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f26e',
    voted: false,
  },
  'accessible-icon': {
    changes: ['5.0.0'],
    label: 'Accessible Icon',
    search: {
      terms: [
        'accessibility',
        'handicap',
        'person',
        'wheelchair',
        'wheelchair-alt',
      ],
    },
    styles: ['brands'],
    unicode: 'f368',
    voted: false,
  },
  accusoft: {
    changes: ['5.0.0'],
    label: 'Accusoft',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f369',
    voted: false,
  },
  'acquisitions-incorporated': {
    changes: ['5.4.0', '5.8.0'],
    label: 'Acquisitions Incorporated',
    search: {
      terms: [
        'Dungeons & Dragons',
        'd&d',
        'dnd',
        'fantasy',
        'game',
        'gaming',
        'tabletop',
      ],
    },
    styles: ['brands'],
    unicode: 'f6af',
    voted: false,
  },
  ad: {
    changes: ['5.3.0', '5.10.2'],
    label: 'Ad',
    search: {
      terms: ['advertisement', 'media', 'newspaper', 'promotion', 'publicity'],
    },
    styles: ['solid'],
    unicode: 'f641',
    voted: false,
  },
  'address-book': {
    changes: ['4.7', '5.0.0', '5.0.3'],
    label: 'Address Book',
    search: {
      terms: ['contact', 'directory', 'index', 'little black book', 'rolodex'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f2b9',
    voted: false,
  },
  'address-card': {
    changes: ['4.7', '5.0.0', '5.0.3'],
    label: 'Address Card',
    search: {
      terms: [
        'about',
        'contact',
        'id',
        'identification',
        'postcard',
        'profile',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f2bb',
    voted: false,
  },
  adjust: {
    changes: ['1', '5.0.0', '5.10.2', '5.11.0'],
    label: 'adjust',
    search: {
      terms: ['contrast', 'dark', 'light', 'saturation'],
    },
    styles: ['solid'],
    unicode: 'f042',
    voted: false,
  },
  adn: {
    changes: ['3.2', '5.0.0'],
    label: 'App.net',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f170',
    voted: false,
  },
  adobe: {
    changes: ['5.6.0', '5.8.0'],
    label: 'Adobe',
    search: {
      terms: [
        'acrobat',
        'app',
        'design',
        'illustrator',
        'indesign',
        'photoshop',
      ],
    },
    styles: ['brands'],
    unicode: 'f778',
    voted: false,
  },
  adversal: {
    changes: ['5.0.0'],
    label: 'Adversal',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f36a',
    voted: false,
  },
  affiliatetheme: {
    changes: ['5.0.0'],
    label: 'affiliatetheme',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f36b',
    voted: false,
  },
  'air-freshener': {
    changes: ['5.2.0'],
    label: 'Air Freshener',
    search: {
      terms: ['car', 'deodorize', 'fresh', 'pine', 'scent'],
    },
    styles: ['solid'],
    unicode: 'f5d0',
    voted: false,
  },
  airbnb: {
    changes: ['5.8.0'],
    label: 'Airbnb',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f834',
  },
  algolia: {
    changes: ['5.0.0'],
    label: 'Algolia',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f36c',
    voted: false,
  },
  'align-center': {
    changes: ['1', '5.0.0', '5.9.0'],
    label: 'align-center',
    search: {
      terms: ['format', 'middle', 'paragraph', 'text'],
    },
    styles: ['solid'],
    unicode: 'f037',
    voted: false,
  },
  'align-justify': {
    changes: ['1', '5.0.0', '5.9.0'],
    label: 'align-justify',
    search: {
      terms: ['format', 'paragraph', 'text'],
    },
    styles: ['solid'],
    unicode: 'f039',
    voted: false,
  },
  'align-left': {
    changes: ['1', '5.0.0', '5.9.0'],
    label: 'align-left',
    search: {
      terms: ['format', 'paragraph', 'text'],
    },
    styles: ['solid'],
    unicode: 'f036',
    voted: false,
  },
  'align-right': {
    changes: ['1', '5.0.0', '5.9.0'],
    label: 'align-right',
    search: {
      terms: ['format', 'paragraph', 'text'],
    },
    styles: ['solid'],
    unicode: 'f038',
    voted: false,
  },
  alipay: {
    changes: ['5.3.0'],
    label: 'Alipay',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f642',
    voted: false,
  },
  allergies: {
    changes: ['5.0.7'],
    label: 'Allergies',
    search: {
      terms: ['allergy', 'freckles', 'hand', 'hives', 'pox', 'skin', 'spots'],
    },
    styles: ['solid'],
    unicode: 'f461',
    voted: false,
  },
  amazon: {
    changes: ['4.4', '5.0.0'],
    label: 'Amazon',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f270',
    voted: false,
  },
  'amazon-pay': {
    changes: ['5.0.2', '5.8.0'],
    label: 'Amazon Pay',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f42c',
    voted: false,
  },
  ambulance: {
    changes: ['3', '5.0.0', '5.0.7'],
    label: 'ambulance',
    search: {
      terms: [
        'covid-19',
        'emergency',
        'emt',
        'er',
        'help',
        'hospital',
        'support',
        'vehicle',
      ],
    },
    styles: ['solid'],
    unicode: 'f0f9',
    voted: false,
  },
  'american-sign-language-interpreting': {
    changes: ['4.6', '5.0.0'],
    label: 'American Sign Language Interpreting',
    search: {
      terms: ['asl', 'deaf', 'finger', 'hand', 'interpret', 'speak'],
    },
    styles: ['solid'],
    unicode: 'f2a3',
    voted: false,
  },
  amilia: {
    changes: ['5.0.0'],
    label: 'Amilia',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f36d',
    voted: false,
  },
  anchor: {
    changes: ['3.1', '5.0.0', '5.11.0'],
    label: 'Anchor',
    search: {
      terms: [
        'berth',
        'boat',
        'dock',
        'embed',
        'link',
        'maritime',
        'moor',
        'secure',
      ],
    },
    styles: ['solid'],
    unicode: 'f13d',
    voted: false,
  },
  android: {
    changes: ['3.2', '5.0.0', '5.12.0'],
    label: 'Android',
    search: {
      terms: ['robot'],
    },
    styles: ['brands'],
    unicode: 'f17b',
    voted: false,
  },
  angellist: {
    changes: ['4.2', '5.0.0'],
    label: 'AngelList',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f209',
    voted: false,
  },
  'angle-double-down': {
    changes: ['3', '5.0.0'],
    label: 'Angle Double Down',
    search: {
      terms: ['arrows', 'caret', 'download', 'expand'],
    },
    styles: ['solid'],
    unicode: 'f103',
    voted: false,
  },
  'angle-double-left': {
    changes: ['3', '5.0.0'],
    label: 'Angle Double Left',
    search: {
      terms: ['arrows', 'back', 'caret', 'laquo', 'previous', 'quote'],
    },
    styles: ['solid'],
    unicode: 'f100',
    voted: false,
  },
  'angle-double-right': {
    changes: ['3', '5.0.0'],
    label: 'Angle Double Right',
    search: {
      terms: ['arrows', 'caret', 'forward', 'more', 'next', 'quote', 'raquo'],
    },
    styles: ['solid'],
    unicode: 'f101',
    voted: false,
  },
  'angle-double-up': {
    changes: ['3', '5.0.0'],
    label: 'Angle Double Up',
    search: {
      terms: ['arrows', 'caret', 'collapse', 'upload'],
    },
    styles: ['solid'],
    unicode: 'f102',
    voted: false,
  },
  'angle-down': {
    changes: ['3', '5.0.0'],
    label: 'angle-down',
    search: {
      terms: ['arrow', 'caret', 'download', 'expand'],
    },
    styles: ['solid'],
    unicode: 'f107',
    voted: false,
  },
  'angle-left': {
    changes: ['3', '5.0.0', '5.11.0'],
    label: 'angle-left',
    search: {
      terms: ['arrow', 'back', 'caret', 'less', 'previous'],
    },
    styles: ['solid'],
    unicode: 'f104',
    voted: false,
  },
  'angle-right': {
    changes: ['3', '5.0.0', '5.11.0'],
    label: 'angle-right',
    search: {
      terms: ['arrow', 'care', 'forward', 'more', 'next'],
    },
    styles: ['solid'],
    unicode: 'f105',
    voted: false,
  },
  'angle-up': {
    changes: ['3', '5.0.0'],
    label: 'angle-up',
    search: {
      terms: ['arrow', 'caret', 'collapse', 'upload'],
    },
    styles: ['solid'],
    unicode: 'f106',
    voted: false,
  },
  angry: {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Angry Face',
    search: {
      terms: ['disapprove', 'emoticon', 'face', 'mad', 'upset'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f556',
    voted: false,
  },
  angrycreative: {
    changes: ['5.0.0'],
    label: 'Angry Creative',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f36e',
    voted: false,
  },
  angular: {
    changes: ['5.0.0', '5.8.0'],
    label: 'Angular',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f420',
    voted: false,
  },
  ankh: {
    changes: ['5.3.0'],
    label: 'Ankh',
    search: {
      terms: [
        'amulet',
        'copper',
        'coptic christianity',
        'copts',
        'crux ansata',
        'egypt',
        'venus',
      ],
    },
    styles: ['solid'],
    unicode: 'f644',
    voted: false,
  },
  'app-store': {
    changes: ['5.0.0'],
    label: 'App Store',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f36f',
    voted: false,
  },
  'app-store-ios': {
    changes: ['5.0.0'],
    label: 'iOS App Store',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f370',
    voted: false,
  },
  apper: {
    changes: ['5.0.0'],
    label: 'Apper Systems AB',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f371',
    voted: false,
  },
  apple: {
    changes: ['3.2', '5.0.0', '5.0.7', '5.8.0'],
    label: 'Apple',
    search: {
      terms: ['fruit', 'ios', 'mac', 'operating system', 'os', 'osx'],
    },
    styles: ['brands'],
    unicode: 'f179',
    voted: false,
  },
  'apple-alt': {
    changes: ['5.2.0'],
    label: 'Fruit Apple',
    search: {
      terms: [
        'fall',
        'fruit',
        'fuji',
        'macintosh',
        'orchard',
        'seasonal',
        'vegan',
      ],
    },
    styles: ['solid'],
    unicode: 'f5d1',
    voted: false,
  },
  'apple-pay': {
    changes: ['5.0.0'],
    label: 'Apple Pay',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f415',
    voted: true,
  },
  archive: {
    changes: ['3.2', '5.0.0', '5.0.9'],
    label: 'Archive',
    search: {
      terms: ['box', 'package', 'save', 'storage'],
    },
    styles: ['solid'],
    unicode: 'f187',
    voted: false,
  },
  archway: {
    changes: ['5.1.0'],
    label: 'Archway',
    search: {
      terms: ['arc', 'monument', 'road', 'street', 'tunnel'],
    },
    styles: ['solid'],
    unicode: 'f557',
    voted: false,
  },
  'arrow-alt-circle-down': {
    changes: ['5.0.0'],
    label: 'Alternate Arrow Circle Down',
    search: {
      terms: ['arrow-circle-o-down', 'download'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f358',
    voted: false,
  },
  'arrow-alt-circle-left': {
    changes: ['5.0.0'],
    label: 'Alternate Arrow Circle Left',
    search: {
      terms: ['arrow-circle-o-left', 'back', 'previous'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f359',
    voted: false,
  },
  'arrow-alt-circle-right': {
    changes: ['5.0.0'],
    label: 'Alternate Arrow Circle Right',
    search: {
      terms: ['arrow-circle-o-right', 'forward', 'next'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f35a',
    voted: false,
  },
  'arrow-alt-circle-up': {
    changes: ['5.0.0'],
    label: 'Alternate Arrow Circle Up',
    search: {
      terms: ['arrow-circle-o-up'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f35b',
    voted: false,
  },
  'arrow-circle-down': {
    changes: ['2', '5.0.0'],
    label: 'Arrow Circle Down',
    search: {
      terms: ['download'],
    },
    styles: ['solid'],
    unicode: 'f0ab',
    voted: false,
  },
  'arrow-circle-left': {
    changes: ['2', '5.0.0'],
    label: 'Arrow Circle Left',
    search: {
      terms: ['back', 'previous'],
    },
    styles: ['solid'],
    unicode: 'f0a8',
    voted: false,
  },
  'arrow-circle-right': {
    changes: ['2', '5.0.0'],
    label: 'Arrow Circle Right',
    search: {
      terms: ['forward', 'next'],
    },
    styles: ['solid'],
    unicode: 'f0a9',
    voted: false,
  },
  'arrow-circle-up': {
    changes: ['2', '5.0.0'],
    label: 'Arrow Circle Up',
    search: {
      terms: ['upload'],
    },
    styles: ['solid'],
    unicode: 'f0aa',
    voted: false,
  },
  'arrow-down': {
    changes: ['1', '5.0.0'],
    label: 'arrow-down',
    search: {
      terms: ['download'],
    },
    styles: ['solid'],
    unicode: 'f063',
    voted: false,
  },
  'arrow-left': {
    changes: ['1', '5.0.0'],
    label: 'arrow-left',
    search: {
      terms: ['back', 'previous'],
    },
    styles: ['solid'],
    unicode: 'f060',
    voted: false,
  },
  'arrow-right': {
    changes: ['1', '5.0.0'],
    label: 'arrow-right',
    search: {
      terms: ['forward', 'next'],
    },
    styles: ['solid'],
    unicode: 'f061',
    voted: false,
  },
  'arrow-up': {
    changes: ['1', '5.0.0'],
    label: 'arrow-up',
    search: {
      terms: ['forward', 'upload'],
    },
    styles: ['solid'],
    unicode: 'f062',
    voted: false,
  },
  'arrows-alt': {
    changes: ['2', '5.0.0'],
    label: 'Alternate Arrows',
    search: {
      terms: [
        'arrow',
        'arrows',
        'bigger',
        'enlarge',
        'expand',
        'fullscreen',
        'move',
        'position',
        'reorder',
        'resize',
      ],
    },
    styles: ['solid'],
    unicode: 'f0b2',
    voted: false,
  },
  'arrows-alt-h': {
    changes: ['5.0.0'],
    label: 'Alternate Arrows Horizontal',
    search: {
      terms: [
        'arrows-h',
        'expand',
        'horizontal',
        'landscape',
        'resize',
        'wide',
      ],
    },
    styles: ['solid'],
    unicode: 'f337',
    voted: false,
  },
  'arrows-alt-v': {
    changes: ['5.0.0', '5.11.0'],
    label: 'Alternate Arrows Vertical',
    search: {
      terms: ['arrows-v', 'expand', 'portrait', 'resize', 'tall', 'vertical'],
    },
    styles: ['solid'],
    unicode: 'f338',
    voted: false,
  },
  artstation: {
    changes: ['5.6.0', '5.8.0'],
    label: 'Artstation',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f77a',
    voted: true,
  },
  'assistive-listening-systems': {
    changes: ['4.6', '5.0.0'],
    label: 'Assistive Listening Systems',
    search: {
      terms: ['amplify', 'audio', 'deaf', 'ear', 'headset', 'hearing', 'sound'],
    },
    styles: ['solid'],
    unicode: 'f2a2',
    voted: false,
  },
  asterisk: {
    changes: ['1', '5.0.0'],
    label: 'asterisk',
    search: {
      terms: ['annotation', 'details', 'reference', 'star'],
    },
    styles: ['solid'],
    unicode: 'f069',
    voted: false,
  },
  asymmetrik: {
    changes: ['5.0.0'],
    label: 'Asymmetrik, Ltd.',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f372',
    voted: false,
  },
  at: {
    changes: ['4.2', '5.0.0'],
    label: 'At',
    search: {
      terms: ['address', 'author', 'e-mail', 'email', 'handle'],
    },
    styles: ['solid'],
    unicode: 'f1fa',
    voted: false,
  },
  atlas: {
    changes: ['5.1.0'],
    label: 'Atlas',
    search: {
      terms: [
        'book',
        'directions',
        'geography',
        'globe',
        'map',
        'travel',
        'wayfinding',
      ],
    },
    styles: ['solid'],
    unicode: 'f558',
    voted: false,
  },
  atlassian: {
    changes: ['5.6.0'],
    label: 'Atlassian',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f77b',
    voted: true,
  },
  atom: {
    changes: ['5.2.0', '5.12.0'],
    label: 'Atom',
    search: {
      terms: [
        'atheism',
        'chemistry',
        'electron',
        'ion',
        'isotope',
        'neutron',
        'nuclear',
        'proton',
        'science',
      ],
    },
    styles: ['solid'],
    unicode: 'f5d2',
    voted: false,
  },
  audible: {
    changes: ['5.0.0'],
    label: 'Audible',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f373',
    voted: false,
  },
  'audio-description': {
    changes: ['4.6', '5.0.0', '5.10.2'],
    label: 'Audio Description',
    search: {
      terms: ['blind', 'narration', 'video', 'visual'],
    },
    styles: ['solid'],
    unicode: 'f29e',
    voted: false,
  },
  autoprefixer: {
    changes: ['5.0.0'],
    label: 'Autoprefixer',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f41c',
    voted: false,
  },
  avianex: {
    changes: ['5.0.0'],
    label: 'avianex',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f374',
    voted: false,
  },
  aviato: {
    changes: ['5.0.0'],
    label: 'Aviato',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f421',
    voted: false,
  },
  award: {
    changes: ['5.1.0', '5.2.0', '5.10.2'],
    label: 'Award',
    search: {
      terms: ['honor', 'praise', 'prize', 'recognition', 'ribbon', 'trophy'],
    },
    styles: ['solid'],
    unicode: 'f559',
    voted: true,
  },
  aws: {
    changes: ['5.0.0', '5.1.0'],
    label: 'Amazon Web Services (AWS)',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f375',
    voted: false,
  },
  baby: {
    changes: ['5.6.0', '5.10.1'],
    label: 'Baby',
    search: {
      terms: [
        'child',
        'diaper',
        'doll',
        'human',
        'infant',
        'kid',
        'offspring',
        'person',
        'sprout',
      ],
    },
    styles: ['solid'],
    unicode: 'f77c',
    voted: true,
  },
  'baby-carriage': {
    changes: ['5.6.0'],
    label: 'Baby Carriage',
    search: {
      terms: [
        'buggy',
        'carrier',
        'infant',
        'push',
        'stroller',
        'transportation',
        'walk',
        'wheels',
      ],
    },
    styles: ['solid'],
    unicode: 'f77d',
    voted: true,
  },
  backspace: {
    changes: ['5.1.0'],
    label: 'Backspace',
    search: {
      terms: ['command', 'delete', 'erase', 'keyboard', 'undo'],
    },
    styles: ['solid'],
    unicode: 'f55a',
    voted: true,
  },
  backward: {
    changes: ['1', '5.0.0'],
    label: 'backward',
    search: {
      terms: ['previous', 'rewind'],
    },
    styles: ['solid'],
    unicode: 'f04a',
    voted: false,
  },
  bacon: {
    changes: ['5.7.0'],
    label: 'Bacon',
    search: {
      terms: [
        'blt',
        'breakfast',
        'ham',
        'lard',
        'meat',
        'pancetta',
        'pork',
        'rasher',
      ],
    },
    styles: ['solid'],
    unicode: 'f7e5',
    voted: false,
  },
  bacteria: {
    changes: ['5.13.0', '5.13.1'],
    label: 'Bacteria',
    search: {
      terms: [
        'antibiotic',
        'antibody',
        'covid-19',
        'health',
        'organism',
        'sick',
      ],
    },
    styles: ['solid'],
    unicode: 'f959',
    voted: false,
  },
  bacterium: {
    changes: ['5.13.0', '5.13.1'],
    label: 'Bacterium',
    search: {
      terms: [
        'antibiotic',
        'antibody',
        'covid-19',
        'health',
        'organism',
        'sick',
      ],
    },
    styles: ['solid'],
    unicode: 'f95a',
    voted: false,
  },
  bahai: {
    changes: ['5.3.0', '5.12.0'],
    label: "Bahá'í",
    search: {
      terms: ['bahai', "bahá'í", 'star'],
    },
    styles: ['solid'],
    unicode: 'f666',
    voted: false,
  },
  'balance-scale': {
    changes: ['4.4', '5.0.0', '5.0.13'],
    label: 'Balance Scale',
    search: {
      terms: ['balanced', 'justice', 'legal', 'measure', 'weight'],
    },
    styles: ['solid'],
    unicode: 'f24e',
    voted: false,
  },
  'balance-scale-left': {
    changes: ['5.0.13', '5.9.0', '5.12.0'],
    label: 'Balance Scale (Left-Weighted)',
    search: {
      terms: ['justice', 'legal', 'measure', 'unbalanced', 'weight'],
    },
    styles: ['solid'],
    unicode: 'f515',
    voted: true,
  },
  'balance-scale-right': {
    changes: ['5.0.13', '5.9.0', '5.12.0'],
    label: 'Balance Scale (Right-Weighted)',
    search: {
      terms: ['justice', 'legal', 'measure', 'unbalanced', 'weight'],
    },
    styles: ['solid'],
    unicode: 'f516',
    voted: true,
  },
  ban: {
    changes: ['1', '5.0.0'],
    label: 'ban',
    search: {
      terms: [
        'abort',
        'ban',
        'block',
        'cancel',
        'delete',
        'hide',
        'prohibit',
        'remove',
        'stop',
        'trash',
      ],
    },
    styles: ['solid'],
    unicode: 'f05e',
    voted: false,
  },
  'band-aid': {
    changes: ['5.0.7', '5.10.2'],
    label: 'Band-Aid',
    search: {
      terms: ['bandage', 'boo boo', 'first aid', 'ouch'],
    },
    styles: ['solid'],
    unicode: 'f462',
    voted: false,
  },
  bandcamp: {
    changes: ['4.7', '5.0.0', '5.13.1'],
    label: 'Bandcamp',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f2d5',
    voted: false,
  },
  barcode: {
    changes: ['1', '5.0.0'],
    label: 'barcode',
    search: {
      terms: ['info', 'laser', 'price', 'scan', 'upc'],
    },
    styles: ['solid'],
    unicode: 'f02a',
    voted: false,
  },
  bars: {
    changes: ['2', '5.0.0'],
    label: 'Bars',
    search: {
      terms: [
        'checklist',
        'drag',
        'hamburger',
        'list',
        'menu',
        'nav',
        'navigation',
        'ol',
        'reorder',
        'settings',
        'todo',
        'ul',
      ],
    },
    styles: ['solid'],
    unicode: 'f0c9',
    voted: false,
  },
  'baseball-ball': {
    changes: ['5.0.5', '5.11.0', '5.11.1'],
    label: 'Baseball Ball',
    search: {
      terms: [
        'foul',
        'hardball',
        'league',
        'leather',
        'mlb',
        'softball',
        'sport',
      ],
    },
    styles: ['solid'],
    unicode: 'f433',
    voted: false,
  },
  'basketball-ball': {
    changes: ['5.0.5', '5.11.0', '5.11.1'],
    label: 'Basketball Ball',
    search: {
      terms: ['dribble', 'dunk', 'hoop', 'nba'],
    },
    styles: ['solid'],
    unicode: 'f434',
    voted: false,
  },
  bath: {
    changes: ['4.7', '5.0.0', '5.12.0'],
    label: 'Bath',
    search: {
      terms: ['clean', 'shower', 'tub', 'wash'],
    },
    styles: ['solid'],
    unicode: 'f2cd',
    voted: false,
  },
  'battery-empty': {
    changes: ['4.4', '5.0.0'],
    label: 'Battery Empty',
    search: {
      terms: ['charge', 'dead', 'power', 'status'],
    },
    styles: ['solid'],
    unicode: 'f244',
    voted: false,
  },
  'battery-full': {
    changes: ['4.4', '5.0.0'],
    label: 'Battery Full',
    search: {
      terms: ['charge', 'power', 'status'],
    },
    styles: ['solid'],
    unicode: 'f240',
    voted: false,
  },
  'battery-half': {
    changes: ['4.4', '5.0.0'],
    label: 'Battery 1/2 Full',
    search: {
      terms: ['charge', 'power', 'status'],
    },
    styles: ['solid'],
    unicode: 'f242',
    voted: false,
  },
  'battery-quarter': {
    changes: ['4.4', '5.0.0'],
    label: 'Battery 1/4 Full',
    search: {
      terms: ['charge', 'low', 'power', 'status'],
    },
    styles: ['solid'],
    unicode: 'f243',
    voted: false,
  },
  'battery-three-quarters': {
    changes: ['4.4', '5.0.0'],
    label: 'Battery 3/4 Full',
    search: {
      terms: ['charge', 'power', 'status'],
    },
    styles: ['solid'],
    unicode: 'f241',
    voted: false,
  },
  'battle-net': {
    changes: ['5.8.0'],
    label: 'Battle.net',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f835',
  },
  bed: {
    changes: ['4.3', '5.0.0', '5.1.0'],
    label: 'Bed',
    search: {
      terms: ['lodging', 'mattress', 'rest', 'sleep', 'travel'],
    },
    styles: ['solid'],
    unicode: 'f236',
    voted: false,
  },
  beer: {
    changes: ['3', '5.0.0'],
    label: 'beer',
    search: {
      terms: [
        'alcohol',
        'ale',
        'bar',
        'beverage',
        'brewery',
        'drink',
        'lager',
        'liquor',
        'mug',
        'stein',
      ],
    },
    styles: ['solid'],
    unicode: 'f0fc',
    voted: false,
  },
  behance: {
    changes: ['4.1', '5.0.0'],
    label: 'Behance',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1b4',
    voted: false,
  },
  'behance-square': {
    changes: ['4.1', '5.0.0', '5.0.3'],
    label: 'Behance Square',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1b5',
    voted: false,
  },
  bell: {
    changes: ['2', '5.0.0', '5.2.0', '5.11.0'],
    label: 'bell',
    search: {
      terms: ['alarm', 'alert', 'chime', 'notification', 'reminder'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f0f3',
    voted: false,
  },
  'bell-slash': {
    changes: ['4.2', '5.0.0', '5.2.0'],
    label: 'Bell Slash',
    search: {
      terms: ['alert', 'cancel', 'disabled', 'notification', 'off', 'reminder'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f1f6',
    voted: false,
  },
  'bezier-curve': {
    changes: ['5.1.0'],
    label: 'Bezier Curve',
    search: {
      terms: ['curves', 'illustrator', 'lines', 'path', 'vector'],
    },
    styles: ['solid'],
    unicode: 'f55b',
    voted: false,
  },
  bible: {
    changes: ['5.3.0'],
    label: 'Bible',
    search: {
      terms: ['book', 'catholicism', 'christianity', 'god', 'holy'],
    },
    styles: ['solid'],
    unicode: 'f647',
    voted: false,
  },
  bicycle: {
    changes: ['4.2', '5.0.0'],
    label: 'Bicycle',
    search: {
      terms: ['bike', 'gears', 'pedal', 'transportation', 'vehicle'],
    },
    styles: ['solid'],
    unicode: 'f206',
    voted: false,
  },
  biking: {
    changes: ['5.9.0'],
    label: 'Biking',
    search: {
      terms: ['bicycle', 'bike', 'cycle', 'cycling', 'ride', 'wheel'],
    },
    styles: ['solid'],
    unicode: 'f84a',
    voted: false,
  },
  bimobject: {
    changes: ['5.0.0'],
    label: 'BIMobject',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f378',
    voted: false,
  },
  binoculars: {
    changes: ['4.2', '5.0.0', '5.2.0'],
    label: 'Binoculars',
    search: {
      terms: ['glasses', 'magnify', 'scenic', 'spyglass', 'view'],
    },
    styles: ['solid'],
    unicode: 'f1e5',
    voted: false,
  },
  biohazard: {
    changes: ['5.6.0', '5.7.0'],
    label: 'Biohazard',
    search: {
      terms: [
        'covid-19',
        'danger',
        'dangerous',
        'hazmat',
        'medical',
        'radioactive',
        'toxic',
        'waste',
        'zombie',
      ],
    },
    styles: ['solid'],
    unicode: 'f780',
    voted: true,
  },
  'birthday-cake': {
    changes: ['4.2', '5.0.0'],
    label: 'Birthday Cake',
    search: {
      terms: [
        'anniversary',
        'bakery',
        'candles',
        'celebration',
        'dessert',
        'frosting',
        'holiday',
        'party',
        'pastry',
      ],
    },
    styles: ['solid'],
    unicode: 'f1fd',
    voted: false,
  },
  bitbucket: {
    changes: ['3.2', '5.0.0', '5.6.0', '5.8.0'],
    label: 'Bitbucket',
    search: {
      terms: ['atlassian', 'bitbucket-square', 'git'],
    },
    styles: ['brands'],
    unicode: 'f171',
    voted: false,
  },
  bitcoin: {
    changes: ['5.0.0'],
    label: 'Bitcoin',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f379',
    voted: false,
  },
  bity: {
    changes: ['5.0.0'],
    label: 'Bity',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f37a',
    voted: false,
  },
  'black-tie': {
    changes: ['4.4', '5.0.0'],
    label: 'Font Awesome Black Tie',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f27e',
    voted: false,
  },
  blackberry: {
    changes: ['5.0.0'],
    label: 'BlackBerry',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f37b',
    voted: false,
  },
  blender: {
    changes: ['5.0.13'],
    label: 'Blender',
    search: {
      terms: ['cocktail', 'milkshake', 'mixer', 'puree', 'smoothie'],
    },
    styles: ['solid'],
    unicode: 'f517',
    voted: false,
  },
  'blender-phone': {
    changes: ['5.4.0'],
    label: 'Blender Phone',
    search: {
      terms: [
        'appliance',
        'cocktail',
        'communication',
        'fantasy',
        'milkshake',
        'mixer',
        'puree',
        'silly',
        'smoothie',
      ],
    },
    styles: ['solid'],
    unicode: 'f6b6',
    voted: false,
  },
  blind: {
    changes: ['4.6', '5.0.0', '5.11.0'],
    label: 'Blind',
    search: {
      terms: ['cane', 'disability', 'person', 'sight'],
    },
    styles: ['solid'],
    unicode: 'f29d',
    voted: false,
  },
  blog: {
    changes: ['5.6.0'],
    label: 'Blog',
    search: {
      terms: [
        'journal',
        'log',
        'online',
        'personal',
        'post',
        'web 2.0',
        'wordpress',
        'writing',
      ],
    },
    styles: ['solid'],
    unicode: 'f781',
    voted: true,
  },
  blogger: {
    changes: ['5.0.0'],
    label: 'Blogger',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f37c',
    voted: false,
  },
  'blogger-b': {
    changes: ['5.0.0'],
    label: 'Blogger B',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f37d',
    voted: false,
  },
  bluetooth: {
    changes: ['4.5', '5.0.0'],
    label: 'Bluetooth',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f293',
    voted: false,
  },
  'bluetooth-b': {
    changes: ['4.5', '5.0.0'],
    label: 'Bluetooth',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f294',
    voted: false,
  },
  bold: {
    changes: ['1', '5.0.0', '5.9.0', '5.10.2'],
    label: 'bold',
    search: {
      terms: ['emphasis', 'format', 'text'],
    },
    styles: ['solid'],
    unicode: 'f032',
    voted: false,
  },
  bolt: {
    changes: ['2', '5.0.0', '5.5.0'],
    label: 'Lightning Bolt',
    search: {
      terms: ['electricity', 'lightning', 'weather', 'zap'],
    },
    styles: ['solid'],
    unicode: 'f0e7',
    voted: false,
  },
  bomb: {
    changes: ['4.1', '5.0.0'],
    label: 'Bomb',
    search: {
      terms: ['error', 'explode', 'fuse', 'grenade', 'warning'],
    },
    styles: ['solid'],
    unicode: 'f1e2',
    voted: false,
  },
  bone: {
    changes: ['5.2.0'],
    label: 'Bone',
    search: {
      terms: ['calcium', 'dog', 'skeletal', 'skeleton', 'tibia'],
    },
    styles: ['solid'],
    unicode: 'f5d7',
    voted: false,
  },
  bong: {
    changes: ['5.1.0'],
    label: 'Bong',
    search: {
      terms: ['aparatus', 'cannabis', 'marijuana', 'pipe', 'smoke', 'smoking'],
    },
    styles: ['solid'],
    unicode: 'f55c',
    voted: false,
  },
  book: {
    changes: ['1', '5.0.0'],
    label: 'book',
    search: {
      terms: ['diary', 'documentation', 'journal', 'library', 'read'],
    },
    styles: ['solid'],
    unicode: 'f02d',
    voted: false,
  },
  'book-dead': {
    changes: ['5.4.0'],
    label: 'Book of the Dead',
    search: {
      terms: [
        'Dungeons & Dragons',
        'crossbones',
        'd&d',
        'dark arts',
        'death',
        'dnd',
        'documentation',
        'evil',
        'fantasy',
        'halloween',
        'holiday',
        'necronomicon',
        'read',
        'skull',
        'spell',
      ],
    },
    styles: ['solid'],
    unicode: 'f6b7',
    voted: false,
  },
  'book-medical': {
    changes: ['5.7.0'],
    label: 'Medical Book',
    search: {
      terms: [
        'diary',
        'documentation',
        'health',
        'history',
        'journal',
        'library',
        'read',
        'record',
      ],
    },
    styles: ['solid'],
    unicode: 'f7e6',
    voted: false,
  },
  'book-open': {
    changes: ['5.0.13', '5.1.0', '5.2.0'],
    label: 'Book Open',
    search: {
      terms: [
        'flyer',
        'library',
        'notebook',
        'open book',
        'pamphlet',
        'reading',
      ],
    },
    styles: ['solid'],
    unicode: 'f518',
    voted: true,
  },
  'book-reader': {
    changes: ['5.2.0'],
    label: 'Book Reader',
    search: {
      terms: [
        'flyer',
        'library',
        'notebook',
        'open book',
        'pamphlet',
        'reading',
      ],
    },
    styles: ['solid'],
    unicode: 'f5da',
    voted: false,
  },
  bookmark: {
    changes: ['1', '5.0.0', '5.10.2'],
    label: 'bookmark',
    search: {
      terms: ['favorite', 'marker', 'read', 'remember', 'save'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f02e',
    voted: false,
  },
  bootstrap: {
    changes: ['5.8.0'],
    label: 'Bootstrap',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f836',
  },
  'border-all': {
    changes: ['5.9.0'],
    label: 'Border All',
    search: {
      terms: ['cell', 'grid', 'outline', 'stroke', 'table'],
    },
    styles: ['solid'],
    unicode: 'f84c',
    voted: false,
  },
  'border-none': {
    changes: ['5.9.0'],
    label: 'Border None',
    search: {
      terms: ['cell', 'grid', 'outline', 'stroke', 'table'],
    },
    styles: ['solid'],
    unicode: 'f850',
    voted: false,
  },
  'border-style': {
    changes: ['5.9.0'],
    label: 'Border Style',
    search: {
      terms: [],
    },
    styles: ['solid'],
    unicode: 'f853',
    voted: false,
  },
  'bowling-ball': {
    changes: ['5.0.5', '5.11.0', '5.11.1'],
    label: 'Bowling Ball',
    search: {
      terms: ['alley', 'candlepin', 'gutter', 'lane', 'strike', 'tenpin'],
    },
    styles: ['solid'],
    unicode: 'f436',
    voted: false,
  },
  box: {
    changes: ['5.0.7'],
    label: 'Box',
    search: {
      terms: ['archive', 'container', 'package', 'storage'],
    },
    styles: ['solid'],
    unicode: 'f466',
    voted: false,
  },
  'box-open': {
    changes: ['5.0.9', '5.7.0'],
    label: 'Box Open',
    search: {
      terms: ['archive', 'container', 'package', 'storage', 'unpack'],
    },
    styles: ['solid'],
    unicode: 'f49e',
    voted: false,
  },
  'box-tissue': {
    changes: ['5.13.0'],
    label: 'Tissue Box',
    search: {
      terms: [
        'cough',
        'covid-19',
        'kleenex',
        'mucus',
        'nose',
        'sneeze',
        'snot',
      ],
    },
    styles: ['solid'],
    unicode: 'f95b',
    voted: false,
  },
  boxes: {
    changes: ['5.0.7'],
    label: 'Boxes',
    search: {
      terms: ['archives', 'inventory', 'storage', 'warehouse'],
    },
    styles: ['solid'],
    unicode: 'f468',
    voted: false,
  },
  braille: {
    changes: ['4.6', '5.0.0'],
    label: 'Braille',
    search: {
      terms: ['alphabet', 'blind', 'dots', 'raised', 'vision'],
    },
    styles: ['solid'],
    unicode: 'f2a1',
    voted: false,
  },
  brain: {
    changes: ['5.2.0', '5.9.0', '5.11.0'],
    label: 'Brain',
    search: {
      terms: [
        'cerebellum',
        'gray matter',
        'intellect',
        'medulla oblongata',
        'mind',
        'noodle',
        'wit',
      ],
    },
    styles: ['solid'],
    unicode: 'f5dc',
    voted: false,
  },
  'bread-slice': {
    changes: ['5.7.0'],
    label: 'Bread Slice',
    search: {
      terms: [
        'bake',
        'bakery',
        'baking',
        'dough',
        'flour',
        'gluten',
        'grain',
        'sandwich',
        'sourdough',
        'toast',
        'wheat',
        'yeast',
      ],
    },
    styles: ['solid'],
    unicode: 'f7ec',
    voted: false,
  },
  briefcase: {
    changes: ['2', '5.0.0', '5.3.0'],
    label: 'Briefcase',
    search: {
      terms: ['bag', 'business', 'luggage', 'office', 'work'],
    },
    styles: ['solid'],
    unicode: 'f0b1',
    voted: false,
  },
  'briefcase-medical': {
    changes: ['5.0.7'],
    label: 'Medical Briefcase',
    search: {
      terms: ['doctor', 'emt', 'first aid', 'health'],
    },
    styles: ['solid'],
    unicode: 'f469',
    voted: false,
  },
  'broadcast-tower': {
    changes: ['5.0.13'],
    label: 'Broadcast Tower',
    search: {
      terms: ['airwaves', 'antenna', 'radio', 'reception', 'waves'],
    },
    styles: ['solid'],
    unicode: 'f519',
    voted: true,
  },
  broom: {
    changes: ['5.0.13'],
    label: 'Broom',
    search: {
      terms: [
        'clean',
        'firebolt',
        'fly',
        'halloween',
        'nimbus 2000',
        'quidditch',
        'sweep',
        'witch',
      ],
    },
    styles: ['solid'],
    unicode: 'f51a',
    voted: true,
  },
  brush: {
    changes: ['5.1.0'],
    label: 'Brush',
    search: {
      terms: ['art', 'bristles', 'color', 'handle', 'paint'],
    },
    styles: ['solid'],
    unicode: 'f55d',
    voted: false,
  },
  btc: {
    changes: ['3.2', '5.0.0'],
    label: 'BTC',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f15a',
    voted: false,
  },
  buffer: {
    changes: ['5.8.0'],
    label: 'Buffer',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f837',
  },
  bug: {
    changes: ['3.2', '5.0.0'],
    label: 'Bug',
    search: {
      terms: ['beetle', 'error', 'insect', 'report'],
    },
    styles: ['solid'],
    unicode: 'f188',
    voted: false,
  },
  building: {
    changes: ['4.1', '5.0.0'],
    label: 'Building',
    search: {
      terms: ['apartment', 'business', 'city', 'company', 'office', 'work'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f1ad',
    voted: false,
  },
  bullhorn: {
    changes: ['2', '5.0.0', '5.3.0'],
    label: 'bullhorn',
    search: {
      terms: ['announcement', 'broadcast', 'louder', 'megaphone', 'share'],
    },
    styles: ['solid'],
    unicode: 'f0a1',
    voted: false,
  },
  bullseye: {
    changes: ['3.1', '5.0.0', '5.3.0', '5.10.1'],
    label: 'Bullseye',
    search: {
      terms: ['archery', 'goal', 'objective', 'target'],
    },
    styles: ['solid'],
    unicode: 'f140',
    voted: false,
  },
  burn: {
    changes: ['5.0.7'],
    label: 'Burn',
    search: {
      terms: ['caliente', 'energy', 'fire', 'flame', 'gas', 'heat', 'hot'],
    },
    styles: ['solid'],
    unicode: 'f46a',
    voted: false,
  },
  buromobelexperte: {
    changes: ['5.0.0'],
    label: 'Büromöbel-Experte GmbH & Co. KG.',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f37f',
    voted: false,
  },
  bus: {
    changes: ['4.2', '5.0.0', '5.1.0'],
    label: 'Bus',
    search: {
      terms: ['public transportation', 'transportation', 'travel', 'vehicle'],
    },
    styles: ['solid'],
    unicode: 'f207',
    voted: false,
  },
  'bus-alt': {
    changes: ['5.1.0'],
    label: 'Bus Alt',
    search: {
      terms: [
        'mta',
        'public transportation',
        'transportation',
        'travel',
        'vehicle',
      ],
    },
    styles: ['solid'],
    unicode: 'f55e',
    voted: false,
  },
  'business-time': {
    changes: ['5.3.0'],
    label: 'Business Time',
    search: {
      terms: [
        'alarm',
        'briefcase',
        'business socks',
        'clock',
        'flight of the conchords',
        'reminder',
        'wednesday',
      ],
    },
    styles: ['solid'],
    unicode: 'f64a',
    voted: false,
  },
  'buy-n-large': {
    changes: ['5.11.0'],
    label: 'Buy n Large',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f8a6',
  },
  buysellads: {
    changes: ['4.3', '5.0.0'],
    label: 'BuySellAds',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f20d',
    voted: false,
  },
  calculator: {
    changes: ['4.2', '5.0.0', '5.3.0', '5.11.0'],
    label: 'Calculator',
    search: {
      terms: [
        'abacus',
        'addition',
        'arithmetic',
        'counting',
        'math',
        'multiplication',
        'subtraction',
      ],
    },
    styles: ['solid'],
    unicode: 'f1ec',
    voted: false,
  },
  calendar: {
    changes: ['3.1', '5.0.0', '5.10.2'],
    label: 'Calendar',
    search: {
      terms: ['calendar-o', 'date', 'event', 'schedule', 'time', 'when'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f133',
    voted: false,
  },
  'calendar-alt': {
    changes: ['1', '5.0.0', '5.6.0', '5.7.0', '5.10.2'],
    label: 'Alternate Calendar',
    search: {
      terms: ['calendar', 'date', 'event', 'schedule', 'time', 'when'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f073',
    voted: false,
  },
  'calendar-check': {
    changes: ['4.4', '5.0.0', '5.10.2'],
    label: 'Calendar Check',
    search: {
      terms: [
        'accept',
        'agree',
        'appointment',
        'confirm',
        'correct',
        'date',
        'done',
        'event',
        'ok',
        'schedule',
        'select',
        'success',
        'tick',
        'time',
        'todo',
        'when',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f274',
    voted: false,
  },
  'calendar-day': {
    changes: ['5.6.0', '5.10.2'],
    label: 'Calendar with Day Focus',
    search: {
      terms: [
        'date',
        'detail',
        'event',
        'focus',
        'schedule',
        'single day',
        'time',
        'today',
        'when',
      ],
    },
    styles: ['solid'],
    unicode: 'f783',
    voted: true,
  },
  'calendar-minus': {
    changes: ['4.4', '5.0.0', '5.10.2'],
    label: 'Calendar Minus',
    search: {
      terms: [
        'calendar',
        'date',
        'delete',
        'event',
        'negative',
        'remove',
        'schedule',
        'time',
        'when',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f272',
    voted: false,
  },
  'calendar-plus': {
    changes: ['4.4', '5.0.0', '5.10.2'],
    label: 'Calendar Plus',
    search: {
      terms: [
        'add',
        'calendar',
        'create',
        'date',
        'event',
        'new',
        'positive',
        'schedule',
        'time',
        'when',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f271',
    voted: false,
  },
  'calendar-times': {
    changes: ['4.4', '5.0.0', '5.10.2'],
    label: 'Calendar Times',
    search: {
      terms: [
        'archive',
        'calendar',
        'date',
        'delete',
        'event',
        'remove',
        'schedule',
        'time',
        'when',
        'x',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f273',
    voted: false,
  },
  'calendar-week': {
    changes: ['5.6.0', '5.10.2'],
    label: 'Calendar with Week Focus',
    search: {
      terms: [
        'date',
        'detail',
        'event',
        'focus',
        'schedule',
        'single week',
        'time',
        'today',
        'when',
      ],
    },
    styles: ['solid'],
    unicode: 'f784',
    voted: true,
  },
  camera: {
    changes: ['1', '5.0.0'],
    label: 'camera',
    search: {
      terms: [
        'image',
        'lens',
        'photo',
        'picture',
        'record',
        'shutter',
        'video',
      ],
    },
    styles: ['solid'],
    unicode: 'f030',
    voted: false,
  },
  'camera-retro': {
    changes: ['1', '5.0.0', '5.10.1'],
    label: 'Retro Camera',
    search: {
      terms: [
        'image',
        'lens',
        'photo',
        'picture',
        'record',
        'shutter',
        'video',
      ],
    },
    styles: ['solid'],
    unicode: 'f083',
    voted: false,
  },
  campground: {
    changes: ['5.4.0'],
    label: 'Campground',
    search: {
      terms: ['camping', 'fall', 'outdoors', 'teepee', 'tent', 'tipi'],
    },
    styles: ['solid'],
    unicode: 'f6bb',
    voted: false,
  },
  'canadian-maple-leaf': {
    changes: ['5.6.0', '5.8.0'],
    label: 'Canadian Maple Leaf',
    search: {
      terms: ['canada', 'flag', 'flora', 'nature', 'plant'],
    },
    styles: ['brands'],
    unicode: 'f785',
  },
  'candy-cane': {
    changes: ['5.6.0', '5.10.1'],
    label: 'Candy Cane',
    search: {
      terms: [
        'candy',
        'christmas',
        'holiday',
        'mint',
        'peppermint',
        'striped',
        'xmas',
      ],
    },
    styles: ['solid'],
    unicode: 'f786',
  },
  cannabis: {
    changes: ['5.1.0'],
    label: 'Cannabis',
    search: {
      terms: [
        'bud',
        'chronic',
        'drugs',
        'endica',
        'endo',
        'ganja',
        'marijuana',
        'mary jane',
        'pot',
        'reefer',
        'sativa',
        'spliff',
        'weed',
        'whacky-tabacky',
      ],
    },
    styles: ['solid'],
    unicode: 'f55f',
    voted: false,
  },
  capsules: {
    changes: ['5.0.7'],
    label: 'Capsules',
    search: {
      terms: ['drugs', 'medicine', 'pills', 'prescription'],
    },
    styles: ['solid'],
    unicode: 'f46b',
    voted: false,
  },
  car: {
    changes: ['4.1', '5.0.0', '5.2.0'],
    label: 'Car',
    search: {
      terms: [
        'auto',
        'automobile',
        'sedan',
        'transportation',
        'travel',
        'vehicle',
      ],
    },
    styles: ['solid'],
    unicode: 'f1b9',
    voted: false,
  },
  'car-alt': {
    changes: ['5.2.0', '5.11.0', '5.11.1'],
    label: 'Alternate Car',
    search: {
      terms: [
        'auto',
        'automobile',
        'sedan',
        'transportation',
        'travel',
        'vehicle',
      ],
    },
    styles: ['solid'],
    unicode: 'f5de',
    voted: false,
  },
  'car-battery': {
    changes: ['5.2.0'],
    label: 'Car Battery',
    search: {
      terms: ['auto', 'electric', 'mechanic', 'power'],
    },
    styles: ['solid'],
    unicode: 'f5df',
    voted: false,
  },
  'car-crash': {
    changes: ['5.2.0'],
    label: 'Car Crash',
    search: {
      terms: [
        'accident',
        'auto',
        'automobile',
        'insurance',
        'sedan',
        'transportation',
        'vehicle',
        'wreck',
      ],
    },
    styles: ['solid'],
    unicode: 'f5e1',
    voted: false,
  },
  'car-side': {
    changes: ['5.2.0'],
    label: 'Car Side',
    search: {
      terms: [
        'auto',
        'automobile',
        'sedan',
        'transportation',
        'travel',
        'vehicle',
      ],
    },
    styles: ['solid'],
    unicode: 'f5e4',
    voted: false,
  },
  caravan: {
    changes: ['5.12.0'],
    label: 'Caravan',
    search: {
      terms: ['camper', 'motor home', 'rv', 'trailer', 'travel'],
    },
    styles: ['solid'],
    unicode: 'f8ff',
    voted: true,
  },
  'caret-down': {
    changes: ['2', '5.0.0'],
    label: 'Caret Down',
    search: {
      terms: ['arrow', 'dropdown', 'expand', 'menu', 'more', 'triangle'],
    },
    styles: ['solid'],
    unicode: 'f0d7',
    voted: false,
  },
  'caret-left': {
    changes: ['2', '5.0.0'],
    label: 'Caret Left',
    search: {
      terms: ['arrow', 'back', 'previous', 'triangle'],
    },
    styles: ['solid'],
    unicode: 'f0d9',
    voted: false,
  },
  'caret-right': {
    changes: ['2', '5.0.0'],
    label: 'Caret Right',
    search: {
      terms: ['arrow', 'forward', 'next', 'triangle'],
    },
    styles: ['solid'],
    unicode: 'f0da',
    voted: false,
  },
  'caret-square-down': {
    changes: ['3.2', '5.0.0'],
    label: 'Caret Square Down',
    search: {
      terms: [
        'arrow',
        'caret-square-o-down',
        'dropdown',
        'expand',
        'menu',
        'more',
        'triangle',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f150',
    voted: false,
  },
  'caret-square-left': {
    changes: ['4', '5.0.0'],
    label: 'Caret Square Left',
    search: {
      terms: ['arrow', 'back', 'caret-square-o-left', 'previous', 'triangle'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f191',
    voted: false,
  },
  'caret-square-right': {
    changes: ['3.2', '5.0.0'],
    label: 'Caret Square Right',
    search: {
      terms: ['arrow', 'caret-square-o-right', 'forward', 'next', 'triangle'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f152',
    voted: false,
  },
  'caret-square-up': {
    changes: ['3.2', '5.0.0'],
    label: 'Caret Square Up',
    search: {
      terms: ['arrow', 'caret-square-o-up', 'collapse', 'triangle', 'upload'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f151',
    voted: false,
  },
  'caret-up': {
    changes: ['2', '5.0.0'],
    label: 'Caret Up',
    search: {
      terms: ['arrow', 'collapse', 'triangle'],
    },
    styles: ['solid'],
    unicode: 'f0d8',
    voted: false,
  },
  carrot: {
    changes: ['5.6.0', '5.10.1'],
    label: 'Carrot',
    search: {
      terms: ['bugs bunny', 'orange', 'vegan', 'vegetable'],
    },
    styles: ['solid'],
    unicode: 'f787',
  },
  'cart-arrow-down': {
    changes: ['4.3', '5.0.0'],
    label: 'Shopping Cart Arrow Down',
    search: {
      terms: ['download', 'save', 'shopping'],
    },
    styles: ['solid'],
    unicode: 'f218',
    voted: false,
  },
  'cart-plus': {
    changes: ['4.3', '5.0.0'],
    label: 'Add to Shopping Cart',
    search: {
      terms: ['add', 'create', 'new', 'positive', 'shopping'],
    },
    styles: ['solid'],
    unicode: 'f217',
    voted: false,
  },
  'cash-register': {
    changes: ['5.6.0'],
    label: 'Cash Register',
    search: {
      terms: [
        'buy',
        'cha-ching',
        'change',
        'checkout',
        'commerce',
        'leaerboard',
        'machine',
        'pay',
        'payment',
        'purchase',
        'store',
      ],
    },
    styles: ['solid'],
    unicode: 'f788',
    voted: true,
  },
  cat: {
    changes: ['5.4.0', '5.10.1'],
    label: 'Cat',
    search: {
      terms: [
        'feline',
        'halloween',
        'holiday',
        'kitten',
        'kitty',
        'meow',
        'pet',
      ],
    },
    styles: ['solid'],
    unicode: 'f6be',
    voted: true,
  },
  'cc-amazon-pay': {
    changes: ['5.0.2'],
    label: 'Amazon Pay Credit Card',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f42d',
    voted: false,
  },
  'cc-amex': {
    changes: ['4.2', '5.0.0', '5.7.0'],
    label: 'American Express Credit Card',
    search: {
      terms: ['amex'],
    },
    styles: ['brands'],
    unicode: 'f1f3',
    voted: false,
  },
  'cc-apple-pay': {
    changes: ['5.0.0'],
    label: 'Apple Pay Credit Card',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f416',
    voted: false,
  },
  'cc-diners-club': {
    changes: ['4.4', '5.0.0'],
    label: "Diner's Club Credit Card",
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f24c',
    voted: false,
  },
  'cc-discover': {
    changes: ['4.2', '5.0.0'],
    label: 'Discover Credit Card',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1f2',
    voted: false,
  },
  'cc-jcb': {
    changes: ['4.4', '5.0.0'],
    label: 'JCB Credit Card',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f24b',
    voted: false,
  },
  'cc-mastercard': {
    changes: ['4.2', '5.0.0'],
    label: 'MasterCard Credit Card',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1f1',
    voted: false,
  },
  'cc-paypal': {
    changes: ['4.2', '5.0.0'],
    label: 'Paypal Credit Card',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1f4',
    voted: false,
  },
  'cc-stripe': {
    changes: ['4.2', '5.0.0'],
    label: 'Stripe Credit Card',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1f5',
    voted: false,
  },
  'cc-visa': {
    changes: ['4.2', '5.0.0'],
    label: 'Visa Credit Card',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1f0',
    voted: false,
  },
  centercode: {
    changes: ['5.0.0'],
    label: 'Centercode',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f380',
    voted: false,
  },
  centos: {
    changes: ['5.6.0', '5.8.0'],
    label: 'Centos',
    search: {
      terms: ['linux', 'operating system', 'os'],
    },
    styles: ['brands'],
    unicode: 'f789',
    voted: true,
  },
  certificate: {
    changes: ['2', '5.0.0', '5.10.1', '5.10.2'],
    label: 'certificate',
    search: {
      terms: ['badge', 'star', 'verified'],
    },
    styles: ['solid'],
    unicode: 'f0a3',
    voted: false,
  },
  chair: {
    changes: ['5.4.0', '5.11.0'],
    label: 'Chair',
    search: {
      terms: ['furniture', 'seat', 'sit'],
    },
    styles: ['solid'],
    unicode: 'f6c0',
    voted: true,
  },
  chalkboard: {
    changes: ['5.0.13'],
    label: 'Chalkboard',
    search: {
      terms: [
        'blackboard',
        'learning',
        'school',
        'teaching',
        'whiteboard',
        'writing',
      ],
    },
    styles: ['solid'],
    unicode: 'f51b',
    voted: false,
  },
  'chalkboard-teacher': {
    changes: ['5.0.13'],
    label: 'Chalkboard Teacher',
    search: {
      terms: [
        'blackboard',
        'instructor',
        'learning',
        'professor',
        'school',
        'whiteboard',
        'writing',
      ],
    },
    styles: ['solid'],
    unicode: 'f51c',
    voted: false,
  },
  'charging-station': {
    changes: ['5.2.0', '5.10.1'],
    label: 'Charging Station',
    search: {
      terms: ['electric', 'ev', 'tesla', 'vehicle'],
    },
    styles: ['solid'],
    unicode: 'f5e7',
    voted: false,
  },
  'chart-area': {
    changes: ['4.2', '5.0.0'],
    label: 'Area Chart',
    search: {
      terms: ['analytics', 'area', 'chart', 'graph'],
    },
    styles: ['solid'],
    unicode: 'f1fe',
    voted: false,
  },
  'chart-bar': {
    changes: ['1', '5.0.0', '5.3.0'],
    label: 'Bar Chart',
    search: {
      terms: ['analytics', 'bar', 'chart', 'graph'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f080',
    voted: false,
  },
  'chart-line': {
    changes: ['4.2', '5.0.0', '5.3.0'],
    label: 'Line Chart',
    search: {
      terms: [
        'activity',
        'analytics',
        'chart',
        'dashboard',
        'gain',
        'graph',
        'increase',
        'line',
      ],
    },
    styles: ['solid'],
    unicode: 'f201',
    voted: false,
  },
  'chart-pie': {
    changes: ['4.2', '5.0.0', '5.3.0'],
    label: 'Pie Chart',
    search: {
      terms: ['analytics', 'chart', 'diagram', 'graph', 'pie'],
    },
    styles: ['solid'],
    unicode: 'f200',
    voted: false,
  },
  check: {
    changes: ['1', '5.0.0'],
    label: 'Check',
    search: {
      terms: [
        'accept',
        'agree',
        'checkmark',
        'confirm',
        'correct',
        'done',
        'notice',
        'notification',
        'notify',
        'ok',
        'select',
        'success',
        'tick',
        'todo',
        'yes',
      ],
    },
    styles: ['solid'],
    unicode: 'f00c',
    voted: false,
  },
  'check-circle': {
    changes: ['1', '5.0.0'],
    label: 'Check Circle',
    search: {
      terms: [
        'accept',
        'agree',
        'confirm',
        'correct',
        'done',
        'ok',
        'select',
        'success',
        'tick',
        'todo',
        'yes',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f058',
    voted: false,
  },
  'check-double': {
    changes: ['5.1.0', '5.8.2'],
    label: 'Double Check',
    search: {
      terms: [
        'accept',
        'agree',
        'checkmark',
        'confirm',
        'correct',
        'done',
        'notice',
        'notification',
        'notify',
        'ok',
        'select',
        'success',
        'tick',
        'todo',
      ],
    },
    styles: ['solid'],
    unicode: 'f560',
    voted: true,
  },
  'check-square': {
    changes: ['3.1', '5.0.0'],
    label: 'Check Square',
    search: {
      terms: [
        'accept',
        'agree',
        'checkmark',
        'confirm',
        'correct',
        'done',
        'ok',
        'select',
        'success',
        'tick',
        'todo',
        'yes',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f14a',
    voted: false,
  },
  cheese: {
    changes: ['5.7.0'],
    label: 'Cheese',
    search: {
      terms: [
        'cheddar',
        'curd',
        'gouda',
        'melt',
        'parmesan',
        'sandwich',
        'swiss',
        'wedge',
      ],
    },
    styles: ['solid'],
    unicode: 'f7ef',
    voted: false,
  },
  chess: {
    changes: ['5.0.5', '5.9.0'],
    label: 'Chess',
    search: {
      terms: [
        'board',
        'castle',
        'checkmate',
        'game',
        'king',
        'rook',
        'strategy',
        'tournament',
      ],
    },
    styles: ['solid'],
    unicode: 'f439',
    voted: false,
  },
  'chess-bishop': {
    changes: ['5.0.5', '5.9.0'],
    label: 'Chess Bishop',
    search: {
      terms: ['board', 'checkmate', 'game', 'strategy'],
    },
    styles: ['solid'],
    unicode: 'f43a',
    voted: false,
  },
  'chess-board': {
    changes: ['5.0.5', '5.7.0', '5.9.0'],
    label: 'Chess Board',
    search: {
      terms: ['board', 'checkmate', 'game', 'strategy'],
    },
    styles: ['solid'],
    unicode: 'f43c',
    voted: false,
  },
  'chess-king': {
    changes: ['5.0.5', '5.9.0'],
    label: 'Chess King',
    search: {
      terms: ['board', 'checkmate', 'game', 'strategy'],
    },
    styles: ['solid'],
    unicode: 'f43f',
    voted: false,
  },
  'chess-knight': {
    changes: ['5.0.5', '5.9.0'],
    label: 'Chess Knight',
    search: {
      terms: ['board', 'checkmate', 'game', 'horse', 'strategy'],
    },
    styles: ['solid'],
    unicode: 'f441',
    voted: false,
  },
  'chess-pawn': {
    changes: ['5.0.5', '5.9.0'],
    label: 'Chess Pawn',
    search: {
      terms: ['board', 'checkmate', 'game', 'strategy'],
    },
    styles: ['solid'],
    unicode: 'f443',
    voted: false,
  },
  'chess-queen': {
    changes: ['5.0.5', '5.9.0'],
    label: 'Chess Queen',
    search: {
      terms: ['board', 'checkmate', 'game', 'strategy'],
    },
    styles: ['solid'],
    unicode: 'f445',
    voted: false,
  },
  'chess-rook': {
    changes: ['5.0.5', '5.9.0'],
    label: 'Chess Rook',
    search: {
      terms: ['board', 'castle', 'checkmate', 'game', 'strategy'],
    },
    styles: ['solid'],
    unicode: 'f447',
    voted: false,
  },
  'chevron-circle-down': {
    changes: ['3.1', '5.0.0'],
    label: 'Chevron Circle Down',
    search: {
      terms: ['arrow', 'download', 'dropdown', 'menu', 'more'],
    },
    styles: ['solid'],
    unicode: 'f13a',
    voted: false,
  },
  'chevron-circle-left': {
    changes: ['3.1', '5.0.0'],
    label: 'Chevron Circle Left',
    search: {
      terms: ['arrow', 'back', 'previous'],
    },
    styles: ['solid'],
    unicode: 'f137',
    voted: false,
  },
  'chevron-circle-right': {
    changes: ['3.1', '5.0.0'],
    label: 'Chevron Circle Right',
    search: {
      terms: ['arrow', 'forward', 'next'],
    },
    styles: ['solid'],
    unicode: 'f138',
    voted: false,
  },
  'chevron-circle-up': {
    changes: ['3.1', '5.0.0'],
    label: 'Chevron Circle Up',
    search: {
      terms: ['arrow', 'collapse', 'upload'],
    },
    styles: ['solid'],
    unicode: 'f139',
    voted: false,
  },
  'chevron-down': {
    changes: ['1', '5.0.0'],
    label: 'chevron-down',
    search: {
      terms: ['arrow', 'download', 'expand'],
    },
    styles: ['solid'],
    unicode: 'f078',
    voted: false,
  },
  'chevron-left': {
    changes: ['1', '5.0.0'],
    label: 'chevron-left',
    search: {
      terms: ['arrow', 'back', 'bracket', 'previous'],
    },
    styles: ['solid'],
    unicode: 'f053',
    voted: false,
  },
  'chevron-right': {
    changes: ['1', '5.0.0'],
    label: 'chevron-right',
    search: {
      terms: ['arrow', 'bracket', 'forward', 'next'],
    },
    styles: ['solid'],
    unicode: 'f054',
    voted: false,
  },
  'chevron-up': {
    changes: ['1', '5.0.0'],
    label: 'chevron-up',
    search: {
      terms: ['arrow', 'collapse', 'upload'],
    },
    styles: ['solid'],
    unicode: 'f077',
    voted: false,
  },
  child: {
    changes: ['4.1', '5.0.0'],
    label: 'Child',
    search: {
      terms: ['boy', 'girl', 'kid', 'toddler', 'young'],
    },
    styles: ['solid'],
    unicode: 'f1ae',
    voted: false,
  },
  chrome: {
    changes: ['4.4', '5.0.0'],
    label: 'Chrome',
    search: {
      terms: ['browser'],
    },
    styles: ['brands'],
    unicode: 'f268',
    voted: false,
  },
  chromecast: {
    changes: ['5.8.0'],
    label: 'Chromecast',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f838',
  },
  church: {
    changes: ['5.0.13'],
    label: 'Church',
    search: {
      terms: ['building', 'cathedral', 'chapel', 'community', 'religion'],
    },
    styles: ['solid'],
    unicode: 'f51d',
    voted: true,
  },
  circle: {
    changes: ['3', '5.0.0', '5.10.1', '5.10.2'],
    label: 'Circle',
    search: {
      terms: [
        'circle-thin',
        'diameter',
        'dot',
        'ellipse',
        'notification',
        'round',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f111',
    voted: false,
  },
  'circle-notch': {
    changes: ['4.1', '5.0.0', '5.10.2'],
    label: 'Circle Notched',
    search: {
      terms: [
        'circle-o-notch',
        'diameter',
        'dot',
        'ellipse',
        'round',
        'spinner',
      ],
    },
    styles: ['solid'],
    unicode: 'f1ce',
    voted: false,
  },
  city: {
    changes: ['5.3.0'],
    label: 'City',
    search: {
      terms: ['buildings', 'busy', 'skyscrapers', 'urban', 'windows'],
    },
    styles: ['solid'],
    unicode: 'f64f',
    voted: false,
  },
  'clinic-medical': {
    changes: ['5.7.0'],
    label: 'Medical Clinic',
    search: {
      terms: [
        'covid-19',
        'doctor',
        'general practitioner',
        'hospital',
        'infirmary',
        'medicine',
        'office',
        'outpatient',
      ],
    },
    styles: ['solid'],
    unicode: 'f7f2',
    voted: false,
  },
  clipboard: {
    changes: ['5.0.0'],
    label: 'Clipboard',
    search: {
      terms: ['copy', 'notes', 'paste', 'record'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f328',
    voted: false,
  },
  'clipboard-check': {
    changes: ['5.0.7'],
    label: 'Clipboard with Check',
    search: {
      terms: [
        'accept',
        'agree',
        'confirm',
        'done',
        'ok',
        'select',
        'success',
        'tick',
        'todo',
        'yes',
      ],
    },
    styles: ['solid'],
    unicode: 'f46c',
    voted: false,
  },
  'clipboard-list': {
    changes: ['5.0.7'],
    label: 'Clipboard List',
    search: {
      terms: [
        'checklist',
        'completed',
        'done',
        'finished',
        'intinerary',
        'ol',
        'schedule',
        'tick',
        'todo',
        'ul',
      ],
    },
    styles: ['solid'],
    unicode: 'f46d',
    voted: false,
  },
  clock: {
    changes: ['1', '5.0.0', '5.12.1'],
    label: 'Clock',
    search: {
      terms: [
        'date',
        'late',
        'schedule',
        'time',
        'timer',
        'timestamp',
        'watch',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f017',
    voted: false,
  },
  clone: {
    changes: ['4.4', '5.0.0', '5.11.0'],
    label: 'Clone',
    search: {
      terms: ['arrange', 'copy', 'duplicate', 'paste'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f24d',
    voted: false,
  },
  'closed-captioning': {
    changes: ['4.2', '5.0.0', '5.10.1'],
    label: 'Closed Captioning',
    search: {
      terms: [
        'cc',
        'deaf',
        'hearing',
        'subtitle',
        'subtitling',
        'text',
        'video',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f20a',
    voted: false,
  },
  cloud: {
    changes: ['2', '5.0.0', '5.0.11'],
    label: 'Cloud',
    search: {
      terms: ['atmosphere', 'fog', 'overcast', 'save', 'upload', 'weather'],
    },
    styles: ['solid'],
    unicode: 'f0c2',
    voted: false,
  },
  'cloud-download-alt': {
    changes: ['5.0.0', '5.0.11'],
    label: 'Alternate Cloud Download',
    search: {
      terms: ['download', 'export', 'save'],
    },
    styles: ['solid'],
    unicode: 'f381',
    voted: false,
  },
  'cloud-meatball': {
    changes: ['5.5.0'],
    label: 'Cloud with (a chance of) Meatball',
    search: {
      terms: ['FLDSMDFR', 'food', 'spaghetti', 'storm'],
    },
    styles: ['solid'],
    unicode: 'f73b',
    voted: false,
  },
  'cloud-moon': {
    changes: ['5.4.0', '5.5.0'],
    label: 'Cloud with Moon',
    search: {
      terms: ['crescent', 'evening', 'lunar', 'night', 'partly cloudy', 'sky'],
    },
    styles: ['solid'],
    unicode: 'f6c3',
    voted: false,
  },
  'cloud-moon-rain': {
    changes: ['5.5.0'],
    label: 'Cloud with Moon and Rain',
    search: {
      terms: [
        'crescent',
        'evening',
        'lunar',
        'night',
        'partly cloudy',
        'precipitation',
        'rain',
        'sky',
        'storm',
      ],
    },
    styles: ['solid'],
    unicode: 'f73c',
    voted: false,
  },
  'cloud-rain': {
    changes: ['5.5.0'],
    label: 'Cloud with Rain',
    search: {
      terms: ['precipitation', 'rain', 'sky', 'storm'],
    },
    styles: ['solid'],
    unicode: 'f73d',
    voted: false,
  },
  'cloud-showers-heavy': {
    changes: ['5.5.0'],
    label: 'Cloud with Heavy Showers',
    search: {
      terms: ['precipitation', 'rain', 'sky', 'storm'],
    },
    styles: ['solid'],
    unicode: 'f740',
    voted: false,
  },
  'cloud-sun': {
    changes: ['5.4.0', '5.5.0'],
    label: 'Cloud with Sun',
    search: {
      terms: [
        'clear',
        'day',
        'daytime',
        'fall',
        'outdoors',
        'overcast',
        'partly cloudy',
      ],
    },
    styles: ['solid'],
    unicode: 'f6c4',
    voted: false,
  },
  'cloud-sun-rain': {
    changes: ['5.5.0'],
    label: 'Cloud with Sun and Rain',
    search: {
      terms: [
        'day',
        'overcast',
        'precipitation',
        'storm',
        'summer',
        'sunshower',
      ],
    },
    styles: ['solid'],
    unicode: 'f743',
    voted: false,
  },
  'cloud-upload-alt': {
    changes: ['5.0.0', '5.0.11'],
    label: 'Alternate Cloud Upload',
    search: {
      terms: ['cloud-upload', 'import', 'save', 'upload'],
    },
    styles: ['solid'],
    unicode: 'f382',
    voted: false,
  },
  cloudscale: {
    changes: ['5.0.0'],
    label: 'cloudscale.ch',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f383',
    voted: false,
  },
  cloudsmith: {
    changes: ['5.0.0'],
    label: 'Cloudsmith',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f384',
    voted: false,
  },
  cloudversify: {
    changes: ['5.0.0'],
    label: 'cloudversify',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f385',
    voted: false,
  },
  cocktail: {
    changes: ['5.1.0', '5.10.1'],
    label: 'Cocktail',
    search: {
      terms: [
        'alcohol',
        'beverage',
        'drink',
        'gin',
        'glass',
        'margarita',
        'martini',
        'vodka',
      ],
    },
    styles: ['solid'],
    unicode: 'f561',
    voted: false,
  },
  code: {
    changes: ['3.1', '5.0.0'],
    label: 'Code',
    search: {
      terms: ['brackets', 'code', 'development', 'html'],
    },
    styles: ['solid'],
    unicode: 'f121',
    voted: false,
  },
  'code-branch': {
    changes: ['5.0.0'],
    label: 'Code Branch',
    search: {
      terms: [
        'branch',
        'code-fork',
        'fork',
        'git',
        'github',
        'rebase',
        'svn',
        'vcs',
        'version',
      ],
    },
    styles: ['solid'],
    unicode: 'f126',
    voted: false,
  },
  codepen: {
    changes: ['4.1', '5.0.0'],
    label: 'Codepen',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1cb',
    voted: false,
  },
  codiepie: {
    changes: ['4.5', '5.0.0'],
    label: 'Codie Pie',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f284',
    voted: false,
  },
  coffee: {
    changes: ['3', '5.0.0'],
    label: 'Coffee',
    search: {
      terms: [
        'beverage',
        'breakfast',
        'cafe',
        'drink',
        'fall',
        'morning',
        'mug',
        'seasonal',
        'tea',
      ],
    },
    styles: ['solid'],
    unicode: 'f0f4',
    voted: false,
  },
  cog: {
    changes: ['1', '5.0.0'],
    label: 'cog',
    search: {
      terms: ['gear', 'mechanical', 'settings', 'sprocket', 'wheel'],
    },
    styles: ['solid'],
    unicode: 'f013',
    voted: false,
  },
  cogs: {
    changes: ['1', '5.0.0'],
    label: 'cogs',
    search: {
      terms: ['gears', 'mechanical', 'settings', 'sprocket', 'wheel'],
    },
    styles: ['solid'],
    unicode: 'f085',
    voted: false,
  },
  coins: {
    changes: ['5.0.13'],
    label: 'Coins',
    search: {
      terms: ['currency', 'dime', 'financial', 'gold', 'money', 'penny'],
    },
    styles: ['solid'],
    unicode: 'f51e',
    voted: true,
  },
  columns: {
    changes: ['2', '5.0.0'],
    label: 'Columns',
    search: {
      terms: ['browser', 'dashboard', 'organize', 'panes', 'split'],
    },
    styles: ['solid'],
    unicode: 'f0db',
    voted: false,
  },
  comment: {
    changes: ['1', '5.0.0', '5.0.9', '5.10.1'],
    label: 'comment',
    search: {
      terms: [
        'bubble',
        'chat',
        'commenting',
        'conversation',
        'feedback',
        'message',
        'note',
        'notification',
        'sms',
        'speech',
        'texting',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f075',
    voted: false,
  },
  'comment-alt': {
    changes: ['4.4', '5.0.0', '5.10.1'],
    label: 'Alternate Comment',
    search: {
      terms: [
        'bubble',
        'chat',
        'commenting',
        'conversation',
        'feedback',
        'message',
        'note',
        'notification',
        'sms',
        'speech',
        'texting',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f27a',
    voted: false,
  },
  'comment-dollar': {
    changes: ['5.3.0'],
    label: 'Comment Dollar',
    search: {
      terms: [
        'bubble',
        'chat',
        'commenting',
        'conversation',
        'feedback',
        'message',
        'money',
        'note',
        'notification',
        'pay',
        'sms',
        'speech',
        'spend',
        'texting',
        'transfer',
      ],
    },
    styles: ['solid'],
    unicode: 'f651',
    voted: false,
  },
  'comment-dots': {
    changes: ['5.0.9'],
    label: 'Comment Dots',
    search: {
      terms: [
        'bubble',
        'chat',
        'commenting',
        'conversation',
        'feedback',
        'message',
        'more',
        'note',
        'notification',
        'reply',
        'sms',
        'speech',
        'texting',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f4ad',
    voted: false,
  },
  'comment-medical': {
    changes: ['5.7.0'],
    label: 'Alternate Medical Chat',
    search: {
      terms: [
        'advice',
        'bubble',
        'chat',
        'commenting',
        'conversation',
        'diagnose',
        'feedback',
        'message',
        'note',
        'notification',
        'prescription',
        'sms',
        'speech',
        'texting',
      ],
    },
    styles: ['solid'],
    unicode: 'f7f5',
    voted: false,
  },
  'comment-slash': {
    changes: ['5.0.9'],
    label: 'Comment Slash',
    search: {
      terms: [
        'bubble',
        'cancel',
        'chat',
        'commenting',
        'conversation',
        'feedback',
        'message',
        'mute',
        'note',
        'notification',
        'quiet',
        'sms',
        'speech',
        'texting',
      ],
    },
    styles: ['solid'],
    unicode: 'f4b3',
    voted: false,
  },
  comments: {
    changes: ['1', '5.0.0', '5.0.9'],
    label: 'comments',
    search: {
      terms: [
        'bubble',
        'chat',
        'commenting',
        'conversation',
        'feedback',
        'message',
        'note',
        'notification',
        'sms',
        'speech',
        'texting',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f086',
    voted: false,
  },
  'comments-dollar': {
    changes: ['5.3.0'],
    label: 'Comments Dollar',
    search: {
      terms: [
        'bubble',
        'chat',
        'commenting',
        'conversation',
        'feedback',
        'message',
        'money',
        'note',
        'notification',
        'pay',
        'sms',
        'speech',
        'spend',
        'texting',
        'transfer',
      ],
    },
    styles: ['solid'],
    unicode: 'f653',
    voted: false,
  },
  'compact-disc': {
    changes: ['5.0.13', '5.10.1', '5.11.0', '5.11.1'],
    label: 'Compact Disc',
    search: {
      terms: [
        'album',
        'bluray',
        'cd',
        'disc',
        'dvd',
        'media',
        'movie',
        'music',
        'record',
        'video',
        'vinyl',
      ],
    },
    styles: ['solid'],
    unicode: 'f51f',
    voted: true,
  },
  compass: {
    changes: ['3.2', '5.0.0', '5.2.0', '5.11.0', '5.11.1'],
    label: 'Compass',
    search: {
      terms: [
        'directions',
        'directory',
        'location',
        'menu',
        'navigation',
        'safari',
        'travel',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f14e',
    voted: false,
  },
  compress: {
    changes: ['5.0.0'],
    label: 'Compress',
    search: {
      terms: [
        'collapse',
        'fullscreen',
        'minimize',
        'move',
        'resize',
        'shrink',
        'smaller',
      ],
    },
    styles: ['solid'],
    unicode: 'f066',
    voted: false,
  },
  'compress-alt': {
    changes: ['1', '5.0.0', '5.12.0'],
    label: 'Alternate Compress',
    search: {
      terms: [
        'collapse',
        'fullscreen',
        'minimize',
        'move',
        'resize',
        'shrink',
        'smaller',
      ],
    },
    styles: ['solid'],
    unicode: 'f422',
    voted: false,
  },
  'compress-arrows-alt': {
    changes: ['5.6.0'],
    label: 'Alternate Compress Arrows',
    search: {
      terms: [
        'collapse',
        'fullscreen',
        'minimize',
        'move',
        'resize',
        'shrink',
        'smaller',
      ],
    },
    styles: ['solid'],
    unicode: 'f78c',
    voted: true,
  },
  'concierge-bell': {
    changes: ['5.1.0'],
    label: 'Concierge Bell',
    search: {
      terms: ['attention', 'hotel', 'receptionist', 'service', 'support'],
    },
    styles: ['solid'],
    unicode: 'f562',
    voted: false,
  },
  confluence: {
    changes: ['5.6.0'],
    label: 'Confluence',
    search: {
      terms: ['atlassian'],
    },
    styles: ['brands'],
    unicode: 'f78d',
    voted: true,
  },
  connectdevelop: {
    changes: ['4.3', '5.0.0'],
    label: 'Connect Develop',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f20e',
    voted: false,
  },
  contao: {
    changes: ['4.4', '5.0.0'],
    label: 'Contao',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f26d',
    voted: false,
  },
  cookie: {
    changes: ['5.1.0'],
    label: 'Cookie',
    search: {
      terms: [
        'baked good',
        'chips',
        'chocolate',
        'eat',
        'snack',
        'sweet',
        'treat',
      ],
    },
    styles: ['solid'],
    unicode: 'f563',
    voted: true,
  },
  'cookie-bite': {
    changes: ['5.1.0'],
    label: 'Cookie Bite',
    search: {
      terms: [
        'baked good',
        'bitten',
        'chips',
        'chocolate',
        'eat',
        'snack',
        'sweet',
        'treat',
      ],
    },
    styles: ['solid'],
    unicode: 'f564',
    voted: true,
  },
  copy: {
    changes: ['2', '5.0.0', '5.10.2'],
    label: 'Copy',
    search: {
      terms: ['clone', 'duplicate', 'file', 'files-o', 'paper', 'paste'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f0c5',
    voted: false,
  },
  copyright: {
    changes: ['4.2', '5.0.0', '5.10.1', '5.10.2'],
    label: 'Copyright',
    search: {
      terms: ['brand', 'mark', 'register', 'trademark'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f1f9',
    voted: false,
  },
  'cotton-bureau': {
    changes: ['5.10.0'],
    label: 'Cotton Bureau',
    search: {
      terms: ['clothing', 't-shirts', 'tshirts'],
    },
    styles: ['brands'],
    unicode: 'f89e',
  },
  couch: {
    changes: ['5.0.9'],
    label: 'Couch',
    search: {
      terms: ['chair', 'cushion', 'furniture', 'relax', 'sofa'],
    },
    styles: ['solid'],
    unicode: 'f4b8',
    voted: false,
  },
  cpanel: {
    changes: ['5.0.0'],
    label: 'cPanel',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f388',
    voted: false,
  },
  'creative-commons': {
    changes: ['4.4', '5.0.0', '5.0.11', '5.1.0'],
    label: 'Creative Commons',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f25e',
    voted: false,
  },
  'creative-commons-by': {
    changes: ['5.0.11'],
    label: 'Creative Commons Attribution',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f4e7',
    voted: false,
  },
  'creative-commons-nc': {
    changes: ['5.0.11'],
    label: 'Creative Commons Noncommercial',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f4e8',
    voted: false,
  },
  'creative-commons-nc-eu': {
    changes: ['5.0.11'],
    label: 'Creative Commons Noncommercial (Euro Sign)',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f4e9',
    voted: false,
  },
  'creative-commons-nc-jp': {
    changes: ['5.0.11'],
    label: 'Creative Commons Noncommercial (Yen Sign)',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f4ea',
    voted: false,
  },
  'creative-commons-nd': {
    changes: ['5.0.11'],
    label: 'Creative Commons No Derivative Works',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f4eb',
    voted: false,
  },
  'creative-commons-pd': {
    changes: ['5.0.11'],
    label: 'Creative Commons Public Domain',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f4ec',
    voted: false,
  },
  'creative-commons-pd-alt': {
    changes: ['5.0.11'],
    label: 'Alternate Creative Commons Public Domain',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f4ed',
    voted: false,
  },
  'creative-commons-remix': {
    changes: ['5.0.11'],
    label: 'Creative Commons Remix',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f4ee',
    voted: false,
  },
  'creative-commons-sa': {
    changes: ['5.0.11'],
    label: 'Creative Commons Share Alike',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f4ef',
    voted: false,
  },
  'creative-commons-sampling': {
    changes: ['5.0.11'],
    label: 'Creative Commons Sampling',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f4f0',
    voted: false,
  },
  'creative-commons-sampling-plus': {
    changes: ['5.0.11'],
    label: 'Creative Commons Sampling +',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f4f1',
    voted: false,
  },
  'creative-commons-share': {
    changes: ['5.0.11'],
    label: 'Creative Commons Share',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f4f2',
    voted: false,
  },
  'creative-commons-zero': {
    changes: ['5.0.11', '5.4.0'],
    label: 'Creative Commons CC0',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f4f3',
    voted: false,
  },
  'credit-card': {
    changes: ['2', '5.0.0'],
    label: 'Credit Card',
    search: {
      terms: [
        'buy',
        'checkout',
        'credit-card-alt',
        'debit',
        'money',
        'payment',
        'purchase',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f09d',
    voted: false,
  },
  'critical-role': {
    changes: ['5.4.0', '5.8.0'],
    label: 'Critical Role',
    search: {
      terms: [
        'Dungeons & Dragons',
        'd&d',
        'dnd',
        'fantasy',
        'game',
        'gaming',
        'tabletop',
      ],
    },
    styles: ['brands'],
    unicode: 'f6c9',
    voted: false,
  },
  crop: {
    changes: ['3.1', '5.0.0', '5.1.0'],
    label: 'crop',
    search: {
      terms: ['design', 'frame', 'mask', 'resize', 'shrink'],
    },
    styles: ['solid'],
    unicode: 'f125',
    voted: false,
  },
  'crop-alt': {
    changes: ['5.1.0'],
    label: 'Alternate Crop',
    search: {
      terms: ['design', 'frame', 'mask', 'resize', 'shrink'],
    },
    styles: ['solid'],
    unicode: 'f565',
    voted: false,
  },
  cross: {
    changes: ['5.3.0', '5.10.1'],
    label: 'Cross',
    search: {
      terms: ['catholicism', 'christianity', 'church', 'jesus'],
    },
    styles: ['solid'],
    unicode: 'f654',
    voted: false,
  },
  crosshairs: {
    changes: ['1', '5.0.0'],
    label: 'Crosshairs',
    search: {
      terms: ['aim', 'bullseye', 'gpd', 'picker', 'position'],
    },
    styles: ['solid'],
    unicode: 'f05b',
    voted: false,
  },
  crow: {
    changes: ['5.0.13'],
    label: 'Crow',
    search: {
      terms: ['bird', 'bullfrog', 'fauna', 'halloween', 'holiday', 'toad'],
    },
    styles: ['solid'],
    unicode: 'f520',
    voted: false,
  },
  crown: {
    changes: ['5.0.13'],
    label: 'Crown',
    search: {
      terms: ['award', 'favorite', 'king', 'queen', 'royal', 'tiara'],
    },
    styles: ['solid'],
    unicode: 'f521',
    voted: true,
  },
  crutch: {
    changes: ['5.7.0'],
    label: 'Crutch',
    search: {
      terms: ['cane', 'injury', 'mobility', 'wheelchair'],
    },
    styles: ['solid'],
    unicode: 'f7f7',
    voted: false,
  },
  css3: {
    changes: ['3.1', '5.0.0'],
    label: 'CSS 3 Logo',
    search: {
      terms: ['code'],
    },
    styles: ['brands'],
    unicode: 'f13c',
    voted: false,
  },
  'css3-alt': {
    changes: ['5.0.0'],
    label: 'Alternate CSS3 Logo',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f38b',
    voted: false,
  },
  cube: {
    changes: ['4.1', '5.0.0'],
    label: 'Cube',
    search: {
      terms: ['3d', 'block', 'dice', 'package', 'square', 'tesseract'],
    },
    styles: ['solid'],
    unicode: 'f1b2',
    voted: false,
  },
  cubes: {
    changes: ['4.1', '5.0.0'],
    label: 'Cubes',
    search: {
      terms: [
        '3d',
        'block',
        'dice',
        'package',
        'pyramid',
        'square',
        'stack',
        'tesseract',
      ],
    },
    styles: ['solid'],
    unicode: 'f1b3',
    voted: false,
  },
  cut: {
    changes: ['2', '5.0.0', '5.1.0'],
    label: 'Cut',
    search: {
      terms: ['clip', 'scissors', 'snip'],
    },
    styles: ['solid'],
    unicode: 'f0c4',
    voted: false,
  },
  cuttlefish: {
    changes: ['5.0.0'],
    label: 'Cuttlefish',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f38c',
    voted: false,
  },
  'd-and-d': {
    changes: ['5.0.0'],
    label: 'Dungeons & Dragons',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f38d',
    voted: false,
  },
  'd-and-d-beyond': {
    changes: ['5.4.0'],
    label: 'D&D Beyond',
    search: {
      terms: [
        'Dungeons & Dragons',
        'd&d',
        'dnd',
        'fantasy',
        'gaming',
        'tabletop',
      ],
    },
    styles: ['brands'],
    unicode: 'f6ca',
    voted: false,
  },
  dailymotion: {
    changes: ['5.12.1'],
    label: 'dailymotion',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f952',
    voted: true,
  },
  dashcube: {
    changes: ['4.3', '5.0.0', '5.0.3'],
    label: 'DashCube',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f210',
    voted: false,
  },
  database: {
    changes: ['4.1', '5.0.0'],
    label: 'Database',
    search: {
      terms: ['computer', 'development', 'directory', 'memory', 'storage'],
    },
    styles: ['solid'],
    unicode: 'f1c0',
    voted: false,
  },
  deaf: {
    changes: ['4.6', '5.0.0'],
    label: 'Deaf',
    search: {
      terms: ['ear', 'hearing', 'sign language'],
    },
    styles: ['solid'],
    unicode: 'f2a4',
    voted: false,
  },
  deezer: {
    changes: ['5.13.1'],
    label: 'Deezer',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f977',
    voted: true,
  },
  delicious: {
    changes: ['4.1', '5.0.0', '5.7.0'],
    label: 'Delicious',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1a5',
    voted: false,
  },
  democrat: {
    changes: ['5.5.0'],
    label: 'Democrat',
    search: {
      terms: [
        'american',
        'democratic party',
        'donkey',
        'election',
        'left',
        'left-wing',
        'liberal',
        'politics',
        'usa',
      ],
    },
    styles: ['solid'],
    unicode: 'f747',
    voted: false,
  },
  deploydog: {
    changes: ['5.0.0'],
    label: 'deploy.dog',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f38e',
    voted: false,
  },
  deskpro: {
    changes: ['5.0.0'],
    label: 'Deskpro',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f38f',
    voted: false,
  },
  desktop: {
    changes: ['3', '5.0.0'],
    label: 'Desktop',
    search: {
      terms: [
        'computer',
        'cpu',
        'demo',
        'desktop',
        'device',
        'imac',
        'machine',
        'monitor',
        'pc',
        'screen',
      ],
    },
    styles: ['solid'],
    unicode: 'f108',
    voted: false,
  },
  dev: {
    changes: ['5.4.0'],
    label: 'DEV',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f6cc',
    voted: true,
  },
  deviantart: {
    changes: ['4.1', '5.0.0'],
    label: 'deviantART',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1bd',
    voted: false,
  },
  dharmachakra: {
    changes: ['5.3.0'],
    label: 'Dharmachakra',
    search: {
      terms: ['buddhism', 'buddhist', 'wheel of dharma'],
    },
    styles: ['solid'],
    unicode: 'f655',
    voted: false,
  },
  dhl: {
    changes: ['5.6.0'],
    label: 'DHL',
    search: {
      terms: ['Dalsey', 'Hillblom and Lynn', 'german', 'package', 'shipping'],
    },
    styles: ['brands'],
    unicode: 'f790',
  },
  diagnoses: {
    changes: ['5.0.7', '5.7.0'],
    label: 'Diagnoses',
    search: {
      terms: ['analyze', 'detect', 'diagnosis', 'examine', 'medicine'],
    },
    styles: ['solid'],
    unicode: 'f470',
    voted: false,
  },
  diaspora: {
    changes: ['5.6.0', '5.8.0'],
    label: 'Diaspora',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f791',
    voted: true,
  },
  dice: {
    changes: ['5.0.13'],
    label: 'Dice',
    search: {
      terms: ['chance', 'gambling', 'game', 'roll'],
    },
    styles: ['solid'],
    unicode: 'f522',
    voted: true,
  },
  'dice-d20': {
    changes: ['5.4.0'],
    label: 'Dice D20',
    search: {
      terms: [
        'Dungeons & Dragons',
        'chance',
        'd&d',
        'dnd',
        'fantasy',
        'gambling',
        'game',
        'roll',
      ],
    },
    styles: ['solid'],
    unicode: 'f6cf',
    voted: true,
  },
  'dice-d6': {
    changes: ['5.4.0'],
    label: 'Dice D6',
    search: {
      terms: [
        'Dungeons & Dragons',
        'chance',
        'd&d',
        'dnd',
        'fantasy',
        'gambling',
        'game',
        'roll',
      ],
    },
    styles: ['solid'],
    unicode: 'f6d1',
    voted: false,
  },
  'dice-five': {
    changes: ['5.0.13'],
    label: 'Dice Five',
    search: {
      terms: ['chance', 'gambling', 'game', 'roll'],
    },
    styles: ['solid'],
    unicode: 'f523',
    voted: true,
  },
  'dice-four': {
    changes: ['5.0.13'],
    label: 'Dice Four',
    search: {
      terms: ['chance', 'gambling', 'game', 'roll'],
    },
    styles: ['solid'],
    unicode: 'f524',
    voted: true,
  },
  'dice-one': {
    changes: ['5.0.13'],
    label: 'Dice One',
    search: {
      terms: ['chance', 'gambling', 'game', 'roll'],
    },
    styles: ['solid'],
    unicode: 'f525',
    voted: true,
  },
  'dice-six': {
    changes: ['5.0.13'],
    label: 'Dice Six',
    search: {
      terms: ['chance', 'gambling', 'game', 'roll'],
    },
    styles: ['solid'],
    unicode: 'f526',
    voted: true,
  },
  'dice-three': {
    changes: ['5.0.13'],
    label: 'Dice Three',
    search: {
      terms: ['chance', 'gambling', 'game', 'roll'],
    },
    styles: ['solid'],
    unicode: 'f527',
    voted: true,
  },
  'dice-two': {
    changes: ['5.0.13'],
    label: 'Dice Two',
    search: {
      terms: ['chance', 'gambling', 'game', 'roll'],
    },
    styles: ['solid'],
    unicode: 'f528',
    voted: true,
  },
  digg: {
    changes: ['4.1', '5.0.0'],
    label: 'Digg Logo',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1a6',
    voted: false,
  },
  'digital-ocean': {
    changes: ['5.0.0', '5.7.0'],
    label: 'Digital Ocean',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f391',
    voted: false,
  },
  'digital-tachograph': {
    changes: ['5.1.0'],
    label: 'Digital Tachograph',
    search: {
      terms: ['data', 'distance', 'speed', 'tachometer'],
    },
    styles: ['solid'],
    unicode: 'f566',
    voted: true,
  },
  directions: {
    changes: ['5.2.0', '5.11.0'],
    label: 'Directions',
    search: {
      terms: ['map', 'navigation', 'sign', 'turn'],
    },
    styles: ['solid'],
    unicode: 'f5eb',
    voted: false,
  },
  discord: {
    changes: ['5.0.0'],
    label: 'Discord',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f392',
    voted: false,
  },
  discourse: {
    changes: ['5.0.0', '5.0.3'],
    label: 'Discourse',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f393',
    voted: false,
  },
  disease: {
    changes: ['5.7.0'],
    label: 'Disease',
    search: {
      terms: [
        'bacteria',
        'cancer',
        'covid-19',
        'illness',
        'infection',
        'sickness',
        'virus',
      ],
    },
    styles: ['solid'],
    unicode: 'f7fa',
    voted: false,
  },
  divide: {
    changes: ['5.0.13'],
    label: 'Divide',
    search: {
      terms: ['arithmetic', 'calculus', 'division', 'math'],
    },
    styles: ['solid'],
    unicode: 'f529',
    voted: false,
  },
  dizzy: {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Dizzy Face',
    search: {
      terms: ['dazed', 'dead', 'disapprove', 'emoticon', 'face'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f567',
    voted: false,
  },
  dna: {
    changes: ['5.0.7', '5.0.10'],
    label: 'DNA',
    search: {
      terms: ['double helix', 'genetic', 'helix', 'molecule', 'protein'],
    },
    styles: ['solid'],
    unicode: 'f471',
    voted: false,
  },
  dochub: {
    changes: ['5.0.0'],
    label: 'DocHub',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f394',
    voted: false,
  },
  docker: {
    changes: ['5.0.0'],
    label: 'Docker',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f395',
    voted: false,
  },
  dog: {
    changes: ['5.4.0', '5.12.0'],
    label: 'Dog',
    search: {
      terms: [
        'animal',
        'canine',
        'fauna',
        'mammal',
        'pet',
        'pooch',
        'puppy',
        'woof',
      ],
    },
    styles: ['solid'],
    unicode: 'f6d3',
    voted: false,
  },
  'dollar-sign': {
    changes: ['3.2', '5.0.0', '5.0.9', '5.11.0', '5.11.1'],
    label: 'Dollar Sign',
    search: {
      terms: ['$', 'cost', 'dollar-sign', 'money', 'price', 'usd'],
    },
    styles: ['solid'],
    unicode: 'f155',
    voted: false,
  },
  dolly: {
    changes: ['5.0.7'],
    label: 'Dolly',
    search: {
      terms: ['carry', 'shipping', 'transport'],
    },
    styles: ['solid'],
    unicode: 'f472',
    voted: false,
  },
  'dolly-flatbed': {
    changes: ['5.0.7'],
    label: 'Dolly Flatbed',
    search: {
      terms: ['carry', 'inventory', 'shipping', 'transport'],
    },
    styles: ['solid'],
    unicode: 'f474',
    voted: false,
  },
  donate: {
    changes: ['5.0.9'],
    label: 'Donate',
    search: {
      terms: ['contribute', 'generosity', 'gift', 'give'],
    },
    styles: ['solid'],
    unicode: 'f4b9',
    voted: false,
  },
  'door-closed': {
    changes: ['5.0.13'],
    label: 'Door Closed',
    search: {
      terms: ['enter', 'exit', 'locked'],
    },
    styles: ['solid'],
    unicode: 'f52a',
    voted: true,
  },
  'door-open': {
    changes: ['5.0.13'],
    label: 'Door Open',
    search: {
      terms: ['enter', 'exit', 'welcome'],
    },
    styles: ['solid'],
    unicode: 'f52b',
    voted: true,
  },
  'dot-circle': {
    changes: ['4', '5.0.0'],
    label: 'Dot Circle',
    search: {
      terms: ['bullseye', 'notification', 'target'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f192',
    voted: false,
  },
  dove: {
    changes: ['5.0.9', '5.10.1', '5.10.2'],
    label: 'Dove',
    search: {
      terms: ['bird', 'fauna', 'flying', 'peace', 'war'],
    },
    styles: ['solid'],
    unicode: 'f4ba',
    voted: false,
  },
  download: {
    changes: ['1', '5.0.0'],
    label: 'Download',
    search: {
      terms: ['export', 'hard drive', 'save', 'transfer'],
    },
    styles: ['solid'],
    unicode: 'f019',
    voted: false,
  },
  draft2digital: {
    changes: ['5.0.0'],
    label: 'Draft2digital',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f396',
    voted: false,
  },
  'drafting-compass': {
    changes: ['5.1.0'],
    label: 'Drafting Compass',
    search: {
      terms: ['design', 'map', 'mechanical drawing', 'plot', 'plotting'],
    },
    styles: ['solid'],
    unicode: 'f568',
    voted: false,
  },
  dragon: {
    changes: ['5.4.0'],
    label: 'Dragon',
    search: {
      terms: [
        'Dungeons & Dragons',
        'd&d',
        'dnd',
        'fantasy',
        'fire',
        'lizard',
        'serpent',
      ],
    },
    styles: ['solid'],
    unicode: 'f6d5',
    voted: false,
  },
  'draw-polygon': {
    changes: ['5.2.0'],
    label: 'Draw Polygon',
    search: {
      terms: ['anchors', 'lines', 'object', 'render', 'shape'],
    },
    styles: ['solid'],
    unicode: 'f5ee',
    voted: false,
  },
  dribbble: {
    changes: ['5.0.0'],
    label: 'Dribbble',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f17d',
    voted: false,
  },
  'dribbble-square': {
    changes: ['5.0.0'],
    label: 'Dribbble Square',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f397',
    voted: false,
  },
  dropbox: {
    changes: ['3.2', '5.0.0', '5.0.1'],
    label: 'Dropbox',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f16b',
    voted: false,
  },
  drum: {
    changes: ['5.1.0', '5.11.0'],
    label: 'Drum',
    search: {
      terms: ['instrument', 'music', 'percussion', 'snare', 'sound'],
    },
    styles: ['solid'],
    unicode: 'f569',
    voted: true,
  },
  'drum-steelpan': {
    changes: ['5.1.0'],
    label: 'Drum Steelpan',
    search: {
      terms: [
        'calypso',
        'instrument',
        'music',
        'percussion',
        'reggae',
        'snare',
        'sound',
        'steel',
        'tropical',
      ],
    },
    styles: ['solid'],
    unicode: 'f56a',
    voted: false,
  },
  'drumstick-bite': {
    changes: ['5.4.0', '5.7.0'],
    label: 'Drumstick with Bite Taken Out',
    search: {
      terms: ['bone', 'chicken', 'leg', 'meat', 'poultry', 'turkey'],
    },
    styles: ['solid'],
    unicode: 'f6d7',
    voted: false,
  },
  drupal: {
    changes: ['4.1', '5.0.0'],
    label: 'Drupal Logo',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1a9',
    voted: false,
  },
  dumbbell: {
    changes: ['5.0.5'],
    label: 'Dumbbell',
    search: {
      terms: ['exercise', 'gym', 'strength', 'weight', 'weight-lifting'],
    },
    styles: ['solid'],
    unicode: 'f44b',
    voted: false,
  },
  dumpster: {
    changes: ['5.6.0'],
    label: 'Dumpster',
    search: {
      terms: ['alley', 'bin', 'commercial', 'trash', 'waste'],
    },
    styles: ['solid'],
    unicode: 'f793',
    voted: true,
  },
  'dumpster-fire': {
    changes: ['5.6.0'],
    label: 'Dumpster Fire',
    search: {
      terms: [
        'alley',
        'bin',
        'commercial',
        'danger',
        'dangerous',
        'euphemism',
        'flame',
        'heat',
        'hot',
        'trash',
        'waste',
      ],
    },
    styles: ['solid'],
    unicode: 'f794',
    voted: true,
  },
  dungeon: {
    changes: ['5.4.0'],
    label: 'Dungeon',
    search: {
      terms: [
        'Dungeons & Dragons',
        'building',
        'd&d',
        'dnd',
        'door',
        'entrance',
        'fantasy',
        'gate',
      ],
    },
    styles: ['solid'],
    unicode: 'f6d9',
    voted: false,
  },
  dyalog: {
    changes: ['5.0.0'],
    label: 'Dyalog',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f399',
    voted: false,
  },
  earlybirds: {
    changes: ['5.0.0'],
    label: 'Earlybirds',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f39a',
    voted: false,
  },
  ebay: {
    changes: ['5.0.11', '5.7.0'],
    label: 'eBay',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f4f4',
    voted: true,
  },
  edge: {
    changes: ['4.5', '5.0.0', '5.12.1'],
    label: 'Edge Browser',
    search: {
      terms: ['browser', 'ie'],
    },
    styles: ['brands'],
    unicode: 'f282',
    voted: false,
  },
  'edge-legacy': {
    changes: ['5.13.1'],
    label: 'Edge Legacy Browser',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f978',
    voted: false,
  },
  edit: {
    changes: ['1', '5.0.0'],
    label: 'Edit',
    search: {
      terms: ['edit', 'pen', 'pencil', 'update', 'write'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f044',
    voted: false,
  },
  egg: {
    changes: ['5.7.0'],
    label: 'Egg',
    search: {
      terms: ['breakfast', 'chicken', 'easter', 'shell', 'yolk'],
    },
    styles: ['solid'],
    unicode: 'f7fb',
    voted: false,
  },
  eject: {
    changes: ['1', '5.0.0'],
    label: 'eject',
    search: {
      terms: ['abort', 'cancel', 'cd', 'discharge'],
    },
    styles: ['solid'],
    unicode: 'f052',
    voted: false,
  },
  elementor: {
    changes: ['5.0.3'],
    label: 'Elementor',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f430',
    voted: true,
  },
  'ellipsis-h': {
    changes: ['3.1', '5.0.0'],
    label: 'Horizontal Ellipsis',
    search: {
      terms: [
        'dots',
        'drag',
        'kebab',
        'list',
        'menu',
        'nav',
        'navigation',
        'ol',
        'reorder',
        'settings',
        'ul',
      ],
    },
    styles: ['solid'],
    unicode: 'f141',
    voted: false,
  },
  'ellipsis-v': {
    changes: ['3.1', '5.0.0'],
    label: 'Vertical Ellipsis',
    search: {
      terms: [
        'dots',
        'drag',
        'kebab',
        'list',
        'menu',
        'nav',
        'navigation',
        'ol',
        'reorder',
        'settings',
        'ul',
      ],
    },
    styles: ['solid'],
    unicode: 'f142',
    voted: false,
  },
  ello: {
    changes: ['5.2.0'],
    label: 'Ello',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f5f1',
    voted: true,
  },
  ember: {
    changes: ['5.0.0', '5.0.3'],
    label: 'Ember',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f423',
    voted: false,
  },
  empire: {
    changes: ['4.1', '5.0.0'],
    label: 'Galactic Empire',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1d1',
    voted: false,
  },
  envelope: {
    changes: ['2', '5.0.0', '5.10.1', '5.10.2'],
    label: 'Envelope',
    search: {
      terms: [
        'e-mail',
        'email',
        'letter',
        'mail',
        'message',
        'notification',
        'support',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f0e0',
    voted: false,
  },
  'envelope-open': {
    changes: ['4.7', '5.0.0', '5.10.1', '5.10.2'],
    label: 'Envelope Open',
    search: {
      terms: [
        'e-mail',
        'email',
        'letter',
        'mail',
        'message',
        'notification',
        'support',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f2b6',
    voted: false,
  },
  'envelope-open-text': {
    changes: ['5.3.0', '5.10.1', '5.12.0'],
    label: 'Envelope Open-text',
    search: {
      terms: [
        'e-mail',
        'email',
        'letter',
        'mail',
        'message',
        'notification',
        'support',
      ],
    },
    styles: ['solid'],
    unicode: 'f658',
    voted: false,
  },
  'envelope-square': {
    changes: ['4.1', '5.0.0'],
    label: 'Envelope Square',
    search: {
      terms: [
        'e-mail',
        'email',
        'letter',
        'mail',
        'message',
        'notification',
        'support',
      ],
    },
    styles: ['solid'],
    unicode: 'f199',
    voted: false,
  },
  envira: {
    changes: ['4.6', '5.0.0'],
    label: 'Envira Gallery',
    search: {
      terms: ['leaf'],
    },
    styles: ['brands'],
    unicode: 'f299',
    voted: false,
  },
  equals: {
    changes: ['5.0.13'],
    label: 'Equals',
    search: {
      terms: ['arithmetic', 'even', 'match', 'math'],
    },
    styles: ['solid'],
    unicode: 'f52c',
    voted: false,
  },
  eraser: {
    changes: ['3.1', '5.0.0', '5.8.0'],
    label: 'eraser',
    search: {
      terms: ['art', 'delete', 'remove', 'rubber'],
    },
    styles: ['solid'],
    unicode: 'f12d',
    voted: false,
  },
  erlang: {
    changes: ['5.0.0', '5.0.3', '5.7.0'],
    label: 'Erlang',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f39d',
    voted: false,
  },
  ethereum: {
    changes: ['5.0.2'],
    label: 'Ethereum',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f42e',
    voted: true,
  },
  ethernet: {
    changes: ['5.6.0'],
    label: 'Ethernet',
    search: {
      terms: [
        'cable',
        'cat 5',
        'cat 6',
        'connection',
        'hardware',
        'internet',
        'network',
        'wired',
      ],
    },
    styles: ['solid'],
    unicode: 'f796',
    voted: true,
  },
  etsy: {
    changes: ['4.7', '5.0.0'],
    label: 'Etsy',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f2d7',
    voted: false,
  },
  'euro-sign': {
    changes: ['3.2', '5.0.0'],
    label: 'Euro Sign',
    search: {
      terms: ['currency', 'dollar', 'exchange', 'money'],
    },
    styles: ['solid'],
    unicode: 'f153',
    voted: false,
  },
  evernote: {
    changes: ['5.8.0'],
    label: 'Evernote',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f839',
  },
  'exchange-alt': {
    changes: ['5.0.0'],
    label: 'Alternate Exchange',
    search: {
      terms: [
        'arrow',
        'arrows',
        'exchange',
        'reciprocate',
        'return',
        'swap',
        'transfer',
      ],
    },
    styles: ['solid'],
    unicode: 'f362',
    voted: false,
  },
  exclamation: {
    changes: ['3.1', '5.0.0', '5.10.1', '5.10.2'],
    label: 'exclamation',
    search: {
      terms: [
        'alert',
        'danger',
        'error',
        'important',
        'notice',
        'notification',
        'notify',
        'problem',
        'warning',
      ],
    },
    styles: ['solid'],
    unicode: 'f12a',
    voted: false,
  },
  'exclamation-circle': {
    changes: ['1', '5.0.0'],
    label: 'Exclamation Circle',
    search: {
      terms: [
        'alert',
        'danger',
        'error',
        'important',
        'notice',
        'notification',
        'notify',
        'problem',
        'warning',
      ],
    },
    styles: ['solid'],
    unicode: 'f06a',
    voted: false,
  },
  'exclamation-triangle': {
    changes: ['1', '5.0.0', '5.6.1'],
    label: 'Exclamation Triangle',
    search: {
      terms: [
        'alert',
        'danger',
        'error',
        'important',
        'notice',
        'notification',
        'notify',
        'problem',
        'warning',
      ],
    },
    styles: ['solid'],
    unicode: 'f071',
    voted: false,
  },
  expand: {
    changes: ['5.0.0'],
    label: 'Expand',
    search: {
      terms: ['bigger', 'enlarge', 'fullscreen', 'resize'],
    },
    styles: ['solid'],
    unicode: 'f065',
    voted: false,
  },
  'expand-alt': {
    changes: ['1', '5.0.0', '5.12.0'],
    label: 'Alternate Expand',
    search: {
      terms: ['arrows', 'bigger', 'enlarge', 'fullscreen', 'resize'],
    },
    styles: ['solid'],
    unicode: 'f424',
    voted: false,
  },
  'expand-arrows-alt': {
    changes: ['5.0.0', '5.8.0'],
    label: 'Alternate Expand Arrows',
    search: {
      terms: ['bigger', 'enlarge', 'fullscreen', 'move', 'resize'],
    },
    styles: ['solid'],
    unicode: 'f31e',
    voted: false,
  },
  expeditedssl: {
    changes: ['4.4', '5.0.0'],
    label: 'ExpeditedSSL',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f23e',
    voted: false,
  },
  'external-link-alt': {
    changes: ['5.0.0', '5.11.0'],
    label: 'Alternate External Link',
    search: {
      terms: ['external-link', 'new', 'open', 'share'],
    },
    styles: ['solid'],
    unicode: 'f35d',
    voted: false,
  },
  'external-link-square-alt': {
    changes: ['5.0.0'],
    label: 'Alternate External Link Square',
    search: {
      terms: ['external-link-square', 'new', 'open', 'share'],
    },
    styles: ['solid'],
    unicode: 'f360',
    voted: false,
  },
  eye: {
    changes: ['1', '5.0.0', '5.7.0'],
    label: 'Eye',
    search: {
      terms: [
        'look',
        'optic',
        'see',
        'seen',
        'show',
        'sight',
        'views',
        'visible',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f06e',
    voted: false,
  },
  'eye-dropper': {
    changes: ['4.2', '5.0.0', '5.1.0'],
    label: 'Eye Dropper',
    search: {
      terms: ['beaker', 'clone', 'color', 'copy', 'eyedropper', 'pipette'],
    },
    styles: ['solid'],
    unicode: 'f1fb',
    voted: false,
  },
  'eye-slash': {
    changes: ['1', '5.0.0', '5.7.0'],
    label: 'Eye Slash',
    search: {
      terms: [
        'blind',
        'hide',
        'show',
        'toggle',
        'unseen',
        'views',
        'visible',
        'visiblity',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f070',
    voted: false,
  },
  facebook: {
    changes: ['2', '5.0.0', '5.8.2'],
    label: 'Facebook',
    search: {
      terms: ['facebook-official', 'social network'],
    },
    styles: ['brands'],
    unicode: 'f09a',
    voted: false,
  },
  'facebook-f': {
    changes: ['5.0.0', '5.8.2'],
    label: 'Facebook F',
    search: {
      terms: ['facebook'],
    },
    styles: ['brands'],
    unicode: 'f39e',
    voted: false,
  },
  'facebook-messenger': {
    changes: ['5.0.0', '5.8.2', '5.9.0'],
    label: 'Facebook Messenger',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f39f',
    voted: false,
  },
  'facebook-square': {
    changes: ['1', '5.0.0', '5.8.2'],
    label: 'Facebook Square',
    search: {
      terms: ['social network'],
    },
    styles: ['brands'],
    unicode: 'f082',
    voted: false,
  },
  fan: {
    changes: ['5.9.0', '5.10.1'],
    label: 'Fan',
    search: {
      terms: ['ac', 'air conditioning', 'blade', 'blower', 'cool', 'hot'],
    },
    styles: ['solid'],
    unicode: 'f863',
    voted: true,
  },
  'fantasy-flight-games': {
    changes: ['5.4.0'],
    label: 'Fantasy Flight-games',
    search: {
      terms: [
        'Dungeons & Dragons',
        'd&d',
        'dnd',
        'fantasy',
        'game',
        'gaming',
        'tabletop',
      ],
    },
    styles: ['brands'],
    unicode: 'f6dc',
    voted: false,
  },
  'fast-backward': {
    changes: ['1', '5.0.0'],
    label: 'fast-backward',
    search: {
      terms: ['beginning', 'first', 'previous', 'rewind', 'start'],
    },
    styles: ['solid'],
    unicode: 'f049',
    voted: false,
  },
  'fast-forward': {
    changes: ['1', '5.0.0'],
    label: 'fast-forward',
    search: {
      terms: ['end', 'last', 'next'],
    },
    styles: ['solid'],
    unicode: 'f050',
    voted: false,
  },
  faucet: {
    changes: ['5.12.0'],
    label: 'Faucet',
    search: {
      terms: [
        'covid-19',
        'drip',
        'house',
        'hygiene',
        'kitchen',
        'sink',
        'water',
      ],
    },
    styles: ['solid'],
    unicode: 'f905',
    voted: false,
  },
  fax: {
    changes: ['4.1', '5.0.0', '5.3.0', '5.11.0'],
    label: 'Fax',
    search: {
      terms: ['business', 'communicate', 'copy', 'facsimile', 'send'],
    },
    styles: ['solid'],
    unicode: 'f1ac',
    voted: false,
  },
  feather: {
    changes: ['5.0.13', '5.1.0'],
    label: 'Feather',
    search: {
      terms: ['bird', 'light', 'plucked', 'quill', 'write'],
    },
    styles: ['solid'],
    unicode: 'f52d',
    voted: true,
  },
  'feather-alt': {
    changes: ['5.1.0'],
    label: 'Alternate Feather',
    search: {
      terms: ['bird', 'light', 'plucked', 'quill', 'write'],
    },
    styles: ['solid'],
    unicode: 'f56b',
    voted: true,
  },
  fedex: {
    changes: ['5.6.0'],
    label: 'FedEx',
    search: {
      terms: ['Federal Express', 'package', 'shipping'],
    },
    styles: ['brands'],
    unicode: 'f797',
  },
  fedora: {
    changes: ['5.6.0', '5.6.3', '5.8.0'],
    label: 'Fedora',
    search: {
      terms: ['linux', 'operating system', 'os'],
    },
    styles: ['brands'],
    unicode: 'f798',
    voted: true,
  },
  female: {
    changes: ['3.2', '5.0.0'],
    label: 'Female',
    search: {
      terms: ['human', 'person', 'profile', 'user', 'woman'],
    },
    styles: ['solid'],
    unicode: 'f182',
    voted: false,
  },
  'fighter-jet': {
    changes: ['3', '5.0.0'],
    label: 'fighter-jet',
    search: {
      terms: [
        'airplane',
        'fast',
        'fly',
        'goose',
        'maverick',
        'plane',
        'quick',
        'top gun',
        'transportation',
        'travel',
      ],
    },
    styles: ['solid'],
    unicode: 'f0fb',
    voted: false,
  },
  figma: {
    changes: ['5.6.0', '5.7.0', '5.8.0'],
    label: 'Figma',
    search: {
      terms: ['app', 'design', 'interface'],
    },
    styles: ['brands'],
    unicode: 'f799',
    voted: false,
  },
  file: {
    changes: ['3.2', '5.0.0', '5.10.1', '5.10.2'],
    label: 'File',
    search: {
      terms: ['document', 'new', 'page', 'pdf', 'resume'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f15b',
    voted: false,
  },
  'file-alt': {
    changes: ['3.2', '5.0.0', '5.10.2'],
    label: 'Alternate File',
    search: {
      terms: ['document', 'file-text', 'invoice', 'new', 'page', 'pdf'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f15c',
    voted: false,
  },
  'file-archive': {
    changes: ['4.1', '5.0.0', '5.7.0', '5.10.2'],
    label: 'Archive File',
    search: {
      terms: ['.zip', 'bundle', 'compress', 'compression', 'download', 'zip'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f1c6',
    voted: false,
  },
  'file-audio': {
    changes: ['4.1', '5.0.0', '5.10.2'],
    label: 'Audio File',
    search: {
      terms: ['document', 'mp3', 'music', 'page', 'play', 'sound'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f1c7',
    voted: false,
  },
  'file-code': {
    changes: ['4.1', '5.0.0', '5.10.2'],
    label: 'Code File',
    search: {
      terms: ['css', 'development', 'document', 'html'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f1c9',
    voted: false,
  },
  'file-contract': {
    changes: ['5.1.0', '5.10.2'],
    label: 'File Contract',
    search: {
      terms: ['agreement', 'binding', 'document', 'legal', 'signature'],
    },
    styles: ['solid'],
    unicode: 'f56c',
    voted: false,
  },
  'file-csv': {
    changes: ['5.4.0', '5.10.2'],
    label: 'File CSV',
    search: {
      terms: ['document', 'excel', 'numbers', 'spreadsheets', 'table'],
    },
    styles: ['solid'],
    unicode: 'f6dd',
    voted: false,
  },
  'file-download': {
    changes: ['5.1.0', '5.10.2'],
    label: 'File Download',
    search: {
      terms: ['document', 'export', 'save'],
    },
    styles: ['solid'],
    unicode: 'f56d',
    voted: true,
  },
  'file-excel': {
    changes: ['4.1', '5.0.0', '5.10.2'],
    label: 'Excel File',
    search: {
      terms: ['csv', 'document', 'numbers', 'spreadsheets', 'table'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f1c3',
    voted: false,
  },
  'file-export': {
    changes: ['5.1.0', '5.7.0', '5.10.2'],
    label: 'File Export',
    search: {
      terms: ['download', 'save'],
    },
    styles: ['solid'],
    unicode: 'f56e',
    voted: true,
  },
  'file-image': {
    changes: ['4.1', '5.0.0', '5.10.2'],
    label: 'Image File',
    search: {
      terms: ['document', 'image', 'jpg', 'photo', 'png'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f1c5',
    voted: false,
  },
  'file-import': {
    changes: ['5.1.0', '5.7.0', '5.10.2'],
    label: 'File Import',
    search: {
      terms: ['copy', 'document', 'send', 'upload'],
    },
    styles: ['solid'],
    unicode: 'f56f',
    voted: true,
  },
  'file-invoice': {
    changes: ['5.1.0', '5.10.2'],
    label: 'File Invoice',
    search: {
      terms: ['account', 'bill', 'charge', 'document', 'payment', 'receipt'],
    },
    styles: ['solid'],
    unicode: 'f570',
    voted: true,
  },
  'file-invoice-dollar': {
    changes: ['5.1.0', '5.10.2'],
    label: 'File Invoice with US Dollar',
    search: {
      terms: [
        '$',
        'account',
        'bill',
        'charge',
        'document',
        'dollar-sign',
        'money',
        'payment',
        'receipt',
        'usd',
      ],
    },
    styles: ['solid'],
    unicode: 'f571',
    voted: true,
  },
  'file-medical': {
    changes: ['5.0.7', '5.10.2'],
    label: 'Medical File',
    search: {
      terms: ['document', 'health', 'history', 'prescription', 'record'],
    },
    styles: ['solid'],
    unicode: 'f477',
    voted: false,
  },
  'file-medical-alt': {
    changes: ['5.0.7', '5.10.2'],
    label: 'Alternate Medical File',
    search: {
      terms: ['document', 'health', 'history', 'prescription', 'record'],
    },
    styles: ['solid'],
    unicode: 'f478',
    voted: false,
  },
  'file-pdf': {
    changes: ['4.1', '5.0.0', '5.10.2'],
    label: 'PDF File',
    search: {
      terms: ['acrobat', 'document', 'preview', 'save'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f1c1',
    voted: false,
  },
  'file-powerpoint': {
    changes: ['4.1', '5.0.0', '5.10.2'],
    label: 'Powerpoint File',
    search: {
      terms: ['display', 'document', 'keynote', 'presentation'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f1c4',
    voted: false,
  },
  'file-prescription': {
    changes: ['5.1.0', '5.10.2'],
    label: 'File Prescription',
    search: {
      terms: ['document', 'drugs', 'medical', 'medicine', 'rx'],
    },
    styles: ['solid'],
    unicode: 'f572',
    voted: false,
  },
  'file-signature': {
    changes: ['5.1.0', '5.10.2'],
    label: 'File Signature',
    search: {
      terms: ['John Hancock', 'contract', 'document', 'name'],
    },
    styles: ['solid'],
    unicode: 'f573',
    voted: true,
  },
  'file-upload': {
    changes: ['5.1.0', '5.10.2'],
    label: 'File Upload',
    search: {
      terms: ['document', 'import', 'page', 'save'],
    },
    styles: ['solid'],
    unicode: 'f574',
    voted: true,
  },
  'file-video': {
    changes: ['4.1', '5.0.0', '5.10.2'],
    label: 'Video File',
    search: {
      terms: ['document', 'm4v', 'movie', 'mp4', 'play'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f1c8',
    voted: false,
  },
  'file-word': {
    changes: ['4.1', '5.0.0', '5.10.2'],
    label: 'Word File',
    search: {
      terms: ['document', 'edit', 'page', 'text', 'writing'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f1c2',
    voted: false,
  },
  fill: {
    changes: ['5.1.0'],
    label: 'Fill',
    search: {
      terms: ['bucket', 'color', 'paint', 'paint bucket'],
    },
    styles: ['solid'],
    unicode: 'f575',
    voted: false,
  },
  'fill-drip': {
    changes: ['5.1.0'],
    label: 'Fill Drip',
    search: {
      terms: ['bucket', 'color', 'drop', 'paint', 'paint bucket', 'spill'],
    },
    styles: ['solid'],
    unicode: 'f576',
    voted: false,
  },
  film: {
    changes: ['1', '5.0.0'],
    label: 'Film',
    search: {
      terms: ['cinema', 'movie', 'strip', 'video'],
    },
    styles: ['solid'],
    unicode: 'f008',
    voted: false,
  },
  filter: {
    changes: ['2', '5.0.0', '5.10.1', '5.11.0', '5.11.1'],
    label: 'Filter',
    search: {
      terms: ['funnel', 'options', 'separate', 'sort'],
    },
    styles: ['solid'],
    unicode: 'f0b0',
    voted: false,
  },
  fingerprint: {
    changes: ['5.1.0'],
    label: 'Fingerprint',
    search: {
      terms: [
        'human',
        'id',
        'identification',
        'lock',
        'smudge',
        'touch',
        'unique',
        'unlock',
      ],
    },
    styles: ['solid'],
    unicode: 'f577',
    voted: true,
  },
  fire: {
    changes: ['1', '5.0.0', '5.6.0', '5.6.3', '5.10.1'],
    label: 'fire',
    search: {
      terms: ['burn', 'caliente', 'flame', 'heat', 'hot', 'popular'],
    },
    styles: ['solid'],
    unicode: 'f06d',
    voted: false,
  },
  'fire-alt': {
    changes: ['5.6.3'],
    label: 'Alternate Fire',
    search: {
      terms: ['burn', 'caliente', 'flame', 'heat', 'hot', 'popular'],
    },
    styles: ['solid'],
    unicode: 'f7e4',
  },
  'fire-extinguisher': {
    changes: ['3.1', '5.0.0'],
    label: 'fire-extinguisher',
    search: {
      terms: [
        'burn',
        'caliente',
        'fire fighter',
        'flame',
        'heat',
        'hot',
        'rescue',
      ],
    },
    styles: ['solid'],
    unicode: 'f134',
    voted: false,
  },
  firefox: {
    changes: ['4.4', '5.0.0', '5.0.1', '5.12.0'],
    label: 'Firefox',
    search: {
      terms: ['browser'],
    },
    styles: ['brands'],
    unicode: 'f269',
    voted: false,
  },
  'firefox-browser': {
    changes: ['5.12.0'],
    label: 'Firefox Browser',
    search: {
      terms: ['browser'],
    },
    styles: ['brands'],
    unicode: 'f907',
    voted: false,
  },
  'first-aid': {
    changes: ['5.0.7'],
    label: 'First Aid',
    search: {
      terms: ['emergency', 'emt', 'health', 'medical', 'rescue'],
    },
    styles: ['solid'],
    unicode: 'f479',
    voted: false,
  },
  'first-order': {
    changes: ['4.6', '5.0.0'],
    label: 'First Order',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f2b0',
    voted: false,
  },
  'first-order-alt': {
    changes: ['5.0.12'],
    label: 'Alternate First Order',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f50a',
    voted: false,
  },
  firstdraft: {
    changes: ['5.0.0'],
    label: 'firstdraft',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3a1',
    voted: false,
  },
  fish: {
    changes: ['5.1.0', '5.10.1'],
    label: 'Fish',
    search: {
      terms: ['fauna', 'gold', 'seafood', 'swimming'],
    },
    styles: ['solid'],
    unicode: 'f578',
    voted: true,
  },
  'fist-raised': {
    changes: ['5.4.0'],
    label: 'Raised Fist',
    search: {
      terms: [
        'Dungeons & Dragons',
        'd&d',
        'dnd',
        'fantasy',
        'hand',
        'ki',
        'monk',
        'resist',
        'strength',
        'unarmed combat',
      ],
    },
    styles: ['solid'],
    unicode: 'f6de',
    voted: false,
  },
  flag: {
    changes: ['1', '5.0.0'],
    label: 'flag',
    search: {
      terms: [
        'country',
        'notice',
        'notification',
        'notify',
        'pole',
        'report',
        'symbol',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f024',
    voted: false,
  },
  'flag-checkered': {
    changes: ['3.1', '5.0.0', '5.7.0', '5.10.1'],
    label: 'flag-checkered',
    search: {
      terms: [
        'notice',
        'notification',
        'notify',
        'pole',
        'racing',
        'report',
        'symbol',
      ],
    },
    styles: ['solid'],
    unicode: 'f11e',
    voted: false,
  },
  'flag-usa': {
    changes: ['5.5.0'],
    label: 'United States of America Flag',
    search: {
      terms: [
        'betsy ross',
        'country',
        'old glory',
        'stars',
        'stripes',
        'symbol',
      ],
    },
    styles: ['solid'],
    unicode: 'f74d',
    voted: false,
  },
  flask: {
    changes: ['2', '5.0.0'],
    label: 'Flask',
    search: {
      terms: ['beaker', 'experimental', 'labs', 'science'],
    },
    styles: ['solid'],
    unicode: 'f0c3',
    voted: false,
  },
  flickr: {
    changes: ['3.2', '5.0.0'],
    label: 'Flickr',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f16e',
    voted: false,
  },
  flipboard: {
    changes: ['5.0.5', '5.0.9'],
    label: 'Flipboard',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f44d',
    voted: true,
  },
  flushed: {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Flushed Face',
    search: {
      terms: ['embarrassed', 'emoticon', 'face'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f579',
    voted: false,
  },
  fly: {
    changes: ['5.0.0'],
    label: 'Fly',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f417',
    voted: false,
  },
  folder: {
    changes: ['1', '5.0.0', '5.3.0', '5.10.1'],
    label: 'Folder',
    search: {
      terms: ['archive', 'directory', 'document', 'file'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f07b',
    voted: false,
  },
  'folder-minus': {
    changes: ['5.3.0'],
    label: 'Folder Minus',
    search: {
      terms: [
        'archive',
        'delete',
        'directory',
        'document',
        'file',
        'negative',
        'remove',
      ],
    },
    styles: ['solid'],
    unicode: 'f65d',
    voted: false,
  },
  'folder-open': {
    changes: ['1', '5.0.0'],
    label: 'Folder Open',
    search: {
      terms: ['archive', 'directory', 'document', 'empty', 'file', 'new'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f07c',
    voted: false,
  },
  'folder-plus': {
    changes: ['5.3.0', '5.11.0', '5.12.1'],
    label: 'Folder Plus',
    search: {
      terms: [
        'add',
        'archive',
        'create',
        'directory',
        'document',
        'file',
        'new',
        'positive',
      ],
    },
    styles: ['solid'],
    unicode: 'f65e',
    voted: false,
  },
  font: {
    changes: ['1', '5.0.0', '5.9.0', '5.10.2'],
    label: 'font',
    search: {
      terms: ['alphabet', 'glyph', 'text', 'type', 'typeface'],
    },
    styles: ['solid'],
    unicode: 'f031',
    voted: false,
  },
  'font-awesome': {
    changes: ['4.6', '5.0.0'],
    label: 'Font Awesome',
    search: {
      terms: ['meanpath'],
    },
    styles: ['brands'],
    unicode: 'f2b4',
    voted: false,
  },
  'font-awesome-alt': {
    changes: ['5.0.0'],
    label: 'Alternate Font Awesome',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f35c',
    voted: false,
  },
  'font-awesome-flag': {
    changes: ['5.0.0', '5.0.1'],
    label: 'Font Awesome Flag',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f425',
    voted: false,
  },
  'font-awesome-logo-full': {
    changes: ['5.0.11'],
    label: 'Font Awesome Full Logo',
    ligatures: ['Font Awesome'],
    private: true,
    search: {
      terms: [],
    },
    styles: ['regular', 'solid', 'brands'],
    unicode: 'f4e6',
    voted: false,
  },
  fonticons: {
    changes: ['4.4', '5.0.0'],
    label: 'Fonticons',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f280',
    voted: false,
  },
  'fonticons-fi': {
    changes: ['5.0.0'],
    label: 'Fonticons Fi',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3a2',
    voted: false,
  },
  'football-ball': {
    changes: ['5.0.5', '5.11.0', '5.11.1'],
    label: 'Football Ball',
    search: {
      terms: ['ball', 'fall', 'nfl', 'pigskin', 'seasonal'],
    },
    styles: ['solid'],
    unicode: 'f44e',
    voted: false,
  },
  'fort-awesome': {
    changes: ['4.5', '5.0.0', '5.0.3'],
    label: 'Fort Awesome',
    search: {
      terms: ['castle'],
    },
    styles: ['brands'],
    unicode: 'f286',
    voted: false,
  },
  'fort-awesome-alt': {
    changes: ['5.0.0'],
    label: 'Alternate Fort Awesome',
    search: {
      terms: ['castle'],
    },
    styles: ['brands'],
    unicode: 'f3a3',
    voted: false,
  },
  forumbee: {
    changes: ['4.3', '5.0.0'],
    label: 'Forumbee',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f211',
    voted: false,
  },
  forward: {
    changes: ['1', '5.0.0'],
    label: 'forward',
    search: {
      terms: ['forward', 'next', 'skip'],
    },
    styles: ['solid'],
    unicode: 'f04e',
    voted: false,
  },
  foursquare: {
    changes: ['3.2', '5.0.0'],
    label: 'Foursquare',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f180',
    voted: false,
  },
  'free-code-camp': {
    changes: ['4.7', '5.0.0', '5.12.0'],
    label: 'freeCodeCamp',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f2c5',
    voted: false,
  },
  freebsd: {
    changes: ['5.0.0'],
    label: 'FreeBSD',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3a4',
    voted: false,
  },
  frog: {
    changes: ['5.0.13'],
    label: 'Frog',
    search: {
      terms: [
        'amphibian',
        'bullfrog',
        'fauna',
        'hop',
        'kermit',
        'kiss',
        'prince',
        'ribbit',
        'toad',
        'wart',
      ],
    },
    styles: ['solid'],
    unicode: 'f52e',
    voted: false,
  },
  frown: {
    changes: ['3.1', '5.0.0', '5.0.9', '5.1.0', '5.11.0', '5.11.1'],
    label: 'Frowning Face',
    search: {
      terms: ['disapprove', 'emoticon', 'face', 'rating', 'sad'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f119',
    voted: false,
  },
  'frown-open': {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Frowning Face With Open Mouth',
    search: {
      terms: ['disapprove', 'emoticon', 'face', 'rating', 'sad'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f57a',
    voted: false,
  },
  fulcrum: {
    changes: ['5.0.12', '5.8.0'],
    label: 'Fulcrum',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f50b',
    voted: false,
  },
  'funnel-dollar': {
    changes: ['5.3.0'],
    label: 'Funnel Dollar',
    search: {
      terms: ['filter', 'money', 'options', 'separate', 'sort'],
    },
    styles: ['solid'],
    unicode: 'f662',
    voted: false,
  },
  futbol: {
    changes: ['4.2', '5.0.0', '5.0.5'],
    label: 'Futbol',
    search: {
      terms: ['ball', 'football', 'mls', 'soccer'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f1e3',
    voted: false,
  },
  'galactic-republic': {
    changes: ['5.0.12'],
    label: 'Galactic Republic',
    search: {
      terms: ['politics', 'star wars'],
    },
    styles: ['brands'],
    unicode: 'f50c',
    voted: false,
  },
  'galactic-senate': {
    changes: ['5.0.12'],
    label: 'Galactic Senate',
    search: {
      terms: ['star wars'],
    },
    styles: ['brands'],
    unicode: 'f50d',
    voted: false,
  },
  gamepad: {
    changes: ['3.1', '5.0.0', '5.11.0'],
    label: 'Gamepad',
    search: {
      terms: [
        'arcade',
        'controller',
        'd-pad',
        'joystick',
        'video',
        'video game',
      ],
    },
    styles: ['solid'],
    unicode: 'f11b',
    voted: false,
  },
  'gas-pump': {
    changes: ['5.0.13', '5.10.1'],
    label: 'Gas Pump',
    search: {
      terms: ['car', 'fuel', 'gasoline', 'petrol'],
    },
    styles: ['solid'],
    unicode: 'f52f',
    voted: true,
  },
  gavel: {
    changes: ['2', '5.0.0'],
    label: 'Gavel',
    search: {
      terms: ['hammer', 'judge', 'law', 'lawyer', 'opinion'],
    },
    styles: ['solid'],
    unicode: 'f0e3',
    voted: false,
  },
  gem: {
    changes: ['5.0.0', '5.10.1'],
    label: 'Gem',
    search: {
      terms: ['diamond', 'jewelry', 'sapphire', 'stone', 'treasure'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f3a5',
    voted: false,
  },
  genderless: {
    changes: ['4.4', '5.0.0', '5.11.0', '5.11.1'],
    label: 'Genderless',
    search: {
      terms: ['androgynous', 'asexual', 'sexless'],
    },
    styles: ['solid'],
    unicode: 'f22d',
    voted: false,
  },
  'get-pocket': {
    changes: ['4.4', '5.0.0'],
    label: 'Get Pocket',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f265',
    voted: false,
  },
  gg: {
    changes: ['4.4', '5.0.0'],
    label: 'GG Currency',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f260',
    voted: false,
  },
  'gg-circle': {
    changes: ['4.4', '5.0.0'],
    label: 'GG Currency Circle',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f261',
    voted: false,
  },
  ghost: {
    changes: ['5.4.0'],
    label: 'Ghost',
    search: {
      terms: [
        'apparition',
        'blinky',
        'clyde',
        'floating',
        'halloween',
        'holiday',
        'inky',
        'pinky',
        'spirit',
      ],
    },
    styles: ['solid'],
    unicode: 'f6e2',
    voted: false,
  },
  gift: {
    changes: ['1', '5.0.0', '5.0.9', '5.6.0'],
    label: 'gift',
    search: {
      terms: [
        'christmas',
        'generosity',
        'giving',
        'holiday',
        'party',
        'present',
        'wrapped',
        'xmas',
      ],
    },
    styles: ['solid'],
    unicode: 'f06b',
    voted: false,
  },
  gifts: {
    changes: ['5.6.0'],
    label: 'Gifts',
    search: {
      terms: [
        'christmas',
        'generosity',
        'giving',
        'holiday',
        'party',
        'present',
        'wrapped',
        'xmas',
      ],
    },
    styles: ['solid'],
    unicode: 'f79c',
  },
  git: {
    changes: ['4.1', '5.0.0', '5.8.2'],
    label: 'Git',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1d3',
    voted: false,
  },
  'git-alt': {
    changes: ['5.8.2'],
    label: 'Git Alt',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f841',
  },
  'git-square': {
    changes: ['4.1', '5.0.0', '5.8.2'],
    label: 'Git Square',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1d2',
    voted: false,
  },
  github: {
    changes: ['2', '5.0.0'],
    label: 'GitHub',
    search: {
      terms: ['octocat'],
    },
    styles: ['brands'],
    unicode: 'f09b',
    voted: false,
  },
  'github-alt': {
    changes: ['3', '5.0.0'],
    label: 'Alternate GitHub',
    search: {
      terms: ['octocat'],
    },
    styles: ['brands'],
    unicode: 'f113',
    voted: false,
  },
  'github-square': {
    changes: ['1', '5.0.0'],
    label: 'GitHub Square',
    search: {
      terms: ['octocat'],
    },
    styles: ['brands'],
    unicode: 'f092',
    voted: false,
  },
  gitkraken: {
    changes: ['5.0.0'],
    label: 'GitKraken',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3a6',
    voted: false,
  },
  gitlab: {
    changes: ['4.6', '5.0.0', '5.7.0'],
    label: 'GitLab',
    search: {
      terms: ['Axosoft'],
    },
    styles: ['brands'],
    unicode: 'f296',
    voted: false,
  },
  gitter: {
    changes: ['5.0.0'],
    label: 'Gitter',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f426',
    voted: false,
  },
  'glass-cheers': {
    changes: ['5.6.0'],
    label: 'Glass Cheers',
    search: {
      terms: [
        'alcohol',
        'bar',
        'beverage',
        'celebration',
        'champagne',
        'clink',
        'drink',
        'holiday',
        "new year's eve",
        'party',
        'toast',
      ],
    },
    styles: ['solid'],
    unicode: 'f79f',
  },
  'glass-martini': {
    changes: ['1', '5.0.0', '5.1.0', '5.10.1'],
    label: 'Martini Glass',
    search: {
      terms: ['alcohol', 'bar', 'beverage', 'drink', 'liquor'],
    },
    styles: ['solid'],
    unicode: 'f000',
    voted: false,
  },
  'glass-martini-alt': {
    changes: ['5.1.0'],
    label: 'Alternate Glass Martini',
    search: {
      terms: ['alcohol', 'bar', 'beverage', 'drink', 'liquor'],
    },
    styles: ['solid'],
    unicode: 'f57b',
    voted: false,
  },
  'glass-whiskey': {
    changes: ['5.6.0'],
    label: 'Glass Whiskey',
    search: {
      terms: [
        'alcohol',
        'bar',
        'beverage',
        'bourbon',
        'drink',
        'liquor',
        'neat',
        'rye',
        'scotch',
        'whisky',
      ],
    },
    styles: ['solid'],
    unicode: 'f7a0',
  },
  glasses: {
    changes: ['5.0.13'],
    label: 'Glasses',
    search: {
      terms: ['hipster', 'nerd', 'reading', 'sight', 'spectacles', 'vision'],
    },
    styles: ['solid'],
    unicode: 'f530',
    voted: true,
  },
  glide: {
    changes: ['4.6', '5.0.0'],
    label: 'Glide',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f2a5',
    voted: false,
  },
  'glide-g': {
    changes: ['4.6', '5.0.0'],
    label: 'Glide G',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f2a6',
    voted: false,
  },
  globe: {
    changes: ['2', '5.0.0', '5.0.9', '5.11.0', '5.11.1'],
    label: 'Globe',
    search: {
      terms: [
        'all',
        'coordinates',
        'country',
        'earth',
        'global',
        'gps',
        'language',
        'localize',
        'location',
        'map',
        'online',
        'place',
        'planet',
        'translate',
        'travel',
        'world',
      ],
    },
    styles: ['solid'],
    unicode: 'f0ac',
    voted: false,
  },
  'globe-africa': {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Globe with Africa shown',
    search: {
      terms: [
        'all',
        'country',
        'earth',
        'global',
        'gps',
        'language',
        'localize',
        'location',
        'map',
        'online',
        'place',
        'planet',
        'translate',
        'travel',
        'world',
      ],
    },
    styles: ['solid'],
    unicode: 'f57c',
    voted: false,
  },
  'globe-americas': {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Globe with Americas shown',
    search: {
      terms: [
        'all',
        'country',
        'earth',
        'global',
        'gps',
        'language',
        'localize',
        'location',
        'map',
        'online',
        'place',
        'planet',
        'translate',
        'travel',
        'world',
      ],
    },
    styles: ['solid'],
    unicode: 'f57d',
    voted: false,
  },
  'globe-asia': {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Globe with Asia shown',
    search: {
      terms: [
        'all',
        'country',
        'earth',
        'global',
        'gps',
        'language',
        'localize',
        'location',
        'map',
        'online',
        'place',
        'planet',
        'translate',
        'travel',
        'world',
      ],
    },
    styles: ['solid'],
    unicode: 'f57e',
    voted: false,
  },
  'globe-europe': {
    changes: ['5.6.0', '5.11.0', '5.11.1'],
    label: 'Globe with Europe shown',
    search: {
      terms: [
        'all',
        'country',
        'earth',
        'global',
        'gps',
        'language',
        'localize',
        'location',
        'map',
        'online',
        'place',
        'planet',
        'translate',
        'travel',
        'world',
      ],
    },
    styles: ['solid'],
    unicode: 'f7a2',
    voted: true,
  },
  gofore: {
    changes: ['5.0.0'],
    label: 'Gofore',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3a7',
    voted: false,
  },
  'golf-ball': {
    changes: ['5.0.5'],
    label: 'Golf Ball',
    search: {
      terms: ['caddy', 'eagle', 'putt', 'tee'],
    },
    styles: ['solid'],
    unicode: 'f450',
    voted: false,
  },
  goodreads: {
    changes: ['5.0.0'],
    label: 'Goodreads',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3a8',
    voted: false,
  },
  'goodreads-g': {
    changes: ['5.0.0'],
    label: 'Goodreads G',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3a9',
    voted: false,
  },
  google: {
    changes: ['4.1', '5.0.0'],
    label: 'Google Logo',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1a0',
    voted: false,
  },
  'google-drive': {
    changes: ['5.0.0'],
    label: 'Google Drive',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3aa',
    voted: false,
  },
  'google-pay': {
    changes: ['5.13.1'],
    label: 'Google Pay',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f979',
    voted: false,
  },
  'google-play': {
    changes: ['5.0.0'],
    label: 'Google Play',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3ab',
    voted: false,
  },
  'google-plus': {
    changes: ['4.6', '5.0.0', '5.13.1'],
    label: 'Google Plus',
    search: {
      terms: ['google-plus-circle', 'google-plus-official'],
    },
    styles: ['brands'],
    unicode: 'f2b3',
    voted: false,
  },
  'google-plus-g': {
    changes: ['2', '5.0.0'],
    label: 'Google Plus G',
    search: {
      terms: ['google-plus', 'social network'],
    },
    styles: ['brands'],
    unicode: 'f0d5',
    voted: false,
  },
  'google-plus-square': {
    changes: ['2', '5.0.0'],
    label: 'Google Plus Square',
    search: {
      terms: ['social network'],
    },
    styles: ['brands'],
    unicode: 'f0d4',
    voted: false,
  },
  'google-wallet': {
    changes: ['4.2', '5.0.0'],
    label: 'Google Wallet',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1ee',
    voted: false,
  },
  gopuram: {
    changes: ['5.3.0', '5.7.0', '5.11.0'],
    label: 'Gopuram',
    search: {
      terms: ['building', 'entrance', 'hinduism', 'temple', 'tower'],
    },
    styles: ['solid'],
    unicode: 'f664',
    voted: false,
  },
  'graduation-cap': {
    changes: ['4.1', '5.0.0', '5.2.0', '5.10.1'],
    label: 'Graduation Cap',
    search: {
      terms: [
        'ceremony',
        'college',
        'graduate',
        'learning',
        'school',
        'student',
      ],
    },
    styles: ['solid'],
    unicode: 'f19d',
    voted: false,
  },
  gratipay: {
    changes: ['3.2', '5.0.0'],
    label: 'Gratipay (Gittip)',
    search: {
      terms: ['favorite', 'heart', 'like', 'love'],
    },
    styles: ['brands'],
    unicode: 'f184',
    voted: false,
  },
  grav: {
    changes: ['4.7', '5.0.0'],
    label: 'Grav',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f2d6',
    voted: false,
  },
  'greater-than': {
    changes: ['5.0.13'],
    label: 'Greater Than',
    search: {
      terms: ['arithmetic', 'compare', 'math'],
    },
    styles: ['solid'],
    unicode: 'f531',
    voted: true,
  },
  'greater-than-equal': {
    changes: ['5.0.13'],
    label: 'Greater Than Equal To',
    search: {
      terms: ['arithmetic', 'compare', 'math'],
    },
    styles: ['solid'],
    unicode: 'f532',
    voted: true,
  },
  grimace: {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Grimacing Face',
    search: {
      terms: ['cringe', 'emoticon', 'face', 'teeth'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f57f',
    voted: false,
  },
  grin: {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Grinning Face',
    search: {
      terms: ['emoticon', 'face', 'laugh', 'smile'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f580',
    voted: false,
  },
  'grin-alt': {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Alternate Grinning Face',
    search: {
      terms: ['emoticon', 'face', 'laugh', 'smile'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f581',
    voted: false,
  },
  'grin-beam': {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Grinning Face With Smiling Eyes',
    search: {
      terms: ['emoticon', 'face', 'laugh', 'smile'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f582',
    voted: false,
  },
  'grin-beam-sweat': {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Grinning Face With Sweat',
    search: {
      terms: ['embarass', 'emoticon', 'face', 'smile'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f583',
    voted: false,
  },
  'grin-hearts': {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Smiling Face With Heart-Eyes',
    search: {
      terms: ['emoticon', 'face', 'love', 'smile'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f584',
    voted: false,
  },
  'grin-squint': {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Grinning Squinting Face',
    search: {
      terms: ['emoticon', 'face', 'laugh', 'smile'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f585',
    voted: false,
  },
  'grin-squint-tears': {
    changes: ['5.1.0'],
    label: 'Rolling on the Floor Laughing',
    search: {
      terms: ['emoticon', 'face', 'happy', 'smile'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f586',
    voted: false,
  },
  'grin-stars': {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Star-Struck',
    search: {
      terms: ['emoticon', 'face', 'star-struck'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f587',
    voted: false,
  },
  'grin-tears': {
    changes: ['5.1.0'],
    label: 'Face With Tears of Joy',
    search: {
      terms: ['LOL', 'emoticon', 'face'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f588',
    voted: false,
  },
  'grin-tongue': {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Face With Tongue',
    search: {
      terms: ['LOL', 'emoticon', 'face'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f589',
    voted: false,
  },
  'grin-tongue-squint': {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Squinting Face With Tongue',
    search: {
      terms: ['LOL', 'emoticon', 'face'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f58a',
    voted: false,
  },
  'grin-tongue-wink': {
    changes: ['5.1.0', '5.11.0', '5.11.1', '5.12.0'],
    label: 'Winking Face With Tongue',
    search: {
      terms: ['LOL', 'emoticon', 'face'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f58b',
    voted: false,
  },
  'grin-wink': {
    changes: ['5.1.0', '5.1.1', '5.11.0', '5.11.1'],
    label: 'Grinning Winking Face',
    search: {
      terms: ['emoticon', 'face', 'flirt', 'laugh', 'smile'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f58c',
    voted: false,
  },
  'grip-horizontal': {
    changes: ['5.1.0'],
    label: 'Grip Horizontal',
    search: {
      terms: ['affordance', 'drag', 'drop', 'grab', 'handle'],
    },
    styles: ['solid'],
    unicode: 'f58d',
    voted: true,
  },
  'grip-lines': {
    changes: ['5.6.0'],
    label: 'Grip Lines',
    search: {
      terms: ['affordance', 'drag', 'drop', 'grab', 'handle'],
    },
    styles: ['solid'],
    unicode: 'f7a4',
    voted: true,
  },
  'grip-lines-vertical': {
    changes: ['5.6.0', '5.11.0', '5.11.1'],
    label: 'Grip Lines Vertical',
    search: {
      terms: ['affordance', 'drag', 'drop', 'grab', 'handle'],
    },
    styles: ['solid'],
    unicode: 'f7a5',
    voted: true,
  },
  'grip-vertical': {
    changes: ['5.1.0'],
    label: 'Grip Vertical',
    search: {
      terms: ['affordance', 'drag', 'drop', 'grab', 'handle'],
    },
    styles: ['solid'],
    unicode: 'f58e',
    voted: true,
  },
  gripfire: {
    changes: ['5.0.0'],
    label: 'Gripfire, Inc.',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3ac',
    voted: false,
  },
  grunt: {
    changes: ['5.0.0'],
    label: 'Grunt',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3ad',
    voted: false,
  },
  guitar: {
    changes: ['5.6.0', '5.11.0'],
    label: 'Guitar',
    search: {
      terms: [
        'acoustic',
        'instrument',
        'music',
        'rock',
        'rock and roll',
        'song',
        'strings',
      ],
    },
    styles: ['solid'],
    unicode: 'f7a6',
    voted: true,
  },
  gulp: {
    changes: ['5.0.0'],
    label: 'Gulp',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3ae',
    voted: false,
  },
  'h-square': {
    changes: ['3', '5.0.0'],
    label: 'H Square',
    search: {
      terms: ['directions', 'emergency', 'hospital', 'hotel', 'map'],
    },
    styles: ['solid'],
    unicode: 'f0fd',
    voted: false,
  },
  'hacker-news': {
    changes: ['4.1', '5.0.0'],
    label: 'Hacker News',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1d4',
    voted: false,
  },
  'hacker-news-square': {
    changes: ['5.0.0'],
    label: 'Hacker News Square',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3af',
    voted: false,
  },
  hackerrank: {
    changes: ['5.2.0', '5.8.0'],
    label: 'Hackerrank',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f5f7',
    voted: true,
  },
  hamburger: {
    changes: ['5.7.0'],
    label: 'Hamburger',
    search: {
      terms: [
        'bacon',
        'beef',
        'burger',
        'burger king',
        'cheeseburger',
        'fast food',
        'grill',
        'ground beef',
        'mcdonalds',
        'sandwich',
      ],
    },
    styles: ['solid'],
    unicode: 'f805',
    voted: false,
  },
  hammer: {
    changes: ['5.4.0'],
    label: 'Hammer',
    search: {
      terms: ['admin', 'fix', 'repair', 'settings', 'tool'],
    },
    styles: ['solid'],
    unicode: 'f6e3',
    voted: true,
  },
  hamsa: {
    changes: ['5.3.0'],
    label: 'Hamsa',
    search: {
      terms: [
        'amulet',
        'christianity',
        'islam',
        'jewish',
        'judaism',
        'muslim',
        'protection',
      ],
    },
    styles: ['solid'],
    unicode: 'f665',
    voted: false,
  },
  'hand-holding': {
    changes: ['5.0.9'],
    label: 'Hand Holding',
    search: {
      terms: ['carry', 'lift'],
    },
    styles: ['solid'],
    unicode: 'f4bd',
    voted: false,
  },
  'hand-holding-heart': {
    changes: ['5.0.9'],
    label: 'Hand Holding Heart',
    search: {
      terms: ['carry', 'charity', 'gift', 'lift', 'package'],
    },
    styles: ['solid'],
    unicode: 'f4be',
    voted: false,
  },
  'hand-holding-medical': {
    changes: ['5.13.0'],
    label: 'Hand Holding Medical Cross',
    search: {
      terms: ['care', 'covid-19', 'donate', 'help'],
    },
    styles: ['solid'],
    unicode: 'f95c',
    voted: false,
  },
  'hand-holding-usd': {
    changes: ['5.0.9', '5.11.0'],
    label: 'Hand Holding US Dollar',
    search: {
      terms: [
        '$',
        'carry',
        'dollar sign',
        'donation',
        'giving',
        'lift',
        'money',
        'price',
      ],
    },
    styles: ['solid'],
    unicode: 'f4c0',
    voted: false,
  },
  'hand-holding-water': {
    changes: ['5.0.9', '5.13.0'],
    label: 'Hand Holding Water',
    search: {
      terms: ['carry', 'covid-19', 'drought', 'grow', 'lift'],
    },
    styles: ['solid'],
    unicode: 'f4c1',
    voted: false,
  },
  'hand-lizard': {
    changes: ['4.4', '5.0.0'],
    label: 'Lizard (Hand)',
    search: {
      terms: ['game', 'roshambo'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f258',
    voted: false,
  },
  'hand-middle-finger': {
    changes: ['5.7.0', '5.11.0', '5.11.1'],
    label: 'Hand with Middle Finger Raised',
    search: {
      terms: ['flip the bird', 'gesture', 'hate', 'rude'],
    },
    styles: ['solid'],
    unicode: 'f806',
    voted: true,
  },
  'hand-paper': {
    changes: ['4.4', '5.0.0'],
    label: 'Paper (Hand)',
    search: {
      terms: ['game', 'halt', 'roshambo', 'stop'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f256',
    voted: false,
  },
  'hand-peace': {
    changes: ['4.4', '5.0.0'],
    label: 'Peace (Hand)',
    search: {
      terms: ['rest', 'truce'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f25b',
    voted: false,
  },
  'hand-point-down': {
    changes: ['2', '5.0.0'],
    label: 'Hand Pointing Down',
    search: {
      terms: ['finger', 'hand-o-down', 'point'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f0a7',
    voted: false,
  },
  'hand-point-left': {
    changes: ['2', '5.0.0'],
    label: 'Hand Pointing Left',
    search: {
      terms: ['back', 'finger', 'hand-o-left', 'left', 'point', 'previous'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f0a5',
    voted: false,
  },
  'hand-point-right': {
    changes: ['2', '5.0.0'],
    label: 'Hand Pointing Right',
    search: {
      terms: ['finger', 'forward', 'hand-o-right', 'next', 'point', 'right'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f0a4',
    voted: false,
  },
  'hand-point-up': {
    changes: ['2', '5.0.0'],
    label: 'Hand Pointing Up',
    search: {
      terms: ['finger', 'hand-o-up', 'point'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f0a6',
    voted: false,
  },
  'hand-pointer': {
    changes: ['4.4', '5.0.0'],
    label: 'Pointer (Hand)',
    search: {
      terms: ['arrow', 'cursor', 'select'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f25a',
    voted: false,
  },
  'hand-rock': {
    changes: ['4.4', '5.0.0'],
    label: 'Rock (Hand)',
    search: {
      terms: ['fist', 'game', 'roshambo'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f255',
    voted: false,
  },
  'hand-scissors': {
    changes: ['4.4', '5.0.0'],
    label: 'Scissors (Hand)',
    search: {
      terms: ['cut', 'game', 'roshambo'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f257',
    voted: false,
  },
  'hand-sparkles': {
    changes: ['5.13.0'],
    label: 'Hand Sparkles',
    search: {
      terms: ['clean', 'covid-19', 'hygiene', 'magic', 'soap', 'wash'],
    },
    styles: ['solid'],
    unicode: 'f95d',
    voted: false,
  },
  'hand-spock': {
    changes: ['4.4', '5.0.0', '5.12.0'],
    label: 'Spock (Hand)',
    search: {
      terms: ['live long', 'prosper', 'salute', 'star trek', 'vulcan'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f259',
    voted: false,
  },
  hands: {
    changes: ['5.0.9'],
    label: 'Hands',
    search: {
      terms: ['carry', 'hold', 'lift'],
    },
    styles: ['solid'],
    unicode: 'f4c2',
    voted: false,
  },
  'hands-helping': {
    changes: ['5.0.9'],
    label: 'Helping Hands',
    search: {
      terms: ['aid', 'assistance', 'handshake', 'partnership', 'volunteering'],
    },
    styles: ['solid'],
    unicode: 'f4c4',
    voted: false,
  },
  'hands-wash': {
    changes: ['5.13.0'],
    label: 'Hands Wash',
    search: {
      terms: ['covid-19', 'hygiene', 'soap', 'wash'],
    },
    styles: ['solid'],
    unicode: 'f95e',
    voted: false,
  },
  handshake: {
    changes: ['4.7', '5.0.0', '5.0.9'],
    label: 'Handshake',
    search: {
      terms: ['agreement', 'greeting', 'meeting', 'partnership'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f2b5',
    voted: false,
  },
  'handshake-alt-slash': {
    changes: ['5.13.0'],
    label: 'Handshake Alternate Slash',
    search: {
      terms: ['broken', 'covid-19', 'social distance'],
    },
    styles: ['solid'],
    unicode: 'f95f',
    voted: false,
  },
  'handshake-slash': {
    changes: ['5.13.0'],
    label: 'Handshake Slash',
    search: {
      terms: ['broken', 'covid-19', 'social distance'],
    },
    styles: ['solid'],
    unicode: 'f960',
    voted: false,
  },
  hanukiah: {
    changes: ['5.4.0'],
    label: 'Hanukiah',
    search: {
      terms: ['candle', 'hanukkah', 'jewish', 'judaism', 'light'],
    },
    styles: ['solid'],
    unicode: 'f6e6',
    voted: false,
  },
  'hard-hat': {
    changes: ['5.7.0'],
    label: 'Hard Hat',
    search: {
      terms: ['construction', 'hardhat', 'helmet', 'safety'],
    },
    styles: ['solid'],
    unicode: 'f807',
    voted: true,
  },
  hashtag: {
    changes: ['4.5', '5.0.0'],
    label: 'Hashtag',
    search: {
      terms: ['Twitter', 'instagram', 'pound', 'social media', 'tag'],
    },
    styles: ['solid'],
    unicode: 'f292',
    voted: false,
  },
  'hat-cowboy': {
    changes: ['5.11.0'],
    label: 'Cowboy Hat',
    search: {
      terms: [
        'buckaroo',
        'horse',
        'jackeroo',
        'john b.',
        'old west',
        'pardner',
        'ranch',
        'rancher',
        'rodeo',
        'western',
        'wrangler',
      ],
    },
    styles: ['solid'],
    unicode: 'f8c0',
    voted: false,
  },
  'hat-cowboy-side': {
    changes: ['5.11.0'],
    label: 'Cowboy Hat Side',
    search: {
      terms: [
        'buckaroo',
        'horse',
        'jackeroo',
        'john b.',
        'old west',
        'pardner',
        'ranch',
        'rancher',
        'rodeo',
        'western',
        'wrangler',
      ],
    },
    styles: ['solid'],
    unicode: 'f8c1',
    voted: false,
  },
  'hat-wizard': {
    changes: ['5.4.0', '5.11.0'],
    label: "Wizard's Hat",
    search: {
      terms: [
        'Dungeons & Dragons',
        'accessory',
        'buckle',
        'clothing',
        'd&d',
        'dnd',
        'fantasy',
        'halloween',
        'head',
        'holiday',
        'mage',
        'magic',
        'pointy',
        'witch',
      ],
    },
    styles: ['solid'],
    unicode: 'f6e8',
    voted: false,
  },
  hdd: {
    changes: ['2', '5.0.0', '5.10.1'],
    label: 'HDD',
    search: {
      terms: ['cpu', 'hard drive', 'harddrive', 'machine', 'save', 'storage'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f0a0',
    voted: false,
  },
  'head-side-cough': {
    changes: ['5.13.0'],
    label: 'Head Side Cough',
    search: {
      terms: ['cough', 'covid-19', 'germs', 'lungs', 'respiratory', 'sick'],
    },
    styles: ['solid'],
    unicode: 'f961',
    voted: false,
  },
  'head-side-cough-slash': {
    changes: ['5.13.0'],
    label: 'Head Side-cough-slash',
    search: {
      terms: ['cough', 'covid-19', 'germs', 'lungs', 'respiratory', 'sick'],
    },
    styles: ['solid'],
    unicode: 'f962',
    voted: false,
  },
  'head-side-mask': {
    changes: ['5.13.0'],
    label: 'Head Side Mask',
    search: {
      terms: ['breath', 'covid-19', 'filter', 'respirator', 'virus'],
    },
    styles: ['solid'],
    unicode: 'f963',
    voted: false,
  },
  'head-side-virus': {
    changes: ['5.13.0'],
    label: 'Head Side Virus',
    search: {
      terms: ['cold', 'covid-19', 'flu', 'sick'],
    },
    styles: ['solid'],
    unicode: 'f964',
    voted: false,
  },
  heading: {
    changes: ['4.1', '5.0.0', '5.9.0', '5.10.1', '5.10.2'],
    label: 'heading',
    search: {
      terms: ['format', 'header', 'text', 'title'],
    },
    styles: ['solid'],
    unicode: 'f1dc',
    voted: false,
  },
  headphones: {
    changes: ['1', '5.0.0'],
    label: 'headphones',
    search: {
      terms: ['audio', 'listen', 'music', 'sound', 'speaker'],
    },
    styles: ['solid'],
    unicode: 'f025',
    voted: false,
  },
  'headphones-alt': {
    changes: ['5.1.0'],
    label: 'Alternate Headphones',
    search: {
      terms: ['audio', 'listen', 'music', 'sound', 'speaker'],
    },
    styles: ['solid'],
    unicode: 'f58f',
    voted: true,
  },
  headset: {
    changes: ['5.1.0'],
    label: 'Headset',
    search: {
      terms: [
        'audio',
        'gamer',
        'gaming',
        'listen',
        'live chat',
        'microphone',
        'shot caller',
        'sound',
        'support',
        'telemarketer',
      ],
    },
    styles: ['solid'],
    unicode: 'f590',
    voted: true,
  },
  heart: {
    changes: ['1', '5.0.0', '5.0.9', '5.10.1', '5.10.2'],
    label: 'Heart',
    search: {
      terms: ['favorite', 'like', 'love', 'relationship', 'valentine'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f004',
    voted: false,
  },
  'heart-broken': {
    changes: ['5.6.0', '5.10.2'],
    label: 'Heart Broken',
    search: {
      terms: [
        'breakup',
        'crushed',
        'dislike',
        'dumped',
        'grief',
        'love',
        'lovesick',
        'relationship',
        'sad',
      ],
    },
    styles: ['solid'],
    unicode: 'f7a9',
    voted: true,
  },
  heartbeat: {
    changes: ['4.3', '5.0.0', '5.0.7'],
    label: 'Heartbeat',
    search: {
      terms: ['ekg', 'electrocardiogram', 'health', 'lifeline', 'vital signs'],
    },
    styles: ['solid'],
    unicode: 'f21e',
    voted: false,
  },
  helicopter: {
    changes: ['5.0.13'],
    label: 'Helicopter',
    search: {
      terms: ['airwolf', 'apache', 'chopper', 'flight', 'fly', 'travel'],
    },
    styles: ['solid'],
    unicode: 'f533',
    voted: true,
  },
  highlighter: {
    changes: ['5.1.0', '5.10.1'],
    label: 'Highlighter',
    search: {
      terms: ['edit', 'marker', 'sharpie', 'update', 'write'],
    },
    styles: ['solid'],
    unicode: 'f591',
    voted: true,
  },
  hiking: {
    changes: ['5.4.0'],
    label: 'Hiking',
    search: {
      terms: [
        'activity',
        'backpack',
        'fall',
        'fitness',
        'outdoors',
        'person',
        'seasonal',
        'walking',
      ],
    },
    styles: ['solid'],
    unicode: 'f6ec',
    voted: false,
  },
  hippo: {
    changes: ['5.4.0', '5.10.1'],
    label: 'Hippo',
    search: {
      terms: ['animal', 'fauna', 'hippopotamus', 'hungry', 'mammal'],
    },
    styles: ['solid'],
    unicode: 'f6ed',
    voted: false,
  },
  hips: {
    changes: ['5.0.5'],
    label: 'Hips',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f452',
    voted: false,
  },
  'hire-a-helper': {
    changes: ['5.0.0'],
    label: 'HireAHelper',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3b0',
    voted: false,
  },
  history: {
    changes: ['4.1', '5.0.0'],
    label: 'History',
    search: {
      terms: ['Rewind', 'clock', 'reverse', 'time', 'time machine'],
    },
    styles: ['solid'],
    unicode: 'f1da',
    voted: false,
  },
  'hockey-puck': {
    changes: ['5.0.5'],
    label: 'Hockey Puck',
    search: {
      terms: ['ice', 'nhl', 'sport'],
    },
    styles: ['solid'],
    unicode: 'f453',
    voted: false,
  },
  'holly-berry': {
    changes: ['5.6.0'],
    label: 'Holly Berry',
    search: {
      terms: [
        'catwoman',
        'christmas',
        'decoration',
        'flora',
        'halle',
        'holiday',
        'ororo munroe',
        'plant',
        'storm',
        'xmas',
      ],
    },
    styles: ['solid'],
    unicode: 'f7aa',
  },
  home: {
    changes: ['1', '5.0.0', '5.7.0'],
    label: 'home',
    search: {
      terms: ['abode', 'building', 'house', 'main'],
    },
    styles: ['solid'],
    unicode: 'f015',
    voted: false,
  },
  hooli: {
    changes: ['5.0.0', '5.7.0'],
    label: 'Hooli',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f427',
    voted: false,
  },
  hornbill: {
    changes: ['5.1.0', '5.8.0'],
    label: 'Hornbill',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f592',
    voted: false,
  },
  horse: {
    changes: ['5.4.0', '5.10.1'],
    label: 'Horse',
    search: {
      terms: ['equus', 'fauna', 'mammmal', 'mare', 'neigh', 'pony'],
    },
    styles: ['solid'],
    unicode: 'f6f0',
    voted: false,
  },
  'horse-head': {
    changes: ['5.6.0', '5.10.1'],
    label: 'Horse Head',
    search: {
      terms: ['equus', 'fauna', 'mammmal', 'mare', 'neigh', 'pony'],
    },
    styles: ['solid'],
    unicode: 'f7ab',
  },
  hospital: {
    changes: ['3', '5.0.0'],
    label: 'hospital',
    search: {
      terms: ['building', 'covid-19', 'emergency room', 'medical center'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f0f8',
    voted: false,
  },
  'hospital-alt': {
    changes: ['5.0.7'],
    label: 'Alternate Hospital',
    search: {
      terms: ['building', 'covid-19', 'emergency room', 'medical center'],
    },
    styles: ['solid'],
    unicode: 'f47d',
    voted: false,
  },
  'hospital-symbol': {
    changes: ['5.0.7'],
    label: 'Hospital Symbol',
    search: {
      terms: ['clinic', 'covid-19', 'emergency', 'map'],
    },
    styles: ['solid'],
    unicode: 'f47e',
    voted: false,
  },
  'hospital-user': {
    changes: ['5.7.0'],
    label: 'Hospital with User',
    search: {
      terms: ['covid-19', 'doctor', 'network', 'patient', 'primary care'],
    },
    styles: ['solid'],
    unicode: 'f80d',
    voted: false,
  },
  'hot-tub': {
    changes: ['5.1.0'],
    label: 'Hot Tub',
    search: {
      terms: ['bath', 'jacuzzi', 'massage', 'sauna', 'spa'],
    },
    styles: ['solid'],
    unicode: 'f593',
    voted: false,
  },
  hotdog: {
    changes: ['5.7.0'],
    label: 'Hot Dog',
    search: {
      terms: [
        'bun',
        'chili',
        'frankfurt',
        'frankfurter',
        'kosher',
        'polish',
        'sandwich',
        'sausage',
        'vienna',
        'weiner',
      ],
    },
    styles: ['solid'],
    unicode: 'f80f',
    voted: false,
  },
  hotel: {
    changes: ['5.1.0'],
    label: 'Hotel',
    search: {
      terms: ['building', 'inn', 'lodging', 'motel', 'resort', 'travel'],
    },
    styles: ['solid'],
    unicode: 'f594',
    voted: false,
  },
  hotjar: {
    changes: ['5.0.0'],
    label: 'Hotjar',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3b1',
    voted: false,
  },
  hourglass: {
    changes: ['4.4', '5.0.0', '5.10.1'],
    label: 'Hourglass',
    search: {
      terms: ['hour', 'minute', 'sand', 'stopwatch', 'time'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f254',
    voted: false,
  },
  'hourglass-end': {
    changes: ['4.4', '5.0.0', '5.10.1'],
    label: 'Hourglass End',
    search: {
      terms: ['hour', 'minute', 'sand', 'stopwatch', 'time'],
    },
    styles: ['solid'],
    unicode: 'f253',
    voted: false,
  },
  'hourglass-half': {
    changes: ['4.4', '5.0.0', '5.10.1'],
    label: 'Hourglass Half',
    search: {
      terms: ['hour', 'minute', 'sand', 'stopwatch', 'time'],
    },
    styles: ['solid'],
    unicode: 'f252',
    voted: false,
  },
  'hourglass-start': {
    changes: ['4.4', '5.0.0', '5.10.1'],
    label: 'Hourglass Start',
    search: {
      terms: ['hour', 'minute', 'sand', 'stopwatch', 'time'],
    },
    styles: ['solid'],
    unicode: 'f251',
    voted: false,
  },
  'house-damage': {
    changes: ['5.4.0'],
    label: 'Damaged House',
    search: {
      terms: ['building', 'devastation', 'disaster', 'home', 'insurance'],
    },
    styles: ['solid'],
    unicode: 'f6f1',
    voted: false,
  },
  'house-user': {
    changes: ['5.13.0'],
    label: 'House User',
    search: {
      terms: ['covid-19', 'home', 'isolation', 'quarantine'],
    },
    styles: ['solid'],
    unicode: 'f965',
    voted: false,
  },
  houzz: {
    changes: ['4.4', '5.0.0', '5.0.9', '5.8.0'],
    label: 'Houzz',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f27c',
    voted: false,
  },
  hryvnia: {
    changes: ['5.4.0'],
    label: 'Hryvnia',
    search: {
      terms: ['currency', 'money', 'ukraine', 'ukrainian'],
    },
    styles: ['solid'],
    unicode: 'f6f2',
    voted: true,
  },
  html5: {
    changes: ['3.1', '5.0.0'],
    label: 'HTML 5 Logo',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f13b',
    voted: false,
  },
  hubspot: {
    changes: ['5.0.0'],
    label: 'HubSpot',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3b2',
    voted: false,
  },
  'i-cursor': {
    changes: ['4.4', '5.0.0'],
    label: 'I Beam Cursor',
    search: {
      terms: ['editing', 'i-beam', 'type', 'writing'],
    },
    styles: ['solid'],
    unicode: 'f246',
    voted: false,
  },
  'ice-cream': {
    changes: ['5.7.0', '5.11.0', '5.11.1'],
    label: 'Ice Cream',
    search: {
      terms: [
        'chocolate',
        'cone',
        'dessert',
        'frozen',
        'scoop',
        'sorbet',
        'vanilla',
        'yogurt',
      ],
    },
    styles: ['solid'],
    unicode: 'f810',
    voted: false,
  },
  icicles: {
    changes: ['5.6.0'],
    label: 'Icicles',
    search: {
      terms: ['cold', 'frozen', 'hanging', 'ice', 'seasonal', 'sharp'],
    },
    styles: ['solid'],
    unicode: 'f7ad',
  },
  icons: {
    changes: ['5.9.0'],
    label: 'Icons',
    search: {
      terms: ['bolt', 'emoji', 'heart', 'image', 'music', 'photo', 'symbols'],
    },
    styles: ['solid'],
    unicode: 'f86d',
    voted: false,
  },
  'id-badge': {
    changes: ['4.7', '5.0.0', '5.0.3'],
    label: 'Identification Badge',
    search: {
      terms: ['address', 'contact', 'identification', 'license', 'profile'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f2c1',
    voted: false,
  },
  'id-card': {
    changes: ['4.7', '5.0.0', '5.0.3', '5.8.0', '5.10.1'],
    label: 'Identification Card',
    search: {
      terms: [
        'contact',
        'demographics',
        'document',
        'identification',
        'issued',
        'profile',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f2c2',
    voted: false,
  },
  'id-card-alt': {
    changes: ['5.0.7', '5.10.1'],
    label: 'Alternate Identification Card',
    search: {
      terms: [
        'contact',
        'demographics',
        'document',
        'identification',
        'issued',
        'profile',
      ],
    },
    styles: ['solid'],
    unicode: 'f47f',
    voted: false,
  },
  ideal: {
    changes: ['5.12.0'],
    label: 'iDeal',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f913',
    voted: true,
  },
  igloo: {
    changes: ['5.6.0'],
    label: 'Igloo',
    search: {
      terms: ['dome', 'dwelling', 'eskimo', 'home', 'house', 'ice', 'snow'],
    },
    styles: ['solid'],
    unicode: 'f7ae',
  },
  image: {
    changes: ['1', '5.0.0', '5.10.1'],
    label: 'Image',
    search: {
      terms: ['album', 'landscape', 'photo', 'picture'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f03e',
    voted: false,
  },
  images: {
    changes: ['1', '5.0.0', '5.10.1'],
    label: 'Images',
    search: {
      terms: ['album', 'landscape', 'photo', 'picture'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f302',
    voted: false,
  },
  imdb: {
    changes: ['4.7', '5.0.0'],
    label: 'IMDB',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f2d8',
    voted: false,
  },
  inbox: {
    changes: ['1', '5.0.0'],
    label: 'inbox',
    search: {
      terms: ['archive', 'desk', 'email', 'mail', 'message'],
    },
    styles: ['solid'],
    unicode: 'f01c',
    voted: false,
  },
  indent: {
    changes: ['1', '5.0.0', '5.9.0'],
    label: 'Indent',
    search: {
      terms: ['align', 'justify', 'paragraph', 'tab'],
    },
    styles: ['solid'],
    unicode: 'f03c',
    voted: false,
  },
  industry: {
    changes: ['4.4', '5.0.0', '5.10.1'],
    label: 'Industry',
    search: {
      terms: [
        'building',
        'factory',
        'industrial',
        'manufacturing',
        'mill',
        'warehouse',
      ],
    },
    styles: ['solid'],
    unicode: 'f275',
    voted: false,
  },
  infinity: {
    changes: ['5.0.13', '5.3.0', '5.10.1'],
    label: 'Infinity',
    search: {
      terms: ['eternity', 'forever', 'math'],
    },
    styles: ['solid'],
    unicode: 'f534',
    voted: true,
  },
  info: {
    changes: ['3.1', '5.0.0', '5.10.1', '5.10.2'],
    label: 'Info',
    search: {
      terms: ['details', 'help', 'information', 'more', 'support'],
    },
    styles: ['solid'],
    unicode: 'f129',
    voted: false,
  },
  'info-circle': {
    changes: ['1', '5.0.0'],
    label: 'Info Circle',
    search: {
      terms: ['details', 'help', 'information', 'more', 'support'],
    },
    styles: ['solid'],
    unicode: 'f05a',
    voted: false,
  },
  instagram: {
    changes: ['4.6', '5.0.0'],
    label: 'Instagram',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f16d',
    voted: false,
  },
  'instagram-square': {
    changes: ['5.12.1'],
    label: 'Instagram Square',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f955',
    voted: true,
  },
  intercom: {
    changes: ['5.6.0'],
    label: 'Intercom',
    search: {
      terms: ['app', 'customer', 'messenger'],
    },
    styles: ['brands'],
    unicode: 'f7af',
    voted: false,
  },
  'internet-explorer': {
    changes: ['4.4', '5.0.0'],
    label: 'Internet-explorer',
    search: {
      terms: ['browser', 'ie'],
    },
    styles: ['brands'],
    unicode: 'f26b',
    voted: false,
  },
  invision: {
    changes: ['5.6.0'],
    label: 'InVision',
    search: {
      terms: ['app', 'design', 'interface'],
    },
    styles: ['brands'],
    unicode: 'f7b0',
    voted: false,
  },
  ioxhost: {
    changes: ['4.2', '5.0.0'],
    label: 'ioxhost',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f208',
    voted: false,
  },
  italic: {
    changes: ['1', '5.0.0', '5.9.0', '5.10.1', '5.10.2'],
    label: 'italic',
    search: {
      terms: ['edit', 'emphasis', 'font', 'format', 'text', 'type'],
    },
    styles: ['solid'],
    unicode: 'f033',
    voted: false,
  },
  'itch-io': {
    changes: ['5.8.0'],
    label: 'itch.io',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f83a',
  },
  itunes: {
    changes: ['5.0.0'],
    label: 'iTunes',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3b4',
    voted: false,
  },
  'itunes-note': {
    changes: ['5.0.0'],
    label: 'Itunes Note',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3b5',
    voted: false,
  },
  java: {
    changes: ['5.0.10', '5.7.0', '5.8.0'],
    label: 'Java',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f4e4',
    voted: false,
  },
  jedi: {
    changes: ['5.3.0'],
    label: 'Jedi',
    search: {
      terms: ['crest', 'force', 'sith', 'skywalker', 'star wars', 'yoda'],
    },
    styles: ['solid'],
    unicode: 'f669',
    voted: false,
  },
  'jedi-order': {
    changes: ['5.0.12', '5.7.0'],
    label: 'Jedi Order',
    search: {
      terms: ['star wars'],
    },
    styles: ['brands'],
    unicode: 'f50e',
    voted: false,
  },
  jenkins: {
    changes: ['5.0.0'],
    label: 'Jenkis',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3b6',
    voted: false,
  },
  jira: {
    changes: ['5.6.0'],
    label: 'Jira',
    search: {
      terms: ['atlassian'],
    },
    styles: ['brands'],
    unicode: 'f7b1',
    voted: true,
  },
  joget: {
    changes: ['5.0.0'],
    label: 'Joget',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3b7',
    voted: false,
  },
  joint: {
    changes: ['5.1.0'],
    label: 'Joint',
    search: {
      terms: [
        'blunt',
        'cannabis',
        'doobie',
        'drugs',
        'marijuana',
        'roach',
        'smoke',
        'smoking',
        'spliff',
      ],
    },
    styles: ['solid'],
    unicode: 'f595',
    voted: false,
  },
  joomla: {
    changes: ['4.1', '5.0.0'],
    label: 'Joomla Logo',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1aa',
    voted: false,
  },
  'journal-whills': {
    changes: ['5.3.0', '5.11.0'],
    label: 'Journal of the Whills',
    search: {
      terms: ['book', 'force', 'jedi', 'sith', 'star wars', 'yoda'],
    },
    styles: ['solid'],
    unicode: 'f66a',
    voted: false,
  },
  js: {
    changes: ['5.0.0'],
    label: 'JavaScript (JS)',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3b8',
    voted: false,
  },
  'js-square': {
    changes: ['5.0.0', '5.0.3'],
    label: 'JavaScript (JS) Square',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3b9',
    voted: false,
  },
  jsfiddle: {
    changes: ['4.1', '5.0.0'],
    label: 'jsFiddle',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1cc',
    voted: false,
  },
  kaaba: {
    changes: ['5.3.0'],
    label: 'Kaaba',
    search: {
      terms: ['building', 'cube', 'islam', 'muslim'],
    },
    styles: ['solid'],
    unicode: 'f66b',
    voted: false,
  },
  kaggle: {
    changes: ['5.2.0', '5.8.0'],
    label: 'Kaggle',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f5fa',
    voted: true,
  },
  key: {
    changes: ['1', '5.0.0', '5.10.1'],
    label: 'key',
    search: {
      terms: ['lock', 'password', 'private', 'secret', 'unlock'],
    },
    styles: ['solid'],
    unicode: 'f084',
    voted: false,
  },
  keybase: {
    changes: ['5.0.11', '5.8.0', '5.10.2', '5.11.0'],
    label: 'Keybase',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f4f5',
    voted: true,
  },
  keyboard: {
    changes: ['3.1', '5.0.0'],
    label: 'Keyboard',
    search: {
      terms: ['accessory', 'edit', 'input', 'text', 'type', 'write'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f11c',
    voted: false,
  },
  keycdn: {
    changes: ['5.0.0'],
    label: 'KeyCDN',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3ba',
    voted: false,
  },
  khanda: {
    changes: ['5.3.0'],
    label: 'Khanda',
    search: {
      terms: ['chakkar', 'sikh', 'sikhism', 'sword'],
    },
    styles: ['solid'],
    unicode: 'f66d',
    voted: false,
  },
  kickstarter: {
    changes: ['5.0.0'],
    label: 'Kickstarter',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3bb',
    voted: false,
  },
  'kickstarter-k': {
    changes: ['5.0.0'],
    label: 'Kickstarter K',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3bc',
    voted: false,
  },
  kiss: {
    changes: ['5.1.0', '5.1.1', '5.11.0', '5.11.1'],
    label: 'Kissing Face',
    search: {
      terms: ['beso', 'emoticon', 'face', 'love', 'smooch'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f596',
    voted: false,
  },
  'kiss-beam': {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Kissing Face With Smiling Eyes',
    search: {
      terms: ['beso', 'emoticon', 'face', 'love', 'smooch'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f597',
    voted: false,
  },
  'kiss-wink-heart': {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Face Blowing a Kiss',
    search: {
      terms: ['beso', 'emoticon', 'face', 'love', 'smooch'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f598',
    voted: false,
  },
  'kiwi-bird': {
    changes: ['5.0.13'],
    label: 'Kiwi Bird',
    search: {
      terms: ['bird', 'fauna', 'new zealand'],
    },
    styles: ['solid'],
    unicode: 'f535',
    voted: false,
  },
  korvue: {
    changes: ['5.0.2'],
    label: 'KORVUE',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f42f',
    voted: false,
  },
  landmark: {
    changes: ['5.3.0'],
    label: 'Landmark',
    search: {
      terms: ['building', 'historic', 'memorable', 'monument', 'politics'],
    },
    styles: ['solid'],
    unicode: 'f66f',
    voted: false,
  },
  language: {
    changes: ['4.1', '5.0.0', '5.10.2'],
    label: 'Language',
    search: {
      terms: [
        'dialect',
        'idiom',
        'localize',
        'speech',
        'translate',
        'vernacular',
      ],
    },
    styles: ['solid'],
    unicode: 'f1ab',
    voted: false,
  },
  laptop: {
    changes: ['3', '5.0.0', '5.2.0'],
    label: 'Laptop',
    search: {
      terms: [
        'computer',
        'cpu',
        'dell',
        'demo',
        'device',
        'mac',
        'macbook',
        'machine',
        'pc',
      ],
    },
    styles: ['solid'],
    unicode: 'f109',
    voted: false,
  },
  'laptop-code': {
    changes: ['5.2.0'],
    label: 'Laptop Code',
    search: {
      terms: [
        'computer',
        'cpu',
        'dell',
        'demo',
        'develop',
        'device',
        'mac',
        'macbook',
        'machine',
        'pc',
      ],
    },
    styles: ['solid'],
    unicode: 'f5fc',
    voted: false,
  },
  'laptop-house': {
    changes: ['5.13.0'],
    label: 'Laptop House',
    search: {
      terms: [
        'computer',
        'covid-19',
        'device',
        'office',
        'remote',
        'work from home',
      ],
    },
    styles: ['solid'],
    unicode: 'f966',
    voted: false,
  },
  'laptop-medical': {
    changes: ['5.7.0'],
    label: 'Laptop Medical',
    search: {
      terms: [
        'computer',
        'device',
        'ehr',
        'electronic health records',
        'history',
      ],
    },
    styles: ['solid'],
    unicode: 'f812',
    voted: false,
  },
  laravel: {
    changes: ['5.0.0', '5.0.3', '5.11.2'],
    label: 'Laravel',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3bd',
    voted: false,
  },
  lastfm: {
    changes: ['4.2', '5.0.0'],
    label: 'last.fm',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f202',
    voted: false,
  },
  'lastfm-square': {
    changes: ['4.2', '5.0.0', '5.0.11'],
    label: 'last.fm Square',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f203',
    voted: false,
  },
  laugh: {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Grinning Face With Big Eyes',
    search: {
      terms: ['LOL', 'emoticon', 'face', 'laugh', 'smile'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f599',
    voted: false,
  },
  'laugh-beam': {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Laugh Face with Beaming Eyes',
    search: {
      terms: ['LOL', 'emoticon', 'face', 'happy', 'smile'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f59a',
    voted: false,
  },
  'laugh-squint': {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Laughing Squinting Face',
    search: {
      terms: ['LOL', 'emoticon', 'face', 'happy', 'smile'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f59b',
    voted: false,
  },
  'laugh-wink': {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Laughing Winking Face',
    search: {
      terms: ['LOL', 'emoticon', 'face', 'happy', 'smile'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f59c',
    voted: false,
  },
  'layer-group': {
    changes: ['5.2.0'],
    label: 'Layer Group',
    search: {
      terms: ['arrange', 'develop', 'layers', 'map', 'stack'],
    },
    styles: ['solid'],
    unicode: 'f5fd',
    voted: false,
  },
  leaf: {
    changes: ['1', '5.0.0', '5.0.9'],
    label: 'leaf',
    search: {
      terms: ['eco', 'flora', 'nature', 'plant', 'vegan'],
    },
    styles: ['solid'],
    unicode: 'f06c',
    voted: false,
  },
  leanpub: {
    changes: ['4.3', '5.0.0'],
    label: 'Leanpub',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f212',
    voted: false,
  },
  lemon: {
    changes: ['1', '5.0.0'],
    label: 'Lemon',
    search: {
      terms: ['citrus', 'lemonade', 'lime', 'tart'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f094',
    voted: false,
  },
  less: {
    changes: ['5.0.0'],
    label: 'Less',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f41d',
    voted: false,
  },
  'less-than': {
    changes: ['5.0.13'],
    label: 'Less Than',
    search: {
      terms: ['arithmetic', 'compare', 'math'],
    },
    styles: ['solid'],
    unicode: 'f536',
    voted: true,
  },
  'less-than-equal': {
    changes: ['5.0.13'],
    label: 'Less Than Equal To',
    search: {
      terms: ['arithmetic', 'compare', 'math'],
    },
    styles: ['solid'],
    unicode: 'f537',
    voted: true,
  },
  'level-down-alt': {
    changes: ['5.0.0'],
    label: 'Alternate Level Down',
    search: {
      terms: ['arrow', 'level-down'],
    },
    styles: ['solid'],
    unicode: 'f3be',
    voted: false,
  },
  'level-up-alt': {
    changes: ['5.0.0'],
    label: 'Alternate Level Up',
    search: {
      terms: ['arrow', 'level-up'],
    },
    styles: ['solid'],
    unicode: 'f3bf',
    voted: false,
  },
  'life-ring': {
    changes: ['4.1', '5.0.0'],
    label: 'Life Ring',
    search: {
      terms: ['coast guard', 'help', 'overboard', 'save', 'support'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f1cd',
    voted: false,
  },
  lightbulb: {
    changes: ['3', '5.0.0', '5.3.0'],
    label: 'Lightbulb',
    search: {
      terms: ['energy', 'idea', 'inspiration', 'light'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f0eb',
    voted: false,
  },
  line: {
    changes: ['5.0.0'],
    label: 'Line',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3c0',
    voted: false,
  },
  link: {
    changes: ['2', '5.0.0'],
    label: 'Link',
    search: {
      terms: ['attach', 'attachment', 'chain', 'connect'],
    },
    styles: ['solid'],
    unicode: 'f0c1',
    voted: false,
  },
  linkedin: {
    changes: ['1', '5.0.0'],
    label: 'LinkedIn',
    search: {
      terms: ['linkedin-square'],
    },
    styles: ['brands'],
    unicode: 'f08c',
    voted: false,
  },
  'linkedin-in': {
    changes: ['2', '5.0.0', '5.4.1', '5.8.0', '5.8.1'],
    label: 'LinkedIn In',
    search: {
      terms: ['linkedin'],
    },
    styles: ['brands'],
    unicode: 'f0e1',
    voted: false,
  },
  linode: {
    changes: ['4.7', '5.0.0'],
    label: 'Linode',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f2b8',
    voted: false,
  },
  linux: {
    changes: ['3.2', '5.0.0'],
    label: 'Linux',
    search: {
      terms: ['tux'],
    },
    styles: ['brands'],
    unicode: 'f17c',
    voted: false,
  },
  'lira-sign': {
    changes: ['4', '5.0.0'],
    label: 'Turkish Lira Sign',
    search: {
      terms: ['currency', 'money', 'try', 'turkish'],
    },
    styles: ['solid'],
    unicode: 'f195',
    voted: false,
  },
  list: {
    changes: ['1', '5.0.0', '5.9.0'],
    label: 'List',
    search: {
      terms: ['checklist', 'completed', 'done', 'finished', 'ol', 'todo', 'ul'],
    },
    styles: ['solid'],
    unicode: 'f03a',
    voted: false,
  },
  'list-alt': {
    changes: ['1', '5.0.0'],
    label: 'Alternate List',
    search: {
      terms: ['checklist', 'completed', 'done', 'finished', 'ol', 'todo', 'ul'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f022',
    voted: false,
  },
  'list-ol': {
    changes: ['2', '5.0.0', '5.9.0'],
    label: 'list-ol',
    search: {
      terms: [
        'checklist',
        'completed',
        'done',
        'finished',
        'numbers',
        'ol',
        'todo',
        'ul',
      ],
    },
    styles: ['solid'],
    unicode: 'f0cb',
    voted: false,
  },
  'list-ul': {
    changes: ['2', '5.0.0', '5.9.0'],
    label: 'list-ul',
    search: {
      terms: ['checklist', 'completed', 'done', 'finished', 'ol', 'todo', 'ul'],
    },
    styles: ['solid'],
    unicode: 'f0ca',
    voted: false,
  },
  'location-arrow': {
    changes: ['3.1', '5.0.0'],
    label: 'location-arrow',
    search: {
      terms: [
        'address',
        'compass',
        'coordinate',
        'direction',
        'gps',
        'map',
        'navigation',
        'place',
      ],
    },
    styles: ['solid'],
    unicode: 'f124',
    voted: false,
  },
  lock: {
    changes: ['1', '5.0.0'],
    label: 'lock',
    search: {
      terms: [
        'admin',
        'lock',
        'open',
        'password',
        'private',
        'protect',
        'security',
      ],
    },
    styles: ['solid'],
    unicode: 'f023',
    voted: false,
  },
  'lock-open': {
    changes: ['3.1', '5.0.0', '5.0.1'],
    label: 'Lock Open',
    search: {
      terms: [
        'admin',
        'lock',
        'open',
        'password',
        'private',
        'protect',
        'security',
      ],
    },
    styles: ['solid'],
    unicode: 'f3c1',
    voted: false,
  },
  'long-arrow-alt-down': {
    changes: ['5.0.0'],
    label: 'Alternate Long Arrow Down',
    search: {
      terms: ['download', 'long-arrow-down'],
    },
    styles: ['solid'],
    unicode: 'f309',
    voted: false,
  },
  'long-arrow-alt-left': {
    changes: ['5.0.0'],
    label: 'Alternate Long Arrow Left',
    search: {
      terms: ['back', 'long-arrow-left', 'previous'],
    },
    styles: ['solid'],
    unicode: 'f30a',
    voted: false,
  },
  'long-arrow-alt-right': {
    changes: ['5.0.0'],
    label: 'Alternate Long Arrow Right',
    search: {
      terms: ['forward', 'long-arrow-right', 'next'],
    },
    styles: ['solid'],
    unicode: 'f30b',
    voted: false,
  },
  'long-arrow-alt-up': {
    changes: ['5.0.0'],
    label: 'Alternate Long Arrow Up',
    search: {
      terms: ['long-arrow-up', 'upload'],
    },
    styles: ['solid'],
    unicode: 'f30c',
    voted: false,
  },
  'low-vision': {
    changes: ['4.6', '5.0.0'],
    label: 'Low Vision',
    search: {
      terms: ['blind', 'eye', 'sight'],
    },
    styles: ['solid'],
    unicode: 'f2a8',
    voted: false,
  },
  'luggage-cart': {
    changes: ['5.1.0'],
    label: 'Luggage Cart',
    search: {
      terms: ['bag', 'baggage', 'suitcase', 'travel'],
    },
    styles: ['solid'],
    unicode: 'f59d',
    voted: false,
  },
  lungs: {
    changes: ['5.2.0'],
    label: 'Lungs',
    search: {
      terms: ['air', 'breath', 'covid-19', 'organ', 'respiratory'],
    },
    styles: ['solid'],
    unicode: 'f604',
    voted: false,
  },
  'lungs-virus': {
    changes: ['5.13.0'],
    label: 'Lungs Virus',
    search: {
      terms: ['breath', 'covid-19', 'respiratory', 'sick'],
    },
    styles: ['solid'],
    unicode: 'f967',
    voted: false,
  },
  lyft: {
    changes: ['5.0.0'],
    label: 'lyft',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3c3',
    voted: false,
  },
  magento: {
    changes: ['5.0.0'],
    label: 'Magento',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3c4',
    voted: false,
  },
  magic: {
    changes: ['2', '5.0.0', '5.1.0'],
    label: 'magic',
    search: {
      terms: [
        'autocomplete',
        'automatic',
        'mage',
        'magic',
        'spell',
        'wand',
        'witch',
        'wizard',
      ],
    },
    styles: ['solid'],
    unicode: 'f0d0',
    voted: false,
  },
  magnet: {
    changes: ['1', '5.0.0', '5.8.0'],
    label: 'magnet',
    search: {
      terms: ['Attract', 'lodestone', 'tool'],
    },
    styles: ['solid'],
    unicode: 'f076',
    voted: false,
  },
  'mail-bulk': {
    changes: ['5.3.0'],
    label: 'Mail Bulk',
    search: {
      terms: [
        'archive',
        'envelope',
        'letter',
        'post office',
        'postal',
        'postcard',
        'send',
        'stamp',
        'usps',
      ],
    },
    styles: ['solid'],
    unicode: 'f674',
    voted: false,
  },
  mailchimp: {
    changes: ['5.1.0', '5.7.0', '5.8.0', '5.8.2'],
    label: 'Mailchimp',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f59e',
    voted: true,
  },
  male: {
    changes: ['3.2', '5.0.0'],
    label: 'Male',
    search: {
      terms: ['human', 'man', 'person', 'profile', 'user'],
    },
    styles: ['solid'],
    unicode: 'f183',
    voted: false,
  },
  mandalorian: {
    changes: ['5.0.12', '5.8.0'],
    label: 'Mandalorian',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f50f',
    voted: false,
  },
  map: {
    changes: ['4.4', '5.0.0', '5.1.0'],
    label: 'Map',
    search: {
      terms: [
        'address',
        'coordinates',
        'destination',
        'gps',
        'localize',
        'location',
        'map',
        'navigation',
        'paper',
        'pin',
        'place',
        'point of interest',
        'position',
        'route',
        'travel',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f279',
    voted: false,
  },
  'map-marked': {
    changes: ['5.1.0'],
    label: 'Map Marked',
    search: {
      terms: [
        'address',
        'coordinates',
        'destination',
        'gps',
        'localize',
        'location',
        'map',
        'navigation',
        'paper',
        'pin',
        'place',
        'point of interest',
        'position',
        'route',
        'travel',
      ],
    },
    styles: ['solid'],
    unicode: 'f59f',
    voted: false,
  },
  'map-marked-alt': {
    changes: ['5.1.0'],
    label: 'Alternate Map Marked',
    search: {
      terms: [
        'address',
        'coordinates',
        'destination',
        'gps',
        'localize',
        'location',
        'map',
        'navigation',
        'paper',
        'pin',
        'place',
        'point of interest',
        'position',
        'route',
        'travel',
      ],
    },
    styles: ['solid'],
    unicode: 'f5a0',
    voted: false,
  },
  'map-marker': {
    changes: ['1', '5.0.0'],
    label: 'map-marker',
    search: {
      terms: [
        'address',
        'coordinates',
        'destination',
        'gps',
        'localize',
        'location',
        'map',
        'navigation',
        'paper',
        'pin',
        'place',
        'point of interest',
        'position',
        'route',
        'travel',
      ],
    },
    styles: ['solid'],
    unicode: 'f041',
    voted: false,
  },
  'map-marker-alt': {
    changes: ['5.0.0'],
    label: 'Alternate Map Marker',
    search: {
      terms: [
        'address',
        'coordinates',
        'destination',
        'gps',
        'localize',
        'location',
        'map',
        'navigation',
        'paper',
        'pin',
        'place',
        'point of interest',
        'position',
        'route',
        'travel',
      ],
    },
    styles: ['solid'],
    unicode: 'f3c5',
    voted: false,
  },
  'map-pin': {
    changes: ['4.4', '5.0.0', '5.2.0'],
    label: 'Map Pin',
    search: {
      terms: [
        'address',
        'agree',
        'coordinates',
        'destination',
        'gps',
        'localize',
        'location',
        'map',
        'marker',
        'navigation',
        'pin',
        'place',
        'position',
        'travel',
      ],
    },
    styles: ['solid'],
    unicode: 'f276',
    voted: false,
  },
  'map-signs': {
    changes: ['4.4', '5.0.0', '5.2.0'],
    label: 'Map Signs',
    search: {
      terms: ['directions', 'directory', 'map', 'signage', 'wayfinding'],
    },
    styles: ['solid'],
    unicode: 'f277',
    voted: false,
  },
  markdown: {
    changes: ['5.2.0', '5.7.0'],
    label: 'Markdown',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f60f',
    voted: true,
  },
  marker: {
    changes: ['5.1.0'],
    label: 'Marker',
    search: {
      terms: ['design', 'edit', 'sharpie', 'update', 'write'],
    },
    styles: ['solid'],
    unicode: 'f5a1',
    voted: true,
  },
  mars: {
    changes: ['4.3', '5.0.0'],
    label: 'Mars',
    search: {
      terms: ['male'],
    },
    styles: ['solid'],
    unicode: 'f222',
    voted: false,
  },
  'mars-double': {
    changes: ['4.3', '5.0.0'],
    label: 'Mars Double',
    search: {
      terms: [],
    },
    styles: ['solid'],
    unicode: 'f227',
    voted: false,
  },
  'mars-stroke': {
    changes: ['4.3', '5.0.0'],
    label: 'Mars Stroke',
    search: {
      terms: [],
    },
    styles: ['solid'],
    unicode: 'f229',
    voted: false,
  },
  'mars-stroke-h': {
    changes: ['4.3', '5.0.0', '5.11.0'],
    label: 'Mars Stroke Horizontal',
    search: {
      terms: [],
    },
    styles: ['solid'],
    unicode: 'f22b',
    voted: false,
  },
  'mars-stroke-v': {
    changes: ['4.3', '5.0.0', '5.11.0'],
    label: 'Mars Stroke Vertical',
    search: {
      terms: [],
    },
    styles: ['solid'],
    unicode: 'f22a',
    voted: false,
  },
  mask: {
    changes: ['5.4.0', '5.10.1'],
    label: 'Mask',
    search: {
      terms: [
        'carnivale',
        'costume',
        'disguise',
        'halloween',
        'secret',
        'super hero',
      ],
    },
    styles: ['solid'],
    unicode: 'f6fa',
    voted: false,
  },
  mastodon: {
    changes: ['5.0.11', '5.8.0'],
    label: 'Mastodon',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f4f6',
    voted: true,
  },
  maxcdn: {
    changes: ['3.1', '5.0.0'],
    label: 'MaxCDN',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f136',
    voted: false,
  },
  mdb: {
    changes: ['5.11.0'],
    label: 'Material Design for Bootstrap',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f8ca',
  },
  medal: {
    changes: ['5.1.0'],
    label: 'Medal',
    search: {
      terms: ['award', 'ribbon', 'star', 'trophy'],
    },
    styles: ['solid'],
    unicode: 'f5a2',
    voted: true,
  },
  medapps: {
    changes: ['5.0.0'],
    label: 'MedApps',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3c6',
    voted: false,
  },
  medium: {
    changes: ['4.3', '5.0.0'],
    label: 'Medium',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f23a',
    voted: false,
  },
  'medium-m': {
    changes: ['5.0.0'],
    label: 'Medium M',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3c7',
    voted: false,
  },
  medkit: {
    changes: ['3', '5.0.0'],
    label: 'medkit',
    search: {
      terms: ['first aid', 'firstaid', 'health', 'help', 'support'],
    },
    styles: ['solid'],
    unicode: 'f0fa',
    voted: false,
  },
  medrt: {
    changes: ['5.0.0'],
    label: 'MRT',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3c8',
    voted: false,
  },
  meetup: {
    changes: ['4.7', '5.0.0'],
    label: 'Meetup',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f2e0',
    voted: false,
  },
  megaport: {
    changes: ['5.1.0'],
    label: 'Megaport',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f5a3',
    voted: false,
  },
  meh: {
    changes: ['3.1', '5.0.0', '5.0.9', '5.1.0', '5.11.0', '5.11.1'],
    label: 'Neutral Face',
    search: {
      terms: ['emoticon', 'face', 'neutral', 'rating'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f11a',
    voted: false,
  },
  'meh-blank': {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Face Without Mouth',
    search: {
      terms: ['emoticon', 'face', 'neutral', 'rating'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f5a4',
    voted: false,
  },
  'meh-rolling-eyes': {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Face With Rolling Eyes',
    search: {
      terms: ['emoticon', 'face', 'neutral', 'rating'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f5a5',
    voted: false,
  },
  memory: {
    changes: ['5.0.13'],
    label: 'Memory',
    search: {
      terms: ['DIMM', 'RAM', 'hardware', 'storage', 'technology'],
    },
    styles: ['solid'],
    unicode: 'f538',
    voted: true,
  },
  mendeley: {
    changes: ['5.6.0'],
    label: 'Mendeley',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f7b3',
    voted: true,
  },
  menorah: {
    changes: ['5.3.0', '5.4.0'],
    label: 'Menorah',
    search: {
      terms: ['candle', 'hanukkah', 'jewish', 'judaism', 'light'],
    },
    styles: ['solid'],
    unicode: 'f676',
    voted: false,
  },
  mercury: {
    changes: ['4.3', '5.0.0', '5.11.0'],
    label: 'Mercury',
    search: {
      terms: ['transgender'],
    },
    styles: ['solid'],
    unicode: 'f223',
    voted: false,
  },
  meteor: {
    changes: ['5.5.0', '5.12.0'],
    label: 'Meteor',
    search: {
      terms: ['armageddon', 'asteroid', 'comet', 'shooting star', 'space'],
    },
    styles: ['solid'],
    unicode: 'f753',
    voted: false,
  },
  microblog: {
    changes: ['5.12.0'],
    label: 'Micro.blog',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f91a',
    voted: true,
  },
  microchip: {
    changes: ['4.7', '5.0.0'],
    label: 'Microchip',
    search: {
      terms: ['cpu', 'hardware', 'processor', 'technology'],
    },
    styles: ['solid'],
    unicode: 'f2db',
    voted: false,
  },
  microphone: {
    changes: ['3.1', '5.0.0', '5.0.13'],
    label: 'microphone',
    search: {
      terms: ['audio', 'podcast', 'record', 'sing', 'sound', 'voice'],
    },
    styles: ['solid'],
    unicode: 'f130',
    voted: false,
  },
  'microphone-alt': {
    changes: ['5.0.0', '5.0.13'],
    label: 'Alternate Microphone',
    search: {
      terms: ['audio', 'podcast', 'record', 'sing', 'sound', 'voice'],
    },
    styles: ['solid'],
    unicode: 'f3c9',
    voted: false,
  },
  'microphone-alt-slash': {
    changes: ['5.0.13'],
    label: 'Alternate Microphone Slash',
    search: {
      terms: [
        'audio',
        'disable',
        'mute',
        'podcast',
        'record',
        'sing',
        'sound',
        'voice',
      ],
    },
    styles: ['solid'],
    unicode: 'f539',
    voted: false,
  },
  'microphone-slash': {
    changes: ['3.1', '5.0.0', '5.0.13'],
    label: 'Microphone Slash',
    search: {
      terms: [
        'audio',
        'disable',
        'mute',
        'podcast',
        'record',
        'sing',
        'sound',
        'voice',
      ],
    },
    styles: ['solid'],
    unicode: 'f131',
    voted: false,
  },
  microscope: {
    changes: ['5.2.0'],
    label: 'Microscope',
    search: {
      terms: ['covid-19', 'electron', 'lens', 'optics', 'science', 'shrink'],
    },
    styles: ['solid'],
    unicode: 'f610',
    voted: false,
  },
  microsoft: {
    changes: ['5.0.0'],
    label: 'Microsoft',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3ca',
    voted: true,
  },
  minus: {
    changes: ['1', '5.0.0'],
    label: 'minus',
    search: {
      terms: [
        'collapse',
        'delete',
        'hide',
        'minify',
        'negative',
        'remove',
        'trash',
      ],
    },
    styles: ['solid'],
    unicode: 'f068',
    voted: false,
  },
  'minus-circle': {
    changes: ['1', '5.0.0', '5.10.2'],
    label: 'Minus Circle',
    search: {
      terms: ['delete', 'hide', 'negative', 'remove', 'shape', 'trash'],
    },
    styles: ['solid'],
    unicode: 'f056',
    voted: false,
  },
  'minus-square': {
    changes: ['3.1', '5.0.0'],
    label: 'Minus Square',
    search: {
      terms: [
        'collapse',
        'delete',
        'hide',
        'minify',
        'negative',
        'remove',
        'shape',
        'trash',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f146',
    voted: false,
  },
  mitten: {
    changes: ['5.6.0'],
    label: 'Mitten',
    search: {
      terms: [
        'clothing',
        'cold',
        'glove',
        'hands',
        'knitted',
        'seasonal',
        'warmth',
      ],
    },
    styles: ['solid'],
    unicode: 'f7b5',
  },
  mix: {
    changes: ['5.0.0', '5.0.3'],
    label: 'Mix',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3cb',
    voted: false,
  },
  mixcloud: {
    changes: ['4.5', '5.0.0'],
    label: 'Mixcloud',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f289',
    voted: false,
  },
  mixer: {
    changes: ['5.12.1'],
    label: 'Mixer',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f956',
    voted: true,
  },
  mizuni: {
    changes: ['5.0.0'],
    label: 'Mizuni',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3cc',
    voted: false,
  },
  mobile: {
    changes: ['3', '5.0.0'],
    label: 'Mobile Phone',
    search: {
      terms: [
        'apple',
        'call',
        'cell phone',
        'cellphone',
        'device',
        'iphone',
        'number',
        'screen',
        'telephone',
      ],
    },
    styles: ['solid'],
    unicode: 'f10b',
    voted: false,
  },
  'mobile-alt': {
    changes: ['5.0.0'],
    label: 'Alternate Mobile',
    search: {
      terms: [
        'apple',
        'call',
        'cell phone',
        'cellphone',
        'device',
        'iphone',
        'number',
        'screen',
        'telephone',
      ],
    },
    styles: ['solid'],
    unicode: 'f3cd',
    voted: false,
  },
  modx: {
    changes: ['4.5', '5.0.0'],
    label: 'MODX',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f285',
    voted: false,
  },
  monero: {
    changes: ['5.0.0'],
    label: 'Monero',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3d0',
    voted: false,
  },
  'money-bill': {
    changes: ['2', '5.0.0', '5.0.13'],
    label: 'Money Bill',
    search: {
      terms: [
        'buy',
        'cash',
        'checkout',
        'money',
        'payment',
        'price',
        'purchase',
      ],
    },
    styles: ['solid'],
    unicode: 'f0d6',
    voted: false,
  },
  'money-bill-alt': {
    changes: ['5.0.0', '5.0.13'],
    label: 'Alternate Money Bill',
    search: {
      terms: [
        'buy',
        'cash',
        'checkout',
        'money',
        'payment',
        'price',
        'purchase',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f3d1',
    voted: false,
  },
  'money-bill-wave': {
    changes: ['5.0.13'],
    label: 'Wavy Money Bill',
    search: {
      terms: [
        'buy',
        'cash',
        'checkout',
        'money',
        'payment',
        'price',
        'purchase',
      ],
    },
    styles: ['solid'],
    unicode: 'f53a',
    voted: true,
  },
  'money-bill-wave-alt': {
    changes: ['5.0.13'],
    label: 'Alternate Wavy Money Bill',
    search: {
      terms: [
        'buy',
        'cash',
        'checkout',
        'money',
        'payment',
        'price',
        'purchase',
      ],
    },
    styles: ['solid'],
    unicode: 'f53b',
    voted: true,
  },
  'money-check': {
    changes: ['5.0.13'],
    label: 'Money Check',
    search: {
      terms: [
        'bank check',
        'buy',
        'checkout',
        'cheque',
        'money',
        'payment',
        'price',
        'purchase',
      ],
    },
    styles: ['solid'],
    unicode: 'f53c',
    voted: true,
  },
  'money-check-alt': {
    changes: ['5.0.13'],
    label: 'Alternate Money Check',
    search: {
      terms: [
        'bank check',
        'buy',
        'checkout',
        'cheque',
        'money',
        'payment',
        'price',
        'purchase',
      ],
    },
    styles: ['solid'],
    unicode: 'f53d',
    voted: true,
  },
  monument: {
    changes: ['5.1.0'],
    label: 'Monument',
    search: {
      terms: ['building', 'historic', 'landmark', 'memorable'],
    },
    styles: ['solid'],
    unicode: 'f5a6',
    voted: false,
  },
  moon: {
    changes: ['3.2', '5.0.0', '5.11.0', '5.11.1'],
    label: 'Moon',
    search: {
      terms: ['contrast', 'crescent', 'dark', 'lunar', 'night'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f186',
    voted: false,
  },
  'mortar-pestle': {
    changes: ['5.1.0'],
    label: 'Mortar Pestle',
    search: {
      terms: [
        'crush',
        'culinary',
        'grind',
        'medical',
        'mix',
        'pharmacy',
        'prescription',
        'spices',
      ],
    },
    styles: ['solid'],
    unicode: 'f5a7',
    voted: false,
  },
  mosque: {
    changes: ['5.3.0'],
    label: 'Mosque',
    search: {
      terms: ['building', 'islam', 'landmark', 'muslim'],
    },
    styles: ['solid'],
    unicode: 'f678',
    voted: false,
  },
  motorcycle: {
    changes: ['4.3', '5.0.0'],
    label: 'Motorcycle',
    search: {
      terms: ['bike', 'machine', 'transportation', 'vehicle'],
    },
    styles: ['solid'],
    unicode: 'f21c',
    voted: false,
  },
  mountain: {
    changes: ['5.4.0'],
    label: 'Mountain',
    search: {
      terms: ['glacier', 'hiking', 'hill', 'landscape', 'travel', 'view'],
    },
    styles: ['solid'],
    unicode: 'f6fc',
    voted: false,
  },
  mouse: {
    changes: ['5.11.0'],
    label: 'Mouse',
    search: {
      terms: ['click', 'computer', 'cursor', 'input', 'peripheral'],
    },
    styles: ['solid'],
    unicode: 'f8cc',
    voted: true,
  },
  'mouse-pointer': {
    changes: ['4.4', '5.0.0', '5.0.3'],
    label: 'Mouse Pointer',
    search: {
      terms: ['arrow', 'cursor', 'select'],
    },
    styles: ['solid'],
    unicode: 'f245',
    voted: false,
  },
  'mug-hot': {
    changes: ['5.6.0'],
    label: 'Mug Hot',
    search: {
      terms: [
        'caliente',
        'cocoa',
        'coffee',
        'cup',
        'drink',
        'holiday',
        'hot chocolate',
        'steam',
        'tea',
        'warmth',
      ],
    },
    styles: ['solid'],
    unicode: 'f7b6',
  },
  music: {
    changes: ['1', '5.0.0', '5.2.0', '5.11.0'],
    label: 'Music',
    search: {
      terms: ['lyrics', 'melody', 'note', 'sing', 'sound'],
    },
    styles: ['solid'],
    unicode: 'f001',
    voted: false,
  },
  napster: {
    changes: ['5.0.0'],
    label: 'Napster',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3d2',
    voted: false,
  },
  neos: {
    changes: ['5.2.0', '5.8.0'],
    label: 'Neos',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f612',
    voted: true,
  },
  'network-wired': {
    changes: ['5.4.0'],
    label: 'Wired Network',
    search: {
      terms: ['computer', 'connect', 'ethernet', 'internet', 'intranet'],
    },
    styles: ['solid'],
    unicode: 'f6ff',
    voted: true,
  },
  neuter: {
    changes: ['4.3', '5.0.0', '5.11.0'],
    label: 'Neuter',
    search: {
      terms: [],
    },
    styles: ['solid'],
    unicode: 'f22c',
    voted: false,
  },
  newspaper: {
    changes: ['4.2', '5.0.0'],
    label: 'Newspaper',
    search: {
      terms: [
        'article',
        'editorial',
        'headline',
        'journal',
        'journalism',
        'news',
        'press',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f1ea',
    voted: false,
  },
  nimblr: {
    changes: ['5.1.0', '5.8.0'],
    label: 'Nimblr',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f5a8',
    voted: false,
  },
  node: {
    changes: ['5.0.0'],
    label: 'Node.js',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f419',
    voted: true,
  },
  'node-js': {
    changes: ['5.0.0', '5.0.3'],
    label: 'Node.js JS',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3d3',
    voted: false,
  },
  'not-equal': {
    changes: ['5.0.13'],
    label: 'Not Equal',
    search: {
      terms: ['arithmetic', 'compare', 'math'],
    },
    styles: ['solid'],
    unicode: 'f53e',
    voted: true,
  },
  'notes-medical': {
    changes: ['5.0.7'],
    label: 'Medical Notes',
    search: {
      terms: ['clipboard', 'doctor', 'ehr', 'health', 'history', 'records'],
    },
    styles: ['solid'],
    unicode: 'f481',
    voted: false,
  },
  npm: {
    changes: ['5.0.0'],
    label: 'npm',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3d4',
    voted: false,
  },
  ns8: {
    changes: ['5.0.0'],
    label: 'NS8',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3d5',
    voted: false,
  },
  nutritionix: {
    changes: ['5.0.0'],
    label: 'Nutritionix',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3d6',
    voted: false,
  },
  'object-group': {
    changes: ['4.4', '5.0.0', '5.10.1'],
    label: 'Object Group',
    search: {
      terms: ['combine', 'copy', 'design', 'merge', 'select'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f247',
    voted: false,
  },
  'object-ungroup': {
    changes: ['4.4', '5.0.0', '5.10.1'],
    label: 'Object Ungroup',
    search: {
      terms: ['copy', 'design', 'merge', 'select', 'separate'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f248',
    voted: false,
  },
  odnoklassniki: {
    changes: ['4.4', '5.0.0'],
    label: 'Odnoklassniki',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f263',
    voted: false,
  },
  'odnoklassniki-square': {
    changes: ['4.4', '5.0.0'],
    label: 'Odnoklassniki Square',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f264',
    voted: false,
  },
  'oil-can': {
    changes: ['5.2.0', '5.10.1'],
    label: 'Oil Can',
    search: {
      terms: ['auto', 'crude', 'gasoline', 'grease', 'lubricate', 'petroleum'],
    },
    styles: ['solid'],
    unicode: 'f613',
    voted: false,
  },
  'old-republic': {
    changes: ['5.0.12'],
    label: 'Old Republic',
    search: {
      terms: ['politics', 'star wars'],
    },
    styles: ['brands'],
    unicode: 'f510',
    voted: false,
  },
  om: {
    changes: ['5.3.0'],
    label: 'Om',
    search: {
      terms: ['buddhism', 'hinduism', 'jainism', 'mantra'],
    },
    styles: ['solid'],
    unicode: 'f679',
    voted: false,
  },
  opencart: {
    changes: ['4.4', '5.0.0'],
    label: 'OpenCart',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f23d',
    voted: false,
  },
  openid: {
    changes: ['4.1', '5.0.0'],
    label: 'OpenID',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f19b',
    voted: false,
  },
  opera: {
    changes: ['4.4', '5.0.0'],
    label: 'Opera',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f26a',
    voted: false,
  },
  'optin-monster': {
    changes: ['4.4', '5.0.0', '5.7.0'],
    label: 'Optin Monster',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f23c',
    voted: false,
  },
  orcid: {
    changes: ['5.11.0'],
    label: 'ORCID',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f8d2',
  },
  osi: {
    changes: ['5.0.0', '5.8.0'],
    label: 'Open Source Initiative',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f41a',
    voted: false,
  },
  otter: {
    changes: ['5.4.0'],
    label: 'Otter',
    search: {
      terms: ['animal', 'badger', 'fauna', 'fur', 'mammal', 'marten'],
    },
    styles: ['solid'],
    unicode: 'f700',
    voted: false,
  },
  outdent: {
    changes: ['1', '5.0.0', '5.9.0'],
    label: 'Outdent',
    search: {
      terms: ['align', 'justify', 'paragraph', 'tab'],
    },
    styles: ['solid'],
    unicode: 'f03b',
    voted: false,
  },
  page4: {
    changes: ['5.0.0'],
    label: 'page4 Corporation',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3d7',
    voted: false,
  },
  pagelines: {
    changes: ['4', '5.0.0'],
    label: 'Pagelines',
    search: {
      terms: ['eco', 'flora', 'leaf', 'leaves', 'nature', 'plant', 'tree'],
    },
    styles: ['brands'],
    unicode: 'f18c',
    voted: false,
  },
  pager: {
    changes: ['5.7.0'],
    label: 'Pager',
    search: {
      terms: ['beeper', 'cellphone', 'communication'],
    },
    styles: ['solid'],
    unicode: 'f815',
    voted: false,
  },
  'paint-brush': {
    changes: ['4.2', '5.0.0', '5.1.0'],
    label: 'Paint Brush',
    search: {
      terms: [
        'acrylic',
        'art',
        'brush',
        'color',
        'fill',
        'paint',
        'pigment',
        'watercolor',
      ],
    },
    styles: ['solid'],
    unicode: 'f1fc',
    voted: false,
  },
  'paint-roller': {
    changes: ['5.1.0'],
    label: 'Paint Roller',
    search: {
      terms: [
        'acrylic',
        'art',
        'brush',
        'color',
        'fill',
        'paint',
        'pigment',
        'watercolor',
      ],
    },
    styles: ['solid'],
    unicode: 'f5aa',
    voted: false,
  },
  palette: {
    changes: ['5.0.13'],
    label: 'Palette',
    search: {
      terms: [
        'acrylic',
        'art',
        'brush',
        'color',
        'fill',
        'paint',
        'pigment',
        'watercolor',
      ],
    },
    styles: ['solid'],
    unicode: 'f53f',
    voted: true,
  },
  palfed: {
    changes: ['5.0.0', '5.0.3'],
    label: 'Palfed',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3d8',
    voted: false,
  },
  pallet: {
    changes: ['5.0.7'],
    label: 'Pallet',
    search: {
      terms: ['archive', 'box', 'inventory', 'shipping', 'warehouse'],
    },
    styles: ['solid'],
    unicode: 'f482',
    voted: false,
  },
  'paper-plane': {
    changes: ['4.1', '5.0.0'],
    label: 'Paper Plane',
    search: {
      terms: ['air', 'float', 'fold', 'mail', 'paper', 'send'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f1d8',
    voted: false,
  },
  paperclip: {
    changes: ['2', '5.0.0'],
    label: 'Paperclip',
    search: {
      terms: ['attach', 'attachment', 'connect', 'link'],
    },
    styles: ['solid'],
    unicode: 'f0c6',
    voted: false,
  },
  'parachute-box': {
    changes: ['5.0.9'],
    label: 'Parachute Box',
    search: {
      terms: ['aid', 'assistance', 'rescue', 'supplies'],
    },
    styles: ['solid'],
    unicode: 'f4cd',
    voted: false,
  },
  paragraph: {
    changes: ['4.1', '5.0.0', '5.9.0'],
    label: 'paragraph',
    search: {
      terms: ['edit', 'format', 'text', 'writing'],
    },
    styles: ['solid'],
    unicode: 'f1dd',
    voted: false,
  },
  parking: {
    changes: ['5.0.13'],
    label: 'Parking',
    search: {
      terms: ['auto', 'car', 'garage', 'meter'],
    },
    styles: ['solid'],
    unicode: 'f540',
    voted: true,
  },
  passport: {
    changes: ['5.1.0'],
    label: 'Passport',
    search: {
      terms: ['document', 'id', 'identification', 'issued', 'travel'],
    },
    styles: ['solid'],
    unicode: 'f5ab',
    voted: false,
  },
  pastafarianism: {
    changes: ['5.3.0'],
    label: 'Pastafarianism',
    search: {
      terms: ['agnosticism', 'atheism', 'flying spaghetti monster', 'fsm'],
    },
    styles: ['solid'],
    unicode: 'f67b',
    voted: false,
  },
  paste: {
    changes: ['2', '5.0.0'],
    label: 'Paste',
    search: {
      terms: ['clipboard', 'copy', 'document', 'paper'],
    },
    styles: ['solid'],
    unicode: 'f0ea',
    voted: false,
  },
  patreon: {
    changes: ['5.0.0', '5.0.3'],
    label: 'Patreon',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3d9',
    voted: false,
  },
  pause: {
    changes: ['1', '5.0.0', '5.10.2'],
    label: 'pause',
    search: {
      terms: ['hold', 'wait'],
    },
    styles: ['solid'],
    unicode: 'f04c',
    voted: false,
  },
  'pause-circle': {
    changes: ['4.5', '5.0.0'],
    label: 'Pause Circle',
    search: {
      terms: ['hold', 'wait'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f28b',
    voted: false,
  },
  paw: {
    changes: ['4.1', '5.0.0'],
    label: 'Paw',
    search: {
      terms: ['animal', 'cat', 'dog', 'pet', 'print'],
    },
    styles: ['solid'],
    unicode: 'f1b0',
    voted: false,
  },
  paypal: {
    changes: ['4.2', '5.0.0'],
    label: 'Paypal',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1ed',
    voted: false,
  },
  peace: {
    changes: ['5.3.0', '5.11.0', '5.11.1'],
    label: 'Peace',
    search: {
      terms: ['serenity', 'tranquility', 'truce', 'war'],
    },
    styles: ['solid'],
    unicode: 'f67c',
    voted: false,
  },
  pen: {
    changes: ['5.0.0', '5.1.0'],
    label: 'Pen',
    search: {
      terms: ['design', 'edit', 'update', 'write'],
    },
    styles: ['solid'],
    unicode: 'f304',
    voted: false,
  },
  'pen-alt': {
    changes: ['5.0.0', '5.1.0'],
    label: 'Alternate Pen',
    search: {
      terms: ['design', 'edit', 'update', 'write'],
    },
    styles: ['solid'],
    unicode: 'f305',
    voted: false,
  },
  'pen-fancy': {
    changes: ['5.1.0'],
    label: 'Pen Fancy',
    search: {
      terms: ['design', 'edit', 'fountain pen', 'update', 'write'],
    },
    styles: ['solid'],
    unicode: 'f5ac',
    voted: false,
  },
  'pen-nib': {
    changes: ['5.1.0'],
    label: 'Pen Nib',
    search: {
      terms: ['design', 'edit', 'fountain pen', 'update', 'write'],
    },
    styles: ['solid'],
    unicode: 'f5ad',
    voted: true,
  },
  'pen-square': {
    changes: ['3.1', '5.0.0'],
    label: 'Pen Square',
    search: {
      terms: ['edit', 'pencil-square', 'update', 'write'],
    },
    styles: ['solid'],
    unicode: 'f14b',
    voted: false,
  },
  'pencil-alt': {
    changes: ['5.0.0'],
    label: 'Alternate Pencil',
    search: {
      terms: ['design', 'edit', 'pencil', 'update', 'write'],
    },
    styles: ['solid'],
    unicode: 'f303',
    voted: false,
  },
  'pencil-ruler': {
    changes: ['5.1.0'],
    label: 'Pencil Ruler',
    search: {
      terms: ['design', 'draft', 'draw', 'pencil'],
    },
    styles: ['solid'],
    unicode: 'f5ae',
    voted: false,
  },
  'penny-arcade': {
    changes: ['5.4.0'],
    label: 'Penny Arcade',
    search: {
      terms: [
        'Dungeons & Dragons',
        'd&d',
        'dnd',
        'fantasy',
        'game',
        'gaming',
        'pax',
        'tabletop',
      ],
    },
    styles: ['brands'],
    unicode: 'f704',
    voted: false,
  },
  'people-arrows': {
    changes: ['5.13.0'],
    label: 'People Arrows',
    search: {
      terms: [
        'covid-19',
        'personal space',
        'social distance',
        'space',
        'spread',
        'users',
      ],
    },
    styles: ['solid'],
    unicode: 'f968',
    voted: false,
  },
  'people-carry': {
    changes: ['5.0.9'],
    label: 'People Carry',
    search: {
      terms: ['box', 'carry', 'fragile', 'help', 'movers', 'package'],
    },
    styles: ['solid'],
    unicode: 'f4ce',
    voted: false,
  },
  'pepper-hot': {
    changes: ['5.7.0'],
    label: 'Hot Pepper',
    search: {
      terms: [
        'buffalo wings',
        'capsicum',
        'chili',
        'chilli',
        'habanero',
        'jalapeno',
        'mexican',
        'spicy',
        'tabasco',
        'vegetable',
      ],
    },
    styles: ['solid'],
    unicode: 'f816',
    voted: true,
  },
  percent: {
    changes: ['4.5', '5.0.0'],
    label: 'Percent',
    search: {
      terms: ['discount', 'fraction', 'proportion', 'rate', 'ratio'],
    },
    styles: ['solid'],
    unicode: 'f295',
    voted: false,
  },
  percentage: {
    changes: ['5.0.13'],
    label: 'Percentage',
    search: {
      terms: ['discount', 'fraction', 'proportion', 'rate', 'ratio'],
    },
    styles: ['solid'],
    unicode: 'f541',
    voted: true,
  },
  periscope: {
    changes: ['5.0.0'],
    label: 'Periscope',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3da',
    voted: false,
  },
  'person-booth': {
    changes: ['5.5.0'],
    label: 'Person Entering Booth',
    search: {
      terms: [
        'changing',
        'changing room',
        'election',
        'human',
        'person',
        'vote',
        'voting',
      ],
    },
    styles: ['solid'],
    unicode: 'f756',
    voted: false,
  },
  phabricator: {
    changes: ['5.0.0'],
    label: 'Phabricator',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3db',
    voted: false,
  },
  'phoenix-framework': {
    changes: ['5.0.0', '5.0.3'],
    label: 'Phoenix Framework',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3dc',
    voted: false,
  },
  'phoenix-squadron': {
    changes: ['5.0.12', '5.8.0'],
    label: 'Phoenix Squadron',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f511',
    voted: false,
  },
  phone: {
    changes: ['2', '5.0.0', '5.10.1', '5.10.2'],
    label: 'Phone',
    search: {
      terms: ['call', 'earphone', 'number', 'support', 'telephone', 'voice'],
    },
    styles: ['solid'],
    unicode: 'f095',
    voted: false,
  },
  'phone-alt': {
    changes: ['5.9.0', '5.10.1', '5.10.2'],
    label: 'Alternate Phone',
    search: {
      terms: ['call', 'earphone', 'number', 'support', 'telephone', 'voice'],
    },
    styles: ['solid'],
    unicode: 'f879',
    voted: false,
  },
  'phone-slash': {
    changes: ['5.0.0', '5.0.9'],
    label: 'Phone Slash',
    search: {
      terms: [
        'call',
        'cancel',
        'earphone',
        'mute',
        'number',
        'support',
        'telephone',
        'voice',
      ],
    },
    styles: ['solid'],
    unicode: 'f3dd',
    voted: false,
  },
  'phone-square': {
    changes: ['2', '5.0.0'],
    label: 'Phone Square',
    search: {
      terms: ['call', 'earphone', 'number', 'support', 'telephone', 'voice'],
    },
    styles: ['solid'],
    unicode: 'f098',
    voted: false,
  },
  'phone-square-alt': {
    changes: ['5.9.0', '5.10.1'],
    label: 'Alternate Phone Square',
    search: {
      terms: ['call', 'earphone', 'number', 'support', 'telephone', 'voice'],
    },
    styles: ['solid'],
    unicode: 'f87b',
    voted: false,
  },
  'phone-volume': {
    changes: ['4.6', '5.0.0', '5.0.3', '5.7.0'],
    label: 'Phone Volume',
    search: {
      terms: [
        'call',
        'earphone',
        'number',
        'sound',
        'support',
        'telephone',
        'voice',
        'volume-control-phone',
      ],
    },
    styles: ['solid'],
    unicode: 'f2a0',
    voted: false,
  },
  'photo-video': {
    changes: ['5.9.0'],
    label: 'Photo Video',
    search: {
      terms: ['av', 'film', 'image', 'library', 'media'],
    },
    styles: ['solid'],
    unicode: 'f87c',
    voted: false,
  },
  php: {
    changes: ['5.0.5'],
    label: 'PHP',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f457',
    voted: true,
  },
  'pied-piper': {
    changes: ['4.6', '5.0.0', '5.0.10', '5.12.0'],
    label: 'Pied Piper Logo',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f2ae',
    voted: false,
  },
  'pied-piper-alt': {
    changes: ['4.1', '5.0.0', '5.7.0'],
    label: 'Alternate Pied Piper Logo (Old)',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1a8',
    voted: false,
  },
  'pied-piper-hat': {
    changes: ['5.0.10'],
    label: 'Pied Piper Hat (Old)',
    search: {
      terms: ['clothing'],
    },
    styles: ['brands'],
    unicode: 'f4e5',
    voted: false,
  },
  'pied-piper-pp': {
    changes: ['4.1', '5.0.0'],
    label: 'Pied Piper PP Logo (Old)',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1a7',
    voted: false,
  },
  'pied-piper-square': {
    changes: ['5.12.0'],
    label: 'Pied Piper Square Logo (Old)',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f91e',
    voted: false,
  },
  'piggy-bank': {
    changes: ['5.0.9', '5.10.2'],
    label: 'Piggy Bank',
    search: {
      terms: ['bank', 'save', 'savings'],
    },
    styles: ['solid'],
    unicode: 'f4d3',
    voted: false,
  },
  pills: {
    changes: ['5.0.7'],
    label: 'Pills',
    search: {
      terms: ['drugs', 'medicine', 'prescription', 'tablets'],
    },
    styles: ['solid'],
    unicode: 'f484',
    voted: false,
  },
  pinterest: {
    changes: ['2', '5.0.0'],
    label: 'Pinterest',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f0d2',
    voted: false,
  },
  'pinterest-p': {
    changes: ['4.3', '5.0.0'],
    label: 'Pinterest P',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f231',
    voted: false,
  },
  'pinterest-square': {
    changes: ['2', '5.0.0'],
    label: 'Pinterest Square',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f0d3',
    voted: false,
  },
  'pizza-slice': {
    changes: ['5.7.0'],
    label: 'Pizza Slice',
    search: {
      terms: [
        'cheese',
        'chicago',
        'italian',
        'mozzarella',
        'new york',
        'pepperoni',
        'pie',
        'slice',
        'teenage mutant ninja turtles',
        'tomato',
      ],
    },
    styles: ['solid'],
    unicode: 'f818',
    voted: true,
  },
  'place-of-worship': {
    changes: ['5.3.0'],
    label: 'Place of Worship',
    search: {
      terms: ['building', 'church', 'holy', 'mosque', 'synagogue'],
    },
    styles: ['solid'],
    unicode: 'f67f',
    voted: false,
  },
  plane: {
    changes: ['1', '5.0.0', '5.0.13'],
    label: 'plane',
    search: {
      terms: [
        'airplane',
        'destination',
        'fly',
        'location',
        'mode',
        'travel',
        'trip',
      ],
    },
    styles: ['solid'],
    unicode: 'f072',
    voted: false,
  },
  'plane-arrival': {
    changes: ['5.1.0'],
    label: 'Plane Arrival',
    search: {
      terms: [
        'airplane',
        'arriving',
        'destination',
        'fly',
        'land',
        'landing',
        'location',
        'mode',
        'travel',
        'trip',
      ],
    },
    styles: ['solid'],
    unicode: 'f5af',
    voted: false,
  },
  'plane-departure': {
    changes: ['5.1.0', '5.8.0'],
    label: 'Plane Departure',
    search: {
      terms: [
        'airplane',
        'departing',
        'destination',
        'fly',
        'location',
        'mode',
        'take off',
        'taking off',
        'travel',
        'trip',
      ],
    },
    styles: ['solid'],
    unicode: 'f5b0',
    voted: false,
  },
  'plane-slash': {
    changes: ['5.13.0'],
    label: 'Plane Slash',
    search: {
      terms: [
        'airplane mode',
        'canceled',
        'covid-19',
        'delayed',
        'grounded',
        'travel',
      ],
    },
    styles: ['solid'],
    unicode: 'f969',
    voted: false,
  },
  play: {
    changes: ['1', '5.0.0', '5.10.2'],
    label: 'play',
    search: {
      terms: ['audio', 'music', 'playing', 'sound', 'start', 'video'],
    },
    styles: ['solid'],
    unicode: 'f04b',
    voted: false,
  },
  'play-circle': {
    changes: ['3.1', '5.0.0', '5.10.2'],
    label: 'Play Circle',
    search: {
      terms: ['audio', 'music', 'playing', 'sound', 'start', 'video'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f144',
    voted: false,
  },
  playstation: {
    changes: ['5.0.0'],
    label: 'PlayStation',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3df',
    voted: false,
  },
  plug: {
    changes: ['4.2', '5.0.0', '5.12.0'],
    label: 'Plug',
    search: {
      terms: ['connect', 'electric', 'online', 'power'],
    },
    styles: ['solid'],
    unicode: 'f1e6',
    voted: false,
  },
  plus: {
    changes: ['1', '5.0.0', '5.0.13'],
    label: 'plus',
    search: {
      terms: ['add', 'create', 'expand', 'new', 'positive', 'shape'],
    },
    styles: ['solid'],
    unicode: 'f067',
    voted: false,
  },
  'plus-circle': {
    changes: ['1', '5.0.0'],
    label: 'Plus Circle',
    search: {
      terms: ['add', 'create', 'expand', 'new', 'positive', 'shape'],
    },
    styles: ['solid'],
    unicode: 'f055',
    voted: false,
  },
  'plus-square': {
    changes: ['3', '5.0.0'],
    label: 'Plus Square',
    search: {
      terms: ['add', 'create', 'expand', 'new', 'positive', 'shape'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f0fe',
    voted: false,
  },
  podcast: {
    changes: ['4.7', '5.0.0'],
    label: 'Podcast',
    search: {
      terms: ['audio', 'broadcast', 'music', 'sound'],
    },
    styles: ['solid'],
    unicode: 'f2ce',
    voted: false,
  },
  poll: {
    changes: ['5.3.0', '5.10.1'],
    label: 'Poll',
    search: {
      terms: ['results', 'survey', 'trend', 'vote', 'voting'],
    },
    styles: ['solid'],
    unicode: 'f681',
    voted: false,
  },
  'poll-h': {
    changes: ['5.3.0', '5.10.1'],
    label: 'Poll H',
    search: {
      terms: ['results', 'survey', 'trend', 'vote', 'voting'],
    },
    styles: ['solid'],
    unicode: 'f682',
    voted: false,
  },
  poo: {
    changes: ['5.0.0', '5.0.9'],
    label: 'Poo',
    search: {
      terms: ['crap', 'poop', 'shit', 'smile', 'turd'],
    },
    styles: ['solid'],
    unicode: 'f2fe',
    voted: false,
  },
  'poo-storm': {
    changes: ['5.5.0'],
    label: 'Poo Storm',
    search: {
      terms: [
        'bolt',
        'cloud',
        'euphemism',
        'lightning',
        'mess',
        'poop',
        'shit',
        'turd',
      ],
    },
    styles: ['solid'],
    unicode: 'f75a',
    voted: false,
  },
  poop: {
    changes: ['5.2.0'],
    label: 'Poop',
    search: {
      terms: ['crap', 'poop', 'shit', 'smile', 'turd'],
    },
    styles: ['solid'],
    unicode: 'f619',
    voted: false,
  },
  portrait: {
    changes: ['5.0.0', '5.0.3'],
    label: 'Portrait',
    search: {
      terms: ['id', 'image', 'photo', 'picture', 'selfie'],
    },
    styles: ['solid'],
    unicode: 'f3e0',
    voted: false,
  },
  'pound-sign': {
    changes: ['3.2', '5.0.0'],
    label: 'Pound Sign',
    search: {
      terms: ['currency', 'gbp', 'money'],
    },
    styles: ['solid'],
    unicode: 'f154',
    voted: false,
  },
  'power-off': {
    changes: ['1', '5.0.0'],
    label: 'Power Off',
    search: {
      terms: ['cancel', 'computer', 'on', 'reboot', 'restart'],
    },
    styles: ['solid'],
    unicode: 'f011',
    voted: false,
  },
  pray: {
    changes: ['5.3.0'],
    label: 'Pray',
    search: {
      terms: ['kneel', 'preach', 'religion', 'worship'],
    },
    styles: ['solid'],
    unicode: 'f683',
    voted: false,
  },
  'praying-hands': {
    changes: ['5.3.0'],
    label: 'Praying Hands',
    search: {
      terms: ['kneel', 'preach', 'religion', 'worship'],
    },
    styles: ['solid'],
    unicode: 'f684',
    voted: false,
  },
  prescription: {
    changes: ['5.1.0'],
    label: 'Prescription',
    search: {
      terms: ['drugs', 'medical', 'medicine', 'pharmacy', 'rx'],
    },
    styles: ['solid'],
    unicode: 'f5b1',
    voted: false,
  },
  'prescription-bottle': {
    changes: ['5.0.7'],
    label: 'Prescription Bottle',
    search: {
      terms: ['drugs', 'medical', 'medicine', 'pharmacy', 'rx'],
    },
    styles: ['solid'],
    unicode: 'f485',
    voted: false,
  },
  'prescription-bottle-alt': {
    changes: ['5.0.7'],
    label: 'Alternate Prescription Bottle',
    search: {
      terms: ['drugs', 'medical', 'medicine', 'pharmacy', 'rx'],
    },
    styles: ['solid'],
    unicode: 'f486',
    voted: false,
  },
  print: {
    changes: ['1', '5.0.0', '5.3.0', '5.11.0'],
    label: 'print',
    search: {
      terms: ['business', 'copy', 'document', 'office', 'paper'],
    },
    styles: ['solid'],
    unicode: 'f02f',
    voted: false,
  },
  procedures: {
    changes: ['5.0.7'],
    label: 'Procedures',
    search: {
      terms: [
        'EKG',
        'bed',
        'electrocardiogram',
        'health',
        'hospital',
        'life',
        'patient',
        'vital',
      ],
    },
    styles: ['solid'],
    unicode: 'f487',
    voted: false,
  },
  'product-hunt': {
    changes: ['4.5', '5.0.0'],
    label: 'Product Hunt',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f288',
    voted: false,
  },
  'project-diagram': {
    changes: ['5.0.13'],
    label: 'Project Diagram',
    search: {
      terms: ['chart', 'graph', 'network', 'pert'],
    },
    styles: ['solid'],
    unicode: 'f542',
    voted: false,
  },
  'pump-medical': {
    changes: ['5.13.0'],
    label: 'Pump Medical',
    search: {
      terms: [
        'anti-bacterial',
        'clean',
        'covid-19',
        'disinfect',
        'hygiene',
        'medical grade',
        'sanitizer',
        'soap',
      ],
    },
    styles: ['solid'],
    unicode: 'f96a',
    voted: false,
  },
  'pump-soap': {
    changes: ['5.13.0'],
    label: 'Pump Soap',
    search: {
      terms: [
        'anti-bacterial',
        'clean',
        'covid-19',
        'disinfect',
        'hygiene',
        'sanitizer',
        'soap',
      ],
    },
    styles: ['solid'],
    unicode: 'f96b',
    voted: false,
  },
  pushed: {
    changes: ['5.0.0'],
    label: 'Pushed',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3e1',
    voted: false,
  },
  'puzzle-piece': {
    changes: ['3.1', '5.0.0'],
    label: 'Puzzle Piece',
    search: {
      terms: ['add-on', 'addon', 'game', 'section'],
    },
    styles: ['solid'],
    unicode: 'f12e',
    voted: false,
  },
  python: {
    changes: ['5.0.0'],
    label: 'Python',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3e2',
    voted: false,
  },
  qq: {
    changes: ['4.1', '5.0.0'],
    label: 'QQ',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1d6',
    voted: false,
  },
  qrcode: {
    changes: ['1', '5.0.0', '5.10.1'],
    label: 'qrcode',
    search: {
      terms: ['barcode', 'info', 'information', 'scan'],
    },
    styles: ['solid'],
    unicode: 'f029',
    voted: false,
  },
  question: {
    changes: ['3.1', '5.0.0'],
    label: 'Question',
    search: {
      terms: ['help', 'information', 'support', 'unknown'],
    },
    styles: ['solid'],
    unicode: 'f128',
    voted: false,
  },
  'question-circle': {
    changes: ['1', '5.0.0'],
    label: 'Question Circle',
    search: {
      terms: ['help', 'information', 'support', 'unknown'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f059',
    voted: false,
  },
  quidditch: {
    changes: ['5.0.5'],
    label: 'Quidditch',
    search: {
      terms: [
        'ball',
        'bludger',
        'broom',
        'golden snitch',
        'harry potter',
        'hogwarts',
        'quaffle',
        'sport',
        'wizard',
      ],
    },
    styles: ['solid'],
    unicode: 'f458',
    voted: false,
  },
  quinscape: {
    changes: ['5.0.5', '5.7.0', '5.8.0'],
    label: 'QuinScape',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f459',
    voted: false,
  },
  quora: {
    changes: ['4.7', '5.0.0'],
    label: 'Quora',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f2c4',
    voted: false,
  },
  'quote-left': {
    changes: ['3', '5.0.0', '5.0.9'],
    label: 'quote-left',
    search: {
      terms: ['mention', 'note', 'phrase', 'text', 'type'],
    },
    styles: ['solid'],
    unicode: 'f10d',
    voted: false,
  },
  'quote-right': {
    changes: ['3', '5.0.0', '5.0.9'],
    label: 'quote-right',
    search: {
      terms: ['mention', 'note', 'phrase', 'text', 'type'],
    },
    styles: ['solid'],
    unicode: 'f10e',
    voted: false,
  },
  quran: {
    changes: ['5.3.0'],
    label: 'Quran',
    search: {
      terms: ['book', 'islam', 'muslim', 'religion'],
    },
    styles: ['solid'],
    unicode: 'f687',
    voted: false,
  },
  'r-project': {
    changes: ['5.0.11', '5.0.12'],
    label: 'R Project',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f4f7',
    voted: true,
  },
  radiation: {
    changes: ['5.6.0', '5.8.2', '5.11.0', '5.11.1'],
    label: 'Radiation',
    search: {
      terms: [
        'danger',
        'dangerous',
        'deadly',
        'hazard',
        'nuclear',
        'radioactive',
        'warning',
      ],
    },
    styles: ['solid'],
    unicode: 'f7b9',
    voted: true,
  },
  'radiation-alt': {
    changes: ['5.6.0', '5.8.2', '5.11.0', '5.11.1'],
    label: 'Alternate Radiation',
    search: {
      terms: [
        'danger',
        'dangerous',
        'deadly',
        'hazard',
        'nuclear',
        'radioactive',
        'warning',
      ],
    },
    styles: ['solid'],
    unicode: 'f7ba',
    voted: true,
  },
  rainbow: {
    changes: ['5.5.0', '5.10.1'],
    label: 'Rainbow',
    search: {
      terms: ['gold', 'leprechaun', 'prism', 'rain', 'sky'],
    },
    styles: ['solid'],
    unicode: 'f75b',
    voted: false,
  },
  random: {
    changes: ['1', '5.0.0'],
    label: 'random',
    search: {
      terms: ['arrows', 'shuffle', 'sort', 'swap', 'switch', 'transfer'],
    },
    styles: ['solid'],
    unicode: 'f074',
    voted: false,
  },
  'raspberry-pi': {
    changes: ['5.6.0'],
    label: 'Raspberry Pi',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f7bb',
    voted: true,
  },
  ravelry: {
    changes: ['4.7', '5.0.0'],
    label: 'Ravelry',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f2d9',
    voted: false,
  },
  react: {
    changes: ['5.0.0'],
    label: 'React',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f41b',
    voted: false,
  },
  reacteurope: {
    changes: ['5.5.0', '5.8.0'],
    label: 'ReactEurope',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f75d',
    voted: false,
  },
  readme: {
    changes: ['5.0.9', '5.0.10'],
    label: 'ReadMe',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f4d5',
    voted: false,
  },
  rebel: {
    changes: ['4.1', '5.0.0'],
    label: 'Rebel Alliance',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1d0',
    voted: false,
  },
  receipt: {
    changes: ['5.0.13'],
    label: 'Receipt',
    search: {
      terms: ['check', 'invoice', 'money', 'pay', 'table'],
    },
    styles: ['solid'],
    unicode: 'f543',
    voted: true,
  },
  'record-vinyl': {
    changes: ['5.11.0'],
    label: 'Record Vinyl',
    search: {
      terms: ['LP', 'album', 'analog', 'music', 'phonograph', 'sound'],
    },
    styles: ['solid'],
    unicode: 'f8d9',
    voted: false,
  },
  recycle: {
    changes: ['4.1', '5.0.0'],
    label: 'Recycle',
    search: {
      terms: ['Waste', 'compost', 'garbage', 'reuse', 'trash'],
    },
    styles: ['solid'],
    unicode: 'f1b8',
    voted: false,
  },
  'red-river': {
    changes: ['5.0.0'],
    label: 'red river',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3e3',
    voted: false,
  },
  reddit: {
    changes: ['4.1', '5.0.0'],
    label: 'reddit Logo',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1a1',
    voted: false,
  },
  'reddit-alien': {
    changes: ['4.5', '5.0.0'],
    label: 'reddit Alien',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f281',
    voted: false,
  },
  'reddit-square': {
    changes: ['4.1', '5.0.0'],
    label: 'reddit Square',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1a2',
    voted: false,
  },
  redhat: {
    changes: ['5.6.0', '5.8.2'],
    label: 'Redhat',
    search: {
      terms: ['linux', 'operating system', 'os'],
    },
    styles: ['brands'],
    unicode: 'f7bc',
    voted: true,
  },
  redo: {
    changes: ['1', '5.0.0', '5.8.0'],
    label: 'Redo',
    search: {
      terms: ['forward', 'refresh', 'reload', 'repeat'],
    },
    styles: ['solid'],
    unicode: 'f01e',
    voted: false,
  },
  'redo-alt': {
    changes: ['5.0.0'],
    label: 'Alternate Redo',
    search: {
      terms: ['forward', 'refresh', 'reload', 'repeat'],
    },
    styles: ['solid'],
    unicode: 'f2f9',
    voted: false,
  },
  registered: {
    changes: ['4.4', '5.0.0', '5.10.1'],
    label: 'Registered Trademark',
    search: {
      terms: ['copyright', 'mark', 'trademark'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f25d',
    voted: false,
  },
  'remove-format': {
    changes: ['5.9.0'],
    label: 'Remove Format',
    search: {
      terms: ['cancel', 'font', 'format', 'remove', 'style', 'text'],
    },
    styles: ['solid'],
    unicode: 'f87d',
    voted: false,
  },
  renren: {
    changes: ['3.2', '5.0.0'],
    label: 'Renren',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f18b',
    voted: false,
  },
  reply: {
    changes: ['3', '5.0.0'],
    label: 'Reply',
    search: {
      terms: ['mail', 'message', 'respond'],
    },
    styles: ['solid'],
    unicode: 'f3e5',
    voted: false,
  },
  'reply-all': {
    changes: ['3.1', '5.0.0'],
    label: 'reply-all',
    search: {
      terms: ['mail', 'message', 'respond'],
    },
    styles: ['solid'],
    unicode: 'f122',
    voted: false,
  },
  replyd: {
    changes: ['5.0.0'],
    label: 'replyd',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3e6',
    voted: false,
  },
  republican: {
    changes: ['5.5.0'],
    label: 'Republican',
    search: {
      terms: [
        'american',
        'conservative',
        'election',
        'elephant',
        'politics',
        'republican party',
        'right',
        'right-wing',
        'usa',
      ],
    },
    styles: ['solid'],
    unicode: 'f75e',
    voted: false,
  },
  researchgate: {
    changes: ['5.0.11'],
    label: 'Researchgate',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f4f8',
    voted: true,
  },
  resolving: {
    changes: ['5.0.0'],
    label: 'Resolving',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3e7',
    voted: false,
  },
  restroom: {
    changes: ['5.6.0'],
    label: 'Restroom',
    search: {
      terms: ['bathroom', 'john', 'loo', 'potty', 'washroom', 'waste', 'wc'],
    },
    styles: ['solid'],
    unicode: 'f7bd',
    voted: true,
  },
  retweet: {
    changes: ['1', '5.0.0'],
    label: 'Retweet',
    search: {
      terms: ['refresh', 'reload', 'share', 'swap'],
    },
    styles: ['solid'],
    unicode: 'f079',
    voted: false,
  },
  rev: {
    changes: ['5.1.0', '5.1.1', '5.8.0'],
    label: 'Rev.io',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f5b2',
    voted: false,
  },
  ribbon: {
    changes: ['5.0.9'],
    label: 'Ribbon',
    search: {
      terms: ['badge', 'cause', 'lapel', 'pin'],
    },
    styles: ['solid'],
    unicode: 'f4d6',
    voted: false,
  },
  ring: {
    changes: ['5.4.0'],
    label: 'Ring',
    search: {
      terms: [
        'Dungeons & Dragons',
        'Gollum',
        'band',
        'binding',
        'd&d',
        'dnd',
        'engagement',
        'fantasy',
        'gold',
        'jewelry',
        'marriage',
        'precious',
      ],
    },
    styles: ['solid'],
    unicode: 'f70b',
    voted: false,
  },
  road: {
    changes: ['1', '5.0.0', '5.2.0'],
    label: 'road',
    search: {
      terms: ['highway', 'map', 'pavement', 'route', 'street', 'travel'],
    },
    styles: ['solid'],
    unicode: 'f018',
    voted: false,
  },
  robot: {
    changes: ['5.0.13', '5.12.0'],
    label: 'Robot',
    search: {
      terms: ['android', 'automate', 'computer', 'cyborg'],
    },
    styles: ['solid'],
    unicode: 'f544',
    voted: true,
  },
  rocket: {
    changes: ['3.1', '5.0.0', '5.7.0', '5.12.0'],
    label: 'rocket',
    search: {
      terms: ['aircraft', 'app', 'jet', 'launch', 'nasa', 'space'],
    },
    styles: ['solid'],
    unicode: 'f135',
    voted: false,
  },
  rocketchat: {
    changes: ['5.0.0', '5.4.2', '5.8.0'],
    label: 'Rocket.Chat',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3e8',
    voted: false,
  },
  rockrms: {
    changes: ['5.0.0'],
    label: 'Rockrms',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3e9',
    voted: false,
  },
  route: {
    changes: ['5.0.9', '5.2.0'],
    label: 'Route',
    search: {
      terms: ['directions', 'navigation', 'travel'],
    },
    styles: ['solid'],
    unicode: 'f4d7',
    voted: false,
  },
  rss: {
    changes: ['2', '5.0.0'],
    label: 'rss',
    search: {
      terms: ['blog', 'feed', 'journal', 'news', 'writing'],
    },
    styles: ['solid'],
    unicode: 'f09e',
    voted: false,
  },
  'rss-square': {
    changes: ['3.1', '5.0.0'],
    label: 'RSS Square',
    search: {
      terms: ['blog', 'feed', 'journal', 'news', 'writing'],
    },
    styles: ['solid'],
    unicode: 'f143',
    voted: false,
  },
  'ruble-sign': {
    changes: ['4', '5.0.0'],
    label: 'Ruble Sign',
    search: {
      terms: ['currency', 'money', 'rub'],
    },
    styles: ['solid'],
    unicode: 'f158',
    voted: false,
  },
  ruler: {
    changes: ['5.0.13'],
    label: 'Ruler',
    search: {
      terms: ['design', 'draft', 'length', 'measure', 'planning'],
    },
    styles: ['solid'],
    unicode: 'f545',
    voted: true,
  },
  'ruler-combined': {
    changes: ['5.0.13'],
    label: 'Ruler Combined',
    search: {
      terms: ['design', 'draft', 'length', 'measure', 'planning'],
    },
    styles: ['solid'],
    unicode: 'f546',
    voted: true,
  },
  'ruler-horizontal': {
    changes: ['5.0.13'],
    label: 'Ruler Horizontal',
    search: {
      terms: ['design', 'draft', 'length', 'measure', 'planning'],
    },
    styles: ['solid'],
    unicode: 'f547',
    voted: true,
  },
  'ruler-vertical': {
    changes: ['5.0.13'],
    label: 'Ruler Vertical',
    search: {
      terms: ['design', 'draft', 'length', 'measure', 'planning'],
    },
    styles: ['solid'],
    unicode: 'f548',
    voted: true,
  },
  running: {
    changes: ['5.4.0', '5.11.0'],
    label: 'Running',
    search: {
      terms: ['exercise', 'health', 'jog', 'person', 'run', 'sport', 'sprint'],
    },
    styles: ['solid'],
    unicode: 'f70c',
    voted: true,
  },
  'rupee-sign': {
    changes: ['3.2', '5.0.0'],
    label: 'Indian Rupee Sign',
    search: {
      terms: ['currency', 'indian', 'inr', 'money'],
    },
    styles: ['solid'],
    unicode: 'f156',
    voted: false,
  },
  rust: {
    changes: ['5.13.1'],
    label: 'Rust',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f97a',
    voted: true,
  },
  'sad-cry': {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Crying Face',
    search: {
      terms: ['emoticon', 'face', 'tear', 'tears'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f5b3',
    voted: false,
  },
  'sad-tear': {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Loudly Crying Face',
    search: {
      terms: ['emoticon', 'face', 'tear', 'tears'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f5b4',
    voted: false,
  },
  safari: {
    changes: ['4.4', '5.0.0', '5.12.0'],
    label: 'Safari',
    search: {
      terms: ['browser'],
    },
    styles: ['brands'],
    unicode: 'f267',
    voted: false,
  },
  salesforce: {
    changes: ['5.8.0'],
    label: 'Salesforce',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f83b',
  },
  sass: {
    changes: ['5.0.0', '5.8.0'],
    label: 'Sass',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f41e',
    voted: false,
  },
  satellite: {
    changes: ['5.6.0', '5.10.1', '5.12.0'],
    label: 'Satellite',
    search: {
      terms: ['communications', 'hardware', 'orbit', 'space'],
    },
    styles: ['solid'],
    unicode: 'f7bf',
    voted: true,
  },
  'satellite-dish': {
    changes: ['5.6.0', '5.12.0'],
    label: 'Satellite Dish',
    search: {
      terms: [
        'SETI',
        'communications',
        'hardware',
        'receiver',
        'saucer',
        'signal',
        'space',
      ],
    },
    styles: ['solid'],
    unicode: 'f7c0',
    voted: true,
  },
  save: {
    changes: ['2', '5.0.0', '5.10.2'],
    label: 'Save',
    search: {
      terms: ['disk', 'download', 'floppy', 'floppy-o'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f0c7',
    voted: false,
  },
  schlix: {
    changes: ['5.0.0'],
    label: 'SCHLIX',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3ea',
    voted: false,
  },
  school: {
    changes: ['5.0.13'],
    label: 'School',
    search: {
      terms: ['building', 'education', 'learn', 'student', 'teacher'],
    },
    styles: ['solid'],
    unicode: 'f549',
    voted: true,
  },
  screwdriver: {
    changes: ['5.0.13'],
    label: 'Screwdriver',
    search: {
      terms: ['admin', 'fix', 'mechanic', 'repair', 'settings', 'tool'],
    },
    styles: ['solid'],
    unicode: 'f54a',
    voted: true,
  },
  scribd: {
    changes: ['4.5', '5.0.0'],
    label: 'Scribd',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f28a',
    voted: false,
  },
  scroll: {
    changes: ['5.4.0', '5.10.2'],
    label: 'Scroll',
    search: {
      terms: [
        'Dungeons & Dragons',
        'announcement',
        'd&d',
        'dnd',
        'fantasy',
        'paper',
        'script',
      ],
    },
    styles: ['solid'],
    unicode: 'f70e',
    voted: false,
  },
  'sd-card': {
    changes: ['5.6.0'],
    label: 'Sd Card',
    search: {
      terms: ['image', 'memory', 'photo', 'save'],
    },
    styles: ['solid'],
    unicode: 'f7c2',
    voted: true,
  },
  search: {
    changes: ['1', '5.0.0'],
    label: 'Search',
    search: {
      terms: ['bigger', 'enlarge', 'find', 'magnify', 'preview', 'zoom'],
    },
    styles: ['solid'],
    unicode: 'f002',
    voted: false,
  },
  'search-dollar': {
    changes: ['5.3.0'],
    label: 'Search Dollar',
    search: {
      terms: [
        'bigger',
        'enlarge',
        'find',
        'magnify',
        'money',
        'preview',
        'zoom',
      ],
    },
    styles: ['solid'],
    unicode: 'f688',
    voted: false,
  },
  'search-location': {
    changes: ['5.3.0'],
    label: 'Search Location',
    search: {
      terms: ['bigger', 'enlarge', 'find', 'magnify', 'preview', 'zoom'],
    },
    styles: ['solid'],
    unicode: 'f689',
    voted: false,
  },
  'search-minus': {
    changes: ['1', '5.0.0', '5.0.13'],
    label: 'Search Minus',
    search: {
      terms: ['minify', 'negative', 'smaller', 'zoom', 'zoom out'],
    },
    styles: ['solid'],
    unicode: 'f010',
    voted: false,
  },
  'search-plus': {
    changes: ['1', '5.0.0'],
    label: 'Search Plus',
    search: {
      terms: ['bigger', 'enlarge', 'magnify', 'positive', 'zoom', 'zoom in'],
    },
    styles: ['solid'],
    unicode: 'f00e',
    voted: false,
  },
  searchengin: {
    changes: ['5.0.0'],
    label: 'Searchengin',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3eb',
    voted: false,
  },
  seedling: {
    changes: ['5.0.9'],
    label: 'Seedling',
    search: {
      terms: ['flora', 'grow', 'plant', 'vegan'],
    },
    styles: ['solid'],
    unicode: 'f4d8',
    voted: false,
  },
  sellcast: {
    changes: ['5.0.0'],
    label: 'Sellcast',
    search: {
      terms: ['eercast'],
    },
    styles: ['brands'],
    unicode: 'f2da',
    voted: false,
  },
  sellsy: {
    changes: ['4.3', '5.0.0'],
    label: 'Sellsy',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f213',
    voted: false,
  },
  server: {
    changes: ['4.3', '5.0.0'],
    label: 'Server',
    search: {
      terms: ['computer', 'cpu', 'database', 'hardware', 'network'],
    },
    styles: ['solid'],
    unicode: 'f233',
    voted: false,
  },
  servicestack: {
    changes: ['5.0.0'],
    label: 'Servicestack',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3ec',
    voted: false,
  },
  shapes: {
    changes: ['5.2.0', '5.12.0'],
    label: 'Shapes',
    search: {
      terms: ['blocks', 'build', 'circle', 'square', 'triangle'],
    },
    styles: ['solid'],
    unicode: 'f61f',
    voted: false,
  },
  share: {
    changes: ['1', '5.0.0'],
    label: 'Share',
    search: {
      terms: ['forward', 'save', 'send', 'social'],
    },
    styles: ['solid'],
    unicode: 'f064',
    voted: false,
  },
  'share-alt': {
    changes: ['4.1', '5.0.0'],
    label: 'Alternate Share',
    search: {
      terms: ['forward', 'save', 'send', 'social'],
    },
    styles: ['solid'],
    unicode: 'f1e0',
    voted: false,
  },
  'share-alt-square': {
    changes: ['4.1', '5.0.0'],
    label: 'Alternate Share Square',
    search: {
      terms: ['forward', 'save', 'send', 'social'],
    },
    styles: ['solid'],
    unicode: 'f1e1',
    voted: false,
  },
  'share-square': {
    changes: ['3.1', '5.0.0'],
    label: 'Share Square',
    search: {
      terms: ['forward', 'save', 'send', 'social'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f14d',
    voted: false,
  },
  'shekel-sign': {
    changes: ['4.2', '5.0.0'],
    label: 'Shekel Sign',
    search: {
      terms: ['currency', 'ils', 'money'],
    },
    styles: ['solid'],
    unicode: 'f20b',
    voted: true,
  },
  'shield-alt': {
    changes: ['5.0.0'],
    label: 'Alternate Shield',
    search: {
      terms: ['achievement', 'award', 'block', 'defend', 'security', 'winner'],
    },
    styles: ['solid'],
    unicode: 'f3ed',
    voted: false,
  },
  'shield-virus': {
    changes: ['5.13.0'],
    label: 'Shield Virus',
    search: {
      terms: ['antibodies', 'barrier', 'covid-19', 'health', 'protect'],
    },
    styles: ['solid'],
    unicode: 'f96c',
    voted: false,
  },
  ship: {
    changes: ['4.3', '5.0.0', '5.10.2', '5.11.0', '5.11.1'],
    label: 'Ship',
    search: {
      terms: ['boat', 'sea', 'water'],
    },
    styles: ['solid'],
    unicode: 'f21a',
    voted: false,
  },
  'shipping-fast': {
    changes: ['5.0.7'],
    label: 'Shipping Fast',
    search: {
      terms: ['express', 'fedex', 'mail', 'overnight', 'package', 'ups'],
    },
    styles: ['solid'],
    unicode: 'f48b',
    voted: false,
  },
  shirtsinbulk: {
    changes: ['4.3', '5.0.0', '5.7.0'],
    label: 'Shirts in Bulk',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f214',
    voted: false,
  },
  'shoe-prints': {
    changes: ['5.0.13'],
    label: 'Shoe Prints',
    search: {
      terms: ['feet', 'footprints', 'steps', 'walk'],
    },
    styles: ['solid'],
    unicode: 'f54b',
    voted: true,
  },
  shopify: {
    changes: ['5.12.1'],
    label: 'Shopify',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f957',
    voted: false,
  },
  'shopping-bag': {
    changes: ['4.5', '5.0.0'],
    label: 'Shopping Bag',
    search: {
      terms: ['buy', 'checkout', 'grocery', 'payment', 'purchase'],
    },
    styles: ['solid'],
    unicode: 'f290',
    voted: false,
  },
  'shopping-basket': {
    changes: ['4.5', '5.0.0'],
    label: 'Shopping Basket',
    search: {
      terms: ['buy', 'checkout', 'grocery', 'payment', 'purchase'],
    },
    styles: ['solid'],
    unicode: 'f291',
    voted: false,
  },
  'shopping-cart': {
    changes: ['1', '5.0.0'],
    label: 'shopping-cart',
    search: {
      terms: ['buy', 'checkout', 'grocery', 'payment', 'purchase'],
    },
    styles: ['solid'],
    unicode: 'f07a',
    voted: false,
  },
  shopware: {
    changes: ['5.1.0', '5.8.0'],
    label: 'Shopware',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f5b5',
    voted: false,
  },
  shower: {
    changes: ['4.7', '5.0.0', '5.12.0'],
    label: 'Shower',
    search: {
      terms: ['bath', 'clean', 'faucet', 'water'],
    },
    styles: ['solid'],
    unicode: 'f2cc',
    voted: false,
  },
  'shuttle-van': {
    changes: ['5.1.0'],
    label: 'Shuttle Van',
    search: {
      terms: [
        'airport',
        'machine',
        'public-transportation',
        'transportation',
        'travel',
        'vehicle',
      ],
    },
    styles: ['solid'],
    unicode: 'f5b6',
    voted: false,
  },
  sign: {
    changes: ['5.0.9'],
    label: 'Sign',
    search: {
      terms: ['directions', 'real estate', 'signage', 'wayfinding'],
    },
    styles: ['solid'],
    unicode: 'f4d9',
    voted: false,
  },
  'sign-in-alt': {
    changes: ['5.0.0'],
    label: 'Alternate Sign In',
    search: {
      terms: [
        'arrow',
        'enter',
        'join',
        'log in',
        'login',
        'sign in',
        'sign up',
        'sign-in',
        'signin',
        'signup',
      ],
    },
    styles: ['solid'],
    unicode: 'f2f6',
    voted: false,
  },
  'sign-language': {
    changes: ['4.6', '5.0.0', '5.10.2'],
    label: 'Sign Language',
    search: {
      terms: ['Translate', 'asl', 'deaf', 'hands'],
    },
    styles: ['solid'],
    unicode: 'f2a7',
    voted: false,
  },
  'sign-out-alt': {
    changes: ['5.0.0'],
    label: 'Alternate Sign Out',
    search: {
      terms: ['arrow', 'exit', 'leave', 'log out', 'logout', 'sign-out'],
    },
    styles: ['solid'],
    unicode: 'f2f5',
    voted: false,
  },
  signal: {
    changes: ['1', '5.0.0', '5.3.0', '5.10.1'],
    label: 'signal',
    search: {
      terms: ['bars', 'graph', 'online', 'reception', 'status'],
    },
    styles: ['solid'],
    unicode: 'f012',
    voted: false,
  },
  signature: {
    changes: ['5.1.0', '5.6.0'],
    label: 'Signature',
    search: {
      terms: ['John Hancock', 'cursive', 'name', 'writing'],
    },
    styles: ['solid'],
    unicode: 'f5b7',
    voted: true,
  },
  'sim-card': {
    changes: ['5.6.0', '5.8.2', '5.10.2'],
    label: 'SIM Card',
    search: {
      terms: [
        'hard drive',
        'hardware',
        'portable',
        'storage',
        'technology',
        'tiny',
      ],
    },
    styles: ['solid'],
    unicode: 'f7c4',
    voted: true,
  },
  simplybuilt: {
    changes: ['4.3', '5.0.0'],
    label: 'SimplyBuilt',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f215',
    voted: false,
  },
  sink: {
    changes: ['5.13.0', '5.13.1'],
    label: 'Sink',
    search: {
      terms: ['bathroom', 'covid-19', 'faucet', 'kitchen', 'wash'],
    },
    styles: ['solid'],
    unicode: 'f96d',
    voted: false,
  },
  sistrix: {
    changes: ['5.0.0'],
    label: 'SISTRIX',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3ee',
    voted: false,
  },
  sitemap: {
    changes: ['2', '5.0.0', '5.0.13'],
    label: 'Sitemap',
    search: {
      terms: [
        'directory',
        'hierarchy',
        'ia',
        'information architecture',
        'organization',
      ],
    },
    styles: ['solid'],
    unicode: 'f0e8',
    voted: false,
  },
  sith: {
    changes: ['5.0.12'],
    label: 'Sith',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f512',
    voted: false,
  },
  skating: {
    changes: ['5.6.0'],
    label: 'Skating',
    search: {
      terms: [
        'activity',
        'figure skating',
        'fitness',
        'ice',
        'person',
        'winter',
      ],
    },
    styles: ['solid'],
    unicode: 'f7c5',
  },
  sketch: {
    changes: ['5.6.0', '5.8.0'],
    label: 'Sketch',
    search: {
      terms: ['app', 'design', 'interface'],
    },
    styles: ['brands'],
    unicode: 'f7c6',
    voted: false,
  },
  skiing: {
    changes: ['5.6.0'],
    label: 'Skiing',
    search: {
      terms: [
        'activity',
        'downhill',
        'fast',
        'fitness',
        'olympics',
        'outdoors',
        'person',
        'seasonal',
        'slalom',
      ],
    },
    styles: ['solid'],
    unicode: 'f7c9',
  },
  'skiing-nordic': {
    changes: ['5.6.0'],
    label: 'Skiing Nordic',
    search: {
      terms: [
        'activity',
        'cross country',
        'fitness',
        'outdoors',
        'person',
        'seasonal',
      ],
    },
    styles: ['solid'],
    unicode: 'f7ca',
  },
  skull: {
    changes: ['5.0.13', '5.10.2'],
    label: 'Skull',
    search: {
      terms: ['bones', 'skeleton', 'x-ray', 'yorick'],
    },
    styles: ['solid'],
    unicode: 'f54c',
    voted: true,
  },
  'skull-crossbones': {
    changes: ['5.4.0', '5.10.2'],
    label: 'Skull & Crossbones',
    search: {
      terms: [
        'Dungeons & Dragons',
        'alert',
        'bones',
        'd&d',
        'danger',
        'dead',
        'deadly',
        'death',
        'dnd',
        'fantasy',
        'halloween',
        'holiday',
        'jolly-roger',
        'pirate',
        'poison',
        'skeleton',
        'warning',
      ],
    },
    styles: ['solid'],
    unicode: 'f714',
    voted: false,
  },
  skyatlas: {
    changes: ['4.3', '5.0.0', '5.0.3'],
    label: 'skyatlas',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f216',
    voted: false,
  },
  skype: {
    changes: ['3.2', '5.0.0'],
    label: 'Skype',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f17e',
    voted: false,
  },
  slack: {
    changes: ['4.1', '5.0.0', '5.7.0'],
    label: 'Slack Logo',
    search: {
      terms: ['anchor', 'hash', 'hashtag'],
    },
    styles: ['brands'],
    unicode: 'f198',
    voted: false,
  },
  'slack-hash': {
    changes: ['5.0.0'],
    label: 'Slack Hashtag',
    search: {
      terms: ['anchor', 'hash', 'hashtag'],
    },
    styles: ['brands'],
    unicode: 'f3ef',
    voted: false,
  },
  slash: {
    changes: ['5.4.0'],
    label: 'Slash',
    search: {
      terms: ['cancel', 'close', 'mute', 'off', 'stop', 'x'],
    },
    styles: ['solid'],
    unicode: 'f715',
    voted: true,
  },
  sleigh: {
    changes: ['5.6.0'],
    label: 'Sleigh',
    search: {
      terms: [
        'christmas',
        'claus',
        'fly',
        'holiday',
        'santa',
        'sled',
        'snow',
        'xmas',
      ],
    },
    styles: ['solid'],
    unicode: 'f7cc',
  },
  'sliders-h': {
    changes: ['4.1', '5.0.0', '5.0.11'],
    label: 'Horizontal Sliders',
    search: {
      terms: ['adjust', 'settings', 'sliders', 'toggle'],
    },
    styles: ['solid'],
    unicode: 'f1de',
    voted: false,
  },
  slideshare: {
    changes: ['4.2', '5.0.0'],
    label: 'Slideshare',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1e7',
    voted: false,
  },
  smile: {
    changes: ['3.1', '5.0.0', '5.0.9', '5.1.0', '5.11.0', '5.11.1'],
    label: 'Smiling Face',
    search: {
      terms: ['approve', 'emoticon', 'face', 'happy', 'rating', 'satisfied'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f118',
    voted: false,
  },
  'smile-beam': {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Beaming Face With Smiling Eyes',
    search: {
      terms: ['emoticon', 'face', 'happy', 'positive'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f5b8',
    voted: false,
  },
  'smile-wink': {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Winking Face',
    search: {
      terms: ['emoticon', 'face', 'happy', 'hint', 'joke'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f4da',
    voted: false,
  },
  smog: {
    changes: ['5.5.0'],
    label: 'Smog',
    search: {
      terms: ['dragon', 'fog', 'haze', 'pollution', 'smoke', 'weather'],
    },
    styles: ['solid'],
    unicode: 'f75f',
    voted: false,
  },
  smoking: {
    changes: ['5.0.7'],
    label: 'Smoking',
    search: {
      terms: ['cancer', 'cigarette', 'nicotine', 'smoking status', 'tobacco'],
    },
    styles: ['solid'],
    unicode: 'f48d',
    voted: true,
  },
  'smoking-ban': {
    changes: ['5.0.13'],
    label: 'Smoking Ban',
    search: {
      terms: ['ban', 'cancel', 'no smoking', 'non-smoking'],
    },
    styles: ['solid'],
    unicode: 'f54d',
    voted: true,
  },
  sms: {
    changes: ['5.6.0'],
    label: 'SMS',
    search: {
      terms: [
        'chat',
        'conversation',
        'message',
        'mobile',
        'notification',
        'phone',
        'sms',
        'texting',
      ],
    },
    styles: ['solid'],
    unicode: 'f7cd',
    voted: true,
  },
  snapchat: {
    changes: ['4.6', '5.0.0'],
    label: 'Snapchat',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f2ab',
    voted: false,
  },
  'snapchat-ghost': {
    changes: ['4.6', '5.0.0'],
    label: 'Snapchat Ghost',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f2ac',
    voted: false,
  },
  'snapchat-square': {
    changes: ['4.6', '5.0.0'],
    label: 'Snapchat Square',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f2ad',
    voted: false,
  },
  snowboarding: {
    changes: ['5.6.0'],
    label: 'Snowboarding',
    search: {
      terms: ['activity', 'fitness', 'olympics', 'outdoors', 'person'],
    },
    styles: ['solid'],
    unicode: 'f7ce',
  },
  snowflake: {
    changes: ['4.7', '5.0.0', '5.5.0'],
    label: 'Snowflake',
    search: {
      terms: ['precipitation', 'rain', 'winter'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f2dc',
    voted: false,
  },
  snowman: {
    changes: ['5.6.0'],
    label: 'Snowman',
    search: {
      terms: ['decoration', 'frost', 'frosty', 'holiday'],
    },
    styles: ['solid'],
    unicode: 'f7d0',
  },
  snowplow: {
    changes: ['5.6.0'],
    label: 'Snowplow',
    search: {
      terms: ['clean up', 'cold', 'road', 'storm', 'winter'],
    },
    styles: ['solid'],
    unicode: 'f7d2',
  },
  soap: {
    changes: ['5.13.0'],
    label: 'Soap',
    search: {
      terms: ['bubbles', 'clean', 'covid-19', 'hygiene', 'wash'],
    },
    styles: ['solid'],
    unicode: 'f96e',
    voted: false,
  },
  socks: {
    changes: ['5.3.0', '5.10.2'],
    label: 'Socks',
    search: {
      terms: [
        'business socks',
        'business time',
        'clothing',
        'feet',
        'flight of the conchords',
        'wednesday',
      ],
    },
    styles: ['solid'],
    unicode: 'f696',
    voted: false,
  },
  'solar-panel': {
    changes: ['5.1.0'],
    label: 'Solar Panel',
    search: {
      terms: ['clean', 'eco-friendly', 'energy', 'green', 'sun'],
    },
    styles: ['solid'],
    unicode: 'f5ba',
    voted: false,
  },
  sort: {
    changes: ['2', '5.0.0', '5.10.2'],
    label: 'Sort',
    search: {
      terms: ['filter', 'order'],
    },
    styles: ['solid'],
    unicode: 'f0dc',
    voted: false,
  },
  'sort-alpha-down': {
    changes: ['3.2', '5.0.0', '5.9.0'],
    label: 'Sort Alphabetical Down',
    search: {
      terms: ['alphabetical', 'arrange', 'filter', 'order', 'sort-alpha-asc'],
    },
    styles: ['solid'],
    unicode: 'f15d',
    voted: false,
  },
  'sort-alpha-down-alt': {
    changes: ['5.9.0'],
    label: 'Alternate Sort Alphabetical Down',
    search: {
      terms: ['alphabetical', 'arrange', 'filter', 'order', 'sort-alpha-asc'],
    },
    styles: ['solid'],
    unicode: 'f881',
    voted: false,
  },
  'sort-alpha-up': {
    changes: ['3.2', '5.0.0', '5.9.0'],
    label: 'Sort Alphabetical Up',
    search: {
      terms: ['alphabetical', 'arrange', 'filter', 'order', 'sort-alpha-desc'],
    },
    styles: ['solid'],
    unicode: 'f15e',
    voted: false,
  },
  'sort-alpha-up-alt': {
    changes: ['5.9.0'],
    label: 'Alternate Sort Alphabetical Up',
    search: {
      terms: ['alphabetical', 'arrange', 'filter', 'order', 'sort-alpha-desc'],
    },
    styles: ['solid'],
    unicode: 'f882',
    voted: false,
  },
  'sort-amount-down': {
    changes: ['3.2', '5.0.0', '5.9.0'],
    label: 'Sort Amount Down',
    search: {
      terms: ['arrange', 'filter', 'number', 'order', 'sort-amount-asc'],
    },
    styles: ['solid'],
    unicode: 'f160',
    voted: false,
  },
  'sort-amount-down-alt': {
    changes: ['5.9.0'],
    label: 'Alternate Sort Amount Down',
    search: {
      terms: ['arrange', 'filter', 'order', 'sort-amount-asc'],
    },
    styles: ['solid'],
    unicode: 'f884',
    voted: false,
  },
  'sort-amount-up': {
    changes: ['3.2', '5.0.0', '5.9.0'],
    label: 'Sort Amount Up',
    search: {
      terms: ['arrange', 'filter', 'order', 'sort-amount-desc'],
    },
    styles: ['solid'],
    unicode: 'f161',
    voted: false,
  },
  'sort-amount-up-alt': {
    changes: ['5.9.0'],
    label: 'Alternate Sort Amount Up',
    search: {
      terms: ['arrange', 'filter', 'order', 'sort-amount-desc'],
    },
    styles: ['solid'],
    unicode: 'f885',
    voted: false,
  },
  'sort-down': {
    changes: ['2', '5.0.0', '5.10.1', '5.10.2'],
    label: 'Sort Down (Descending)',
    search: {
      terms: ['arrow', 'descending', 'filter', 'order', 'sort-desc'],
    },
    styles: ['solid'],
    unicode: 'f0dd',
    voted: false,
  },
  'sort-numeric-down': {
    changes: ['3.2', '5.0.0', '5.9.0'],
    label: 'Sort Numeric Down',
    search: {
      terms: ['arrange', 'filter', 'numbers', 'order', 'sort-numeric-asc'],
    },
    styles: ['solid'],
    unicode: 'f162',
    voted: false,
  },
  'sort-numeric-down-alt': {
    changes: ['5.9.0'],
    label: 'Alternate Sort Numeric Down',
    search: {
      terms: ['arrange', 'filter', 'numbers', 'order', 'sort-numeric-asc'],
    },
    styles: ['solid'],
    unicode: 'f886',
    voted: false,
  },
  'sort-numeric-up': {
    changes: ['3.2', '5.0.0', '5.9.0'],
    label: 'Sort Numeric Up',
    search: {
      terms: ['arrange', 'filter', 'numbers', 'order', 'sort-numeric-desc'],
    },
    styles: ['solid'],
    unicode: 'f163',
    voted: false,
  },
  'sort-numeric-up-alt': {
    changes: ['5.9.0'],
    label: 'Alternate Sort Numeric Up',
    search: {
      terms: ['arrange', 'filter', 'numbers', 'order', 'sort-numeric-desc'],
    },
    styles: ['solid'],
    unicode: 'f887',
    voted: false,
  },
  'sort-up': {
    changes: ['2', '5.0.0', '5.10.1', '5.10.2'],
    label: 'Sort Up (Ascending)',
    search: {
      terms: ['arrow', 'ascending', 'filter', 'order', 'sort-asc'],
    },
    styles: ['solid'],
    unicode: 'f0de',
    voted: false,
  },
  soundcloud: {
    changes: ['4.1', '5.0.0'],
    label: 'SoundCloud',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1be',
    voted: false,
  },
  sourcetree: {
    changes: ['5.6.0', '5.8.0'],
    label: 'Sourcetree',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f7d3',
    voted: true,
  },
  spa: {
    changes: ['5.1.0'],
    label: 'Spa',
    search: {
      terms: ['flora', 'massage', 'mindfulness', 'plant', 'wellness'],
    },
    styles: ['solid'],
    unicode: 'f5bb',
    voted: false,
  },
  'space-shuttle': {
    changes: ['4.1', '5.0.0', '5.10.2'],
    label: 'Space Shuttle',
    search: {
      terms: [
        'astronaut',
        'machine',
        'nasa',
        'rocket',
        'space',
        'transportation',
      ],
    },
    styles: ['solid'],
    unicode: 'f197',
    voted: false,
  },
  speakap: {
    changes: ['5.0.0', '5.4.0', '5.8.0'],
    label: 'Speakap',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3f3',
    voted: false,
  },
  'speaker-deck': {
    changes: ['5.8.0'],
    label: 'Speaker Deck',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f83c',
  },
  'spell-check': {
    changes: ['5.9.0'],
    label: 'Spell Check',
    search: {
      terms: ['dictionary', 'edit', 'editor', 'grammar', 'text'],
    },
    styles: ['solid'],
    unicode: 'f891',
    voted: false,
  },
  spider: {
    changes: ['5.4.0'],
    label: 'Spider',
    search: {
      terms: ['arachnid', 'bug', 'charlotte', 'crawl', 'eight', 'halloween'],
    },
    styles: ['solid'],
    unicode: 'f717',
    voted: true,
  },
  spinner: {
    changes: ['3', '5.0.0', '5.10.2'],
    label: 'Spinner',
    search: {
      terms: ['circle', 'loading', 'progress'],
    },
    styles: ['solid'],
    unicode: 'f110',
    voted: false,
  },
  splotch: {
    changes: ['5.1.0'],
    label: 'Splotch',
    search: {
      terms: ['Ink', 'blob', 'blotch', 'glob', 'stain'],
    },
    styles: ['solid'],
    unicode: 'f5bc',
    voted: false,
  },
  spotify: {
    changes: ['4.1', '5.0.0'],
    label: 'Spotify',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1bc',
    voted: false,
  },
  'spray-can': {
    changes: ['5.1.0'],
    label: 'Spray Can',
    search: {
      terms: ['Paint', 'aerosol', 'design', 'graffiti', 'tag'],
    },
    styles: ['solid'],
    unicode: 'f5bd',
    voted: false,
  },
  square: {
    changes: ['2', '5.0.0', '5.10.1', '5.10.2'],
    label: 'Square',
    search: {
      terms: ['block', 'box', 'shape'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f0c8',
    voted: false,
  },
  'square-full': {
    changes: ['5.0.5', '5.10.2'],
    label: 'Square Full',
    search: {
      terms: ['block', 'box', 'shape'],
    },
    styles: ['solid'],
    unicode: 'f45c',
    voted: false,
  },
  'square-root-alt': {
    changes: ['5.3.0'],
    label: 'Alternate Square Root',
    search: {
      terms: ['arithmetic', 'calculus', 'division', 'math'],
    },
    styles: ['solid'],
    unicode: 'f698',
    voted: false,
  },
  squarespace: {
    changes: ['5.1.0'],
    label: 'Squarespace',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f5be',
    voted: true,
  },
  'stack-exchange': {
    changes: ['4', '5.0.0', '5.0.3'],
    label: 'Stack Exchange',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f18d',
    voted: false,
  },
  'stack-overflow': {
    changes: ['3.2', '5.0.0'],
    label: 'Stack Overflow',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f16c',
    voted: false,
  },
  stackpath: {
    changes: ['5.8.2'],
    label: 'Stackpath',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f842',
  },
  stamp: {
    changes: ['5.1.0', '5.10.2'],
    label: 'Stamp',
    search: {
      terms: ['art', 'certificate', 'imprint', 'rubber', 'seal'],
    },
    styles: ['solid'],
    unicode: 'f5bf',
    voted: false,
  },
  star: {
    changes: ['1', '5.0.0', '5.10.2'],
    label: 'Star',
    search: {
      terms: [
        'achievement',
        'award',
        'favorite',
        'important',
        'night',
        'rating',
        'score',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f005',
    voted: false,
  },
  'star-and-crescent': {
    changes: ['5.3.0'],
    label: 'Star and Crescent',
    search: {
      terms: ['islam', 'muslim', 'religion'],
    },
    styles: ['solid'],
    unicode: 'f699',
    voted: false,
  },
  'star-half': {
    changes: ['1', '5.0.0'],
    label: 'star-half',
    search: {
      terms: [
        'achievement',
        'award',
        'rating',
        'score',
        'star-half-empty',
        'star-half-full',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f089',
    voted: false,
  },
  'star-half-alt': {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Alternate Star Half',
    search: {
      terms: [
        'achievement',
        'award',
        'rating',
        'score',
        'star-half-empty',
        'star-half-full',
      ],
    },
    styles: ['solid'],
    unicode: 'f5c0',
    voted: true,
  },
  'star-of-david': {
    changes: ['5.3.0', '5.11.0', '5.11.1'],
    label: 'Star of David',
    search: {
      terms: ['jewish', 'judaism', 'religion'],
    },
    styles: ['solid'],
    unicode: 'f69a',
    voted: false,
  },
  'star-of-life': {
    changes: ['5.2.0'],
    label: 'Star of Life',
    search: {
      terms: ['doctor', 'emt', 'first aid', 'health', 'medical'],
    },
    styles: ['solid'],
    unicode: 'f621',
    voted: false,
  },
  staylinked: {
    changes: ['5.0.0'],
    label: 'StayLinked',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3f5',
    voted: false,
  },
  steam: {
    changes: ['4.1', '5.0.0'],
    label: 'Steam',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1b6',
    voted: false,
  },
  'steam-square': {
    changes: ['4.1', '5.0.0'],
    label: 'Steam Square',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1b7',
    voted: false,
  },
  'steam-symbol': {
    changes: ['5.0.0'],
    label: 'Steam Symbol',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3f6',
    voted: false,
  },
  'step-backward': {
    changes: ['1', '5.0.0', '5.10.2', '5.11.0', '5.11.1'],
    label: 'step-backward',
    search: {
      terms: ['beginning', 'first', 'previous', 'rewind', 'start'],
    },
    styles: ['solid'],
    unicode: 'f048',
    voted: false,
  },
  'step-forward': {
    changes: ['1', '5.0.0', '5.10.2', '5.11.0', '5.11.1'],
    label: 'step-forward',
    search: {
      terms: ['end', 'last', 'next'],
    },
    styles: ['solid'],
    unicode: 'f051',
    voted: false,
  },
  stethoscope: {
    changes: ['3', '5.0.0', '5.0.7'],
    label: 'Stethoscope',
    search: {
      terms: [
        'covid-19',
        'diagnosis',
        'doctor',
        'general practitioner',
        'hospital',
        'infirmary',
        'medicine',
        'office',
        'outpatient',
      ],
    },
    styles: ['solid'],
    unicode: 'f0f1',
    voted: false,
  },
  'sticker-mule': {
    changes: ['5.0.0', '5.7.0'],
    label: 'Sticker Mule',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3f7',
    voted: false,
  },
  'sticky-note': {
    changes: ['4.4', '5.0.0', '5.10.2'],
    label: 'Sticky Note',
    search: {
      terms: ['message', 'note', 'paper', 'reminder', 'sticker'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f249',
    voted: false,
  },
  stop: {
    changes: ['1', '5.0.0', '5.10.2'],
    label: 'stop',
    search: {
      terms: ['block', 'box', 'square'],
    },
    styles: ['solid'],
    unicode: 'f04d',
    voted: false,
  },
  'stop-circle': {
    changes: ['4.5', '5.0.0'],
    label: 'Stop Circle',
    search: {
      terms: ['block', 'box', 'circle', 'square'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f28d',
    voted: false,
  },
  stopwatch: {
    changes: ['5.0.0', '5.10.2'],
    label: 'Stopwatch',
    search: {
      terms: ['clock', 'reminder', 'time'],
    },
    styles: ['solid'],
    unicode: 'f2f2',
    voted: false,
  },
  'stopwatch-20': {
    changes: ['5.13.0'],
    label: 'Stopwatch 20',
    search: {
      terms: [
        'ABCs',
        'countdown',
        'covid-19',
        'happy birthday',
        'i will survive',
        'reminder',
        'seconds',
        'time',
        'timer',
      ],
    },
    styles: ['solid'],
    unicode: 'f96f',
    voted: false,
  },
  store: {
    changes: ['5.0.13', '5.11.0', '5.11.1'],
    label: 'Store',
    search: {
      terms: ['building', 'buy', 'purchase', 'shopping'],
    },
    styles: ['solid'],
    unicode: 'f54e',
    voted: true,
  },
  'store-alt': {
    changes: ['5.0.13'],
    label: 'Alternate Store',
    search: {
      terms: ['building', 'buy', 'purchase', 'shopping'],
    },
    styles: ['solid'],
    unicode: 'f54f',
    voted: true,
  },
  'store-alt-slash': {
    changes: ['5.13.0'],
    label: 'Alternate Store Slash',
    search: {
      terms: ['building', 'buy', 'closed', 'covid-19', 'purchase', 'shopping'],
    },
    styles: ['solid'],
    unicode: 'f970',
    voted: false,
  },
  'store-slash': {
    changes: ['5.13.0'],
    label: 'Store Slash',
    search: {
      terms: ['building', 'buy', 'closed', 'covid-19', 'purchase', 'shopping'],
    },
    styles: ['solid'],
    unicode: 'f971',
    voted: false,
  },
  strava: {
    changes: ['5.0.0', '5.0.1', '5.7.0', '5.8.0'],
    label: 'Strava',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f428',
    voted: false,
  },
  stream: {
    changes: ['5.0.13'],
    label: 'Stream',
    search: {
      terms: ['flow', 'list', 'timeline'],
    },
    styles: ['solid'],
    unicode: 'f550',
    voted: false,
  },
  'street-view': {
    changes: ['4.3', '5.0.0', '5.2.0'],
    label: 'Street View',
    search: {
      terms: ['directions', 'location', 'map', 'navigation'],
    },
    styles: ['solid'],
    unicode: 'f21d',
    voted: false,
  },
  strikethrough: {
    changes: ['2', '5.0.0', '5.9.0'],
    label: 'Strikethrough',
    search: {
      terms: ['cancel', 'edit', 'font', 'format', 'text', 'type'],
    },
    styles: ['solid'],
    unicode: 'f0cc',
    voted: false,
  },
  stripe: {
    changes: ['5.0.0', '5.0.3'],
    label: 'Stripe',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f429',
    voted: false,
  },
  'stripe-s': {
    changes: ['5.0.1', '5.8.0'],
    label: 'Stripe S',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f42a',
    voted: false,
  },
  stroopwafel: {
    changes: ['5.0.13'],
    label: 'Stroopwafel',
    search: {
      terms: ['caramel', 'cookie', 'dessert', 'sweets', 'waffle'],
    },
    styles: ['solid'],
    unicode: 'f551',
    voted: false,
  },
  studiovinari: {
    changes: ['5.0.0'],
    label: 'Studio Vinari',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3f8',
    voted: false,
  },
  stumbleupon: {
    changes: ['4.1', '5.0.0'],
    label: 'StumbleUpon Logo',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1a4',
    voted: false,
  },
  'stumbleupon-circle': {
    changes: ['4.1', '5.0.0'],
    label: 'StumbleUpon Circle',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1a3',
    voted: false,
  },
  subscript: {
    changes: ['3.1', '5.0.0', '5.9.0', '5.10.2'],
    label: 'subscript',
    search: {
      terms: ['edit', 'font', 'format', 'text', 'type'],
    },
    styles: ['solid'],
    unicode: 'f12c',
    voted: false,
  },
  subway: {
    changes: ['4.3', '5.0.0'],
    label: 'Subway',
    search: {
      terms: ['machine', 'railway', 'train', 'transportation', 'vehicle'],
    },
    styles: ['solid'],
    unicode: 'f239',
    voted: false,
  },
  suitcase: {
    changes: ['3', '5.0.0', '5.0.9'],
    label: 'Suitcase',
    search: {
      terms: ['baggage', 'luggage', 'move', 'suitcase', 'travel', 'trip'],
    },
    styles: ['solid'],
    unicode: 'f0f2',
    voted: false,
  },
  'suitcase-rolling': {
    changes: ['5.1.0', '5.10.2'],
    label: 'Suitcase Rolling',
    search: {
      terms: ['baggage', 'luggage', 'move', 'suitcase', 'travel', 'trip'],
    },
    styles: ['solid'],
    unicode: 'f5c1',
    voted: false,
  },
  sun: {
    changes: ['3.2', '5.0.0', '5.5.0'],
    label: 'Sun',
    search: {
      terms: [
        'brighten',
        'contrast',
        'day',
        'lighter',
        'sol',
        'solar',
        'star',
        'weather',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f185',
    voted: false,
  },
  superpowers: {
    changes: ['4.7', '5.0.0'],
    label: 'Superpowers',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f2dd',
    voted: false,
  },
  superscript: {
    changes: ['3.1', '5.0.0', '5.9.0', '5.10.2'],
    label: 'superscript',
    search: {
      terms: ['edit', 'exponential', 'font', 'format', 'text', 'type'],
    },
    styles: ['solid'],
    unicode: 'f12b',
    voted: false,
  },
  supple: {
    changes: ['5.0.0'],
    label: 'Supple',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3f9',
    voted: false,
  },
  surprise: {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Hushed Face',
    search: {
      terms: ['emoticon', 'face', 'shocked'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f5c2',
    voted: false,
  },
  suse: {
    changes: ['5.6.0', '5.8.0'],
    label: 'Suse',
    search: {
      terms: ['linux', 'operating system', 'os'],
    },
    styles: ['brands'],
    unicode: 'f7d6',
    voted: true,
  },
  swatchbook: {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Swatchbook',
    search: {
      terms: ['Pantone', 'color', 'design', 'hue', 'palette'],
    },
    styles: ['solid'],
    unicode: 'f5c3',
    voted: false,
  },
  swift: {
    changes: ['5.11.0'],
    label: 'Swift',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f8e1',
  },
  swimmer: {
    changes: ['5.1.0'],
    label: 'Swimmer',
    search: {
      terms: ['athlete', 'head', 'man', 'olympics', 'person', 'pool', 'water'],
    },
    styles: ['solid'],
    unicode: 'f5c4',
    voted: false,
  },
  'swimming-pool': {
    changes: ['5.1.0'],
    label: 'Swimming Pool',
    search: {
      terms: ['ladder', 'recreation', 'swim', 'water'],
    },
    styles: ['solid'],
    unicode: 'f5c5',
    voted: false,
  },
  symfony: {
    changes: ['5.8.0'],
    label: 'Symfony',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f83d',
  },
  synagogue: {
    changes: ['5.3.0'],
    label: 'Synagogue',
    search: {
      terms: [
        'building',
        'jewish',
        'judaism',
        'religion',
        'star of david',
        'temple',
      ],
    },
    styles: ['solid'],
    unicode: 'f69b',
    voted: false,
  },
  sync: {
    changes: ['1', '5.0.0', '5.8.0'],
    label: 'Sync',
    search: {
      terms: ['exchange', 'refresh', 'reload', 'rotate', 'swap'],
    },
    styles: ['solid'],
    unicode: 'f021',
    voted: false,
  },
  'sync-alt': {
    changes: ['5.0.0'],
    label: 'Alternate Sync',
    search: {
      terms: ['exchange', 'refresh', 'reload', 'rotate', 'swap'],
    },
    styles: ['solid'],
    unicode: 'f2f1',
    voted: false,
  },
  syringe: {
    changes: ['5.0.7'],
    label: 'Syringe',
    search: {
      terms: ['covid-19', 'doctor', 'immunizations', 'medical', 'needle'],
    },
    styles: ['solid'],
    unicode: 'f48e',
    voted: false,
  },
  table: {
    changes: ['2', '5.0.0'],
    label: 'table',
    search: {
      terms: ['data', 'excel', 'spreadsheet'],
    },
    styles: ['solid'],
    unicode: 'f0ce',
    voted: false,
  },
  'table-tennis': {
    changes: ['5.0.5'],
    label: 'Table Tennis',
    search: {
      terms: ['ball', 'paddle', 'ping pong'],
    },
    styles: ['solid'],
    unicode: 'f45d',
    voted: false,
  },
  tablet: {
    changes: ['3', '5.0.0'],
    label: 'tablet',
    search: {
      terms: ['apple', 'device', 'ipad', 'kindle', 'screen'],
    },
    styles: ['solid'],
    unicode: 'f10a',
    voted: false,
  },
  'tablet-alt': {
    changes: ['5.0.0'],
    label: 'Alternate Tablet',
    search: {
      terms: ['apple', 'device', 'ipad', 'kindle', 'screen'],
    },
    styles: ['solid'],
    unicode: 'f3fa',
    voted: false,
  },
  tablets: {
    changes: ['5.0.7'],
    label: 'Tablets',
    search: {
      terms: ['drugs', 'medicine', 'pills', 'prescription'],
    },
    styles: ['solid'],
    unicode: 'f490',
    voted: false,
  },
  'tachometer-alt': {
    changes: ['5.0.0', '5.2.0'],
    label: 'Alternate Tachometer',
    search: {
      terms: ['dashboard', 'fast', 'odometer', 'speed', 'speedometer'],
    },
    styles: ['solid'],
    unicode: 'f3fd',
    voted: false,
  },
  tag: {
    changes: ['1', '5.0.0'],
    label: 'tag',
    search: {
      terms: ['discount', 'label', 'price', 'shopping'],
    },
    styles: ['solid'],
    unicode: 'f02b',
    voted: false,
  },
  tags: {
    changes: ['1', '5.0.0', '5.10.2'],
    label: 'tags',
    search: {
      terms: ['discount', 'label', 'price', 'shopping'],
    },
    styles: ['solid'],
    unicode: 'f02c',
    voted: false,
  },
  tape: {
    changes: ['5.0.9'],
    label: 'Tape',
    search: {
      terms: ['design', 'package', 'sticky'],
    },
    styles: ['solid'],
    unicode: 'f4db',
    voted: false,
  },
  tasks: {
    changes: ['2', '5.0.0', '5.9.0'],
    label: 'Tasks',
    search: {
      terms: [
        'checklist',
        'downloading',
        'downloads',
        'loading',
        'progress',
        'project management',
        'settings',
        'to do',
      ],
    },
    styles: ['solid'],
    unicode: 'f0ae',
    voted: false,
  },
  taxi: {
    changes: ['4.1', '5.0.0', '5.1.0'],
    label: 'Taxi',
    search: {
      terms: [
        'cab',
        'cabbie',
        'car',
        'car service',
        'lyft',
        'machine',
        'transportation',
        'travel',
        'uber',
        'vehicle',
      ],
    },
    styles: ['solid'],
    unicode: 'f1ba',
    voted: false,
  },
  teamspeak: {
    changes: ['5.0.11', '5.1.0', '5.8.0'],
    label: 'TeamSpeak',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f4f9',
    voted: true,
  },
  teeth: {
    changes: ['5.2.0'],
    label: 'Teeth',
    search: {
      terms: ['bite', 'dental', 'dentist', 'gums', 'mouth', 'smile', 'tooth'],
    },
    styles: ['solid'],
    unicode: 'f62e',
    voted: false,
  },
  'teeth-open': {
    changes: ['5.2.0'],
    label: 'Teeth Open',
    search: {
      terms: ['dental', 'dentist', 'gums bite', 'mouth', 'smile', 'tooth'],
    },
    styles: ['solid'],
    unicode: 'f62f',
    voted: false,
  },
  telegram: {
    changes: ['4.7', '5.0.0'],
    label: 'Telegram',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f2c6',
    voted: false,
  },
  'telegram-plane': {
    changes: ['5.0.0'],
    label: 'Telegram Plane',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f3fe',
    voted: false,
  },
  'temperature-high': {
    changes: ['5.5.0'],
    label: 'High Temperature',
    search: {
      terms: ['cook', 'covid-19', 'mercury', 'summer', 'thermometer', 'warm'],
    },
    styles: ['solid'],
    unicode: 'f769',
    voted: false,
  },
  'temperature-low': {
    changes: ['5.5.0'],
    label: 'Low Temperature',
    search: {
      terms: ['cold', 'cool', 'covid-19', 'mercury', 'thermometer', 'winter'],
    },
    styles: ['solid'],
    unicode: 'f76b',
    voted: false,
  },
  'tencent-weibo': {
    changes: ['4.1', '5.0.0'],
    label: 'Tencent Weibo',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1d5',
    voted: false,
  },
  tenge: {
    changes: ['5.6.0'],
    label: 'Tenge',
    search: {
      terms: ['currency', 'kazakhstan', 'money', 'price'],
    },
    styles: ['solid'],
    unicode: 'f7d7',
    voted: true,
  },
  terminal: {
    changes: ['3.1', '5.0.0'],
    label: 'Terminal',
    search: {
      terms: ['code', 'command', 'console', 'development', 'prompt'],
    },
    styles: ['solid'],
    unicode: 'f120',
    voted: false,
  },
  'text-height': {
    changes: ['1', '5.0.0', '5.9.0', '5.10.2'],
    label: 'text-height',
    search: {
      terms: ['edit', 'font', 'format', 'text', 'type'],
    },
    styles: ['solid'],
    unicode: 'f034',
    voted: false,
  },
  'text-width': {
    changes: ['1', '5.0.0', '5.9.0', '5.10.2'],
    label: 'Text Width',
    search: {
      terms: ['edit', 'font', 'format', 'text', 'type'],
    },
    styles: ['solid'],
    unicode: 'f035',
    voted: false,
  },
  th: {
    changes: ['1', '5.0.0', '5.7.0'],
    label: 'th',
    search: {
      terms: ['blocks', 'boxes', 'grid', 'squares'],
    },
    styles: ['solid'],
    unicode: 'f00a',
    voted: false,
  },
  'th-large': {
    changes: ['1', '5.0.0'],
    label: 'th-large',
    search: {
      terms: ['blocks', 'boxes', 'grid', 'squares'],
    },
    styles: ['solid'],
    unicode: 'f009',
    voted: false,
  },
  'th-list': {
    changes: ['1', '5.0.0'],
    label: 'th-list',
    search: {
      terms: ['checklist', 'completed', 'done', 'finished', 'ol', 'todo', 'ul'],
    },
    styles: ['solid'],
    unicode: 'f00b',
    voted: false,
  },
  'the-red-yeti': {
    changes: ['5.3.0', '5.7.0', '5.8.0'],
    label: 'The Red Yeti',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f69d',
    voted: false,
  },
  'theater-masks': {
    changes: ['5.2.0', '5.10.2'],
    label: 'Theater Masks',
    search: {
      terms: ['comedy', 'perform', 'theatre', 'tragedy'],
    },
    styles: ['solid'],
    unicode: 'f630',
    voted: false,
  },
  themeco: {
    changes: ['5.1.0', '5.8.0'],
    label: 'Themeco',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f5c6',
    voted: false,
  },
  themeisle: {
    changes: ['4.6', '5.0.0'],
    label: 'ThemeIsle',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f2b2',
    voted: false,
  },
  thermometer: {
    changes: ['5.0.7'],
    label: 'Thermometer',
    search: {
      terms: ['covid-19', 'mercury', 'status', 'temperature'],
    },
    styles: ['solid'],
    unicode: 'f491',
    voted: false,
  },
  'thermometer-empty': {
    changes: ['4.7', '5.0.0'],
    label: 'Thermometer Empty',
    search: {
      terms: ['cold', 'mercury', 'status', 'temperature'],
    },
    styles: ['solid'],
    unicode: 'f2cb',
    voted: false,
  },
  'thermometer-full': {
    changes: ['4.7', '5.0.0'],
    label: 'Thermometer Full',
    search: {
      terms: ['fever', 'hot', 'mercury', 'status', 'temperature'],
    },
    styles: ['solid'],
    unicode: 'f2c7',
    voted: false,
  },
  'thermometer-half': {
    changes: ['4.7', '5.0.0'],
    label: 'Thermometer 1/2 Full',
    search: {
      terms: ['mercury', 'status', 'temperature'],
    },
    styles: ['solid'],
    unicode: 'f2c9',
    voted: false,
  },
  'thermometer-quarter': {
    changes: ['4.7', '5.0.0'],
    label: 'Thermometer 1/4 Full',
    search: {
      terms: ['mercury', 'status', 'temperature'],
    },
    styles: ['solid'],
    unicode: 'f2ca',
    voted: false,
  },
  'thermometer-three-quarters': {
    changes: ['4.7', '5.0.0'],
    label: 'Thermometer 3/4 Full',
    search: {
      terms: ['mercury', 'status', 'temperature'],
    },
    styles: ['solid'],
    unicode: 'f2c8',
    voted: false,
  },
  'think-peaks': {
    changes: ['5.4.2', '5.8.0'],
    label: 'Think Peaks',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f731',
    voted: false,
  },
  'thumbs-down': {
    changes: ['3.2', '5.0.0', '5.10.2'],
    label: 'thumbs-down',
    search: {
      terms: [
        'disagree',
        'disapprove',
        'dislike',
        'hand',
        'social',
        'thumbs-o-down',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f165',
    voted: false,
  },
  'thumbs-up': {
    changes: ['3.2', '5.0.0', '5.10.2'],
    label: 'thumbs-up',
    search: {
      terms: [
        'agree',
        'approve',
        'favorite',
        'hand',
        'like',
        'ok',
        'okay',
        'social',
        'success',
        'thumbs-o-up',
        'yes',
        'you got it dude',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f164',
    voted: false,
  },
  thumbtack: {
    changes: ['1', '5.0.0', '5.10.2'],
    label: 'Thumbtack',
    search: {
      terms: ['coordinates', 'location', 'marker', 'pin', 'thumb-tack'],
    },
    styles: ['solid'],
    unicode: 'f08d',
    voted: false,
  },
  'ticket-alt': {
    changes: ['5.0.0', '5.10.2'],
    label: 'Alternate Ticket',
    search: {
      terms: ['movie', 'pass', 'support', 'ticket'],
    },
    styles: ['solid'],
    unicode: 'f3ff',
    voted: false,
  },
  tiktok: {
    changes: ['5.13.1'],
    label: 'TikTok',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f97b',
    voted: true,
  },
  times: {
    changes: ['1', '5.0.0', '5.0.13', '5.11.0', '5.11.1'],
    label: 'Times',
    search: {
      terms: [
        'close',
        'cross',
        'error',
        'exit',
        'incorrect',
        'notice',
        'notification',
        'notify',
        'problem',
        'wrong',
        'x',
      ],
    },
    styles: ['solid'],
    unicode: 'f00d',
    voted: false,
  },
  'times-circle': {
    changes: ['1', '5.0.0'],
    label: 'Times Circle',
    search: {
      terms: [
        'close',
        'cross',
        'exit',
        'incorrect',
        'notice',
        'notification',
        'notify',
        'problem',
        'wrong',
        'x',
      ],
    },
    styles: ['solid', 'regular'],
    unicode: 'f057',
    voted: false,
  },
  tint: {
    changes: ['1', '5.0.0', '5.1.0'],
    label: 'tint',
    search: {
      terms: ['color', 'drop', 'droplet', 'raindrop', 'waterdrop'],
    },
    styles: ['solid'],
    unicode: 'f043',
    voted: false,
  },
  'tint-slash': {
    changes: ['5.1.0'],
    label: 'Tint Slash',
    search: {
      terms: ['color', 'drop', 'droplet', 'raindrop', 'waterdrop'],
    },
    styles: ['solid'],
    unicode: 'f5c7',
    voted: false,
  },
  tired: {
    changes: ['5.1.0', '5.11.0', '5.11.1'],
    label: 'Tired Face',
    search: {
      terms: ['angry', 'emoticon', 'face', 'grumpy', 'upset'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f5c8',
    voted: false,
  },
  'toggle-off': {
    changes: ['4.2', '5.0.0'],
    label: 'Toggle Off',
    search: {
      terms: ['switch'],
    },
    styles: ['solid'],
    unicode: 'f204',
    voted: false,
  },
  'toggle-on': {
    changes: ['4.2', '5.0.0'],
    label: 'Toggle On',
    search: {
      terms: ['switch'],
    },
    styles: ['solid'],
    unicode: 'f205',
    voted: false,
  },
  toilet: {
    changes: ['5.6.0'],
    label: 'Toilet',
    search: {
      terms: [
        'bathroom',
        'flush',
        'john',
        'loo',
        'pee',
        'plumbing',
        'poop',
        'porcelain',
        'potty',
        'restroom',
        'throne',
        'washroom',
        'waste',
        'wc',
      ],
    },
    styles: ['solid'],
    unicode: 'f7d8',
    voted: true,
  },
  'toilet-paper': {
    changes: ['5.4.0', '5.10.2'],
    label: 'Toilet Paper',
    search: {
      terms: [
        'bathroom',
        'covid-19',
        'halloween',
        'holiday',
        'lavatory',
        'prank',
        'restroom',
        'roll',
      ],
    },
    styles: ['solid'],
    unicode: 'f71e',
    voted: false,
  },
  'toilet-paper-slash': {
    changes: ['5.13.0'],
    label: 'Toilet Paper Slash',
    search: {
      terms: [
        'bathroom',
        'covid-19',
        'halloween',
        'holiday',
        'lavatory',
        'leaves',
        'prank',
        'restroom',
        'roll',
        'trouble',
        'ut oh',
      ],
    },
    styles: ['solid'],
    unicode: 'f972',
    voted: false,
  },
  toolbox: {
    changes: ['5.0.13'],
    label: 'Toolbox',
    search: {
      terms: ['admin', 'container', 'fix', 'repair', 'settings', 'tools'],
    },
    styles: ['solid'],
    unicode: 'f552',
    voted: true,
  },
  tools: {
    changes: ['5.6.0', '5.10.2'],
    label: 'Tools',
    search: {
      terms: [
        'admin',
        'fix',
        'repair',
        'screwdriver',
        'settings',
        'tools',
        'wrench',
      ],
    },
    styles: ['solid'],
    unicode: 'f7d9',
    voted: true,
  },
  tooth: {
    changes: ['5.1.0'],
    label: 'Tooth',
    search: {
      terms: ['bicuspid', 'dental', 'dentist', 'molar', 'mouth', 'teeth'],
    },
    styles: ['solid'],
    unicode: 'f5c9',
    voted: true,
  },
  torah: {
    changes: ['5.3.0', '5.7.0', '5.9.0', '5.10.2'],
    label: 'Torah',
    search: {
      terms: ['book', 'jewish', 'judaism', 'religion', 'scroll'],
    },
    styles: ['solid'],
    unicode: 'f6a0',
    voted: false,
  },
  'torii-gate': {
    changes: ['5.3.0'],
    label: 'Torii Gate',
    search: {
      terms: ['building', 'shintoism'],
    },
    styles: ['solid'],
    unicode: 'f6a1',
    voted: false,
  },
  tractor: {
    changes: ['5.4.0'],
    label: 'Tractor',
    search: {
      terms: ['agriculture', 'farm', 'vehicle'],
    },
    styles: ['solid'],
    unicode: 'f722',
    voted: false,
  },
  'trade-federation': {
    changes: ['5.0.12'],
    label: 'Trade Federation',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f513',
    voted: false,
  },
  trademark: {
    changes: ['4.4', '5.0.0'],
    label: 'Trademark',
    search: {
      terms: ['copyright', 'register', 'symbol'],
    },
    styles: ['solid'],
    unicode: 'f25c',
    voted: false,
  },
  'traffic-light': {
    changes: ['5.2.0'],
    label: 'Traffic Light',
    search: {
      terms: ['direction', 'road', 'signal', 'travel'],
    },
    styles: ['solid'],
    unicode: 'f637',
    voted: false,
  },
  trailer: {
    changes: ['5.12.0'],
    label: 'Trailer',
    search: {
      terms: ['carry', 'haul', 'moving', 'travel'],
    },
    styles: ['solid'],
    unicode: 'f941',
    voted: true,
  },
  train: {
    changes: ['4.3', '5.0.0'],
    label: 'Train',
    search: {
      terms: ['bullet', 'commute', 'locomotive', 'railway', 'subway'],
    },
    styles: ['solid'],
    unicode: 'f238',
    voted: false,
  },
  tram: {
    changes: ['5.6.0'],
    label: 'Tram',
    search: {
      terms: ['crossing', 'machine', 'mountains', 'seasonal', 'transportation'],
    },
    styles: ['solid'],
    unicode: 'f7da',
  },
  transgender: {
    changes: ['4.3', '5.0.0'],
    label: 'Transgender',
    search: {
      terms: ['intersex'],
    },
    styles: ['solid'],
    unicode: 'f224',
    voted: false,
  },
  'transgender-alt': {
    changes: ['4.3', '5.0.0', '5.11.0'],
    label: 'Alternate Transgender',
    search: {
      terms: ['intersex'],
    },
    styles: ['solid'],
    unicode: 'f225',
    voted: false,
  },
  trash: {
    changes: ['4.2', '5.0.0', '5.7.0', '5.10.2'],
    label: 'Trash',
    search: {
      terms: ['delete', 'garbage', 'hide', 'remove'],
    },
    styles: ['solid'],
    unicode: 'f1f8',
    voted: false,
  },
  'trash-alt': {
    changes: ['5.0.0', '5.7.0', '5.10.2'],
    label: 'Alternate Trash',
    search: {
      terms: ['delete', 'garbage', 'hide', 'remove', 'trash-o'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f2ed',
    voted: false,
  },
  'trash-restore': {
    changes: ['5.7.0', '5.10.2'],
    label: 'Trash Restore',
    search: {
      terms: ['back', 'control z', 'oops', 'undo'],
    },
    styles: ['solid'],
    unicode: 'f829',
    voted: true,
  },
  'trash-restore-alt': {
    changes: ['5.7.0', '5.10.2'],
    label: 'Alternative Trash Restore',
    search: {
      terms: ['back', 'control z', 'oops', 'undo'],
    },
    styles: ['solid'],
    unicode: 'f82a',
    voted: true,
  },
  tree: {
    changes: ['4.1', '5.0.0'],
    label: 'Tree',
    search: {
      terms: ['bark', 'fall', 'flora', 'forest', 'nature', 'plant', 'seasonal'],
    },
    styles: ['solid'],
    unicode: 'f1bb',
    voted: false,
  },
  trello: {
    changes: ['3.2', '5.0.0', '5.6.0'],
    label: 'Trello',
    search: {
      terms: ['atlassian'],
    },
    styles: ['brands'],
    unicode: 'f181',
    voted: false,
  },
  tripadvisor: {
    changes: ['4.4', '5.0.0', '5.13.1'],
    label: 'TripAdvisor',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f262',
    voted: false,
  },
  trophy: {
    changes: ['1', '5.0.0', '5.11.0'],
    label: 'trophy',
    search: {
      terms: ['achievement', 'award', 'cup', 'game', 'winner'],
    },
    styles: ['solid'],
    unicode: 'f091',
    voted: false,
  },
  truck: {
    changes: ['2', '5.0.0', '5.0.7'],
    label: 'truck',
    search: {
      terms: ['cargo', 'delivery', 'shipping', 'vehicle'],
    },
    styles: ['solid'],
    unicode: 'f0d1',
    voted: false,
  },
  'truck-loading': {
    changes: ['5.0.9'],
    label: 'Truck Loading',
    search: {
      terms: [
        'box',
        'cargo',
        'delivery',
        'inventory',
        'moving',
        'rental',
        'vehicle',
      ],
    },
    styles: ['solid'],
    unicode: 'f4de',
    voted: false,
  },
  'truck-monster': {
    changes: ['5.2.0'],
    label: 'Truck Monster',
    search: {
      terms: ['offroad', 'vehicle', 'wheel'],
    },
    styles: ['solid'],
    unicode: 'f63b',
    voted: false,
  },
  'truck-moving': {
    changes: ['5.0.9'],
    label: 'Truck Moving',
    search: {
      terms: ['cargo', 'inventory', 'rental', 'vehicle'],
    },
    styles: ['solid'],
    unicode: 'f4df',
    voted: false,
  },
  'truck-pickup': {
    changes: ['5.2.0'],
    label: 'Truck Side',
    search: {
      terms: ['cargo', 'vehicle'],
    },
    styles: ['solid'],
    unicode: 'f63c',
    voted: false,
  },
  tshirt: {
    changes: ['5.0.13', '5.10.2'],
    label: 'T-Shirt',
    search: {
      terms: ['clothing', 'fashion', 'garment', 'shirt'],
    },
    styles: ['solid'],
    unicode: 'f553',
    voted: true,
  },
  tty: {
    changes: ['4.2', '5.0.0', '5.7.0'],
    label: 'TTY',
    search: {
      terms: ['communication', 'deaf', 'telephone', 'teletypewriter', 'text'],
    },
    styles: ['solid'],
    unicode: 'f1e4',
    voted: false,
  },
  tumblr: {
    changes: ['3.2', '5.0.0'],
    label: 'Tumblr',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f173',
    voted: false,
  },
  'tumblr-square': {
    changes: ['3.2', '5.0.0'],
    label: 'Tumblr Square',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f174',
    voted: false,
  },
  tv: {
    changes: ['4.4', '5.0.0', '5.11.0'],
    label: 'Television',
    search: {
      terms: ['computer', 'display', 'monitor', 'television'],
    },
    styles: ['solid'],
    unicode: 'f26c',
    voted: false,
  },
  twitch: {
    changes: ['4.2', '5.0.0', '5.12.0'],
    label: 'Twitch',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1e8',
    voted: false,
  },
  twitter: {
    changes: ['2', '5.0.0'],
    label: 'Twitter',
    search: {
      terms: ['social network', 'tweet'],
    },
    styles: ['brands'],
    unicode: 'f099',
    voted: false,
  },
  'twitter-square': {
    changes: ['1', '5.0.0'],
    label: 'Twitter Square',
    search: {
      terms: ['social network', 'tweet'],
    },
    styles: ['brands'],
    unicode: 'f081',
    voted: false,
  },
  typo3: {
    changes: ['5.0.1', '5.8.0'],
    label: 'Typo3',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f42b',
    voted: false,
  },
  uber: {
    changes: ['5.0.0'],
    label: 'Uber',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f402',
    voted: false,
  },
  ubuntu: {
    changes: ['5.6.0'],
    label: 'Ubuntu',
    search: {
      terms: ['linux', 'operating system', 'os'],
    },
    styles: ['brands'],
    unicode: 'f7df',
    voted: true,
  },
  uikit: {
    changes: ['5.0.0'],
    label: 'UIkit',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f403',
    voted: false,
  },
  umbraco: {
    changes: ['5.11.0'],
    label: 'Umbraco',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f8e8',
  },
  umbrella: {
    changes: ['2', '5.0.0'],
    label: 'Umbrella',
    search: {
      terms: ['protection', 'rain', 'storm', 'wet'],
    },
    styles: ['solid'],
    unicode: 'f0e9',
    voted: false,
  },
  'umbrella-beach': {
    changes: ['5.1.0'],
    label: 'Umbrella Beach',
    search: {
      terms: ['protection', 'recreation', 'sand', 'shade', 'summer', 'sun'],
    },
    styles: ['solid'],
    unicode: 'f5ca',
    voted: false,
  },
  underline: {
    changes: ['2', '5.0.0', '5.9.0'],
    label: 'Underline',
    search: {
      terms: ['edit', 'emphasis', 'format', 'text', 'writing'],
    },
    styles: ['solid'],
    unicode: 'f0cd',
    voted: false,
  },
  undo: {
    changes: ['2', '5.0.0'],
    label: 'Undo',
    search: {
      terms: [
        'back',
        'control z',
        'exchange',
        'oops',
        'return',
        'rotate',
        'swap',
      ],
    },
    styles: ['solid'],
    unicode: 'f0e2',
    voted: false,
  },
  'undo-alt': {
    changes: ['5.0.0'],
    label: 'Alternate Undo',
    search: {
      terms: ['back', 'control z', 'exchange', 'oops', 'return', 'swap'],
    },
    styles: ['solid'],
    unicode: 'f2ea',
    voted: false,
  },
  uniregistry: {
    changes: ['5.0.0'],
    label: 'Uniregistry',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f404',
    voted: false,
  },
  unity: {
    changes: ['5.12.0'],
    label: 'Unity 3D',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f949',
    voted: true,
  },
  'universal-access': {
    changes: ['4.6', '5.0.0', '5.10.2'],
    label: 'Universal Access',
    search: {
      terms: [
        'accessibility',
        'hearing',
        'person',
        'seeing',
        'visual impairment',
      ],
    },
    styles: ['solid'],
    unicode: 'f29a',
    voted: false,
  },
  university: {
    changes: ['4.1', '5.0.0', '5.0.3', '5.11.0', '5.11.1'],
    label: 'University',
    search: {
      terms: [
        'bank',
        'building',
        'college',
        'higher education - students',
        'institution',
      ],
    },
    styles: ['solid'],
    unicode: 'f19c',
    voted: false,
  },
  unlink: {
    changes: ['3.1', '5.0.0'],
    label: 'unlink',
    search: {
      terms: ['attachment', 'chain', 'chain-broken', 'remove'],
    },
    styles: ['solid'],
    unicode: 'f127',
    voted: false,
  },
  unlock: {
    changes: ['2', '5.0.0'],
    label: 'unlock',
    search: {
      terms: ['admin', 'lock', 'password', 'private', 'protect'],
    },
    styles: ['solid'],
    unicode: 'f09c',
    voted: false,
  },
  'unlock-alt': {
    changes: ['3.1', '5.0.0'],
    label: 'Alternate Unlock',
    search: {
      terms: ['admin', 'lock', 'password', 'private', 'protect'],
    },
    styles: ['solid'],
    unicode: 'f13e',
    voted: false,
  },
  unsplash: {
    changes: ['5.13.1'],
    label: 'Unsplash',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f97c',
    voted: false,
  },
  untappd: {
    changes: ['5.0.0'],
    label: 'Untappd',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f405',
    voted: false,
  },
  upload: {
    changes: ['1', '5.0.0'],
    label: 'Upload',
    search: {
      terms: ['hard drive', 'import', 'publish'],
    },
    styles: ['solid'],
    unicode: 'f093',
    voted: false,
  },
  ups: {
    changes: ['5.6.0', '5.8.0'],
    label: 'UPS',
    search: {
      terms: ['United Parcel Service', 'package', 'shipping'],
    },
    styles: ['brands'],
    unicode: 'f7e0',
  },
  usb: {
    changes: ['4.5', '5.0.0'],
    label: 'USB',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f287',
    voted: false,
  },
  user: {
    changes: ['1', '5.0.0', '5.0.3', '5.0.11'],
    label: 'User',
    search: {
      terms: ['account', 'avatar', 'head', 'human', 'man', 'person', 'profile'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f007',
    voted: false,
  },
  'user-alt': {
    changes: ['5.0.0', '5.0.3', '5.0.11'],
    label: 'Alternate User',
    search: {
      terms: ['account', 'avatar', 'head', 'human', 'man', 'person', 'profile'],
    },
    styles: ['solid'],
    unicode: 'f406',
    voted: false,
  },
  'user-alt-slash': {
    changes: ['5.0.11'],
    label: 'Alternate User Slash',
    search: {
      terms: ['account', 'avatar', 'head', 'human', 'man', 'person', 'profile'],
    },
    styles: ['solid'],
    unicode: 'f4fa',
    voted: false,
  },
  'user-astronaut': {
    changes: ['5.0.11'],
    label: 'User Astronaut',
    search: {
      terms: ['avatar', 'clothing', 'cosmonaut', 'nasa', 'space', 'suit'],
    },
    styles: ['solid'],
    unicode: 'f4fb',
    voted: false,
  },
  'user-check': {
    changes: ['5.0.11'],
    label: 'User Check',
    search: {
      terms: ['accept', 'check', 'person', 'verified'],
    },
    styles: ['solid'],
    unicode: 'f4fc',
    voted: false,
  },
  'user-circle': {
    changes: ['4.7', '5.0.0', '5.0.3', '5.0.11', '5.11.0', '5.11.1'],
    label: 'User Circle',
    search: {
      terms: ['account', 'avatar', 'head', 'human', 'man', 'person', 'profile'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f2bd',
    voted: false,
  },
  'user-clock': {
    changes: ['5.0.11'],
    label: 'User Clock',
    search: {
      terms: ['alert', 'person', 'remind', 'time'],
    },
    styles: ['solid'],
    unicode: 'f4fd',
    voted: false,
  },
  'user-cog': {
    changes: ['5.0.11'],
    label: 'User Cog',
    search: {
      terms: ['admin', 'cog', 'person', 'settings'],
    },
    styles: ['solid'],
    unicode: 'f4fe',
    voted: false,
  },
  'user-edit': {
    changes: ['5.0.11'],
    label: 'User Edit',
    search: {
      terms: ['edit', 'pen', 'pencil', 'person', 'update', 'write'],
    },
    styles: ['solid'],
    unicode: 'f4ff',
    voted: false,
  },
  'user-friends': {
    changes: ['5.0.11'],
    label: 'User Friends',
    search: {
      terms: ['group', 'people', 'person', 'team', 'users'],
    },
    styles: ['solid'],
    unicode: 'f500',
    voted: false,
  },
  'user-graduate': {
    changes: ['5.0.11'],
    label: 'User Graduate',
    search: {
      terms: [
        'cap',
        'clothing',
        'commencement',
        'gown',
        'graduation',
        'person',
        'student',
      ],
    },
    styles: ['solid'],
    unicode: 'f501',
    voted: false,
  },
  'user-injured': {
    changes: ['5.4.0'],
    label: 'User Injured',
    search: {
      terms: ['cast', 'injury', 'ouch', 'patient', 'person', 'sling'],
    },
    styles: ['solid'],
    unicode: 'f728',
    voted: false,
  },
  'user-lock': {
    changes: ['5.0.11', '5.9.0'],
    label: 'User Lock',
    search: {
      terms: ['admin', 'lock', 'person', 'private', 'unlock'],
    },
    styles: ['solid'],
    unicode: 'f502',
    voted: false,
  },
  'user-md': {
    changes: ['2', '5.0.0', '5.0.3', '5.0.7', '5.0.11'],
    label: 'Doctor',
    search: {
      terms: [
        'covid-19',
        'job',
        'medical',
        'nurse',
        'occupation',
        'physician',
        'profile',
        'surgeon',
      ],
    },
    styles: ['solid'],
    unicode: 'f0f0',
    voted: false,
  },
  'user-minus': {
    changes: ['5.0.11'],
    label: 'User Minus',
    search: {
      terms: ['delete', 'negative', 'remove'],
    },
    styles: ['solid'],
    unicode: 'f503',
    voted: false,
  },
  'user-ninja': {
    changes: ['5.0.11'],
    label: 'User Ninja',
    search: {
      terms: ['assassin', 'avatar', 'dangerous', 'deadly', 'sneaky'],
    },
    styles: ['solid'],
    unicode: 'f504',
    voted: false,
  },
  'user-nurse': {
    changes: ['5.7.0', '5.12.0'],
    label: 'Nurse',
    search: {
      terms: ['covid-19', 'doctor', 'midwife', 'practitioner', 'surgeon'],
    },
    styles: ['solid'],
    unicode: 'f82f',
    voted: false,
  },
  'user-plus': {
    changes: ['4.3', '5.0.0', '5.0.3', '5.0.11'],
    label: 'User Plus',
    search: {
      terms: ['add', 'avatar', 'positive', 'sign up', 'signup', 'team'],
    },
    styles: ['solid'],
    unicode: 'f234',
    voted: false,
  },
  'user-secret': {
    changes: ['4.3', '5.0.0', '5.0.3', '5.0.11'],
    label: 'User Secret',
    search: {
      terms: [
        'clothing',
        'coat',
        'hat',
        'incognito',
        'person',
        'privacy',
        'spy',
        'whisper',
      ],
    },
    styles: ['solid'],
    unicode: 'f21b',
    voted: false,
  },
  'user-shield': {
    changes: ['5.0.11'],
    label: 'User Shield',
    search: {
      terms: ['admin', 'person', 'private', 'protect', 'safe'],
    },
    styles: ['solid'],
    unicode: 'f505',
    voted: false,
  },
  'user-slash': {
    changes: ['5.0.11'],
    label: 'User Slash',
    search: {
      terms: ['ban', 'delete', 'remove'],
    },
    styles: ['solid'],
    unicode: 'f506',
    voted: false,
  },
  'user-tag': {
    changes: ['5.0.11'],
    label: 'User Tag',
    search: {
      terms: ['avatar', 'discount', 'label', 'person', 'role', 'special'],
    },
    styles: ['solid'],
    unicode: 'f507',
    voted: false,
  },
  'user-tie': {
    changes: ['5.0.11'],
    label: 'User Tie',
    search: {
      terms: [
        'avatar',
        'business',
        'clothing',
        'formal',
        'professional',
        'suit',
      ],
    },
    styles: ['solid'],
    unicode: 'f508',
    voted: false,
  },
  'user-times': {
    changes: ['4.3', '5.0.0', '5.0.3', '5.0.11'],
    label: 'Remove User',
    search: {
      terms: ['archive', 'delete', 'remove', 'x'],
    },
    styles: ['solid'],
    unicode: 'f235',
    voted: false,
  },
  users: {
    changes: ['2', '5.0.0', '5.0.3', '5.0.11'],
    label: 'Users',
    search: {
      terms: ['friends', 'group', 'people', 'persons', 'profiles', 'team'],
    },
    styles: ['solid'],
    unicode: 'f0c0',
    voted: false,
  },
  'users-cog': {
    changes: ['5.0.11'],
    label: 'Users Cog',
    search: {
      terms: ['admin', 'cog', 'group', 'person', 'settings', 'team'],
    },
    styles: ['solid'],
    unicode: 'f509',
    voted: false,
  },
  'users-slash': {
    changes: ['5.13.0'],
    label: 'Users Slash',
    search: {
      terms: [
        'disband',
        'friends',
        'group',
        'people',
        'persons',
        'profiles',
        'separate',
        'team',
        'ungroup',
      ],
    },
    styles: ['solid'],
    unicode: 'f973',
    voted: false,
  },
  usps: {
    changes: ['5.6.0', '5.8.0'],
    label: 'United States Postal Service',
    search: {
      terms: ['american', 'package', 'shipping', 'usa'],
    },
    styles: ['brands'],
    unicode: 'f7e1',
  },
  ussunnah: {
    changes: ['5.0.0'],
    label: 'us-Sunnah Foundation',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f407',
    voted: false,
  },
  'utensil-spoon': {
    changes: ['5.0.0', '5.10.2'],
    label: 'Utensil Spoon',
    search: {
      terms: ['cutlery', 'dining', 'scoop', 'silverware', 'spoon'],
    },
    styles: ['solid'],
    unicode: 'f2e5',
    voted: false,
  },
  utensils: {
    changes: ['5.0.0'],
    label: 'Utensils',
    search: {
      terms: [
        'cutlery',
        'dining',
        'dinner',
        'eat',
        'food',
        'fork',
        'knife',
        'restaurant',
      ],
    },
    styles: ['solid'],
    unicode: 'f2e7',
    voted: false,
  },
  vaadin: {
    changes: ['5.0.0'],
    label: 'Vaadin',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f408',
    voted: false,
  },
  'vector-square': {
    changes: ['5.1.0'],
    label: 'Vector Square',
    search: {
      terms: ['anchors', 'lines', 'object', 'render', 'shape'],
    },
    styles: ['solid'],
    unicode: 'f5cb',
    voted: false,
  },
  venus: {
    changes: ['4.3', '5.0.0', '5.11.0', '5.11.1'],
    label: 'Venus',
    search: {
      terms: ['female'],
    },
    styles: ['solid'],
    unicode: 'f221',
    voted: false,
  },
  'venus-double': {
    changes: ['4.3', '5.0.0'],
    label: 'Venus Double',
    search: {
      terms: ['female'],
    },
    styles: ['solid'],
    unicode: 'f226',
    voted: false,
  },
  'venus-mars': {
    changes: ['4.3', '5.0.0'],
    label: 'Venus Mars',
    search: {
      terms: ['Gender'],
    },
    styles: ['solid'],
    unicode: 'f228',
    voted: false,
  },
  viacoin: {
    changes: ['4.3', '5.0.0'],
    label: 'Viacoin',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f237',
    voted: false,
  },
  viadeo: {
    changes: ['4.6', '5.0.0'],
    label: 'Video',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f2a9',
    voted: false,
  },
  'viadeo-square': {
    changes: ['4.6', '5.0.0', '5.7.0'],
    label: 'Video Square',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f2aa',
    voted: false,
  },
  vial: {
    changes: ['5.0.7'],
    label: 'Vial',
    search: {
      terms: ['experiment', 'lab', 'sample', 'science', 'test', 'test tube'],
    },
    styles: ['solid'],
    unicode: 'f492',
    voted: false,
  },
  vials: {
    changes: ['5.0.7'],
    label: 'Vials',
    search: {
      terms: ['experiment', 'lab', 'sample', 'science', 'test', 'test tube'],
    },
    styles: ['solid'],
    unicode: 'f493',
    voted: false,
  },
  viber: {
    changes: ['5.0.0', '5.0.3'],
    label: 'Viber',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f409',
    voted: false,
  },
  video: {
    changes: ['1', '5.0.0', '5.0.9'],
    label: 'Video',
    search: {
      terms: ['camera', 'film', 'movie', 'record', 'video-camera'],
    },
    styles: ['solid'],
    unicode: 'f03d',
    voted: false,
  },
  'video-slash': {
    changes: ['5.0.9'],
    label: 'Video Slash',
    search: {
      terms: ['add', 'create', 'film', 'new', 'positive', 'record', 'video'],
    },
    styles: ['solid'],
    unicode: 'f4e2',
    voted: false,
  },
  vihara: {
    changes: ['5.3.0'],
    label: 'Vihara',
    search: {
      terms: ['buddhism', 'buddhist', 'building', 'monastery'],
    },
    styles: ['solid'],
    unicode: 'f6a7',
    voted: false,
  },
  vimeo: {
    changes: ['5.0.0'],
    label: 'Vimeo',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f40a',
    voted: false,
  },
  'vimeo-square': {
    changes: ['4', '5.0.0'],
    label: 'Vimeo Square',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f194',
    voted: false,
  },
  'vimeo-v': {
    changes: ['4.4', '5.0.0'],
    label: 'Vimeo',
    search: {
      terms: ['vimeo'],
    },
    styles: ['brands'],
    unicode: 'f27d',
    voted: false,
  },
  vine: {
    changes: ['4.1', '5.0.0'],
    label: 'Vine',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1ca',
    voted: false,
  },
  virus: {
    changes: ['5.13.0'],
    label: 'Virus',
    search: {
      terms: ['bug', 'covid-19', 'flu', 'health', 'sick', 'viral'],
    },
    styles: ['solid'],
    unicode: 'f974',
    voted: false,
  },
  'virus-slash': {
    changes: ['5.13.0'],
    label: 'Virus Slash',
    search: {
      terms: [
        'bug',
        'covid-19',
        'cure',
        'eliminate',
        'flu',
        'health',
        'sick',
        'viral',
      ],
    },
    styles: ['solid'],
    unicode: 'f975',
    voted: false,
  },
  viruses: {
    changes: ['5.13.0'],
    label: 'Viruses',
    search: {
      terms: [
        'bugs',
        'covid-19',
        'flu',
        'health',
        'multiply',
        'sick',
        'spread',
        'viral',
      ],
    },
    styles: ['solid'],
    unicode: 'f976',
    voted: false,
  },
  vk: {
    changes: ['3.2', '5.0.0'],
    label: 'VK',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f189',
    voted: false,
  },
  vnv: {
    changes: ['5.0.0'],
    label: 'VNV',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f40b',
    voted: false,
  },
  voicemail: {
    changes: ['5.9.0'],
    label: 'Voicemail',
    search: {
      terms: ['answer', 'inbox', 'message', 'phone'],
    },
    styles: ['solid'],
    unicode: 'f897',
    voted: true,
  },
  'volleyball-ball': {
    changes: ['5.0.5', '5.8.0'],
    label: 'Volleyball Ball',
    search: {
      terms: ['beach', 'olympics', 'sport'],
    },
    styles: ['solid'],
    unicode: 'f45f',
    voted: false,
  },
  'volume-down': {
    changes: ['1', '5.0.0', '5.3.0'],
    label: 'Volume Down',
    search: {
      terms: ['audio', 'lower', 'music', 'quieter', 'sound', 'speaker'],
    },
    styles: ['solid'],
    unicode: 'f027',
    voted: false,
  },
  'volume-mute': {
    changes: ['5.3.0'],
    label: 'Volume Mute',
    search: {
      terms: ['audio', 'music', 'quiet', 'sound', 'speaker'],
    },
    styles: ['solid'],
    unicode: 'f6a9',
    voted: true,
  },
  'volume-off': {
    changes: ['1', '5.0.0', '5.3.0', '5.8.0'],
    label: 'Volume Off',
    search: {
      terms: ['audio', 'ban', 'music', 'mute', 'quiet', 'silent', 'sound'],
    },
    styles: ['solid'],
    unicode: 'f026',
    voted: false,
  },
  'volume-up': {
    changes: ['1', '5.0.0', '5.3.0'],
    label: 'Volume Up',
    search: {
      terms: ['audio', 'higher', 'louder', 'music', 'sound', 'speaker'],
    },
    styles: ['solid'],
    unicode: 'f028',
    voted: false,
  },
  'vote-yea': {
    changes: ['5.5.0'],
    label: 'Vote Yea',
    search: {
      terms: ['accept', 'cast', 'election', 'politics', 'positive', 'yes'],
    },
    styles: ['solid'],
    unicode: 'f772',
    voted: false,
  },
  'vr-cardboard': {
    changes: ['5.4.0'],
    label: 'Cardboard VR',
    search: {
      terms: ['3d', 'augment', 'google', 'reality', 'virtual'],
    },
    styles: ['solid'],
    unicode: 'f729',
    voted: true,
  },
  vuejs: {
    changes: ['5.0.0'],
    label: 'Vue.js',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f41f',
    voted: false,
  },
  walking: {
    changes: ['5.0.13'],
    label: 'Walking',
    search: {
      terms: ['exercise', 'health', 'pedometer', 'person', 'steps'],
    },
    styles: ['solid'],
    unicode: 'f554',
    voted: true,
  },
  wallet: {
    changes: ['5.0.13'],
    label: 'Wallet',
    search: {
      terms: ['billfold', 'cash', 'currency', 'money'],
    },
    styles: ['solid'],
    unicode: 'f555',
    voted: true,
  },
  warehouse: {
    changes: ['5.0.7'],
    label: 'Warehouse',
    search: {
      terms: ['building', 'capacity', 'garage', 'inventory', 'storage'],
    },
    styles: ['solid'],
    unicode: 'f494',
    voted: false,
  },
  water: {
    changes: ['5.5.0'],
    label: 'Water',
    search: {
      terms: ['lake', 'liquid', 'ocean', 'sea', 'swim', 'wet'],
    },
    styles: ['solid'],
    unicode: 'f773',
    voted: false,
  },
  'wave-square': {
    changes: ['5.8.0'],
    label: 'Square Wave',
    search: {
      terms: ['frequency', 'pulse', 'signal'],
    },
    styles: ['solid'],
    unicode: 'f83e',
    voted: false,
  },
  waze: {
    changes: ['5.8.0'],
    label: 'Waze',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f83f',
  },
  weebly: {
    changes: ['5.1.0'],
    label: 'Weebly',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f5cc',
    voted: true,
  },
  weibo: {
    changes: ['3.2', '5.0.0'],
    label: 'Weibo',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f18a',
    voted: false,
  },
  weight: {
    changes: ['5.0.7'],
    label: 'Weight',
    search: {
      terms: ['health', 'measurement', 'scale', 'weight'],
    },
    styles: ['solid'],
    unicode: 'f496',
    voted: false,
  },
  'weight-hanging': {
    changes: ['5.1.0'],
    label: 'Hanging Weight',
    search: {
      terms: ['anvil', 'heavy', 'measurement'],
    },
    styles: ['solid'],
    unicode: 'f5cd',
    voted: false,
  },
  weixin: {
    changes: ['4.1', '5.0.0', '5.0.3'],
    label: 'Weixin (WeChat)',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1d7',
    voted: false,
  },
  whatsapp: {
    changes: ['4.3', '5.0.0'],
    label: "What's App",
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f232',
    voted: false,
  },
  'whatsapp-square': {
    changes: ['5.0.0'],
    label: "What's App Square",
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f40c',
    voted: false,
  },
  wheelchair: {
    changes: ['4', '5.0.0', '5.10.2'],
    label: 'Wheelchair',
    search: {
      terms: ['accessible', 'handicap', 'person'],
    },
    styles: ['solid'],
    unicode: 'f193',
    voted: false,
  },
  whmcs: {
    changes: ['5.0.0'],
    label: 'WHMCS',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f40d',
    voted: false,
  },
  wifi: {
    changes: ['4.2', '5.0.0', '5.3.0', '5.10.1', '5.11.1'],
    label: 'WiFi',
    search: {
      terms: ['connection', 'hotspot', 'internet', 'network', 'wireless'],
    },
    styles: ['solid'],
    unicode: 'f1eb',
    voted: false,
  },
  'wikipedia-w': {
    changes: ['4.4', '5.0.0'],
    label: 'Wikipedia W',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f266',
    voted: false,
  },
  wind: {
    changes: ['5.4.0', '5.5.0'],
    label: 'Wind',
    search: {
      terms: ['air', 'blow', 'breeze', 'fall', 'seasonal', 'weather'],
    },
    styles: ['solid'],
    unicode: 'f72e',
    voted: false,
  },
  'window-close': {
    changes: ['4.7', '5.0.0'],
    label: 'Window Close',
    search: {
      terms: ['browser', 'cancel', 'computer', 'development'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f410',
    voted: false,
  },
  'window-maximize': {
    changes: ['4.7', '5.0.0'],
    label: 'Window Maximize',
    search: {
      terms: ['browser', 'computer', 'development', 'expand'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f2d0',
    voted: false,
  },
  'window-minimize': {
    changes: ['4.7', '5.0.0', '5.10.1'],
    label: 'Window Minimize',
    search: {
      terms: ['browser', 'collapse', 'computer', 'development'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f2d1',
    voted: false,
  },
  'window-restore': {
    changes: ['4.7', '5.0.0'],
    label: 'Window Restore',
    search: {
      terms: ['browser', 'computer', 'development'],
    },
    styles: ['solid', 'regular'],
    unicode: 'f2d2',
    voted: false,
  },
  windows: {
    changes: ['3.2', '5.0.0'],
    label: 'Windows',
    search: {
      terms: ['microsoft', 'operating system', 'os'],
    },
    styles: ['brands'],
    unicode: 'f17a',
    voted: false,
  },
  'wine-bottle': {
    changes: ['5.4.0'],
    label: 'Wine Bottle',
    search: {
      terms: [
        'alcohol',
        'beverage',
        'cabernet',
        'drink',
        'glass',
        'grapes',
        'merlot',
        'sauvignon',
      ],
    },
    styles: ['solid'],
    unicode: 'f72f',
    voted: false,
  },
  'wine-glass': {
    changes: ['5.0.9', '5.1.0', '5.10.1', '5.11.0', '5.11.1'],
    label: 'Wine Glass',
    search: {
      terms: [
        'alcohol',
        'beverage',
        'cabernet',
        'drink',
        'grapes',
        'merlot',
        'sauvignon',
      ],
    },
    styles: ['solid'],
    unicode: 'f4e3',
    voted: false,
  },
  'wine-glass-alt': {
    changes: ['5.1.0', '5.10.1', '5.11.0', '5.11.1'],
    label: 'Alternate Wine Glas',
    search: {
      terms: [
        'alcohol',
        'beverage',
        'cabernet',
        'drink',
        'grapes',
        'merlot',
        'sauvignon',
      ],
    },
    styles: ['solid'],
    unicode: 'f5ce',
    voted: false,
  },
  wix: {
    changes: ['5.1.0'],
    label: 'Wix',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f5cf',
    voted: true,
  },
  'wizards-of-the-coast': {
    changes: ['5.4.0'],
    label: 'Wizards of the Coast',
    search: {
      terms: [
        'Dungeons & Dragons',
        'd&d',
        'dnd',
        'fantasy',
        'game',
        'gaming',
        'tabletop',
      ],
    },
    styles: ['brands'],
    unicode: 'f730',
    voted: false,
  },
  'wolf-pack-battalion': {
    changes: ['5.0.12', '5.8.0'],
    label: 'Wolf Pack Battalion',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f514',
    voted: false,
  },
  'won-sign': {
    changes: ['3.2', '5.0.0'],
    label: 'Won Sign',
    search: {
      terms: ['currency', 'krw', 'money'],
    },
    styles: ['solid'],
    unicode: 'f159',
    voted: false,
  },
  wordpress: {
    changes: ['4.1', '5.0.0'],
    label: 'WordPress Logo',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f19a',
    voted: false,
  },
  'wordpress-simple': {
    changes: ['5.0.0'],
    label: 'Wordpress Simple',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f411',
    voted: false,
  },
  wpbeginner: {
    changes: ['4.6', '5.0.0'],
    label: 'WPBeginner',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f297',
    voted: false,
  },
  wpexplorer: {
    changes: ['4.7', '5.0.0'],
    label: 'WPExplorer',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f2de',
    voted: false,
  },
  wpforms: {
    changes: ['4.6', '5.0.0'],
    label: 'WPForms',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f298',
    voted: false,
  },
  wpressr: {
    changes: ['5.4.2'],
    label: 'wpressr',
    search: {
      terms: ['rendact'],
    },
    styles: ['brands'],
    unicode: 'f3e4',
    voted: false,
  },
  wrench: {
    changes: ['2', '5.0.0', '5.0.13'],
    label: 'Wrench',
    search: {
      terms: [
        'construction',
        'fix',
        'mechanic',
        'plumbing',
        'settings',
        'spanner',
        'tool',
        'update',
      ],
    },
    styles: ['solid'],
    unicode: 'f0ad',
    voted: false,
  },
  'x-ray': {
    changes: ['5.0.7', '5.10.2'],
    label: 'X-Ray',
    search: {
      terms: [
        'health',
        'medical',
        'radiological images',
        'radiology',
        'skeleton',
      ],
    },
    styles: ['solid'],
    unicode: 'f497',
    voted: false,
  },
  xbox: {
    changes: ['5.0.0'],
    label: 'Xbox',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f412',
    voted: false,
  },
  xing: {
    changes: ['3.2', '5.0.0'],
    label: 'Xing',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f168',
    voted: false,
  },
  'xing-square': {
    changes: ['3.2', '5.0.0'],
    label: 'Xing Square',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f169',
    voted: false,
  },
  'y-combinator': {
    changes: ['4.4', '5.0.0'],
    label: 'Y Combinator',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f23b',
    voted: false,
  },
  yahoo: {
    changes: ['4.1', '5.0.0', '5.0.3', '5.13.1'],
    label: 'Yahoo Logo',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f19e',
    voted: false,
  },
  yammer: {
    changes: ['5.8.0'],
    label: 'Yammer',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f840',
  },
  yandex: {
    changes: ['5.0.0'],
    label: 'Yandex',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f413',
    voted: false,
  },
  'yandex-international': {
    changes: ['5.0.0'],
    label: 'Yandex International',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f414',
    voted: false,
  },
  yarn: {
    changes: ['5.6.0'],
    label: 'Yarn',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f7e3',
    voted: true,
  },
  yelp: {
    changes: ['4.2', '5.0.0', '5.7.0', '5.8.0'],
    label: 'Yelp',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f1e9',
    voted: false,
  },
  'yen-sign': {
    changes: ['3.2', '5.0.0'],
    label: 'Yen Sign',
    search: {
      terms: ['currency', 'jpy', 'money'],
    },
    styles: ['solid'],
    unicode: 'f157',
    voted: false,
  },
  'yin-yang': {
    changes: ['5.3.0', '5.10.2', '5.11.0', '5.11.1'],
    label: 'Yin Yang',
    search: {
      terms: ['daoism', 'opposites', 'taoism'],
    },
    styles: ['solid'],
    unicode: 'f6ad',
    voted: false,
  },
  yoast: {
    changes: ['4.6', '5.0.0', '5.0.3'],
    label: 'Yoast',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f2b1',
    voted: false,
  },
  youtube: {
    changes: ['3.2', '5.0.0'],
    label: 'YouTube',
    search: {
      terms: ['film', 'video', 'youtube-play', 'youtube-square'],
    },
    styles: ['brands'],
    unicode: 'f167',
    voted: false,
  },
  'youtube-square': {
    changes: ['5.0.3'],
    label: 'YouTube Square',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f431',
    voted: false,
  },
  zhihu: {
    changes: ['5.2.0'],
    label: 'Zhihu',
    search: {
      terms: [],
    },
    styles: ['brands'],
    unicode: 'f63f',
    voted: true,
  },
};
