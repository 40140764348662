import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import {
  HelpDocumentDto,
  HelpState,
  HelpTypeEnum,
} from '@ups/xplat/base/in-app-help';
import { BaseComponent } from '@ups/xplat/core';

@Component({
  selector: 'help-editor-tooltip',
  templateUrl: './editor-tooltip.component.html',
})
export class EditorTooltipComponent extends BaseComponent implements OnInit {
  @Input() document: HelpDocumentDto;

  @Output() complete = new EventEmitter<void>();

  f: UntypedFormGroup;
  documents: Observable<{ [path: string]: HelpDocumentDto[] }>;

  constructor(private formBuilder: UntypedFormBuilder, private store: Store) {
    super();

    this.documents = store.select(HelpState.selectDocumentDictionary);
  }

  ngOnInit() {
    this.f = this.formBuilder.group({
      titleControl: new UntypedFormControl(
        this.document?.title,
        Validators.required
      ),
      bodyControl: new UntypedFormControl(
        this.document?.html,
        Validators.required
      ),
      elementControl: new UntypedFormControl(
        this.document?.path,
        Validators.required
      ),
    });

    this.f.controls.elementControl.valueChanges
      .pipe(takeUntil(this.destroy$), withLatestFrom(this.documents))
      .subscribe(([path, documents]) => {
        console.log(path, documents);
        let existing: HelpDocumentDto = null;
        if (documents[path]) {
          existing = documents[path].find(
            (x) => x.type === HelpTypeEnum.Tooltip
          );
        }
        if (existing) {
          Object.assign(this.document, existing);
          this.f.patchValue({
            titleControl: this.document.title,
            bodyControl: this.document.html,
          });
        } else {
          this.document.path = path;
        }
      });

    this.f.controls.bodyControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((x) => {
        this.document.html = x;
      });

    this.f.controls.titleControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((x) => {
        this.document.title = x;
      });
  }
}
