import {
  OnInit,
  OnChanges,
  Input,
  Component,
  Injector,
  ElementRef,
} from '@angular/core';
import { ProfileModel } from '@ups/xplat/api/dto';
import { BaseComponent } from '@ups/xplat/core';
import { EquipmentVpService, MessageHelper } from '@ups/xplat/web/core';

@Component({
  selector: 'ups-equipment-notes',
  templateUrl: './equipment-notes.component.html',
})
export class EquipmentNotesComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() keyID: string;
  @Input() isEditable: boolean = true;
  @Input() mechanicNotes: boolean = false;

  isEditMode: boolean = false;
  notes: string = '';
  newNotes: string = '';

  authProfile: ProfileModel;

  constructor(
    private msgHelper: MessageHelper,
    private equipmentVpService: EquipmentVpService
  ) {
    super();
    this.authProfile = JSON.parse(
      localStorage.getItem('profile')
    ) as ProfileModel;
  }

  ngOnInit() {}

  ngOnChanges() {
    this.notes = '';
    this.loadData();
  }

  loadData() {
    const self = this;
    if (this.keyID) {
      if (this.mechanicNotes) {
        this.equipmentVpService
          .getEquipmentMechanicNotes(this.keyID)
          .then((data) => {
            self.notes = data;
          });
      } else {
        this.equipmentVpService.getEquipmentNotes(this.keyID).then((data) => {
          self.notes = data;
        });
      }
    }
  }

  editNotes() {
    this.isEditMode = true;
  }

  saveNotes() {
    const self = this;
    if (this.newNotes) {
      const info =
        this.getDateTimeNowString() +
        ' - ' +
        this.authProfile.lastName +
        ', ' +
        this.authProfile.firstName;
      const newNoteWithInfo = info + '\n' + this.newNotes;
      if (this.mechanicNotes) {
        this.equipmentVpService
          .updateEquipmentMechanicNotes(this.keyID, newNoteWithInfo)
          .then((data) => {
            self.msgHelper.success('Notes Modified');
            self.loadData();
            self.newNotes = '';
            self.isEditMode = false;
          })
          .catch((error) => {
            self.msgHelper.error('Unable to save changes');
          });
      } else {
        this.equipmentVpService
          .updateEquipmentNotes(this.keyID, newNoteWithInfo)
          .then((data) => {
            self.msgHelper.success('Notes Modified');
            self.loadData();
            self.newNotes = '';
            self.isEditMode = false;
          })
          .catch((error) => {
            self.msgHelper.error('Unable to save changes');
          });
      }
    }
  }

  cancelNotes() {
    this.newNotes = '';
    this.isEditMode = false;
  }

  public getDateTimeNowString() {
    const mS = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ];
    const today = new Date();
    const dd = today.getDate();
    const mm = today.getMonth();
    const yyyy = today.getFullYear();
    const tt = today.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return mS[mm] + ' ' + dd + ', ' + yyyy + ' ' + tt;
  }
}
