<div class="dr-admin-design-surface dr-admin-panel">
  <!-- <div class="dynamic-container" [formGroup]="drFormGroup">
    <div cdkDropList (cdkDropListDropped)="drop($event)">
      <div cdkDrag *ngFor="let item of formItems" [id]="item.id">
        <div [innerHTML]="item.content | safe: 'html'" (click)="select(item)"></div>
      </div>
    </div>
  </div> -->
  <div style="max-width: 600px; margin: auto; padding: 10px">
    <div *ngIf="!groupItems || (groupItems && groupItems.length === 0)">
      <div class="dr-admin-panel panel-properties">
        <h5>Builder</h5>
        <p>Select from the toolbox on the left to build a form.</p>
      </div>
    </div>
    <div *ngIf="groupItems && groupItems.length">
      <ups-dynamic-admin-container
        (formBuilderItemSelected)="select($event)"
        (formBuilderItemReordered)="reordered($event)"
        [dynamicGroup]="dynamicGroup"
      ></ups-dynamic-admin-container>
    </div>
  </div>
  <div
    *ngIf="canModify && dynamicGroup?.controls?.length"
    class="dr-admin-save-button"
  >
    <div class="dynamic-field form-button save-update-button">
      <button class="k-button k-primary" (click)="submit()">
        {{ dynamicRender.metadata?.dynamicContainerId ? 'Update' : 'Save' }}
      </button>

      <!-- <button class="k-button" (click)="logStructure()">
        Debug: Log Structure
      </button> -->
    </div>
    <div class="dynamic-field form-button publish-button">
      <button class="k-button k-primary" (click)="submit(true)">
        {{ dynamicRender.metadata?.dynamicContainerId ? 'Update' : 'Save' }} &
        Publish
      </button>
    </div>
  </div>
  <a class="top-link hide" href="" id="js-top" (click)="scrollToTop($event)">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 6">
      <path d="M12 6H0l6-6z" />
    </svg>
    <!-- need master css styler to get something nice for this -->
    <!-- <span class="top-link hide btn-apply" [class.has-changes]="dynamicRender.activeFormProperties?.dirty"> Apply </span> -->
  </a>
</div>
