import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  DetailsViewService,
  FileManagerModule as SFFileMgr,
  NavigationPaneService,
  ToolbarService,
} from '@syncfusion/ej2-angular-filemanager';

import { COMPONENTS } from './components';

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, SFFileMgr],
  exports: [...COMPONENTS],
  providers: [
    DetailsViewService,
    NavigationPaneService,
    ToolbarService,
  ],
})
export class FilesModule {}
