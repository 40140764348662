import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { catchError } from 'rxjs/operators';

import { environment, MyHttpClientFactory } from '@ups/xplat/core';

import { AcceptInterestDTO } from '@ups/xplat/base/employee-profile';

const GET_JOB_OPENINGS: string = '/api/jobopenings';
const GET_INTEREST_ODATA: string = '/api/jobopenings/submittedinterest-odata';
const GET_OPENINGS_COUNT: string = '/api/jobopenings/employeeCountOData';
const GET_EMPLOYEE_PROSPECT_JOB_OPENINGS: string = '/api/jobopeningsByJobClass';
const GET_MANAGER_JOB_OPENINGS: string = '/api/jobopenings/manager';
const GET_RECRUITER_JOB_OPENINGS: string = '/api/jobopenings/recruiter';
const GET_JOB_OPENINGS_BY_CLASS: string =
  '/api/jobopenings?sJobClass={:JobClass}';
const GET_EMPLOYEE_JOB_OPENINGS_BY_CLASS: string =
  '/api/jobopenings/jobclass/{:JobClass}/employee/{:employeeID}';
const GET_EMPLOYEE_JOB_OPENINGS: string = '/api/jobopenings/{:hrref}';
const GET_EMPLOYEE_JOB_OPENINGS_BY_EMPLOYEE_ID: string =
  '/api/jobopenings/ByEmployeeId/{:employeeId}';
const SUBMIT_INTEREST_IN_JOB = '/api/jobopenings/submitInterest';
const ACCEPT_INTEREST_IN_JOB = 'api/jobopenings/{:id}/acceptInterest';
const REJECT_INTEREST_IN_JOB = 'api/jobopenings/{:id}/rejectInterest';
const WITHDRAW_INTEREST_OF_SUBMITTED_APPLICATION =
  '/api/jobopenings/deleteSubmittedInterest';
const GET_CURRENT_JOB_OPENINGS = '/api/jobopenings/EmployeeCurrentJobs';
const FETCH_EMPLOYEE_CLASS_INFO =
  '/api/jobopenings/fetchEmployeeClassInfo/{:hrref}';
const REQUISITION_WITH_INTEREST = '/api/jobopenings/requisition';
@Injectable({
  providedIn: 'root',
})
export class JobOpeningsService {
  httpClient: HttpClient;

  constructor(injector: Injector, httpFactory: MyHttpClientFactory) {
    this.httpClient = httpFactory.createHttpClient(
      environment.urls.spartaAPI,
      true
    );
  }

  public getJobOpeningsByEmployeeAndClass(
    jobClass: string,
    employeeID: string
  ): any {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers };
    return this.httpClient.post(
      GET_EMPLOYEE_JOB_OPENINGS_BY_CLASS,
      { JobClass: jobClass, EmployeeID: employeeID },
      options
    );
  }

  public getEmployeeCurrentJob(jobClass: string, employeeID: string): any {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers };
    return this.httpClient.post(
      GET_CURRENT_JOB_OPENINGS,
      { JobClass: jobClass, EmployeeID: employeeID },
      options
    );
  }

  public getJobOpeningsByEmployeeProspectAndClass(jobClass: string): any {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers };

    return this.httpClient.post(
      GET_EMPLOYEE_PROSPECT_JOB_OPENINGS,
      {
        JobClass: jobClass,
      },
      options
    );
  }

  public getJobOpenings(): any {
    return this.httpClient.get(GET_JOB_OPENINGS);
  }

  public getInterestOdata(odatastring): any {
    const url =
      GET_INTEREST_ODATA +
      '?$format=json&$count=true&' +
      (odatastring ? odatastring : '');
    return this.httpClient.get(url);
  }

  public getManagerJobOpenings(): any {
    return this.httpClient.get(GET_MANAGER_JOB_OPENINGS);
  }

  public getRecruiterJobOpenings(): any {
    return this.httpClient.get(GET_RECRUITER_JOB_OPENINGS);
  }

  public getJobOpeningsByClass(jobClass: string): any {
    if (jobClass && jobClass == 'N/A') jobClass = null;
    const url = GET_JOB_OPENINGS_BY_CLASS.split('{:JobClass}').join(jobClass);
    return this.httpClient.get(url);
  }

  public getEmployeeJobOpeningsByClass(
    jobClass: string,
    employeeID: string
  ): any {
    if (jobClass && jobClass === 'N/A') jobClass = null;
    let url =
      GET_EMPLOYEE_JOB_OPENINGS_BY_CLASS.split('{:JobClass}').join(jobClass);
    url = url.split('{:employeeID}').join(employeeID);
    return this.httpClient.get(url).pipe(catchError(this.handleError));
  }

  public getEmployeeJobOpenings(
    hrref: number | string,
    showPastInterests: boolean = false,
    jobNumber: string = null
  ): any {
    let url = GET_EMPLOYEE_JOB_OPENINGS.split('{:hrref}').join(
      hrref.toString()
    );
    const queryParams = {
      showPastInterests,
      jobNumber,
    };
    const paramsToAdd = Object.entries(queryParams).filter(
      (x) => x[1] !== undefined && x[1] !== null
    );
    if (paramsToAdd.length > 0) {
      url += '?';
      const query = paramsToAdd.map((x) => `${x[0]}=${x[1]}`);
      url += query.join('&');
    }
    return this.httpClient.get(url);
  }

  public getEmployeeJobOpeningsByEmployeeId(
    employeeId: string,
    showPastInterests: boolean = false,
    jobNumber: string = null
  ): any {
    let url =
      GET_EMPLOYEE_JOB_OPENINGS_BY_EMPLOYEE_ID.split('{:employeeId}').join(
        employeeId
      );
    const queryParams = {
      showPastInterests,
      jobNumber,
    };
    const paramsToAdd = Object.entries(queryParams).filter(
      (x) => x[1] !== undefined && x[1] !== null
    );
    if (paramsToAdd.length > 0) {
      url += '?';
      const query = paramsToAdd.map((x) => `${x[0]}=${x[1]}`);
      url += query.join('&');
    }
    return this.httpClient.get(url);
  }

  public getEmployeeClassInfo(hrref: string): any {
    const url = FETCH_EMPLOYEE_CLASS_INFO.split('{:hrref}').join(hrref);
    return this.httpClient.get(url);
  }

  public handleError(error: any) {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  public submitInterestInJob(submitInterest: any): any {
    var url = SUBMIT_INTEREST_IN_JOB;

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.httpClient.put(url, submitInterest, options);
  }

  public acceptInterestInJob(interest: AcceptInterestDTO) {
    const url = ACCEPT_INTEREST_IN_JOB.split('{:id}').join(
      interest.employeeJobInterestId
    );
    return this.httpClient.post(url, interest);
  }

  public rejectInterestInJob(interestId: string) {
    const url = REJECT_INTEREST_IN_JOB.split('{:id}').join(interestId);
    return this.httpClient.delete(url);
  }

  public requisitionWithInterest(interestObject: any) {
    const url = REQUISITION_WITH_INTEREST;
    return this.httpClient.post(url, interestObject);
  }

  public withdrawInterestOfSubmittedApplication(
    submittedApplication: any
  ): any {
    let url = WITHDRAW_INTEREST_OF_SUBMITTED_APPLICATION;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    // let body = JSON.stringify(submittedApplication);
    return this.httpClient.put(url, submittedApplication, options);
  }

  public getEmployeeOpeningCounts(odataQuery: string) {
    const url =
      GET_OPENINGS_COUNT +
      '?$format=json&$count=true&' +
      (odataQuery ? odataQuery : '');
    return this.httpClient.get(url);
  }
}
