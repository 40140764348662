<span
  *ngFor="let i of filledStars"
  class="star"
  [class.previous-hovered]="lastHoveredStar && i < lastHoveredStar"
  (click)="setRating(i + 1)"
  (mouseover)="hoverStar(i + 1)"
  (mouseout)="hoverStar(null)"
>
  <i class="fas fa-star"></i>
</span>
<span
  *ngIf="hasHalfStar"
  class="star"
  [class.previous-hovered]="
    lastHoveredStar && filledStars.length < lastHoveredStar
  "
  (click)="setRating(filledStars.length + 1)"
  (mouseover)="hoverStar(filledStars.length + 1)"
  (mouseout)="hoverStar(null)"
>
  <i class="fas fa-star-half-alt"></i>
</span>
<span
  *ngFor="let i of emptyStars"
  class="star"
  [class.previous-hovered]="
    lastHoveredStar && allFilledStarsLength + i < lastHoveredStar
  "
  (click)="setRating(allFilledStarsLength + 1 + i)"
  (mouseover)="hoverStar(allFilledStarsLength + 1 + i)"
  (mouseout)="hoverStar(null)"
>
  <i class="far fa-star"></i>
</span>
