import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { MyHttpClientFactory, environment } from '@ups/xplat/core';
import { parseData } from '@ups/xplat/utils';
import { Observable } from 'rxjs';

const GET_EMPLOYEE_JOB_OPENINGS = '/api/jobopenings/{:hrref}';
const GET_CURRENT_JOBS_OF_EMPLOYEE =
  '/api/manpowers/currentandupcomingjobsByHrref/{:hrref}';
const SUBMIT_INTEREST_IN_JOB = '/api/jobopenings/submitInterest';
const WITHDRAW_INTEREST_OF_SUBMITTED_APPLICATION =
  '/api/jobopenings/deleteSubmittedInterest';

@Injectable({
  providedIn: 'root',
})
export class EmployeeUpcomingJobsService {
  private httpClient: HttpClient;

  constructor(httpFactory: MyHttpClientFactory) {
    this.httpClient = httpFactory.createHttpClient(
      environment.urls.spartaAPI,
      true
    );
  }

  public getEmployeeJobOpenings(
    hrref: number,
    showPastInterests = false,
    jobNumber: string = null
  ): any {
    let url = GET_EMPLOYEE_JOB_OPENINGS.split('{:hrref}').join(
      hrref.toString()
    );
    const queryParams = {
      showPastInterests,
      jobNumber,
    };
    const paramsToAdd = Object.entries(queryParams).filter(
      (x) => x[1] !== undefined && x[1] !== null
    );
    if (paramsToAdd.length > 0) {
      url += '?';
      const query = paramsToAdd.map((x) => `${x[0]}=${x[1]}`);
      url += query.join('&');
    }
    return this.httpClient.get(url);
  }

  public getCurrentAndUpcomingJobsByHRREf(hrref: string): any {
    const url = GET_CURRENT_JOBS_OF_EMPLOYEE.split('{:hrref}').join(hrref);
    return this.httpClient.get(url).toPromise().then(parseData);
  }

  public submitInterestInJob(submitInterest: {
    employeeId: string;
    openingId: string;
  }): Observable<any> {
    const url = SUBMIT_INTEREST_IN_JOB;

    return this.httpClient.put(url, submitInterest);
  }

  public withdrawInterest(
    mprOpeningId: string,
    hrRef: number
  ): Observable<any> {
    const url = WITHDRAW_INTEREST_OF_SUBMITTED_APPLICATION;
    return this.httpClient.put(url, {
      vManPowerOpeningId: mprOpeningId,
      HRRef: hrRef,
    });
  }
}
