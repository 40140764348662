/* eslint-disable @typescript-eslint/naming-convention */
import { EquipmentUsageCorrectionDto } from './EquipmentUsageCorrectionDto';

export class EquipmentUsageDetailDto {
  public Amount: number;
  public Corrections: EquipmentUsageCorrectionDto[];
  public DateExported: string;
  public DateReallocated: string;
  public DateUsed: Date;
  public EquipmentUsageID: string;
  public ReallocatedBy: string;
  public TimeSheetID: string;
  public TimeSheetName: string;
  public bActive: boolean;
  public HasCorrections: boolean;
  CreatedBy: string;

  public isDirty: boolean;
}
