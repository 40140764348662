<div class="road1" *ngIf="addressModel?.Line1 || addressModel?.Address">
  {{ addressModel?.Line1 || addressModel?.Address }}
</div>
<div class="road2" *ngIf="addressModel?.Line2 || addressModel?.Address2">
  {{ addressModel?.Line2 || addressModel?.Address2 }}
</div>
<div class="cityState">
  <ng-container *ngIf="addressModel?.City && addressModel?.State">
    {{ addressModel?.City }}, {{ addressModel?.State }}
  </ng-container>
  {{ addressModel?.Zip }}
</div>
<div class="country" *ngIf="addressModel?.Country">
  {{ addressModel?.Country }}
</div>
