import { BaseModel } from '../base.model';

export class RoleDto extends BaseModel {
  public RoleID: string;
  public RoleName: string;

  constructor(o: any = null) {
    super(o);
  }
}
