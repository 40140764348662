import { BaseModel } from '../../base.model';

export class ManPowerReqGridDto extends BaseModel {
  public ManPowerReqID: string | null;
  public JobID: string;
  public VPJobID: string;
  public JobName: string;
  public VPCompanyID: number | null;
  public IsActive: boolean;
  public PlantID: number | null;
  public PlantName: string;
  public BadgeLeadDays: number;
}
