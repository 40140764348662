import {
  Injectable,
  EventEmitter,
  Output,
  Injector,
  Inject,
} from '@angular/core';
import { parseData } from '@ups/xplat/utils';
import { HttpResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { environment, MyHttpClientFactory } from '@ups/xplat/core';
import { convertBase64ToByteArray } from '@ups/xplat/web/utils';

declare let _: any;

const GET_EMPLOYEE_DOCUMENTS: string = '/api/employeedocument/{:hrref}';
const PUT_EMPLOYEE_DOCUMENTS: string = '/api/employeedocument/';
const DOWNLOAD_FROM_VIEWPOINT_WITH_GUID: string =
  '/api/sparta/perdiemdocs/attachment/{:id}/foremployeeportalwithguid';

const PUT_EMPLOYEE_DOCUMENTS_VIEWPOINT: string =
  'http://172.17.1.87:8180/api/operationaldocument';
const GET_DOCUMENT_VIEWPOINT: string =
  'http://172.17.1.87:8180/api/operationaldocument/{:id}';
const GET_DOCUMENT_VIEWPOINT_DOWNLOAD: string =
  'http://172.17.1.87:8180/api/operationaldocument/{:id}/download';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  fileDownloaded: EventEmitter<any> = new EventEmitter<any>();
  fileUploaded: EventEmitter<any> = new EventEmitter<any>();
  portalDocument: any;
  fileList: FileList;
  @Output() notifyFile: EventEmitter<any> = new EventEmitter<any>();

  private http: HttpClient;

  constructor(httpFactory: MyHttpClientFactory, private basicHttp: HttpClient) {
    this.http = httpFactory.createHttpClient(
      environment.urls.viewpointAPI,
      true
    );
  }

  public uploadDocument(file: any): any {
    var url = PUT_EMPLOYEE_DOCUMENTS;

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    return this.http.put(url, file, options).toPromise().then(parseData);
  }

  public downloadFileFromViewPoint(attachmentId: string): any {
    let url =
      '/' + DOWNLOAD_FROM_VIEWPOINT_WITH_GUID.replace('{:id}', attachmentId);

    return this.http.get(url).toPromise().then(parseData);
  }

  public uploadDocumentToViewPoint(file: any): any {
    var url = PUT_EMPLOYEE_DOCUMENTS_VIEWPOINT;

    return this.http.post(url, file).toPromise().then(parseData);
  }

  public displayDocument(hrref: string): any {
    var url = GET_EMPLOYEE_DOCUMENTS.split('{:hrref}').join(hrref);

    return this.basicHttp
      .get(url, { responseType: 'blob', observe: 'response' })
      .pipe(
        map(
          (res: HttpResponse<Blob>) =>
            new Blob([res.body], { type: 'application/pdf' })
        )
      );
  }

  public getSpecificFileForDownload(documentId: any): any {
    console.log('TODO: Fix this service it is broken.');
    var url = GET_DOCUMENT_VIEWPOINT.split('{:id}').join(documentId);

    return this.http.get(url).toPromise().then(parseData);
  }

  public uploadFile(fileList: FileList) {
    this.fileList = fileList;
    let base64Encoded: string;
    this.portalDocument = {};

    if (this.fileList.length > 0) {
      var file: File = this.fileList[0];
      var myReader: FileReader = new FileReader();

      myReader.onload = () => {
        base64Encoded = myReader.result as string;

        this.portalDocument.EmployeeHRRef = 55;
        this.portalDocument.FileName = file.name;
        this.portalDocument.FileSize = file.size;

        this.portalDocument.FileType =
          this.GetFileTypeFromBase64(base64Encoded);
        var byteCharacters = convertBase64ToByteArray(base64Encoded);
        this.portalDocument.EmployeeDocument = byteCharacters;
      };
      myReader.readAsDataURL(file);
    }
  }

  public onCallUploadFile(): any {
    return this.uploadDocument(this.portalDocument).then((data) => {
      if (!data.HasError) {
        this.success(data);
        this.portalDocument.EmployeeDocumentID = data.Data;
        this.notifyFile.emit(this.portalDocument);
        return data;
      } else {
        this.fail(data);
      }
    });
  }

  public uploadFileToViewPoint() {
    let base64Encoded: any;
    let viewPointDocument: any = {};

    if (this.fileList.length > 0) {
      var file: File = this.fileList[0];
      var myReader: FileReader = new FileReader();

      myReader.onload = () => {
        base64Encoded = myReader.result;
        viewPointDocument.Ssn = '123456789';
        viewPointDocument.DocumentType = 1;
        viewPointDocument.AutoApprove = true;
        viewPointDocument.FileName = file.name;
        var byteCharacters = convertBase64ToByteArray(base64Encoded);
        viewPointDocument.FileBytes = byteCharacters;
        viewPointDocument.JsonMetadata = '';

        this.uploadDocumentToViewPoint(viewPointDocument).then((data) => {
          if (!data.HasError) {
            this.success(data);
          } else {
            this.fail(data);
          }
        });
      };
      myReader.readAsDataURL(file);
    }
  }

  public GetFileTypeFromBase64(wholeBase: string): string {
    var beforeSemi = wholeBase.split(';')[0];
    var afterColon = beforeSemi.split(':')[1];

    return afterColon;
  }

  public success(data: any): any {
    return true;
  }

  public fail(data: any): any {
    return false;
  }
}
