/* eslint-disable */
import {
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import {
  ErrorHandler,
  InjectionToken,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ICON_SETTINGS } from '@progress/kendo-angular-icons';

// libs
import {
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { PerDiemService } from '@ups/xplat/api/services';

import {
  CacheCollectionService,
  CoreModule,
  ExportService,
  LiveUpdateService,
  LocalStorageService,
  OfflineStorageService,
  PlatformDeviceInfoToken,
  PlatformLanguageToken,
  PlatformWindowToken,
  RouterToken,
  SessionStorageService,
  SignalRPlatformService,
} from '@ups/xplat/core';
import {
  FeatureSwitch,
  throwIfAlreadyLoaded,
} from '@ups/xplat/utils';

import {
  GlobalErrorHandler,
  MessageHelper,
  NotificationsViewService,
  PROVIDERS,
  WebLiveUpdateService,
} from './services';
import { WebCacheCollectionService } from './services/web-cache-collection.service';
import { WebExportService } from './services/web-export.service';
import { WebNetworkService } from './services/web-network.service';
import { WebOfflineStorageService } from './services/web-offline-storage.service';
import { WebSignalRPlatformService } from './services/web-signal-r-platform.service';
// import { WebMicrosoftClarityService } from './services/web-microsoft-clarity.service';
import { WebSmartlookService } from './services/web-smartlook.service';

// bring in custom web services here...

// factories
export function winFactory() {
  return window;
}

export function platformLangFactory() {
  const browserLang = window.navigator.language || 'en'; // fallback English
  // browser language has 2 codes, ex: 'en-US'
  return browserLang.split('-')[0];
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `./assets/i18n/`,
    '.json'
  );
}

export function platformDeviceInfoFactory() {
  /**
   * Done here to ensure version info is available to the earliest api calls made on app launch
   */
  // TODO: web team - you could fill this in with various web info
  const versionName = '1.0.0';
  const buildNumber = '1.0.0';
  const deviceDetails = 'browser'; // this can be a collection of any client details
  // console.log('version:', versionName, ' versionDisplay:', `v${versionName} (${buildNumber})`);
  return {
    version: versionName,
    versionDisplay: `v${versionName} (${buildNumber})`,
    os: 'web', // could report client platform (or browser vendor)
    deviceDetails,
  };
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
      timeOut: 10000,
      preventDuplicates: true,
    }),
    CoreModule.forRoot([
      FeatureSwitch.globalErrorHandlerEnabled
        ? {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
          }
        : {
            provide: new InjectionToken('empty'),
            useValue: null,
          },
      {
        provide: PlatformLanguageToken,
        useFactory: platformLangFactory,
      },
      {
        provide: PlatformWindowToken,
        useFactory: winFactory,
      },
      {
        provide: PlatformDeviceInfoToken,
        useFactory: platformDeviceInfoFactory,
      },
      {
        provide: RouterToken,
        // just nullify: we rely on Router injection in ui.effect
        // however the token at least needs a provider
        useValue: null,
      },
      {
        provide: OfflineStorageService,
        useClass: WebOfflineStorageService,
      },
      LocalStorageService,
      SessionStorageService,
      {
        provide: CacheCollectionService,
        useClass: WebCacheCollectionService,
      },
      {
        provide: SignalRPlatformService,
        useClass: WebSignalRPlatformService,
      },
      {
        provide: LiveUpdateService,
        useClass: WebLiveUpdateService,
      },
      {
        provide: ExportService,
        useClass: WebExportService,
      },
    ]),
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: false, // Restrict extension to log-only mode
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: ICON_SETTINGS, useValue: { type: 'font' } },
    ...PROVIDERS,
  ],
})
export class UpsCoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: UpsCoreModule,
    // global web behavior
    // perDiemService: PerDiemService,
    webNetworkService: WebNetworkService,
    // init singleton services
    private messageHelper: MessageHelper,
    private notifications: NotificationsViewService, // private clarity: WebMicrosoftClarityService
    private smartlook: WebSmartlookService
  ) {
    throwIfAlreadyLoaded(parentModule, 'UpsCoreModule');

    // perDiemService.showMessage$.subscribe((res) => {
    //   switch (res.type) {
    //     case 'error':
    //       this.messageHelper.error(res.message);
    //       break;
    //     case 'information':
    //       this.messageHelper.information(res.message);
    //       break;
    //     case 'success':
    //       this.messageHelper.success(res.message);
    //       break;
    //   }
    // });

    // web offline behavior handling
    webNetworkService.initOfflineHandling();
  }
}
