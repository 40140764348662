import { Injectable, EventEmitter } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subject, Subscription, timer } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { SpartaSyncStateDto } from '@ups/xplat/api/dto';
import { SyncSpartaService } from '@ups/xplat/web/core';

@Injectable({
  providedIn: 'root',
})
/** Shared across components and pages to have unified periodically refreshed access to the Sparta Sync Status */
export class SyncSpartaPeriodicRefreshService {
  private selectedJobId: string;
  private lastSyncStates: SpartaSyncStateDto[] = [];
  private routerEvents: Record<string, Subscription> = {};
  private scheduleGetSyncStates = new Subject<boolean>();
  private lastUserActionTime = new Date();
  private forceIncludeJob = false;

  constructor(private syncSpartaService: SyncSpartaService) {
    // The logic here is to gradually increase interval between fetching the latest sync state from server over time to release stress from backend server.
    // The interval is reset to a shorter time whenever there is an action from user (changed selected job, grid refreshed, ...)
    this.scheduleGetSyncStates
      .pipe(
        debounceTime(1000),
        switchMap((firstPageLoad) =>
          timer(this.calculateNewSleepTimeInMilliseconds(firstPageLoad)).pipe(
            map(() => firstPageLoad)
          )
        )
      )
      .subscribe((firstPageLoad) => {
        if (this.keepCheckingSyncStatus) {
          this.getSyncStates(firstPageLoad);
        }
      });
  }

  get jobId(): string {
    return this.selectedJobId;
  }

  set jobId(jobId: string) {
    this.fetchSyncStates(jobId);
  }

  private keepCheckingSyncStatus = false;
  public syncStatesChanged = new EventEmitter<SpartaSyncStateDto[]>();
  public isLoading = new EventEmitter<boolean>();

  public fetchSyncStates(jobId: string | null) {
    if (this.selectedJobId === jobId) {
      this.resetSyncTimer(false);
      return;
    }

    this.selectedJobId = jobId;
    this.resetSyncTimer(true);
  }

  public startSync(syncState: SpartaSyncStateDto) {
    this.resetSyncTimer(false);
    this.syncSpartaService
      .startSync(syncState.MethodName, this.jobId)
      .subscribe();
    syncState.IsRunning = true;
  }

  /**
   * Starts the periodic sync update when the page is loaded.
   * Contains common code that would be usually used on every page that uses the component in ngOnInit method.
   * To avoid duplicating code use this method in ngOnInit of the page.
   * @returns Url of the current page. Remember it and use it when calling onPageDestroy() method when navigating away from the page.
   */
  public onPageInit(
    router: Router,
    jobId: string,
    forceIncludeJob: boolean
  ): string {
    const currentRoute = router.url;
    this.selectedJobId = jobId;
    this.forceIncludeJob = forceIncludeJob;

    if (!this.routerEvents[currentRoute]) {
      this.routerEvents[currentRoute] = router.events.subscribe((event) => {
        if (event instanceof NavigationStart && event.url === currentRoute) {
          this.resetSyncTimer(true);
        } else if (
          (event instanceof NavigationEnd && event.url === currentRoute) ||
          (event instanceof NavigationStart && event.url !== currentRoute)
        ) {
          this.keepCheckingSyncStatus = false;
        }
      });
    }

    this.resetSyncTimer(true);

    return currentRoute;
  }

  private resetSyncTimer(firstPageLoad: boolean) {
    this.keepCheckingSyncStatus = true;
    this.lastUserActionTime = new Date();

    if (firstPageLoad === true) {
      this.isLoading.emit(true);
    }

    this.scheduleGetSyncStates.next(firstPageLoad);
  }

  /**
   * Stops the periodic sync update when moving out from the page that uses the sync component.
   * Contains common code that would be usually used on every page that uses the component.in ngOnDestroy method.
   * To avoid duplicating code use this method in ngOnDestroy of the page.
   * @pageUrl: Use the Url returned by SyncSpartaPeriodicRefreshService.onPageInit().
   * Remark: We cannot use router instance as parameter directly but rather pageUrl as when onPageDestroy is called the router.url already has a new url from currently active page.
   */
  public onPageDestroy(pageUrl: string) {
    this.keepCheckingSyncStatus = false;
    const routerEvent = this.routerEvents[pageUrl];
    if (routerEvent) {
      routerEvent.unsubscribe();
      this.routerEvents[pageUrl] = null;
    }
  }

  private getSyncStates(firstPageLoad: boolean) {
    this.syncSpartaService
      .fetchSyncStates(this.jobId, this.forceIncludeJob)
      .subscribe({
        next: (d) => {
          this.scheduleGetSyncStates.next(false);
          this.processSyncStates(firstPageLoad, d);
        },
        error: () => {
          this.scheduleGetSyncStates.next(false);
        },
      });
  }

  private processSyncStates(
    firstPageLoad: boolean,
    syncStates: SpartaSyncStateDto[]
  ) {
    // create new objects for every type of sync on first load or update them on subsequent loads
    if (
      firstPageLoad ||
      syncStates.length !==
        (!this.lastSyncStates ? 0 : this.lastSyncStates.length)
    ) {
      const syncStatesUI: SpartaSyncStateDto[] = [];
      for (const state of syncStates) {
        const stateUI = new SpartaSyncStateDto();
        stateUI.update(state);
        syncStatesUI.push(stateUI);
      }

      this.lastSyncStates = syncStatesUI;
    } else {
      for (const state of this.lastSyncStates) {
        const newState = syncStates.find(
          (s) => s.MethodName === state.MethodName
        );
        state.update(newState);
      }
    }

    this.lastSyncStates = !this.lastSyncStates ? [] : this.lastSyncStates;
    this.isLoading.emit(false);
    this.syncStatesChanged.emit(this.lastSyncStates);
  }

  /**
   * This is gradually increase the time between refreshes of the SyncStatus since the last user action on the page - to lower stress on backend server
   */
  private calculateNewSleepTimeInMilliseconds(firstPageLoad: boolean): number {
    const now = new Date();
    const secondsFromLastUserAction = this.dateDiffSeconds(
      this.lastUserActionTime,
      now
    );

    let sleepTime: number;
    if (firstPageLoad || secondsFromLastUserAction < 2) {
      sleepTime = 0;
    } else if (secondsFromLastUserAction < 15) {
      // within first 15 seconds wake up every 5 seconds
      sleepTime = 5000;
    } else if (secondsFromLastUserAction < 60) {
      // within 15 to 60 seconds wake up every 10 seconds
      sleepTime = 10000;
    } else if (secondsFromLastUserAction < 600) {
      // within 1 to 10 minutes wait up every 15s
      sleepTime = 15000;
    } else {
      // over 10 minutes wake up every 1 minute
      sleepTime = 60000;
    }

    return sleepTime;
  }

  private dateDiffSeconds(oldDate: Date, newDate: Date) {
    const diff = (newDate.getTime() - oldDate.getTime()) / 1000;
    return Math.abs(Math.round(diff));
  }
}
