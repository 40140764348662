import { BaseModel } from '../base.model';

export class UpdateEmployeeOnsiteStateDto extends BaseModel {
  PRCo: number;
  EmployeeVPID: number;
  OnsiteDate: Date;

  constructor(o: any = null) {
    super(o);
  }
}
