import { Inject, Injectable, Injector } from '@angular/core';
import { parseData } from '@ups/xplat/utils';
import { ProspectModel, TradeGroupDto } from '@ups/xplat/api/dto';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MyHttpClientFactory, environment } from '@ups/xplat/core';

const GET_TRADE_GROUPS: string = '/api/classManagement/tradeGroups';
const GET_TRADE_CLASSES: string = '/api/classManagement/tradeClasses/all';
const CREATE_APPLICANT: string = '/api/applicant';
const GET_PROSPECT_POPUP: string = '/api/prospect/{:prospectID}';
const SAVE_PROSPECT_POPUP: string = '/api/prospect';

@Injectable({
  providedIn: 'root',
})
export class ProspectService {
  http: HttpClient;

  constructor(private httpFactory: MyHttpClientFactory) {
    this.http = httpFactory.createHttpClient(environment.urls.spartaAPI);
  }

  public getTradeGroups(): Promise<TradeGroupDto[]> {
    let url = GET_TRADE_GROUPS;
    return this.http.get(url).toPromise().then(parseData);
  }

  public getTradeClasses(): any {
    let url = GET_TRADE_CLASSES;
    return this.http.get(url).toPromise().then(parseData);
  }

  public createApplicantProspect(
    applicant /*: ApplicantBody*/
  ): Promise<any /*ApplicantBody*/> {
    var url = CREATE_APPLICANT;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    return (
      this.http
        .post(url, applicant, options)
        // .map(d=> d as ApplicantBody)
        .toPromise()
    );
  }

  public GetProspectPopUP(prospectID: string): Promise<ProspectModel> {
    var url = GET_PROSPECT_POPUP.split('{:prospectID}').join(prospectID);
    return this.http
      .get(url)
      .toPromise()
      .then((data) => parseData(data) as ProspectModel);
  }

  public SaveProspectPopUP(prospect: ProspectModel): any {
    var url = SAVE_PROSPECT_POPUP;

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    return this.http.put(url, prospect, options).toPromise().then(parseData);
  }
}
