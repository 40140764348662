import { Injectable } from '@angular/core';
import { ApplicationModel } from '@ups/xplat/api/dto';
import { HRResourceService } from './hrresource-service';

@Injectable({
  providedIn: 'root',
})
export class CustomerIndustriesResolver {
  isLoading: boolean = false;

  constructor(private hrResourceService: HRResourceService, private applicationModel: ApplicationModel) {}

  public load(): Promise<any> {
    if (this.applicationModel.customerIndustries.length === 0 && !this.isLoading) {
      this.isLoading = true;
      return this.hrResourceService.fetchCustomerIndustries().then((data) => {
        this.applicationModel.customerIndustries = data;
        this.isLoading = false;
      });
    } else {
      return Promise.resolve(this.applicationModel.customerIndustries);
    }
  }
}
