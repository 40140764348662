import { BaseModel } from '../base.model';

export class ReqStatusDto extends BaseModel {
  public ManPowerRequirementStatusID: string;
  public ManPowerRequirementID: string;
  public StatusName: string;
  public isFinalStatus: boolean;
  public isFinalSubtasks: boolean;
  public StatusPosition: number;
  public bActive: boolean;
  public isSystemStatus: boolean;
  public isDeleted: boolean;

  constructor(o: any = null) {
    super(o);
  }
}
