import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationModel } from '@ups/xplat/api/dto';
import { JobsService } from './jobs-service';
import { EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LicenseTypesResolver {
  constructor(private jobsService: JobsService, private applicationModel: ApplicationModel) {}

  public load(): Observable<any> {
    var self = this;
    if (this.applicationModel.licenseTypes.length == 0) {
      this.jobsService.fetchLicenseTypes().subscribe((data) => (self.applicationModel.licenseTypes = data));
      return EMPTY;
    } else {
      return EMPTY;
    }
  }
}
