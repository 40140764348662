import {
  Injectable,
  Type,
  ComponentFactoryResolver,
  Injector,
  TemplateRef,
  Inject,
  ComponentRef,
  ViewContainerRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { AdminItemMapping } from '../models/dr-admin-panel-item-models';

import {
  EditorComponentBase,
  EditableWrapperComponent,
} from '../components/editors';
import { DynamicModelType } from '@ups/xplat/core';
import { DynamicItemBaseComponent } from '@ups/xplat/features';
import { EDITOR_COMPONENT_MAPPING } from './dr-admin-editor-component-mapping';

export type DynComponentEditorMapping = {
  [key in DynamicModelType]?: AdminItemMapping;
};

export type Content<T> = string | TemplateRef<T> | Type<T>;

@Injectable({ providedIn: 'root' })
export class DrAdminMapResolverService {
  public availableAdminItems: AdminItemMapping[] = [];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    @Inject(DOCUMENT) private document: Document
  ) {
    for (const key in EDITOR_COMPONENT_MAPPING) {
      const item = EDITOR_COMPONENT_MAPPING[key] as AdminItemMapping;
      this.availableAdminItems.push(item);
    }
    this.availableAdminItems.sort((a, b) => {
      if (a.displayName < b.displayName) return -1;
      if (a.displayName > b.displayName) return 1;
      return 0;
    });
  }

  public createDesignComponentWrapper(): ComponentRef<EditableWrapperComponent> {
    const wrapperFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        EditableWrapperComponent
      );
    const wrapperComponentRef = wrapperFactory.create(this.injector);

    return wrapperComponentRef;
  }

  public createRenderComponentRef(
    //designItem: AdminDesignPanelItem,
    controlName: string
  ): ComponentRef<DynamicItemBaseComponent> {
    const renderCmpType =
      this.getRenderComponentTypeForControlName(controlName);
    const renderComponentRef = this.resolveComponentType(renderCmpType);

    /*
    const wrapperFactory = this.componentFactoryResolver.resolveComponentFactory(
      EditableWrapperComponent
    );
    const wrapperComponentRef = wrapperFactory.create(this.injector);
*/
    //wrapperComponentRef.instance.childComponentType = renderCmpType;
    //wrapperComponentRef.instance.childConfig = designItem.

    return renderComponentRef;
    //return wrapperComponentRef as any;
  }

  public createEditorComponentRef(
    controlName: string
  ): ComponentRef<EditorComponentBase> {
    const editorCmpType =
      this.getEditorComponentTypeForControlName(controlName);
    const editorComponentRef = this.resolveComponentType(editorCmpType);
    return editorComponentRef;
  }

  public createEditorComponentRefWithViewContainer(
    controlName: string,
    viewContainerRef: ViewContainerRef
  ): ComponentRef<EditorComponentBase> {
    const editorCmpType =
      this.getEditorComponentTypeForControlName(controlName);
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(editorCmpType);
    const editorComponentRef =
      viewContainerRef.createComponent(componentFactory);
    return editorComponentRef;
  }

  /*
  private resolveNgContent<T>(content: Content<T>) {
    if (typeof content === 'string') {
      const element = this.document.createTextNode(content);
      return [[element]];
    }

    if (content instanceof TemplateRef) {
      const viewRef = content.createEmbeddedView(null);
      return [viewRef.rootNodes];
    }

    const componentRef = this.resolveComponentType(content);
    return [[componentRef.location.nativeElement]];
  }
  */

  private resolveComponentType<T>(cType: Type<T>): ComponentRef<T> {
    const factory =
      this.componentFactoryResolver.resolveComponentFactory(cType);

    const componentRef = factory.create(this.injector);
    return componentRef;
  }

  public getRenderDelayForControlName(controlName: string): number {
    return this.availableAdminItems.find((i) => i.controlName === controlName)
      .renderDelay;
  }

  public getRenderComponentTypeForControlName(
    controlName: string
  ): Type<DynamicItemBaseComponent> {
    return this.availableAdminItems.find((i) => i.controlName === controlName)
      .renderedComponent;
  }

  private getEditorComponentTypeForControlName(
    controlName: string
  ): Type<EditorComponentBase> {
    return this.availableAdminItems.find((i) => i.controlName === controlName)
      .editorComponent;
  }
}
