/* eslint-disable @typescript-eslint/naming-convention */
import { Directive, OnInit } from '@angular/core';
import { uniqueObjectsByFields } from '@ups/xplat/utils';
import { dynamicTypeaheadEmployeeFilter } from '../../utils';
import { DynamicTypeaheadBaseComponent } from '../dynamic-typeahead/dynamic-typeahead.base-component';

@Directive()
export abstract class DynamicTypeaheadEmployeeBaseComponent
  extends DynamicTypeaheadBaseComponent
  implements OnInit
{
  queryFilter = (query: string, data: unknown) =>
    dynamicTypeaheadEmployeeFilter(query, data, this.customQueryParams);

  ngOnInit() {
    if (this.config?.options?.distinctHRRef) {
      this.config.options.valueQueryParamName = 'hrRefs';
    }
    super.ngOnInit();
  }

  public getCompaniesList(dataItem: {
    Company?: number;
    CompanyDetails: {
      Company?: number;
      IsActive?: boolean;
    }[];
  }) {
    if ((dataItem.CompanyDetails || []).length) {
      return dataItem.CompanyDetails;
    }
    return [{ Company: dataItem.Company || 'n/a' }];
  }

  public getEMDesignationList(dataItem: {
    EMDesignationName?: string;
    CompanyDetails: {
      EMDesignationName?: string;
      IsActive?: boolean;
    }[];
  }) {
    if ((dataItem.CompanyDetails || []).length) {
      const uniqueDesignations = uniqueObjectsByFields(
        dataItem.CompanyDetails,
        ['EMDesignationName']
      );
      return uniqueDesignations.length > 1
        ? dataItem.CompanyDetails
        : uniqueDesignations;
    }
    return [{ EMDesignationName: dataItem.EMDesignationName || 'n/a' }];
  }

  public getFileClassList(dataItem: {
    FileClassName?: string;
    CompanyDetails: {
      FileClassName?: string;
      IsActive?: boolean;
    }[];
  }) {
    if ((dataItem.CompanyDetails || []).length) {
      const uniqueClasses = uniqueObjectsByFields(dataItem.CompanyDetails, [
        'FileClassName',
      ]);
      return uniqueClasses.length > 1 ? dataItem.CompanyDetails : uniqueClasses;
    }
    return [{ FileClassName: dataItem.FileClassName || 'n/a' }];
  }
}
