import { Injectable } from '@angular/core';
import { ApplicationModel } from '@ups/xplat/api/dto';
import { HRResourceService } from './hrresource-service';

@Injectable({
  providedIn: 'root',
})
export class SafteyCouncilResolver {
  constructor(private hrResourceService: HRResourceService, private applicationModel: ApplicationModel) {}

  public load(): Promise<any> {
    if (this.applicationModel.saftyCouncils.length == 0) return this.hrResourceService.getListOfSafetyCouncils().then((data) => (this.applicationModel.saftyCouncils = data));
    else return Promise.resolve(this.applicationModel.saftyCouncils);
  }
}
