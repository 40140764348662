export class ShipMaterial {
  material: string;
  keyID: number;
  loc?: string;
  lineItemId: number;
  qtyPacked: number;
  qtyOnOrder: number;
  packId?: number;
  orderId?: number;
}
