import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from '@ups/xplat/core';

@Injectable()
export class WebSignalRPlatformService {
  private _signalR: signalR.HubConnection;

  constructor() {
    this._signalR = new signalR.HubConnectionBuilder()
      .withUrl(environment.sockets.signalR.url, {
        accessTokenFactory: () => localStorage.id_token,
      })
      .withAutomaticReconnect()
      .build();
  }

  public start() {
    return this._signalR.start();
  }

  public stop() {
    return this._signalR.stop();
  }

  public on(name: string, callback: (data: any) => void) {
    this._signalR.on(name, callback);
  }

  public off(name: string, callback?: (data: any) => void) {
    if (typeof callback === 'function') {
      this._signalR.off(name, callback);
    } else {
      this._signalR.off(name);
    }
  }

  public invoke(name: string, args?: any) {
    if (Array.isArray(args) && args?.length > 1) {
      return this._signalR.invoke(name, ...args);
    } else {
      return this._signalR.invoke(name, args);
    }
  }
}

/// SETUP FROM SIGNALR-notification service

/**
 * Set up signalR listeners
 */
// setupHubConnection = (urlBase: string) => {
//   this.signalR.start();
//   this.connection = new signalR.HubConnectionBuilder()
//     .configureLogging(signalR.LogLevel.Trace)
//     .withUrl(
//       `${urlBase}/notificationhub`,
//       {
//         accessTokenFactory: () => localStorage.access_token,
//       }
//     )
//     .build();

//   // Keepalive
//   // this.connection.keepAliveIntervalInMilliseconds = 10000;

//   // NOTE: handling re-connect on disconnection
//   this.connection.onclose((error) => {
//     this.logger.warn("Connection closed", error);
//     this.startHubConnection();
//   });

//   this.connection.on("onConnectionListReceived", (hubConnectionData) => {
//     // NOTE: just a testing function
//     this.logger.info("hub connection list received", hubConnectionData);
//   });

//   this.connection.on("onNotificationsReceived", (notificationDetailsDtos: []) => {
//     this.logger.info("new notification items were received, mark them as viewed", notificationDetailsDtos, this.initialNotificationsAlreadyProcessed);

//     // NOTE: altered showNotification - it will de-dupe messages, so we don't need the initialNotificationsAlreadyProcessed anymore
//     if (true || !this.initialNotificationsAlreadyProcessed) {

//       // TODO:
//       // Add option to filter out by appId!

//       notificationDetailsDtos.forEach((i: any) => {
//         this.showNotification(i);
//       });

//       this.markNotificationsAsViewed(notificationDetailsDtos.map((i: any) => i.id));

//       // mark processed, so o n re-connect we don't process same messages again
//       this.initialNotificationsAlreadyProcessed = true;
//     }
//   });

//   this.connection.on("onNotificationDismissed", (notificationDetailsDto) => {
//     this.logger.info("a notification was dismissed by one of the client instances", notificationDetailsDto);
//     this.closeNotification(notificationDetailsDto);
//   });

//   this.connection.on("onNotificationSnoozed", (notificationDetailsDto) => {
//     this.logger.info("a notification was snoozed by one of the client instances", notificationDetailsDto);
//     this.closeNotification(notificationDetailsDto);
//   });

// }

// startHubConnection = () => {

//   // TODO: similar approach as Auth0 via Subject vs. checking status?

//   if (this.connection) this.connection
//     .start()
//     .then(success => {
//       // SUCCESS
//       this.logger.info("Notification HUB started...");
//       // NOTE: test only
//       // this.requestConnectionList();
//     })
//     .catch(error => {
//       // ERROR
//       // throw error;
//       this.logger.warn("Errors starting Notification HUB...", error);

//       // retry
//       setTimeout(() => this.startHubConnection(), this.reconnectionTimeout);
//     })
//     ;
// }

// stopHubConnection = () => {
//   if (this.connection) this.connection.stop();
// }
