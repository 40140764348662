import { BaseModel } from '../base.model';
import { FileDataModel } from '../sparta-api/fileData-model';

export class BackgroundModel extends BaseModel {
  isNewItem: boolean;
  editing: boolean;
  showFiles: boolean;
  Delete: boolean = false;

  HRRef: string = '';
  Type: string = '';
  CertDate: Date;
  ExpireDate: Date;
  Score: string = '';
  Result: string = '';
  bActive: boolean = true;
  editable: boolean = false;

  Files: FileDataModel[] = [];

  KeyID?: number;
  SkillTester?: any;
  Notes?: string;

  constructor(o: any = null) {
    super(o);
  }
}
