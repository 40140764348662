import { BaseModel } from '../base.model';

export class SafetyCouncilDto extends BaseModel {
  public SafetyCouncilID: string;
  public Name: string;
  public Contact: string;
  public Address: string;
  public City: string;
  public State: string;
  public ZipCode: string;
  public Email: string;
  public PhoneNumber: string;
  public bActive: boolean;

  constructor(o: any = null) {
    super(o);
  }
}
