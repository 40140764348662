import { BaseModel } from '../base.model';
import { AddressChangeRequestDto } from './AddressChangeRequestDto';

// In case you need to extend the behavior (e.g. add custom properties) extend the class by creating a new class that derives from this class.
// REMARK: As the DTO properties in API Backend are Pascal Cased (Upper Camel Cases) but properties of DTO in TypeScript are lower camel cases use the parseDataToLowerCamelCased() to parse the incoming data on client side:
// Example: return Observable<AddressChangeRequestDto []>this.http.get(url).map(response => this.parseDataToLowerCamelCased(response));
export class AddressChangeRequestExtendedDto extends BaseModel {
  public addressChangeRequestDto: AddressChangeRequestDto;
  public originalAddress: string;
  public originalAddress2: string;
  public originalCity: string;
  public originalState: string;
  public originalZip: string;
  public originalCountry: string;
  public employeeFirstName: string;
  public employeeLastName: string;

  /** Calculated field on client side */
  public employeeFullName: string;

  /** Calculated field on client side  */
  public originalFullAddress: string;

  /** Calculated field on client side  */
  public requestedFullAddress: string;

  /** Hepling client side field */
  public isBeingSaved: boolean;

  constructor(o: any = null) {
    super(o);
  }
}
