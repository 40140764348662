import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlEndpoint',
})
export class UrlEndpointPipe implements PipeTransform {
  transform(url: string) {
    if (url) {
      return url.replace(/^.*\/\/[^\/]+/, '');
    }
    return '';
  }
}
