import { assertType } from './typescript';

/**
 * Parsed the incoming object which if it has Pascal Cased properties they will be changed to lower camel cased e.g. { Name: 'Adam' } will be changed to { name: 'Adam'}
 * As lower camel casing is used in Angular/Client side typescript but API use Pascal cased responses (until they are updated to have JSON serializer to serialize as lower camel cased)
 * @param res Example of usage: Observable<MyFooClass[]>this.http.get(url).map(response => parseDataToLowerCamelCased(response));
 */
export function parseDataToLowerCamelCased(res: any) {
  const body = JSON.parse(res.body, function (key, value) {
    let a;
    if (typeof value === 'string') {
      a = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)$/.exec(
        value
      );
      if (a) {
        return new Date(
          Number(a[1]),
          Number(+a[2]) - 1,
          Number(+a[3]),
          Number(+a[4]),
          Number(+a[5]),
          Number(+a[6])
        );
      }
    } else if (value && typeof value === 'object') {
      for (const k in value) {
        if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
          value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
          delete value[k];
        }
      }
    }

    return value;
  });
  return body;
}

export function parseData<T = any>(res): T {
  let body = null;
  try {
    body = res
      ? JSON.parse(res.body || res._body || JSON.stringify(res), parseDate)
      : null;
  } catch (e) {}
  return body;
}

/**
 * When calling this function from a promise response, it doesn't have access to this.parseDate... but if
 * we duplicate data from anywhere and need to use this function, its copied from above.
 *
 * self.filteredData = JSON.parse(JSON.stringify(self.data),jobsService.parseDate);
 *
 * @param key
 * @param value
 */
export function parseDate(key: any, value: any) {
  let a;
  if (typeof value === 'string') {
    a = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/.exec(
      value
    );
    if (a) {
      return new Date(
        Number(a[1]),
        Number(+a[2]) - 1,
        Number(+a[3]),
        Number(+a[4]),
        Number(+a[5]),
        Number(+a[6])
      );
    }
  }
  return value;
}
