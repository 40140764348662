import {
  AfterViewInit,
  Component,
  ElementRef,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { FileInfo } from '@progress/kendo-angular-upload';

import { SpartaFileService, FileDto } from '@ups/files';
import { IDynamicModel } from '@ups/xplat/core';

import { EditModalBaseComponent } from '@ups/xplat/features';
import { assertType, deepClone } from '@ups/xplat/utils';
import { takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
// import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'ups-edit-modal',
  templateUrl: 'edit-modal.component.html',
})
export class EditModalComponent
  extends EditModalBaseComponent
  implements AfterViewInit
{
  show = false;

  filesInProgress = 0;

  @ViewChild('formElement') form: ElementRef;

  @ViewChildren('search') public searchElementRef: QueryList<ElementRef>;
  private searchInput: ElementRef;

  public selectedAddress: any;
  public addressOptions: any;
  public address: any;
  public placeID: string;

  constructor(
    public spartaFileService: SpartaFileService,
    private http: HttpClient // private mapsAPILoader: MapsAPILoader
  ) {
    super();
    this.closed$.pipe(takeUntil(this.destroy$)).subscribe((saved) => {
      if (!saved) {
        // Erase changes so next time popup is opened the actual data are there. #8982
        // The this.current replaces the changedModel in base class but only in setter when it changes wich is not always the case.
        this.changedModel = deepClone(this.current);
      }
    });
  }

  public ngAfterViewInit() {
    this.searchElementRef.changes.subscribe((comps: QueryList<ElementRef>) => {
      this.searchInput = comps.first;
      // this.mapsAPILoader.load().then(() => {
      //   const autocomplete = new google.maps.places.Autocomplete(
      //     this.searchInput.nativeElement
      //   );
      //   autocomplete.addListener('place_changed', () => {
      //     const place: google.maps.places.PlaceResult = autocomplete.getPlace();

      //     let streetNumber = '';
      //     let route = '';

      //     place.address_components.forEach((cmp) => {
      //       if (cmp.types.includes('street_number')) {
      //         streetNumber = cmp.long_name;
      //       } else if (cmp.types.includes('route')) {
      //         route = cmp.long_name;
      //       } else if (cmp.types.includes('locality')) {
      //         this.changedModel['City'] = cmp.long_name;
      //       } else if (cmp.types.includes('administrative_area_level_1')) {
      //         this.changedModel['State'] = cmp.short_name;
      //       } else if (cmp.types.includes('country')) {
      //         this.changedModel['Country'] = cmp.short_name;
      //       } else if (cmp.types.includes('postal_code')) {
      //         this.changedModel['Zip'] = cmp.long_name;
      //       }
      //     });
      //     this.changedModel['Line1'] = (streetNumber + ' ' + route).trim();
      //   });
      // });
    });
  }

  open(scrollToTop = true, resetModel = false) {
    if (resetModel) {
      // to restore the fields if previous save() action failed - helps only when mutateOriginal is false. Ticket: #8982
      this.changedModel = deepClone(this.current);
    }

    this.show = true;
    if (scrollToTop) {
      setTimeout(() => {
        scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }, 5);
    }
  }

  hide() {
    this.show = false;
  }

  async deleteFile(fileId: string) {
    if (fileId) {
      await this.spartaFileService.deleteFile(fileId);
    }
  }

  async fileChange(evt, field: IDynamicModel) {
    const fileList = evt.files;

    if (!this.changedModel[field.formControlName]) {
      this.changedModel[field.formControlName] = [];
    }
    assertType<FileDto[]>(this.changedModel[field.formControlName]);

    if (fileList.length > 0) {
      fileList.forEach((file: FileInfo) => {
        const myReader: FileReader = new FileReader();
        myReader.onload = () => {
          this.filesInProgress++;
          const fileDto: FileDto = new FileDto();
          if (typeof myReader.result === 'string') {
            fileDto.contentBase64 = myReader.result;
          } else {
            fileDto.content = myReader.result;
          }

          fileDto.name = file.name;
          fileDto.size = file.size;
          fileDto.contentType = file.rawFile.type;
          fileDto.directory = field?.options?.directory || 'editModal';
          fileDto.bActive = true;

          this.spartaFileService
            .uploadFile(fileDto)
            .then((f) => {
              console.debug('uploadFile', f);
              this.changedModel[field.formControlName].push(f);
              this.filesInProgress--;
            })
            .catch((error) => {
              console.error(
                'Error while uploading file thru SpartaService',
                error
              );
              this.filesInProgress--;
              throw error;
            });
        };
        console.log(file);
        myReader.readAsDataURL(file.rawFile);
      });
    }
  }
}
