import { Component, Output, EventEmitter } from '@angular/core';

import { MultiEditProperty } from '@ups/common';

import {
  EquipmentDashboardGridDto,
  EquipmentDispatchStatusEnum,
} from '@ups/xplat/api/dto';
import {
  EquipmentDispatchStatusService,
  EquipmentService,
} from '@ups/xplat/api/services';
import { BaseComponent } from '@ups/xplat/core';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';

import { MessageHelper } from '@ups/xplat/web/core';

@Component({
  selector: 'ups-equipment-dashboard-bulk-edit',
  templateUrl: './equipment-dashboard-bulk-edit.component.html',
  styleUrls: ['./equipment-dashboard-bulk-edit.component.scss'],
})
export class EquipmentDashboardBulkEditComponent extends BaseComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() bulkEditConfirmed: EventEmitter<any> = new EventEmitter<any>();

  items: EquipmentDashboardGridDto[] = [];

  canModify = false;
  isOpened = false;
  isSaving = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataEdit: any = this.createEditModel([]);

  dispatchStatusEnum = EquipmentDispatchStatusEnum;
  dispatchItems: Array<{ text: string; value: number }> = [];
  anyDispatchStatusUneditable = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  assetControlManagers: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  assetControlManagersFiltered: any[] = [];

  constructor(
    private security: SecurityService,
    private msgHelper: MessageHelper,
    private equipmentService: EquipmentService,
    private dispatchStatusService: EquipmentDispatchStatusService
  ) {
    super();

    const fSecurity = this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_equipmentdashboardbulkedit
    );
    this.canModify = fSecurity.editAll;
  }

  open(items: EquipmentDashboardGridDto[]) {
    if (!items || items.length < 2) return;

    this.items = JSON.parse(JSON.stringify(items));
    this.initDispatchItems();
    this.dataEdit = this.createEditModel(this.items);

    // const self = this;
    this.equipmentService.getAssetControlManagers().subscribe((d) => {
      this.assetControlManagers = d;
      this.isOpened = true;
    });
  }

  close() {
    this.isOpened = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createEditModel(selectedItems: any[]) {
    const model = {
      startDate: MultiEditProperty.ForCPP(
        selectedItems,
        (data) => !data.StartDate,
        (left, right) =>
          (left.StartDate == null && right.StartDate == null) ||
          (left.StartDate != null &&
            right.StartDate != null &&
            left.StartDate.getTime() === right.StartDate.getTime()),
        (e) => (e.createFromData ? e.firstData.StartDate : null)
      ),
      endDate: MultiEditProperty.ForCPP(
        selectedItems,
        (data) => !data.EndDate,
        (left, right) =>
          (left.EndDate == null && right.EndDate == null) ||
          (left.EndDate != null &&
            right.EndDate != null &&
            left.EndDate.getTime() === right.EndDate.getTime()),
        (e) => (e.createFromData ? e.firstData.EndDate : null)
      ),
      transportDate: MultiEditProperty.ForCPP(
        selectedItems,
        (data) => !data.TransportDate,
        (left, right) =>
          (left.TransportDate == null && right.TransportDate == null) ||
          (left.TransportDate != null &&
            right.TransportDate != null &&
            left.TransportDate.getTime() === right.TransportDate.getTime()),
        (e) => (e.createFromData ? e.firstData.TransportDate : null)
      ),
      dispatchStatus: MultiEditProperty.ForCCPx(
        selectedItems,
        'DispatchStatusID',
        'DispatchStatus',
        'value',
        'text'
      ),
      assetControlManager: MultiEditProperty.ForCCPx(
        selectedItems,
        'AssetControlManagerID',
        'AssetControlManager',
        'Id',
        'Name'
      ),
      notes: null,
    };
    return model;
  }

  initDispatchItems() {
    const dispatchStatusIDs = this.items.map((i) => i.DispatchStatusID);
    this.dispatchItems = this.dispatchStatusService
      .availableStatuses(dispatchStatusIDs)
      .map((d) => ({ text: d.DispatchStatusName, value: d.DispatchStatusId }));
    if (
      dispatchStatusIDs &&
      dispatchStatusIDs.some(
        (id) => id !== EquipmentDispatchStatusEnum.Dispatched
      )
    ) {
      this.dispatchItems = this.dispatchItems.filter(
        (item) => item.value !== EquipmentDispatchStatusEnum.Dispatched
      );
    }
    this.dispatchItems.sort((a, b) =>
      a.text > b.text ? 1 : b.text > a.text ? -1 : 0
    );
    if (this.dispatchItems.length === 0) {
      this.anyDispatchStatusUneditable = true;
    }
  }

  createUpdateModel(item) {
    item.StartDate = this.dataEdit.startDate.fetchFinalValue(item.StartDate);
    item.EndDate = this.dataEdit.endDate.fetchFinalValue(item.EndDate);
    item.TransportDate = this.dataEdit.transportDate.fetchFinalValue(
      item.TransportDate
    );
    item.DispatchStatusID = this.dataEdit.dispatchStatus.fetchFinalValue(
      item.DispatchStatusID
    );
    item.AssetControlManagerID =
      this.dataEdit.assetControlManager.fetchFinalValue(
        item.AssetControlManagerID
      );
  }

  isValid() {
    return (
      (this.dataEdit.startDate.value ||
        (this.dataEdit.startDate.hasMultipleValues &&
          !this.dataEdit.startDate.valueHasChanged)) &&
      (this.dataEdit.endDate.value ||
        (this.dataEdit.endDate.hasMultipleValues &&
          !this.dataEdit.endDate.valueHasChanged)) &&
      (this.dataEdit.dispatchStatus.value ||
        (this.dataEdit.dispatchStatus.hasMultipleValues &&
          !this.dataEdit.dispatchStatus.valueHasChanged))
    );
  }

  onConfirm() {
    if (!this.isValid()) return;

    this.items.forEach((i) => {
      this.createUpdateModel(i);
    });

    this.isSaving = true;

    this.equipmentService.bulkEditEquipmentRequests(this.items).subscribe(
      () => {
        this.isSaving = false;

        this.close();
        this.bulkEditConfirmed.next('');
        this.msgHelper.success('Equipment Requests updated successfully!');
      },
      (error) => {
        this.isSaving = false;
        this.msgHelper.error(error.error.ExceptionDetails);
      }
    );
  }
}
