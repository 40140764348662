import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DynamicItemBaseComponent } from '@ups/xplat/features';
import { SearchEmployeeService } from '@ups/xplat/api/services';
import { fromEvent, Observable, of } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { UserService } from '@ups/user';
import { uuidv4 } from '@ups/xplat/utils';
import { CommentService } from '@ups/xplat/features';
import { IEmployeeData } from '@ups/xplat/api/dto';
import { EventBusService } from '@ups/xplat/core';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';

/* eslint-disable */
@Component({
  selector: 'ups-dynamic-comment',
  styleUrls: ['dynamic-comment.component.scss'],
  templateUrl: 'dynamic-comment.component.html',
})
export class DynamicCommentComponent
  extends DynamicItemBaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('dynamicComponent') dynamicComponent: ElementRef;
  @ViewChild('commentInput') commentInput: ElementRef;

  isCommenting = false;
  showCancelConfirm = false;
  listenerCreated = false;
  isReplyingTo;
  commentsCurrent: Array<unknown> = [];
  comments: Array<{ id?: string; comments: Array<{ id?: string }> }> = [];
  comment: string;
  mentionConfig: {
    items?: Array<unknown>;
    dropUp?: boolean;
    allowSpace?: boolean;
    disableStyle?: boolean;
    labelKey?: string;
    disableSearch?: boolean;
    mentions?;
  } = {
    items: [],
    dropUp: true,
    allowSpace: true,
    disableStyle: true,
    labelKey: 'FullName',
    disableSearch: true,
  };
  usersCache: Array<IEmployeeData> = [];
  searchQuery: (...args: Array<unknown>) => Observable<Array<unknown>>;
  profile: { firstName: string; lastName: string };

  canMentionRoles = false;
  roles: { RoleId: string; RoleName: string }[];

  constructor(
    private _eventBus: EventBusService,
    private securityService: SecurityService,
    private searchService: SearchEmployeeService,
    private userService: UserService,
    private commentService: CommentService
  ) {
    super();
    this.searchQuery = this.searchService.search.bind(this.searchService);

    this.canMentionRoles = securityService.getFeatureById(
      SecurityConstants.safety_2_0_forms_canmentionroles
    ).editAll;

    this.profile = JSON.parse(localStorage.getItem('profile'));
  }

  ngOnInit() {
    if (this.config.options.dynamicContainerResponseId) {
      this.usersCache.push({
        Id: this.userService.myInfo.Data.EmployeeID,
        FullName: this.userService.fullName,
      });
      this.commentService
        .getComments(this.config.options.dynamicContainerResponseId)
        .pipe(take(1))
        .subscribe((res: any) => {
          let comments = res?.CommentsJson as string;
          if (comments) {
            try {
              // find all userids then fetch them
              let userIds =
                comments.match(
                  /#[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/gi
                ) || ([] as string[]);

              if (userIds?.length) {
                // remove duplicates
                userIds = Array.from(new Set(userIds));
                this.commentService.getAllFormRoles().then((d) => {
                  this.roles = d;
                  this.refreshMentionConfig([], d);
                  this.roles.forEach((role) => {
                    comments = comments.replace(
                      new RegExp(`#${role.RoleId}`, 'gi'),
                      `#${role.RoleName}`
                    );
                  });
                  const roleIds = this.roles.map((r) => r.RoleId);
                  userIds = userIds
                    .map((i) => i.replace('#', ''))
                    .filter((i) => !roleIds.includes(i));
                  this.replaceMentionIds(comments, userIds);
                  this.comments = JSON.parse(comments);
                });
              } else {
                this.comments = JSON.parse(comments);
              }
            } catch (e) {}
          }
        });
    }
  }

  ngAfterViewInit() {
    fromEvent(this.dynamicComponent?.nativeElement, 'click')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.isCommenting && !this.listenerCreated) {
          this.listenerCreated = true;

          fromEvent(this.commentInput?.nativeElement, 'input')
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              console.log('changes detected');

              this._eventBus.emit(this._eventBus.types.cmpReadyEvent, {
                type: 'dynamic-comments-component',
                instance: this.checkForFormChanges.bind(this),
              });
            });
        }
      });
  }

  replaceMentionIds(comments: string, ids: string[]) {
    ids.forEach((id) => {
      this.searchService
        .search(id)
        .pipe(
          take(1),
          catchError(() => of([]))
        )
        .subscribe((value: Array<IEmployeeData>) => {
          value.forEach((user) => {
            comments = comments.replace(
              new RegExp(`#${user.Id}`, 'gi'),
              `@${user.FullName}`
            );
          });
          this.comments = JSON.parse(comments);
        });
    });
  }

  isValid() {
    return (
      this.config.options.dynamicContainerResponseId &&
      this.comment &&
      this.comment.length > 2
    );
  }

  enableCommenting() {
    this.isCommenting = true;
    if (!this.roles) {
      this.commentService.getAllFormRoles().then((d) => {
        this.roles = d;
        this.refreshMentionConfig([], d);
      });
    }
  }

  refreshMentionConfig(employees, roles) {
    const mentions = [
      {
        dropUp: true,
        allowSpace: true,
        labelKey: 'FullName',
        disableStyle: true,
        items: employees,
        disableSearch: true,
        triggerChar: '@',
      },
    ];

    if (this.canMentionRoles) {
      mentions.push({
        dropUp: true,
        allowSpace: true,
        labelKey: 'RoleName',
        disableStyle: true,
        items: roles,
        disableSearch: false,
        triggerChar: '#',
      });
    }

    this.mentionConfig = {
      mentions: mentions,
    };
  }

  searchChange(event) {
    this.searchQuery(event)
      .pipe(
        take(1),
        catchError(() => of([]))
      )
      .subscribe((value) => {
        this.refreshMentionConfig(value, this.roles);
      });
  }

  itemSelected(event) {
    this.usersCache.push(event);
  }

  _findComment(comments: any[], id) {
    if (!comments) {
      return;
    }
    let result;
    comments.every((item) => {
      if (item.id === id) {
        result = item;
        return false;
      } else {
        const found = this._findComment(item.comments, id);
        if (found) {
          result = found;
          return false;
        }
        return true;
      }
    });
    return result;
  }

  replyToComment(id) {
    this.isCommenting = true;
    this.comments.every((item) => {
      const result = item.id === id;
      if (result) {
        this.isReplyingTo = item;
        return false;
      } else {
        const comment = this._findComment(item.comments, id);
        if (comment) {
          this.isReplyingTo = comment;
          return false;
        }
      }
      return true;
    });
  }

  _addNewComment(comments, comment, commentStore?) {
    if (!comments) {
      return;
    }
    if (comment.commentId) {
      comments.forEach((item) => {
        if (item.id === comment.commentId) {
          item.comments.push(comment);
        } else {
          this._addNewComment(item.comments, comment);
        }
      });
    } else {
      (commentStore || this.comments).push(comment);
    }
  }

  _replaceMentionHandles(newComment) {
    this.usersCache.forEach((selection) => {
      newComment.text = newComment?.text?.replaceAll(
        `@${selection.FullName}`,
        `#${selection.Id}`
      );
    });
    this.roles.forEach((role) => {
      newComment.text = newComment?.text?.replaceAll(
        `#${role.RoleName}`,
        `#${role.RoleId}`
      );
    });
    return newComment;
  }

  checkForFormChanges() {
    return this.isCommenting && !!this.comment;
  }

  saveComment() {
    if (!this.isValid()) {
      return;
    }
    const name = this.config?.formControlName;
    if (name && this.config.options.dynamicContainerResponseId) {
      const createdAt = new Date().toJSON();
      const id = uuidv4();
      const newComment = {
        id,
        text: this.comment,
        createdAt,
        createdBy: `${this.profile.firstName} ${this.profile.lastName}`,
        commentId: null,
        comments: [],
      };
      if (this.isReplyingTo) {
        newComment.commentId = this.isReplyingTo.id;
      }

      const commentToSave = { ...newComment };

      const commentToAdd = Object.assign(
        {},
        this._replaceMentionHandles(commentToSave)
      );

      if (this.comments.length) {
        this.commentService
          .getComments(this.config.options.dynamicContainerResponseId)
          .pipe(take(1))
          .subscribe((response: { CommentsJson?: string }) => {
            // fetch latest comments
            if (response && response.CommentsJson) {
              try {
                const comments = JSON.parse(response.CommentsJson);
                this.postComment(commentToAdd, comments);
                this._addNewComment(this.comments, newComment, this.comments);
              } catch (e) {}
            }
          });
      } else {
        const comments = this.comments.slice(0);
        this.postComment(commentToAdd, comments);
      }
    }
  }

  postComment(comment, comments) {
    this._addNewComment(comments, comment, comments);
    const formUrl = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? ':' + window.location.port : ''
    }${this.config.options.formPath}&showComments=true`;
    this.commentService
      .postComment(
        this.config.options.dynamicContainerResponseId,
        comments,
        formUrl
      )
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.comments = comments;
          this.comment = '';
          this.isReplyingTo = undefined;

          this._eventBus.emit(this._eventBus.types.cmpReadyEvent, {
            type: 'dynamic-comments-component',
            instance: this.checkForFormChanges.bind(this),
          });
        }
      });
  }

  cancelComment() {
    this.comment = '';
    this.isCommenting = false;
    this.isReplyingTo = undefined;
    this.showCancelConfirm = false;
  }

  trackByFn(index: unknown, item: { id?: unknown }) {
    return item.id;
  }
}
/* eslint-enable */
