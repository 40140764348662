import { FilterDescriptorEx, ODataExSettings } from './odata';
import { wrapIf } from '@progress/kendo-data-query/dist/npm/filter-serialization.common';

//
// Core
export type Transformation = (
  f: FilterDescriptorEx,
  s: ODataExSettings
) => FilterDescriptorEx;

//
// transformations over the field property
export const normalizeField: Transformation = (f: FilterDescriptorEx) => {
  f.field = (<string>f.field).replace(/\./g, '/');
  return f;
};

export const toLowerField: Transformation = (
  f: FilterDescriptorEx,
  s: ODataExSettings
) => {
  f.field = wrapIf(() =>
    f.hasOwnProperty('ignoreCase') ? f.ignoreCase : s.filter.globalIgnoreCase
  )`tolower(${f.field})`;
  return f;
};

export const castFieldToDateTimeOffset: Transformation = (
  f: FilterDescriptorEx
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isLocalDateSearch = (JSON as any).useDateStringifyMode === 2;
  f.field = wrapIf(() => isLocalDateSearch)`date(${f.field})`;
  return f;
};

export const castFieldToString: Transformation = (f: FilterDescriptorEx) => {
  f.field = wrapIf(() => f.convertToString)`cast(${f.field},'Edm.String')`;
  return f;
};

//
// Transformations over the value
export const quote: Transformation = (f: FilterDescriptorEx) => {
  f.value =
    f.skipQuotes === true
      ? f.value
      : Array.isArray(f.value)
      ? f.value.map((v) => `'${v.replace(/'/g, "''")}'`)
      : (f.value = `'${f.value.replace(/'/g, "''")}'`);
  return f;
};

export const encodeValue: Transformation = (f: FilterDescriptorEx) => {
  f.value = Array.isArray(f.value)
    ? f.value.map((v) => `${encodeURIComponent(v)}`)
    : `${encodeURIComponent(f.value)}`;
  return f;
};

export const toLowerValue: Transformation = (
  f: FilterDescriptorEx,
  s: ODataExSettings
) => {
  f.value = Array.isArray(f.value)
    ? f.value.map(
        (v) =>
          wrapIf(() =>
            f.hasOwnProperty('ignoreValueCase')
              ? f.ignoreValueCase
              : s.filter.ignoreValueCase
          )`tolower(${v})`
      )
    : wrapIf(() =>
        f.hasOwnProperty('ignoreValueCase')
          ? f.ignoreValueCase
          : s.filter.ignoreValueCase
      )`tolower(${f.value})`;
  return f;
};

const formatSingleDate = (value) => {
  let stringifiedDate = JSON.stringify(value).replace(/"/g, '');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isLocalDateSearch = (JSON as any).useDateStringifyMode === 2;
  if (isLocalDateSearch) {
    // Wrap field with date() and append Z to the end (+00:00) so it passes OData
    // Due converting datetime to datetimeoffset on the SQL level the date(field) eq '2019-01-01T20:00:00.000Z' will be compared without time-shifts
    stringifiedDate += 'Z';
  }

  // note: since we allow also non UTC values, we need to encode the (sometimes occuring) + (offset sign) in the ISO8601 stringified date
  return stringifiedDate.replace('+', '%2B');
};

export const formatDateEx: Transformation = (f: FilterDescriptorEx) => {
  f.value = Array.isArray(f.value)
    ? f.value.map((v) => formatSingleDate(v))
    : formatSingleDate(f.value);

  return f;
};
