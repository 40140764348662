import { Component } from '@angular/core';
import { WebDynamicItemPreviewComponentBase } from '../web-dynamic-item-component-base';

@Component({
  selector: 'form-button',
  template: `
    <div class="dynamic-field form-button">
      <label></label>
      <button [attr.name]="config.id" class="k-button k-primary">
        {{ config.data.content }}
      </button>
    </div>
  `,
  styles: [
    `
      .form-button {
        width: 100%;
        height: 100%;
      }
      .form-button button {
        width: 100%;
        height: 100%;
      }
    `,
  ],
})
export class FormButtonComponent extends WebDynamicItemPreviewComponentBase {}
