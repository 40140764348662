import { Component } from '@angular/core';

import { BaseComponent } from '@ups/xplat/core';

@Component({
  selector: 'ups-forms',
  templateUrl: 'forms.component.html',
})
export class FormsComponent extends BaseComponent {
  text = 'todo';
  constructor() {
    super();
  }
}
