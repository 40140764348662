import { Component, OnInit } from '@angular/core';
import { DynamicCheckboxBaseComponent } from '@ups/xplat/features';

@Component({
  selector: 'ups-dynamic-checkbox',
  styleUrls: ['dynamic-checkbox.component.scss'],
  template: `
    <div class="dynamic-field-container" [class.selected]="config?.selected">
      <div
        class="dynamic-field form-input text-align"
        [formGroup]="group"
        [hidden]="!config?.isFormBuilder && config?.options?.hidden"
      >
        <p class="mb-3" *ngIf="config?.instruction">{{ config.instruction }}</p>
        <label kendoTooltip>
          <input
            class="k-checkbox mr-2"
            [attr.name]="config.formControlName"
            [formControlName]="config.formControlName"
            (change)="checkValue($event)"
            type="checkbox"
          />
          <span [ngStyle]="config?.options?.style?.label">
            {{ config?.label }}
          </span>
          <span [hidden]="!config?.required" class="c-red">*</span>
          <i
            *ngIf="config?.options?.tooltip"
            class="far fa-info-circle"
            [title]="config?.options?.tooltip"
          ></i>
        </label>
        <div
          class="form-builder-mask"
          [class.hidden]="config?.options?.hidden"
          *ngIf="config?.isFormBuilder"
        ></div>
      </div>
      <div class="form-builder-options" *ngIf="config?.isFormBuilder">
        <i
          class="fas fa-times ml-3 cursor-pointer c-red"
          (click)="delete()"
        ></i>
        <!-- <i class="fas fa-bars ml-3 cursor-grab "></i> -->
      </div>
    </div>
  `,
})
export class DynamicCheckboxComponent
  extends DynamicCheckboxBaseComponent
  implements OnInit
{
  checkValue(args) {
    if (args && args.target) {
      this.updateConfigTargets(args);
    }
  }
}
