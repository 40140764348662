<kendo-scheduler
  [events]="baseData"
  [selectedDate]="selectedDate"
  scrollTime="08:00"
  style="height: 600px"
>
  <kendo-scheduler-multi-day-view
    [numberOfDays]="14"
  ></kendo-scheduler-multi-day-view>

  <kendo-scheduler-month-view></kendo-scheduler-month-view>

  <kendo-scheduler-week-view></kendo-scheduler-week-view>

  <kendo-scheduler-day-view></kendo-scheduler-day-view>

  <kendo-scheduler-timeline-view></kendo-scheduler-timeline-view>

  <kendo-scheduler-agenda-view></kendo-scheduler-agenda-view>
</kendo-scheduler>
