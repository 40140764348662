export class GetDataEvent {
  id: string;
  data: any;

  public static get GET_CERTIFICATIONS(): string {
    return 'GET_CERTIFICATIONS';
  }

  public static get GET_JOB_REQUIREMENT_LOGS(): string {
    return 'GET_JOB_REQUIREMENT_LOGS';
  }

  public static get GET_TRAINING(): string {
    return 'GET_TRAINING';
  }

  public static get GET_FIT(): string {
    return 'GET_FIT';
  }

  public static get GET_OPERATIONAL_DOCUMENTS(): string {
    return 'GET_OPERATIONAL_DOCUMENTS';
  }

  public static get GET_PER_DIEM_DOCUMENTS(): string {
    return 'GET_PER_DIEM_DOCUMENTS';
  }

  public static get GET_I9_DOCUMENTS(): string {
    return 'GET_I9_DOCUMENTS';
  }

  public static get GET_PROFILE_COMPLETE(): string {
    return 'GET_PROFILE_COMPLETE';
  }

  public static get GET_PROFILE(): string {
    return 'GET_PROFILE';
  }

  public static get GET_BGND(): string {
    return 'GET_BGND';
  }

  public static get GET_MPR(): string {
    return 'GET_MPR';
  }

  public static get GET_MPR_HISTORY(): string {
    return 'GET_MPR_HISTORY';
  }

  public static get GET_ZIPWHIPCHATS(): string {
    return 'GET_ZIPWHIPCHATS';
  }

  public static get GET_REC_WORKLOADS(): string {
    return 'GET_REC_WORKLOADS';
  }

  public static get GET_EMERGENCY_CONTACT(): string {
    return 'GET_EMERGENCY_CONTACT';
  }

  constructor(id: any, data: Object) {
    if (id) this.id = id;
    if (data) this.data = data;
  }
}
