import {
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Self,
} from '@angular/core';
import { Subscription, takeUntil } from 'rxjs';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { BaseComponent } from '@ups/xplat/core';

/*
USAGE:      Import DropDownsModule & DropDownsModuleEx - the module/directive and add add rememberFilter attribute to <kendo-dropdownlist>.

            The rememberFilter can be used as an input parameter, it does support two-way binding.
            We can also set expression or a value that should be displayed in search input when pop-up is opened.

            rfRaiseFilterChangeOnOpen: boolean = TRUE
                By default we trigger an filterChange event when we open the dialog.
                When used with [search-ex] (DropDownSearchExtensionDirective) we might need to alter this based on the DropDownSearchExtensionDirective settings...

HTML sample:
<kendo-dropdownlist remember-filter />
<kendo-dropdownlist [(remember-filter)]="txt" />
<kendo-dropdownlist [remember-filter]="'txt'" /> or <kendo-dropdownlist remember-filter="txt" />
 */
@Directive({
  selector: '[remember-filter]',
})
export class DropDownListRememberFilterDirective
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input('remember-filter')
  get filterText(): string {
    return this._filterText;
  }

  set filterText(value: string) {
    // we need to filter out if there's no change, to avoid unnecessary filterChange event triggering on the drop-down
    if (this._filterText != value) {
      // set new filter value
      this._filterText = value;

      // if there's a DDL and it's opened, apply new value...
      if (this.dropDownListComponent && this.dropDownListComponent.isOpen)
        this.applyFilter(this.filterText);
    }
  }

  @Output('remember-filterChange') _filterTextChange = new EventEmitter();

  @Input() rfRaiseFilterChangeOnOpen = true;

  protected _filterText: string;

  constructor(@Self() private dropDownListComponent: DropDownListComponent) {
    super();
  }

  ngOnInit() {
    this.dropDownListComponent.filterChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.filterChangeEx.bind(this));
    this.dropDownListComponent.open
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.openEx.bind(this));
  }

  protected filterChangeEx(e) {
    if (this.dropDownListComponent.isOpen) {
      // When filter value changes, store it...
      this._filterText = this.dropDownListComponent.filterText;

      // Since the stored value might be 2 way bound (in/out) we need to trigger the related change event as well, so the bound property get's also updated...
      this._filterTextChange.emit(this._filterText);
    }
  }

  protected openEx(e) {
    // NOTE:
    // Originally we triggered the apply filter only when we had a valid filter value.
    // To allow this control to play better with the search-ex attribute (DropDownSearchExtensionDirective), we do trigger a change just when asked so and regardless of filter value!
    if (this.rfRaiseFilterChangeOnOpen) this.applyFilter(this.filterText);
  }

  protected applyFilter(filter: string) {
    // change input value
    this.dropDownListComponent.filterText = filter;

    // trigger filtering callback
    this.dropDownListComponent.filterChange.emit(filter);
  }
}
