export class TimeSheetRecordDto {
  public Amount: number;
  public Class: string;
  public Craft: string;
  public CustomerPO: string;
  public DateWorked: string;
  public EarnCode: string;
  public EarnCodeType: string;
  public Employee: string;
  public EmployeeJobID: string;
  public EmployeeLaborID: string;
  public EquipmentUsageID: string;
  public ExportFlag: number;
  public ExportMessage: string;
  public Phase: string;
  public Shift: number;
  public TimeSheetID: string;
  public TimeSheetName: string;
}
