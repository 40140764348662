import { Directive, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[upsSingleTabFocus]',
})
export class SingleTabFocusDirective implements OnInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.renderer.setAttribute(this.el.nativeElement, 'tabindex', '-1');
    const input = this.el.nativeElement.querySelector('input');
    if (input) {
      this.renderer.setAttribute(input, 'tabindex', '0');
    }
  }
}
