import { Component, Input } from '@angular/core';
import { EventBusService } from '@ups/xplat/core';

import { HeaderBaseComponent } from '@ups/xplat/features';

@Component({
  selector: 'ups-header',
  templateUrl: 'header.component.html',
})
export class HeaderComponent extends HeaderBaseComponent {
  constructor(eventBus: EventBusService) {
    super(eventBus);
  }
}
