import { environmentProd } from '@ups/xplat/environments';
import { deepMerge } from '@ups/xplat/utils';

export const environment = deepMerge(environmentProd, {
  name: process.env['Localize:AppName@web-warehouse'],
  liveUpdate: {
    url: '',
  },
  auth0: {
    clientId: process.env['Auth0:ClientId@web-warehouse'],
  },
  security: {
    appId: process.env['Security:AppId@web-warehouse'],
  },
  smartlook: {
    webApiKey: process.env['Smartlook:WebApiKey@web-warehouse'],
  },
});
