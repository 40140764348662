/* eslint-disable @typescript-eslint/naming-convention */
import { MissingRequirementDto } from './MissingRequirementDto';

export class ManPowerReqSupervisorDashboardDto {
  ManPowerReqDetailID: string;
  CompanyID: string | null;
  VPCompanyID: number | null;
  PlantID: number | null;
  FacilityID: number | null;
  CustomerID: number | null;
  PlantName: string;
  JobID: string | null;
  VPJobID: string;
  JobName: string;
  bInternalEvent: boolean;
  bRequiresLodging: boolean;
  JobInterestCount: number | null;
  SupervisorName: string;
  SupervisorID: string | null;
  SupervisorPhoneNumber: string;
  SupervisorSSN: string;
  Foreman: string;
  EmployeeID: string | null;
  ProspectID: string | null;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  Nickname: string;
  FullName: string;
  SSN: string;
  Phone: string;
  HRRef: number | null;
  EMDesignation: string;
  Shift: number | null;
  ShiftString: string;
  StartDate: Date | null;
  EndDate: Date | null;
  ExpectedOnsiteDate: Date | null;
  DispatchStatusID: number | null;
  DispatchStatusName: string;
  JobClassID: number | null;
  JobClassName: string;
  RequestedTradeGroupID: string | null;
  RequestedTradeClassID: string | null;
  RequestedClassCodeID: string | null;
  RequestedLinkedClassID: string | null;
  RequestedClassName: string;
  DispatchRequestedClassName: string;
  Notes: string;
  PrevNotes: string;
  DeclineReasonID: number | null;
  DeclineReasonName: string;
  CellPhone: string;
  Email: string;
  UPSEmail: string;
  Position: string;
  EmpCompany: string;
  MissingRequirements: MissingRequirementDto[];
  HasEmployee: number;
  UseInTotal: number;
  IsOnsite: number;
  IsDispatched: number;
  UseInTotalOnsite: number;
  HasLodging: number;
  LodgeName: string;
  LodgeRoomTypeName: string;
  LodgeCheckInDate: Date | null;
  LodgeCheckOutDate: Date | null;
  LodgeRequestedCheckInDate: Date | null;
  LodgeRequestedCheckOutDate: Date | null;
  LodgeReservationNumber: string;
  LodgeNotes: string;
  DispatchStatusChangeDate: Date | null;
  TradeGroupName: string;
  RequestedClassLevel: number | null;
}
