import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent } from '@ups/xplat/core';
import { UserState } from '@ups/user';
import { IEmployeeInfo, JobOpeningsResult } from '@ups/xplat/api/dto';
import { EmployeeService } from '../services/employee.service';
import { EmployeeUpcomingJobsService } from '../services/employee-upcoming-jobs.service';
import { Subject } from 'rxjs';

/* eslint-disable */
@Directive()
export abstract class ProfileFutureAvailJobsBaseComponent extends BaseComponent {
  @Input() allEvents: any[] = [];

  @Output() futureJobsLoaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() jobWithdrawn: EventEmitter<any> = new EventEmitter<any>();
  @Output() showOverlapDialog: EventEmitter<any> = new EventEmitter<any>();

  public openings: JobOpeningsResult[] = [];
  public submitConfirmationSubject: Subject<boolean> = new Subject<boolean>();
  profile: IEmployeeInfo;
  isLoading = false;

  constructor(
    protected jobOpeningsService: EmployeeUpcomingJobsService,
    protected employeeService: EmployeeService,
    protected store: Store<any>
  ) {
    super();
    this.isLoading = true;
    this.store
      .pipe(UserState.selectCurrentTruthy(this.destroy$))
      .subscribe((x) => {
        this.profile = x;
        this.loadData(x.Data.HRRef);
      });
  }

  loadData(hrRef) {
    if (!hrRef) {
      this.openings = [];
      this.isLoading = false;
      return;
    }
    this.jobOpeningsService
      .getEmployeeJobOpenings(hrRef, true)
      .subscribe(({ Data }) => {
        if (Data?.length > 0) {
          const openings: JobOpeningsResult[] = Data.filter(
            (x) =>
              x && (!x.Interest || (x.Interest && !x.Interest.StatusIsFinal))
          );
          this.openings = openings;
          this.futureJobsLoaded.emit(openings);
        }
        this.isLoading = false;
      });
  }

  withDrawInterest(opening: JobOpeningsResult) {
    // console.log('opening:', opening.Interest)
    this.jobOpeningsService
      .withdrawInterest(
        opening.Interest.vManPowerOpeningId,
        this.profile.Data.HRRef
      )
      .subscribe(() => {
        opening.Interest = null;
        this.loadData(this.profile.Data.HRRef);
      });

    this.jobWithdrawn.emit(opening);
  }

  checkInterestOverlap(opening: JobOpeningsResult) {
    const overlappingEvent = this.allEvents.find((event) =>
      this.checkOverlap(opening, event)
    );

    if (overlappingEvent) {
      this.showOverlapDialog.emit({ opening, overlappingEvent });
    } else {
      this.submitInterest(opening);
    }
  }

  submitInterest(opening: JobOpeningsResult) {
    const submitInterestObj = {
      employeeId: this.profile.Data.EmployeeID,
      openingId: opening.OpeningId,
    };

    this.jobOpeningsService
      .submitInterestInJob(submitInterestObj)
      .subscribe((response) => {
        if (response.Data && !response.HasError) {
          this.loadData(this.profile.Data.HRRef);
        }
      });
  }

  private checkOverlap(opening: JobOpeningsResult, event: any) {
    return (
      opening.StartDate <= event.EndDate && opening.EndDate >= event.StartDate
    );
  }
}
