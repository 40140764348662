<kendo-dialog modal fade [hidden]="!isOpened" (close)="close()" [width]="650">
  <kendo-dialog-titlebar style="padding: 5px 1em !important">
    <span>Bulk Edit</span>
  </kendo-dialog-titlebar>

  <div style="padding: 16px">
    <div class="form-group row">
      <label class="col-form-label col-md-4">Start Date</label>
      <div class="col-md-8">
        <ups-kendo-datepicker-shared
          #startDate="ngModel"
          name="startDate"
          [required]="dataEdit.startDate.required(true, true)"
          [(ngModel)]="dataEdit.startDate.value"
          [placeholder]="
            dataEdit.startDate.hasMultipleValues ? 'Multiple Values' : ''
          "
          [validWhen]="[
            {
              expression:
                !startDate?.value ||
                !endDate?.value ||
                startDate?.value?.getTime() <= endDate?.value?.getTime(),
              errorProperty: 'invalidStartEndDate',
              errorMessage: 'Start date is expected to precede end date.'
            }
          ]"
        ></ups-kendo-datepicker-shared>
        <span
          *ngIf="startDate?.errors?.invalidStartEndDate"
          class="text-danger"
        >
          {{ startDate?.errors?.invalidStartEndDate.message }}
        </span>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-md-4">End Date</label>
      <div class="col-md-8">
        <ups-kendo-datepicker-shared
          #endDate="ngModel"
          name="endDate"
          [required]="dataEdit.endDate.required(true, true)"
          [(ngModel)]="dataEdit.endDate.value"
          [placeholder]="
            dataEdit.endDate.hasMultipleValues ? 'Multiple Values' : ''
          "
          [validWhen]="[
            {
              expression:
                !startDate?.value ||
                !endDate?.value ||
                startDate?.value?.getTime() <= endDate?.value?.getTime(),
              errorProperty: 'invalidStartEndDate',
              errorMessage: 'Start date is expected to precede end date.'
            }
          ]"
        ></ups-kendo-datepicker-shared>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-md-4">Transport Date</label>
      <div class="col-md-8">
        <ups-kendo-datepicker-shared
          #transportDate="ngModel"
          name="transportDate"
          [(ngModel)]="dataEdit.transportDate.value"
          [placeholder]="
            dataEdit.transportDate.hasMultipleValues ? 'Multiple Values' : ''
          "
        ></ups-kendo-datepicker-shared>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-md-4">Dispatch Status</label>
      <div class="col-md-8">
        <kendo-dropdownlist
          #dispatchStatus="ngModel"
          class="k-no-dropdown"
          name="dispatchStatus"
          [disabled]="anyDispatchStatusUneditable"
          [(ngModel)]="dataEdit.dispatchStatus.value"
          [valuePrimitive]="true"
          [textField]="'text'"
          [valueField]="'value'"
          [data]="dispatchItems"
        ></kendo-dropdownlist>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-md-4">Asset Control Manager</label>
      <div class="col-md-8">
        <kendo-combobox
          name="assetControlManager"
          [(ngModel)]="dataEdit.assetControlManager.value"
          [valuePrimitive]="true"
          [textField]="'name'"
          [valueField]="'id'"
          [filterable]="true"
          [placeholder]="
            dataEdit.assetControlManager.hasMultipleValues
              ? 'Multiple Values'
              : ''
          "
          [data]="assetControlManagers"
        ></kendo-combobox>
      </div>
    </div>
  </div>

  <kendo-dialog-actions>
    <button
      *ngIf="canModify"
      class="k-button k-primary"
      [disabled]="!isValid() || isSaving"
      (click)="onConfirm($event)"
    >
      <span *ngIf="!isSaving">Confirm</span>
      <span *ngIf="isSaving">
        <i class="fa fa-spinner fa-spin"></i>
        Saving...
      </span>
    </button>
    <button class="k-button" [disabled]="isSaving" (click)="close()">
      Cancel
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
