import {
  Input,
  Directive,
  EventEmitter,
  Output,
  OnInit,
  inject,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  BaseComponent,
  dynamicAdminButtonControlNameActions,
  IApiModelSearchable,
  IDynamicModel,
  IDynamicModelProperties,
  WindowService,
} from '@ups/xplat/core';
import { TranslateService } from '@ngx-translate/core';
import { filter, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { DynamicRenderService } from '../../services/dynamic-render.service';
import { DynamicEventBusTypes } from '../../utils';

/* eslint-disable */
/**
 * Base level abstraction for all form element components.
 */
@Directive()
export abstract class DynamicItemBaseComponent
  extends BaseComponent
  implements OnInit
{
  win = inject(WindowService);
  translate = inject(TranslateService);
  @Input()
  config: IDynamicModelProperties;
  @Input()
  group: UntypedFormGroup;
  @Input()
  formGroupName: string;
  @Input()
  dynamicService: DynamicRenderService;
  @Output()
  itemCreated: EventEmitter<unknown> = new EventEmitter();
  @Input()
  disableItem = false;

  valueObjectId: string | number | Date;
  isRedacted = false;

  /** we have a custom form validator dynamicValidatorNotLoading that makes the form invalid
   * if the isLoading_dynamic is true to prevent form from saving while a dropdown is loading */
  get isLoading(): boolean {
    const control = this.group.controls[this.config.formControlName];
    if (control) {
      return control['isLoading_dynamic'] ?? false;
    }

    return false;
  }

  /** we have a custom form validator dynamicValidatorNotLoading that makes the form invalid
   * if the isLoading_dynamic is true to prevent form from saving while a dropdown is loading */
  set isLoading(value: boolean) {
    const control = this.group.controls[this.config.formControlName];
    if (control) {
      const originalValue = control['isLoading_dynamic'];
      if (originalValue !== value) {
        control['isLoading_dynamic'] = value;
        control.updateValueAndValidity();
      }
    }
  }

  ngOnInit() {
    if (this.dynamicService && !this.isRedacted) {
      if (this.config.formControlName) {
        // some controls just contain groups of other controls using 'formArrayName' below
        this._registerControl(this.config, this.group);
      } else if (this.config.formArrayName) {
        // category/nested/grouped controls
        const formArrayGroup = this.dynamicService.formBuilder.group({});
        const formArray: UntypedFormArray =
          this.dynamicService.formBuilder.array([formArrayGroup]);
        this.group.addControl(this.config.formArrayName, formArray);
      }
      if (this.config.disabled) {
        this.disableItem = true;
      }
    }
  }

  private _registerControl(config: IDynamicModel, group: UntypedFormGroup) {
    const settings = this.dynamicService.registerControlForGroup(config, group);
    if (settings.valueObjectId) {
      this.valueObjectId = <string>settings.valueObjectId;
    }

    if (
      config.options?.showOnValues &&
      group.controls[config.formControlName]
    ) {
      group.controls[config.formControlName].valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe((value) => {
          this.config.options.valueMatchFound =
            this.config.options?.showOnValues.values.includes(value);
          this.dynamicService.itemTargetUpdate$.next({
            formControlName: this.config.options?.showOnValues.formControlName,
            type: 'target',
            options: {
              hidden: !this.config.options.valueMatchFound,
            },
          });
        });
    }

    this.dynamicService.itemTargetUpdate$
      .pipe(
        filter(
          (updates) =>
            updates && updates.formControlName === this.config.formControlName
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((updates: IDynamicModel) => {
        // Some other fields is calculating a property for this field in config.options.calculatedProperty() so setting loading indicator for this field
        if (updates.type === 'target-loading') {
          this.isLoading = updates.value ? true : false;
          return;
        } else {
          // remove dynamicValidatorNotLoading's
          this.isLoading = false;
        }

        if (updates.options) {
          this.config.options = {
            ...(this.config.options || {}),
            ...updates.options,
          };
        }
        let clearValue = false;
        if (updates.disabled || (updates.options && updates.options.hidden)) {
          // when activeFormResponse is present, don't auto-clear values
          // otherwise, always clear values when disabling or hiding controls
          clearValue = this.dynamicService.activeFormResponse ? false : true;
          group.get(this.config.formControlName).disable({
            emitEvent: true,
          });
        } else if (updates.value) {
          let targetOptions;
          if (updates.options) {
            if (updates.options.typeahead) {
              targetOptions = updates.options.typeahead;
            }
            if (
              updates.options.updateOnValueChange &&
              updates.options.updateOnValueChange.length
            ) {
              targetOptions = updates.options.updateOnValueChange[0];
            }
          }
          // when targeting different controls with various options, the value may be async fetched via options instead of directly setting here
          if (!targetOptions) {
            if (updates.type === 'date') {
              this.updateDateValue(updates.value);
            } else {
              this.updateValue(updates.value);
            }
          }
        } else {
          group.get(this.config.formControlName).enable({
            emitEvent: true,
          });
        }

        if (clearValue) {
          this.updateValue(null);
        }
      });

    if (config.options?.updateOnValueChange?.length) {
      const validationConstraints = config.options.updateOnValueChange.filter(
        (options) => {
          return !!options.validation;
        }
      );
      if (validationConstraints.length) {
        for (const constraint of validationConstraints) {
          if (group.controls[constraint.formControlName]) {
            group.controls[constraint.formControlName].valueChanges
              .pipe(
                filter((v) => !!v),
                takeUntil(this.destroy$)
              )
              .subscribe((value) => {
                let message = '';
                if (['maxDate', 'minDate'].includes(constraint.validation)) {
                  const controlValue =
                    group.controls[this.config.formControlName].value;
                  // only consider truthy values on both constraints
                  if (controlValue) {
                    const currentValue = new Date(controlValue);
                    const valueOfOtherControl = new Date(value);

                    if (currentValue) {
                      if (constraint.validation === 'maxDate') {
                        // always compare days only
                        const days = moment(currentValue).add(1, 'day');
                        if (valueOfOtherControl > days.toDate()) {
                          message = `You cannot set this value greater than "${this.config.label}"`;
                          this.updateValue(null, constraint.formControlName);
                        }
                      } else {
                        if (valueOfOtherControl < currentValue) {
                          message = `You cannot set this value less than "${this.config.label}"`;
                          this.updateValue(null, constraint.formControlName);
                        }
                      }
                    }
                  }
                }
                if (message) {
                  this.dynamicService.win.alert({
                    title: 'Oops',
                    message,
                    okButtonText: 'Ok',
                  });
                }
              });
          }
        }
      }
    }
  }

  isDisabled() {
    return this.config?.disabled || this.disableItem;
  }

  setRequired(validators?: Array<ValidatorFn>, targetFieldName?: string) {
    if (this.config) {
      this.setValidators(validators || [Validators.required], targetFieldName);
    }
  }

  setValidators(validators: Array<ValidatorFn>, targetFieldName?: string) {
    if (this.config && this.group) {
      const field = this.group.get(
        targetFieldName || this.config.formControlName
      );
      if (field) {
        field.setValidators(validators);
        field.updateValueAndValidity();
      }
    }
  }

  clearValidators(hideField?: boolean, targetFieldName?: string) {
    if (this.config && this.group) {
      const field = this.group.get(
        targetFieldName || this.config.formControlName
      );
      if (field) {
        // clear validators
        field.setValidators(null);
      }
      // also reset it's value:
      this.updateValue(null, targetFieldName);
      if (hideField) {
        // no parent value, hide this field
        if (!this.config.options) {
          this.config.options = {};
        }
        this.config.options.hidden = true;
      }
    }
  }

  updateDateValue(value: unknown, targetFieldName?: string) {
    // can be implemented by dynamic components for custom date field handling
    this.updateValue(value, targetFieldName);
  }

  updateValue(value: unknown, targetFieldName?: string) {
    if (this.config && this.group) {
      const valueMatch: { [key: string]: unknown } = {};
      const fieldName = targetFieldName || this.config.formControlName;
      if (fieldName) {
        valueMatch[fieldName] = value;
        this.group.patchValue(valueMatch);
      }
    }
  }

  // Updates this form value along with other dependent values in one patch call
  updateWithDependents(
    value: unknown,
    dependentKeyValues: { [key: string]: unknown }
  ) {
    if (this.config && this.group) {
      const valueMatch = {};
      valueMatch[this.config.formControlName] = value;
      for (const key in dependentKeyValues) {
        if (dependentKeyValues.hasOwnProperty(key)) {
          valueMatch[key] = dependentKeyValues[key];
        }
      }
      this.group.patchValue(valueMatch, {
        emitEvent: true,
      });
    }
  }

  /**
   * Useful to map async responses to specific ui bound properties
   * @param res response payload
   */
  apiModelMapper(res: Array<IApiModelSearchable>): Array<IApiModelSearchable> {
    if (res && this.config.options?.api?.model) {
      // model property mapping
      return res.map((item) => {
        const id =
          item[this.config.options?.api?.model?.id] || item.Id || item.id;
        const name =
          item[this.config.options?.api?.model?.name] || item.Name || item.name;
        const description =
          item[this.config.options?.api?.model?.description] ||
          item.Description ||
          item.description;
        const active =
          item[this.config.options?.api?.model?.active]?.toString() ||
          item.Active ||
          item.active;
        const result: IApiModelSearchable = {};
        result.id = id;
        result.Id = id;
        result.name = name;
        result.Name = name;
        result.description = description;
        result.Description = description;
        result.active = active;
        result.Active = active;
        return result;
      });
    } else if (res) {
      return res as Array<unknown>;
    } else {
      return [];
    }
  }

  filterData(res: Array<IApiModelSearchable>, searchString?: string) {
    return this.apiModelMapper(res).filter((item) => {
      if (searchString) {
        // TODO: remove mixed Pascal and camel casing on arch branch
        return (
          (item.id || item.Id)
            ?.toString()
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) > -1 ||
          (item.name || item.Name)
            ?.toString()
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) > -1 ||
          (item.description || item.Description)
            ?.toString()
            ?.toLowerCase()
            .indexOf(searchString.toLowerCase()) > -1
        );
      } else {
        return true;
      }
    });
  }

  delete() {
    this.dynamicService.eventBus.emit(
      DynamicEventBusTypes.dynamicRemoveControl,
      this.config
    );
  }

  cancel() {
    this.dynamicService.resetActiveForm();
  }

  submit(draft?: boolean) {
    // check for custom builder behavior first
    if (
      dynamicAdminButtonControlNameActions.includes(this.config.formControlName)
    ) {
      this.dynamicService.eventBus.emit(
        DynamicEventBusTypes[`dynamic${this.config.formControlName}`]
      );
    } else if (this.config.options?.submit) {
      this.dynamicService.submitForm(this.group, null, draft);
    }
  }
}
