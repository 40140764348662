import { BaseModel } from '../../base.model';
import { RoleDto } from '../../sparta-api';
import { ReportCategoryDto } from './ReportCategoryDto';

export class ReportDto extends BaseModel {
  /** GUID */
  public ReportID: string;
  public bActive: boolean;
  public Description: string;
  public Name: string;
  public ReportPath: string;
  public ReportServerUrl: string;
  public ShowOrderIndex: number | null;
  public IsUserFavorite: boolean;

  public CategoryName: string;
  public RoleName: string;
  public ReportRoles: RoleDto[];
  public ReportCategories: ReportCategoryDto[];
  public ReportSyncTypes: string[];

  /** Client side only property */
  public IsSaving: boolean = false;

  constructor(o: any = null) {
    super(o);
  }
}
