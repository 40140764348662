/* eslint-disable */
export interface IEmployeeData {
  HRRef?: number;
  LastName?: string;
  FirstName?: string;
  Email?: string;
  WorkPhone?: string;
  ProxyHRRef?: number;
  PRCo?: number;
  PREmp?: number;
  MiddleName?: string;
  Suffix?: string;
  HireDate?: Date;
  StdClass?: string;
  ActiveYN?: 'Y' | 'N';
  SeniorityDate?: Date;
  Position?: string;
  G40?: 'Y' | 'N';
  Core?: 'Y' | 'N';
  ATF?: 'Y' | 'N';
  BeneElig?: 'Y' | 'N';
  EmployeeType?: string;
  JobCategory?: number;
  LTDClass?: number;
  CoMastercard?: 'Y' | 'N';
  FuelCard?: 'Y' | 'N';
  CompanyVehicle?: 'Y' | 'N';
  FileCraft?: string;
  FileClass?: string;
  EmployeeID?: string; // guid
  VPCompanyID?: number;
  Designation?: any;
  Active?: boolean;
  ChangesApproved?: any;
  PendingAddress?: any;
  AbsorbDateUpdated?: any;
  NickName?: string;
  MailingAddress?: IAddress;
  PhysicalAddress?: IAddress;
  Phone?: string;
  CellPhone?: string;
  SSN?: string;
  AltContactPhone?: string;
  udUPSEmail?: string;
  UniqueAttchID?: string;
  PreferredSafetyCouncil?: any;
  DriversLicence?: IDriversLicence;
  Passport?: IPassport;
  NonResAlien?: boolean;
  TermDate?: any;
  TermReason?: any;
  PrehTermDate?: any;
  PrehTermReason?: any;
  NoRehireYN?: string;
  Status?: any;
  StatusExp?: any;
  G40Mgr?: any;
  SponsoredSupv?: any;
  HomeLocal?: any;
  BeneEligDate?: Date;
  StartVacHrs?: any;
  PhoneAllowance?: any;
  AutoAllowance?: any;
  BirthDate?: Date;
  LicNumber?: string;
  LicType?: string;
  LicState?: string;
  LicCountry?: string;
  LicClass?: string;
  LicExpDate?: Date;
  I9Status?: string;
  I9ReviewDate?: Date;
  Notes?: any;
  TWICID?: any;
  TWICIssueDate?: any;
  TWICExpireDate?: any;
  EmergencyPrimaryName?: any;
  EmergencyPrimaryPhone?: any;
  EmergencySecName?: any;
  EmergencySecPhone?: any;
  Id?: string;
  FullName?: string;
}

export interface IEmployeeInfo {
  Auth0UserId: string;
  Data: IEmployeeData;
  HasError: boolean;
  Message: string;
  UserId: string;
}

export interface IAddress {
  CustomerShippingRelationID?: any;
  CustomerShippingDescription?: any;
  EffectiveDate?: Date;
  Line1?: string;
  Line2?: any;
  City?: string;
  State?: string;
  Zip?: string;
  Country?: string;
  ShippingTo?: any;
  Attention?: any;
}

export interface IDriversLicence {
  Number: string;
  Class: string;
  Type: string;
  IssuingCountry: string;
  IssuingState: string;
  Expiration: Date;
}

export interface IPassport {
  Number?: any;
  Expiration?: any;
  IssuingCountry?: any;
  IssuingState?: any;
}
/* eslint-enable */
