/* eslint-disable */
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IFileData } from '@ups/xplat/api/dto';
import { MyHttpClientFactory, environment } from '@ups/xplat/core';
import { parseData } from '@ups/xplat/utils';

const GET_FILE_DATA_BY_UNIQUE_ATTACHID: string = '/api/hqat/filedata/{:ID}';
const GET_FILE_BY_ATTACHIDWITHNAMEOVERRIDE: string =
  '/api/hqaf/file/{:ID}/{:FileName}';
const GET_FILE_BY_ATTACHID: string = '/api/hqaf/file/{:ID}';
const GET_FILE_DATA_DL_BY_HRREF: string = '/api/hqat/filedata/dl/{:ID}';
const GET_FILE_DATA_PASSPORT_BY_HRREF: string =
  '/api/hqat/filedata/passport/{:ID}';
const GET_FILE_DATA_BC_BY_HRREF: string =
  '/api/hqat/filedata/birthcertificate/{:ID}';
const GET_FILE_DATA_SSN_BY_HRREF: string = '/api/hqat/filedata/ssn/{:ID}';
const GET_FILE_DATA_ID_CARD_BY_HRREF: string =
  '/api/hqat/filedata/idcard/{:ID}';

@Injectable({
  providedIn: 'root',
})
export class AttachmentVpService {
  http: HttpClient;

  constructor(private httpFactory: MyHttpClientFactory) {
    // this.http = httpFactory.createHttpClient(environment.urls.viewpointAPI);
    // TMP Nathan W.: just to be able to demo real files for shareholder meeting
    this.http = httpFactory.createHttpClient(environment.urls.viewpointAPI);
  }

  public getFileDataById(id: string): any {
    const url = GET_FILE_DATA_BY_UNIQUE_ATTACHID.split('{:ID}').join(id);
    return this.http.get(url).toPromise().then(parseData);
  }

  public getFileById(id: string, fileName: string): any {
    let url = GET_FILE_BY_ATTACHID.split('{:ID}').join(id);
    url = url.split('{:FileName}').join(fileName);
    return this.http.get(url).toPromise().then(parseData);
  }

  public getFileById2(id: number | string): Promise<IFileData> {
    const url = GET_FILE_BY_ATTACHID.split('{:ID}').join(id.toString());
    return this.http
      .get<IFileData>(url)
      .toPromise()
      .then((d) => parseData(d));
  }

  public getFileByAttachmentId(id: string) {
    const url = GET_FILE_BY_ATTACHID.split('{:ID}').join(id);
    return this.http.get<IFileData>(url);
  }

  public getFileDataDLById(id: string): any {
    const url = GET_FILE_DATA_DL_BY_HRREF.split('{:ID}').join(id);

    return this.http.get(url).toPromise().then(parseData);
  }

  public getFileDataBCById(id: string): any {
    const url = GET_FILE_DATA_BC_BY_HRREF.split('{:ID}').join(id);

    return this.http.get(url).toPromise().then(parseData);
  }

  public getFileDataSSNById(id: string): any {
    const url = GET_FILE_DATA_SSN_BY_HRREF.split('{:ID}').join(id);

    return this.http.get(url).toPromise().then(parseData);
  }

  public getFileDataIDCardById(id: string): any {
    const url = GET_FILE_DATA_ID_CARD_BY_HRREF.split('{:ID}').join(id);

    return this.http.get(url).toPromise().then(parseData);
  }

  public getFileDataPassportById(id: string): any {
    const url = GET_FILE_DATA_PASSPORT_BY_HRREF.split('{:ID}').join(id);

    return this.http.get(url).toPromise().then(parseData);
  }
}
