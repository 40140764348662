/*
http://www.anasfirdousi.com/share-controlvalueaccessor-provider-creation-with-abstract-controlvalueaccessor-across-custom-form-enabled-angular-components.html
*/
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { forwardRef } from '@angular/core';

export function ValueAccessorProvider(type: any) {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => type),
    multi: true,
  } as any;
}
