import { Component } from '@angular/core';
import { DynamicItemBaseComponent } from '@ups/xplat/features';

@Component({
  selector: 'ups-dynamic-button',
  template: `
    <div class="dynamic-field-container" [class.selected]="config?.selected">
      <div class="dynamic-field form-button" [formGroup]="group">
        <button
          *ngIf="config?.options?.showCancelButton"
          class="k-button"
          (click)="cancel()"
        >
          Clear
        </button>
        <button
          *ngIf="config?.options?.showDeleteDraftButton"
          class="k-button"
          (click)="showDeleteConfirm = true"
        >
          Delete Draft
        </button>
        <button
          *ngIf="config?.options?.showDraftButton"
          class="k-button"
          (click)="submit(true)"
        >
          Save Draft
        </button>
        <button
          [attr.name]="config.formControlName"
          class="k-button"
          [class.k-primary]="config?.options?.primary"
          (click)="submit()"
        >
          {{ config?.value }}
        </button>
      </div>
      <div class="form-builder-options" *ngIf="config?.isFormBuilder">
        <i
          class="fas fa-times ml-3 cursor-pointer c-red"
          (click)="delete()"
        ></i>
        <!-- <i class="fas fa-bars ml-3 cursor-grab "></i> -->
      </div>
    </div>

    <ups-dynamic-delete-form-modal
      *ngIf="showDeleteConfirm"
      (closeModal)="showDeleteConfirm = false"
      (deleteDone)="dynamicService.formReviewDone$.next()"
    ></ups-dynamic-delete-form-modal>
  `,
  styles: [
    `
      .form-button {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
      }
      .form-button button {
        height: 100%;
        min-width: 25%;
      }
    `,
  ],
})
export class DynamicButtonComponent extends DynamicItemBaseComponent {
  showDeleteConfirm = false;
}
