import { Inject, Injectable } from '@angular/core';
import {
  IODataResult,
  JobRequirementLogDTO,
  ManPowerReqDto,
  ManPowerReqGridDto,
} from '@ups/xplat/api/dto';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { parseData } from '@ups/xplat/utils';
import { MyHttpClientFactory, environment } from '@ups/xplat/core';
import { Observable } from 'rxjs';

const GET_JOB_REQUIREMENTS: string = '/api/manpowerReq/jobRequirements-odata';
const GET_MAN_POWER_REQ: string = '/api/manPowerReq/{:manPowerReqID}';
const SAVE_JOB_REQUIREMENTS: string = '/api/manPowerReq';
const GET_DEFAULT_REQUIREMENTS: string = '/api/manPowerReq/defaultRequirements';
const GET_JOB_REQUIREMENT_LOG_HISTORY = 'api/job/jobRequirementLog/{:jobId}';

@Injectable({ providedIn: 'root' })
export class JobReqService {
  http: HttpClient;

  constructor(httpFactory: MyHttpClientFactory) {
    this.http = httpFactory.createHttpClient(environment.urls.spartaAPI);
  }

  public getJobRequirements(
    oDataString: string
  ): Observable<IODataResult<ManPowerReqGridDto>> {
    let url = GET_JOB_REQUIREMENTS;

    url += '?$format=json&$count=true';
    url += oDataString ? '&' + oDataString : '';

    return this.http.get(url) as Observable<IODataResult<ManPowerReqGridDto>>;
  }

  public getJobRequirementsLogHistory(
    jobId: string
  ): Promise<JobRequirementLogDTO[]> {
    const url = GET_JOB_REQUIREMENT_LOG_HISTORY.split('{:jobId}').join(jobId);
    return this.http.get(url).toPromise().then(parseData);
  }

  public getManPowerReq(manPowerReqID: string): Promise<ManPowerReqDto> {
    let url = GET_MAN_POWER_REQ.split('{:manPowerReqID}').join(manPowerReqID);
    return this.http.get(url).toPromise().then(parseData);
  }

  public saveJobRequirements(model: ManPowerReqDto) {
    let url = SAVE_JOB_REQUIREMENTS;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.http.post(url, model, options).toPromise().then(parseData);
  }

  public getPlantRequirements(plantID: number): any {
    return this.http
      .get(`/api/manPowerReq/plantRequirement/${plantID}`)
      .toPromise()
      .then(parseData);
  }

  public getDefaultRequirements(): any {
    let url = GET_DEFAULT_REQUIREMENTS;
    return this.http.get(url).toPromise().then(parseData);
  }
}
