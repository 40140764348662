/**
 * Usage:
 * const nameOf = nameOfFactory‹Person›();     // in *.ts component's class
 * ‹input [(ngModel)]="nameOf('LastName')"›    // in html component's template
 *
 * This causes early compile error if "LastName" is renamed or removed from Person class.
 * Use the notation above instead of
 * ‹input [(ngModel)]="LastName"›             // This won't cause compile error but runtime error if LastName property renamed/removed
 */
export const nameOfFactory =
  <T>() =>
  (name: keyof T) =>
    name as string;
