<kendo-dialog
  title="Bulk Message"
  (close)="closeModal()"
  [minWidth]="250"
  [width]="600"
>
  <div class="row">
    <div class="col-12">
      <kendo-label [for]="messageArea" text="Message"></kendo-label>
      <kendo-textarea #messageArea [(ngModel)]="message"></kendo-textarea>
    </div>
    <div class="col-12">
      <input
        type="checkbox"
        id="excludeCautionEmployees"
        class="k-checkbox"
        [(ngModel)]="excludeCautionEmployees"
      />
      <label class="k-checkbox-label" for="excludeCautionEmployees">
        Exclude Not for Rehire or Caution employees
      </label>
    </div>
  </div>

  <kendo-dialog-actions [layout]="'normal'">
    <button kendoButton (click)="closeModal()">Cancel</button>
    <button
      kendoButton
      primary="true"
      [disabled]="!message"
      (click)="sendMessage()"
    >
      Send Message
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
