import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TooltipModule as KendoTooltipModule } from '@progress/kendo-angular-tooltip';

import { SYNC_COMPONENT } from './components';
import { UIModule } from '../ui';
import { SyncSpartaPeriodicRefreshService } from './sync-sparta-periodic-refresh-service';

export const SYNC_MODULES = [
  CommonModule,
  FormsModule,
  KendoTooltipModule,
  UIModule,
];

@NgModule({
  declarations: [...SYNC_COMPONENT],
  imports: [CommonModule, ...SYNC_MODULES],
  exports: [...SYNC_MODULES, SYNC_COMPONENT],
  providers: [SyncSpartaPeriodicRefreshService],
})
export class SyncFeatureModule {}
