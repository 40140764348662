import { Inject, Injectable, Injector, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  environment,
  LogService,
  ProgressService,
  WindowService,
  IDynamicModel,
  IDynamicRegisterControl,
  IDynamicModelGroup,
  EventBusService,
  IDynamicModelProperties,
  IDynamicResponseBody,
  IDynamicControlResponse,
  MyHttpClientFactory,
  ResponseCasingEnum,
  IDynamicContainerMetadata,
  IDynamicBuilderState,
  dynamicModelFormArrayTypes,
  IDynamicControl,
  IFormReview,
  IDynamicPageOptions,
  IDynamicActiveActionOptions,
  ICannedResponse,
  DynamicModelType,
  IDynamicTagDisplay,
  IDynamicActiveGroupSettings,
  IDynamicDatePicker,
  LocalStorageService,
  IDynamicOfflineCache,
  OfflineStorageService,
  IDynamicUpdateOnValueChange,
  NetworkService,
  dynamicFormGroupExcludedControlNames,
  IDynamicTag,
} from '@ups/xplat/core';
import { TranslateService } from '@ngx-translate/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  guid,
  tagCleanser,
  sortByProperty,
  StorageKeys,
} from '@ups/xplat/utils';
import { Observable, of, Subject } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import {
  ApplicationModel,
  FileDto,
  FormRole,
  IFileData,
} from '@ups/xplat/api/dto';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AttachmentVpService, CompanyResolver } from '@ups/xplat/api/services';
import { SpartaFileService } from '@ups/files';
import { IMenuItem, IMenuService, MENU_SERVICE_TOKEN } from '@ups/common';
import {
  dynamicConvertTagToName,
  DynamicEventBusTypes,
  dynamicFindControlResponseByCondition,
  dynamicGetCategoryTags,
  findNestedFormGroupsWith,
  dynamicValidatorNotLoading,
  dynamicValidatorDate,
  dynamicGetCategoryTagName,
  dynamicGetAppTags,
  dynamicGetAppTagName,
} from '../utils';
import {
  dynamicDefaultFormCategories,
  dynamicSupportedAppTags,
} from '../models/dto/display-dto-models';
import moment from 'moment';
import { SecurityConstants } from '@ups/security-constants';

// various debug logs which can be isolated
const logTag = 'DynamicRenderService';
const logTagPrefetchDropdowns = 'prefectched dropdown data:';

@Injectable({
  providedIn: 'root',
})
export class DynamicRenderService {
  metadata: IDynamicContainerMetadata;
  // active dynamic group
  activeModelGroup: IDynamicModelGroup;
  // active group settings (these can determine any number of custom ux for dynamic groups)
  activeGroupSettings: IDynamicActiveGroupSettings;
  activeForm: UntypedFormGroup;
  activeFormProperties: UntypedFormGroup;
  activeFormResponse: IDynamicResponseBody;
  isFormDirty = false;
  itemTargetUpdate$: Subject<IDynamicModel> = new Subject();
  // track file field property names to attachment data
  activeAttachments: { [key: string]: Array<FileDto> };
  activeAction: (options?: IDynamicActiveActionOptions) => Promise<unknown>;
  activeActionDone$: Subject<unknown> = new Subject();
  toggleDatePicker$: Subject<IDynamicDatePicker> = new Subject();
  formReviewDone$: Subject<void> = new Subject();
  formReady$: Subject<void> = new Subject();
  dynamicContainerList: Array<IDynamicContainerMetadata>;
  dynamicContainerListAll: Array<IDynamicContainerMetadata>;
  dynamicContainerListAllVersions: Array<IDynamicContainerMetadata>;
  dynamicAppTags: Array<IDynamicTagDisplay>;
  dynamicCategories: Array<IDynamicTagDisplay>;
  showingAllVersions = false;
  // helps form builder UX
  formBuilderState: IDynamicBuilderState;
  _selectNestedControl$: Subject<IDynamicControl>;
  companyResolver: CompanyResolver;
  selectedControl: IDynamicModel;
  // signature components override the following to determine if user has physically signed the components
  isSignatureEmpty: () => boolean;
  signatureUploadFile: FileDto;
  signatureBase64: () => Promise<string>;
  signatureReset: () => boolean;
  private _hasCachedOfflineData: boolean;
  private http: HttpClient;

  constructor(
    private injector: Injector,
    private translate: TranslateService,
    private progress: ProgressService,
    private clientFactory: MyHttpClientFactory,
    @Inject(MENU_SERVICE_TOKEN) private menuService: IMenuService,
    public log: LogService,
    public win: WindowService,
    public ngZone: NgZone,
    public eventBus: EventBusService,
    public storage: LocalStorageService,
    public network: NetworkService,
    public offlineStorage: OfflineStorageService,
    public formBuilder: UntypedFormBuilder,
    public attachmentService: AttachmentVpService,
    public spartaFileService: SpartaFileService,
    // public companyResolver: CompanyResolver,
    public appModel: ApplicationModel
  ) {
    this.http = this.clientFactory.createHttpClient(
      environment.urls.dynamicAPI,
      true,
      ResponseCasingEnum.PascalCase
    );
    this.companyResolver = this.injector.get(CompanyResolver);
  }

  get selectNestedControl$() {
    if (!this._selectNestedControl$) {
      this._selectNestedControl$ = new Subject();
    }
    return this._selectNestedControl$;
  }

  // convenient to avoid having to inject translateService into various dynamic components
  translateKey(key: string) {
    return this.translate.instant(key);
  }

  /**
   * Update an apps menu with listing of dynamic containers combined with the apps menu
   * @returns The first dynamic container in the list (if any)
   */
  updateAppMenu() {
    this.ngZone.run(() => {
      let menuConfigItems: IMenuItem[] = [];
      if (this.dynamicContainerList && this.dynamicContainerList.length) {
        // create categories first
        for (let i = 0; i < this.dynamicCategories.length; i++) {
          const category = this.dynamicCategories[i];
          const iconClass = category.icon
            ? category.icon.substring(0, category.icon.indexOf('-'))
            : 'fa';
          menuConfigItems.push({
            path: `forms${i + 1}`,
            title: category.Name,
            tagName: category.tagName,
            isCategory: true,
            icon: category.icon || 'fa-file-contract',
            iconClass,
            exact: true,
            url: `/forms${i + 1}/${tagCleanser(category.Name)}`,
            visible:
              Array.isArray(category.visible) && category.visible?.length
                ? category.visible
                : [SecurityConstants.safety_2_0_forms_reportingforms],
            enabled: true,
            children: [],
            disableRouting: true,
          });
        }

        menuConfigItems = menuConfigItems.sort(sortByProperty('title', true));

        const addToGenericCategory = (menuItem, namePath) => {
          let genericCategory = menuConfigItems.find(
            (m) => m.title === 'Forms'
          );
          if (!genericCategory) {
            genericCategory = {
              path: `form`,
              title: 'Forms',
              isCategory: true,
              icon: 'fa-file-contract',
              iconClass: 'fa',
              exact: true,
              url: `/form/forms`,
              visible: true,
              enabled: true,
              children: [],
              disableRouting: true,
            };
            menuConfigItems.push(genericCategory);
          }
          menuItem.path = `forms/${namePath}`;
          menuItem.url = `${genericCategory.url}/${namePath}`;
          genericCategory.children.push(menuItem);
        };
        for (const f of this.dynamicContainerList) {
          const namePath = tagCleanser(f.dynamicContainerName);
          // this assumes the icon name is as follows {style}-{icon}
          const iconClass = f.displayIcon
            ? f.displayIcon.substr(0, f.displayIcon.indexOf('-'))
            : 'fa';
          const menuItem: IMenuItem = {
            title: f.dynamicContainerName,
            tooltip:
              f.dynamicContainerName === 'Lesson Learned'
                ? 'Submit Lessons Learned'
                : '',
            icon: f.displayIcon || 'fa-file-alt', // using a default icon in case one isn't supplied
            iconClass,
            exact: true,
            visible: true,
            enabled: true,
            children: [],
          };
          // find if a category match exist to put it in
          const containerCategoryTag = f.tags
            ? f.tags.find((t) => t.tagName.indexOf('category:') > -1)
            : null;
          if (containerCategoryTag) {
            const menuCategoryMatch = menuConfigItems.find(
              (m) => m.tagName === containerCategoryTag.tagName
            );
            if (menuCategoryMatch) {
              menuItem.tagName = menuCategoryMatch.tagName;
              menuItem.path = `${tagCleanser(
                menuCategoryMatch.title
              )}/${namePath}`;
              menuItem.url = `${menuCategoryMatch.url}/${namePath}`;
              menuCategoryMatch.children.push(menuItem);
            } else {
              addToGenericCategory(menuItem, namePath);
            }
          } else {
            addToGenericCategory(menuItem, namePath);
          }
          f.url = menuItem.url;
        }
      }
      // make sure any categories with no children are removed
      const emptyCategoryIndices = [];
      for (let i = 0; i < menuConfigItems.length; i++) {
        if (menuConfigItems[i].children.length === 0) {
          emptyCategoryIndices.push(i);
        } else {
          // alphabetize children
          menuConfigItems[i].children = menuConfigItems[i].children.sort(
            sortByProperty('title', true)
          );
        }
      }
      for (const emptyIndex of emptyCategoryIndices) {
        menuConfigItems.splice(emptyIndex, 1);
      }
      // divide those which are dynamic from those hard coded for example purposes
      if (this.win.isBrowser) {
        menuConfigItems.push({
          path: `admin`,
          title: '----',
          icon: '',
          exact: true,
          url: `/admin`,
          visible: true,
          enabled: true,
          children: [],
          disableRouting: true,
        });
      }
      menuConfigItems = menuConfigItems.concat(
        this.menuService.staticMenuConfig || []
      );
      this.menuService.configureMenu(menuConfigItems, 0, this.win.isMobile);
    });
  }

  loadAllVersionContainerList(
    forceRefresh?: boolean
  ): Promise<Array<IDynamicContainerMetadata>> {
    return new Promise((resolve) => {
      const loadList = () => {
        const params = [];

        const tagFilter = ` tag/tagName eq '${this.getAppTagName()}'`;
        params.push(`tags/any(tag:${tagFilter})`);

        this.http
          .get(`/api/DynamicContainer/all/odata`, {
            params: {
              $filter: params.join(' and '),
              $format: 'json',
            },
          })
          .pipe(
            catchError((err) => {
              this.log.debug(logTag, 'ERROR:', err);
              return of(null);
            })
          )
          .subscribe((list: { value: Array<IDynamicContainerMetadata> }) => {
            if (list && list.value) {
              this.dynamicContainerListAllVersions = list.value;
              resolve(list.value);
            }
          });
      };

      if (
        forceRefresh ||
        !this.dynamicContainerListAllVersions ||
        !this.dynamicContainerListAllVersions.length
      ) {
        loadList();
      } else {
        resolve(this.dynamicContainerListAllVersions);
      }
    });
  }

  loadFormRoles(): Promise<Array<FormRole>> {
    return new Promise((resolve) => {
      this.http
        .get(`/api/form/roles`)
        .pipe(catchError(() => of(null)))
        .subscribe((roles: Array<FormRole>) => {
          if (roles) {
            resolve(roles);
          }
        });
    });
  }

  loadContainerList(
    taggedWithAppOnly = true,
    showAllVersions = false,
    forceRefresh?: boolean
  ): Promise<Array<IDynamicContainerMetadata>> {
    return new Promise((resolve) => {
      this.showingAllVersions = showAllVersions;

      const loadList = () => {
        const usingAll = taggedWithAppOnly && !this.showingAllVersions;
        let httpUrl = usingAll
          ? `/api/DynamicContainer/all`
          : `/api/DynamicContainer/odata`;
        const httpParams: {
          tagNames?: string;
          $filter?: string;
          $format?: 'json';
        } = {};
        const appTag = this.getAppTagName();
        if (usingAll) {
          // Note: issue with query param encoding
          // solution is to use params on the url instead
          // https://github.com/angular/angular/issues/18261#issuecomment-338352188
          // httpParams.tagNames = appTag;
          httpUrl = `${httpUrl}?tagNames=${appTag}`;
        } else {
          const params = [];
          if (taggedWithAppOnly) {
            const tagFilter = ` tag/tagName eq '${appTag}'`;
            params.push(`tags/any(tag:${tagFilter})`);
            if (!this.showingAllVersions) {
              params.push(`isActive eq true`);
            }
            httpParams.$filter = params.join(' and ');
          } else {
            // include all apps
            for (const dynamicAppTag of dynamicSupportedAppTags) {
              const tagFilter = ` tag/tagName eq '${dynamicAppTag.tag}'`;
              params.push(`tags/any(tag:${tagFilter})`);
            }
            httpParams.$filter = params.join(' or ');
          }
          httpParams.$format = 'json';
        }

        this.http
          .get(httpUrl, {
            params: httpParams,
          })
          .pipe(
            catchError((err) => {
              this.log.debug(logTag, 'ERROR:', err);
              return of(null);
            })
          )
          .subscribe(
            (
              list:
                | Array<IDynamicContainerMetadata>
                | { value: Array<IDynamicContainerMetadata> }
            ) => {
              if (list) {
                this.log.debug(logTag, 'all dynamic container list:', list);
                this.dynamicContainerListAll = usingAll
                  ? <Array<IDynamicContainerMetadata>>list
                  : (<{ value: Array<IDynamicContainerMetadata> }>list).value;
                this.dynamicContainerList = [];
                this.dynamicAppTags = [];
                this.dynamicCategories = [];
                // handle published and category lists
                const olderVersions: Array<IDynamicContainerMetadata> = [];
                for (const c of this.dynamicContainerListAll) {
                  c.dynamicContainerIdAndVersion = `${c.dynamicContainerId}:${c.versionNumber}`;
                  if (c.isPublished && c.isActive) {
                    this.dynamicContainerList.push(c);
                  }
                  if (this.showingAllVersions && c.versionNumber > 1) {
                    this.log.debug(
                      'adding older versions for:',
                      c.dynamicContainerName,
                      c.versionNumber
                    );
                    // add every version from the current down to 1
                    for (let i = c.versionNumber - 1; i > 0; i--) {
                      olderVersions.push({
                        ...c,
                        dynamicContainerIdAndVersion: `${c.dynamicContainerId}:${i}`,
                        isPublished: false,
                        versionNumber: i,
                      });
                    }
                  }
                  if (c.tags && c.tags.length) {
                    this.setupAppTags(c.tags);
                    this.setupCategoryTags(c.tags);
                  }
                }
                if (this.showingAllVersions && olderVersions.length) {
                  this.log.debug(logTag, 'older versions:', olderVersions);
                  this.dynamicContainerListAll =
                    this.dynamicContainerListAll.concat(olderVersions);
                  this.dynamicContainerListAll =
                    this.dynamicContainerListAll.sort(
                      sortByProperty('dynamicContainerName', true)
                    );
                } else {
                  this.dynamicContainerListAll = this.dynamicContainerListAll
                    .sort(sortByProperty('dynamicContainerName', true))
                    .sort(sortByProperty('isPublished'));
                }
              } else {
                this.win.alert(
                  `The dynamic data api is not warmed up yet. ${
                    this.win.isBrowser
                      ? 'You may try refreshing the browser shortly.'
                      : 'Please quit the app and try restarting it in a moment.'
                  }`
                );
              }
              this.updateAppMenu();
              if (usingAll && (!this._hasCachedOfflineData || forceRefresh)) {
                if (this.win.navigator.onLine) {
                  // cache all dynamic containers for offline use later
                  this.cacheDynamicContainersForOffline(
                    this.dynamicContainerListAll
                  ).then(() => {
                    this.prefetchDropdownsForOffline(forceRefresh);
                  });
                } else {
                  this.prefetchDropdownsForOffline();
                }
              }
              resolve(this.dynamicContainerList);
            }
          );
      };
      if (forceRefresh || !this.dynamicContainerList) {
        loadList();
      } else {
        resolve(this.dynamicContainerList);
      }
    });
  }

  setupAppTags(tags: Array<IDynamicTag>) {
    // app tags can be open ended or prefixed with 'app:'
    // the app: prefixing began in October 2023
    // See notes inside this util method:
    const appTags = dynamicGetAppTags(tags);
    for (const t of appTags) {
      if (!this.dynamicAppTags.find((cat) => cat.Id === t.tagId)) {
        const appName = dynamicGetAppTagName(t);
        // find the name defined locally for the matching app tag if available
        const appTag = dynamicSupportedAppTags.find((df) => df.tag === appName);
        if (appTag) {
          this.dynamicAppTags.push({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Id: t.tagId,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Name: appTag.title,
            tagName: t.tagName,
            visible: [],
          });
        }
      }
    }
  }

  setupCategoryTags(tags: Array<IDynamicTag>) {
    // category tags should always be prefixed with 'category:'
    const categoryTags = dynamicGetCategoryTags(tags);
    for (const t of categoryTags) {
      if (!this.dynamicCategories.find((cat) => cat.Id === t.tagId)) {
        const categoryName = dynamicConvertTagToName(t);
        // find the icon defined locally for the matching category if available
        const defaultCategory = dynamicDefaultFormCategories.find(
          (df) => df.title === categoryName
        );
        this.dynamicCategories.push({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Id: t.tagId,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Name: categoryName,
          tagName: t.tagName,
          icon: defaultCategory ? defaultCategory.icon : 'fa-file-contract',
          visible: defaultCategory ? defaultCategory.visible : [],
        });
      }
    }
  }

  loadContainersIncludingControls(
    tagNames: string[] = null
  ): Promise<IDynamicContainerMetadata[]> {
    const queryParameters: string[] = [];

    if (tagNames) {
      tagNames.forEach((i) => {
        queryParameters.push(`tagNames=${i}`);
      });
    }

    const url =
      '/api/DynamicContainer/all' +
      (queryParameters.length ? '?' + queryParameters.join('&') : '');

    return new Promise((resolve) => {
      this.http
        .get(url)
        .pipe(catchError(() => of(null)))
        .subscribe((c: Array<IDynamicContainerMetadata>) => {
          if (c) {
            resolve(c);
          }
        });
    });
  }

  loadContainerDetails(
    metadata: IDynamicContainerMetadata
  ): Promise<IDynamicContainerMetadata> {
    const id =
      metadata.versionNumber > 1 && metadata.originalVersionContainerId
        ? metadata.originalVersionContainerId
        : metadata.dynamicContainerId;
    return new Promise((resolve) => {
      this.http
        .get(`/api/DynamicContainer/${id}/container/${metadata.versionNumber}`)
        .pipe(catchError(() => of(null)))
        .subscribe((container) => {
          if (container) {
            this.updateMetaData(container);
            resolve(container);
          }
        });
    });
  }

  loadReusableControls(): Promise<Array<IDynamicControl>> {
    return new Promise((resolve) => {
      this.http
        .get(`/api/DynamicControl/reusable`)
        .pipe(catchError(() => of(null)))
        .subscribe((controls: Array<IDynamicControl>) => {
          if (controls) {
            resolve(controls);
          }
        });
    });
  }

  loadReportCategories(type?: DynamicModelType): Promise<Array<string>> {
    return new Promise((resolve) => {
      this.http
        .get(`/api/DynamicControl/${type ? type + '/' : ''}reportCategoryNames`)
        .pipe(catchError(() => of(null)))
        .subscribe((controls: Array<string>) => {
          if (controls) {
            resolve(controls);
          }
        });
    });
  }

  loadContainerResponse(
    dynamicResponseId: string
  ): Promise<IDynamicResponseBody> {
    return new Promise((resolve) => {
      this.http
        .get(`/api/DynamicResponse/${dynamicResponseId}`)
        .pipe(catchError(() => of(null)))
        .subscribe((response) => {
          if (response) {
            resolve(response);
          }
        });
    });
  }

  applyResponseContent(
    control: IDynamicControl,
    dynamicResponse: IDynamicResponseBody,
    options?: IDynamicPageOptions
  ) {
    const response = dynamicFindControlResponseByCondition(
      dynamicResponse.dynamicControlResponses,
      options?.isReclassification
        ? (cr) => cr.dynamicControlName === control.dynamicControlName
        : (cr) => cr.dynamicControlId === control.dynamicControlId
    );
    control.value = this.prepareResponseValue(response);
    control.options.initWithQuery = response?.responseContent;
    control.disabled = !options?.isEditReview && !options?.isReclassification;
    control.readOnly = true;
    for (const childControl of control.dynamicControls) {
      this.applyResponseContent(childControl, dynamicResponse, options);
    }
  }

  prepareResponseValue(response: IDynamicControlResponse) {
    switch (response?.dynamicControlType) {
      case 'checkbox':
        // database saves as strings, convert to boolean values
        return response?.responseContent === 'true';
    }
    return response?.responseContent;
  }

  prepareContainerForDisplay(
    container: Partial<IDynamicContainerMetadata>,
    isFormBuilder?: boolean,
    options?: IDynamicPageOptions
  ): Promise<Array<IDynamicModel>> {
    return new Promise((resolve) => {
      const prepare = (dynamicResponse?: IDynamicResponseBody) => {
        const controls: IDynamicModel[] = [];
        const dynamicControls =
          container && container.dynamicControls
            ? container.dynamicControls.sort(sortByProperty('order', true))
            : [];

        if (options?.dynamicResponseId && !options?.isReclassification) {
          controls.push({
            formControlName: 'copyLinkButton',
            type: 'copyLinkButton',
            options: {
              dynamicContainerId: container.dynamicContainerId,
              dynamicContainerResponseId: options?.dynamicResponseId,
              formPath: this.getFormPath(options),
            },
          });
        }

        if (dynamicResponse) {
          options.submittedUserId = dynamicResponse.submittedBy;
        }

        for (const c of dynamicControls) {
          // prepare any legacy data for latest app changes
          // e.g., if dynamic properties are refactored to different naming at anytime
          this.handleDataMigrations(c);

          if (dynamicResponse) {
            this.applyResponseContent(c, dynamicResponse, options);
          }
          // handle certain types based on way backend api stores them
          switch (c.dynamicControlType) {
            case 'typeahead-category':
              // right now, these are hard coded values in dynamic-typeahead-category.base-component, always make sure valueProperty is correct
              c.valueProperty = 'Id';
              break;
            case 'accordion':
              if (c.dynamicControls) {
                const nestedControls = c.dynamicControls.sort(
                  sortByProperty('order', true)
                );
                c.options = c.options || {};
                c.options.accordion = [];
                for (const nestedControl of nestedControls) {
                  // prepare any legacy data for latest app changes
                  // e.g., if dynamic properties are refactored to different naming at anytime
                  this.handleDataMigrations(nestedControl);

                  switch (nestedControl.dynamicControlType) {
                    case 'buttongroup':
                      if (
                        nestedControl.options &&
                        nestedControl.options.buttongroup &&
                        nestedControl.options.buttongroup.length
                      ) {
                        if (
                          nestedControl.options.showOnValues &&
                          nestedControl.options.showOnValues.dynamicControlName
                        ) {
                          nestedControl.options.showOnValues.formControlName =
                            nestedControl.options.showOnValues.dynamicControlName;

                          if (
                            nestedControl.options.showOnValues.values.includes(
                              nestedControl.value
                            )
                          ) {
                            const controlToShow = nestedControls.find(
                              (nc) =>
                                nc.dynamicControlName ===
                                nestedControl.options.showOnValues
                                  .dynamicControlName
                            );
                            if (controlToShow) {
                              controlToShow.options.hidden = false;
                            }
                          }
                        }
                        nestedControl.options.buttongroup =
                          nestedControl.options.buttongroup.map(
                            (bg: {
                              dynamicControlLabel?: string;
                              value?: string | number;
                            }) => {
                              return {
                                label: bg.dynamicControlLabel,
                                value: bg.value,
                              };
                            }
                          );
                      }
                      break;
                  }
                  nestedControl.isFormBuilder = isFormBuilder;
                  c.options.accordion.push({
                    clientId: nestedControl.dynamicControlId,
                    formControlName: nestedControl.dynamicControlName,
                    type: nestedControl.dynamicControlType,
                    label: nestedControl.dynamicControlLabel,
                    isReusable: nestedControl.isReusable,
                    ...nestedControl,
                  });
                }
                delete c.dynamicControls;
              }
              break;
          }
          const dynamicModel: IDynamicModel = {
            clientId: c.dynamicControlId,
            type: c.dynamicControlType,
            label: c.dynamicControlLabel,
            instruction: c.dynamicControlInstruction,
            isReusable: c.isReusable,
            ...c,
          };
          if (dynamicModelFormArrayTypes.includes(dynamicModel.type)) {
            dynamicModel.formArrayName = c.dynamicControlName;
          } else {
            dynamicModel.formControlName = c.dynamicControlName;
          }
          if (dynamicModel.type !== 'button') {
            // right now, not supporting custom buttons within containers
            controls.push(dynamicModel);
          }
        }

        if (options?.showComments && !options?.isDraft) {
          controls.push({
            formControlName: 'comment',
            type: 'comment',
            options: {
              dynamicContainerId: container.dynamicContainerId,
              dynamicContainerResponseId: options?.dynamicResponseId,
              formPath: this.getFormPath(options),
            },
          });
        }

        if (!options?.isDraft) {
          if (options?.isCoaching) {
            controls.push({
              formControlName: 'coaching',
              type: 'coaching',
              options: {
                dynamicContainerId: container.dynamicContainerId,
                dynamicContainerResponseId: options?.dynamicResponseId,
                deleteComment: options?.deleteComment,
                submittedUserId: options.submittedUserId,
                isReadOnly: options.isReadOnly,
              },
            });
          }
          if (
            (options?.isReview || options?.isEditReview) &&
            !options?.isDraft
          ) {
            controls.push({
              formControlName: 'review',
              type: 'review',
              options: {
                dynamicContainerId: container.dynamicContainerId,
                dynamicContainerResponseId: options?.dynamicResponseId,
                isEditReview: options?.isEditReview,
                reviewId: options?.reviewId,
                deleteComment: options?.deleteComment,
                submittedUserId: options.submittedUserId,
                isReadOnly: options.isReadOnly,
              },
            });
          }
        }

        if (options?.isDraft) {
          controls.push({
            formControlName: 'submit',
            type: 'button',
            value: 'Submit',
            // order,
            options: {
              showCancelButton: true,
              showDraftButton: true,
              showDeleteDraftButton: true,
              primary: true,
              submit: true,
            },
          });
        }

        if (options?.showLastModified) {
          // Add last modified data component
          controls.push({
            formControlName: 'last-modified',
            type: 'last-modified',
          });
        }

        resolve(controls);
      };

      this.activeFormResponse = null;

      const prepareResponse = (response: IDynamicResponseBody) => {
        this.activeFormResponse = options?.isReclassification ? null : response;
        this.checkResponseForAttachments(response);
        prepare(response);
      };
      if (options?.dynamicResponseId) {
        this.loadContainerResponse(options?.dynamicResponseId).then((r) => {
          prepareResponse(r);
        });
      } else if (options?.dynamicResponseBody) {
        prepareResponse(options.dynamicResponseBody);
      } else {
        prepare();
      }
    });
  }

  /**
   * Everytime a dynamic container is displayed, each control will be passed through this
   * Can be used to auto-update property name refactors from older legacy naming to latest
   * After >90 days of new versions being released with migration handling in place, can safely remove those migrations applied >90 days ago
   * @param control dynamic control
   */
  handleDataMigrations(control: IDynamicControl) {
    if (control?.options) {
      if (
        (<{ updateOnSelection: Array<IDynamicUpdateOnValueChange> }>(
          (<unknown>control.options)
        )).updateOnSelection
      ) {
        /**
         * January 11, 2022
         * updateOnSelection: property renamed to > updateOnValueChange
         */
        control.options.updateOnValueChange = (<
          { updateOnSelection: Array<IDynamicUpdateOnValueChange> }
        >(<unknown>control.options)).updateOnSelection;

        delete (<{ updateOnSelection: Array<IDynamicUpdateOnValueChange> }>(
          (<unknown>control.options)
        )).updateOnSelection;
      }
    }
  }

  checkResponseForAttachments(response?: IDynamicResponseBody) {
    response = response || this.activeFormResponse;
    if (response.dynamicControlResponses) {
      this.activeAttachments = {};
      response.dynamicControlResponses.forEach((res) => {
        if (res.responseContentJson) {
          const fileAttachments = JSON.parse(res.responseContentJson);
          if (fileAttachments) {
            if (Array.isArray(fileAttachments)) {
              for (const fileAttachment of fileAttachments) {
                this.addAttachmentForField(
                  res.dynamicControlName,
                  fileAttachment
                );
              }
            } else {
              this.addAttachmentForField(
                res.dynamicControlName,
                fileAttachments
              );
            }
          }
        }
      });
    }
  }

  getAppTagName(selectedAppTag?: string) {
    const name = tagCleanser(selectedAppTag || environment.name);
    // Note: could run migration to add `app:` prefix to tags which don't have a prefix
    // Started adding `app:` prefix to tags in October 2023
    // TODO: Talk with Peter/Tim about using multiple tags with tagNames param?
    // For awhile (without a migration), we need to request 'safety,app:safety'
    return name === 'safety' ? name : encodeURIComponent(`app:${name}`);
  }

  getFormPath(options: IDynamicPageOptions): string {
    if (options) {
      let form = this.dynamicContainerList.find(
        (f) => tagCleanser(f.dynamicContainerName) === options.name
      );
      if (!form) {
        // unpublished form
        form = this.dynamicContainerListAll.find(
          (f) => tagCleanser(f.dynamicContainerName) === options.name
        );
        if (!form.url) {
          const tags = dynamicGetCategoryTags(form.tags);
          const categoryName = dynamicGetCategoryTagName(tags[0]);
          const namePath = tagCleanser(form.dynamicContainerName);
          form.url = `/forms1/${tagCleanser(categoryName)}/${namePath}`;
        }
      }
      if (form && form.url) {
        let url = `form/${options.name}`;
        url = form.url;
        if (options.dynamicResponseId) {
          url += `?dynamicResponseId=${options.dynamicResponseId}`;
          if (options.shortName) {
            url += `&shortName=${options.shortName}`;
          }
          if (options.isCoaching) {
            url += '&isCoaching=1';
          }
          if (options.isEditReview) {
            url += '&isEditReview=1';
          } else if (options.isReview) {
            url += '&isReview=1';
          }
        }
        return url;
      }
    }
    return null;
  }

  updateMetaData(value: IDynamicContainerMetadata) {
    this.metadata = {
      ...(this.metadata || {}),
      ...value,
    };
  }

  resetMetaData() {
    this.updateMetaData({
      dynamicContainerName: '',
      dynamicContainerDescription: '',
      dynamicContainerId: '',
      displayIcon: '',
      tags: [],
      dynamicContainerWhatsNew: '',
      isDirty: false,
    });
  }

  updateFormBuilderState(value: IDynamicBuilderState) {
    this.formBuilderState = {
      ...(this.formBuilderState || {}),
      ...value,
    };
  }

  selectNestedControl(e, itemConfig) {
    if (e && itemConfig && itemConfig.isFormBuilder) {
      e.stopPropagation();
      e.preventDefault();
      this.selectNestedControl$.next(itemConfig);
      return false;
    }
  }

  submitForm(form: UntypedFormGroup, publish?: boolean, draft?: boolean) {
    this.log.debug(logTag, 'form:', form.value);
    this.log.debug(logTag, 'isFormValid:', this.isFormValid(form));
    if (this.activeAction) {
      // this.progress.toggleSpinner(true);
      this.activeAction({
        publish,
        draft,
      }).then(
        (result) => {
          // reset
          this._resetSpinner();
          this.activeActionDone$.next(result);
          this.activeAttachments = null;
          this.signatureUploadFile = null;
        },
        (errorMessage) => {
          this.win.alert(errorMessage);
          this._resetSpinner();
        }
      );
    }
  }

  isFormValid(form: UntypedFormGroup): boolean {
    if (form) {
      if (form.status === 'INVALID' && !form.valid) {
        this.isFormDirty = true;
        this._reportFormErrors(form);
        return false;
      }
    }
    return true;
  }

  createConfigGroup(options: {
    groupName?: string;
    groupDescription?: string;
    dynamicModels: Array<IDynamicModelProperties>;
    editing?: boolean;
    /**
     * generate unique formControlName's
     */
    generateFormControlNames?: boolean;
    submitButtonText?: string;
    addCancelButton?: boolean;
    addDraftButton?: boolean;
    /**
     * Enables controls to reorder fields, remove fields and disable editing.
     */
    isFormBuilder?: boolean;
    /**
     * Ignore adding a save or edit button
     * Used when configuring modals with your own save/edit/cancel buttons
     */
    ignoreDefaultButton?: boolean;
    /**
     * used to activate a single shared instance of the active model group
     */
    activateModelGroup?: boolean;
  }): IDynamicModelGroup {
    const controls: Array<IDynamicModelProperties> = [];
    let order = 0;
    for (const model of options.dynamicModels) {
      this.log.debug(logTag, 'dynamic model type:', model.type);
      const dynamicmodel: IDynamicModelProperties = {
        formControlName: options.generateFormControlNames
          ? guid()
          : model.formControlName,
        formArrayName: model.formArrayName,
        // reportCategoryName: model.reportCategoryName || model.formControlName,
        type: model.type,
        label: model.label || model.formControlName,
        instruction: model.instruction,
        types: model.types,
        selected: model.selected,
        value: model.value,
        disabled: model.disabled,
        ignoreValue: model.ignoreValue,
        required: model.required,
        placeholder: model.placeholder,
        inputType: model.inputType ? model.inputType : null,
        valueProperty: model.valueProperty || model.formControlName,
        // TODO: create validation mapping method to return validations based on fieldmodel types
        validators: model.validators || [],
        order,
        options: model.options,
        isFormBuilder: options.isFormBuilder,
        clientId: model.clientId,
        isReusable: model.isReusable,
        readOnly: model.readOnly,
        reportCategoryName: model.reportCategoryName,
        accessRoleIds: model.accessRoleIds,
      };
      controls.push(dynamicmodel);
      order++;
    }
    // for now, always add a save or update button at the end
    if (!options.ignoreDefaultButton) {
      controls.push({
        formControlName: options.generateFormControlNames ? guid() : 'submit',
        type: 'button',
        value:
          options.submitButtonText || (options.editing ? 'Update' : 'Save'),
        order,
        options: {
          showCancelButton: options.addCancelButton,
          showDraftButton: options.addDraftButton,
          primary: true,
          submit: true,
        },
      });
    }

    const modelGroup = {
      groupType: 'GpType',
      groupName: options.groupName,
      groupDescription: options.groupDescription,
      controls: controls.sort((a, b) => (a.order > b.order ? 1 : -1)),
    };
    if (options.activateModelGroup) {
      this.activeModelGroup = modelGroup;
      this.activeGroupSettings = null;
    }
    return modelGroup;
  }

  updateActiveGroupSettings(updates: IDynamicActiveGroupSettings) {
    this.activeGroupSettings = {
      ...(this.activeGroupSettings || {}),
      ...updates,
    };
  }

  registerControlForGroup(
    config: IDynamicModel,
    group: UntypedFormGroup
  ): IDynamicRegisterControl {
    const registerControlSettings: IDynamicRegisterControl = {};
    const formState = {
      disabled: config.disabled,
      value: null,
    };
    if (config.value !== null && config.value !== undefined) {
      if (config.type.indexOf('typeahead') > -1) {
        // values are objects which match id's
        registerControlSettings.valueObjectId = <string>config?.value;
      } else if (config.type === 'date') {
        formState.value = new Date(<string>config.value);
      } else if (config.type === 'checkbox') {
        formState.value = config.value === 'true' || config.value === true;
      } else if (config.type === 'numerictextbox') {
        formState.value = Number(config.value);
      } else {
        formState.value = config.value;
      }
    }
    config.validators = [];
    if (!config.isFormBuilder) {
      if (config.required) {
        config.validators.push(Validators.required);
      }
      if (config.options?.maxlength) {
        config.validators.push(Validators.maxLength(config.options.maxlength));
      }
      if (config.options?.customValidations) {
        for (const validation of config.options.customValidations) {
          if (validation.startsWith('date:')) {
            config.validators.push(
              dynamicValidatorDate(config.label, validation)
            );
          }
        }
      }

      // every control will have this loading validator so if it is loading data then form is invalid
      config.validators.push(dynamicValidatorNotLoading(config.label));
    }
    if (
      !dynamicFormGroupExcludedControlNames.includes(config.formControlName)
    ) {
      group.addControl(
        config.formControlName,
        new UntypedFormControl(formState, config.validators)
      );
    }

    return registerControlSettings;
  }

  createAndUploadFileForConfig(
    filename: string,
    content: string,
    contentType: string,
    config: IDynamicModel
  ): Promise<FileDto> {
    return new Promise((resolve) => {
      const fileDto: FileDto = new FileDto();
      fileDto.name = filename;
      if (typeof content === 'string') {
        fileDto.contentBase64 = content;
      } else {
        fileDto.content = content;
      }
      fileDto.contentType = contentType;
      fileDto.directory =
        config.options && config.options.directory
          ? config.options.directory
          : 'editModal';
      fileDto.bActive = true;

      this.spartaFileService
        .uploadFile(fileDto)
        .then((f) => {
          this.addAttachmentForField(config.formControlName, f);
          resolve(f);
        })
        .catch(() => {
          this.win.alert('An error has occurred.');
          resolve(null);
        });
    });
  }

  addAttachmentForField(name: string, attachment: FileDto) {
    if (!this.activeAttachments) {
      this.activeAttachments = {};
    }
    if (!this.activeAttachments[name]) {
      this.activeAttachments[name] = [];
    }
    // track attachment per form control
    this.activeAttachments[name].push(attachment);
  }

  removeAttachmentForField(name: string, attachment: FileDto) {
    if (this.activeAttachments && this.activeAttachments[name] && attachment) {
      const index = this.activeAttachments[name].findIndex(
        (a) => a.fileID === attachment.fileID
      );
      if (index > -1) {
        this.activeAttachments[name].splice(index, 1);
      }
    }
  }

  getFile(
    attachmentID: string,
    useSparta?: boolean,
    spartaDownload?: boolean
  ): Promise<string | IFileData> {
    return new Promise((resolve) => {
      if (attachmentID) {
        if (useSparta) {
          this.spartaFileService.getFileWithContent(`${attachmentID}`).then(
            (data) => {
              resolve(<IFileData>data);
            },
            () => {
              resolve(null);
            }
          );
        }
        if (spartaDownload) {
          this.spartaFileService.downloadFileFromSparta(`${attachmentID}`).then(
            (data) => {
              resolve(data.Data);
            },
            () => {
              resolve(null);
            }
          );
        } else {
          this.attachmentService
            .getFileByAttachmentId(`${attachmentID}`)
            .subscribe((data: IFileData) => resolve(data));
        }
      }
    });
  }

  isExistingContainer() {
    return !!(this.metadata && this.metadata.dynamicContainerId);
  }

  isDirtyContainer() {
    return !!(this.metadata && this.metadata.isDirty);
  }

  saveDynamicContainer(body: IDynamicContainerMetadata) {
    if (this.isExistingContainer()) {
      body.dynamicContainerId = this.metadata.dynamicContainerId;
      return this.http
        .put(`/api/DynamicContainer/${this.metadata.dynamicContainerId}`, body)
        .pipe(
          catchError((err) => {
            this.log.debug(logTag, 'ERROR:', err);
            return of(err);
          })
        );
    } else {
      return this.http.post(`/api/DynamicContainer`, body).pipe(
        catchError((err) => {
          this.log.debug(logTag, 'ERROR:', err);
          return of(err);
        })
      );
    }
  }

  publishDynamicContainer(containerId?: string, version?: number) {
    return this.http
      .post(`/api/DynamicContainer/publish/${containerId}/${version}`, null)
      .pipe(
        catchError((err) => {
          this.log.debug(logTag, 'ERROR:', err);
          return of(null);
        })
      );
  }

  /**
   * Ability to prepare any extracurricular data before submission
   * For example: attachment handling with dynamic signature components
   * This method can be used to expand on any type of extra preprocessing on the data before submitting user response
   * @param controls collection of dynamic models
   * @returns
   */
  prepareToSubmitResponse(controls: Array<IDynamicModel>): Promise<void> {
    return new Promise((resolve) => {
      const signatureControl = controls.find((c) => c.type === 'signature');
      if (signatureControl) {
        // check if pad is signed
        if (this.isSignatureEmpty()) {
          this.progress.toggleSpinner(false);
          this.win.alert({
            title: 'Signature Required!',
            message: `Your signature is required to submit this form.`,
            okButtonText: 'Ok',
          });
          return;
        } else if (!this.signatureUploadFile) {
          this.progress.toggleSpinner(true);
          // get base64 signature image
          this.signatureBase64().then((signatureBase64) => {
            // create filedto of signature image
            this.createAndUploadFileForConfig(
              `Signature-${guid()}.png`,
              signatureBase64,
              'image/png',
              signatureControl
            ).then((fileDto) => {
              if (fileDto) {
                this.signatureUploadFile = fileDto;
                resolve();
              } else {
                this.progress.toggleSpinner(false);
              }
            });
          });
        } else {
          resolve();
        }
      } else {
        resolve();
      }
    });
  }

  saveDynamicResponse(
    dynamicControlResponses?: Array<IDynamicControlResponse>,
    draft?: boolean
  ): Observable<IDynamicResponseBody> {
    const body: IDynamicResponseBody = {
      dynamicContainerResponseId:
        this.activeFormResponse?.dynamicContainerResponseId,
      dynamicContainerId: this.metadata.dynamicContainerId,
      isActive: true,
      isDraft: false,
      isFinal: true,
      dynamicControlResponses,
    };

    if (draft) {
      body.isDraft = true;
    }

    return this.http.post('/api/DynamicResponse', body).pipe(
      catchError((err) => {
        this.log.debug(logTag, 'ERROR:', err);
        return of(null);
      })
    );
  }

  updateDynamicResponse(deleteComment: string) {
    const body: IDynamicResponseBody = {
      dynamicContainerResponseId:
        this.activeFormResponse?.dynamicContainerResponseId,
      dynamicContainerId: this.metadata.dynamicContainerId,
      isActive: false,
      deleteComment,
      // isFinal: true,
    };

    return this.http
      .put('/api/DynamicResponse/' + body.dynamicContainerResponseId, body)
      .pipe(
        catchError((err) => {
          this.log.debug(logTag, 'ERROR:', err);
          return of(null);
        })
      );
  }

  failValidation(message = 'Please fill out all the required fields.') {
    this.progress.toggleSpinner(false);
    this.win.setTimeout(() => {
      if (this.activeForm?.errors) {
        // const errors = Object.keys(this.dynamicRender.activeForm.errors);
        // TODO: this seems to only be filled under circumstances unknown at this time
        // if you come across this and want to use as a way to manage the error message, uncomment this:
        // this.dynamicRender.win.alert(errors.join(' '));
      } else {
        this.win.alert(message);
      }
    });
  }

  /**
   * Checks the activeForm [FormGroup] and any nested FormGroup validity
   * @param modelGroup (optional) validate against a specific IDynamicModelGroup vs. just the activeModelGroup
   * @param skipNestedFormGroups (optional) skip validating nested form groups. useful
   * @returns failure message if form is invalid
   */
  isActiveFormValid(
    modelGroup?: IDynamicModelGroup,
    skipNestedFormGroups?: boolean
  ) {
    let message = null;
    let reportedNestedGroupError = false;
    const invalidLabels = [];

    for (const key of Object.keys(this.activeForm.controls)) {
      const nestedFormGroup = findNestedFormGroupsWith(this.activeForm, key)[0];
      if (nestedFormGroup) {
        if (reportedNestedGroupError) {
          // prevent numerous nested group validation errors (only 1 is needed at a time)
          break;
        }
        if (!skipNestedFormGroups) {
          for (const nestedKey of Object.keys(nestedFormGroup.controls)) {
            if (!nestedFormGroup.controls[nestedKey].valid) {
              const control = this.activeModelGroup.controls.find(
                (c) => c.formArrayName === key
              );
              if (control && control.options?.accordion) {
                const nestedControl = control.options.accordion.find(
                  (c) => c.formControlName === nestedKey
                );
                if (nestedControl) {
                  // limit reported nested gropus to only 1 to prevent long list of errors
                  reportedNestedGroupError = true;
                  invalidLabels.push(
                    `${nestedControl.label} in ${control.label}`
                  );
                  break;
                }
              }
            }
          }
        }
      } else if (
        !this.activeForm.controls[key].valid &&
        !this.activeForm.controls[key].disabled
      ) {
        const control = (modelGroup || this.activeModelGroup).controls.find(
          (c) => c.formControlName === key
        );
        if (control) {
          const controlErrors = Object.keys(
            this.activeForm.controls[key].errors
          );
          let customErrorMessage: string;
          if (controlErrors.length) {
            if (
              typeof this.activeForm.controls[key].errors[controlErrors[0]] ===
              'string'
            ) {
              // if any errors exist, extra first string type error to display to user
              customErrorMessage =
                this.activeForm.controls[key].errors[controlErrors[0]];
            }
          }
          if (customErrorMessage) {
            message = customErrorMessage;
          } else {
            if (
              control?.options?.hidden === null ||
              control.options.hidden !== true
            ) {
              invalidLabels.push(control.label || control.formControlName);
            }
          }
        }
      }
    }
    if (invalidLabels.length) {
      message = `To submit the form, please check the validity of the following: ${invalidLabels
        .map((l) => `'${l}'`)
        .join(', ')}`;
    }
    return message;
  }

  /**
   * Prompts a confirm dialog before resetting
   * @returns Promise when confirmed to reset
   */
  resetActiveForm(): Promise<void> {
    return new Promise((resolve) => {
      this.win
        .confirm('Are you sure you want to cancel and discard your changes?')
        .then((ok) => {
          if (ok) {
            this.clearActiveForm();
            resolve();
          }
        });
    });
  }

  confirmSaveActiveFormAsDraft(): Promise<void> {
    const message = this.win.isBrowser
      ? 'It looks like you are in the middle of filling out this form, if you wish to save this form as a draft click Ok. If you wish to discard the changes you have made on this form click Cancel.'
      : `Do you wish to save the form you are filling out as a draft or do you wish to discard?`;
    return new Promise((resolve) => {
      this.win
        .confirm({
          title: `Save a Draft?`,
          message,
          okButtonText: 'Yes',
          cancelButtonText: 'No, Ignore',
        })
        .then((ok) => {
          if (ok) {
            this.submitForm(this.activeForm, null, true);
          } else {
            this.clearActiveForm();
          }
          resolve();
        });
    });
  }

  /**
   * Immediately clears and resets the active form without prompting to confirm
   */
  clearActiveForm(ignoreFormGroupReset?: boolean) {
    if (!ignoreFormGroupReset) {
      // ignoreFormGroupReset can be used when just recreating the FormGroup [activeForm], no need to trigger reset events, however still want to clear all the service level state associated with activeForm's
      if (this.activeForm) {
        this.activeForm.reset();

        const nestedFormGroups = findNestedFormGroupsWith(this.activeForm);
        if (nestedFormGroups.length) {
          for (const nestedFormGroup of nestedFormGroups) {
            nestedFormGroup.reset();
          }
        }
      }
    }
    // clear any other service level state associated with forms
    this.activeAttachments = {};
    this.eventBus.emit(DynamicEventBusTypes.dynamicFormResetState);
    this.activeFormResponse = null;
    if (this.signatureReset) {
      this.signatureReset();
    }
  }

  /**
   * Resets various state level properties within dynamic controls
   * ie, selected state
   * @param controls dynamic models
   */
  resetControlState(controls: Array<IDynamicModel>) {
    for (const control of controls) {
      control.selected = false;
      if (control.options?.buttongroup) {
        for (const bg of control.options.buttongroup) {
          bg.selected = false;
        }
      }
      if (control.options?.accordion) {
        for (const accordionControl of control.options.accordion) {
          accordionControl.selected = false;
          if (accordionControl.options?.buttongroup) {
            for (const bg of accordionControl.options.buttongroup) {
              bg.selected = false;
            }
          }
        }
      }
    }
    // notify architecture of state reset
    // useful for custom instance state handling
    this.eventBus.emit(DynamicEventBusTypes.dynamicFormResetState, true);
  }

  cacheDynamicContainersForOffline(data: Array<IDynamicContainerMetadata>) {
    return new Promise<void>((resolve) => {
      const dynamicCache: IDynamicOfflineCache = {
        cacheDate: Date.now(),
        data,
      };
      this.offlineStorage
        .setItem(
          StorageKeys.OFFLINE_DATA_DYNAMIC_CONTAINERS,
          {
            dynamicContainers: dynamicCache,
          },
          'dynamicContainers'
        )
        .pipe(take(1))
        .subscribe(() => {
          this.log.debug(
            logTag,
            'all dynamic container data:',
            'Cache refreshed, stored and dated:',
            new Date(dynamicCache.cacheDate)
          );
          resolve();
        });
    });
  }

  /**
   * Prefetches dropdown data and stores for offline use later
   * @returns Promise when prefeth and cache is complete
   */
  prefetchDropdownsForOffline(forceRefresh?: boolean) {
    return new Promise((resolve) => {
      const fetchAndCache = () => {
        this.log.debug(
          logTag,
          logTagPrefetchDropdowns,
          'Fetching fresh for offline use later...'
        );
        this.http
          .get(`/api/Form/dropdowns`)
          .pipe(
            catchError((err) => {
              this.log.debug(logTag, 'ERROR:', err);
              return of(null);
            })
          )
          .subscribe((data) => {
            const dynamicCache: IDynamicOfflineCache = {
              cacheDate: Date.now(),
              ...data,
            };
            if (this.win.navigator.onLine) {
              // only cache data when online to ensure only fresh data is stored
              this.offlineStorage
                .setItem(
                  StorageKeys.OFFLINE_DATA_DROPDOWNS,
                  {
                    dynamicDropdowns: dynamicCache,
                  },
                  'dynamicDropdowns'
                )
                .pipe(take(1))
                .subscribe();
              this.log.debug(
                logTag,
                logTagPrefetchDropdowns,
                'Cache refreshed, stored and dated:',
                new Date(dynamicCache.cacheDate)
              );
            }
            this._hasCachedOfflineData = true;
            resolve(dynamicCache);
          });
      };
      if (forceRefresh) {
        this.log.debug(logTag, logTagPrefetchDropdowns, 'refreshing cache...');
        fetchAndCache();
      } else {
        this.log.debug(
          logTag,
          logTagPrefetchDropdowns,
          'checking for existing cache...'
        );
        this.offlineStorage
          .getItem(StorageKeys.OFFLINE_DATA_DROPDOWNS, null, 'dynamicDropdowns')
          .pipe(take(1))
          .subscribe((data: { dynamicDropdowns?: IDynamicOfflineCache }) => {
            if (data?.dynamicDropdowns) {
              // check if out of date
              // right now, we will cache every 2 weeks
              // TODO: can add a user setting that any user could initiate a fresh cache reset to force preload a fresh cache on demand
              if (
                moment(data.dynamicDropdowns.cacheDate).diff(
                  moment(),
                  'weeks'
                ) >= 2
              ) {
                // refetch and cache
                this.log.debug(
                  logTag,
                  logTagPrefetchDropdowns,
                  'cache found but was out of date:',
                  new Date(data.dynamicDropdowns.cacheDate),
                  '...fetching fresh data...'
                );
                fetchAndCache();
              } else {
                // utlize existing cache
                this.log.debug(
                  logTag,
                  logTagPrefetchDropdowns,
                  'cache found dated:',
                  new Date(data.dynamicDropdowns.cacheDate)
                );
                this._hasCachedOfflineData = true;
                resolve(data.dynamicDropdowns);
              }
            } else {
              fetchAndCache();
            }
          });
      }
    });
  }

  saveFormReview(dto: IFormReview) {
    return this.http.post(`/api/Form/coach`, dto);
  }

  getFormReview(
    dynamicResponseId: string,
    type: string
  ): Observable<IFormReview> {
    const url = `/api/form/review/${type}/${dynamicResponseId}`;

    return this.http.get(url) as Observable<IFormReview>;
  }

  getCannedResponses(type?: string): Observable<ICannedResponse[]> {
    let url = '/api/form/cannedResponses';
    if (type) {
      url += `?type=${type}`;
    }
    return this.http.get(url) as Observable<ICannedResponse[]>;
  }

  private _getValidators(field: IDynamicModel) {
    const validators: Array<Validators> = [];
    if (field.required) {
      validators.push(Validators.required);
    }
    return validators;
  }

  private _reportFormErrors(form: UntypedFormGroup): void {
    const errorMessage = '';
    Object.keys(form.controls).forEach((key) => {
      const controlErrors = form.get(key).errors;
      if (controlErrors != null) {
        const errorTypes = Object.keys(controlErrors);
        if (errorTypes && errorTypes.length) {
          for (const errorType of errorTypes) {
            this._defaultErrorMessage(form, errorMessage, key, errorType);
          }
        }
      }
    });
    if (errorMessage) {
      this.win.alert(errorMessage);
    }
  }

  private _defaultErrorMessage(
    form: UntypedFormGroup,
    errorMessage: string,
    key: string,
    errorType: string
  ) {
    errorMessage += `Error '${key}': '${errorType}', value: '${form.controls[key].value}' `;
  }

  private _resetSpinner() {
    this.progress.infinitePreventionOn = true;
    this.progress.toggleSpinner(false);
  }
}
