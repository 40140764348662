import { Directive, AfterViewInit, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import { TreeViewComponent, TreeItemDropEvent, TreeItemDragStartEvent, DropPosition } from '@progress/kendo-angular-treeview';

const EMPTY_ITEM = { text: '[Empty]', placeholder: true, id: '3D5CABE6-43AC-42A0-9045-264C11C56CA2' };

@Directive({
  selector: '[emptyItemPlaceholder]',
})
export class EmptyItemPlaceholderDirective implements AfterViewInit {
  @Input() textField: string;
  @Input() set nodes(v: any[]) {
    console.log('Setter Called');
    this.handleEmptyNodes(v);
  }

  constructor(private treeview: TreeViewComponent, private cdr: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    this.treeview.addItem.subscribe(this.handleAdd.bind(this));
    this.treeview.removeItem.subscribe(this.handleRemoveItem.bind(this));
    this.treeview.nodeDragStart.subscribe(this.handleDragStart.bind(this));

    this.handleEmptyNodes(this.treeview.nodes);
  }

  private handleAdd(event: TreeItemDropEvent): void {
    const placeholderItem = this.treeview.nodes.find((item) => item.placeholder);
    if (placeholderItem) {
      // remove the empty item placeholder
      const index = this.treeview.nodes.indexOf(placeholderItem);
      this.treeview.nodes.splice(index, 1);

      // if the item was dropped into the empty item, we've just spliced it as well, so restore it back
      if (event.dropPosition === DropPosition.Over) {
        this.treeview.nodes.push(event.sourceItem.item.dataItem);
      }
    }
  }

  private handleRemoveItem(): void {
    if (this.treeview.nodes.length > 0) {
      return;
    }

    this.treeview.nodes.push({ ...EMPTY_ITEM, [this.textField]: EMPTY_ITEM.text });
  }

  private handleDragStart(event: TreeItemDragStartEvent): void {
    if (event.sourceItem.item.dataItem.placeholder) {
      event.preventDefault();
    }
  }

  private handleEmptyNodes(nodes) {
    if (nodes.length === 0) {
      console.log('Before: ', ...this.treeview.nodes);
      this.treeview.nodes.push({ ...EMPTY_ITEM, [this.textField]: EMPTY_ITEM.text });
      this.cdr.detectChanges();
      console.log('After: ', ...this.treeview.nodes);
    }
  }
}
