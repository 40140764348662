import { NgModule } from '@angular/core';

import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

// app
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { LayoutModule } from '@ups/xplat/web/layout';
import {
  AuthModule,
  UIModule,
  UserModule,
} from '@ups/xplat/web/features';
import {
  MENU_SERVICE_TOKEN,
  MenuService,
} from '@ups/common/menu';
import { InAppHelpModule } from '@ups/xplat/web/in-app-help';
import { RedirectGuard } from './core/redirect-guard';
import '@progress/kendo-angular-intl/locales/en-GB/all';
import { IntlService } from '@progress/kendo-angular-intl';
import { IntlDateService } from '@ups/xplat/web/services';

@NgModule({
  imports: [
    CoreModule,
    AppRoutingModule,
    UserModule,
    AuthModule,
    LayoutModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    InAppHelpModule,
    UIModule,
    // disabled for now (todo: need to figure out how auth0 could work with service workers)
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   // NOTE: we may want some flag back here to only enable in "deployed" environments
    //   // prod flag is not quite ideal here since we deploy qa/uat/stg as well
    //   // enabled: environment.production,
    //   registrationStrategy: 'registerImmediately',
    // }),
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: MENU_SERVICE_TOKEN,
      useClass: MenuService,
    },

    {
      provide: IntlService,
      useClass: IntlDateService,
    },

    RedirectGuard,
  ],
})
export class AppModule {}
