import { Component, Input, TemplateRef } from '@angular/core';
import { UpsMessageTypeEnum, UpsMessageObject } from '@ups/xplat/api/dto';

/*
Usage:
------
Import UpsMessageModule
Insert message component into HTML and give it a local name <ups-message-component></ups-message-component>.
Inside the main component use @ViewChild() mc: UpsMessageComponent directive to reference it (note: instance won't be avail. inside ctor!).

In the TS code, just use:
this.mc.info("my message")
this.mc.success("my message", 2500)
this.mc.warn("my <b>warning</b>", 10000, true)
this.mc.error("my <b>error</b>", 0, true)

Component also supports:
- singleMessageMode (so prev. messages will be flushed)
- custom templates
- defaults for timeout
- property to set HTML as default
 */
@Component({
  selector: 'ups-message-component',
  template: `
    <ng-template #defaultTemplate>
      <div *ngFor="let m of messages" class="alert alert-dismissible show" role="alert" [ngClass]="{ 'alert-info': hasFlag(m.type, _messageTypeEnum.Info), 'alert-success': hasFlag(m.type, _messageTypeEnum.Success), 'alert-warning': hasFlag(m.type, _messageTypeEnum.Warning), 'alert-danger': hasFlag(m.type, _messageTypeEnum.Error) }">
        <button type="button" class="close" aria-label="Close" data-dismiss (click)="remove(m.id)">
          <span aria-hidden="true">&times;</span>
        </button>
        <div *ngIf="m.isHtml" [innerHtml]="m.message"></div>
        <div *ngIf="!m.isHtml">{{ m.message }}</div>
      </div>
    </ng-template>

    <ng-container *ngTemplateOutlet="customTemplate ? customTemplate : defaultTemplate"></ng-container>
  `,
})
export class UpsMessageComponent {
  @Input() public defaultTimeout = 0;
  @Input() public defaultIsHtml = false;
  @Input() public singleMessageMode = false;
  @Input('template') public customTemplate: TemplateRef<any>;

  protected lastUsedId = 0;

  // NOTE: public due HTML ref.
  public messages: Array<UpsMessageObject> = [];

  // NOTE: referencing enum in templates
  public _messageTypeEnum = UpsMessageTypeEnum;

  private addMessage(type: UpsMessageTypeEnum, message: string, timeout: number, isHtml: boolean): void {
    const messageObject = new UpsMessageObject(++this.lastUsedId, type, message, timeout, isHtml);

    if (this.singleMessageMode) this.messages = new Array<UpsMessageObject>(messageObject);
    else this.messages.push(messageObject);

    if (timeout > 0) setTimeout((_) => this.removeObsolete(), timeout);
  }

  // NOTE: public due HTML ref.
  public hasFlag(value: UpsMessageTypeEnum, flag: UpsMessageTypeEnum): boolean {
    return value & flag ? true : false;
  }

  public remove(id: number) {
    this.messages = this.messages.filter((_) => _.id != id /* expression to keep an item in the list */);
  }

  protected removeObsolete() {
    const currentDate = new Date(Date.now());
    this.messages = this.messages.filter((_) => _.expireDate > currentDate /* expression to keep an item in the list */);
  }

  public clear(): void {
    this.messages = new Array<UpsMessageObject>();
  }

  public info(message: string, timeout: number = this.defaultTimeout, isHtml: boolean = this.defaultIsHtml): void {
    this.addMessage(UpsMessageTypeEnum.Info, message, timeout, isHtml);
  }

  public success(message: string, timeout: number = this.defaultTimeout, isHtml: boolean = this.defaultIsHtml): void {
    this.addMessage(UpsMessageTypeEnum.Success, message, timeout, isHtml);
  }

  public warn(message: string, timeout: number = this.defaultTimeout, isHtml: boolean = this.defaultIsHtml): void {
    this.addMessage(UpsMessageTypeEnum.Warning, message, timeout, isHtml);
  }

  public error(message: string, timeout: number = this.defaultTimeout, isHtml: boolean = this.defaultIsHtml): void {
    this.addMessage(UpsMessageTypeEnum.Error, message, timeout, isHtml);
  }
}
