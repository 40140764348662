// angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutModule } from '@ups/xplat/web/layout';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./_layout/layout-routing.module').then(
        (m) => m.LayoutRoutingModule
      ),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [LayoutModule, RouterModule.forRoot(routes)],
})
export class AppRoutingModule {}
