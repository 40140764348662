import { Output, EventEmitter, Component, Directive } from '@angular/core';

@Directive()
export abstract class EditorComponentBase {
  public data: { [key: string]: string };

  @Output() public applyChanges: EventEmitter<{
    [key: string]: any;
  }> = new EventEmitter();

  @Output() public deleteControl: EventEmitter<void> = new EventEmitter();

  public onDeleteClick() {
    if (confirm('are you sure?')) {
      this.deleteControl.emit();
    }
  }
}
