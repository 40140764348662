<kendo-dialog
  title="Reorder {{ itemName }} for: {{ parentName }}"
  (close)="closeReorder(false)"
  [minWidth]="250"
  [width]="600"
>
  <div class="main-sortable">
    <div class="sortable-items simple">
      <kendo-sortable
        #sortableComponent
        [kendoSortableBinding]="reorderDraggables"
        [navigable]="true"
      >
        <ng-template let-palette="item" let-index="index">
          <span
            class="d-flex justify-content-between align-items-center text-center"
          >
            <button
              class="me-5"
              kendoButton
              [disabled]="index === 0"
              (click)="moveTo(index, 0)"
            >
              <i class="fas fa-arrow-to-top"></i>
            </button>
            {{ palette }}
            <button
              class="ms-5"
              kendoButton
              [disabled]="index === reorderDraggables.length - 1"
              (click)="moveTo(index, reorderDraggables.length - 1)"
            >
              <i class="fas fa-arrow-to-bottom"></i>
            </button>
          </span>
        </ng-template>
      </kendo-sortable>
    </div>
  </div>
  <kendo-dialog-actions [layout]="'normal'">
    <button kendoButton (click)="closeReorder(false)">Cancel</button>
    <button kendoButton (click)="closeReorder(true)" primary="true">
      Save
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
