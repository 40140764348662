<h4>
  <div class="title mb-5">
    &nbsp;
    <span *ngIf="!equipmentResourceNotFound">
      {{equipmentResource.EquipmentName}}
      {{equipmentResource.EquipmentDescription}}
      {{!equipmentResource.EquipmentName ||
      !equipmentResource.EquipmentName.includes(equipmentResource.EquipmentCategory)
      ? equipmentResource.EquipmentCategory : ''}}
      Co:{{equipmentResource.EquipmentCompany}}
    </span>
    <span *ngIf="equipmentResourceNotFound" class="text-danger">
      Equipment Resource Not Found in Viewpoint!
    </span>
  </div>
</h4>

<br />
<!--Kendo Tab -->
<div *ngIf="!equipmentResourceNotFound">
  <!--First Row-->
  <kendo-tabstrip
    id="upperTabGroupId"
    class="default-bg"
    *ngIf="isVisibleUpperGroupTab()"
    [tabAlignment]="'left'"
    (tabSelect)="changeTabs($event)"
    #upperTabGroupId
  >
    <kendo-tabstrip-tab
      id="infoTabId"
      *ngIf="isInfoTabVisible()"
      [selected]="true"
    >
      <ng-template kendoTabTitle>
        <span id="infoTabTitle">Info</span>
      </ng-template>
      <ng-template kendoTabContent>
        <ups-equipment-info
          [keyId]="equipmentResource.KeyId"
        ></ups-equipment-info>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab id="scheduleTabId" *ngIf="isScheduleTabVisible()">
      <ng-template kendoTabTitle>
        <span id="scheduleTabTitle">Schedule</span>
      </ng-template>
      <ng-template kendoTabContent>
        <ups-equipment-schedule
          [equipmentName]="equipmentResource.EquipmentName"
          [vpCompanyID]="equipmentResource.EquipmentCompany"
        ></ups-equipment-schedule>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab id="metersTabId" *ngIf="isMetersTabVisible()">
      <ng-template kendoTabTitle>
        <span id="metersTabTitle" help-anchor="metersTabTitle">Meters</span>
      </ng-template>
      <ng-template kendoTabContent>
        <ups-equipment-meters
          [keyId]="equipmentResource.KeyId"
        ></ups-equipment-meters>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab id="inspectionTabId" *ngIf="isInspectionTabVisible()">
      <ng-template kendoTabTitle>
        <span id="inspectionTabTitle" help-anchor="inspectionTabTitle">
          Inspection
        </span>
      </ng-template>
      <ng-template kendoTabContent>
        <ups-equipment-inspection-history
          [keyId]="equipmentResource.KeyId"
          [equipmentName]="equipmentResource.EquipmentName"
          [vpCompanyID]="equipmentResource.EquipmentCompany"
        ></ups-equipment-inspection-history>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      id="equipmentCalibrationsTabId"
      *ngIf="isEquipmentCalibrationsTabVisible()"
    >
      <ng-template kendoTabTitle>
        <span
          id="equipmentCalibrationsTabTitle"
          help-anchor="calibrationTabTitle"
        >
          Calibration
        </span>
      </ng-template>
      <ng-template kendoTabContent>
        <ups-equipment-calibrations
          [equipmentName]="equipmentResource.EquipmentName"
          [vpCompanyID]="equipmentResource.EquipmentCompany"
        ></ups-equipment-calibrations>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      id="ownershipInfoTabId"
      *ngIf="isOwnershipInfoTabVisible()"
    >
      <ng-template kendoTabTitle>
        <span id="ownershipInfoTabTitle" help-anchor="ownershipInfoTabTitle">
          Ownership Info
        </span>
      </ng-template>
      <ng-template kendoTabContent>
        <ups-equipment-ownership-info
          [keyId]="equipmentResource.KeyId"
        ></ups-equipment-ownership-info>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      id="rentalReleaseInfoTabId"
      *ngIf="isRentalReleaseInfoTabVisible()"
    >
      <ng-template kendoTabTitle>
        <span
          id="rentalReleaseInfoTabTitle"
          help-anchor="rentalLeaseInfoTabTitle"
        >
          Rental/Lease Info
        </span>
      </ng-template>
      <ng-template kendoTabContent>
        <ups-equipment-lease-rental-info
          [keyId]="equipmentResource.KeyId"
        ></ups-equipment-lease-rental-info>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      id="equipmentNotesTabId"
      *ngIf="isEquipmentNotesTabVisible()"
    >
      <ng-template kendoTabTitle>
        <span id="equipmentNotesTabTitle" help-anchor="equipmentNotesTabTitle">
          Equipment Notes
        </span>
      </ng-template>
      <ng-template kendoTabContent>
        <ups-equipment-notes
          *ngIf="isEquipmentNotesTabReadable()"
          [keyID]="equipmentResource.KeyId"
          [isEditable]="isEquipmentNotesTabEditable()"
        ></ups-equipment-notes>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      id="mechanicNotesTabId"
      *ngIf="isMechanicNotesTabVisible()"
    >
      <ng-template kendoTabTitle>
        <span id="mechanicNotesTabTitle" help-anchor="mechanicNotesTabTitle">
          Mechanic Notes
        </span>
      </ng-template>
      <ng-template kendoTabContent>
        <ups-equipment-notes
          *ngIf="isMechanicNotesTabReadable()"
          [keyID]="equipmentResource.KeyId"
          [mechanicNotes]="true"
          [isEditable]="isMechanicNotesTabEditable()"
        ></ups-equipment-notes>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>

  <!--Second Row-->
  <kendo-tabstrip
    id="lowerTabGroupId"
    class="default-bg"
    *ngIf="isVisibleLowerGroupTab()"
    [tabAlignment]="'left'"
    #lowerTabGroupId
  >
    <kendo-tabstrip-tab
      id="jobHistoryTabId"
      *ngIf="isJobHistoryTabVisible()"
      [selected]="true"
    >
      <ng-template kendoTabTitle>
        <span id="jobHistoryTabTitle" help-anchor="jobHistoryTabTitle">
          Job History
        </span>
      </ng-template>
      <ng-template kendoTabContent>
        <ups-equipment-job-history
          [equipmentName]="equipmentResource.EquipmentName"
          [vpCompanyID]="equipmentResource.EquipmentCompany"
        ></ups-equipment-job-history>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      id="equipmentRequestHistoryTabId"
      *ngIf="isEquipmentRequestHistoryTabVisible()"
    >
      <ng-template kendoTabTitle>
        <span
          id="equipmentRequestHistoryTabTitle"
          help-anchor="currentEquipmentRequestsTabTitle"
        >
          Current Equipment Requests
        </span>
      </ng-template>
      <ng-template kendoTabContent>
        <ups-current-equipment-request
          [equipmentName]="equipmentResource.EquipmentName"
          [vpCompanyID]="equipmentResource.EquipmentCompany"
        ></ups-current-equipment-request>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      id="fixedAssetHistoryTabId"
      *ngIf="isFixedAssetHistoryTabVisible()"
    >
      <ng-template kendoTabTitle>
        <span
          id="fixedAssetHistoryTabTitle"
          help-anchor="fixedAssetHistoryTabTitle"
        >
          Fixed Asset History
        </span>
      </ng-template>
      <ng-template kendoTabContent>
        <ups-equipment-fixed-asset-history
          [equipmentName]="equipmentResource.EquipmentName"
          [vpCompanyID]="equipmentResource.EquipmentCompany"
        ></ups-equipment-fixed-asset-history>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      id="equipmentLocationHistoryTabId"
      *ngIf="isLocationHistoryTabVisible()"
    >
      <ng-template kendoTabTitle>
        <span
          id="equipmentLocationHistoryTabTitle"
          help-anchor="locationHistoryTabTitle"
        >
          Location History
        </span>
      </ng-template>
      <ng-template kendoTabContent>
        <ups-equipment-location-history
          [equipmentName]="equipmentResource.EquipmentName"
          [vpCompanyID]="equipmentResource.EquipmentCompany"
        ></ups-equipment-location-history>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>
