import { nameOfFactory, parseData } from '@ups/xplat/utils';
import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  EarnCode,
  IODataResult,
  NameIdDescModel,
  TimekeepingCorrectionsDto,
  TimekeepingDto,
} from '@ups/xplat/api/dto';
import { ChangeDispatchStatusRequestDto } from '@ups/xplat/api/dto';
import { EmployeeTimeInOutDto } from '@ups/xplat/api/dto';
import { TimekeepingSearchDto } from '@ups/xplat/api/dto';
import { EmployeeNotesDto } from '@ups/xplat/api/dto';
import { MileageRateDto, MileageRateDtoCamel } from '@ups/xplat/api/dto';
import { DatePipe } from '@angular/common';
import { PayRateHistoryDto } from '@ups/xplat/api/dto';

const GET_EARN_CODES = '/api/timekeeping/earncodes';
const GET_TIMEKEEPING_BY_EMPLOYEE =
  '/api/timekeeping/employee/{:employeeID}/{:dateFrom}/{:dateTo}';
const GET_TIMEKEEPING_BY_JOB =
  '/api/timekeeping/job/{:jobID}/{:dateFrom}/{:dateTo}';
const GET_DISPATCH_BY_EMPLOYEE = '/api/timekeeping/dispatch/{:employeeID}';
const GET_PHASES = '/api/timekeeping/phases/{:jobID}';
const GET_ABSENT_REASONS = '/api/timekeeping/absentreasons';
const GET_TIMESHEET_DESIGNATIONS = '/api/timekeeping/timeSheetDesignations';
const SAVE_TIMEKEEPING_RECORDS = '/api/timekeeping';
const SAVE_TIMEKEEPING_AND_PER_DIEM_SPLIT = '/api/timekeeping/perDiemSplit';
const GET_BILLING_RATES = '/api/timekeeping/billingRates/{:companyID}';
const GET_JOB_TEMPLATE_HISTORY = '/api/equipment/templateHistory/{:jobID}';
const GET_DISPATCH_BY_JOB = '/api/timekeeping/dispatch/job/{:jobID}';
const GET_FOREMAN_CREWS = '/api/timekeeping/foremanCrews/{:jobID}';
const GET_EMPLOYEE_HOURS_PER_WEEK = '/api/timekeeping/employeeHoursPerWeek';
const CHANGE_DISPATCH_STATUS = '/api/timekeeping/changeDispatchStatus';
const CHANGE_DISPATCH_STATUSES = '/api/timekeeping/changeDispatchStatuses';
const GET_MILEAGE_RATES = '/api/timekeeping/mileageRates/{:jobID}';
const GET_TIME_IN_OUT_BY_JOB = '/api/timeinout/job/{:jobID}/{:date}';
const GET_TIME_IN_OUT_BY_EMPLOYEE =
  '/api/timeinout/employee/{:employeeID}/{:date}';
const SAVE_TIME_IN_OUT = '/api/timeInOut';
const GET_TIMEKEEPING_SEARCH_DATA = '/api/timekeeping/search/{:jobID}';
const REALLOCATE_TIMEKEEPING_RECORDS = '/api/timekeeping/reallocate';
const SAVE_EMPLOYEE_NOTES = '/api/timekeeping/employee/notes';
const GET_TIMEKEEPING_HISTORY = '/api/timekeeping/history/{:employeeLaborID}';
const GET_DISPATCH_PAY_RATE_HISTORY =
  '/api/timekeeping/history/dispatch/payrate/{:employeeJobID}';
const GET_LABOR_PAY_RATE_HISTORY =
  '/api/timekeeping/history/labor/payrate/{:employeeLaborID}';
const GET_TIMEKEEPING_CORRECTIONS_DATA = '/api/timekeeping/corrections';

@Injectable({ providedIn: 'root' })
export class TimekeepingService {
  protected spartaClient: HttpClient;
  protected spartaApiClient: HttpClient;
  protected nameOf = nameOfFactory<MileageRateDto>();
  protected http: HttpClient;

  constructor(injector: Injector, private clientFactory: MyHttpClientFactory) {
    const urlBase = environment.urls.spartaAPI;
    this.http = this.clientFactory.createHttpClient(
      urlBase,
      true,
      ResponseCasingEnum.PascalCase
    );
    this.spartaClient = this.clientFactory.createHttpClient(
      urlBase,
      true,
      ResponseCasingEnum.CamelCase
    );
    this.spartaApiClient = this.clientFactory.createHttpClient(
      urlBase,
      true,
      ResponseCasingEnum.PascalCase
    );
  }

  public getEarnCodes(vpCompanyID: number = null): Promise<EarnCode[]> {
    let url = GET_EARN_CODES;

    if (vpCompanyID) {
      url += `?vpCompanyID=${vpCompanyID}`;
    }

    return this.spartaClient.get(url).toPromise() as Promise<EarnCode[]>;
  }

  public queryTimekeepingByEmployeeData(
    employeeID: string,
    dateFrom: string,
    dateTo: string,
    odataString: string,
    shift: number = null,
    includeDispatched: boolean = false
  ): Observable<IODataResult<TimekeepingDto>> {
    let url = GET_TIMEKEEPING_BY_EMPLOYEE.split('{:employeeID}')
      .join(employeeID)
      .split('{:dateFrom}')
      .join(dateFrom)
      .split('{:dateTo}')
      .join(dateTo);

    url += '?$format=json&$count=true';

    if (shift) {
      url += `&shift=${shift}`;
    }

    if (includeDispatched === true) {
      url += `&includeDispatched=true`;
    }

    url += odataString ? '&' + odataString : '';

    console.log('Calling service: ', url);

    return this.http.get(url).pipe(map(parseData));
  }

  public queryTimekeepingByJobData(
    jobID: string,
    dateFrom: string,
    dateTo: string,
    odataString: string,
    employeeJobID: string = null,
    workOrder: string = null,
    shift: number = null,
    jobCrewID: string = null,
    showInactive: boolean = false
  ): Observable<IODataResult<TimekeepingDto>> {
    let url = GET_TIMEKEEPING_BY_JOB.split('{:jobID}')
      .join(jobID)
      .split('{:dateFrom}')
      .join(dateFrom)
      .split('{:dateTo}')
      .join(dateTo);

    url += '?$format=json&$count=true';

    if (shift) {
      url += `&shift=${shift}`;
    }

    if (employeeJobID) {
      url += `&employeeJobID=${employeeJobID}`;
    }

    if (workOrder) {
      url += `&workOrder=${escape(workOrder).replace('/', '%2F')}`;
    }

    if (showInactive === true) {
      url += `&includeInactive=true`;
    }

    if (jobCrewID) {
      url +=
        jobCrewID === 'all'
          ? `&includeDispatched=true`
          : `&jobCrewID=${jobCrewID}`;
    }

    url += odataString ? '&' + odataString : '';

    console.log('Calling service: ', url);

    return this.http.get(url).pipe(map(parseData));
  }

  public getDispatchByEmployee(employeeID: string) {
    const url =
      GET_DISPATCH_BY_EMPLOYEE.split('{:employeeID}').join(employeeID);

    return this.spartaClient.get(url).toPromise();
  }

  public getPhases(
    jobID: string,
    searchString: string,
    includeCosts: boolean = false,
    po: string = null,
    take: number = 0
  ) {
    let url = GET_PHASES.split('{:jobID}').join(jobID);

    url += '?take=' + take;
    if (searchString) url += '&searchString=' + escape(searchString);
    if (includeCosts === true) url += '&includeCosts=true';
    if (po) url += '&po=' + po;

    return this.spartaClient.get(url).toPromise();
  }

  public getAbsentReasons() {
    const url = GET_ABSENT_REASONS;

    return this.spartaClient.get(url).toPromise();
  }

  public getTimeSheetDesignations(): Promise<NameIdDescModel[]> {
    const url = GET_TIMESHEET_DESIGNATIONS;

    return this.spartaClient.get(url).toPromise() as Promise<NameIdDescModel[]>;
  }

  public saveTimekeepingRecords(dtos: TimekeepingDto[]) {
    return this.http.post(SAVE_TIMEKEEPING_RECORDS, dtos).toPromise();
  }

  public saveTimekeepingRecordsAndPerDiemSplit(dtos: TimekeepingDto[]) {
    return this.http
      .post(SAVE_TIMEKEEPING_AND_PER_DIEM_SPLIT, dtos)
      .toPromise();
  }

  public getBillingRates(
    companyID: string,
    template: string,
    jobClass: string,
    shift: number
  ) {
    let url = GET_BILLING_RATES.split('{:companyID}').join(companyID);

    url += `?template=${template}&jobClass=${jobClass}&shift=${shift}`;

    return this.spartaClient.get(url).toPromise();
  }

  public getJobTemplateHistory(jobID: string) {
    const url = GET_JOB_TEMPLATE_HISTORY.split('{:jobID}').join(jobID);

    return this.http.get(url).pipe(map(parseData)).toPromise();
  }

  public getDispatchByJob(
    jobID: string,
    showInactive: boolean = false,
    template: string = null
  ) {
    const url = GET_DISPATCH_BY_JOB.split('{:jobID}').join(jobID);

    let queryParams = '';
    if (showInactive === true) {
      queryParams += '?includeInactive=true';
    }

    if (template) {
      queryParams += `${queryParams.length ? '&' : '?'}template=${template}`;
    }

    return this.spartaClient.get(url + queryParams).toPromise();
  }

  public getForemanCrews(jobID: string) {
    const url = GET_FOREMAN_CREWS.split('{:jobID}').join(jobID);

    return this.spartaClient.get(url).toPromise();
  }

  public getEmployeeHoursPerWeek(
    date: string,
    employeeIDs: string[],
    jobID: string,
    shift: number = null
  ) {
    return this.http
      .post(GET_EMPLOYEE_HOURS_PER_WEEK, {
        /* eslint-disable @typescript-eslint/naming-convention */
        AnyDateInTheWeek: date,
        EmployeeIDs: employeeIDs,
        JobID: jobID,
        Shift: shift,
        /* eslint-enable @typescript-eslint/naming-convention */
      })
      .pipe(map(parseData))
      .toPromise();
  }

  public changeDispatchStatus(
    employeeJobID: string,
    newDispatchStatusID: number,
    date: string,
    rofNote: string
  ) {
    return this.http
      .post(CHANGE_DISPATCH_STATUS, {
        /* eslint-disable @typescript-eslint/naming-convention */
        EmployeeJobID: employeeJobID,
        NewDispatchStatusID: newDispatchStatusID,
        Date: date,
        ROFNote: rofNote,
        /* eslint-enable @typescript-eslint/naming-convention */
      } as ChangeDispatchStatusRequestDto)
      .toPromise();
  }

  public changeDispatchStatuses(
    rofChangeRequests: ChangeDispatchStatusRequestDto[]
  ) {
    return this.http.post(CHANGE_DISPATCH_STATUSES, rofChangeRequests);
  }

  public getMileageRates(jobID: string): Promise<MileageRateDtoCamel[]> {
    const url = GET_MILEAGE_RATES.split('{:jobID}').join(jobID);

    return this.spartaClient.get(url).toPromise() as Promise<
      MileageRateDtoCamel[]
    >;
  }

  public fetchMileageRatesOverlays(
    jobId: string,
    companyId: string,
    startDate: Date,
    endDate: Date | null,
    mileageRateId: number | null
  ): Observable<MileageRateDto[]> {
    let url = `/api/mileageRateOverlays?`;

    const queryParameters: string[] = [];
    const datePipe = new DatePipe('en-US');

    if (jobId) {
      queryParameters.push(`jobId=${jobId}`);
    }

    if (companyId) {
      queryParameters.push(`companyId=${companyId}`);
    }

    if (mileageRateId) {
      queryParameters.push(`mileageRateId=${mileageRateId}`);
    }

    if (endDate) {
      const endDateString = datePipe.transform(endDate, 'MM/dd/yyyy');
      queryParameters.push(`endDate=${endDateString}`);
    }

    const startDateString = datePipe.transform(startDate, 'MM/dd/yyyy');
    queryParameters.push(`startDate=${startDateString}`);

    url = url + queryParameters.join('&');

    return this.spartaApiClient.get(url) as Observable<MileageRateDto[]>;
  }

  public fetchMileageRatesOData(
    oDataString: string
  ): Observable<IODataResult<MileageRateDto[]>> {
    let url = '/api/mileageRates-odata?$format=json&$count=true';
    if (oDataString) {
      oDataString = oDataString.replace('T00:00:00.000Z', ''); // hack to enable date search - for some reason it is not taking such format into account so removing the time.
      oDataString = oDataString.replace('filter()/', ''); // hack bug when filter is combined with group by in certain scenarios

      // Hack to sort by date and time even if we use date only column in the grid (for grouping purposes by date)
      if (
        !oDataString.includes(
          `groupby((${this.nameOf('LastModifiedAtDateOnly')})`
        )
      ) {
        oDataString = oDataString.replace(
          `orderby=${this.nameOf('LastModifiedAtDateOnly')}`,
          `orderby=${this.nameOf('LastModifiedAt')}`
        );
      }

      url += '&' + oDataString;
    }

    return this.spartaApiClient.get(url) as Observable<
      IODataResult<MileageRateDto[]>
    >;
  }

  public saveMileageRate(
    mileageRateDto: MileageRateDto
  ): Observable<MileageRateDto> {
    const url = '/api/mileagerate';

    return this.spartaApiClient.post(
      url,
      mileageRateDto
    ) as Observable<MileageRateDto>;
  }

  public saveMileageRates(
    mileageRateDtos: MileageRateDto[]
  ): Observable<MileageRateDto[]> {
    const url = '/api/mileagerates';

    return this.spartaApiClient.post(url, mileageRateDtos) as Observable<
      MileageRateDto[]
    >;
  }

  public getTimeInOutByJobUri(
    jobID: string,
    date: string,
    odataString: string,
    shift: number = null,
    jobCrewID: string = null,
    showInactive: boolean = false
  ): string {
    const url = GET_TIME_IN_OUT_BY_JOB.split('{:jobID}')
      .join(jobID)
      .split('{:date}')
      .join(date);

    const queryParameters: string[] = [];

    if (shift) {
      queryParameters.push(`&shift=${shift}`);
    }

    if (showInactive === true) {
      queryParameters.push(`includeInactive=true`);
    }

    if (jobCrewID) {
      queryParameters.push(
        jobCrewID === 'all'
          ? `includeDispatched=true`
          : `jobCrewID=${jobCrewID}`
      );
    }

    if (odataString) {
      queryParameters.push('$format=json');
      queryParameters.push('$count=true');
      queryParameters.push(odataString);
    }

    return url + '?' + queryParameters.join('&');
  }

  getTimeInOutByJobUriInclServer(
    jobID: string,
    date: string,
    odataString: string,
    shift: number = null,
    jobCrewID: string = null,
    showInactive: boolean = false
  ): string {
    return (
      environment.urls.spartaAPI +
      this.getTimeInOutByJobUri(
        jobID,
        date,
        odataString,
        shift,
        jobCrewID,
        showInactive
      )
    );
  }

  public queryTimeInOutByJobData(
    jobID: string,
    date: string,
    odataString: string,
    shift: number = null,
    jobCrewID: string = null,
    showInactive: boolean = false
  ): Observable<IODataResult<EmployeeTimeInOutDto>> {
    const url = this.getTimeInOutByJobUri(
      jobID,
      date,
      odataString,
      shift,
      jobCrewID,
      showInactive
    );
    return this.http.get(url).pipe(map(parseData));
  }

  public getTimeInOutByEmployeeUri(
    employeeID: string,
    date: string,
    odataString: string,
    shift: number = null,
    showInactive: boolean = false
  ): string {
    const url = GET_TIME_IN_OUT_BY_EMPLOYEE.split('{:employeeID}')
      .join(employeeID)
      .split('{:date}')
      .join(date);

    const queryParameters: string[] = [];

    if (shift) {
      queryParameters.push(`&shift=${shift}`);
    }

    if (showInactive === true) {
      queryParameters.push(`includeInactive=true`);
    }

    if (odataString) {
      queryParameters.push('$format=json');
      queryParameters.push('$count=true');
      queryParameters.push(odataString);
    }

    return url + '?' + queryParameters.join('&');
  }

  getTimeInOutByEmployeeUriInclServer(
    employeeID: string,
    date: string,
    odataString: string,
    shift: number = null,
    showInactive: boolean = false
  ): string {
    return (
      environment.urls.spartaAPI +
      this.getTimeInOutByEmployeeUri(
        employeeID,
        date,
        odataString,
        shift,
        showInactive
      )
    );
  }

  public queryTimeInOutByEmployeeData(
    employeeID: string,
    date: string,
    odataString: string,
    shift: number = null,
    showInactive: boolean = false
  ): Observable<IODataResult<EmployeeTimeInOutDto>> {
    const url = this.getTimeInOutByEmployeeUri(
      employeeID,
      date,
      odataString,
      shift,
      showInactive
    );
    return this.http.get(url).pipe(map(parseData));
  }

  public saveTimeInOut(dtos: EmployeeTimeInOutDto[]) {
    return this.http.post(SAVE_TIME_IN_OUT, dtos).toPromise();
  }

  public queryTimekeepingSearchData(
    jobID: string,
    odataString: string,
    dateFrom: string = null,
    dateTo: string = null,
    po: string = null,
    phase: string = null
  ): Observable<IODataResult<TimekeepingSearchDto>> {
    let url = GET_TIMEKEEPING_SEARCH_DATA.split('{:jobID}').join(jobID);

    url += '?$format=json&$count=true';

    if (dateFrom) {
      url += `&dateFrom=${dateFrom}`;
    }

    if (dateTo) {
      url += `&dateTo=${dateTo}`;
    }

    if (po) {
      url += `&po=${escape(po).replace('/', '%2F')}`;
    }

    if (phase) {
      url += `&phase=${escape(phase).replace('/', '%2F')}`;
    }

    url += odataString ? '&' + odataString : '';

    console.log('Calling service: ', url);

    return this.http.get(url).pipe(map(parseData));
  }

  public reallocateTimekeepingRecords(dtos: TimekeepingSearchDto[]) {
    return this.http.post(REALLOCATE_TIMEKEEPING_RECORDS, dtos).toPromise();
  }

  public saveEmployeeNotes(dto: EmployeeNotesDto) {
    return this.http
      .post(SAVE_EMPLOYEE_NOTES, dto)
      .pipe(map(parseData))
      .toPromise();
  }

  public getTimekeepingHistory(employeeLaborID: string) {
    const url =
      GET_TIMEKEEPING_HISTORY.split('{:employeeLaborID}').join(employeeLaborID);

    return this.spartaClient.get(url).toPromise();
  }

  public getDispatchPayRateHistory(
    employeeJobID: string
  ): Promise<PayRateHistoryDto[]> {
    const url =
      GET_DISPATCH_PAY_RATE_HISTORY.split('{:employeeJobID}').join(
        employeeJobID
      );

    return this.spartaClient.get(url).toPromise() as Promise<
      PayRateHistoryDto[]
    >;
  }

  public getLaborPayRateHistory(
    employeeLaborID: string
  ): Promise<PayRateHistoryDto[]> {
    const url =
      GET_LABOR_PAY_RATE_HISTORY.split('{:employeeLaborID}').join(
        employeeLaborID
      );

    return this.spartaClient.get(url).toPromise() as Promise<
      PayRateHistoryDto[]
    >;
  }

  public queryTimekeepingCorrectionsData(
    companyIDs: number[],
    jobIDs: string[],
    odataString: string,
    dateFrom: string = null,
    dateTo: string = null,
    allData: boolean = false
  ): Observable<IODataResult<TimekeepingCorrectionsDto>> {
    let url = GET_TIMEKEEPING_CORRECTIONS_DATA;

    url += '?$format=json&$count=true';

    if (allData) {
      url += `&allData=true`;
    }

    if (companyIDs && companyIDs.length) {
      companyIDs.forEach((i) => {
        url += `&companyIDs=${i}`;
      });
    }

    if (jobIDs) {
      jobIDs.forEach((i) => {
        url += `&jobIDs=${i}`;
      });
    }

    if (dateFrom) {
      url += `&dateFrom=${dateFrom}`;
    }

    if (dateTo) {
      url += `&dateTo=${dateTo}`;
    }

    url += odataString ? '&' + odataString : '';

    console.log('Calling service: ', url);

    return this.http.get(url).pipe(map(parseData));
  }
}
