/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Observable, Subscription } from 'rxjs';

import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  State,
} from '@progress/kendo-data-query';

import { FilterUtils } from '../../Data-Query/filter-utils';

import * as _ from 'lodash';
import { ODataExSettings } from '@ups/xplat/features';

/**
 *  this.viewModel = new GroupingExViewModel(
        odataQuery => this.hrrmService.queryEmployeeData(odataQuery, this.gridFilterText),
        null // we can use null as we don't do selection state restoration
        )
        .configure(config => {
            config.loadDataOnInit = false;
        });
 */
export class GroupingExViewModel {
  public loadDataOnInit = true;

  public loadGroupsByLevels = true;
  public loadAllItemsToGroups = false;

  public autoExpandGroupOnSelection = false;

  public linkedAggregateFields: string[][] = []; // = [ ['VPJobID', 'JobName'], ['PlantID', 'PlantName'] ];
  public customODataAggregateExpression: string = undefined; // = "HasEmployee with sum as nonEmptyCount"

  public loadAllItemsUntilCountOf = 25;

  public extraFilter: FilterDescriptor | CompositeFilterDescriptor;
  public extraFilterLogic: 'and' | 'or' = 'and';

  public oDataSettings: ODataExSettings;
  public oDataOpenTypesFieldName: string;

  public loadDataSubscription: Subscription;

  constructor(
    private dataLoadObservableCallback: (odataQuery: string) => Observable<any>,
    private equalityComparerCallback: (left: any, right: any) => boolean
  ) {
    // Ctor.
  }

  public getDataLoadObservable(odataQuery: string): Observable<any> {
    return this.dataLoadObservableCallback(odataQuery);
  }

  public getEqualityComparer(): (left: any, right: any) => boolean {
    return this.equalityComparerCallback;
  }

  public configure(
    configureCallback: (config: GroupingExViewModel) => void
  ): GroupingExViewModel {
    configureCallback(this);
    return this;
  }

  public createStateWithExtraFilter(state: State): State {
    const stateCopy = _.cloneDeep(state) as State;
    FilterUtils.replaceFilter(
      stateCopy,
      FilterUtils.concatFilters(
        stateCopy.filter,
        this.extraFilter,
        this.extraFilterLogic
      )
    );
    return stateCopy;
  }
}
