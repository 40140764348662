import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UPSTextFilter } from './text-filter.component';
import { FormsModule } from '@angular/forms';
import { UIModule } from '../../../ui/ui.module';

const components = [UPSTextFilter];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [CommonModule, FormsModule, UIModule],
})
export class TextFilterModule {}
