/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import { JobSafetyCouncilDto } from './JobSafetyCouncilDto';
import { JobContactDto } from './JobContactDto';
import { EquipmentPlantJobRequirementDto } from './equipment/EquipmentPlantJobRequirementDto';
import { BaseModel } from '../base.model';
import { ManpowerAttachmentModel } from './manpower-attachment-model';
import { JobDto } from './JobDto';

export class ManPowerReqDto extends BaseModel {
  public ManPowerReqID: string;
  public JobID: string;
  public VPJobID: string;
  public JobName: string;
  public VPCompanyID: number;
  public bActive: boolean;
  public PlantID: number;
  public PlantName: string;
  public Description: string;
  public EmployeeJobDescription: string;
  public BadgeLeadDays: number;
  public SafetyCouncils: JobSafetyCouncilDto[];
  public Contacts: JobContactDto[];
  public Attachments: ManpowerAttachmentModel[];
  public Requirements: any[];
  public bUseJobRequirements: boolean;
  public EquipmentRequest: any;
  public EquipmentRequirements: EquipmentPlantJobRequirementDto[];
  public Job: JobDto;
  public Plant: any;
  public OverflowSTLimit: number;
  public OverflowOTLimit: number;
  public OverflowRules: string;
  public bRequiresLodging: boolean;
  public bInternalEvent: boolean;
  public BadgeRequest: string | null;
  public DayShiftStart: Date | null;
  public DayShiftEnd: Date | null;
  public NightShiftStart: Date | null;
  public NightShiftEnd: Date | null;
  public Gate: string;
  public PerDiemID: string | null;
  public MileageID: string | null;
  public BadgeInfo: string;
  public MappingPlatform: string;

  constructor(o: any = null) {
    super(o);
  }
}
