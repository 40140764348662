import { environmentBase } from './environment.base';
import { IEnvironmentConfiguration } from '../environment.interface';
import { deepMerge } from '@ups/xplat/utils';

export const environmentProd = deepMerge(environmentBase, <
  IEnvironmentConfiguration
>{
  // customizations here...
  production: true,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  environment_name: 'production',
  logging: {
    defaultLogLevel: 0x0200,
  },
  appsync: {
    production: true,
    android: {
      productionKey: process.env['AppSync:AndroidKey@prod'],
      stagingKey: process.env['AppSync:AndroidKey@stg'],
    },
    ios: {
      productionKey: process.env['AppSync:IosKey@prod'],
      stagingKey: process.env['AppSync:IosKey@stg'],
    },
    version: '0.0.1',
    serverUrl: process.env['Urls:AppSyncServerUrl'],
  },
  smartlook: {
    webApiKey: process.env['Smartlook:WebApiKey'],
    mobileApiKey: process.env['Smartlook:MobileApiKey'],
  },
  uxcam: {
    apiKey: process.env['UxCam:ApiKey'],
  },
  sockets: {
    signalR: {
      url: process.env['Urls:NotificationHub@prod'],
    },
  },
  name: 'safety',
  displayName: '',
  version: '',
  auth0: {
    domain: process.env['Auth0:Domain'],
    audience: process.env['Auth0:Audience'],
    callbackPath: 'callback',
    clientId: null,
  },
  syncfusionLicenseKey: process.env['SyncFusion:LicenseKey@prod'],
  security: {
    allowEverything: false,
    securityOverride: false,
    securityOverrideJson: 'environments/security.json',
    apiUrl: process.env['Urls:SecurityApiAzure@prod'],
    appId: null,
  },
  urls: {
    azureAPI: process.env['Urls:AzureApi@prod'],
    sparta: process.env['Urls:Sparta@prod'],
    spartaAPI: process.env['Urls:SpartaApi@prod'],
    viewpointAPI: process.env['Urls:ViewpointApi@prod'],
    packetAPI: process.env['Urls:PacketApi@prod'],
    securityAPI: process.env['Urls:SecurityApiAzure@prod'],
    softwareSecurityAPI: process.env['Urls:SoftwareSecurityApi@prod'],
    fileManagementAPI: process.env['Urls:FileManagementApi@prod'],
    tasksAPI: process.env['Urls:TasksApi@prod'],
    safetyAPI: process.env['Urls:SafetyApi@prod'],
    ratesAPI: process.env['Urls:RatesApi@prod'],
    toolTrackerAPI: process.env['Urls:ToolTrackerApi@prod'],
    reportsServer: process.env['Urls:ReportsServer@prod'],
    dynamicAPI: process.env['Urls:DynamicApi@prod'],
  },
  host: process.env['Urls:HseToolBoxHostUrl@prod'],
  blobStorage: {
    url: process.env['Urls:AzureBlobStorageUrl'],
    container: process.env['AzureBlobStorage:ContainerName@prod'],
  },
});
