<!--
  best sample to start with is: https://adminlte.io/themes/AdminLTE/index2.html as it has both shifting/overlaying aside menus
  and https://coreui.io/demo/#main.html - which is more "fluent" (like header always on top, side-menus scrollable, etc...)
  and https://bootstrapious.com/p/bootstrap-sidebar & https://codepen.io/JFarrow/pen/fFrpg for minimized "hover" menu...

  see layout.scss on more information about the current layout-setup
-->
<div class="overlay d-none"></div>

<div class="layout-wrapper">
  <div class="layout-content-wrapper">
    <aside
      #sidebarLeft
      class="sidebar sidebar-left sidebar-minimize"
      [class.open]="isSidebarExpanded"
    >
      <div class="sidebar-content-wrapper">
        <div
          *ngIf="!isSidebarExpanded"
          class="sidebar-content"
          kendoTooltip
          position="right"
          [offset]="0"
        >
          <ng-container *ngTemplateOutlet="menuContent"></ng-container>
        </div>
        <div *ngIf="isSidebarExpanded" class="sidebar-content">
          <ng-container *ngTemplateOutlet="menuContent"></ng-container>
        </div>
      </div>
    </aside>

    <main>
      <article class="content">
        <router-outlet></router-outlet>
      </article>
    </main>
  </div>
</div>

<ng-template #menuContent>
  <ups-kendo-panel-bar-menu
    [title]="applicationName"
    [displayName]="displayName"
    (sidebarToggled)="toggleSidebarStatus()"
  ></ups-kendo-panel-bar-menu>
  <div class="version-footer d-flex justify-content-center align-items-center">
    <div
      class="d-flex align-items-center flex-column"
      title="Show Latest Release Notes"
      upsShowReleaseButton
    >
      <p class="small mb-0">
        {{ version }}
      </p>
      <p class="small mb-0">
        {{ buildNumber }}
      </p>
      <div *ngIf="!environment.production">
        ({{ environment.environment_name }})
      </div>
    </div>

    <div>
      <!-- Pin extended side bar -->
      <div *ngIf="isSidebarExpanded" (click)="togglePinSidebar()">
        <i
          style="cursor: pointer; transform: translateX(40px) rotate(45deg)"
          [style.color]="isSidebarPinned ? '#00275d' : '#d4d0d0'"
          class="fa fa-thumbtack"
        ></i>
      </div>
    </div>
  </div>
</ng-template>

<ups-help-editor></ups-help-editor>
<ups-help-desk-modal></ups-help-desk-modal>
<ups-walkthrough-player></ups-walkthrough-player>
<ups-release-checker></ups-release-checker>
