import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EquipmentResourceDto } from '@ups/xplat/api/dto';
import { BaseComponent } from '@ups/xplat/core';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';

@Component({
  selector: 'ups-equipment-basic-info',
  templateUrl: './equipment-basic-info.component.html',
})
export class EquipmentBasicInfoComponent extends BaseComponent {
  @Input() vpEquipment: EquipmentResourceDto;
  @Input() showEquipmentEditButton = true;
  @Output() switchEquipment = new EventEmitter<void>();
  canSeeEquipmentHyperlink = false;

  constructor(private security: SecurityService) {
    super();
    this.canSeeEquipmentHyperlink = this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_equipmentresourcehyperlink
    ).readAll;
  }
}
