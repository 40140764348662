import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { EditorComponentBase } from '../editor-component-base';

@Component({
  selector: 'editor-form-radio',
  templateUrl: './editor-form-radio.component.html',
})
export class EditorFormRadioComponent extends EditorComponentBase implements OnInit {
  public peLabel = '';
  public peOptions: string[] = [];

  public constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  public ngOnInit() {
    if (this.data) {
      this.peLabel = this.data['label'];
      this.peOptions = ((this.data['options'] as any) as string[]) || [];
    }
  }

  removeItemFromArr(arr: any[], idx: number) {
    arr.splice(idx, 1);
  }

  addBlankRadio() {
    this.peOptions = [...this.peOptions, ''];
    this.cdr.detectChanges();
  }

  public onApplyClick() {
    const changesObj = {
      label: this.peLabel,
      options: this.peOptions,
    };

    console.log('changesObj', changesObj);

    this.applyChanges.emit(changesObj);
  }

  public changePropertyDescriptor(ev: any, idx: number): void {
    this.peOptions[idx] = ev.target.value;
  }
}
