import { Component, NgZone } from '@angular/core';

import {
  LogService,
  NetworkService,
  OfflineHttpTrackingService,
  WindowService,
} from '@ups/xplat/core';
import { OfflineBannerBaseComponent } from '@ups/xplat/features';

@Component({
  selector: 'ups-offline-banner',
  templateUrl: 'offline-banner.component.html',
})
export class OfflineBannerComponent extends OfflineBannerBaseComponent {
  constructor(
    network: NetworkService,
    win: WindowService,
    ngZone: NgZone,
    log: LogService,
    offlineHttpTracking: OfflineHttpTrackingService
  ) {
    super(network, win, ngZone, log, offlineHttpTracking);
  }
}
