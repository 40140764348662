import { BaseModel } from '../base.model';

export class ProspectiveJobDto extends BaseModel {
  public JobID: string;
  public VPJobID: string;
  public Name: string;
  public VPCompanyID: number;
  public IsActive: boolean;
  public ActStartDate: Date;
  public ActEndDate: Date;
  public PlantID: number;
  public PlantName: string;
  public ProjectMgrHRRef: number;
  public OperationsMgrHRRef: number;
  public ProjectMgr: string;
  public OperationsMgr: string;
  public bJobRequirements: boolean;

  constructor(o: any = null) {
    super(o);
  }
}
