import { BaseModel } from '../../base.model';

export class PayrollNoteDto extends BaseModel {
  public Notes: string;
  public LineID: string;

  constructor(o: any = null) {
    super(o);
  }
}
