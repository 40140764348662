import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EquipmentResourceDto } from '@ups/xplat/api/dto';
import { EquipmentRequestOverlapDto } from '@ups/xplat/api/dto';

@Component({
  selector: 'ups-equipment-resource-confirm',
  templateUrl: './equipment-resource-confirm.component.html',
})
export class EquipmentResourceConfirmComponent {
  @Input() equipmentResourceDto: EquipmentResourceDto;
  @Input() overlappingRequests: EquipmentRequestOverlapDto[];
  @Input() showWarning: boolean = true;
  @Input() showButtons: boolean = true;
  @Output() confirmEquipment = new EventEmitter();
  @Output() discardEquipment = new EventEmitter();

  constructor() {}
}
