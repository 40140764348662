import { Directive, ElementRef, OnInit, Input, Output, EventEmitter } from '@angular/core';

declare let jQuery: any;

@Directive({
  selector: 'widget',
})
export class Widget implements OnInit {
  $el: any;
  @Input() options: any;
  @Output() collapsed = new EventEmitter();
  @Output() expanded = new EventEmitter();

  constructor(el: ElementRef) {
    const self = this;
    this.$el = jQuery(el.nativeElement);
    jQuery.fn.widgster.Constructor.DEFAULTS.bodySelector = '.widget-body';

    /*
         When widget is closed remove its parent if it is .col-*
         */
    jQuery(document).on('close.widgster', (e) => {
      const $colWrap = jQuery(e.target).closest('.content .row [class*="col-"]:not(.widget-container)');

      // remove colWrap only if there are no more widgets inside
      if (!$colWrap.find('.widget').not(e.target).length) {
        $colWrap.remove();
      }
    });

    this.$el.on('collapse.widgster', function () {
      self.collapsed.emit({ value: true });
    });

    this.$el.on('expand.widgster', function () {
      self.expanded.emit({ value: true });
    });
  }

  ngOnInit(): void {
    if (this.options) {
      this.$el.widgster(this.options);
    } else {
      this.$el.widgster();
    }
  }
}
