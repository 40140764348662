import { Observable } from 'rxjs';
import { ValidatorFn, UntypedFormGroup } from '@angular/forms';
// eslint-disable-next-line
import { EmailQueueDto } from '@ups/xplat/api/dto';

/* eslint-disable */
// NOTE: we can reenable eslint once mix-casing on properties if 100% consistent between frontend and backend
export interface IDynamicModelOptions {
  // hide by default
  hidden?: boolean;
  // ignore any label above the control
  labelIgnore?: boolean;
  // display label inline next to control instead of default above it
  labelInline?: boolean;
  // use a soft style label (not bold and dominant)
  labelSoft?: boolean;
  // checkbox: disable the named formControlName when checked
  disableFormControlName?: string;
  // checkbox: show the hidden formControlName when checked
  hiddenFormControlName?: string;
  // checkbox: show the formControlName when unchecked - otherwise hide it
  uncheckedFormControlName?: string;
  // checkbox: explicitly check
  checked?: boolean;
  // tooltip (often used with web hover)
  tooltip?: string;
  // shows in (i) badge next to the label so user sees there is a tooltip and can hower the badge or the label to get the tooltip message
  showTooltipIcon?: boolean;
  // custom style attributes
  style?: {
    button?: Partial<CSSStyleDeclaration>;
    container?: Partial<CSSStyleDeclaration>;
    label?: Partial<CSSStyleDeclaration>;
  };
  // custom layout
  layout?: IDynamicControlLayout;
  // collection of custom validations used throughout various dynamic controls
  customValidations?: Array<DynamicCustomValidationTypes>;
  // max character length
  maxlength?: number;
  // number and numerictextbox: can set a decimal range for precision
  decimals?: number;
  // date: minimum date
  minDate?: Date;
  // date: show time picker
  showTimePicker?: boolean;
  // typeahead: api options
  api?: {
    host?: string;
    endpoint?: string;
    model?: IApiModelType;
    properties?: string; // comma separated properties (data returned from api)
  };
  // typeahead: show id as a prefix on each drop down option label
  displayIdPrefix?: boolean;
  // typeahead: explicit data service resolver
  dataResolver?: () => Promise<unknown>;
  // typeahead: manual collection of options
  dropdownOptions?: Array<unknown>;
  // array of controls to update based on the value change.
  updateOnValueChange?: Array<IDynamicUpdateOnValueChange>;
  // typeahead: scope a typeahead query based on the value of another formControlName
  scopeQueryWhenFormControlIsSet?: {
    formControlName?: string;
    matchTargetProperty?: string;
    queryParamName?: string;
  };
  // typeahead: initial query to make when initializing the control
  initWithQuery?: string;
  typeahead?: {
    matchTargetProperty?: string;
    /** When true the target typeahead control value is set to null if no matching value was found in searchQuery for the matchTargetProperty.
     * This is useful when using updateOnValueChange.calculatedPropertyValue property and there was no candidate found to select based on the calculatedPropertyValue.
     */
    setNullValueIfNotFoundInSearch?: boolean;
  };
  // file: specific directory to use
  directory?: string;
  // model is a primary control (mostly used with buttons)
  primary?: boolean;
  // model is a submit control (mostly used with buttons)
  submit?: boolean;
  // show a cancel button alongside the submit/save button
  showCancelButton?: boolean;
  // show a delete draft button alongside the submit/save button
  showDeleteDraftButton?: boolean;
  // show a draft button alongside the submit/save button
  showDraftButton?: boolean;
  // accordion rollups of multiple models
  accordion?: Array<IDynamicModel>;
  accordionCloseOthers?: boolean;
  accordionHasError?: boolean;
  accordionOpen?: boolean;
  accordionRequiredValues?: string;
  accordionRequiredValuesApplyToAll?: boolean;
  accordionRequiredValueRequiresAnother?: string;
  // this is used with accordions right now to specify a min limit on what can be viewed as acceptable with filling out controls marked as required
  // not prefixed with 'accordion' as this option could be used with other setups
  minimumFieldsRequired?: number;
  // buttongroup
  buttongroup?: Array<IDynamicButtonGroup>;
  // buttongroup should ignore selected states
  buttonGroupIgnoreState?: boolean;
  // explicitly emit an eventBus dynamicDataUpdate event when change occurs on this control
  emitEventOnChange?: boolean;
  // show any formControlName when the control with this setting gets the listed values
  showOnValues?: {
    formControlName: string;
    dynamicControlName?: string;
    values: Array<string | number | boolean>;
  };
  // indicates if the control was combined with another to modify behavior of found control
  valueMatchFound?: boolean;
  // allow multiple file attachments
  fileAllowMultiple?: boolean;
  // used when a control is used outside of dynamic groupings, will remove default group margins, etc.
  standalone?: boolean;
  // admin builder specific options
  admin?: {
    allowDelete?: boolean;
  };

  signature?: IDynamicSignaturePadOptions;

  isMultiSelect?: boolean;
  isReusable?: boolean;
  dynamicContainerId?: string;
  dynamicContainerResponseId?: string;
  isEditReview?: boolean;
  isReadOnly?: boolean;
  reviewId?: string;
  formPath?: string;
  deleteComment?: string;
  submittedBy?: string;
  submittedUserId?: string;
  // employee: use employee endpoint grouped by HRRef
  distinctHRRef?: boolean;
  pointToViewpoint?: boolean;
  valueQueryParamName?: string;
  shortName?: string;
}

/**
 * Item returned by an API by DataResolver can be mapped to IApiModelType so it is searchable
 */
export interface IApiModelType {
  id: string | number;
  name: string | number;
  description?: string | number;
  active?: string | number;
}

/**
 * Items returned from API by dataResolver() are searchable if they are of this interface.
 * It they are not and you want to still make them searchable convert them using IDynamicModel.options.api.model
 */
export interface IApiModelSearchable extends Partial<IApiModelType> {
  Id?: string | number;
  Name?: string | number;
  Description?: string | number;
  Active?: string | number;
}

// Validations which are dynamic control type specific can prefix their name with the type
export type DynamicCustomValidationTypes =
  | 'date: less than current'
  | 'date: greater than current'
  | 'date: greater than past 7 days';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const DynamicCustomValidations: Array<DynamicCustomValidationTypes> = [
  'date: less than current',
  'date: greater than current',
  'date: greater than past 7 days',
];

export type DynamicModelType =
  | 'input'
  | 'text'
  | 'textarea'
  | 'date'
  | 'file'
  | 'number'
  | 'phone'
  | 'checkbox'
  | 'email'
  | 'html-text'
  | 'label'
  | 'last-modified'
  | 'button'
  | 'numerictextbox'
  | 'addressautocomplete'
  | 'typeahead'
  | 'typeahead-autocomplete'
  | 'typeahead-category'
  | 'typeahead-company'
  | 'typeahead-customers'
  | 'typeahead-employee'
  | 'typeahead-job'
  | 'typeahead-facilities'
  | 'typeahead-icons'
  | 'typeahead-serial-number'
  | 'typeahead-sublocation'
  | 'typeahead-material'
  | 'typeahead-item'
  | 'typeahead-manufacturer'
  | 'typeahead-sales-order'
  | 'target'
  | 'target-loading'
  | 'accordion'
  | 'buttongroup'
  | 'chart'
  | 'circle'
  | 'image'
  | 'radio'
  | 'safetyatrisk'
  | 'signature'
  | 'coaching'
  | 'review'
  | 'copyLinkButton'
  | 'comment'
  | 'redacted'
  | 'dataLabels';
// the dynamic builder allows for extra custom types to control custom property behavior
export type DynamicPropertyModelType =
  | DynamicModelType
  | 'typeahead-all'
  | 'all';
// list of controls that are allowed to be inside accordions:
export const dynamicAccordionControls: Array<DynamicModelType> = [
  'input',
  'textarea',
  'date',
  'file',
  'checkbox',
  'buttongroup',
  'typeahead',
  'typeahead-autocomplete',
  'typeahead-company',
  'typeahead-customers',
  'typeahead-employee',
  'typeahead-facilities',
  'typeahead-job',
  'typeahead-icons',
  'typeahead-serial-number',
  'typeahead-sublocation',
  'typeahead-item',
  'typeahead-manufacturer',
  'typeahead-sales-order',
];
export type IDynamicInputType =
  | 'email'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'text'
  | 'url';
// various controls that never need to be tracked with FormGroups
// for example, auxiliary UI elements in the form structure
export const dynamicFormGroupExcludedControlNames = ['copyLinkButton'];

export interface IDynamicModel {
  clientId?: string;
  formControlName?: string;
  formArrayName?: string;
  reportCategoryName?: string;
  label?: string;
  instruction?: string;
  placeholder?: string;
  value?: string | number | boolean | Date;
  valueProperty?: string;
  ignoreValue?: boolean;
  required?: boolean;
  disabled?: boolean;
  regex?: RegExp;
  // not used directly in FormBuilder but used to populate according to other options
  validators?: ValidatorFn | Array<ValidatorFn>;
  type: DynamicModelType;
  inputType?: IDynamicInputType;
  order?: number;
  options?: IDynamicModelOptions;
  selected?: boolean;
  isFormBuilder?: boolean;
  isReusable?: boolean;
  readOnly?: boolean;
  accessRoleIds?: string;
}
export type IDynamicUpdateOnValueChangeValidationTypes = 'maxDate' | 'minDate';
export interface IDynamicUpdateOnValueChange {
  formControlName?: string;
  property?: string;
  matchTargetProperty?: string;
  query?: string;
  // can enforce validation types on other controls based on value of the control defining this option
  validation?: IDynamicUpdateOnValueChangeValidationTypes;
  // Can be used to calculate the value based on the selected value and other values in the form which can be then passed to matchTargetProperty of the formControlName.
  // Useful for example if CompanyDropDown changes calculate based on the CompanyId and facility location (other field in the form) the employee id and pass it to formControlName.matchTargetProperty
  calculatedPropertyValue?: (
    selectedValue: unknown,
    config: IDynamicModel,
    group: UntypedFormGroup
  ) => Observable<string | number>;
}

// all top level properties of each dynamic model: useful for admin builder
export const dynamicModelBaseLevelKeys: Array<keyof IDynamicModel> = [
  'clientId',
  'formControlName',
  'formArrayName',
  'reportCategoryName',
  'label',
  'instruction',
  'placeholder',
  'value',
  'valueProperty',
  'ignoreValue',
  'required',
  'disabled',
  'type',
  'inputType',
  'order',
  'options',
  'selected',
  'isReusable',
  'readOnly',
  'accessRoleIds',
];
export const dynamicModelNonNullableKeys: Array<keyof IDynamicModel> = [
  'required',
  'isReusable',
];
// dynamic types which utlize formArrayName instead of formControlName
export const dynamicModelFormArrayTypes: Array<DynamicModelType> = [
  'accordion',
];
// admin builder properties can contain custom button actions
// collection of admin formControlNames used for 'button' types in admin
export const dynamicAdminButtonControlNameActions = [
  'AddItemToButtonGroup',
  'AddUpdateOnValueChange',
];

export const dynamicTypesUnsupportedOffline: Array<DynamicModelType> = ['file'];

export interface IDynamicModelProperties extends IDynamicModel {
  types?: Array<DynamicPropertyModelType>;
  // useful when needing to use a catch-all in 'types' while excluding particular ones
  // for example: 'typeahead-all' will match for all typeaheads, however you can exclude some if needed
  typesExclusions?: Array<DynamicPropertyModelType>;
  isAdvanced?: boolean;
}

export interface IDynamicButtonGroup {
  label?: string;
  value?: string | number;
  selected?: boolean;
  selectedColor?: string;
  dynamicControlLabel?: string;
}

export interface IDynamicDisplayDate {
  day: string | number;
  month: string | number;
  year: string | number;
  hour?: string | number;
  minute?: string | number;
  second?: string | number;
}

export interface IDynamicDatePicker {
  enable: boolean;
  isRoot?: boolean;
  date?: Date;
  fieldId?: string;
  formControlName?: string;
  selectedDate?: string | number | Date;
  showTime?: boolean;
  displayDate?: IDynamicDisplayDate;
  minDate?: Date;
}

// various settings that can be returned for component settings when registering new controls dynamically
export interface IDynamicRegisterControl {
  valueObjectId?: string | number | Date;
}

export interface IDynamicModelGroup {
  groupType: string;
  groupName: string;
  groupDescription?: string;
  controls: IDynamicModel[];
}

export interface IDynamicContainer {
  metadata?: IDynamicContainerMetadata;
  groups: IDynamicModelGroup[];
}

export interface IDynamicActiveGroupSettings {
  accordionApplyAll?: string /* formArrayName of accordion used to apply all */;
  accordionStatusComplete?: boolean /* whether the override has been found to validate */;
}

export interface IDynamicControlLayout {
  row?: number;
  col?: number;
  sizeX?: number;
  sizeY?: number;
  colSpan?: number;
}

export interface IDynamicTag {
  tagId?: string;
  tagName?: string;
  icon?: string;
  visible?: Array<string>;
}

export interface IDynamicTagDisplay {
  Id?: string;
  Name: string;
  tagName: string;
  icon?: string;
  visible?: string[];
}

export interface IDynamicContainerMetadata {
  dynamicContainerName?: string;
  dynamicContainerDescription?: string;
  originalVersionContainerId?: string;
  dynamicContainerWhatsNew?: string;
  displayIcon?: string;
  displayOrder?: { [key: string]: number };
  instructions?: string;
  isActive?: boolean;
  dynamicContainerId?: string;
  containerVersionId?: string;
  formId?: string;
  versionNumber?: number;
  createdDate?: string;
  modifiedDate?: string;
  modifiedBy?: string;
  createdBy?: string;
  isPublished?: boolean;
  tags?: Array<IDynamicTag>;
  isDirty?: boolean;
  isUsedForParticipation?: boolean;
  dynamicControls?: Array<IDynamicControl>;
  // (UI only) used for scoping drop down items when browsing all versions of same dynamicContainerId (helps provide a proper unique id)
  dynamicContainerIdAndVersion?: string;
  url?: string;
}

export interface IDynamicControl {
  createdBy?: string;
  createdDate?: string;
  dynamicControlId?: string;
  dynamicControlInstruction?: string;
  dynamicControlLabel?: string;
  dynamicControlName?: string;
  dynamicReportCategoryName?: string;
  dynamicControlType?: DynamicModelType;
  dynamicControls?: Array<IDynamicControl>;
  accessRoleIds?: string;
  inputType?: IDynamicInputType;
  isActive?: boolean;
  isPublished?: boolean;
  isReusable?: boolean;
  modifiedBy?: string;
  modifiedDate?: string;
  options: IDynamicModelOptions;
  order?: number;
  originalVersionControlId?: string;
  parentDynamicControlId?: string;
  placeholder?: string;
  reportCategoryName?: string;
  required?: boolean;
  validators?: ValidatorFn | Array<ValidatorFn>;
  value?: string | number | boolean;
  valueProperty?: string;
  versionNumber?: number;
  formArrayName?: string;
  disabled?: boolean;
  readOnly?: boolean;
  isFormBuilder?: boolean;
}

// Dynamic builder helpers
export interface IDynamicBuilderState {
  accordion?: { open?: { [key: string]: boolean } };
}

// Dynamic response (saving of user input from dynamic containers)
export interface IDynamicControlResponse {
  dynamicControlResponseId?: string;
  dynamicContainerResponseId?: string;
  dynamicControlId?: string;
  responseContent: string;
  responseContentJson?: string;
  isActive?: boolean;
  isFinal?: boolean;
  modifiedDate?: string;
  modifiedBy?: string;
  dynamicControlName?: string;
  dynamicControlType?: string;
}
export interface IDynamicResponseBody {
  dynamicContainerResponseId?: string;
  dynamicContainerId?: string;
  isActive?: boolean;
  isFinal?: boolean;
  isDraft?: boolean;
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
  deleteComment?: string;
  dynamicControlResponses?: Array<IDynamicControlResponse>;
  submittedBy?: string;
}

export interface IDynamicSignaturePadOptions {
  penColor?: string;
  dotSize?: number;
  width?: number | string;
  height?: number | string;
  minWidth?: number;
  maxWidth?: number;
  hint?: string;
}

export interface IDynamicPointGroup {
  color: string;
  points: IDynamicBasicPoint[];
}

export interface IDynamicBasicPoint {
  x: number;
  y: number;
  time: number;
}

export interface IDynamicReorderEvent {
  clientId: string;
  direction: 'up' | 'down';
}

export interface IDynamicPageOptions {
  name?: string;
  dynamicContainerId?: string;
  dynamicResponseId?: string;
  isCoaching?: boolean;
  isReview?: boolean;
  isEditReview?: boolean;
  showLastModified?: boolean;
  ignoreDefaultButton?: boolean;
  editDataItem?: unknown;
  reviewId?: string;
  deleteComment?: string;
  isDraft?: boolean;
  isReclassification?: boolean;
  showComments?: boolean;
  submittedBy?: string;
  submittedUserId?: string;
  isReadOnly?: boolean;
  shortName?: string;
  dynamicResponseBody?: IDynamicResponseBody;
}

export interface IDynamicActiveActionOptions {
  publish?: boolean;
  draft?: boolean;
  ignoreAutoSuccessMessage?: boolean;
}

export interface IFormReview {
  ReviewComment: string;
  FormReviewId?: string;
  FormId?: string;
  DynamicContainerId?: string;
  DynamicContainerResponseId?: string;
  FormReviewStatusId?: string;
  ReviewType: string;
  FormReviewStatusName?: string;
  Response?: string;
  ApprovalComments?: string;
  ReviewDate?: string;
  ReviewedBy?: string;
  ReviewedUserId?: string;
  bActive?: boolean;
  FormEmails: EmailQueueDto[];
}

export interface ICannedResponse {
  CannedResponseId: string;
  Type: string;
  Response: string;
  clicked?: boolean;
}

// Note: mixed upper/camel - camel is what we create - the upper comes from backend right now (hopefully in future all backend apis will be standardized to not have capital casing)
export interface IDynamicOfflineCache {
  cacheDate?: number;
  Clients?: Array<unknown>;
  Companies?: Array<unknown>;
  Employees?: Array<unknown>;
  Facilities?: Array<unknown>;
  Jobs?: Array<unknown>;
  LessonLearnedCategories?: Array<unknown>;
  NearMissCategories?: Array<unknown>;
  data?: Array<unknown>;
}
