export class ExportType {
  name: string;
  /**
   * ```
   * 1 == saveLaborAsCsv
   * 2 == savePerDiemsAsCsv
   * 3 == saveMileagesAsCsv
   * 4 == saveEquipmentUsagesAsCsv
   * 5 == sendLaborToViewpoint
   * 6 == sendEquipmentToViewpoint
   * ```
   */
  type: number;
  buttonText: string;
}
