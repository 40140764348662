import { Injectable } from '@angular/core';

@Injectable()
export class AllowableInputTypesService {
  constructor() {}

  isInEditableElement(): boolean {
    const activeNode = document.activeElement;

    const skipInput =
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      /^(textarea|select)$/i.test(activeNode!.nodeName) ||
      (event.target as HTMLElement).isContentEditable;

    const allowedInputTypes = [
      'text',
      'email',
      'password',
      'search',
      'url',
      'datetime',
    ];
    const inputType = (activeNode as HTMLInputElement).type;

    const isAllowableInputType =
      activeNode?.nodeName === 'INPUT' &&
      allowedInputTypes.indexOf(inputType) > -1;

    // If textarea or select input is focused or input type is not allowed, do not trigger hotkey
    return skipInput || isAllowableInputType;
  }
}
