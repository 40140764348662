/* eslint-disable @typescript-eslint/naming-convention */
export class EmployeeTimeInOutDto {
  AbsentReason: string;
  AbsentReasonID: string;
  Class: string;
  Craft: string;
  Deduction: number;
  EmployeeCompany: number;
  EmployeeDisplayName: string;
  EmployeeInOutID: string;
  EmployeeJobID: string;
  FirstName: string;
  JobDisplayName: string;
  JobName: string;
  Last4SSN: string;
  LastName: string;
  Notes: string;
  Shift: number;
  TimeIn: string;
  TimeOut: string;
  VPJobID: string;
  bAbsent: boolean;
  bActive: boolean;
  bPayDeduction: boolean;
  bPerDiemDocs: boolean;
  isDirty: boolean;
  EquipmentName: string;
  WorkLocation: string;
}
