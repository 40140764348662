import { Injectable } from '@angular/core';
import { ToastrService, Toast } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class MessageHelper {
  constructor(private toaster: ToastrService) {}

  error(message: string, title = 'Error!', timeOut = 5000) {
    this.toaster.error(message, title, {
      enableHtml: true,
      timeOut,
    });
  }

  success(message: string, title = 'Success!', timeOut = 5000) {
    this.toaster.success(message, title, {
      enableHtml: true,
      timeOut,
    });
  }

  warning(message: string, title = 'Warning!', timeOut = 5000) {
    this.toaster.warning(message, title, {
      enableHtml: true,
      timeOut,
    });
  }

  information(message: string, title = 'Information!', timeOut = 5000) {
    this.toaster.info(message, title, {
      enableHtml: true,
      timeOut,
    });
  }
}
