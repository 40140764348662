import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild,
  SimpleChanges,
  AfterViewInit,
  ViewEncapsulation,
  SecurityContext,
  HostBinding,
} from '@angular/core';
import { tap } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { regionExtractor } from 'region-parser';

import { PrettyPrinterService } from '../pretty-printer.service';
import { CopierService } from '../copier.service';

/**
 * NOTE:
 *  code-example
 *
 * NOTE: we can import any file directly:
 *
 *  import * as kendoIconMeta from 'raw-loader!./kendo.icons.html';
 *  import * as faCategoriesMeta from 'raw-loader!./fa.categories.yml';
 *  import * as faIconMeta from 'raw-loader!./fa.icons.yml';
 *
 *  for JSON we don't even need the raw-loader! prefix, we need just to extend typings:
 *    declare module '*.json' {
 *      const value: any;
 *      export default value;
 *    }
 *
 * https://github.com/thickkoezz/ng-sample-code-prettify
 */
@Component({
  selector: 'custom-code',
  template: `
    <code-example>
      <pre
        class="prettyprint lang-{{ language }}"
        [class.linenums]="lineNums ? lineNums : null"
      ><code class="animated fadeIn"><div #codeContainer></div></code></pre>
    </code-example>
    <div #content style="display: none">
      <!-- ng-content to pick up static/manually assigned content -->
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    // not working for some reason
    ':host { display: block; padding: 1rem; }',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class CustomCodeComponent implements OnChanges, AfterViewInit {
  @HostBinding('class.d-none') @Input() hidden = false;
  @Input() title: string;
  @Input() hideCopy = false;

  @Input() code: string;
  @Input() region: string;

  /**
   * ts: inlineC,
   * js: inlineC,
   * es6: inlineC,
   * dart: inlineC,
   * html: html,
   * svg: html,
   * css: blockC,
   * yaml: inlineHash,
   * yml: inlineHash,
   * jade: inlineCOnly,
   * pug: inlineCOnly,
   * json: inlineC
   */
  @Input() language = 'ts';
  @Input() lineNums: boolean | number = false;

  /** Holds a preprocessed, parsed code, this is what we use to set up what we display... */
  public codeRegionData: object;

  /** code-region selected (or entire code if no region was set) */
  public selectedCode: string;
  public selectedCodeStartingLine: number;

  @ViewChild('codeContainer', { static: true }) codeContainer: ElementRef;
  @ViewChild('content', { static: true }) content: ElementRef;

  constructor(
    private sanitizer: DomSanitizer,
    private pretty: PrettyPrinterService,
    private copier: CopierService
  ) {
    // ...
  }

  ngOnChanges(changes: SimpleChanges) {
    // NOTE: values are set already so we can use this.code instead of changes.code.currentValue;

    // quit if no code was set!
    if (!this.code) return;

    if (changes.code || changes.language) {
      // NOTE: need to re-set (parse) code on both code and language change
      this.processCode();
    }

    if (changes.code || changes.language || changes.region) {
      // Select a region, save selection for copying, temporarily display this un-formatted value
      this.selectRegion();
    }

    this.displayPlain();
    this.displayFormatted();
  }

  ngAfterViewInit() {
    // Pick up the content if no code was assigned via the input property!
    const innerHTML = this.content.nativeElement.innerHTML;

    if (!this.code) {
      this.code = innerHTML;
      this.processCode();
      this.selectRegion();
      this.displayPlain();
      this.displayFormatted();
    }
  }

  processCode() {
    // pre-process
    const processedCode = this.leftAlign(this.code);

    // set globals
    this.codeRegionData = this.parseCodeRegions(processedCode, this.language);
  }

  selectRegion() {
    const selectedRegion = (this.codeRegionData as any).regions[
      this.region || '' /* all content if region(s) were present */
    ];

    if (this.region && selectedRegion) {
      /* concrete region was selected */
      this.selectedCodeStartingLine = selectedRegion.startingLineNum;
      this.selectedCode = selectedRegion.content;
    } else {
      /* if no region was present */
      this.selectedCodeStartingLine = 0;
      this.selectedCode = (this.codeRegionData as any).contents;
    }
  }

  getSelectedCodeForDisplay(): string {
    const isHtmlLike =
      ['htm', 'html', 'mxml', 'xhtml', 'xml', 'xsl'].indexOf(
        this.language.toLowerCase()
      ) >= 0;
    const codeToDisplay = isHtmlLike
      ? this.selectedCode
          .replace(/&/g, '&amp;')
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;')
      : this.selectedCode;
    return codeToDisplay;
  }

  displayPlain() {
    this.setCodeHtml(this.getSelectedCodeForDisplay());
  }

  displayFormatted() {
    const codeToPrettify = this.getSelectedCodeForDisplay();
    const lineNumsToUse =
      typeof this.lineNums === 'number'
        ? (((this.lineNums as number) +
            this.selectedCodeStartingLine) as number)
        : (this.lineNums as boolean);

    this.pretty
      .formatCode(codeToPrettify, this.language, lineNumsToUse)
      .pipe(
        tap((formattedCode) => {
          this.setCodeHtml(formattedCode);
        })
      )
      .subscribe(
        (formattedCode) => {},
        (formattingError) => {
          /* ignore failure to format */
        }
      );
  }

  leftAlign(text: string): string {
    let indent = Number.MAX_VALUE;
    const lines = text.split('\n');

    lines.forEach((line) => {
      const lineIndent = line.search(/\S/);
      if (lineIndent !== -1) {
        indent = Math.min(lineIndent, indent);
      }
    });

    return lines
      .map((line) => line.substr(indent))
      .join('\n')
      .trim();
  }

  parseCodeRegions(code, language): object {
    const originalRegionObject = regionExtractor()(code, language) as any;

    let lineNum = 0;
    const extendedRegionObject = {
      contents: originalRegionObject.contents,
      regions: Object.entries(originalRegionObject['regions']).reduce(
        (result, [key, value]) => {
          const startingLineNum = lineNum;
          lineNum += (value as string).split('\n').length;
          result[key] = {
            startingLineNum: startingLineNum,
            content: value,
          };
          return result;
        },
        {}
      ),
    };

    return extendedRegionObject;
  }

  setCodeHtml(formattedCode: string) {
    // **Security:** Code example content is provided by docs authors and as such its considered to be safe for innerHTML purposes.
    this.codeContainer.nativeElement.innerHTML = formattedCode;
  }

  public getContent(): string {
    return this.selectedCode;
  }

  public doCopy() {
    const code = this.getContent();
    const successfullyCopied = this.copier.copyText(code as string);
    if (!successfullyCopied) alert('Copy failed. Please try again!');
  }
}
