import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabContentLoadOnDemandDirective } from './tabstrip/tab.content-load.on.demand-directive';

const exportedModules = [TabContentLoadOnDemandDirective];

const declarations = [...exportedModules];

@NgModule({
  declarations: [declarations],
  exports: [exportedModules],
  imports: [CommonModule],
})
export class TabStripModuleEx {}
