/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { ISignalR } from '../models';

@Injectable()
export class SignalRPlatformService implements ISignalR {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  invoke(name: string, args?: any): Promise<any> {
    return Promise.resolve(undefined);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  on(name: string, callback: (data: any) => void): void {}

  start(): Promise<any> {
    return Promise.resolve(undefined);
  }

  stop(): Promise<any> {
    return Promise.resolve(undefined);
  }
}

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  constructor(private _signalRPlatformService: SignalRPlatformService) {}

  public start() {
    return this._signalRPlatformService.start();
  }

  public stop() {
    return this._signalRPlatformService.stop();
  }

  public on(name: string, callback: (data: any) => void) {
    this._signalRPlatformService.on(name, callback);
  }

  public invoke(name: string, args?: any) {
    return this._signalRPlatformService.invoke(name, args);
  }
}
