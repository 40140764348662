/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { BaseModel } from '../base.model';
import {
  CertificationModel,
  ProspectData,
  ProspectDataFileDto,
  ProspectFitModel,
  ProspectResumeModel,
  SafetyModel,
} from '../sparta-api';

export class ProspectModel extends BaseModel implements ProspectData {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  // Remove the ANY type once we replace the my-datepicker with kendo-datepicker - as the my-datepicker mixes the formats
  _dateOfBirthBackingStore: any;
  _tWICCardExpirationDateBackingStore: any;
  _licenseExpirationBackingStore: any;
  _lastContactedDateBackingStore: any;
  _lastCheckinDateBackingStore: any;
  /* eslint-enable @typescript-eslint/no-explicit-any */

  ProspectID: string;
  Contact = '';
  FirstName = '';
  LastName = '';
  MiddleName = '';
  Nickname = '';
  PhoneNumber = '';
  CellPhone = '';
  AlternatePhoneNumber = '';
  Email = '';
  SSN = '';
  StreetAddress = '';
  StreetAddress2 = '';
  City = '';
  State = '';
  Country = '';
  ZipCode = '';
  Notes = '';
  Suffix = '';
  CompanyID = '';
  VPCompanyID = null;
  LicenseTypeID: number | null;
  LicenseTypeName = '';
  LicenseNumber = '';
  PhysicalStreetAddress = '';
  PhysicalAddress2 = '';
  PhysicalCity = '';
  PhysicalState = '';
  PhysicalCountry = '';
  PhysicalZipCode = '';
  TWICCardNumber = '';
  IsActive = false;
  Ranking = null;
  bFormerEmployee = false;
  ClaimedTwic = false;
  ClaimedCerts = '';
  CommunicationPreference = '';
  TradeGroupIDs: string[] = [];
  TradeClassIDs: string[] = [];
  bContacted = false;
  bCheckin = false;
  CheckinNotes = '';
  PersonNotes = '';
  AbsorbDepartmentId = '';

  LocalUnionName = '';
  LocalUnionNumber = '';
  LocalUnionGrade = 0;
  LocalUnionYrsOfExperience = 0;

  SavedCellPhone = '';
  certifications: CertificationModel[] = [];
  safetyItems: SafetyModel[] = [];
  fitItems: ProspectFitModel[] = [];
  resumes: ProspectResumeModel[] = [];

  // Remove the ANY type once we replace the my-datepicker with kendo-datepicker - as the my-datepicker mixes the formats
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get DateOfBirth(): any {
    return this._dateOfBirthBackingStore;
  }

  // Remove the ANY type once we replace the my-datepicker with kendo-datepicker - as the my-datepicker mixes the formats
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set DateOfBirth(x: any) {
    this.Birthday = x
      ? x.date
        ? new Date(x.date.year, x.date.month - 1, x.date.day)
        : new Date(x) instanceof Date
        ? x
        : null
      : null;
    this._dateOfBirthBackingStore = x;
  }

  // Remove the ANY type once we replace the my-datepicker with kendo-datepicker - as the my-datepicker mixes the formats
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get TWICCardExpirationDate(): any {
    return this._tWICCardExpirationDateBackingStore;
  }

  // Remove the ANY type once we replace the my-datepicker with kendo-datepicker - as the my-datepicker mixes the formats
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set TWICCardExpirationDate(x: any) {
    this.TWICCardExpiration = x
      ? x.date
        ? new Date(x.date.year, x.date.month - 1, x.date.day)
        : null
      : null;
    this._tWICCardExpirationDateBackingStore = x;
  }

  // Remove the ANY type once we replace the my-datepicker with kendo-datepicker - as the my-datepicker mixes the formats
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get LicenseExpiration(): any {
    return this._licenseExpirationBackingStore;
  }

  // Remove the ANY type once we replace the my-datepicker with kendo-datepicker - as the my-datepicker mixes the formats
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set LicenseExpiration(x: any) {
    this.LicenseExpirationDate = x
      ? x.date
        ? new Date(x.date.year, x.date.month - 1, x.date.day)
        : null
      : null;
    this._licenseExpirationBackingStore = x;
  }

  // Remove the ANY type once we replace the my-datepicker with kendo-datepicker - as the my-datepicker mixes the formats
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get LastContacted(): any {
    return this._lastContactedDateBackingStore;
  }

  // Remove the ANY type once we replace the my-datepicker with kendo-datepicker - as the my-datepicker mixes the formats
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set LastContacted(x: any) {
    this.LastContactedDate = x
      ? x.date
        ? new Date(x.date.year, x.date.month - 1, x.date.day)
        : null
      : null;
    this._lastContactedDateBackingStore = x;
  }

  // Remove the ANY type once we replace the my-datepicker with kendo-datepicker - as the my-datepicker mixes the formats
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get LastCheckin(): any {
    return this._lastCheckinDateBackingStore;
  }

  // Remove the ANY type once we replace the my-datepicker with kendo-datepicker - as the my-datepicker mixes the formats
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set LastCheckin(x: any) {
    this.LastCheckinDate = x
      ? x.date
        ? new Date(x.date.year, x.date.month - 1, x.date.day)
        : null
      : null;
    this._lastCheckinDateBackingStore = x;
  }

  Birthday: Date | null;
  BirthDateString = '';
  TWICCardExpiration: Date;
  LicenseExpirationDate: Date;
  LastContactedDate: Date;
  LastCheckinDate: Date;

  constructor(o: unknown = null) {
    super(o);
  }

  ApplicationStatus: string;
  Files: ProspectDataFileDto[];
  Position: string;
  CompanyName: string;
  bExternalGuest: boolean;
}
