import { SortDescriptor } from '@progress/kendo-data-query';
import { ODataExSettings } from './odata';

/**
 * Based on implementation only those fields will be included in the sort, which have a sort direction (dir property) set!
 */
export const serializeSort = (
  orderby: Array<SortDescriptor>,
  settings: ODataExSettings
): string => {
  const str = orderby
    .filter((sort) => settings.sort.gridSort === false || !!sort.dir)
    .map((sort) => {
      const order = sort.field.replace(/\./g, '/');
      return sort.dir === 'desc'
        ? settings.isMobile
          ? encodeURIComponent(order + ' desc')
          : order + ' desc'
        : order;
    })
    .join(',');

  return str ? `$orderby=${str}` : str;
};
