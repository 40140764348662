import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HotkeysComponent } from './components/hotkeys/hotkeys.component';
import { AllowableInputTypesService } from './services';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';

@NgModule({
  imports: [CommonModule, ButtonsModule, DialogsModule],
  declarations: [HotkeysComponent],
  providers: [AllowableInputTypesService],
  exports: [HotkeysComponent],
})
export class XplatHotkeysModule {}
