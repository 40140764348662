/**
 * @deprecated A9 REMOVES SUPPORT FOR REFERENCES IN ViewChild opts.
 */
export class A8Consts {
  /**
   * BREAKING CHANGE !!! { static: true } needs to be set when you want to access the ViewChild in ngOnInit.
   * NOTE: that if you add static: true on a dynamic element (wrapped in a condition or a loop), then it will not be accessible in ngOnInit nor in ngAfterViewInit!
   * https://blog.ninja-squad.com/2019/05/29/what-is-new-angular-8.0/
   *
   * BREAKING CHANGE !!! (pt 2): @ViewChild now requires object referances, these can no longer work.
   * @deprecated A9 REMOVES SUPPORT FOR REFERENCES IN ViewChild opts.
   */
  public static ViewAndContentChildStatic = { static: true };

  /**
   * { static: false } can only be accessed in ngAfterViewInit.
   * This is also what you want to go for when you have a structural directive (i.e. *ngIf) on your element in your template.
   * @deprecated A9 REMOVES SUPPORT FOR REFERENCES IN ViewChild opts.
   */
  public static ViewAndContentChildNonStatic = { static: false };

  /** see: A8Consts.ViewAndContentChildStatic - when you want to access the ViewChild in ngOnInit (possible issues with *ngIf)
   * @deprecated A9 REMOVES SUPPORT FOR REFERENCES IN ViewChild opts.
   */
  public static ViewAndContentChildLegacy = A8Consts.ViewAndContentChildStatic;

  /** see: A8Consts.ViewAndContentChildNonStatic - can only be accessed in ngAfterViewInit (works with *ngIf)
   * @deprecated A9 REMOVES SUPPORT FOR REFERENCES IN ViewChild opts.
   */
  public static ViewAndContentChildDefault = A8Consts.ViewAndContentChildNonStatic;
}
