<kendo-panelbar [expandMode]="expandMode">
  <kendo-panelbar-item title="" class="k-item-expand-menu">
    <ng-template kendoPanelBarItemTitle>
      <div
        help-anchor="main-menu__hamburger"
        class="k-item-container"
        (click)="toggleSidebarMenu()"
      >
        <div kendoTooltip>
          <i class="far fa-bars fa-fw cursor-pointer"></i>
          <i class="far fa-times fa-fw cursor-pointer"></i>
          <span title="{{ displayName }}">{{ title }}</span>
        </div>
      </div>
    </ng-template>
  </kendo-panelbar-item>
  <!-- L1 menu (note: invisible itema are filtered via MenuService) -->

  <kendo-panelbar-item
    title=""
    [expanded]="isExpanded(itemL1)"
    [disabled]="!itemL1.enabled"
    [class.active]="isActive(itemL1)"
    [routerLink]="
      itemL1.children.length || itemL1.isCategory ? null : itemL1.url
    "
    *ngFor="let itemL1 of menuService.menuItems; let iL1 = index"
  >
    <ng-template kendoPanelBarItemTitle>
      <ng-container
        *ngTemplateOutlet="
          useWebFrameworkDesign ? navLinkSpecial : navLinkUni;
          context: { $implicit: itemL1 }
        "
      ></ng-container>
    </ng-template>

    <!-- nested (L2) menu (note: invisible is filtered via MenuService) -->
    <kendo-panelbar-item
      class="child-route"
      title=""
      [expanded]="isExpanded(itemL2)"
      [disabled]="!itemL2.enabled"
      [class.active]="isActive(itemL2)"
      [routerLink]="itemL2.url"
      *ngFor="let itemL2 of itemL1.children; let iL2 = index"
    >
      <ng-template kendoPanelBarItemTitle>
        <ng-container
          *ngTemplateOutlet="
            useWebFrameworkDesign ? navLinkSpecial : navLinkUni;
            context: { $implicit: itemL2 }
          "
        ></ng-container>
      </ng-template>
    </kendo-panelbar-item>
  </kendo-panelbar-item>
</kendo-panelbar>

<ng-template #navLinkUni let-dataItem>
  <div class="k-item-container">
    <a
      (click)="$event.stopPropagation()"
      class="routing"
      [class.d-none]="dataItem.disableRouting"
      routerLink="{{ dataItem.url }}"
      routerLinkActive="active"
      [routerLinkActiveOptions]="dataItem.exact ? { exact: true } : {}"
    ></a>
    <i
      class="{{ 'fas' + (dataItem.icon ? ' ' + dataItem.icon : '') }} fa-fw"
    ></i>
    <span>{{ dataItem.title }}</span>
  </div>
</ng-template>

<ng-template #navLinkSpecial let-dataItem>
  <div class="k-item-container">
    <a
      (click)="$event.stopPropagation()"
      class="routing"
      [class.d-none]="dataItem.disableRouting"
      routerLink="{{ dataItem.url }}"
      routerLinkActive="active"
      [routerLinkActiveOptions]="dataItem.exact ? { exact: true } : {}"
      [attr.title]="dataItem.tooltip || dataItem.title"
    ></a>
    <i
      class="{{ 'fas' + (dataItem.icon ? ' ' + dataItem.icon : '') }} fa-fw"
    ></i>
    <span>{{ dataItem.title }}</span>
    <!-- ideal would be to have it without this, as this leads to L2 menu being rendered twice -->
    <div *ngIf="dataItem.children.length" class="k-item-hover">
      <span *ngFor="let child of dataItem.children">
        <a
          (click)="$event.stopPropagation()"
          class="routing"
          [class.d-none]="child.disableRouting"
          routerLink="{{ child.url }}"
          routerLinkActive="active"
          [routerLinkActiveOptions]="child.exact ? { exact: true } : {}"
          [attr.title]="child.title"
          [help-anchor]="child.title"
        >
          {{ child.title }}
        </a>
      </span>
    </div>
  </div>
</ng-template>
