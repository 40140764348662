<kendo-grid
  [data]="gridData"
  [groupable]="true"
  [group]="state.group"
  [rowClass]="rowCallback"
  (dataStateChange)="dataStateChange($event)"
>
  <kendo-grid-command-column *ngIf="!isReadOnly" title="" width="120">
    <ng-template kendoGridHeaderTemplate>
      <button
        kendoButton
        [primary]="true"
        (click)="editModal.open()"
        title="Add Subtask"
      >
        <i class="fa fa-plus"></i>
        Add
      </button>
    </ng-template>
    <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
      <button
        kendoButton
        *ngIf="dataItem.bActive"
        iconClass="fa fa-edit"
        (click)="editModal.open(dataItem)"
        title="Update Subtask"
      ></button>
      <button
        kendoButton
        *ngIf="dataItem.bActive"
        iconClass="fa fa-trash"
        (click)="dataItem.bActive = false"
        title="Delete Subtask"
      ></button>
      <button
        kendoButton
        *ngIf="!dataItem.bActive"
        (click)="dataItem.bActive = true"
        iconClass="fa fa-sync"
        title="Undelete Subtask"
      ></button>
    </ng-template>
  </kendo-grid-command-column>

  <kendo-grid-column
    field="GroupName"
    title="Group"
    width="150"
  ></kendo-grid-column>

  <kendo-grid-column field="Name" title="Subtask" width="150">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ dataItem.Name }}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="SafetyCouncilName"
    title="Safety Council"
    width="150"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ dataItem.SafetyCouncilName }}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="bMandatory" title="Mandatory" width="60">
    <ng-template kendoGridCellTemplate let-dataItem>
      <label class="k-form-field right">
        <input
          [disabled]="true"
          [(ngModel)]="dataItem.bMandatory"
          class="k-checkbox"
          id="checkMandatory"
          type="checkbox"
        />
        <label class="k-checkbox-label" for="checkMandatory"></label>
      </label>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="bBillable" title="Billable" width="60">
    <ng-template kendoGridCellTemplate let-dataItem>
      <label class="k-form-field right">
        <input
          [disabled]="true"
          [(ngModel)]="dataItem.bBillable"
          class="k-checkbox"
          id="checkBillable"
          type="checkbox"
        />
        <label class="k-checkbox-label" for="checkBillable"></label>
      </label>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="Notes" title="Notes" width="150">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ dataItem.Notes }}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="DateModified" title="Modified" width="120">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ dataItem.DateModified | date: 'short' }}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="ModifiedBy" title="Modified By" width="90">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ dataItem.ModifiedBy }}</span>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

<ups-training-subtask-editor
  [companyId]="companyId"
  (editSubtask)="onEditSubtask($event)"
></ups-training-subtask-editor>
