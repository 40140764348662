import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UserService } from '@ups/user';
import { UpsApiHttpErrorResponseCamelCase } from '@ups/common';
import { EventBusService, ICannedResponse, IFormReview } from '@ups/xplat/core';
import { DynamicItemBaseComponent } from '@ups/xplat/features';
import { MessageHelper } from '@ups/xplat/web/core';
import { filter, takeUntil } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import { SecurityConstants, SecurityService } from '@ups/security';

@Component({
  selector: 'ups-dynamic-coaching',
  styleUrls: ['dynamic-coaching.component.scss'],
  templateUrl: 'dynamic-coaching.component.html',
})
export class DynamicCoachingComponent
  extends DynamicItemBaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('coachingResult') coachingResult: ElementRef;
  @ViewChild('coachingComment') coachingComment: ElementRef;

  selectedButton: string = null;
  comments: string = null;
  approvalComments = '';
  showCancelConfirm = false;
  showDeleteConfirm = false;
  formReview: IFormReview = null;
  cannedResponses: ICannedResponse[] = [];
  coachedBy = '';
  dateCoached = '';
  isSaving = false;

  initialState = {
    selectedButton: null,
    comments: null,
  };

  dynamicComment = {
    isCommenting: false,
    commentText: '',
  };

  isReadOnly = false;
  isSubmittedByLoggedInUser = false;

  canCoachForms = false;

  canViewApprovalComments = false;
  canReadAllApprovalComments = false;
  canReadSelfApprovalComments = false;
  canEditAllApprovalComments = false;

  constructor(
    public userService: UserService,
    private _eventBus: EventBusService,
    public security: SecurityService,
    private msg: MessageHelper
  ) {
    super();

    this.canCoachForms = this.security.getFeatureById(
      SecurityConstants.safety_2_0_forms_cancoachforms
    ).editAll;

    const fSecurity = this.security.getFeatureById(
      SecurityConstants.safety_2_0_forms_approvalcomments
    );
    this.canViewApprovalComments = fSecurity.viewFeature;
    this.canReadAllApprovalComments = fSecurity.readAll;
    this.canReadSelfApprovalComments = fSecurity.readSelf;
    this.canEditAllApprovalComments = fSecurity.editAll;
  }

  ngOnInit() {
    this.isReadOnly = this.config?.options?.isReadOnly;
    this.isSubmittedByLoggedInUser =
      this.userService.myInfo?.UserId === this.config.options.submittedUserId;

    if (this.config.options.dynamicContainerResponseId) {
      this.dynamicService
        .getFormReview(this.config.options.dynamicContainerResponseId, 'Coach')
        .toPromise()
        .then((d) => {
          if (d) {
            this.formReview = d;
            this.comments = d.Response;
            this.approvalComments = d.ApprovalComments;
            this.coachedBy = d.ReviewedBy;
            this.dateCoached = d.ReviewDate;
            this.selectedButton = d.FormReviewStatusName.toLowerCase();

            this.initialState.comments = d.Response;
            this.initialState.selectedButton =
              d.FormReviewStatusName.toLowerCase();
          } else {
            this.dynamicService
              .getCannedResponses()
              .toPromise()
              .then((c) => {
                this.cannedResponses = c;
              });
          }
        });
    }
  }

  ngAfterViewInit(): void {
    this._eventBus
      .observe(this._eventBus.types.cmpReadyEvent)
      .pipe(
        filter((data) => data['type'] === 'dynamic-comments-component'),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        this.dynamicComment.isCommenting = data['instance'].isCommenting;
        this.dynamicComment.commentText = data['instance'].comment;
      });

    // Listen to changes with rxjs so dynamic page can keep track of changes
    merge(
      fromEvent(this.coachingResult.nativeElement, 'click'),
      fromEvent(this.coachingComment.nativeElement, 'input')
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        console.log('changes detected');

        this._eventBus.emit(this._eventBus.types.cmpReadyEvent, {
          type: 'dynamic-coaching-component',
          instance: this.checkForFormChanges.bind(this),
        });
      });
  }

  getCannedResponses() {
    if (this.selectedButton) {
      return this.cannedResponses.filter(
        (c) => c.Type.toLowerCase() === this.selectedButton.toLowerCase()
      );
    }
    return [];
  }

  isEditable() {
    return (
      this.canCoachForms &&
      !this.isReadOnly &&
      !this.isSubmittedByLoggedInUser &&
      !this.formReview
    );
  }

  canAddApprovalComments() {
    return (
      !this.formReview && this.canEditAllApprovalComments && this.isEditable()
    );
  }

  canReadApprovalComments() {
    const isReviewedByLoggedInUser =
      this.formReview?.ReviewedUserId === this.userService.myInfo.UserId;
    return (
      this.formReview &&
      (this.canReadAllApprovalComments ||
        (isReviewedByLoggedInUser &&
          (this.canReadSelfApprovalComments ||
            this.canEditAllApprovalComments)))
    );
  }

  canEditApprovalComments() {
    return (
      this.canReadApprovalComments() &&
      this.canEditAllApprovalComments &&
      this.isEditable()
    );
  }

  showApprovalComments() {
    return (
      this.selectedButton === 'approved' &&
      (this.canAddApprovalComments() ||
        this.canReadApprovalComments() ||
        this.canEditApprovalComments())
    );
  }

  isValid() {
    return (
      this.config.options.dynamicContainerId &&
      this.config.options.dynamicContainerResponseId &&
      this.selectedButton &&
      (this.selectedButton === 'approved' ||
        (this.selectedButton === 'coached' &&
          this.comments &&
          this.comments.length))
    );
  }

  closeTab() {
    this.showCancelConfirm = false;
    this.dynamicService.formReviewDone$.next();
  }

  checkForFormChanges() {
    if (
      this.initialState.selectedButton === this.selectedButton &&
      this.initialState.comments === this.comments
    ) {
      return false;
    } else {
      return true;
    }
  }

  resetFormChanges() {
    this.initialState.selectedButton = this.selectedButton;
    this.initialState.comments = this.comments;

    this._eventBus.emit(this._eventBus.types.cmpReadyEvent, {
      type: 'dynamic-coaching-component',
      instance: this.checkForFormChanges.bind(this),
    });
  }

  submitCoaching() {
    if (!this.isValid()) {
      return;
    }

    if (this.dynamicComment.isCommenting && this.dynamicComment.commentText) {
      const msg =
        'There is an unsaved comment. Are you sure you want to continue?';

      this.win.confirm(msg).then((ok) => {
        if (ok) {
          this.submit();
        }
      });
    } else {
      this.submit();
    }
  }

  submit() {
    /* eslint-disable @typescript-eslint/naming-convention */
    const dto = {
      DynamicContainerId: this.config.options.dynamicContainerId,
      DynamicContainerResponseId:
        this.config.options.dynamicContainerResponseId,
      FormReviewStatusName: this.selectedButton,
      Response: this.comments,
      ApprovalComments: this.approvalComments,
      ReviewType: 'Coach',
    } as IFormReview;
    /* eslint-enable @typescript-eslint/naming-convention */

    this.isSaving = true;
    this.dynamicService
      .saveFormReview(dto)
      .toPromise()
      .then(
        () => {
          this.isSaving = false;
          this.resetFormChanges();
          this.closeTab();
        },
        (e: UpsApiHttpErrorResponseCamelCase) => {
          this.isSaving = false;
          this.msg.error(e?.error.message);
        }
      );
  }

  addCannedResponse(cr: ICannedResponse) {
    cr.clicked = !cr.clicked;

    const comments = (this.comments || '').trim();

    // Check if comment contains cr.Response and remove it if it does
    if (comments.includes(cr.Response)) {
      // Check if comment ends with a comma and remove it if it does
      if (comments.includes(`${cr.Response},`)) {
        this.comments = comments.replace(`${cr.Response},`, '').trim();
      } else {
        this.comments = comments.replace(cr.Response, '').trim();
      }
      return;
    }

    this.comments =
      (comments
        ? comments + (this.endsWithAlphaNum(comments) ? ', ' : ' ')
        : '') + cr.Response;
  }

  endsWithAlphaNum(comment: string) {
    return (
      comment &&
      comment.length &&
      /^[a-z0-9]+$/i.test(comment[comment.length - 1])
    );
  }
}
