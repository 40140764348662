export class EquipmentDashboardDetailJobInfo {
  public assetControlManager: string;
  public email: string;
  public jobName: string;
  public phoneNumber: string;
  public plantAddress: string;
  public plantName: string;
  public requestDescription: string;
  public vpJobId: string;
  public vpCompanyId: number | null;
  public equipmentBillingTemplate: string;
  public companiesWithSameEquipmentBillingTemplate: number[] | null;
}
