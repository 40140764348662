import { BaseModel } from '../../base.model';

export class PaySequenceRuleDto extends BaseModel {
  public EarnCodeTypeID: string;
  public bActive: boolean;
  public CompanyID: string | null;
  public PaySequenceRuleID: number;
  public CompanyName: string;
  public VpCompanyID: number;

  constructor(o: any = null) {
    super(o);
  }
}
