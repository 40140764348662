import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import { map } from 'rxjs/operators';
import {
  AddressChangeRequestDto,
  AddressChangeRequestExtendedDto,
  EmployeeMultiClassDto,
} from '@ups/xplat/api/dto';

const GET_EMPLOYEE_MULTI_CLASSES =
  '/api/spartaemployee/employeemulticlass/{:hrRef}';
const SAVE_EMPLOYEE_MULTI_CLASSES =
  '/api/spartaemployee/employeemulticlass/{:hrRef}';
const GET_ADDRESS_CHANGE_REQUEST =
  '/api/spartaemployee/addresschangerequest/{:hrRef}';
const SAVE_ADDRESS_CHANGE_REQUEST = '/api/spartaemployee/addresschangerequest/';
const GET_ADDRESS_CHANGE_REQUESTS =
  '/api/spartaemployee/addresschangerequests-odata/';

@Injectable({
  providedIn: 'root',
})
export class SpartaEmployeeService {
  protected urlBase: string;
  protected http: HttpClient;

  constructor(private clientFactory: MyHttpClientFactory) {
    this.urlBase = environment.urls.spartaAPI;
    this.http = this.clientFactory.createHttpClient(
      this.urlBase,
      true,
      ResponseCasingEnum.CamelCase
    );
  }

  public fetchEmployeeMultiClasses(
    hrRef: number
  ): Promise<EmployeeMultiClassDto[]> {
    let url = GET_EMPLOYEE_MULTI_CLASSES.split('{:hrRef}').join(
      hrRef.toString()
    );

    url += '?prCos=all';

    return this.http
      .get(url)
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((array: any[]) => array.map((o) => new EmployeeMultiClassDto(o)))
      )
      .toPromise();
  }

  public saveEmployeeMultiClasses(
    hrRef: number,
    employeeFileClasses: EmployeeMultiClassDto[]
  ) {
    let url = SAVE_EMPLOYEE_MULTI_CLASSES.split('{:hrRef}').join(
      hrRef.toString()
    );
    url += '?prCos=all';

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    return this.http.post(url, employeeFileClasses, options).toPromise();
  }

  public fetchAddressChangeRequests(
    requestStatusIds: number[],
    odatastring: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    let url = GET_ADDRESS_CHANGE_REQUESTS + '?';

    // For now by default we show only active change requests
    if (requestStatusIds != null && requestStatusIds.length !== 0)
      url += 'requestStatusId=' + requestStatusIds.join();

    url += '&$format=json&$count=true';
    url += odatastring ? '&' + odatastring : '';

    return this.http.get(url).pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map((r: any) => {
        if (r.value && r.value.length > 0) {
          // Filling out calculated fields
          r.value.forEach(
            (v: AddressChangeRequestExtendedDto) =>
              (v.originalFullAddress =
                v.originalAddress +
                (v.originalAddress2 ? ', ' : '') +
                v.originalAddress2 +
                (v.originalCity ? ', ' : '') +
                v.originalCity +
                (v.originalState ? ', ' : '') +
                v.originalState +
                (v.originalZip ? ', ' : '') +
                v.originalZip +
                (v.originalCountry ? ', ' : '') +
                v.originalCountry)
          );
          r.value.forEach(
            (v: AddressChangeRequestExtendedDto) =>
              (v.requestedFullAddress =
                v.addressChangeRequestDto.address +
                (v.addressChangeRequestDto.address2 ? ', ' : '') +
                v.addressChangeRequestDto.address2 +
                (v.addressChangeRequestDto.city ? ', ' : '') +
                v.addressChangeRequestDto.city +
                (v.addressChangeRequestDto.state ? ', ' : '') +
                v.addressChangeRequestDto.state +
                (v.addressChangeRequestDto.zip ? ', ' : '') +
                v.addressChangeRequestDto.zip +
                (v.addressChangeRequestDto.country ? ', ' : '') +
                v.addressChangeRequestDto.country)
          );
          r.value.forEach(
            (v: AddressChangeRequestExtendedDto) =>
              (v.employeeFullName =
                v.employeeLastName + ', ' + v.employeeFirstName)
          );
        }

        return r;
      })
    );
    //.pipe(map(array => _.map(array, o => new AddressChangeRequestDto(o))));
  }

  public fetchAddressChangeRequest(
    hrRef: number,
    requestStatusId: number
  ): Promise<AddressChangeRequestDto> {
    let url = GET_ADDRESS_CHANGE_REQUEST.split('{:hrRef}').join(
      hrRef.toString()
    );

    if (requestStatusId) {
      url += '?requestStatusId=' + requestStatusId;
    }

    return this.http
      .get(url)
      .pipe(map((o) => new AddressChangeRequestDto(o)))
      .toPromise();
  }

  public saveAddressChangeRequest(
    addressChangeRequestDto: AddressChangeRequestDto
  ): Promise<AddressChangeRequestDto> {
    const url = SAVE_ADDRESS_CHANGE_REQUEST;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    return this.http
      .post(url, addressChangeRequestDto, options)
      .pipe(map((o) => new AddressChangeRequestDto(o)))
      .toPromise();
  }
}
