import { AddressComponent } from './address/address.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { EditModalComponent } from './edit-modal/edit-modal.component';
import { EmployeeDetailedProfileComponent } from './employee-detailed-profile/employee-detailed-profile.component';
import { EmployeeHeaderComponent } from './employee-header/employee-header.component';
import { HeaderComponent } from './header/header.component';
import { LabeledFieldComponent } from './labeled-field/labeled-field.component';
import { MultiColumnFilterComponent } from './multi-column-filter/multi-column-filter.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NavbarContentDirective } from './navbar/navbar-content.directive';
import { PanelComponent } from './panel/panel.component';
import { PopoverComponent } from './popover/popover.component';
import { TableviewComponent } from './tableview/tableview.component';
import { JobSearchComponent } from './job-search/job-search.component';
import { GridToolbarComponent } from './grid-toolbar/grid-toolbar.component';

import { SpinnerComponent } from './spinner/spinner.component';

import { OfflineBannerComponent } from './offline-banner/offline-banner.component';

import { InfoOverlayComponent } from './info-overlay/info-overlay.component';

import { VersionDisplayComponent } from './version-display/version-display.component';
import { NotificationIconComponentComponent } from './notification-icon-component/notification-icon-component.component';

import { AppsyncStatusComponent } from './appsync-status/appsync-status.component';

export const UI_COMPONENTS = [
  HeaderComponent,
  NavbarComponent,
  NavbarContentDirective,
  ConfirmationDialogComponent,
  EmployeeDetailedProfileComponent,
  TableviewComponent,
  JobSearchComponent,
  PopoverComponent,
  MultiColumnFilterComponent,
  LabeledFieldComponent,
  AddressComponent,
  EditModalComponent,
  PanelComponent,
  GridToolbarComponent,
  EmployeeHeaderComponent,
  SpinnerComponent,
  OfflineBannerComponent,
  InfoOverlayComponent,
  VersionDisplayComponent,
  NotificationIconComponentComponent,
  AppsyncStatusComponent,
];

export * from './header/header.component';
export * from './navbar/navbar.component';
export * from './confirmation-dialog/confirmation-dialog.component';
export * from './employee-detailed-profile/employee-detailed-profile.component';
export * from './tableview/tableview.component';
export * from './job-search/job-search.component';
export * from './job-search/job-search.service';
export * from './popover/popover.component';
export * from './multi-column-filter/multi-column-filter.component';
export * from './labeled-field/labeled-field.component';
export * from './address/address.component';
export * from './edit-modal/edit-modal.component';
export * from './panel/panel.component';
export * from './grid-toolbar/grid-toolbar.component';
export * from './spinner/spinner.component';
export * from './offline-banner/offline-banner.component';
export * from './info-overlay/info-overlay.component';
export * from './version-display/version-display.component';
export * from './notification-icon-component/notification-icon-component.component';
export * from './appsync-status/appsync-status.component';

// export * from './sidebar/sidebar.component';
