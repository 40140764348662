import { Injectable } from '@angular/core';
import { ApplicationModel } from '@ups/xplat/api/dto';
import { ProspectService } from './prospect.service';

@Injectable({
  providedIn: 'root',
})
export class TradeGroupsResolver {
  constructor(private prospectService: ProspectService, private applicationModel: ApplicationModel) {}

  public load(): Promise<any> {
    var self = this;
    if (this.applicationModel.tradeGroups.length == 0) return this.prospectService.getTradeGroups().then((data) => (self.applicationModel.tradeGroups = data));
    else return Promise.resolve(this.applicationModel.tradeGroups);
  }
}
