import { BaseModel } from '../base.model';

export class LodgeDto extends BaseModel {
  public LodgeID: number;
  public Name: string;
  public Address: string;
  public City: string;
  public State: string;
  public Zip: string;
  public PhoneNumber: string;
  public Notes: string;
  public bActive: boolean;

  constructor(o: any = null) {
    super(o);
  }
}
