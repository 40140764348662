// import { HostBinding } from '@angular/core';
// import { FormControl } from '@ups/xplat/features/ui/dynamic-render/models/dynamic-dtos';

import { AdminPreviewPanelItem } from '../../models/dr-admin-panel-item-models';
import { HostBinding, Component } from '@angular/core';

@Component({
  template: '',
})
export class WebDynamicItemPreviewComponentBase {
  public config: AdminPreviewPanelItem;
  // public group: FormGroup;

  /*
  @HostBinding('class')
  get className() {
    return 'e-panel';
  }
 */
  /*
  @HostBinding('attr.data-row')
  get dataRow() {
    return this.config.DataRow;
  }

  @HostBinding('attr.data-col')
  get dataCol() {
    return this.config.DataCol;
  }

  @HostBinding('attr.data-sizeX')
  get dataSizeX() {
    return this.config.DataSizeX;
  }

  @HostBinding('attr.data-sizeY')
  get dataSizeY() {
    return this.config.DataSizeY;
  }
 */

  @HostBinding('style.grid-row')
  get gRow() {
    let row = `${this.config.layout.row + 1}`;
    if (this.config.layout.sizeY) {
      row = `${row} / span ${this.config.layout.sizeY}`;
    }
    return row;
  }

  @HostBinding('style.grid-column')
  get gCol() {
    let col = `${this.config.layout.col + 1}`;
    if (this.config.layout.sizeX) {
      col = `${col} / span ${this.config.layout.sizeX}`;
    }
    return col;
  }
}
