import { BaseModel } from '../base.model';

export class ProspectConversionDetailsModel extends BaseModel {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ProspectID = '';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  SSN = '';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Company;

  constructor(o = null) {
    super(o);
  }
}
