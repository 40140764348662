import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KendoDatepickerSharedComponent } from './kendo-datepicker-shared.component';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [KendoDatepickerSharedComponent],
  imports: [CommonModule, DatePickerModule, FormsModule],
  exports: [KendoDatepickerSharedComponent],
})
export class KendoDatepickerSharedModule {}
