import { BaseModel } from '../base.model';

export class JobRequirementLogDTO extends BaseModel {
  public DateModified: Date;
  public ModifiedUserID: string;
  public ModifiedUserName: string;
  public ManPowerReqID: string;
  public JobID: string;
  public PlantID: number;
  public PlantName: string;
  public bActive: boolean;
  public Description: string;
  public PersonelEmail: string;
  public bUseJobSubtasks: boolean;
  public bUseJobRequirements: boolean;
  public BadgeRequest: string | null;
  public DayShiftStart: Date | null;
  public DayShiftEnd: Date | null;
  public NightShiftStart: Date | null;
  public NightShiftEnd: Date | null;
  public Gate: string;
  public PerDiemID: string | null;
  public MileageID: string | null;
  public BadgeInfo: string;
  public BadgeLeadDays: number;
  public MappingPlatform: string;
  public OverflowSTLimit: number;
  public OverflowOTLimit: number;
  public OverflowRules: string;
  public EmployeeJobDescription: string;

  constructor(o: any = null) {
    super(o);
  }
}
