<div class="dynamic-field-container" [class.selected]="config?.selected">
  @if (config?.isFormBuilder || data?.length) {
  <!-- when displaying in live form, only show when data is available 
     otherwise, show control in form builder fully -->
  <div
    class="dynamic-field form-input text-align"
    [formGroup]="group"
    [hidden]="!config?.isFormBuilder && config?.options?.hidden"
  >
    <ups-dynamic-helper-label
      [config]="config"
      [dynamicService]="dynamicService"
    ></ups-dynamic-helper-label>
    @for (prop of data; track $index) {
    <div>
      <label class="k-label">
        {{ prop?.label }}:
        <strong>{{ prop?.value }}</strong>
      </label>
    </div>
    }
    <div
      class="form-builder-mask"
      [class.hidden]="config?.options?.hidden"
      *ngIf="config?.isFormBuilder"
    ></div>
  </div>
  @if (config?.isFormBuilder) {
  <div class="form-builder-options">
    <i class="fas fa-times ml-3 cursor-pointer c-red" (click)="delete()"></i>
    <!-- <i class="fas fa-bars ml-3 cursor-grab "></i> -->
  </div>
  } }
</div>
