import { BaseModel } from '../base.model';

export class PayStubDto extends BaseModel {
  public hrRef: number;
  public prStartDate: Date;
  public prEndDate: Date;
  public hours: number;
  public attachmentId: number;
  public employee: number;
  public prCo: number;
  public paySeq: number;

  /** Client side helper field only */
  public isDownloading: boolean;

  constructor(o: any = null) {
    super(o);
  }
}
