import {
  Directive,
  HostListener,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { isDate } from 'util';
import { parseDate } from '@telerik/kendo-intl';
import { DatePipe } from '@angular/common';

@Directive({
  selector: '[allowShortYearFormat]',
})
export class AllowShortYearFormatDirective {
  @Input() ngModel: any;
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  @Output() dateChanged = new EventEmitter<{
    singleDate: {
      date: {
        year: number;
        day: number;
        month: number;
      };
      jsDate: Date;
      formatted: string;
      epoc: number;
    };
  }>();

  constructor(private el: ElementRef, private datePipe: DatePipe) {}

  @HostListener('change') onChange() {
    // For kendo-datepicker component
    if (
      this.ngModel &&
      isDate(this.ngModel) &&
      this.ngModel.getFullYear() < 100
    ) {
      this.ngModelChange.emit(
        new Date(
          this.ngModel.getFullYear() + 2000,
          this.ngModel.getMonth(),
          this.ngModel.getDate()
        )
      );
    }
    // For my-date-picker component
    else if (
      this.el.nativeElement &&
      this.el.nativeElement.tagName == 'MY-DATE-PICKER'
    ) {
      let input = this.el.nativeElement.querySelector(
        'input'
      ) as HTMLInputElement;

      let dateObject =
        parseDate(input.value, 'M/d/yy') || parseDate(input.value, 'M/d/yyyy');
      if (dateObject && isDate(dateObject)) {
        input.value = this.datePipe.transform(dateObject, 'MM/dd/yyyy');
        input.classList.remove('invaliddate');

        let event = {
          singleDate: {
            date: {
              year: dateObject.getFullYear(),
              day: dateObject.getDate(),
              month: dateObject.getMonth(),
            },
            jsDate: dateObject,
            formatted: input.value,
            epoc: dateObject.getTime(),
          },
        };

        this.dateChanged.emit(event);
      }
    }
  }
}
