import { InventoryEntry } from './InventoryEntry';

export class Location {
  location: string;
  percentComplete: number;
  inventoryEntries: InventoryEntry[];

  constructor(location: string) {
    this.location = location;
    this.percentComplete = 0.0;
    this.inventoryEntries = new Array<InventoryEntry>();
  }
}
