import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
  WorkflowCreationModel,
  WorkflowRegistration,
} from '@ups/xplat/core';

const CREATE_WORKFLOW_VERSION = 'api/workflow/create';
const GET_MY_APPROVALS = 'api/workflow/my-verifications';
const COMPLETE_APPROVAL = 'api/workflow/verification';
const GET_WORKFLOW_METADATA_ODATA = (query) => `api/workflow?${query}`;
const GET_WORKFLOW_DETAIL = (workflowId) => `api/workflow/detail/${workflowId}`;
const GET_WORKFLOW_TEMPLATE = (workflowId) =>
  `api/workflow/latest-template/${workflowId}`;

const REGISTER_FOR_WORKFLOW = 'api/workflow/register';

@Injectable({ providedIn: 'root' })
export class WorkflowsService {
  private http: HttpClient;

  constructor(private httpClientFactory: MyHttpClientFactory) {
    this.http = httpClientFactory.createHttpClient(
      environment.urls.tasksAPI,
      true,
      ResponseCasingEnum.CamelCase
    );
  }

  createWorkflowVersion(template: WorkflowCreationModel) {
    return this.http.post(CREATE_WORKFLOW_VERSION, template);
  }

  getWorkflowMetadata(odataQuery: string) {
    return this.http.get(GET_WORKFLOW_METADATA_ODATA(odataQuery));
  }

  getWorkflowDetail(workflowId: string) {
    return this.http.get(GET_WORKFLOW_DETAIL(workflowId));
  }

  getLatestVersionTemplate(workflowId: string) {
    return this.http.get(GET_WORKFLOW_TEMPLATE(workflowId));
  }

  registerForWorkflow(registration: WorkflowRegistration) {
    return this.http.post(REGISTER_FOR_WORKFLOW, registration);
  }

  getMyActiveVerifications() {
    return this.http.get<any[]>(GET_MY_APPROVALS);
  }

  completeVerification(verified: boolean, workflowInstanceId: string) {
    return this.http.post(COMPLETE_APPROVAL, {
      verified,
      workflowInstanceId,
    });
  }
}
