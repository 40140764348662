import { saveAs } from '@progress/kendo-file-saver';
import {
  Component,
  EventEmitter,
  Host,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { SecurityFeatureModel } from '@ups/security';
import { BaseComponent, MyHttpClientFactory } from '@ups/xplat/core';
import { State } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { TableviewComponent } from '../tableview/tableview.component';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MessageHelper } from '@ups/xplat/web/core';
import { HotkeyOptions } from '@ups/xplat/hotkeys';

@Component({
  selector: 'ups-grid-toolbar',
  templateUrl: './grid-toolbar.component.html',
})
export class GridToolbarComponent extends BaseComponent {
  @Input() xlsxDownloadUri: string;
  @Input() xlsxDownloadMethod = 'GET';
  @Input() xlsxSelectedDownloadUri: string;
  @Input() isExportEnabled = false;
  @Input() isExportButtonEnabled = false;
  @Input() isExportSelectedButtonEnabled = false;
  @Output() reloadClick: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() addWarehouseClick: EventEmitter<unknown> =
    new EventEmitter<unknown>();
  @Output() addSublocationClick: EventEmitter<unknown> =
    new EventEmitter<unknown>();
  @Output() clearFiltersClick: EventEmitter<unknown> =
    new EventEmitter<unknown>();
  @Input() showClearFilters = true;
  @Input() showActions = false;
  @Input() actions = [];

  @Input() showHotkeys = false;
  @Input() hotkeyOptions: HotkeyOptions;

  @Input() multiSearchValue: string;
  @Input() multiSearchColumns: string[] = [];
  @Input() multiSearchPlaceholder: string;
  @Input() multiSearchTitle: string;
  @Output()
  multiSearchValueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  multiSearchChange: EventEmitter<CompositeFilterDescriptor> = new EventEmitter<CompositeFilterDescriptor>();

  @Input() tableViewKey: string;
  @Input() tableViewSecurity: SecurityFeatureModel;
  @Input() tableViewExcludedColumns: string[] = [];
  @Input() tableViewCustomSettings: unknown;
  @Input() tableViewCustomOptions: { text: string; value: boolean }[] = [];
  @Input() showColumnChooser = true;
  @Input() forceColumnChooser = false;
  @Input() gridState: State;
  @Output() gridStateChange: EventEmitter<State> = new EventEmitter<State>();
  @Output() customSettingsChange: EventEmitter<unknown> =
    new EventEmitter<unknown>();

  @Input() showAdd = false;
  @Input() isAddDisabled = false;
  @Input() addText = 'Add';
  @Output() addClick: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Input() showEdit = false;
  @Input() isEditDisabled = false;
  @Input() editText = 'Edit';
  @Input() editWarning = '';
  @Output() editClick: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Input() showDelete = false;
  @Input() isDeleteDisabled = false;
  @Input() deleteText = 'Delete';
  @Output() deleteClick: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Input() showCopy = false;
  @Input() isCopyDisabled = false;
  @Input() copyText = 'Copy';
  @Output() copyClick: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Input() useDeleteConfirm = true;
  @Input() deleteConfirmMessage =
    'Do you really want to Delete the selected record(s)?';
  @Input() deleteConfirmTitle = 'Delete';
  @Input() deleteConfirmYesText = 'Yes, Delete!';
  @Input() deleteConfirmNoText = 'No';

  @Input() isCondensed = false;
  @Input() wrapWords = false;
  @Input() showWrapText = true;

  @Input() showPDF = false;
  @Input() showPDFImages = false;
  @Input() isPDFDisabled = false;

  @Output() wrapWordsChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Output() deleteConfirmClick: EventEmitter<unknown> =
    new EventEmitter<unknown>();

  @Output() pdfClick: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() pdfImageClick: EventEmitter<unknown> = new EventEmitter<unknown>();

  @ViewChild(TableviewComponent) tableView: TableviewComponent;
  @ViewChild(ConfirmationDialogComponent)
  confirmDialog: ConfirmationDialogComponent;
  http: HttpClient;
  isFileDownloading = false;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    @Host() private grid: GridComponent,
    private clientFactory: MyHttpClientFactory,
    private messageHelper: MessageHelper
  ) {
    super();
    this.http = this.clientFactory.createHttpClient(null, true);
  }

  updateXlsxHref(e) {
    e.target.setAttribute('href', this.xlsxDownloadUri);
  }

  downloadXlsxFile(selected: boolean = false): void {
    if (!this.xlsxDownloadUri) {
      this.isFileDownloading = false;
      return;
    }

    this.isFileDownloading = true;

    let url = selected ? this.xlsxSelectedDownloadUri : this.xlsxDownloadUri;
    let formData: FormData;

    if (this.xlsxDownloadMethod?.toLowerCase() !== 'get') {
      // here we move the 'report' query parameter from the url to http body - as URL on chrome supports maximum number of characters around 2000 and query can be longer
      const urlParts = url.split('?', 2);
      const queryParams = urlParts[1].split('&');
      url = urlParts[0];
      formData = new FormData();
      let isFirstParam = true;

      for (const queryParam of queryParams) {
        const parts = queryParam.split('=', 2);
        if (parts[0].toLowerCase() !== 'report') {
          url += (isFirstParam ? '?' : '&') + queryParam;
          isFirstParam = false;
        } else {
          formData.append(
            'report',
            decodeURIComponent(parts[1].replace(/\+/g, ' '))
          );
        }
      }
    }

    this.http
      .request(this.xlsxDownloadMethod, url, {
        body: formData,
        observe: 'response',
        responseType: 'blob',
      })
      .subscribe(
        (response: HttpResponse<Blob>) => {
          const fileName =
            response.headers.get('ups-file-name') ??
            response.headers
              .get('Content-Disposition')
              ?.split(';')[1]
              ?.split('=')[1] ??
            'report.xlsx';
          saveAs(response.body, fileName);
          this.isFileDownloading = false;
        },
        (error: unknown) => {
          this.messageHelper.error(error.toString());
          this.isFileDownloading = false;
        }
      );
  }

  extraFilterStateChange(filter: CompositeFilterDescriptor) {
    this.multiSearchValueChange.emit(this.multiSearchValue);
    this.multiSearchChange.emit(filter);
  }

  handleDeleteClick() {
    if (this.useDeleteConfirm) {
      this.confirmDialog.open(this.deleteConfirmMessage, () =>
        this.deleteConfirmClick.emit()
      );
    } else {
      this.deleteClick.emit();
    }
  }

  clearFilters() {
    this.clearFiltersClick.emit();
    if (this.gridState) {
      this.gridState.filter = {
        logic: 'and',
        filters: [],
      } as CompositeFilterDescriptor;

      if (this.multiSearchValue === '') {
        this.gridStateChange.emit(this.gridState);
      } else {
        this.multiSearchValue = '';
        this.multiSearchValueChange.emit(this.multiSearchValue);
      }
    }
  }
}
