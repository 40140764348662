export class CustomExportDto {
  public customExportID: string;
  public customExportFieldGroupID: string;
  public name: string;
  public fields: string[];
  public jsonConfig: string;
  public bHeaderRow: boolean;
  public bPublic: boolean;
  public bActive: boolean;
  public createdUserID: string;
  public createdBy: string;
}
