<kendo-dialog
  *ngIf="showDialog"
  width="50%"
  (close)="onClose()"
  #dialog="kendoDialog"
  id="announcementPopup"
>
  <kendo-dialog-titlebar>
    <div class="container-fluid">
      <div class="row">
        <div class="col text-center">
          {{ announcements[visibleIdx].headerText }}
        </div>
      </div>
    </div>
  </kendo-dialog-titlebar>

  <div class="release-body">
    <div class="row">
      <div
        class="col"
        [innerHTML]="
          domSanitizer.bypassSecurityTrustHtml(
            announcements[visibleIdx].shortDescription
          )
        "
      ></div>
    </div>

    <div class="row">
      <div class="col d-flex">
        <a
          *ngFor="let f of announcements[visibleIdx].documents"
          class="file btn btn-outline-primary"
          target="_blank"
          href="{{
            releaseService.getDownloadUrl(
              f.location,
              f.fileName,
              helpDownloadToken,
              true
            )
          }}"
        >
          <i class="fas fa-file"></i>
          <span class="d-block">{{ f.description }}</span>
        </a>
      </div>
    </div>

    <div
      class="row see-details d-flex"
      *ngIf="
        announcements[visibleIdx].longContent &&
        announcements[visibleIdx].longContent.length > 0
      "
      (click)="toggleDetails()"
    >
      <div class="left-icon">
        <i class="fas fa-chevron-{{ showDetails ? 'up' : 'down' }}"></i>
      </div>

      <div class="line"></div>

      <div class="verbiage">
        {{ showDetails ? 'Hide Details' : 'See Details' }}
      </div>

      <div class="line"></div>

      <div class="right-icon">
        <i class="fas fa-chevron-{{ showDetails ? 'up' : 'down' }}"></i>
      </div>
    </div>

    <div
      class="details"
      [ngClass]="{ collapsed: !showDetails }"
      [innerHTML]="announcements[visibleIdx].longContent"
    ></div>
  </div>
  <div
    class="arrows-container d-flex align-items-center justify-content-between"
  >
    <div
      class="left-arrow me-auto"
      (click)="previousAnnouncement()"
      *ngIf="visibleIdx > 0"
    >
      <div class="fa-stack fa-2x">
        <i class="fas fa-stop fa-stack-2x"></i>
        <i class="fas fa-angle-double-left fa-inverse fa-stack-1x"></i>
      </div>
    </div>
    <div
      class="right-arrow ms-auto"
      (click)="nextAnnouncement()"
      *ngIf="announcements.length > visibleIdx + 1"
    >
      <div class="fa-stack fa-2x">
        <i class="fas fa-stop fa-stack-2x"></i>
        <i class="fas fa-angle-double-right fa-inverse fa-stack-1x"></i>
        <i
          *ngIf="unseenAnnouncement"
          class="fas fa-circle fa-inverse fa-stack-1x unseen-indicator"
        ></i>
      </div>
    </div>
  </div>
</kendo-dialog>
