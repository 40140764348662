<div class="properties-editor">
  <div class="pe-field">
    <label for="label">Label</label>
    <input kendoTextBox [(ngModel)]="peLabel" id="label" class="form-input" />
  </div>

  <div class="pe-field" *ngFor="let option of peOptions; let idx = index">
    <div class="d-flex">
      <i
        class="far fa-trash delete me-2"
        (click)="removeItemFromArr(peOptions, idx)"
      ></i>
      <input
        kendoTextBox
        [value]="peOptions[idx]"
        (change)="changePropertyDescriptor($event, idx)"
        id="radio-{{ idx }}"
        name="radio-{{ idx }}"
        class="form-input"
      />
    </div>
  </div>
  <div class="pe-field">
    <label>
      New Property
      <i class="far fa-plus ms-2" (click)="addBlankRadio()"></i>
    </label>
  </div>

  <div class="k-form-buttons dr-prop-actions">
    <button class="k-button k-primary" (click)="onApplyClick()">Apply</button>

    <i class="far fa-trash delete" (click)="onDeleteClick()"></i>
  </div>
</div>
