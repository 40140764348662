import { SystemTaskCompletion } from './../systemTaskCompletion.model';
import { TaskConstants } from './../task-constants';
import { Task } from '../task.model';
import { SystemTaskRegistration } from '../systemTaskRegistration.model';

export class EmployeeFitCertValidation {
  public get employeeFullName(): string {
    return this.task.parameterDto.parameters[0];
  }

  public set employeeFullName(value: string) {
    this.task.parameterDto.parameters[0] = value;
  }

  public get hrRef(): number {
    return this.task.parameterDto.parameters[1];
  }

  public set hrRef(value: number) {
    this.task.parameterDto.parameters[1] = value;
  }

  public get testDate(): Date | string {
    return this.task.parameterDto.parameters[2];
  }

  public set testDate(value: Date | string) {
    this.task.parameterDto.parameters[2] = value;
  }

  public get expirationDate(): Date | string {
    return this.task.parameterDto.parameters[3];
  }

  public set expirationDate(value: Date | string) {
    this.task.parameterDto.parameters[3] = value;
  }

  public get fileId(): string {
    return this.task.parameterDto.parameters[4];
  }

  public set fileId(value: string) {
    this.task.parameterDto.parameters[4] = value;
  }

  public get fileName(): string {
    return this.task.parameterDto.parameters[5];
  }

  public set fileName(value: string) {
    this.task.parameterDto.parameters[5] = value;
  }

  public get certificationCode(): string | null {
    return this.task.parameterDto.parameters[6];
  }

  public set certificationCode(value: string | null) {
    this.task.parameterDto.parameters[6] = value;
  }

  public get isFitTestValidation(): boolean {
    return (
      this.task.systemTaskId?.toUpperCase() ===
      TaskConstants.systemTasksTypes.employee_fit_test_verification.toUpperCase()
    );
  }

  public set isFitTestValidation(value: boolean) {
    this.task.systemTaskId = value
      ? TaskConstants.systemTasksTypes.employee_fit_test_verification
      : null;
  }

  public get isCertificationValidation(): boolean {
    return (
      this.task.systemTaskId?.toUpperCase() ===
      TaskConstants.systemTasksTypes.employee_certification_verification.toUpperCase()
    );
  }

  public set isCertificationValidation(value: boolean) {
    this.task.systemTaskId = value
      ? TaskConstants.systemTasksTypes.employee_certification_verification
      : null;
  }

  public get isApproved(): boolean | null {
    return this.task.parameterDto.outputs[0];
  }

  public set isApproved(value: boolean | null) {
    this.task.parameterDto.outputs[0] = value;
  }

  public get notes(): string | null {
    return this.task.parameterDto.outputs[1];
  }

  public set notes(value: string | null) {
    this.task.parameterDto.outputs[1] = value;
  }

  public get relatedUserId(): string | null {
    return this.task.relatedUserId;
  }

  public set relatedUserId(value: string | null) {
    this.task.relatedUserId = value;
  }

  /** Client side helper method to track if attachment  is being downloaded */
  public isFileDownloading = false;

  /** Client side helper method to render test type in the Kendo Grid and allow filtering */
  public get validationTypeName(): string {
    return this.isFitTestValidation
      ? 'Fit test'
      : this.isCertificationValidation
      ? 'Certification'
      : null;
  }

  public task: Task;

  constructor(task: Task) {
    if (!task) {
      const parametersArray = new Array(6);
      task = {
        userId: null,
        relatedUserId: null,
        assignmentGroupId:
          TaskConstants.assignmentGroups.fitTestAndCertApprovers,
        parameterDto: {
          parameterId: null,
          systemTaskId: null,
          parameters: parametersArray,
          outputs: new Array(2).concat(parametersArray),
          urlParameters: [],
          descriptionParameters: [],
        },
        systemTaskTypeDto: {},
      } as Task;
    }

    this.task = task;
  }

  public createSystemTaskRegistration(): SystemTaskRegistration {
    return {
      systemTaskId: this.task.systemTaskId,
      userId: null,
      assignmentGroupId: this.task.assignmentGroupId,
      relatedUserId: this.task.relatedUserId,
      parameters: this.task.parameterDto.parameters,
      descriptionParameters: [this.employeeFullName, this.hrRef],
      urlParameters: null,
    };
  }

  public createSystemTaskComplete(): SystemTaskCompletion {
    return {
      systemTaskId: this.task.systemTaskId,
      parameters: this.task.parameterDto.parameters,
      output: [this.isApproved, this.notes].concat(
        this.task.parameterDto.parameters
      ),
    };
  }
}
