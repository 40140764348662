<kendo-dialog [hidden]="!opened" (close)="close()" [width]="650">
  <kendo-dialog-titlebar>
    <span>{{ isEdit ? 'Update' : 'Add' }} Subtask</span>
  </kendo-dialog-titlebar>

  <ng-container
    *ngIf="
      security.getFeatureById(
        securityConstants.employee_portal_job_requirements_trainingsubtasksgroups
      ).viewFeature
    "
  >
    <div class="mb-3 row align-items-center">
      <kendo-label class="col-4" text="Group"></kendo-label>
      <div class="col-8">
        <kendo-combobox
          [data]="subRequirementGroups"
          [allowCustom]="true"
          textField="name"
          valueField="id"
          [valuePrimitive]="true"
          [(ngModel)]="model.ManPowerSubRequirementGroupID"
          [valueNormalizer]="groupValueNormalizer"
        ></kendo-combobox>
      </div>
    </div>

    <div class="mb-3 row align-items-center">
      <kendo-label class="col-4" text="Viewpoint Training"></kendo-label>
      <div class="col-8">
        <kendo-dropdownlist
          [(ngModel)]="model.VPTrainingClassKeyID"
          [filterable]="true"
          valueField="keyId"
          textField="classDescription"
          [valuePrimitive]="true"
          [data]="localTrainingClasses"
          search-ex
          [seSearchColumns]="['classDescription']"
          (seFilterChangeEx)="filterChangeTrainingClass($event)"
          [seDebounceFilterChange]="150"
          [seUseCustomLocalFilter]="true"
          [seMinSearchLength]="2"
        ></kendo-dropdownlist>
      </div>
    </div>

    <div class="mb-3 row align-items-center">
      <kendo-label class="col-4" text="Requested Class"></kendo-label>
      <div class="col-8">
        <kendo-multiselect
          [(ngModel)]="selectedTradeClasses"
          [data]="tradeClasses"
          textField="requestedClassName"
          valueField="tradeClassId"
        ></kendo-multiselect>
      </div>
    </div>
  </ng-container>

  <div class="mb-3 row align-items-center">
    <kendo-label class="col-4" text="Subtask Name"></kendo-label>
    <div class="col-8">
      <input
        [disabled]="false"
        [required]="true"
        [(ngModel)]="model.Name"
        class="form-control k-input"
        kendoTextBox
        maxlength="100"
        name="name"
        type="text"
      />
    </div>
  </div>

  <div class="mb-3 row">
    <div class="offset-4 col-8">
      <input
        kendoCheckBox
        [(ngModel)]="model.bMandatory"
        id="mandatoryCheckbox"
        type="checkbox"
      />
      <kendo-label
        class="k-checkbox-label"
        for="mandatoryCheckbox"
        text="Mandatory"
      ></kendo-label>
    </div>
  </div>

  <div class="mb-3 row">
    <div class="offset-4 col-8">
      <input
        kendoCheckBox
        [(ngModel)]="model.bBillable"
        id="billableCheckbox"
        type="checkbox"
      />
      <kendo-label
        class="k-checkbox-label"
        for="billableCheckbox"
        text="Billable"
      ></kendo-label>
    </div>
  </div>

  <div class="mb-3 row align-items-center">
    <kendo-label class="col-4" text="Safety Council"></kendo-label>
    <div class="col-8">
      <kendo-dropdownlist
        [data]="[safetyCouncil]"
        [disabled]="false"
        [filterable]="true"
        [required]="false"
        [seDebounceFilterChange]="150"
        [seMinSearchLength]="0"
        [seSearchColumns]="['Name']"
        [textField]="'Name'"
        [valueField]="'Id'"
        [valuePrimitive]="false"
        [(ngModel)]="safetyCouncil"
        (seFilterChangeEx)="filterChangeSafetyCouncil($event)"
        (valueChange)="valueChangeSafetyCouncil($event)"
        class="k-no-dropdown w-100"
        name="safetyCouncil"
        search-ex
      >
        <ng-template kendoDropDownListItemTemplate let-dataItem>
          <span>{{ dataItem?.Name }}</span>
        </ng-template>
        <ng-template kendoDropDownListValueTemplate let-dataItem>
          <span *ngIf="dataItem">{{ dataItem?.Name }}</span>
        </ng-template>
      </kendo-dropdownlist>
    </div>
  </div>

  <div class="mb-3 row align-items-center">
    <kendo-label class="col-4" text="Notes"></kendo-label>
    <div class="col-8">
      <input
        [disabled]="false"
        [(ngModel)]="model.Notes"
        class="form-control k-input"
        kendoTextBox
        maxlength="100"
        name="email"
        type="text"
      />
    </div>
  </div>

  <kendo-dialog-actions>
    <button (click)="close()" class="k-button">Cancel</button>
    <button
      [disabled]="!isValid()"
      (click)="onAdd()"
      class="k-button k-primary"
    >
      {{ isEdit ? 'Update' : 'Add' }}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
