export class FeatureSwitch {
  // whether the Angular GlobalErrorHandler which catches all errors is enabled
  // often flipping to false is better for local web development in browser
  // it should remain `true` for all production deployments
  static globalErrorHandlerEnabled = true;
  // various global settings:
  static biometricGracePeriod = 2; // seconds
  static enablePasscode = true;
  static enableSignalR = false;
  // how many post/put http requests can be made while offline
  static offlineActionLimit = 20;
  static offlineTimeLimit = 1 * 60 * 60 * 1000; // 1 hour
  // mobile apps don't need a time limit or action limit typically but this can be enabled if needed
  static enableMobileOfflineLimits = false;
}
