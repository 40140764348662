import { Directive, Input, OnInit } from '@angular/core';
import { RouterLinkActive } from '@angular/router';

/**
 * Because: https://github.com/angular/angular/issues/31930 - routerLinkActive broken in ng-template Angular 8.
 * We introduced PatchContextRouterLinkActiveDirective, which made routerLinkActive details available directly on the item.
 *
 * It makes the routerLinkActive variable from the angular environment available...
 * ...for parent controls in cases when we use ng-template to define anchor tags!
 *
 *
 * USAGE:
 *
 *  <div [class.active]="someVariableThatWillBeExtendedWithProperty_routerLinkActive['_routerLinkActive']?.isActive">
 *    <ng-container *ngTemplateOutlet="navLink; context: { $implicit: someVariableThatWillBeExtendedWithProperty_routerLinkActive }">
 *    </ng-container>
 *  </div>
 *
 *  <ng-template #navLink let-dataItem let-context>
 *    <div class="k-item-container">
 *      <a class="routing"
 *        routerLink="{{dataItem.url}}"
 *        [routerLinkActiveOptions]="dataItem.exact ? { exact: true } : {}"
 *
 *        routerLinkActive
 *        [patch-context-routerLinkActive]="context"
 *        >
 *      </a>
 *      <i class="{{ 'fas' + (dataItem.icon ? ' ' + dataItem.icon : '') }} fa-fw"></i>{{dataItem.title}}
 *    </div>
 *  </ng-template>
 *
 */
@Directive({
  selector: '[patch-context-routerLinkActive]',
})
export class PatchContextRouterLinkActiveDirective implements OnInit {
  @Input('patch-context-routerLinkActive') context: any;

  constructor(private routerLinkActive: RouterLinkActive) {}

  ngOnInit() {
    this.context._routerLinkActive = this.routerLinkActive;
  }
}
