import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  bufferTime,
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { HelpManagementService } from '../services';
import { HelpDeskService } from '../services/help-desk.service';

import { routerNavigatedAction } from '@ngrx/router-store';

import { HelpActions } from './help.actions';
import { HelpState } from './help.state';

@Injectable()
export class HelpEffects {
  createOrUpdateHelpDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HelpActions.saveHelpDoc),
      switchMap(({ doc }) =>
        this.helpManagementService.createUpdateWalkthrough(doc).pipe(
          mergeMap((x) => {
            doc = {
              ...doc,
              id: x,
            };
            return [
              HelpActions.saveHelpDocSuccess({ doc }),
              HelpActions.loadedDocuments({ docs: [doc] }),
              HelpActions.loadDocumentsForDisplay(),
            ];
          })
        )
      ),
      catchError((x) => of(HelpActions.saveHelpDocError(x)))
    )
  );

  documentsLoadForPaths$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HelpActions.loadDocumentsForPaths),
      switchMap(({ documentType, paths }) =>
        this.helpManagementService
          .fetchWalkthroughDocumentsByPaths(documentType, ...paths)
          .pipe(mergeMap((x) => [HelpActions.loadedDocuments({ docs: x })]))
      )
    )
  );

  documentsLoadForCurrentlyDisplayedIconsOnShowTooltips = createEffect(() =>
    this.actions$.pipe(
      ofType(HelpActions.toggleTooltips),
      filter(({ state }) => state === true),
      withLatestFrom(this.store.select(HelpState.selectUIPaths)),
      switchMap(([, paths]) => of(HelpActions.loadDocumentsForPaths({ paths })))
    )
  );

  documentsLoadForCurrentlyDisplayedIcons = createEffect(() =>
    this.actions$.pipe(
      ofType(HelpActions.loadDocumentsForDisplay),
      withLatestFrom(this.store.select(HelpState.selectUIPaths)),
      switchMap(([, paths]) => of(HelpActions.loadDocumentsForPaths({ paths })))
    )
  );

  loadWalkthrough = createEffect(() =>
    this.actions$.pipe(
      ofType(HelpActions.loadWalkthrough),
      switchMap(({ documentId }) =>
        this.helpManagementService
          .fetchWalkthroughDocumentsByIds(documentId)
          .pipe(
            map((x) => x[0]),
            mergeMap((doc) => [
              HelpActions.loadWalkthroughSuccess({ document: doc }),
            ])
          )
      ),
      catchError((err) => of(HelpActions.loadWalkthroughError(err)))
    )
  );

  bufferUIPaths$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HelpActions.registerUIPath),
      map((x) => x.path),
      bufferTime(5000),
      filter((x) => x.length > 0),
      mergeMap(() => [HelpActions.loadDocumentsForDisplay()])
    )
  );

  bufferRemoveUIPaths$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HelpActions.deregisterUIPath),
      map((x) => x.path),
      bufferTime(5000),
      filter((x) => x.length > 0),
      mergeMap((paths) => [HelpActions.bulkDeregisterUIPaths({ paths })])
    )
  );

  sendHelpTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HelpActions.sendHelpDeskTicket),
      switchMap((submission) =>
        this.helpDeskService
          .submitHelpdeskTicket(submission)
          .pipe(
            mergeMap(() => [
              HelpActions.sendHelpDeskTicketSuccess(),
              HelpActions.hideHelpDeskModal(),
            ])
          )
      ),
      catchError((err) => of(HelpActions.sendHelpDeskTicketError(err)))
    )
  );

  deleteDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HelpActions.deleteDocument),
      switchMap(({ document }) =>
        this.helpManagementService
          .deleteWalkthrough(document)
          .pipe(mergeMap(() => [HelpActions.deleteDocumentSuccess()]))
      ),
      catchError((err) => of(HelpActions.deleteDocumentError(err)))
    )
  );

  registerRouteDocumentPath$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      withLatestFrom(this.store.select(HelpState.selectUrlPath)),
      mergeMap(([{ payload }, oldPath]) => [
        HelpActions.setUrlPath({ path: payload.event.urlAfterRedirects }),
        HelpActions.registerUIPath({ path: payload.event.urlAfterRedirects }),
        HelpActions.deregisterUIPath({ path: oldPath }),
      ])
    )
  );

  resumeCurrentWalkthrough$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      withLatestFrom(this.store.select(HelpState.selectCurrentWalkthrough)),
      withLatestFrom(this.store.select(HelpState.selectCurrentWalkthroughStep)),
      filter(
        ([[{ payload }, walkthrough], step]) =>
          walkthrough?.steps?.[step + 1]?.path ===
          payload.event.urlAfterRedirects
      ),
      mergeMap(([, step]) => [
        HelpActions.resumeWalkthrough(),
        HelpActions.goToWalkthroughStep({ step: step + 1 }),
      ])
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private helpManagementService: HelpManagementService,
    private helpDeskService: HelpDeskService
  ) {
    // actions$.subscribe((x) => console.log(x));
  }
}
