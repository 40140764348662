import { BaseModel } from '../../base.model';
import { ManPowerSubRequirementDto } from './ManPowerSubRequirementDto';

export class ManPowerRequirementDto extends BaseModel {
  ManPowerPlantRequirementID: string | null;
  ManPowerJobRequirementID: string | null;
  ManPowerRequirementID: string | null;
  RequirementName: string;
  RequirementPosition: number | null;
  PlantID: number | null;
  ManPowerReqID: string | null;
  TypeID: string | null;
  TypeName: string;
  bActive: boolean;
  bBillable: boolean;
  bWaiver: boolean;
  WaiverDays: number | null;
  DisaPolicyID: string | null;
  bNonDisa: boolean;
  Description: string;
  SubRequirements: ManPowerSubRequirementDto[];
  VpCompanyIDs: (number | null)[];

  // Client Side Only
  FullRequirementName: string;

  constructor(o: any = null) {
    super(o);
  }
}
