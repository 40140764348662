import { BaseModel } from '../../../base.model';

export class TimeSheetDto extends BaseModel {
  public customerPO: string;
  public dateCreated: string;
  public dateExported: string;
  public designationID: number;
  public jobID: string;
  public notes: string;
  public timeSheetID: string;
  public timeSheetName: string;
  public totalCost: number;
  public weekEndingDate: Date;
  public timeSheetNameWithType: string;
  public hasLabor: boolean;
  public hasPerDiem: boolean;
  public hasMileage: boolean;
  public isRemovedFromPayroll: boolean;

  constructor(o = null) {
    super(o);
  }
}
