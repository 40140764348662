/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { EquipmentDispatchDto, EquipmentResourceDto } from '@ups/xplat/api/dto';
import { EquipmentService } from '@ups/xplat/api/services';
import { EquipmentDto } from '@ups/xplat/api/dto';
import { EquipmentRequestOverlapDto } from '@ups/xplat/api/dto';
import { ConfirmationDialogComponent } from '../../../ui/components/confirmation-dialog/confirmation-dialog.component';
import { EquipmentVpService, MessageHelper } from '@ups/xplat/web/core';
import { BaseComponent } from '@ups/xplat/core';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';

@Component({
  selector: 'ups-equipment-dispatch-edit',
  templateUrl: './equipment-dispatch-edit.component.html',
})
export class EquipmentDispatchEditComponent
  extends BaseComponent
  implements OnChanges
{
  @Input() canModify = false;
  @Input() jobID: string;
  @Input() jobVPCompanyID: number;
  @Input() billingTemplate: string;
  @Output() saveEquipmentDispatch: EventEmitter<EquipmentDispatchDto> =
    new EventEmitter<EquipmentDispatchDto>();

  @ViewChild(ConfirmationDialogComponent)
  confirmDialog: ConfirmationDialogComponent;

  opened = false;
  isEdit = false;
  isBulkEdit = false;
  isSaving = false;
  isNew = false; // we don't allow for now changing already dispatch equipment for another one
  showWarning = false;
  showButtons = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  savedModel: any;
  bulkEdit: boolean;
  equipment: EquipmentDto;
  equipmentResourceDto: EquipmentResourceDto;
  overlappingRequests: EquipmentRequestOverlapDto[];
  showResourceConfirm = false;
  model: EquipmentDispatchDto = new EquipmentDispatchDto({ bActive: true });

  selectedRecords: EquipmentDispatchDto[];

  constructor(
    private security: SecurityService,
    private msgHelper: MessageHelper,
    private equipmentService: EquipmentService,
    private equipmentVpService: EquipmentVpService
  ) {
    super();
    const fSecurity = this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_equipmentdispatchgrid
    );
    this.canModify = fSecurity.editAll;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isBulkEdit && changes.isBulkEdit.currentValue) {
      this.isBulkEdit = changes.isBulkEdit.currentValue;
    }
  }

  public open(model: EquipmentDispatchDto) {
    this.equipment = null;
    this.isBulkEdit = false;

    if (model) {
      this.savedModel = JSON.stringify(model);
      this.model = JSON.parse(this.savedModel);

      if (model.EquipmentIDs && model.EquipmentName) {
        const equipment: EquipmentDto = {
          EquipmentIDs: model.EquipmentIDs,
          DisplayName: model.EquipmentName,
          Name: model.Name,
          VPCompanyID: this.jobVPCompanyID,
          Order: null,
        };
        this.equipment = equipment;
      }
      this.isEdit = true;
      this.opened = true;
      this.isNew = false;
    } else {
      this.model = new EquipmentDispatchDto({ bActive: true });
      this.savedModel = JSON.stringify(this.model);
      this.isEdit = false;
      this.opened = true;
      this.isNew = true;
      this.equipment = null;
    }
  }

  public close() {
    if (this.savedModel !== JSON.stringify(this.model)) {
      this.confirmDialog.open(
        'Do you really want to Discard Unsaved Changes?',
        () => (this.opened = false)
      );
      return;
    }
    this.opened = false;
    this.equipment = null;
    this.showResourceConfirm = false;
    this.model = new EquipmentDispatchDto({ bActive: true });
  }

  reloadOverlappingEquipmentRequests() {
    this.overlappingRequests = null;
    this.showButtons = false;
    this.showWarning = false;

    if (
      !this.isNew ||
      !this.equipment ||
      !this.model ||
      !this.equipment.VPCompanyID ||
      !this.equipment.Name
    ) {
      return;
    }

    this.equipmentService
      .getOverlappingEquipmentRequestsByName(
        this.equipment.Name,
        this.equipment.VPCompanyID,
        this.model.StartDate,
        this.model.EndDate
      )
      .toPromise()
      .then((o) => {
        this.overlappingRequests = o;
        if (o && o.length) {
          this.showButtons = true;
          this.showWarning = true;
        }
      });
  }

  reloadEquipmentResource() {
    this.equipmentResourceDto = null;
    this.showResourceConfirm = false;

    if (
      !this.equipment ||
      !this.model ||
      !this.equipment.VPCompanyID ||
      !this.equipment.Name
    ) {
      return;
    }

    this.showResourceConfirm = true;

    this.equipmentVpService
      .getEquipmentResource(
        this.equipment.VPCompanyID.toString(),
        this.equipment.Name
      )
      .toPromise()
      .then((v) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.equipmentResourceDto = v.value[0] as any;
      });
  }

  reloadEquipmentInfo(): void {
    this.reloadEquipmentResource();
    this.reloadOverlappingEquipmentRequests();
  }

  valueChangeEquipment(selectedEquipment: EquipmentDto): void {
    if (selectedEquipment) {
      this.equipment = selectedEquipment;
      this.model.EquipmentName = selectedEquipment.DisplayName;
      this.model.Name = selectedEquipment.Name;
      this.model.EquipmentIDs = selectedEquipment.EquipmentIDs;
    } else {
      this.equipment = null;
      this.model.EquipmentName = null;
      this.model.Name = null;
      this.model.EquipmentIDs = [];
    }

    this.reloadEquipmentInfo();
  }

  isValid() {
    if (this.isBulkEdit) return this.model.EndDate != null;
    else return this.model && this.jobID && this.equipment;
  }

  onSave() {
    if (this.isBulkEdit) {
      this.onSaveBulkEdit();
    } else {
      if (!this.isValid()) {
        return;
      }

      if (
        this.model.StartDate &&
        this.model.EndDate &&
        this.model.StartDate > this.model.EndDate
      ) {
        this.msgHelper.error('End Date cannot be before Start Date!');
        return;
      }

      this.isSaving = true;

      this.model.JobID = this.jobID;

      this.equipmentService
        .saveEquipmentDispatch(this.model)
        .toPromise()
        .then(
          (httpOkData) => {
            this.isSaving = false;
            this.model = httpOkData;

            this.opened = false;
            this.saveEquipmentDispatch.next(this.model);
            this.msgHelper.success(
              'Equipment Dispatch ' +
                (this.isEdit ? 'updated' : 'created') +
                ' successfully!'
            );
          },
          (error) => {
            this.isSaving = false;
            const e = error.error;
            this.msgHelper.error(
              (e.messages && e.messages.length && e.messages.join('|')) || error
            );
          }
        );
    }
  }

  onSaveBulkEdit() {
    this.isSaving = true;

    this.model.JobID = this.jobID;

    this.selectedRecords.forEach((record: EquipmentDispatchDto) => {
      record.JobID = this.jobID;
      record.EndDate = this.model.EndDate;
    });

    this.equipmentService
      .bulkEditEquipmentDispatch(this.selectedRecords)
      .subscribe({
        complete: () => {
          this.isSaving = false;
          this.model = new EquipmentDispatchDto({ bActive: true });
          this.equipment = null;
          this.opened = false;

          this.msgHelper.success(
            'Equipment Dispatch ' +
              (this.isEdit ? 'updated' : 'created') +
              ' successfully!'
          );
        },
        error: (error) => {
          this.isSaving = false;
          const e = error.error;
          this.msgHelper.error(
            (e.messages && e.messages.length && e.messages.join('|')) || error
          );
        },
      });
  }

  handleBlur(date, element) {
    if (date == null) {
      element.input.dateInput.nativeElement.value = null;
    }
  }

  confirmEquipment() {
    this.showButtons = false;
    this.showWarning = false;
  }

  discardEquipment() {
    this.showWarning = false;
    this.equipment = null;
    this.reloadEquipmentInfo();
  }

  onBulkEdit(vpCompanyId: number, selectedRecords: EquipmentDispatchDto[]) {
    this.selectedRecords = selectedRecords;

    const equipment: EquipmentDto = {
      EquipmentIDs: [],
      DisplayName: null,
      Name: null,
      VPCompanyID: vpCompanyId,
      Order: null,
    };
    this.equipment = equipment;
    this.isEdit = true;
    this.isBulkEdit = true;
    this.opened = true;
    this.isNew = false;
    this.showResourceConfirm = false;
  }
}
