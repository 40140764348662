import {
  EquipmentCorrectionsDto,
  EquipmentJobHistoryDto,
  EquipmentRequestCreateUpdateDto,
  EquipmentUsageCorrectionDto,
  IODataResult,
} from '@ups/xplat/api/dto';
import { Injectable } from '@angular/core';
import { parseData } from '@ups/xplat/utils';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EquipmentTypeDto } from '@ups/xplat/api/dto';
import { EquipmentRequirementDto } from '@ups/xplat/api/dto';
import { EquipmentDispatchDto } from '@ups/xplat/api/dto';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import { EquipmentDashboardDetailDto } from '@ups/xplat/api/dto';
import { EquipmentResourceActiveRequestsSummaryDto } from '@ups/xplat/api/dto';
import { map } from 'rxjs/operators';
import { EquipmentRequestGridDto } from '@ups/xplat/api/dto';
import { EquipmentScoreCardDropdownItemDto } from '@ups/xplat/api/dto';
import { EquipmentDto } from '@ups/xplat/api/dto';
import { EquipmentRequestOverlapDto } from '@ups/xplat/api/dto';
import { DatePipe } from '@angular/common';
import { EquipmentDashboardGridDto } from '@ups/xplat/api/dto';
import { EquipmentUsageDto } from '@ups/xplat/api/dto';

const GET_EQUIPMENT_REQUEST_GRID_DATA =
  '/api/equipment/equipment-request-odata';
const GET_EXPORT_ALL_EQUIPMENT_REQUEST_DATA =
  '/api/equipment/equipmentRequestGridExportAll';
const SAVE_EQUIPMENT_REQUEST_DETAILS = '/api/equipment/equipment-request';
const GET_EQUIPMENT_DISPATCH_STATUSES = '/api/equipment/dispatchStatuses';
const GET_EQUIPMENT_TYPES_DROPDOWN = '/api/equipment/types-dropdown';
const GET_EQUIPMENT_TYPES = '/api/equipment/types';
const GET_EQUIPMENT_TYPE = '/api/equipment/type';
const SAVE_EQUIPMENT_TYPE = '/api/equipment/equipment-type';
const GET_EQUIPMENT_REQUIREMENT_GRID_DATA =
  '/api/equipment/equipment-requirement-odata';
const SAVE_EQUIPMENT_REQUIREMENT = '/api/equipment/requirement';
const GET_EQUIPMENT_DISPATCH_GRID_DATA =
  '/api/equipment/equipment-dispatch-odata';
const GET_EQUIPMENT_TEMPLATE_HISTORY =
  '/api/equipment/templateHistory/{:jobID}';
const GET_EQUIPMENTS = '/api/equipments/{:jobID}';
const SAVE_EQUIPMENT_DISPATCH = '/api/equipment/equipment-dispatch';
const GET_EQUIPMENT_DISPATCH_HISTORY =
  '/api/equipment/dispatchHistory/{:jobID}/{:equipmentDispatchID}';
const GET_EQUIPMENT_BILLING_RATES = '/api/equipment/rates';
const GET_EQUIPMENT_DASHBOARD_DATA_IN_PROCESS =
  '/api/equipment/dashboard-odata/inprocess/{:assetControlManagerID}';
const GET_EQUIPMENT_DASHBOARD_DATA_DISPATCHED =
  '/api/equipment/dashboard-odata/dispatched/{:assetControlManagerID}';
const GET_ASSET_CONTROL_MANAGERS = '/api/equipment/assetControlManagers';
const GET_EQUIPMENT_DASHBOARD_DATA_BY_JOB =
  '/api/equipment/dashboard-odata/job/{:jobNumber}';
const GET_EQUIPMENT_JOB_HISTORY = '/api/equipment/jobHistory/{:vpCompanyID}';
const GET_CURRENT_EQUIPMENT_REQUESTS =
  '/api/equipment/requestHistory/{:vpCompanyID}';
const GET_EQUIPMENT_SCHEDULE_DATA = '/api/equipment/schedule/{:vpCompanyID}';
const GET_EQUIPMENT_DASHBOARD_DETAIL =
  '/api/equipment/dashboard/detail/{:equipmentrequestDetailID}';
const GET_EQUIPMENT_DISPATCHES = '/api/equipment/{:equipmentID}/dispatches';
const SAVE_EQUIPMENT_REQUEST_DETAIL = '/api/equipment/dashboard/detail';
const GET_OVERLAPPING_REQUESTS =
  '/api/equipment/{:equipmentID}/overlappingRequests/{:equipmentRequestDetailID}';
const GET_OVERLAPPING_REQUESTS_BY_EQUIPMENTNAME =
  '/api/equipment/overlappingRequests';
const GET_EQUIPMENT_RESOURCE_REQUEST_SUMMARY =
  'api/equipment/equipment-resource-request-summary-odata';
const GET_EQUIPMENT_SCORE_CARD_DROPDOWNS_DATA =
  '/api/equipment/scorecard-dropdowns-data';
const GET_SCORE_CARD_DATA_BY_WEEKS =
  '/api/equipment/equipment-request-chart-data-by-weeks-equipment/{:weeksCount}';
const BULK_EDIT_EQUIPMENTS = '/api/equipment/resource/bulkEdit';
const BULK_EDIT_EQUIPMENT_REQUESTS = '/api/equipment/request/bulkEdit';
const GET_EQUIPMENT_CORRECTIONS = '/api/equipmentUsage/corrections/';
const GET_DISPATCHED_EQUIPMENTS = '/api/equipment/dispatch/{:jobID}';
const REALLOCATE_EQUIPMENT_USAGE = '/api/equipmentUsage/reallocate';
const GET_EQUIPMENT_SEARCH_DATA = '/api/equipment/search/{:jobID}';
const GET_EQUIPMENT_CORRECTIONS_DATA = '/api/equipment/corrections';

// TODO disabled no-explicit-any
/* eslint-disable  @typescript-eslint/no-explicit-any */

@Injectable({ providedIn: 'root' })
export class EquipmentService {
  protected httpCamelCase: HttpClient;

  /** Parses properties in incoming SpartaAPI DTOs as Pascal/CamelCased - uses new UPS Common Angular 8 MyHttpClientFactory */
  protected httpPascalCase: HttpClient;

  /** Parses properties with default casing as comes from the API */
  protected http: HttpClient;
  protected datePipe: DatePipe = new DatePipe('en-US');

  constructor(private clientFactory: MyHttpClientFactory) {
    const urlBase = environment.urls.spartaAPI;
    this.httpCamelCase = this.clientFactory.createHttpClient(
      urlBase,
      true,
      ResponseCasingEnum.CamelCase
    );
    this.httpPascalCase = this.clientFactory.createHttpClient(
      urlBase,
      true,
      ResponseCasingEnum.PascalCase
    ); // Using pascal case as odata has bug where GroupBy statement with orderBy is case sensitive and the backend is PascalCased
    this.http = this.clientFactory.createHttpClient(
      urlBase,
      true,
      ResponseCasingEnum.Default
    );
  }

  public queryEquipmentRequestData(
    odataString: string
  ): Observable<IODataResult<EquipmentRequestGridDto>> {
    let url = GET_EQUIPMENT_REQUEST_GRID_DATA;

    url += '?$format=json&$count=true';
    url += odataString ? '&' + odataString : '';

    return this.httpPascalCase.get(url) as Observable<
      IODataResult<EquipmentRequestGridDto>
    >;
  }

  public fetchScoreCardDropdownsData(): Observable<
    EquipmentScoreCardDropdownItemDto[]
  > {
    const url = GET_EQUIPMENT_SCORE_CARD_DROPDOWNS_DATA;

    return this.httpPascalCase.get(url) as Observable<
      EquipmentScoreCardDropdownItemDto[]
    >;
  }

  public queryScoreCardChartDataByWeeks(
    weeksCount: number,
    queryString: string
  ): Observable<any> {
    let url = GET_SCORE_CARD_DATA_BY_WEEKS.split('{:weeksCount}').join(
      weeksCount.toString()
    );

    url += queryString;

    return this.httpPascalCase.get(url);
  }

  public exportAllData(): Observable<any> {
    return this.httpPascalCase
      .get(GET_EXPORT_ALL_EQUIPMENT_REQUEST_DATA)
      .pipe(map(parseData));
  }

  public saveEquipmentRequestDetails(
    items: EquipmentRequestCreateUpdateDto[]
  ): Observable<EquipmentRequestCreateUpdateDto> {
    return this.httpCamelCase.post<EquipmentRequestCreateUpdateDto>(
      SAVE_EQUIPMENT_REQUEST_DETAILS,
      items
    );
  }

  public getEquipmentDispatchStatuses(): Observable<any> {
    return this.httpPascalCase
      .get(GET_EQUIPMENT_DISPATCH_STATUSES)
      .pipe(map(parseData));
  }

  public getEquipmentTypesDropdown(
    companyID: string,
    odataString: string
  ): Observable<any> {
    let url = GET_EQUIPMENT_TYPES_DROPDOWN;

    url += companyID ? `?companyID=${companyID}&` : '?';
    url += '$format=json&$count=true';
    url += odataString ? '&' + odataString : '';

    return this.httpPascalCase.get(url).pipe(map(parseData));
  }

  public queryEquipmentTypeData(
    companyID: string | null,
    odataString: string
  ): Observable<IODataResult<EquipmentTypeDto>> {
    let url = GET_EQUIPMENT_TYPES;

    url += companyID ? `?companyID=${companyID}&` : '?';
    url += '$format=json&$count=true';
    url += odataString ? '&' + odataString : '';

    return this.httpPascalCase.get(url) as Observable<
      IODataResult<EquipmentTypeDto>
    >;
  }

  public getEquipmentType(
    equipmentTypeID: string = null
  ): Observable<EquipmentTypeDto> {
    let url = GET_EQUIPMENT_TYPE;

    if (equipmentTypeID) {
      url += `?equipmentTypeID=${equipmentTypeID}`;
    }

    return this.http.get(url) as Observable<EquipmentTypeDto>;
  }

  public saveEquipmentType(
    dto: EquipmentTypeDto
  ): Observable<EquipmentTypeDto> {
    return this.httpCamelCase.post(
      SAVE_EQUIPMENT_TYPE,
      dto
    ) as Observable<EquipmentTypeDto>;
  }

  getEquipmentDispatchUri(
    jobID: string,
    template: string,
    odataString: string
  ): string {
    const url = GET_EQUIPMENT_DISPATCH_GRID_DATA;
    const queryParameters: string[] = [];
    if (jobID) {
      queryParameters.push(`jobID=${jobID}`);
    }
    if (template) {
      queryParameters.push(`template=${template}`);
    }
    if (odataString) {
      queryParameters.push('$format=json');
      queryParameters.push('$count=true');
      queryParameters.push(odataString);
    }
    return url + '?' + queryParameters.join('&');
  }

  getEquipmentDispatchUriInclServer(
    jobID: string,
    template: string,
    odataString: string = null
  ): string {
    return (
      environment.urls.spartaAPI +
      this.getEquipmentDispatchUri(jobID, template, odataString)
    );
  }

  public queryEquipmentDispatchData(
    jobID: string,
    template: string,
    odataString: string
  ): Observable<any> {
    const url = this.getEquipmentDispatchUri(jobID, template, odataString);
    return this.httpPascalCase.get(url).pipe(map(parseData));
  }

  public getEquipmentTemplateHistory(jobID: string): Observable<any> {
    const url = GET_EQUIPMENT_TEMPLATE_HISTORY.split('{:jobID}').join(jobID);

    return this.httpPascalCase.get(url).pipe(map(parseData));
  }

  public getEquipments(
    jobID: string,
    billingTemplate: string,
    equipmentTypeID: string,
    searchString: string,
    take: number = 0,
    searchNameOnly: boolean,
    includeOtherCompanies: boolean
  ): Observable<EquipmentDto[]> {
    const url = GET_EQUIPMENTS.split('{:jobID}').join(jobID);
    let queryParams = '?';

    if (searchString) {
      queryParams += 'searchString=' + escape(searchString);
    }

    if (billingTemplate) {
      queryParams +=
        (queryParams.length > 1 ? '&' : '') +
        'billingTemplate=' +
        escape(billingTemplate).replace('/', '%2F');
    }

    if (includeOtherCompanies) {
      queryParams +=
        (queryParams.length > 1 ? '&' : '') +
        'includeOtherCompanies=' +
        includeOtherCompanies;
    }

    if (equipmentTypeID) {
      queryParams +=
        (queryParams.length > 1 ? '&' : '') +
        'equipmentTypeID=' +
        equipmentTypeID;
    }

    if (searchNameOnly) {
      queryParams +=
        (queryParams.length > 1 ? '&' : '') +
        'searchNameOnly=' +
        searchNameOnly;
    }

    if (take) {
      queryParams += (queryParams.length > 1 ? '&' : '') + 'take=' + take;
    }

    return this.httpPascalCase.get(url + queryParams).pipe(map(parseData));
  }

  public queryEquipmentRequirementData(
    equipmentTypeID: string,
    odataString: string
  ): Observable<IODataResult<EquipmentRequirementDto[]>> {
    let url = GET_EQUIPMENT_REQUIREMENT_GRID_DATA;

    url += equipmentTypeID ? `?equipmentTypeID=${equipmentTypeID}&` : '?';
    url += '$format=json&$count=true';
    url += odataString ? '&' + odataString : '';

    return this.httpPascalCase.get(url).pipe(map(parseData));
  }

  public saveEquipmentRequirement(
    dto: EquipmentRequirementDto
  ): Observable<EquipmentRequirementDto> {
    return this.http.post(
      SAVE_EQUIPMENT_REQUIREMENT,
      dto
    ) as Observable<EquipmentRequirementDto>;
  }

  public saveEquipmentDispatch(dto: EquipmentDispatchDto): Observable<any> {
    return this.httpPascalCase.post(SAVE_EQUIPMENT_DISPATCH, dto);
  }

  public bulkEditEquipmentDispatch(
    dto: EquipmentDispatchDto[]
  ): Observable<any> {
    return this.httpCamelCase.post(`${SAVE_EQUIPMENT_DISPATCH}/bulk`, dto);
  }

  public getEquipmentDispatchHistory(
    jobID: string,
    equipmentDispatchID: string
  ): Observable<any> {
    const url = GET_EQUIPMENT_DISPATCH_HISTORY.split('{:jobID}')
      .join(jobID)
      .split('{:equipmentDispatchID}')
      .join(equipmentDispatchID);

    return this.httpPascalCase.get(url).pipe(map(parseData));
  }

  public getEquipmentBillingRates(
    jobID: string,
    billingTemplate: string,
    equipmentName: string
  ): Observable<any> {
    const url = GET_EQUIPMENT_BILLING_RATES;

    // API uses Pascal Case
    return this.httpPascalCase
      .post(url, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        JobID: jobID,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        BillingTemplate: billingTemplate,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        EquipmentName: equipmentName,
      })
      .pipe(map(parseData));
  }

  public queryEquipmentDashboardInProcessData(
    assetControlManagerID: string,
    odatastring: string
  ): Observable<any> {
    let url = GET_EQUIPMENT_DASHBOARD_DATA_IN_PROCESS.split(
      '{:assetControlManagerID}'
    ).join(assetControlManagerID);

    url += '?$format=json&$count=true';
    url += odatastring ? '&' + odatastring : '';

    return this.httpPascalCase.get(url).pipe(map(parseData));
  }

  public queryEquipmentDashboardDispatchedData(
    assetControlManagerID: string,
    odatastring: string
  ): Observable<any> {
    let url = GET_EQUIPMENT_DASHBOARD_DATA_DISPATCHED.split(
      '{:assetControlManagerID}'
    ).join(assetControlManagerID);

    url += '?$format=json&$count=true';
    url += odatastring ? '&' + odatastring : '';

    return this.httpPascalCase.get(url).pipe(map(parseData));
  }

  public queryEquipmentDashboardByJobData(
    jobNumber: string,
    odatastring: string
  ): Observable<any> {
    let url =
      GET_EQUIPMENT_DASHBOARD_DATA_BY_JOB.split('{:jobNumber}').join(jobNumber);

    url += '?$format=json&$count=true';
    url += odatastring ? '&' + odatastring : '';

    return this.httpPascalCase.get(url).pipe(map(parseData));
  }

  public getAssetControlManagers(): Observable<any> {
    return this.httpCamelCase
      .get(GET_ASSET_CONTROL_MANAGERS)
      .pipe(map(parseData));
  }

  public getPlantRequirements(plantID: number): Observable<any> {
    return this.httpCamelCase
      .get(`/api/equipment/plantRequirement/${plantID}`)
      .pipe(map(parseData));
  }

  public getEquipmentJobHistory(
    vpCompanyID: string,
    equipmentName: string
  ): Observable<EquipmentJobHistoryDto[]> {
    const url =
      GET_EQUIPMENT_JOB_HISTORY.split('{:vpCompanyID}')
        .join(vpCompanyID)
        .split('{:equipmentName}') +
      `?equipmentName=${escape(equipmentName).replace('/', '%2F')}`;

    return this.httpPascalCase.get(url).pipe(map(parseData));
  }

  public getCurrentEquipmentRequests(
    vpCompanyID: string,
    equipmentName: string
  ): Observable<any> {
    const url =
      GET_CURRENT_EQUIPMENT_REQUESTS.split('{:vpCompanyID}')
        .join(vpCompanyID)
        .split('{:equipmentName}') +
      `?equipmentName=${escape(equipmentName).replace('/', '%2F')}`;

    return this.httpPascalCase.get(url).pipe(map(parseData));
  }

  public getEquipmentScheduleData(
    vpCompanyID: string,
    equipmentName: string
  ): Observable<any> {
    const url =
      GET_EQUIPMENT_SCHEDULE_DATA.split('{:vpCompanyID}')
        .join(vpCompanyID)
        .split('{:equipmentName}') +
      `?equipmentName=${escape(equipmentName).replace('/', '%2F')}`;

    return this.httpCamelCase.get(url).pipe(map(parseData));
  }

  public getEquipmentDashboardDetail(
    equipmentrequestDetailID: string
  ): Observable<EquipmentDashboardDetailDto> {
    const url = GET_EQUIPMENT_DASHBOARD_DETAIL.split(
      '{:equipmentrequestDetailID}'
    ).join(equipmentrequestDetailID);

    return this.httpCamelCase
      .get(url)
      .pipe(map((o) => new EquipmentDashboardDetailDto(o)));
  }

  public saveEquipmentRequestDetail(
    dto: EquipmentDashboardDetailDto
  ): Observable<any> {
    return this.httpCamelCase.post(SAVE_EQUIPMENT_REQUEST_DETAIL, dto);
  }

  public getEquipmentDispatches(equipmentID: string): Observable<any> {
    const url =
      GET_EQUIPMENT_DISPATCHES.split('{:equipmentID}').join(equipmentID);

    return this.httpCamelCase.get(url).pipe(map(parseData));
  }

  public getOverlappingEquipmentRequests(
    equipmentRequestDetailID: string,
    equipmentID: string
  ): Observable<EquipmentRequestOverlapDto[]> {
    const url = GET_OVERLAPPING_REQUESTS.split('{:equipmentRequestDetailID}')
      .join(equipmentRequestDetailID ? equipmentRequestDetailID : '')
      .split('{:equipmentID}')
      .join(equipmentID);

    return this.httpPascalCase.get(url).pipe(map(parseData));
  }

  public getOverlappingEquipmentRequestsByName(
    equipmentName: string,
    vpCompanyID: number,
    startDate: Date,
    endDate: Date
  ): Observable<EquipmentRequestOverlapDto[]> {
    let url = GET_OVERLAPPING_REQUESTS_BY_EQUIPMENTNAME;

    const queryStringParameters: string[] = [];

    if (startDate) {
      queryStringParameters.push(
        'startDate=' + this.datePipe.transform(startDate, 'MM/dd/y')
      );
    }

    if (endDate) {
      queryStringParameters.push(
        'endDate=' + this.datePipe.transform(endDate, 'MM/dd/y')
      );
    }

    if (equipmentName) {
      queryStringParameters.push(
        'equipmentName=' + escape(equipmentName).replace('/', '%2F')
      );
    }

    if (vpCompanyID) {
      queryStringParameters.push('vpCompanyID=' + vpCompanyID.toString());
    }

    if (queryStringParameters.length) {
      url = url + '?' + queryStringParameters.join('&');
    }

    return this.httpPascalCase.get(url).pipe(map(parseData));
  }

  public getEquipmentResourceRequestSummary(
    equipmentKeyIds: number[]
  ): Observable<IODataResult<EquipmentResourceActiveRequestsSummaryDto>> {
    const url = GET_EQUIPMENT_RESOURCE_REQUEST_SUMMARY;
    return this.httpPascalCase.post(url, equipmentKeyIds) as Observable<
      IODataResult<EquipmentResourceActiveRequestsSummaryDto>
    >;
  }

  public bulkEditEquipments(dto): Observable<any> {
    const url = BULK_EDIT_EQUIPMENTS;

    return this.httpCamelCase.put(url, dto);
  }

  public bulkEditEquipmentRequests(
    dtos: EquipmentDashboardGridDto[]
  ): Observable<any> {
    const url = BULK_EDIT_EQUIPMENT_REQUESTS;

    return this.httpCamelCase.put(url, dtos);
  }

  public getEquipmentCorrections(
    equipmentUsageID: string
  ): Observable<EquipmentUsageCorrectionDto[]> {
    const url = GET_EQUIPMENT_CORRECTIONS + equipmentUsageID;
    return this.httpPascalCase.get<EquipmentUsageCorrectionDto[]>(url);
  }

  public getDispatchedEquipments(
    jobID: string,
    date: string,
    template: string = null
  ): Observable<any> {
    const url = GET_DISPATCHED_EQUIPMENTS.split('{:jobID}').join(jobID);

    let queryParams = '';
    if (date) {
      queryParams += '?date=' + date;
    }

    if (template) {
      queryParams += `${queryParams.length ? '&' : '?'}template=${template}`;
    }

    return this.httpCamelCase.get(url + queryParams);
  }

  public reallocateEquipmentUsage(dtos: EquipmentUsageDto[]): Observable<any> {
    return this.httpCamelCase.post(REALLOCATE_EQUIPMENT_USAGE, dtos);
  }

  public queryEquipmentSearchData(
    jobID: string,
    odataString: string
  ): Observable<IODataResult<EquipmentUsageDto>> {
    let url = GET_EQUIPMENT_SEARCH_DATA.split('{:jobID}').join(jobID);

    url += '?$format=json&$count=true';
    url += odataString ? '&' + odataString : '';

    return this.httpPascalCase.get(url).pipe(map(parseData));
  }

  public queryEquipmentCorrectionsData(
    companyIDs: number[],
    jobIDs: string[],
    odataString: string,
    dateFrom: string = null,
    dateTo: string = null,
    allData: boolean = false
  ): Observable<IODataResult<EquipmentCorrectionsDto>> {
    let url = GET_EQUIPMENT_CORRECTIONS_DATA;

    url += '?$format=json&$count=true';

    if (allData) {
      url += `&allData=true`;
    }

    if (companyIDs && companyIDs.length) {
      companyIDs.forEach((i) => {
        url += `&companyIDs=${i}`;
      });
    }

    if (jobIDs) {
      jobIDs.forEach((i) => {
        url += `&jobIDs=${i}`;
      });
    }

    if (dateFrom) {
      url += `&dateFrom=${dateFrom}`;
    }

    if (dateTo) {
      url += `&dateTo=${dateTo}`;
    }

    url += odataString ? '&' + odataString : '';

    return this.httpPascalCase.get(url).pipe(map(parseData));
  }
}
