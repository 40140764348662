import { BaseModel } from '../base.model';

export class EquipmentFixedAssetHistoryDto extends BaseModel {
  public EquipmentName: string;
  public Company: number;
  public Seq: number;
  public TransferCompany: number | null;
  public TransferStatus: number | null;
  public TransferDate: Date | null;
  public TransferStatusName: string | null;

  constructor(o: any = null) {
    super(o);
  }
}
