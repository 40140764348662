/* eslint-disable @typescript-eslint/naming-convention */
export const zipCodeRegex = {
  US: {
    expr: /^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] *\d[A-Z]\d)$/,
    message: 'Please enter a valid Zipcode of USA (eg. 12345 or 12345-6789)',
  },
  CA: {
    expr: /^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]) {0,1}(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/,
    message: 'Please enter a valid Zipcode of Canada',
  },
  UK: {
    expr: /^([Gg][Ii][Rr] 0[Aa]{2}|([A-Za-z][0-9]{1,2}|[A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2}|[A-Za-z][0-9][A-Za-z]|[A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]) {0,1}[0-9][A-Za-z]{2})$/,
    message: 'Please enter a valid Zipcode of United Kingdom',
  },
};
