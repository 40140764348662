import { BaseModel } from '../base.model';
import { FileDataModel } from './fileData-model';

export class SafetyModel extends BaseModel {
  isNewItem?: boolean;
  editing?: boolean;
  showFiles?: boolean;
  isSaving?: boolean;

  // HRRef is profile bound, so we even might omit it...
  KeyID: number;
  HRRef: number;

  Description: string = '';
  Institution: string = '';
  ClassName: string = '';

  TrainingDate: Date;
  CompleteDate: Date;
  udExpireDate: Date;

  Files: FileDataModel[] = [];
  bActive: boolean = true;
  editable: boolean;
  Delete: boolean = false;

  ExpDate: any;
  Location: any;

  constructor(o: any = null) {
    super(o);
  }
}
