import { Injectable } from '@angular/core';
import { ManPowerService } from '@ups/xplat/base/manpower';
import { ApplicationModel } from '@ups/xplat/api/dto';

/* eslint-disable */
@Injectable({
  providedIn: 'root',
})
export class JobRequirementStatusResolver {
  constructor(
    private manPowerService: ManPowerService,
    private applicationModel: ApplicationModel
  ) {}

  public load(): Promise<any> {
    if (this.applicationModel.jobRequirementStatuses.length == 0)
      return this.manPowerService
        .fetchJobRequirementStatuses()
        .then(
          (data: any[]) => (this.applicationModel.jobRequirementStatuses = data)
        );
    else return Promise.resolve(this.applicationModel.jobRequirementStatuses);
  }
}
