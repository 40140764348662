// angular
import { Location } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
// libs
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { isNativeScript, isObject } from '@ups/xplat/utils';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
// module
import { LocaleService } from '../../services/locale.service';
import { LogService } from '../../services/log.service';
import { ProgressService } from '../../services/progress.service';
import { RouterToken } from '../../services/tokens';
import { WindowService } from '../../services/window.service';
// import { UIService } from '../../services/ui.service';
import { LocalStorageService } from '../../services/storage.service';
import { AppActions } from '../core.state';
import { DrawerActions } from './drawer/drawer.action';
import { LocaleActions } from './locale/locale.action';
import { ProgressIndicatorActions } from './progress-indicator/progress-indicator.action';
import { RouterActions } from '../router/router.action';
import { UIActions } from './ui.action';

/* eslint-disable */
@Injectable()
export class UIEffects {
  localeSet$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocaleActions.Types.SET),
      map((action: LocaleActions.Set) => {
        this._localeService.locale = action.payload;
        this._translateService.use(action.payload);
        return new LocaleActions.SetSuccess(action.payload);
      })
    )
  );

  localeSetSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocaleActions.Types.SET_SUCCESS),
      map((action: LocaleActions.SetSuccess) => {
        return new UIActions.Changed({
          locale: action.payload,
        });
      })
    )
  );

  progressShow$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(ProgressIndicatorActions.Types.SHOW),
      map((action: ProgressIndicatorActions.Show) => {
        let payload = action.payload;
        if (!action.payload) {
          // if no payload, default to showing page level progress loader
          payload = {
            page: {
              enabled: true,
            },
          };
        }
        return new UIActions.Changed({
          progressIndicator: payload,
        });
      })
    )
  );

  progressHide$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(ProgressIndicatorActions.Types.HIDE),
      map((action: ProgressIndicatorActions.Hide) => {
        // .map((action: ProgressIndicatorActions.HideAction) => {
        let payload = action.payload;
        if (!action.payload) {
          // if no payload, default to hiding page level progress loader
          payload = {
            page: {
              enabled: false,
            },
          };
        }
        return new UIActions.Changed({
          progressIndicator: payload,
        });
      })
    )
  );

  navigate$ = createEffect(() =>
    this._actions$.pipe(
      ofType(RouterActions.Types.GO),
      map((action: RouterActions.Go) => {
        const path = action.payload.path;
        let extras = action.payload.extras;
        if (!this._router) {
          // on web we just rely on injecting @angular/router
          // avoids having to create a factory to create it in core.module
          // on mobile, this will be defined as RouterExtensions
          this._router = this._ngRouter;
        }
        if (LogService.DEBUG.LEVEL_4) {
          this._log.debug(
            `${RouterActions.Types.GO} from: `,
            this._ngRouter.url
          );
          this._log.debug(
            `${RouterActions.Types.GO} to: `,
            isObject(path) ? JSON.stringify(path) : path
          );
        }
        extras = extras ? { ...extras } : undefined; // must be explicitly undefined (angular issue)
        this._ngZone.run(() => {
          this._router.navigate(path, extras);
        });
        return new AppActions.Noop();
      })
    )
  );

  navigateBack$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RouterActions.Types.BACK),
        tap(() => this._location.back())
      ),
    { dispatch: false }
  );

  navigateForward$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RouterActions.Types.FORWARD),
        tap(() => this._location.forward())
      ),
    { dispatch: false }
  );

  openDrawer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DrawerActions.Types.OPEN),
      map(() => {
        return new UIActions.Changed({
          drawer: {
            open: true,
          },
        });
      })
    )
  );

  closeDrawer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DrawerActions.Types.CLOSE),
      map(() => {
        return new UIActions.Changed({
          drawer: {
            open: false,
          },
        });
      })
    )
  );

  localeInit$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocaleActions.Types.INIT),
      startWith(new LocaleActions.Init()),
      map(() => {
        // We don't want to set a default language since it pulls the extra data,
        // but leaving this here to document that and show where we would if anyone searches
        // this._translateService.setDefaultLang('en');
        return new LocaleActions.Set(this._localeService.locale);
      })
    )
  );

  private _isNativeScript = false;

  constructor(
    private _store: Store,
    private _actions$: Actions,
    private _log: LogService,
    private _ngZone: NgZone,
    private _win: WindowService,
    private _localeService: LocaleService,
    private _translateService: TranslateService,
    // instantiates service automatically
    // even though not used this helps ensure single instance is created on boot
    private _progress: ProgressService,
    // private _uiService: UIService,
    @Inject(RouterToken)
    private _router: any,
    private _ngRouter: Router,
    private _location: Location,
    private _storageService: LocalStorageService
  ) {
    this._isNativeScript = isNativeScript();
  }
}
