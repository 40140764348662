/* eslint-disable @typescript-eslint/no-explicit-any */

import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { AuthService } from '@auth0/auth0-angular';

import { SignalRService } from './signalr.service';
import { environment } from '../environments';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  public notificationRecieved$ = new Subject<any>();
  public notificationClosed$ = new Subject<any>();

  private notificationIndex = 0;
  private notificationDetailsDtos;

  constructor(protected auth: AuthService, protected signalR: SignalRService) {
    if (environment.featuresSwitch.enableSignalR) {
      // // set-up hub
      // const urlBase = environment.urls.securityAPI.replace(/^\/+|\/+$/, ''); // trim starting slashes of the partial url
      // this.setupHubConnection(urlBase);

      // authentication related stuff
      this.auth.isAuthenticated$.subscribe((loggedIn) => {
        if (loggedIn) {
          this.signalR.start();
          // this.startHubConnection();
        } else {
          this.signalR.stop();
          // this.stopHubConnection();
        }
      });

      this.signalR.on('onConnectionListReceived', () => {
        // NOTE: just a testing function
        // this.logger.info("hub connection list received", hubConnectionData);
      });

      this.signalR.on('onNotificationsReceived', (notificationDetailsDtos) => {
        this.notificationDetailsDtos = [...notificationDetailsDtos];
        this.showFollowingNotification();
      });

      this.signalR.on('onNotificationDismissed', (notificationDetailsDto) => {
        // this.logger.info("a notification was dismissed by one of the client instances", notificationDetailsDto);
        this.closeNotification(notificationDetailsDto);
      });

      this.signalR.on('onNotificationSnoozed', (notificationDetailsDto) => {
        // this.logger.info("a notification was snoozed by one of the client instances", notificationDetailsDto);
        this.closeNotification(notificationDetailsDto);
      });
    }
  }

  public showNotification(notificationDto: any) {
    this.notificationRecieved$.next(notificationDto);
    this.markNotificationsAsViewed(notificationDto.id);
  }

  public closeNotification(notificationDto: any) {
    this.notificationClosed$.next(notificationDto);
  }

  //
  // Actions
  //

  public requestConnectionList = () => {
    this.signalR.invoke('requestConnectionList');
  };

  public markNotificationsAsViewed(notificationIds: string[]) {
    this.signalR.invoke('requestMarkNotificationAsViewed', notificationIds);
  }

  public dismissNotification(notificationId) {
    this.signalR.invoke('requestDismissNotification', notificationId);
    this.showFollowingNotification();
  }

  public snoozeNotification(notificationId, snoozeTimeInSeconds = 300) {
    this.showFollowingNotification();
    this.signalR.invoke('requestSnoozeNotification', [
      notificationId,
      snoozeTimeInSeconds,
    ]);
  }

  private showFollowingNotification() {
    if (this.notificationDetailsDtos.length > this.notificationIndex) {
      this.showNotification(
        this.notificationDetailsDtos[this.notificationIndex]
      );
      this.notificationIndex++;
    }
  }
}
