<div
  id="tableview-container"
  *ngIf="
    securityFeature === null ||
    securityFeature.viewFeature ||
    securityFeature.editAll ||
    securityFeature.editSelf ||
    securityFeature.viewFeature ||
    securityFeature.editAll ||
    securityFeature.editSelf
  "
>
  <div *ngIf="!showAsDropdown" id="view-label" class="pe-2 pt-1">
    <small>View:</small>
  </div>
  <kendo-button-group *ngIf="!showAsDropdown">
    <kendo-chip
      *ngIf="
        securityFeature && (securityFeature.editAll || securityFeature.editSelf)
      "
      [label]="selectedTableView ? selectedTableView.Name : 'Default'"
      title="Show Available Views"
      (click)="open()"
    ></kendo-chip>
    <kendo-chip
      *ngIf="
        securityFeature && (securityFeature.editAll || securityFeature.editSelf)
      "
      [label]="'Save'"
      title="Save View"
      (click)="openSave()"
      [disabled]="!isSaveEnabled()"
    ></kendo-chip>
    <kendo-chip
      *ngIf="
        securityFeature && (securityFeature.editAll || securityFeature.editSelf)
      "
      [label]="'Create'"
      title="Create View"
      (click)="openCreate()"
    ></kendo-chip>
  </kendo-button-group>

  <kendo-dropdownbutton
    *ngIf="showAsDropdown"
    [data]="menuData"
    title="Custom Views"
  >
    <span class="font-weight-normal" help-anchor="customView">
      <i class="fa fa-eye me-1"></i>
      {{ selectedTableView ? selectedTableView.Name : 'Default' }}
    </span>
    <ng-template kendoDropDownButtonItemTemplate let-dataItem>
      <span class="fa fa-{{ dataItem.icon }}"></span>
      <span>{{ dataItem.text }}</span>
    </ng-template>
  </kendo-dropdownbutton>
</div>

<kendo-dialog
  modal
  fade
  [hidden]="!showSaveTableViewDialog"
  (close)="showSaveTableViewDialog = false"
>
  <kendo-dialog-titlebar>
    <span>Save Current Table View</span>
  </kendo-dialog-titlebar>

  <div *ngIf="tableViewToEdit" style="width: 650px; padding-left: 10px">
    <div class="row form-group">
      <div class="col-sm-12">
        <label class="font-weight-bold">View Name</label>
        <input
          type="text"
          help-anchor="viewNameInput"
          maxlength="50"
          class="k-textbox"
          style="width: 75%; margin-left: 25px"
          name="newTableViewName"
          required
          [(ngModel)]="tableViewToEdit.Name"
          [disabled]="
            tableViewToEdit.TableViewID !== null ||
            (tableViewToEdit.bDefault && tableViewToEdit.bPublic)
          "
        />
      </div>
    </div>
    <div class="row form-group">
      <div class="col-sm-4">
        <label class="font-weight-bold">Public</label>
        <br />
        <kendo-switch
          [(ngModel)]="tableViewToEdit.bPublic"
          help-anchor="publicToggle"
          [onLabel]="'Yes'"
          [offLabel]="'No'"
          [disabled]="
            !securityFeature ||
            !securityFeature.editAll ||
            tableViewToEdit.bDefault
          "
        ></kendo-switch>
      </div>
      <div class="col-sm-4">
        <label class="font-weight-bold">Pre-selected</label>
        <br />
        <kendo-switch
          [(ngModel)]="bPreSelected"
          help-anchor="preSelectedToggle"
          [onLabel]="'Yes'"
          [offLabel]="'No'"
          [disabled]="tableViewToEdit.bDefault && tableViewToEdit.bPublic"
        ></kendo-switch>
      </div>
      <div class="col-sm-4">
        <label class="font-weight-bold">Save Grouping</label>
        <br />
        <kendo-switch
          [(ngModel)]="bSaveGrouping"
          help-anchor="saveGroupingToggle"
          [onLabel]="'Yes'"
          [offLabel]="'No'"
          [disabled]="tableViewToEdit.bDefault && tableViewToEdit.bPublic"
        ></kendo-switch>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-sm-4">
        <label class="font-weight-bold">Save Filtering</label>
        <br />
        <kendo-switch
          [(ngModel)]="bSaveFiltering"
          help-anchor="saveFilteringToggle"
          [onLabel]="'Yes'"
          [offLabel]="'No'"
          [disabled]="tableViewToEdit.bDefault && tableViewToEdit.bPublic"
        ></kendo-switch>
      </div>
      <div class="col-sm-4">
        <label class="font-weight-bold">Save Sorting</label>
        <br />
        <kendo-switch
          [(ngModel)]="bSaveSorting"
          help-anchor="saveSortingToggle"
          [onLabel]="'Yes'"
          [offLabel]="'No'"
          [disabled]="tableViewToEdit.bDefault && tableViewToEdit.bPublic"
        ></kendo-switch>
      </div>
      <div class="col-sm-4">
        <label
          class="font-weight-bold"
          title="Save Column Order and Visibility"
        >
          Save Column Settings
        </label>
        <br />
        <kendo-switch
          [(ngModel)]="bSaveColConfig"
          help-anchor="saveColumnSettingsToggle"
          [onLabel]="'Yes'"
          [offLabel]="'No'"
          [disabled]="tableViewToEdit.bDefault && tableViewToEdit.bPublic"
        ></kendo-switch>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-sm-4">
        <label
          class="font-weight-bold"
          title="Save Items per Page (Fewer items per page may allow data to load faster)"
        >
          Save Items Per Page
        </label>
        <br />
        <kendo-switch
          [(ngModel)]="bSaveItemsPerPage"
          help-anchor="saveItemsPerPageToggle"
          [onLabel]="'Yes'"
          [offLabel]="'No'"
          [disabled]="tableViewToEdit.bDefault && tableViewToEdit.bPublic"
        ></kendo-switch>
        <div class="pt-1" style="font-size: 12px">
          Fewer items per page may allow your dashboard to load faster.
        </div>
      </div>
      <div *ngFor="let opt of customOptions; let i = index" class="col-sm-4">
        <label class="font-weight-bold" title="{{ opt.text }}">
          {{ opt.text }}
        </label>
        <br />
        <kendo-switch
          [(ngModel)]="opt.value"
          help-anchor="{{ 'customOptToggle_' + i }}"
          [onLabel]="'Yes'"
          [offLabel]="'No'"
          [disabled]="tableViewToEdit.bDefault && tableViewToEdit.bPublic"
          (valueChange)="customOptionChange(opt)"
        ></kendo-switch>
        <div class="pt-1" style="font-size: 12px">
          {{ opt.desc }}
        </div>
      </div>
    </div>

    <div
      class="row form-group"
      *ngIf="tableViewToEdit.bDefault && tableViewToEdit.bPublic"
    >
      <div class="col">
        <label class="font-weight-bold">
          I understand that saving this will affect every user's default grid
          view.
        </label>
        <br />
        <kendo-switch
          [(ngModel)]="dangerAcknowledged"
          help-anchor="iUnderstandSettingsToggle"
          [onLabel]="'Yes'"
          [offLabel]="'No'"
        ></kendo-switch>
      </div>
    </div>
  </div>

  <kendo-dialog-actions *ngIf="tableViewToEdit" [layout]="'normal'">
    <button
      type="button"
      class="k-button k-primary"
      help-anchor="saveButton"
      (click)="saveGridSettings()"
      [disabled]="
        isSaving ||
        tableViewToEdit.Name.length === 0 ||
        (tableViewToEdit.bDefault &&
          tableViewToEdit.bPublic &&
          !dangerAcknowledged)
      "
    >
      Save
    </button>
    <button
      type="button"
      class="k-button"
      (click)="showSaveTableViewDialog = false"
    >
      Cancel
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog
  modal
  fade
  [hidden]="!showTableViewDialog"
  (close)="showTableViewDialog = false"
>
  <kendo-dialog-titlebar>
    <span>Table Views</span>
  </kendo-dialog-titlebar>

  <div style="width: 950px">
    <kendo-grid [data]="tableViews">
      <kendo-grid-column field="Name" title="Name" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [ngClass]="{ 'font-weight-bold': dataItem.bDefault }">
            {{ dataItem.Name }}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="bPublic"
        title="Public"
        [width]="70"
        [style]="{ 'text-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <i
            *ngIf="dataItem.bPublic"
            class="fa fa-check"
            style="color: #64bd63"
          ></i>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="bShared"
        title="Shared"
        [width]="70"
        [style]="{ 'text-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <i
            *ngIf="dataItem.bShared"
            class="fa fa-check"
            style="color: #64bd63"
          ></i>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="Name"
        title="Pre-selected"
        [width]="70"
        [style]="{ 'text-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <i
            *ngIf="dataItem.Name === preSelectedTableViewName"
            class="fa fa-check"
            help-anchor="checkPreSelected"
            style="color: #64bd63"
          ></i>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Actions" [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button
            type="button"
            help-anchor="loadButtonCustomViewWindow"
            class="btn btn-xs btn-success me-1"
            (click)="loadState(dataItem)"
          >
            Load
          </button>
          <button
            type="button"
            help-anchor="preSelectButton"
            class="btn btn-xs btn-info me-1"
            (click)="preSelect(dataItem)"
          >
            Pre-select
          </button>
          <button
            *ngIf="canShare && !dataItem.bShared"
            type="button"
            class="btn btn-xs btn-info me-1"
            help-anchor="shareButtonCustomView"
            (click)="tableViewToShare = dataItem; showShareDialog = true"
          >
            Share
          </button>
          <button
            *ngIf="
              dataItem.bActive &&
              !dataItem.bDefault &&
              securityFeature &&
              (securityFeature.editAll ||
                (securityFeature.editSelf && !dataItem.bPublic))
            "
            type="button"
            help-anchor="deleteButton"
            class="btn btn-xs btn-danger me-1"
            (click)="delete(dataItem)"
          >
            Delete
          </button>
          <button
            *ngIf="!dataItem.bActive"
            type="button"
            help-anchor="unDeleteButton"
            class="btn btn-xs btn-warning me-1"
            (click)="undelete(dataItem)"
          >
            UnDelete
          </button>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>

  <kendo-dialog-actions [layout]="'normal'">
    <button type="button" class="k-button" (click)="close()">Close</button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog
  modal
  fade
  [hidden]="!showShareDialog"
  (close)="showShareDialog = false"
>
  <kendo-dialog-titlebar>
    <span>{{ 'Share View - ' + tableViewToShare?.Name }}</span>
  </kendo-dialog-titlebar>

  <div class="d-flex" style="width: 600px">
    <div class="w-100">
      <kendo-combobox
        class="k-no-dropdown flex-fill w-100"
        help-anchor="searchFieldShareView"
        [name]="'employee'"
        [(ngModel)]="selectedEmployee"
        [valuePrimitive]="false"
        [textField]="'name'"
        [valueField]="'id'"
        [filterable]="true"
        placeholder="Enter the first 3 Letters to Search for an Employee"
        search-ex
        [seInitialData]="[]"
        [seSearchColumns]="['Name', 'VPPRId']"
        (seFilterChangeEx)="filterChangeEmployee($event)"
        [seDebounceFilterChange]="1000"
        [seMinSearchLength]="3"
        (valueChange)="valueChangeEmployee()"
      ></kendo-combobox>
    </div>
    <button
      class="k-button ms-5"
      help-anchor="shareButtonShareView"
      [disabled]="!selectedEmployee || isSaving"
      (click)="shareView()"
    >
      Share
    </button>
  </div>
  <hr />
  <div style="width: 600px" help-anchor="gridShareView">
    <kendo-grid [data]="tableViewToShare?.Shares" [loading]="sharesLoading">
      <kendo-grid-column
        field="Employee"
        title="Employee"
        [width]="150"
      ></kendo-grid-column>
      <kendo-grid-column field="Action" title="Action" [width]="50">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button class="k-button k-primary" (click)="unShareView(dataItem)">
            UnShare
          </button>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
  <kendo-dialog-actions [layout]="'normal'">
    <button type="button" class="k-button" (click)="showShareDialog = false">
      Close
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
