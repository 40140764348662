<div class="job-search-container" style="position: relative">
  <kendo-dropdownlist
    #jobDropdown
    *ngIf="!isMultiSelect"
    class="k-no-dropdown w-100"
    name="job"
    [(ngModel)]="selectedJob"
    [valuePrimitive]="false"
    [textField]="'Name'"
    [valueField]="'JobID'"
    [filterable]="true"
    [disabled]="disabled"
    (valueChange)="valueChangeJob($event)"
    [defaultItem]="{ Name: placeholder }"
    search-ex
    [seSearchColumns]="['VpJobId', 'Name']"
    (seFilterChangeEx)="filterDebounce.next($event)"
    [seDebounceFilterChange]="150"
    [seUseCustomLocalFilter]="true"
    [seMinSearchLength]="0"
    help-anchor="dropdown-list__job"
  >
    <ng-template kendoDropDownListItemTemplate let-dataItem>
      <div style="display: inline-flex">
        <span
          *ngIf="dataItem.JobID && showFavoritesButton"
          (click)="toggleFavorite(dataItem, $event)"
        >
          <i
            class="fas fa-star"
            title="Add/Remove Favorite Job"
            [ngStyle]="{
              color: dataItem.IsUserFavorite ? 'orange' : 'lightgrey'
            }"
          ></i>
          &nbsp;
        </span>
        <span
          *ngIf="dataItem.JobID && showFacilityTooltip"
          kendoTooltip
          [tooltipTemplate]="facilityTooltip"
        >
          <i class="fa fa-info-circle" title></i>
          &nbsp;
        </span>
        <ng-template #facilityTooltip>
          <div>Active Jobs:</div>
          <div *ngIf="dataItem.FacilityActiveJobs.length === 0">N/A</div>
          <div *ngFor="let job of dataItem.FacilityActiveJobs">{{ job }}</div>
        </ng-template>
        <span *ngIf="dataItem.IsDispatched">
          <i
            class="fas fa-walking"
            title="{{
              hrRef ? 'Employee is dispatched on this job' : 'Dispatched'
            }}"
          ></i>
          &nbsp;
        </span>
        <div>
          <strong *ngIf="dataItem.JobID">
            {{ dataItem.JobID }}
          </strong>
          <span *ngIf="dataItem.JobID">{{ ' - ' }}</span>
          <span *ngIf="dataItem.Name" class="job-name">
            {{ dataItem.Name }}
          </span>
          <span
            *ngIf="!dataItem.IsActive && dataItem.JobID"
            class="text-danger"
          >
            (Closed)
          </span>
        </div>
      </div>
    </ng-template>
    <ng-template kendoDropDownListValueTemplate let-dataItem>
      <span
        *ngIf="dataItem.JobID && showFavoritesButton"
        (click)="toggleFavorite(dataItem, $event)"
      >
        <i
          class="fas fa-star"
          title="Add/Remove Favorite Job"
          [ngStyle]="{
            color: dataItem.IsUserFavorite ? 'orange' : 'lightgrey'
          }"
        ></i>
        &nbsp;
      </span>
      <span
        *ngIf="dataItem.JobID && showFacilityTooltip"
        kendoTooltip
        [tooltipTemplate]="facilityTooltip"
      >
        <i class="fa fa-info-circle" title></i>
        &nbsp;
      </span>
      <ng-template #facilityTooltip>
        <div>Active Jobs:</div>
        <div *ngIf="dataItem.FacilityActiveJobs.length === 0">N/A</div>
        <div *ngFor="let job of dataItem.FacilityActiveJobs">{{ job }}</div>
      </ng-template>
      <span *ngIf="dataItem.IsDispatched">
        <i
          class="fas fa-walking"
          title="{{
            hrRef ? 'Employee is dispatched on this job' : 'Dispatched'
          }}"
        ></i>
        &nbsp;
      </span>
      <span
        kendoTooltip
        [title]="
          dataItem.JobID +
          ' - ' +
          dataItem.Name +
          (!dataItem.IsActive && dataItem.JobID ? ' (Closed)' : '')
        "
      >
        <strong *ngIf="dataItem.JobID">
          {{ dataItem.JobID }}
        </strong>
        <span *ngIf="dataItem.JobID">{{ ' - ' }}</span>
        <span *ngIf="dataItem.Name" class="job-name">
          {{ dataItem.Name }}
        </span>
        <span *ngIf="!dataItem.IsActive && dataItem.JobID" class="text-danger">
          (Closed)
        </span>
      </span>
    </ng-template>
  </kendo-dropdownlist>
  <kendo-multiselect
    #jobDropdown
    *ngIf="isMultiSelect"
    class="k-no-dropdown w-100"
    name="job"
    [ngStyle]="{ width: showClosedJobsCheckbox ? '88%' : '100%' }"
    [(ngModel)]="selectedJobs"
    [valuePrimitive]="false"
    [textField]="'JobID'"
    [valueField]="'JobID'"
    [filterable]="true"
    [disabled]="disabled"
    (valueChange)="valueChangeJobs($event)"
    search-ex
    [seSearchColumns]="['VpJobId', 'Name']"
    (seFilterChangeEx)="filterDebounce.next($event)"
    [seDebounceFilterChange]="150"
    [seUseCustomLocalFilter]="true"
    [seMinSearchLength]="0"
  >
    <ng-template kendoMultiSelectItemTemplate let-dataItem>
      <div>
        <span
          *ngIf="dataItem.JobID && showFavoritesButton"
          kendoTooltip
          (click)="toggleFavorite(dataItem, $event)"
        >
          <i
            class="fas fa-star"
            title="Add/Remove Favorite Job"
            [ngStyle]="{
              color: dataItem.IsUserFavorite ? 'orange' : 'lightgrey'
            }"
          ></i>
          &nbsp;
        </span>
        <span
          *ngIf="dataItem.JobID && showFacilityTooltip"
          kendoTooltip
          [tooltipTemplate]="facilityTooltip"
        >
          <i class="fa fa-info-circle" title></i>
          &nbsp;
        </span>
        <ng-template #facilityTooltip>
          <div>Active Jobs:</div>
          <div *ngIf="dataItem.FacilityActiveJobs.length === 0">N/A</div>
          <div *ngFor="let job of dataItem.FacilityActiveJobs">{{ job }}</div>
        </ng-template>
        <span *ngIf="dataItem.JobID" class="font-weight-bold">
          {{ dataItem.JobID }}
        </span>
        <span *ngIf="dataItem.JobID">{{ ' - ' }}</span>
        <span *ngIf="dataItem.Name" class="job-name">{{ dataItem.Name }}</span>
        <span *ngIf="!dataItem.IsActive && dataItem.JobID" class="text-danger">
          (Closed)
        </span>
      </div>
    </ng-template>
  </kendo-multiselect>
  <div class="d-flex" style="position: absolute; left: 50px; top: -28px">
    <div *ngIf="showClosedJobsCheckbox">
      <input
        id="closed-jobs-checkbox"
        type="checkbox"
        class="k-checkbox me-2"
        kendoCheckBox
        [(ngModel)]="includeClosedJobs"
      />
      <label for="closed-jobs-checkbox">Include Closed Jobs</label>
    </div>
    <div *ngIf="showMultiSelectCheckbox">
      <input
        id="multiselect-jobs"
        type="checkbox"
        class="k-checkbox me-2 ms-4"
        kendoCheckBox
        [(ngModel)]="isMultiSelect"
        (change)="onMultiSelectCheckboxChange()"
      />
      <span for="multiselect-jobs">Select multiple jobs</span>
    </div>
  </div>
</div>
