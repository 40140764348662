/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
// angular
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

// app
import { isObject, isString } from '@ups/xplat/utils';
// NOTE: revisit - theres circular import somewhere causing issue with jest testing
// import { XPlatWindow } from '../models';
import { PlatformWindowToken } from './tokens';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

export interface IActionOptions {
  message: string;
  cancelButtonText: string;
  actions: Array<string>;
}

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  // for navigation flows that need access outside of page component
  activeRoute: ActivatedRoute;
  // globally applies to all apps: whether to respond to header updates
  headerUpdatesEnabled = true;

  // streamlined suggest version upgrade handling
  _suggestVersionUpgrade$: Subject<boolean>;

  private _isBrowser = false;
  private _isServer = false;
  private _dialogOpened = false;

  // xplat toast message handling
  // any service can emit here to have platform specific handlers display toast messages
  private _showToast: Subject<string>;

  constructor(
    @Inject(PlatformWindowToken) private _platformWindow: any, //XPlatWindow,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this._isBrowser = isPlatformBrowser(this.platformId);
    this._isServer = isPlatformServer(this.platformId);
  }

  get showToast$() {
    if (!this._showToast) {
      this._showToast = new Subject();
    }
    return this._showToast;
  }

  get navigator() {
    return this._platformWindow.navigator;
  }

  get location() {
    return this._platformWindow.location;
  }

  get localStorage() {
    return this._platformWindow.localStorage;
  }

  get sessionStorage() {
    return this._platformWindow.sessionStorage;
  }

  get process() {
    return this._platformWindow.process;
  }

  get require() {
    return this._platformWindow.require;
  }

  get platformWindow() {
    return this._platformWindow;
  }

  get isBrowser() {
    return this._isBrowser;
  }

  get isServer() {
    return this._isServer;
  }

  get isMobile() {
    return !this._isServer && !this._isBrowser;
  }

  get suggestVersionUpgrade$() {
    if (!this._suggestVersionUpgrade$) {
      this._suggestVersionUpgrade$ = new Subject();
    }
    return this._suggestVersionUpgrade$;
  }

  get isDialogOpen() {
    return this._dialogOpened;
  }

  alert(msg: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._dialogOpened = true;
      const result: any = this._platformWindow.alert(
        this._isBrowser ? (isString(msg) ? msg : msg.message) : msg
      );
      if (isObject(result) && result.then) {
        // console.log('WindowService -- using result.then promise');
        result.then(resolve, reject);
      } else {
        this._dialogOpened = false;
        resolve(null);
      }
    });
  }

  confirm(msg: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._dialogOpened = true;
      const result: any = (<any>this._platformWindow).confirm(
        this._isBrowser ? (isString(msg) ? msg : msg.message) : msg
      );
      if (isObject(result) && result.then) {
        result.then(resolve, reject);
      } else if (result) {
        this._dialogOpened = false;
        resolve(result);
      } else {
        this._dialogOpened = false;
        resolve(null);
      }
    });
  }

  action(options: IActionOptions): Promise<any> {
    return new Promise((resolve, reject) => {
      const result: any = (<any>this._platformWindow).action(options);
      if (isObject(result) && result.then) {
        result.then(resolve, reject);
      } else if (result) {
        resolve(null);
      } else {
        reject();
      }
    });
  }

  setTimeout(handler: (...args: any[]) => void, timeout?: number): number {
    return this._platformWindow.setTimeout(handler, timeout);
  }

  clearTimeout(timeoutId: number): void {
    return this._platformWindow.clearTimeout(timeoutId);
  }

  setInterval(
    handler: (...args1: any[]) => void,
    ms?: number,
    ...args: any[]
  ): number {
    return this._platformWindow.setInterval(handler, ms, args);
  }

  clearInterval(intervalId: number): void {
    return this._platformWindow.clearInterval(intervalId);
  }

  open(
    url?: string,
    target?: string,
    features?: string,
    replace?: boolean
  ): Window {
    return this._platformWindow.open(url);
  }

  reset() {
    if (this._platformWindow.reset) {
      this._platformWindow.reset();
    }
  }
}
