import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { EMPTY, pipe } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { HelpDeskSubmission, HelpDocumentDto, HelpTypeEnum } from '../models';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace HelpState {
  /**
   * Interface for 'user' state
   */
  export interface IState {
    showTooltips: boolean;
    editor: {
      show: boolean;
      document?: HelpDocumentDto;
    };
    helpdesk: {
      showModal: boolean;
      submission?: Partial<HelpDeskSubmission>;
    };
    loadedDocuments: { [path: string]: HelpDocumentDto[] };
    uiPaths: string[];
    urlPath?: string;
    playingWalkthrough?: HelpDocumentDto;
    currentStep?: number;
  }

  /**
   * You can define other interfaces as needed here
   */

  export const initialState: IState = {
    showTooltips: false,
    editor: {
      show: false,
    },
    helpdesk: {
      showModal: false,
    },
    loadedDocuments: {},
    uiPaths: [],
  };

  /**
   * Selectors
   */
  export const selectState = createFeatureSelector<IState>('help');

  const _selectShowTooltips = createSelector(selectState, (state: IState) => state.showTooltips);

  export const selectShowTooltips = (takeUntil$ = null) => pipe(select(_selectShowTooltips), takeUntil(takeUntil$ || EMPTY));

  const _selectEditorState = createSelector(selectState, (state: IState) => state.editor);

  export const selectEditorState = (takeUntil$ = null) => pipe(select(_selectEditorState), takeUntil(takeUntil$ || EMPTY));

  export const selectDocumentDictionary = createSelector(selectState, (state: IState) => state.loadedDocuments);

  export const selectDocumentList = (takeUntil$ = null, type: HelpTypeEnum = null) =>
    pipe(
      select(selectDocumentDictionary),
      map((dict) => Object.values(dict).reduce((previous, current) => [...previous, ...current.filter((x) => type === null || x.type === type)], [])),
      takeUntil(takeUntil$ || EMPTY)
    );

  export const selectUIPaths = createSelector(selectState, (state: IState) => state.uiPaths);

  export const selectDocumentsForUIPath = (uiPath) =>
    pipe(
      select(selectDocumentDictionary),
      map((dict) => dict[uiPath])
    );

  export const selectCurrentWalkthrough = createSelector(selectState, (state: IState) => state.playingWalkthrough);

  export const selectCurrentWalkthroughStep = createSelector(selectState, (state: IState) => state.currentStep);

  export const selectHelpDesk = createSelector(selectState, (state: IState) => state.helpdesk);

  export const selectUrlPath = createSelector(selectState, (state: IState) => state.urlPath);
}
