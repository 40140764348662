export class JobClassEmployeeAroundPlant {
  constructor(
    public distanceInMiles: number,
    public employeeId: string,
    public employeeLat: number,
    public employeeLng: number,
    public jobClassId: number,
    public jobClassName: string,
    public vpCompanyId: number,
    public vpEmployeeId: string
  ) {}
}
