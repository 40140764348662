import { BaseModel } from '../base.model';

export class DashboardTradeClassDto extends BaseModel {
  public tradeClassId: string;
  public linkedClassId: string;
  public requestedClassName: string;
  public companyId: string;
  public tradeGroupId: string;
  public tradeGroupName: string;

  /* Client side generated */
  public uniqueId: string;

  constructor(o: unknown = null) {
    super(o);
  }
}
