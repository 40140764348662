/**
 * Created by Tom.Jaeschke on 5/23/2017.
 */
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'simpleDate' })
export class SimpleDatePipe implements PipeTransform {
  transform(value: Date): string {
    let datePipe = new DatePipe('en-US');
    let revamp = datePipe.transform(value, 'MM/dd/yy');
    return revamp;
  }
}
