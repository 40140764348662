/* eslint-disable */
export const DynamicEventBusTypes = {
  dynamicFormReady: 'dynamicFormReady',
  // resets the instance state on active page
  dynamicFormResetState: 'dynamicFormResetState',
  // wipes out entire form instances (used with page navigation)
  dynamicFormTotalReset: 'dynamicFormTotalReset',
  dynamicDataUpdate: 'dynamicDataUpdate',
  dynamicRemoveControl: 'dynamicRemoveControl',
  dynamicContainerSaved: 'dynamicContainerSaved',
  dynamicChangeControlOrder: 'dynamicChangeControlOrder',
  dynamicApplyProperties: 'dynamicApplyProperties',
  dynamicAddItemToButtonGroup: 'dynamicAddItemToButtonGroup',
  dynamicRemoveItemFromOptions: 'dynamicRemoveItemFromOptions',
  dynamicAddUpdateOnValueChange: 'dynamicAddUpdateOnValueChange',
  dynamicAccordionApplyToAllStatusComplete:
    'dynamicAccordionApplyToAllStatusComplete',
  dynamicAccordionApplyToAllStatusReset:
    'dynamicAccordionApplyToAllStatusReset',
};
/* eslint-enable */
