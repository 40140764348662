import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidWhenDirective } from './valid-when.directive';

@NgModule({
  declarations: [ValidWhenDirective],
  exports: [ValidWhenDirective],
  imports: [CommonModule],
})
export class ValidatorsModuleEx {}
