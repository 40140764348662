<div>
  <span *ngIf="!hasReadPermissions">
    You don't have permissions to see the Equipment Calibrations
  </span>
  <span *ngIf="hasReadPermissions && isLoading">Loading ...</span>

  <form
    id="latestEquipmentCalibrationFormId"
    #equipmentForm="ngForm"
    (ngSubmit)="saveEquipment(equipmentForm.valid)"
    *ngIf="hasReadPermissions && latestEquipmentCalibration !== null"
  >
    <div class="d-flex align-items-center justify-content-between mb-7">
      <h4 class="mb-0">Latest Calibration</h4>
      <div *ngIf="hasEditPermissions && latestEquipmentCalibration !== null">
        <button
          id="editEquipmentBtn"
          help-anchor="editCalibrationTabButton"
          *ngIf="!isEditMode"
          type="button"
          [disabled]="isLoading"
          class="k-button"
          (click)="editEquipment()"
        >
          Edit
        </button>
        <button
          id="editEquipmentSaveBtn"
          help-anchor="saveButton"
          *ngIf="isEditMode"
          type="button"
          [disabled]="isLoading || !equipmentForm.dirty"
          class="k-button k-primary"
          (click)="saveEquipment(equipmentForm.valid)"
        >
          Save
        </button>
        <button
          id="editEquipmentCancelBtn"
          *ngIf="isEditMode"
          type="button"
          [disabled]="isLoading"
          class="k-button"
          (click)="cancelEquipment(equipmentForm.dirty)"
        >
          Cancel
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center mb-7">
      <div>
        <label for="calibrationFrequency" class="me-5">
          Calibration Frequency
        </label>
        <kendo-dropdownlist
          id="calibrationFrequency"
          help-anchor="calibrationFrequencyDD"
          name="calibrationFrequency"
          [(ngModel)]="latestEquipmentCalibration.CalibrationFrequency"
          [disabled]="!isEditMode"
          [data]="calibrationFrequencyDropdownItems"
          [textField]="'text'"
          [valueField]="'id'"
          [valuePrimitive]="true"
        ></kendo-dropdownlist>
      </div>
      <div>
        <label>Calibrated By</label>
        <p class="mb-0">
          {{ getCalibratedByText(latestEquipmentCalibration.CalibratedBy) }}
        </p>
      </div>
      <div>
        <label>Last Calibration Date</label>
        <p class="mb-0">
          {{
            latestEquipmentCalibration.LastCalibrationDate | date: 'MM/dd/yyyy'
          }}
        </p>
      </div>
      <div>
        <label>Calibration Due Date</label>
        <p class="mb-0">
          {{
            latestEquipmentCalibration.NextCalibrationDate | date: 'MM/dd/yyyy'
          }}
        </p>
      </div>
      <div>
        <label>Notes:</label>
        <p class="mb-0">{{ latestEquipmentCalibration.CalibrationNotes }}</p>
      </div>
    </div>
  </form>
  <kendo-grid
    id="equipmentCalibrationGridId"
    *ngIf="hasReadPermissions"
    [data]="gridData"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [filter]="state.filter"
    [sortable]="true"
    [pageable]="true"
    [filterable]="'menu'"
    [loading]="isLoading"
    (dataStateChange)="dataStateChange($event)"
    class="rate-sheet transparent-around"
  >
    <ng-template kendoGridToolbarTemplate>
      <div class="d-flex align-items-center justify-content-between w-100">
        <h4 class="mb-0">Calibration History</h4>
        <button
          id="addCalibrationButtonId"
          help-anchor="addButton"
          *ngIf="hasEditPermissions"
          [disabled]="isLoading"
          (click)="addCalibration()"
          class="k-button k-primary"
          kendoButton
        >
          Add
        </button>
      </div>
    </ng-template>
    <kendo-grid-command-column
      field="Edit"
      *ngIf="hasEditPermissions"
      title=""
      width="80"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          id="editCalibrationButtonId"
          title="Edit"
          class="k-button"
          [disabled]="isLoading"
          (click)="editCalibration(dataItem)"
        >
          <i class="far fa-edit"></i>
        </button>
        <button
          id="editCalibrationButtonId"
          title="Remove"
          class="k-button"
          [disabled]="isLoading"
          (click)="removeCalibration(dataItem)"
        >
          <i class="far fa-trash"></i>
        </button>
      </ng-template>
    </kendo-grid-command-column>
    <kendo-grid-column field="CalibratedBy" title="Calibrated By" width="120">
      <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
        <span>{{ getCalibratedByText(dataItem.CalibratedBy) }}</span>
      </ng-template>
      <ng-template
        kendoGridFilterMenuTemplate
        let-column="column"
        let-filter="filter"
        let-filterService="filterService"
      >
        <grid-checkbox-filter-menu
          [isPrimitive]="false"
          [textField]="'text'"
          [valueField]="'id'"
          [field]="column.field"
          [filterService]="filterService"
          [currentFilter]="filter"
          [data]="calibratedByDropdownItems"
        ></grid-checkbox-filter-menu>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="LastCalibrationDate"
      title="Last Calibration Date"
      width="120"
      filter="date"
    >
      <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
        <span>{{ dataItem.LastCalibrationDate | date: 'MM/dd/yyyy' }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="NextCalibrationDate"
      title="Calibration Due Date"
      width="120"
      filter="date"
    >
      <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
        <span>{{ dataItem.NextCalibrationDate | date: 'MM/dd/yyyy' }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="CalibrationNotes"
      title="Notes"
      width="120"
    ></kendo-grid-column>
    <kendo-grid-column
      field="DateModified"
      title="Date Modified"
      width="60"
      filter="date"
    >
      <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
        <span>{{ dataItem.DateModified | date: 'MM/dd/yyyy h:mm:ss' }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="FileName" title="Attachment" width="60">
      <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
        <a (click)="downloadFile(dataItem)" class="link">
          {{ dataItem.FileName }}
        </a>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
  <form #calibrationForm="ngForm">
    <kendo-dialog
      id="calibrationEditDialogId"
      *ngIf="isEditCalibrationMode"
      [hidden]="!isEditCalibrationMode"
      modal
      fade
      (close)="cancelCalibration(calibrationForm.dirty)"
      [width]="550"
      [height]="600"
    >
      <kendo-dialog-titlebar>
        <span>
          {{
            selectedCalibrationItem.EquipmentCalibrationID ? 'Edit' : 'Create'
          }}
          calibration history item
        </span>
      </kendo-dialog-titlebar>
      <div>
        <div class="d-flex flex-column mb-5">
          <label>Calibrated By</label>
          <kendo-dropdownlist
            #calibratedBy
            class="w-100"
            id="calibrationItemCalibratedBy"
            help-anchor="calibratedByDD"
            name="calibrationItemCalibratedBy"
            [(ngModel)]="selectedCalibrationItem.CalibratedBy"
            [disabled]="!isEditCalibrationMode"
            [data]="calibratedByDropdownItems"
            [textField]="'text'"
            [valueField]="'id'"
            [valuePrimitive]="true"
          ></kendo-dropdownlist>
        </div>
        <div class="row mb-5">
          <div class="col-md-6">
            <div class="d-flex flex-column">
              <label>Last Calibration Date</label>
              <ups-kendo-datepicker-shared
                id="calibrationItemLastCalibrationDate"
                help-anchor="lastCalibrationDate"
                name="calibrationItemLastCalibrationDate"
                #lastCalibrationDateControl="ngModel"
                [(ngModel)]="selectedCalibrationItem.LastCalibrationDate"
                [disabled]="!isEditCalibrationMode"
                (valueChange)="
                  lastCalibrationDateChanged(lastCalibrationDateControl)
                "
                [max]="today"
                [validWhen]="
                  selectedCalibrationItem.LastCalibrationDate &&
                  selectedCalibrationItem.LastCalibrationDate <= today
                "
                placeholder="mm/dd/yyyy"
                required
                [isFullWidth]="true"
              ></ups-kendo-datepicker-shared>
              <span
                *ngIf="
                  lastCalibrationDateControl.errors &&
                  lastCalibrationDateControl.dirty
                "
                class="text-danger"
              >
                Calibration Last Date cannot be a date in future.
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="d-flex flex-column">
              <label>Calibration Due Date</label>
              <ups-kendo-datepicker-shared
                id="calibrationItemNextCalibrationDate"
                help-anchor="calibrationDueDate"
                name="calibrationItemNextCalibrationDate"
                #calibrationItemNextCalibrationDate="ngModel"
                [(ngModel)]="selectedCalibrationItem.NextCalibrationDate"
                [disabled]="!isEditCalibrationMode"
                [min]="selectedCalibrationItem.LastCalibrationDate"
                [validWhen]="
                  !selectedCalibrationItem.LastCalibrationDate ||
                  !selectedCalibrationItem.NextCalibrationDate ||
                  selectedCalibrationItem.NextCalibrationDate >=
                    selectedCalibrationItem.LastCalibrationDate
                "
                placeholder="mm/dd/yyyy"
                [isFullWidth]="true"
              ></ups-kendo-datepicker-shared>
              <span
                *ngIf="
                  calibrationItemNextCalibrationDate.errors &&
                  calibrationItemNextCalibrationDate.dirty
                "
                class="text-danger"
              >
                Calibration Due Date must be after the Last Calibration Date.
              </span>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column mb-5">
          <label>Notes</label>
          <textarea
            id="calibrationItemNotes"
            help-anchor="notesField"
            name="calibrationItemNotes"
            type="text"
            [disabled]="!isEditCalibrationMode"
            rows="3"
            [(ngModel)]="selectedCalibrationItem.CalibrationNotes"
            kendoTextArea
          ></textarea>
        </div>
        <div class="d-flex justify-content-between">
          <label
            for="addIDAttachment"
            *ngIf="!selectedCalibrationItem.FileName"
            [class.disabled-field]="!isEditCalibrationMode"
          >
            Attachment:
          </label>
          <label
            *ngIf="selectedCalibrationItem.FileName"
            [class.disabled-field]="true"
          >
            Attachment: {{ selectedCalibrationItem.FileName }}
          </label>
          <input
            id="addIDAttachment"
            help-anchor="addAttachmentButton"
            type="file"
            *ngIf="!selectedCalibrationItem.FileName"
            hidden
            (change)="selectFile($event)"
            [disabled]="!isEditCalibrationMode"
          />
          <label
            for="addIDAttachment"
            *ngIf="!selectedCalibrationItem.FileName"
            class="k-button k-primary"
            [class.disabled-field]="!isEditCalibrationMode"
            tooltip="Upload an attachment."
          >
            Add Attachment
          </label>
          <button
            id="removeAttachmentBtn"
            *ngIf="selectedCalibrationItem.FileName"
            class="k-button"
            [disabled]="!isEditCalibrationMode"
            (click)="removeAttachment()"
          >
            Remove Attachment
          </button>
        </div>
      </div>
      <kendo-dialog-actions [layout]="'layout'">
        <button
          id="calibrationItemCancelButtonId"
          (click)="cancelCalibration(calibrationForm.dirty)"
          [disabled]="isLoading"
          class="k-button"
        >
          Cancel
        </button>
        <button
          id="calibrationItemSaveButtonId"
          help-anchor="saveCalibrationHistoryItemButton"
          (click)="saveCalibration(calibrationForm.valid)"
          [disabled]="
            isLoading || !calibrationForm.dirty || !calibrationForm.valid
          "
          class="k-button k-primary"
        >
          Save
        </button>
      </kendo-dialog-actions>
    </kendo-dialog>
  </form>
</div>
