import { State, toODataString } from '@progress/kendo-data-query';
import {
  IFieldSettingObject,
  IFieldSettingObjectItem,
} from '@ups/xplat/api/dto';
import { deepClone } from '@ups/xplat/utils';

import { toODataStringEx } from '../odata';

export class ODataFilterDataUtils {
  /**
   * From a given OData endpoint it fetches all the unique values for the supplied columns (fields).
   * Whether include null/empty values in the result is possible via global parameters or via supplying IFieldSettingObject into the fieldSettings parameter.
   *
   * @param state
   * @param fieldSettings
   * @param defaultSkipNull
   * @param defaultSkipEmpty
   * @param defaultSkipWhiteSpace
   * @param useCustomToOdataStringImplementation
   */
  public static getFilterDataQueryString(
    state: State,
    fieldSettings: (string | IFieldSettingObjectItem)[] | IFieldSettingObject,
    defaultSkipNull = true,
    defaultSkipEmpty = true,
    defaultSkipWhiteSpace = true,
    useCustomToOdataStringImplementation = true
  ): string {
    const reportingState = deepClone(state) as State;

    delete reportingState.take;
    delete reportingState.skip;

    delete reportingState.group;

    const odataString = useCustomToOdataStringImplementation
      ? toODataStringEx(reportingState)
      : toODataString(reportingState);

    const fieldSettingsObject = Array.isArray(fieldSettings)
      ? fieldSettings.map((fieldName: string | IFieldSettingObjectItem) =>
          typeof fieldName === 'string'
            ? { field: fieldName }
            : { field: fieldName.field, idField: fieldName.idField }
        )
      : fieldSettings;

    Object.keys(fieldSettingsObject).forEach((key) => {
      const fieldSetting = fieldSettingsObject[key];
      if (!fieldSetting.hasOwnProperty('skipNull'))
        fieldSetting.skipNull = defaultSkipNull;
      if (!fieldSetting.hasOwnProperty('skipEmpty'))
        fieldSetting.skipEmpty = defaultSkipEmpty;
      if (!fieldSetting.hasOwnProperty('skipWhiteSpace'))
        fieldSetting.skipWhiteSpace = defaultSkipWhiteSpace;
    });

    const filterDataSettings = fieldSettingsObject;

    return (
      (odataString ? `${odataString}&` : ``) +
      `filterData=${encodeURIComponent(JSON.stringify(filterDataSettings))}`
    );
  }
}
