import {
  ColumnBase,
  ColumnComponent,
  ColumnGroupComponent,
  GridComponent,
  SpanColumnComponent,
} from '@progress/kendo-angular-grid';
import { State, toODataString } from '@progress/kendo-data-query';

import { toODataStringEx } from '../odata';

import { ODataSortUtils } from './odata-sort-utils';

export class ODataReportingUtils {
  public static getXlsxDowloadQueryString(
    grid: GridComponent,
    state: State,
    excludeFields: string[] = [],
    fileName = 'report.xlsx',
    autoSizeColumns = true,
    useCustomToOdataStringImplementation = true,
    jwt: string = undefined,
    dataXPropertyName: string = undefined,
    includeHiddenColumns: boolean = false
  ): string {
    jwt = jwt || localStorage.getItem('id_token'); // NOTE: jwt bearer token without the bearer prefix
    excludeFields = excludeFields || [];

    const reportingState = JSON.parse(JSON.stringify(state)) as State;

    reportingState.sort = ODataSortUtils.getPatchedSortForGroupQuery(
      reportingState,
      true
    );

    delete reportingState.take;
    delete reportingState.skip;

    delete reportingState.group;

    const odataString = useCustomToOdataStringImplementation
      ? toODataStringEx(reportingState)
      : toODataString(reportingState);

    const groupFields = state.group ? state.group.map((g) => g.field) : [];

    const reportSetting = {
      fileName: fileName,
      autoSizeColumns: autoSizeColumns,
      showGrouping: true,
      // TODO: in case the grid is a group column, it has children, this has to be also considered (even multiple levels): this.grid.columns._results[0].children._results
      // TODO: BE support for complex properties: person.mailAddress.zipCode
      // TODO: BE support for indexers: person.addresses[0].zipCode
      // TODO:
      // - BE support for enumeration joins: person.addresses - join zipCodes with comma (ignore nullOrEmpty)
      // - somehow add the sub-property (zipCode) to columns: person.addresses[, ].zipCode
      // - even consider: persons[0].addresses[0].zipCode or persons[, ].addresses[0].zipCode or persons[\r\n ].addresses[, ].zipCode
      columns: ODataReportingUtils.flattenColumnDefinitions(
        grid ? grid.columns.toArray() : []
      )
        .filter(
          (col: ColumnComponent) =>
            col.isVisible ||
            includeHiddenColumns ||
            groupFields.some((gf) => gf === col.field)
        ) // only visible or group columns
        .filter((col: ColumnBase) => !col.isColumnGroup) // ignoring "fake" (virtual) column groups
        .filter(
          (col: ColumnComponent) =>
            !excludeFields.some((ef) => ef === col.field)
        ) // ignoring excluded columns
        .sort((a, b) =>
          a.orderIndex < b.orderIndex ? -1 : a.orderIndex > b.orderIndex ? 1 : 0
        )
        .map((col: any) => {
          // we have extra formatting stored as data-x attribute
          // the data-x attribute might be a string or an object (in that case we need to fetch one of it's property)
          const dataX = col.dataX
            ? dataXPropertyName
              ? col.dataX[dataXPropertyName]
              : col.dataX
            : undefined;

          return {
            field: col.field,
            isGroup: groupFields.some((gf) => gf === col.field),
            isVisible: col.isVisible || includeHiddenColumns,
            index: col.orderIndex,
            fieldFormatting: dataX || col.field,
            description: col.title || col.field || dataX,
          };
        })
        // NOTE: Filter out those who have no expression nor field set
        .filter((data) => !!data.fieldFormatting),
    };

    return (
      (odataString ? `${odataString}&` : ``) +
      `jwt=${jwt}&report=${encodeURIComponent(JSON.stringify(reportSetting))}`
    );
  }

  public static flattenColumnDefinitions(
    columns: (
      | ColumnBase
      | ColumnComponent
      | ColumnGroupComponent
      | SpanColumnComponent
    )[]
  ): (
    | ColumnBase
    | ColumnComponent
    | ColumnGroupComponent
    | SpanColumnComponent
  )[] {
    const thisResult: (
      | ColumnBase
      | ColumnComponent
      | ColumnGroupComponent
      | SpanColumnComponent
    )[] = [];

    columns.forEach((col: any) => {
      if (col.children) {
        // NOTE: for some reason the parent component get's into the children list, which we need to filter out, public get colspan(): number on the public get colspan(): number is doing similar op.
        const childrenResult = this.flattenColumnDefinitions(
          col.children.toArray().filter((c) => c !== col)
        );
        thisResult.push(...childrenResult);
      } else if (col.childColumns) {
        // NOTE: for some reason the parent component get's into the children list, which we need to filter out, public get colspan(): number on the public get colspan(): number is doing similar op.
        const childrenResult = this.flattenColumnDefinitions(
          col.childColumns.toArray().filter((c) => c !== col)
        );
        thisResult.push(...childrenResult);
      } else {
        thisResult.push(col);
      }
    });

    return thisResult;
  }
}
