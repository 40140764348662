<div *ngIf="canRead">
  <div id="equipmentDashboardGrid">
    <kendo-grid
      class="white-bg toolbar-no-border rate-sheet cell-edit high-header grouping fit-to-screen-xl"
      grid-grouping-ex
      [ggx-viewModel]="viewModel"
      grid-grouping-ex-selection-support
      [selectable]="{ mode: 'multiple', checkboxOnly: true }"
      (selectionChange)="selectionChange()"
      [data]="view"
      [sortable]="true"
      [filterable]="'menu'"
      [resizable]="true"
      [groupable]="true"
      [reorderable]="true"
      [pageable]="{ pageSizes: [10, 25, 50, 100, 200] }"
      [pageSize]="gridState.take"
      [skip]="gridState.skip"
      [sort]="gridState.sort"
      [group]="gridState.group"
      [filter]="gridState.filter"
      (dataStateChange)="gridDataStateChange($event)"
      [style.height]="'100%'"
      [loading]="isLoading"
      #grid
    >
      <ng-template kendoGridToolbarTemplate>
        <div
          [attr.disabled]="grid.loading ? true : null"
          class="w-100 d-flex justify-content-between"
        >
          <ups-grid-toolbar
            [gridState]="gridState"
            (gridStateChange)="gridDataStateChange($event)"
            (reloadClick)="loadData()"
            [showClearFilters]="true"
            [tableViewKey]="tableViewKey"
            [tableViewSecurity]="
              security.getFeatureById(
                securityConstants.employee_portal_equipment_equipmentdashboardcustomview
              )
            "
            [tableViewExcludedColumns]="['Detail', 'AssetControlManager']"
            [multiSearchValue]="gridSearchValue"
            [multiSearchPlaceholder]="'Search Equipment, or Job'"
            [multiSearchTitle]="'Search Equipment, or Job'"
            [multiSearchColumns]="['Name', 'RequestedEquipment', 'VPJobNumber']"
            [isCondensed]="true"
            [(wrapWords)]="shouldWrapWords"
            (multiSearchChange)="mcsFilterChange($event)"
          >
            <div>
              <a
                class="k-button-icontext k-button"
                download
                href="#"
                target="_blank"
                (mouseenter)="updateXlsxHref($event)"
              >
                Export
              </a>
              <button
                kendoButton
                *ngIf="
                  security.getFeatureById(
                    securityConstants.employee_portal_equipment_equipmentdashboardbulkedit
                  ).viewFeature
                "
                id="bulkEditButtonId"
                type="button"
                class="k-button-icontext k-button"
                [disabled]="selectedItems.length < 2"
                (click)="bulkEditDialog.open(selectedItems)"
                class="ms-3"
              >
                Bulk Edit
              </button>
            </div>
          </ups-grid-toolbar>
        </div>
      </ng-template>

      <kendo-grid-checkbox-column
        width="40"
        [resizable]="false"
        [includeInChooser]="false"
        [hidden]="!showCheckboxColumn"
        [reorderable]="false"
        [locked]="false"
        showSelectAll="true"
      ></kendo-grid-checkbox-column>

      <kendo-grid-column-ex
        title=""
        width="130"
        [filterable]="false"
        [sortable]="false"
        [groupable]="false"
        [reorderable]="false"
        [locked]="false"
        [includeInChooser]="false"
      >
        <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
          <button
            class="k-button-icontext k-button smBtn condensed-button"
            (click)="open(dataItem)"
            title="Open"
          >
            <i class="fa fa-edit"></i>
            Open
          </button>
        </ng-template>
      </kendo-grid-column-ex>

      <kendo-grid-column
        title="Transport Date"
        field="TransportDate"
        filter="date"
        type="date"
        format="{0:d}"
        width="150"
      >
        <ng-template
          kendoGridGroupHeaderTemplate
          let-field
          let-value
          let-dataItem
        >
          <ggx-group-selection-header
            [dataItem]="dataItem"
            [showLabel]="false"
          ></ggx-group-selection-header>
          <div style="font-weight: initial">
            <span>
              Transport Date:
              <strong>{{ dataItem.value | date: 'MM/dd/yyyy' }}</strong>
            </span>
          </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
          <span title="{{ dataItem.TransportDate | date: 'MM/dd/yyyy' }}">
            {{ dataItem.TransportDate | date: 'MM/dd/yyyy' }}
          </span>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">Transport Date</span>
        </ng-template>
        <ng-template
          kendoGridFilterMenuTemplate
          let-filter
          let-column="column"
          let-filterService="filterService"
        >
          <kendo-grid-date-filter-menu
            [column]="column"
            [filter]="filter"
            [filterService]="filterService"
          >
            <kendo-filter-eq-operator></kendo-filter-eq-operator>
            <kendo-filter-neq-operator></kendo-filter-neq-operator>
            <kendo-filter-after-eq-operator></kendo-filter-after-eq-operator>
            <kendo-filter-after-operator></kendo-filter-after-operator>
            <kendo-filter-before-eq-operator></kendo-filter-before-eq-operator>
            <kendo-filter-before-operator></kendo-filter-before-operator>
            <kendo-filter-isnull-operator
              text="Is blank"
            ></kendo-filter-isnull-operator>
            <kendo-filter-isnotnull-operator
              text="Is not blank"
            ></kendo-filter-isnotnull-operator>
          </kendo-grid-date-filter-menu>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        title="Start Date"
        field="StartDate"
        filter="date"
        type="date"
        format="{0:d}"
        width="120"
      >
        <ng-template
          kendoGridGroupHeaderTemplate
          let-field
          let-value
          let-dataItem
        >
          <ggx-group-selection-header
            [dataItem]="dataItem"
            [showLabel]="false"
          ></ggx-group-selection-header>
          <div style="font-weight: initial">
            <span>
              Start Date:
              <strong>{{ dataItem.value | date: 'MM/dd/yyyy' }}</strong>
            </span>
          </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
          <span title="{{ dataItem.StartDate | date: 'MM/dd/yyyy' }}">
            {{ dataItem.StartDate | date: 'MM/dd/yyyy' }}
          </span>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">{{ column.title }}</span>
        </ng-template>
        <ng-template
          kendoGridFilterMenuTemplate
          let-filter
          let-column="column"
          let-filterService="filterService"
        >
          <kendo-grid-date-filter-menu
            [column]="column"
            [filter]="filter"
            [filterService]="filterService"
          >
            <kendo-filter-eq-operator></kendo-filter-eq-operator>
            <kendo-filter-neq-operator></kendo-filter-neq-operator>
            <kendo-filter-after-eq-operator></kendo-filter-after-eq-operator>
            <kendo-filter-after-operator></kendo-filter-after-operator>
            <kendo-filter-before-eq-operator></kendo-filter-before-eq-operator>
            <kendo-filter-before-operator></kendo-filter-before-operator>
            <kendo-filter-isnull-operator
              text="Is blank"
            ></kendo-filter-isnull-operator>
            <kendo-filter-isnotnull-operator
              text="Is not blank"
            ></kendo-filter-isnotnull-operator>
          </kendo-grid-date-filter-menu>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        title="End Date"
        field="EndDate"
        filter="date"
        type="date"
        format="{0:d}"
        width="180"
      >
        <ng-template
          kendoGridGroupHeaderTemplate
          let-field
          let-value
          let-dataItem
        >
          <ggx-group-selection-header
            [dataItem]="dataItem"
            [showLabel]="false"
          ></ggx-group-selection-header>
          <div style="font-weight: initial">
            <span>
              End Date:
              <strong>{{ dataItem.value | date: 'MM/dd/yyyy' }}</strong>
            </span>
          </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
          <span title="{{ dataItem.EndDate | date: 'MM/dd/yyyy' }}">
            {{ dataItem.EndDate | date: 'MM/dd/yyyy' }}
          </span>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">{{ column.title }}</span>
        </ng-template>
        <ng-template
          kendoGridFilterMenuTemplate
          let-filter
          let-column="column"
          let-filterService="filterService"
        >
          <kendo-grid-date-filter-menu
            [column]="column"
            [filter]="filter"
            [filterService]="filterService"
          >
            <kendo-filter-eq-operator></kendo-filter-eq-operator>
            <kendo-filter-neq-operator></kendo-filter-neq-operator>
            <kendo-filter-after-eq-operator></kendo-filter-after-eq-operator>
            <kendo-filter-after-operator></kendo-filter-after-operator>
            <kendo-filter-before-eq-operator></kendo-filter-before-eq-operator>
            <kendo-filter-before-operator></kendo-filter-before-operator>
            <kendo-filter-isnull-operator
              text="Is blank"
            ></kendo-filter-isnull-operator>
            <kendo-filter-isnotnull-operator
              text="Is not blank"
            ></kendo-filter-isnotnull-operator>
          </kendo-grid-date-filter-menu>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column-ex title="Job" field="VPJobNumber" width="120">
        <ng-template
          kendoGridGroupHeaderTemplate
          let-field
          let-value
          let-dataItem
          let-aggregates="aggregates"
        >
          <ggx-group-selection-header
            [dataItem]="dataItem"
            [fieldName]="'Job'"
            [showLabel]="false"
          ></ggx-group-selection-header>
          <div style="font-weight: initial">
            <span>
              Job:
              <strong>{{ dataItem.value }}</strong>
            </span>
            <span *ngIf="aggregates.default && aggregates.default.JobName">
              &nbsp;{{ aggregates.default.JobName }}
            </span>
          </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
          <div
            class="extended"
            [ngClass]="{ 'text-truncate': !shouldWrapWords }"
          >
            <span title="{{ dataItem.JobName }}">
              {{ dataItem.VPJobNumber }}
            </span>
            <ng-container *ngIf="dataItem.OtherJobs?.length > 0">
              <span>(See Other Jobs)</span>
            </ng-container>
          </div>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">{{ column.title }}</span>
        </ng-template>
        <ng-template
          kendoGridFilterMenuTemplate
          let-column="column"
          let-filter="filter"
          let-filterService="filterService"
        >
          <grid-checkbox-filter-menu
            [isPrimitive]="true"
            [field]="column.field"
            [filterService]="filterService"
            [currentFilter]="filter"
            [addSelectAllOption]="true"
            [isLoadingFilterData]="isLoadingFilterData"
            [data]="gridFilterData[column.field]?.Values"
          ></grid-checkbox-filter-menu>
        </ng-template>
      </kendo-grid-column-ex>

      <kendo-grid-column-ex
        title="Job Name"
        field="JobName"
        width="130"
        [hidden]="true"
      >
        <ng-template
          kendoGridGroupHeaderTemplate
          let-field
          let-value
          let-dataItem
        >
          <ggx-group-selection-header
            [dataItem]="dataItem"
            [fieldName]="'Job Name'"
          ></ggx-group-selection-header>
        </ng-template>
        <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
          <span title="{{ dataItem.JobName }}">{{ dataItem.JobName }}</span>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">{{ column.title }}</span>
        </ng-template>
        <ng-template
          kendoGridFilterMenuTemplate
          let-filter
          let-column="column"
          let-filterService="filterService"
        >
          <kendo-grid-string-filter-menu
            [column]="column"
            [filter]="filter"
            [filterService]="filterService"
          >
            <kendo-filter-eq-operator></kendo-filter-eq-operator>
            <kendo-filter-neq-operator></kendo-filter-neq-operator>
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
            <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
            <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
            <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
          </kendo-grid-string-filter-menu>
        </ng-template>
      </kendo-grid-column-ex>

      <kendo-grid-column-ex title="Equipment Name" field="Name" width="180">
        <ng-template
          kendoGridGroupHeaderTemplate
          let-field
          let-value
          let-dataItem
        >
          <ggx-group-selection-header
            [dataItem]="dataItem"
            [fieldName]="'Equipment'"
          ></ggx-group-selection-header>
        </ng-template>
        <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
          <div [ngClass]="{ 'text-truncate': !shouldWrapWords }">
            <a
              [routerLink]="[
                '/resources/equipment-page',
                dataItem.EquipmentKeyID
              ]"
              *ngIf="dataItem.EquipmentKeyID && canSeeEquipmentHyperlink"
              target="_blank"
            >
              <span title="Click to open the equipment in a new browser tab.">
                {{ dataItem.Name }}
              </span>
            </a>
            <span
              *ngIf="!dataItem.EquipmentKeyID || !canSeeEquipmentHyperlink"
              title="{{ dataItem.Name }}"
            >
              {{ dataItem.Name }}
            </span>
          </div>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">Equipment Name</span>
        </ng-template>
        <ng-template
          kendoGridFilterMenuTemplate
          let-filter
          let-column="column"
          let-filterService="filterService"
        >
          <kendo-grid-string-filter-menu
            [column]="column"
            [filter]="filter"
            [filterService]="filterService"
          >
            <kendo-filter-eq-operator></kendo-filter-eq-operator>
            <kendo-filter-neq-operator></kendo-filter-neq-operator>
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
            <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
            <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
            <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            <kendo-filter-isempty-operator
              text="Is blank"
            ></kendo-filter-isempty-operator>
            <kendo-filter-isnotempty-operator
              text="Is not blank"
            ></kendo-filter-isnotempty-operator>
          </kendo-grid-string-filter-menu>
        </ng-template>
      </kendo-grid-column-ex>

      <kendo-grid-column-ex
        title="Equipment Requested"
        field="RequestedEquipment"
        width="150"
      >
        <ng-template
          kendoGridGroupHeaderTemplate
          let-field
          let-value
          let-dataItem
        >
          <ggx-group-selection-header
            [dataItem]="dataItem"
            [fieldName]="'Requested Equipment'"
          ></ggx-group-selection-header>
        </ng-template>
        <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
          <span
            title="{{ dataItem.RequestedEquipment }}"
            [ngClass]="{ 'text-truncate': !shouldWrapWords }"
          >
            {{ dataItem.RequestedEquipment }}
          </span>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">Equipment Requested</span>
        </ng-template>
        <ng-template
          kendoGridFilterMenuTemplate
          let-column="column"
          let-filter="filter"
          let-filterService="filterService"
        >
          <grid-checkbox-filter-menu
            [isPrimitive]="true"
            [field]="column.field"
            [filterService]="filterService"
            [currentFilter]="filter"
            [addSelectAllOption]="true"
            [addBlankOption]="true"
            [isLoadingFilterData]="isLoadingFilterData"
            [data]="gridFilterData[column.field]?.Values"
          ></grid-checkbox-filter-menu>
        </ng-template>
      </kendo-grid-column-ex>

      <kendo-grid-column-ex
        title="Dispatch Status"
        field="DispatchStatus"
        width="120"
      >
        <ng-template
          kendoGridGroupHeaderTemplate
          let-field
          let-value
          let-dataItem
        >
          <ggx-group-selection-header
            [dataItem]="dataItem"
            [fieldName]="'DispatchStatus'"
          ></ggx-group-selection-header>
        </ng-template>
        <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
          <span title="{{ dataItem.DispatchStatus }}">
            {{ dataItem.DispatchStatus }}
          </span>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">Dispatch Status</span>
        </ng-template>
        <ng-template
          kendoGridFilterMenuTemplate
          let-column="column"
          let-filter="filter"
          let-filterService="filterService"
        >
          <grid-checkbox-filter-menu
            [isPrimitive]="true"
            [field]="column.field"
            [operator]="'eq'"
            [filterService]="filterService"
            [currentFilter]="filter"
            [addSelectAllOption]="true"
            [isLoadingFilterData]="isLoadingFilterData"
            [data]="gridFilterData[column.field]?.Values"
          ></grid-checkbox-filter-menu>
        </ng-template>
      </kendo-grid-column-ex>

      <kendo-grid-column-ex
        title="Missing Requirements"
        field="MissingRequirements.Name"
        width="160"
        [sortable]="false"
        [groupable]="false"
        data-x='MissingRequirements[{ "join":"\n", "skipEmpty":"true", "toStringFormat": "{Name} ({Status})" }]'
      >
        <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
          <div class="wrap">
            <ng-template
              *ngIf="dataItem.Name !== null"
              ngFor
              let-missing
              [ngForOf]="dataItem.MissingRequirements"
              let-i="index"
            >
              {{ missing.Name }} ({{ missing.Status }})&nbsp;&nbsp;
            </ng-template>
          </div>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">Missing Requirements</span>
        </ng-template>
        <ng-template
          kendoGridFilterMenuTemplate
          let-column="column"
          let-filter="filter"
          let-filterService="filterService"
        >
          <grid-checkbox-filter-menu
            [isPrimitive]="true"
            [field]="column.field"
            [collectionField]="'MissingRequirements'"
            [collectionItemField]="'Name'"
            [filterService]="filterService"
            [currentFilter]="filter"
            [isLoadingFilterData]="isLoadingFilterData"
            [addSelectAllOption]="true"
            [data]="gridFilterData[column.field]?.Values"
          ></grid-checkbox-filter-menu>
        </ng-template>
      </kendo-grid-column-ex>

      <kendo-grid-column-ex
        title="Notes"
        field="Notes"
        width="100"
        [filterable]="true"
        [sortable]="false"
        [groupable]="false"
      >
        <ng-template
          kendoGridGroupHeaderTemplate
          let-field
          let-value
          let-dataItem
        >
          <ggx-group-selection-header
            [dataItem]="dataItem"
            [fieldName]="'Notes'"
          ></ggx-group-selection-header>
        </ng-template>
        <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
          <div
            kendoTooltip
            [tooltipTemplate]="notesTooltip"
            style="text-align: center; font-size: 18px"
          >
            <span
              *ngIf="dataItem.Notes !== null && dataItem.Notes.length !== 0"
              title
              class="text-truncate"
            >
              <i class="fa fa-clipboard"></i>
            </span>
            <ng-template #notesTooltip>
              <div style="width: 300px">
                <span [ngStyle]="{ 'white-space': 'pre-wrap' }">
                  {{ dataItem.Notes }}
                </span>
              </div>
            </ng-template>
          </div>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">{{ column.title }}</span>
        </ng-template>
        <ng-template
          kendoGridFilterMenuTemplate
          let-filter
          let-column="column"
          let-filterService="filterService"
        >
          <kendo-grid-string-filter-menu
            [column]="column"
            [filter]="filter"
            [filterService]="filterService"
          >
            <kendo-filter-eq-operator></kendo-filter-eq-operator>
            <kendo-filter-neq-operator></kendo-filter-neq-operator>
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
            <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
            <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
            <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            <kendo-filter-isempty-operator
              text="Is blank"
            ></kendo-filter-isempty-operator>
            <kendo-filter-isnotempty-operator
              text="Is not blank"
            ></kendo-filter-isnotempty-operator>
          </kendo-grid-string-filter-menu>
        </ng-template>
      </kendo-grid-column-ex>

      <kendo-grid-column-ex
        title="Other Job(s)"
        field="OtherJobs.VPJobId"
        width="100"
        [sortable]="false"
        [groupable]="false"
        data-x='OtherJobs[{ "join":"\n", "skipEmpty":"true" }].VPJobId'
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div
            class="extended"
            kendoTooltip
            title
            [tooltipTemplate]="otherJobsTemplate"
            [ngClass]="{ 'text-truncate': !shouldWrapWords }"
          >
            <ng-container *ngTemplateOutlet="otherJobsTemplate"></ng-container>
            <ng-template #otherJobsTemplate>
              <ng-template ngFor let-otherJob [ngForOf]="dataItem.OtherJobs">
                <span>
                  {{ otherJob.VPJobId }}
                </span>
              </ng-template>
            </ng-template>
          </div>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">Other Job(s)</span>
        </ng-template>
        <ng-template
          kendoGridFilterMenuTemplate
          let-column="column"
          let-filter="filter"
          let-filterService="filterService"
        >
          <grid-checkbox-filter-menu
            [isPrimitive]="true"
            [field]="column.field"
            [collectionField]="'OtherJobs'"
            [collectionItemField]="'VPJobId'"
            [filterService]="filterService"
            [currentFilter]="filter"
            [isLoadingFilterData]="isLoadingFilterData"
            [addBlankOption]="true"
            [data]="gridFilterData[column.field]?.Values"
          ></grid-checkbox-filter-menu>
        </ng-template>
      </kendo-grid-column-ex>

      <kendo-grid-column-ex
        title="Other Job(s) Start Date"
        field="OtherJobs.StartDate"
        width="180"
        [sortable]="false"
        [groupable]="false"
        data-x='OtherJobs[{ "join":"\n", "skipEmpty":"true" }].StartDate { "toStringFormat": "{0:MM/dd/yyyy}" }'
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div
            class="extended"
            [ngClass]="{ 'text-truncate': !shouldWrapWords }"
            kendoTooltip
            title
            [tooltipTemplate]="otherJobsStartDateTemplate"
          >
            <ng-container
              *ngTemplateOutlet="otherJobsStartDateTemplate"
            ></ng-container>
            <ng-template #otherJobsStartDateTemplate>
              <ng-template ngFor let-otherJob [ngForOf]="dataItem.OtherJobs">
                <span *ngIf="otherJob.StartDate">
                  {{ otherJob.StartDate | date: 'MM/dd/yyyy' }}
                </span>
              </ng-template>
            </ng-template>
          </div>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">Other Job(s) Start Date</span>
        </ng-template>
        <ng-template
          kendoGridFilterMenuTemplate
          let-filter
          let-column="column"
          let-filterService="filterService"
        >
          <grid-checkbox-filter-menu
            [isPrimitive]="false"
            [showFilter]="false"
            [height]="100"
            [field]="column.field"
            [collectionField]="'OtherJobs'"
            [collectionItemField]="'StartDate'"
            [filterService]="filterService"
            [currentFilter]="filter"
            [isSingleSelection]="true"
            [isLoadingFilterData]="isLoadingFilterData"
            [valueField]="'value'"
            [textField]="'text'"
            [data]="[
              { value: blankFilterValue, text: blankFilterText },
              { value: notBlankFilterValue, text: notBlankFilterText }
            ]"
          ></grid-checkbox-filter-menu>
        </ng-template>
      </kendo-grid-column-ex>

      <kendo-grid-column-ex
        title="Other Job(s) End Date"
        field="OtherJobs.EndDate"
        width="180"
        [sortable]="false"
        [groupable]="false"
        data-x='OtherJobs[{ "join":"\n", "skipEmpty":"true" }].EndDate { "toStringFormat": "{0:MM/dd/yyyy}" }'
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div
            class="extended"
            [ngClass]="{ 'text-truncate': !shouldWrapWords }"
            kendoTooltip
            title
            [tooltipTemplate]="otherJobsEndDateTemplate"
          >
            <ng-container
              *ngTemplateOutlet="otherJobsEndDateTemplate"
            ></ng-container>
            <ng-template #otherJobsEndDateTemplate>
              <ng-template ngFor let-otherJob [ngForOf]="dataItem.OtherJobs">
                <span *ngIf="otherJob.EndDate">
                  {{ otherJob.EndDate | date: 'MM/dd/yyyy' }}
                </span>
              </ng-template>
            </ng-template>
          </div>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">Other Job(s) End Date</span>
        </ng-template>
        <ng-template
          kendoGridFilterMenuTemplate
          let-filter
          let-column="column"
          let-filterService="filterService"
        >
          <grid-checkbox-filter-menu
            [isPrimitive]="false"
            [showFilter]="false"
            [height]="100"
            [field]="column.field"
            [collectionField]="'OtherJobs'"
            [collectionItemField]="'EndDate'"
            [filterService]="filterService"
            [currentFilter]="filter"
            [isSingleSelection]="true"
            [isLoadingFilterData]="isLoadingFilterData"
            [valueField]="'value'"
            [textField]="'text'"
            [data]="[
              { value: blankFilterValue, text: blankFilterText },
              { value: notBlankFilterValue, text: notBlankFilterText }
            ]"
          ></grid-checkbox-filter-menu>
        </ng-template>
      </kendo-grid-column-ex>

      <kendo-grid-column-ex
        title="Other Job(s) Dispatch"
        field="OtherJobs.DispatchStatus"
        width="180"
        [sortable]="false"
        [groupable]="false"
        data-x='OtherJobs[{ "join":"\n", "skipEmpty":"true" }].DispatchStatus'
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div
            class="extended"
            [ngClass]="{ 'text-truncate': !shouldWrapWords }"
            kendoTooltip
            title
            [tooltipTemplate]="otherJobsDispatchStatusTemplate"
          >
            <ng-container
              *ngTemplateOutlet="otherJobsDispatchStatusTemplate"
            ></ng-container>
            <ng-template #otherJobsDispatchStatusTemplate>
              <ng-template ngFor let-otherJob [ngForOf]="dataItem.OtherJobs">
                <span>
                  {{ otherJob.DispatchStatus }}
                </span>
              </ng-template>
            </ng-template>
          </div>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">Other Job(s) Dispatch</span>
        </ng-template>
        <ng-template
          kendoGridFilterMenuTemplate
          let-column="column"
          let-filter="filter"
          let-filterService="filterService"
        >
          <grid-checkbox-filter-menu
            [isPrimitive]="true"
            [field]="column.field"
            [collectionField]="'OtherJobs'"
            [collectionItemField]="'DispatchStatus'"
            [filterService]="filterService"
            [currentFilter]="filter"
            [isLoadingFilterData]="isLoadingFilterData"
            [addBlankOption]="true"
            [data]="gridFilterData[column.field]?.Values"
          ></grid-checkbox-filter-menu>
        </ng-template>
      </kendo-grid-column-ex>

      <kendo-grid-column-ex
        title="Assigned To"
        field="AssetControlManager"
        [hidden]="false"
        width="130"
        [includeInChooser]="false"
      >
        <ng-template
          kendoGridGroupHeaderTemplate
          let-field
          let-value
          let-dataItem
        >
          <ggx-group-selection-header
            [dataItem]="dataItem"
            [fieldName]="'AssetControlManager'"
          ></ggx-group-selection-header>
        </ng-template>
        <ng-template
          kendoGridCellTemplate
          let-field
          let-value
          let-dataItem
          let-rowIndex="rowIndex"
        >
          <span
            title="{{
              dataItem.AssetControlManager
                ? dataItem.AssetControlManager
                : 'Unassigned'
            }}"
          >
            {{
              dataItem.AssetControlManager
                ? dataItem.AssetControlManager
                : 'Unassigned'
            }}
          </span>
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-column>
          <span title="{{ column.title }}">{{ column.title }}</span>
        </ng-template>
        <ng-template
          kendoGridFilterMenuTemplate
          let-column="column"
          let-filter="filter"
          let-filterService="filterService"
        >
          <grid-checkbox-filter-menu
            [isPrimitive]="true"
            [field]="column.field"
            [filterService]="filterService"
            [currentFilter]="filter"
            [addSelectAllOption]="true"
            [addBlankOption]="true"
            [customBlankOptionText]="'Unassigned'"
            [isLoadingFilterData]="isLoadingFilterData"
            [data]="gridFilterData[column.field]?.Values"
          ></grid-checkbox-filter-menu>
        </ng-template>
      </kendo-grid-column-ex>

      <kendo-grid-column
        title="Billable"
        field="IsBillable"
        [width]="100"
      ></kendo-grid-column>
      <kendo-grid-column
        title="Time Needed"
        field="TimeNeeded"
        [width]="120"
      ></kendo-grid-column>
      <kendo-grid-column
        title="PO Number"
        field="PONumber"
        [width]="100"
      ></kendo-grid-column>
      <kendo-grid-column
        title="Phase"
        field="PhaseName"
        [width]="130"
      ></kendo-grid-column>
      <kendo-grid-column
        title="Requested by"
        field="SubmittedUserName"
        [width]="130"
      ></kendo-grid-column>
      <kendo-grid-column
        title="Requested Date"
        field="DateSubmitted"
        [width]="130"
        filter="date"
        type="date"
        format="{0:d}"
      ></kendo-grid-column>
      <kendo-grid-column
        title="Modified by"
        field="ModifiedUserName"
        [width]="130"
      ></kendo-grid-column>
      <kendo-grid-column
        title="Modified Date"
        field="DateModified"
        [width]="130"
        filter="date"
        type="date"
        format="{0:d}"
      ></kendo-grid-column>
    </kendo-grid>
  </div>
</div>

<ups-equipment-dashboard-detail
  (dataChanged)="loadData()"
></ups-equipment-dashboard-detail>

<ups-equipment-dashboard-bulk-edit
  (bulkEditConfirmed)="loadData()"
></ups-equipment-dashboard-bulk-edit>
