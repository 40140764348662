import { Injectable } from '@angular/core';
import { ApplicationModel } from '@ups/xplat/api/dto';
import { ManPowerService } from '@ups/xplat/base/manpower';

@Injectable({ providedIn: 'root' })
export class RegionResolver {
  constructor(
    private manPowerService: ManPowerService,
    private applicationModel: ApplicationModel
  ) {}

  public load(): Promise<unknown> {
    if (this.applicationModel.regions.length === 0) {
      return this.manPowerService.getRegions().then((regions) => {
        this.applicationModel.regions = regions;
      });
    } else {
      return Promise.resolve(this.applicationModel.regions);
    }
  }
}
