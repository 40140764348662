import { BaseModel } from '../base.model';

export class HelpDeskDTO extends BaseModel {
  FullName: string;
  Email: string;
  Phone: string;
  Body: string;
  SmartlookURL?: string;
  RelativeURL: string;
  ErrorHistory: string[];
  Image?: File;
  ShortDescription: string;
  SupportInfo: string;

  constructor(o: any = null) {
    super(o);
  }
}
