import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
  AssignmentGroup,
  WorkflowCreationModel,
} from '@ups/xplat/core';

const GET_USERS_ODATA = (query) => `api/users?${query}`;
const GET_ASSIGNMENT_GROUPS_ODATA = (query) =>
  `api/assignmentgroup${query ? '?' + query : ''}`;
const CREATE_ASSIGNMENT_GROUP = `api/assignmentgroup`;
const REMOVE_USER = (group, user) =>
  `api/assignmentgroup/id/${group}/user/${user}`;
const ADD_USER = (group, user) =>
  `api/assignmentgroup/id/${group}/user/${user}`;
@Injectable({ providedIn: 'root' })
export class AssignmentGroupsService {
  private http: HttpClient;

  constructor(private httpClientFactory: MyHttpClientFactory) {
    this.http = httpClientFactory.createHttpClient(
      environment.urls.tasksAPI,
      true,
      ResponseCasingEnum.CamelCase
    );
  }

  getUsers(odataQuery: string) {
    return this.http.get<any[]>(GET_USERS_ODATA(odataQuery));
  }

  getAssignmentGroups(odataQuery?: string) {
    return this.http.get<AssignmentGroup[]>(
      GET_ASSIGNMENT_GROUPS_ODATA(odataQuery)
    );
  }

  createAssignmentGroup(template: AssignmentGroup) {
    return this.http.post<AssignmentGroup>(CREATE_ASSIGNMENT_GROUP, template);
  }

  removeUserFromAssignmentGroup(groupId: string, userId: string) {
    return this.http.delete<AssignmentGroup>(REMOVE_USER(groupId, userId));
  }

  addUserToAssignmentGroup(groupId: string, userId: string) {
    return this.http.post<AssignmentGroup>(ADD_USER(groupId, userId), null);
  }
}
