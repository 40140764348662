import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import { DynamicSignatureBaseComponent } from '@ups/xplat/features';
import SignaturePad, { Options, PointGroup } from 'signature_pad';
import { IDynamicPointGroup } from '@ups/xplat/core';

@Component({
  selector: 'ups-dynamic-signature',
  templateUrl: './dynamic-signature.component.html',
})
export class DynamicSignatureComponent
  extends DynamicSignatureBaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('signaturePad', { static: false }) signaturePadElement: ElementRef;
  @ViewChild('signaturePadContainer', { static: false })
  signaturePadContainerElement: ElementRef;

  private signaturePad: SignaturePad;

  ngOnInit() {
    super.ngOnInit();
    this.config.options.signature.width = 600;
    if (!this.config.placeholder) {
      this.config.placeholder = this.legalText;
    }
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (!changes.config.firstChange) {
  //     Object.keys(changes.config.currentValue).map((key) => {
  //       this.signaturePad[key] = changes.config.currentValue[key];
  //     });
  //   }
  // }

  ngAfterViewInit(): void {
    if (!this.config?.disabled) {
      this.setCanvasSize();
      this.signaturePad = new SignaturePad(
        this.signaturePadElement.nativeElement,
        {
          ...(this.config ? this.config?.options?.signature : {}),
        }
      );
    }
  }

  private setCanvasSize(): void {
    let width;
    let height;
    console.log('this.config:', this.config);
    if (this.config) {
      if (this.config.options?.signature?.width) {
        width = this.config.options?.signature?.width;
      }
      if (this.config.options?.signature?.height) {
        height = this.config.options?.signature?.height;
      }
    }
    const { offsetWidth, offsetHeight } =
      this.signaturePadContainerElement.nativeElement;
    // console.log('width:', width);
    // console.log('height:', height);
    this.signaturePadElement.nativeElement.width = width || offsetWidth || 300;
    this.signaturePadElement.nativeElement.height =
      height || offsetHeight || 200;
  }

  clear(): void {
    this.confirmClear().then((ok) => {
      if (ok) {
        this.signaturePad.clear();
      }
    });
  }

  reset() {
    if (this.signaturePad) {
      this.signaturePad.clear();
    }
    return true;
  }

  fromDataURL(
    dataUrl: string,
    options?: {
      ratio?: number;
      width?: number;
      height?: number;
    },
    callback?: (value?: void) => void
  ): void {
    this.signaturePad.fromDataURL(dataUrl, options).then(callback, callback);
  }

  toDataURL(type?: string, encoderOptions?: number): string {
    return this.signaturePad.toDataURL(type, encoderOptions);
  }

  on(): void {
    this.signaturePad.on();
  }

  off(): void {
    this.signaturePad.off();
  }

  undo() {
    const data = this.signaturePad.toData();
    if (data) {
      data.pop(); // remove the last dot or line
      this.signaturePad.fromData(data);
    }
  }

  changeColor() {
    const r = Math.round(Math.random() * 255);
    const g = Math.round(Math.random() * 255);
    const b = Math.round(Math.random() * 255);
    const color = 'rgb(' + r + ',' + g + ',' + b + ')';
    this.signaturePad.penColor = color;
  }

  isEmpty(): boolean {
    return this.signaturePad ? this.signaturePad.isEmpty() : true;
  }

  fromData(pointGroups: IDynamicPointGroup[]): void {
    this.signaturePad.fromData(<Array<PointGroup>>(<unknown>pointGroups));
  }

  toData(): IDynamicPointGroup[] {
    return <Array<IDynamicPointGroup>>(<unknown>this.signaturePad.toData());
  }

  forceUpdate(): void {
    this.setCanvasSize();
    this.signaturePad = new SignaturePad(
      this.signaturePadElement.nativeElement,
      this.config as Options
    );
  }

  savePNG() {
    this.confirmSignature().then((ok) => {
      if (ok) {
        if (this.signaturePad.isEmpty()) {
          alert('Please provide a signature first.');
        } else {
          this.getBase64().then((dataURL) => {
            if (this.config.formControlName) {
              this.save.emit(dataURL);
            } else {
              this.download(dataURL, 'signature.png');
            }
          });
        }
      }
    });
  }

  getBase64(): Promise<string> {
    // ensure required validators are removed
    this.setValidators(null);
    return Promise.resolve(this.signaturePad.toDataURL());
  }

  saveJPG() {
    this.confirmSignature().then((ok) => {
      if (ok) {
        if (this.signaturePad.isEmpty()) {
          alert('Please provide a signature first.');
        } else {
          const dataURL = this.signaturePad.toDataURL('image/jpeg');
          this.download(dataURL, 'signature.jpg');
        }
      }
    });
  }

  saveSVG() {
    this.confirmSignature().then((ok) => {
      if (ok) {
        if (this.signaturePad.isEmpty()) {
          alert('Please provide a signature first.');
        } else {
          const dataURL = this.signaturePad.toDataURL('image/svg+xml');
          this.download(dataURL, 'signature.svg');
        }
      }
    });
  }

  download(dataURL, filename) {
    if (
      navigator.userAgent.indexOf('Safari') > -1 &&
      navigator.userAgent.indexOf('Chrome') === -1
    ) {
      window.open(dataURL);
    } else {
      const blob = this.dataURLToBlob(dataURL);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
    }
    this.showSuccess();
  }

  dataURLToBlob(dataURL) {
    // Code taken from https://github.com/ebidel/filer.js
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  }
}
