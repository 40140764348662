import {
  ChangeDetectorRef,
  Directive,
  inject,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  BaseComponent,
  ErrorSuccessService,
  LogService,
  RequestStatusEnum,
} from '@ups/xplat/core';
import {
  AddressChangeRequestDto,
  AddressModel,
  ProfileModel,
} from '@ups/xplat/api/dto';
import { NgForm } from '@angular/forms';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import { SpartaFileService } from '@ups/files';
import { EmployeeGuardService, SpartaEmployeeService } from '../services';
import {
  CountriesResolver,
  JobsService,
  StatesResolver,
} from '@ups/xplat/api/services';
import { zipCodeRegex } from '@ups/xplat/utils';
import { PROFILE_INFO_PROVIDER_TOKEN } from '../models';

@Directive()
export abstract class ProfileBasicColumnBaseComponent
  extends BaseComponent
  implements OnInit
{
  @Input() editCellPhone: boolean;
  @Input() editMode: boolean;

  @Input() set profile(profile: ProfileModel) {
    this.profileSignal.update(() => ({ profile: profile }));
    this._profile = profile;
    this.originalProfile = JSON.parse(JSON.stringify(profile));
  }

  get profile() {
    return this._profile;
  }

  _profile: ProfileModel;

  readonly profileSignal = inject(PROFILE_INFO_PROVIDER_TOKEN);

  @ViewChild('form') form: NgForm;

  addressChangeRequestDto: AddressChangeRequestDto = null;
  addressChangeRequestDtoReadonly: AddressChangeRequestDto = null;
  originalAddressChangeRequestDto: AddressChangeRequestDto = null;

  acknowledgementOfPhysicalAddressApproval = false;
  addressSameAs = false;

  isLoadingStates = false;
  isLoadingCountries = false;

  isAnySecondaryAddressFieldFilled = false;
  isMailingAddressChanged = false;
  isPhysicalAddressChanged = false;

  countries = [];
  states = [];

  tier1Security = false;

  today = new Date();
  minAllowableEffectiveDate = this.today;

  onEdit: boolean;

  zipCodeRegex = zipCodeRegex;

  protected originalProfile: ProfileModel;

  protected spartaFileService: SpartaFileService;
  protected errorSuccessService: ErrorSuccessService;
  protected spartaEmployeeService: SpartaEmployeeService;

  private securityConsts = SecurityConstants;

  private employeeGuardService: EmployeeGuardService;
  private securityService: SecurityService;
  private jobsService: JobsService;
  private stateResolver: StatesResolver;
  private countryResolver: CountriesResolver;
  private ref: ChangeDetectorRef;

  constructor(private injector: Injector, protected log: LogService) {
    super();
  }

  abstract showSuccessNotification(message: string): void;
  abstract showErrorNotification(message: string): void;

  ngOnInit() {
    this.spartaFileService = this.injector.get(SpartaFileService);
    this.securityService = this.injector.get(SecurityService);
    this.spartaEmployeeService = this.injector.get(SpartaEmployeeService);
    this.errorSuccessService = this.injector.get(ErrorSuccessService);
    this.employeeGuardService = this.injector.get(EmployeeGuardService);
    this.jobsService = this.injector.get(JobsService);
    this.stateResolver = this.injector.get(StatesResolver);
    this.countryResolver = this.injector.get(CountriesResolver);
    this.ref = this.injector.get(ChangeDetectorRef);

    this.employeeGuardService.isToUseGuard = false;

    this.originalProfile = JSON.parse(JSON.stringify(this.profile));

    this.tier1Security = this.securityService.getFeatureById(
      this.securityConsts.employeeportal_employeeprofile_editemail
    ).editAll;

    if (!this.profile.physicalAddress) {
      this.profile.physicalAddress = new AddressModel();
    }

    this.today.setHours(0, 0, 0);

    this.loadAddressChangeRequest();
  }

  ngOnChanges() {
    if (this.profile) {
      if (!this.profile.isDirty) {
        this.onEdit = false;
      }
    }
    this.addressSameAs = this.checkAddressEquality();
  }

  checkAddressEquality() {
    if (!this.addressChangeRequestDto) return false;
    if (!this.profile) return false;
    if (
      this.profile.mailingAddress.line1 !== this.addressChangeRequestDto.address
    )
      return false;
    if (
      this.profile.mailingAddress.line2 !==
      this.addressChangeRequestDto.address2
    )
      return false;
    if (this.profile.mailingAddress.city !== this.addressChangeRequestDto.city)
      return false;
    if (
      this.profile.mailingAddress.state !== this.addressChangeRequestDto.state
    )
      return false;
    if (this.profile.mailingAddress.zip !== this.addressChangeRequestDto.zip)
      return false;
    if (
      this.profile.mailingAddress.country !==
      this.addressChangeRequestDto.country
    )
      return false;
    return true;
  }

  saveMailingAddressAndContactInfo(body) {
    try {
      return this.jobsService
        .patchEmployeeProfileBasicDetails(this.profile.hrRef, body)
        .then((data) => {
          this.errorSuccessService.setSuccess(data);
          this.onEdit = false;
          this.employeeGuardService.isToUseGuard = false;
          this.profile.isDirty = false;
          this.markFormAsPristine();

          this.jobsService
            .patchEmployeeProfileForSpartaOnly(this.profile.hrRef, body)
            .then(() => {
              this.originalProfile = JSON.parse(JSON.stringify(this.profile));
              this.profileSignal.update((x) => ({
                ...x,
                profile: this.originalProfile,
              }));
              this.showSuccessNotification('Successfully saved');
            })
            .catch((error) => {
              this.showErrorNotification(
                'Mailing Address and Contact info was saved to Viewpoint but NOT SAVED to Security and/or AbsorbLMS due to error:' +
                  error?.error?.Messages ??
                  error?.error ??
                  error
              );
              this.log.error(
                'Mailing Address and Contact info was saved to Viewpoint but NOT SAVED to Security and/or AbsorbLMS due to an error.',
                error
              );
            });
        })
        .catch((error) => {
          this.showErrorNotification(
            'Saving of the Mailing Address and Contact info failed. Error:' +
              error?.error?.Messages ??
              error?.error ??
              error
          );
          this.errorSuccessService.setError(
            'Saving of the Mailing Address and Contact info failed.'
          );
          this.log.error(
            'Saving of the Mailing Address and Contact info failed.',
            error
          );
          throw error;
        });
    } catch (error) {
      // To catch errors before we call http request. Mostly bugs in code silently failing #31349
      this.showErrorNotification(
        'Saving of the Mailing Address and Contact info failed. Error: ' +
          error?.error?.Messages ??
          error?.error ??
          error
      );
      this.errorSuccessService.setError(
        'Saving of the Mailing Address and Contact info failed.'
      );
      this.log.error(
        'Saving of the Mailing Address and Contact info failed.',
        error
      );
      throw error;
    }
  }

  loadAddressChangeRequest() {
    this.spartaEmployeeService
      .fetchAddressChangeRequest(parseInt(this.profile.hrRef, 10), null)
      .then((r) => this.processReceivedAddressChangeRequest(r))
      .then(() => this.loadStates())
      .catch((error) => {
        this.errorSuccessService.setError(error);
        throw error;
      });
  }

  async loadStates() {
    if (!this.states || this.states.length === 0) {
      this.isLoadingStates = true;
      await this.stateResolver.load().then((data) => {
        this.isLoadingStates = false;
        this.states = data;
        this.filterStates(this.addressChangeRequestDto.country, 'pa');
        this.filterStates(this.profile?.mailingAddress?.country, 'ma');
        this.calculateMailingAddressChanges();
        this.calculatePhysicalAddressChanges();
      });
    } else {
      this.filterStates(this.addressChangeRequestDto.country, 'pa');
      this.filterStates(this.profile?.mailingAddress?.country, 'ma');
    }

    if (!this.countries || this.countries.length === 0) {
      this.isLoadingCountries = true;
      this.countryResolver.load().then((x) => {
        this.isLoadingCountries = false;
        this.calculateMailingAddressChanges();
        this.calculatePhysicalAddressChanges();
        this.countries = x.filter((x2) =>
          this.states.some((y) => y.Country === x2.CountryCode)
        );
      });
    }
  }

  processReceivedAddressChangeRequest(r: AddressChangeRequestDto) {
    if (r === null || r.requestStatusId !== RequestStatusEnum.Requested) {
      // In case of the declined
      if (r != null && r.requestStatusId === RequestStatusEnum.Declined) {
        this.addressChangeRequestDtoReadonly = r;
      }

      // Show original physical address if there is no pending address change request
      this.addressChangeRequestDto = new AddressChangeRequestDto();
      this.mapAddressToChangeRequestDto(
        this.addressChangeRequestDto,
        this.profile.physicalAddress
      );
    } else {
      // Show pending address change request if there is one active
      this.addressChangeRequestDto = r;
      this.addressChangeRequestDtoReadonly = r;
    }

    this.originalAddressChangeRequestDto = new AddressChangeRequestDto(
      this.addressChangeRequestDto
    );

    this.calculatePhysicalAddressChanges();
  }

  mapAddressToChangeRequestDto(
    addressChangeRequestDto: AddressChangeRequestDto,
    address: AddressModel
  ) {
    if (!addressChangeRequestDto) {
      return;
    }

    if (address == null) {
      address = new AddressModel();
    }

    addressChangeRequestDto.address = address.line1;
    addressChangeRequestDto.address2 = address.line2;
    addressChangeRequestDto.city = address.city;
    addressChangeRequestDto.state = address.state;
    addressChangeRequestDto.country = address.country;
    addressChangeRequestDto.zip = address.zip;
  }

  cancelEdit(event) {
    event.preventDefault();

    this.onEdit = false;

    if (
      this.addressChangeRequestDto.fileId !==
      this.originalAddressChangeRequestDto.fileId
    ) {
      // As the file got uploaded but not saved with address change request,- delete the file to avoid orphaned files in azure storage
      this.deleteFile(this.addressChangeRequestDto.fileId);
    }

    this.profile = JSON.parse(JSON.stringify(this.originalProfile));

    this.addressSameAs = false;
    this.acknowledgementOfPhysicalAddressApproval = false;
    this.profile.isDirty = false;
    this.employeeGuardService.isToUseGuard = false;

    this.markFormAsPristine();
    this.calculateMailingAddressChanges();
    this.calculatePhysicalAddressChanges();
  }

  async deleteFile(fileId: string) {
    if (fileId) {
      await this.spartaFileService.deleteFile(fileId);
    }
  }

  markFormAsPristine() {
    if (this.form && this.form.form) {
      this.form.form.markAsPristine();
    }
  }

  calculateMailingAddressChanges() {
    this.isMailingAddressChanged = this.hasMailingAddressChanged();
    if (this.isMailingAddressChanged) {
      if (!this.profile.mailingAddress.country) {
        // Set Default Country to US when State is not empty and mailing address is touched/updated
        if (this.profile.mailingAddress.state) {
          this.profile.mailingAddress.country = 'US';
        } else {
          this.eraseMailingAddressCountry();
        }
      }
    } else {
      if (
        this.profile.mailingAddress.state &&
        !this.profile.mailingAddress.country
      ) {
        this.profile.mailingAddress.country = 'US';
      }
    }

    // Recalculate as country field could have been corrected
    this.isMailingAddressChanged = this.hasMailingAddressChanged();
  }

  hasMailingAddressChanged(): boolean {
    if (!this.originalProfile) return false;
    if (!this.profile) return false;
    if (!this.profile.mailingAddress) return false;
    if (!this.profile.mailingAddress && this.originalProfile.mailingAddress)
      return true;
    if (this.profile.mailingAddress && !this.originalProfile.mailingAddress)
      return true;

    if (
      this.trim(this.profile.mailingAddress.line1) !==
      this.trim(this.originalProfile.mailingAddress.line1)
    )
      return true;
    if (
      this.trim(this.profile.mailingAddress.line2) !==
      this.trim(this.originalProfile.mailingAddress.line2)
    )
      return true;
    if (
      this.trim(this.profile.mailingAddress.city) !==
      this.trim(this.originalProfile.mailingAddress.city)
    )
      return true;
    if (
      this.trim(this.profile.mailingAddress.state) !==
      this.trim(this.originalProfile.mailingAddress.state)
    )
      return true;
    if (
      this.trim(this.profile.mailingAddress.zip) !==
      this.trim(this.originalProfile.mailingAddress.zip)
    )
      return true;
    if (
      this.trim(this.profile.mailingAddress.country) !==
      this.trim(this.originalProfile.mailingAddress.country)
    )
      return true;
  }

  calculatePhysicalAddressChanges() {
    this.isPhysicalAddressChanged = this.hasPhysicalAddressChanged();
    if (this.isPhysicalAddressChanged) {
      if (!this.addressChangeRequestDto.country) {
        // Set Default Country to US when State is not empty and physical address is touched/updated
        if (this.addressChangeRequestDto.state) {
          this.addressChangeRequestDto.country = 'US';
        } else {
          this.erasePhysicalAddressCountry();
        }
      }
    } else {
      if (
        this.profile.physicalAddress.state &&
        !this.profile.physicalAddress.country
      ) {
        this.profile.physicalAddress.country = 'US';
      }
    }

    // Recalculate as country field could have been corrected
    this.isPhysicalAddressChanged = this.hasPhysicalAddressChanged();
  }

  hasPhysicalAddressChanged(): boolean {
    if (!this.addressChangeRequestDto) return false;
    if (!this.addressChangeRequestDto && this.originalAddressChangeRequestDto)
      return true;
    if (this.addressChangeRequestDto && !this.originalAddressChangeRequestDto)
      return true;

    if (
      this.trim(this.addressChangeRequestDto.address) !==
      this.trim(this.originalAddressChangeRequestDto.address)
    )
      return true;
    if (
      this.trim(this.addressChangeRequestDto.address2) !==
      this.trim(this.originalAddressChangeRequestDto.address2)
    )
      return true;
    if (
      this.trim(this.addressChangeRequestDto.city) !==
      this.trim(this.originalAddressChangeRequestDto.city)
    )
      return true;
    if (
      this.trim(this.addressChangeRequestDto.state) !==
      this.trim(this.originalAddressChangeRequestDto.state)
    )
      return true;
    if (
      this.trim(this.addressChangeRequestDto.zip) !==
      this.trim(this.originalAddressChangeRequestDto.zip)
    )
      return true;
    if (
      this.trim(this.addressChangeRequestDto.country) !==
      this.trim(this.originalAddressChangeRequestDto.country)
    )
      return true;
    if (
      this.addressChangeRequestDto.requestDate !==
      this.originalAddressChangeRequestDto.requestDate
    )
      return true;
    if (
      this.addressChangeRequestDto.fileId !==
      this.originalAddressChangeRequestDto.fileId
    )
      return true;
  }

  eraseMailingAddressCountry() {
    // Hotfix #20146
    // The country can be undefined if user is manually erasing the country dropdown so setting it to null so the validation behave as expected
    // Original country value can be null or "" so here we preserve what was originally used. Otherwise the logic will thing the country field is being updated.
    if (this.originalProfile && this.originalProfile.mailingAddress) {
      this.profile.mailingAddress.country = !this.originalProfile.mailingAddress
        .country
        ? this.originalProfile.mailingAddress.country
        : null;
    } else {
      this.profile.mailingAddress.country = null;
    }
  }

  erasePhysicalAddressCountry() {
    // Hotfix #20146
    // The country can be undefined if user is manually erasing the dropdown so setting it to null so the validation behave as expected
    // Original country value can be null or "" so here we preserve what was originally used. Otherwise the logic will thing the country field is being updated.
    if (this.originalProfile && this.originalProfile.physicalAddress) {
      this.addressChangeRequestDto.country = !this.originalProfile
        .physicalAddress.country
        ? this.originalProfile.physicalAddress.country
        : null;
    } else {
      this.addressChangeRequestDto.country = null;
    }
  }

  handleStateErase(value: string, selector: string) {
    if (!value) {
      if (selector === 'pa') {
        this.erasePhysicalAddressState();
      } else {
        this.eraseMailingAddressState();
      }
    }
  }

  erasePhysicalAddressState() {
    // Hotfix #20146 original state can be null or "" so here we preserve what was originally used. Otherwise the logic will thing the state field is being updated.
    if (this.originalProfile && this.originalProfile.physicalAddress) {
      this.addressChangeRequestDto.state = !this.originalProfile.physicalAddress
        .state
        ? this.originalProfile.physicalAddress.state
        : null;
    } else {
      this.addressChangeRequestDto.state = null;
    }
  }

  eraseMailingAddressState() {
    // Hotfix #20146 original state can be null or "" so here we preserve what was originally used. Otherwise the logic will thing the state field is being updated.
    if (this.originalProfile && this.originalProfile.mailingAddress) {
      this.profile.mailingAddress.state = !this.originalProfile.mailingAddress
        .state
        ? this.originalProfile.mailingAddress.state
        : null;
    } else {
      this.profile.mailingAddress.state = null;
    }
  }

  filterStates(country: string, selector: string): void {
    country = country == null ? 'US' : country;

    const states = this.states.filter((x) => x.Country === country);
    const stateNames = states.map((x) => x.StateCode);

    // if there was a selected state and we changed the country, erasing the state.
    // enhanced logic is here so that during form filling during load the states are not erased if the country and state are not filled in the right order
    if (
      selector === 'pa' &&
      this.form &&
      this.form.controls['physicalAddress'] &&
      this.form.controls['physicalAddress'].value['pa-state-field'] !==
        undefined &&
      this.form.controls['physicalAddress'].value['pa-state-field'] != null &&
      !this.isStateWithinStates(
        stateNames,
        this.form.controls['physicalAddress'].value['pa-state-field']
      )
    ) {
      this.erasePhysicalAddressState();
    }

    if (
      selector === 'ma' &&
      this.form &&
      this.form.controls['mailingAddress'] &&
      this.form.controls['mailingAddress'].value['ma-state-field'] !==
        undefined &&
      this.form.controls['mailingAddress'].value['ma-state-field'] != null &&
      !this.isStateWithinStates(
        stateNames,
        this.form.controls['mailingAddress'].value['ma-state-field']
      )
    ) {
      // Hotfix #20146 original state can be null or "" so here we preserve what was originally used. Otherwise the logic will thing the state field is being updated.
      this.eraseMailingAddressState();
    }

    this[selector + 'FilteredStates'] = states;
    this[selector + 'ZipRegex'] =
      this.zipCodeRegex[country || 'US']?.expr?.source;

    if (this.addressSameAs) {
      this['paFilteredStates'] = states; // if Physical address is same as Mailing (checked), update the dropdown on Physical address as well as we change the country there as well.
      this['paZipRegex'] = this['maZipRegex'];
    }

    this.anySecondaryAddressFieldFilled();
  }

  anySecondaryAddressFieldFilled(): void {
    // SetTimeout and detectChanges() prevents us from getting ExpressionChangedAfterItHasBeenCheckedError error
    setTimeout(() => {
      if (!this.addressChangeRequestDto) {
        this.isAnySecondaryAddressFieldFilled = false;
      }

      const anyFilled =
        !!this.addressChangeRequestDto.address ||
        !!this.addressChangeRequestDto.address2 ||
        !!this.addressChangeRequestDto.city ||
        !!this.addressChangeRequestDto.state ||
        !!this.addressChangeRequestDto.zip ||
        !!this.addressChangeRequestDto.requestDate ||
        (this.addressChangeRequestDto.country &&
          this.addressChangeRequestDto.country !== 'US'); // 'US' is default preselected

      if (this.isAnySecondaryAddressFieldFilled !== anyFilled) {
        this.isAnySecondaryAddressFieldFilled = anyFilled;
        if (!this.ref['destroyed']) {
          this.ref.detectChanges();
        }
      }
    }, 10);
  }

  onSameAsCheckboxChange(checked: boolean) {
    this.addressSameAs = checked;

    const profile = this.profileSignal().profile;

    if (checked) {
      this.addressChangeRequestDto.address = profile.mailingAddress.line1;
      this.addressChangeRequestDto.address2 = profile.mailingAddress.line2;
      this.addressChangeRequestDto.city = profile.mailingAddress.city;
      this.addressChangeRequestDto.zip = profile.mailingAddress.zip;
      if (
        this.addressChangeRequestDto.country !== profile.mailingAddress.country
      ) {
        this.addressChangeRequestDto.country = profile.mailingAddress.country;
        this.loadStates()
          .then(
            () =>
              (this.addressChangeRequestDto.state =
                profile.mailingAddress.state)
          )
          .then(() => this.anySecondaryAddressFieldFilled());
      } else {
        this.addressChangeRequestDto.state = profile.mailingAddress.state;
        this.anySecondaryAddressFieldFilled();
      }
    }
  }

  edit() {
    this.onEdit = true;
    this.employeeGuardService.isToUseGuard = true;
    this.profile.isDirty = true;
    this.minAllowableEffectiveDate =
      this.addressChangeRequestDto.requestDate != null
        ? new Date(
            Math.min(
              this.addressChangeRequestDto.requestDate.getTime(),
              this.today.getTime()
            )
          )
        : this.today;
    this.filterStates(this.addressChangeRequestDto.country, 'pa');
    this.filterStates(this.profile.mailingAddress.country, 'ma');
  }

  protected trim(stringToTrim: string): string {
    if (!stringToTrim || !stringToTrim.length) {
      return stringToTrim;
    }

    return stringToTrim.trim();
  }

  private isStateWithinStates(states: string[], state: string) {
    const foundState = states.find((s: string) => s === state);
    return foundState != null;
  }
}
