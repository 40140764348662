import { BaseModel } from '../../base.model';

export class PaySequenceDto extends BaseModel {
  public bActive: boolean;
  public CompanyID: string | null;
  public Description: string;
  public PaySeq: number;
  public PaySequenceID: number;
  public CompanyName: string;
  public VpCompanyID: number;

  constructor(o: any = null) {
    super(o);
  }
}
