import {
  Component,
  Output,
  EventEmitter,
  ComponentRef,
  Type,
  ViewChild,
  ComponentFactoryResolver,
  ChangeDetectorRef,
  ElementRef,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IDynamicModel } from '@ups/xplat/core';
import { DynamicItemBaseComponent } from '@ups/xplat/features';
import { EditableInsertionDirective } from './editable-insertion.directive';

@Component({
  selector: 'dr-editable',
  template: `
    <div class="dr-editable-wrapper" (click)="onWrapperClick()">
      <div class="dynamic-control-container">
        <ng-template editableInsertion></ng-template>
      </div>
      <div class="dr-editable-header">
        <span class="action-btn">
          <i class="far fa-clone"></i>
        </span>

        <span
          [attr.id]="'dr-edit-del-' + childConfig.formControlName"
          class="action-btn dr-action-alert"
        >
          <i class="far fa-trash"></i>
        </span>
      </div>
    </div>
  `,
  styles: [
    `
      .dr-editable-wrapper {
        border-radius: 4px;
        padding: 4px;
        position: relative;
        margin-right: 15px;
        margin-bottom: 30px;
        cursor: grab;
        display: flex;
        align-items: center;
      }

      .dr-editable-wrapper:hover {
        background-color: #f5f5f5;
      }

      .dynamic-control-container {
        flex: 1;
      }

      .dr-editable-header {
        z-index: 999;
        display: flex;
        justify-content: flex-end;
      }
      .action-btn {
        display: inline-block;
        height: 30px;
        width: 30px;
        background-color: #ffffff;
        border-radius: 40px;
        box-shadow: 0px 5px 10px #00000040;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
      }
      .dr-action-alert {
        color: #ef3b24;
      }
    `,
  ],
})
export class EditableWrapperComponent {
  @Output() selectedEvt: EventEmitter<string> = new EventEmitter();
  @Output() childLoaded: EventEmitter<boolean> = new EventEmitter();

  public componentRef: ComponentRef<DynamicItemBaseComponent>;
  public childComponentType: Type<DynamicItemBaseComponent>;
  public childConfig: IDynamicModel;
  public childFormGroup: UntypedFormGroup;
  public renderDelay: number = undefined;

  @ViewChild(EditableInsertionDirective)
  insertionPoint: EditableInsertionDirective;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private elRef: ElementRef<any>,
    private cd: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.loadChildComponent(this.childComponentType);
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  public onWrapperClick() {
    alert('click');
    this.selectedEvt.emit('id');
  }

  public loadChildComponent(componentType: Type<DynamicItemBaseComponent>) {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(componentType);

    const viewContainerRef = this.insertionPoint.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
    this.componentRef.instance.config = this.childConfig;
    this.componentRef.instance.group = this.childFormGroup;
    this.componentRef.instance.itemCreated.subscribe((e) => {
      this.onDynamicComponentCreated(e);
    });

    if (!this.renderDelay) {
      this.childLoaded.emit(true);
    }
  }

  private onDynamicComponentCreated(e) {
    if (this.renderDelay) {
      setTimeout(() => {
        this.childLoaded.emit(true);
      }, this.renderDelay);
    }
  }
}
