import { PackListDto } from './PackListDto';

export class PackDto {
  packId: number;
  orderId: number;
  location: string;
  itemList: PackListDto[];
  dateShipped?: string;
  carrier?: string;
  trackingNumber?: string;
  trackingURL?: string;
}
