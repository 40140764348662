import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ups-notification-icon-component',
  templateUrl: 'notification-icon-component.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationIconComponentComponent {
  @Input()
  isVisible = false;

  @Input()
  fontAwesomeIconClass: string;

  @Input()
  get rehireStatus() {
    return this._rehireStatus;
  }
  set rehireStatus(val: string | null | undefined) {
    this._rehireStatus = val;
    this.isVisible = val != null && val !== 'Rehireable' && val !== '';
    this._notificationTitle = val;
  }

  @Input()
  get notificationTitle() {
    return this._notificationTitle;
  }
  set notificationTitle(val: string | null | undefined) {
    this._notificationTitle = val;
  }

  private _rehireStatus: string | null | undefined;

  private _notificationTitle: string | null | undefined;

  constructor() {}
}
