import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fit',
})
export class FitPipe implements PipeTransform {
  transform(item: any, show = false) {
    if (new Date(item?.ExpireDate) < new Date()) {
      return `${show ? '(' : ''}expired${show ? ')' : ''}`;
    } else if (item?.approvalRequest) {
      return `${show ? '(' : ''}pending${show ? ')' : ''}`;
    }
    return '';
  }
}
