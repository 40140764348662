import { Injectable } from '@angular/core';
import { ApplicationModel } from '@ups/xplat/api/dto';
import { JobsService } from '@ups/xplat/api/services';

@Injectable({ providedIn: 'root' })
export class JobBillMethodResolver {
  isLoading: boolean = false;

  constructor(private jobService: JobsService, private applicationModel: ApplicationModel) {}

  public load(): Promise<any> {
    const self = this;
    if (this.applicationModel.jobBillMethods.length === 0 && !this.isLoading) {
      self.isLoading = true;
      return this.jobService.getJobBillMethods().then(function (data) {
        self.applicationModel.jobBillMethods = data;
        self.isLoading = false;
      });
    } else {
      return Promise.resolve(this.applicationModel.jobBillMethods);
    }
  }
}
