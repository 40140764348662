import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService, environment, MyHttpClientFactory } from '@ups/xplat/core';
import { Observable } from 'rxjs';
import { SafetyCouncilCardModel, SafetyTrainingCert } from './models';
import { TrainingClass } from './models';

@Injectable({
  providedIn: 'root',
})
export class TrainingClassService extends DataService<TrainingClass> {
  route: string;

  constructor(httpClient: HttpClient, httpFactory: MyHttpClientFactory) {
    super(
      environment.urls.softwareSecurityAPI,
      '/api/TrainingClass',
      httpClient,
      httpFactory
    );
  }

  saveTrainingClasses(
    trainingCodes: SafetyCouncilCardModel[]
  ): Observable<unknown> {
    return this.httpClient.post(this.route, trainingCodes);
  }

  getTrainingClasses(query: string): Observable<TrainingClass[]> {
    return this.httpClient.get<TrainingClass[]>(
      this.route + `?${query}&top=20`
    );
  }

  getTrainingClassByKeyId(keyId: number): Observable<TrainingClass> {
    return this.httpClient.get<TrainingClass>(this.route + `/${keyId}`);
  }

  uploadCertificate(
    safetyTrainingCert: SafetyTrainingCert
  ): Observable<string> {
    return this.httpClient.post<string>(
      `${this.route}/upload`,
      safetyTrainingCert
    );
  }
}
