import { NgModule } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';

import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ChartModule } from '@progress/kendo-angular-charts';
import { GridModule } from '@progress/kendo-angular-grid';

import 'hammerjs';

import { UIModule } from '../ui/ui.module';
import { ADMIN_PANEL_COMPONENTS } from './components/admin-panels';
import { EDITOR_COMPONENTS } from './components/editors';
import {
  DrAdminItemEditorHostDirective,
  DynamicItemPreviewDirective,
} from './directives';
import { EditableWrapperComponent } from './components/editors/editable-wrapper.component';
import {
  PlatformDynamicComponentsPreviewToken,
  PlatformDynamicComponentsToken,
} from '@ups/xplat/features';
import { DynamicRenderModule } from '../dynamic-render/dynamic-render.module';
import { DYNAMIC_COMPONENT_PREVIEW_MAPPING } from './components/preview';
import { DynamicRenderAdminComponent } from './components/dynamic-render-admin/designer/dynamic-render-admin.component';
import { SaveDialogComponent } from './components/dynamic-render-admin/designer/save-dialog.component';
import { DynamicRenderAdminListComponent } from './components';
import { EditableInsertionDirective } from './components/editors/editable-insertion.directive';

@NgModule({
  imports: [
    UIModule,
    DashboardLayoutModule,
    DynamicRenderModule,
    DragDropModule,
    LayoutModule,
    ButtonsModule,
    LabelModule,
    InputsModule,
    DropDownListModule,
    DialogsModule,
    ChartModule,
    GridModule,
  ],
  declarations: [
    DynamicRenderAdminComponent,
    DynamicRenderAdminListComponent,
    ...ADMIN_PANEL_COMPONENTS,
    ...EDITOR_COMPONENTS,
    DrAdminItemEditorHostDirective,
    EditableWrapperComponent,
    EditableInsertionDirective,
    DynamicItemPreviewDirective,
    SaveDialogComponent,
  ],
  exports: [
    UIModule,
    DashboardLayoutModule,
    DynamicRenderModule,
    DragDropModule,
    LayoutModule,
    ButtonsModule,
    LabelModule,
    InputsModule,
    DropDownListModule,
    DialogsModule,
    ChartModule,
    GridModule,
    DynamicRenderAdminComponent,
    DynamicRenderAdminListComponent,
    ...ADMIN_PANEL_COMPONENTS,
    ...EDITOR_COMPONENTS,
    DrAdminItemEditorHostDirective,
    EditableWrapperComponent,
    EditableInsertionDirective,
    DynamicItemPreviewDirective,
    SaveDialogComponent,
  ],
  // entryComponents: [EditableWrapperComponent, SaveDialogComponent],
  providers: [
    {
      provide: PlatformDynamicComponentsPreviewToken,
      useValue: DYNAMIC_COMPONENT_PREVIEW_MAPPING,
    },
  ],
})
export class DynamicRenderAdminModule {}
