import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  environment,
  MyHttpClientFactory,
  ResponseCasingEnum,
} from '@ups/xplat/core';
import { SpartaSyncStateDto } from '@ups/xplat/api/dto';

const GET_SYNC_STATES: string = '/api/sync-sparta/getSyncStates/{jobId}';
const START_SYNC: string = '/api/sync-sparta/startSync/{syncMethod}/{jobId}';

@Injectable({
  providedIn: 'root',
})
export class SyncSpartaService {
  protected urlBase: string;
  protected http: HttpClient;

  constructor(private clientFactory: MyHttpClientFactory) {
    this.urlBase = environment.urls.spartaAPI;
    this.http = this.clientFactory.createHttpClient(
      this.urlBase,
      true,
      ResponseCasingEnum.PascalCase
    );
  }

  public fetchSyncStates(
    jobGuid: string,
    forceIncludeJob?: boolean
  ): Observable<SpartaSyncStateDto[]> {
    let url = GET_SYNC_STATES.replace('{jobId}', jobGuid ? jobGuid : '');

    if (forceIncludeJob) {
      url += '?forceIncludeJob=' + forceIncludeJob.toString();
    }

    return this.http.get(url) as Observable<SpartaSyncStateDto[]>;
  }

  public startSync(syncMethod: string, jobGuid: string): Observable<any> {
    const url = START_SYNC.replace('{syncMethod}', syncMethod).replace(
      '{jobId}',
      jobGuid ? jobGuid : ''
    );
    return this.http.post(url, null) as Observable<any>;
  }
}
