import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { take } from 'rxjs/operators';

/**
 * @description
 * Will allow just authenticathed users to enter.
 * If user is not authenticated, flow will redirsct to SSO login.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  private _authCallbackInit = false;

  constructor(private auth: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Promise<boolean | UrlTree>
    | Observable<boolean | UrlTree> {
    return new Promise((resolve) => {
      this.auth.isAuthenticated$.pipe(take(1)).subscribe((loggedIn) => {
        if (loggedIn) {
          resolve(true);
        } else {
          this.auth
            .loginWithRedirect({
              // eslint-disable-next-line @typescript-eslint/naming-convention
              redirect_uri: state.url,
            })
            .pipe(take(1))
            .subscribe(() => {
              this.auth.isAuthenticated$.pipe(take(1)).subscribe((auth) => {
                if (auth) {
                  resolve(true);
                } else {
                  resolve(false);
                }
              });
            });
        }
      });
    });
  }
}
