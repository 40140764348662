import { Inject, Injectable } from '@angular/core';
import { parseData } from '@ups/xplat/utils';
import { MyHttpClientFactory, environment } from '@ups/xplat/core';
import { HttpClient } from '@angular/common/http';
import { NameAndLongDto } from '@ups/xplat/api/dto';

const GET_TERMINATION_REASONS: string = '/api/codelist/termreasons';
const GET_REHIRE_STATUSES: string = '/api/codelist/rehirestatuses';
const GET_LIST_OF_PRCOS_BY_SSN: string = '/api/hrrm/employee/prco/{:SSN}';
const GET_EMPLOYEE_TYPES: string = '/api/codelist/employeetypes';
const GET_JOB_CATEGORIES: string = '/api/codelist/jobcategories';
const GET_LTD_CLASSES: string = '/api/codelist/ltdclasses';

const GET_LICENSE_TYPES: string = '/api/codelist/licensetypes';
const GET_LICENSE_CLASSES: string = '/api/codelist/licenseclasses';
const GET_I9_STATUSES: string = '/api/codelist/i9statuses';
const GET_CRAFTS_BY_PRCO: string = '/api/codelist/crafts/{:prCo}';
const GET_CLASSES_BY_PRCO_AND_CRAFT: string =
  '/api/codelist/classes/{:prCo}/{:craft}';

//Background
const GET_Background_Types: string = '/api/codelist/backgroundtype';
const Get_Training_Institutions_Odata: string =
  '/api/codelist/training/institution/odata';

const GET_EMPLOYEES_DROPDOWN = '/api/hrrm/employee/all/dropdown';
@Injectable({
  providedIn: 'root',
})
export class CodelistVpService {
  http: HttpClient;

  constructor(private httpFactory: MyHttpClientFactory) {
    this.http = httpFactory.createHttpClient(environment.urls.viewpointAPI);
  }

  public getPercosBySSN(SSN: string): any {
    var url = GET_LIST_OF_PRCOS_BY_SSN.split('{:SSN}').join(SSN);
    return this.http.get(url).toPromise().then(parseData);
  }

  public getRehireStatuses(): any {
    var url = GET_REHIRE_STATUSES;
    return this.http.get(url).toPromise().then(parseData);
  }

  public getLicenseTypes(): any {
    var url = GET_LICENSE_TYPES;
    return this.http.get(url).toPromise().then(parseData);
  }

  public getTerminationReasons(): Promise<NameAndLongDto[]> {
    var url = GET_TERMINATION_REASONS;
    return this.http.get(url).toPromise().then(parseData);
  }

  public getEmployeeTypes(): any {
    var url = GET_EMPLOYEE_TYPES;
    return this.http.get(url).toPromise().then(parseData);
  }

  public getJobCategoryList(): any {
    var url = GET_JOB_CATEGORIES;
    return this.http.get(url).toPromise().then(parseData);
  }

  public getLicenseClasses(): any {
    var url = GET_LICENSE_CLASSES;
    return this.http.get(url).toPromise().then(parseData);
  }

  public getI9Statuses(): any {
    var url = GET_I9_STATUSES;
    return this.http.get(url).toPromise().then(parseData);
  }

  public handleError(error: any) {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  public getLTDClassLists(): any {
    var url = GET_LTD_CLASSES;
    return this.http.get(url).toPromise().then(parseData);
  }

  public getCraftsByPRCo(prCo: string): any {
    const url = GET_CRAFTS_BY_PRCO.split('{:prCo}').join(prCo);
    return this.http.get(url).toPromise().then(parseData);
  }

  public getClassesByPRCoAndCraft(prCo: string, craft: string): any {
    const url = GET_CLASSES_BY_PRCO_AND_CRAFT.split('{:prCo}')
      .join(prCo)
      .split('{:craft}')
      .join(craft);
    return this.http.get(url).toPromise().then(parseData);
  }

  public getBackGroundTypesQuery(query: string, top: number = 15): any {
    // NOTE: due to having search-ex capability, the query is now generated on the kendo komponent itself...

    var url = GET_Background_Types + `?$format=json`;
    if (top) {
      url += `&$top=${top}`;
    }

    if (query !== '') url += '&' + query;

    return this.http
      .get(url)
      .toPromise()
      .then((data) => parseData(data))
      .catch(this.handleError);
  }

  public getTrainingInstitutionOData(query: string, top: number = 15): any {
    // NOTE: due to having search-ex capability, the query is now generated on the kendo komponent itself...

    var url = Get_Training_Institutions_Odata + `?$format=json`;
    if (top) {
      url += `&$top=${top}`;
    }

    if (query !== '') url += '&' + query;

    return this.http
      .get(url)
      .toPromise()
      .then((data) => parseData(data))
      .catch(this.handleError);
  }

  public getEmployeesDropdown(searchString = ''): any {
    const url = GET_EMPLOYEES_DROPDOWN + '?searchString=' + searchString;
    return this.http
      .get(url)
      .toPromise()
      .then((data) => parseData(data));
  }
}
