import { Injectable, InjectionToken, Inject, Optional } from '@angular/core';
import { ToastModel, Toast } from '@syncfusion/ej2-angular-notifications';

export const TOAST_SERVICE_TOKEN = new InjectionToken<IAppToastConfig>('TOAST_SERVICE_TOKEN');

export interface IAppToastConfig {
  containerSelector: string;
  defaultModel: ToastModel;
}

/**
 * Will append to first document > div.toast-container!
 */
@Injectable({
  providedIn: 'root',
})
export class WebToastService {
  public defaultModel: ToastModel;
  public defaultElement: HTMLElement;

  constructor(@Optional() @Inject(TOAST_SERVICE_TOKEN) private config: IAppToastConfig) {
    this.config = this.config || ({} as IAppToastConfig);
    this.config.containerSelector = this.config.containerSelector || 'div.toast-container';
    this.config.defaultModel =
      this.config.defaultModel ||
      ({
        showCloseButton: true,
        position: {
          X: 'Right',
          Y: 'Bottom',
        },
        animation: {
          show: { effect: 'ZoomIn', duration: 500, easing: 'ease' },
          hide: { effect: 'ZoomOut', duration: 500, easing: 'ease' },
        },
        showProgressBar: true,
        timeOut: 0,
        extendedTimeout: 0,
      } as ToastModel);
  }

  /*
  onShow = new EventEmitter<ToastModel>();
  onClose = new EventEmitter<ToastModel>();
  */

  private createToast(model: ToastModel): Toast {
    const containerElement = document.querySelector(this.config.containerSelector) as HTMLElement;
    if (containerElement) {
      const patchedModel = Object.assign({}, this.config.defaultModel, model);
      const toast = new Toast(patchedModel, containerElement);
      return toast;
    } else {
      console.error(`You should add <div class="toast-container"></div> to your root app.component.html of this app to take advantage of toast messages.`);
      return null;
    }
  }

  //
  // Generic
  //

  public show(model: ToastModel): Toast {
    const toastInstance = this.createToast(model);
    if (toastInstance) {
      toastInstance.show();
      return toastInstance;
    }
    return null;
  }

  public hide(toast: Toast) {
    // NOTE:
    // an awful API that syncfusion has, it calls it Toast component/instance, yet:
    // toastInstance.hide() hides not the instance that refers to the element with the given toast, but the last entry!
    // toastInstance.destroy() seems to destroy the entire container

    // HACK: using internal variable
    if (toast) {
      toast.hide((toast as any).toastEle);
    }
  }
}
