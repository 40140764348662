<div class="dynamic-field-container" [class.selected]="config?.selected">
  <div
    class="dynamic-field form-input text-align"
    [formGroup]="group"
    [hidden]="!config?.isFormBuilder && config?.options?.hidden"
  >
    <ups-dynamic-helper-label
      [config]="config"
      [dynamicService]="dynamicService"
    ></ups-dynamic-helper-label>
    <div class="job-search-container" style="position: relative">
      <kendo-autocomplete
        class="k-no-dropdown w-100"
        [attr.name]="config.formControlName"
        [formControlName]="config.formControlName"
        [valueField]="'Id'"
        [filterable]="true"
        (valueChange)="valueChangeItem($event)"
        search-ex
        [seSearchColumns]="['Name']"
        (seFilterChangeEx)="filterChangeItem($event)"
        [seDebounceFilterChange]="150"
        [seUseCustomLocalFilter]="true"
        [seMinSearchLength]="0"
        [loading]="isLoading"
      >
        <ng-template kendoAutoCompleteItemTemplate let-dataItem>
          <div
            style="display: inline-flex"
            [hidden]="dataItem?.Name === config?.placeholder"
          >
            <div>
              <span [hidden]="!displayIdPrefix">{{ dataItem.Id }}&nbsp;</span>
              <span class="job-name link-style">{{ dataItem.Name }}</span>
            </div>
          </div>
        </ng-template>
      </kendo-autocomplete>
    </div>
    <div
      class="form-builder-mask"
      [class.hidden]="config?.options?.hidden"
      *ngIf="config?.isFormBuilder"
    ></div>
  </div>
  <div class="form-builder-options" *ngIf="config?.isFormBuilder">
    <i class="fas fa-times ms-3 cursor-pointer c-red" (click)="delete()"></i>
    <!-- <i class="fas fa-bars ms-3 cursor-grab"></i> -->
  </div>
</div>
