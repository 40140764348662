/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import { BaseModel } from '../base.model';
import { ProcessingDetailsModel } from './processingdetails-model';
import { PersonModel } from './person-model';
import { DisaResultModel } from './disaresult-model';
import { AddressModel } from '../sparta-api/address-model';
import { ManpowerAttachmentModel } from '../sparta-api/manpower-attachment-model';

export class JobRequirementDetailsModel extends BaseModel {
  public static get DATE_REGEX(): string {
    return '[0-9]{2}[/][0-9]{2}[/][0-9]{4}$';
  }

  isPersonInitiallyBlank = false;
  Class = '';
  Company = '';
  Designation = '';
  DispatchStatus = '';
  Email = '';
  EmployeeId = '';
  FirstName = '';
  HRAppointmentDate: any = '';
  HRRef: number;
  JobName = '';
  LastName = '';
  MiddleName = '';
  Notes = '';
  DisaResult: DisaResultModel = new DisaResultModel();
  Person: PersonModel = new PersonModel();
  PhysicalAddress: AddressModel = new AddressModel();
  MailingAddress: AddressModel = new AddressModel();
  ProcessingDetails: ProcessingDetailsModel = new ProcessingDetailsModel();
  ProspectId = '';
  RequirementDescription = '';
  SSN = '';
  VPJobId = '';
  ManPowerAttachments: Array<ManpowerAttachmentModel> = [];
  IsActiveInAnyCompany = false;
  MostRecentTermDate: Date = new Date(2000, 1, 1);
  TermDate: Date = new Date(2000, 1, 1);

  private _HRAppointmentDateString = '';
  /**
   * We need to format the BatchMonth into MM/YYYY format.
   * @param value
   * @constructor
   */
  set HRAppointmentDateString(value: any) {
    this._HRAppointmentDateString = value;
    if (value !== undefined) {
      if (typeof value === 'object') {
        this.HRAppointmentDate = value;
      } else if (
        typeof value === 'string' &&
        value.search(new RegExp(JobRequirementDetailsModel.DATE_REGEX)) > -1
      ) {
        try {
          const d = new Date(value);
          this.HRAppointmentDate = d;

          console.log('HRAppointmentDate:' + d);
        } catch (error) {
          console.log(error);
        }
      }
    }
  }

  get HRAppointmentDateString(): any {
    if (
      this.HRAppointmentDate !== undefined &&
      this.HRAppointmentDate != null &&
      typeof this.HRAppointmentDate === 'object'
    ) {
      const dd = this.HRAppointmentDate.getUTCDate();
      let ddd;
      const mm = this.HRAppointmentDate.getUTCMonth() + 1; //January is 0!
      let mmm;
      const yyyy = this.HRAppointmentDate.getUTCFullYear();
      if (dd < 10) {
        ddd = '0' + dd;
      } else {
        ddd = dd.toString();
      }
      if (mm < 10) {
        mmm = '0' + mm;
      } else {
        mmm = mm.toString();
      }
      const s = mmm + '/' + ddd + '/' + yyyy;

      return s;
    }
  }

  constructor(o: any = null) {
    super(o);
    if (o) {
      Object.assign(this, o);

      if (this.Person) {
        this.Person = new PersonModel(this.Person);
      } else {
        this.isPersonInitiallyBlank = true;
        this.Person = new PersonModel();
      }

      if (this.ManPowerAttachments && this.ManPowerAttachments.length) {
        for (let i = 0; i < this.ManPowerAttachments.length; i++) {
          this.ManPowerAttachments[i] = new ManpowerAttachmentModel(
            this.ManPowerAttachments[i]
          );
        }
      }

      this.ProcessingDetails = new ProcessingDetailsModel(
        this.ProcessingDetails
      );
      this.DisaResult = new DisaResultModel(this.DisaResult);
    }
  }
}
