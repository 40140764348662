import { Component, OnInit } from '@angular/core';

import { EditorComponentBase } from '../editor-component-base';

@Component({
  selector: 'editor-dashboard-image',
  templateUrl: 'editor-dashboard-image.html',
})
export class EditorDashboardImageComponent extends EditorComponentBase implements OnInit {
  public peSrc = '';
  public peHeight = '';
  public peWidth = '';

  public ngOnInit() {
    if (this.data) {
      this.peSrc = this.data['src'];
      this.peHeight = this.data['height'];
      this.peWidth = this.data['width'];
    }
  }

  public onApplyClick() {
    const changesObj = {
      src: this.peSrc,
      height: this.peHeight,
      width: this.peWidth,
    };
    this.applyChanges.emit(changesObj);
  }
}
