import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InventoryFilterPipe } from './inventory-filter-pipe';
import { RequirementFilterPipe } from './requirement-filter-pipe';
import { SalesOrderFilterPipe } from './salesorder-filter-pipe';
import { ScoreCardJobNamePipe } from './score-card-job-name.pipe';
import { ScoreCardTradeGroupNamePipe } from './score-card-trade-group-name.pipe';
import { VendorFilterPipe } from './vendor-filter-pipe';

const pipes = [
  InventoryFilterPipe,
  RequirementFilterPipe,
  SalesOrderFilterPipe,
  ScoreCardJobNamePipe,
  ScoreCardTradeGroupNamePipe,
  VendorFilterPipe,
];

@NgModule({
  declarations: [...pipes],
  exports: [...pipes],
  providers: [...pipes],
  imports: [CommonModule],
})
export class PortalPipesModule {}
