/* eslint-disable */
import IBaseProspectLoadable from '../models/ibase-prospect-loadable';
import { Directive, ElementRef, Injector, Input, NgZone } from '@angular/core';
//
import { ApplicationModel, ProspectModel } from '@ups/xplat/api/dto';
import { SecurityFeatureGroupModel, SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import {
  AttachmentVpService,
  DispatchService,
  HRResourceService,
  HRRMService,
  HRRSService,
  HRRTService,
  JobReqService,
  JobsService,
  PerDiemService,
  ProfileService,
  VpService,
} from '@ups/xplat/api/services';
import { AuthService } from '@auth0/auth0-angular';
import { AppConstants, GetDataEvent } from '@ups/xplat/utils';
import { ApplicationEmitter, TableviewService } from '@ups/xplat/core';
import { ActivatedRoute, Router } from '@angular/router';

declare var jQuery: any;

@Directive()
export abstract class BaseProspectLoadable implements IBaseProspectLoadable {
  @Input() security: Partial<SecurityFeatureGroupModel> &
    Partial<SecurityService>;

  @Input() prospect: ProspectModel;
  @Input() subscribeTo: string;

  public subscription: any;

  securityFeatureId = '';
  securityService: SecurityService;
  securityConstants = SecurityConstants;

  zone: NgZone;
  jobsService: JobsService;
  applicationEmitter: ApplicationEmitter;
  profileService: ProfileService;
  authService: AuthService;
  applicationModel: ApplicationModel;
  applicationConst = AppConstants;
  /* Sparta */
  hrResourceService: HRResourceService;
  jobReqService: JobReqService;
  perDiemService: PerDiemService;
  tableviewService: TableviewService;
  dispatchService: DispatchService;

  /* Viewpoint Service */
  vpService: VpService;
  attachmentVpService: AttachmentVpService;
  hrrmService: HRRMService;
  hrrsService: HRRSService;
  hrrtService: HRRTService;

  router: Router;
  activatedRoute: ActivatedRoute;

  constructor(public injector: Injector, el?: ElementRef, featureId?: string) {
    // super(injector, el, featureId);

    this.zone = injector.get(NgZone);
    this.jobsService = injector.get(JobsService);
    this.applicationEmitter = injector.get(ApplicationEmitter);
    this.authService = injector.get(AuthService);
    this.profileService = injector.get(ProfileService);
    // this.employeePortalService = injector.get(EmployeePortalService);
    this.applicationModel = injector.get(ApplicationModel);

    this.hrResourceService = injector.get(HRResourceService);
    this.jobReqService = injector.get(JobReqService);
    this.perDiemService = injector.get(PerDiemService);
    // this.spartaEmployeeService = injector.get(SpartaEmployeeService);

    this.attachmentVpService = injector.get(AttachmentVpService);
    this.vpService = injector.get(VpService);
    this.hrrmService = injector.get(HRRMService);
    this.hrrsService = injector.get(HRRSService);
    this.hrrtService = injector.get(HRRTService);

    this.router = injector.get(Router);
    this.activatedRoute = injector.get(ActivatedRoute);

    this.securityService = injector.get(SecurityService);

    // this.prospectService = injector.get(ProspectService);
    this.tableviewService = injector.get(TableviewService);
    this.dispatchService = injector.get(DispatchService);

    // if (featureId) {
    //     this.securityFeatureId = featureId;
    //     if (this.securityService.getFeatureById[this.securityFeatureId])
    //         this.security = this.securityService.getFeatureById[this.securityFeatureId];
    //     this.security = this.securityService;
    // } else {
    // }
    this.security = this.securityService;
  }

  getDataComplete(): void {
    this.zone.run(() => {
      // bug with re-render
      console.log('enabled fix bug in rerender');
    });
  }

  render(): void {
    let prospect = this.prospect;
  }

  getData(event: GetDataEvent): void {
    console.log('------------WARNING!!!---------');
    console.log('------------OVERRIDE GETDATA()---------');
    console.log('------------WARNING!!!---------');
  }

  ngOnInit(): void {
    this.render();
    if (this.subscribeTo) {
      console.log('subscribed:' + this.subscribeTo);
      this.subscription = this.applicationEmitter.getDataEmitter.subscribe(
        (id) => this.getData(id)
      );
    }
  }

  ngOnDestroy(): void {
    if (this.subscribeTo) {
      if (this.subscription && !this.subscription.closed) {
        this.subscription.unsubscribe();
      }
    }
  }
}
