import { Inject, Injectable, Injector } from '@angular/core';
import { parseData } from '@ups/xplat/utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@ups/xplat/core';
import { map } from 'rxjs/operators';
import { LodgeDto } from '@ups/xplat/api/dto';
import { MyHttpClientFactory } from '@ups/xplat/core';
import { IODataResult } from '@ups/xplat/api/dto';
import { Observable } from 'rxjs';

const GET_LODGES: string = '/api/lodges-odata';
const SAVE_LODGE: string = '/api/lodge';

@Injectable({
  providedIn: 'root',
})
export class LodgingService {
  http: HttpClient;

  constructor(private httpFactory: MyHttpClientFactory) {
    this.http = httpFactory.createHttpClient(environment.urls.spartaAPI);
  }

  public getLodges(
    odatastring: string,
    isActive: boolean = true
  ): Observable<IODataResult<LodgeDto>> {
    let url = GET_LODGES;

    url += '?$format=json&$count=true';

    if (isActive) {
      url += '&isActive=' + isActive;
    }

    url += odatastring ? '&' + odatastring : '';

    return this.http.get(url).pipe(map(parseData));
  }

  public saveLodge(model: LodgeDto) {
    const url = SAVE_LODGE;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers };
    return this.http.post(url, model, options).toPromise().then(parseData);
  }
}
