import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import {
  State,
  FilterDescriptor,
  CompositeFilterDescriptor,
} from '@progress/kendo-data-query';
import {
  EquipmentBulkEditDropdownsDto,
  EquipmentTypeDto,
  IODataResult,
  NameAndGuidDto,
} from '@ups/xplat/api/dto';
import {
  EquipmentCalibrationDto,
  EquipmentResourceDto,
  EquipmentFixedAssetHistoryDto,
  EquipmentInspectionHistoryDto,
  EquipmentLocationHistoryDto,
} from '@ups/xplat/api/dto';
import { nameOf } from '@ups/xplat/utils';
import { ODataResult, toODataStringEx } from '@ups/xplat/features';

const GET_EQUIPMENT_RESOURCES = '/api/emem/equipment-odata';
const GET_EQUIPMENT_NOTES = '/api/emem/equipment/{:keyID}/notes';
const UPDATE_EQUIPMENT_NOTES = '/api/emem/equipment/{:keyID}/notes';
const GET_EQUIPMENT_MECHANIC_NOTES =
  '/api/emem/equipment/{:keyID}/mechanicnotes';
const UPDATE_EQUIPMENT_MECHANIC_NOTES =
  '/api/emem/equipment/{:keyID}/mechanicnotes';
const UPDATE_EQUIPMENT = '/api/emem/equipment/{:keyID}';
const GET_EQUIPMENT_BULK_EDIT_DROPDOWNS = '/api/emem/bulkEdit/dropdowns';
const BULK_EDIT_EQUIPMENTS = '/api/emem/bulkEdit';
const SAVE_EQUIPMENT_TYPE = '/api/emem/equipment-type';
const GET_EQUIPMENT_FIXED_ASSET_HISTORY =
  'api/emem/equipment-fixed-asset-history-odata?company={:vpCompanyID}&equipmentName={:equipmentName}';
const GET_EQUIPMENT_LOCATION_HISTORY =
  'api/emem/equipment-location-history-odata?company={:vpCompanyID}&equipmentName={:equipmentName}';
const GET_EQUIPMENT_INSPECTION_HISTORY =
  'api/emem/equipment-inspection-history-odata/?company={:vpCompanyID}&equipmentName={:equipmentName}';
const SAVE_EQUIPMENT_INSPECTION_HISTORY_ITEM =
  'api/emem/equipment-inspection-history';
const SAVE_EQUIPMENT_CALIBRATION_ITEM = 'api/emem/equipment-calibration';
const GET_EQUIPMENT_CALIBRATIONS =
  'api/emem/equipment-calibrations-odata/?company={:vpCompanyID}&equipmentName={:equipmentName}';
const GET_LATEST_EQUIPMENT_CALIBRATION =
  'api/emem/equipment-calibrations-latest/?company={:vpCompanyID}&equipmentName={:equipmentName}';
const SAVE_HIDDEN_EQUIPMENT_CALIBRATION =
  'api/emem/equipment-calibration-hidden';
const GET_EQUIPMENT_TYPES = '/api/emem/equipmentTypes';
const GET_EQUIPMENT_CATEGORIES = '/api/emem/categories';

@Injectable({
  providedIn: 'root',
})
export class EquipmentVpService {
  protected httpViewpointClient: HttpClient;

  constructor(clientFactory: MyHttpClientFactory) {
    // TODO: Workaround - Pascal case used now instead of CamelCase as OData has some bug where when using grouping (with orderby) the field names on server side are case sensitive
    this.httpViewpointClient = clientFactory.createHttpClient(
      environment.urls.viewpointAPI,
      true,
      ResponseCasingEnum.PascalCase
    );
  }

  public queryEquipmentResources(
    oDataString: string,
    searchString: string,
    keyId: number
  ): Observable<IODataResult<EquipmentResourceDto>> {
    let url = GET_EQUIPMENT_RESOURCES;

    if (searchString) {
      searchString = searchString.replace("'", "''"); // proper odata escape when name contains ' character
    }

    url += '?$format=json&$count=true';
    url += searchString ? '&searchString=' + escape(searchString) : '';
    url += oDataString ? '&' + oDataString : '';

    if (keyId) {
      const state = {} as State;
      state.filter = {
        logic: 'and',
        filters: [
          {
            field: nameOf<EquipmentResourceDto>('KeyId'),
            operator: 'eq',
            value: keyId,
          } as FilterDescriptor,
        ],
      } as CompositeFilterDescriptor;

      const filterOData = toODataStringEx(state);
      url += filterOData ? '&' + filterOData : '';
    }

    return this.httpViewpointClient.get(url) as Observable<
      IODataResult<EquipmentResourceDto>
    >;
  }

  public getEquipmentFixedAssetHistory(
    vpCompanyID: number,
    equipmentName: string
  ): Observable<IODataResult<EquipmentFixedAssetHistoryDto[]>> {
    const url = GET_EQUIPMENT_FIXED_ASSET_HISTORY.split('{:vpCompanyID}')
      .join(vpCompanyID.toString())
      .split('{:equipmentName}')
      .join(escape(equipmentName));

    return this.httpViewpointClient.get(url) as Observable<
      IODataResult<EquipmentFixedAssetHistoryDto[]>
    >;
  }

  public getEquipmentLocationHistory(
    vpCompanyID: number,
    equipmentName: string
  ): Observable<IODataResult<EquipmentLocationHistoryDto[]>> {
    const url = GET_EQUIPMENT_LOCATION_HISTORY.split('{:vpCompanyID}')
      .join(vpCompanyID.toString())
      .split('{:equipmentName}')
      .join(escape(equipmentName));

    return this.httpViewpointClient.get(url) as Observable<
      IODataResult<EquipmentLocationHistoryDto[]>
    >;
  }

  public getEquipmentInspectionHistory(
    vpCompanyID: number,
    equipmentName: string
  ): Observable<IODataResult<EquipmentInspectionHistoryDto>> {
    const url = GET_EQUIPMENT_INSPECTION_HISTORY.split('{:vpCompanyID}')
      .join(vpCompanyID.toString())
      .split('{:equipmentName}')
      .join(escape(equipmentName));

    return this.httpViewpointClient.get(url) as Observable<
      IODataResult<EquipmentInspectionHistoryDto>
    >;
  }

  public getEquipmentResource(
    emCo: string,
    name: string
  ): Observable<IODataResult<EquipmentResourceDto>> {
    let url = GET_EQUIPMENT_RESOURCES;

    if (!emCo || !name) {
      return of(ODataResult.EmptyODataResult);
    }

    if (name) {
      name = name.replace("'", "''"); // proper odata escape when name contains ' character
    }

    url += '?$format=json&$count=true';
    const odata = `(tolower(EquipmentName) eq tolower('${name}') and EquipmentCompany eq ${emCo})`;
    url += '&$filter=' + escape(odata);

    return this.httpViewpointClient.get(url) as Observable<
      IODataResult<EquipmentResourceDto>
    >;
  }

  public getEquipmentCalibrations(
    vpCompanyID: number,
    equipmentName: string
  ): Observable<IODataResult<EquipmentCalibrationDto[]>> {
    const url = GET_EQUIPMENT_CALIBRATIONS.split('{:vpCompanyID}')
      .join(vpCompanyID.toString())
      .split('{:equipmentName}')
      .join(escape(equipmentName));

    return this.httpViewpointClient.get(url) as Observable<
      IODataResult<EquipmentCalibrationDto[]>
    >;
  }

  public getLatestEquipmentCalibration(
    vpCompanyID: number,
    equipmentName: string
  ): Observable<EquipmentCalibrationDto> {
    const url = GET_LATEST_EQUIPMENT_CALIBRATION.split('{:vpCompanyID}')
      .join(vpCompanyID.toString())
      .split('{:equipmentName}')
      .join(escape(equipmentName));

    return this.httpViewpointClient.get(
      url
    ) as Observable<EquipmentCalibrationDto>;
  }

  public updateEquipmentResource(
    equipmentResource: EquipmentResourceDto,
    fieldsToBeUpdated: string[]
  ): Observable<EquipmentResourceDto> {
    let url = UPDATE_EQUIPMENT.split('{:keyID}').join(
      equipmentResource.KeyId.toString()
    );

    if (fieldsToBeUpdated && fieldsToBeUpdated.length) {
      url += '?fieldsToBeUpdated=' + fieldsToBeUpdated.join(',');
    }

    return this.httpViewpointClient.put(
      url,
      equipmentResource
    ) as Observable<EquipmentResourceDto>;
  }

  public getEquipmentNotes(keyID: string): Promise<string> {
    const url = GET_EQUIPMENT_NOTES.split('{:keyID}').join(keyID);

    return this.httpViewpointClient.get(url).toPromise() as Promise<string>;
  }

  public updateEquipmentNotes(keyID: string, notes: string) {
    const url = UPDATE_EQUIPMENT_NOTES.split('{:keyID}').join(keyID);

    return this.httpViewpointClient.put(url, { notes: notes }).toPromise();
  }

  public getEquipmentMechanicNotes(keyID: string): Promise<string> {
    const url = GET_EQUIPMENT_MECHANIC_NOTES.split('{:keyID}').join(keyID);

    return this.httpViewpointClient.get(url).toPromise() as Promise<string>;
  }

  public updateEquipmentMechanicNotes(keyID: string, notes: string) {
    const url = UPDATE_EQUIPMENT_MECHANIC_NOTES.split('{:keyID}').join(keyID);

    return this.httpViewpointClient.put(url, { notes: notes }).toPromise();
  }

  public getEquipmentBulkEditDropdowns(
    vpCompanyID: number
  ): Promise<EquipmentBulkEditDropdownsDto> {
    let url = GET_EQUIPMENT_BULK_EDIT_DROPDOWNS;

    if (vpCompanyID) {
      url += `?vpCompanyID=${vpCompanyID}`;
    }

    return this.httpViewpointClient
      .get(url)
      .toPromise() as Promise<EquipmentBulkEditDropdownsDto>;
  }

  public bulkEditEquipments(dto) {
    const url = BULK_EDIT_EQUIPMENTS;

    return this.httpViewpointClient.put(url, dto).toPromise();
  }

  public saveEquipmentType(
    dto: EquipmentTypeDto
  ): Observable<EquipmentTypeDto> {
    return this.httpViewpointClient.post(
      SAVE_EQUIPMENT_TYPE,
      dto
    ) as Observable<EquipmentTypeDto>;
  }

  public saveEquipmentInspectionHistoryItem(
    dto: EquipmentInspectionHistoryDto
  ): Observable<EquipmentInspectionHistoryDto> {
    return this.httpViewpointClient.post(
      SAVE_EQUIPMENT_INSPECTION_HISTORY_ITEM,
      dto
    ) as Observable<EquipmentInspectionHistoryDto>;
  }

  public saveEquipmentCalibrationItem(
    dto: EquipmentCalibrationDto
  ): Observable<EquipmentCalibrationDto> {
    return this.httpViewpointClient.post(
      SAVE_EQUIPMENT_CALIBRATION_ITEM,
      dto
    ) as Observable<EquipmentCalibrationDto>;
  }

  public saveHiddenEquipmentCalibrationItem(
    dto: EquipmentCalibrationDto
  ): Observable<EquipmentCalibrationDto> {
    return this.httpViewpointClient.post(
      SAVE_HIDDEN_EQUIPMENT_CALIBRATION,
      dto
    ) as Observable<EquipmentCalibrationDto>;
  }

  public getEquipmentTypes(): Observable<NameAndGuidDto[]> {
    const url = GET_EQUIPMENT_TYPES;

    return this.httpViewpointClient.get(url) as Observable<NameAndGuidDto[]>;
  }

  public getEquipmentCategories(): Observable<string[]> {
    const url = GET_EQUIPMENT_CATEGORIES;

    return this.httpViewpointClient.get(url) as Observable<string[]>;
  }
}
