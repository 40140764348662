import { Inject, Injectable, Injector } from '@angular/core';
import { SafetyModel } from '@ups/xplat/api/dto';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MyHttpClientFactory, environment } from '@ups/xplat/core';
import { parseData } from '@ups/xplat/utils';

const GET_TRAINING_BY_HRREF: string = '/api/hret/training/{:ID}';
// const GET_CERTIFICATION_SKILLTESTERS: string = '/api/hret/skilltesters';
const GET_CERTIFICATION_SKILLTESTERS: string = '/api/hrrs/skilltesters';
const PUT_SAFETY_ITEM = '/api/hret/training'; // create, HRREF inside DTO
const PUT_SAFETY_ITEMS = '/api/hret/trainings';
const POST_SAFETY_ITEM = '/api/hret/training'; // update, KeyID inside DTO
const GET_SAFETY_CLASSES = '/api/codelist/training/safety/classdesc';
const GET_SAFETY_INSTITUTIONS = '/api/codelist/training/institutions';

@Injectable({ providedIn: 'root' })
export class HRRTService {
  http: HttpClient;

  constructor(private httpFactory: MyHttpClientFactory) {
    this.http = httpFactory.createHttpClient(environment.urls.viewpointAPI);
  }

  public getTrainingById(id: string): any {
    var url = GET_TRAINING_BY_HRREF.split('{:ID}').join(id);

    return this.http.get(url).toPromise().then(parseData);
  }

  public getCertificationSkillTesters(): any {
    var url = GET_CERTIFICATION_SKILLTESTERS;

    return this.http
      .get(url)
      .pipe(map((response) => parseData(response)))
      .toPromise();

    // NOTE: all our fnc. returns a promise, so why we do return here values instead? violation of using same behaviour !
  }

  public searchCertificationSkillTesters(term: string, top: number = 10): any {
    var url =
      GET_CERTIFICATION_SKILLTESTERS +
      '/?search=' +
      encodeURI(term) +
      '&top=' +
      top;
    return this.http.get(url).toPromise().then(parseData);
  }

  public createSafetyItem(item: SafetyModel): any {
    return this.http.put(PUT_SAFETY_ITEM, item).toPromise().then(parseData);
  }

  public createSafetyItems(items: SafetyModel[]): Observable<unknown> {
    return this.http.put(PUT_SAFETY_ITEMS, items);
  }

  public updateSafetyItem(item: SafetyModel): any {
    return this.http.post(POST_SAFETY_ITEM, item).toPromise().then(parseData);
  }

  public getSafetyInstitutionNames(): any {
    var url = GET_SAFETY_INSTITUTIONS;

    return this.http.get(url).toPromise().then(parseData);
  }

  public getSafetyClassNames(): any {
    var url = GET_SAFETY_CLASSES;

    return this.http.get(url).toPromise().then(parseData);
  }

  public searchSafetyClassNames(term: string, top: number = 10): any {
    var url =
      GET_SAFETY_CLASSES + '/?search=' + encodeURI(term) + '&top=' + top;
    return this.http.get(url).toPromise().then(parseData);
  }
}
