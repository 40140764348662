import { Component, OnInit } from '@angular/core';
import { DynamicItemBaseComponent } from '@ups/xplat/features';

@Component({
  selector: 'ups-dynamic-html-text',
  template: `
    <div
      [innerHtml]="config?.label"
      class="dynamic-field form-input text-align"
      [hidden]="config?.options?.hidden"
    ></div>
  `,
})
export class DynamicHtmlTextComponent extends DynamicItemBaseComponent {}
