import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class JobRequirementsConstants {
  adminApprovedDisaBackgroundCheckStatusID: string = '146f945b-acdf-494f-b09f-80812ee12e17';
  /* Admin Approved Disa Background */
  adminApprovedUpsBackgroundCheckStatusID: string = 'e279aae6-ed69-45cf-843d-9d021d4464e6';
  /* Admin Approved UPS Background */
}
