import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { MyHttpHandler } from './my-http-extensions';
import { HttpBaseUrlAppenderInterceptor } from './interceptors/http-baseUrlAppender.interceptor';
import { HttpForceCamelCaseInterceptor } from './interceptors/http-force-camelCase.interceptor';
import { HttpForcePascalCaseInterceptor } from './interceptors/http-force-PascalCase.interceptor';
import { HttpUPSInterceptor } from './interceptors/http-ups.interceptor';
import { ResponseCasingEnum } from './response-enum';
import { IMyHttpClientFactory } from './my-http-client-factory.interface';

@Injectable({
  providedIn: 'root',
})
export class MyHttpClientFactory implements IMyHttpClientFactory {
  constructor(
    private backend: HttpBackend,
    private authenticationInterceptor: AuthHttpInterceptor,
    private httpUPSInterceptor: HttpUPSInterceptor,
    private forceCamelCaseInterceptor: HttpForceCamelCaseInterceptor,
    private forcePascalCaseInterceptor: HttpForcePascalCaseInterceptor
  ) {}

  public createHttpClient(
    baseUrl: string,
    auth = true,
    casing: ResponseCasingEnum = ResponseCasingEnum.Default
  ) {
    const interceptors = [];

    if (baseUrl) interceptors.push(new HttpBaseUrlAppenderInterceptor(baseUrl));

    if (auth) {
      interceptors.push(this.authenticationInterceptor);
      interceptors.push(this.httpUPSInterceptor);
    }

    // NOTE: we sue == instead of === in case of non-typed value is passed due to binding on UI...
    if (casing === ResponseCasingEnum.CamelCase)
      interceptors.push(this.forceCamelCaseInterceptor);
    else if (casing === ResponseCasingEnum.PascalCase)
      interceptors.push(this.forcePascalCaseInterceptor);

    const handler = new MyHttpHandler(this.backend, interceptors);
    const client = new HttpClient(handler);
    return client;
  }
}
