/* eslint-disable @typescript-eslint/no-explicit-any */
import * as _ from 'lodash';

import { ReplaySubject, forkJoin, of } from 'rxjs';

import { isDate } from 'util';

import { GridComponent } from '@progress/kendo-angular-grid';
import {
  CompositeFilterDescriptor,
  DataResult,
  FilterDescriptor,
  State,
} from '@progress/kendo-data-query';

import { ODataResult } from '@ups/xplat/features';

import { PathSegmentDetached } from './../Group-Result/path-segment-detached';
import { GroupResultMerger } from './../Group-Result/group-result-merger';

import { GroupOData } from './OData/group-odata';
import { GroupingExViewModel } from './grouping-ex.view-model';
import { GroupingExLoadResult } from './grouping-ex.load';

/**
 * NOTE:
 * This part was extracted into separate TS file, as it's a very specific function used to do selective loading...
 * ...while considering all the rules of the grouping-ex grid, especially:
 * - group is only shown in grid only, if there's an item to it (sub-group and/or child items)
 * - if an aggregate data item contains (even a single) item (dataItem.items) in the items array, then those items are FULLY loaded
 * - since we enabled aggregates on all group levels, we need to load groups level by level
 */
export class GroupingExLoadSpecificGroups {
  public static loadSpecificGroups(
    vm: GroupingExViewModel,
    grid: GridComponent,
    groups: PathSegmentDetached[][],
    state: State
  ): ReplaySubject<GroupingExLoadResult> {
    const finishEventEmitter: ReplaySubject<any> = new ReplaySubject<any>();

    const relevantExpansionDataForLoad =
      GroupingExLoadSpecificGroups.filterRelevantPathSegmentsForLoad(
        grid,
        groups
      );

    if (relevantExpansionDataForLoad.length) {
      const maxSegments = Math.max(
        ...relevantExpansionDataForLoad.map((i) => i.length)
      );
      const maxGroupExpansionLevel = Math.min(
        maxSegments,
        grid.group.length -
          1 /* -1 because if we expand last group, we load items, not groups... */
      );

      // prepare values for each level of grouping that we will use in our odata filter with an "in" operator
      // we need to load level by level due to aggregates!
      const groupStateCopy = _.cloneDeep(state) as State;
      const itemStateCopy = _.cloneDeep(state) as State;

      //
      // Prepare LOAD observables...
      const dataLoadObservables = [];

      // We do as many group calls as many level of grouping is there...
      // ...unlike regular grid expansion even the last groups are loaded WITH A WHERE CLAUSULE (so we just take SOME groups)
      for (let i = 0; i < maxGroupExpansionLevel; i++) {
        const relevantExpansionDataForLoadWithValueForCurrentLevel =
          relevantExpansionDataForLoad.filter((red) => red.length > i);

        // NOTE:
        // extendFilterFor & i + 1:
        // - 1st level of data influences 2nd level of data we load (2nd filter - 3rd data, etc.)
        // - so for the 2nd level of groups/items we need to extend state with the values from the 1st level
        // getGroupQuery & i + 2:
        // - we already have level 1 loaded, so the next level we load is 1 + 1 = 2
        // - so we start from 2
        // we get this series:
        // - 1 - 2
        // - 2 - 3
        // ...
        // - N-1 - N ... where N - number of groups (nesting)

        // extend level-by level
        GroupingExLoadSpecificGroups.extendFilterFor(
          grid,
          groupStateCopy,
          relevantExpansionDataForLoadWithValueForCurrentLevel,
          i + 1
        );
        const iThGroupStateForLoad = groupStateCopy;

        // create observable with odata query groups (note last groups are also filtered!)
        const groupOdataQuery = GroupOData.getGroupQuery(
          iThGroupStateForLoad,
          null,
          vm.linkedAggregateFields,
          false,
          i + 2,
          'count',
          vm.customODataAggregateExpression
        );
        const groupPromise = vm.getDataLoadObservable(groupOdataQuery);
        dataLoadObservables.push(groupPromise);
      }

      // Now create filter to load items
      // ...we load just when grid.group.length == pathSegment.length (items are last node of a fully expanded tree)
      const relevantItemExpansionData = relevantExpansionDataForLoad.filter(
        (red) => red.length === grid.group.length
      );

      if (relevantItemExpansionData.length) {
        // create filter for segments(segment, level)
        for (let i = 0; i < grid.group.length; i++)
          GroupingExLoadSpecificGroups.extendFilterFor(
            grid,
            itemStateCopy,
            relevantItemExpansionData,
            i + 1
          );

        const itemStateForLoad = itemStateCopy;

        // when last level is expanded, we load ITEMS!
        const itemOdataQuery = GroupOData.getItemQuery(
          itemStateForLoad,
          null,
          vm.loadAllItemsToGroups
        );
        const itemPromise = vm.getDataLoadObservable(itemOdataQuery);
        dataLoadObservables.push(itemPromise);
      } else {
        // ensuring that last observable result is always item loading result!
        dataLoadObservables.push(of(ODataResult.EmptyODataResult));
      }

      //
      // Execute LOAD...
      forkJoin(dataLoadObservables).subscribe((d) => {
        // apply group(s) to grid (level by level)
        const loadResult = new GroupingExLoadResult();

        for (let i = 0; i < d.length - 1; i++) {
          const groupIthLevelResult = d[i];

          const groupData = groupIthLevelResult['value'];
          const groupTotalCount = parseInt(
            groupIthLevelResult['@odata.count'],
            10
          );

          loadResult.addGroupDataSet(
            { data: groupData, total: groupTotalCount } as DataResult,
            GroupResultMerger.processGroups(
              null,
              groupData,
              state,
              vm.linkedAggregateFields,
              i + 2
            )
          );
        }

        // apply items to grid
        const itemOData = d[d.length - 1];

        const itemData = itemOData['value'];
        const itemTotalCount = parseInt(itemOData['@odata.count'], 10);

        loadResult.addItemDataSet(
          { data: itemData, total: itemTotalCount } as DataResult,
          GroupResultMerger.processItems(itemData, state)
        );

        // emit success
        finishEventEmitter.next(loadResult);
      });
    } else {
      // emit no data load needed
      finishEventEmitter.next(new GroupingExLoadResult());
    }

    return finishEventEmitter;
  }

  public static mergeLoadSpecificGroupsResultToGrid(
    vm: GroupingExViewModel,
    grid: GridComponent,
    state: State,
    result: GroupingExLoadResult
  ) {
    const gridData = <any>grid.data;

    result.processedGroupSets.forEach((gds) => {
      GroupResultMerger.mergeSubTreeOfProcessedNewItemsWithGrid(
        gridData.data,
        gds.data
      );
    });

    result.processedItemSets.forEach((ids) => {
      const mergeItemsToExistingAggregatesOnly = true;
      GroupResultMerger.mergeSubTreeOfProcessedNewItemsWithGrid(
        gridData.data,
        ids.data,
        mergeItemsToExistingAggregatesOnly
      );
    });
  }

  /** Due changes in filtering, paging, some group paths might be obsolete... */
  public static filterRelevantPathSegmentsForLoad(
    grid: GridComponent,
    paths: PathSegmentDetached[][]
  ): PathSegmentDetached[][] {
    if (!paths || !paths.length) return [];

    const groupNestingLevel = grid.group.length;
    if (groupNestingLevel < 1) return [];

    // NOTE:
    // we could also check after the 1st node (especially when we do load all groups at once)
    // ...but let's just assume that those sub-nodes are relevant (if main/1st level group node is relevant)
    // ...and do assume that grouping data is cleared when grouping changes
    const gridData = <any>grid.data;
    const gridItems = gridData ? gridData.data : null;
    if (!gridItems) return [];

    const firstLevelNodeValues = gridItems.map((i) => i.value);

    const relevantExpansionData = paths.filter(
      (i) =>
        // only those with segments
        i.length &&
        // use only setting where remembered nesting is below current grouping level (questionable)
        i.length <= groupNestingLevel &&
        // ignore if any of the grouping field does not match
        i.every(
          (ps, psIndex) =>
            ps.field === grid.group[psIndex].field &&
            // first segment value is in the first level node value array (we can use === as grid always gets same type for same col.)
            firstLevelNodeValues.some((flnv) => i[0].value === flnv)
        )
    );

    return relevantExpansionData;
  }

  public static extendFilterFor(
    grid: GridComponent,
    state: State,
    relevantGroupExpansionData: PathSegmentDetached[][],
    level: number
  ) {
    // ensure our condition is added as AND
    if (!state.filter || !(state.filter.logic === 'and')) {
      state.filter = {
        logic: 'and',
        filters: [state.filter],
      } as CompositeFilterDescriptor;
    }

    // extend original state copy with out special filtering
    // level1 remember values where field1 in [level1values] - this will load all subgroups to each level 1 value (has to as merge relies on that logic!)
    // level 2 remember values where field2 in [level2values] (and also use level 1 filter) - this will load all subgroups to each level 2 value (has to as merge relies on that logic!)
    // ...
    // EVERY ITERATION A NEW FILTER IS APPENDED
    // WHILE 1ST LEVEL IS ALWAYS 100% MATCH, FROM 2ND ONWARDS WE MIGHT LOAD EXTRA DATA...
    const ithLevelGroupValues = _.uniqBy(
      relevantGroupExpansionData
        .filter((red) => red.length >= level)
        .map((red) => red[level - 1].value),
      (i) => i
    );

    const ithLevelGroupValuesFiltered = ithLevelGroupValues
      .filter((ilgv) => ilgv !== undefined && ilgv !== null && ilgv !== '')
      .map((v) => (typeof v === 'string' ? v.toLowerCase() : v));

    const ithLevelGroupValuesHasNull = ithLevelGroupValues.some(
      (ilgv) => ilgv === undefined || ilgv === null
    );
    const ithLevelGroupValuesHasEmpty = ithLevelGroupValues.some(
      (ilgv) => ilgv === ''
    );

    const inFilter: FilterDescriptor[] = [];

    if (ithLevelGroupValuesFiltered.length) {
      const ithLevelGroupValuesAreDates = isDate(
        ithLevelGroupValuesFiltered[0]
      );

      if (ithLevelGroupValuesAreDates) {
        inFilter.push(
          ...ithLevelGroupValuesFiltered.map(
            (i) =>
              ({
                field: grid.group[level - 1].field,
                operator: 'eq',
                value: i,
              } as FilterDescriptor)
          )
        );
      } else {
        inFilter.push({
          field: grid.group[level - 1].field,
          operator: 'in', // NOTE: new operator added to toOdataStringEx
          value: ithLevelGroupValuesFiltered,
          ignoreValueCase:
            false /* FilterDescriptorEx: must be false due OData limitation with the IN operator */,
        } as FilterDescriptor);
      }

      const finalFilterExtension = {
        logic: 'or',
        filters: inFilter,
      } as CompositeFilterDescriptor;

      if (ithLevelGroupValuesHasNull)
        finalFilterExtension.filters.push({
          field: grid.group[level - 1].field,
          operator: 'isnull',
        } as FilterDescriptor);

      if (ithLevelGroupValuesHasEmpty)
        finalFilterExtension.filters.push({
          field: grid.group[level - 1].field,
          operator: 'isempty',
        } as FilterDescriptor);

      // the final filter get's "extended" on each iteration
      state.filter.filters.push(finalFilterExtension);
    }
  }
}
