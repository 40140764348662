import { AfterViewInit, Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MyHttpClientFactory, ResponseCasingEnum } from '@ups/xplat/core';
import { DynamicTypeaheadWebBaseComponent } from '../base/dynamic-typeahead.web-base-component';

const SEARCH_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DynamicTypeaheadAutocompleteComponent),
  multi: true,
};

@Component({
  selector: 'ups-dynamic-typeahead-autocomplete',
  templateUrl: 'dynamic-typeahead-autocomplete.component.html',
  providers: [SEARCH_CONTROL_VALUE_ACCESSOR],
})
export class DynamicTypeaheadAutocompleteComponent
  extends DynamicTypeaheadWebBaseComponent
  implements OnInit, AfterViewInit
{
  protected http: HttpClient;

  constructor(private clientFactory: MyHttpClientFactory) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.config.options) {
      if (this.config.options.api) {
        this.http = this.clientFactory.createHttpClient(
          this.config.options.api.host,
          true,
          ResponseCasingEnum.CamelCase
        );
        this.searchQuery = this.http.get.bind(
          this.http,
          this.config.options.api.endpoint
        );
      }
    }
  }

  ngAfterViewInit() {
    if (this.valueObjectId) {
      this.selectedItem = this.valueObjectId.toString();
      this.updateValue(this.valueObjectId);
    }
  }
}
