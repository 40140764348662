import { Component, OnInit } from '@angular/core';
import { DynamicItemBaseComponent } from '@ups/xplat/features';

@Component({
  selector: 'ups-dynamic-chart',
  template: `
    <div class="dynamic-field">
      <kendo-chart (render)="onRender($event)">
        <kendo-chart-category-axis>
          <kendo-chart-category-axis-item
            [categories]="['Jan', 'Feb', 'Mar', 'Apr']"
          ></kendo-chart-category-axis-item>
        </kendo-chart-category-axis>
        <kendo-chart-series>
          <kendo-chart-series-item
            type="verticalArea"
            [data]="[1, 2, 3, 5]"
          ></kendo-chart-series-item>
          <kendo-chart-series-item
            type="verticalArea"
            [data]="[-1, -2, -3, -5]"
          ></kendo-chart-series-item>
        </kendo-chart-series>
      </kendo-chart>
    </div>
  `,
})
export class DynamicChartComponent
  extends DynamicItemBaseComponent
  implements OnInit
{
  private rendered = false;

  public onRender(e): void {
    console.log('chart render event');
    if (this.rendered) {
      return;
    }
    this.itemCreated.emit({ series: [], categories: [] });
    this.rendered = true;
  }
}
