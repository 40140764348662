import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CompanyDto } from '@ups/xplat/api/dto';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';

@Injectable()
export class SpartaCompanyService {
  protected spartaApiClient: HttpClient;
  constructor(private clientFactory: MyHttpClientFactory) {
    const urlBase = environment.urls.spartaAPI;
    this.spartaApiClient = this.clientFactory.createHttpClient(
      urlBase,
      true,
      ResponseCasingEnum.PascalCase
    );
  }

  public fetchCompanies(): Observable<CompanyDto[]> {
    const url = '/api/companies';

    return this.spartaApiClient.get(url) as Observable<CompanyDto[]>;
  }

  public loadCompanies(): Promise<CompanyDto[]> {
    const url = '/api/companies';

    return this.spartaApiClient
      .get<CompanyDto[]>(url)
      .toPromise()
      .then((data) => data);
  }
}
