import { Injectable, Injector } from '@angular/core';
import { EquipmentDispatchStatusResolver } from './equipment-dispatch-status-resolver';
import {
  ApplicationModel,
  EquipmentDispatchStatusEnum,
} from '@ups/xplat/api/dto';
import { SecurityConstants } from '@ups/security-constants';

import * as _ from 'lodash';
import { SecurityService, SecurityFeatureGroupModel } from '@ups/security';

@Injectable({
  providedIn: 'root',
})
export class EquipmentDispatchStatusService {
  public static notEditableDispatchStatusesExceptCancelled: EquipmentDispatchStatusEnum[] =
    [EquipmentDispatchStatusEnum.Onsite, EquipmentDispatchStatusEnum.Returned];

  security: SecurityFeatureGroupModel;
  allDispatchStatuses: any[];

  private securityConstants = SecurityConstants;

  public static anyItemWithStatus(
    items: EquipmentDispatchStatusEnum[],
    ...statuses: EquipmentDispatchStatusEnum[]
  ): boolean {
    return items.some((i) => statuses.some((s) => s === i));
  }

  public static everyItemWithStatus(
    items: EquipmentDispatchStatusEnum[],
    ...statuses: EquipmentDispatchStatusEnum[]
  ): boolean {
    return items.every((i) => statuses.some((s) => s === i));
  }

  public static anyItemNotWithStatus(
    items: EquipmentDispatchStatusEnum[],
    ...statuses: EquipmentDispatchStatusEnum[]
  ): boolean {
    return items.some((i) => !statuses.some((s) => s === i));
  }

  constructor(
    private dispatchStatusResolver: EquipmentDispatchStatusResolver,
    private applicationModel: ApplicationModel,
    private securityService: SecurityService
  ) {
    const self = this;
    this.dispatchStatusResolver
      .load()
      .then(
        (d) =>
          (self.allDispatchStatuses =
            self.applicationModel.equipmentDispatchStatuses)
      );
    this.security = this.securityService.getFeatureGroupByName(
      'Dispatch Status'
    ) as SecurityFeatureGroupModel;
  }

  public allEditable(dispatchStatusIDs: number[]) {
    for (let id of dispatchStatusIDs) {
      if (!this.isEditable(id)) return false;
    }
    return true;
  }

  public isEditable(dispatchStatusID: number) {
    // Onsite isn't editable
    if (dispatchStatusID === EquipmentDispatchStatusEnum.Onsite) return false;

    // Checking permissions for individual statuses
    if (dispatchStatusID === EquipmentDispatchStatusEnum.Dispatched)
      return this.securityService.getFeatureById(
        SecurityConstants.employeeportal_dispatchstatus_changedispatchedstatus
      ).editAll;

    if (dispatchStatusID === EquipmentDispatchStatusEnum.Hold) {
      if (
        this.securityService.getFeatureById(
          SecurityConstants.employeeportal_dispatchstatus_savedeclinedfromhold
        ).editAll ||
        this.securityService.getFeatureById(
          SecurityConstants.employeeportal_dispatchstatus_saverequestedfromhold
        ).editAll
      )
        return true;
    }
    if (dispatchStatusID === EquipmentDispatchStatusEnum.Requested) {
      if (
        this.securityService.getFeatureById(
          SecurityConstants.employeeportal_dispatchstatus_savedeclinedfromrequested
        ).editAll ||
        this.securityService.getFeatureById(
          SecurityConstants.employeeportal_dispatchstatus_saveholdfromrequested
        ).editAll
      )
        return true;
    }

    // Final Statuses aren't editable
    let ds = this.allDispatchStatuses.find(
      (d) => d.DispatchStatusId === dispatchStatusID
    );
    if (ds != null && ds.isFinalStatus) return false;

    // Checking basic edit permission
    if (
      this.securityService.getFeatureById(
        SecurityConstants.employeeportal_dispatchstatus_basicedit
      ).editAll
    )
      return true;

    return false;
  }

  public nextStatuses(dispatchStatusID: number): any[] {
    if (!dispatchStatusID) {
      return _.filter(this.allDispatchStatuses, (item) => {
        return (
          item.DispatchStatusId === EquipmentDispatchStatusEnum.Hold ||
          item.DispatchStatusId === EquipmentDispatchStatusEnum.Requested
        );
      });
    }

    let ds = this.allDispatchStatuses.find(
      (d) => d.DispatchStatusId === dispatchStatusID
    );

    if (!this.isEditable(dispatchStatusID)) return [ds];

    // possible statuses for dispatched status
    if (dispatchStatusID === EquipmentDispatchStatusEnum.Dispatched) {
      return _.filter(this.allDispatchStatuses, (item) => {
        return (
          item.DispatchStatusId === EquipmentDispatchStatusEnum.Dispatched ||
          item.DispatchStatusId === EquipmentDispatchStatusEnum.Returned ||
          item.DispatchStatusId === EquipmentDispatchStatusEnum.Onsite
        );
      });
    }

    let possibleSelections = _.filter(this.allDispatchStatuses, (item) => {
      // current selection have to be always in the list
      if (item.DispatchStatusId === dispatchStatusID) return true;

      // not selectable
      if (
        item.DispatchStatusId === EquipmentDispatchStatusEnum.Onsite ||
        item.DispatchStatusId === EquipmentDispatchStatusEnum.Returned
      )
        return false;

      // set by permission
      if (dispatchStatusID === EquipmentDispatchStatusEnum.Hold) {
        if (item.DispatchStatusId === EquipmentDispatchStatusEnum.Requested)
          return this.securityService.getFeatureById(
            SecurityConstants.employeeportal_dispatchstatus_saverequestedfromhold
          ).editAll;
        if (item.DispatchStatusId === EquipmentDispatchStatusEnum.Hold)
          return true;
      }
      if (dispatchStatusID === EquipmentDispatchStatusEnum.Requested) {
        if (item.DispatchStatusId === EquipmentDispatchStatusEnum.Hold)
          return this.securityService.getFeatureById(
            SecurityConstants.employeeportal_dispatchstatus_saveholdfromrequested
          ).editAll;
      }

      // other statuses are selectable if basic edit is allowed
      if (
        this.securityService.getFeatureById(
          SecurityConstants.employeeportal_dispatchstatus_basicedit
        ).editAll
      ) {
        return true;
      }

      return false;
    });

    return possibleSelections;
  }

  public availableStatuses(dispatchStatusIDs: number[]) {
    if (dispatchStatusIDs == null) return this.nextStatuses(null);

    const self = this;
    const statuses = [];
    dispatchStatusIDs.forEach((id) =>
      statuses.push(self.nextStatuses(id).map((i) => i.DispatchStatusId))
    );
    const resultIds = _.intersection(...statuses);
    return this.allDispatchStatuses.filter(
      (ds) => resultIds.indexOf(ds.DispatchStatusId) !== -1
    );
  }
}
