import { Component, OnInit } from '@angular/core';
import { IDynamicModel } from '@ups/xplat/core';
import { DynamicAccordionBaseComponent } from '@ups/xplat/features';
import { AccordionConfig } from 'ngx-bootstrap/accordion';

/**
 * Classes used on statusCompletionTotal if coloring ever desired again:
 * [class.need-required]="hasRequired && !statusComplete"
 * [class.all-done]="hasRequired && statusComplete"
 */
@Component({
  selector: 'ups-dynamic-accordion',
  styleUrls: ['dynamic-accordion.component.scss'],
  template: `
    <div class="dynamic-field-container" [class.selected]="config?.selected">
      <div
        class="dynamic-field form-button accordion"
        [formGroup]="group"
        [hidden]="config?.options?.hidden"
      >
        <accordion [formArrayName]="config.formArrayName" [isAnimated]="true">
          <accordion-group
            (isOpenChange)="isOpenChange($event)"
            [isOpen]="open[config.formArrayName]"
          >
            <div accordion-heading>
              {{ config?.label }}
              <span class="status-complete" [hidden]="config?.isFormBuilder">
                {{ statusCompletionTotal }}
              </span>
              <i
                class="fa pull-xs-right"
                [class.fa-angle-down]="!open[config.formArrayName]"
                [class.fa-angle-up]="open[config.formArrayName]"
              ></i>
              <i
                class="fa fa-exclamation-triangle"
                [hidden]="!config?.options?.accordionHasError"
              ></i>
            </div>

            <div
              class="accordion-item"
              *ngFor="let itemConfig of config?.options?.accordion"
              [class.empty]="
                itemConfig?.options?.hidden ||
                itemConfig?.options?.valueMatchFound
              "
              (click)="selectNested($event, itemConfig)"
            >
              <ng-container
                dynamicItem
                [config]="itemConfig"
                [group]="formArrayGroup"
              ></ng-container>
            </div>
          </accordion-group>
        </accordion>
      </div>

      <div class="form-builder-options" *ngIf="config?.isFormBuilder">
        <i
          class="fas fa-times ml-3 cursor-pointer c-red"
          (click)="delete()"
        ></i>
        <!-- <i class="fas fa-bars ml-3 cursor-grab "></i> -->
      </div>
    </div>
  `,
})
export class DynamicAccordionComponent
  extends DynamicAccordionBaseComponent
  implements OnInit
{
  constructor(private accordionConfig: AccordionConfig) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.config.options && this.config.options.accordionCloseOthers) {
      this.accordionConfig.closeOthers = true;
    }
    if (this.config.options && this.config.options.accordionOpen) {
      this.open[this.config.formArrayName] = true;
    }
  }

  selectNested(e: MouseEvent, itemConfig: IDynamicModel) {
    this.dynamicService.selectNestedControl(e, itemConfig);
  }
}
