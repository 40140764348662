import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IDynamicModelGroup } from '@ups/xplat/core';

@Component({
  selector: 'admin-preview-web',
  templateUrl: 'admin-preview-web.component.html',
  styleUrls: ['admin-preview-web.component.scss'],
})
export class AdminPreviewWebComponent {
  @Input() dynamicGroup: IDynamicModelGroup;
}
