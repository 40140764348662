import { BaseModel } from '../../base.model';

export class EquipmentScoreCardDropdownItemDto extends BaseModel {
  public SupervisorName: string | null;
  public VPCompanyID: number | null;
  public VPJobID: string | null;
  public JobName: string | null;
  public EquipmentTypeName: string | null;
  public AccountManagerName: string | null;
  public PlantName: string | null;

  constructor(o: any = null) {
    super(o);
  }
}
