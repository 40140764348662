export class EquipmentDashboardGridDto {
  public AssetControlManager: string;
  public AssetControlManagerID: string;
  public DispatchStatus: string;
  public DispatchStatusID: number;
  public EndDate: string;
  public EquipmentRequestDetailID: string;
  public JobName: string;
  public MissingRequirements: any[];
  public Name: string;
  public Notes: string;
  public OtherJobs: any[];
  public RequestedEquipment: string;
  public StartDate: string;
  public TransportDate: string;
  public VPCompanyID: string;
  public VPJobNumber: string;
}
