import { PathSegment } from './path-segment';
import { Traverse } from './traverse';

/** We could just have used also a PathSegment[] (or PathSegmentDetached[]) where last node refers to the item... */
export class FlattenedItemDetails {
  parentPath: PathSegment[];
  dataItem: any;

  constructor(parentPath: PathSegment[], dataItem: any) {
    this.parentPath = parentPath;
    this.dataItem = dataItem;
  }
}

export class GroupResultFlattener {
  /** Gets all items in the grid into a flat-array, the items will contain a parentPath and the item itself... */
  public static getFlattenedItemsWithDetails(items: any[], parentItem: PathSegment[]): FlattenedItemDetails[] {
    const parentPath = PathSegment.getPathToDataItem(items, parentItem);

    const flattenedItemDetails: FlattenedItemDetails[] = [];

    Traverse.traverseGridData(
      items,
      parentPath,
      false,
      false,
      (callback) => {
        if (!callback.isAggregate) flattenedItemDetails.push(new FlattenedItemDetails(callback.parentPath, callback.dataItem));
      },
      null
    );

    return flattenedItemDetails;
  }
}
