import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';

import { UIModule } from '@ups/xplat/web/features';

import { KendoPanelBarMenuComponent } from './kendo-panel-bar-menu.component';

@NgModule({
  declarations: [KendoPanelBarMenuComponent],
  exports: [KendoPanelBarMenuComponent],
  imports: [
    //
    // Basic angular related imports
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    // Kendo related imports
    UIModule,
  ],
  providers: [],
})
export class KendoPanelBarMenuModule {}
