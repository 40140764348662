import { BaseModel } from '../base.model';

export class CustomerNameDto extends BaseModel {
  public customer: number;
  public name: string | null;
  public physicalName: string | null;
  public status: string;
  public custGroup: number;

  constructor(o = null) {
    super(o);
  }
}
