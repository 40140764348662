import { Component, Input } from '@angular/core';

@Component({
  selector: 'ups-kendo-scheduler-shared',
  templateUrl: './kendo-scheduler-shared.component.html',
})
export class EmployeeJobSchedulerComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() baseData: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() selectedDate: any;

  constructor() {}
}
