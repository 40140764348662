import { Injectable } from '@angular/core';
import { ApplicationModel } from '@ups/xplat/api/dto';
import { ProspectService } from './prospect.service';

@Injectable({
  providedIn: 'root',
})
export class TradeClassesResolver {
  constructor(private prospectService: ProspectService, private applicationModel: ApplicationModel) {}

  public load(): Promise<any> {
    if (this.applicationModel.tradeClasses.length == 0) return this.prospectService.getTradeClasses().then((data) => (this.applicationModel.tradeClasses = data));
    else return Promise.resolve(this.applicationModel.tradeClasses);
  }
}
