import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { EditorModule } from '@progress/kendo-angular-editor';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

import { InAppHelpModule as BaseModule } from '@ups/xplat/base/in-app-help';
import { UIModule } from '@ups/xplat/web/features';
import { FilesModule } from '@ups/xplat/web/files';

import { COMPONENTS } from './components';
import { DIRECTIVES } from './directives';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BaseModule,
    TooltipModule,
    DropDownListModule,
    InputsModule,
    WindowModule,
    LayoutModule,
    ButtonsModule,
    EditorModule,
    UIModule,
    FilesModule,
  ],
  declarations: [...COMPONENTS, ...DIRECTIVES],
  exports: [...COMPONENTS, ...DIRECTIVES],
})
export class InAppHelpModule {}
