import {
  Component,
  OnInit,
  HostListener,
  OnDestroy,
  Input,
} from '@angular/core';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import { SyncSpartaPeriodicRefreshService } from '../../sync-sparta-periodic-refresh-service';
import { JobDropdown } from '@ups/xplat/api/dto';
import { BaseComponent } from '@ups/xplat/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ups-sync-page',
  templateUrl: 'sync-page.component.html',
})
export class SyncPageComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  hasReadPermissions: boolean;
  hasEditPermissions: boolean;

  width: number;
  height: number;
  pixelsToFitTable = 85;

  currentUrl: string;

  @Input() selectedJob: JobDropdown;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth;
    this.height = event.target.innerHeight;
    if (this.height < 400) {
      this.height = 400;
    }
  }

  constructor(
    private router: Router,
    private security: SecurityService,
    private syncRefreshService: SyncSpartaPeriodicRefreshService
  ) {
    super();

    this.hasReadPermissions = this.security.getFeatureById(
      SecurityConstants.employee_portal_sync_syncspartapage
    ).readAll;
    this.hasEditPermissions = this.security.getFeatureById(
      SecurityConstants.employee_portal_sync_syncspartapage
    ).editAll;
  }

  ngOnInit() {
    this.currentUrl = this.syncRefreshService.onPageInit(
      this.router,
      this.selectedJob ? this.selectedJob.Id : null,
      false
    );
  }

  ngOnDestroy() {
    this.syncRefreshService.onPageDestroy(this.currentUrl);
  }

  jobFilterChanged(selectedJob: JobDropdown) {
    this.selectedJob = selectedJob;
  }
}
