/**
 * While PathSegment has a lot of extra data that makes sense while references are kept in tact, it does not make sense if data is reloaded.
 *
 * A PathSegmentDetached should fill that gap, should use an array of field/value pair to reference the groups and items.
 * - Groups will always have a single value for a field, but items might have composite keys, so we will need a callback function to compute it.
 * - Groups will have the field property filled out, while item will have it empty.
 */
export class PathSegmentDetached {
  field: string;
  value: any;

  constructor(field: string, value: any) {
    this.field = field;
    this.value = value;
  }
}
