<div class="properties-editor">
  <div class="pe-field">
    <label for="content">Content</label>
    <input
      kendoTextBox
      [(ngModel)]="peButtonContent"
      id="content"
      class="form-input"
    />
  </div>

  <div class="pe-field">
    <div class="wrap">
      <kendo-label
        class="k-checkbox-label"
        [for]="primary"
        text="Primary"
      ></kendo-label>
      <input type="checkbox" #primary kendoCheckBox [(ngModel)]="pePrimary" />
    </div>
  </div>

  <div class="k-form-buttons dr-prop-actions">
    <button class="k-button k-primary" (click)="onApplyClick()">Apply</button>
    <i class="far fa-trash delete" (click)="onDeleteClick()"></i>
  </div>
</div>
