import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ParentCustomerDto } from '@ups/xplat/api/dto';
import { SearchCustomerService } from '@ups/xplat/api/services';
import { dynamicTypeaheadCustomersFilter } from '@ups/xplat/features';
import { DynamicTypeaheadWebBaseComponent } from '../base/dynamic-typeahead.web-base-component';

const SEARCH_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DynamicTypeaheadCustomersComponent),
  multi: true,
};

@Component({
  selector: 'ups-dynamic-typeahead-customers',
  templateUrl: 'dynamic-typeahead-customers.component.html',
  providers: [SEARCH_CONTROL_VALUE_ACCESSOR],
})
export class DynamicTypeaheadCustomersComponent extends DynamicTypeaheadWebBaseComponent {
  constructor(private searchService: SearchCustomerService) {
    super();
    this.queryFilter = dynamicTypeaheadCustomersFilter;
    this.searchQuery = this.searchService.search.bind(this.searchService);
  }

  toggleFavoriteClient(client: ParentCustomerDto, e: PointerEvent) {
    client.IsUserFavorite = !client.IsUserFavorite;
    this.searchService.addOrRemoveFavoriteCustomer(client.ParentCustomerID);
    e.stopPropagation();
  }
}
