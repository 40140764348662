import { Directive, OnInit } from '@angular/core';
import {
  BaseComponent,
  IDynamicModel,
  IDynamicModelGroup,
  LogService,
  ProgressService,
} from '@ups/xplat/core';
import { DynamicRenderService } from '../../dynamic-render/services/dynamic-render.service';
import { deepClone, getFullNameFromInfo } from '@ups/xplat/utils';
import { Store } from '@ngrx/store';
import { UserState } from '@ups/user';
import { take, takeUntil } from 'rxjs/operators';

@Directive()
export abstract class StopWorkAuthorityBaseComponent
  extends BaseComponent
  implements OnInit
{
  public editDataItem: unknown;
  public isNew = true;
  public isDuplicating = false;
  public showFormSuccess = false;

  dynamicGroup: IDynamicModelGroup;

  /* eslint-disable */
  private _categoryTmpOptions = [
    {
      id: 1,
      Name: 'Permit - JSA',
    },
    {
      id: 2,
      Name: 'PHA - Work Practices',
    },
    {
      id: 3,
      Name: 'Facility Hazards',
    },
    {
      id: 4,
      Name: 'Lock Out / Tag Out',
    },
    {
      id: 5,
      Name: 'Personal Protective Equipment',
    },
    {
      id: 6,
      Name: 'Line of Fire',
    },
    {
      id: 7,
      Name: 'Worker Understanding and Quality',
    },
    {
      id: 8,
      Name: 'Electrical - GFCI, Tools',
    },
    {
      id: 9,
      Name: 'Confined Space',
    },
    {
      id: 10,
      Name: 'Working at Heights',
    },
    {
      id: 11,
      Name: 'Hot Work',
    },
    {
      id: 12,
      Name: 'Cranes',
    },
    {
      id: 13,
      Name: 'Equipment',
    },
    {
      id: 14,
      Name: 'Supplied Air',
    },
    {
      id: 15,
      Name: 'Other',
    },
  ];

  stopWorkControls: IDynamicModel[] = [
    {
      label: 'Employee',
      formControlName: 'hrRefNum',
      placeholder: 'Search for Employee...',
      required: true,
      valueProperty: 'Id',
      type: 'typeahead-employee',
    },
    {
      label: 'Facility',
      formControlName: 'facilityId',
      valueProperty: 'ParentFacilityID',
      placeholder: 'Choose a Facility...',
      required: false,
      type: 'typeahead-facilities',
      options: {
        updateOnValueChange: [
          // an array of other fields to populate based on the selected item.
          {
            formControlName: 'clientContactName',
            property: 'ParentCustomerName',
            matchTargetProperty: 'CustomerName',
          },
          {
            formControlName: 'clientContactCity',
            property: 'City',
          },
          {
            formControlName: 'clientContactState',
            property: 'State',
          },
        ],
      },
    },
    {
      label: 'Client',
      formControlName: 'clientContactName',
      placeholder: 'Search for Clients...',
      valueProperty: 'ParentCustomerID',
      required: true,
      type: 'typeahead-customers',
      options: {
        updateOnValueChange: [
          // an array of other fields to populate based on the selected item.
          {
            formControlName: 'facilityId',
            property: 'CustomerName',
            matchTargetProperty: 'ParentCustomerName',
          },
        ],
        scopeQueryWhenFormControlIsSet: {
          formControlName: 'facilityId',
          matchTargetProperty: 'ParentCustomerName',
        },
      },
    },

    {
      // this field is meant to only show jobs associated with the selected facility,
      // but i did not find an API endpoint for that in the job service.
      label: 'Job',
      formControlName: 'Id',
      required: false,
      valueProperty: 'Name',
      type: 'typeahead-job',
      placeholder: 'Search for job...',
    },
    {
      label: 'City',
      formControlName: 'clientContactCity',
      required: true,
      type: 'text',
      disabled: true,
    },
    {
      label: 'State',
      formControlName: 'clientContactState',
      required: true,
      type: 'text',
      disabled: true,
    },
    {
      // TO DO:
      // If Facility is selected and the Job is null, show only the companies the Facility is associated with in the dropdown.
      // This can be found on the Plants table in Sparta (https://sparta.universalplant.com/administration/plants-page).
      // If Facility and Job are selected, default the company number to the company associated to the job.
      // This can be found on the job table in Sparta.
      label: 'UPS Company Number',
      formControlName: 'upsCompanyNumber',
      placeholder: 'Choose a Company...',
      required: true,
      type: 'typeahead',
      options: {
        dataResolver: () => {
          return Promise.resolve(); //this.dynamicRender.companyResolver.load();
        },
        displayIdPrefix: true,
        api: {
          model: {
            id: 'CompanyNumber',
            name: 'CompanyName',
          },
        },
      },
    },
    {
      label: 'UPS Employee',
      formControlName: 'isUpsEmployee',
      required: true,
      type: 'checkbox',
      options: {
        uncheckedFormControlName: 'affectedEmployees',
        hiddenFormControlName: 'affectedUpsEmployees',
      },
    },
    {
      label: 'Affected Employees',
      formControlName: 'affectedEmployees',
      required: false,
      placeholder: 'Enter a name...',
      type: 'text',
    },
    {
      label: 'Affected UPS Employees',
      formControlName: 'affectedUpsEmployees',
      placeholder: 'Search for Employee...',
      valueProperty: 'Id',
      required: false,
      type: 'typeahead-employee',
      options: {
        hidden: true,
      },
    },
    {
      label: 'Incident Date and Time',
      formControlName: 'incidentTime',
      placeholder: 'Choose a date...',
      valueProperty: 'jsdate',
      required: true,
      type: 'date',
      options: {
        minDate: new Date(),
        showTimePicker: true,
      },
    },
    {
      // TO DO:
      // This will be a new table/Admin Page.  Users with permissions will be able to edit the dropdown options.
      // Admin Page should be named Safety Categories and should be accessible under the Admin menu option.
      // Apparently no API sipport fo rthis yet.
      label: 'Category',
      formControlName: 'category',
      placeholder: 'Choose a Category...',
      required: true,
      type: 'typeahead',
      options: {
        dropdownOptions: this._categoryTmpOptions,
      },
    },
    {
      label: 'Date and Time Reported to Safety Supervisor',
      formControlName: 'dateReported',
      placeholder: 'Choose a date...',
      valueProperty: 'jsdate',
      required: true,
      type: 'date',
      options: {
        minDate: new Date(),
        showTimePicker: true,
      },
    },
    {
      label: 'Description',
      formControlName: 'description',
      required: true,
      type: 'textarea',
    },
    {
      label: 'Attachments',
      placeholder: 'Add Attachments',
      formControlName: 'files',
      type: 'file',
    },
  ];
  /* eslint-enable */

  constructor(
    protected store: Store,
    protected log: LogService,
    protected progress: ProgressService,
    public dynamicRender: DynamicRenderService
  ) {
    super();
  }

  ngOnInit() {
    // this.progress.toggleSpinner(true);
    // this.employeeService.myInfo$
    //   .pipe(
    //     filter((info) => !!info),
    //     take(1),
    //     takeUntil(this.destroy$)
    //   )
    //   .subscribe(() => {
    //     this.progress.toggleSpinner(false);
    this.addNew();
    // });

    this.dynamicRender.activeAction = () => {
      return new Promise(() => {
        const formData: {
          [key: string]: string | number | Array<unknown>;
        } = {};
        const formProperties = Object.keys(
          this.dynamicRender.activeForm.controls
        ).filter((k) => k !== 'submit');
        this.log.debug('---- submitting form data ----');

        for (const prop of formProperties) {
          this.log.debug('prop:', prop);
          const fieldControl = this.stopWorkControls.find(
            (f) => f.formControlName === prop
          );
          if (!fieldControl.ignoreValue) {
            if (
              prop === 'Attachments' &&
              this.dynamicRender.activeAttachments &&
              Array.isArray(this.dynamicRender.activeAttachments[prop])
            ) {
              if (!formData[prop]) {
                formData[prop] = [];
              }
              for (const a of this.dynamicRender.activeAttachments[prop]) {
                (<Array<unknown>>formData[prop]).push(a);
              }
            } else {
              let formValue =
                this.dynamicRender.activeForm.controls[prop].value;

              // console.log(prop, 'fieldControl:', fieldControl)

              if (formValue && fieldControl.valueProperty) {
                formValue = formValue[fieldControl.valueProperty];
              }
              formData[prop] =
                fieldControl.inputType === 'number' ? +formValue : formValue;
              this.log.debug(prop, formValue);
            }
          }
        }
        this.log.debug('formData:', formData);
        this.progress.toggleSpinner(true);
        // Fake this here for now:
        setTimeout(() => {
          this.progress.toggleSpinner(false);
          this.showFormSuccess = true;
          setTimeout(() => {
            this.showFormSuccess = false;
            this.dynamicRender.clearActiveForm();
          }, 3000);
        }, 2000);
        // this.quoteLogService
        //   .createOrUpdate(formData)
        //   .pipe(
        //     catchError((err) => {
        //       // this.log.debug(err);
        //       return of(null);
        //     })
        //   )
        //   .subscribe((res) => {
        //     this.progress.toggleSpinner(false);
        //     if (res) {
        //       this.log.debug('createOrUpdate result:', res);
        //     }
        //   });
      });
    };
  }

  addNew() {
    this._configureAndOpenForm();
  }

  formSubmitted() {}

  // to do: wire this up.
  private _configureAndOpenForm(dataItem?: unknown) {
    this.store
      .pipe(UserState.selectCurrentTruthy(), take(1), takeUntil(this.destroy$))
      .subscribe((myInfo) => {
        const isNew = this.isDuplicating || dataItem === undefined;
        this.editDataItem = dataItem || {};
        const editingControls = this.editDataItem
          ? []
          : deepClone(this.stopWorkControls);
        if (this.editDataItem) {
          // this.log.debug('this.editDataItem:', this.editDataItem);
          for (const control of this.stopWorkControls) {
            const editControl: IDynamicModel = deepClone(control);
            if (control.formControlName === 'hrRefNum' && myInfo) {
              editControl.value = myInfo.Data.EmployeeID;
              editControl.options = {
                initWithQuery: getFullNameFromInfo(myInfo),
              };
            } else {
              editControl.value = this.editDataItem[control.formControlName];
            }
            editingControls.push(editControl);
          }
        }
        this.dynamicRender.ngZone.run(() => {
          this.dynamicGroup = this.dynamicRender.createConfigGroup({
            groupName: this.dynamicRender.win.isMobile
              ? null
              : `${isNew ? '' : 'Edit '}Stop Work Authority`,
            dynamicModels: editingControls,
            editing: !isNew,
            addCancelButton: true,
            submitButtonText: 'Submit',
          });
        });
      });
  }
}
