import { Injectable } from '@angular/core';
import { JobsService } from './jobs-service';
import { Company } from '@ups/xplat/api/dto';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchCompanyService {
  constructor(private jobsService: JobsService) {}

  search(searchString: string) {
    return from(
      new Promise((resolve) => {
        this.jobsService.getCompanies(false).then((data) => {
          resolve(this._filterByName(searchString, data));
        });
      })
    );
  }

  private _filterByName(searchString: string, data): Company[] {
    // make sure the search string is a string before doing toLowerCase
    const names = (searchString || '')
      .toString()
      .toLowerCase()
      .split(',')
      .filter((v) => !!v);
    return (data || []).filter((c) => {
      if (names.length && c) {
        return c.CompanyName
          ? names.includes((<string>c.CompanyName).toLowerCase()) ||
              names.includes(c.CompanyNumber.toString())
          : false;
      } else {
        return true;
      }
    });
  }
}
