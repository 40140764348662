import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { StoreModule } from '@ngrx/store';

import { EffectsModule } from '@ngrx/effects';

import { UserEffects } from './state/user.effects';

import { userReducer } from './state/user.reducer';

import { UserState } from './state/user.state';

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    StoreModule.forFeature('user', userReducer, {
      initialState: UserState.initialState,
    }),
    EffectsModule.forFeature([UserEffects]),
  ],
  providers: [UserEffects],
})
export class UserModule {}
