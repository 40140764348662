import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationModel } from '@ups/xplat/api/dto';
import { JobsService } from './jobs-service';

@Injectable({
  providedIn: 'root',
})
export class EmployeePositionNamesResolver {
  constructor(
    private jobService: JobsService,
    private applicationModel: ApplicationModel
  ) {}

  public load(): Promise<any> {
    if (this.applicationModel.employeePositionNames.length == 0)
      return this.jobService
        .getEmployeePositionNames()
        .then((data) => (this.applicationModel.employeePositionNames = data));
    else return Promise.resolve(this.applicationModel.employeePositionNames);
  }
}
