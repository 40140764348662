import { Component, Input } from '@angular/core';
import { EquipmentDispatchDto, EquipmentRateDto } from '@ups/xplat/api/dto';

import { EquipmentService } from '@ups/xplat/api/services';
import { BaseComponent } from '@ups/xplat/core';
import { MessageHelper } from '@ups/xplat/web/core';

@Component({
  selector: 'ups-equipment-billing-rates',
  templateUrl: './equipment-billing-rates.component.html',
})
export class EquipmentBillingRatesComponent extends BaseComponent {
  @Input() jobID: string;
  @Input() billingTemplate: string;

  opened = false;
  isSaving = false;

  dataItem: EquipmentDispatchDto;
  billingRates: EquipmentRateDto[];

  constructor(
    private equipmentService: EquipmentService,
    private msgHelper: MessageHelper
  ) {
    super();
  }

  public open(dataItem: EquipmentDispatchDto) {
    this.dataItem = dataItem;
    this.billingRates = dataItem.Rates;
    this.opened = true;
  }

  public close() {
    this.opened = false;
  }

  isNewRate(rate: EquipmentRateDto) {
    return !this.dataItem.EquipmentIDs.includes(rate.EquipmentID);
  }

  newRatesAvailable() {
    return this.dataItem?.EquipmentIDs.length < this.dataItem?.Rates.length;
  }

  isUpdateAvailable() {
    return (
      this.dataItem?.EquipmentIDs.length !== this.dataItem?.Rates?.length ||
      this.billingRates?.filter((r) => r.BillingRate === 'N/A').length > 0
    );
  }

  update() {
    const dataItem = JSON.parse(JSON.stringify(this.dataItem));
    dataItem.EquipmentIDs = this.billingRates
      .filter((r) => r.BillingRate !== 'N/A')
      .map((r) => r.EquipmentID);

    this.isSaving = true;
    this.equipmentService
      .saveEquipmentDispatch(dataItem)
      .toPromise()
      .then(
        (httpOkData: EquipmentDispatchDto) => {
          this.isSaving = false;
          this.dataItem.EquipmentIDs = httpOkData.EquipmentIDs;
          this.dataItem.Rates = httpOkData.Rates;
          this.billingRates = httpOkData.Rates;

          //this.opened = false;
          this.msgHelper.success('Equipment Dispatch updated successfully!');
        },
        (error) => {
          this.isSaving = false;
          const e = error.error;
          this.msgHelper.error(
            (e.messages && e.messages.length && e.messages.join('|')) || error
          );
        }
      );
  }
}
