import { forwardRef, Inject, Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';

import { AuthService } from '@auth0/auth0-angular';
import {
  offlineRequestDropdowns,
  httpCacheRequestKey,
  httpEndpointFromUrl,
  httpPrefetchedRequestsForOffline,
  isObject,
  offlineStorageColumnType,
  getOfflineRequestHandling,
  StorageKeys,
  offlineRequestChecks,
  setHttpCacheResponseTimestamps,
} from '@ups/xplat/utils';
import { LogService } from '../../services/log.service';
import { WindowService } from '../../services/window.service';
import { NetworkService } from '../../services/network.service';
import { LocalStorageService } from '../../services/storage.service';
import { OfflineStorageService } from '../../services/offline-storage.service';
import { OfflineHttpTrackingService } from '../../services/offline-http-tracking.service';
import { environment } from '../../environments';

const logTag = 'http';

@Injectable({ providedIn: 'root' })
export class HttpUPSInterceptor implements HttpInterceptor {
  // avoids circular dep by acquiring through injector.get
  private offlineHttpTracking: OfflineHttpTrackingService;

  constructor(
    private auth: AuthService,
    private log: LogService,
    private win: WindowService,
    private storage: LocalStorageService,
    @Inject(forwardRef(() => OfflineStorageService))
    private offlineStorage: OfflineStorageService,
    private injector: Injector,
    private network: NetworkService
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (LogService.DEBUG_HTTP.enable) {
      this.logRequest(req);
    }
    return this.handleRequest(req, next);
  }

  private handleRequest(req: HttpRequest<unknown>, next: HttpHandler) {
    let endpoint = httpEndpointFromUrl(req.url);
    if (
      environment.offline?.enabled &&
      !this.win.navigator.onLine &&
      this._getRequestMethod(req) === 'get'
    ) {
      // NOT online [Offline]
      // GET cached responses only
      let cacheKey = httpCacheRequestKey(req);
      let prefetchCacheKey: offlineStorageColumnType;
      let preloadEmbeddedKey: string;
      endpoint = endpoint.split('?')[0]; // ignore query params
      this.log.debug('Offline', 'endpoint:', endpoint);
      if (httpPrefetchedRequestsForOffline.includes(endpoint)) {
        // check for endpoint matches against various prefetched endpoints
        for (const key in offlineRequestDropdowns) {
          if (offlineRequestDropdowns[key] === endpoint) {
            cacheKey = StorageKeys.OFFLINE_DATA_DROPDOWNS;
            prefetchCacheKey = 'dynamicDropdowns';
            preloadEmbeddedKey = key;
            break;
          }
        }
      }

      // check for any additional custom offline request handling
      const offlineRequestHandling = getOfflineRequestHandling(cacheKey);
      if (offlineRequestHandling) {
        cacheKey = offlineRequestHandling.cacheKey;
        prefetchCacheKey = offlineRequestHandling.prefetchCacheKey;
      }

      if (LogService.DEBUG_HTTP.enable) {
        this.log.debug(logTag, `OFFLINE request ${cacheKey}`);
      }
      this.log.debug('Offline', 'cacheKey:', cacheKey);
      this.log.debug('Offline', 'prefetchCacheKey:', prefetchCacheKey);
      // console.log('cacheKey:', cacheKey)
      // console.log('prefetchCacheKey:', prefetchCacheKey)

      return this.offlineStorage
        .getItem(cacheKey, null, prefetchCacheKey)
        .pipe(take(1))
        .pipe(
          tap((cachedResponse) => {
            if (LogService.DEBUG_HTTP.enable) {
              this.log.debug(logTag, `OFFLINE response ${cacheKey}`);
              if (LogService.DEBUG_HTTP.includeResponse) {
                this.log.debug(
                  logTag,
                  `OFFLINE response data:`,
                  cachedResponse
                );
              }
            }
          }),
          map((res) => {
            let body = res;
            if (res) {
              if (prefetchCacheKey) {
                body = res[prefetchCacheKey];
              }
              if (offlineRequestHandling) {
                body = offlineRequestHandling.handleResponseBody(body);
              } else if (preloadEmbeddedKey) {
                body = body[preloadEmbeddedKey];
              } else {
                // non-custom offline http request/response
                body = res.body;
              }
            }
            this.log.debug('Offline', 'body:', body);
            return new HttpResponse({
              body,
              status: 200,
              url: req.url,
            });
          })
        );
    } else {
      return next.handle(req).pipe(
        tap((event: HttpEvent<unknown>) => {
          if (event instanceof HttpResponse) {
            // configurable debug output
            if (LogService.DEBUG_HTTP.enable) {
              this.logResponse(event);
            }

            switch (this._getRequestMethod(req)) {
              case 'get':
                // cache all GET requests for offline usage later
                // ignore prefetched requests (since they are already cached beforehand)
                if (event.body) {
                  const cacheKey = httpCacheRequestKey(req);
                  if (
                    !httpPrefetchedRequestsForOffline.includes(endpoint) &&
                    !offlineRequestChecks.isDynamicContainer(req.url)
                  ) {
                    this.log.debug(
                      logTag,
                      'CACHE endpoint for offline:',
                      endpoint
                    );
                    const timestamp = Date.now();
                    setHttpCacheResponseTimestamps(cacheKey, timestamp);
                    this.offlineStorage
                      .setItem(cacheKey, {
                        body: event.body,
                        timestamp,
                      })
                      .pipe(take(1))
                      .subscribe();
                  }
                }
                break;
            }

            if (event.headers) {
              // Detect header conditions for specific app behavior
              // this.log.debug(logTag, 'x-app-version-status:', event.headers.get('x-app-version-status'))
              if (event.headers.get('x-app-version-status') === 'deprecated') {
                // suggest user to upgrade
                this.win.suggestVersionUpgrade$.next(true);
              }
            }
          }
        }),
        catchError((err: HttpErrorResponse) => {
          let url = null;
          let status = 0;
          this.log.debug(logTag, 'catch error:', err);

          if (err instanceof HttpErrorResponse) {
            url = err.url;
            if (!url && req) {
              url = req.url;
            }
            status = err.status;
            // a null url and status 0 is an api timing out from responding or user could be offline, just reset them all
            const isOffline = status === 0;
            if (isOffline || !this.win.navigator.onLine) {
              return this.offlineHandler(req, next, err);
            }
          } else if (err && (<{ status: number }>err).status) {
            // this could happen under some rare cases of network flakiness which did not return a valid instance of HttpErrorResponse - make sure to still capture the right http status code if possible
            status = (<{ status: number }>err).status;
          }

          if (LogService.DEBUG_HTTP.enable) {
            // log out error detail
            this.log.debug(logTag, `error --- ${url}`);
            for (const key in err) {
              if (
                !['headers', 'ok', 'name'].includes(key) &&
                typeof err[key] !== 'function'
              ) {
                this.log.debug(logTag, 'error - ' + key, err[key]);
              }
            }
          }

          // global http status code handling, ensure it's an endpoint that isn't setup to ignore global error status code handling

          switch (status) {
            // case 401:
            //   return this.handle401Error(req, next, err);
            // case 449:
            //   // app version issues
            //   return this._handle449Error(req, next, err);
            // case 500:
            //   // this indicates a backend code error
            //   return this._handle500Error(request, next);
            // case 503:
            //   return this._handleMaintenanceError(req, next, err);
            // case 504:
            //   // gateway timeout - usually means api is deploying or under maintenance
            //   return this._handleMaintenanceError(req, next, err);
            case 0:
              // likely the response was never constructed as HttpErrorResponse, api timed out responding or user is offline
              return this.offlineHandler(req, next, err);
          }

          return throwError(err);
        })
      );
    }
  }

  private _getRequestMethod(req: HttpRequest<unknown>) {
    return req.method && req.method.toLowerCase();
  }

  private _getOfflineHttpTracking() {
    if (!this.offlineHttpTracking) {
      this.offlineHttpTracking = this.injector.get(OfflineHttpTrackingService);
    }
    return this.offlineHttpTracking;
  }

  // private handle401Error(req: HttpRequest<unknown>, next: HttpHandler, err: HttpErrorResponse): Observable<HttpEvent<unknown>> {
  //   // TODO: token renew or reauth user?

  //   if (this.win.isMobile) {
  //     // for now, remove details since they are not relevant
  //     this.storage.removeItem(StorageKeys.USER_DATA);
  //     // TODO: pull redirectPath from environment config
  //     this.auth.loginWithRedirect();
  //   }

  //   return throwError(err);
  // }

  private offlineHandler(
    req: HttpRequest<unknown>,
    next: HttpHandler,
    err: unknown
  ) {
    this.log.debug(logTag, `OFFLINE or NETWORK PROBLEMS.`);
    if (environment.offline?.enabled) {
      switch (this._getRequestMethod(req)) {
        case 'put':
        case 'patch':
        case 'post':
        case 'delete':
          // track other transactionary requests
          // only when offline will they go into a queue that can be fulfilled when back online
          this._getOfflineHttpTracking().trackRequest(req);
          // for all these method requests made offline, we return their modified body as a success
          // so the UI responds like a normal success handling
          // TODO: there could be cases where the response should not match the body however
          // this covers a large majority of cases observed with UPS data handling
          return from([
            new HttpResponse({
              url: req.url,
              body: req.body,
              status: 200,
            }),
          ]);
      }
    }

    return throwError(err);
  }

  private logRequest(req: HttpRequest<unknown>) {
    this.log.debug(logTag, `request ${req.method} --- ${req.urlWithParams}`);
    if (LogService.DEBUG_HTTP.includeRequestHeaders && req.headers) {
      const headerKeys = req.headers.keys();
      this.log.debug(logTag, 'headers:', headerKeys);
      for (const key of headerKeys) {
        this.log.debug(logTag, key, req.headers.get(key));
      }
    }
    if (req.body && LogService.DEBUG_HTTP.includeRequestBody) {
      this.log.debug(logTag, 'body:', req.body);
      if (isObject(req.body)) {
        for (const key in <{ body: unknown }>req.body) {
          if (req.body.hasOwnProperty(key)) {
            this.log.debug(logTag, `   ${key}:`, req.body[key]);
          }
        }
      }
    }
  }

  private logResponse(event) {
    if (LogService.DEBUG_HTTP.includeResponse) {
      this.log.debug(logTag, `response --- ${event.url}`);
      this.log.debug(logTag, 'status:', event.status);
      const result = event.body;
      // mobile console we print a stringified version to see
      // web console we just log the object as is
      this.log.debug(
        logTag,
        'result:',
        !this.win.isBrowser && isObject(result)
          ? JSON.stringify(result)
          : result
      );
      this.log.debug(logTag, `response end ---`);
    }
  }
}
