import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { MyHttpClientFactory, environment, LogService } from '@ups/xplat/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { parseData } from '@ups/xplat/utils';

const GET_EMPLOYEE_PAY_STUBS =
  '/api/hrrm/employee-odata/paystubs/hrref/{:hrRef}';
const DOWNLOAD_SELECTED_ATTACHMENT =
  '/api/employeeportal/attachment/{:ID}/download';

@Injectable({
  providedIn: 'root',
})
export class EmployeePayStubsService {
  private http: HttpClient;

  constructor(private log: LogService, httpClientFactory: MyHttpClientFactory) {
    this.http = httpClientFactory.createHttpClient(
      environment.urls.viewpointAPI,
      true
    );
  }

  public fetchEmployeePayStubs(
    hrRef: number,
    oDataString: string
  ): Observable<any> {
    this.log.debug('fetchEmployeePayStubs');

    let url = GET_EMPLOYEE_PAY_STUBS.split('{:hrRef}').join(hrRef.toString());
    url += '?$format=json&$count=true';
    url += oDataString ? '&' + oDataString : '';

    return this.http.get(url).pipe(map(parseData)) as Observable<any>;
  }

  public downloadAttachment(attachment): any {
    const url = DOWNLOAD_SELECTED_ATTACHMENT.split('{:ID}').join(
      attachment.AttachmentID.toString()
    );
    const self = this;
    return this.http
      .get(url, {
        responseType: 'blob', // set as Blob type instead of Json
        observe: 'response',
      })
      .toPromise()
      .then(
        (data) => {
          this.log.debug('dowload succeess');
          this.downloadBlob(data, attachment);
        },
        (err) => {
          this.log.error('Error while downloading file! ' + err.Messages);
          throw err;
        }
      );
  }

  public downloadBlob(data: any, attachmentInfo) {
    if (data) {
      this.log.debug('dowload blob');
      const fileURL = URL.createObjectURL(data.body);
      const a = window.document.createElement('a');
      a.style.display = 'none';
      a.href = fileURL;
      a['download'] = attachmentInfo.DocumentName;
      // Append anchor to body.
      document.body.appendChild(a);
      a.click();
      // Remove anchor from body
      document.body.removeChild(a);
    }
  }
}
