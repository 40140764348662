export * from './hr-recordsmaster-service';
export * from './vp-service';
export * from './viewpoint-customer-service';
export * from './viewpoint-facility-service';
export * from './hr-resourcetraining-service';
export * from './hr-resourceskill-service';
export * from './codelist-service';
export * from './attachment-vp-service';
export * from './warehouse-service';
export * from './my-team/my-team-service';
export * from './search-job-vp.service';
export * from './warehouse-inventory.service';
export * from './material.service';
export * from './receive.service';
