<span *ngIf="hasReadPermissions && equipmentResource === null">
  Loading ...
</span>
<span *ngIf="!hasReadPermissions">
  You have no permissions to read the Equipment Resource Ownership Information.
</span>

<form
  id="equipmentResourceOnwershipInfoFormId"
  #form="ngForm"
  (ngSubmit)="saveEquipment(form.valid)"
  *ngIf="hasReadPermissions && equipmentResource !== null"
>
  <div
    *ngIf="hasReadPermissions && equipmentResource !== null"
    class="d-flex justify-content-end"
  >
    <button
      id="editEquipmentBtn"
      help-anchor="editOwnershipInfoTabButton"
      *ngIf="!isEditMode && hasEditPermissions"
      type="button"
      class="k-button"
      (click)="editEquipment()"
    >
      Edit
    </button>
    <button
      id="editEquipmentSaveBtn"
      help-anchor="saveButton"
      *ngIf="isEditMode"
      type="button"
      class="k-button k-primary me-3"
      (click)="saveEquipment(form.valid)"
    >
      Save
    </button>
    <button
      id="editEquipmentCancelBtn"
      *ngIf="isEditMode"
      type="button"
      class="k-button"
      (click)="cancelEquipment()"
    >
      Cancel
    </button>
  </div>

  <h4 class="mb-5">Purchase Information</h4>
  <div class="d-flex align-items-center">
    <div class="me-11">
      <label for="purchasedFrom" class="me-5">Purchased From</label>
      <input
        id="purchasedFrom"
        help-anchor="purchasedFromField"
        name="purchasedFrom"
        [(ngModel)]="equipmentResource.PurchasedFrom"
        [disabled]="!isEditMode"
        maxlength="30"
        noWhitespace
        kendoTextBox
      />
    </div>
    <div class="me-11">
      <label for="purchaseDate" class="me-5">Purchase Date</label>
      <ups-kendo-datepicker-shared
        id="purchaseDate"
        help-anchor="purchaseDate"
        name="purchaseDate"
        [(ngModel)]="equipmentResource.PurchaseDate"
        [disabled]="!isEditMode"
        placeholder="mm/dd/yyyy"
      ></ups-kendo-datepicker-shared>
    </div>
    <div>
      <label for="purchasePrice" class="me-5">Purchase Price</label>
      <kendo-numerictextbox
        id="purchasePrice"
        help-anchor="purchasePriceField"
        name="purchasePrice"
        [(ngModel)]="equipmentResource.PurchasePrice"
        [disabled]="!isEditMode"
        [decimals]="2"
        [format]="'n2'"
        [min]="-999999999.99"
        [max]="999999999.99"
      ></kendo-numerictextbox>
    </div>
  </div>
</form>
