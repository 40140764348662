<div
  class="dynamic-field form-input text-align"
  [class.standalone]="config?.options?.standalone"
>
  <ups-dynamic-helper-label
    [config]="config"
    [dynamicService]="dynamicService"
  ></ups-dynamic-helper-label>
  <div class="dynamic-redacted">-- Redacted --</div>
</div>
