import { Directive, EventEmitter, Optional, Output } from '@angular/core';

import { takeUntil } from 'rxjs';

import { GridComponent } from '@progress/kendo-angular-grid';

import { PathSegment } from './../Group-Result/path-segment';
import { GroupResultSelectionSupport } from './../Group-Result/group-result-selection-support';

import { GroupingExDirective } from './grouping-ex.directive';
import { GroupingExLoadResult } from './grouping-ex.load';
import { BaseComponent } from '@ups/xplat/core';

/** Interface for selection change events... */
export interface IGroupingExSelectionChangeArgs {
  checked: boolean;
  dataItem: any;
}

/**
 * This directive is responsible for:
 * - applying rowSelected logic to the grid
 * - adding selection state to loaded objects (via listening to the sync gridEx.dataLoadedEvent)
 * - handling item selection and thus altering parent groups check-state
 * - raising event on selection change (IMPORTANT: even the header component will raise a selection change via this directive)
 */
@Directive({
  selector: '[grid-grouping-ex-selection-support]',
})
export class GroupingExSelectionDirective extends BaseComponent {
  /**
   * Event is raised when change in selection is made (iten and or group selection as well) and when all selection-state properties are updated.
   * We can use this event to trigger updateGlobalSelectionStateVariables() like functions on the main component...
   */
  @Output('ggx-selectionChangedEvent')
  public selectionChangedEvent: EventEmitter<IGroupingExSelectionChangeArgs> = new EventEmitter<IGroupingExSelectionChangeArgs>();

  constructor(
    @Optional() private gridComponent: GridComponent,
    @Optional() private groupingExDirective: GroupingExDirective
  ) {
    super();
    if (!gridComponent)
      throw new Error(
        'The GroupingExSelectionDirective has to be declared inside a GridComponent!'
      );

    if (!groupingExDirective)
      throw new Error(
        'The GroupingExSelectionDirective needs the GroupingExDirective extension on the GridComponent!'
      );

    this.gridComponent.selectionChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.internalSelectionChange.bind(this));

    this.groupingExDirective.dataLoadedEvent
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.internalDataLoadedEvent.bind(this));
  }

  public ngOnInit(): void {
    // apply selections rule to grid (will work JUST for items, not group headers)
    this.gridComponent.rowSelected = GroupResultSelectionSupport.rowSelected;
  }

  internalSelectionChange(e) {
    /* This is called for item selections - so for item check/uncheck */
    const gridData = <any>this.gridComponent.data;
    const gridItems = gridData.data;

    e.selectedRows.forEach((r) => {
      const dataItemPath = PathSegment.getPathToDataItem(gridItems, r.dataItem);
      GroupResultSelectionSupport.handleItemChecked(
        gridItems,
        dataItemPath,
        true
      );
    });

    e.deselectedRows.forEach((r) => {
      const dataItemPath = PathSegment.getPathToDataItem(gridItems, r.dataItem);
      GroupResultSelectionSupport.handleItemChecked(
        gridItems,
        dataItemPath,
        false
      );
    });

    // raise selection changed event for given items (when working with checkboxes, only 1 item will be and only in one of the collections)
    e.selectedRows.forEach((r) => {
      const args = {
        checked: true,
        dataItem: r.dataItem,
      } as IGroupingExSelectionChangeArgs;
      this.raiseSelectionChangedEvent(args);
    });

    e.deselectedRows.forEach((r) => {
      const args = {
        checked: false,
        dataItem: r.dataItem,
      } as IGroupingExSelectionChangeArgs;
      this.raiseSelectionChangedEvent(args);
    });
  }

  internalDataLoadedEvent(args: GroupingExLoadResult) {
    // NOTE: path can be null if we pass in the newly loaded items by ref (which we do)!
    args.processedGroupSets.forEach((pgs) =>
      GroupResultSelectionSupport.appendSelectionStateToItems(
        pgs.data,
        null /* , args.stateSkip */
      )
    );
    args.processedItemSets.forEach((pis) =>
      GroupResultSelectionSupport.appendSelectionStateToItems(
        pis.data,
        null /* , args.stateSkip */
      )
    );
  }

  raiseSelectionChangedEvent(args: IGroupingExSelectionChangeArgs) {
    // NOTE: to support functions like: updateGlobalSelectionStateVariables
    this.selectionChangedEvent.emit(args);
  }
}
