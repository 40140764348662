import { BaseModel } from '../../base.model';
import { EquipmentTypeToCompanyDto } from './EquipmentTypeToCompanyDto';
import { EquipmentTypeToRequirementDto } from './EquipmentTypeToRequirementDto';

export class EquipmentTypeDto extends BaseModel {
  public EquipmentTypeID: string;
  public Name: string;
  public bActive: boolean;

  Companies: EquipmentTypeToCompanyDto[] = [];
  Requirements: EquipmentTypeToRequirementDto[] = [];

  constructor(o: any = null) {
    super(o);
  }
}
