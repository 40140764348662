<ejs-filemanager
  id="default-filemanager"
  height="700px"
  [ajaxSettings]="ajaxSettings"
  [toolbarSettings]="toolbarSettings"
  [contextMenuSettings]="contextMenuSettings"
  [showThumbnail]="false"
  view="Details"
  [uploadSettings]="{ autoClose: true }"
  [rootAliasName]="title"
  (beforeSend)="attachAuthToken($event)"
></ejs-filemanager>
