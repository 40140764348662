/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import { BaseModel } from '../base.model';

export class ProcessingDetailsModel extends BaseModel {
  public static get DATE_REGEX(): string {
    return '[0-9]{2}[/][0-9]{2}[/][0-9]{4}$';
  }

  Contacted = false;
  Attempt = 0;
  TypeOfProcessing: any = '';
  ApplicationStatus: any = '';
  I9Status: any = '';
  Shift: any = '';
  StartDate: any = '';
  EndDate: any = '';
  Workgroup: any = '';
  ExpectedOnSiteDate: any = '';
  HRAppointmentDate: any = '';
  JobClassName: any = '';
  JobClassID: any = '';
  RequestedClassID: any = '';
  RequestedClassName: any = '';
  RequestedClassGroupType: any = '';

  private _HRAppointmentDateString = '';
  set HRAppointmentDateString(value: any) {
    this._HRAppointmentDateString = value;
    this.setDateFromString(this.HRAppointmentDate, value);
  }

  get HRAppointmentDateString(): any {
    if (
      this.HRAppointmentDate !== undefined &&
      this.HRAppointmentDate != null &&
      typeof this.HRAppointmentDate === 'object'
    ) {
      const s = this.dateToString(this.HRAppointmentDate);
      return s;
    }
    return '';
  }

  private _EndDateString = '';
  set EndDateString(value: any) {
    this._EndDateString = value;
    this.setDateFromString(this.EndDate, value);
  }

  get EndDateString(): any {
    if (
      this.EndDate !== undefined &&
      this.EndDate != null &&
      typeof this.EndDate === 'object'
    ) {
      const s = this.dateToString(this.EndDate);
      return s;
    }
    return '';
  }

  private _ExpectedOnSiteDateString = '';
  set ExpectedOnSiteDateString(value: any) {
    this._ExpectedOnSiteDateString = value;
    this.setDateFromString(this.ExpectedOnSiteDate, value);
  }

  get ExpectedOnSiteDateString(): any {
    if (
      this.ExpectedOnSiteDate !== undefined &&
      this.ExpectedOnSiteDate != null &&
      typeof this.ExpectedOnSiteDate === 'object'
    ) {
      const s = this.dateToString(this.ExpectedOnSiteDate);
      return s;
    }
    return '';
  }

  private _StartDateString = '';
  set StartDateString(value: any) {
    this._StartDateString = value;
    this.setDateFromString(this.StartDate, value);
  }

  get StartDateString(): any {
    if (
      this.StartDate !== undefined &&
      this.StartDate != null &&
      typeof this.StartDate === 'object'
    ) {
      const s = this.dateToString(this.StartDate);
      return s;
    }
    return '';
  }

  constructor(o: any = null) {
    super(o);
    if (o) {
      Object.assign(this, o);

      if (o.RequestedTradeClassID) {
        this.RequestedClassID = o.RequestedTradeClassID;
        this.RequestedClassName = o.RequestedTradeClassName;
        this.RequestedClassGroupType = 'TradeClass';
      } else {
        this.RequestedClassID = o.RequestedLinkedClassID;
        this.RequestedClassName = o.RequestedLinkedClassName;
        this.RequestedClassGroupType = 'LinkedClass';
      }
    }
  }

  setDateFromString(property: any, value: any): void {
    if (value !== undefined) {
      if (typeof value === 'object') {
        property = value;
      } else if (
        typeof value === 'string' &&
        value.search(new RegExp(ProcessingDetailsModel.DATE_REGEX)) > -1
      ) {
        try {
          const d = new Date(value);
          property = d;
        } catch (error) {
          console.log(error);
        }
      }
    }
  }

  dateToString(d: Date): string {
    const dd = d.getDate();
    let ddd;
    const mm = d.getMonth() + 1; //January is 0!
    let mmm;
    const yyyy = d.getFullYear();
    if (dd < 10) {
      ddd = '0' + dd;
    } else {
      ddd = dd.toString();
    }
    if (mm < 10) {
      mmm = '0' + mm;
    } else {
      mmm = mm.toString();
    }
    const s = mmm + '/' + ddd + '/' + yyyy;

    return s;
  }
}
