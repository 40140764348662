<div class="row py-2">
  <h4 class="col-2 pt-3">{{ title }}</h4>
  <div class="col-6">
    <kendo-combobox
      *ngIf="!isReadOnly && !showInactive"
      name="add"
      class="w-100"
      [disabled]="false"
      [(ngModel)]="selectedRequirement"
      [valuePrimitive]="false"
      [textField]="'FullRequirementName'"
      [valueField]="'RequirementName'"
      [filterable]="false"
      [placeholder]="'Select ' + title"
      [data]="inactiveRequirements()"
      [loading]="isLoading"
    ></kendo-combobox>
  </div>
  <div class="col-1">
    <button
      *ngIf="!isReadOnly && !showInactive"
      class="k-button"
      id="addButton"
      [disabled]="!selectedRequirement"
      (click)="selectedRequirement.bActive = true; selectedRequirement = null"
      title="Add"
    >
      <i class="fa fa-plus"></i>
    </button>
  </div>
  <div *ngIf="isLoading" style="float: right">
    <span>
      Loading...
      <i class="fa fa-spinner fa-spin"></i>
    </span>
  </div>
</div>

<div class="req-header" *ngIf="showHeader">
  <label class="text-center col-20">Requirement</label>
  <label class="text-center col-15">Required</label>
  <label class="text-center col-15">
    Waiver
    <br />
    Applies
  </label>
  <label class="text-center col-15">
    Waiver
    <br />
    Days
  </label>
  <label class="text-center col-20" style="text-align: left">Type</label>
  <label class="text-center col-15">Billable</label>
</div>

<kendo-panelbar
  *ngIf="!isLoading"
  #panelbar
  [expandMode]="'single'"
  (stateChange)="onStateChange()"
  class="kendo-accordion mb-7"
>
  <kendo-panelbar-item
    *ngFor="let req of visibleRequirements(); let i = index"
    [title]="req.RequirementName"
  >
    <ng-template kendoPanelBarItemTitle>
      <div
        class="row no-margin w-100 px-5 align-items-center"
        (click)="onClick($event)"
      >
        <label
          *ngIf="!showSubtasks"
          title="{{ req.FullRequirementName }}"
          class="col-20 ps-6"
        >
          {{ req.RequirementName }}
        </label>
        <div
          *ngIf="showSubtasks"
          class="col-20"
          style="cursor: pointer; pointer-events: auto"
          title="{{ req.FullRequirementName }}"
          (click)="onToggleExpand(req.RequirementName)"
        >
          <span
            class="k-icon"
            [class.k-i-arrow-s]="expandedItem !== req.RequirementName"
            [class.k-i-arrow-n]="expandedItem === req.RequirementName"
          ></span>
          <span class="d-inline-block ms-4">{{ req.RequirementName }}</span>
        </div>
        <div class="col-15 text-center">
          <label
            *ngIf="req.RequirementName.indexOf('Background') === -1"
            class="k-form-field right"
          >
            <input
              type="checkbox"
              id="{{ id + '_checkRequired_' + i }}"
              class="k-checkbox"
              [disabled]="isReadOnly"
              [(ngModel)]="req.bActive"
            />
            <label
              class="k-checkbox-label"
              (click)="req.bActive = !req.bActive"
              for="{{ id + '_checkRequired_' + i }}"
            ></label>
          </label>
          <div *ngIf="req.RequirementName.indexOf('Background') !== -1">
            <input
              type="radio"
              kendoRadioButton
              name="checkMode"
              id="checkMode"
              [disabled]="isReadOnly"
              [value]="true"
              [checked]="req.bActive"
              class="k-radio right"
              [(ngModel)]="req.bActive"
              (change)="onBackgroundClick(req)"
            />
            <label class="k-radio-label right" for="checkMode"></label>
          </div>
        </div>
        <div class="col-15 text-center">
          <label class="k-form-field right">
            <input
              type="checkbox"
              id="{{ id + '_checkWaiver_' + i }}"
              class="k-checkbox"
              [disabled]="isReadOnly"
              [(ngModel)]="req.bWaiver"
            />
            <label
              class="k-checkbox-label"
              (click)="req.bWaiver = !req.bWaiver"
              for="{{ id + '_checkWaiver_' + i }}"
            ></label>
          </label>
        </div>
        <div class="col-15 text-center">
          <span *ngIf="isReadOnly">{{ req.WaiverDays }}</span>
          <kendo-numerictextbox
            *ngIf="!isReadOnly"
            [style.margin-top]="'-5px'"
            [disabled]="false"
            class="w-75"
            name="{{ 'waiverDays' + i }}"
            [(ngModel)]="req.WaiverDays"
            [spinners]="true"
            [step]="1"
            [value]="1"
            [min]="0"
            [format]="'n0'"
          ></kendo-numerictextbox>
        </div>
        <div class="col-20">
          <span *ngIf="isReadOnly">{{ req.TypeName }}</span>
          <kendo-combobox
            *ngIf="!isReadOnly && isTypeEditable(req.RequirementName)"
            name="type"
            class="w-100"
            [style.margin-top]="'-5px'"
            [disabled]="false"
            [required]="false"
            [(ngModel)]="req.TypeID"
            [valuePrimitive]="true"
            [textField]="'Name'"
            [valueField]="'Id'"
            [filterable]="false"
            [placeholder]="'Select Type'"
            [data]="getTypes(req.RequirementName)"
          ></kendo-combobox>
        </div>
        <div class="col-15 text-center">
          <kendo-combobox
            name="type"
            class="w-100"
            [style.margin-top]="'-5px'"
            [style.margin-left]="'15px'"
            [disabled]="false"
            [required]="false"
            [(ngModel)]="req.bBillable"
            [valuePrimitive]="true"
            textField="name"
            valueField="value"
            [filterable]="false"
            placeholder="Choose..."
            [data]="billableReqDropdownData"
          ></kendo-combobox>
        </div>
      </div>
    </ng-template>
    <ng-template kendoPanelBarContent>
      <div class="px-5 pb-5">
        <ups-subtask-list
          *ngIf="req.RequirementName !== 'Training'"
          [requirement]="req"
          [isReadOnly]="isReadOnly"
        ></ups-subtask-list>
        <ups-training-subtask-list
          *ngIf="req.RequirementName === 'Training'"
          [requirement]="req"
          [isReadOnly]="isReadOnly"
          [companyId]="companyId"
        ></ups-training-subtask-list>
      </div>
    </ng-template>
  </kendo-panelbar-item>
</kendo-panelbar>
