import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import { ReportDto, ReportCategoryDto, IODataResult } from '@ups/xplat/api/dto';
import { AuthService } from '@auth0/auth0-angular';
import { take } from 'rxjs/operators';

const GET_REPORTS = '/api/reports-odata';
const GET_REPORT_CATEGORIES = '/api/reports-categories-odata';
const SAVE_REPORT = '/api/report';
const SAVE_REPORTS = '/api/reports';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  protected urlBase: string;
  protected http: HttpClient;

  constructor(
    private clientFactory: MyHttpClientFactory,
    private authService: AuthService
  ) {
    this.urlBase = environment.urls.spartaAPI;
    this.http = this.clientFactory.createHttpClient(
      this.urlBase,
      true,
      ResponseCasingEnum.PascalCase
    );
  }

  public fetchReports(
    oDataString: string,
    searchString: string
  ): Observable<IODataResult<ReportDto[]>> {
    let url = GET_REPORTS + '?';

    oDataString = oDataString.replace('filter()/', '').replace('filter()', ''); // workaround as for some reason it generates wong odataquery if the searchfilter is cleared.
    oDataString = oDataString.replace("tolower('Blank')", "''");

    const queryParameters: string[] = [];

    if (searchString) {
      queryParameters.push('searchString=' + escape(searchString));
    }

    queryParameters.push('$count=true');
    queryParameters.push('$format=json');

    if (oDataString) {
      queryParameters.push(oDataString);
    }

    url = url + queryParameters.join('&');

    return this.http.get(url) as Observable<IODataResult<ReportDto[]>>;
  }

  public fetchReportCategories(
    oDataString: string
  ): Observable<IODataResult<ReportCategoryDto>> {
    let url = GET_REPORT_CATEGORIES + '?';

    const queryParameters: string[] = [];

    queryParameters.push('$count=true');
    queryParameters.push('$format=json');

    if (oDataString) {
      queryParameters.push(oDataString);
    }

    url = url + queryParameters.join('&');

    return this.http.get(url) as Observable<IODataResult<ReportCategoryDto>>;
  }

  public saveReport(report: ReportDto): Observable<ReportDto> {
    return this.http.post(SAVE_REPORT, report) as Observable<ReportDto>;
  }

  public saveReports(reports: ReportDto[]): Observable<ReportDto[]> {
    return this.http.post(SAVE_REPORTS, reports) as Observable<ReportDto[]>;
  }

  public openReport(
    reportId: string,
    jobIds: string[],
    customParameters: Record<string, string>
  ) {
    if (jobIds && jobIds.length) {
      jobIds = jobIds.filter((id: string) => id !== null && id.trim() !== '');
    } else {
      jobIds = [];
    }

    this.authService
      .getAccessTokenSilently()
      .pipe(take(1))
      .subscribe((token) => {
        const jobsQuery = /*jobIds.length ? '&jobID=' + jobIds.join() :*/ '';
        const tokenQuery = '&token=' + escape('Bearer ' + token); // TODO: Find a better - secure way - how to send token (e.g. as port of the post body, or call the page from httpclient and show blob?)
        let reportUrl =
          environment.urls.sparta +
          '/WebForms/ViewReport.aspx?reportId=' +
          reportId +
          jobsQuery +
          tokenQuery;

        let customParameterList: string = null;
        for (const key in customParameters) {
          if (Object.prototype.hasOwnProperty.call(customParameters, key)) {
            const value = customParameters[key];
            reportUrl += `&${key}=${value}`;
            customParameterList = customParameterList
              ? customParameterList + ',' + key
              : key;
          }
        }

        if (customParameterList) {
          reportUrl += '&customParameters=' + customParameterList;
        }

        //window.open(reportUrl);
        this.openWindowWithPost(reportUrl, {
          jobID: jobIds.join(),
        });
      });
  }

  openWindowWithPost(url, data) {
    const form = document.createElement('form');
    form.target = '_blank';
    form.method = 'POST';
    form.action = url;
    form.style.display = 'none';

    for (const key in data) {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = data[key];
      form.appendChild(input);
    }
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }
}
