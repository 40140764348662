/* eslint-disable @typescript-eslint/naming-convention */
import { HttpHeaders } from '@angular/common/http';

export type UpsCacheClientStatusType = 'hit' | 'miss';
export type UpsCacheCollectionKeyType = string;
export type UpsCacheInvalidateCollectionType = boolean;
export type UpsOfflineSyncType = boolean;
export type UpsOfflineSyncReqDateType = number;
export type UpsOfflineSyncMethodType = 'create' | 'update' | 'delete';
export type UpsOfflineStoreMember = 'backlogItems';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type UpsHttpHeader = {
  'ups-cache-client-status': UpsCacheClientStatusType;
  'ups-cache-collection-key': UpsCacheCollectionKeyType;
  'ups-cache-invalidate-collection': UpsCacheInvalidateCollectionType;
  'ups-offline-sync': UpsOfflineSyncType;
  'ups-offline-sync-req-date': UpsOfflineSyncReqDateType;
  'ups-offline-sync-method': UpsOfflineSyncMethodType;
  'ups-offline-store-member': UpsOfflineStoreMember;
  'ups-offline-store-member-id': string;
};

export type UpsHttpHeaderKey = keyof UpsHttpHeader;

export function initHttpHeadersWithUpsHeader<K extends UpsHttpHeaderKey>(
  key: K,
  value: UpsHttpHeader[K]
): HttpHeaders {
  const headerCol = {};
  headerCol[key as string] = JSON.stringify(value);
  const headers = new HttpHeaders(headerCol);
  return headers;
}

export function appendHttpHeadersWithUpsHeader<K extends UpsHttpHeaderKey>(
  key: K,
  value: UpsHttpHeader[K],
  headers: HttpHeaders
): HttpHeaders {
  return headers.append(key as string, JSON.stringify(value));
}

export function getUpsHeaderValueFromHttpHeader<K extends UpsHttpHeaderKey>(
  key: K,
  headers: HttpHeaders
): UpsHttpHeader[K] {
  const valStr = headers.get(key);
  return JSON.parse(valStr) as UpsHttpHeader[K];
}

export function stripUpsHeader<K extends UpsHttpHeaderKey>(
  key: K,
  headers: HttpHeaders
): HttpHeaders {
  return headers.delete(key);
}

/**
 * Generates for http request the query parameters string such as param1=value1&param2=value2
 * @param queryParameterObject - object where the properties are query parameter keys
 * and their values are query parameter values such as { param1: value1, param2: value2 }
 */

export function getQueryParametersString(
  // eslint-disable-next-line @typescript-eslint/ban-types
  queryParameterObject: Object,
  excludeNullParameters = false
): string {
  let queryParameterString = '';
  for (const key in queryParameterObject) {
    const value = queryParameterObject[key];

    if (excludeNullParameters === true && value == null) {
      continue;
    }

    if (queryParameterString !== '') {
      queryParameterString += '&';
    }

    queryParameterString += key;
    queryParameterString += '=';
    if (value != null) {
      queryParameterString += value.toString();
    }
  }

  return queryParameterString;
}

export function appendStartDateAndEndDate(
  url: string,
  startDate: string,
  endDate: string
): string {
  let parameters = '';

  if (startDate != null && startDate !== '') {
    parameters += 'startDate=' + startDate;
  }

  if (endDate != null && endDate !== '') {
    if (parameters !== '') {
      parameters += '&';
    }

    parameters += 'endDate=' + endDate;
  }

  return url + parameters;
}

export function pathJoin(parts: string[], sep: string): string {
  return parts
    .map((part) => {
      const part2 = part.endsWith(sep)
        ? part.substring(0, part.length - 1)
        : part;
      return part2.startsWith(sep) ? part2.slice(1) : part2;
    })
    .join(sep);
}
