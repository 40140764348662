import { Injectable, NgZone } from '@angular/core';
import { LogService, NetworkService, WindowService } from '@ups/xplat/core';
import { Observable } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class WebNetworkService {
  constructor(
    private ngZone: NgZone,
    private log: LogService,
    private win: WindowService,
    private network: NetworkService
  ) {}

  initOfflineHandling() {
    this.win.platformWindow.addEventListener('online', () => {
      this._throttledOfflineNotice(!this.win.navigator.onLine);
    });
    this.win.platformWindow.addEventListener('offline', () => {
      this._throttledOfflineNotice(!this.win.navigator.onLine);
    });
    this.network.retryConnection$.subscribe((_) => {
      this.log.debug('retrying connection...');
      // todo: make api call to check and verify
    });
  }

  private _throttledOfflineNotice(isOffline: boolean) {
    this.ngZone.run(() => {
      this.network.offline = isOffline; // ensure state is updated
    });
  }
}
