import { BaseModel } from '../../base.model';

export class EmployeeJobsWorkedResult extends BaseModel {
  VPJobId: string;
  JobName: string;
  PayRate: number | null;
  StartDate: Date | null;
  ROFDate: Date | null;
  EndDate: Date | null;
  HoursWorked: number;
  DispatchStatus: string;
  JobClass: string;
  Shift: string;
  PlantName: string;
}
