import { BaseModel } from '../base.model';

export class JobSafetyCouncilDto extends BaseModel {
  public JobSafetyCouncilID: string;
  public JobID: string;
  public SafetyCouncilID: string;
  public bActive: boolean;
  public Name: string;
  public Contact: string;
  public Address: string;
  public City: string;
  public State: string;
  public ZipCode: string;
  public Email: string;
  public PhoneNumber: string;

  constructor(o: any = null) {
    super(o);
  }
}
