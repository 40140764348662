/* eslint-disable @typescript-eslint/naming-convention */
import { BaseModel } from '../base.model';
import { FileDataModel } from './fileData-model';

export class CertificationModel extends BaseModel {
  KeyID: number;
  HRRef = '';
  Code = '';
  CertDate: Date;
  ExpireDate: Date;
  SkillTester = '';
  Score?: number;
  udScore = '';
  udBckResult = '';
  udSkillScore = '';
  bActive = true;
  editable = false;
  showFiles: boolean;
  isSaving = false;
  isNewItem = false;
  Delete = false;
  udModel: string;
  udMake: string;
  AudioType: string;
  Notes: string;

  Files: FileDataModel[] = [];

  ProspectID?: string;
  ProspectCertificationID?: string;

  constructor(o = null) {
    super(o);
  }
}
