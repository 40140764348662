import { Action } from '@ngrx/store';

import { UIState } from './ui/ui.state';
import { AppRouterState } from './router/router.state';

export interface ICoreState {
  // user: UserState.IState;
  ui: UIState.IState;
  router: AppRouterState.IState;
}

export namespace AppActions {
  export enum Types {
    NOOP = '[@ups/app] Noop',
  }

  export class Noop implements Action {
    type = Types.NOOP;
    payload: string = null;
  }

  export type Actions = Noop;
}

export namespace AppState {}
