/* eslint-disable @typescript-eslint/no-explicit-any */
import { WebLiveUpdateService } from './web-live-update.service';
import { WebSignalRPlatformService } from './web-signal-r-platform.service';
import { WebToastService } from './web-toast.service';

export const PROVIDERS: any[] = [
  WebLiveUpdateService,
  WebSignalRPlatformService,
  WebToastService,
];

export * from './notifications-view.service';
export * from './sparta';
export * from './viewpoint';
export * from './web-error-handler.service';
export * from './web-live-update.service';
export * from './web-message-helper.service';
export * from './web-signal-r-platform.service';
export * from './web-toast.service';
export * from './web-helper.service';
// export * from './web-microsoft-clarity.service';
export * from './web-smartlook.service';
