<div *ngIf="hasReadPermissions" style="width: fit-content">
  <div *ngIf="isLoading && !showAsJobSyncButton">
    <span>Loading ....</span>
    <i class="fa fa-spinner fa-spin"></i>
  </div>
  <div
    *ngIf="!isLoading && syncStates && syncStates.length > 0"
    class=""
    [style.padding]="showInCompactMode ? '0 0 0 0' : 'default'"
  >
    <div class="row">
      <div
        [class.col-12]="true"
        [class.col-lg-8]="!showInCompactMode"
        [style.padding]="showInCompactMode ? '0 0 0 0' : 'default'"
      >
        <div class="">
          <div
            *ngIf="!showInCompactMode"
            class="row"
            style="
              vertical-align: middle;
              margin-bottom: 10px;
              margin-top: 10px;
            "
          >
            <div class="col-12 col-lg-4 d-none d-lg-block">
              <b>Sync Data For:</b>
            </div>
            <div class="col-12 col-lg-8 d-none d-lg-block">
              <b>Last Sync Completed:</b>
            </div>
          </div>
          <ng-template ngFor let-data [ngForOf]="syncStates">
            <div
              class="row"
              [style.vertical-align]="'middle'"
              [style.margin-bottom.px]="
                showAsJobSyncButton ? 0 : showInCompactMode ? 5 : 20
              "
            >
              <div
                [class.col-12]="true"
                [class.col-lg-4]="!showInCompactMode"
                kendoTooltip
                [tooltipTemplate]="showInCompactMode ? syncButtonTooltip : null"
              >
                <ng-template #syncButtonTooltip>
                  <div
                    *ngIf="
                      data.SecondsSinceEndOfSync > 0 &&
                      data.SecondsSinceEndOfSync <
                        disableButtonsAfterSyncInSeconds
                    "
                  >
                    <span>
                      Wait for
                      {{
                        disableButtonsAfterSyncInSeconds -
                          data.SecondsSinceEndOfSync
                      }}
                      seconds before syncing again ...
                      <br />
                      <br />
                    </span>
                  </div>
                  <div *ngIf="data.IsDisabled">
                    <span>Select one job to enable the sync button ...</span>
                  </div>
                  <div *ngIf="!data.IsDisabled" style="width: 300px">
                    <span *ngIf="!data.IsRunning">
                      Last Sync Completed:
                      <br />
                      <br />
                    </span>
                    {{ data.Message1 }}
                    <br />
                    <br />
                    <span *ngIf="data.IsRunning">
                      Duration:
                      <br />
                      <br />
                    </span>
                    {{ data.Message2 }}
                    <br />
                    <br />
                    <span *ngIf="data.IsError" class="text-danger">
                      Error: {{ data.ErrorMessage }}
                      <br />
                    </span>
                    Log:
                    <br />
                    <textarea
                      class="form-control input-sm scrollable"
                      [(ngModel)]="data.Log"
                      [disabled]="true"
                      style="width: 100%"
                      kendoTextArea
                      rows="10"
                    ></textarea>
                  </div>
                </ng-template>
                <div title>
                  <button
                    [id]="'syncBtn' + data.MethodName"
                    class="btn btn-sm btn-primary"
                    [class.w-100]="!showInCompactMode"
                    [class.w-75]="showInCompactMode && !showAsJobSyncButton"
                    [class.k-button]="showAsJobSyncButton"
                    (click)="sync(data)"
                    [disabled]="
                      !hasEditPermissions ||
                      data.IsRunning ||
                      data.IsDisabled ||
                      (data.SecondsSinceEndOfSync > 0 &&
                        data.SecondsSinceEndOfSync <
                          disableButtonsAfterSyncInSeconds)
                    "
                    kendoButton
                    help-anchor="button__sync"
                  >
                    <span *ngIf="data.IsRunning" style="margin-right: 3px">
                      <i class="fas fa-sync fa-spin"></i>
                    </span>
                    <span
                      *ngIf="
                        data.SecondsSinceEndOfSync > 0 &&
                        data.SecondsSinceEndOfSync <
                          disableButtonsAfterSyncInSeconds &&
                        !data.IsError
                      "
                      style="margin-right: 3px"
                    >
                      <i class="fas fa-check text-success"></i>
                    </span>
                    <span *ngIf="data.IsError" style="margin-right: 3px">
                      <i class="fas fa-exclamation text-danger"></i>
                    </span>
                    <span style="white-space: normal">
                      {{ showAsJobSyncButton ? 'Sync' : data.FriendlySyncName }}
                    </span>
                  </button>
                </div>
                <div *ngIf="!showInCompactMode">
                  <small>
                    <span>{{ data.FriendlySyncDescription }}</span>
                  </small>
                </div>
              </div>
              <div *ngIf="!showInCompactMode" class="col-12 d-block d-lg-none">
                <label><b>Last Sync Completed:</b></label>
              </div>
              <div *ngIf="!showInCompactMode" class="col-12 col-lg-3">
                <span>{{ data.Message1 }}</span>
              </div>
              <div *ngIf="!showInCompactMode" class="col-12 col-lg-3">
                <span>{{ data.Message2 }}</span>
              </div>
              <div
                *ngIf="!showInCompactMode"
                [id]="'showLogContainer' + data.MethodName"
                class="col-12 col-lg-1"
                tabindex="-1"
                style="height: 40px; outline-style: none"
              >
                <a
                  [id]="'showLogLink' + data.MethodName"
                  (click)="selectedSyncName = data.MethodName"
                  style="border-width: 2px"
                  [ngStyle]="{
                    'border-style':
                      selectedSyncName === data.MethodName ? 'dotted' : 'none'
                  }"
                >
                  ShowLog
                </a>
                <span class="text-danger">{{ data.ErrorMessage }}</span>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div *ngIf="!showInCompactMode" class="col-12 col-lg-4">
        <span [hidden]="!selectedSyncName"><b>Log:</b></span>
        <div class="row" style="height: 50px; vertical-align: middle">
          <ng-template ngFor let-data [ngForOf]="syncStates">
            <textarea
              *ngIf="selectedSyncName === data.MethodName"
              class="form-control input-sm scrollable"
              [(ngModel)]="data.Log"
              [disabled]="true"
              style="width: 100%"
              kendoTextArea
              rows="25"
            ></textarea>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!hasReadPermissions">
  <span>You currently don't have permissions to work with Sparta Sync.</span>
</div>
