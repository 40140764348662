/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import {
  AttachmentDto,
  IODataResult,
  UpdateEmployeeOnsiteStateDto,
  UpdateEmployeeROFStateDto,
  PayStubDto,
} from '@ups/xplat/api/dto';

const GET_EMP_BY_PRCO_VPID = '/api/hrrm/employee/{:VPCompany}/{:VPId}';
const GET_EMP_BY_HRREF = '/api/hrrm/employee/{:ID}';
const GET_EMPNOTES_BY_HRREF = '/api/hrrm/employee/{:ID}/notes';
const UPDATE_EMPNOTES_BY_HRREF = '/api/hrrm/employee/{:ID}/notes';
const GET_EMP_BY_HRREF_PARTIAL = '/api/hrrm/employeepartial/{:ID}';
const GET_EMP_BY_TOKEN = '/api/hrrm/employee';
const GET_I9_ATTACHEMENTS = '/api/employeeportal/attachment/hrref/{:HRRef}/i9';
const GET_GENERAL_ATTACHEMENTS =
  '/api/employeeportal/attachment/hrref/{:HRRef}/general';
const GET_ID_ATTACHEMENTS =
  '/api/employeeportal/attachment/hrref/{:HRRef}/identity';
const GET_TWIC_ATTACHEMENTS =
  '/api/employeeportal/attachment/hrref/{:HRRef}/twic';
const ADD_NEW_ATTACHMENT = '/api/employeeportal/attachment';
const DOWNLOAD_SELECTED_ATTACHMENT =
  '/api/employeeportal/attachment/{:ID}/download';
const REMOVE_SELECTED_ATTACHMENT = '/api/employeeportal/attachment/{:ID}';
const GET_I9_STATUSES = '/api/employeeportal/attachment/types/i9';
const GET_I9_TYPES = '/api/employeeportal/attachment/types/identity';
const GET_GENERAL_ATTACHMENT_TYPES =
  '/api/employeeportal/attachment/types/general';
const GET_EMPLOYEE_DATA_ODATA = '/api/hrrm/employees-odata';
const GET_EMPLOYEE_PAY_STUBS =
  '/api/hrrm/employee-odata/paystubs/hrref/{:hrRef}';
const UPDATE_EMPLOYEE_ONSITE_STATE = '/api/employee/updateOnsiteState';
const UPDATE_EMPLOYEE_ROF_STATE = '/api/employee/updateROFState';
const UPDATE_EMPLOYEE_ONSITE_STATES = '/api/employee/updateOnsiteStates';
const UPDATE_EMPLOYEE_ROF_STATES = '/api/employee/updateROFStates';

@Injectable({ providedIn: 'root' })
export class HRRMService {
  http: HttpClient;

  constructor(private clientFactory: MyHttpClientFactory) {
    this.http = this.clientFactory.createHttpClient(
      environment.urls.viewpointAPI,
      true,
      ResponseCasingEnum.CamelCase
    );
  }

  public getEmpByPrCOVPID(vpCompany: string, vpid: string): any {
    let url = GET_EMP_BY_PRCO_VPID.split('{:VPId}').join(vpid);
    url = url.split('{:VPCompany}').join(vpCompany);

    return this.http.get(url).toPromise();
  }

  public getEmployee(id: string | number): any {
    return this.http.get(GET_EMP_BY_HRREF.split('{:ID}').join(id + ''));
  }

  public getEmpByHRREF(id: string | number): any {
    return this.http
      .get(GET_EMP_BY_HRREF.split('{:ID}').join(id + ''))
      .toPromise();
  }

  public getEmpNotesByHRREF(id: string): any {
    const url = GET_EMPNOTES_BY_HRREF.split('{:ID}').join(id);

    return this.http.get(url).toPromise();
  }

  public updateEmpNotesByHRREF(id: string, notes: string): any {
    const url = UPDATE_EMPNOTES_BY_HRREF.split('{:ID}').join(id);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    return this.http.put(url, { Notes: notes }).toPromise();
  }

  public fetchEmployeePayStubs(
    hrRef: number,
    oDataString: string
  ): Observable<IODataResult<PayStubDto[]>> {
    let url = GET_EMPLOYEE_PAY_STUBS.split('{:hrRef}').join(hrRef.toString());
    url += '?$format=json&$count=true';
    url += oDataString ? '&' + oDataString : '';

    console.log('Calling service: ', url);

    return this.http.get(url) as Observable<IODataResult<PayStubDto[]>>;
  }

  public getEmpByHRREFPartial(id: string): any {
    const url = GET_EMP_BY_HRREF_PARTIAL.split('{:ID}').join(id);

    return this.http.get(url).toPromise();
  }

  public getEmpByToken(): any {
    return this.http.get(GET_EMP_BY_TOKEN);
  }

  public getIDTypes(): any {
    return this.http.get(GET_I9_TYPES).toPromise();
  }

  public getI9Statuses(): any {
    return this.http.get(GET_I9_STATUSES).toPromise();
  }

  public getGeneralAttachmentTypes(): any {
    const url = GET_GENERAL_ATTACHMENT_TYPES;

    return this.http.get(url).toPromise();
  }

  public getI9Attachments(hrref: string): any {
    return this.http
      .get(GET_I9_ATTACHEMENTS.split('{:HRRef}').join(hrref))
      .toPromise();
  }

  public getGeneralAttachments(hrref: string): any {
    return this.http
      .get(GET_GENERAL_ATTACHEMENTS.split('{:HRRef}').join(hrref))
      .toPromise();
  }

  public getIDAttachments(hrref: string): any {
    return this.http
      .get(GET_ID_ATTACHEMENTS.split('{:HRRef}').join(hrref))
      .toPromise();
  }

  public getTWICAttachments(hrref: string): any {
    return this.http
      .get(GET_TWIC_ATTACHEMENTS.split('{:HRRef}').join(hrref))
      .toPromise();
  }

  public addNewAttachment(attachment): any {
    const url = ADD_NEW_ATTACHMENT;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    return this.http.post(url, attachment, options).toPromise();
  }

  public getSelectedAttachment(id): any {
    return this.http
      .get(DOWNLOAD_SELECTED_ATTACHMENT.split('{:ID}').join(id))
      .toPromise();
  }

  public downloadAttachment(attachment: AttachmentDto): any {
    const url = DOWNLOAD_SELECTED_ATTACHMENT.split('{:ID}').join(
      attachment.attachmentID.toString()
    );

    return this.http
      .get(url, {
        responseType: 'blob', // set as Blob type instead of Json
        observe: 'response',
      })
      .toPromise()
      .then(
        (data) => {
          console.log('dowload succeess');
          this.downloadBlob(data, attachment);
        },
        (err) => {
          console.log('Error while downloading file! ' + err.Messages);
          throw err;
        }
      );
  }

  public downloadBlob(data, attachmentInfo: AttachmentDto): any {
    if (data) {
      console.log('dowload blob');
      const fileURL = URL.createObjectURL(data.body);
      const a = window.document.createElement('a');
      a.style.display = 'none';
      a.href = fileURL;
      a['download'] = attachmentInfo.documentName;
      // Append anchor to body.
      document.body.appendChild(a);
      a.click();
      // Remove anchor from body
      document.body.removeChild(a);
    }
  }

  public removeSelectedAttachment(id): any {
    const url = REMOVE_SELECTED_ATTACHMENT.split('{:ID}').join(id);

    return this.http.delete(url).toPromise();
  }

  public queryEmployeeData(
    odataString: string,
    searchString: string
  ): Observable<IODataResult> {
    let url = GET_EMPLOYEE_DATA_ODATA;
    url += '?$format=json&$count=true';
    url += searchString ? '&searchString=' + searchString : '';
    url += odataString ? '&' + odataString : '';
    url = url.replaceAll('prCo', 'PRCo');
    url = url.replaceAll('city', 'City');
    url = url.replaceAll('class', 'Class');
    url = url.replaceAll('designation', 'Designation');
    url = url.replaceAll('state', 'State');
    console.log('Calling service: ', url);

    return this.http.get<IODataResult>(url);
  }

  public updateEmployeeOnsiteState(
    prco: number,
    employeeVPID: number,
    onsiteDate: string
  ): any {
    return this.http
      .post(UPDATE_EMPLOYEE_ONSITE_STATE, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        PRCo: prco,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        EmployeeVPID: employeeVPID,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        OnsiteDate: onsiteDate,
      })
      .toPromise();
  }

  public updateEmployeeROFState(
    prco: number,
    employeeVPID: number,
    rofDate: string,
    rofReason: string
  ): any {
    return this.http
      .post(UPDATE_EMPLOYEE_ROF_STATE, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        PRCo: prco,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        EmployeeVPID: employeeVPID,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ROFDate: rofDate,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ROFReason: rofReason,
      })
      .toPromise();
  }

  public updateEmployeeOnsiteStates(dtos: UpdateEmployeeOnsiteStateDto[]): any {
    return this.http.post(UPDATE_EMPLOYEE_ONSITE_STATES, dtos);
  }

  public updateEmployeeROFStates(dtos: UpdateEmployeeROFStateDto[]): any {
    return this.http.post(UPDATE_EMPLOYEE_ROF_STATES, dtos);
  }
}
