import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'NumberFilter',
})
/**
 * Usage:
 * valid params lte, lt, eq, gt, gte
 *
 * NumberFilter: 'field': type : value
 *
 * value = a date object.
 *
 * ts file:
 *
 * var searchDate = new Date();
 *
 * <tr *ngFor="let item of mf.data | NumberFilter:'Count':'lte':customNumber:showNull">
 */
export class NumberFilter implements PipeTransform {
  transform(
    values: any[],
    key: string,
    type: string,
    args: any,
    showNull: any
  ): any {
    return values.filter((value) => {
      if (args == undefined) {
        return true;
      }

      if (args || args == 0) {
        if (value && value.hasOwnProperty(key)) {
          //if there is a null or undefined value dont show it
          if (!value[key] && value[key] != 0 && showNull == false) return false;
          else if (showNull == true && !value[key] && value[key] != 0) {
            return true;
          } else if (typeof value[key] == 'number' && typeof args == 'number') {
            var number = value[key];

            if (type == 'lt') {
              if (number < args) return true;
              else return false;
            } else if (type == 'lte') {
              if (number <= args) return true;
              else return false;
            } else if (type == 'gt') {
              if (number > args) return true;
              else return false;
            } else if (type == 'gte') {
              if (number >= args) return true;
              else return false;
            } else if (type == 'eq') {
              if (number == args) return true;
              else return false;
            }
          }
        }
      }

      return true;
    });
  }
}
