// plantuml.service.ts
import { Injectable } from '@angular/core';
import { encode } from 'plantuml-encoder'; // If you prefer using the plantuml-encoder package for more complex diagrams

@Injectable({
  providedIn: 'root',
})
export class PlantumlService {
  constructor() {}

  // Encodes the PlantUML text and returns the URL for the diagram
  public getDiagramUrl(umlText: string): string {
    const encoded = encode(umlText);
    console.log(encoded);
    return `https://plantuml-ups1.azurewebsites.net/plantuml/svg/${encoded}`;
  }
}
