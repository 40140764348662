import { BaseModel } from '../../base.model';
import { PrGroupDto } from './PrGroupDto';

export class PayPeriodDto extends BaseModel {
  PrEndDate: Date;
  PRGroups: PrGroupDto[];

  constructor(o: any = null) {
    super(o);
  }
}
