/* eslint-disable @typescript-eslint/naming-convention */

import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  inject,
  Input,
} from '@angular/core';
import {
  ApplicationModel,
  DashboardTradeClassDto,
  ManPowerSubRequirementDto,
  ManpowerSubRequirementGroup,
  ManPowerSubRequirementRequestedClass,
} from '@ups/xplat/api/dto';
import {
  CodeListResolver,
  TrainingClass,
  TrainingClassService,
} from '@ups/xplat/web/services';
import { HRResourceService, JobsService } from '@ups/xplat/api/services';
import { SeFilterChangeExArgs } from '../../kendo';
import { Observable, map } from 'rxjs';
import { SecurityConstants, SecurityService } from '@ups/security';

@Component({
  selector: 'ups-training-subtask-editor',
  templateUrl: './training-subtask-editor.component.html',
})
export class TrainingSubtaskEditorComponent implements OnInit {
  applicationModel = inject(ApplicationModel);
  codeListResolver = inject(CodeListResolver);
  hrResourceService = inject(HRResourceService);
  jobsService = inject(JobsService);
  trainingClassService = inject(TrainingClassService);
  security = inject(SecurityService);

  @Input() companyId: string;

  @Output() editSubtask: EventEmitter<ManPowerSubRequirementDto> =
    new EventEmitter<ManPowerSubRequirementDto>();

  isEdit: boolean;
  model: ManPowerSubRequirementDto;
  opened: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  safetyCouncil: any;

  subRequirementGroups: ManpowerSubRequirementGroup[] = [];
  tradeClasses: DashboardTradeClassDto[] = [];
  selectedTradeClasses: DashboardTradeClassDto[] = [];

  localTrainingClasses: TrainingClass[] = [];

  securityConstants = SecurityConstants;

  ngOnInit(): void {
    this.isEdit = false;
    this.opened = false;
    this.model = new ManPowerSubRequirementDto();
  }

  public open(model?: ManPowerSubRequirementDto) {
    this.safetyCouncil = null;
    this.codeListResolver.loadSafetyCouncils().then(() => {
      if (model) {
        this.model = JSON.parse(JSON.stringify(model));
        if (model.SafetyCouncilID) {
          this.safetyCouncil = this.applicationModel?.saftyCouncils.find(
            (c) => c.SafetyCouncilID === model.SafetyCouncilID
          );
        }
        this.isEdit = true;
      } else {
        this.model = new ManPowerSubRequirementDto();
        this.model.bActive = true;
        this.isEdit = false;
      }
      this.opened = true;
    });

    this.jobsService
      .fetchTradeClassesForCompany(this.companyId, '', false)
      .subscribe((tradeClasses) => {
        this.tradeClasses = tradeClasses.value;
        this.selectedTradeClasses =
          model?.RequestedClasses.map((c) => {
            return this.tradeClasses.find(
              (t) => t.tradeClassId === c.RequestedTradeClassID
            );
          }) || [];
      });

    this.hrResourceService
      .getManPowerSubRequirementGroups()
      .subscribe((data) => {
        this.subRequirementGroups = data;
      });

    if (model?.VPTrainingClassKeyID) {
      this.trainingClassService
        .getTrainingClassByKeyId(model.VPTrainingClassKeyID)
        .subscribe((trainingClass) => {
          this.localTrainingClasses = [trainingClass];
        });
    }
  }

  public close() {
    this.opened = false;
  }

  isValid() {
    if (this.model.Name && this.model.Name.trim().length !== 0) return true;
    return false;
  }

  onAdd() {
    if (this.isExistingGroupSelectedAfterCreatingNew()) {
      this.model.GroupName = undefined;
    }

    const originalRequestedClasses: ManPowerSubRequirementRequestedClass[] =
      JSON.parse(JSON.stringify(this.model?.RequestedClasses ?? []));

    this.model.RequestedClasses = this.selectedTradeClasses.map(
      (tradeClass) => {
        const originalRequestedClass = originalRequestedClasses.find(
          (c) => c.RequestedTradeClassID === tradeClass.tradeClassId
        );

        if (originalRequestedClass) {
          return originalRequestedClass;
        } else {
          return {
            RequestedTradeClassID: tradeClass.tradeClassId,
            RequestedLinkedClassID: tradeClass.linkedClassId,
            RequestedClassName: tradeClass.requestedClassName,
            bActive: true,
          };
        }
      }
    );
    this.editSubtask.emit(this.model);
    this.opened = false;
  }

  valueChangeSafetyCouncil(sc) {
    if (sc) {
      this.model.SafetyCouncilID = sc.Id;
      this.model.SafetyCouncilName = sc.Name;
    } else {
      this.model.SafetyCouncilID = null;
      this.model.SafetyCouncilName = null;
    }
  }

  filterChangeSafetyCouncil(event) {
    let saftyCouncils = this.applicationModel?.saftyCouncils;
    if (event.query) {
      const query = event.query.toLowerCase();
      saftyCouncils = this.applicationModel?.saftyCouncils.filter(
        (saftyCouncil) => saftyCouncil.Name.toLowerCase().indexOf(query) !== -1
      );
    }

    event.extension.applyData(saftyCouncils);
  }

  filterChangeTrainingClass(event: SeFilterChangeExArgs) {
    const oDataQuery = event.extension.toODataQuery(event.query);

    this.trainingClassService
      .getTrainingClasses(oDataQuery)
      .subscribe((trainingClasses) => {
        event.extension.applyData(trainingClasses);
      });
  }

  groupValueNormalizer = (text: Observable<string>) =>
    text.pipe(
      map((content: string) => {
        this.model.GroupName = content;
        return undefined;
      })
    );

  private isExistingGroupSelectedAfterCreatingNew() {
    return this.model.ManPowerSubRequirementGroupID && this.model.GroupName;
  }
}
