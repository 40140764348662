/* eslint-disable @typescript-eslint/naming-convention */
export class ManPowerSubRequirementDetailDto {
  AutoAudited: boolean;
  DateModified: Date;
  ExpirationDate: Date;
  ManPowerSubRequirementId: string;
  Mandatory: boolean;
  ModifiedBy: string;
  Name: string;
  Notes: string;
  SafetyCouncilLocation: string;
  SubtaskDone: boolean;

  changed: boolean;
}
