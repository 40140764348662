import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { catchError } from 'rxjs';
import { MyHttpClientFactory } from '../http';

export interface OfflineAPICall {
  id?: string;
  applicationId: string;
  userId: string;
  httpType: string;
  url: string;
  body: string;
  headers: string;
  application: string;
  user: string;
  reason: string;
}

@Injectable({
  providedIn: 'root',
})
export class OfflineAPICallService extends DataService<OfflineAPICall> {
  constructor(httpClient: HttpClient) {
    const url = 'https://software-security2-api.azurewebsites.net';
    const route = '/api/OfflineAPICall';
    const httpFactoryService = inject(MyHttpClientFactory);
    super(url, route, httpClient, httpFactoryService);
  }

  createOfflineAPICall(offlineAPICall: OfflineAPICall) {
    return this.httpClient
      .post<OfflineAPICall>(`${this.route}`, offlineAPICall)
      .pipe(catchError(this.errorHandler));
  }
}
