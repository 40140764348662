export class EquipmentSearchDto {
  public AltID: string;
  public Amount: number;
  public BillingRate: number;
  public BillingTemplate: string;
  public CustomerPO: string;
  public DateExported: string;
  public DateReallocated: string;
  public DateUsed: Date;
  public Equipment: string;
  public EquipmentBillingID: string;
  public EquipmentDispatchID: string;
  public EquipmentID: string;
  public EquipmentName: string;
  public EquipmentUsageID: string;
  public ReallocatedBy: string;
  public Shift: number;
  public TimeSheetID: string;
  public TimeSheetName: string;
  public UnitName: string;
  public WorkOrder: string;
  public WorkOrderDescription: string;
  public bActive: boolean;
}
