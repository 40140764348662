import { Directive } from '@angular/core';
import { BaseComponent } from '@ups/xplat/core';

@Directive()
export abstract class ProfileI9DocumentsBaseComponent extends BaseComponent {
  public text = 'ProfileI9Documents';

  constructor() {
    super();
  }
}
