/* eslint-disable @typescript-eslint/no-explicit-any */
import { GridComponent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { combineLatest, of } from 'rxjs';
import { StateUtils } from '../../../Data-Query';
import {
  GroupResultSelectionSupport,
  GroupResultUtils,
} from '../../Group-Result';
import { GroupingExDirective } from '../grouping-ex.directive';
import { GroupingExLoad, GroupingExLoadResult } from '../grouping-ex.load';

import { PathSegment } from './../../Group-Result/path-segment';
import { Traverse } from './../../Group-Result/traverse';

export interface IGroupExpansionDetails {
  expanded: PathSegment[][];
  collapsed: PathSegment[][];
}

/**
 * This class is responsible for altering the grid state (expand/unexpand)...
 */
export class GroupCollapseExpandUtils {
  /**
   * NOTE: it uses INTERNAL kendo function!
   */
  public static getExpansionDetails(
    grid: GridComponent,
    items: any[],
    parentItem: PathSegment[],
    skipSize: number
  ): IGroupExpansionDetails {
    const gridGroupService = (grid as any).groupsService;
    if (!gridGroupService)
      return { expanded: [], collapsed: [] } as IGroupExpansionDetails;

    const parentPath = PathSegment.getPathToDataItem(items, parentItem);

    const expandedGroupPaths: PathSegment[][] = [];
    const collapsedGroupPaths: PathSegment[][] = [];

    Traverse.traverseGridData(
      items,
      parentPath,
      true,
      true,
      (callback) => {
        const groupPath = [].concat(callback.parentPath, [
          new PathSegment(callback.dataItem, callback.dataIndex),
        ]);
        const groupIndex = PathSegment.getGroupIndex(groupPath, skipSize);

        // NOTE: groupService is PRIVATE (internal) property of GridComponent!
        if (gridGroupService.isExpanded({ groupIndex: groupIndex }))
          expandedGroupPaths.push(groupPath);
        else collapsedGroupPaths.push(groupPath);
      },
      null
    );

    return {
      expanded: expandedGroupPaths,
      collapsed: collapsedGroupPaths,
    } as IGroupExpansionDetails;
  }

  /*
    Used on NIFTY where all groups are loaded, but last node items not, so we need to collapse last node, expand (default) rest...
     */
  public static expandAllCollapseLast(
    grid: GridComponent,
    state: State,
    items: any[],
    parentIdentificator: string = undefined,
    level = 1
  ) {
    items.forEach((item, index) => {
      const fixedIndex = level === 1 ? index + grid.skip : index;
      const identificator = parentIdentificator
        ? parentIdentificator + '_' + fixedIndex.toString()
        : fixedIndex.toString();

      // had to remove deleteNonAggregateItems because we clear items...
      const isAggregate = PathSegment.isAggregate(item);

      if (isAggregate) {
        if (level === state.group.length) {
          // when last level is reached, collapse it (grid starts with expanded by default)
          grid.collapseGroup(identificator);
        } else {
          // if not last level, go deeper (recursivity)...
          this.expandAllCollapseLast(
            grid,
            state,
            item['items'],
            identificator,
            level + 1
          );

          // NOTE: need to expand, because grid remembers! maybe an alt. way would be clear their internal cache.
          //
          // NOTE: I don't know what i meant by the note ^ above.
          // Maybe a paging issue, where previous items on same index were collapsed, but due the remember cache grid would assume that the node has to be collapsed...
          // Anyhow the desired state is expanded
          grid.expandGroup(identificator);
        }
      }
    });
  }

  public static collapseAll(
    grid: GridComponent,
    items: any[],
    parentIdentificator: string = undefined,
    level = 1
  ) {
    // NOTE: replaceable by a collapseSubtree call on every item
    items.forEach((item, index) => {
      const fixedIndex = level === 1 ? index + grid.skip : index;
      const identificator = parentIdentificator
        ? parentIdentificator + '_' + fixedIndex.toString()
        : fixedIndex.toString();

      const isAggregate = PathSegment.isAggregate(item);

      if (isAggregate) {
        this.collapseAll(grid, item['items'], identificator, level + 1);
        grid.collapseGroup(identificator);
      }
    });
  }

  public static expandAll(
    grid: GridComponent,
    items: any[],
    expandJustWhenAggregateItemsPresent = true,
    parentIdentificator: string = undefined,
    level = 1
  ) {
    // NOTE: replaceable by a expandSubtree call on every item
    items.forEach((item, index) => {
      const fixedIndex = level === 1 ? index + grid.skip : index;
      const identificator = parentIdentificator
        ? parentIdentificator + '_' + fixedIndex.toString()
        : fixedIndex.toString();

      const isAggregate = PathSegment.isAggregate(item);

      if (isAggregate) {
        // TODO: check if there's an item, expand only if it is (and add override also)...
        const aggregateItems = item['items'];

        if (
          expandJustWhenAggregateItemsPresent === false ||
          aggregateItems.length > 0
        ) {
          this.expandAll(
            grid,
            aggregateItems,
            expandJustWhenAggregateItemsPresent,
            identificator,
            level + 1
          );
          grid.expandGroup(identificator);
        }
      }
    });
  }

  public static expandSubtree(
    grid: GridComponent,
    groupDataItem: any,
    expandMyself = false
  ) {
    const gridData = <any>grid.data;
    const gridItems = gridData.data;
    const path =
      PathSegment.getPathToDataItem(gridData.data, groupDataItem) || [];

    // TODO: extract to function - getIndexIdentificator(path)
    let pathNodeItems = gridItems;
    let parentIdentificator = '';

    path.forEach((item, levelIndex) => {
      const fixedIndex = levelIndex === 0 ? item.index + grid.skip : item.index;
      pathNodeItems = pathNodeItems[item.index].items;
      parentIdentificator = parentIdentificator
        ? parentIdentificator + '_' + fixedIndex.toString()
        : fixedIndex.toString();
    });

    // expanding...
    if (expandMyself) grid.expandGroup(parentIdentificator);

    GroupCollapseExpandUtils.traverseGroups(
      pathNodeItems,
      grid.skip,
      parentIdentificator,
      (groupItem, groupIndex) => {
        grid.expandGroup(groupIndex);
      }
    );
  }

  public static collapseSubtree(
    grid: GridComponent,
    groupDataItem: any,
    collapseMyself = false
  ) {
    const gridData = <any>grid.data;
    const gridItems = gridData.data;
    const path =
      PathSegment.getPathToDataItem(gridData.data, groupDataItem) || [];

    // TODO: extract to function - getIndexIdentificator(path)
    let pathNodeItems = gridItems;
    let parentIdentificator = '';

    path.forEach((item, levelIndex) => {
      const fixedIndex = levelIndex === 0 ? item.index + grid.skip : item.index;
      pathNodeItems = pathNodeItems[item.index].items;
      parentIdentificator = parentIdentificator
        ? parentIdentificator + '_' + fixedIndex.toString()
        : fixedIndex.toString();
    });

    // collapsing...
    if (collapseMyself) grid.collapseGroup(parentIdentificator);

    GroupCollapseExpandUtils.traverseGroups(
      pathNodeItems,
      grid.skip,
      parentIdentificator,
      (groupItem, groupIndex) => {
        grid.collapseGroup(groupIndex);
      }
    );
  }

  public static expandNode(
    groupingEx: GroupingExDirective,
    grid: GridComponent,
    groupDataItem: any,
    checked?: boolean,
    expandMyself = true,
    loadIndicator = true
  ) {
    const mergedGridState = groupingEx.getModdedState(
      StateUtils.getStateFromGrid(grid)
    );
    const gridData = <any>grid.data;
    const groupDataItemPath =
      PathSegment.getPathToDataItem(gridData.data, groupDataItem) || [];

    if (!groupDataItem[GroupResultSelectionSupport.selectionStateProperty]) {
      groupDataItem[GroupResultSelectionSupport.selectionStateProperty] = {
        checked: false,
      };
    }

    if (checked != null) {
      groupDataItem[
        GroupResultSelectionSupport.selectionStateProperty
      ].checked = checked;
    }

    const isAggregate = PathSegment.isAggregate(groupDataItem);
    if (!isAggregate) return;

    GroupCollapseExpandUtils.expandSubtree(grid, groupDataItem, expandMyself);

    const anySubNodeWithoutItems = GroupResultUtils.anyUnloadedItemsBelowPath(
      gridData.data,
      groupDataItemPath
    );

    const localObservable = anySubNodeWithoutItems
      ? GroupingExLoad.loadLevelsBelow(
          groupingEx.viewModel,
          grid,
          mergedGridState,
          groupDataItem
        )
      : of(new GroupingExLoadResult());

    // load
    if (loadIndicator) grid.loading = true;

    localObservable.subscribe((data: GroupingExLoadResult) => {
      if (data) {
        GroupingExLoad.mergeLoadLevelsBelowResultToGrid(
          groupingEx.viewModel,
          grid,
          mergedGridState,
          groupDataItem,
          data
        );

        if (groupDataItem.items) {
          groupDataItem.items.forEach((item) => {
            this.expandNode(
              groupingEx,
              grid,
              item,
              checked,
              expandMyself,
              loadIndicator
            );
          });
        }
      }
      if (loadIndicator) grid.loading = false;
    });
    return localObservable;
  }

  public static expandNodes(
    groupingEx: GroupingExDirective,
    grid: GridComponent,
    groupDataItems: any[],
    checked?: boolean,
    expandMyself = true
  ) {
    grid.loading = true;
    const allObservables = groupDataItems.map((gridItem) =>
      GroupCollapseExpandUtils.expandNode(
        groupingEx,
        grid,
        gridItem,
        checked,
        expandMyself,
        false
      )
    );
    combineLatest(allObservables).subscribe(() => {
      grid.loading = false;
    });
  }

  /** An efficient g(groupIndex based) recursive traverse method... */
  private static traverseGroups(
    items: any[],
    gridSkip: number,
    parentIdentificator: string,
    callback: (groupItem: any, groupIndex: string) => any
  ) {
    const level = (parentIdentificator || '').split('_').length + 1;

    items.forEach((item, index) => {
      const fixedIndex = level === 1 ? index + gridSkip : index;
      const identificator = parentIdentificator
        ? parentIdentificator + '_' + fixedIndex.toString()
        : fixedIndex.toString();
      const isAggregate = PathSegment.isAggregate(item);
      if (isAggregate) {
        callback(item, identificator);
        GroupCollapseExpandUtils.traverseGroups(
          item['items'],
          gridSkip,
          identificator,
          callback
        );
      }
    });
  }
}
