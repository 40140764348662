import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SimpleDateForPseudodatePipe } from './simple-date-for-pseudodate.pipe';

@NgModule({
  declarations: [SimpleDateForPseudodatePipe],
  exports: [SimpleDateForPseudodatePipe],
  imports: [CommonModule],
})
export class SimpleDateForPseudodateModule {}
