import { Component } from '@angular/core';
import { WebDynamicItemPreviewComponentBase } from '../web-dynamic-item-component-base';

@Component({
  selector: 'drawing-circle',
  styleUrls: ['drawing-circle.component.scss'],
  template: `
    <div class="dynamic-field drawing-circle">
      <div [innerHTML]="config.data.content"></div>
    </div>
  `,
})
export class DrawingCircleComponent extends WebDynamicItemPreviewComponentBase {}
