<div class="d-flex py-7 justify-content-between align-items-center">
  <div *ngIf="withPageTitle" class="d-flex">
    <h3 class="me-5">{{ pageTitle }}</h3>
  </div>
  <div class="d-flex" *ngIf="!withPageTitle">
    <h3 class="me-5">{{ employeeInfo.fullName }}</h3>
    <p class="mb-0">
      <span>{{ data?.Position }}</span>
      <span class="ms-3">
        <i class="far fa-map-marker-alt primary-50"></i>
        {{ data?.PhysicalAddress?.City }}, {{ data?.PhysicalAddress?.State }}
      </span>
    </p>
  </div>
  <div class="d-flex align-items-center">
    <div *ngIf="withPageTitle" class="d-flex align-items-center">
      <h3 class="me-5">{{ employeeInfo.fullName }}</h3>
      <p class="mb-0">
        <span>{{ data?.Position }}</span>
        <span class="ms-3">
          <i class="far fa-map-marker-alt primary-50"></i>
          {{ data?.PhysicalAddress?.City }}, {{ data?.PhysicalAddress?.State }}
        </span>
      </p>
    </div>
    <div class="ms-5">
      <!-- <kendo-chip [label]="'Caution'" [type]="'warning'" [icon]="'k-icon k-i-warning'" class="me-3"></kendo-chip> TODO dynamic show -->
      <kendo-chip
        *ngIf="data?.ActiveYN === 'Y'"
        themeColor="success"
        [label]="'Active'"
        [icon]="'k-icon k-i-check'"
      ></kendo-chip>
      <!-- <kendo-chip *ngIf="data?.ActiveYN === 'N'" [label]="'Inactive'" [type]="'error'" [icon]="'k-icon k-i-close'" class="me-3"></kendo-chip> -->
    </div>
  </div>
</div>
