import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import {
  DashboardTradeClassDto,
  DispatchDetail,
  DispatchFile,
  DispatchFileUpload,
  DispatchLogHistoryDto,
  EmployeeHistory,
  EmployeeJob,
  EmployeeRatesDto,
  IODataResult,
  JobCraftClass,
  JobHistory,
  LaborBillingTemplate,
  PerDiemDoc,
  PerDiemDocumentType,
  ValidationDto,
  TransferEmployeeCompany,
} from '@ups/xplat/api/dto';

const GET_DISPATCH_ODATA_BY_JOB_DATA = '/api/dispatch/dashboard-odata';
const GET_JOB_HISTORY_DATA = '/api/dispatch/dashboard/jobhistory/{:employeeId}';
const GET_EMPLOYEE_HISTORY_DATA =
  '/api/dispatch/dashboard/employeehistory/{:employeeId}';
const GET_JOB_CRAFTS_AND_CLASSES_DATA =
  '/api/dispatch/dashboard/jobCraftsAndClasses/{:jobId}';
const GET_PER_DIEM_DOCS_DATA =
  '/api/dispatch/dashboard/perdiemdocs/{:manPowerRequirementId}';
const SAVE_EMPLOYEE_JOB = '/api/dispatch/dashboard/savejobcrew';
const SAVE_EMPLOYEE_JOBS = '/api/dispatch/dashboard/savejobcrews';
const VALIDATE_EMPLOYEE_JOBS = '/api/dispatch/dashboard/validatejobcrews';
const TRANSFER_EMPLOYEE_COMPANY = '/api/dispatch/transferEmployee/';
const GET_LABOR_BILLING_TEMPLATES_FOR_JOB =
  '/api/dispatch/dashboard/laborbillingtemplates/{:jobId}';
const GET_PER_DIEM_DOC_TYPES = '/api/dispatch/dashboard/perdiem/doctypes';
const GET_SIGNED_DISPATCH_FILES_FOR_JOB =
  '/api/dispatch/dashboard/signeddispatchfiles/{:employeeJobId}';
const REMOVE_SIGNED_DISPATCH_FILES_FOR_JOB =
  '/api/dispatch/dashboard/signeddispatchfiles/{:dispatchFileId}';
const UPLOAD_SIGNED_DISPATCH_FILE_FOR_JOB =
  '/api/dispatch/dashboard/signeddispatchfile';
const GET_DISPATCH_DETAIL_DATA_BY_EMPLOYEE_JOB =
  '/api/dispatch/dashboard/detaildata/employeejob/{:employeeJobId}';
const GET_PR_GROUPS = '/api/dispatch/dashboard/prgroups/{:jobId}';
const GET_EMPLOYEE_RATES = '/api/employee/rates/{:employeeId}';
const FETCH_TRADECLASSES_FOR_COMPANY =
  '/api/manpowers/tradeclasses/company/{:companyId}';
const FETCH_ALL_TRADECLASSES = '/api/manpowers/tradeclasses/all';
const GET_DISPATCH_LOG_HISTORY =
  '/api/dispatch/history/employee/{:employeeJobId}';

@Injectable({ providedIn: 'root' })
export class DispatchService {
  httpPascalCase: HttpClient;
  httpCamelCase: HttpClient;

  constructor(private clientFactory: MyHttpClientFactory) {
    const urlBase = environment.urls.spartaAPI;
    this.httpPascalCase = this.clientFactory.createHttpClient(
      urlBase,
      true,
      ResponseCasingEnum.PascalCase
    );
    this.httpCamelCase = this.clientFactory.createHttpClient(
      urlBase,
      true,
      ResponseCasingEnum.CamelCase
    );
  }

  public fetchDispatchOdataByJobData(
    jobId: string,
    oDataString: string
  ): Observable<IODataResult<EmployeeJob>> {
    let url = GET_DISPATCH_ODATA_BY_JOB_DATA + '?jobId=' + jobId;

    url += '&$format=json&$count=true';
    url += oDataString ? '&' + oDataString : '';

    return this.httpPascalCase.get(url) as Observable<
      IODataResult<EmployeeJob>
    >;
  }

  public queryJobHistoryData(employeeId: string): Observable<JobHistory[]> {
    const url = GET_JOB_HISTORY_DATA.split('{:employeeId}').join(employeeId);
    return this.httpCamelCase.get(url) as Observable<JobHistory[]>;
  }

  public queryEmployeeHistoryData(
    employeeId: string
  ): Observable<EmployeeHistory[]> {
    const url =
      GET_EMPLOYEE_HISTORY_DATA.split('{:employeeId}').join(employeeId);
    return this.httpCamelCase.get(url) as Observable<EmployeeHistory[]>;
  }

  public queryJobCraftsAndClassesData(
    jobId: string
  ): Observable<JobCraftClass> {
    const url = GET_JOB_CRAFTS_AND_CLASSES_DATA.split('{:jobId}').join(jobId);
    return this.httpCamelCase.get(url) as Observable<JobCraftClass>;
  }

  public queryPerDiemDocsData(
    manPowerRequirementId: string
  ): Observable<PerDiemDoc[]> {
    const url = GET_PER_DIEM_DOCS_DATA.split('{:manPowerRequirementId}').join(
      manPowerRequirementId
    );
    return this.httpCamelCase.get(url) as Observable<PerDiemDoc[]>;
  }

  public saveEmployeeJob(employeeJob: EmployeeJob): Observable<EmployeeJob> {
    return this.httpPascalCase.post(
      SAVE_EMPLOYEE_JOB,
      employeeJob
    ) as Observable<EmployeeJob>;
  }

  public saveEmployeeJobs(employeeJobs: EmployeeJob[]): Observable<boolean> {
    return this.httpPascalCase.post(
      SAVE_EMPLOYEE_JOBS,
      employeeJobs
    ) as Observable<boolean>;
  }

  public validateEmployeeJobs(
    employeeJobs: EmployeeJob[]
  ): Observable<ValidationDto[]> {
    return this.httpCamelCase.post(
      VALIDATE_EMPLOYEE_JOBS,
      employeeJobs
    ) as Observable<ValidationDto[]>;
  }

  public queryLaborBillingTemplatesForJob(
    jobId: string
  ): Observable<LaborBillingTemplate[]> {
    let url = GET_LABOR_BILLING_TEMPLATES_FOR_JOB.split('{:jobId}').join(jobId);
    url += '?template=AllTemplatesFromJobHistory';
    return this.httpCamelCase.get(url) as Observable<LaborBillingTemplate[]>;
  }

  public queryPerDiemDocumentTypes(): Observable<PerDiemDocumentType> {
    const url = GET_PER_DIEM_DOC_TYPES;
    return this.httpCamelCase.get(url) as Observable<PerDiemDocumentType>;
  }

  public getSignedDispatchFiles(
    employeeJobId: string
  ): Observable<DispatchFile[]> {
    const url =
      GET_SIGNED_DISPATCH_FILES_FOR_JOB.split('{:employeeJobId}').join(
        employeeJobId
      );
    return this.httpCamelCase.get(url) as Observable<DispatchFile[]>;
  }

  public removeSignedDispatchFile(dispatchFileId: string): Observable<never> {
    const url =
      REMOVE_SIGNED_DISPATCH_FILES_FOR_JOB.split('{:dispatchFileId}').join(
        dispatchFileId
      );
    return this.httpCamelCase.delete(url) as Observable<never>;
  }

  public uploadSignedDispatchFile(
    dispatchFile: DispatchFileUpload
  ): Observable<never> {
    const url = UPLOAD_SIGNED_DISPATCH_FILE_FOR_JOB;

    const formData = new FormData();
    Object.keys(dispatchFile).forEach(function (item) {
      formData.append(item, dispatchFile[item]);
    });
    return this.httpCamelCase.post(url, formData) as Observable<never>;
  }

  public queryDispatchDetailDataByEmployeeJob(
    employeeJobId: string
  ): Observable<DispatchDetail> {
    const url =
      GET_DISPATCH_DETAIL_DATA_BY_EMPLOYEE_JOB.split('{:employeeJobId}').join(
        employeeJobId
      );
    return this.httpCamelCase.get(url) as Observable<DispatchDetail>;
  }

  public getPRGroups(jobId: string): Observable<string[]> {
    const url = GET_PR_GROUPS.split('{:jobId}').join(jobId);
    return this.httpCamelCase.get(url) as Observable<string[]>;
  }

  public getEmployeeRates(
    employeeId: string,
    craft: string,
    jobClass: string,
    shift: number
  ): Observable<EmployeeRatesDto> {
    let url = GET_EMPLOYEE_RATES.split('{:employeeId}').join(employeeId);

    const queryParameters: string[] = [];

    if (craft) {
      queryParameters.push(`craft=${craft}`);
    }
    if (jobClass) {
      queryParameters.push(`jobClass=${jobClass}`);
    }
    if (shift) {
      queryParameters.push(`shift=${shift}`);
    }

    if (queryParameters.length) {
      url = url + '?' + queryParameters.join('&');
    }

    return this.httpCamelCase.get(url) as Observable<EmployeeRatesDto>;
  }

  public getRequestedClassesForCompany(
    companyId: string,
    odataString: string,
    skipCompany: boolean
  ): Observable<IODataResult<DashboardTradeClassDto>> {
    let url = skipCompany
      ? FETCH_ALL_TRADECLASSES
      : FETCH_TRADECLASSES_FOR_COMPANY.split('{:companyId}').join(companyId);

    url += '?$format=json&$count=true';
    url += odataString ? '&' + odataString : '';

    return this.httpCamelCase.get<IODataResult<DashboardTradeClassDto>>(url);
  }

  public getEmployeeDispatchLogHistory(
    employeeJobId: string
  ): Observable<DispatchLogHistoryDto[]> {
    const url =
      GET_DISPATCH_LOG_HISTORY.split('{:employeeJobId}').join(employeeJobId);
    return this.httpCamelCase.get<DispatchLogHistoryDto[]>(url);
  }

  public transferEmployeeCompany(
    tec: TransferEmployeeCompany[]
  ): Observable<TransferEmployeeCompany[]> {
    const url = TRANSFER_EMPLOYEE_COMPANY;
    return this.httpCamelCase.put(url, tec) as Observable<
      TransferEmployeeCompany[]
    >;
  }

  checkTransferEmployeeLabors(employeeId: string, vpJobId: string) {
    return this.httpCamelCase.get(
      `/api/dispatch/checkTransferEmployeeTimekeeping/${employeeId}/${vpJobId}`
    );
  }
}
