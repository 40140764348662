<nav class="navbar navbar-expand-lg">
  <div class="container-fluid">
    <div class="navbar-brand">
      <a href="#" title="{{ title }}" help-anchor="header__title">
        <img src="{{ logoSrc }}" alt="{{ logoAltText }}" />
        <span class="d-none d-md-inline-block">{{ title }}</span>
      </a>
    </div>

    <ng-container *ngIf="content">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </ng-container>

    <div class="ms-auto user-details">
      <kendo-menu>
        <kendo-menu-item>
          <ng-template kendoMenuItemTemplate>
            <i class="fas fa-user" title="User Menu"></i>
            <span class="d-none d-xl-block ms-3" help-anchor="display-name">
              {{
                (auth.isAuthenticated$ | async)
                  ? 'Hello ' +
                    (user?.Data?.FirstName || '') +
                    ' ' +
                    (user?.Data?.LastName || '')
                  : 'Log In'
              }}
            </span>
            <i class="far fa-angle-down ms-3"></i>
          </ng-template>

          <kendo-menu-item *ngIf="(defaultPageLoaded$ | async) === false">
            <ng-template kendoMenuItemTemplate>
              <div class="w-100 mx-0" (click)="setDefaultPage()">
                Set Default Page
              </div>
            </ng-template>
          </kendo-menu-item>

          <kendo-menu-item *ngIf="defaultPageLoaded$ | async">
            <ng-template kendoMenuItemTemplate>
              <div class="w-100 mx-0" (click)="clearDefaultPage()">
                Clear Default Page
              </div>
            </ng-template>
          </kendo-menu-item>

          <kendo-menu-item [separator]="true"></kendo-menu-item>

          <kendo-menu-item *ngIf="allowOfflineManager">
            <ng-template kendoMenuItemTemplate>
              <i
                class="fas fa-wifi-slash"
                title="Offline Manager"
                (click)="viewOfflineManager()"
              ></i>

              <span
                class="d-none d-xl-block ms-3"
                (click)="viewOfflineManager()"
              >
                Offline Manager
              </span>
            </ng-template>
          </kendo-menu-item>

          <kendo-menu-item [separator]="true"></kendo-menu-item>

          <kendo-menu-item *ngIf="isNiftyApp">
            <ng-template kendoMenuItemTemplate>
              <div class="w-100 mx-0" (click)="goToNiftySettings()">
                Settings
              </div>
            </ng-template>
          </kendo-menu-item>

          <kendo-menu-item>
            <ng-template kendoMenuItemTemplate>
              <ng-container
                *ngIf="auth.isAuthenticated$ | async; else logInTemplate"
              >
                <div
                  help-anchor="button__logout"
                  class="w-100 mx-0"
                  (click)="logout()"
                >
                  Log Out
                </div>
              </ng-container>
              <ng-template #logInTemplate>
                <div class="w-100 mx-0" (click)="login()">Log In</div>
              </ng-template>
            </ng-template>
          </kendo-menu-item>
        </kendo-menu-item>
      </kendo-menu>
    </div>

    <div class="navbar-right">
      <div>
        <kendo-menu>
          <kendo-menu-item>
            <ng-template kendoMenuItemTemplate>
              <i class="far fa-question-circle me-3"></i>
              <i class="far fa-angle-down"></i>
            </ng-template>

            <kendo-menu-item>
              <ng-template kendoMenuItemTemplate>
                <div (click)="onHelpdeskClicked()" class="w-100 m-0">
                  <i class="k-icon k-i-mail me-2"></i>
                  Submit a Help Desk Ticket
                </div>
              </ng-template>
            </kendo-menu-item>

            <kendo-menu-item *ngIf="helpDocumentsRoute">
              <ng-template kendoMenuItemTemplate>
                <div [routerLink]="[helpDocumentsRoute]" class="w-100 m-0">
                  <i class="k-icon k-i-mail me-2"></i>
                  Help Documents
                </div>
              </ng-template>
            </kendo-menu-item>

            <kendo-menu-item *ngIf="viewTooltips">
              <ng-template kendoMenuItemTemplate>
                <div (click)="onShowTooltipsClicked()" class="w-100 m-0">
                  <i class="k-icon k-i-help me-2"></i>
                  Show Tooltips
                </div>
              </ng-template>
            </kendo-menu-item>

            <kendo-menu-item *ngIf="viewWalkthroughs">
              <ng-template kendoMenuItemTemplate>
                <div class="w-100 m-0 d-flex align-items-center">
                  <i class="fas fa-shoe-prints me-2"></i>
                  <div>Walkthroughs</div>
                  <span
                    *ngIf="matchingWalkthroughs?.length"
                    class="small e-badge e-badge-primary ms-auto"
                  >
                    {{ matchingWalkthroughs.length }}
                  </span>
                </div>
              </ng-template>

              <kendo-menu-item
                *ngFor="let wt of matchingWalkthroughs"
                [text]="wt.title"
              >
                <ng-template kendoMenuItemTemplate>
                  <div (click)="showWalkthrough(wt)" class="w-100 m-0">
                    {{ wt.title }}
                  </div>
                </ng-template>
              </kendo-menu-item>

              <kendo-menu-item
                [separator]="true"
                *ngIf="matchingDrafts?.length > 0"
              ></kendo-menu-item>

              <kendo-menu-item
                *ngFor="let wt of matchingDrafts"
                [text]="wt.title"
              >
                <ng-template kendoMenuItemTemplate>
                  <div
                    (click)="showWalkthrough(wt)"
                    class="w-100 m-0 text-muted"
                  >
                    {{ wt.title }}
                  </div>
                </ng-template>
              </kendo-menu-item>
            </kendo-menu-item>

            <kendo-menu-item *ngIf="manageWalkthroughs">
              <ng-template kendoMenuItemTemplate>
                <div (click)="onEditWalkthroughsClicked()" class="w-100 m-0">
                  <i class="fas fa-pencil me-2" title="New Help Document"></i>
                  New Help Document
                </div>
              </ng-template>
            </kendo-menu-item>
          </kendo-menu-item>
        </kendo-menu>
      </div>

      <span class="navbar-icon cursor-pointer" (click)="toggleColorMode()">
        <i class="fas fa-adjust"></i>
      </span>

      <span class="navbar-separator mx-5"></span>

      <span
        class="app-switcher-logo"
        (click)="toggleAppSwitcher()"
        *ngIf="viewAppSwitcher"
      >
        <img src="assets/ups-one-logo.svg" alt="UPS 1 Logo" />
        <i class="fas fa-chevron-down"></i>
      </span>
    </div>
  </div>

  <kendo-popup
    *ngIf="showAppSwitcher && viewAppSwitcher"
    [popupAlign]="popupAlign"
    class="k-app-switcher"
  >
    <kendo-card class="alt">
      <kendo-card-header>
        <h3>UPS1 Applications</h3>
        <span class="k-card-close" (click)="toggleAppSwitcher()">
          <i class="fas fa-times"></i>
        </span>
      </kendo-card-header>
      <kendo-card-body>
        <div class="k-app-switcher-apps">
          <div
            *ngFor="let item of appSwitcherItemsAlt"
            [class.active]="item.active"
          >
            <a [href]="item.url">
              <img [src]="item.icon" [alt]="item.name" />
              <span>{{ item.name }}</span>
              <span *ngIf="item.active">(active)</span>
            </a>
          </div>
        </div>
      </kendo-card-body>
      <kendo-card-footer>
        <i class="fa fa-info-circle" aria-hidden="true"></i>
        <a href="#">Learn more</a>
        about our Applications
      </kendo-card-footer>
    </kendo-card>
  </kendo-popup>
</nav>
