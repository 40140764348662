import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PhaseDto } from '@ups/xplat/api/dto';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import { parseData } from '@ups/xplat/utils';
import { map } from 'rxjs/operators';
import { ODataResult } from '@ups/xplat/features';
import { Observable } from 'rxjs';

const GET_PHASES = '/api/phases-odata';
const SAVE_PHASE = '/api/phase';

@Injectable({
  providedIn: 'root',
})
export class PhaseService {
  http: HttpClient;

  constructor(private clientFactory: MyHttpClientFactory) {
    this.http = this.clientFactory.createHttpClient(
      environment.urls.spartaAPI,
      true,
      ResponseCasingEnum.PascalCase
    );
  }

  getPhasesUri(jobID: string, odataString: string = null) {
    const queryParameters: string[] = [];
    if (jobID) {
      queryParameters.push(`jobID=${jobID}`);
    }
    if (odataString) {
      queryParameters.push('$format=json');
      queryParameters.push('$count=true');
      queryParameters.push(odataString);
    }
    return GET_PHASES + '?' + queryParameters.join('&');
  }

  getPhasesUriInclServer(jobID: string, odataString: string = null) {
    return environment.urls.spartaAPI + this.getPhasesUri(jobID, odataString);
  }

  getPhases(
    jobID: string,
    odatastring: string
  ): Observable<ODataResult<PhaseDto>> {
    const url = this.getPhasesUri(jobID, odatastring);
    return this.http.get(url).pipe(map(parseData));
  }

  savePhase(model: PhaseDto[]) {
    return this.http.post(SAVE_PHASE, model).toPromise().then(parseData);
  }
}
