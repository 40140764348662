import { Component, OnInit } from '@angular/core';
import { DynamicItemBaseComponent } from '@ups/xplat/features';
import { FormatSettings } from '@progress/kendo-angular-dateinputs';

@Component({
  selector: 'ups-dynamic-date',
  template: `
    <div class="dynamic-field-container" [class.selected]="config?.selected">
      <div
        class="dynamic-field form-input text-align"
        [formGroup]="group"
        [hidden]="!config?.isFormBuilder && config?.options?.hidden"
      >
        <ups-dynamic-helper-label
          [config]="config"
          [dynamicService]="dynamicService"
        ></ups-dynamic-helper-label>
        <div>
          <kendo-datepicker
            *ngIf="!config?.options?.showTimePicker"
            class="w-100"
            [attr.name]="config.formControlName"
            [format]="dateFormat"
            formatPlaceholder="formatPattern"
            (valueChange)="onDateChanged($event)"
            (open)="onOpen()"
            [placeholder]="config?.placeholder"
            [formControlName]="config.formControlName"
          ></kendo-datepicker>
          <kendo-datetimepicker
            *ngIf="config?.options?.showTimePicker"
            class="w-100"
            [attr.name]="config.formControlName"
            [format]="dateFormat"
            formatPlaceholder="formatPattern"
            (valueChange)="onDateChanged($event)"
            (open)="onOpen()"
            [placeholder]="config?.placeholder"
            [formControlName]="config.formControlName"
          ></kendo-datetimepicker>
        </div>
        <div
          class="form-builder-mask"
          [class.hidden]="config?.options?.hidden"
          *ngIf="config?.isFormBuilder"
        ></div>
      </div>
      <div class="form-builder-options" *ngIf="config?.isFormBuilder">
        <i
          class="fas fa-times ml-3 cursor-pointer c-red"
          (click)="delete()"
        ></i>
        <!-- <i class="fas fa-bars ml-3 cursor-grab "></i> -->
      </div>
    </div>
  `,
})
export class DynamicDateComponent
  extends DynamicItemBaseComponent
  implements OnInit
{
  // https://www.telerik.com/kendo-angular-ui/components/dateinputs/datetimepicker/formats/
  dateFormat: FormatSettings = {
    displayFormat: 'MM/dd/yyyy',
    inputFormat: 'MM/dd/yyyy',
  };

  ngOnInit() {
    super.ngOnInit();
    if (this.config.options?.showTimePicker) {
      this.dateFormat.displayFormat = 'MM/dd/yyyy - h:mm a';
      this.dateFormat.inputFormat = 'MM/dd/yyyy - h:mm a';
    }
  }

  onDateChanged(date) {
    this.dynamicService.log.debug('onDateChanged:', date);
    if (date) {
      this.updateValue(date);
    }
  }

  onOpen() {
    if (!this.group.get(this.config.formControlName).value) {
      // when date is not yet set, default to right now when opening
      this.updateValue(new Date());
    }
  }
}
