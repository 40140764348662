import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { MyHttpClientFactory, environment } from '@ups/xplat/core';
import { parseData } from '@ups/xplat/utils';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AbsorbEnrollmentDto } from './../models';

const GET_USER = '/api/absorb/user/{:email}';
const GET_DEPARTMENT = '/api/absorb/department/{:id}';
const GET_COURSES = '/api/absorb/courses';
const GET_USER_ENROLLMENTS = '/api/absorb/user/{:id}/enrollments';
const GET_CRAFT_TESTING_DEPARTMENTS = '/api/absorb/departments/craft-testing';

@Injectable({
  providedIn: 'root',
})
export class EmployeeAbsorbService {
  private http: HttpClient;

  constructor(httpClientFactory: MyHttpClientFactory) {
    this.http = httpClientFactory.createHttpClient(
      environment.urls.spartaAPI,
      true
    );
  }

  public getUser(email: string): any {
    const url = GET_USER.split('{:email}').join(email);
    return this.http.get(url).pipe(map(parseData));
  }

  public getDepartment(id: string): any {
    const url = GET_DEPARTMENT.split('{:id}').join(id);
    return this.http.get(url).pipe(map(parseData));
  }

  public getUserEnrollments(id: string): Observable<AbsorbEnrollmentDto[]> {
    const url = GET_USER_ENROLLMENTS.split('{:id}').join(id);
    return this.http.get(url) as Observable<AbsorbEnrollmentDto[]>;
  }
}
