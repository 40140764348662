import { IInventoryCounter } from '@ups/tool-tracker';
import { SubLocationEntry } from './SubLocationEntry';

export class WarehouseInventoryAdmin {
  inventoryId: number;
  inventoryCo: number;
  inventoryName: string;
  inventoryStatusName: string;
  inventoryLocation: number;
  inventoryStatusId: number;
  nextInventoryStatusId: number;
  previousInventoryStatusId: number;
  countStartDate: Date;
  lastUpdated: Date;
  percentComplete: number;
  itemsLeft: number;
  verificationPercent: number;
  totalDollarVariance: number;
  totalNegativeVariance: number;
  performedBy: IInventoryCounter[];
  subLocationEntries: SubLocationEntry[];
}
