/* eslint-disable @typescript-eslint/naming-convention */
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { zipCodeRegex } from './zip-code-regex';

export const ValidatorFns: ValidatorFn[] = [];

export const PhoneRegex = /([0-9]{3}).*([0-9]{3}).*([0-9]{4})/;

export class CustomValidators {
  public static isPatternMatched(regex: RegExp, controlValue): boolean {
    return regex.test(controlValue);
  }

  public static zipcodeValidator(countrycode: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: unknown } => {
      const regex = new RegExp(zipCodeRegex[countrycode]?.expr);
      return control.value && !this.isPatternMatched(regex, control.value)
        ? { zipcode: zipCodeRegex[countrycode] }
        : null;
    };
  }
}
