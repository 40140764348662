import { createAction, props } from '@ngrx/store';
import { IEmployeeData } from '@ups/xplat/api/dto';
import { IAddressChangeRequest, IVacationAndSickBalance } from '../models';
import { UserState } from './user.state';

// TODO: Remove all the anys in this file

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace UserActions {
  export const resetState = createAction('[@ups/user] reset state');

  export const loadCurrent = createAction(
    '[@ups/user] load current',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (payload: any = {}) => payload
  );

  export const loadCurrentSuccess = createAction(
    '[@ups/user] load current success',
    props<{ payload?: UserState.IState['current'] }>()
  );

  export const loadCurrentFailed = createAction(
    '[@ups/user] load current failed',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (payload: any = {}) => payload
  );

  export const update = createAction(
    '[@ups/user] update',
    (payload: { data: IEmployeeData }) => payload
  );

  export const loadRoles = createAction(
    '[@ups/user] load roles',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (payload: any = {}) => payload
  );

  export const loadRolesSuccess = createAction(
    '[@ups/user] load roles success',
    props<{ payload?: UserState.IState['roles'] }>()
  );

  export const loadRolesFailed = createAction(
    '[@ups/user] load roles failed',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (payload: any = {}) => payload
  );

  export const loadVacationAndSickBalance = createAction(
    '[@ups/user] load vacation and sick balance',
    (payload: { hrRef: number }) => payload
  );

  export const loadVacationAndSickBalanceSuccess = createAction(
    '[@ups/user] load vacation and sick balance success',
    (payload: { vacationAndSickBalance: IVacationAndSickBalance }) => payload
  );

  export const loadVacationAndSickBalanceFailed = createAction(
    '[@ups/user] load vacation and sick balance failed',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (payload: any) => payload
  );

  export const updateSuccess = createAction(
    '[@ups/user] update success',
    (payload: { data: IEmployeeData }) => payload
  );

  export const updateFailed = createAction(
    '[@ups/user] update failed',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (payload: any = {}) => payload
  );

  export const checkAddressChangeRequest = createAction(
    '[@ups/user] check address change request',
    (payload: { hRRef: number }) => payload
  );

  export const checkAddressChangeRequestSuccess = createAction(
    '[@ups/user] check address change request success',
    (payload: { data: IAddressChangeRequest }) => payload
  );

  export const checkAddressChangeRequestFailed = createAction(
    '[@ups/user] check address change request failed',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (payload: any = {}) => payload
  );

  export const submitAddressChangeRequest = createAction(
    '[@ups/user] sumbmit address change request',
    (payload: IAddressChangeRequest) => payload
  );

  export const submitAddressChangeSuccess = createAction(
    '[@ups/user] sumbmit address change request success',
    props<{ payload?: IAddressChangeRequest }>()
  );

  export const submitAddressChangeFailed = createAction(
    '[@ups/user] sumbmit address change request failed',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (payload: any = {}) => payload
  );

  export const loadAttachments = createAction(
    '[@ups/user] load attachments dispatched',
    (payload: { hRRef: number }) => payload
  );

  export const loadAttachmentsSuccess = createAction(
    '[@ups/user] load attachments success',
    props<{ payload?: UserState.IState['attachments'] }>()
  );

  export const loadAttachmentsFailed = createAction(
    '[@ups/user] load attachments failed',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (payload: any) => payload
  );

  export const loadEContacts = createAction(
    '[@ups/user] load emergency contacts dispatched',
    (payload: { hRRef: number }) => payload
  );

  export const loadEContactsSuccess = createAction(
    '[@ups/user] load emergency contacts success',
    props<{ payload?: UserState.IState['emergencyContacts'] }>()
  );

  export const loadEContactsFailed = createAction(
    '[@ups/user] load emergency contacts failed',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (payload: any) => payload
  );

  export const updateEContact = createAction(
    '[@ups/user] update emergency contact dispatched',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (payload: { contact: any }) => payload
  );

  export const updateEContactSuccess = createAction(
    '[@ups/user] update emergency contact success'
  );

  export const updateEContactFailed = createAction(
    '[@ups/user] update emergency contact failed',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (payload: any) => payload
  );

  export const loadMulticlass = createAction(
    '[@ups/user] load multiclass data',
    (payload: { hRRef: number }) => payload
  );

  export const loadMulticlassSuccess = createAction(
    '[@ups/user] load multiclass data success',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (payload: { payload: any[] }) => payload
  );

  export const loadMulticlassFailed = createAction(
    '[@ups/user] load multiclass data failed',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (payload: any) => payload
  );

  export const loadDefaultPage = createAction('[@ups/user] load default page');

  export const loadedDefaultPage = createAction(
    '[@ups/user] loaded default page',
    (payload: { path: string }) => payload
  );

  export const setDefaultPage = createAction(
    '[@ups/user] set default page',
    (payload: { path: string }) => payload
  );

  export const clearDefaultPage = createAction(
    '[@ups/user] clear default page'
  );

  export const getLoggedInSecurityUserId = createAction(
    '[@ups/user] get logged in security user id'
  );

  export const setLoggedInSecurityUserId = createAction(
    '[@ups/user] set logged in security user id',
    (payload: { id: string }) => payload
  );
}
