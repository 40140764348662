<form [formGroup]="f">
  <label class="d-block k-label">
    Element (right click to select)
    <help-element-picker
      class="w-100"
      formControlName="elementControl"
    ></help-element-picker>
  </label>
  <label class="d-block k-label">
    Title
    <input class="w-100 k-textbox" formControlName="titleControl" />
  </label>
  <label class="d-block k-label">
    Content
    <kendo-editor formControlName="bodyControl"></kendo-editor>
  </label>
  <div class="d-flex justify-content-end">
    <button
      kendoButton
      [primary]="true"
      [disabled]="!f.valid"
      (click)="complete.emit()"
    >
      Complete
    </button>
  </div>
</form>
