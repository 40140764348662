import { BaseModel } from '../../base.model';

export class EquipmentPlantJobRequirementDto extends BaseModel {
  public EquipmentPlantJobRequirementID: string;
  public EquipmentPlantRequirementID: string;
  public EquipmentJobRequirementID: string;
  public EquipmentTypeID: string;
  public EquipmentRequirementID: string;
  public bBillable: boolean;
  public bWaiver: boolean;
  public WaiverDays: number;
  public bRequired: boolean;
  public bActive: boolean;
  public EquipmentRequirementName: string;

  public SubRequirements: any[];

  constructor(o: any = null) {
    super(o);
  }
}
