import { Inject, Injectable } from '@angular/core';
import {
  CustomerNameDto,
  DropDownItem,
  HelpDeskDTO,
  IODataResult,
  VendorName,
} from '@ups/xplat/api/dto';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import { parseData } from '@ups/xplat/utils';

const GET_ID_DOCUMENTS_BY_HRREF: string = '/api/employee/{:HRREF}/iddocuments';
const GET_AR_CUSTOMERS: string = '/api/arcm/customernames';
const SEND_HELPDESK: string = '/api/sendHelpDeskEmail';
const GET_CUSTOMER_NAMES_ODATA: string = '/api/arcm/customernames-odata/{:ID}';
const GET_VENDOR_NAMES_ODATA: string = '/api/apvm/vendornames-odata/{:ID}';
const GET_DROPDOWN_ITEMS_ODATA: string = 'api/dropdown-odata/{:combotype}';
const GET_DROPDOWN_ITEMS_BY_COLUMN_NAME_ODATA: string =
  'api/dropdown-by-column/{:viewname}/{:columnname}';

@Injectable({ providedIn: 'root' })
export class VpService {
  http: HttpClient;
  httpPascalCase: HttpClient;

  constructor(private clientFactory: MyHttpClientFactory) {
    const urlBase = environment.urls.viewpointAPI;
    this.httpPascalCase = this.clientFactory.createHttpClient(
      urlBase,
      true,
      ResponseCasingEnum.PascalCase
    ); //
    this.http = this.clientFactory.createHttpClient(
      urlBase,
      true,
      ResponseCasingEnum.CamelCase
    ); //
  }

  public getIdDocuments(hrref: number): any {
    var url = GET_ID_DOCUMENTS_BY_HRREF.split('{:HRREF}').join(
      hrref.toString()
    );
    return this.http.get(url).toPromise().then(parseData);
  }

  public getARCustomers(): Promise<CustomerNameDto[]> {
    const url = GET_AR_CUSTOMERS;
    return this.httpPascalCase.get(url).toPromise().then(parseData);
  }

  public queryCustomerNamesByGroupId(
    customerGroup: number,
    oDataQuery: string
  ): Promise<IODataResult<CustomerNameDto[]>> {
    const url =
      GET_CUSTOMER_NAMES_ODATA.split('{:ID}').join(customerGroup.toString()) +
      '?$format=json&' +
      oDataQuery;

    return this.http.get(url).toPromise().then(parseData);
  }

  public queryVendorNamesByGroupId(
    vendorGroup: number,
    oDataQuery: string
  ): Promise<IODataResult<VendorName[]>> {
    const url =
      GET_VENDOR_NAMES_ODATA.split('{:ID}').join(vendorGroup.toString()) +
      '?$format=json&' +
      oDataQuery;

    return this.http.get(url).toPromise().then(parseData);
  }

  public submitHelpdeskTicket(helpdesk: HelpDeskDTO): Promise<any> {
    var url = SEND_HELPDESK;
    return this.http.post(url, helpdesk).toPromise();
  }

  public testErrorMessage() {
    return this.http.post('/errorTesting', '').toPromise();
  }

  public getDropdownItems(
    comboType: string,
    oDataQuery: string = null
  ): Observable<IODataResult<DropDownItem>> {
    let url = GET_DROPDOWN_ITEMS_ODATA.split('{:combotype}').join(comboType);

    if (oDataQuery) {
      url = url + '?$format=json&' + oDataQuery;
    }

    return this.http.get(url) as Observable<IODataResult<DropDownItem>>;
  }

  public getDropdownItemsByColumnName(
    viewName: string,
    columnName: string,
    oDataQuery: string = null
  ): Observable<IODataResult<DropDownItem[]>> {
    let url = GET_DROPDOWN_ITEMS_BY_COLUMN_NAME_ODATA.split('{:viewname}')
      .join(viewName)
      .split('{:columnname}')
      .join(columnName);

    if (oDataQuery) {
      url = url + '?$format=json&' + oDataQuery;
    }

    return this.http.get(url) as Observable<IODataResult<DropDownItem[]>>;
  }
}
