import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  EmployeeExpertiseDto,
  EmployeeToEvaluateDto,
  ExpertiseDto,
  ExpertiseTypeEnum,
  IODataResult,
  ManPowerReqDetailExpertiseDto,
  PerformanceEvaluationDto,
} from '@ups/xplat/api/dto';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmployeeExpertiseService {
  protected http: HttpClient;

  datePipe: DatePipe = new DatePipe('en-US');

  constructor(private clientFactory: MyHttpClientFactory) {
    const urlBase = environment.urls.spartaAPI;
    this.http = this.clientFactory.createHttpClient(
      urlBase,
      true,
      ResponseCasingEnum.Default
    );
  }

  public getExpertises(
    expertiseTypeId: ExpertiseTypeEnum | null,
    vpCompanyIdsOrFilter: number[] | null = null,
    oDataString: string | null = null
  ): Observable<IODataResult<ExpertiseDto>> {
    let url = '/api/expertise-odata?';

    const queryParameters: string[] = [];

    if (vpCompanyIdsOrFilter && vpCompanyIdsOrFilter.length > 0) {
      vpCompanyIdsOrFilter.forEach((vpId) => {
        queryParameters.push('orVpCompanyId=' + vpId);
      });
    }

    if (expertiseTypeId != null && expertiseTypeId != ExpertiseTypeEnum.All) {
      queryParameters.push('expertiseTypeId=' + expertiseTypeId);
    }

    queryParameters.push('$count=true');
    queryParameters.push('$format=json');

    if (oDataString) {
      queryParameters.push(oDataString);
    }

    url = url + queryParameters.join('&');

    return this.http.get(url) as Observable<IODataResult<ExpertiseDto>>;
  }

  public saveExpertise(expertiseDto: ExpertiseDto): Observable<ExpertiseDto> {
    const url = '/api/expertise';

    return this.http.post(url, expertiseDto) as Observable<ExpertiseDto>;
  }

  /**
   * Either employeeId or hrRef need to be specified. If Employeeid is specified, backend will find the related HrRef and will return results for the HrRef that matches the EmployeeId
   */
  public getEmployeeExpertises(
    employeeId: string | null /*Guid*/,
    hrRef: number | null,
    expertiseVpCompanyIdOrFilter: number[] | null = null
  ): Observable<EmployeeExpertiseDto[]> {
    let url = '/api/expertise/employee?';

    const queryParameters: string[] = [];

    if (
      expertiseVpCompanyIdOrFilter &&
      expertiseVpCompanyIdOrFilter.length > 0
    ) {
      expertiseVpCompanyIdOrFilter.forEach((vpId) => {
        queryParameters.push('expertiseVpCompanyIdOrFilter=' + vpId);
      });
    }

    if (employeeId != null) {
      queryParameters.push('employeeId=' + employeeId);
    }

    if (hrRef != null) {
      queryParameters.push('hrRef=' + hrRef);
    }

    url = url + queryParameters.join('&');

    return this.http.get(url) as Observable<EmployeeExpertiseDto[]>;
  }

  public saveEmployeeExpertises(
    expertiseDtos: EmployeeExpertiseDto[]
  ): Observable<EmployeeExpertiseDto[]> {
    const url = '/api/expertise/employee';

    return this.http.post(url, expertiseDtos) as Observable<
      EmployeeExpertiseDto[]
    >;
  }

  public getEmployeeExpertiseHistory(
    employeeExpertiseId: string /*Guid*/
  ): Observable<EmployeeExpertiseDto[]> {
    const url =
      '/api/expertise/history/employee?employeeExpertiseId=' +
      employeeExpertiseId;

    return this.http.get(url) as Observable<EmployeeExpertiseDto[]>;
  }

  public getManPowerReqDetailExpertises(
    manPowerReqDetailId: string
  ): Observable<ManPowerReqDetailExpertiseDto[]> {
    const url =
      '/api/expertise/manpowerreqdetail?manPowerReqDetailId=' +
      manPowerReqDetailId;
    return this.http.get(url) as Observable<ManPowerReqDetailExpertiseDto[]>;
  }

  public savePerformanceEvaluation(
    performanceEvaluation: PerformanceEvaluationDto
  ): Observable<PerformanceEvaluationDto> {
    const url = '/api/expertise/performanceevaluation';
    return this.http.post(
      url,
      performanceEvaluation
    ) as Observable<PerformanceEvaluationDto>;
  }

  /**
   * Gets Performance Evaluation pending for currently logged in user - for example a supervisor
   * It also returns results where the performance evaluation was already filled. You can find those results when
   * EmployeeToEvaluateDto.PerformanceEvaluationId and PerformanceEvaluationDate are not null
   * */
  public getPendingPerformanceEvaluations(
    oDataQuery: string | null
  ): Observable<IODataResult<EmployeeToEvaluateDto>> {
    let url = '/api/expertise/performanceevaluation/pending-odata?';

    const queryParameters: string[] = [];

    queryParameters.push('$count=true');
    queryParameters.push('$format=json');

    if (oDataQuery) {
      queryParameters.push(oDataQuery);
    }

    url = url + queryParameters.join('&');

    return this.http.get(url) as Observable<
      IODataResult<EmployeeToEvaluateDto>
    >;
  }

  public getPerformanceEvaluations(
    employeeId: string | null,
    performanceEvaluationId: string | null,
    oDataQuery: string | null
  ): Observable<IODataResult<PerformanceEvaluationDto>> {
    let url = '/api/expertise/performanceevaluation-odata?';

    const queryParameters: string[] = [];

    if (employeeId) {
      queryParameters.push('employeeId=' + employeeId);
    }

    if (performanceEvaluationId) {
      queryParameters.push(
        'performanceEvaluationId=' + performanceEvaluationId
      );
    }

    queryParameters.push('$count=true');
    queryParameters.push('$format=json');

    if (oDataQuery) {
      queryParameters.push(oDataQuery);
    }

    url = url + queryParameters.join('&');

    return this.http.get(url) as Observable<
      IODataResult<PerformanceEvaluationDto>
    >;
  }
}
