export interface SecurityObject {
  data: Datum[];
  hasError: boolean;
  message: null;
}

export interface Datum {
  featureGroupName: string;
  features: Feature[];
}

export interface Feature {
  permissionId: null;
  featureId: string;
  featureName: string;
  uniqFeatureName: string;
  viewFeature: boolean;
  readAll: boolean;
  editAll: boolean;
  approvalNeeded: boolean;
  containsSelf: boolean;
  readSelf: boolean;
  editSelf: boolean;
  approvalNeededSelf: boolean;
  company: boolean;
  job: boolean;
}

export enum PermissionType {
  viewFeature = 'viewFeature',
  readAll = 'readAll',
  editAll = 'editAll',
  approvalNeeded = 'approvalNeeded',
  containsSelf = 'containsSelf',
  readSelf = 'readSelf',
  editSelf = 'editSelf',
  approvalNeededSelf = 'approvalNeededSelf',
  company = 'company',
  job = 'job',
}

export class PermissionSetting {
  uniqSecurityName: string;
  permissionTypes: PermissionType[];

  constructor(secName: string, pt: PermissionType[]) {
    this.uniqSecurityName = secName;
    this.permissionTypes = pt;
  }
}

export class PageSecurityTest {
  pageTitle: string;
  menuOption: string;
  subMenuOption: string;
  permssions: PermissionSetting[];
}
