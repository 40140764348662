/* eslint-disable @typescript-eslint/no-namespace */
import { Action, createAction } from '@ngrx/store';
import { AppRouterState } from './router.state';

/* tslint:disable:max-classes-per-file */
export namespace RouterActions {
  export enum Types {
    GO = '[@ups/router] Go',
    BACK = '[@ups/router] Back',
    FORWARD = '[@ups/router] Forward',
    INITIALIZE = '[@ups/router] init',
  }

  export class Go implements Action {
    readonly type = Types.GO;

    constructor(
      public payload: {
        path: Array<any>;
        extras?: any;
      }
    ) {}
  }

  export class Back implements Action {
    readonly type = Types.BACK;
  }

  export class Forward implements Action {
    readonly type = Types.FORWARD;
  }

  export const Initialize = createAction(
    '[@ups/router] init',
    (payload: { local: Partial<AppRouterState.IState['custom']> }) => payload
  );

  export type Actions = Go | Back | Forward;
}
