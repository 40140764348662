import { Injectable } from '@angular/core';
import {
  ExportService,
  IExportComponent,
  IExportService,
  LogService,
} from '@ups/xplat/core';

@Injectable()
export class WebExportService extends ExportService implements IExportService {
  constructor(protected log: LogService) {
    super();
  }

  exportAndDownload(
    filename: string,
    type: 'excel',
    config?: { group?: unknown; data?: unknown },
    component?: IExportComponent
  ) {
    switch (type) {
      case 'excel':
        component.fileName = filename;
        component.group = config?.group;
        component.data = config?.data;
        const currentWorkbookOptions = component.workbookOptions();
        currentWorkbookOptions.sheets[0].rows =
          currentWorkbookOptions.sheets[0].rows.filter((row) => {
            if (
              row.type === 'group-header' &&
              !row.cells[0]?.value &&
              !row.cells[1]?.value
            ) {
              return false;
            }

            return true;
          });
        currentWorkbookOptions.sheets[0].rows.forEach((row) => {
          row.cells.forEach((cell) => {
            cell.textAlign = 'left';
          });
        });
        component.save(currentWorkbookOptions);

        break;
    }
  }
}
