/**
 * Used to access various core services without having to inject them
 * Only allow @ups/xplat/core services here
 * Helpful when wanting to avoid modifying constructor signatures
 * for example, instead of injecting multiple services, you can do this:
 *   import { StaticService } from '@ups/xplat/core';
 *   StaticService.progress.toggleSpinner(true);
 */
import { Injectable, inject } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { IPlatformDeviceInfo, PlatformDeviceInfoToken } from './tokens';
import { WindowService } from './window.service';
import { ProgressService } from './progress.service';
import { LogService } from './log.service';
import { EventBusService } from './event-bus.service';

@Injectable({
  providedIn: 'root',
})
export class StaticService {
  static translateService: TranslateService;
  static platformDeviceInfo: IPlatformDeviceInfo;
  static log: LogService;
  static win: WindowService;
  static progress: ProgressService;
  static eventBus: EventBusService;
  static actions$: Actions;

  constructor() {
    StaticService.translateService = inject(TranslateService);
    StaticService.platformDeviceInfo = inject<IPlatformDeviceInfo>(
      PlatformDeviceInfoToken
    );
    StaticService.log = inject(LogService);
    StaticService.win = inject(WindowService);
    StaticService.progress = inject(ProgressService);
    StaticService.eventBus = inject(EventBusService);
    StaticService.actions$ = inject(Actions);
  }
}
