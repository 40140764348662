export class EquipmentRequestCreateUpdateDto {
  copies: number;
  equipmentRequestDetailId: string;
  equipmentTypeId: string;

  supervisor: string;
  supervisorId: string;

  jobId: string;
  startDate: Date;
  endDate: Date;
  equipmentDispatchStatusId: string;
  notes: string;
  previousNotes: string;

  isBillable: string;
  phaseId: string;
  phaseName: string;
  poNumber: string;
  timeNeeded: string;
}
