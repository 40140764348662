import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  Provider,
  SkipSelf,
} from '@angular/core';
import {
  HttpForceCamelCaseInterceptor,
  HttpForcePascalCaseInterceptor,
  HttpUPSInterceptor,
} from '@ups/xplat/core';
import { throwIfAlreadyLoaded } from '@ups/xplat/utils';

import { AuthGuard } from './services';

@NgModule()
export class AuthModule {
  static forRoot(
    configuredProviders: Array<Provider>
  ): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthGuard,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpUPSInterceptor,
          multi: true,
        },
        HttpForceCamelCaseInterceptor,
        HttpForcePascalCaseInterceptor,
        ...configuredProviders,
      ],
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: AuthModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'AuthModule');
  }
}
