import {
  Component,
  Input,
  Injector,
  ElementRef,
  OnChanges,
} from '@angular/core';

import {
  GridDataResult,
  DataStateChangeEvent,
} from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';

import { EquipmentService } from '@ups/xplat/api/services';
import { EquipmentJobHistoryDto } from '@ups/xplat/api/dto';
import { BaseComponent } from '@ups/xplat/core';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';

@Component({
  selector: 'ups-equipment-job-history',
  templateUrl: './equipment-job-history.component.html',
  // styleUrls: ['./equipment-job-history.component.scss'],
})
export class EquipmentJobHistoryComponent
  extends BaseComponent
  implements OnChanges
{
  @Input() vpCompanyID: string;
  @Input() equipmentName: string;

  jobHistory: EquipmentJobHistoryDto[] = [];
  public gridData: GridDataResult;
  public state: State = {
    skip: 0,
    take: 10,
  };
  isLoading: boolean = false;
  hasReadPermissions: boolean = false;

  constructor(
    private security: SecurityService,
    private equipmentService: EquipmentService
  ) {
    super();
    this.hasReadPermissions = this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_jobhistorytab
    ).readAll;
  }

  ngOnChanges() {
    this.jobHistory = [];
    this.gridData = process(this.jobHistory, this.state);
    console.log('ON CHANGES', this.gridData);
    this.loadJobHistory();
  }

  loadJobHistory() {
    const self = this;
    if (this.vpCompanyID && this.equipmentName && this.hasReadPermissions) {
      self.isLoading = true;
      this.equipmentService
        .getEquipmentJobHistory(this.vpCompanyID, this.equipmentName)
        .subscribe((d: EquipmentJobHistoryDto[]) => {
          self.jobHistory = d;
          self.gridData = process(self.jobHistory, self.state);
          console.log('LOAD JOB HISTORY', this.gridData);
          self.isLoading = false;
        });
    }
  }

  dataStateChange(state: DataStateChangeEvent): any {
    this.state = state;
    this.gridData = process(this.jobHistory, this.state);
  }
}
