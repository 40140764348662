import { CommonDateFunctions } from '@ups/xplat/utils';

export class PerDiemSpartaSideUpdate {
  FileID?: string;
  Name: string;
  Directory: string;
  ContentType: string;
  Description: string;
  bActive: boolean;
  UserID: string;
  AttachmentID: number;
  ServiceDate?: Date;
  ExpirationDate?: Date;
  Notes: string;
  DocType: string;
  KeyId: number;

  // This is to fix the problem where Date gets translated to UTC during original JSON.stringify(). This method overrides the dates and translates them to string so no conversion as in some timezones the date can change as well when translating to UTC
  toJSON() {
    return {
      FileID: this.FileID,
      Name: this.Name,
      Directory: this.Directory,
      ContentType: this.ContentType,
      Description: this.Description,
      bActive: this.bActive,
      UserID: this.UserID,
      AttachmentID: this.AttachmentID,
      ServiceDate: CommonDateFunctions.ToDateString(this.ServiceDate),
      ExpirationDate: CommonDateFunctions.ToDateString(this.ExpirationDate),
      Notes: this.Notes,
      DocType: this.DocType,
      KeyId: this.KeyId,
    };
  }
}
