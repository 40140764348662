import { Injectable } from '@angular/core';
import { ApplicationModel } from '@ups/xplat/api/dto';
import { ManPowerService } from '@ups/xplat/base/manpower';

@Injectable({ providedIn: 'root' })
export class WorkgroupResolver {
  isLoading: boolean = false;

  constructor(
    private manPowerService: ManPowerService,
    private applicationModel: ApplicationModel
  ) {}

  public load(): Promise<any> {
    const self = this;
    if (this.applicationModel.workgroups.length === 0 && !this.isLoading) {
      self.isLoading = true;
      return this.manPowerService.fetchWorkgroups().then(function (data) {
        self.applicationModel.workgroups = data;
        self.isLoading = false;
      });
    } else {
      return Promise.resolve(this.applicationModel.workgroups);
    }
  }
}
