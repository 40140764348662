import { Inject, Injectable } from '@angular/core';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
  LocalStorageService,
} from '@ups/xplat/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs';
import { ProfileModel } from '@ups/xplat/api/dto';

const FAVORITE_JOB = '/api/jobs/favorite';

@Injectable({
  providedIn: 'root',
})
export class SearchJobVPService {
  private http: HttpClient;
  private spartaHttp: HttpClient;
  private profile: ProfileModel;

  constructor(
    private clientFactory: MyHttpClientFactory,
    private storage: LocalStorageService
  ) {
    this.http = this.clientFactory.createHttpClient(
      environment.urls.viewpointAPI,
      true,
      ResponseCasingEnum.PascalCase
    );
    this.spartaHttp = this.clientFactory.createHttpClient(
      environment.urls.spartaAPI,
      true,
      ResponseCasingEnum.PascalCase
    );
    this.profile = this.storage.getItem('profile') as ProfileModel;
  }

  search(searchString: string, params: HttpParams = null, limit: number = 20) {
    params = params || new HttpParams();
    params = params.append('searchString', searchString);
    params = params.append('take', limit);
    return this.http
      .get(`/api/jobs/dropdown`, {
        params,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  public addOrRemoveFavoriteJob(vpJobID: string, auth0UserId?: string) {
    const url = FAVORITE_JOB;
    return this.spartaHttp
      .post(url, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        UserID: auth0UserId ? auth0UserId : this.profile.auth0UserId,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        VPJobID: vpJobID,
      })
      .toPromise();
  }
}
