import { BaseModel } from '../base.model';

export class EmployeeAroundPlantDto extends BaseModel {
  public distanceInMiles: number;
  public employeeDesignationNames: string;
  public employeeFirstName: string;
  public employeeLastName: string;
  public employeeHRefs: string;
  public employeeLat: string;
  public employeeLng: string;
  public employeeId: string;
  public lastDateWorked: Date;
  public phyCity: string;
  public phyState: string;
  public workedSinceDate: Date;
  public employeeData: string;
  public employeeCellPhone: string;

  constructor(o: any = null) {
    super(o);
  }
}
