/* eslint-disable @typescript-eslint/naming-convention */
import { LaborDto } from './LaborDto';

export class TimekeepingDto {
  public AbsentReason: string;
  public AbsentReasonID: string;
  public AbsentReasonsByDate: unknown[];
  public Class: string;
  public Craft: string;
  public DateWorked: Date;
  public EmployeeCompanyID: number;
  public EmployeeID: string;
  public EmployeeJobID: string;
  public EmployeeVPID: string;
  public FirstName: string;
  public HasPerDiem: boolean;
  public JobID: string;
  public Labors: LaborDto[];
  public Last4SSN: string;
  public LastName: string;
  public Mileage: number;
  public MileageType: number;
  public Notes: string;
  public OTFactor: number;
  public PTFactor: number;
  public PayPerDiem: number;
  public PayRate: number;
  public PerDiem: number;
  public Shift: number;
  public ShiftDiffAmount: number;
  public VPJobID: string;
  public WorkOrder: string;
  public CustomerPO: string;
  public bActiveState: boolean;
  public bDispatchActive: boolean;
  public bDispatchSigned: boolean;
  public isNoteDirty: boolean;
  public EmployeeNotes: string;
  public EmployeeNotesDateModified: string;
  public EmployeeNotesModifiedBy: string;
  public IsBillableWorkOrder: boolean;
  public IsContractActiveWorkOrder: boolean;
  public ShouldMoveOnsite: boolean;
  public LaborTemplate: string;

  // client side
  public isNew: boolean;
  public isToCombine: boolean;
  _selectionState: unknown;
}
