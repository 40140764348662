import {
  ApplicationRef,
  Component,
  ElementRef,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import {
  Margin,
  PopupComponent,
  PopupRef,
  PopupService,
} from '@progress/kendo-angular-popup';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';

import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import {
  HelpActions,
  HelpDocumentDto,
  HelpState,
  NgMutationObserver,
  WalkthroughDocumentStepDto,
} from '@ups/xplat/base/in-app-help';
import { BaseComponent } from '@ups/xplat/core';

@Component({
  selector: 'ups-walkthrough-player',
  templateUrl: './walkthrough.component.html',
})
export class WalkthroughPlayerComponent extends BaseComponent {
  @ViewChild('popupTemplate') popupTemplate: TemplateRef<null>;
  popup: PopupRef;
  backdrop: HTMLDivElement;
  show = false;

  walkthrough: HelpDocumentDto = null;
  step: WalkthroughDocumentStepDto;
  stepIdx: number;

  anchor: ElementRef<HTMLElement>;
  margin: Margin = {
    horizontal: 5,
    vertical: 15,
  };
  showNext: boolean;

  intersectionObserver: IntersectionObserver;

  get final(): boolean {
    return this.walkthrough.steps.length === this.stepIdx + 1;
  }

  public securityConsts = SecurityConstants;

  private highlightedElement: ExHTMLElement;

  constructor(
    private store: Store,
    public security: SecurityService,
    actions$: Actions,
    private popupService: PopupService,
    private applicationRef: ApplicationRef
  ) {
    super();

    // this.setupBackdrop();
    store
      .select(HelpState.selectCurrentWalkthrough)
      .pipe(takeUntil(this.destroy$))
      .subscribe((x) => {
        if (x && x.id !== this.walkthrough?.id) {
          this.show = true;
        }
        this.walkthrough = x;
      });

    store
      .select(HelpState.selectCurrentWalkthroughStep)
      .pipe(
        takeUntil(this.destroy$),
        filter(() => this.walkthrough !== null) // only fire if a walkthrough is loaded
      )
      .subscribe((x) => {
        this.stepIdx = x;
        if (x !== undefined && x !== null) {
          this.step = this.walkthrough.steps[x];
          this.highlightStep(x);
        } else if (this.highlightedElement) {
          this.clearHighlight();
        }
      });

    this.intersectionObserver = new IntersectionObserver(
      this.domChangeListener,
      { threshold: [0, 0.5, 1] }
    );

    actions$
      .pipe(takeUntil(this.destroy$), ofType(HelpActions.resumeWalkthrough))
      .subscribe(() => (this.show = !!this.walkthrough && !!this.step));
  }

  highlightStep(idx) {
    if (this.highlightedElement) {
      this.clearHighlight();
    }
    const path = this.walkthrough.steps[idx].path;
    console.log('Looking for path', path);
    const el = document.querySelector<ExHTMLElement>(`[help-path="${path}"]`);
    this.anchor = new ElementRef(el);
    this.highlightedElement = el;
    if (el.scrollIntoViewIfNeeded) el.scrollIntoViewIfNeeded(true);
    else el.scrollIntoView();
    document.addEventListener('scroll', this.lockScrollToTarget, {
      capture: true,
    });
    if (el.tagName === 'BUTTON') {
      this.showNext = false;
      el.addEventListener('click', this.buttonClickListener);
    } else {
      this.showNext = true;
    }

    this.intersectionObserver.observe(el);

    // this.backdrop.style.setProperty("display", "block");

    const cs = getComputedStyle(el);

    el.oldTransform = cs.transform === 'none' ? '' : cs.transform;
    const newTransform =
      el.oldTransform.indexOf('translateZ') !== -1
        ? el.oldTransform.replace(/translateZ(.*?)/, 'translateZ(10px)')
        : el.oldTransform + 'translateZ(10px)';

    el.style.setProperty('transform', newTransform);

    el.oldBackground = cs.backgroundColor;
    el.oldFilter = cs.filter;
    el.style.setProperty(
      'filter',
      'drop-shadow(yellow 0px 0px 4px) drop-shadow(yellow 0px 0px 4px)'
    );

    this.popup = this.popupService.open({
      content: this.popupTemplate,
      anchor: this.anchor,
      appendTo:
        this.applicationRef.components[0].injector.get(ViewContainerRef),
    });
  }

  clearHighlight() {
    const x = this.highlightedElement;
    if (x) {
      this.intersectionObserver.disconnect();
      x.style.setProperty('transform', x.oldTransform);
      x.style.setProperty('background-color', x.oldBackground);
      x.style.setProperty('filter', x.oldFilter);
      x.removeEventListener('click', this.buttonClickListener);
      this.highlightedElement = null;
      document.removeEventListener('scroll', this.lockScrollToTarget);
      // this.backdrop.style.setProperty("display", "none");
    }
    this.popup?.close?.();
  }

  start() {
    this.store.dispatch(HelpActions.goToWalkthroughStep({ step: 0 }));
  }

  increment() {
    if (this.final) {
      this.end();
    } else {
      this.store.dispatch(
        HelpActions.goToWalkthroughStep({ step: this.stepIdx + 1 })
      );
    }
  }

  decrement() {
    this.store.dispatch(
      HelpActions.goToWalkthroughStep({ step: this.stepIdx - 1 })
    );
  }

  end() {
    this.step = null;
    this.walkthrough = null;
    this.show = false;
    this.clearHighlight();
    this.store.dispatch(HelpActions.closeWalkthrough());
  }

  edit() {
    this.store.dispatch(
      HelpActions.toggleEditor({ state: true, document: this.walkthrough })
    );
    this.end();
  }

  setupBackdrop() {
    this.backdrop = document.createElement('div');
    this.backdrop.setAttribute('id', 'help-backdrop');
    document.body.appendChild(this.backdrop);
    this.backdrop.onclick = () => this.backdropClick();
  }

  backdropClick() {
    this.show = false;
  }

  popupPositioned(ev: {
    flip: { horizontal: boolean; vertical: boolean };
    offset: { left: number; top: number };
  }) {
    if (ev.flip.vertical) {
      document.querySelector('.k-help-popup').classList.add('k-popup-top');
      document
        .querySelector('.k-help-popup')
        .classList.remove('k-popup-bottom');
    } else {
      document.querySelector('.k-help-popup').classList.add('k-popup-bottom');
      document.querySelector('.k-help-popup').classList.remove('k-popup-top');
    }
  }

  buttonClickListener = () => {
    console.log('Click fired');
    this.increment();
  };

  domChangeListener = (entries: IntersectionObserverEntry[]) => {
    for (const record of entries) {
      if (record.intersectionRatio < 0.5) {
        if (record.target === this.highlightedElement) {
          console.log(
            '%c "Weesa In Trouble Now." - Jar Jar Binks',
            'font-size: large; font-weight: bold; color:red'
          );
          console.trace();
        }
      }
    }
  };

  lockScrollToTarget = () =>
    this.highlightedElement?.scrollIntoViewIfNeeded
      ? this.highlightedElement?.scrollIntoViewIfNeeded(true)
      : this.highlightedElement?.scrollIntoView?.();
}

type ExHTMLElement = HTMLElement & {
  oldTransform: string;
  oldBackground: string;
  oldFilter: string;

  // Note, this will not work if in firefox.
  // If using, please be sure to check if it exists first
  scrollIntoViewIfNeeded?: (center: boolean) => void;
};
