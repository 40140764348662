import { Component, Input } from '@angular/core';

import { filter, takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@ups/xplat/core';
import { EmployeeService } from '@ups/xplat/base/employee-profile';
import { UserState } from '@ups/user';

import { IEmployeeInfo } from '@ups/xplat/api/dto';
import { select, Store } from '@ngrx/store';

@Component({
  selector: 'ups-employee-header',
  templateUrl: 'employee-header.component.html',
  styles: [':host{ display: block }'],
})
export class EmployeeHeaderComponent extends BaseComponent {
  data: IEmployeeInfo['Data'];
  @Input() withPageTitle = false;
  @Input() pageTitle = '';

  constructor(public employeeInfo: EmployeeService, public store: Store<any>) {
    super();

    this.store
      .pipe(UserState.selectCurrentTruthy(this.destroy$))
      .subscribe((x) => {
        this.data = x.Data;
      });
  }
}
