import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DynamicTypeaheadCategoryWebBaseComponent } from '../base/dynamic-typeahead-category.web-base-component';

const SEARCH_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DynamicTypeaheadCategoryComponent),
  multi: true,
};

@Component({
  selector: 'ups-dynamic-typeahead-category',
  templateUrl: 'dynamic-typeahead-category.component.html',
  providers: [SEARCH_CONTROL_VALUE_ACCESSOR],
})
export class DynamicTypeaheadCategoryComponent extends DynamicTypeaheadCategoryWebBaseComponent {}
