import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MyHttpClientFactory, environment, LogService } from '@ups/xplat/core';
import { parseData } from '@ups/xplat/utils';

@Injectable({ providedIn: 'root' })
export class CommentService {
  private http: HttpClient;

  constructor(
    private httpFactory: MyHttpClientFactory,
    private log: LogService
  ) {
    this.http = this.httpFactory.createHttpClient(environment.urls.dynamicAPI);
  }

  postComment(dynamicContainerResponseId, comment, formUrl) {
    const url = '/api/Form/comments';
    /* eslint-disable */
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    /* eslint-enable */
    const options = { headers };
    return this.http.post(
      url,
      {
        dynamicContainerResponseId,
        commentsJson: JSON.stringify(comment),
        formUrl: formUrl,
      },
      options
    );
  }

  getComments(dynamicContainerResponseId) {
    const url = `/api/Form/comments/${dynamicContainerResponseId}`;
    /* eslint-disable */
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    /* eslint-enable */
    const options = { headers };
    return this.http.get(url, options);
  }

  public getAllFormRoles() {
    return this.http.get('/api/form/roles').toPromise().then(parseData);
  }
}
