import { Component } from '@angular/core';
import { DynamicItemBaseComponent } from '@ups/xplat/features';

@Component({
  selector: 'ups-dynamic-circle',
  styleUrls: ['dynamic-circle.component.scss'],
  template: `
    <div class="dynamic-field drawing-circle" [formGroup]="group">
      <div [innerHTML]="config?.value"></div>
    </div>
  `,
})
export class DynamicCircleComponent extends DynamicItemBaseComponent {}
