import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { SortableComponent } from '@progress/kendo-angular-sortable';

@Component({
  selector: 'ups-reorder-rows-modal',
  templateUrl: 'reorder-rows-modal.component.html',
})
export class ReorderRowsModalComponent {
  @ViewChild('sortableComponent') sortableComponent: SortableComponent;

  @Input() itemName = 'items';
  @Input() sortField = 'sortOrder';

  @Input() parentName: string;
  @Input() nestedPath: string;

  @Input() showArrows = true;

  @Input() doRegex = false;

  @Input() set reorderItems(reorderItems: unknown[]) {
    this._reorderItems = reorderItems;
    this.reorderDraggables = [
      ...new Set<string>(
        reorderItems.map((itemDetail) =>
          this.resolvePath(itemDetail, this.nestedPath)
        )
      ),
    ];
  }

  get reorderItems() {
    return this._reorderItems;
  }

  @Output() cancel: EventEmitter<unknown> = new EventEmitter();
  @Output() save: EventEmitter<unknown> = new EventEmitter();

  public reorderDraggables: string[];
  public _reorderItems;

  constructor() {}

  closeReorder(save: boolean) {
    let isStringType = false;

    if (save) {
      for (const itemDetail of this.reorderItems) {
        if (typeof itemDetail !== 'string') {
          const sortOrder = this.reorderDraggables.indexOf(
            this.resolvePath(itemDetail, this.nestedPath)
          );
          if (sortOrder !== itemDetail[this.sortField]) {
            itemDetail[this.sortField] = sortOrder;
          }
        } else {
          isStringType = true;
        }
      }

      if (isStringType) {
        this.reorderItems = [...this.reorderDraggables];
      }

      this.save.emit(this.reorderItems);
    }

    this.cancel.emit();
  }

  moveTo(from, to) {
    this.sortableComponent.moveItem(from, to);
  }

  private resolvePath = (object, path, defaultValue?) => {
    if (path) {
      const orderName = path
        .split('.')
        .reduce((o, p) => (o ? o[p] : defaultValue), object);

      return this.doRegex ? orderName.replace(/^\d+-/, '') : orderName;
    } else {
      return object;
    }
  };
}
