import { AfterViewInit, Directive } from '@angular/core';
import { IDynamicButtonGroup } from '@ups/xplat/core';
import { DynamicEventBusTypes } from '../../utils';
import { DynamicItemBaseComponent } from '../dynamic-item/dynamic-item.base-component';

@Directive()
export abstract class DynamicButtonGroupBaseComponent
  extends DynamicItemBaseComponent
  implements AfterViewInit
{
  selectButton(button: IDynamicButtonGroup) {
    if (this.config?.disabled || this.disableItem) {
      return;
    }

    // Toggle feature
    if (button.selected) {
      button.selected = false;

      if (!this.config.options.buttonGroupIgnoreState) {
        for (const b of this.config.options.buttongroup) {
          b.selected = false;
        }
        button.selected = false;
      }

      this.updateValue(null);

      if (this.config.required) this.setRequired();
    } else {
      if (!this.config.options.buttonGroupIgnoreState) {
        for (const b of this.config.options.buttongroup) {
          b.selected = false;
        }
        button.selected = true;
      }
      this.updateValue(button.value);
      // always when selecting a value, ensure any required status is removed
      this.setValidators(null);
    }

    if (this.config.options.emitEventOnChange) {
      this.dynamicService.eventBus.emit(DynamicEventBusTypes.dynamicDataUpdate);
    }
  }

  ngAfterViewInit() {
    if (this.config.value) {
      const bgItem = this.config.options.buttongroup.find(
        (b) => b.value === this.config.value
      );
      if (bgItem) {
        bgItem.selected = true;
      }
    }
  }
}
