import { HttpClient } from '@angular/common/http';
import { Directive } from '@angular/core';

import { Store } from '@ngrx/store';

import { UserActions, UserState } from '@ups/user';
import { BaseComponent } from '@ups/xplat/core';
import { IEmployeeInfo } from '@ups/xplat/api/dto';

import { EmployeeService } from '../services/employee.service';

@Directive()
export abstract class ProfileEmploymentBaseComponent extends BaseComponent {
  loading = true;
  chipsEmployment = [];
  chipsPosition = [];
  chipsBenefits = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  classifications: any[];
  classificationsListedText: string;
  protected httpClient: HttpClient;
  private _employmentFields = [
    {
      name: 'Active',
      field: 'ActiveYN',
    },
  ];
  private _positionFields = [
    {
      name: 'Core',
      field: 'Core',
    },
    {
      name: 'G40',
      field: 'G40',
    },
    {
      name: 'All Time Foreman',
      field: 'ATF',
    },
  ];
  private _benefitFields = [
    {
      name: 'Company MasterCard',
      field: 'CoMastercard',
    },
    {
      name: 'Fuel Card',
      field: 'FuelCard',
    },
    {
      name: 'Company Vehicle',
      field: 'CompanyVehicle',
    },
  ];

  constructor(public employeeService: EmployeeService, protected store: Store) {
    super();
  }

  ngOnInit() {
    this.store
      .pipe(UserState.selectCurrentTruthy(this.destroy$))
      .subscribe((x) => {
        this.data = x;
        this._populatePrivileges(x);
        this.loading = false;
        this.store.dispatch(
          UserActions.loadMulticlass({ hRRef: x.Data.HRRef })
        );
      });

    this.store
      .pipe(UserState.selectMulticlassTruthy(this.destroy$))
      .subscribe((x) => {
        console.log(x);
        this.classifications = x;
        this.classificationsListedText = this.classifications
          .map((c) => c.ClassName)
          .filter((c) => !!c)
          .join(', ');
      });
  }

  private _populatePrivileges(details: IEmployeeInfo) {
    if (details && details.Data) {
      for (const key in details.Data) {
        // Employment
        const employmentFields = this._employmentFields.map((f) => f.field);
        if (employmentFields.includes(key) && details.Data[key] === 'Y') {
          const employmentField = this._employmentFields.find(
            (f) => f.field === key
          );
          this.chipsEmployment.push(employmentField.name);
        }

        // Position
        const positionFields = this._positionFields.map((f) => f.field);
        if (positionFields.includes(key) && details.Data[key] === 'Y') {
          const positionField = this._positionFields.find(
            (f) => f.field === key
          );
          this.chipsPosition.push(positionField.name);
        }

        // Benefits
        const benefitFields = this._benefitFields.map((f) => f.field);
        if (benefitFields.includes(key) && details.Data[key] === 'Y') {
          const benefitField = this._benefitFields.find((f) => f.field === key);
          this.chipsBenefits.push(benefitField.name);
        }
      }
    }
  }

  // provides opportunity for subclasses to handle setter for custom data needs (ie, spinner turning off when data is loaded)
  abstract set data(value: IEmployeeInfo);
}
