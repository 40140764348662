<label
  kendoTooltip
  class="k-label"
  [hidden]="config?.options?.labelIgnore"
  [ngStyle]="config?.options?.style?.label"
  [attr.title]="config?.options?.tooltip"
  [class.disabled-field]="config?.disabled"
>
  {{ config?.label }}
  <i
    *ngIf="config?.options?.tooltip && config?.options?.showTooltipIcon"
    class="far fa-info-circle m-1"
    [title]="config?.options?.tooltip"
  ></i>
  <span [hidden]="!config?.required" class="c-red">*</span>
</label>
<i
  *ngIf="config?.options?.admin?.allowDelete"
  [hidden]="config?.options?.labelIgnore"
  class="fas fa-times ms-3 cursor-pointer c-red"
  (click)="removeItem()"
></i>
<p class="mb-3" *ngIf="config?.instruction">{{ config?.instruction }}</p>
