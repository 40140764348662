import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MyHttpClientFactory, environment } from '@ups/xplat/core';
import { parseData } from '@ups/xplat/utils';

const GET_MESSAGES: string = '/api/zipwhip/messages/{:number}/{:start}';
const GET_ATTACHMENT: string = '/api/zipwhip/attachment/{:storageKey}';
const SEND_MESSAGE: string = '/api/zipwhip/message/send';
const SAVE_CONTACT: string = '/api/zipwhip/contact/save';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  http: HttpClient;

  constructor(private httpFactory: MyHttpClientFactory) {
    this.http = httpFactory.createHttpClient(environment.urls.spartaAPI);
  }

  public getMessages(phoneNumber: string, startFrom: number = 0): any {
    let url = GET_MESSAGES.split('{:start}').join(startFrom.toString());
    url = url.split('{:number}').join(phoneNumber);
    return this.http.get(url).toPromise().then(parseData);
  }

  public getAttachemnt(storageKey: string): any {
    let url = GET_ATTACHMENT.split('{:storageKey}').join(storageKey);
    return this.http.get(url).toPromise().then(parseData);
  }

  public sendMessage(sender: string, receiver: string, message: string): any {
    let item = {
      SenderPhone: sender,
      ReceiverPhones: [receiver],
      Body: message,
    };
    return this.http.post(SEND_MESSAGE, item).toPromise().then(parseData);
  }

  public saveContact(
    mobileNumber: string,
    firstName: string,
    lastName: string,
    email: string
  ): any {
    let item = {
      MobileNumber: mobileNumber,
      FirstName: firstName,
      LastName: lastName,
      Email: email,
    };
    return this.http.post(SAVE_CONTACT, item).toPromise().then(parseData);
  }
}
