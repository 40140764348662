import { Component } from '@angular/core';

import { AppsyncStatusBaseComponent } from '@ups/xplat/features';

@Component({
  selector: 'ups-appsync-status',
  templateUrl: 'appsync-status.component.html',
})
export class AppsyncStatusComponent extends AppsyncStatusBaseComponent {
  constructor() {
    super();
  }
}
