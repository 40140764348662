import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import {
  ApplicationModel,
  HRResourceDto,
  ManPowerRequirementDto,
} from '@ups/xplat/api/dto';
import { BaseComponent } from '@ups/xplat/core';

@Component({
  selector: 'ups-requirements-editor',
  templateUrl: './requirements-editor.component.html',
})
export class RequirementsEditorComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild('panelbar') panelbar;

  @Input() id?: string = '';
  @Input() isLoading = false;
  @Input() isReadOnly = true;
  @Input() showHeader = true;
  @Input() showInactive = true;
  @Input() showSubtasks = true;
  @Input() title = 'Requirements';
  @Input() companyId?: string;

  @Input() set requirements(value: ManPowerRequirementDto[]) {
    if (value) {
      value.forEach(
        (v) =>
          (v.FullRequirementName = v.Description
            ? v.Description
            : v.RequirementName)
      );
    }

    this._requirements = value;
    this._requirements.forEach((r) => {
      if (
        r.RequirementName.indexOf('Background') !== -1 &&
        r.bActive === true
      ) {
        this.background = r.RequirementName;
      }
    });
  }

  get requirements() {
    return this._requirements;
  }

  _requirements: ManPowerRequirementDto[];
  background: string;
  expandedItem: string;
  selectedRequirement: ManPowerRequirementDto;
  employmentVerificationTypes = [];

  billableReqDropdownData: { name: string; value: boolean | null }[] = [
    { name: 'YES', value: true },
    { name: 'NO', value: false },
    { name: 'EMPTY', value: null },
  ];

  constructor(public applicationModel: ApplicationModel) {
    super();
  }

  ngOnInit(): void {
    this.background = '';
    this.expandedItem = '';

    this.applicationModel.employmentVerificationTypes.forEach(
      (type: HRResourceDto) => {
        const employmentVerification = {};

        employmentVerification['Id'] = type.HRResourceID;
        employmentVerification['Name'] = type.Name;

        this.employmentVerificationTypes.push(employmentVerification);
      }
    );
  }

  onBackgroundClick(background: ManPowerRequirementDto) {
    this.requirements.forEach((r) => {
      if (r.RequirementName.indexOf('Background') !== -1) {
        r.bActive = false;
      }
      if (r.RequirementName === background.RequirementName) {
        r.bActive = true;
      }
    });
    this.background = background.RequirementName;
  }

  onClick(event) {
    if (event.target && event.target.id.toString().indexOf('check') !== -1)
      return true;
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  onStateChange() {
    this.panelbar.childrenItems.forEach((item) => {
      if (item.expanded && item.title !== this.expandedItem) {
        item.expanded = false;
        item.selected = false;
      }
      if (item.title === this.expandedItem) {
        item.expanded = true;
        item.selected = true;
      }
    });
  }

  onToggleExpand(title: string) {
    this.expandedItem = this.expandedItem !== title ? title : '';
    this.panelbar.stateChange.next([
      { title: title, expanded: true, selected: true },
    ]);
  }

  rowCallback(context: RowClassArgs) {
    return {
      red: context.dataItem.bActive === false,
    };
  }

  inactiveRequirements() {
    return this.showInactive
      ? this.requirements
      : this.requirements.filter((r) => r.bActive === false);
  }

  isTypeEditable(reqName: string) {
    return (
      ['UA', 'HT', 'SA', 'Background', 'Employment Verification'].indexOf(
        reqName
      ) !== -1
    );
  }

  visibleRequirements() {
    return this.showInactive
      ? this.requirements
      : this.requirements.filter((r) => r.bActive === true);
  }

  getTypes(requirementName) {
    if (requirementName === 'Background')
      return this.applicationModel.backgroundTypes;
    else if (requirementName === 'Employment Verification')
      return this.employmentVerificationTypes;
    else return this.applicationModel.drugTestTypes;
  }
}
