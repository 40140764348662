import { Component } from '@angular/core';
import { WebDynamicItemPreviewComponentBase } from '../web-dynamic-item-component-base';

@Component({
  selector: 'form-textarea',
  styleUrls: ['form-textarea.component.scss'],
  template: `
    <div class="dynamic-field form-input text-align">
      <label></label>
      <textarea class="k-textbox" [attr.name]="config.id" [attr.placeholder]="config.data.placeholder"></textarea>
    </div>
  `,
})
export class FormTextareaComponent extends WebDynamicItemPreviewComponentBase {}
