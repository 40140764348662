import { Injectable } from '@angular/core';
import { ApplicationModel } from '@ups/xplat/api/dto';
import { HRResourceService } from '@ups/xplat/api/services';

@Injectable({ providedIn: 'root' })
export class PerDiemResolver {
  isLoading: boolean = false;

  constructor(private hrResourceService: HRResourceService, private applicationModel: ApplicationModel) {}

  public load(): Promise<any> {
    const self = this;
    if (this.applicationModel.perDiems.length === 0 && !this.isLoading) {
      self.isLoading = true;
      return this.hrResourceService.fetchPerDiem().then(function (data) {
        self.applicationModel.perDiems = data;
        self.isLoading = false;
      });
    } else {
      return Promise.resolve(self.applicationModel.perDiems);
    }
  }
}
