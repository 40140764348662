import { Component, OnInit } from '@angular/core';
import { DynamicItemBaseComponent } from '@ups/xplat/features';

@Component({
  selector: 'ups-dynamic-image',
  template: `
    <div class="dynamic-field">
      <img
        [src]="src"
        [width]="width"
        class="dashboard-image"
        draggable="false"
      />
    </div>
  `,
  styles: [
    `
      .dashboard-image {
        pointer-events: none;
      }
    `,
  ],
})
export class DynamicImageComponent
  extends DynamicItemBaseComponent
  implements OnInit
{
  public src = 'https://via.placeholder.com/300';
  public width = '300';
  public height = '300';

  public ngOnInit() {
    super.ngOnInit();
    if (this.config && this.config.value) {
      let data = <{ src?: string; width?: string; height?: string }>(
        this.config.value
      );
      if (typeof data === 'string') {
        data = JSON.parse(data);
      }
      this.src = data.src || 'https://via.placeholder.com/300';
      this.width = data.width || '300';
      this.height = data.height || '300';
    }
  }
}
