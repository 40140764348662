/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseModel } from '../base.model';

export class SubmitInterestModel extends BaseModel {
  employeeId: string;
  openingId: string;

  constructor(o: any = null) {
    super(o);
    if (o) {
      Object.assign(this, o);
    }
  }
}

export class WithDrawInterestModel extends BaseModel {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  HRRef?: number;
  vManPowerOpeningId: string;

  constructor(o: any = null) {
    super(o);
    if (o) {
      Object.assign(this, o);
    }
  }
}
