/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-undef */
import {
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  Directive,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import { ManPowerService } from '../services';

@Directive()
export abstract class BaseMoreInfo implements OnDestroy {
  isError = false;
  showMessage = false;
  message = '';
  expanded = false;
  selectedRequestedClass: any;
  selectedDispatchClass: any;

  @Input('f')
  f: UntypedFormGroup;

  @Input('groupId')
  groupId = 0;

  @Input('manPowerReqDetailId')
  manPowerReqDetailId: any = '';

  get hasEditRights(): any {
    return this.security.getFeatureById(
      SecurityConstants.employeeportal_manpowerpopup_jobrequirements
    ).editAll;
  }

  _jobRequirements: any[];
  requirementUrls: string[];

  @Input('jobRequirements')
  set jobRequirements(value: any) {
    // we need to parse out the different job reqs by group;
    this._jobRequirements = value;
    if (value !== undefined) {
      // gets unique list of nonempty urls
      this.requirementUrls = [...new Set(value.map((r) => r.Url))].filter(
        (url) => url != null && url !== ''
      ) as string[];
    } else {
      this.requirementUrls = [];
    }
  }

  get jobRequirements(): any {
    return this._jobRequirements;
  }

  @Input('jobRequirementStatuses')
  jobRequirementStatuses: any[];

  @Output() onSaveClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancelClick: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  requirementsChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  disaSyncStatusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  _model: any;
  @Input('model')
  set model(value: any) {
    this._model = value;
    if (value !== undefined) {
      this.render();
      this.populateForm();
      this.selectedRequestedClass = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ID: value.RequestedClassID,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Name: value.RequestedClassName,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        GroupType: value.RequestedClassGroupType,
      };
      this.selectedDispatchClass = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Id: value.JobClassID,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Name: value.JobClassName,
      };
    }
  }

  get model(): any {
    return this._model;
  }

  _personJobRequirements: any;
  @Input('personJobRequirements')
  set personJobRequirements(value: any) {
    // we need to parse out the different job reqs by group;
    if (value !== undefined) {
      this._personJobRequirements = value;
      this.render();
      this.populateForm();
    } else {
      this._personJobRequirements = value;
    }
  }

  get personJobRequirements(): any {
    return this._personJobRequirements;
  }

  $el: any;

  @Output() saveComplete: EventEmitter<any> = new EventEmitter<any>();

  private _isDisaSyncInProgress = false;
  get isDisaSyncInProgress(): boolean {
    return this._isDisaSyncInProgress;
  }

  set isDisaSyncInProgress(newValue: boolean) {
    this._isDisaSyncInProgress = newValue;
    this.disaSyncStatusChanged.emit(newValue);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  syncDisaStatusSubscription: Subscription = null;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  securityConstants = SecurityConstants;

  constructor(
    public security: SecurityService,
    protected manPowerService: ManPowerService
  ) {}

  handleSaveClick(): void {
    this.onSaveClick.emit();
  }

  handleCancelClick(): void {
    this.onCancelClick.emit();
  }

  toggleExpanded(): void {
    this.expanded = !this.expanded;
  }

  callError(error): void {
    console.log(error);
  }

  clearMessage(): void {
    this.message = '';
    this.showMessage = false;
  }

  updateModelFromForm(): void {
    console.log('OVERRIDE THIS');
    //this.customerModel.NiftyNotes =  this.f.controls['NiftyNotes'].value;
    //We dont need to do this, because we do this inside the html and update the model onChangeCallback with angular
  }

  createForm(): void {
    console.log('OVERRIDE THIS');
    /*
        this.f = new FormGroup({
            'WarehouseDetails': new FormArray([])
        });
        */
  }

  render(): void {
    //Should make a base form component to have a lot of these functions, render, handleSubmit, updateModelFromForm, createForm, populateForm
  }

  populateForm(): void {
    console.log('OVERRIDE THIS');
  }

  handleSubmit(): void {
    if (!this.f.valid) {
      this.isError = true;
      this.message = 'Form invalid';
      return;
    }

    this.updateModelFromForm();

    console.log('OVERRIDE THIS');
  }

  handleRequirementsChanged(event: any): void {
    this.requirementsChanged.emit(event);
  }

  handleDisaSyncClick(): void {
    // PORTAL-857; UPSFA-2246

    if (this.manPowerReqDetailId == null) {
      console.error(
        'No ManPowerReqDetailId specified. Please fix the code to pass the value to the component.'
      );
      // this.msgHelper.error('No ManPowerReqDetailId specified. Please fix the code to pass the value to the component.'); // msgHelper is web only.
      return;
    }

    console.log('clicked sync disa');
    this.isDisaSyncInProgress = true;

    const originalPersonJobRequirements: any = this.setDisaInfoFields(
      this.personJobRequirements,
      'Loading ...'
    );

    this.syncDisaStatusSubscription = this.manPowerService
      .syncDisaStatus(this.manPowerReqDetailId)
      .subscribe(
        (newPersonJobRequirements) => {
          this.isDisaSyncInProgress = false;

          if (
            newPersonJobRequirements == null ||
            newPersonJobRequirements.Data == null ||
            newPersonJobRequirements.HasError === true
          ) {
            // self.msgHelper.error('Sync of disa results failed. Error:' + newPersonJobRequirements.Message);
            this.copyDisaInfoFields(
              originalPersonJobRequirements,
              this.personJobRequirements
            );
            this.isDisaSyncInProgress = false;
            return;
          }

          newPersonJobRequirements = newPersonJobRequirements.Data;
          this.copyDisaInfoFields(
            newPersonJobRequirements,
            this.personJobRequirements
          );

          if (
            newPersonJobRequirements.JobRequirements != null &&
            newPersonJobRequirements.JobRequirements.length > 0 &&
            this.personJobRequirements.JobRequirements != null &&
            this.personJobRequirements.JobRequirements.length > 0
          ) {
            for (const jobRequirement of this.personJobRequirements
              .JobRequirements) {
              const newJobRequirement =
                newPersonJobRequirements.JobRequirements.find(
                  (jr) =>
                    (jr.ManPowerRequirementId =
                      jobRequirement.ManPowerRequirementId)
                );
              if (
                newJobRequirement != null &&
                jobRequirement.ManPowerRequirementStatusID !==
                  newJobRequirement.ManPowerRequirementStatusID
              ) {
                jobRequirement.ManPowerRequirementStatusID =
                  newJobRequirement.ManPowerRequirementStatusID;
                this.handleReqChanged(jobRequirement, newJobRequirement);
              }
            }
          }
        },
        (err) => {
          let message = 'Sync of disa results failed. Error: ';
          if (err != null) {
            if (err.Messages != null) {
              message += err.Messages.join(';');
            }

            if (err.StatusCode != null) {
              message += ' Status code: ' + err.StatusCode;
            }
          }

          console.log(message + err.ExceptionDetails);
          // self.msgHelper.error(message); // no msghelper on base layer!
          this.copyDisaInfoFields(
            originalPersonJobRequirements,
            this.personJobRequirements
          );
          this.isDisaSyncInProgress = false;
        }
      );
  }

  // eslint-disable-next-line no-unused-vars
  handleReqChanged(_jr: any, _newValue: any): void {
    // virtual
  }

  copyDisaInfoFields(
    fromPersonJobRequirements: any,
    toPersonJobRequirements: any
  ) {
    for (const personJobRequirements of [
      fromPersonJobRequirements,
      toPersonJobRequirements,
    ]) {
      if (personJobRequirements.DisaResult == null) {
        personJobRequirements.DisaResult = {};
      }

      if (personJobRequirements.Person == null) {
        personJobRequirements.Person = {};
      }

      if (personJobRequirements.JobPanel == null) {
        personJobRequirements.JobPanel = {};
      }

      if (personJobRequirements.DisaConsortiums == null) {
        personJobRequirements.DisaConsortiums = [];
      }
    }

    // disa-info.component values
    toPersonJobRequirements.DisaResult.DisaUADate =
      fromPersonJobRequirements.DisaResult.DisaUADate;
    toPersonJobRequirements.DisaResult.DisaOralFluidTestDate =
      fromPersonJobRequirements.DisaResult.DisaOralFluidTestDate;
    toPersonJobRequirements.DisaResult.DisaHairTestDate =
      fromPersonJobRequirements.DisaResult.DisaHairTestDate;
    toPersonJobRequirements.DisaResult.UPSUADate =
      fromPersonJobRequirements.DisaResult.UPSUADate;
    toPersonJobRequirements.DisaResult.DisaOwnerNameForUPSUA =
      fromPersonJobRequirements.DisaResult.DisaOwnerNameForUPSUA;
    toPersonJobRequirements.DisaResult.DisaOwnerNameForDrugTest =
      fromPersonJobRequirements.DisaResult.DisaOwnerNameForDrugTest;

    // processing-info.component values
    toPersonJobRequirements.DisaResult.UseUpsBackground =
      fromPersonJobRequirements.DisaResult.UseUpsBackground;
    toPersonJobRequirements.DisaResult.UseDisaBackground =
      fromPersonJobRequirements.DisaResult.UseDisaBackground;
    toPersonJobRequirements.DisaResult.BackgroundDate =
      fromPersonJobRequirements.DisaResult.BackgroundDate;
    toPersonJobRequirements.Person.TWICCardExpirationDate =
      fromPersonJobRequirements.Person.TWICCardExpirationDate;

    // disa-info.component values
    toPersonJobRequirements.JobPanel.PreOnSite_DaysUntilUrine =
      fromPersonJobRequirements.JobPanel.PreOnSite_DaysUntilUrine;
    toPersonJobRequirements.JobPanel.PreOnsite_DaysUntilOral =
      fromPersonJobRequirements.JobPanel.PreOnsite_DaysUntilOral;
    toPersonJobRequirements.JobPanel.PreOnSite_DaysUntilHair =
      fromPersonJobRequirements.JobPanel.PreOnSite_DaysUntilHair;
    toPersonJobRequirements.JobPanel.OnSite_DaysUntilUrine =
      fromPersonJobRequirements.JobPanel.OnSite_DaysUntilUrine;
    toPersonJobRequirements.JobPanel.OnSite_DaysUntilOral =
      fromPersonJobRequirements.JobPanel.OnSite_DaysUntilOral;
    toPersonJobRequirements.JobPanel.OnSite_DaysUntilHair =
      fromPersonJobRequirements.JobPanel.OnSite_DaysUntilHair;

    // processintoPersonJobRequirementsg-info.component values
    toPersonJobRequirements.JobPanel.PreOnSite_DaysUntilBackground =
      fromPersonJobRequirements.JobPanel.PreOnSite_DaysUntilBackground;
    toPersonJobRequirements.JobPanel.OnSite_DaysUntilBackground =
      fromPersonJobRequirements.JobPanel.OnSite_DaysUntilBackground;

    for (const fromDisaConsortium of fromPersonJobRequirements.DisaConsortiums) {
      let toDisaConsortium = toPersonJobRequirements.DisaConsortiums.find(
        (jr) => jr.Consortium === fromDisaConsortium.Consortium
      );
      if (toDisaConsortium == null) {
        toDisaConsortium = {};
        toPersonJobRequirements.DisaConsortiums.push(toDisaConsortium);
      }

      toDisaConsortium.LastTestDate = fromDisaConsortium.LastTestDate;
      toDisaConsortium.LastUrineTest = fromDisaConsortium.LastUrineTest;
      toDisaConsortium.LastHairTest = fromDisaConsortium.LastHairTest;
      toDisaConsortium.LastOralFluidTest = fromDisaConsortium.LastOralFluidTest;
      toDisaConsortium.IsInRandomHairProgram =
        fromDisaConsortium.IsInRandomHairProgram;
      toDisaConsortium.IsBackgrounds = fromDisaConsortium.IsBackgrounds;
      toDisaConsortium.BackgroundsExpDate =
        fromDisaConsortium.BackgroundsExpDate;
      toDisaConsortium.IsDriving = fromDisaConsortium.IsDriving;
      toDisaConsortium.DrivingExpDate = fromDisaConsortium.DrivingExpDate;
      toDisaConsortium.Status = fromDisaConsortium.Status;
      toDisaConsortium.Consortium = fromDisaConsortium.Consortium;
    }
  }

  setDisaInfoFields(personJobRequirements: any, message: string): any {
    // returns original values
    const originalValues: any = {};

    this.copyDisaInfoFields(personJobRequirements, originalValues);

    // disa-info.component values
    personJobRequirements.DisaResult.DisaUADate = null;
    personJobRequirements.DisaResult.DisaOralFluidTestDate = null;
    personJobRequirements.DisaResult.DisaHairTestDate = null;
    personJobRequirements.DisaResult.UPSUADate = null;
    personJobRequirements.DisaResult.DisaOwnerNameForUPSUA = message;
    personJobRequirements.DisaResult.DisaOwnerNameForDrugTest = message;

    // processing-info.component values
    personJobRequirements.DisaResult.BackgroundDate = null;
    personJobRequirements.Person.TWICCardExpirationDate = null;

    // disa-info.component values
    personJobRequirements.JobPanel.PreOnSite_DaysUntilUrine = message;
    personJobRequirements.JobPanel.PreOnsite_DaysUntilOral = message;
    personJobRequirements.JobPanel.PreOnSite_DaysUntilHair = message;
    personJobRequirements.JobPanel.OnSite_DaysUntilUrine = message;
    personJobRequirements.JobPanel.OnSite_DaysUntilOral = message;
    personJobRequirements.JobPanel.OnSite_DaysUntilHair = message;

    // processing-info.component values
    personJobRequirements.JobPanel.PreOnSite_DaysUntilBackground = message;
    personJobRequirements.JobPanel.OnSite_DaysUntilBackground = message;

    for (const disaConsortium of personJobRequirements.DisaConsortiums) {
      disaConsortium.LastTestDate = null;
      disaConsortium.LastUrineTest = null;
      disaConsortium.LastHairTest = null;
      disaConsortium.LastOralFluidTest = null;
      disaConsortium.IsInRandomHairProgram = message;
      disaConsortium.IsBackgrounds = message;
      disaConsortium.BackgroundsExpDate = null;
      disaConsortium.IsDriving = message;
      disaConsortium.DrivingExpDate = null;
      disaConsortium.Status = message;
    }

    return originalValues;
  }

  onClose(): void {
    if (
      this.syncDisaStatusSubscription != null &&
      !this.syncDisaStatusSubscription.closed
    ) {
      console.log(
        'Cancelling the SyncDisaStatus request due to window closing ...'
      );
      this.syncDisaStatusSubscription.unsubscribe();
      this.syncDisaStatusSubscription = null;
    }

    this.isDisaSyncInProgress = false;
  }

  ngOnDestroy(): void {
    this.onClose();
  }
}
