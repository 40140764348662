import { Component, EventEmitter, Input, Output } from '@angular/core';
import { JobsService } from '@ups/xplat/api/services';
import { MessageHelper } from '@ups/xplat/web/core';

@Component({
  selector: 'ups-bulk-message-modal',
  templateUrl: 'bulk-message-modal.component.html',
})
export class BulkMessageModalComponent {
  @Input() hrRefs: number[] = [];

  @Output() cancel: EventEmitter<void> = new EventEmitter();

  message = '';
  excludeCautionEmployees = true;
  constructor(
    private jobsService: JobsService,
    private msgHelper: MessageHelper
  ) {}

  sendMessage() {
    this.jobsService
      .sendBulkMessage(this.hrRefs, this.message, this.excludeCautionEmployees)
      .subscribe(() => {
        this.msgHelper.success('Bulk message successfully sent.');
      });

    this.closeModal();
  }

  closeModal() {
    this.cancel.emit();
  }
}
