import { BaseModel } from '../../base.model';

export class EquipmentDto extends BaseModel {
  public VPCompanyID: number;
  public Name: string;
  public EquipmentIDs: string[];
  public DisplayName: string;
  public Order: number;

  constructor(o: any = null) {
    super(o);
  }
}
