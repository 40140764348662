import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ResponseCasingEnum,
  MyHttpClientFactory,
  environment,
} from '@ups/xplat/core';
import {
  PaySequenceDto,
  ExportTimesheetDto,
  IODataResult,
  TimeSheetExportHistoryDto,
  PaySequenceRuleGroupDto,
} from '@ups/xplat/api/dto';
import { nameOfFactory } from '@ups/xplat/utils';

@Injectable({
  providedIn: 'root',
})
export class ExportTimesheetsService {
  protected urlBase: string;
  protected http: HttpClient;
  protected httpCamelCase: HttpClient;
  nameOf = nameOfFactory<ExportTimesheetDto>();

  constructor(private clientFactory: MyHttpClientFactory) {
    this.urlBase = environment.urls.spartaAPI;
    this.http = this.clientFactory.createHttpClient(
      this.urlBase,
      true,
      ResponseCasingEnum.PascalCase
    );
    this.httpCamelCase = this.clientFactory.createHttpClient(
      this.urlBase,
      true,
      ResponseCasingEnum.CamelCase
    );
  }

  public fetchPaySequencesOData(
    oDataString: string
  ): Observable<IODataResult<PaySequenceDto[]>> {
    let url = '/api/paysequences-odata?$format=json&$count=true';

    if (oDataString) {
      oDataString = oDataString.replace('filter()/', ''); // hack bug when filter is combined with group by in certain scenarios
      url += '&' + oDataString;
    }

    return this.http.get(url) as Observable<IODataResult<PaySequenceDto[]>>;
  }

  public fetchPaySequences(companyVpId: number): Observable<PaySequenceDto[]> {
    const url = '/api/export/paysequences/{companyVpId}'.replace(
      '{companyVpId}',
      companyVpId.toString()
    );
    return this.http.get(url) as Observable<PaySequenceDto[]>;
  }

  public fetchPaySequenceRulesOData(
    oDataString: string
  ): Observable<IODataResult<PaySequenceRuleGroupDto[]>> {
    let url = '/api/paysequencerules-odata?$format=json&$count=true';

    if (oDataString) {
      oDataString = oDataString.replace('filter()/', ''); // hack bug when filter is combined with group by in certain scenarios
      url += '&' + oDataString;
    }

    return this.http.get(url) as Observable<
      IODataResult<PaySequenceRuleGroupDto[]>
    >;
  }

  public savePaySequence(
    paySequenceDto: PaySequenceDto
  ): Observable<PaySequenceDto> {
    const url = '/api/paysequence';

    return this.http.post(url, paySequenceDto) as Observable<PaySequenceDto>;
  }

  public savePaySequenceRule(
    paySequenceRuleDto: PaySequenceRuleGroupDto
  ): Observable<PaySequenceRuleGroupDto> {
    const url = '/api/paysequencerule';

    return this.http.post(
      url,
      paySequenceRuleDto
    ) as Observable<PaySequenceRuleGroupDto>;
  }

  getTimesheetsUri(jobId: string, oDataString: string = null) {
    const url = '/api/export/timesheets-odata/{jobId}?'.replace(
      '{jobId}',
      jobId
    );
    const queryParameters: string[] = [];
    if (jobId) {
      queryParameters.push(`jobID=${jobId}`);
    }
    if (oDataString) {
      queryParameters.push('$format=json');
      queryParameters.push('$count=true');

      oDataString = oDataString.replace('T00:00:00.000Z', ''); // hack to enable date search - for some reason it is not taking such format into account so removing the time.
      oDataString = oDataString.replace('filter()/', ''); // hack bug when filter is combined with group by in certain scenarios

      // Hack to sort by date and time even if we use date only column in the grid (for grouping purposes by date)
      if (
        !oDataString.includes(
          `groupby((${this.nameOf('LaborExportedDateOnly')})`
        )
      ) {
        oDataString = oDataString.replace(
          `orderby=${this.nameOf('LaborExportedDateOnly')}`,
          `orderby=${this.nameOf('LaborExportedDate')}`
        );
      }

      if (
        !oDataString.includes(
          `groupby((${this.nameOf('PerDiemExportedDateOnly')})`
        )
      ) {
        oDataString = oDataString.replace(
          `orderby=PerDiemExportedDateOnly`,
          `orderby=${this.nameOf('PerDiemExportedDate')}`
        );
      }

      if (
        !oDataString.includes(
          `groupby((${this.nameOf('MileageExportedDateOnly')})`
        )
      ) {
        oDataString = oDataString.replace(
          `orderby=${this.nameOf('MileageExportedDateOnly')}`,
          `orderby=${this.nameOf('MileageExportedDate')}`
        );
      }

      if (
        !oDataString.includes(
          `groupby((${this.nameOf('EquipmentExportedDateOnly')})`
        )
      ) {
        oDataString = oDataString.replace(
          `orderby=${this.nameOf('EquipmentExportedDateOnly')}`,
          `orderby=${this.nameOf('EquipmentExportedDate')}`
        );
      }

      if (
        !oDataString.includes(`groupby((${this.nameOf('DateCreatedDateOnly')})`)
      ) {
        oDataString = oDataString.replace(
          `orderby=${this.nameOf('DateCreatedDateOnly')}`,
          `orderby=${this.nameOf('DateCreated')}`
        );
      }

      queryParameters.push(oDataString);
    }
    return url + '?' + queryParameters.join('&');
  }

  getTimesheetsUriInclServer(jobID: string, odataString: string = null) {
    return (
      environment.urls.spartaAPI + this.getTimesheetsUri(jobID, odataString)
    );
  }

  public fetchTimesheets(
    /** Job GUID */ jobId: string,
    oDataString: string
  ): Observable<IODataResult<ExportTimesheetDto[]>> {
    const url = this.getTimesheetsUri(jobId, oDataString);
    return this.http.get(url) as Observable<IODataResult<ExportTimesheetDto[]>>;
  }

  getTimeSheetExport(jobID: string, timeSheetID: string) {
    const oDataString = `$filter=timeSheetID%20eq%20${timeSheetID}`;
    return this.fetchTimesheets(jobID, oDataString);
  }

  public getTimeSheetExportHistory(
    timeSheetID: string
  ): Observable<TimeSheetExportHistoryDto[]> {
    const url = 'api/timesheet/export/history/{timeSheetID}'.replace(
      '{timeSheetID}',
      timeSheetID
    );
    return this.httpCamelCase.get(url) as Observable<
      TimeSheetExportHistoryDto[]
    >;
  }
}
