/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, Injector, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseComponent, EmployeeFitCertValidation } from '@ups/xplat/core';
import { EmployeeFitService } from '../services/employee-fit.service';
import { EmployeeService } from '../services';
import { IEmployeeInfo } from '@ups/xplat/api/dto';
import { UserState } from '@ups/user';
import { Store } from '@ngrx/store';
import { TasksService } from '@ups/xplat/features';
import { SecurityService } from '@ups/security';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FileDto } from '@ups/files';
import { AttachmentVpService } from '@ups/xplat/api/services';

@Directive()
export abstract class ProfileFitBaseComponent extends BaseComponent {
  @Input() hrRef: number;
  @Input() profile;
  authProfile: IEmployeeInfo;

  public form: UntypedFormGroup = new UntypedFormGroup({
    fitTestDate: new UntypedFormControl(null, Validators.required),
    expiration: new UntypedFormControl(null, Validators.required),
  });

  public uploadedFile: FileDto;
  public attachmentService: AttachmentVpService;
  protected httpClient: HttpClient;

  constructor(
    protected injector: Injector,
    protected fitService: EmployeeFitService,
    protected employeeInfo: EmployeeService,
    protected taskService: TasksService,
    protected securityService: SecurityService,
    protected store: Store<any>
  ) {
    super();
    this.attachmentService = this.injector.get(AttachmentVpService);
  }

  ngOnInit() {
    this.loadData();

    this.store
      .pipe(UserState.selectCurrentTruthy(this.destroy$))
      .subscribe((x) => {
        this.authProfile = x;
      });
  }

  public loadData() {
    this.fitService.getFitDataByHRRef(this.hrRef).subscribe((results) => {
      const fitTests = results;

      this.securityService
        .getSecurityProfileOfLoggedInUser()
        .subscribe((security) => {
          this.taskService
            .getTasksForRelatedUser(security.userId)
            .subscribe((data) => {
              const tasks = data.map((t) => new EmployeeFitCertValidation(t));
              const approvalRequests = tasks.map((request) => ({
                approvalRequest: true,
                isFitTestValidation: request.isFitTestValidation,
                CertDate: request.testDate,
                ExpireDate: request.expirationDate,
                Files: [
                  {
                    AttachmentID: request.fileId,
                    FileName: request.fileName,
                  },
                ],
              }));

              this.data = approvalRequests
                .filter((request) => request.isFitTestValidation)
                .concat(...fitTests);
            });
        });
    });
  }

  abstract set data(value: Array<any>);

  getFile(attachmentID: number): Promise<any> {
    return new Promise((resolve) => {
      if (attachmentID) {
        this.attachmentService.getFileById2(attachmentID).then(
          (data) => {
            // A byte array serialized by JSON is then returned as Base64 encoded string
            resolve(data);
          },
          () => {
            resolve(null);
          }
        );
      }
    });
  }

  public requestNewFitTest(formData?) {
    const testDate = formData
      ? formData.fitTestDate.split('T')[0]
      : new Date(this.form.value.fitTestDate).toLocaleDateString();
    const expirationDate = formData
      ? formData.expiration.split('T')[0]
      : new Date(this.form.value.expiration).toLocaleDateString();

    this.securityService
      .getSecurityProfileOfLoggedInUser()
      .subscribe((data) => {
        const t = new EmployeeFitCertValidation(null);
        t.employeeFullName =
          this.authProfile.Data.LastName +
          ', ' +
          this.authProfile.Data.FirstName;
        t.hrRef = this.authProfile.Data.HRRef;
        t.relatedUserId = data.userId;
        t.testDate = testDate;
        t.expirationDate = expirationDate;
        t.fileId = formData?.attachment[0]?.fileID ?? this.uploadedFile.fileID;
        t.fileName = formData?.attachment[0]?.name ?? this.uploadedFile.name;
        t.isFitTestValidation = true;

        const x = t.createSystemTaskRegistration();

        this.taskService.enrollInSystemTask(x).subscribe(() => {
          this.loadData();
        });
      });
  }
}
