import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ArraySumPipe } from './array-sum.pipe';
import { CheckboxFilterPipe } from './checkbox-filter.pipe';
import { DateFilter } from './date-filter.pipe';
import { FilterByPipe } from './filter-by.pipe';
import { FixKendoStateDatesPipe } from './fix-kendo-state-dates.pipe';
import { NonNullPipe } from './non-null.pipe';
import { NumberFilter } from './number-filter.pipe';
import { OnlyNullPipe } from './only-null.pipe';
import { SearchPipe } from './search-pipe';
import { TruncatePipe } from './truncate-pipe';

const pipes = [
  ArraySumPipe,
  CheckboxFilterPipe, // also in @ups/common
  DateFilter, // also in @ups/common
  FilterByPipe, // also in @ups/common
  FixKendoStateDatesPipe,
  NonNullPipe, // also in @ups/common
  NumberFilter,
  OnlyNullPipe,
  TruncatePipe,
  SearchPipe, // 2 similar pipes in the solution
];

@NgModule({
  declarations: [...pipes],
  exports: [...pipes],
  providers: [...pipes],
  imports: [CommonModule],
})
export class PipesModule {}
