import { DestroyRef, Directive, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MyHttpClientFactory } from '@ups/xplat/core';
import { DynamicItemBaseComponent } from '../dynamic-item/dynamic-item.base-component';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { UserState } from '@ups/user';
import { debounceTime, filter, map, take } from 'rxjs';

// can support any number of internal automatically handled dynamic variables
const supportedInternalVariables = ['{HRRef}'];

@Directive()
export abstract class DynamicDataLabelsBaseComponent extends DynamicItemBaseComponent {
  private http = inject(HttpClient);
  private httpFactory = inject(MyHttpClientFactory);
  private store = inject(Store);
  destroyRef = inject(DestroyRef);
  baseUrl: string;
  // prepared endpoint with proper value substitution for request
  apiEndpoint: string;
  // original endpoint with variables for continual dynamic substitution
  apiEndpointWithVariables: string;
  data: Array<{ label: string; value: string }>;
  // maps other form control names to the property name to replace variables in endpoint
  // { formControlName: dataPropertyName }
  private _otherControls: Map<string, string>;

  ngOnInit() {
    if (
      this.config?.options?.api?.endpoint &&
      this.config?.options?.api?.properties
    ) {
      this.attachUrl(this.config?.options?.api?.endpoint);
    }
  }

  requestData(reset?: boolean) {
    if (this.http) {
      this.http.get(this.apiEndpoint).subscribe((res) => {
        if (res) {
          const props = this.config?.options?.api?.properties
            .split(',')
            .map((p) => p.trim());
          if (reset) {
            // reset data before populating
            this.data = [];
          }
          for (const prop of props) {
            this._addData({
              label: prop,
              value: res[prop],
            });
          }
        }
      });
    }
  }

  attachUrl(apiUrl: string) {
    // Note: rudimentary parsing, could use Url (when WinterCG spec is in {N})
    const parts = apiUrl.split('/');
    if (parts?.length > 3) {
      this.baseUrl = `${parts[0]}//${parts[2]}`;
      this.http = this.httpFactory.createHttpClient(this.baseUrl);
      this.apiEndpoint = parts.slice(3, parts.length).join('/');
      // capture the endpoint "with variables still in it" to dynamically substitute later if needed
      // used when data is derived from other controls which do variable substitution after they fire
      this.apiEndpointWithVariables = this.apiEndpoint;
      this.handleVariables();
    }
  }

  handleVariables() {
    if (this.apiEndpoint) {
      const variables = this.apiEndpointWithVariables
        .match(/\{[^}]*\}/gi)
        .map((v) => v.replace('{', '').replace('}', ''));
      if (variables.length) {
        // check if variables are derived from other controls
        // example: {anyOtherControlName.SomeProperty}
        const otherControlVariables = variables.filter((v) => v.includes('.'));
        if (otherControlVariables.length) {
          this._listenToOtherControls(otherControlVariables);
          if (otherControlVariables.length < variables.length) {
            // uses internally supported variables as well
            this._setupInternalVariables();
          }
        } else {
          // can only be internally supported variables
          this._setupInternalVariables();
          // make request right away
          this.requestData();
        }
      }
    }
  }

  private _replaceVariable(variable: string, value: string) {
    this.apiEndpoint = this.apiEndpointWithVariables.replace(variable, value);
  }

  private _listenToOtherControls(variables: Array<string>) {
    // supporting direct properties from other controls (not nested properties right now)
    this._otherControls = new Map();
    variables.forEach((v) => {
      const keyValue = v.split('.');
      this._otherControls.set(keyValue[0], keyValue[1]);
    });

    // wait until control fires selection to make api request

    // https://sample.com/{dropDown1.SomeProperty}/detail/{dropDown2.HRRef}
    // enumerate all control dot notiation to setup listeners
    // only fire when all controls have valid values
    // NOTE: Add new config property to conditionally determine if the data should show with default values (perhaps 'n/a' when no value to start with)
    this.group.valueChanges
      .pipe(
        filter((formValue) => {
          return Array.from(this._otherControls.keys()).some(
            (name) => formValue.hasOwnProperty(name) && !!formValue[name]
          );
        }),
        // helps prevent too early initialization
        debounceTime(300),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((data) => {
        if (data) {
          for (const [formControlName, dataPropertyName] of this
            ._otherControls) {
            this._replaceVariable(
              `{${formControlName}.${dataPropertyName}}`,
              data[formControlName][dataPropertyName]
            );
          }
          this.requestData(true);
        }
      });
  }

  private _setupInternalVariables() {
    for (const internalVariable of supportedInternalVariables) {
      if (this.apiEndpointWithVariables.includes(internalVariable)) {
        // support any number of internal dynamic variables as needed
        switch (internalVariable) {
          case '{HRRef}':
            // use auth'd user hrref
            this.store
              .select(UserState.selectCurrent)
              .pipe(take(1))
              .subscribe((info) => {
                if (info?.Data?.HRRef) {
                  this._replaceVariable(internalVariable, `${info.Data.HRRef}`);
                }
              });
            break;
        }
      }
    }
  }

  private _addData(prop: { label: string; value: string }) {
    if (!this.data) {
      this.data = [];
    }
    this.data.push(prop);
  }
}
