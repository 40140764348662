import { BaseComponent } from '@ups/xplat/core';
import { Input, TemplateRef, Output, EventEmitter, Component } from '@angular/core';

@Component({ template: '' })
export abstract class ConfirmationDialogBaseComponent extends BaseComponent {
  public text = 'ConfirmationDialog';

  // callback functions useful when opening programmatically, but should be avoided when using through template.
  protected confirmCallback: () => void;
  protected cancelCallback: () => void;

  @Input() titleText = 'Confirm?';
  @Input() cancelText = 'Cancel';
  @Input() confirmText = 'Confirm';

  // Provide a simple string body or an ng-template ref.
  @Input() message: string;
  @Input() bodyTemplate: TemplateRef<any>; // https://stackoverflow.com/questions/45976862/templateref-requires-type-argument

  // Output emitters are preferred when using template
  @Output() confirm = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  constructor() {
    super();
  }

  public open(message?: string, confirmCallback?: () => void, cancelCallback?: () => void) {
    if (message) {
      this.message = message;
    }
    if (confirmCallback) {
      this.confirmCallback = confirmCallback;
    }
    if (cancelCallback) {
      this.cancelCallback = cancelCallback;
    }
  }

  onCancel() {
    if (this.cancelCallback) {
      this.cancelCallback();
    }
    this.cancel.emit();
  }

  onConfirm() {
    if (this.confirmCallback) {
      this.confirmCallback();
    }
    this.confirm.emit();
  }
}
