import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';

import {
  UIModule,
  ReleaseNoteModule,
} from '@ups/xplat/web/features';
import { InAppHelpModule } from '@ups/xplat/web/in-app-help';

import { COMPONENTS } from './components';
import { KendoPanelBarMenuModule } from './components/kendo-panel-bar-menu/kendo-panel-bar-menu.module';

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [
    //
    // Basic angular related imports
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    // Kendo related imports
    UIModule,
    KendoPanelBarMenuModule,
    //
    // Other modules (from node_modules)

    //
    // Our custom modules (like the ones in the shared library)
    // UpsVersionDisplayCheckerModule
    InAppHelpModule,
    ReleaseNoteModule,
  ],
  providers: [],
})
export class LayoutModule {}
