import { Directive } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

export const ngFormProviderFactory = (form: NgForm) => form;

/*
 * Please use CONTROL_CONTAINER_PROVIDER or [provide-control-container] directive instead for more flexibility...
 * ...handling both ngForm as ngModelGroup universally.
 *
 * To be used with nested TEMPLATE DRIVEN forms.
 *
 * For MODEL DRIVEN form nesting consider using: FORM_GROUP_DIRECTIVE_PROVIDER
 *
 * What it does:
 *  When the parent control uses ngForm and inside that form (group) we do add out custom control...
 *  ...and we want our custom control inputs to be registered in that form (group)
 *  ...then we need to provide the parent ngForm to our control
 *
 * Usage:
 *  The child component (we add to HTML)
 *  - must have viewProvider: [NG_FORM_PROVIDER] defined
 *  - or one of it's top level node must have the [provide-ng-form] attribute
 */
export const NG_FORM_PROVIDER: any = {
  provide: ControlContainer,
  useFactory: ngFormProviderFactory,
  deps: [NgForm],
};

/**
 * Please use CONTROL_CONTAINER_PROVIDER or [provide-control-container] directive instead for more flexibility
 * See additional notes on: NG_FORM_PROVIDER
 */
@Directive({
  selector: '[provide-ng-form]',
  providers: [NG_FORM_PROVIDER],
})
export class ProvideNgFormDirective {}
