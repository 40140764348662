/* eslint-disable */
import { environmentBase } from './environment.base';
import { IEnvironmentConfiguration } from '../environment.interface';
import { deepMerge } from '@ups/xplat/utils';

export const environmentDev = deepMerge(environmentBase, <
  IEnvironmentConfiguration
>{
  // customizations here...
  environment_name: 'dev',
  appsync: {
    production: true,
    android: {
      productionKey: process.env['AppSync:AndroidKey@prod'],
      stagingKey: process.env['AppSync:AndroidKey@stg'],
    },
    ios: {
      productionKey: process.env['AppSync:IosKey@prod'],
      stagingKey: process.env['AppSync:IosKey@stg'],
    },
    version: '0.0.1',
  },
  name: 'safety',
  urls: {
    spartaAPI: process.env['Urls:SpartaApi@stg'],
    softwareSecurityAPI: process.env['Urls:SecurityApi@stg'],
    fileManagementAPI: process.env['Urls:FileManagementApi@stg'],
    ratesAPI: 'https://localhost:5001', //process.env['Urls:RatesApi@stg'],
    toolTrackerAPI: 'https://localhost:5002', //process.env['Urls:ToolTrackerApi@stg'],
    dynamicAPI: 'https://localhost:5003', //process.env['Urls:DynamicApi@stg'],
  },
  blobStorage: {
    url: process.env['Urls:AzureBlobStorageUrl'],
    container: process.env['AzureBlobStorage:ContainerName@stg'],
  },
});
/* eslint-enable */
