import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import {
  ForemanCrewDto,
  ForemanCrewMemberDto,
  IODataResult,
  LaborTypeSetupDto,
} from '@ups/xplat/api/dto';
import { DatePipe } from '@angular/common';

const GET_FOREMAN_CREWS: string = '/api/foremancrews/{:jobID}';
const SAVE_FOREMAN_CREW: string = '/api/foremancrew';
const SAVE_FOREMAN_CREW_MEMBERS: string = '/api/foremancrew-members';
const GET_FOREMAN_CREW_MEMBERS: string =
  '/api/foremancrew-members-odata/{:foremanCrewId}';
const SAVE_FOREMAN_CREW_MEMBER: string = '/api/foremancrew-member';
const GET_LABOR_TYPE_SETUP: string = '/api/laborTypeSetup/{:jobID}';
const SAVE_LABOR_TYPE_SETUP: string = '/api/laborTypeSetup';

@Injectable({
  providedIn: 'root',
})
export class ForemanCrewService {
  protected urlBase: string;
  protected http: HttpClient;

  constructor(private clientFactory: MyHttpClientFactory) {
    this.urlBase = environment.urls.spartaAPI;
    this.http = this.clientFactory.createHttpClient(
      this.urlBase,
      true,
      ResponseCasingEnum.PascalCase
    );
  }

  public fetchForemanCrews(
    jobId: string,
    shift: number | null
  ): Observable<ForemanCrewDto[]> {
    let url = GET_FOREMAN_CREWS.split('{:jobID}').join(jobId);

    if (shift) {
      url += '?shift=' + shift.toString();
    }

    return this.http.get(url) as Observable<ForemanCrewDto[]>;
  }

  public saveForemanCrew(
    foremanCrewDto: ForemanCrewDto
  ): Observable<ForemanCrewDto> {
    return this.http.post(
      SAVE_FOREMAN_CREW,
      foremanCrewDto
    ) as Observable<ForemanCrewDto>;
  }

  public saveForemanCrewMembers(
    foremanCrewMemberDtos: ForemanCrewMemberDto[]
  ): Observable<ForemanCrewMemberDto[]> {
    return this.http.post(
      SAVE_FOREMAN_CREW_MEMBERS,
      foremanCrewMemberDtos
    ) as Observable<ForemanCrewMemberDto[]>;
  }

  public fetchForemanCrewMembersSparta(
    foremanCrewId: string,
    jobId: string,
    shift: number | null,
    includeWithoutCrew: boolean,
    includeROFed: boolean,
    includeNonOnSiteEmployees: boolean,
    oDataString: string,
    searchString: string
  ): Observable<IODataResult<ForemanCrewMemberDto>> {
    let url =
      GET_FOREMAN_CREW_MEMBERS.split('{:foremanCrewId}').join(foremanCrewId) +
      '?';

    const queryParameters: string[] = [];

    if (!foremanCrewId && jobId && shift) {
      queryParameters.push('shift=' + shift);
    }

    if (jobId) {
      queryParameters.push('jobId=' + jobId);
    }

    if (searchString) {
      queryParameters.push('searchString=' + escape(searchString));
    }

    queryParameters.push('includeROFed=' + includeROFed);
    queryParameters.push('includeWithoutCrew=' + includeWithoutCrew);

    if (!includeNonOnSiteEmployees) {
      const datePipe = new DatePipe('en-US');
      const startDateFilter = datePipe.transform(new Date(), 'MM/dd/yyyy');
      queryParameters.push('startDateFilter=' + startDateFilter);
    }

    queryParameters.push('$count=true');
    queryParameters.push('$format=json');

    if (oDataString) {
      queryParameters.push(oDataString);
    }

    url = url + queryParameters.join('&');

    return this.http.get(url) as Observable<IODataResult<ForemanCrewMemberDto>>;
  }

  public fetchForemanCrewMembers(
    foremanCrewId: string,
    oDataString: string,
    searchString: string,
    includeROFed: boolean
  ): Observable<IODataResult<ForemanCrewMemberDto[]>> {
    let url =
      GET_FOREMAN_CREW_MEMBERS.split('{:foremanCrewId}').join(foremanCrewId) +
      '?';

    const queryParameters: string[] = [];

    if (searchString) {
      queryParameters.push('searchString=' + escape(searchString));
    }

    queryParameters.push('$count=true');
    queryParameters.push('$format=json');
    queryParameters.push('includeROFed=' + includeROFed);

    if (oDataString) {
      queryParameters.push(oDataString);
    }

    url = url + queryParameters.join('&');

    return this.http.get(url) as Observable<
      IODataResult<ForemanCrewMemberDto[]>
    >;
  }

  public saveForemanCrewMember(
    foremanCrewMemberDto: ForemanCrewMemberDto
  ): Observable<ForemanCrewMemberDto> {
    return this.http.post(
      SAVE_FOREMAN_CREW_MEMBER,
      foremanCrewMemberDto
    ) as Observable<ForemanCrewMemberDto>;
  }

  public getLaborTypeSetup(jobID: string, odataString: string) {
    let url = GET_LABOR_TYPE_SETUP.split('{:jobID}').join(jobID);
    url += '?$format=json&$count=true';
    url += odataString ? '&' + odataString : '';

    console.log('Calling service: ', url);

    return this.http.get(url);
  }

  public saveLaborTypeSetup(dtos: LaborTypeSetupDto[]): any {
    return this.http.post(SAVE_LABOR_TYPE_SETUP, dtos).toPromise();
  }
}
