import { DynamicDeleteFormModalComponent } from './dynamic-delete-form-modal/dynamic-delete-form-modal.component';
import { DynamicEditModalComponent } from './dynamic-edit-modal/dynamic-edit-modal.component';

export const DYNAMIC_MODALS = [
  DynamicEditModalComponent,
  DynamicDeleteFormModalComponent,
];

export * from './dynamic-edit-modal/dynamic-edit-modal.component';
export * from './dynamic-delete-form-modal/dynamic-delete-form-modal.component';
