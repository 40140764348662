<span *ngIf="hasReadPermissions && equipmentResource === null">
  Loading ...
</span>
<span *ngIf="!hasReadPermissions">
  You have no permissions to read the Equipment Resource Ownership Information.
</span>

<form
  id="equipmentResourceOnwershipInfoFormId"
  #form="ngForm"
  (ngSubmit)="saveEquipment(form.valid)"
  *ngIf="hasReadPermissions && equipmentResource !== null"
>
  <div
    *ngIf="hasReadPermissions && equipmentResource !== null"
    class="d-flex justify-content-end w-100"
  >
    <button
      id="editEquipmentBtn"
      help-anchor="editRentalLeaseInfoTabButton"
      *ngIf="!isEditMode && hasEditPermissions"
      type="button"
      class="k-button"
      (click)="editEquipment()"
    >
      Edit
    </button>
    <button
      id="editEquipmentSaveBtn"
      help-anchor="saveButton"
      *ngIf="isEditMode"
      type="button"
      class="k-button k-primary me-3"
      (click)="saveEquipment(form.valid)"
    >
      Save
    </button>
    <button
      id="editEquipmentCancelBtn"
      *ngIf="isEditMode"
      type="button"
      class="k-button"
      (click)="cancelEquipment()"
    >
      Cancel
    </button>
  </div>

  <h4 class="mb-5">Lease / Rental Information</h4>
  <div class="d-flex flex-wrap mb-7 w-100">
    <div class="me-11 mb-5">
      <label for="apCompany" class="me-5">AP Company</label>
      <kendo-dropdownlist
        id="apCompany"
        help-anchor="apCompanyDD"
        name="apCompany"
        [(ngModel)]="equipmentResource.ApCompany"
        [disabled]="!isEditMode"
        [data]="applicationModel.companies"
        [textField]="'CompanyNumber'"
        [valueField]="'CompanyNumber'"
        [valuePrimitive]="true"
        (valueChange)="onApCompanyChange($event)"
      ></kendo-dropdownlist>
    </div>
    <div class="me-11 mb-5">
      <label
        *ngIf="equipmentResource.OwnershipStatus !== 'R'"
        for="leasedFrom"
        class="me-5 d-block"
      >
        Leased From
      </label>
      <label
        *ngIf="equipmentResource.OwnershipStatus === 'R'"
        for="leasedFrom"
        class="me-5"
      >
        Rented From
      </label>
      <kendo-dropdownlist
        id="leasedFrom"
        help-anchor="leasedFromDD"
        name="leasedFrom"
        #leasedFromDropdown
        [(ngModel)]="selectedVendor"
        (valueChange)="onVendorChange($event)"
        [disabled]="!isEditMode"
        [textField]="'name'"
        [valueField]="'vendor'"
        [valuePrimitive]="false"
        [filterable]="true"
        search-ex
        [seSearchColumns]="['name']"
        (seFilterChangeEx)="vendorFilterChanged($event)"
        [seDebounceFilterChange]="150"
        [seMinSearchLength]="0"
      ></kendo-dropdownlist>
    </div>
    <div class="me-11 mb-5">
      <label for="leaseStartDate" class="me-5">Lease Start Date</label>
      <ups-kendo-datepicker-shared
        id="leaseStartDate"
        help-anchor="leaseStartDate"
        name="leaseStartDate"
        [(ngModel)]="equipmentResource.LeaseStartDate"
        [disabled]="!isEditMode"
        placeholder="mm/dd/yyyy"
      ></ups-kendo-datepicker-shared>
    </div>
    <div class="me-11 mb-5">
      <label for="leaseEndDate" class="me-5">Lease End Date</label>
      <ups-kendo-datepicker-shared
        id="leaseEndDate"
        help-anchor="leaseEndDate"
        name="leaseEndDate"
        [(ngModel)]="equipmentResource.LeaseEndDate"
        [disabled]="!isEditMode"
        placeholder="mm/dd/yyyy"
      ></ups-kendo-datepicker-shared>
    </div>
    <div class="me-11 mb-5">
      <label for="leasePayment" class="me-5">Lease Payment</label>
      <kendo-numerictextbox
        id="leasePayment"
        help-anchor="leasePaymentField"
        name="leasePayment"
        [(ngModel)]="equipmentResource.LeasePayment"
        [disabled]="!isEditMode"
        [decimals]="2"
        [format]="'n2'"
        [min]="-999999999.99"
        [max]="999999999.99"
      ></kendo-numerictextbox>
    </div>
    <div class="me-11 mb-5">
      <label for="leaseResidualValue" class="me-5">Lease Residual Value</label>
      <kendo-numerictextbox
        id="leaseResidualValue"
        help-anchor="leaseResidualValueField"
        name="leaseResidualValue"
        [(ngModel)]="equipmentResource.LeaseResidualValue"
        [disabled]="!isEditMode"
        [decimals]="2"
        [format]="'n2'"
        [min]="-999999999.99"
        [max]="999999999.99"
      ></kendo-numerictextbox>
    </div>
    <div>
      <label for="leaseNotes" class="me-5 d-block">Lease Notes</label>
      <textarea
        id="leaseNotes"
        help-anchor="leaseNotesField"
        name="leaseNotes"
        type="text"
        [disabled]="!isEditMode"
        rows="3"
        [(ngModel)]="equipmentResource.LeaseNotes"
        kendoTextArea
      ></textarea>
    </div>
  </div>
  <h4 class="mb-5">Customer Information</h4>
  <div class="d-flex mb-7 w-100">
    <div class="me-11">
      <label for="arCoCompany" class="me-5">AR Company</label>
      <kendo-dropdownlist
        id="arCoCompany"
        help-anchor="arCompanyDD"
        name="arCoCompany"
        [(ngModel)]="equipmentResource.ArCoCompany"
        [disabled]="!isEditMode"
        [data]="applicationModel.companies"
        [textField]="'CompanyNumber'"
        [valueField]="'CompanyNumber'"
        [valuePrimitive]="true"
        (valueChange)="onArCompanyChange($event)"
      ></kendo-dropdownlist>
    </div>
    <div class="me-11">
      <label for="customer" class="me-5 d-block">Customer</label>
      <kendo-dropdownlist
        id="customer"
        help-anchor="customerDD"
        name="customer"
        #customerDropdown
        [(ngModel)]="selectedCustomer"
        (valueChange)="onCustomerChange($event)"
        [disabled]="!isEditMode"
        [textField]="'name'"
        [valueField]="'customer'"
        [valuePrimitive]="false"
        [filterable]="true"
        search-ex
        [seSearchColumns]="['Name']"
        (seFilterChangeEx)="customerFilterChanged($event)"
        [seDebounceFilterChange]="150"
        [seMinSearchLength]="0"
      ></kendo-dropdownlist>
    </div>
    <div class="me-11">
      <label for="customerEquipmentNumber" class="me-5">
        Customer Equipment Number
      </label>
      <input
        id="customerEquipmentNumber"
        help-anchor="customerEquipmentNumberField"
        name="customerEquipmentNumber"
        [(ngModel)]="equipmentResource.CustomerEquipmentNumber"
        [disabled]="!isEditMode"
        maxlength="15"
        noWhitespace
        kendoTextBox
      />
    </div>
  </div>
  <h4>Other Information</h4>
  <div class="d-flex flex-wrap align-items-center mb-7 w-100">
    <div class="me-11 mb-5">
      <label for="inServiceDate" class="me-5">In Service Date</label>
      <ups-kendo-datepicker-shared
        id="inServiceDate"
        help-anchor="inServiceDate"
        name="inServiceDate"
        [(ngModel)]="equipmentResource.InServiceDate"
        [disabled]="!isEditMode"
        placeholder="mm/dd/yyyy"
      ></ups-kendo-datepicker-shared>
    </div>
    <div class="me-11 mb-5">
      <label for="replacementCost" class="me-5">Replacement Cost</label>
      <kendo-numerictextbox
        id="replacementCost"
        help-anchor="replacementCostField"
        name="replacementCost"
        [(ngModel)]="equipmentResource.ReplacementCost"
        [disabled]="!isEditMode"
        [decimals]="2"
        [format]="'n2'"
        [min]="-999999999.99"
        [max]="999999999.99"
      ></kendo-numerictextbox>
    </div>
    <div class="me-11 mb-5">
      <label for="expectedLife" class="me-5">Expected Life</label>
      <div class="d-flex">
        <kendo-numerictextbox
          id="expectedLife"
          help-anchor="expectedLifeField"
          name="expectedLife"
          class="me-7"
          [(ngModel)]="equipmentResource.ExpectedLife"
          [disabled]="!isEditMode"
          [decimals]="0"
          [format]="'n0'"
          [min]="0"
          [max]="99"
        ></kendo-numerictextbox>
        <kendo-dropdownlist
          id="expectedLifeTimeFrame"
          class="w-300"
          help-anchor="expectedLifeDD"
          name="expectedLifeTimeFrame"
          [(ngModel)]="equipmentResource.ExpectedLifeTimeFrame"
          [disabled]="!isEditMode"
          [data]="[
            { text: 'Y-Years', value: 'Y' },
            { text: 'M-Months', value: 'M' }
          ]"
          [textField]="'text'"
          [valueField]="'value'"
          [valuePrimitive]="true"
        ></kendo-dropdownlist>
      </div>
    </div>
    <div class="me-11 mb-5">
      <label for="currentAppraisal" class="me-5">Current Appraisal</label>
      <kendo-numerictextbox
        id="currentAppraisal"
        help-anchor="currentAppraisalField"
        name="currentAppraisal"
        [(ngModel)]="equipmentResource.CurrentAppraisal"
        [disabled]="!isEditMode"
        [decimals]="2"
        [format]="'n2'"
        [min]="-999999999.99"
        [max]="999999999.99"
      ></kendo-numerictextbox>
    </div>
    <div class="me-11 mb-5">
      <label for="soldDate" class="me-5">Sold Date</label>
      <ups-kendo-datepicker-shared
        id="soldDate"
        help-anchor="soldDate"
        name="soldDate"
        [(ngModel)]="equipmentResource.SoldDate"
        [disabled]="!isEditMode"
        placeholder="mm/dd/yyyy"
      ></ups-kendo-datepicker-shared>
    </div>
    <div class="me-11 mb-5">
      <label for="salePrice" class="me-5">Sale Price</label>
      <kendo-numerictextbox
        id="salePrice"
        help-anchor="salePriceField"
        name="salePrice"
        [(ngModel)]="equipmentResource.SalePrice"
        [disabled]="!isEditMode"
        [decimals]="2"
        [format]="'n2'"
        [min]="-999999999.99"
        [max]="999999999.99"
      ></kendo-numerictextbox>
    </div>
    <div class="me-11 mb-5">
      <label for="balanceAtTheTimeOfSale" class="me-5">
        Balance at the time of sale
      </label>
      <kendo-numerictextbox
        id="balanceAtTheTimeOfSale"
        help-anchor="balanceAtTheTimeOfSaleField"
        name="balanceAtTheTimeOfSale"
        [(ngModel)]="equipmentResource.BalanceAtTheTimeOfSale"
        [disabled]="!isEditMode"
        [decimals]="2"
        [format]="'n2'"
        [min]="-999999999.99"
        [max]="999999999.99"
      ></kendo-numerictextbox>
    </div>
    <div>
      <div class="wrap">
        <input
          id="capitalized"
          help-anchor="capitalizedCheckbox"
          name="capitalized"
          type="checkbox"
          kendoCheckBox
          [(ngModel)]="capitalizedCheckboxValue"
          [disabled]="!isEditMode"
        />
        <label for="capitalized" class="k-checkbox-label">Capitalized</label>
      </div>
    </div>
  </div>
</form>
