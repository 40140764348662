import { RequestedClassWithCountDto } from '.';

export class JobClassification {
  requestedTradeClassID: string;
  requestedLinkedClassID: string;
  requestedClassName: string;
  requestedClassNameWithCount: string;

  constructor(item: RequestedClassWithCountDto) {
    this.requestedTradeClassID = item.jobRequestedClass.requestedTradeClassID;
    this.requestedLinkedClassID = item.jobRequestedClass.requestedLinkedClassID;
    this.requestedClassNameWithCount =
      item.jobRequestedClass.requestedClassName + ' (' + item.count + ')';
    this.requestedClassName = item.jobRequestedClass.requestedClassName;
  }
}
