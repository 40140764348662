import { AbsorbEnrollmentDto } from './../models';
import { Directive } from '@angular/core';
import { environment } from '@ups/xplat/core';
import { BaseComponent } from '@ups/xplat/core';
import { EmployeeAbsorbService, EmployeeService } from '../services';
import {
  process,
  SortDescriptor,
  State,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { nameOf } from '@ups/xplat/utils';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import { ProfileModel } from '@ups/xplat/api/dto';

@Directive()
export abstract class ProfileAbsorbBaseComponent extends BaseComponent {
  categoryNames: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  courses: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  craftTestingDepartments: any[];
  currentProfile: ProfileModel;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  department: any;
  enrollments: AbsorbEnrollmentDto[];
  gridView: GridDataResult;
  isLoading = false;
  selectedFilterCategoryNames: string[] = [];
  shouldPrefilterSkillsAndOnboardingCourses = false; // US-8419
  state: State = {
    skip: 0,
    take: 15,
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: <SortDescriptor[]>[
      {
        field: nameOf<AbsorbEnrollmentDto>('CourseName'),
        dir: 'asc',
      },
    ],
  };
  text = 'ProfileBasic';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any;

  constructor(
    protected absorbService: EmployeeAbsorbService,
    protected employeeInfo: EmployeeService,
    protected securityService: SecurityService
  ) {
    super();
    this.shouldPrefilterSkillsAndOnboardingCourses =
      securityService.getFeatureById(
        SecurityConstants.resource_allocation_employee_absorb_tab_prefilterskillandonboardingcourses
      ).readAll && !(environment.name.indexOf('My UPS') > -1);
  }

  ngOnInit() {
    this.isLoading = true;
    if (this.currentProfile) {
      // TODO: rework web impl to be cleaner
      const email = this.currentProfile.udUPSEmail
        ? this.currentProfile.udUPSEmail
        : this.currentProfile.email;
      this.init(email);
    }
  }

  init(email: string) {
    this.absorbService.getUser(email).subscribe((results) => {
      if (results) {
        this.user = results;

        this.absorbService
          .getDepartment(this.user.DepartmentId)
          .subscribe((department) => {
            this.department = department;
          });

        this.absorbService
          .getUserEnrollments(this.user.Id)
          .subscribe((enrollments) => {
            this.enrollments = enrollments;
            this.data = enrollments;
            this.categoryNames = Array.from(
              new Set(enrollments.map((e) => e.CategoryName))
            ); // distinct list of Cateogory Names

            // US-8419 this is to pre-filter ACTIVE courses in the Skills and Onboarding categories
            if (this.shouldPrefilterSkillsAndOnboardingCourses) {
              this.selectedFilterCategoryNames = this.categoryNames.filter(
                (e) =>
                  e?.toLocaleLowerCase()?.includes('skill') ||
                  e?.toLocaleLowerCase()?.includes('onboarding') ||
                  e?.toLocaleLowerCase()?.includes('craft testing')
              );
              this.state.filter = {
                logic: 'and',
                filters: [
                  {
                    logic: 'or',
                    filters: [
                      {
                        field: nameOf<AbsorbEnrollmentDto>('DateExpires'),
                        operator: 'gte',
                        value: new Date(new Date().toDateString()),
                      },
                      {
                        field: nameOf<AbsorbEnrollmentDto>('DateExpires'),
                        operator: 'eq',
                        value: null,
                      },
                    ],
                  },
                  {
                    logic: 'or',
                    filters: this.selectedFilterCategoryNames.map(
                      (c) =>
                        ({
                          field: nameOf<AbsorbEnrollmentDto>('CategoryName'),
                          operator: 'eq',
                          value: c,
                        } as FilterDescriptor)
                    ),
                  },
                ],
              };
            }

            this.gridView = process(this.enrollments, this.state);
            this.isLoading = false;
          });
      } else {
        this.enrollments = [];
        this.isLoading = false;
      }
    });
  }

  abstract set data(value: Array<AbsorbEnrollmentDto>);
}
