import { Component } from '@angular/core';

import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import { BaseComponent, environment } from '@ups/xplat/core';

@Component({
  selector: 'ups-help-documents',
  templateUrl: 'help-documents.component.html',
})
export class HelpDocumentsComponent extends BaseComponent {
  filePath: string;

  canEdit = false;

  constructor(private securityService: SecurityService) {
    super();
    this.filePath =
      environment.helpDocumentsDirName ||
      (environment.displayName || environment.name) + '-help-documents';
    this.canEdit = this.securityService.getFeatureById(
      SecurityConstants.employee_portal_frameworkfeatures_helpdocuments
    ).editAll;
  }
}
