import { Component, inject, OnInit } from '@angular/core';
import { DynamicItemBaseComponent } from '@ups/xplat/features';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';

@Component({
  selector: 'ups-dynamic-last-modified',
  template: `
    <p *ngIf="canView">
      Last Modified
      {{
        dynamicService?.activeFormResponse?.modifiedDate + 'Z'
          | date: 'M/d/yy, h:mm a':'CST'
      }}
      (CST) by
      {{ dynamicService?.activeFormResponse?.modifiedByName }}
    </p>
  `,
})
export class DynamicLastModifiedComponent
  extends DynamicItemBaseComponent
  implements OnInit
{
  securityConstants = SecurityConstants;
  securityService = inject(SecurityService);
  canView = false;

  ngOnInit(): void {
    this.canView = this.securityService.getFeatureById(
      SecurityConstants.safety_2_0_forms_lastmodifieddetails
    ).viewFeature;
  }
}
