import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FilterByPipe } from './filter-by.pipe';
import { CheckboxFilterPipe } from './checkbox-filter.pipe';
import { NonNullPipe } from './non-null.pipe';
import { DateFilter } from './date-filter.pipe';

@NgModule({
  declarations: [
    // Components / Directives/ Pipes
    FilterByPipe,
    CheckboxFilterPipe,
    NonNullPipe,
    DateFilter,
  ],
  exports: [
    FilterByPipe,
    CheckboxFilterPipe,
    NonNullPipe,
    DateFilter,
  ],
  imports: [CommonModule],
})
export class FilterPipeModule {}
