import { BaseModel } from '../base.model';
import { FileDataModel } from '../sparta-api/fileData-model';

export class FitModel extends BaseModel {
  isNewItem: boolean;
  editing: boolean;
  isSelected: boolean;
  showFiles: boolean;

  KeyID: number;
  HRRef: number;

  Code: string;
  SkillTester: string;
  CertDate: Date;
  ExpireDate: Date;

  udFFModel: string;
  udFFMfg: string;
  udFFSize: string;
  udFFModel2: string;
  udFFMfg2: string;
  udFFSize2: string;
  udFFModel3: string;
  udFFMfg3: string;
  udFFSize3: string;
  udHMModel: string;
  udHMMfg: string;
  udHMSize: string;
  udHMModel2: string;
  udHMMfg2: string;
  udHMSize2: string;
  udHMModel3: string;
  udHMMfg3: string;
  udHMSize3: string;

  Files: FileDataModel[] = [];
  bActive: boolean = true;
  Delete: boolean = false;

  editable: any;

  constructor(o: any = null) {
    super(o);
  }
}
