import { BaseModel } from '../../base.model';
import * as moment from 'moment';

export class SpartaSyncStateDto extends BaseModel {
  public MethodName: string;
  public FriendlySyncName: string;
  public IsRunning: boolean;
  public Log: string;
  public IsError: boolean;
  public LastSyncedAtString: string;
  public TimeElapsedString: string;
  public FriendlySyncDescription: string;
  public IsDisabled: boolean;

  // Calculated Fields
  public Message1: string;
  public Message2: string;
  public ErrorMessage: string;
  public SecondsSinceEndOfSync: number;

  update(o: SpartaSyncStateDto) {
    this.MethodName = o.MethodName;
    this.FriendlySyncName = o.FriendlySyncName;
    this.IsRunning = o.IsRunning;
    this.Log = o.Log;
    this.IsError = o.IsError;
    this.LastSyncedAtString = o.LastSyncedAtString;
    this.TimeElapsedString = o.TimeElapsedString;
    this.FriendlySyncDescription = o.FriendlySyncDescription;
    this.Message1 = this.GetMessage1();
    this.Message2 = this.GetMessage2();
    this.ErrorMessage = this.GetErrorMessage();
    this.IsDisabled = o.IsDisabled;
    this.SecondsSinceEndOfSync =
      o.LastSyncedAtString && !o.IsRunning
        ? moment().diff(moment(o.LastSyncedAtString), 'seconds')
        : 0;
  }

  constructor(o: any = null) {
    super(o);
  }

  private GetErrorMessage(): string {
    return this.IsError ? 'FAILED' : '';
  }

  private GetMessage1(): string {
    return this.IsRunning
      ? 'Sync is in progress ...'
      : this.LastSyncedAtString
      ? this.showDateTimeAsLocalDateTime(this.LastSyncedAtString)
      : 'N/A';
  }

  private GetMessage2(): string {
    return this.IsRunning
      ? this.TimeElapsedString
      : this.LastSyncedAtString
      ? this.getDiffTimeFriendly(this.LastSyncedAtString)
      : 'N/A';
  }

  showDateTimeAsLocalTimeCustomFormat(dateTime: string, format: string) {
    if (!dateTime || !format) {
      return dateTime;
    }

    return moment(dateTime).format(format); // this will show time in local time - in timezone set in browser
  }

  // on server - side the time formatted by DateTimeCommon.FormatUtcDateTimeWithTimeZone() shows the time in local format as string
  showDateTimeAsLocalDateTime(dateTime: string): string {
    if (!dateTime) {
      return dateTime;
    }

    const dateTimeFormat = 'M/D/YYYY h:mm:ss A ';
    return this.showDateTimeAsLocalTimeCustomFormat(dateTime, dateTimeFormat); // this will show time in local time - in timezone set in browser
  }

  getDiffTimeFriendly(dateTime: string): string {
    const now = moment();
    const duration = moment.duration(now.diff(dateTime));
    const days = duration.get('days');
    const months = duration.get('months');
    const years = duration.get('years');
    const yearsText =
      years === 1 ? years + 'year ' : years > 1 ? years + 'years ' : '';
    const monthsText =
      months === 1 ? months + 'month ' : months > 1 ? months + 'months ' : '';
    const daysText =
      days === 1 ? days + 'day ' : days > 1 ? days + 'days ' : '';
    return (
      yearsText +
      monthsText +
      daysText +
      duration.get('hours') +
      'h ' +
      duration.get('minutes') +
      'min ' +
      duration.get('seconds') +
      's ago'
    );
  }
}
