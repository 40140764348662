/* eslint-disable */
import {
  Component,
  Directive,
  ElementRef,
  Injector,
  Input,
  NgZone,
  Optional,
} from '@angular/core';

//
import { ProfileModel } from '@ups/xplat/api/dto';

import { IBaseProfileLoadable } from '../models';
import { ApplicationModel } from '@ups/xplat/api/dto';
import {
  AttachmentVpService,
  DispatchService,
  HRResourceService,
  HRRMService,
  HRRSService,
  HRRTService,
  JobReqService,
  JobsService,
  PerDiemService,
  ProfileService,
  VpService,
} from '@ups/xplat/api/services';
import { AuthService } from '@auth0/auth0-angular';
import { AppConstants, GetDataEvent } from '@ups/xplat/utils';
import { ApplicationEmitter, TableviewService } from '@ups/xplat/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityFeatureGroupModel, SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';

@Directive()
export abstract class BaseProfileLoadable implements IBaseProfileLoadable {
  @Input() security: Partial<SecurityFeatureGroupModel> &
    Partial<SecurityService>;

  profile: ProfileModel;
  @Input() subscribeTo: string;

  public subscription: any;

  securityService: SecurityService;
  securityConstants = SecurityConstants;
  securityFeatureId = '';

  zone: NgZone;
  jobsService: JobsService;
  applicationEmitter: ApplicationEmitter;
  profileService: ProfileService;
  authService: AuthService;
  applicationModel: ApplicationModel;
  applicationConst = AppConstants;

  /* Sparta */
  hrResourceService: HRResourceService;
  jobReqService: JobReqService;
  perDiemService: PerDiemService;
  tableviewService: TableviewService;
  dispatchService: DispatchService;

  /* Viewpoint Service */
  vpService: VpService;
  attachmentVpService: AttachmentVpService;
  hrrmService: HRRMService;
  hrrsService: HRRSService;
  hrrtService: HRRTService;

  router: Router;
  activatedRoute: ActivatedRoute;

  constructor(public injector: Injector, el?: ElementRef) {
    // super(injector, el);

    this.zone = injector.get(NgZone);
    this.jobsService = injector.get(JobsService);
    this.applicationEmitter = injector.get(ApplicationEmitter);
    this.authService = injector.get(AuthService);
    this.profileService = injector.get(ProfileService);

    this.applicationModel = injector.get(ApplicationModel);

    this.hrResourceService = injector.get(HRResourceService);
    this.jobReqService = injector.get(JobReqService);
    this.perDiemService = injector.get(PerDiemService);

    this.attachmentVpService = injector.get(AttachmentVpService);
    this.vpService = injector.get(VpService);
    this.hrrmService = injector.get(HRRMService);
    this.hrrsService = injector.get(HRRSService);
    this.hrrtService = injector.get(HRRTService);

    this.router = injector.get(Router);
    this.activatedRoute = injector.get(ActivatedRoute);

    this.securityService = injector.get(SecurityService);

    this.tableviewService = injector.get(TableviewService);
    this.dispatchService = injector.get(DispatchService);

    this.security = this.securityService;
  }

  getDataComplete(): void {
    this.zone.run(() => {
      //bug with re-render
      console.log('enabled fix bug in rerender');
    });
  }

  render(): void {
    let profile = this.profile;
  }

  getData(event: GetDataEvent): void {
    console.log('------------WARNING!!!---------');
    console.log('------------OVERRIDE GETDATA()---------');
    console.log('------------WARNING!!!---------');
  }

  ngOnInit(): void {
    this.render();
    if (this.subscribeTo) {
      console.log('subscribed:' + this.subscribeTo);
      this.subscription = this.applicationEmitter.getDataEmitter.subscribe(
        (id) => this.getData(id)
      );
    }
  }

  ngOnDestroy(): void {
    if (this.subscribeTo) {
      if (this.subscription && !this.subscription.closed) {
        this.subscription.unsubscribe();
      }
    }
  }
}
