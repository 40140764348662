import { Directive, HostListener, ElementRef } from '@angular/core';
import { ReleaseNoteService } from '@ups/xplat/core';

@Directive({
  selector: '[upsShowReleaseButton]',
})
export class ShowReleaseButtonDirective {
  @HostListener('click') async onClick() {
    this.releaseService.getLatestReleaseNote().then((x) => {
      this.releaseService.showReleaseNotesSubject.next(x);
    });
  }

  constructor(private releaseService: ReleaseNoteService, private el: ElementRef) {
    this.el.nativeElement.style.cursor = 'pointer';
  }
}
