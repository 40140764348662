import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import {
  BaseComponent,
  IDynamicModel,
  IDynamicModelGroup,
} from '@ups/xplat/core';
import { takeUntil } from 'rxjs/operators';

import { DynamicRenderService } from '../../services/dynamic-render.service';
import { DynamicEventBusTypes } from '../../utils';

@Directive()
export class DynamicContainerBaseComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  form: UntypedFormGroup;
  @Input() dynamicGroup: IDynamicModelGroup;
  @Input() isProperties: boolean;
  @Input() showWhatsNew: boolean;

  constructor(
    protected fb: UntypedFormBuilder,
    public dynamicService: DynamicRenderService
  ) {
    super();
  }

  get dynamicItems(): IDynamicModel[] {
    if (this.dynamicGroup && this.dynamicGroup.controls) {
      return this.dynamicGroup.controls;
    } else {
      return [];
    }
  }

  ngOnInit() {
    this.dynamicService.eventBus
      .observe(DynamicEventBusTypes.dynamicFormTotalReset)
      .pipe(takeUntil(this.destroy$))
      .subscribe(this._resetActiveForm.bind(this));
    if (this.isProperties) {
      this.dynamicService.activeFormProperties = this.form = this.fb.group({});
    } else {
      this._resetActiveForm();
    }
  }

  private _resetActiveForm() {
    this.dynamicService.clearActiveForm(true);
    this.dynamicService.activeForm = this.form = this.fb.group({});
    this.dynamicService.eventBus.emit(DynamicEventBusTypes.dynamicFormReady);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    // clear any active attachments
    this.dynamicService.activeAttachments = {};
  }
}
