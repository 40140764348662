import { BaseModel } from '../../base.model';

export class EquipmentRequirementStatusDto extends BaseModel {
  public EquipmentRequirementStatusID: string;
  public Name: string;
  public bActive: boolean;
  public isFinalStatus: boolean | null;
  public isFinalSubtasks: boolean | null;

  constructor(o: any = null) {
    super(o);
  }
}
