import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TreeViewModule } from '@progress/kendo-angular-treeview';

import { EmptyItemPlaceholderDirective } from './empty-item-placeholder.directive';

@NgModule({
  declarations: [EmptyItemPlaceholderDirective],
  imports: [CommonModule, TreeViewModule],
  exports: [TreeViewModule, EmptyItemPlaceholderDirective],
  providers: [],
})
export class TreeViewExModule {}
