import { NotificationModule } from '@progress/kendo-angular-notification';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';

import {
  ButtonModule,
  ButtonsModule,
} from '@progress/kendo-angular-buttons';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import {
  DropDownListModule,
  DropDownsModule,
} from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import {
  CheckBoxModule,
  InputsModule,
} from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import {
  CardModule,
  DrawerModule,
  LayoutModule,
} from '@progress/kendo-angular-layout';
import { MenuModule as KendoMenuModule } from '@progress/kendo-angular-menu';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { IconsModule } from '@progress/kendo-angular-icons';
import { UtilsModule } from '@progress/kendo-angular-utils';
import { BreadCrumbModule } from '@progress/kendo-angular-navigation';

import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';

import { UserModule } from '@ups/user';
import { InAppHelpModule } from '@ups/xplat/base/in-app-help';
import { TableviewService } from '@ups/xplat/core';
import { UISharedModule } from '@ups/xplat/features';
// import { AgmCoreModule } from '@agm/core';

import { UI_COMPONENTS } from './components';
import { UI_PIPES } from './pipes';
import { DIRECTIVES } from './directives';
import { DropDownsModuleEx } from '../kendo';

import { MentionModule } from 'angular-mentions';
import { XplatHotkeysModule } from '@ups/xplat/hotkeys';
import { KendoDatepickerSharedModule } from '../kendo-datepicker/kendo-datepicker-shared.module';

const MODULES = [
  BreadCrumbModule,
  ButtonModule,
  ButtonsModule,
  CardModule,
  CheckBoxModule,
  CommonModule,
  DashboardLayoutModule,
  DatePickerModule,
  DialogsModule,
  DrawerModule,
  DropDownListModule,
  DropDownsModule,
  DropDownsModuleEx,
  IconsModule,
  FormsModule,
  GridModule,
  InAppHelpModule,
  InputsModule,
  KendoMenuModule,
  LabelModule,
  LayoutModule,
  MentionModule,
  NotificationModule,
  PopupModule,
  ReactiveFormsModule,
  RouterModule,
  TooltipModule,
  UtilsModule,
  XplatHotkeysModule,
  UISharedModule,
  UploadsModule,
  UserModule,
];

@NgModule({
  imports: [
    ...MODULES,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyC88EqC4Fa48ciljyds6usLtU8A72SXv7c',
    //   libraries: ['places'],
    // }),
    KendoDatepickerSharedModule,
  ],
  declarations: [
    ...UI_COMPONENTS,
    ...UI_PIPES,
    ...DIRECTIVES,
  ],
  exports: [
    ...MODULES,
    ...UI_COMPONENTS,
    ...UI_PIPES,
    ...DIRECTIVES,
  ],
  providers: [TableviewService],
})
export class UIModule {}
