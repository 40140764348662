<ups-popover #popover>
    <i popoverLink class="fa fa-filter filter" [ngClass]="{'filtered' : isFiltered }" aria-hidden="true"></i>
    <div popoverContent id="filter-popover">
        <div class="input-group input-group-sm input-group-transparent input-group-rounded">
            <span class="input-group-addon"><i class="fa fa-search"></i></span>
            <input class="form-control form-control-sm" id="employee-search-input" type="text" placeholder="Search"
                   [(ngModel)]="search">
        </div>
        <div class="action-buttons">
            <button class="btn btn-danger" (click)="clear()">Clear</button>
            <button class="btn btn-primary pull-right" (click)="cancel(); popover.toggle(false)">Cancel</button>
            <button class="btn btn-success pull-right margin-right-2" (click)="confirm(); popover.toggle(false)">OK</button>
        </div>
    </div>
</ups-popover>