<ups-header title="Equipment Dispatch"></ups-header>

<div class="container-fluid mt-3 condensed-view">
  <div class="row">
    <div class="col-12 col-xl-6">
      <label>Job</label>
      <ups-job-search (valueChange)="valueChangeJob($event)"></ups-job-search>
    </div>
    <div class="col-12 col-xl-6">
      <label>Billing Template:</label>
      <kendo-combobox
        name="billingTemplate"
        class="w-100"
        style="height: 2.625rem"
        [(ngModel)]="selectedBillingTemplate"
        [disabled]="!selectedJob"
        [valuePrimitive]="false"
        [textField]="'text'"
        [valueField]="'value'"
        [filterable]="false"
        [placeholder]="'Select Billing Template'"
        [clearButton]="false"
        [data]="templateHistory"
        (valueChange)="valueChangeTemplate()"
      ></kendo-combobox>
    </div>
  </div>
</div>

<div *ngIf="canRead" class="container-fluid mt-2 condensed-view">
  <section class="widget" widget>
    <div
      id="equipmentDispatchGrid"
      [style.height.px]="height - pixelsToFitTable"
    >
      <kendo-grid
        grid-grouping-ex
        [ggx-viewModel]="viewModel"
        grid-grouping-ex-selection-support
        [selectable]="{ mode: 'multiple', checkboxOnly: true }"
        [data]="view"
        [sortable]="true"
        [filterable]="'menu'"
        [resizable]="true"
        [groupable]="true"
        [reorderable]="true"
        [pageable]="{ pageSizes: [10, 25, 50, 100, 200] }"
        [pageSize]="gridState.take"
        [skip]="gridState.skip"
        [sort]="gridState.sort"
        [group]="gridState.group"
        [filter]="gridState.filter"
        (dataStateChange)="gridDataStateChange($event)"
        (selectionChange)="selectionChange($event)"
        [style.height]="'100%'"
        [loading]="isLoading"
        #grid
        help-anchor="grid-equipment-dispatch"
        class="white-bg toolbar-no-border high-header fit-to-screen-xl"
      >
        <ng-template kendoGridToolbarTemplate>
          <ups-grid-toolbar
            [xlsxDownloadUri]="getXlsxDownloadUri"
            [isExportEnabled]="selectedJob"
            [isExportButtonEnabled]="selectedJob"
            (reloadClick)="loadData()"
            [showAdd]="canModify"
            [isAddDisabled]="!selectedJob || !selectedJob.JobID"
            (addClick)="editModal.open(null)"
            [showEdit]="true"
            [editText]="canModify ? 'Edit' : ' Detail'"
            [isEditDisabled]="!hasSelection"
            (editClick)="openEditModal()"
            [showDelete]="canModify"
            [isDeleteDisabled]="!hasSelection"
            [deleteConfirmMessage]="
              'Do you really want to Delete the selected Equipment Dispatch?'
            "
            (deleteConfirmClick)="delete(selectedRecord)"
            [(multiSearchValue)]="gridSearchValue"
            [multiSearchColumns]="[
              'EquipmentName',
              'AltID',
              'CustomerID',
              'Note'
            ]"
            [multiSearchPlaceholder]="
              'Search Equipment, Alt ID, Customer ID, or Note'
            "
            [multiSearchTitle]="
              'Search Equipment, Alt ID, Customer ID, or Note'
            "
            (multiSearchChange)="extraFilterStateChange($event)"
            [gridState]="gridState"
            (gridStateChange)="gridDataStateChange($event)"
            [tableViewKey]="'EmployeePortal_Equipment_Dispatch'"
            [tableViewSecurity]="
              security.getFeatureById(
                securityConstants.employee_portal_equipment_equipmentdispatchcustomview
              )
            "
          ></ups-grid-toolbar>
        </ng-template>

        <kendo-grid-checkbox-column
          [width]="25"
          [resizable]="false"
          [includeInChooser]="false"
        ></kendo-grid-checkbox-column>

        <kendo-grid-column title="Equipment" field="EquipmentName" width="160">
          <ng-template
            kendoGridGroupHeaderTemplate
            let-field
            let-value
            let-dataItem
          >
            <ggx-group-selection-header
              [dataItem]="dataItem"
              [fieldName]="'Equipment'"
            ></ggx-group-selection-header>
          </ng-template>
          <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
            <span
              *ngIf="
                !dataItem.EquipmentKeyIDs ||
                !dataItem.EquipmentKeyIDs.length ||
                !canSeeEquipmentHyperlink
              "
              title="{{ dataItem.EquipmentName }}"
            >
              {{ dataItem.EquipmentName }}
            </span>
            <a
              *ngIf="
                dataItem.EquipmentKeyIDs &&
                dataItem.EquipmentKeyIDs.length === 1 &&
                canSeeEquipmentHyperlink
              "
              target="_blank"
              [routerLink]="[
                '/resources/equipment-page',
                dataItem.EquipmentKeyIDs[0]
              ]"
            >
              <span
                class="btn-link"
                title="Click to open the equipment in a new browser tab."
              >
                {{ dataItem.EquipmentName }}
              </span>
            </a>
            <ng-template
              *ngIf="
                dataItem.EquipmentKeyIDs &&
                dataItem.EquipmentKeyIDs.length > 1 &&
                canSeeEquipmentHyperlink
              "
            >
              <span title="{{ dataItem.EquipmentName }}">
                {{ dataItem.EquipmentName }}
              </span>
              <a
                *ngFor="let keyId of dataItem.EquipmentKeyIDs"
                target="_blank"
                [routerLink]="['/resources/equipment-page', keyId]"
              >
                <span
                  class="btn-link"
                  title="Click to open the equipment in a new browser tab."
                >
                  [{{ keyId }}]
                </span>
              </a>
            </ng-template>
          </ng-template>
          <ng-template kendoGridHeaderTemplate let-column>
            <span title="{{ column.title }}">{{ column.title }}</span>
          </ng-template>
          <ng-template
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService"
          >
            <kendo-grid-string-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
            >
              <kendo-filter-eq-operator></kendo-filter-eq-operator>
              <kendo-filter-neq-operator></kendo-filter-neq-operator>
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-menu>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Alt ID" field="AltID" width="100">
          <ng-template
            kendoGridGroupHeaderTemplate
            let-field
            let-value
            let-dataItem
          >
            <ggx-group-selection-header
              [dataItem]="dataItem"
              [fieldName]="'Alt ID'"
            ></ggx-group-selection-header>
          </ng-template>
          <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
            <span title="{{ dataItem.AltID }}">{{ dataItem.AltID }}</span>
          </ng-template>
          <ng-template kendoGridHeaderTemplate let-column>
            <span title="{{ column.title }}">{{ column.title }}</span>
          </ng-template>
          <ng-template
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService"
          >
            <kendo-grid-string-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
            >
              <kendo-filter-eq-operator></kendo-filter-eq-operator>
              <kendo-filter-neq-operator></kendo-filter-neq-operator>
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-menu>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Customer ID" field="CustomerID" width="100">
          <ng-template
            kendoGridGroupHeaderTemplate
            let-field
            let-value
            let-dataItem
          >
            <ggx-group-selection-header
              [dataItem]="dataItem"
              [fieldName]="'Customer ID'"
            ></ggx-group-selection-header>
          </ng-template>
          <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
            <span title="{{ dataItem.CustomerID }}">
              {{ dataItem.CustomerID }}
            </span>
          </ng-template>
          <ng-template kendoGridHeaderTemplate let-column>
            <span title="{{ column.title }}">{{ column.title }}</span>
          </ng-template>
          <ng-template
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService"
          >
            <kendo-grid-string-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
            >
              <kendo-filter-eq-operator></kendo-filter-eq-operator>
              <kendo-filter-neq-operator></kendo-filter-neq-operator>
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-menu>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          title="Start Date"
          field="StartDate"
          filter="date"
          type="date"
          format="{0:d}"
          width="95"
        >
          <ng-template
            kendoGridGroupHeaderTemplate
            let-field
            let-value
            let-dataItem
          >
            <ggx-group-selection-header
              [dataItem]="dataItem"
              [showLabel]="false"
            ></ggx-group-selection-header>
            <div style="font-weight: initial">
              <span>
                Start Date:
                <strong>{{ dataItem.value | date: 'MM/dd/yyyy' }}</strong>
              </span>
            </div>
          </ng-template>
          <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
            <span title="{{ dataItem.StartDate | date: 'MM/dd/yyyy' }}">
              {{ dataItem.StartDate | date: 'MM/dd/yyyy' }}
            </span>
          </ng-template>
          <ng-template kendoGridHeaderTemplate let-column>
            <span title="{{ column.title }}">{{ column.title }}</span>
          </ng-template>
          <ng-template
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService"
          >
            <kendo-grid-date-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
            >
              <kendo-filter-eq-operator></kendo-filter-eq-operator>
              <kendo-filter-neq-operator></kendo-filter-neq-operator>
              <kendo-filter-after-eq-operator></kendo-filter-after-eq-operator>
              <kendo-filter-after-operator></kendo-filter-after-operator>
              <kendo-filter-before-eq-operator></kendo-filter-before-eq-operator>
              <kendo-filter-before-operator></kendo-filter-before-operator>
              <kendo-filter-isnull-operator
                text="Is blank"
              ></kendo-filter-isnull-operator>
              <kendo-filter-isnotnull-operator
                text="Is not blank"
              ></kendo-filter-isnotnull-operator>
            </kendo-grid-date-filter-menu>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          title="End Date"
          field="EndDate"
          filter="date"
          type="date"
          format="{0:d}"
          width="95"
        >
          <ng-template
            kendoGridGroupHeaderTemplate
            let-field
            let-value
            let-dataItem
          >
            <ggx-group-selection-header
              [dataItem]="dataItem"
              [showLabel]="false"
            ></ggx-group-selection-header>
            <div style="font-weight: initial">
              <span>
                End Date:
                <strong>{{ dataItem.value | date: 'MM/dd/yyyy' }}</strong>
              </span>
            </div>
          </ng-template>
          <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
            <span title="{{ dataItem.EndDate | date: 'MM/dd/yyyy' }}">
              {{ dataItem.EndDate | date: 'MM/dd/yyyy' }}
            </span>
          </ng-template>
          <ng-template kendoGridHeaderTemplate let-column>
            <span title="{{ column.title }}">{{ column.title }}</span>
          </ng-template>
          <ng-template
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService"
          >
            <kendo-grid-date-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
            >
              <kendo-filter-eq-operator></kendo-filter-eq-operator>
              <kendo-filter-neq-operator></kendo-filter-neq-operator>
              <kendo-filter-after-eq-operator></kendo-filter-after-eq-operator>
              <kendo-filter-after-operator></kendo-filter-after-operator>
              <kendo-filter-before-eq-operator></kendo-filter-before-eq-operator>
              <kendo-filter-before-operator></kendo-filter-before-operator>
              <kendo-filter-isnull-operator
                text="Is blank"
              ></kendo-filter-isnull-operator>
              <kendo-filter-isnotnull-operator
                text="Is not blank"
              ></kendo-filter-isnotnull-operator>
            </kendo-grid-date-filter-menu>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          title="Note"
          field="Note"
          width="120"
          [groupable]="false"
        >
          <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
            <span title="{{ dataItem.Note }}">{{ dataItem.Note }}</span>
          </ng-template>
          <ng-template kendoGridHeaderTemplate let-column>
            <span title="{{ column.title }}">{{ column.title }}</span>
          </ng-template>
          <ng-template
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService"
          >
            <kendo-grid-string-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
            >
              <kendo-filter-eq-operator></kendo-filter-eq-operator>
              <kendo-filter-neq-operator></kendo-filter-neq-operator>
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-menu>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          title="Rates"
          width="65"
          field="Rates"
          [sortable]="false"
          [groupable]="false"
          [filterable]="false"
        >
          <ng-template kendoGridHeaderTemplate let-column>
            <span title="{{ column.title }}">{{ column.title }}</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
            <button
              class="k-button small px-3"
              type="button"
              title="Show Equipment Billing Rates"
              kendoTooltip
              (click)="billingRatesModal.open(dataItem)"
            >
              <i class="fa fa-dollar-sign"></i>
              <span
                *ngIf="dataItem.EquipmentIDs.length !== dataItem.Rates?.length"
                class="ms-2 badge badge-warning"
                kendoTooltip
                title="New Revenue Code/Billing Rates available. Click to See Rates Dialog."
              >
                <i class="fa fa-exclamation"></i>
              </span>
            </button>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          title="Log"
          width="65"
          field="Log"
          [sortable]="false"
          [groupable]="false"
          [filterable]="false"
        >
          <ng-template kendoGridHeaderTemplate let-column>
            <span title="{{ column.title }}">{{ column.title }}</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-field let-value let-dataItem>
            <button
              class="k-button-icontext k-button small px-3"
              type="button"
              title="Show Equipment Dispatch Log History"
              kendoTooltip
              (click)="historyModal.open(dataItem.EquipmentDispatchIDs[0])"
            >
              <i class="fa fa-history"></i>
            </button>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </section>
</div>

<ups-equipment-dispatch-edit
  [canModify]="canModify"
  [jobID]="selectedJob?.Id"
  [jobVPCompanyID]="selectedJob?.CompanyID"
  [billingTemplate]="selectedBillingTemplate?.value"
  (saveEquipmentDispatch)="loadData()"
></ups-equipment-dispatch-edit>

<ups-equipment-dispatch-history
  [jobID]="selectedJob?.Id"
></ups-equipment-dispatch-history>

<ups-equipment-billing-rates
  [jobID]="selectedJob?.Id"
  [billingTemplate]="selectedBillingTemplate?.value"
></ups-equipment-billing-rates>
