<kendo-datepicker
  #kendoDatePicker
  ups-helper-id="kendo-datepicker"
  [class.w-100]="isFullWidth"
  [(value)]="value"
  [disabled]="disabled"
  [disabledDates]="disabledDates"
  [format]="displayFormat"
  [min]="min"
  [max]="max"
  [placeholder]="placeholder"
  [readOnlyInput]="readOnlyInput"
  (valueChange)="onDateChange($event)"
  (blur)="onTouched()"
>
  <ng-template
    kendoCalendarNavigationItemTemplate
    let-title
    let-date="date"
    let-view="activeView"
  >
    {{ view === 'month' && date.getMonth() === 0 ? 'Jan' : title }}
  </ng-template>
</kendo-datepicker>
