import { Component, HostListener, Input, OnInit } from '@angular/core';
import { AllowableInputTypesService } from '../../services';
import {
  HotkeyOptions,
  Letter,
  LetterOption,
  OptionsShortcut,
} from './hotkeys.model';

@Component({
  selector: 'ups-hotkeys',
  templateUrl: './hotkeys.component.html',
  styleUrls: ['./hotkeys.component.css'],
})
export class HotkeysComponent implements OnInit {
  @Input()
  options: HotkeyOptions;

  isHotkeysMenuOpened = false;

  optionsShortcutsArr: OptionsShortcut[];

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const isInEditableElement =
      this.allowableInputTypesService.isInEditableElement();
    if (isInEditableElement) {
      return;
    }

    const isShortcutMenuEnabled =
      !this.options.shortcutMenuEnabledCondition ||
      this.options.shortcutMenuEnabledCondition(event);

    const key = event.key.toLowerCase() as Letter | 's';
    if (
      key === 's' &&
      this.isHotkeysMenuOpened === false &&
      isShortcutMenuEnabled
    ) {
      this.openHotkeysMenu();
      return;
    }

    const option: LetterOption = this.options.shortcuts[key];

    if (!option) {
      return;
    }

    const isHotkeyConditionMet =
      !option.hotkeyCondition || option.hotkeyCondition(event);

    if (isHotkeyConditionMet && this.isHotkeysMenuOpened === false) {
      option.hotkeyHandler(event);
    }
  }

  constructor(private allowableInputTypesService: AllowableInputTypesService) {}

  ngOnInit(): void {
    if (!this.options || !this.options.shortcuts) {
      throw new Error(
        'HotkeysComponent: options.shortcuts must be provided to the component.'
      );
    }

    this.optionsShortcutsArr = Object.entries(this.options.shortcuts).map(
      (entry) => {
        return { key: entry[0], description: entry[1].description };
      }
    );
  }

  closeHotkeysMenu() {
    this.isHotkeysMenuOpened = false;
  }

  openHotkeysMenu() {
    this.isHotkeysMenuOpened = true;
  }
}
