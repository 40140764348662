<div *ngIf="!vpEquipment" class="row">
  <span>Loading equipment ...</span>
</div>

<div *ngIf="vpEquipment" class="row">
  <div class="col-sm-2"><label>Name:</label></div>
  <div class="col-sm-5">
    <a
      *ngIf="vpEquipment.KeyId && canSeeEquipmentHyperlink"
      [routerLink]="['/resources/equipment-page', vpEquipment.KeyId]"
      target="_blank"
    >
      <span title="Click to open the equipment in a new browser tab.">
        {{ vpEquipment?.EquipmentName }}
      </span>
    </a>
    <span
      *ngIf="!vpEquipment.KeyId || !canSeeEquipmentHyperlink"
      title="{{ vpEquipment?.EquipmentName }}"
    >
      {{ vpEquipment?.EquipmentName }}
    </span>

    <button
      *ngIf="showEquipmentEditButton"
      kendoButton
      (click)="switchEquipment.emit()"
      class="ms-3"
      icon="edit"
    ></button>
  </div>
  <div class="col-sm-2"><label>Category:</label></div>
  <div class="col-sm-3">
    <p class="mb-0">{{ vpEquipment?.EquipmentCategory }}</p>
  </div>
</div>
<div class="row">
  <div class="col-sm-2"><label>Description:</label></div>
  <div class="col-sm-5">
    <p class="mb-0">{{ vpEquipment?.EquipmentDescription }}</p>
  </div>
  <div class="col-sm-2"><label>Model:</label></div>
  <div class="col-sm-3">
    <p class="mb-0">{{ vpEquipment?.EquipmentModel }}</p>
  </div>
</div>
<div class="row">
  <div class="col-sm-3"><label>Manufacture:</label></div>
  <div class="col-sm-4">
    <p class="mb-0">{{ vpEquipment?.EquipmentManufacturer }}</p>
  </div>
  <div class="col-sm-2"><label>Model Year:</label></div>
  <div class="col-sm-3">
    <p class="mb-0">{{ vpEquipment?.EquipmentModelYear }}</p>
  </div>
</div>
<div class="row">
  <div class="col-sm-2"><label>Vin Number:</label></div>
  <div class="col-sm-5">
    <p class="mb-0">{{ vpEquipment?.EquipmentVinNumber }}</p>
  </div>
  <div class="col-sm-2"><label>Status:</label></div>
  <div class="col-sm-3">
    <p class="mb-0">{{ vpEquipment?.EquipmentStatus }}</p>
  </div>
</div>
