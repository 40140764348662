<ng-template #popupTemplate>
  <div class="content p-4">
    <div class="d-flex">
      <div (click)="end()" class="cursor-pointer">
        <i class="fas fa-times"></i>
      </div>
      <div class="ms-auto">
        {{ stepIdx + 1 }}/{{ walkthrough.steps.length }}
      </div>
    </div>
    <hr class="mx-0" />
    <div>{{ step.title }}</div>
    <div [innerHTML]="step.html | safe: 'html'"></div>
    <hr class="mx-0" />
    <div class="d-flex">
      <button
        (click)="decrement()"
        class="me-2 k-button k-button-sm"
        *ngIf="stepIdx !== 0"
      >
        Previous
      </button>
      <button
        (click)="increment()"
        class="k-button k-button-sm k-primary"
        *ngIf="!final && showNext"
      >
        Next
      </button>
      <button
        (click)="end()"
        class="k-button k-button-sm k-primary"
        *ngIf="final"
      >
        End
      </button>
    </div>
  </div>
</ng-template>
<kendo-dialog
  *ngIf="show && walkthrough && !step"
  [title]="walkthrough.title"
  (close)="end()"
>
  <div [innerHTML]="walkthrough.html | safe: 'html'"></div>
  <div class="d-flex">
    <button
      kendoButton
      class="me-auto"
      (click)="edit()"
      *ngIf="
        security.getFeatureById(
          securityConsts.employee_portal_frameworkfeatures_walkthroughs
        ).editAll
      "
    >
      Edit Walkthrough
    </button>
    <button
      kendoButton
      class="ms-2"
      [primary]="true"
      (click)="start()"
      *ngIf="
        security.getFeatureById(
          securityConsts.employee_portal_frameworkfeatures_walkthroughs
        ).readAll
      "
    >
      Begin Walkthrough
    </button>
  </div>
</kendo-dialog>
