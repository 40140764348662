export class UserDto {
  Auth0UserId: string;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  FullName: string;
  Email: string;
  HRRef: number;
  SecurityUserId: string;
}
