import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { LogService, ProgressService } from '@ups/xplat/core';
import {
  NearmissBaseComponent,
  DynamicRenderService,
} from '@ups/xplat/features';

@Component({
  selector: 'ups-nearmiss',
  templateUrl: 'nearmiss.component.html',
})
export class NearmissComponent extends NearmissBaseComponent {
  constructor(
    store: Store,
    log: LogService,
    progress: ProgressService,
    dynamicRender: DynamicRenderService
  ) {
    super(store, log, progress, dynamicRender);
  }
}
