import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SortFilterItemsService {
  /**
   * sortFilteredItems takes an object and sorts the Values array contained in the object
   * @param filterData the object that contains the Values array that will be sorted
   * @returns a new object that contains a sorted Values array, or an empty object if
   * incoming object is null
   */
  public sortFilteredItems(filterData: object): object {
    // if filterData is null or undefined, return an empty object
    if (!filterData) return {}; // is this be the right thing to do?

    if (this.isArrayOfTextValueObjects(filterData)) {
      this.sortTextValueObjects(filterData as object[], 'text');
    } else {
      for (const property in filterData) {
        if (filterData[property]?.Values?.length) {
          if (this.isArrayOfStrings(filterData[property].Values)) {
            this.sortStrings(filterData[property].Values);
          } else if (this.isArrayOfNumbers(filterData[property].Values)) {
            this.sortNumbers(filterData[property].Values);
          }
        }
      }
    }

    return filterData;
  }

  public sortArray(arrayToSort: unknown[]): void {
    if (this.isArrayOfStrings(arrayToSort)) {
      this.sortStrings(arrayToSort as string[]);
    } else {
      this.sortNumbers(arrayToSort as number[]);
    }
  }

  /**
   * sortStrings takes an array of strings and sort them. If any element in the array contains
   * a null or undefined valued, that element is moved to the bottom of the sorted array
   * @param stringsToSort an array of string that will be sorted
   * @returns There is no return value. The incoming array is sorted in place.
   */
  public sortStrings(stringsToSort: string[]): void {
    stringsToSort.sort((a: string, b: string) => {
      // a.localCompare() method called below can not handle null strings
      // so we have to deal with them first
      // this will in effect move all null values to the bottom of the array
      if (a === null || a === undefined) {
        return 1;
      }

      if (b === null || b === undefined) {
        return -1;
      }

      if ((a === null || a === undefined) && (b === null || b === undefined)) {
        return 0;
      }

      return a.localeCompare(b, undefined, {
        sensitivity: 'base',
        ignorePunctuation: true,
      });
    });
  }

  /**
   * sortNumberss takes an array of numbers and sorts them. If any element in the array contains
   * a null or undefined value, that element is moved to the bottom of the sorted array.
   * @param numbersToSort an array of numbers to be sorted
   * @returns There is no return value. The incoming array is sorted in place.
   */
  public sortNumbers(numbersToSort: number[]): void {
    numbersToSort.sort((a: number, b: number) => {
      if (a === null || a === undefined) {
        return 1;
      }

      if (b === null || b === undefined) {
        return -1;
      }

      if ((a === null || a === undefined) && (b === null || b === undefined)) {
        return 0;
      }

      return a - b;
    });
  }

  public sortTextValueObjects(filterData: object[], textField: string) {
    filterData.sort((o1: object, o2: object) => {
      return o1[textField].localeCompare(o2[textField], undefined, {
        sensitivity: 'base',
        ignorePunctuation: true,
      });
    });
  }

  /**
   * isArrayOfStrings tests whether an array contains a string of alphanumeric characters
   * @param data an array which may or may not contain strings
   * @returns true if the array only contains alphanumeric strings, and
   * false otherwise
   */
  public isArrayOfStrings(data: unknown[]): boolean {
    let returnValue = false;

    if (data.length) {
      data.forEach((element) => {
        if (element !== null && element !== undefined) {
          if (typeof element === 'string') {
            returnValue = true;
          }
        }
      });
    }

    return returnValue;
  }

  /**
   * isArrayOfNumbers tests whether an array constains numbers
   * @param data an array which may or may not contain numbers
   * @returns true if the contents of the array are numbers, and
   * false otherwise
   */
  public isArrayOfNumbers(data: unknown[]): boolean {
    let returnValue = false;

    if (data.length) {
      data.forEach((element) => {
        if (element !== null && element !== undefined) {
          if (typeof element === 'number') {
            returnValue = true;
          }
        }
      });
    }

    return returnValue;
  }

  public isArrayOfTextValueObjects(filterData: object) {
    // check for numeric keys
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Object.keys(filterData).every((key: any) => !isNaN(key));
  }

  public stringContainsNumericCharacters(str: string): boolean {
    return /\d/.test(str);
  }
}
