import { Injectable } from '@angular/core';
import {
  LocalStorageService,
  CacheRecord,
  CacheCollection,
  CacheRecordMap,
  CacheCollectionOptions,
} from '@ups/xplat/core';

@Injectable({ providedIn: 'root' })
export class WebCacheCollectionService {
  private generalUpsCacheCollectionKey = 'ups_cache';
  private appVersion = '1.0'; // pass in from config

  // other possible config options, TODO
  private invalidateAllCacheOnVersionChange = false;
  private maxRecordsPerCollection = 1000;

  constructor(private storageService: LocalStorageService) {}
  public set<T>(options: CacheCollectionOptions<T>): void {
    const expMins = options.expireInMins || 0;
    const expirationMS = expMins !== 0 ? options.expireInMins * 60 * 1000 : 0;

    const newRecord: CacheRecord = {
      value:
        typeof options.data === 'string'
          ? options.data
          : JSON.stringify(options.data),
      expiration:
        expirationMS !== 0 ? new Date().getTime() + expirationMS : undefined,
      appVersion: this.appVersion,
    };

    const cacheCollectionKey =
      options.collectionKey || this.generalUpsCacheCollectionKey;

    const collection = this.getCollectionFromStore(cacheCollectionKey);
    if (collection) {
      collection.collectionRecordMap[options.key] = newRecord;
      this.setCollectionInStore(collection, cacheCollectionKey);
    } else {
      const newCollection = this.createCollection(
        newRecord,
        options.key,
        cacheCollectionKey
      );
      this.setCollectionInStore(newCollection, cacheCollectionKey);
    }
  }

  public get<T>(key: string, cacheColKey?: string): T {
    const cacheCollectionKey = cacheColKey || this.generalUpsCacheCollectionKey;

    const collection = this.getCollectionFromStore(cacheCollectionKey);
    if (collection) {
      const record = collection.collectionRecordMap[key];
      if (record) {
        // Check app version
        if (record.appVersion !== this.appVersion) {
          delete collection.collectionRecordMap[key];
          this.setCollectionInStore(collection, cacheCollectionKey);
          return undefined;
        }

        // Check expiration
        const now = new Date().getTime();

        if (!record || (record.expiration && record.expiration <= now)) {
          delete collection.collectionRecordMap[key];
          this.setCollectionInStore(collection, cacheCollectionKey);
          return undefined;
        } else {
          return JSON.parse(record.value) as T;
        }
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  public remove(key: string, cacheColKey?: string): void {
    const cacheCollectionKey = cacheColKey || this.generalUpsCacheCollectionKey;

    const collection = this.getCollectionFromStore(cacheCollectionKey);
    if (collection) {
      const record = collection.collectionRecordMap[key];
      if (record) {
        delete collection.collectionRecordMap[key];
        this.setCollectionInStore(collection, cacheCollectionKey);
      }
    }
  }

  public clearCollection(cacheCollectionKey: string): void {
    const collection = this.getCollectionFromStore(cacheCollectionKey);
    if (collection) {
      this.storageService.removeItem(cacheCollectionKey);
    }
  }

  public clear(): void {
    this.storageService.clear();
  }

  private createCollection(
    rec: CacheRecord,
    key: string,
    cacheCollectionKey?: string
  ): CacheCollection {
    const colKey = cacheCollectionKey || this.generalUpsCacheCollectionKey;

    const recMap: CacheRecordMap = {};
    recMap[key] = rec;

    const col: CacheCollection = {
      collectionKey: colKey,
      collectionRecordMap: recMap,
    };

    return col;
  }

  private setCollectionInStore(
    col: CacheCollection,
    cacheCollectionKey?: string
  ) {
    const colKey = cacheCollectionKey || this.generalUpsCacheCollectionKey;
    this.storageService.setItem<CacheCollection>(colKey, col);
  }

  private getCollectionFromStore(
    cacheCollectionKey?: string
  ): CacheCollection | undefined {
    const colKey = cacheCollectionKey || this.generalUpsCacheCollectionKey;
    const col = this.storageService.getItem<CacheCollection>(colKey);
    return col;
  }
}
