import {
  Output,
  Directive,
  EventEmitter,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IDynamicSignaturePadOptions } from '@ups/xplat/core';
import { DynamicItemBaseComponent } from '../dynamic-item/dynamic-item.base-component';
// import { IOptions } from 'signature_pad';

@Directive()
export abstract class DynamicSignatureBaseComponent
  extends DynamicItemBaseComponent
  implements OnInit, OnDestroy
{
  @Output() done: EventEmitter<unknown> = new EventEmitter();
  @Output() cleared: EventEmitter<boolean> = new EventEmitter();
  @Output() fullscreen: EventEmitter<boolean> = new EventEmitter();
  @Output() save: EventEmitter<string> = new EventEmitter();
  legalText = `Please sign to indicate you have accepted the submitted values to be true to the best of your knowledge.`;
  signatureImage: string;
  private _defaults: IDynamicSignaturePadOptions = {
    width: 'auto',
    height: 200,
    penColor: 'red',
    dotSize: 2,
    // web pad provides min/max with variable pen
    minWidth: 2,
  };

  ngOnInit() {
    super.ngOnInit();
    if (!this.config.options) {
      this.config.options = {};
    }
    if (!this.config.options.signature) {
      this.config.options.signature = {};
    }
    if (!this.config?.options?.signature) {
      this.config.options.signature = this._defaults;
    }
    // default hint
    if (!this.config.options?.signature?.hint) {
      const hint = 'forms.signature-hint';
      this.translate.get([hint]).subscribe((localize) => {
        this.config.options.signature.hint = localize[hint];
      });
    }
    this.dynamicService.isSignatureEmpty = this.isEmpty.bind(this);
    this.dynamicService.signatureBase64 = this.getBase64.bind(this);
    this.dynamicService.signatureReset = this.reset.bind(this);

    if (this.config?.value) {
      this.dynamicService
        .getFile(<string>this.config.value, false, true)
        .then((data: string) => {
          // this.dynamicService.log.debug('sig data:', data);
          this.signatureImage = data;
        });
    }
  }

  // implemented in platform components
  isEmpty(): boolean {
    return true;
  }

  // implemented in platform components
  getBase64(): Promise<string> {
    return Promise.resolve(null);
  }

  // implemented in platform components
  reset(): boolean {
    return true;
  }

  confirmClear(message?: string) {
    return new Promise((resolve) => {
      this.win
        .confirm({
          title: 'Are you sure?',
          message: message || 'This will clear your signature!',
          okButtonText: 'Ok',
          cancelButtonText: 'Cancel',
        })
        .then((ok) => {
          if (ok) {
            this.dynamicService.removeAttachmentForField(
              this.config.formControlName,
              this.dynamicService.signatureUploadFile
            );
            this.dynamicService.signatureUploadFile = null;
            if (this.config?.required) {
              // ensure required validators are added back when clearing
              this.setRequired();
            }
            resolve(ok);
          }
        })
        .catch(() => {});
    });
  }

  confirmSignature(message?: string) {
    return new Promise((resolve) => {
      this.win
        .confirm({
          title: 'Are you sure?',
          message: message || 'You are about to submit ABC for signature.',
          okButtonText: 'Ok',
          cancelButtonText: 'Cancel',
        })
        .then((ok) => {
          resolve(ok);
        })
        .catch(() => {});
    });
  }

  showSuccess() {
    this.win.setTimeout(() => {
      this.win.alert({
        title: 'Signed!',
        message: 'ABC has been successfully signed.',
        okButtonText: 'Ok, Thanks',
      });
    }, 1600);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    // reset
    this.dynamicService.isSignatureEmpty = null;
    this.dynamicService.signatureBase64 = null;
    this.dynamicService.signatureUploadFile = null;
  }
}
