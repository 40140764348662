import { BaseModel } from '../base.model';

export class FileDataModel extends BaseModel {
  AttachmentID: number;
  OrigFileName: string = '';
  Description: string = '';
  AddDate: Date;
  AddedBy: string = '';
  FileName: string = '';
  isDownloading: boolean = false;

  constructor(o: any = null) {
    super(o);
  }
}
