<span *ngIf="hasReadPermissions && equipmentResource === null">
  Loading ...
</span>
<span *ngIf="!hasReadPermissions">
  You have no permissions to read the Equipment Resource Information.
</span>

<form
  id="equipmentResourceMetersFormId"
  #metersForm="ngForm"
  (ngSubmit)="saveEquipment(metersForm.valid)"
  *ngIf="hasReadPermissions && equipmentResource !== null"
>
  <div class="d-flex align-items-center justify-content-end">
    <div *ngIf="hasReadPermissions && equipmentResource !== null">
      <button
        id="editEquipmentBtn"
        help-anchor="editMetersTabButton"
        *ngIf="!isEditMode && hasEditPermissions"
        type="button"
        class="k-button"
        (click)="editEquipment()"
      >
        Edit
      </button>
      <button
        id="editEquipmentSaveBtn"
        help-anchor="saveButton"
        *ngIf="isEditMode"
        type="button"
        class="k-button k-primary me-3"
        (click)="saveEquipment(metersForm.valid)"
      >
        Save
      </button>
      <button
        id="editEquipmentCancelBtn"
        *ngIf="isEditMode"
        type="button"
        class="k-button"
        (click)="cancelEquipment()"
      >
        Cancel
      </button>
    </div>
  </div>
  <h4 class="mb-5">Hour Meter</h4>
  <div class="d-flex align-items-center justify-content-between mb-7">
    <div>
      <label for="hourReading" class="me-5">Reading</label>
      <kendo-numerictextbox
        id="hourReading"
        help-anchor="readingFieldHM"
        name="hourReading"
        [(ngModel)]="equipmentResource.HourReading"
        [disabled]="!isEditMode"
        [decimals]="2"
        [format]="'n2'"
        [min]="0"
        [max]="99999999.99"
      ></kendo-numerictextbox>
    </div>
    <div>
      <label for="hourDate" class="me-5">Date</label>
      <ups-kendo-datepicker-shared
        id="hourDate"
        help-anchor="dateFieldHM"
        name="hourDate"
        [(ngModel)]="equipmentResource.HourDate"
        [disabled]="!isEditMode"
        placeholder="mm/dd/yyyy"
      ></ups-kendo-datepicker-shared>
    </div>
    <div>
      <label for="replacedHourReading" class="me-5">
        Replaced Meter Reading
      </label>
      <kendo-numerictextbox
        id="replacedHourReading"
        help-anchor="replacedMeterReadingFieldHM"
        name="replacedHourReading"
        [(ngModel)]="equipmentResource.ReplacedHourReading"
        [disabled]="!isEditMode"
        [decimals]="2"
        [format]="'n2'"
        [min]="0"
        [max]="99999999.99"
      ></kendo-numerictextbox>
    </div>
    <div>
      <label for="replacedHourDate" class="me-5">Replacement Date</label>
      <ups-kendo-datepicker-shared
        id="replacedHourDate"
        help-anchor="replacementDateHM"
        name="replacedHourDate"
        [(ngModel)]="equipmentResource.ReplacedHourDate"
        [disabled]="!isEditMode"
        placeholder="mm/dd/yyyy"
      ></ups-kendo-datepicker-shared>
    </div>
  </div>
  <h4 class="mb-5">Odometer</h4>
  <div class="d-flex align-items-center justify-content-between">
    <div>
      <label for="odometerReading" class="me-5">Reading</label>
      <kendo-numerictextbox
        id="odometerReading"
        help-anchor="readingFieldOdometer"
        name="odometerReading"
        [(ngModel)]="equipmentResource.OdometerReading"
        [disabled]="!isEditMode"
        [decimals]="2"
        [format]="'n2'"
        [min]="0"
        [max]="99999999.99"
      ></kendo-numerictextbox>
    </div>
    <div>
      <label for="odometerDate" class="me-5">Date</label>
      <ups-kendo-datepicker-shared
        id="odometerDate"
        help-anchor="dateFieldOdometer"
        name="odometerDate"
        [(ngModel)]="equipmentResource.OdometerDate"
        [disabled]="!isEditMode"
        placeholder="mm/dd/yyyy"
      ></ups-kendo-datepicker-shared>
    </div>
    <div>
      <label for="replacedOdometerReading" class="me-5">
        Replaced Meter Reading
      </label>
      <kendo-numerictextbox
        id="replacedOdometerReading"
        help-anchor="replacedMeterReadingFieldOdometer"
        name="replacedOdometerReading"
        [(ngModel)]="equipmentResource.ReplacedOdometerReading"
        [disabled]="!isEditMode"
        [decimals]="2"
        [format]="'n2'"
        [min]="0"
        [max]="99999999.99"
      ></kendo-numerictextbox>
    </div>
    <div>
      <label for="replacedOdometerDate" class="me-5">Replacement Date</label>
      <ups-kendo-datepicker-shared
        id="replacedOdometerDate"
        help-anchor="replacementDateOdometer"
        name="replacedOdometerDate"
        [(ngModel)]="equipmentResource.ReplacedOdometerDate"
        [disabled]="!isEditMode"
        placeholder="mm/dd/yyyy"
      ></ups-kendo-datepicker-shared>
    </div>
  </div>
</form>
