import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MyHttpClientFactory, environment } from '@ups/xplat/core';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EmployeePacketService {
  httpClient: HttpClient;
  route = '/api/packetforms';
  constructor(httpFactory: MyHttpClientFactory) {
    this.httpClient = httpFactory.createHttpClient(
      environment.urls.packetAPI,
      true
    );
  }

  getPacketDocuments(ssn: string) {
    return this.httpClient
      .get(`${this.route}/listbyssn/${ssn}`)
      .pipe(catchError(this.handleError));
  }

  getPacketDocumentsByHrref(hrref: number) {
    return this.httpClient
      .get(`${this.route}/listbyhrref/${hrref}`)
      .pipe(catchError(this.handleError));
  }

  getAllUniquePacketDocumentsFilenames() {
    return this.httpClient
      .get(`${this.route}/listallunique`)
      .pipe(catchError(this.handleError));
  }

  downloadPdfForm(formId: number) {
    return this.httpClient
      .get(`${this.route}/${formId}/download`, { responseType: 'blob' })
      .pipe(catchError(this.handleError));
  }

  // Error types could be anything
  // https://angular.io/api/core/ErrorHandler
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleError(error: any) {
    console.error('An error occurred', error);
    return Promise.reject(error?.error?.Message || error);
  }
}
