import { Pipe, PipeTransform } from '@angular/core';
import { PhoneRegex } from '@ups/xplat/utils';

@Pipe({
  name: 'phoneNumber',
  pure: true,
})
export class PhoneNumberPipe implements PipeTransform {
  transform(value: string) {
    if (!value) {
      return '';
    }
    const pattern: RegExp = PhoneRegex;
    const match = pattern.exec(value);
    if (match && match.length) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    } else {
      return value;
    }
  }
}
