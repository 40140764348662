import { Component, OnInit } from '@angular/core';
import { DynamicItemBaseComponent } from '@ups/xplat/features';

//[attr.id]="'radio_choice_' + index.toString()"
@Component({
  selector: 'ups-dynamic-radio',
  styleUrls: ['dynamic-radio.component.scss'],
  template: `
    <div class="dynamic-field-container" [class.selected]="config?.selected">
      <div class="dynamic-field form-input text-align" [formGroup]="group">
        <label class="radio-heading" *ngIf="!config?.options?.ignoreLabel">
          {{ config?.label }}
        </label>
        <div *ngIf="!options || !options.length">
          No options specified for the radio group...
        </div>
        <label *ngFor="let option of options || []; let index = index">
          <input
            type="radio"
            [attr.name]="config.formControlName"
            [value]="option"
            [formControlName]="config.formControlName"
          />
          {{ option }}
        </label>
        <div
          class="form-builder-mask"
          [class.hidden]="config?.options?.hidden"
          *ngIf="config?.isFormBuilder"
        ></div>
      </div>
      <div class="form-builder-options" *ngIf="config?.isFormBuilder">
        <i
          class="fas fa-times ml-3 cursor-pointer c-red"
          (click)="delete()"
        ></i>
        <!-- <i class="fas fa-bars ml-3 cursor-grab "></i> -->
      </div>
    </div>
  `,
})
export class DynamicRadioComponent
  extends DynamicItemBaseComponent
  implements OnInit
{
  public options: string[] = [];

  ngOnInit() {
    super.ngOnInit();
    // this.group.addControl(this.config.id, new FormControl());
    if (this.config && this.config.options) {
      // console.log(this.config);
      const opts = this.config.options['radio'];
      // if (typeof opts === 'string') {
      //   this.options = JSON.parse(opts);
      // } else {
      this.options = opts;
      // }
    }
  }
}
