import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BootstrapCalendar } from './bootstrap-calendar.component';

@NgModule({
  imports: [CommonModule],
  declarations: [BootstrapCalendar],
  exports: [BootstrapCalendar],
})
export class BootstrapCalendarModule {}
