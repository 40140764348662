/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  GroupDescriptor,
} from '@progress/kendo-data-query';
import { GroupResultSelectionSupport } from './group-result-selection-support';
import { isDate } from '@ups/xplat/utils';

/**
 * This class could be moved to a generic selection-support.ts file as it could be applied to non-grouping grid as well.
 * Same applies for some parts of the group-result-selection-support.ts!
 */
export class GroupResultSelectionUtils {
  /**
   * This class creates from the selected items inside the grid  a CompositeFilterDescriptor so we can fetch the same data via OData for reporting or other purposes.
   *
   *  NOTE:
   *  If we omit the groups, the resulting composite filter will contain a single FilterDescriptor with an 'in' operator and all the selected keys (array).
   *  With large number of keys the azure URI/query string length will be exceeded (currently it's 8k, but according docs it should be extended to 32K).
   *  Current limit (due JWT token, export settings) is around 150 guids.
   *
   *  If we do provide groups, the function will create more shorter output (but also a more complex query).
   *  It will enable to select the entire groups with a single filter operation.
   *  Handles also cases when just some nested groups are selected or when just some items are selected from the innermost group.
   *  Downside: if other user adds new items, even if they're not on the grid, the new OData call (report) will include those items.
   *
   * @param groups
   * @param items
   * @param keyFieldName
   * @param level
   * @param filter
   */
  public static getFilterForGroupSelection(
    groups: GroupDescriptor[],
    items: any[],
    keyFieldName: string,
    level = 0,
    filter: CompositeFilterDescriptor = { logic: 'or', filters: [] }
  ): CompositeFilterDescriptor {
    // NOTE:
    // grid ensures an item[GroupResultSelectionSupport.selectionStateProperty].checked property
    // based on value: true = full selection; null = partial selection; false = no selection
    // we need following logic...

    groups = groups || [];
    items = items || [];

    //
    // no groups at all or already at item level
    const noGroups = !groups || !groups.length;
    const itemLevel = noGroups || level > groups.length - 1;

    if (itemLevel) {
      /*
      // NOTE: if we're interesetd inside component in filtering based on keys only...

      const selectedItems = GroupResultFlattener.getFlattenedItemsWithDetails(gridItems, null)
        .map(i => i.dataItem)
        .filter(i => i[GroupResultSelectionSupport.selectionStateProperty].checked);

      return {
        field: keyFiledName,
        operator: "in",
        ignoreCase: false,
        ignoreValueCase: false, // NOTE: must for the "in" operator
        value: selectedItems.map(i => i[keyFiledName])
      } as FilterDescriptor;
      */

      const selectedItems = items.filter(
        (i) => i[GroupResultSelectionSupport.selectionStateProperty].checked
      );
      const selectedItemsFilter = {
        field: keyFieldName,
        operator: 'in',
        ignoreCase: false,
        ignoreValueCase: false, // NOTE: must for the "in" operator
        value: selectedItems.map((i) => i[keyFieldName]),
      } as FilterDescriptor;

      filter.filters.push(selectedItemsFilter);
      return filter;
    }

    //
    // current group - items here must be or-ed

    const groupField = groups[level].field;

    const groupCompositeFilter = {
      logic: 'or',
      filters: [],
    } as CompositeFilterDescriptor;

    filter.filters.push(groupCompositeFilter);

    // handle full selection
    const fullySelectedGroupItems = items.filter(
      (i) => i[GroupResultSelectionSupport.selectionStateProperty].checked
    );

    if (fullySelectedGroupItems && fullySelectedGroupItems.length) {
      if (
        fullySelectedGroupItems.map((gi) => gi.value).includes(null) ||
        isDate(fullySelectedGroupItems[0].value)
      ) {
        fullySelectedGroupItems.forEach((gi) => {
          const thisGroupItemValue = gi.value;
          const thisFilter = {
            logic: 'and',
            filters: [
              {
                field: groupField,
                operator: 'eq',
                ignoreCase: false,
                ignoreValueCase: false,
                value: thisGroupItemValue,
              } as FilterDescriptor,
            ],
          } as CompositeFilterDescriptor;

          groupCompositeFilter.filters.push(thisFilter);

          // recursion to extend newly created filter
          this.getFilterForGroupSelection(
            groups,
            gi.items,
            keyFieldName,
            level + 1,
            thisFilter
          );
        });
      } else {
        const fullySelectedGroupItemsFilter = {
          field: groupField,
          operator: 'in',
          ignoreCase: false,
          ignoreValueCase: false, // NOTE: must for the "in" operator
          value: fullySelectedGroupItems.map((gi) => gi.value),
        } as FilterDescriptor;

        groupCompositeFilter.filters.push(fullySelectedGroupItemsFilter);
      }
    }

    // partial selection (needs nesting)
    const partiallySelectedGroupItems = items.filter(
      (i) =>
        i[GroupResultSelectionSupport.selectionStateProperty].checked === null
    );

    partiallySelectedGroupItems.forEach((gi) => {
      const thisGroupItemValue = gi.value;
      const thisFilter = {
        logic: 'and',
        filters: [
          {
            field: groupField,
            operator: 'eq',
            ignoreCase: false,
            ignoreValueCase: false,
            value: thisGroupItemValue,
          } as FilterDescriptor,
        ],
      } as CompositeFilterDescriptor;

      groupCompositeFilter.filters.push(thisFilter);

      // recursion to extend newly created filter
      this.getFilterForGroupSelection(
        groups,
        gi.items,
        keyFieldName,
        level + 1,
        thisFilter
      );
    });

    return filter;
  }
}
