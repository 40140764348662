import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  BaseComponent,
  IDynamicContainer,
  IDynamicModelGroup,
} from '@ups/xplat/core';

import {
  DynamicRenderService,
  EditModalBaseComponent,
} from '@ups/xplat/features';
import { assertType } from '@ups/xplat/utils';
import { DynamicContainerComponent } from '../../containers';

@Component({
  selector: 'ups-dynamic-edit-modal',
  templateUrl: 'dynamic-edit-modal.component.html',
})
export class DynamicEditModalComponent extends BaseComponent {
  show = false;
  @Input() editing = false;
  @Input() dynamicGroup: IDynamicModelGroup;
  @Input() title: string = null;
  /** Useful if the dialog should be read only */
  @Input() hideSaveButton = false;
  @Input() cancelButtonLabel: string;
  @ViewChild(DynamicContainerComponent)
  dynamicContainerComponent: DynamicContainerComponent;

  @Output() saveChanges = new EventEmitter<any>();

  constructor(public dynamicRender: DynamicRenderService) {
    super();
  }

  open(scrollToTop = true) {
    this.show = true;
    if (scrollToTop) {
      setTimeout(() => {
        scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }, 5);
    }
  }

  save() {
    this.saveChanges.emit();
  }

  hide() {
    this.show = false;
  }
}
