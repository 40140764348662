<kendo-dialog *ngIf="showDialog" width="50%" #dialog="kendoDialog">
  <div class="d-flex">
    <h1>
      {{ releaseNote.headerText ? releaseNote.headerText : 'New Release!' }}
    </h1>
    <div class="ms-auto">
      <span class="k-icon k-i-close cursor-pointer" (click)="onClose()"></span>
    </div>
  </div>
  <div class="d-flex justify-content-between">
    <h1 class="subheading mb-11">{{ releaseNote.releaseVersionNumber }}</h1>
    <h2 class="subheading">{{ releaseNote.displayDate | date }}</h2>
  </div>
  <div
    [innerHTML]="
      domSanitizer.bypassSecurityTrustHtml(releaseNote.shortDescription)
    "
  ></div>
  <!-- <button kendoButton [primary]="true" class="mb-11">CTA button</button> -->
  <!-- Currently no CTA button for releases. -->
  <kendo-card class="w-100 mb-0">
    <kendo-card-header (click)="toggleDetails()">
      {{ showDetails ? 'Hide' : 'Show' }} Release Details
    </kendo-card-header>
    <!--    <kendo-card-body #details style="transition: max-height 0.5s linear, padding 0.25s linear; overflow:hidden; padding: 0;">-->
    <kendo-card-body #details [hidden]="!showDetails">
      <div class="details-content">
        <div *ngFor="let fg of usedFeatureGroups">
          <h3 class="mb-7">{{ fg.sectionHeader }}</h3>
          <ul class="mb-0">
            <li *ngFor="let i of fg.featureNotes">
              <p>
                <strong>{{ i.featureName }}</strong>
              </p>
              <div
                [innerHTML]="domSanitizer.bypassSecurityTrustHtml(i.body)"
              ></div>
            </li>
          </ul>
          <hr class="separator-regular-dashed mt-7 mb-7" />
        </div>
      </div>
    </kendo-card-body>
  </kendo-card>
</kendo-dialog>
