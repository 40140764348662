import { AdminDesignWebComponent } from './admin-design-web/admin-design-web.component';
import { AdminFormBuilderWebComponent } from './admin-form-builder-web/admin-form-builder-web.component';
import { AdminPreviewWebComponent } from './admin-preview-web/admin-preview-web.component';
import { AdminPropertiesComponent } from './admin-properties/admin-properties.component';
import { AdminToolboxComponent } from './admin-toolbox/admin-toolbox.component';
import { DrContainerPickerComponent } from './container-picker/container-picker.component';

export const ADMIN_PANEL_COMPONENTS = [
  AdminDesignWebComponent,
  AdminFormBuilderWebComponent,
  AdminPreviewWebComponent,
  AdminPropertiesComponent,
  AdminToolboxComponent,
  DrContainerPickerComponent,
];

export * from './admin-design-web/admin-design-web.component';
export * from './admin-form-builder-web/admin-form-builder-web.component';
export * from './admin-preview-web/admin-preview-web.component';
export * from './admin-properties/admin-properties.component';
export * from './admin-toolbox/admin-toolbox.component';
export * from './container-picker/container-picker.component';
