import { Injectable } from '@angular/core';
import { ApplicationModel } from '@ups/xplat/api/dto';
import { HRRSService } from '@ups/xplat/api/services';

@Injectable({ providedIn: 'root' })
export class CertificationCodeResolver {
  constructor(
    private hrrsService: HRRSService,
    private applicationModel: ApplicationModel
  ) {}

  public loadCertSkillCodes(): Promise<CertificationCode[]> {
    if (this.applicationModel.certSkillCodes.length === 0)
      return this.hrrsService
        .getCertSkillCodes()
        .then((data) => (this.applicationModel.certSkillCodes = data));
    else return Promise.resolve(this.applicationModel.certSkillCodes);
  }

  public loadPhysicalSkillCodes(): Promise<CertificationCode[]> {
    if (this.applicationModel.physicalSkillCodes.length === 0)
      return this.hrrsService
        .getPhysicalSkillCodes()
        .then((data) => (this.applicationModel.physicalSkillCodes = data));
    else return Promise.resolve(this.applicationModel.physicalSkillCodes);
  }

  public loadBackgroundSkillCodes(): Promise<CertificationCode[]> {
    if (this.applicationModel.backgroundSkillCodes.length === 0)
      return this.hrrsService
        .getBackgroundSkillCodes()
        .then((data) => (this.applicationModel.backgroundSkillCodes = data));
    else return Promise.resolve(this.applicationModel.backgroundSkillCodes);
  }
}

export interface CertificationCode {
  id: number;
  text: string;
  cat: string;
}
