import { NgModule } from '@angular/core';
import { environment } from '@ups/xplat/core';
import { AuthModule as SharedAuthModule } from '@ups/user';
import {
  AuthHttpInterceptor,
  AuthModule as Auth0Module,
} from '@auth0/auth0-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  imports: [
    SharedAuthModule,
    Auth0Module.forRoot({
      clientId: environment.auth0.clientId,
      domain: environment.auth0.domain,
      audience: environment.auth0.audience,
      useRefreshTokens: environment.auth0.useRefreshTokens,
      httpInterceptor: {
        allowedList: [
          {
            uri: '*',
            tokenOptions: {
              audience: environment.auth0.audience,
            },
          },
        ],
      },
    }),
  ],
  providers: [
    AuthHttpInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
})
export class AuthModule {}
