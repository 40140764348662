import { Component } from '@angular/core';

import { ConfirmationDialogBaseComponent } from '@ups/xplat/features';

@Component({
  selector: 'ups-confirmation-dialog',
  templateUrl: 'confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent extends ConfirmationDialogBaseComponent {
  show: boolean;
  constructor() {
    super();
  }

  /**
   * @param message What message should be shown on the dialog?
   * @param confirmCallback This function is ran whenever the dialog is confirmed
   * @param cancelCallback This function is called if the dialog is cancelled
   */
  open(
    message?: string,
    confirmCallback?: () => void,
    cancelCallback?: () => void
  ) {
    super.open(message, confirmCallback, cancelCallback);
    this.show = true;
  }

  onConfirm() {
    super.onConfirm();
    this.show = false;
  }

  onCancel() {
    super.onCancel();
    this.show = false;
  }
}
