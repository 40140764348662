import { Pipe, PipeTransform } from '@angular/core';
import { keyBy } from 'lodash';

@Pipe({
  name: 'CheckboxFilter',
})
/**
 * Arg should be an array of objects in this format
 *
 ts:
 checkboxes: any[] = [{
        id: 1,
        label: 'Filter 1',
        state: true
    }];

 forceUpdateCheckboxes(): void {
        if( this.checkboxes )
            this.checkboxes = this.checkboxes.slice();
    }

 html:

 <div class="btn-group">
 <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
 Filter
 </button>
 <div class="dropdown-menu">
 <span class="dropdown-item" *ngFor="let cb of checkboxes">
 <label>
 <input id="designationFilter{{cb.id}}" type="checkbox" [(ngModel)]="cb.value" (ngModelChange)="forceUpdateCheckboxes()"> {{cb.label}}
 </label>
 </span>
 </div>
 </div>

 */
export class CheckboxFilterPipe implements PipeTransform {
  transform(
    values: any[],
    filterKey: string,
    args: any[],
    equalsExact: boolean = false,
    blankText: string = '(Blank)'
  ): any {
    if (values == null || values.length === 0 || args == null) return;
    return values.filter((value) => {
      for (let i = 0; i < args.length; i++) {
        const arg = args[i];
        if (arg['value'] === true && value[filterKey] !== undefined) {
          if (
            arg['label'] === blankText &&
            (value[filterKey] == null ||
              value[filterKey].toString().trim().length === 0)
          ) {
            return true;
          }
          if (
            value[filterKey] != null &&
            ((equalsExact && value[filterKey] === arg['label']) ||
              (!equalsExact && value[filterKey].indexOf(arg['label']) >= 0))
          ) {
            return true;
          }
        }
      }
      return false;
    });
  }

  createFilterItems(
    data: any[],
    field: string,
    value: boolean = true,
    blankText: string = '(Blank)'
  ): any[] {
    const self = this;
    let temp = keyBy(data, field);
    let unique = Object.keys(temp);
    let checkboxes = [];
    for (let i = 0; i < unique.length; i++) {
      let oo = {
        id: i,
        label:
          unique[i] === 'null' || unique[i].trim().length === 0
            ? blankText
            : unique[i],
        value: value,
      };
      checkboxes.push(oo);
    }
    checkboxes = checkboxes.sort((yin, yang) => {
      if (yin.label > yang.label) {
        return 1;
      }
      if (yin.label < yang.label) {
        return -1;
      }
      return 0;
    });
    return checkboxes;
  }
}
