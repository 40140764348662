import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// common
import { DropDownsOpenOnEnterDirective } from './dropdowns-open.on.enter-directive';
import { DropDownSearchExtensionDirective } from './dropdowns-search.ex-directive';

// dropdown-list
import { DropDownListRememberFilterDirective } from './dropdownlist-remember.filter-directive';

// dropdown-list - applied to ALL
import { DropDownListClearButtonDirective } from './dropdownlist-clear.button-directive';
import { DatePickerClearButtonDirective } from './datepicker-clear.button-directive';

const exportedModules = [
  // common
  DropDownsOpenOnEnterDirective,
  DropDownSearchExtensionDirective,
  // dropdown-list
  DropDownListRememberFilterDirective,
  // dropdown-list - applied to ALL
  DropDownListClearButtonDirective,
  DatePickerClearButtonDirective,
];

const declarations = [...exportedModules];

@NgModule({
  declarations: [declarations],
  exports: [exportedModules],
  imports: [CommonModule],
})
export class DropDownsModuleEx {}
