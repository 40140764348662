import { BaseModel } from '../../base.model';

export class EquipmentResourceActiveRequestsSummaryDto extends BaseModel {
  KeyID: number;
  VPJobIDs: string[];
  StartDates: Date[];
  EndDates: Date[];
  DispatchStatusNames: string[];

  constructor(o: any = null) {
    super(o);
  }
}
