import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { HelpState } from './state';
import { HelpEffects } from './state/help.effects';
import { helpReducer } from './state/help.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('help', helpReducer, {
      initialState: HelpState.initialState,
    }),
    EffectsModule.forFeature([HelpEffects]),
  ],
  declarations: [],
  exports: [],
})
export class InAppHelpModule {}
