import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UI_DIRECTIVES } from './directives';
import { UI_PIPES } from './pipes';

const MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  TranslateModule,
];

@NgModule({
  imports: [...MODULES],
  declarations: [...UI_DIRECTIVES, ...UI_PIPES],
  exports: [...MODULES, ...UI_DIRECTIVES, ...UI_PIPES],
})
export class UISharedModule {}
