import {
  Component,
  ElementRef,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';

import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import {
  HelpActions,
  HelpBaseComponent,
  HelpDocumentDto,
  HelpState,
  HelpTypeEnum,
} from '@ups/xplat/base/in-app-help';

@Component({
  selector: 'help-control',
  templateUrl: './help.component.html',
  styles: [
    `
      help-control {
        position: absolute;
        right: 0em;
        top: 0;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class HelpComponent extends HelpBaseComponent {
  tooltip: HelpDocumentDto[];

  public hasWalkthroughAssociated(...types: HelpTypeEnum[]) {
    types = types || [];
    return types.some((t) => {
      return t == HelpTypeEnum.Tooltip && this.tooltip?.length;
    });
  }

  public get shouldRender(): boolean {
    // return this.container.showEditMode
    return (
      this.showTooltips &&
      this.hasWalkthroughAssociated(HelpTypeEnum.Tooltip) &&
      this.security.getFeatureById(
        SecurityConstants.employee_portal_frameworkfeatures_tooltips
      ).readAll
    );
  }

  private securityConstants = SecurityConstants;

  constructor(
    private renderer: Renderer2,
    private element: ElementRef,
    private store: Store,
    private security: SecurityService
  ) {
    super();
    store.pipe(HelpState.selectShowTooltips(this.destroy$)).subscribe((x) => {
      this.showTooltips = x;
    });

    store
      .select(HelpState.selectDocumentDictionary)
      .pipe(takeUntil(this.destroy$))
      .subscribe((docs) => {
        this.tooltip = (docs[this.walkthroughPathValue] || []).filter(
          (x) => x.type === HelpTypeEnum.Tooltip
        );
      });
  }

  onClick(ev, type, shouldEdit = false) {
    // this.container.onElementClick.emit({
    //   elementId: this.walkthroughIdValue,
    //   elementPath: this.walkthroughPathValue,
    //   type: type,
    //   edit: shouldEdit
    // } as WalkthroughElementClickEvent);
  }

  ngOnInit(): void {
    this.store.dispatch(
      HelpActions.registerUIPath({ path: this.walkthroughPathValue })
    );
  }

  ngAfterViewInit() {
    // Fixing position (web only)
    if (this.element?.nativeElement?.parentNode) {
      this.renderer.setStyle(
        this.element?.nativeElement?.parentNode,
        'position',
        'relative'
      );
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(
      HelpActions.deregisterUIPath({ path: this.walkthroughPathValue })
    );
  }
}
