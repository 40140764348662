import { Directive } from '@angular/core';
import { BaseComponent } from '@ups/xplat/core';
import { HelpTypeEnum } from '../models';

@Directive()
export class HelpBaseComponent extends BaseComponent {
  public helpTypeEnum = HelpTypeEnum;

  public walkthroughIdValue: string;
  public walkthroughPathValue: string;

  public showTooltips = false;
}
