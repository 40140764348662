import {
  Component,
  Input,
  ChangeDetectorRef,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { CountSettingsEnum } from '@ups/xplat/api/dto';
import { LogService } from '@ups/xplat/core';

@Component({
  selector: 'ups-equipment-bar-chart',
  templateUrl: './equipment-bar-chart.component.html',
  styleUrls: ['./equipment-bar-chart.component.scss'],
})
export class EquipmentBarChartComponent implements AfterViewInit {
  @ViewChild('weeksDropdown') set weeksDropdown(
    content: DropDownListComponent
  ) {
    if (content?.wrapper?.nativeElement?.children?.length ?? 0 > 1) {
      // content.wrapper.nativeElement.children[1].hidden = true;
    }
  }

  @ViewChild('daysDropdown') set daysDropdown(content: DropDownListComponent) {
    if (content?.wrapper?.nativeElement?.children?.length ?? 0 > 1) {
      // content.wrapper.nativeElement.children[1].hidden = true;
    }
  }

  @Input() showDataByDates = false;
  @Input() showDataByWeeks = false;
  @Input() showDataByJobs = false;
  @Input() useMonths = false;
  @Input() sectionTitle = 'Title';
  @Input() field: string;
  @Input() vertical = false;
  countSettingsEnum = CountSettingsEnum;
  @Input() countSettings: CountSettingsEnum = CountSettingsEnum.Equipment;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartData: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  weeksChartData: any[] = [];
  chartHeight = 500;
  daysToProcess = 30;
  weeksToProcess = 13;
  monthsToProcess = 6;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() set data(data: any) {
    if ((this.field || this.showDataByDates) && data) {
      this.chartData = this.showDataByWeeks
        ? data.slice(0, this.weeksToProcess)
        : data;
      this.weeksChartData = this.showDataByWeeks ? data : null;

      if (!this.showDataByDates) {
        this.processChartData();
      } else {
        if (this.useMonths) {
          this.processMonthData();
        } else {
          this.processDateData();
        }
      }
    }
  }

  public color = '#AA9569';

  public placeholderColor = '#EF3B24';

  showPanel = true;

  categories: string[] = [];
  openJobs: number[] = [];
  filledJobs: number[] = [];
  onsiteJobs: number[] = [];
  notOnsiteJobs: number[] = [];
  onsiteDispatchJobs: number[] = [];
  notOnsiteDispatchJobs: number[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private dateFormatPipe: DatePipe,
    private logger: LogService
  ) {}

  handleShowHide(property: string): void {
    this[property] = !this[property];
    this.logger.debug('hideShow', this[property]);
  }

  public ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public labelContent = (e: any) =>
    e.stackValue - e.value + ' / ' + e.stackValue;

  processDateData() {
    if (!this.daysToProcess) this.daysToProcess = 30;

    const dateData = [];
    this.clearChartArrays();

    for (let i = 0; i < this.daysToProcess; i++) {
      const date = new Date();
      date.setDate(date.getDate() + i);
      dateData.push({
        date: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
        totalCount: 0,
        nonEmptyCount: 0,
        onsiteCount: 0,
        dispatchedCount: 0,
        totalCountOnsite: 0,
      });
    }

    dateData.forEach((element) => {
      this.categories.push(
        this.dateFormatPipe.transform(element.date, 'MM/dd/yyyy')
      );

      this.chartData.forEach((item) => {
        if (
          (!item.StartDate ||
            item.StartDate.getTime() <= element.date.getTime()) &&
          (!item.EndDate || item.EndDate.getTime() >= element.date.getTime())
        ) {
          element.totalCount += item.totalCount;
          element.nonEmptyCount += item.nonEmptyCount;
          element.onsiteCount += item.onsiteCount;
          element.dispatchedCount += item.dispatchedCount;
          element.totalCountOnsite += item.totalCountOnsite;
        }
      });

      this.processRecordToJobsArrays(element);
    });
  }

  processWeeksData() {
    if (!this.weeksToProcess) this.weeksToProcess = 13;
    this.chartData = this.weeksChartData.slice(0, this.weeksToProcess);

    this.processChartData();
  }

  processMonthData() {
    if (!this.monthsToProcess) this.monthsToProcess = 6;

    const monthData = [];
    this.clearChartArrays();

    for (let i = 0; i < this.monthsToProcess; i++) {
      const date = new Date();
      date.setMonth(date.getMonth() + i);

      monthData.push({
        date: new Date(date.getFullYear(), date.getMonth(), 1),
        totalCount: 0,
        nonEmptyCount: 0,
        onsiteCount: 0,
        dispatchedCount: 0,
        totalCountOnsite: 0,
      });
    }

    monthData.forEach((element) => {
      this.categories.push(
        this.dateFormatPipe.transform(element.date, 'MMM yy')
      );
      const monthEnd = new Date(
        element.date.getFullYear(),
        element.date.getMonth() + 1,
        0
      );

      this.chartData.forEach((item) => {
        if (
          (!item.StartDate || item.StartDate.getTime() <= monthEnd.getTime()) &&
          (!item.EndDate || item.EndDate.getTime() >= element.date.getTime())
        ) {
          element.totalCount += item.totalCount;
          element.nonEmptyCount += item.nonEmptyCount;
          element.onsiteCount += item.onsiteCount;
          element.dispatchedCount += item.dispatchedCount;
          element.totalCountOnsite += item.totalCountOnsite;
        }
      });

      this.processRecordToJobsArrays(element);
    });
  }

  processChartData() {
    this.clearChartArrays();

    this.chartData.forEach((element) => {
      this.categories.push(element[this.field]);
      this.processRecordToJobsArrays(element);
    });

    if (!this.vertical) {
      this.chartHeight = this.chartData.length * 15;
      if (this.chartHeight < 300) {
        this.chartHeight = 300;
      }
    }
  }

  processRecordToJobsArrays(element) {
    this.openJobs.push(element.totalCount - element.nonEmptyCount);
    this.filledJobs.push(element.nonEmptyCount);
    this.onsiteJobs.push(element.onsiteCount);
    this.notOnsiteJobs.push(element.totalCountOnsite - element.onsiteCount);
    this.onsiteDispatchJobs.push(element.dispatchedCount + element.onsiteCount);
    this.notOnsiteDispatchJobs.push(
      element.totalCountOnsite - (element.dispatchedCount + element.onsiteCount)
    );
  }

  clearChartArrays() {
    this.categories = [];
    this.openJobs = [];
    this.filledJobs = [];
    this.onsiteJobs = [];
    this.notOnsiteJobs = [];
    this.onsiteDispatchJobs = [];
    this.notOnsiteDispatchJobs = [];
  }
}
