import { Directive, EventEmitter, Input, OnDestroy, Optional, Output } from '@angular/core';
import type { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { NgControl } from '@angular/forms';

import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { IMCSOptions, ToStateEx, IMCSSearchColumnConfig, MCSSearchColumnUtils, IMCSOrderConfig, MCSOrderUtils } from '../models';
import { BaseComponent } from '@ups/xplat/core';

/**
 * Please refer to:
 * @ups/angular-shared-libs/kendo/DropDowns/dropdowns.module.ex as the parameter types are the same (just have diff. prefix and some are not valid here)!
 */
@Directive({
  selector: '[multiColumnSearch][formControlName],[multiColumnSearch][formControl],[multiColumnSearch][ngModel]',
})
export class MultiColumnSearchDirective extends BaseComponent implements OnDestroy {
  @Input() public mcsSearchColumns: string | string[] | any = [];

  @Output() public mcsFilterChange = new EventEmitter<CompositeFilterDescriptor>();

  @Input() public mcsMatchUseStartsWithDefault = false;
  @Input() public mcsMatchCaseInsensitiveDefault = true;

  @Input() public mcsWordSplitter = ' ';
  @Input() public mcsMatchAllWords = true;
  @Input() public mcsMatchInEveryColumn = false;

  @Input() public mcsCreateForKendoToOdataString = true;

  protected _mcsDebounceFilterChange = 250;

  @Input() /* must be placed on getter */ public get mcsDebounceFilterChange(): number {
    return this._mcsDebounceFilterChange;
  }

  public set mcsDebounceFilterChange(value: number) {
    this._mcsDebounceFilterChange = value;

    // set up subscription
    if (this.filterChangeSubscription) this.filterChangeSubscription.unsubscribe();

    this.filterChangeSubscription = this.filterChangeSubject$.pipe(debounceTime(this.mcsDebounceFilterChange)).subscribe((value: any) => this.internalFilterChangeExDebounced(value));
  }

  private s: Subscription;

  private lastFilterValue = '';
  private filterChangeSubject$: Subject<any> = new Subject<any>();
  private filterChangeSubscription: Subscription;

  constructor(@Optional() private ngControl: NgControl) {
    super();
    this.ngControl.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.valueChangesInternal.bind(this));

    // NOTE; triggering setter to set up subscription...
    this.mcsDebounceFilterChange = this.mcsDebounceFilterChange;
  }

  ngOnDestroy() {
    if (this.s) {
      this.s.unsubscribe();
    }
  }

  public valueChangesInternal(e): void {
    this.filterChangeSubject$.next(e);
  }

  //
  //
  //

  protected internalFilterChangeExDebounced(e) {
    // get safe search string
    const search = e || '';

    const searchOptions = {
      matchWithStartsWithByDefault: this.mcsMatchUseStartsWithDefault,
      matchCaseInsensitiveByDefault: this.mcsMatchCaseInsensitiveDefault,

      wordSplitter: this.mcsWordSplitter,
      matchAllWords: this.mcsMatchAllWords,
      matchInEveryColumn: this.mcsMatchInEveryColumn,

      orderByFirstSearchColumnWhenNoOrderByColumns: false,
      maximizeResultTo: undefined,
    } as IMCSOptions;

    const columnFilterSettings = MCSSearchColumnUtils.getSearchColumnConfigArray(this.mcsSearchColumns, this.mcsMatchUseStartsWithDefault, this.mcsMatchCaseInsensitiveDefault);
    const orderSettings = [] as IMCSOrderConfig[];
    const state = ToStateEx.convert(searchOptions, columnFilterSettings, orderSettings, search, null, this.mcsCreateForKendoToOdataString);

    this.mcsFilterChange.emit(state.filter);
  }
}
