/**
 * Common constants shared accross
 */
export class CommonConstants {
  /** DB side restriction for smalldatetime type */
  public static readonly minSmallDateTime: Date = new Date(1900, 1, 1);

  /** DB side restriction for smalldatetime type */
  public static readonly maxSmallDateTime: Date = new Date(2079, 6, 6);

  /** Reduction of force reasons dropdown values */
  public static readonly rofReasons: string[] = ['Voluntary', 'Involuntary', 'Deceased', 'Reduction in Force', 'No Show'];
}
