import { Injectable } from '@angular/core';
import { ApplicationModel } from '@ups/xplat/api/dto';
import { HRRTService } from '@ups/xplat/api/services';

@Injectable({ providedIn: 'root' })
export class CertificationSkillTestersResolver {
  constructor(private hrrtService: HRRTService, private applicationModel: ApplicationModel) {}

  public load(): Promise<any> {
    var self = this;
    if (this.applicationModel.certificationSkillTesters.length == 0) return this.hrrtService.getCertificationSkillTesters().then((data) => (self.applicationModel.certificationSkillTesters = data.Data));
    else return Promise.resolve(this.applicationModel.certificationSkillTesters);
  }
}
