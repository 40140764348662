import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApplicationModel } from '@ups/xplat/api/dto';
import { HRResourceService } from './hrresource-service';

@Injectable({
  providedIn: 'root',
})
export class BackgroundTeamResolver {
  constructor(
    private hrResourceService: HRResourceService,
    private applicationModel: ApplicationModel
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    const self = this;
    if (this.applicationModel.backgroundTeam.length === 0)
      return this.hrResourceService
        .fetchBackgroundTeam()
        .then((data) => (self.applicationModel.backgroundTeam = data));
    else return null;
  }
}
