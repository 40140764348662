import { Directive, EventEmitter, OnInit, Output } from '@angular/core';
import { DynamicItemBaseComponent } from '../dynamic-item/dynamic-item.base-component';
import { take } from 'rxjs';
import moment from 'moment';
import { IDynamicModelOptions } from '@ups/xplat/core';

@Directive()
export abstract class DynamicDateBaseComponent
  extends DynamicItemBaseComponent
  implements OnInit
{
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  selectedDate: string;
  dateFormat = 'MMM D, yyyy';
  apiDateFormat = 'YYYY-MM-DDTHH';
  emptyDisplay = '---';

  updateValue(value: string, targetFieldName?: string) {
    super.updateValue(value, targetFieldName);

    if (this.config?.options?.updateOnValueChange) {
      this.config?.options?.updateOnValueChange.forEach((selectionConfig) => {
        if (selectionConfig.formControlName) {
          const options: IDynamicModelOptions = {};
          if (selectionConfig.validation === 'minDate') {
            options.minDate = this.getDateFromValue(value);
          }
          this.dynamicService.itemTargetUpdate$.next({
            formControlName: selectionConfig.formControlName,
            value,
            type: 'date',
            options,
          });
        }
      });
    }
  }

  updateDateValue(value: string | number | Date) {
    const datetime = moment(value);
    if (this.config?.options?.minDate) {
      const minDate = moment(this.config?.options?.minDate);
      // console.log('datetime:', datetime);
      // console.log('minDate:', minDate);
      if (datetime.isBefore(minDate)) {
        this.dynamicService.win.alert({
          title: 'Invalid Date Selection',
          message: `${this.config.label} cannot be less than ${minDate.format(
            this.dateFormat
          )}`,
          okButtonText: 'Ok',
        });
        return;
      }
    }
    this.selectedDate = datetime.format(this.dateFormat);

    const apiFormatValue = datetime.format(this.apiDateFormat);
    this.updateValue(apiFormatValue);
    this.valueChange.emit(apiFormatValue);
    this.dynamicService.log.debug(apiFormatValue);
  }

  getDateFromValue(value: string) {
    const minDate = value.split('T')[0];
    const dateParts = minDate.split('-').map((v) => parseInt(v));
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
    return new Date(year, month - 1, day);
  }
}
