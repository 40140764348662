import { Injectable } from '@angular/core';

// declare let $: any;

// import * as kendoIconMeta from '!!raw-loader!./data/kendo.icons.html';
// import * as faCategoriesMeta from '!!raw-loader!./data/fa.categories.yml';
// import * as faIconMeta from '!!raw-loader!./data/fa.icons.yml';
import { faCategories } from './data/fa-categories';
import { faIcons } from './data/fa-icons';

@Injectable({
  providedIn: 'root',
})
export class IconSearchService {
  // public kendoSet: IconSet;
  public faSet: IconSet;

  public categories;

  constructor() {
    this.faSet = this.loadFontAwesomeMeta();
    this.categories = [...new Set(Object.getOwnPropertyNames(this.faSet))];
  }

  getFaIcons() {
    return Object.entries(faIcons)
      .filter((icon) => icon[1]['styles'].includes('solid'))
      .map((icon) => ({ Name: 'fa-' + icon[0] }));
  }

  loadFontAwesomeMeta() {
    const iconSet = {} as IconSet;

    const jsonCategories = faCategories;
    const jsonIcons = faIcons;

    for (const categoryName in jsonCategories) {
      const categoryData = jsonCategories[categoryName];
      const groupName = categoryData.label;

      iconSet[groupName] = categoryData.icons.map((iconName) => {
        const iconData = jsonIcons[iconName];
        return {
          label: iconData.label,
          names: [iconName],
          searchTerms: iconData.search.terms,
          styles: iconData.styles,
          unicode: iconData.unicode,
        } as IconDetail;
      });
    }

    return iconSet;
  }

  public getFaStyleClass(style: string) {
    if (style === 'solid') return 'fas';
    if (style === 'regular') return 'far';
    if (style === 'light') return 'fal';
    if (style === 'duotone') return 'fad';
    if (style === 'brands') return 'fab';
  }

  public search(set: IconSet, iconSearch: string, categorySearch: string = '') {
    const filteredCategoriesSet = categorySearch
      ? Object.getOwnPropertyNames(set)
          .filter(
            (categoryName) =>
              categoryName
                .toUpperCase()
                .indexOf(categorySearch.toUpperCase()) >= 0
          )
          .reduce((prev, curr) => {
            prev[curr] = set[curr];
            return prev;
          }, {})
      : set;

    const resultSet = {} as IconSet;

    const searchInCategoryNames = !categorySearch;

    // tslint:disable-next-line: forin
    for (const categoryName in filteredCategoriesSet) {
      resultSet[categoryName] = filteredCategoriesSet[categoryName].filter(
        (iconDetail) =>
          // in the icon search terms
          iconDetail.searchTerms.some(
            (st) => st.toUpperCase().indexOf(iconSearch.toUpperCase()) >= 0
          ) ||
          // or in the category name
          categoryName.toUpperCase().indexOf(iconSearch.toUpperCase()) >= 0
      );

      if (resultSet[categoryName].length === 0) delete resultSet[categoryName];
    }

    return resultSet;
  }
}

export interface IconDetail {
  label: string;
  names: string[];
  searchTerms: string[];
  unicode?: string;
  styles?: string[];
}

export interface IconSet {
  [index: string]: IconDetail[];
}
