export class PackMaterial {
  material: string;
  keyID: number;
  lineItemId: number;
  qtyPicked: number;
  qtyPacked: number;
  qtyShipped: number;
  qtyReadyToPack: number;
  packListID?: number;
  packID?: number;
  loc?: string;
}
