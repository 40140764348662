import { DataResult, orderBy, process, SortDescriptor, State } from '@progress/kendo-data-query';

import { PathSegment } from './path-segment';

/**
 * Class is responsible for:
 * - grouping the flat item array from the call
 * - merge the grouped result into the existing group-structure hierarchy
 */
export class GroupResultMerger {
  /*
    Converts  plain array of items int a structure kendo grid can work with.
    Actually it calls the kendos process() to do that grouping conversion.
     */
  public static processItems(loadedItems: any[], state: State): DataResult {
    if (!loadedItems || !loadedItems.length) return process([], {});

    // Regular items were loaded, so it's a regular process call, no aggregates, etc...
    // ...but we have to avoid skip/take/sort/filter

    // important: if we use grouping, kendo process will use group.dir for group sort and filter.dir for other sorting...
    return process(loadedItems, { group: state.group, sort: state.sort });
  }

  /*
    Handles the grouping of loaded group-items similarly as kendo process(...) function does - so into the same structure.

    Some developer notes:
        Even if we do just group fetching, the last level nodes will be items (aggregate values).

        If we do level-by-level fetching and are fetching just the 2nd level (but more grouping level exists), then kendo process() will cretate groups for the remaining 3..N levels as well.
        ...this behaviour is patched here!

        Also we do load aggregates as well (at least count). These aggregates are on the last nodes of the structure acquired by kendos process(...)
        These aggregates (properties on the item) should be bound to the immediate-upper node (group).
        Those aggregates might have also some linkedAggregateFields which should be processed here as well.
     */
  public static processGroups(parentGroupPath: PathSegment[], loadedGroups: any[], state: State, linkedAggregateFields: string[][] | null = null, levelLoaded = 0, countFieldName = 'count'): DataResult {
    if (!loadedGroups || !loadedGroups.length) return process([], {});

    let processedGroupItems: any;
    let usedGroupState: State;

    if (levelLoaded === 0) {
      // all group-combinations were loaded in one shot, we do regular kendo pocessing...but we loose aggregates for each groups...
      usedGroupState = { group: state.group, sort: state.sort } as State;

      // important: if we use grouping, kendo process will use group.dir for group sort and filter.dir for other sorting...
      processedGroupItems = process(loadedGroups, usedGroupState);
    } else {
      // just the N-th level sub-group was loaded...here we need to remove "extra" groups that would be normally generated.
      usedGroupState = { group: state.group.slice(0, (parentGroupPath ? parentGroupPath.length : 0) + levelLoaded), sort: state.sort } as State;
      processedGroupItems = process(loadedGroups, usedGroupState);
    }

    // ...then we do handle aggregates (and clear last-node non-aggregate items)

    const groupNestingLevel = usedGroupState.group.length;
    GroupResultMerger.applyAggregatesForProcessedGroupItems(processedGroupItems.data, groupNestingLevel, linkedAggregateFields);
    this.sortGroupItems(processedGroupItems.data, usedGroupState.sort);

    return processedGroupItems;
  }

  private static sortGroupItems(items: any[], sort: SortDescriptor[]) {
    items = items || [];
    items.forEach((i) => {
      if (PathSegment.isAggregate(i) && i.items.length) {
        i.items = orderBy(i.items, sort);
      }

      const hasAnotherLevel = i.items.length && PathSegment.isAggregate(i.items[0]);
      if (hasAnotherLevel) {
        this.sortGroupItems(i.items, sort);
      }
    });
  }

  /*
    Unlike the way how aggregates are handled by default: https://www.telerik.com/kendo-angular-ui/components/dataquery/#toc-aggregates.

    We will use aggregate["default"] property (object) to store the aggregate values (and also linked aggregate values), so we will usually have:
    aggregate["default"] = {
        count: anyValidNumber,
        sum: anyValidNumber,
        average: anyValidNumber,
        min: anyValidNumber,
        max: anyValidNumber,
        linkedField1Name: linkedField1Value,
        ...
        linkedFieldNName: linkedFieldNValue,
    }

    Our GroupOdata helpers do generate aggregates just when we're loading groups (not items) - there will be just one single item...
    ...so item[0] "default" aggregate values (sum, count, average, min, max) has to be copied to the immediate parent only.
    ...while the linkedAggregateFields should be copied to any aggregate, where field name matches any of the field names in the array.
    ...but they might also be copied to the immediate parent as well (should be safe).

    //
    // GETTING LEVEL 1 GROUPS (default PM-dashboard load where at least 1 group exists)
    //
    [
        // LEVEL 1 GROUPS
        {
            "aggregates": {},
            "field": "VPJobID",
            "items": [{
                    "PlantName": "17 CHS McPherson Refinery",
                    "PlantID": 160,
                    "JobName": "CHS/McPherson, KS/2019 Spring TAR",
                    "VPJobID": "600723.17",
                    "count": 6
                }
            ],
            "value": "600723.17"
        }
    ]

    //
    // GETTING LEVEL 2 GROUPS - TO LEVEL1 VPJOBID == "600723.17" GROUP
    //
    [
        // LEVEL 1 GROUP(S) - depends if we load 1 by 1 or all in one...
        {
            "aggregates": {},
            "field": "VPJobID",
            "items": [
                // LEVEL 2 GROUPS
                {
                    "aggregates": {},
                    "field": "SupervisorName",
                    "items": [{
                            "PlantName": "17 CHS McPherson Refinery",
                            "PlantID": 160,
                            "JobName": "CHS/McPherson, KS/2019 Spring TAR",
                            "SupervisorName": "Roel",
                            "VPJobID": "600723.17",
                            "count": 3
                        }
                    ],
                    "value": "Roel"
                }
            ],
            "value": "600723.17"
        }
        ...
    ]

    //
    // GETTING LEVEL 3 ITEMS - as we're getting items, it's just a flat array, with no GroupOdata calculated aggregate field
    //
    // ...
    // NO SAMPLE
    // ...

    //
    // HTML SAMPLE:
    //

    <kendo-grid-column title="Job" field="VPJobID">
        <ng-template kendoGridGroupHeaderTemplate let-dataItem let-field="field" let-value="value" let-group="group" let-aggregates="aggregates">

            <pm-dashboard2-group-header
                [dataItem]="dataItem"
                [showLabel]="false"
                (groupSelectChecked)="groupFullExpand($event)">
            </pm-dashboard2-group-header>

            <!-- TODO: override detault header not to be bold -->
            <div style="font-weight: initial;">
                <span>{{ dataItem.field}}: <strong>{{dataItem.value}}</strong></span>
                <span *ngIf="aggregates.default && aggregates.default.PlantName">&nbsp;{{ aggregates.default.PlantName }}</span>
                <span *ngIf="aggregates.default && aggregates.default.JobName">&nbsp;{{ aggregates.default.JobName }}</span>
            </div>

        </ng-template>
        <!--
        <ng-template kendoGridFilterCellTemplate>
            <pm-dashboard2-filter [textField]="'VPJobID'" [valueField]="'VPJobID'" [seSearchColumns]="['VPJobID']"></pm-dashboard2-filter>
        </ng-template>
        -->
    </kendo-grid-column>

    //
    // COUNT ISSUE
    // - we always get item counts in the given group, but NEVER the number of next group rows!
    //

    L1
    {
        "value": [{
                "PlantName": "17 CHS McPherson Refinery",
                "PlantID": 160,
                "JobName": "CHS/McPherson, KS/2019 Spring TAR",
                "VPJobID": "600723.17",
                "count": 12 -- total items count, not L2 item count to L1 which is 2
            }
            ...
        ],
        "@odata.count": 39,
        "@odata.nextLink": ""
    }

    L2 to L1
    {
        "value": [{
                "PlantName": "17 CHS McPherson Refinery",
                "PlantID": 160,
                "JobName": "CHS/McPherson, KS/2019 Spring TAR",
                "SupervisorName": "",
                "VPJobID": "600723.17",
                "count": 9
            }, {
                "PlantName": "17 CHS McPherson Refinery",
                "PlantID": 160,
                "JobName": "CHS/McPherson, KS/2019 Spring TAR",
                "SupervisorName": "Roel",
                "VPJobID": "600723.17",
                "count": 3
            }
        ],
        "@odata.count": 2,
        "@odata.nextLink": ""
    }


     */
  private static applyAggregatesForProcessedGroupItems(
    gridItems: any[],
    groupNestLevel: number,
    linkedAggregateFields: string[][] | null,

    // values for the recursive call:
    parentItems: any[] = []
  ) {
    const level = parentItems ? parentItems.length + 1 : 1;
    const currentNodeItems = gridItems;

    for (const nodeItem of currentNodeItems) {
      if (PathSegment.isAggregate(nodeItem)) {
        // NOTE: if we'd know that there's surely an item, we could do just this: let lastAggregateNode = !this.isAggregate(nodeItem.items[0]);
        const lastAggregateNode = level === groupNestLevel;

        if (lastAggregateNode) {
          //
          // apply default and special (linked aggregate field) values to the group item's aggregate property
          nodeItem.aggregates['default'] = nodeItem.items[0];
          Object.assign(nodeItem, nodeItem.items[0]);

          //
          // apply linked aggregate fields...
          //
          // - considering if we do need it, cause on each level we do fetch the data again and again
          // - so copy to immediate parent should be enough
          // - this just actually keeps all those data in-sync in case of some DB changes

          // take array-by-array
          if (linkedAggregateFields) {
            for (const linkedAggregateFieldsItem of linkedAggregateFields) {
              // traverse parent items
              for (const parentItem of parentItems) {
                // if field name inside the linkedAggregateFieldsItem, we do copy
                const copyOver = linkedAggregateFieldsItem.some((i) => i == parentItem.field);

                if (copyOver) {
                  parentItem.aggregates['default'] = parentItem.aggregates['default'] || {};
                  linkedAggregateFieldsItem.forEach((i) => (parentItem.aggregates['default'][i] = nodeItem.aggregates['default'][i]));
                }
              }
            }
          }

          //
          // delete the only item inside items, which contains just aggregate values
          // also an empty items collection is a marker that we can merge here data (so REAL data is not loaded)
          nodeItem.items = [];
        } else {
          this.applyAggregatesForProcessedGroupItems(nodeItem.items, groupNestLevel, linkedAggregateFields, parentItems.concat(nodeItem));
        }
      }
    }
  }

  /*
    This function does merge 2 kendo group result structures.

        Merge is done in a simple way, whenever we see that aggregate has items assigned, we skip setting new items, since we assume items were already set/loaded.

        PARAMS:
            destinationItems    - usually items on the grid
            sourceItems         - usually the new items loaded

            mergeItemsToExistingAggregatesOnly
                if this is FALSE, then the sourceItem groups will be merged into the destination (FULL MERGE)
                if set to TRUE, then only items (no groups) will be merged to an existing aggregate (group) inside the destinationItems (item only merge) - no new aggregates on that level will be created

        TOTAL CHANGE DUE EPHF-556:
        - the SQL not necessarily distinguishes between casing when grouping, so JuneBug/junebug might become a single group: Junebug
        - the kendo process(...) function does distinguish between casing, so it might end up with 2 aggregates with keys: JuneBug and junbebug which have to be merged into the single parnet group

        IMPORTANT:
        - we will need to add the items one by one, level by level, never a tree, as the case sensitive grouping might split a case insensitive group into multiple

        12/21/19: The function had to be rewritten, so it merges properly with case-insensitivity grouping.
     */
  public static mergeSubTreeOfProcessedNewItemsWithGrid(destinationItems: any[], sourceItems: any[], mergeItemsToExistingAggregatesOnly = false, matchCaseInsensitive = true, matchNullEmpty = true) {
    // TEST
    // console.log('>> merge', destinationItems, sourceItems);

    // NOTE:
    // 1) we-re parsing same level - always, so all items of the grid should have same field value as the pre-processed items
    // 2) we-re loading data to existing nodes, so we will alter existing grid node items
    const multipleGridFields = destinationItems.some((i) => i.field !== destinationItems[0].field);
    const multiplePpItemFields = sourceItems.some((i) => i.field !== sourceItems[0].field);
    const mismatchingFields = destinationItems.length && sourceItems.length && destinationItems[0].field !== sourceItems[0].field;

    if (multipleGridFields || multiplePpItemFields || mismatchingFields) throw new Error('Wrong data in destinationItems / sourceItems array - on the same level only one fieldName should exist and it should be equal in both arrays!');

    for (const ppItem of sourceItems) {
      // skip all items if NOT aggregate
      if (!PathSegment.isAggregate(ppItem)) return;

      // we need to match corresponding item on the grid
      // if no corresponding item is found, we don't do anything, as we extend just existing nodes
      let gItem;

      if (ppItem.value instanceof Date) {
        gItem = destinationItems.find((i) => i.field === ppItem.field && i.value && ppItem.value && i.value.getTime() === ppItem.value.getTime());
      } else {
        gItem = destinationItems.find((i) => i.field === ppItem.field && (i.value === ppItem.value || (matchCaseInsensitive && i.value && ppItem.value && i.value.toString().toLowerCase() === ppItem.value.toString().toLowerCase()) || (matchNullEmpty && (i.value === null || i.value === '') && (ppItem.value === null || ppItem.value === ''))));
      }

      if (!gItem) {
        // skip this item if not parent group found and just merges to existing aggregate is allowed
        if (mergeItemsToExistingAggregatesOnly) continue;

        // NOTE: we just push a single item (and it's main level, no subrees)!
        const moddedItem = Object.assign({}, ppItem);
        moddedItem.items = [];
        destinationItems.push(moddedItem);
        continue;
      }

      // we now merge ppItem.items
      // but we do clear ppItem.items.items...
      // ...as we will merge level by level (reason: merge must consider case-insensitive parent match)
      if (!gItem.items) gItem.items = [];

      const hasAnotherLevel = ppItem.items.length && PathSegment.isAggregate(ppItem.items[0]);
      if (hasAnotherLevel) {
        // handling aggregates
        this.mergeSubTreeOfProcessedNewItemsWithGrid(gItem.items, ppItem.items, mergeItemsToExistingAggregatesOnly);
      } else {
        // last level (nodes)
        gItem.items.push(...ppItem.items);
      }
    }
  }
}
