/* eslint-disable @typescript-eslint/naming-convention */
// angular
import { Injectable } from '@angular/core';

export interface IDebug {
  LEVEL_1: boolean;
  LEVEL_2: boolean;
  LEVEL_3: boolean;
  LEVEL_4: boolean;
  LEVEL_5: boolean;
  // isolate logging to specific logTag's
  ISOLATE_LOG_TAGS?: Array<string>;
  // exclude logging to specific logTag's
  EXCLUDE_LOG_TAGS?: Array<string>;
}

export interface IHttpDebug {
  enable: boolean;
  includeRequestBody?: boolean;
  includeRequestHeaders?: boolean;
  includeResponse?: boolean;
  jsonFilePath?: string; // if this is set, it will use local json files
}

@Injectable({
  providedIn: 'root',
})
export class LogService {
  static DEBUG: IDebug = {
    LEVEL_1: false, // .warn only
    LEVEL_2: false, // .error only
    LEVEL_3: false, // .log + all the above
    LEVEL_4: false, // .log + all the above + info
    LEVEL_5: false, // just info (excluding all else)
  };

  static DEBUG_HTTP: IHttpDebug = {
    enable: false,
    includeRequestBody: false,
    includeRequestHeaders: false,
    includeResponse: false,
  };

  // info (extra messages like analytics)
  // use LEVEL_5 to see only these
  info(...msg: Array<unknown>) {
    if (LogService.DEBUG.LEVEL_5 || LogService.DEBUG.LEVEL_4) {
      // extra messages
      // eslint-disable-next-line no-restricted-syntax
      console.info(msg);
    }
  }

  // debug (standard output)
  debug(...msg: Array<unknown>) {
    if (LogService.DEBUG.LEVEL_4 || LogService.DEBUG.LEVEL_3) {
      if (
        LogService.DEBUG.EXCLUDE_LOG_TAGS &&
        LogService.DEBUG.EXCLUDE_LOG_TAGS.includes(msg[0] as string)
      ) {
        return;
      }
      if (
        !LogService.DEBUG.ISOLATE_LOG_TAGS ||
        (msg && LogService.DEBUG.ISOLATE_LOG_TAGS.includes(msg[0] as string))
      ) {
        console.log(msg);
      }
    }
  }

  // error
  error(...err: Array<unknown>) {
    if (
      LogService.DEBUG.LEVEL_4 ||
      LogService.DEBUG.LEVEL_3 ||
      LogService.DEBUG.LEVEL_2
    ) {
      console.error(err);
    }
  }

  // warn
  warn(...warn: Array<unknown>) {
    if (
      LogService.DEBUG.LEVEL_4 ||
      LogService.DEBUG.LEVEL_3 ||
      LogService.DEBUG.LEVEL_1
    ) {
      console.warn(warn);
    }
  }
}
