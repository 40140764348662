import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'OnlyNullPipe',
})
/**
 * Usage:
 *
 * <tr *ngFor="let item of mf.data | OnlyNullPipe:'fieldName'">
 *
 * ts file:
 <tr *ngFor="let item of mf.data | OnlyNullPipe:'JobClass'">
 */
export class OnlyNullPipe implements PipeTransform {
  transform(values: any[], key: string): any {
    return values.filter((value) => {
      if (!value.hasOwnProperty(key) || !value[key]) {
        return true;
      } else {
        return false;
      }
    });
  }
}
