/**
 * Class with static functions to work with items inside kendo grid GroupResult tree-structure.
 * - convert PathSegment[] to group index and vice versa
 * - get item to a PathSegment[]
 * - or get PathSegment[] to an item
 *
 * A PathSegment[] describes the path to a selected item or group.
 * For all group (aggregate) a field/value property is set.
 * For items those values are missing.
 *
 * PROPERTY INDEX IS IN USE @:
 * PathSegment.getGroupIndex
 * PathSegment.getItem - why not use just PathSegment.item!? or getItemByItemIndex !?
 * Traverse.traverseGridData
 *
 */
export class PathSegment {
  public field: string;
  public value: any;

  public index: number;
  public item: any;

  public get isAggregate() {
    // for aggregates we have field value set (or also groupIndex)
    return this.field ? true : false;
  }

  constructor(item: any, index: number) {
    const isAggregate = PathSegment.isAggregate(item);

    if (isAggregate) {
      this.field = item.field;
      this.value = item.value;
    }

    this.index = index;
    this.item = item;
  }

  //
  //
  //

  /**  Checks if given item is an aggregate. */
  public static isAggregate(item: any) {
    // TODO: consider a better place for this function!
    // NOTE: if item is a GroupResult (so has all it's properties), then it's an aggregate...
    return ['aggregates', 'field', 'items', 'value'].every((p) => item.hasOwnProperty(p));
  }

  /*
    Gets kendo groupIndex value for the given path.

    Params:

        path:
            Path to get groupIndex for
        skipSize:
            It's value is the state.Skip = (pageNumber - 1) * pageSize = (pageNumber - 1) * state.take!
            We need to patch 1st node index, due how groupIndex is used in kendo internally.
     */
  public static getGroupIndex(path: PathSegment[], skipSize: number): string {
    // note: fix for cases where state.skip is undefined (so assumed to be zero)
    skipSize = skipSize || 0;

    let groupIndex = '';

    path.forEach((item, index) => {
      if (index === 0) groupIndex = (item.index + skipSize).toString();
      else groupIndex += '_' + item.index;
    });

    return groupIndex;
  }

  /*
        Gets a path to a groupIndex.

        Params:

            groupIndex:
                a kendo identifier for groups inside the grid for which we will create a path-array identifier
            items:
                root node items on the grid
            pageSize:
                It's value eq. to state.Take
                We need to patch 1st level index values, due how groupIndex is used in kendo internally.
     */
  public static getPathToGroupIndex(groupIndex: string, items: any[], pageSize: number): PathSegment[] {
    // get indexes (patch 1. node index as it's shifted via original state's skip * currentPage)
    const groupIndexSegments = groupIndex.split('_').map((i) => parseInt(i));
    groupIndexSegments[0] = pageSize ? groupIndexSegments[0] % pageSize : groupIndexSegments[0];

    let currentNodeItems = items;

    const path = groupIndexSegments.map((index) => {
      // get item and create path-segment
      const item = currentNodeItems[index];
      const pathSegment = new PathSegment(item, index);

      // enter next level of nesting
      currentNodeItems = currentNodeItems[index].items;

      // return segment for current level...
      return pathSegment;
    });

    return path;
  }

  /*
    Gets a path identifier to a data item within items[].
    The items[] is a kendo grid data structure (might contain multiple GroupResult nested ~ so it's a tree).
     */
  public static getPathToDataItem(items: any[], dataItem: any): PathSegment[] | null {
    return this.getPathToDataItemInternal(items, dataItem);
  }

  private static getPathToDataItemInternal(items: any[], dataItem: any, parentPath: PathSegment[] = []): PathSegment[] | null {
    if (!dataItem) return null;

    const currentNodeItems = items;

    for (let index = 0; index < currentNodeItems.length; index++) {
      const nodeItem = currentNodeItems[index];
      const nodeItemPath = parentPath.concat(new PathSegment(nodeItem, index));

      const itemFound = nodeItem === dataItem;
      if (itemFound) return nodeItemPath;

      if (PathSegment.isAggregate(nodeItem)) {
        const innerResult = PathSegment.getPathToDataItemInternal(nodeItem.items, dataItem, nodeItemPath);
        if (innerResult) return innerResult;
      }
    }

    return null;
  }

  /*
    Gets an item identified by the path from within items[].
    The items[]  is a kendo grid data structure (might contain multiple GroupResult nested ~ so it's a tree).
     */
  public static getItem(items: any[], path: PathSegment[] | null) {
    let thisNode;
    let thisNodeItems = items;

    path.forEach((ps, psIdx) => {
      thisNode = thisNodeItems[ps.index];
      thisNodeItems = thisNode.items;
    });

    return thisNode;
  }
}
