/**
 * Created by Tom.Jaeschke on 12/27/2017.
 */
import { Pipe, PipeTransform } from '@angular/core';
import { PseudodateWrapper } from './pseudodate-wrapper.model';

@Pipe({ name: 'simplePseudodate' })
export class SimpleDateForPseudodatePipe implements PipeTransform {
  transform(value: PseudodateWrapper): string {
    if (value && value.date) {
      let month: string = value.date.month + '/';
      if (value.date.month < 10) month = '0' + month;
      let day: string = value.date.day + '/';
      if (value.date.day < 10) day = '0' + day;
      let year: string = String(value.date.year);
      return month + day + year[2] + year[3];
    }
    return '';
  }
}
