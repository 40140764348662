import { HttpErrorResponse } from '@angular/common/http';
import { ApiResult, ApiResultCamelCase } from './api-result';

/** Returned by UPS backend APIs in case of http error such as Sparta Api, Viewpoint Api, ... in case of and http error httpError.error is the ApiResult.
 * If http client was constructed as pascal cased use UpsApiHttpErrorResponse, if it was constructed as camel cased use UpsApiHttpErrorResponseCamelCase
 * or you can use ApiResultTransform class to transform between each other.
 */
export class UpsApiHttpErrorResponse extends HttpErrorResponse {
  /** Api specific error information */
  error: ApiResult;
}

/** Returned by UPS backend APIs in case of http error such as Sparta Api, Viewpoint Api, ... in case of and http error httpError.error is the ApiResult.  */
export class UpsApiHttpErrorResponseCamelCase extends HttpErrorResponse {
  /** Api specific error information */
  error: ApiResultCamelCase;
}
