export interface IMCSOrderConfig {
  field: string;
  dir: 'asc' | 'desc';
}

export class MCSOrderUtils {
  /**
   * Function converts sort expression to a SortDescriptor[].
   *
   *  Parameters:
   *      sortExpression: string[] - column names to do apply sort, use + or - prefix for ascending/descending order.
   *      orderByFirstSearchColumnWhenNoOrderByColumns - if TRUE and no sort expression is given, it'll use the supplied firstColumnName to sort result
   */
  public static getSortOrderConfigArray(orderExpression: string[], orderByFirstSearchColumnWhenNoOrderByColumns: boolean, firstColumnName: string): IMCSOrderConfig[] {
    const orderByColumns = orderExpression.length === 0 && orderByFirstSearchColumnWhenNoOrderByColumns ? [firstColumnName] : orderExpression || []; // ensuring at least an empty array

    return orderByColumns.map((orderExpression) => {
      const isAsc = orderExpression.startsWith('+');
      const isDesc = orderExpression.startsWith('-');
      const columnName = isAsc || isDesc ? orderExpression.substring(1) : orderExpression;

      return {
        field: columnName,
        dir: isDesc ? 'desc' : 'asc',
      } as IMCSOrderConfig;
    });
  }
}
