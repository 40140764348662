import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { GridModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { ValidatorsModuleEx } from '@ups/xplat/features';

import { EquipmentResourceComponent } from './equipment-resource.component';
import { DropDownsModuleEx } from '../kendo/DropDowns/dropdowns.module.ex';
import {
  GridModuleEx,
  GroupingGridExModule,
} from '../kendo/Grid';
import { EQUIPMENT_DASHBOARD_SHARED_COMPONENTS } from '../equipment-dashboard/components';
import { KendoDatepickerSharedModule } from '../kendo-datepicker/kendo-datepicker-shared.module';

@NgModule({
  imports: [
    CommonModule,
    GridModule,
    GridModuleEx,
    GroupingGridExModule,
    LayoutModule,
    SchedulerModule,
    FormsModule,
    InputsModule,
    DateInputsModule,
    DropDownsModule,
    DropDownsModuleEx,
    DialogModule,
    TooltipModule,
    ValidatorsModuleEx,
    KendoDatepickerSharedModule,
  ],
  declarations: [
    EquipmentResourceComponent,
    ...EQUIPMENT_DASHBOARD_SHARED_COMPONENTS,
  ],
  exports: [
    EquipmentResourceComponent,
    ...EQUIPMENT_DASHBOARD_SHARED_COMPONENTS,
  ],
  providers: [],
})
export class EquipmentResourceModule {}
