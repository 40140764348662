/* eslint-disable */
import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  Output,
  Host,
} from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import { TableviewModel } from '@ups/xplat/api/dto';
import { BaseComponent, TableviewService } from '@ups/xplat/core';
import { MessageHelper } from '@ups/xplat/web/core';
import { catchError, filter, of } from 'rxjs';

declare var jQuery: any;

@Component({
  selector: 'tableview',
  templateUrl: './tableview.component.html',
  styleUrls: ['./tableview.component.scss'],
})
export class TableviewComponent extends BaseComponent {
  @Input('key') key: string;
  @Input() state: State;
  @Input() securityFeature: any;
  @Input() excludeFromColConfig: string[] = [];
  @Output() stateChanged = new EventEmitter<State>();

  modalOpen: boolean = false;
  tableViewToEdit: TableviewModel;
  isSaving: boolean = false;
  tableViews: TableviewModel[] = [];
  bSaveGrouping: boolean = true;
  bSaveFiltering: boolean = true;
  bSaveSorting: boolean = true;
  bSaveColConfig: boolean = true;
  bPreSelected: boolean = true;
  bSaveItemsPerPage: boolean = true;
  preSelectedTableViewName: string = 'Default';
  selectedTableView: TableviewModel;
  defaultTableView: TableviewModel;
  $grid: any;

  showTableViewDialog: boolean = false;
  showSaveTableViewDialog: boolean = false;

  defaultGroup = [];
  defaultFilter = <CompositeFilterDescriptor>{ logic: 'and', filters: [] };
  defaultSort = [];
  defaultTake = 10;
  canSetDefaults = false;

  dangerAcknowledged = false;

  constructor(
    @Host() private grid: GridComponent,
    private securityService: SecurityService,
    private msgHelper: MessageHelper,
    private tableviewService: TableviewService
  ) {
    super();

    if (grid) {
      this.$grid = jQuery(grid.wrapper.nativeElement);
    }
  }

  ngOnInit() {
    this.loadTableViews();
    let ls = localStorage.getItem(this.key);
    if (ls) {
      this.preSelectedTableViewName = ls;
    } else {
      localStorage.setItem(this.key, 'Default');
    }
    this.canSetDefaults = this.securityService.getFeatureById(
      SecurityConstants.employee_portal_supers_savedefaulttableviews
    ).editAll;
  }

  loadTableViews() {
    if (this.securityFeature) {
      this.securityFeature.editAll =
        this.securityFeature.editAll || this.securityFeature.editAll;
      this.securityFeature.EditSelf =
        this.securityFeature.EditSelf || this.securityFeature.editSelf;
      this.securityFeature.ViewFeature =
        this.securityFeature.ViewFeature || this.securityFeature.viewFeature;
    }

    this.tableviewService.getTableviews(this.key).subscribe((data) => {
      this.tableViews = data.sort((a, b) =>
        a.Name === 'Default' || a.Name < b.Name ? -1 : 1
      );
      this.selectedTableView = this.tableViews.find(
        (t) => t.Name === this.preSelectedTableViewName
      );
      this.defaultTableView = this.tableViews.find((t) => t.Name === 'Default');
      if (!this.selectedTableView)
        this.selectedTableView = this.defaultTableView;
      if (this.selectedTableView) {
        // this.defaultTableView.Definition = JSON.stringify(this.getGridConfig());
        this.loadState(this.selectedTableView);
      } else {
        this.createDefaultView();
        this.stateChanged.emit(this.state);
      }
    });
  }

  open() {
    this.modalOpen = true;
    this.showTableViewDialog = true;
  }

  openCreate() {
    this.createNewTableView();
    this.showSaveTableViewDialog = true;
  }

  isSaveEnabled() {
    if (!this.selectedTableView) return false;
    if (this.selectedTableView.bDefault) return this.canSetDefaults;
    if (
      !this.securityFeature ||
      (!this.securityFeature.editAll && !this.securityFeature.EditSelf)
    )
      return false;
    return (
      this.securityFeature.editAll ||
      (this.securityFeature.EditSelf && !this.selectedTableView.bPublic)
    );
  }

  openSave() {
    this.tableViewToEdit = this.selectedTableView;
    this.showSaveTableViewDialog = true;
  }

  createDefaultView() {
    this.createNewTableView();
    this.tableViewToEdit.Name = 'Default';
    this.tableViewToEdit.bDefault = true;
    this.tableViewToEdit.bPublic = true;
    this.bSaveColConfig = true;
    this.saveGridSettings(false);
  }

  createNewTableView() {
    this.tableViewToEdit = new TableviewModel({
      Name: '',
      Key: this.key,
      bActive: true,
      bPublic: false,
    });
  }

  public saveGridSettings(showSuccessMessage: boolean = true): void {
    if (!this.tableViewToEdit.TableViewID) {
      let existing = this.tableViews.find(
        (t) => t.Name === this.tableViewToEdit.Name && t.bActive === true
      );
      if (existing) {
        this.msgHelper.error('Duplicate Table View Name!');
        return;
      }
    }

    this.tableViewToEdit.Definition = JSON.stringify(this.getGridConfig());

    this.tableviewService
      .createTableView(this.tableViewToEdit)
      .pipe(
        catchError(() => {
          this.isSaving = false;
          return of(null);
        }),
        filter((r) => !!r)
      )
      .subscribe((data) => {
        if (showSuccessMessage)
          this.msgHelper.success('Table View Save successful');
        this.isSaving = false;
        if (this.tableViewToEdit.TableViewID == null) {
          let temp = this.tableViews;
          temp.push(data);
          this.tableViews = null;
          setTimeout(() => {
            this.tableViews = temp;
            this.selectedTableView = data;
          }, 100);
        }
        this.tableViewToEdit = new TableviewModel();

        if (this.bPreSelected) {
          this.preSelect(data);
        }

        this.bPreSelected = true;
        this.bSaveGrouping = true;
        this.bSaveFiltering = true;
        this.bSaveSorting = true;
        this.bSaveColConfig = true;
        this.bSaveItemsPerPage = true;

        if (data.Name === 'Default') {
          this.defaultTableView = data;
          this.defaultTableView.Definition = JSON.stringify(
            this.getGridConfig()
          );
        }

        this.showSaveTableViewDialog = false;
      });
  }

  getGridConfig() {
    let colConfig = [];
    if (this.grid && this.bSaveColConfig) {
      const columns = this.grid.columns;
      colConfig = columns
        .toArray()
        .filter(
          (col) =>
            col['field'] &&
            (col.width || col.orderIndex !== 0) &&
            this.excludeFromColConfig.indexOf(col['field']) === -1
        )
        .map((col) => {
          return {
            field: col['field'],
            width: col.width,
            orderIndex: col.orderIndex,
            isVisible: col.isVisible,
          };
        });
      colConfig.sort((a, b) =>
        a.orderIndex < b.orderIndex ? -1 : a.orderIndex > b.orderIndex ? 1 : 0
      );

      // to set correct order when columns are hidden
      let order = 0;
      for (let c of colConfig) {
        if (c.isVisible) c.orderIndex = ++order;
      }
      for (let c of colConfig) {
        if (!c.isVisible) c.orderIndex = ++order;
      }
      colConfig.sort((a, b) =>
        a.orderIndex < b.orderIndex ? -1 : a.orderIndex > b.orderIndex ? 1 : 0
      );
    }

    return {
      group: this.bSaveGrouping
        ? this.state.group
          ? this.state.group
          : this.defaultGroup
        : null,
      filter: this.bSaveFiltering
        ? this.state.filter
          ? this.state.filter
          : this.defaultFilter
        : null,
      sort: this.bSaveSorting
        ? this.state.sort
          ? this.state.sort
          : this.defaultSort
        : null,
      columnsConfig: this.bSaveColConfig ? colConfig : null,
      take: this.bSaveItemsPerPage
        ? this.state.take
          ? this.state.take
          : this.defaultTake
        : null,
    };
  }

  loadState(tableView: TableviewModel) {
    let gridConfig = JSON.parse(tableView.Definition);

    let state = this.state;
    let bLockedColumnExist = false;
    if (gridConfig.group) state.group = gridConfig.group;
    if (gridConfig.filter) state.filter = gridConfig.filter;
    if (gridConfig.sort) state.sort = gridConfig.sort;
    state.take = gridConfig.take
      ? gridConfig.take
      : !state.take
      ? this.defaultTake
      : state.take;

    if (this.grid) {
      const columns = this.grid.columns.toArray();
      let order = 0;
      for (let c of columns) {
        if (c['field']) c.orderIndex = ++order;
        if (c['locked']) {
          bLockedColumnExist = true;
        }
      }

      if (tableView.bDefault) {
        // this.$el.parent().parent().next().find('table').removeAttr('style');
        this.$grid.find('table').removeAttr('style');
      }

      if (gridConfig.columnsConfig) {
        for (let c of gridConfig.columnsConfig) {
          let col = columns.find((col) => col['field'] === c.field);
          if (col) {
            if (c.isVisible) {
              if (
                !tableView.bDefault &&
                (col['hidden'] === undefined || col['hidden'] === false)
              )
                this.grid.reorderColumn(col, c.orderIndex - 1);
              col['hidden'] = false;
            } else {
              col['hidden'] = true;
            }
            col.width = c.width;
          }
        }
        for (let c of this.excludeFromColConfig) {
          let col = columns.find((col) => col['field'] === c);
          if (
            !tableView.bDefault &&
            col &&
            col['hidden'] === false &&
            !col['locked']
          ) {
            this.grid.reorderColumn(col, col.orderIndex);
          }
        }
      } else {
        const colsReversed = columns.reverse();
        for (let col of colsReversed) {
          if (this.excludeFromColConfig.indexOf(col['field']) === -1) {
            if (col.isVisible) {
              if (!bLockedColumnExist) {
                this.grid.reorderColumn(col, 0);
                col.width = null;
              }
            } else {
              col['hidden'] = true;
            }
          }
        }
      }
    }

    this.stateChanged.emit(state);
    this.selectedTableView = tableView;
    this.showTableViewDialog = false;
  }

  preSelect(tableView: TableviewModel) {
    this.preSelectedTableViewName = tableView.Name;
    localStorage.setItem(this.key, tableView.Name);
  }

  delete(tableView: TableviewModel) {
    tableView.bActive = false;
    this.saveTableView(tableView);
  }

  undelete(tableView: TableviewModel) {
    tableView.bActive = true;
    this.saveTableView(tableView);
  }

  saveTableView(tableView: TableviewModel) {
    this.tableviewService
      .updateTableView(tableView)
      .pipe(
        catchError(() => {
          this.isSaving = false;
          return of(null);
        }),
        filter((r) => !!r)
      )
      .subscribe(() => {
        this.msgHelper.success('Table View Save successful');
        this.isSaving = false;
      });
  }

  close() {
    if (this.tableViews.some((t) => t.bActive === false)) {
      this.loadTableViews();
    }
    this.showTableViewDialog = false;
  }

  public mapGridSettings() {
    const settings = localStorage.getItem('pmGridSettings');
    if (settings) {
      let gridConfig = JSON.parse(settings);
      this.stateChanged.emit(gridConfig.state);
    }
  }
}
