<!-- Disabling drag/drop in form builder 
  Leaving this here as example of how it can be reenabled. In the future, it could be brought back with more scroll handling and css magic. For now, using the up/down arrow buttons in properties is more precise and easier to use.
-->
<!-- <div class="dynamic-container" cdkDropList (cdkDropListDropped)="drop($event)">
    <div cdkDrag [cdkDragData]="item" *ngFor="let item of dynamicItems" (click)="formBuilderItemSelected.next(item)">
      <ng-container dynamicItem [config]="item" [group]="form"> </ng-container>
    </div>
</div> -->

<div class="dynamic-container">
  <div
    *ngFor="let item of dynamicItems"
    (click)="formBuilderItemSelected.next(item)"
  >
    <ng-container dynamicItem [config]="item" [group]="form"></ng-container>
  </div>
</div>
