import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EquipmentResourceConfirmComponent } from '../equipment-resource-confirm/equipment-resource-confirm.component';
import { EquipmentBasicInfoComponent } from './equipment-basic-info.component';
import { ButtonsModule as KendoButtonsModule } from '@progress/kendo-angular-buttons';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, RouterModule, KendoButtonsModule],
  declarations: [
    EquipmentBasicInfoComponent,
    EquipmentResourceConfirmComponent,
  ],
  exports: [
    EquipmentBasicInfoComponent,
    EquipmentResourceConfirmComponent,
  ],
  providers: [],
})
export class EquipmentBasicInfoModule {}
