import { IEmployeeInfo } from '@ups/xplat/api/dto';

export function getFullNameFromInfo(value: IEmployeeInfo) {
  return value && value.Data ? `${value.Data.FirstName}${value.Data.LastName ? ' ' + value.Data.LastName : ''}` : `...`;
}

export function hasEmptyAddress(value: IEmployeeInfo, prop: 'PhysicalAddress' | 'MailingAddress') {
  if (value && value.Data && value.Data[prop]) {
    if (value.Data[prop].Line1 || value.Data[prop].Line2 || value.Data[prop].City || value.Data[prop].State || value.Data[prop].Zip || value.Data[prop].Country) {
      return false;
    }
  }
  return true;
}

export function getPhysicalCityStateZip(value: IEmployeeInfo) {
  if (value && value.Data && value.Data.PhysicalAddress) {
    return getAddressCityStateZip(value.Data.PhysicalAddress);
  } else {
    return '';
  }
}

export function getMailingCityStateZip(value: IEmployeeInfo) {
  if (value && value.Data && value.Data.MailingAddress) {
    return getAddressCityStateZip(value.Data.MailingAddress);
  } else {
    return '';
  }
}

export function getAddressCityStateZip(address) {
  return `${address.City ? address.City + ', ' : ''}${address.State ? address.State + ' ' : ''}${address.Zip || ''}`;
}
