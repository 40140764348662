/* eslint-disable @typescript-eslint/naming-convention */

import { EmployeeAroundPlantDto, JobClassEmployeeAroundPlant } from '.';
import { ProfileModel } from '..';

export class EmployeeAroundPlantExtended {
  public DistanceInMiles: number;
  public EmployeeCellPhone: string;
  public EmployeeDesignationNames: string;
  public EmployeeId: string;
  public EmployeeJobClasses: JobClassEmployeeAroundPlant[] = [];
  public EmployeeJobClassNames = '';
  public EmployeeLat: string;
  public EmployeeLng: string;
  public FirstName: string;
  public FullName: string;
  public LastDateWorked: Date;
  public LastName: string;
  public PhyCity: string;
  public PhyState: string;
  public Profile: ProfileModel = null;
  public WorkedSinceDate: Date;

  constructor(employeeAroundPlantDto: EmployeeAroundPlantDto) {
    this.DistanceInMiles = employeeAroundPlantDto.distanceInMiles;
    this.EmployeeCellPhone = employeeAroundPlantDto.employeeCellPhone;
    this.EmployeeDesignationNames =
      employeeAroundPlantDto.employeeDesignationNames;
    this.EmployeeId = employeeAroundPlantDto.employeeId;
    this.EmployeeLat = employeeAroundPlantDto.employeeLat;
    this.EmployeeLng = employeeAroundPlantDto.employeeLng;
    this.FirstName = employeeAroundPlantDto.employeeFirstName;
    this.LastDateWorked = employeeAroundPlantDto.lastDateWorked;
    this.LastName = employeeAroundPlantDto.employeeLastName;
    this.PhyCity = employeeAroundPlantDto.phyCity;
    this.PhyState = employeeAroundPlantDto.phyState;
    this.WorkedSinceDate = employeeAroundPlantDto.workedSinceDate;

    this.FullName = this.FirstName + ' ' + this.LastName;

    const employeeData: string[] =
      employeeAroundPlantDto.employeeData.split(';');
    for (const dataItem of employeeData) {
      const dataItemFields: string[] = dataItem.split(',');
      const jobClassName: string = dataItemFields[0]; // [0] JobClassName
      const jobClassId: number = +dataItemFields[1]; // [1] JobClassId
      const employeeId: string = dataItemFields[2]; // [2] EmployeeId in that company
      const vpEmployeeId: string = dataItemFields[3];
      const vpCompanyId: number = +dataItemFields[4]; // [4] VpCompanyId
      const employeeLat: number = +dataItemFields[5]; // [5] EmployeeLat
      const employeeLng: number = +dataItemFields[6]; // [6] EmployeeLng
      const distanceInMiles: number = +dataItemFields[7]; // [7] DistanceInMiles

      this.EmployeeJobClasses.push(
        new JobClassEmployeeAroundPlant(
          distanceInMiles,
          employeeId,
          employeeLat,
          employeeLng,
          jobClassId,
          jobClassName,
          vpCompanyId,
          vpEmployeeId
        )
      );
    }
    this.EmployeeJobClassNames = this.EmployeeJobClasses.map(
      (jc) => jc.jobClassName
    )
      .filter((v, i, a) => a.indexOf(v) === i) // filter makes array to be distinct values
      .join(',');
  }
}
