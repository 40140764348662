/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable } from '@angular/core';
import { parseData } from '@ups/xplat/utils';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {
  DeclineReasonDto,
  DispatchGroupDto,
  DispatchStatusDto,
  HRResourceDto,
  ManpowerSubRequirementGroup,
  ReqStatusDto,
  SafetyCouncilDto,
  UserDto,
} from '@ups/xplat/api/dto';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  MyHttpClientFactory,
  environment,
  ResponseCasingEnum,
} from '@ups/xplat/core';

const GET_BACKGROUND_TEAM = '/api/hrresource/backgroundteam';
const GET_TYPE_OF_PROCESSING = '/api/hrresource/typeofprocessing';
const GET_PERDIEM = '/api/hrresource/perDiem';
const GET_MILEAGE = '/api/hrresource/mileage';
const GET_SAFTY_COUNCILS_LIST = '/api/hrresource/safetyCouncils';
const GET_MANPOWER_SUB_REQUIREMENTS = '/api/hrresource/subRequirementGroups';
const GET_BACKGROUND_TYPES = '/api/hrresource/backgroundTypes';
const GET_DRUGTEST_TYPES = '/api/hrresource/drugTestTypes';
const GET_CUSTOMER_INDUSTRIES = '/api/hrresource/customerIndustries';
const GET_HR_RESOURCE_TYPES = '/api/hrresource/types';
const GET_HR_RESOURCES = '/api/hrresources/{:type}';
const UPDATE_HR_RESOURCE_POSITIONS = '/api/hrresource/positions';
const SAVE_HR_RESOURCE = '/api/hrresource';
const GET_REQUIREMENT_STATUSES = '/api/hrresource/statuses/{:requirementID}';
const UPDATE_REQ_STATUS_POSITIONS = '/api/hrresource/reqStatus/positions';
const SAVE_REQ_STATUS = '/api/hrresource/reqStatus';
const GET_DECLINE_REASONS = '/api/hrresource/declineReasons';
const UPDATE_DECLINE_REASON_POSITIONS =
  '/api/hrresource/declineReason/positions';
const SAVE_DECLINE_REASON = '/api/hrresource/declineReason';
const SAVE_SAFETY_COUNCIL = '/api/hrresource/safetyCouncil';
const GET_DISPATCH_GROUPS = '/api/hrresource/dispatchGroups';
const UPDATE_DISPATCH_GROUP_POSITIONS =
  '/api/hrresource/dispatchGroup/positions';
const SAVE_DISPATCH_GROUP = '/api/hrresource/dispatchGroup';
const USER_SEARCH = '/api/security/user/search?searchString={:term}';
const GET_DISPATCH_STATUSES = '/api/hrresource/dispatchStatuses';
const UPDATE_DISPATCH_STATUS_POSITIONS =
  '/api/hrresource/dispatchStatus/positions';
const SAVE_DISPATCH_STATUS = '/api/hrresource/dispatchStatus';
const GET_EMPLOYMENTVERIFICATION_TYPES = '/api/hrresources/evTypes';

@Injectable({ providedIn: 'root' })
export class HRResourceService {
  http: HttpClient;
  httpCamelCase: HttpClient;

  constructor(private httpFactory: MyHttpClientFactory) {
    this.http = httpFactory.createHttpClient(environment.urls.spartaAPI);
    this.httpCamelCase = httpFactory.createHttpClient(
      environment.urls.spartaAPI,
      true,
      ResponseCasingEnum.CamelCase
    );
  }

  public fetchBackgroundTeam(): any {
    const url = GET_BACKGROUND_TEAM;
    return this.http.get(url).pipe(map(parseData)).toPromise();
  }

  public fetchTypeOfProcessing(): any {
    const url = GET_TYPE_OF_PROCESSING;
    return this.http.get(url).pipe(map(parseData)).toPromise();
  }

  public fetchPerDiem(): any {
    const url = GET_PERDIEM;
    return this.http.get(url).pipe(map(parseData)).toPromise();
  }

  public fetchMileage(): any {
    const url = GET_MILEAGE;
    return this.http.get(url).pipe(map(parseData)).toPromise();
  }

  public getListOfSafetyCouncils(): Promise<any[]> {
    const url = GET_SAFTY_COUNCILS_LIST;
    return this.http.get(url).pipe(map(parseData)).toPromise();
  }

  public getManPowerSubRequirementGroups(): Observable<
    ManpowerSubRequirementGroup[]
  > {
    return this.httpCamelCase.get<ManpowerSubRequirementGroup[]>(
      GET_MANPOWER_SUB_REQUIREMENTS
    );
  }

  public fetchBackgroundTypes(): any {
    const url = GET_BACKGROUND_TYPES;
    return this.http.get(url).pipe(map(parseData)).toPromise();
  }

  public fetchDrugTestTypes(): any {
    const url = GET_DRUGTEST_TYPES;
    return this.http.get(url).pipe(map(parseData)).toPromise();
  }

  public fetchCustomerIndustries(): any {
    const url = GET_CUSTOMER_INDUSTRIES;
    return this.http.get(url).pipe(map(parseData)).toPromise();
  }

  fetchLodgeRoomTypes(): any {
    return this.fetchHRResources('lodgeType');
  }

  fetchLaborTypes(): any {
    return this.fetchHRResources('laborType');
  }

  public fetchHRResourceTypes(): any {
    const url = GET_HR_RESOURCE_TYPES;
    return this.http.get(url).pipe(map(parseData)).toPromise();
  }

  public fetchHRResources(type: string): any {
    const url = GET_HR_RESOURCES.split('{:type}').join(type);
    return this.http.get(url).pipe(map(parseData)).toPromise();
  }

  public updateHRResourcePositions(dtos: HRResourceDto[]) {
    const url = UPDATE_HR_RESOURCE_POSITIONS;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.http.post(url, dtos, options).toPromise().then(parseData);
  }

  public saveHRResource(model: HRResourceDto) {
    const url = SAVE_HR_RESOURCE;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.http.post(url, model, options).toPromise().then(parseData);
  }

  public getRequirementStatuses(requirementID: string): any {
    const url =
      GET_REQUIREMENT_STATUSES.split('{:requirementID}').join(requirementID);
    return this.http.get(url).pipe(map(parseData)).toPromise();
  }

  public updateReqStatusPositions(dtos: ReqStatusDto[]) {
    const url = UPDATE_REQ_STATUS_POSITIONS;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.http.post(url, dtos, options).toPromise().then(parseData);
  }

  public saveReqStatus(model: ReqStatusDto) {
    const url = SAVE_REQ_STATUS;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.http.post(url, model, options).toPromise().then(parseData);
  }

  public getDeclineReasons(): any {
    const url = GET_DECLINE_REASONS;
    return this.http.get(url).pipe(map(parseData)).toPromise();
  }

  public updateDeclineReasonPositions(dtos: DeclineReasonDto[]) {
    const url = UPDATE_DECLINE_REASON_POSITIONS;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.http.post(url, dtos, options).toPromise().then(parseData);
  }

  public saveDeclineReason(model: DeclineReasonDto) {
    const url = SAVE_DECLINE_REASON;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.http.post(url, model, options).toPromise().then(parseData);
  }

  public saveSafetyCouncil(model: SafetyCouncilDto) {
    const url = SAVE_SAFETY_COUNCIL;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.http.post(url, model, options).toPromise().then(parseData);
  }

  public getDispatchGroups(): any {
    const url = GET_DISPATCH_GROUPS;
    return this.http.get(url).pipe(map(parseData)).toPromise();
  }

  public updateDispatchGroupPositions(dtos: DispatchGroupDto[]) {
    const url = UPDATE_DISPATCH_GROUP_POSITIONS;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.http.post(url, dtos, options).toPromise().then(parseData);
  }

  public saveDispatchGroup(model: DispatchGroupDto) {
    const url = SAVE_DISPATCH_GROUP;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.http.post(url, model, options).toPromise().then(parseData);
  }

  public searchUsers(term: string, take: number = 0): Observable<UserDto[]> {
    if (term && term.length > 2) {
      let url = USER_SEARCH.split('{:term}').join(term);

      if (take) url += '&take=' + take;

      return this.http.get<UserDto[]>(url);
    } else {
      return of([]);
    }
  }

  public getDispatchStatuses(): any {
    const url = GET_DISPATCH_STATUSES;
    return this.http.get(url).toPromise();
  }

  public updateDispatchStatusPositions(dtos: DispatchStatusDto[]) {
    const url = UPDATE_DISPATCH_STATUS_POSITIONS;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.http.post(url, dtos, options).toPromise().then(parseData);
  }

  public saveDispatchStatus(model: DispatchStatusDto) {
    const url = SAVE_DISPATCH_STATUS;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.http.post(url, model, options).toPromise().then(parseData);
  }

  public fetchEmploymentVerificationTypes(): any {
    return this.http
      .get(GET_EMPLOYMENTVERIFICATION_TYPES)
      .pipe(map(parseData))
      .toPromise();
  }
}
