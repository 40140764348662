import { BaseModel } from '../base.model';

export class EquipmentLocationHistoryDto extends BaseModel {
  public LocationHistoryId: number;
  public EMCo: number;
  public Equipment: string;
  public Sequence: number;
  public DateIn: Date;
  public TimeIn: Date | null;
  public ToJCCo: number | null;
  public ToJob: string | null;
  public ToLocation: string | null;
  public Memo: string | null;
  public EstDateOut: Date | null;
  public DateTimeIn: Date | null;
  public Notes: string | null;
  public CreatedBy: string;
  public CreatedDate: Date;
  public ModifiedBy: string | null;
  public ModifiedDate: Date | null;

  constructor(o: any = null) {
    super(o);
  }
}
