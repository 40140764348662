import { createReducer, on, Action } from '@ngrx/store';
import { HelpActions } from './help.actions';
import { HelpState } from './help.state';
import { isWalkthrougDocumentForUiPath } from '../utils';

const reducer = createReducer(
  HelpState.initialState,
  on(HelpActions.toggleEditor, (state, payload) => ({
    ...state,
    editor: { show: payload.state, document: payload.document },
  })),
  on(HelpActions.toggleTooltips, (state, payload) => ({
    ...state,
    showTooltips: payload.state,
  })),
  on(HelpActions.loadedDocuments, (state, { docs }) => ({
    ...state,
    loadedDocuments: {
      // Convert the loaded documents (an array), to a map with the path as a key.
      // There could be multiple docs per element, so an array is used.
      // Before adding a loaded document into the array, ensure it is not already there
      ...(Array.isArray(docs) ? docs : []).reduce(
        (map, current) => {
          const idx = (map[current.path] || []).findIndex(
            (doc) => current.id === doc.id
          );
          if (idx !== -1) {
            map[current.path] = [...map[current.path]]; //clone the array
            map[current.path][idx] = current; // update the in-memory value
          } else {
            map[current.path] = [...(map[current.path] || []), current]; // doc not in array, or array is null.
          }
          return map;
        },
        { ...state.loadedDocuments } // start with a copy of the currently loaded doc's
      ),
    },
  })),
  on(HelpActions.registerUIPath, (state, { path }) => ({
    ...state,
    uiPaths: [...state.uiPaths, path],
  })),
  on(HelpActions.bulkDeregisterUIPaths, (state, { paths }) => {
    const newPaths = state.uiPaths.filter((x) => !paths.includes(x));
    return {
      ...state,
      uiPaths: newPaths,
      loadedDocuments: Object.fromEntries(
        Object.entries(state.loadedDocuments).filter(([path]) =>
          newPaths.some((p) => isWalkthrougDocumentForUiPath(p, path))
        )
      ),
    };
  }),
  on(HelpActions.loadWalkthroughSuccess, (state, { document }) => ({
    ...state,
    playingWalkthrough: document,
  })),
  on(HelpActions.closeWalkthrough, (state) => ({
    ...state,
    currentStep: null,
    playingWalkthrough: null,
  })),
  on(HelpActions.goToWalkthroughStep, (state, { step }) => ({
    ...state,
    currentStep: step,
  })),
  on(HelpActions.showHelpDeskModal, (state, action) => ({
    ...state,
    helpdesk: {
      showModal: true,
      submission: action,
    },
  })),
  on(HelpActions.setUrlPath, (state, { path }) => ({
    ...state,
    urlPath: path,
  })),
  on(HelpActions.hideHelpDeskModal, (state) => ({
    ...state,
    helpdesk: {
      showModal: false,
      submission: null,
    },
  }))
);

export function helpReducer(
  state: HelpState.IState | undefined,
  action: Action
) {
  return reducer(state, action);
}
