import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  MyHttpClientFactory,
  ResponseCasingEnum,
  environment,
} from '@ups/xplat/core';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { PaySequenceDto } from '@ups/xplat/api/dto';

/**
 * Sends request to Legacy Sparta APP
 * On sparta App server mark the methods with Auth0AuthenticationSupport Attribute
 * so that the Auth0 user is propertly mapped to Sparta user identity.
 */
@Injectable({
  providedIn: 'root',
})
export class SpartaLegacyService {
  protected urlBase: string;
  protected http: HttpClient;

  constructor(private clientFactory: MyHttpClientFactory) {
    this.urlBase = environment.urls.sparta;
    this.http = this.clientFactory.createHttpClient(
      this.urlBase,
      true,
      ResponseCasingEnum.PascalCase
    );
  }

  public saveLaborAsCsv(
    timesheetIds: string[],
    weekEnd: Date,
    fileName: string
  ): Promise<void> {
    return this.downloadFile(
      this.getLaborAsCsvUrl(timesheetIds, weekEnd),
      fileName
    );
  }

  public saveMileagesAsCsv(
    timesheetIds: string[],
    weekEnd: Date,
    fileName: string
  ): Promise<void> {
    return this.downloadFile(
      this.getMileagesAsCsvUrl(timesheetIds, weekEnd),
      fileName
    );
  }

  public savePerDiemsAsCsv(
    timesheetIds: string[],
    weekEnd: Date,
    fileName: string
  ): Promise<void> {
    return this.downloadFile(
      this.getPerDiemsAsCsvUrl(timesheetIds, weekEnd),
      fileName
    );
  }

  public saveEquipmentUsagesAsCsvUrl(
    timesheetIds: string[],
    weekEnd: Date,
    fileName: string
  ): Promise<void> {
    return this.downloadFile(
      this.getEquipmentUsagesAsCsvUrl(timesheetIds, weekEnd),
      fileName
    );
  }

  public sendLaborToViewpoint(
    timesheetIds: string[],
    weekEnd: Date,
    paySequence: PaySequenceDto
  ): Observable<object> {
    return this.sendToViewpoint(
      timesheetIds,
      weekEnd,
      paySequence,
      '/DataSync/SendTimesheetsToViewpoint'
    );
  }

  public sendEquipmentToViewpoint(
    timesheetIds: string[],
    weekEnd: Date
  ): Observable<object> {
    return this.sendToViewpoint(
      timesheetIds,
      weekEnd,
      null,
      '/DataSync/SendEquipmentTimesheetsToViewpoint'
    );
  }

  private sendToViewpoint(
    timesheetIds: string[],
    weekEnd: Date,
    paySequence: PaySequenceDto,
    method: string
  ): Observable<object> {
    return this.http.post(this.getCsvUrl(timesheetIds, weekEnd, method), {
      timesheetIDs: timesheetIds,
      weekEnd: weekEnd,
      paySequence: paySequence ? paySequence.PaySeq : undefined,
    });
  }

  private getLaborAsCsvUrl(timesheetIds: string[], weekEnd: Date): string {
    return this.getCsvUrl(timesheetIds, weekEnd, '/AppData/GetLaborAsCSV');
  }

  private getMileagesAsCsvUrl(timesheetIds: string[], weekEnd: Date): string {
    return this.getCsvUrl(timesheetIds, weekEnd, '/AppData/GetMileagesAsCSV');
  }

  private getPerDiemsAsCsvUrl(timesheetIds: string[], weekEnd: Date): string {
    return this.getCsvUrl(timesheetIds, weekEnd, '/AppData/GetPerDiemsAsCSV');
  }

  private getEquipmentUsagesAsCsvUrl(
    timesheetIds: string[],
    weekEnd: Date
  ): string {
    return this.getCsvUrl(
      timesheetIds,
      weekEnd,
      '/AppData/GetEquipmentUsagesAsCSV'
    );
  }

  private getCsvUrl(
    timesheetIds: string[],
    weekEnd: Date,
    method: string
  ): string {
    let url = method + '?';

    const queryParameters: string[] = [];

    for (let i = 0; i < timesheetIds.length; i++) {
      queryParameters.push(`[${i}]=${timesheetIds[i]}`);
    }
    const datePipe = new DatePipe('en-US');
    queryParameters.push(
      `weekEnd=${datePipe.transform(weekEnd, 'MM/dd/yyyy')}`
    );

    url = url + queryParameters.join('&');

    return url;
  }

  private downloadFile(url: string, fileName: string): Promise<void> {
    return this.http
      .get(url, {
        responseType: 'blob',
        withCredentials: true,
      })
      .toPromise()
      .then(
        (data) => {
          console.log('Dowload succeess. FileName:' + fileName);
          this.downloadBlob(data, fileName);
        },
        (err) => {
          console.log('Error while downloading file! ' + err.Messages);
          throw err;
        }
      );
  }

  /** Creates file dialog popup for user to choose where to save file.*/
  private downloadBlob(data: any, fileName: string) {
    if (data) {
      console.log('dowload blob:' + fileName);

      let fileURL = URL.createObjectURL(data);

      var a = window.document.createElement('a');
      a.style.display = 'none';
      a.href = fileURL;
      a['download'] = fileName;

      // Append anchor to body.
      document.body.appendChild(a);
      a.click();
      // Remove anchor from body
      document.body.removeChild(a);
    }
  }
}
