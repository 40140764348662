import { Component, Input, OnChanges } from '@angular/core';
import { SelectEvent } from '@progress/kendo-angular-charts';

import { EquipmentResourceDto } from '@ups/xplat/api/dto';
import { BaseComponent } from '@ups/xplat/core';
import { SecurityService } from '@ups/security';
import { SecurityConstants } from '@ups/security-constants';
import { EmployeeGuardService } from '@ups/xplat/base/employee-profile';

@Component({
  templateUrl: './equipment-resource.html',
  selector: 'ups-equipment-resource',
})
export class EquipmentResourceComponent
  extends BaseComponent
  implements OnChanges
{
  public editMode = false;
  public equipmentResourceNotFound = false;

  @Input() equipmentResource: EquipmentResourceDto;

  constructor(
    private security: SecurityService,
    public employeeGuard: EmployeeGuardService
  ) {
    super();
  }

  changeTabs(event: SelectEvent): void {
    if (this.employeeGuard.isToUseGuard) {
      const result = confirm('Discard Changes?');
      if (result) {
        this.employeeGuard.isToUseGuard = false;
      } else {
        event.preventDefault();
      }
    }
  }

  ngOnChanges() {
    if (this.equipmentResource) {
      this.equipmentResourceNotFound = false;
    } else {
      this.equipmentResourceNotFound = true;
    }
  }

  isInfoTabVisible(): boolean {
    return this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_infotab
    ).viewFeature;
  }

  isScheduleTabVisible(): boolean {
    return this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_scheduletab
    ).viewFeature;
  }

  isMetersTabVisible(): boolean {
    return this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_meterstab
    ).viewFeature;
  }

  isInspectionTabVisible(): boolean {
    return this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_inspectiontab
    ).viewFeature;
  }

  isOwnershipInfoTabVisible(): boolean {
    return this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_ownershipinfotab
    ).viewFeature;
  }

  isRentalReleaseInfoTabVisible(): boolean {
    return this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_rentalreleaseinfotab
    ).viewFeature;
  }

  isEquipmentNotesTabVisible(): boolean {
    return this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_equipmentnotestab
    ).viewFeature;
  }

  isEquipmentNotesTabEditable(): boolean {
    return this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_equipmentnotestab
    ).editAll;
  }

  isEquipmentNotesTabReadable(): boolean {
    return this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_equipmentnotestab
    ).readAll;
  }

  isMechanicNotesTabVisible(): boolean {
    return this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_mechanicnotestab
    ).viewFeature;
  }

  isMechanicNotesTabEditable(): boolean {
    return this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_mechanicnotestab
    ).editAll;
  }

  isMechanicNotesTabReadable(): boolean {
    return this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_mechanicnotestab
    ).readAll;
  }

  isJobHistoryTabVisible(): boolean {
    return this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_jobhistorytab
    ).viewFeature;
  }

  isEquipmentRequestHistoryTabVisible(): boolean {
    return this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_equipmentrequesthistorytab
    ).viewFeature;
  }

  isFixedAssetHistoryTabVisible(): boolean {
    return this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_fixedassethistorytab
    ).viewFeature;
  }

  isLocationHistoryTabVisible(): boolean {
    return this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_equipmentlocationhistorytab
    ).viewFeature;
  }

  isEquipmentCalibrationsTabVisible(): boolean {
    return this.security.getFeatureById(
      SecurityConstants.employee_portal_equipment_resources_calibrationstab
    ).viewFeature;
  }

  isVisibleUpperGroupTab(): boolean {
    if (
      this.isInfoTabVisible() ||
      this.isScheduleTabVisible() ||
      this.isMetersTabVisible() ||
      this.isInspectionTabVisible() ||
      this.isEquipmentCalibrationsTabVisible() ||
      this.isOwnershipInfoTabVisible() ||
      this.isRentalReleaseInfoTabVisible() ||
      this.isEquipmentNotesTabVisible() ||
      this.isMechanicNotesTabVisible()
    ) {
      return true;
    }

    return false;
  }

  isVisibleLowerGroupTab(): boolean {
    if (
      this.isJobHistoryTabVisible() ||
      this.isEquipmentRequestHistoryTabVisible() ||
      this.isFixedAssetHistoryTabVisible() ||
      this.isLocationHistoryTabVisible()
    ) {
      return true;
    }

    return false;
  }
}
