import { Directive, OnDestroy, OnInit } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { FilterService } from '@progress/kendo-angular-grid';

import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { patchDateTo4Digits } from './filter-utils';

/** Apply directive on the grid, to turn all date's below 100 to 20XY */
@Directive({
  selector: 'kendo-grid[grid-filter-2digit-year-support]',
})
export class GridFilter2DigitYearSupportDirective implements OnInit, OnDestroy {
  origFilterFnc;
  origFilterFncBound;

  constructor(private gridComponent: GridComponent, private gridFilterService: FilterService) {
    if (!this.gridComponent) {
      throw new Error(`'GridFilters2DigitYearDirective' must be defined on a GridComponent!`);
    }

    // store original filter function of filterService
    this.origFilterFnc = this.gridFilterService.filter;
    this.origFilterFncBound = this.origFilterFnc.bind(this.gridFilterService);

    // redirect to the new filter function
    this.gridFilterService.filter = this.filterNew;
  }

  ngOnInit() {}

  ngOnDestroy() {
    // reset back to original filter function
    this.gridFilterService.filter = this.origFilterFnc;
  }

  // NOTE: must be fat arrow, for this.origFilterBound to work
  filterNew = (compositeFilter: CompositeFilterDescriptor) => {
    /*
    const patchedValue = this.patchToODataListFiltering(compositeFilter);
    this.origFilterFncBound(patchedValue);
    */

    // in case of grid filter (final) we might have a complex statement, with extended composite and filter descriptors (and/or collection filters)...
    // ...we need to parse all and check values everywhere!
    // maybe it'd be worth to consider per column-filter patching!

    // NOTE: on the grid level we can alter the compositeFilter instance
    // ...but we're ok with just a patch (so ref. remains)
    patchDateTo4Digits(compositeFilter);

    console.log('grid hook setting filter on component (called even on clear) - grid filter', compositeFilter);

    this.origFilterFncBound(compositeFilter);
  };
}
