import { BaseModel } from '../../base.model';
import { ExpertiseRatingEnum } from './ExpertiseRatingEnum';

export class EmployeeExpertiseDto extends BaseModel {
  DateModified: Date;
  EmployeeExpertiseId: string | null;
  EmployeeId: string;
  ExpertiseId: string;
  IsActive: boolean | null;
  Rating: number | null | ExpertiseRatingEnum;
  RatingComments: string;
  YearsOfExpertise: number | null;
  ExpertiseName: string;
  ModifiedUserName: string;
  ExpertiseTypeId: number;
  HrRef: number | null; // Are only numbers, but have also string type possible to be compatible with CompanyDto that can have vpcompanyid as string or number

  constructor(o: any = null) {
    super(o);
  }
}
