import { Injectable } from '@angular/core';
import { parseData } from '@ups/xplat/utils';
import { FitModel } from '@ups/xplat/api/dto';
import { BackgroundModel } from '@ups/xplat/api/dto';
import { CertificationModel } from '@ups/xplat/api/dto';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { MyHttpClientFactory, environment } from '@ups/xplat/core';
import { Observable } from 'rxjs';

const GET_CERTIFICATION_BY_HRREF = '/api/hrrs/skills/{:ID}';
const GET_PHYSICALS_BY_HRREF = '/api/hrrs/skills/physical/{:ID}';
const GET_BACKGROUND_BY_HRREF = '/api/hrrs/background/{:ID}';
const GET_CERT_SKILL_CODES = '/api/hrrs/skillcodes/cert';
const GET_PHYSICAL_SKILL_CODES = '/api/hrrs/skillcodes/physical';
const GET_BACKGROUND_SKILL_CODES = '/api/hrrs/skillcodes/background';
const PUT_BACKGROUND_ITEM = '/api/hrrs/background';
const POST_BACKGROUND_ITEM = '/api/hrrs/background';
const PUT_CERTIFICATION_ITEM = '/api/hrrs/cert';
const POST_CERTIFICATION_ITEM = '/api/hrrs/cert';

const GET_FIT_BY_HRREF = '/api/hrrs/fit/{:ID}';
const GET_FIT_CODES = '/api/hrrs/fit/skillcodes';
const GET_FIT_SKILLTESTERS = '/api/hrrs/fit/skilltesters';
const GET_FIT_MODELS = '/api/hrrs/fit/models';
const GET_FIT_MFGS = '/api/hrrs/fit/mfgs';
const GET_FIT_SIZES = '/api/hrrs/fit/sizes';
const PUT_FIT_ITEM = '/api/hrrs/fit';
const POST_FIT_ITEM = '/api/hrrs/fit';

@Injectable({ providedIn: 'root' })
export class HRRSService {
  http: HttpClient;

  constructor(private httpFactory: MyHttpClientFactory) {
    this.http = httpFactory.createHttpClient(environment.urls.viewpointAPI);
  }

  public getCertificationById(id: string) {
    const url = GET_CERTIFICATION_BY_HRREF.split('{:ID}').join(id);

    return this.http.get(url).toPromise().then(parseData);
  }

  public getPhysicalsById(id: string) {
    const url = GET_PHYSICALS_BY_HRREF.split('{:ID}').join(id);

    return this.http.get(url).toPromise().then(parseData);
  }

  public getBackgroundById(id: string) {
    const url = GET_BACKGROUND_BY_HRREF.split('{:ID}').join(id);

    return this.http.get(url).toPromise().then(parseData);
  }

  public getCertSkillCodes() {
    return this.http
      .get(GET_CERT_SKILL_CODES)
      .pipe(map((response) => parseData(response)))
      .toPromise();
  }

  public getPhysicalSkillCodes() {
    return this.http
      .get(GET_PHYSICAL_SKILL_CODES)
      .pipe(map((response) => parseData(response)))
      .toPromise();
  }

  public getBackgroundSkillCodes() {
    return this.http
      .get(GET_BACKGROUND_SKILL_CODES)
      .pipe(map((response) => parseData(response)))
      .toPromise();
  }

  public getFitById(id: string) {
    const url = GET_FIT_BY_HRREF.split('{:ID}').join(id);

    return this.http.get(url).toPromise().then(parseData);
  }

  public getFitCodes() {
    const url = GET_FIT_CODES;

    return this.http.get(url).toPromise().then(parseData);
  }

  public getFitSkillTesters() {
    const url = GET_FIT_SKILLTESTERS;

    return this.http.get(url).toPromise().then(parseData);
  }

  public getFitModels() {
    const url = GET_FIT_MODELS;

    return this.http.get(url).toPromise().then(parseData);
  }

  public getFitMfgs() {
    const url = GET_FIT_MFGS;

    return this.http.get(url).toPromise().then(parseData);
  }

  public getFitSizes() {
    const url = GET_FIT_SIZES;

    return this.http.get(url).toPromise().then(parseData);
  }

  public createFitItem(item: FitModel): Observable<FitModel> {
    return this.http.put(PUT_FIT_ITEM, item) as Observable<FitModel>;
  }

  public updateFitItem(item: FitModel): Observable<FitModel> {
    return this.http.post(POST_FIT_ITEM, item) as Observable<FitModel>;
  }

  public createBackground(item: BackgroundModel) {
    return this.http.put(PUT_BACKGROUND_ITEM, item).toPromise().then(parseData);
  }

  public createCertification(item: CertificationModel) {
    return this.http
      .put(PUT_CERTIFICATION_ITEM, item)
      .toPromise()
      .then(parseData);
  }

  public updateBackground(item: BackgroundModel) {
    return this.http
      .post(POST_BACKGROUND_ITEM, item)
      .toPromise()
      .then(parseData);
  }

  public updateCertification(item: CertificationModel) {
    return this.http
      .post(POST_CERTIFICATION_ITEM, item)
      .toPromise()
      .then(parseData);
  }
}
