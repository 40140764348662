/* eslint-disable @typescript-eslint/ban-ts-comment */
/**
 * You never need to manually modify this file.
 * CI Pipelines use this to stamp version/build info into each app build.
 * @returns version string and build number
 */
export function getAppVersionInfo() {
  let versionName = '0.0.0';
  let buildNumber = '0';

  try {
    // @ts-ignore
    versionName = "1.1.16";
  } catch (err) {}
  try {
    // @ts-ignore
    buildNumber = "135456";
  } catch (err) {}

  return {
    versionName,
    buildNumber,
  };
}
