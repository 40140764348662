<kendo-dialog
  *ngIf="show"
  [title]="titleText"
  [maxWidth]="400"
  [minWidth]="250"
  (close)="onCancel()"
>
  <ng-container *ngIf="bodyTemplate; else messageTemplate">
    <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
  </ng-container>
  <ng-template #messageTemplate>
    <div help-anchor="confirmDialogMessage">
      {{ message }}
    </div>
  </ng-template>
  <kendo-dialog-actions>
    <button
      class="k-button"
      (click)="onCancel()"
      help-anchor="confirmDialogCancelBtn"
    >
      {{ cancelText }}
    </button>
    <button
      help-anchor="confirmDialogConfirmBtn"
      class="k-button k-primary"
      (click)="onConfirm()"
      primary="true"
    >
      {{ confirmText }}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
