import { Action } from '@ngrx/store';
import { ProgressIndicatorState } from './progress-indicator.state';

export namespace ProgressIndicatorActions {
  export enum Types {
    SHOW = '[@ups/progress-indicator] Show',
    HIDE = '[@ups/progress-indicator] Hide',
  }

  export class Show implements Action {
    type = Types.SHOW;

    constructor(public payload?: ProgressIndicatorState.IState) {}
  }

  export class Hide implements Action {
    type = Types.HIDE;

    constructor(public payload?: ProgressIndicatorState.IState) {}
  }

  export type Actions = Show | Hide;
}
