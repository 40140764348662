import { BaseModel } from '../base.model';

export class JobDto extends BaseModel {
  /** VPJobID */
  public JobID: string;
  public Name: string;
  public IsActive: boolean;
  /** Job GUID */
  public Id: string;
  public CompanyID: number | null;
  public CompanyGUID: string | null;

  constructor(o: any = null) {
    super(o);
  }
}
