import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class CustomValidatorsService {
  /**
   * Uses regex to check urls
   */
  public urlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: unknown } => {
      if (!control.value) {
        return null;
      }
      const regex =
        /((ftp|http|https):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/;
      const valid = regex.test(control.value);
      return valid ? null : { invalidUrl: true };
    };
  }

  /**
   * Valid if other is blank, or self is filled.
   * @param other Must have a 'value' property to work
   */
  public requiredIfOtherValidator(other: AbstractControl): ValidatorFn {
    return (control: AbstractControl) => {
      return other.value == null ||
        other.value === '' ||
        (control.value && control.value.length > 0)
        ? null
        : { requiredFieldMissing: true };
    };
  }

  public regexValidator(pattern: RegExp): ValidatorFn {
    return (control: AbstractControl) => {
      return pattern.test(control.value) ? null : { invalidInput: true };
    };
  }
}
