/* eslint-disable @typescript-eslint/naming-convention */
import { BaseModel } from '../base.model';
import { NameAndGuidDto } from './NameAndGuidDto';

export class EquipmentBulkEditDropdownsDto extends BaseModel {
  public Categories: string[];
  public Manufacturers: string[];
  public Models: string[];
  public EquipmentTypes: NameAndGuidDto[];
}
