import { Component } from '@angular/core';

import { BaseComponent } from '@ups/xplat/core';

@Component({
  selector: 'ups-employee-detailed-profile',
  templateUrl: 'employee-detailed-profile.component.html',
})
export class EmployeeDetailedProfileComponent extends BaseComponent {
  constructor() {
    super();
  }
}
