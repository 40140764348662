import { RowArgs } from '@progress/kendo-angular-grid';
import { PathSegment } from './path-segment';
import { Traverse } from './traverse';
import {
  FlattenedItemDetails,
  GroupResultFlattener,
} from './group-result-flattener';

export interface ISelectionDetails {
  selected: FlattenedItemDetails[];
  unselected: FlattenedItemDetails[];
}

/**
 * NOTE:
 *
 * Part of this class could be moved to a generic selection-support.ts file as it could be applied to non-grouping grid as well,
 * ...but they already contain logic for grouping (so it's OK to keep them here),
 * ...they should work on flat-tables as well, but they do have support for handling hierarchical (group) structure!
 */
export class GroupResultSelectionSupport {
  public static selectionStateProperty = '_selectionState';

  public static rowSelected = (context: RowArgs) => {
    return (
      context.dataItem[GroupResultSelectionSupport.selectionStateProperty] &&
      context.dataItem[GroupResultSelectionSupport.selectionStateProperty]
        .checked
    );
  };

  /** Returns an array of selected and unselected items */
  public static getItemSelectionDetails(items): ISelectionDetails {
    items = items || [];

    const itemDetails = GroupResultFlattener.getFlattenedItemsWithDetails(
      items,
      null
    );

    return {
      selected: itemDetails.filter(
        (i) =>
          (i.dataItem[GroupResultSelectionSupport.selectionStateProperty] || {})
            .checked === true
      ),
      unselected: itemDetails.filter(
        (i) =>
          (i.dataItem[GroupResultSelectionSupport.selectionStateProperty] || {})
            .checked === false
      ),
    } as ISelectionDetails;
  }

  /** This function extends every newly loaded item with a selection state object (applies to both for groups and items). */
  public static appendSelectionStateToItems(
    items,
    path: PathSegment[] /*, stateSkip: number */
  ) {
    /*
        const self = this;

        let pathIndex = (path && path.length )
            ? PathSegment.getGroupIndex(path, stateSkip)
            : "";
        */

    Traverse.traverseGridData(
      items,
      path,
      false,
      false,
      (callback) => {
        // init special property if not present or set new itemIndex if exists...
        if (
          !callback.dataItem[GroupResultSelectionSupport.selectionStateProperty]
        ) {
          callback.dataItem[
            GroupResultSelectionSupport.selectionStateProperty
          ] = {};
          callback.dataItem[
            GroupResultSelectionSupport.selectionStateProperty
          ].checked = false;
        }

        /*
                // NOTE: originally there was no way to get itemIndex from the template itself, so we had to patch data here before

                // stack = parentItemIndex (pathIndex initially)
                let itemIndex = callback.stackData
                    ? callback.stackData + "_" + callback.dataIndex.toString()
                    : (callback.dataIndex + stateSkip).toString();

                // set new stack!
                callback.stackData = itemIndex;

                // set item index
                callback.dataItem[GroupingSelectionSupport.selectionStateProperty].itemIndex = itemIndex
                */
      },
      null /*,
            pathIndex */
    );
  }

  /** If we check/uncheck a group, all the items below has to be also checked/unchecked... */
  public static setSelectionStateForSubTree(
    items,
    path: PathSegment[],
    checkState: boolean | null,
    includeSelf: boolean
  ) {
    Traverse.traverseGridData(
      items,
      path,
      includeSelf,
      false,
      (callback) => {
        if (
          !callback.dataItem[GroupResultSelectionSupport.selectionStateProperty]
        ) {
          callback.dataItem[
            GroupResultSelectionSupport.selectionStateProperty
          ] = {};
        }
        callback.dataItem[
          GroupResultSelectionSupport.selectionStateProperty
        ].checked = checkState;
      },
      null
    );
  }

  /** If a selection changes, all the parent groups tri-state selection's has to be recalculated and altered. */
  public static alterSelectionStateForParents(
    items,
    path: PathSegment[]
    // checkState: boolean | null,
    // includeSelf: boolean
  ) {
    if (!path) return;

    for (let i = 0; i < path.length - 1; i++) {
      const index = path.length - 1 - i - 1;
      const segment = path[index];

      if (!segment.item[GroupResultSelectionSupport.selectionStateProperty]) {
        segment.item[GroupResultSelectionSupport.selectionStateProperty] = {};
      }

      if (!segment.item.items || !segment.item.items.length) continue;

      segment.item.items.forEach((item) => {
        if (!item[GroupResultSelectionSupport.selectionStateProperty]) {
          item[GroupResultSelectionSupport.selectionStateProperty] = {};
        }
      });

      const anySoSo = segment.item.items.some(
        (j) =>
          j[GroupResultSelectionSupport.selectionStateProperty].checked == null
      );
      if (anySoSo) {
        // node contains both checked and unchecked items below
        segment.item[
          GroupResultSelectionSupport.selectionStateProperty
        ].checked = null;
        continue;
      }

      const anyChecked = segment.item.items.some(
        (j) =>
          j[GroupResultSelectionSupport.selectionStateProperty].checked === true
      );
      const anyUnchecked = segment.item.items.some(
        (j) =>
          j[GroupResultSelectionSupport.selectionStateProperty].checked ===
          false
      );

      if (anyChecked && anyUnchecked) {
        // node contains both checked and unchecked items below
        segment.item[
          GroupResultSelectionSupport.selectionStateProperty
        ].checked = null;
      } else if (anyChecked) {
        // node contains just checked items
        segment.item[
          GroupResultSelectionSupport.selectionStateProperty
        ].checked = true;
      } else {
        // node contains just unchecked items
        segment.item[
          GroupResultSelectionSupport.selectionStateProperty
        ].checked = false;
      }
    }
  }

  /** Will manage parent and child items checked-state... */
  public static handleItemChecked(
    items,
    path: PathSegment[],
    checkState: boolean
  ) {
    GroupResultSelectionSupport.setSelectionStateForSubTree(
      items,
      path,
      checkState,
      true
    );
    GroupResultSelectionSupport.alterSelectionStateForParents(
      items,
      path
      // checkState,
      // false
    );
  }

  /** Alter selection (checked/unchecked) state for grid items (below given path) to state: checked, if given data is found inside the itemDetails array. */
  public static setSelectionFromItemDetails(
    gridItems,
    path: PathSegment[],
    checked: boolean,
    itemDetails: FlattenedItemDetails[],
    equalityComparerCallback: (left, right) => boolean
  ) {
    if (!itemDetails || !itemDetails.length) return;

    if (!equalityComparerCallback)
      throw new Error(
        'Need equalityComparerCallback to be able to apply selections!'
      );

    Traverse.traverseGridData(
      gridItems,
      path,
      true,
      false,
      (callback) => {
        // set checked state
        const isInSelection = itemDetails.some((si) =>
          equalityComparerCallback(callback.dataItem, si.dataItem)
        );
        if (isInSelection) {
          const selectionProperty =
            callback.dataItem[
              GroupResultSelectionSupport.selectionStateProperty
            ];
          if (selectionProperty) selectionProperty.checked = checked;
        }
      },
      null
    );
  }

  /** Sets proper 3-state checked/unchecked/indetermined values for group selection based on whether all items below are selected/unselected/or mixed... */
  public static refreshGroupSelectionStateForGrid(items) {
    Traverse.traverseGridData(items, null, true, true, null, (callback) => {
      // by using exit callback, we first process innermost items
      const childItems = callback.dataItem['items'];

      if (
        !callback.dataItem[GroupResultSelectionSupport.selectionStateProperty]
      ) {
        callback.dataItem[GroupResultSelectionSupport.selectionStateProperty] =
          {};
      }

      const allChecked =
        childItems.length &&
        childItems.every(
          (i) =>
            i[GroupResultSelectionSupport.selectionStateProperty] &&
            i[GroupResultSelectionSupport.selectionStateProperty].checked ===
              true
        );
      if (allChecked) {
        callback.dataItem[
          GroupResultSelectionSupport.selectionStateProperty
        ].checked = true;
        return;
      }

      const allUnchecked = childItems.every(
        (i) =>
          i[GroupResultSelectionSupport.selectionStateProperty] &&
          i[GroupResultSelectionSupport.selectionStateProperty].checked ===
            false
      );
      if (allUnchecked) {
        callback.dataItem[
          GroupResultSelectionSupport.selectionStateProperty
        ].checked = false;
        return;
      }

      callback.dataItem[
        GroupResultSelectionSupport.selectionStateProperty
      ].checked = null;
      return;
    });
  }
}
