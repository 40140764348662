/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  isCompositeFilterDescriptor,
} from '@progress/kendo-data-query';

import {
  CollectionFilterDescriptorEx,
  UnaryFilterDescriptorEx,
  NoopFilterDescriptorEx,
  isUnaryFilterDescriptorEx,
  isCollectionFilterDescriptorEx,
} from '@ups/xplat/features';

import * as _ from 'lodash';

export const patchFilterDescriptors = (
  filter: FilterDescriptor | CompositeFilterDescriptor | any,
  callback: (f: FilterDescriptor | any) => void
) => {
  if (filter.filters) {
    // traverse groups
    filter.filters.forEach((f) => patchFilterDescriptors(f, callback));
  } else {
    // handle simple filter descriptor
    callback(filter);
  }
};

export const createCollectionFilterDescriptor = (
  collectionField: string,
  collectionRef: string,
  collectionOperator: 'any' | 'all',
  filter: FilterDescriptor | CompositeFilterDescriptor | any
): CollectionFilterDescriptorEx => {
  patchFilterDescriptors(filter, (f) => {
    if (f.field.startsWith(collectionField))
      f.field = collectionRef + f.field.substring(collectionField.length);
  });

  return {
    collectionField: collectionField,
    collectionRef: collectionRef,
    operator: collectionOperator,
    filter: filter,
  } as CollectionFilterDescriptorEx;
};

export const skipEmptyCompositeFilters = (
  filter: CompositeFilterDescriptor,
  keepLastCompositeFilter = false
): FilterDescriptor | CompositeFilterDescriptor | any => {
  let currentFilter = filter as any;

  do {
    const canTrimCurrentNode =
      currentFilter?.filters &&
      currentFilter.filters.length === 1 && // current node is an unnecessary group (group with single item)
      (keepLastCompositeFilter === false || // if we don't wish to keep last group
        (currentFilter.filters[0] as any).filters); // or if there's an additional group present below current node

    if (canTrimCurrentNode) currentFilter = filter.filters[0];
    else return currentFilter;
    // eslint-disable-next-line no-constant-condition
  } while (true);
};

export const removeEmptyFilters = (
  filters: FilterDescriptor[]
): FilterDescriptor[] => {
  return filters.filter(
    (f) => !!f.value || f.operator === 'isnull' || f.operator === 'isnotnull'
  );
};

/**
 * This function creates a valid OData filter structure (interpretable via toOdataStringEx) for collection filtering.
 * Supports'any'|'all' operators for the collection and all the out-of-box (and even extra) string|number|date|boolean operators on values.
 *
 * @param compositeFilter is a flat composite-filter that get's converted to a collection filter.
 *
 * @returns see: resulting filter structure in the comment below.
 *
 *    -> $noop
 *      -> composite(uiFilterLogic)
 *        -> filters[
 *          isnull?: CollectionFilterDescriptorEx*,
 *          isnotnull?: CollectionFilterDescriptorEx*,
 *          values: CollectionFilterDescriptorEx
 *            -> filter: composite(uiFilterLogic)
 *        ]
 *
 *    * the isblank/isnotblank filters might be wrapped inside a 'not' unary filter and the collection-operator inside then might be also negated (switched from all to any and vice versa)
 */
export const patchToUiCollectionFilter = (
  compositeFilter: CompositeFilterDescriptor,
  field: string, // we could ev. 'guess' from compositefilter
  collectionField: string,
  collectionItemField: string,
  collectionOperator: 'any' | 'all' = 'any',
  collectionFieldRef: string = undefined,
  collectionBlankNotBlankOverride = true
): CompositeFilterDescriptor => {
  /*
    We expect a specific structure from menu/cell filters.
    DescriptionFilter's are wrapped inside CompositeFilterDescriptor.
    Sometimes we might get 2 levels of CompositeFilterDescriptor nesting, where:
    - the main one is the one for the grid, using AND logic,
    - the inner one is the one related to current filter (this is what we care about)
  */
  const localCompositeFilter = _.cloneDeep(compositeFilter);

  const uiCompositeFilter = skipEmptyCompositeFilters(
    localCompositeFilter,
    true
  ) as CompositeFilterDescriptor;
  const uiFilterLogic = uiCompositeFilter.logic;
  const uiFilters = removeEmptyFilters(
    uiCompositeFilter.filters as FilterDescriptor[]
  ); /* assuming that we got no more nesting, so just FilterDescriptors */
  const collectionRef =
    collectionFieldRef ||
    'p_' + collectionField.replace(/\/\./g, '_') + new Date().getTime();
  // collectionItemFieldNameRef is assigned a value and never used
  //const collectionItemFieldNameRef = collectionItemField ? collectionRef + '/' + collectionItemField.replace(/\./g, '/') : collectionRef;

  /*
    collectionBlankNotBlankOverride = yes (default)
    - we need them so that is-blank returns just purely empty collections

    also note:
    - in case of 'all' operator, filtering by dates will return also empty results

    collectionOperator == any:
    null = not(any is not null)
    not null = any is not null

    collectionOperator == all:
    null = all is null
    not null = not (all is null) - NOTE: not sure what it'll do with empty colections...
  */
  const collectionFilters = [];

  if (collectionBlankNotBlankOverride) {
    // NOTE: we need special override so that onty TOTALLY NULL COLLECTIONS are considered as null
    const nullFilters = uiFilters.filter((f) => f.operator === 'isnull');
    const notNullFilters = uiFilters.filter((f) => f.operator === 'isnotnull');
    const valueFilters = uiFilters.filter(
      (f) => f.operator !== 'isnull' && f.operator !== 'isnotnull'
    );

    if (nullFilters.length) {
      collectionFilters.push(
        ...nullFilters.map((f) => {
          const negate = collectionOperator === 'any';
          if (negate) f.operator = 'isnotnull';
          const collectionNullFilter = createCollectionFilterDescriptor(
            collectionField,
            collectionRef,
            collectionOperator,
            f
          );
          return negate
            ? ({
                operator: 'not',
                filter: collectionNullFilter,
              } as UnaryFilterDescriptorEx)
            : collectionNullFilter;
        })
      );
    }

    if (notNullFilters.length) {
      collectionFilters.push(
        ...notNullFilters.map((f) => {
          const negate = collectionOperator === 'all';
          if (negate) f.operator = 'isnull';
          const collectionNotNullFilter = createCollectionFilterDescriptor(
            collectionField,
            collectionRef,
            collectionOperator,
            f
          );
          return negate
            ? ({
                operator: 'not',
                filter: collectionNotNullFilter,
              } as UnaryFilterDescriptorEx)
            : collectionNotNullFilter;
        })
      );
    }

    if (valueFilters.length) {
      const valueGroupFilter = {
        logic: uiFilterLogic,
        filters: valueFilters,
      } as CompositeFilterDescriptor;
      collectionFilters.push(
        createCollectionFilterDescriptor(
          collectionField,
          collectionRef,
          collectionOperator,
          valueGroupFilter
        )
      );
    }
  } else {
    const uiGroupFilter = {
      logic: uiFilterLogic,
      filters: uiFilters,
    } as CompositeFilterDescriptor;
    collectionFilters.push(
      createCollectionFilterDescriptor(
        collectionField,
        collectionRef,
        collectionOperator,
        uiGroupFilter
      )
    );
  }

  const compositeCollectionFilter = {
    logic: uiFilterLogic,
    filters: collectionFilters,
  };

  const finalFilter = {
    logic: uiFilterLogic,
    filters: [
      new NoopFilterDescriptorEx(field, compositeCollectionFilter) as any,
    ],
  } as CompositeFilterDescriptor;

  return finalFilter;
};

/**
 * It extracts values that were used to create a collection filter (or a regular filter). Returns them in a single, flat composite filter.
 *
 * @param compositeFilter collection search filter generated via: patchToODataListFiltering or a flat composite filter (although it has some flattener, so it could ev. support more complex scenario).
 */
export const fetchSimpleCompositeFilterFromUiCollectionFilter = (
  compositeFilter: CompositeFilterDescriptor,
  field: string
): CompositeFilterDescriptor => {
  const localCompositeFilter = _.cloneDeep(compositeFilter);

  const innerCompositeFilter = skipEmptyCompositeFilters(
    localCompositeFilter,
    true
  );

  if (
    innerCompositeFilter?.filters &&
    innerCompositeFilter.filters.length === 1 &&
    innerCompositeFilter.filters[0].operator === '$noop'
  ) {
    // we got an extendedfilter, which we need to parse to one kendo can understand...
    const noopFilter = innerCompositeFilter
      .filters[0] as NoopFilterDescriptorEx;
    const fieldName = noopFilter.field;

    if (fieldName !== field) {
      return { logic: 'or', filters: [] };
    }

    const compositeCollectionFilter =
      noopFilter.filter as CompositeFilterDescriptor;
    const filterLogic = compositeCollectionFilter.logic;

    const collectionFilters = compositeCollectionFilter.filters as any;

    // NOTE:
    // there can be multiple collection filters
    // - isnull - optional not filter around a collection filter with a single FilterDescriptor inside
    // - isnotnull - optional not filter around a collection filter with a single FilterDescriptor inside
    // - values - a collection filter with a single CompositeFilterDescriptor inside
    //
    // todo: backwards compatibility
    const flattenedFilters = [] as FilterDescriptor[];

    const fieldPatcher = (
      f: string,
      collectionField: string,
      collectionRef: string
    ): string => {
      if (f.startsWith(collectionRef))
        return collectionField + f.substring(collectionRef.length);
      else return f;
    };

    for (const collectionFilter of collectionFilters) {
      const negate =
        isUnaryFilterDescriptorEx(collectionFilter) &&
        collectionFilter.operator === 'not';

      const collectionFilterToParse = negate
        ? collectionFilter.filter
        : collectionFilter;

      // NOTE: this is just to handle old colection filters from check-box (saved ones) that are incompatible with the new structure
      if (!isCollectionFilterDescriptorEx(collectionFilterToParse)) continue;

      const filterInsideCollectionFilter =
        collectionFilterToParse.filter as any;

      const collectionField = collectionFilterToParse.collectionField;
      const collectionRef = collectionFilterToParse.collectionRef;

      patchFilterDescriptors(
        filterInsideCollectionFilter,
        (f) => (f.field = fieldPatcher(f.field, collectionField, collectionRef))
      );

      const isCompositeFilter = !!filterInsideCollectionFilter.filters;

      if (isCompositeFilter) {
        // values
        flattenedFilters.push(
          ...filterInsideCollectionFilter.filters.filter((f) => !f.filters)
        );
      } else {
        // isnull or isnotnull
        const singleFilter = filterInsideCollectionFilter;

        if (negate) {
          singleFilter.operator =
            singleFilter.operator === 'isnull' ? 'isnotnull' : 'isnull';
        }

        flattenedFilters.push(singleFilter);
      }
    }

    return {
      logic: filterLogic,
      filters: flattenedFilters,
    } as CompositeFilterDescriptor;
  } else {
    // regular filter (need to flatten and filter so we got our values for later use)
    // NOTE: not the most correct, but the expression can be fairly complex
    const filterLogic = innerCompositeFilter?.logic;

    const compositeFilters = flatten(innerCompositeFilter).filter(
      (f) => f.field === field
    );

    return {
      logic: filterLogic,
      filters: compositeFilters,
    } as CompositeFilterDescriptor;
  }
};

export const createUiFilterFor = ({
  field,
  compositeLogic = 'or',
  values,
  valuesOperator = 'eq',
  ignoreCase = true,
}: {
  field: string;
  compositeLogic?: 'and' | 'or';
  values: any[];
  valuesOperator?: string;
  ignoreCase?: boolean;
}): CompositeFilterDescriptor => {
  return {
    logic: compositeLogic,
    filters: (values || []).map((v) => {
      // TODO: isnull and isnotnull could be prefixed and thus support could be added here
      return {
        field: field,
        operator: valuesOperator,
        value: v,
        ignoreCase: ignoreCase,
      } as FilterDescriptor;
    }),
  } as CompositeFilterDescriptor;
};

export const createUiCollectionFilterFor = ({
  field,
  compositeLogic = 'or',
  values,
  valuesOperator = 'eq',
  ignoreCase = true,
  collectionField,
  collectionItemField,
  collectionOperator = 'any',
  collectionFieldRef,
  collectionBlankNotBlankOverride = true,
}: {
  field: string;
  compositeLogic?: 'and' | 'or';
  values: any[];
  valuesOperator?: string;
  ignoreCase?: boolean;
  collectionField?: string;
  collectionItemField?: string;
  collectionOperator?: 'all' | 'any';
  collectionFieldRef?: string;
  collectionBlankNotBlankOverride?: boolean;
}): CompositeFilterDescriptor => {
  // in case of primitive collections, the collection field is same as the field!
  collectionField = collectionField || field;

  const plainCompositeFilter = createUiFilterFor({
    field,
    compositeLogic,
    values,
    valuesOperator,
    ignoreCase,
  });
  const collectionFilter = patchToUiCollectionFilter(
    plainCompositeFilter,
    field,
    collectionField,
    collectionItemField,
    collectionOperator,
    collectionFieldRef,
    collectionBlankNotBlankOverride
  );
  return collectionFilter;
};

export const flatten = (filter) => {
  if (filter?.filters) {
    return filter.filters.reduce(
      (acc, curr) =>
        acc.concat(isCompositeFilterDescriptor(curr) ? flatten(curr) : [curr]),
      []
    );
  }
  return [];
};

export const filterByField = (filter, field) => {
  const [currentFilter] = filtersByField(filter, field);
  return currentFilter;
};

export const removeFilter = (filter, field) => {
  trimFilterByField(filter, field);
  return filter;
};

const trimFilterByField = (filter, field) => {
  if (filter && filter.filters) {
    filter.filters = filter.filters.filter((x) => {
      if (isCompositeFilterDescriptor(x)) {
        trimFilterByField(x, field);
        return x.filters.length;
      } else {
        return x.field !== field;
      }
    });
  }
};
export const filtersByField = (filter, field) =>
  flatten(filter || {}).filter((x) => x.field === field);
