import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { WindowService } from '@ups/xplat/core';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard implements CanActivate {
  constructor(private win: WindowService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const message =
      'You are about to be redirected to sharepoint.universalplant.com. Would you like to continue? You will need to enter the credentials you use to log into your computer.';
    this.win.confirm(message).then((ok) => {
      if (ok) {
        // window.location.href = route.data['externalUrl']; - use this to open url on the same tab
        window.open(route.data['externalUrl'], '_blank');
      }
    });
    return false;
  }
}
