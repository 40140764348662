import { Component, Input, ViewChild } from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { EquipmentSubtaskEditorComponent } from '../equipment-subtask-editor/equipment-subtask-editor.component';
import {
  EquipmentSubRequirementDto,
  EquipmentTypeDto,
} from '@ups/xplat/api/dto';
import { EquipmentPlantJobRequirementDto } from '@ups/xplat/api/dto';
import { Guid } from '@ups/xplat/core';

@Component({
  selector: 'ups-equipment-subtask-list',
  templateUrl: './equipment-subtask-list.component.html',
})
export class EquipmentSubtaskListComponent {
  @Input() equipmentType: EquipmentTypeDto;
  @Input() requirement: EquipmentPlantJobRequirementDto;
  @Input() isReadOnly = true;

  @ViewChild(EquipmentSubtaskEditorComponent)
  editModal: EquipmentSubtaskEditorComponent;

  constructor() {}

  onEditSubtask(subtask: EquipmentSubRequirementDto) {
    if (subtask.EquipmentRequirementID) {
      this.requirement.SubRequirements = this.requirement.SubRequirements.map(
        (s) => {
          if (
            s.EquipmentTypeID === subtask.EquipmentTypeID &&
            s.EquipmentSubRequirementID === subtask.EquipmentSubRequirementID
          )
            return subtask;
          return s;
        }
      );
    } else {
      subtask.EquipmentSubRequirementID = Guid.newGuid();
      subtask.EquipmentTypeID = !this.equipmentType
        ? null
        : this.equipmentType.EquipmentTypeID;
      subtask.EquipmentRequirementID = this.requirement.EquipmentRequirementID;
      this.requirement.SubRequirements.unshift(subtask);
    }
  }

  rowCallback(context: RowClassArgs) {
    return {
      warning: context.dataItem.bActive === false,
    };
  }
}
